/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelBrltlEdiOrderHdrXFgp.ts          *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/datafusion/brltl-edi-order-hdr-x-fgp";

export class AutogenModelBrltlEdiOrderHdrXFgp<RowType extends ModelRow> extends Endpoint<RowType, RowBrltlEdiOrderHdrXFgpFields> {
	constructor(ctor: RowConstructor) {
		super(endpointPath, ctor);
	}
}

export class AutogenRowBrltlEdiOrderHdrXFgp extends ModelRow<RowBrltlEdiOrderHdrXFgpFields> {
	constructor(values?: Partial<RowBrltlEdiOrderHdrXFgpFields>) {
		super(endpointPath, true, values);
	}
}

export interface RowBrltlEdiOrderHdrXFgpFields {
	date_title: string;
	date_value: Date;
	equipment_match_id: number;
	fgp_uid: number;
	float_title: string;
	float_value: number;
	hdr_title: string;
	hdr_uid: number;
	hdr_web_order: number;
	hxf_uid: number;
	integer_title: string;
	integer_value: number;
	lme_order_id: string;
	order_id: string;
	text_title: string;
	text_value: string;
	time_title: string;
	time_value: Date;
	timestamp_title: string;
	timestamp_value: Date;
}

