import { Alignment, Color, VerticalAlignment } from "@mcleod/core";
import { Component } from "../..";
import { ComponentPropDefinitionUtil, ComponentPropDefinitions, ComponentProps } from "../../base/ComponentProps";
import { PropType } from "../../base/PropType";
import { SelectionMode } from "../../base/SelectionMode";
import { ClearButtonVisible } from "../textbox/ClearButtonVisible";
import { layoutSelector } from "../textbox/TextboxProps";
import { AddType } from "./AddType";
import { RowModeControlType } from "./RowModeControlType";
import { Table } from "./Table";
import { TableRowProps } from "./TableRowProps";

export type TableCallback = (table: Table) => void;

export enum TableRowEditResolveMode {
    NONE = "none",
    DIRECT_FROM_EDITOR = "direct",
    LOOKUP_KEY = "lookup",
    CUSTOM = "custom"
}

export interface TableProps extends ComponentProps {
    addType: string;
    allowAdd: boolean;
    allowAdvancedSearch: boolean;
    allowConfig: boolean;
    allowDbSearch: boolean;
    allowDelete: boolean;
    allowDetail: boolean;
    allowEdit: boolean;
    allowExport: boolean;
    allowPin: boolean;
    allowShare: boolean;
    busy: boolean;
    busyWhenDataSourceBusy: boolean;
    columnHeadingsVisible: boolean;
    columns: any[];
    configName: string;
    configDefName: string;
    editingConfig: boolean;
    editLayout: string;
    exportName: string;
    searchLayout: string;
    addLayout: string;
    generalLayout: string;
    editLayoutWidth: number;
    emptyCaption: string;
    emptyComponent: Component;
    filterValue: string;
    filterClearButtonVisible: ClearButtonVisible;
    headerVisible: boolean;
    persistChangesImmediately: boolean;
    preRenderRowCount: number;
    rowAlign: VerticalAlignment;
    rowBorderBottomColor: Color;
    rowBorderBottomWidth: number;
    rowBorderTopColor: Color;
    rowBorderTopWidth: number;
    rowModeControlType: string;
    rowProps: Partial<TableRowProps>;
    rowSpacing: number;
    selectionMode: SelectionMode;
    sequenceField: string;
    rowEditResolveMode: TableRowEditResolveMode;
    virtualized: boolean;
}

let tablePropDefs: ComponentPropDefinitions;

export class TablePropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (tablePropDefs == null) {
            tablePropDefs = {
                ...ComponentPropDefinitionUtil.getComponentPropertyDefinitions(),
                ...ComponentPropDefinitionUtil.getDataBoundPropertyDefinitions(),
                addLayout: { type: PropType.string, category: "Associated layout", editor: layoutSelector, visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "Layout name used in the table when adding a record.  If left blank, the generalLayout will be used." },
                addType: { type: PropType.string, defaultValue: AddType.NOT_ALLOWED, items: [...Object.values(AddType)], visibleInMcLeodTailor: true, description: "Specifies if/how new rows can be added to a table." },
                allowAdd: { type: PropType.bool, category: "Tools", defaultValue: false, visibleInMcLeodTailor: true, description: "Specifies if the user is allowed to add new rows to the table using .  If checked, a button will be shown right-aligned in the Table's header." },
                allowAdvancedSearch: { type: PropType.bool, category: "Tools", visibleInMcLeodTailor: true, description: "Specifies whether a search button will be visible in the tools section.  The search button will show the searchLayout (or generalLayout if not searchLayout is specified) to gather search criteria and filter this Table's dataSource." },
                allowConfig: { type: PropType.bool, category: "Tools", defaultValue: false, visibleInMcLeodTailor: true, description: "Specifies whether the user is allowed to change the column configuration of this Table." },
                allowEdit: { type: PropType.bool, defaultValue: false, visibleInMcLeodTailor: true, description: "Specifies if the rows in a table are allowed to be edited." },
                allowExport: { type: PropType.bool, category: "Tools", defaultValue: false, visibleInMcLeodTailor: true, description: "Specifies if the content of this table can be exported to files." },
                allowDbSearch: { type: PropType.bool, category: "Tools", defaultValue: false, visibleInMcLeodTailor: true, description: "Specifies if the Table's text search field can be used to query the database." },
                allowDelete: { type: PropType.bool, category: "Tools", defaultValue: false, visibleInMcLeodTailor: true, description: "Specifies whether a button to delete the selected row is shown in the Table header." },
                allowDetail: { type: PropType.bool, category: "Tools", defaultValue: false, visibleInMcLeodTailor: true, description: "Specifes whether a button to detail the selected row is shown in the Table header.  This requires the generalLayout or editLayout to be set." },
                allowPin: { type: PropType.bool, category: "Tools", defaultValue: false, visibleInMcLeodTailor: true, description: "Specifies whether an option to pin the current search will shown in an ellipsis button in the Table's header." },
                allowShare: { type: PropType.bool, category: "Tools", defaultValue: false, description: "Specifes whether a button to share a link to the selected row is shown in the Table header." },
                busy: { type: PropType.bool, defaultValue: false, visibleInDesigner: false, description: "This toggles whether or not the table displays the spinner animation." },
                busyWhenDataSourceBusy: { type: PropType.bool, category: "Data", defaultValue: true, visibleInMcLeodTailor: true, description: "This toggles whether or not the table will automatically be set to busy when its bound datasource is busy." },
                columnHeadingsVisible: { type: PropType.bool, defaultValue: true, visibleInMcLeodTailor: true },
                configDefName: { type: PropType.string, category: "Configurable columns", defaultValue: "Default", description: "This is used on Tables that we intend to be configurable by end users.  It specifies the name of the available column format definition (('name' field of list_definition table) that actually be used to save and load the user's preferred view." },
                configName: { type: PropType.string, category: "Configurable columns", description: "This is used on Tables that we intend to be configurable by end users.  It specifies the configuration name ('path' field of the list_definition table) that is used to get a list of the available column formats that are available for the Table." },
                detailLayout: { type: PropType.string, category: "Associated layout", editor: layoutSelector, visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "Layout name used in the table when detailing a record.  If left blank, the generalLayout will be used." },
                editLayout: { type: PropType.string, category: "Associated layout", editor: layoutSelector, visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "Layout name used in the table when editing a record.  If left blank, the generalLayout will be used." },
                editLayoutWidth: { type: PropType.number, category: "Associated layout", visibleInMcLeodTailor: true, description: "The width of the layout that will be shown when editing a record.  This is only needed when it's different than the layout's default width." },
                emptyCaption: { type: PropType.string, visibleInMcLeodTailor: true, description: "This specifies the text to display when there are no records displayed in this table." },
                exportName: { type: PropType.string, visibleInMcLeodTailor: true, description: "This specifies the file name format that will be used when exporting this table.  It currently accepts the keyword {timestamp} that will be replaced by the current timestamp." },
                filterClearButtonVisible: { type: PropType.string, items: Object.values(ClearButtonVisible), visibleInMcLeodTailor: true, description: "Allows for the clearButtonVisible property of the table's filter field to be set." },
                generalLayout: { type: PropType.string, category: "Associated layout", editor: layoutSelector, visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "This is a layout name used for adding, updating, searching, and detailing records.  This can be overridden for specific functions with the other properties in the associated layouts section." },
                headerVisible: { type: PropType.bool, category: "Tools", defaultValue: true, visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "Specifies whether the enter tools header will be shown or hidden." },
                onSelect: { type: PropType.event, category: "Events", addListenerMethod: "addSelectionListener", removeListenerMethod: "removeSelectionListener", eventSignature: "OnSelect(event: TableSelectionEvent)" },
                beforeRowSave: { type: PropType.event, category: "Events", addListenerMethod: "addRowBeforeSaveListener", removeListenerMethod: "removeRowBeforeSaveListener", eventSignature: "BeforeRowSave(event: TableRowBeforeSaveEvent)", description: "This event will be fired prior to saving a table row to the database." },
                onContentsChanged: { type: PropType.event, category: "Events", addListenerMethod: "addContentsChangedListener", removeListenerMethod: "removeContentsChangedListener", eventSignature: "OnContentsChanged(event: TableContentsChangedEvent)", description: "This event will be fired when a table row is added, updated, or deleted." },
                onTableRowMove: { type: PropType.event, category: "Events", addListenerMethod: "addTableRowMoveListener", removeListenerMethod: "removeTableRowMoveListener", eventSignature: "TableRowMove(event: TableRowMoveEvent)", description: "This event will be fired when a table row is moved." },
                onRowCreate: { type: PropType.event, category: "Events", addListenerMethod: "addRowCreateListener", removeListenerMethod: "removeRowCreateListener", eventSignature: "OnRowCreate(event: TableRowCreationEvent)", description: "This event will be fired when a table row is created." },
                onRowDisplay: { type: PropType.event, category: "Events", addListenerMethod: "addRowDisplayListener", removeListenerMethod: "removeRowDisplayListener", eventSignature: "OnRowDisplay(event: TableRowDisplayEvent)", description: "This event will be fired when a table row is displayed." },
                onRowExpand: { type: PropType.event, category: "Events", addListenerMethod: "addRowExpandListener", removeListenerMethod: "removeRowExpandListener", eventSignature: "OnRowExpand(event: TableRowExpansionEvent)", description: "This event will be fired when a table row is expanded." },
                persistChangesImmediately: { type: PropType.bool, category: "Data", defaultValue: false, description: "Specifies whether table changes will be saved after each change or on the save button or parent datasource." },
                preRenderRowCount: { type: PropType.number, visibleInMcLeodTailor: true },
                rowAlign: { type: PropType.string, defaultValue: Alignment.TOP, visibleInMcLeodTailor: true },
                rowBorderBottomColor: { type: PropType.string, visibleInMcLeodTailor: true },
                rowBorderBottomWidth: { type: PropType.number, visibleInMcLeodTailor: true },
                rowBorderTopColor: { type: PropType.string, visibleInMcLeodTailor: true },
                rowBorderTopWidth: { type: PropType.number, visibleInMcLeodTailor: true },
                rowModeControlType: { type: PropType.string, defaultValue: RowModeControlType.AUTO, items: [...Object.values(RowModeControlType)], visibleInMcLeodTailor: true, description: "Specifies if a table row in add or edit mode needs to present save/cancel buttons." },
                rowEditResolveMode: { type: PropType.string, category: "Data", defaultValue: TableRowEditResolveMode.DIRECT_FROM_EDITOR, dropdownProps: { items: [...Object.values(TableRowEditResolveMode)] }, visibleInMcLeodTailor: true, description: "This specifies how row edits are resolved back to the row that originated the edit.\nNONE: When a row is edited and saved, changes to the row are not reflected in the table.\nDIRECT_FROM_EDITOR: The row that is edited is placed directly in the Table.  This can be used when the metadata of the row in the editor matches that of the table.\nKEY_LOOKUP: After a row is edited, its primary key will be looked up from this Table's DataSource's model.\nCUSTOM: Table.doOnResolveRowEdit() will be called so that custom logic can be applied to determine how the row edit will be applied to the Table." },
                rowSpacing: { type: PropType.number, defaultValue: 4, visibleInMcLeodTailor: true },
                searchLayout: { type: PropType.string, category: "Associated layout", editor: layoutSelector, visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "Layout name used in the table when searching records.  If left blank, the generalLayout will be used." },
                selectionMode: { type: PropType.string, defaultValue: SelectionMode.NONE, items: [...Object.values(SelectionMode)], visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "Specifies whether the user is allowed to select one or more rows in the Table." },
                sequenceField: { type: PropType.string, category: "Data", visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "Specifies the field in each row's data that tracks the sequence of the records." },
                virtualized: { type: PropType.bool, defaultValue: true, visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "Specifies whether this Table will defer fully populating its DOM elements until the user has scrolled to make them visible." },
            }
            ComponentPropDefinitionUtil.populateComponentPropNames(tablePropDefs);
        }
        return tablePropDefs;
    }
}
