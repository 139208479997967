import {
    ClickEvent, Component, DataSource, DataSourceMode, EditRowDecorator, Layout, TableRow, TableRowMode
} from "@mcleod/components";
import { ModelRow } from "@mcleod/core";
import { getDispatchControlValue } from "@mcleod/general/src/models/ModelDispatchControl";
import { QuoteStop } from "@mcleod/powerbroker/src/QuoteStop";
import { OrderStop } from "./OrderStop";
import { RecOrderStop } from "./RecOrderStop";
import { StopShowAs } from "./StopShowAs";
import { StopShowasQuickInfo } from "./StopShowasQuickInfo";
import { AutogenLayoutStopShowAsButton } from "./autogen/AutogenLayoutStopShowAsButton";
import { RowStop } from "./models/ModelStop";

enum ShowAsOrderType {
    QUOTE = "lme/powerbroker/quote-stop",
    ORDER = "lme/dispatch/stop",
    REC_ORDER = "lme/dispatch/stop-rec-order"
}

type StopLayout = QuoteStop | OrderStop | RecOrderStop;

export class StopShowAsButton extends AutogenLayoutStopShowAsButton {
    private _orderSource: DataSource;
    private _stopRow: RowStop;

    public get orderSource(): DataSource {
        return this._orderSource;
    }

    public set orderSource(value: DataSource) {
        this._orderSource = value;
    }

    public get stopRow(): RowStop {
        return this._stopRow;
    }

    public set stopRow(value: RowStop) {
        this._stopRow = value;
        if (value != null) {
            if (this.showAsInfoIsSet()) this.imageShowAs.color = "primary";
        }
    }

    onLoad() {
        this.imageShowAs.visible = getDispatchControlValue("showas_location") === 'Y'
        this.imageShowAs.tooltipCallback = this.getShowasToolTip();
    }

    getShowasToolTip() {
        return (baseTooltip: Component): Component => {
            const stopRow = TableRow.getContainingTableRow(this.imageShowAs).data;
            if (this.showAsInfoIsSet()) {
                const layout = Layout.getLayout("lme/dispatch/StopShowasQuickInfo") as StopShowasQuickInfo;
                layout.onLoad = () => {
                    if (stopRow.get("showas_city_id") == null) {
                        layout.citystateShowAs.visible = false;
                    }
                    layout.mainDataSource.setRowsAndMode(DataSourceMode.NONE, [stopRow]);
                    layout.mainDataSource.displayDataInBoundComponents();
                }
                return this.imageShowAs.showTooltip(layout, null, { themeKey: "quickInfo", color: null, fillRow: true });
            }
            else {
                return this.imageShowAs.showTooltip(baseTooltip, null, {});
            }
        };
    }

    /** This is an event handler for the onClick event of buttonShowasSlideout.  */
    imageShowAsOnClick(event: ClickEvent) {
        const tableRow = TableRow.getContainingTableRow(event.target as Component);
        if (tableRow?.mode === TableRowMode.ADD || tableRow?.mode === TableRowMode.UPDATE) {
            const changesValid = tableRow.saveChanges();
            if (changesValid !== true) return;
        }
        if (tableRow != null) {
            const layout: StopShowAs = Layout.getLayout("lme/dispatch/StopShowAs") as StopShowAs;
            layout.customerId = this.orderSource.activeRow.get("customer_id");
            const stopData = tableRow.data.createBasicCopy();
            const erd = new EditRowDecorator({
                title: `Stop Show As Details`,
                layout: layout,
                data: stopData,
                width: "65%",
                layoutLoadListeners: () => {
                    layout.tabStop.removeAll(); // remove the devInfo label
                    // inject the correct stop layout based on the table row's model path and initialize it
                    let stopLayout: StopLayout;
                    switch (tableRow.data._modelPath) {
                        case ShowAsOrderType.ORDER:
                            stopLayout = Layout.getLayout("lme/dispatch/OrderStop") as OrderStop;
                            break;
                        case ShowAsOrderType.REC_ORDER:
                            stopLayout = Layout.getLayout("lme/dispatch/RecOrderStop") as RecOrderStop;
                            break;
                        case ShowAsOrderType.QUOTE:
                            stopLayout = Layout.getLayout("lme/powerbroker/QuoteStop") as QuoteStop;
                            break;
                    }
                    layout.tabStop.add(stopLayout)
                    layout.tabShowAs.forEveryChildComponent(comp => comp.displayData(stopData, null, 0))
                    stopLayout.addLayoutLoadListener(() => {
                        stopLayout.replaceMainDatasource(this.mainDataSource);
                        //Because there is a nested layout in the layout, the addLayout() in ERD does not set data
                        stopLayout.mainDataSource.setRowsAndMode(DataSourceMode.NONE, [stopData]);
                        stopLayout.initialize(true, tableRow, this.orderSource);
                        stopLayout.displayData(stopData, null, 0);
                        stopLayout.layoutStopShowAsButton.visible = false;
                    })
                }, fillVerticalSpace: true, overlayProps: { closeOnClickOff: false, greyedBackground: true }, onSave: (updatedData: ModelRow | any) => {
                    layout.validateSimple();
                    tableRow.saveChangesFromEdit(updatedData);
                }, onClose: (cancelled: boolean) => {
                    this.showAsInfoIsSet() ? this.imageShowAs.color = "primary" : this.imageShowAs.color = "default";
                    tableRow.onSlideoutClose(erd, cancelled, false);
                }
            });
        }
    }

    showAsInfoIsSet(): boolean {
        return this.stopRow.get("showas_city_id") != null ||
            this.stopRow.get("showas_city_name") != null ||
            this.stopRow.get("showas_address") != null ||
            this.stopRow.get("showas_address2") != null ||
            this.stopRow.get("showas_location_id") != null ||
            this.stopRow.get("showas_location_name") != null ||
            this.stopRow.get("showas_state") != null ||
            this.stopRow.get("showas_zip_code") != null;
    }
}
