import { Checkbox, DataSource, HorizontalSpacer, Label, Layout, NumberEditor, Panel } from "@mcleod/components";

export abstract class AutogenLayoutDuplicateOrderPrompt extends Layout {
    checkboxHandlingReq: Checkbox;
    checkboxIncludeOtherCharges: Checkbox;
    checkboxIncludeRate: Checkbox;
    checkboxPerItem: Checkbox;
    horizontalspacer1: HorizontalSpacer;
    horizontalspacer3: HorizontalSpacer;
    horizontalspacer4: HorizontalSpacer;
    labelNumberOfOrdersToCreate: Label;
    labelQuestion: Label;
    numbereditor1: NumberEditor;
    panelBrLTLOptions: Panel;
    panelOrderOptions: Panel;
    sourceCopyControl: DataSource;
}
