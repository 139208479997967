/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelOrderStops.ts                    *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/datafusion/order-stops";

export class AutogenModelOrderStops<RowType extends ModelRow> extends Endpoint<RowType, RowOrderStopsFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowOrderStops extends ModelRow<RowOrderStopsFields> {
    constructor(values?: Partial<RowOrderStopsFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowOrderStopsFields {
    actual_arrival: Date;
    actual_departure: Date;
    address: string;
    address2: string;
    appointment_status_id: string;
    appt_contact_name: string;
    appt_required: string;
    cases: number;
    city_id: number;
    city_name: string;
    company_id: string;
    confirmed: string;
    contact_email: string;
    contact_name: string;
    dist_from_prev: number;
    dist_from_prev_um: string;
    driver_load_unload: string;
    edi_code: string;
    edi_loc_code: string;
    eta: Date;
    eta_distance: number;
    eta_late: string;
    eta_oor: string;
    google_place_id: string;
    grouped_id: number;
    hub: number;
    ic_stop_id: string;
    id: string;
    inbound_status: string;
    late_eta_colorcode: string;
    late_route_status: string;
    latitude: number;
    location_id: string;
    location_name: string;
    longitude: number;
    manifest_fgp_uid: number;
    manifest_reg_uid: number;
    move_dist_from_preassign: number;
    move_dist_from_preassign_um: string;
    move_dist_from_previous: number;
    move_dist_from_previous_um: string;
    movement_id: string;
    movement_sequence: number;
    operational_status: string;
    order_id: string;
    order_sequence: number;
    orig_sched_early: Date;
    orig_sched_late: Date;
    pallets_dropped: number;
    pallets_picked_up: number;
    payment_code_reviewed: string;
    phone: string;
    planned_arrival_time: Date;
    ponum: string;
    prior_uncleared_stops: string;
    projected_arrival: Date;
    pu_override_arrive_early: Date;
    pu_override_arrive_late: Date;
    rate_dist_from_previous: number;
    rate_dist_from_previous_um: string;
    refno: string;
    requested_service: string;
    sched_arrive_early: Date;
    sched_arrive_late: Date;
    seal: string;
    service_date: Date;
    showas_address: string;
    showas_address2: string;
    showas_city_id: number;
    showas_city_name: string;
    showas_location_id: string;
    showas_location_name: string;
    showas_state: string;
    showas_zip_code: string;
    signed_for_name: string;
    state: string;
    status: string;
    stop_type: string;
    trimble_place_id: string;
    txl_uid: number;
    volume: number;
    volume_um: string;
    weight: number;
    weight_um: string;
    zip_code: string;
    zone_id: string;
}

