import { Textbox } from "@mcleod/components";
import { Currency, CurrencyUtil } from "@mcleod/core";
import { AutogenLayoutARInquiry } from "./autogen/AutogenLayoutARInquiry";

export class ARInquiry extends AutogenLayoutARInquiry {
    sourceArinquiryOnDisplay(event) {
        this.setSummaryText(event, this.textUnposted, this.sourceArinquiry?.summaryData?.get("unposted"));
        this.setSummaryText(event, this.textMiscBills, this.sourceArinquiry?.summaryData?.get("misc_bills"));
        this.setSummaryText(event, this.textCreditMemos, this.sourceArinquiry?.summaryData?.get("credit_memos"));
        this.setSummaryText(event, this.textCashReceipts, this.sourceArinquiry?.summaryData?.get("cash_receipts"));
        this.setSummaryText(event, this.textNetTotal, this.sourceArinquiry?.summaryData?.get("net_total"));
    }

    setSummaryText(event, textbox: Textbox, data: Currency) {
        if (textbox == null)
            return;
        textbox.visible = event.allData?.length > 0;
        if (event.allData != null && data)
            textbox.text = CurrencyUtil.formatCurrency(data);
    }
}
