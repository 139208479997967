import { DataSource, Layout, Panel, Switch, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutBillOfLading extends Layout {
    panelContactInfo: Panel;
    panelCustomerShipper: Panel;
    panelInputs: Panel;
    panelPdf: Panel;
    sourceSendBillOfLading: DataSource;
    switchLookupType: Switch;
    textboxContactName: Textbox;
    textboxCustomer: Textbox;
    textboxEmail: Textbox;
    textboxShipper: Textbox;
    textboxSubject: Textbox;
}
