import { Button, CityState, DataSource, Label, Layout, Panel, Textbox } from "@mcleod/components";
import { CarrierPrequalResult } from "@mcleod/powerbroker/src/CarrierPrequalResult";
import { MovementCarrierPay } from "@mcleod/powerbroker/src/MovementCarrierPay";
import { QuickSellDetails } from "../QuickSellDetails";

export abstract class AutogenLayoutCarrierOfferSlideout extends Layout {
    buttonAssignCarrier: Button;
    csEmptyCity: CityState;
    layoutCarrierPrequalResult: CarrierPrequalResult;
    layoutQuickSellDetails: QuickSellDetails;
    lblCarrierContact: Label;
    lblCarrierInfo: Label;
    lblGenInfo: Label;
    lblRates: Label;
    loCarrierPay: MovementCarrierPay;
    panel4: Panel;
    panel5: Panel;
    panel6: Panel;
    panel7: Panel;
    panelAssignCarrier: Panel;
    panelCarrier: Panel;
    panelContact: Panel;
    panelGeneral: Panel;
    sourceCarrierOffer: DataSource;
    tbCarrierName: Textbox;
    tbComments: Textbox;
    tbContactName: Textbox;
    tbContactPhone: Textbox;
    tbCounterMargin: Textbox;
    tbCounterMarginPrcnt: Textbox;
    tbDispatchMargin: Textbox;
    tbDispatchMarginPrcnt: Textbox;
    tbDotNumber: Textbox;
    tbEmail: Textbox;
    tbMcNumber: Textbox;
    tbMilesToPickup: Textbox;
    tbOfferDate: Textbox;
    tbPayeeId: Textbox;
    tbStatus: Textbox;
    tbTrailerType: Textbox;
    textboxAmount: Textbox;
    textboxCounterOffer: Textbox;
}
