/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelEdilocation.ts                   *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/datafusion/edilocation";

export class AutogenModelEdilocation<RowType extends ModelRow> extends Endpoint<RowType, RowEdilocationFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowEdilocation extends ModelRow<RowEdilocationFields> {
    constructor(values?: Partial<RowEdilocationFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowEdilocationFields {
    company_id: string;
    customer_id: string;
    edi_location: string;
    edi_location_qual: string;
    id: string;
    location_id: string;
    location_type: string;
    partner_id: string;
    version: string;
}

