/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelCarriers.ts                      *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/powerbroker/carriers";

export class AutogenModelCarriers<RowType extends ModelRow> extends Endpoint<RowType, RowCarriersFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowCarriers extends ModelRow<RowCarriersFields> {
    constructor(values?: Partial<RowCarriersFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowCarriersFields {
    "contact.comments": string;
    "contact.company_id": string;
    "contact.contact_name": string;
    "contact.customer_sequence": number;
    "contact.detention_contact": string;
    "contact.ect_uid": number;
    "contact.email": string;
    "contact.fax": string;
    "contact.id": string;
    "contact.is_active": string;
    "contact.is_sms_opted_in": string;
    "contact.mobile_phone": string;
    "contact.name": string;
    "contact.opt_out_date": Date;
    "contact.parent_row_id": string;
    "contact.parent_row_type": string;
    "contact.payable_contact": string;
    "contact.personal_ref": string;
    "contact.phone": string;
    "contact.rapid_alert_notify": string;
    "contact.rec_interval": number;
    "contact.rec_next_date": Date;
    "contact.rec_ops_user_id": string;
    "contact.rec_salesperson_id": string;
    "contact.sequence": number;
    "contact.terms_contact": string;
    "contact.title": string;
    "contact.type_of": string;
    "contact.web_access": string;
    "drs_payee.adp_co_code": string;
    "drs_payee.adp_e_no": string;
    "drs_payee.agreement_dt": Date;
    "drs_payee.auth_reinstate_date": Date;
    "drs_payee.authority_reg_date": Date;
    "drs_payee.authority_reg_status": string;
    "drs_payee.authority_reg_type": string;
    "drs_payee.auto_create_dispatch_tractr": string;
    "drs_payee.bank_acct_type_name": string;
    "drs_payee.bank_address_1": string;
    "drs_payee.bank_address_2": string;
    "drs_payee.bank_city_name": string;
    "drs_payee.bank_state": string;
    "drs_payee.bank_zip_code": string;
    "drs_payee.basic_update": Date;
    "drs_payee.begin_vacation_dt": Date;
    "drs_payee.bond_filed": string;
    "drs_payee.bond_required": string;
    "drs_payee.box_for_1099_total": string;
    "drs_payee.broker_auth_grant_date": Date;
    "drs_payee.broker_auth_status": string;
    "drs_payee.c_tpat_flag": string;
    "drs_payee.c_tpat_svi": string;
    "drs_payee.calc_agent_pay": string;
    "drs_payee.carb_flag": string;
    "drs_payee.cargo_agri_farm": string;
    "drs_payee.cargo_beverages": string;
    "drs_payee.cargo_building_mat": string;
    "drs_payee.cargo_cancel_date": Date;
    "drs_payee.cargo_chemicals": string;
    "drs_payee.cargo_coal_coke": string;
    "drs_payee.cargo_commod_dry": string;
    "drs_payee.cargo_construction": string;
    "drs_payee.cargo_drive_tow": string;
    "drs_payee.cargo_email": string;
    "drs_payee.cargo_garbage": string;
    "drs_payee.cargo_gen_freight": string;
    "drs_payee.cargo_grain_feed": string;
    "drs_payee.cargo_household": string;
    "drs_payee.cargo_ins_amount": number;
    "drs_payee.cargo_ins_amount_c": string;
    "drs_payee.cargo_ins_amount_d": Date;
    "drs_payee.cargo_ins_amount_n": number;
    "drs_payee.cargo_ins_amount_r": number;
    "drs_payee.cargo_ins_on_file": string;
    "drs_payee.cargo_ins_renew_dt": Date;
    "drs_payee.cargo_ins_required": string;
    "drs_payee.cargo_insur_fax": string;
    "drs_payee.cargo_insur_phone": string;
    "drs_payee.cargo_insurer": string;
    "drs_payee.cargo_intermodal": string;
    "drs_payee.cargo_liquids_gas": string;
    "drs_payee.cargo_livestock": string;
    "drs_payee.cargo_logs_lumber": string;
    "drs_payee.cargo_machinery": string;
    "drs_payee.cargo_meat": string;
    "drs_payee.cargo_metal": string;
    "drs_payee.cargo_mobile_homes": string;
    "drs_payee.cargo_oil_equip": string;
    "drs_payee.cargo_other": string;
    "drs_payee.cargo_other_descr": string;
    "drs_payee.cargo_paper_prod": string;
    "drs_payee.cargo_passengers": string;
    "drs_payee.cargo_policy": string;
    "drs_payee.cargo_produce": string;
    "drs_payee.cargo_reefer_breakdown": string;
    "drs_payee.cargo_reefer_food": string;
    "drs_payee.cargo_related_oa": string;
    "drs_payee.cargo_related_ot": string;
    "drs_payee.cargo_related_pct": number;
    "drs_payee.cargo_related_sv": string;
    "drs_payee.cargo_us_mail": string;
    "drs_payee.cargo_utilities": string;
    "drs_payee.cargo_vehicles": string;
    "drs_payee.cargo_water_well": string;
    "drs_payee.carrier": string;
    "drs_payee.carrier_cont_type": string;
    "drs_payee.carrier_contract": string;
    "drs_payee.carrier_flat_count": number;
    "drs_payee.carrier_has_flats": string;
    "drs_payee.carrier_has_refers": string;
    "drs_payee.carrier_has_vans": string;
    "drs_payee.carrier_mgr": string;
    "drs_payee.carrier_ranking": number;
    "drs_payee.carrier_refe_count": number;
    "drs_payee.carrier_status_id": string;
    "drs_payee.carrier_van_count": number;
    "drs_payee.category": string;
    "drs_payee.child_contract": number;
    "drs_payee.com_auth_reinstate_date": Date;
    "drs_payee.common_auth_grant_date": Date;
    "drs_payee.common_auth_status": string;
    "drs_payee.company_id": string;
    "drs_payee.con_substance_oa": string;
    "drs_payee.con_substance_ot": string;
    "drs_payee.con_substance_sv": string;
    "drs_payee.cont_auth_reinstate_date": Date;
    "drs_payee.cont_substance_pct": number;
    "drs_payee.contact": string;
    "drs_payee.contract_auth_grant_date": Date;
    "drs_payee.contract_auth_stat": string;
    "drs_payee.contract_authority": string;
    "drs_payee.contract_date": Date;
    "drs_payee.cr_union_wh": number;
    "drs_payee.cr_union_wh_c": string;
    "drs_payee.cr_union_wh_d": Date;
    "drs_payee.cr_union_wh_n": number;
    "drs_payee.cr_union_wh_r": number;
    "drs_payee.crashes_fatal": number;
    "drs_payee.crashes_injury": number;
    "drs_payee.crashes_total": number;
    "drs_payee.crashes_tow": number;
    "drs_payee.credit_approved": string;
    "drs_payee.credit_check_date": Date;
    "drs_payee.cxna_id": number;
    "drs_payee.date_entered": Date;
    "drs_payee.def_load_to_radius": number;
    "drs_payee.def_loc_radius": number;
    "drs_payee.default_bank_account": string;
    "drs_payee.department": string;
    "drs_payee.detention_max_pay_amt": number;
    "drs_payee.detention_max_pay_amt_c": string;
    "drs_payee.detention_max_pay_amt_d": Date;
    "drs_payee.detention_max_pay_amt_n": number;
    "drs_payee.detention_max_pay_amt_r": number;
    "drs_payee.detention_pay_rate": number;
    "drs_payee.detention_tm_earning_code": string;
    "drs_payee.detention_tm_max_pay_amt": number;
    "drs_payee.detention_tm_max_pay_amt_c": string;
    "drs_payee.detention_tm_max_pay_amt_d": Date;
    "drs_payee.detention_tm_max_pay_amt_n": number;
    "drs_payee.detention_tm_max_pay_amt_r": number;
    "drs_payee.detention_tm_pay_rate": number;
    "drs_payee.disable_automatic_tracking": string;
    "drs_payee.disable_electronic_offers": string;
    "drs_payee.distance_method": string;
    "drs_payee.distance_profile": string;
    "drs_payee.distance_source": string;
    "drs_payee.distance_type": string;
    "drs_payee.division_id": string;
    "drs_payee.dob_processed": string;
    "drs_payee.dot_number": string;
    "drs_payee.dray_rate": number;
    "drs_payee.driver_fitness_oa": string;
    "drs_payee.driver_fitness_ot": string;
    "drs_payee.driver_fitness_pct": number;
    "drs_payee.driver_fitness_sv": string;
    "drs_payee.driver_inspection": number;
    "drs_payee.driver_rating": string;
    "drs_payee.drivers": number;
    "drs_payee.drv_oos_inspection": number;
    "drs_payee.earning_code": string;
    "drs_payee.eforms_carrier_onboarding": string;
    "drs_payee.eforms_contract_alert": number;
    "drs_payee.eforms_contract_expire": number;
    "drs_payee.eforms_docrequest_alert": number;
    "drs_payee.eforms_docrequest_expire": number;
    "drs_payee.eforms_expirations": string;
    "drs_payee.eforms_profile_alert": number;
    "drs_payee.eforms_profile_expire": number;
    "drs_payee.eforms_qual_profile": string;
    "drs_payee.efs_account": string;
    "drs_payee.email": string;
    "drs_payee.empty_pay_rate": number;
    "drs_payee.equipment_type_id": string;
    "drs_payee.exclude_from_perf_calc": string;
    "drs_payee.expense_glid": string;
    "drs_payee.experience_date": Date;
    "drs_payee.factor_name": string;
    "drs_payee.factoring_company_id": string;
    "drs_payee.fatigue_drive_pct": number;
    "drs_payee.fatigued_drive_oa": string;
    "drs_payee.fatigued_drive_ot": string;
    "drs_payee.fatigued_drive_sv": string;
    "drs_payee.fax": string;
    "drs_payee.fuel_reimburse_id": string;
    "drs_payee.fuel_surcharge_pay": string;
    "drs_payee.futa": number;
    "drs_payee.futa_c": string;
    "drs_payee.futa_d": Date;
    "drs_payee.futa_n": number;
    "drs_payee.futa_r": number;
    "drs_payee.gross_non_taxed": number;
    "drs_payee.gross_non_taxed_c": string;
    "drs_payee.gross_non_taxed_d": Date;
    "drs_payee.gross_non_taxed_n": number;
    "drs_payee.gross_non_taxed_r": number;
    "drs_payee.haz_oos_inspection": number;
    "drs_payee.hazmat_cert_number": string;
    "drs_payee.hazmat_expire_date": Date;
    "drs_payee.hazmat_inspection": number;
    "drs_payee.highway_id_number": string;
    "drs_payee.highway_rule_assessment": string;
    "drs_payee.highway_status": string;
    "drs_payee.holiday_hours_pd": number;
    "drs_payee.hourly_pay_allocation": string;
    "drs_payee.household_goods": string;
    "drs_payee.ic_company_id": string;
    "drs_payee.icc_number": string;
    "drs_payee.id": string;
    "drs_payee.ins_auto_update": string;
    "drs_payee.ins_expire_notify": string;
    "drs_payee.ins_last_upd_dt": Date;
    "drs_payee.ins_on_file": string;
    "drs_payee.insurance_oa": string;
    "drs_payee.insurance_sv": string;
    "drs_payee.intrastate_code": string;
    "drs_payee.intrastate_only": string;
    "drs_payee.intrastate_state": string;
    "drs_payee.is_air": string;
    "drs_payee.is_any_auto": string;
    "drs_payee.is_broker": string;
    "drs_payee.is_certified": string;
    "drs_payee.is_expedited": string;
    "drs_payee.is_factored": string;
    "drs_payee.is_hired_autos": string;
    "drs_payee.is_intercompany": string;
    "drs_payee.is_intermodal": string;
    "drs_payee.is_ltl": string;
    "drs_payee.is_non_owned_autos": string;
    "drs_payee.is_ocean": string;
    "drs_payee.is_owned_autos": string;
    "drs_payee.is_partial": string;
    "drs_payee.is_rail": string;
    "drs_payee.is_scheduled_autos": string;
    "drs_payee.is_truckload": string;
    "drs_payee.its_cpr_reports": string;
    "drs_payee.its_suspension_reason": string;
    "drs_payee.job_class_code_id": string;
    "drs_payee.last_contacted": Date;
    "drs_payee.last_contacted_by": string;
    "drs_payee.last_upd_rate": Date;
    "drs_payee.leased_tractors": number;
    "drs_payee.leased_trailers": number;
    "drs_payee.liab_cancel_date": Date;
    "drs_payee.liab_expire_date": Date;
    "drs_payee.liab_insur_fax": string;
    "drs_payee.liab_insur_phone": string;
    "drs_payee.liability_amount": number;
    "drs_payee.liability_amount_c": string;
    "drs_payee.liability_amount_d": Date;
    "drs_payee.liability_amount_n": number;
    "drs_payee.liability_amount_r": number;
    "drs_payee.liability_email": string;
    "drs_payee.liability_insurer": string;
    "drs_payee.liability_percent": number;
    "drs_payee.liability_policy": string;
    "drs_payee.liability_reqd": number;
    "drs_payee.liability_reqd_c": string;
    "drs_payee.liability_reqd_d": Date;
    "drs_payee.liability_reqd_n": number;
    "drs_payee.liability_reqd_r": number;
    "drs_payee.loaded_pay_rate": number;
    "drs_payee.loadpay_accepted_status": string;
    "drs_payee.loadpay_acct_id": string;
    "drs_payee.loadpay_contact_email": string;
    "drs_payee.loadpay_contact_name": string;
    "drs_payee.loadpay_date": Date;
    "drs_payee.logo": string;
    "drs_payee.logo_name": string;
    "drs_payee.macropoint_provider_code": string;
    "drs_payee.mark_plan_id": string;
    "drs_payee.mc_payroll_pin": string;
    "drs_payee.mcs_150_date": Date;
    "drs_payee.mcs_150_mileage": number;
    "drs_payee.mcs_150_mileage_yr": number;
    "drs_payee.method": string;
    "drs_payee.mileage_code": string;
    "drs_payee.minimum_pay": number;
    "drs_payee.minimum_pay_c": string;
    "drs_payee.minimum_pay_d": Date;
    "drs_payee.minimum_pay_n": number;
    "drs_payee.minimum_pay_r": number;
    "drs_payee.monitor_ins": string;
    "drs_payee.monitor_ins_basic": string;
    "drs_payee.mpw_carrier_contact_method": string;
    "drs_payee.mpw_carrier_high": number;
    "drs_payee.mpw_carrier_low": number;
    "drs_payee.mpw_exclude": string;
    "drs_payee.mpw_last_contact": Date;
    "drs_payee.mpw_num_attempts": number;
    "drs_payee.mpw_num_orders": number;
    "drs_payee.night_phone_number": string;
    "drs_payee.no_dispatch": string;
    "drs_payee.num_days_to_pay": number;
    "drs_payee.onboarded": string;
    "drs_payee.onboarding_status": string;
    "drs_payee.operations_class": string;
    "drs_payee.ops_class_descr": string;
    "drs_payee.order_entry_url": string;
    "drs_payee.out_of_service": string;
    "drs_payee.override_qual_date": Date;
    "drs_payee.override_qual_enf": string;
    "drs_payee.override_reason": string;
    "drs_payee.overtime_cutoff": number;
    "drs_payee.overtime_hours_pd": number;
    "drs_payee.overtime_rate": number;
    "drs_payee.owned_tractors": number;
    "drs_payee.owned_trailers": number;
    "drs_payee.p44_cap_provider_code": string;
    "drs_payee.p44_profile_id": string;
    "drs_payee.parent_contract": number;
    "drs_payee.partner_tar_uid": number;
    "drs_payee.partner_tariff_id": string;
    "drs_payee.passenger": string;
    "drs_payee.pay_advances": string;
    "drs_payee.pay_factoring_company": string;
    "drs_payee.pay_method": string;
    "drs_payee.pay_option": string;
    "drs_payee.pay_rate_group": string;
    "drs_payee.payment_frequency": string;
    "drs_payee.payroll_run": string;
    "drs_payee.pend_broker_auth": string;
    "drs_payee.pend_common_auth": string;
    "drs_payee.pend_contract_auth": string;
    "drs_payee.pend_rev_broker": string;
    "drs_payee.pend_rev_common": string;
    "drs_payee.pend_rev_contract": string;
    "drs_payee.perdiem_rate": number;
    "drs_payee.perform_rating": string;
    "drs_payee.perform_rating_dt": Date;
    "drs_payee.period_loads": number;
    "drs_payee.period_miles": number;
    "drs_payee.period_revenue": number;
    "drs_payee.period_revenue_c": string;
    "drs_payee.period_revenue_d": Date;
    "drs_payee.period_revenue_n": number;
    "drs_payee.period_revenue_r": number;
    "drs_payee.power_units": number;
    "drs_payee.print_1099": string;
    "drs_payee.print_driver_orders_dtl": string;
    "drs_payee.process_status": string;
    "drs_payee.property": string;
    "drs_payee.pu_pay_amount": number;
    "drs_payee.pu_pay_amount_c": string;
    "drs_payee.pu_pay_amount_d": Date;
    "drs_payee.pu_pay_amount_n": number;
    "drs_payee.pu_pay_amount_r": number;
    "drs_payee.puc_number": string;
    "drs_payee.quickpay_amount": number;
    "drs_payee.quickpay_basis": string;
    "drs_payee.quickpay_contract": Date;
    "drs_payee.quickpay_daystopay": number;
    "drs_payee.quickpay_deduct_id": string;
    "drs_payee.quickpay_enabled": string;
    "drs_payee.quickpay_min_chg": number;
    "drs_payee.quickpay_min_chg_c": string;
    "drs_payee.quickpay_min_chg_d": Date;
    "drs_payee.quickpay_min_chg_n": number;
    "drs_payee.quickpay_min_chg_r": number;
    "drs_payee.recur_tractor_last_created": Date;
    "drs_payee.regular_hours_pd": number;
    "drs_payee.regular_rate": number;
    "drs_payee.reimburse_fuel": string;
    "drs_payee.req_dispatch_over": string;
    "drs_payee.risk_assessment": string;
    "drs_payee.rmis_carrier_id": string;
    "drs_payee.safety_fitness": string;
    "drs_payee.safety_fitness_dt": Date;
    "drs_payee.safety_rate_rev_dt": Date;
    "drs_payee.safety_rating": string;
    "drs_payee.safety_rating_dt": Date;
    "drs_payee.safety_reg_date": Date;
    "drs_payee.safety_reg_status": string;
    "drs_payee.safety_review_type": string;
    "drs_payee.salesperson_id": string;
    "drs_payee.scac": string;
    "drs_payee.sea_accident": number;
    "drs_payee.sea_driver": number;
    "drs_payee.sea_safety_mgmt": number;
    "drs_payee.sea_vehicle": number;
    "drs_payee.search_city_id": number;
    "drs_payee.search_city_name": string;
    "drs_payee.search_state": string;
    "drs_payee.search_zip_code": string;
    "drs_payee.send_to": string;
    "drs_payee.sick_earned": number;
    "drs_payee.sick_hours_due": number;
    "drs_payee.sick_hours_pd": number;
    "drs_payee.smartway_certified": string;
    "drs_payee.smartway_score": number;
    "drs_payee.so_pay_amount": number;
    "drs_payee.so_pay_amount_c": string;
    "drs_payee.so_pay_amount_d": Date;
    "drs_payee.so_pay_amount_n": number;
    "drs_payee.so_pay_amount_r": number;
    "drs_payee.start_date": Date;
    "drs_payee.taxable_owed": number;
    "drs_payee.taxable_owed_c": string;
    "drs_payee.taxable_owed_d": Date;
    "drs_payee.taxable_owed_n": number;
    "drs_payee.taxable_owed_r": number;
    "drs_payee.team_empty_rate": number;
    "drs_payee.team_method": string;
    "drs_payee.team_pay_rate": number;
    "drs_payee.term_code": string;
    "drs_payee.term_is_quick_pay": string;
    "drs_payee.terminal_id": string;
    "drs_payee.tiered_stop_id": string;
    "drs_payee.total_inspection": number;
    "drs_payee.trackid_carrier_onboarding": number;
    "drs_payee.trackid_document_request": number;
    "drs_payee.trackid_expiring_insurance": number;
    "drs_payee.trailer_rent": number;
    "drs_payee.trainee_rate": number;
    "drs_payee.trainer_rate": number;
    "drs_payee.type_of": string;
    "drs_payee.unsafe_driving_oa": string;
    "drs_payee.unsafe_driving_ot": string;
    "drs_payee.unsafe_driving_pct": number;
    "drs_payee.unsafe_driving_sv": string;
    "drs_payee.vacation_earned": number;
    "drs_payee.vacation_hours_due": number;
    "drs_payee.vacation_hours_pd": number;
    "drs_payee.vacation_hrs_base": number;
    "drs_payee.vacation_pay_rate": number;
    "drs_payee.veh_oos_inspection": number;
    "drs_payee.vehicle_inspection": number;
    "drs_payee.vehicle_maint_oa": string;
    "drs_payee.vehicle_maint_ot": string;
    "drs_payee.vehicle_maint_pct": number;
    "drs_payee.vehicle_maint_sv": string;
    "drs_payee.via": string;
    "drs_payee.w9_on_file": string;
    "drs_payee.watchdog_report": string;
    "drs_payee.wats_phone_number": string;
    "drs_payee.workmans_comp": number;
    "drs_payee.workmanscomp_date": Date;
    "drs_payee.ytd_401k_catchup": number;
    "drs_payee.ytd_401k_catchup_c": string;
    "drs_payee.ytd_401k_catchup_d": Date;
    "drs_payee.ytd_401k_catchup_n": number;
    "drs_payee.ytd_401k_catchup_r": number;
    "drs_payee.ytd_401k_contrib": number;
    "drs_payee.ytd_401k_contrib_c": string;
    "drs_payee.ytd_401k_contrib_d": Date;
    "drs_payee.ytd_401k_contrib_n": number;
    "drs_payee.ytd_401k_contrib_r": number;
    "drs_payee.ytd_city_gross": number;
    "drs_payee.ytd_city_gross_c": string;
    "drs_payee.ytd_city_gross_d": Date;
    "drs_payee.ytd_city_gross_n": number;
    "drs_payee.ytd_city_gross_r": number;
    "drs_payee.ytd_city_tax_wh": number;
    "drs_payee.ytd_city_tax_wh_c": string;
    "drs_payee.ytd_city_tax_wh_d": Date;
    "drs_payee.ytd_city_tax_wh_n": number;
    "drs_payee.ytd_city_tax_wh_r": number;
    "drs_payee.ytd_county_wages": number;
    "drs_payee.ytd_county_wages_c": string;
    "drs_payee.ytd_county_wages_d": Date;
    "drs_payee.ytd_county_wages_n": number;
    "drs_payee.ytd_county_wages_r": number;
    "drs_payee.ytd_county_wh": number;
    "drs_payee.ytd_county_wh_c": string;
    "drs_payee.ytd_county_wh_d": Date;
    "drs_payee.ytd_county_wh_n": number;
    "drs_payee.ytd_county_wh_r": number;
    "drs_payee.ytd_ett_wages": number;
    "drs_payee.ytd_ett_wages_c": string;
    "drs_payee.ytd_ett_wages_d": Date;
    "drs_payee.ytd_ett_wages_n": number;
    "drs_payee.ytd_ett_wages_r": number;
    "drs_payee.ytd_fed_gross": number;
    "drs_payee.ytd_fed_gross_c": string;
    "drs_payee.ytd_fed_gross_d": Date;
    "drs_payee.ytd_fed_gross_n": number;
    "drs_payee.ytd_fed_gross_r": number;
    "drs_payee.ytd_fed_withheld": number;
    "drs_payee.ytd_fed_withheld_c": string;
    "drs_payee.ytd_fed_withheld_d": Date;
    "drs_payee.ytd_fed_withheld_n": number;
    "drs_payee.ytd_fed_withheld_r": number;
    "drs_payee.ytd_fica_hi_gros": number;
    "drs_payee.ytd_fica_hi_gros_c": string;
    "drs_payee.ytd_fica_hi_gros_d": Date;
    "drs_payee.ytd_fica_hi_gros_n": number;
    "drs_payee.ytd_fica_hi_gros_r": number;
    "drs_payee.ytd_fica_hi_wh": number;
    "drs_payee.ytd_fica_hi_wh_c": string;
    "drs_payee.ytd_fica_hi_wh_d": Date;
    "drs_payee.ytd_fica_hi_wh_n": number;
    "drs_payee.ytd_fica_hi_wh_r": number;
    "drs_payee.ytd_fica_oadi_gr": number;
    "drs_payee.ytd_fica_oadi_gr_c": string;
    "drs_payee.ytd_fica_oadi_gr_d": Date;
    "drs_payee.ytd_fica_oadi_gr_n": number;
    "drs_payee.ytd_fica_oadi_gr_r": number;
    "drs_payee.ytd_fica_oadi_wh": number;
    "drs_payee.ytd_fica_oadi_wh_c": string;
    "drs_payee.ytd_fica_oadi_wh_d": Date;
    "drs_payee.ytd_fica_oadi_wh_n": number;
    "drs_payee.ytd_fica_oadi_wh_r": number;
    "drs_payee.ytd_fui_gross": number;
    "drs_payee.ytd_fui_gross_c": string;
    "drs_payee.ytd_fui_gross_d": Date;
    "drs_payee.ytd_fui_gross_n": number;
    "drs_payee.ytd_fui_gross_r": number;
    "drs_payee.ytd_holiday_hrs": number;
    "drs_payee.ytd_loads": number;
    "drs_payee.ytd_miles": number;
    "drs_payee.ytd_overtime_hrs": number;
    "drs_payee.ytd_puc_gross": number;
    "drs_payee.ytd_puc_gross_c": string;
    "drs_payee.ytd_puc_gross_d": Date;
    "drs_payee.ytd_puc_gross_n": number;
    "drs_payee.ytd_puc_gross_r": number;
    "drs_payee.ytd_puc_withheld": number;
    "drs_payee.ytd_puc_withheld_c": string;
    "drs_payee.ytd_puc_withheld_d": Date;
    "drs_payee.ytd_puc_withheld_n": number;
    "drs_payee.ytd_puc_withheld_r": number;
    "drs_payee.ytd_reg_hrs_paid": number;
    "drs_payee.ytd_revenue": number;
    "drs_payee.ytd_revenue_c": string;
    "drs_payee.ytd_revenue_d": Date;
    "drs_payee.ytd_revenue_n": number;
    "drs_payee.ytd_revenue_r": number;
    "drs_payee.ytd_sdi_gross": number;
    "drs_payee.ytd_sdi_gross_c": string;
    "drs_payee.ytd_sdi_gross_d": Date;
    "drs_payee.ytd_sdi_gross_n": number;
    "drs_payee.ytd_sdi_gross_r": number;
    "drs_payee.ytd_sdi_withheld": number;
    "drs_payee.ytd_sdi_withheld_c": string;
    "drs_payee.ytd_sdi_withheld_d": Date;
    "drs_payee.ytd_sdi_withheld_n": number;
    "drs_payee.ytd_sdi_withheld_r": number;
    "drs_payee.ytd_sick_hrs_paid": number;
    "drs_payee.ytd_state_gross": number;
    "drs_payee.ytd_state_gross_c": string;
    "drs_payee.ytd_state_gross_d": Date;
    "drs_payee.ytd_state_gross_n": number;
    "drs_payee.ytd_state_gross_r": number;
    "drs_payee.ytd_state_wh": number;
    "drs_payee.ytd_state_wh_c": string;
    "drs_payee.ytd_state_wh_d": Date;
    "drs_payee.ytd_state_wh_n": number;
    "drs_payee.ytd_state_wh_r": number;
    "drs_payee.ytd_sui_gross": number;
    "drs_payee.ytd_sui_gross_c": string;
    "drs_payee.ytd_sui_gross_d": Date;
    "drs_payee.ytd_sui_gross_n": number;
    "drs_payee.ytd_sui_gross_r": number;
    "drs_payee.ytd_sui_withheld": number;
    "drs_payee.ytd_sui_withheld_c": string;
    "drs_payee.ytd_sui_withheld_d": Date;
    "drs_payee.ytd_sui_withheld_n": number;
    "drs_payee.ytd_sui_withheld_r": number;
    "drs_payee.ytd_total_gross": number;
    "drs_payee.ytd_total_gross_c": string;
    "drs_payee.ytd_total_gross_d": Date;
    "drs_payee.ytd_total_gross_n": number;
    "drs_payee.ytd_total_gross_r": number;
    "drs_payee.ytd_vacation_hrs": number;
    "payee.ach_batch_class_code": string;
    "payee.add_fed_to_wh": number;
    "payee.add_fed_to_wh_c": string;
    "payee.add_fed_to_wh_d": Date;
    "payee.add_fed_to_wh_n": number;
    "payee.add_fed_to_wh_r": number;
    "payee.addl_st_to_whold": number;
    "payee.addl_st_to_whold_c": string;
    "payee.addl_st_to_whold_d": Date;
    "payee.addl_st_to_whold_n": number;
    "payee.addl_st_to_whold_r": number;
    "payee.address1": string;
    "payee.address2": string;
    "payee.adp_co_code": string;
    "payee.adp_e_no": string;
    "payee.birth_date": Date;
    "payee.check_address": string;
    "payee.check_address2": string;
    "payee.check_city": string;
    "payee.check_city_st_zip": string;
    "payee.check_date": Date;
    "payee.check_name": string;
    "payee.check_number": string;
    "payee.check_st": string;
    "payee.check_stub_comment": string;
    "payee.check_zip": string;
    "payee.city": string;
    "payee.city_tax_code": string;
    "payee.company_id": string;
    "payee.country_id": string;
    "payee.county_tax_code": string;
    "payee.currency": string;
    "payee.dependents": number;
    "payee.dependents_c": string;
    "payee.dependents_d": Date;
    "payee.dependents_n": number;
    "payee.dependents_r": number;
    "payee.disable_payroll_taxes": string;
    "payee.email": string;
    "payee.email_summary": string;
    "payee.enable_carrier_app": string;
    "payee.exempt_from_fed": string;
    "payee.extra_withholdings": number;
    "payee.extra_withholdings_c": string;
    "payee.extra_withholdings_d": Date;
    "payee.extra_withholdings_n": number;
    "payee.extra_withholdings_r": number;
    "payee.fed_amt_to_whold": number;
    "payee.fed_amt_to_whold_c": string;
    "payee.fed_amt_to_whold_d": Date;
    "payee.fed_amt_to_whold_n": number;
    "payee.fed_amt_to_whold_r": number;
    "payee.fed_exemptions": number;
    "payee.fed_marital_status": string;
    "payee.freeze_pay": string;
    "payee.freight_matching_override": string;
    "payee.gp_exclude": string;
    "payee.gp_exported": Date;
    "payee.hire_date": Date;
    "payee.holiday_hours_pd": number;
    "payee.id": string;
    "payee.last_raise_date": Date;
    "payee.legal_name": string;
    "payee.load_booking_eligible": string;
    "payee.loadpay_contact_phone": string;
    "payee.multiple_jobs": string;
    "payee.name": string;
    "payee.non_office_emp": string;
    "payee.office_employee": string;
    "payee.other_deductions": number;
    "payee.other_deductions_c": string;
    "payee.other_deductions_d": Date;
    "payee.other_deductions_n": number;
    "payee.other_deductions_r": number;
    "payee.other_income": number;
    "payee.other_income_c": string;
    "payee.other_income_d": Date;
    "payee.other_income_n": number;
    "payee.other_income_r": number;
    "payee.overtime_hours_pd": number;
    "payee.payment_method": string;
    "payee.phone_number": string;
    "payee.posted_check_date": Date;
    "payee.prev_code": string;
    "payee.primary_agency": string;
    "payee.regular_hours_pd": number;
    "payee.remarks": string;
    "payee.settlement_status": string;
    "payee.sick_hours_pd": number;
    "payee.social_security_no": string;
    "payee.st_amt_to_whold": number;
    "payee.st_amt_to_whold_c": string;
    "payee.st_amt_to_whold_d": Date;
    "payee.st_amt_to_whold_n": number;
    "payee.st_amt_to_whold_r": number;
    "payee.st_marital_status": string;
    "payee.state": string;
    "payee.state_exemptions": number;
    "payee.state_tax_code": string;
    "payee.status": string;
    "payee.sui_tax_code": string;
    "payee.tax_table_city_aux": number;
    "payee.tax_table_city_misc": number;
    "payee.tax_table_county_aux": number;
    "payee.tax_table_county_misc": number;
    "payee.tax_table_fit_aux": number;
    "payee.tax_table_fit_misc": number;
    "payee.tax_table_st_aux": number;
    "payee.tax_table_st_misc": number;
    "payee.tax_table_sui_aux": number;
    "payee.tax_table_sui_misc": number;
    "payee.termination_date": Date;
    "payee.vacation_hours_pd": number;
    "payee.vm_part_update": string;
    "payee.w4_status": string;
    "payee.wf_queue": number;
    "payee.ytd_holiday_hrs": number;
    "payee.ytd_overtime_hrs": number;
    "payee.ytd_reg_hrs_paid": number;
    "payee.ytd_sick_hrs_paid": number;
    "payee.ytd_vacation_hrs": number;
    "payee.zip_code": string;
    icc_name_search: string;
}

