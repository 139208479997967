/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelWirecodesForUser.ts              *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/powerbroker/wirecodes-for-user";

export class AutogenModelPbwLoadboardUsers<RowType extends ModelRow> extends Endpoint<RowType, RowPbwLoadboardUsers> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowPbwLoadboardUsers extends ModelRow<RowPbwLoadboardUsers> {
    constructor(values?: Partial<RowPbwLoadboardUsers>) {
        super(endpointPath, true, values);
    }
}

export interface RowPbwLoadboardUsers {
    bp_profile: string;
    default_dispatch_layout: string;
    tm_profile: string;
    new_order_next_action: string;
    user_id: string;
    loadboard_dat_id: string;
    dat_user_id: string;
    dat_password: string;
    loadboard_truckstop_rest_id: string;
    truckstop_access_token: string;
    truckstop_refresh_token: string;
    truckstop_token_expires: Date;
    truckstop_user_id: string;
}

