import { DataHeader, DataSource, Label, Layout, Panel, Switch, Tab, Tabset, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutProject44Profile extends Layout {
    dataheaderP44Profile: DataHeader;
    labelDeliveryAcc: Label;
    labelDeliveryNote: Label;
    labelDimensions: Label;
    labelIncluded: Label;
    labelOtherAcc: Label;
    labelPickupAcc: Label;
    labelPickupNote: Label;
    labelPriorityAcc: Label;
    panelShipmentNotes: Panel;
    sourceP44ConfigProfile: DataSource;
    switchCarrierConfiguration: Switch;
    switchDeliveryAcc: Switch;
    switchDeliveryNote: Switch;
    switchDimensions: Switch;
    switchDispFallbacktoDefaultLoginGroup: Switch;
    switchDispatchAllowUnacceptedAccessorials: Switch;
    switchDispatchEnableUnitConversion: Switch;
    switchEnablePickupNoteTruncation: Switch;
    switchImgFallbacktoDefaultLoginGroup: Switch;
    switchOtherAcc: Switch;
    switchPickupAcc: Switch;
    switchPickupNote: Switch;
    switchPickupOnly: Switch;
    switchPrescheduledPickup: Switch;
    switchPriorityAcc: Switch;
    switchQuoteFallbacktoDefaultLoginGroup: Switch;
    switchRateAllowUnacceptedAccessorials: Switch;
    switchRateEnableUnitConversion: Switch;
    switchRequestAllGuaranteedServiceLevels: Switch;
    switchRequestAllInsideDelivery: Switch;
    switchRequestAllServiceLevels: Switch;
    switchTrackingFallbacktoDefaultLoginGroup: Switch;
    tabDispatchConfiguration: Tab;
    tabGeneral: Tab;
    tabImageConfiguration: Tab;
    tabRateConfiguration: Tab;
    tabShipmentNotes: Tab;
    tabTrackingConfiguration: Tab;
    tabsetProject44Profile: Tabset;
    textboxDescription: Textbox;
    textboxId: Textbox;
    textboxQuoteTimeout: Textbox;
    textboxSendDispRequest: Textbox;
}
