/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelEdiCompareItem.ts                *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/datafusion/edi-compare-item";

export class AutogenModelEdiCompareItem<RowType extends ModelRow> extends Endpoint<RowType, RowEdiCompareItemFields> {
	constructor(ctor: RowConstructor) {
		super(endpointPath, ctor);
	}
}

export class AutogenRowEdiCompareItem extends ModelRow<RowEdiCompareItemFields> {
	constructor(values?: Partial<RowEdiCompareItemFields>) {
		super(endpointPath, true, values);
	}
}

export interface RowEdiCompareItemFields {
	apply: string;
	change_type: string;
	is_child_group: boolean;
	no_changes: boolean;
	order_compared: string;
	source_descr: string;
	source_fieldname: string;
	source_id: string;
	source_tablename: string;
	source_value: string;
	target_descr: string;
	target_fieldname: string;
	target_id: string;
	target_tablename: string;
	target_value: string;
}

