/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelCarrierVehicleLookup.ts          *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/powerbroker/CarrierVehicleLookup";

export class AutogenModelCarrierVehicleLookup<RowType extends ModelRow> extends Endpoint<RowType, RowCarrierVehicleLookupFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowCarrierVehicleLookup extends ModelRow<RowCarrierVehicleLookupFields> {
    constructor(values?: Partial<RowCarrierVehicleLookupFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowCarrierVehicleLookupFields {
    carrier_id: string;
    cell_phone_number: string;
    company_id: string;
    driver_name: string;
    email_address: string;
    id: number;
    is_active: string;
    notes: string;
    tractor_number: string;
}

