/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelEdiorderProfile.ts               *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/datafusion/ediorder-profile";

export class AutogenModelEdiorderProfile<RowType extends ModelRow> extends Endpoint<RowType, RowEdiorderProfileFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowEdiorderProfile extends ModelRow<RowEdiorderProfileFields> {
    constructor(values?: Partial<RowEdiorderProfileFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowEdiorderProfileFields {
    ack_xmit_method: string;
    agent_payee_id: string;
    alt_partner_id: string;
    apply_chassis_adds: string;
    apply_container_adds: string;
    apply_frt_adds: string;
    apply_frt_chgs: string;
    apply_frt_dels: string;
    apply_hdr_adds: string;
    apply_hdr_chgs: string;
    apply_hdr_dels: string;
    apply_move_chgs: string;
    apply_note_adds: string;
    apply_note_dels: string;
    apply_oc_adds: string;
    apply_oc_chgs: string;
    apply_oc_dels: string;
    apply_order_chgs: string;
    apply_refno_adds: string;
    apply_refno_chgs: string;
    apply_refno_dels: string;
    apply_stop_adds: string;
    apply_stop_chgs: string;
    apply_stop_dels: string;
    auto_add_carrier: string;
    auto_add_location: string;
    auto_cancel: string;
    auto_compare: string;
    auto_compare_rt: string;
    auto_create_edi_loc: string;
    auto_create_order: string;
    auto_create_reply: string;
    auto_rate: string;
    auto_send_reply: string;
    auto_send_tender: string;
    bl_updates_order: string;
    carrier_id: string;
    clear_old_changes: string;
    commodity_id: string;
    company_id: string;
    compare_confirm: string;
    compare_exis_stops: string;
    compare_new_stops: string;
    compare_update: string;
    complete_document: string;
    copy_from_company: string;
    create_shipstatus: string;
    currency_type_id: string;
    days_to_retain: number;
    decl_req_reason: string;
    direction: string;
    document_compared: string;
    dup_cutoff_days: number;
    edi_comm_id: number;
    enable_load_analyzer: string;
    enable_tl_wizard: string;
    enf_batch_cont_204: string;
    enf_batch_cont_990: string;
    enf_batch_cont_997: string;
    equipment_type_id: string;
    fa_threshold: number;
    filename: string;
    func_ack_filename: string;
    func_ack_st02_frmt: string;
    func_ack_tid: number;
    func_altpartner_id: string;
    func_comm_id: number;
    func_next_batch: number;
    func_partner_id: string;
    func_use_m_batch: string;
    func_version: string;
    id: string;
    ignore_map_errors: string;
    ignore_old_changes: string;
    intercompany: string;
    lock_miles: string;
    next_batch: number;
    notes: string;
    old_changes_reply: string;
    order_type_id: string;
    override_reply: string;
    override_warning: string;
    partner_code_car: string;
    partner_code_loc: string;
    partner_id: string;
    partner_name: string;
    partner_status: string;
    payee_expense_glid: string;
    payee_pay_freq: string;
    payee_pay_method: string;
    pieces_info: string;
    primary_agency: string;
    process_as: string;
    process_priority: number;
    prompt_xref_edi_loc: string;
    rate_info: string;
    rbd_timezone_id: string;
    remove_file: string;
    reply_altpartnerid: string;
    reply_comm_id: number;
    reply_filename: string;
    reply_next_batch: number;
    reply_partner_id: string;
    reply_st02_format: string;
    reply_tid: number;
    reply_use_m_batch: string;
    reply_version: string;
    reply_xmit_method: string;
    require_loc_code: string;
    requires_reply: string;
    respond_within: number;
    revenue_code_id: string;
    rply_rquired_cance: string;
    rply_rquired_chang: string;
    rply_rquired_orig: string;
    scac: string;
    seg_alloc_code: string;
    send_func_ack: string;
    send_to_company: string;
    special_processing: string;
    st02_format: string;
    template_id: number;
    transaction_set: string;
    transmit_method: string;
    use_master_batch: string;
    version: string;
    weight_info: string;
}

