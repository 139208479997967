/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelBrokerRating.ts                  *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/powerbroker/broker-rating";

export class AutogenModelBrokerRating<RowType extends ModelRow> extends Endpoint<RowType, RowBrokerRatingFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowBrokerRating extends ModelRow<RowBrokerRatingFields> {
    constructor(values?: Partial<RowBrokerRatingFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowBrokerRatingFields {
    company_id: string;
    id: string;
    parent_row_id: string;
    prompt: string;
    rating: number;
}

