import { DataSource, Label, Layout, Table } from "@mcleod/components";

export abstract class AutogenLayoutOtherChargeCompare extends Layout {
	labelChanges: Label;
	labelOtherChargeAddsAndDeletes: Label;
	sourceCompareChangeGroup: DataSource;
	sourceEdiCompareItemChanges: DataSource;
	sourceOtherChargeCompareGroup: DataSource;
	tableOCChanges: Table;
	tableOtherCharge: Table;
}
