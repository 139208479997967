import { BlurEvent, DataSource, TableRow, Textbox } from "@mcleod/components";
import { Api, Currency, CurrencyUtil, ModelRow } from "@mcleod/core";
import { OrderRates } from "./OrderRates";
import { RecOrderRates } from "./RecOrderRates";
import { QuoteRates } from "./QuoteRates";

interface OtherChargeData {
  otherChargeRow: ModelRow;
  otherChargeTextbox: Textbox;
}

type RatesLayout = OrderRates | RecOrderRates | QuoteRates;

export class OtherChargesUtil {
  private ratesLayout: RatesLayout;
  private ordersDatasource: DataSource;

  public initialize(ratesLayout: RatesLayout) {
    this.ratesLayout = ratesLayout;
    this.ordersDatasource = ratesLayout.mainDataSource;
  }

  otherChargeOnBlur(event: BlurEvent) {
    if (event.changedWhileFocused) {
      const textbox: Textbox = event.target as Textbox;
      this.calculateOtherCharges({
        otherChargeRow: textbox.boundRow,
        otherChargeTextbox: textbox
      });
    }  }

  chargeIdChanged(textbox: Textbox, selection) {
    const otherChargeRow = textbox.boundRow;
    this.calculateOtherCharges({
      otherChargeRow,
      otherChargeTextbox: textbox
    }, true);
  }

  calculateOtherCharges(props: OtherChargeData, newSelection: boolean = false) {
    const orderInfo = {
      "order_distance": this.ordersDatasource.activeRow.get("bill_distance", null),
      "order_weight": this.ordersDatasource.activeRow.get("weight", null),
      "type": this.getOrderType()
    };
    if (this.ordersDatasource.activeRow.data["freight_charge"] != null) this.addCurrencyToProps(orderInfo, this.ordersDatasource.activeRow, "freight_charge", "order_freight");
    if (this.getOtherChargeTotal(false) != null) this.addCurrencyValueToProps(orderInfo, "previous_other_charge_total", this.getOtherChargeTotal(false));
    Api.search("lme/dispatch/calculate-other-charge", {
      "other_charge_row": props.otherChargeRow,
      "order_info": orderInfo,
      "new_selection": newSelection
    }).then(response => {
      this.otherChargeFromApi(response, props.otherChargeTextbox);
    });
  }

  tableOtherChargesOnRowDisplay(event) {
    const chargeId: Textbox = event.getTableRow().findComponentById("textboxChargeId") as Textbox;
    const textboxDescr: Textbox = event.getTableRow().findComponentById("textboxDescr") as Textbox;
    if (chargeId != null && chargeId.onSelectItem == null) {
      chargeId.onSelectItem = ((textbox, selectedItem) => {
        textboxDescr.text = (selectedItem as ModelRow).get("descr");
        this.chargeIdChanged(textbox, selectedItem);
        return undefined;
      });
    }
  }

  tableOtherChargesOnContentsChanged() {
    this.ordersDatasource.activeRow.set("otherchargetotal", this.getOtherChargeTotal(false));
    this.ordersDatasource.activeRow.set("total_charge", this.getOtherChargeTotal(true));
  }

  getOrderType(): string {
    return this.ratesLayout instanceof OrderRates ? "ORDER"
    : this.ratesLayout instanceof RecOrderRates ? "RECURRING" : "QUOTE";
  }

  addCurrencyToProps(props, row: ModelRow,  fieldName: string, propName: string) {
    const currencyValue = row.get(fieldName);
    this.addCurrencyValueToProps(props, propName, currencyValue);
  }

  addCurrencyValueToProps(props: any, propName: string, currencyValue: any) {
    props[propName] = currencyValue;
  }

  getOtherChargeTotal(includeFreightCharge: boolean) {
    let totalAmount: number = 0;
    const freightCharge: number = this.ordersDatasource.activeRow.get("freight_charge")?.amount ?? 0;
    this.ratesLayout.getOtherChargeDatasource().data.forEach(row => {
      if (row.data["amount"] != null) {
        totalAmount += row.data["amount"]["amount"];
      }
    });
    return {
      amount: includeFreightCharge ? freightCharge + totalAmount : totalAmount, currency_code: CurrencyUtil.getCurrencySettings().effective_currency_code
    } as Currency;
  }

  otherChargeFromApi(response, textbox: Textbox) {
    const data = response.data[0];
    const otherCharge = data["other_charge_row"];
    const tableRow: TableRow = TableRow.getContainingTableRow(textbox);
    const modelRow = textbox.boundRow;
    modelRow.set("descr", otherCharge["descr"]);
    modelRow.set("calc_method", otherCharge["calc_method"]);
    modelRow.set("units", otherCharge["units"]);
    modelRow.set("rate", otherCharge["rate"]);
    modelRow.set("amount", otherCharge["amount"]);
    tableRow.displayComponentValues();
  }
}

