import { DataHeader, DataSource, HorizontalSpacer, Label, Layout, Panel, Switch, Tab, Tabset, Textbox } from "@mcleod/components";
import { TableComment } from "../../../general/src/TableComment";
import { CommodityImage } from "../CommodityImage";

export abstract class AutogenLayoutCommodity extends Layout {
    dataheader1: DataHeader;
    horizontalspacer1: HorizontalSpacer;
    horizontalspacer2: HorizontalSpacer;
    horizontalspacer3: HorizontalSpacer;
    horizontalspacer4: HorizontalSpacer;
    labelAcceptableVariances: Label;
    labelTemperatureRange: Label;
    layoutAttachment: CommodityImage;
    layoutTableComments: TableComment;
    panel1: Panel;
    panel2: Panel;
    panel3: Panel;
    panel5: Panel;
    panelAcceptableVariances: Panel;
    panelTempRange: Panel;
    panelTempRight: Panel;
    sourceComments: DataSource;
    sourceCommodity: DataSource;
    sourceCommodityImage: DataSource;
    sourcePackagingType: DataSource;
    switchCubicUnitsPerPiece: Switch;
    switchHandlingUnitsPerPiece: Switch;
    switchHazmat: Switch;
    switchHazmatRouting: Switch;
    switchPoisonInhalationHazard: Switch;
    switchResidue: Switch;
    switchSpotsPerPiece: Switch;
    switchValuePerPiece: Switch;
    switchWeightIsPerPiece: Switch;
    tabComments: Tab;
    tabGeneral: Tab;
    tabHazmat: Tab;
    tabLTL: Tab;
    tabTemperature: Tab;
    tabsetCommodity: Tabset;
    textboxContactName: Textbox;
    textboxContactType: Textbox;
    textboxCubicUnits: Textbox;
    textboxDescr: Textbox;
    textboxERGNumber: Textbox;
    textboxEmergencyNumber: Textbox;
    textboxFgiValue: Textbox;
    textboxHandlingUnits: Textbox;
    textboxHazmatNumber: Textbox;
    textboxHazmatQtyCode: Textbox;
    textboxHeight: Textbox;
    textboxId: Textbox;
    textboxLength: Textbox;
    textboxMaxTemp: Textbox;
    textboxMinTemp: Textbox;
    textboxNmfcClassCode: Textbox;
    textboxNmfcCode: Textbox;
    textboxPackagingGroup: Textbox;
    textboxPackagingTypeCode: Textbox;
    textboxPlacardRequired: Textbox;
    textboxPrimaryHazardClass: Textbox;
    textboxProductSku: Textbox;
    textboxProperShippingName: Textbox;
    textboxReqSpots: Textbox;
    textboxReturnTemp: Textbox;
    textboxSetPoint: Textbox;
    textboxSetPointTemp: Textbox;
    textboxSignoffText: Textbox;
    textboxSubsidiaryHazardClass: Textbox;
    textboxTrainingUrl: Textbox;
    textboxTypeCode: Textbox;
    textboxWeight: Textbox;
    textboxWeightUomTypeCode: Textbox;
    textboxWidth: Textbox;
    textboxZone: Textbox;
}
