import { TableRowDisplayEvent } from "@mcleod/components";
import { AutogenLayoutRateIndexResultsData } from "./autogen/AutogenLayoutRateIndexResultsData";

export class RateIndexResultsData extends AutogenLayoutRateIndexResultsData {
    override async onLoad(): Promise<void> {
    }

    /* This is an event handler for the onRowDislay event fo table */
    tableOnRowDisplay(event: TableRowDisplayEvent) {
    }
}
