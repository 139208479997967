import { ObjectUtil } from "./ObjectUtil";
import { StringUtil } from "./StringUtil";

export class UrlUtil {
    public static isAbsoluteUrl(path: string) {
        path = path.toLowerCase();
        return path.startsWith("http://") || path.startsWith("https://");
    }

    /**
     * This function accepts either a query string or an array of strings or objects and returns a query string that can be passed on a URL.
     * Example usages with the return value:
     *
     * buildQueryString("someParameter=value")  -> "?someParameter=value"
     * buildQueryString("?someParameter=value") -> "?someParameter=value"
     * buildQueryString("someParameter=value&other=value2") -> "?someParameter=value&other=value2"
     * buildQueryString(["someParameter=value", "other=value2"]) -> "?someParameter=value&other=value2"
     * buildQueryString({someParameter:"value", other:"value2"}) -> "?someParameter=value&other=value2"
     *
     * @param {*} queryStringOrArray
     */
    public static buildQueryString(queryStringOrArrayOrObject: string | string[] | any, needsUrlEncoding: boolean = true) {
        if (queryStringOrArrayOrObject == null)
            return null;
        let result;
        if (typeof queryStringOrArrayOrObject === "string") {
            result = queryStringOrArrayOrObject;
            if (result.length > 0 && !result.startsWith("?"))
                result = "?" + result;
        }
        else if (Array.isArray(queryStringOrArrayOrObject)) {
            if (queryStringOrArrayOrObject.length === 0)
                return null;
            result = "?" + queryStringOrArrayOrObject.join("&");
        } else if (ObjectUtil.isObject(queryStringOrArrayOrObject)) {
            let first = true;
            for (const key in queryStringOrArrayOrObject) {
                if (first)
                    result = "?";
                else
                    result += "&";
                first = false;
                result += key;
                const value = queryStringOrArrayOrObject[key];
                if (value != null)
                    result += "=" + value;
            }
        }
        if (needsUrlEncoding)
            result = encodeURI(result);
        return result;
    }

    public static trimQueryString(url: string): string {
        if (url != null) {
            const index = url.indexOf("?");
            if (index >= 0)
                return url.substring(0, index);
        }
        return url;
    }

    public static getPropsFromUrl(url: string): any {
        const params = StringUtil.stringAfter(url, "?", false, null);
        if (params == null)
            return null;
        const urlParams = new URLSearchParams(params);
        const result = {};
        urlParams.forEach((value, key) => result[key] = value);
        return result;
    }

    public static getSearchParamFromUrl(paramName: string, url: string = window.location.search): any {
        const searchParams = new URLSearchParams(url);
        if (searchParams == null)
            return null;
        return searchParams.get(paramName);
    }
}
