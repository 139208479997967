/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelChargeCode.ts                    *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/dispatch/charge-code";

export class AutogenModelChargeCode<RowType extends ModelRow> extends Endpoint<RowType, RowChargeCodeFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowChargeCode extends ModelRow<RowChargeCodeFields> {
    constructor(values?: Partial<RowChargeCodeFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowChargeCodeFields {
    amount: number;
    charge_rate_method: string;
    co_amount: number;
    co_deduct_code_id: string;
    co_pay_method: string;
    co_payee_acct: string;
    co_percent: number;
    company_id: string;
    descr: string;
    edi_code: string;
    glid: string;
    id: string;
    incl_in_freight: string;
    is_fuel_surcharge: string;
    is_intermodal: string;
    is_taxable: string;
    is_tonu: string;
    ltl_allocation_method: string;
    oo_amount: number;
    oo_deduct_code_id: string;
    oo_pay_method: string;
    oo_payee_acct: string;
    oo_percent: number;
    pay_company_hourly_driver: string;
    prev_flag: string;
    rate: number;
    wf_min_charge: number;
    wf_occ_doctype: string;
    wf_occ_queue: string;
    who_to_pay: string;
}

