import { CommonDialogs } from "@mcleod/common";
import { Button, ButtonVariant, Component, DropdownItem, Label, Layout, Panel, Snackbar, Tabset, Textbox, Tree, TreeNode } from "@mcleod/components";
import { Alignment, Api, ArrayUtil, AuthType, Navigation, StringUtil } from "@mcleod/core";
import { CodeEditor } from "../common/CodeEditor";
import { PanelOpenModel } from "../ui/PanelOpenModel";
import { ModelDesignerTab } from "./ModelDesignerTab";
import { ModelTableDefinition } from "./ModelDesignerTypes";

export class ModelDesigner extends Layout {
    tabset: Tabset;
    finishedLoading: boolean;
    selectedComponents: Component[];
    buttonViewCode: Button;
    buttonViewJson: Button;
    buttonTestModel: Button;
    buttonOpen: Button;
    buttonSave: Button;
    buttonNew: Button;
    tableNames: string[];
    recordTypes: DropdownItem[];
    respFilteringControlTypes: DropdownItem[];
    respFilteringContactTypes: DropdownItem[];
    open: string;

    constructor(props?) {
        super({ auth: AuthType.LME, fillHeight: true, scrollX: true, needsServerLayout: false, ...props });
        const toolsProps = { variant: ButtonVariant.round, color: "subtle.darker" };
        this.buttonViewCode = new Button({ ...toolsProps, tooltip: "View code for this model", imageName: "curlyBrackets" });
        this.buttonViewJson = new Button({ ...toolsProps, tooltip: "View raw JSON of this model", imageName: "codeTags" });
        this.buttonTestModel = new Button({ ...toolsProps, tooltip: "Test this model", imageName: "run" });
        this.buttonOpen = new Button({ ...toolsProps, tooltip: "Open a model for editing", imageName: "folder" });
        this.buttonSave = new Button({ ...toolsProps, tooltip: "Save this model", imageName: "disk" });
        this.buttonNew = new Button({ ...toolsProps, tooltip: "Create a new model", imageName: "add" });

        this.buttonViewCode.addClickListener(event => this.showCode());
        this.buttonTestModel.addClickListener(event => this.testModel());
        this.buttonOpen.addClickListener(event => this.showOpen());
        this.buttonSave.addClickListener(event => this.showSave());
        this.buttonNew.addClickListener(event => this.addNewTab());
        this.tabset = new Tabset({
            fillRow: true,
            fillHeight: true,
            allowStyleChange: false,
            tools: [this.buttonTestModel, this.buttonViewCode, this.buttonViewJson, this.buttonOpen, this.buttonSave, this.buttonNew]
        });
        this.setProps({ title: "Model Designer", fillRow: true, fillHeight: true, padding: 0 });
        this.add(this.tabset);
    }

    // function called by router that can affect the properties of the router
    getRouterProps() {
        return { padding: 0 };
    }

    override onLoad() {
        this.tabset.addAfterTabSelectionListener(event => this.tabChanged(event.newSelection));
        this.tabset.addBeforeTabCloseListener(event => this.beforeTabClosed(event.tab as ModelDesignerTab));
        this.tabset.addAfterTabCloseListener(event => this.afterTabClosed());
        this.selectedComponents = [];
        const open = this.getLastOpen();
        if (open.length === 0)
            this.openTab(null, true);
        else {
            const lastSel = this.getLastSelected();
            for (let i = 0; i < open.length; i++)
                this.openTab(open[i], open[i] === lastSel);
        }
        this.finishedLoading = true;
        if (this.open != null) {
            this.openTab(this.open, true);
            this.addLastOpen(this.open);
        }
        this.loadTableList();
        this.loadRespFilteringValues();
    }

    async showCode() {
        let fileName = this.getActiveTab().designerPanel.def.file;
        let contents: string;
        if (fileName == null) {
            const path: string[] = this.getActiveTab().path.split("/");
            const last = path[path.length - 1];
            path.splice(path.length - 1);
            const className = StringUtil.toLowerCamelCase("Model_" + last);
            fileName = path.join("/") + "/" + className + ".java";

            if (await CommonDialogs.showYesNo("There is no associated Java file for this model.  Do you want to create and open " + fileName + "?") === false)
                return;// we probably want to ask the java api to create this file instead of specifying the contents here
            contents = `package com.tms.model.${path.join(".")};

import com.tms.api.lib.metadata.Path;
import com.tms.api.lib.model.QueryModelEndpoint;

@Path("${this.getActiveTab().path}")
public class ${className} extends QueryModelEndpoint {

}
`
        }
        fileName = "../mcleod-services/portal-api/src/main/java/com/tms/model/" + fileName;
        CodeEditor.openCodeEditor(fileName, { contentsIfEmpty: contents });
    }

    showJson() {
        const path = "../mcleod-services/portal-api/src/main/resources/models/" + this.getActiveTab().path + ".model";
        CodeEditor.openCodeEditor(path);
    }

    testModel() {
        Navigation.navigateTo("designer/model/ModelTester?model=" + encodeURI(this.getActiveTab().path), { newTab: true });
    }

    loadTableList() {
        Api.search("metadata/tables").then(response => {
            this.tableNames = [];
            for (const data of response.data)
                this.tableNames.push(data.table_name);
        });
    }

    private loadRespFilteringValues() {
        Api.search("metadata/resp-filtering").then(response => {
            this.recordTypes = [];
            this.respFilteringControlTypes = [];
            this.respFilteringContactTypes = [];
            const data = response?.data?.[0];
            if (data != null) {
                for (const recordType of data.record_types) {
                    this.recordTypes.push({ caption: recordType.caption, value: recordType.value })
                }
                for (const controlType of data.control_types) {
                    this.respFilteringControlTypes.push({ caption: controlType.caption, value: controlType.value })
                }
                for (const contactType of data.contact_types) {
                    this.respFilteringContactTypes.push({ caption: contactType.caption, value: contactType.value })
                }
            }
        });
    }

    getRecordType(value: string): DropdownItem {
        return this.getDropdownItemFromArray(value, this.recordTypes);
    }

    getRespFilteringControlType(value: string): DropdownItem {
        return this.getDropdownItemFromArray(value, this.respFilteringControlTypes);
    }

    getRespFilteringContactType(value: string): DropdownItem {
        return this.getDropdownItemFromArray(value, this.respFilteringContactTypes);
    }

    private getDropdownItemFromArray(value: string, array: DropdownItem[]): DropdownItem {
        if (StringUtil.isEmptyString(value) || ArrayUtil.isEmptyArray(array))
            return null;
        for (const item of array) {
            if (item.value === value)
                return item;
        }
        return null;
    }

    getLastSelected() {
        return localStorage.getItem("designer.model.selected");
    }

    addNewTab() {
        const tab = this.openTab(null, true);
        tab.designerPanel.panelModelProps.focus();
    }

    openTab(path: string, selectTab: boolean): ModelDesignerTab {
        if (path != null) {
            for (const tab of this.tabset) {
                if (tab.path === path) {
                    tab.select();
                    return;
                }
            }
        }
        const tab = new ModelDesignerTab(this, path);
        this.tabset.add(tab);
        if (selectTab === true)
            this.tabset.selectedIndex = this.tabset.getComponentCount() - 1;
        return tab;
    }

    getActiveTab(): ModelDesignerTab {
        return (this.tabset.getActiveTab() as ModelDesignerTab);
    }

    tabChanged(tab: ModelDesignerTab) {
        if (this.finishedLoading === true) {
            if (tab == null || tab.path == null)
                localStorage.setItem("designer.model.selected", null);
            else
                localStorage.setItem("designer.model.selected", tab.serviceProject == null ? tab.path : tab.serviceProject + "/" + tab.path);
        }
    }

    showOpen() {
        const pnl = new PanelOpenModel();
        CommonDialogs.showDialog(pnl, { title: "Open Model", height: 600, width: 500 }).then(() => {
            if (pnl.tree.selectedNode == null) {
                Snackbar.showSnackbar("Select a model to open.");
                return false;
            }
            let path = "";
            pnl.tree.selectedNode.path.forEach((node) => { path += node.caption + "/" });
            path = path.substring(0, path.length - 1);
            this.openTab(path, true);
            this.addLastOpen(path);
        });
    }

    getLastOpen(): string[] {
        const open = localStorage.getItem("designer.model.open");
        if (open == null || open.length === 0)
            return [];
        else
            return JSON.parse(open);
    }

    addLastOpen(entry: string) {
        const open = this.getLastOpen();
        if (open.indexOf(entry) < 0) {
            open.push(entry);
            localStorage.setItem("designer.model.open", JSON.stringify(open));
        }
    }

    beforeTabClosed(tab: ModelDesignerTab) {
        if (tab.path != null) {
            const lastOpen = this.getLastOpen();
            const index = lastOpen.indexOf(tab.fullPath);
            if (index >= 0) {
                lastOpen.splice(index, 1);
                localStorage.setItem("designer.model.open", JSON.stringify(lastOpen));
            }
        }
    }

    afterTabClosed() {
        if (this.tabset.getComponentCount() === 0)
            this.addNewTab();
    }

    showSave() {
        const tab = this.getActiveTab();
        if (tab.path == null) {
            Api.search("metadata/models").then((response) => {
                const tree = new Tree({ height: 300, width: 400, borderWidth: 1, borderRadius: 4, borderColor: "strokeSecondary", nodeLeafImageName: "folder" });
                const node = tree.makeTreeNodesFromObject(response.data[0], "name", "children");
                this.removeLeafNodes(node);
                node.expanded = true;
                tree.getRootNode().setChildren(node.getChildren());
                const label = new Label({ caption: "Select save folder", fontBold: true })
                const savePath = new Textbox({ caption: "Model name", required: true, fillRow: true });
                const panel = new Panel({ components: [label, tree, savePath] });
                CommonDialogs.showDialog(panel, { title: "Save Model" })
                    .then(() => {
                        const tab = this.getActiveTab();
                        const sel = tree.selectedNode;
                        if (sel == null) {
                            tree.showTooltip("You must select a folder to save this model.", {
                                timeout: 3000, shaking: true, position: Alignment.RIGHT
                            });
                            return false;
                        }
                        let path = "";
                        for (const n of sel.path)
                            path += n.caption + "/";
                        path += savePath.text;
                        tab.fullPath = path;
                        this.saveActiveTab();
                    });
            });
        }
        else
            this.saveActiveTab();
    }

    removeLeafNodes(node: TreeNode) {
        for (let i = node.getChildCount() - 1; i >= 0; i--) {
            const child = node.getChild(i);
            if (child.getChildCount() === 0)
                node.removeChild(i);
            else
                this.removeLeafNodes(child);
        }
    }

    saveActiveTab() {
        const tab = this.getActiveTab();
        const def = tab.designerPanel.panelModelProps.def;
        if (def.orderBy == null || def.orderBy.length === 0)
            delete def.orderBy;
        this.removeDefDefaultValues(def);
        const body = { path: tab.path, service_project: tab.serviceProject, definition: JSON.stringify(def) };
        Api.update("metadata/model", body).then((response) => {
            this.addLastOpen(tab.path);
            Snackbar.showSnackbar(response.data[0].response);
            this.getActiveTab().modified = false;
        }).catch(reason => CommonDialogs.showError(reason));
    }

    /**
     * This removes extraneous data from the model definitions.  This extraneous data includes empty arrays and other default values.
     * @param def
     */
    removeDefDefaultValues(def: Partial<ModelTableDefinition>) {
        if (def.joinConditions?.length == 0)
            delete def.joinConditions;
        if (def.fields?.length === 0)
            delete def.fields;
        if (def.joins?.length === 0)
            delete def.joins;
        if (def.qualifyFields === false)
            delete def.qualifyFields;
        if (def.joins?.length > 0)
            for (const join of def.joins)
                this.removeDefDefaultValues(join);
    }

    modified() {
        this.getActiveTab().modified = true;
    }
}
