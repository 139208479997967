import { BlurEvent, Button, ChangeEvent, ClickEvent, Component, DataSourceMode, DropdownItem, Label, Panel, Switch, TableRow, TableRowBeforeSaveEvent, TableRowDisplayEvent, TableRowMode, Textbox } from "@mcleod/components";
import { ModelRow, StringUtil } from "@mcleod/core";
import { apptConfirmCheck, checkAppointment, showAppointmentChangeDialog } from "./AppointmentUtil";
import { makeTooltipCallbackFunction } from "./LocationMakeQuickInfo";
import { StopNotesAndReferenceNumbers } from "./StopNotesAndReferenceNumbers";
import { AutogenLayoutMultiStop } from "./autogen/AutogenLayoutMultiStop";

const DATE_ON = { caption: "On", value: "O" };
const DATE_BETWEEN = { caption: "Between", value: "B" };

export class MultiStop extends AutogenLayoutMultiStop {
    public get stopDateTypeBetween(): DropdownItem { return DATE_BETWEEN }
    public get stopDateTypeOn(): DropdownItem { return DATE_ON }
    private distanceBadgeArray: boolean[] = [];
    commentsAndRefNbrs: StopNotesAndReferenceNumbers;

    setupTableRowListeners(tableRow: TableRow) {
        const btnExpandRow = tableRow.findComponentById("btnExpandRow") as Button;
        btnExpandRow.addClickListener(event => this.btnExpandRowOnClick(event));
        const apptRequired = tableRow.findComponentById("switchApptRequired") as Switch;
        apptRequired.addChangeListener(event => this.switchApptRequiredOnChange(event.target["row"]));
        const switchConfirmed = tableRow.findComponentById("switchConfirmed") as Switch;
        switchConfirmed.addChangeListener(event => this.switchConfirmedOnChange(event));
    }

    /** This is an event handler for the beforeRowSave event of tableStop.  */
    tableStopBeforeRowSave(event: TableRowBeforeSaveEvent) {
        const tableRow: TableRow = event.target as TableRow;
        const changedData = tableRow.data.getChangedData();
        const stopNotesRefNumbersPanel = tableRow.findComponentById("panelStopCommentsReferenceNumbers") as Panel;
        const stopNotesRefNumbers: StopNotesAndReferenceNumbers = stopNotesRefNumbersPanel.components[0] as StopNotesAndReferenceNumbers;
        const modelRow: ModelRow = event.target["data"];
        modelRow.set("stop_notes", stopNotesRefNumbers.tableStopComments.data ?? []);
        modelRow.set("reference_numbers", stopNotesRefNumbers.tableStopReferenceNumbers.data ?? []);
        modelRow.set("bp_stop_slideout", true);

        if (apptConfirmCheck(changedData, modelRow) == true && tableRow["appointmentChangeData"] != null) {
            tableRow["appointmentChangeData"].set("revised_early", changedData["sched_arrive_early"] ?? null)
            tableRow["appointmentChangeData"].set("revised_late", changedData["sched_arrive_late"] ?? null)
            modelRow.set("appointment_change", tableRow["appointmentChangeData"]);
        }
    }

    /** This is an event handler for the onRowDisplay event of tableStop.  */
    tableStopOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.target as TableRow;
        const modelRow: ModelRow = tableRow.data as ModelRow;
        tableRow.hideDragHandle();
        tableRow.table.dataSource.preventChangeNotifications = true;
        tableRow.table.rows[tableRow.index].borderBottomWidth = 0;
        tableRow["appointmentChangeData"] = null;
        this.setupTableRowListeners(tableRow);
        this.switchApptRequiredOnChange(tableRow);

        const locationName: Component = tableRow.findComponentById("textboxLocationName");
        const contactName: Textbox = tableRow.findComponentById("textboxContactName") as Textbox;
        const contactPhone: Textbox = tableRow.findComponentById("textboxPhone") as Textbox;
        const appointmentStatus: Textbox = tableRow.findComponentById("textboxAppointmentStatusId") as Textbox;
        const between: Textbox = tableRow.findComponentById("textboxStopDateType") as Textbox;
        const distanceBadge: Label = tableRow.findComponentById("labelDistanceBadge") as Label;

        if (StringUtil.isEmptyString(modelRow.get("contact_name")))
            contactName.text = "";

        if (StringUtil.isEmptyString(modelRow.get("phone")))
            contactPhone.text = "";

        if (StringUtil.isEmptyString(modelRow.get("appointment_status_id", "")))
            appointmentStatus.text = "";

        // if current stop or delivered - solid orange
        // all other badges clear
        const orderSequence = modelRow.get("order_sequence");
        this.distanceBadgeArray[orderSequence] = (modelRow.get("actual_arrival") != null || modelRow.get("actual_departure") != null);

        if (orderSequence != 1) {
            if (this.distanceBadgeArray[orderSequence - 1] == true) {
                distanceBadge.backgroundColor = "warning";
                distanceBadge.color = "warning.reverse";
                distanceBadge.borderWidth = 0;
            }
            else {
                distanceBadge.backgroundColor = "warning.reverse";
                distanceBadge.color = "warning";
                distanceBadge.borderWidth = 1;
            }
        }

        if (locationName != null)
            locationName.tooltipCallback = makeTooltipCallbackFunction(modelRow.get("location_id"), locationName);

        if (modelRow.get("actual_departure") != null)
            between.visible = false;
        else if (modelRow.get("sched_arrive_late") == null)
            between.text = "On";
        else
            between.text = "Between";

        const early: Textbox = tableRow.findComponentById("textboxSchedArriveEarly") as Textbox;
        const late: Textbox = tableRow.findComponentById("textboxSchedArriveLate") as Textbox;
        const actualArrive: Textbox = tableRow.findComponentById("textboxActualArrival") as Textbox;
        const actualDepart: Textbox = tableRow.findComponentById("textboxActualDeparture") as Textbox;

        if (modelRow.get("actual_arrival") == null) {
            if (actualArrive != null)
                actualArrive.visible = false;
            if (actualDepart != null)
                actualDepart.visible = false;
            if (early != null)
                early.visible = true;
            if (late != null)
                late.visible = true && between.text == "Between";

            tableRow.mode = TableRowMode.UPDATE;
            tableRow.canBeEdited = true;
        }
        else {
            if (actualArrive != null)
                actualArrive.visible = true;
            if (actualDepart != null)
                actualDepart.visible = !modelRow.isNull("actual_departure");
            if (early != null)
                early.visible = false;
            if (late != null)
                late.visible = false;

            tableRow.mode = TableRowMode.NONE;
            tableRow.canBeEdited = false;
        }

        const labelDistanceBadge: Label = tableRow.findComponentById("labelDistanceBadge") as Label;
        if (modelRow.get("rate_dist_from_previous") != null) {
            labelDistanceBadge.visible = true;
            labelDistanceBadge.caption = modelRow.get("rate_dist_from_previous") + " MILES BETWEEN STOPS";
        } else {
            labelDistanceBadge.visible = false;
            labelDistanceBadge.caption = "";
        }

        this.setAppointmentLabel(tableRow, modelRow, "labelApptImage", "confirmed", "appt_required");

        tableRow.canBeDeleted = false;
        const panelStopCommentsReferenceNumbers = tableRow.findComponentById("panelStopCommentsReferenceNumbers") as Panel;
        const stopCommentRefNbr: StopNotesAndReferenceNumbers = panelStopCommentsReferenceNumbers.components[0] as StopNotesAndReferenceNumbers;
        stopCommentRefNbr.addLayoutLoadListener(event => {
            const stopRow = new ModelRow(this.sourceStop.url, true, modelRow.data);
            stopCommentRefNbr.stopRow = stopRow;
            this.setupCommentRefNbrTables(stopCommentRefNbr);
        })
    }

    /** This is an event handler for the onClick event of btnExpandRow.  */
    btnExpandRowOnClick(event: ClickEvent) {
        const tableRow: TableRow = TableRow.getContainingTableRow(event.target as Component);
        const row = tableRow.data;
        const expandablePanel: Component = tableRow.findComponentById("expandablePanel");
        tableRow["custom_expanded"] = !tableRow["custom_expanded"] ?? true;
        event.target["imageRotation"] = tableRow["custom_expanded"] ? 0 : 90;
        expandablePanel.visible = tableRow["custom_expanded"] ? true : false;

        if (tableRow["custom_expanded"]) {
            // Populate the location website
            if (row.data.location != null && row.data.location[0].website_url != null) {
                let url = row.data.location[0].website_url;
                if (!url.startsWith("http:") && !url.startsWith("https")) {
                    url = "http://" + url;
                }
                row.set("location_website", url);
            }
        }
    }

    /** This is an event handler for the onChange event of textboxStopDateType.  */
    textboxStopDateTypeOnChange(event) {
        const tableRow: TableRow = TableRow.getContainingTableRow(event.target as Component);
        const type: string = tableRow.data.get("stop_type");
        const early: Textbox = tableRow.findComponentById("textboxSchedArriveEarly") as Textbox;
        const late: Textbox = tableRow.findComponentById("textboxSchedArriveLate") as Textbox;
        const between = event.newValue == "Between";
        late.required = false;
        late.visible = between;
        if (between) {
            late["_captionLabel"].preventCollapse = true;
            late["_captionLabel"].visible = false;
            late.required = true;
            late.placeholder = "Required";
        }
        else {
            tableRow.data?.set("sched_arrive_late", null);
        }
        this.setStopDateCaptions(early as Textbox, type == "SO", between);
    }

    setStopDateCaptions(early: Textbox, isDelivery: boolean, isDateWindow: boolean) {
        const caption = isDelivery ? "Delivery" : "Pickup";
        early.caption = isDateWindow ? `${caption} Window` : `${caption} Appointment`;
    }

    scheduleArriveOnBlur(event: BlurEvent) {
        checkAppointment(event, null);
    }

    /** This is an event handler for the onChange event of switchApptRequired.  */
    switchApptRequiredOnChange(tableRow: TableRow) {
        const switchConfirmed = tableRow.findComponentById("switchConfirmed") as Switch;
        const apptRequired = tableRow.findComponentById("switchApptRequired") as Switch;
        switchConfirmed.visible = apptRequired.checked;
    }

    /** This is an event handler for the onChange event of switchConfirmed.  */
    switchConfirmedOnChange(event: ChangeEvent) {
        const tableRow: TableRow = TableRow.getContainingTableRow(event.target as Component);
        const apptRequired = tableRow.findComponentById("switchApptRequired") as Switch;
        const modelRow = tableRow.data;
        const changedData = modelRow.getChangedData();
        if (event.userInitiatedChange &&
            changedData["confirmed"] != null && changedData["confirmed"] === "N") {
            showAppointmentChangeDialog({ "confirmed": "Y" }, null, modelRow, { switchConfirmed: event.target as Switch }, tableRow);
        } else {
            apptRequired.enabled = !modelRow.getBoolean("confirmed");
        }
    }

    /** This is an event handler for the beforeLookupModelSearch event of textboxContactName.  */
    textboxContactNameBeforeLookupModelSearch(event) {
        if (this.mainDataSource.activeRow.get("location_id")) {
            event.filter.parent_row_type = "L";
            event.filter.parent_row_id = this.mainDataSource.activeRow.get("location_id");
            event.filter.is_active = "Y";
        } else {
            event.filter.id = null;
        }
    }

    setupCommentRefNbrTables(stopCommentRefNbr: StopNotesAndReferenceNumbers) {
        const tableStopComment = stopCommentRefNbr.tableStopComments;
        const tableStopRefNbr = stopCommentRefNbr.tableStopReferenceNumbers;
        tableStopComment.dataSource.parentDataSource = tableStopRefNbr.dataSource.parentDataSource = this.sourceMultiStop;
        tableStopComment.dataSource.mode = tableStopRefNbr.dataSource.mode = DataSourceMode.UPDATE;
    }

    private setAppointmentLabel(tableRow: TableRow, modelRow: any, labelId: string, confirmedField: string, requiredField: string) {
        const panelAppt = tableRow.findComponentById("panelAppt") as Panel;
        const labelAppt = tableRow.findComponentById(labelId) as Label;
        labelAppt.imageMarginLeft = 0;
        const apptRequired = modelRow.getBoolean(requiredField);
        if (apptRequired) {
            if (modelRow.getBoolean(confirmedField)) {
                labelAppt.caption = "Confirmed";
                labelAppt.imageColor = "green";
            } else {
                labelAppt.caption = "Required";
                labelAppt.imageColor = "orange";
            }
            labelAppt.imageName = "clock";
        }
        panelAppt.visible = apptRequired;
    }

}
