import { Button, CityState, DataSource, HorizontalSpacer, Label, Layout, Panel, Tab, Tabset, Textbox } from "@mcleod/components";
import { LaneHistoryTable } from "../../../dispatch/src/LaneHistoryTable";
import { RateIndexDataSummary } from "../../../dispatch/src/RateIndexDataSummary";
import { TenderRates } from "../TenderRates";

export abstract class AutogenLayoutEdiSuggestedPricing extends Layout {
    buttonClear: Button;
    buttonExpand: Button;
    citystateConsigneeCS: CityState;
    citystateCustomer: CityState;
    citystateShipperCS: CityState;
    citystateStopOriginCityId: CityState;
    horizontalspacer1: HorizontalSpacer;
    horizontalspacer2: HorizontalSpacer;
    labelCongineeCaption: Label;
    labelCustomerAddress: Label;
    labelCustomerCaption: Label;
    labelCustomerName: Label;
    labelDeliveryCaption: Label;
    labelEdiStopConsigneeSchedArriveEarly: Label;
    labelEdiStopConsigneeSchedArriveEarlyTime: Label;
    labelEdiStopConsigneeSchedArriveLate: Label;
    labelEdiStopOriginSchedArriveEarly: Label;
    labelEdiStopOriginSchedArriveEarlyTime: Label;
    labelEdiStopOriginSchedArriveLate: Label;
    labelFilter: Label;
    labelPickupCaption: Label;
    labelShipperCaption: Label;
    layoutCommitmentAutoRating: Layout;
    layoutLaneHistory: LaneHistoryTable;
    layoutRateIndex: RateIndexDataSummary;
    layoutTenderRates: TenderRates;
    panel1: Panel;
    panelCommodity: Panel;
    panelCustomer: Panel;
    panelFilterComponents: Panel;
    panelOverview: Panel;
    panelPickupDelivery: Panel;
    panelSearchFilter: Panel;
    panelSearchHeader: Panel;
    panelShipperConsignee: Panel;
    panelWeightDistance: Panel;
    searchButton: Button;
    sourceEdiOrdersList: DataSource;
    sourceEdiStop: DataSource;
    sourceLaneHistory: DataSource;
    sourceOtherChargeEdi: DataSource;
    tabCommitmentAutoRating: Tab;
    tabLaneHistory: Tab;
    tabRateIndex: Tab;
    tabsetEdiSuggestedPricing: Tabset;
    textboxBillDistance: Textbox;
    textboxBillDistanceUm: Textbox;
    textboxCommodityId: Textbox;
    textboxDateRange: Textbox;
    textboxEquipmentTypeId: Textbox;
    textboxFreightCharge: Textbox;
    textboxMovementStatus: Textbox;
    textboxOtherchargetotal: Textbox;
    textboxRadius: Textbox;
    textboxRate: Textbox;
    textboxRateType: Textbox;
    textboxRateUnits: Textbox;
    textboxStopsPickDrop: Textbox;
    textboxWeight: Textbox;
    textboxWeightUm: Textbox;
}
