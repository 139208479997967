import { Label, TableRow, TableRowDisplayEvent, TableRowExpansionEvent } from "@mcleod/components";
import { CommentsAndReferenceNumbers } from "./CommentsAndReferenceNumbers";
import { AutogenLayoutLoadTenderExpressEdiStopGrid } from "./autogen/AutogenLayoutLoadTenderExpressEdiStopGrid";

export class LoadTenderExpressEdiStopGrid extends AutogenLayoutLoadTenderExpressEdiStopGrid {
    private locStopType: String;

    tableLtxStopGridOnRowExpand(event: TableRowExpansionEvent) {
        if ((event.target as TableRow).data != null) {
            const row = (event.target as TableRow).data;
            const commentsAndRefnos = event.expandComponentParent as CommentsAndReferenceNumbers;
            commentsAndRefnos.ediStopId = row.get("id");
        }
    }

    tableLtxStopGridOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.getTableRow();
        const row = (event.target as TableRow).data;
        const stopTypeComponent = tableRow.findComponentById("labelStopType") as Label;

        if (row.get("stop_type") == "PU") {
            stopTypeComponent.caption = "Pickup";
        }
        else if (row.get("stop_type") == "SO") {
            stopTypeComponent.caption = "Delivery";
        }
    }

}
