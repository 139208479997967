/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelTariff.ts                        *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/dispatch/tariff";

export class AutogenModelTariff<RowType extends ModelRow> extends Endpoint<RowType, RowTariffFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowTariff extends ModelRow<RowTariffFields> {
    constructor(values?: Partial<RowTariffFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowTariffFields {
    add_timestamp: Date;
    add_userid: string;
    apply_deficit_rating_flag: boolean;
    company_id: string;
    cur_uid: number;
    description: string;
    dim_weight_multiplier: number;
    effective_timestamp: Date;
    expire_timestamp: Date;
    fallback_tar_uid: number;
    fallback_tariff_id: string;
    icc_id: string;
    manual_override_flag: boolean;
    minimum_weight_per_pallet: number;
    mod_timestamp: Date;
    mod_userid: string;
    rxz_type_code: string;
    special_points_reg_uid: number;
    tar_uid: number;
    tariff_id: string;
    tariff_name: string;
    tariff_type_code: string;
}

