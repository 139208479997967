/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelEdiRefNumber.ts                  *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/datafusion/edi-ref-number";

export class AutogenModelEdiRefNumber<RowType extends ModelRow> extends Endpoint<RowType, RowEdiRefNumberFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowEdiRefNumber extends ModelRow<RowEdiRefNumberFields> {
    constructor(values?: Partial<RowEdiRefNumberFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowEdiRefNumberFields {
    company_id: string;
    description: string;
    element_id: string;
    id: string;
    partner_id: string;
    pieces: number;
    reference_number: string;
    reference_qual: string;
    stop_id: string;
    version: string;
    weight: number;
    weight_um: string;
}

