import { CommonDialogs } from "@mcleod/common";
import {
    BlurEvent, Button, ClickEvent, DataDisplayEvent, DataSource, DataSourceAction, DataSourceExecutionEvent,
    DataSourceMode, DataSourceModeChangeEvent, EditRowDecorator, Label, Layout, LookupModelSearchEvent, SaveButton,
    SlideoutDecorator, Snackbar, TableAddRowResult, TableContentsChangedEvent, TableRow, TableRowBeforeSaveEvent,
    TableRowDisplayEvent, TableRowMode, Textbox, Toast
} from "@mcleod/components";
import { DataSourceValidationEvent } from "@mcleod/components/src/events/DataSourceValidationEvent";
import { TableAction } from "@mcleod/components/src/events/TableContentsChangedEvent";
import { Api, ArrayUtil, ModelRow, Navigation, StringUtil, getLogger, getUnauthSettings } from "@mcleod/core";
import { UsersResponsibilitySlideoutMF } from "@mcleod/general/src/UsersResponsibilitySlideoutMF";
import { RearrangeStopSlideout } from "./RearrangeStopSlideout";
import { RecOrderStop } from "./RecOrderStop";
import { RecurringSchedule } from "./RecurringSchedule";
import { AutogenLayoutRecurringOrders } from "./autogen/AutogenLayoutRecurringOrders";

const log = getLogger("lme.dispatch.RecurringOrders");

const updateModeUrl = "lme/dispatch/RecurringOrders?mode=update&key=";

enum NextAction {
    VIEW_REC_ORDER = 0,
    CREATE_SCHEDULE = 1
}

export class RecurringOrders extends AutogenLayoutRecurringOrders {
    private _validateRFDuringAdd = (event: DataSourceValidationEvent) => this.validateRFDuringAdd(event, this.saveButton);
    private rfEntryCodeRequired: boolean;
    private nextAction: NextAction = NextAction.VIEW_REC_ORDER;
    private datasourceAction: DataSourceAction;

    private get saveButton(): SaveButton {
        return this.dataHeader?.["saveButton"];
    }

    override onLoad(): void {
        this.dataHeader.showSave = true;
        this.setupTableOnEditLayoutLoadedListeners();
        this.setupOnSelectListeners();
        this.setCompanySettings();
        this.sourceRecurringOrder.afterGetDataboundValues = (row: ModelRow, dataSource: DataSource) => this.sourceRecurringOrderAfterGetDataboundValues(row, dataSource);
        this.buttonViewRecurringOrder.clicked();
        this.layoutRecurringSchedTable.tableRecurringSchedule.addContentsChangedListener((event: TableContentsChangedEvent) => this.tableSchedulerOnContentsChanged());
        this.addLayoutDataLoadListener(event => {
            this.navigateWithAction(this.datasourceAction);
            if (this.mainDataSource.mode === DataSourceMode.UPDATE) {
                this.checkScheduledRequiredFieldsEntered();
                this.layoutRecurringSchedTable.initialize(this.activeRow, this.sourceStopRecOrder.data)
                this.activeRow.addAfterFieldUpdateListener((event) => {
                    if (this.isScheduledField(event.fieldName)) {
                        this.checkScheduledRequiredFieldsEntered();
                    }
                });
            }
        });
        this.textboxCommodityId.addChangeListener(event => {
            this.commodityCodeChanged();
        });
        this.layoutRates.checkPickupAndDeliveryEntered = () => {
            const stopData = this.sourceStopRecOrder.data;
            if (stopData.length < 2) return false;
            const pickupEntered = stopData[0].get("stop_type") === "PU" && stopData[0].get("city_id") != null;
            const deliveryEntered = stopData[stopData.length - 1].get("stop_type") === "SO" && stopData[stopData.length - 1].get("city_id") != null;
            return pickupEntered && deliveryEntered;
        }
    }

    setCompanySettings() {
        const companySettings = getUnauthSettings()?.company_settings;
        this.textboxDefMoveType.visible = companySettings["is_asset"] && companySettings["is_brokerage"] && !companySettings["is_ltl"];
    }

    private isScheduledField(fieldName: string) {
        return fieldName === "collection_method" || fieldName === "order_mode" || fieldName === "customer_id";
    }

    /** This is an event handler for the beforeModeChange event of sourceRecurringOrder.  */
    sourceRecurringOrderBeforeModeChange(event: DataSourceModeChangeEvent) {
        this.hideSuffix = event.newMode != DataSourceMode.ADD
        this.panelDataHeaderAddlLeft.visible = event.newMode === DataSourceMode.UPDATE || event.newMode === DataSourceMode.ADD || event.newMode === DataSourceMode.SEARCH;
        if (event.newMode === DataSourceMode.SEARCH) {
            for (let x = this.panelDataHeaderAddlLeft.components.length - 1; x >= 0; x--) {
                const component = this.panelDataHeaderAddlLeft.components[x];
                if (component !== this.buttonUsers)
                    component.visible = false;
            }
        }

        if (event.newMode === DataSourceMode.UPDATE) {
            this.title = "Recurring Order - " + this.mainDataSource.activeRow?.get("id");
        }

        this.tabScheduler.visible = event.newMode === DataSourceMode.UPDATE;
        this.tabNextActions.visible = event.newMode === DataSourceMode.ADD;
        this.tabStops.visible = event.newMode !== DataSourceMode.SEARCH;
        this.imageReorder.visible = event.newMode !== DataSourceMode.ADD;
    }

    /** This is an event handler for the afterModeChange event of sourceRecurringOrder.  */
    sourceRecurringOrderAfterModeChange(event: DataSourceModeChangeEvent) {
        this.dataheader1["saveButton"].enabled = true;
        if (this.mainDataSource.mode == DataSourceMode.SEARCH) {
            this.stopsTable.dataSource = null;
        }
        else if (this.stopsTable.dataSource == null)
            this.stopsTable.dataSource = this.sourceStopRecOrder;

        if (event.oldMode != DataSourceMode.ADD && event.newMode == DataSourceMode.ADD)
            this.initializeAdd();
    }

    /** This is an event handler for the beforeExecution event of sourceRecurringOrder.  */
    sourceRecurringOrderBeforeExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() === DataSourceAction.ADD) {
            this.stopsTable.rows.forEach(
                row => {
                    log.debug(row);
                    row.saveChanges();
                    log.debug(row.data);
                });
            log.debug("stops datasource length " + this.stopsTable.dataSource.data.length);
        }
    }

    /** This is an event handler for the afterExecution event of sourceRecurringOrder.  */
    sourceRecurringOrderAfterExecution(event: DataSourceExecutionEvent) {
        this.datasourceAction = event.getAction();
    }

    /** This is an event handler for the beforeExecution event of sourceRecurringOrder. Responsibility Filtering.*/
    sourceRecurringOrderAfterGetDataboundValues(row: ModelRow, dataSource: DataSource) {
        if (dataSource.mode === DataSourceMode.SEARCH) {
            //have to set primary_agency in search mode because the field isn't bound
            const primaryAgencyField = this.getRecurringOrderUsersAgencyField();
            row.set(primaryAgencyField, dataSource.searchRow.get(primaryAgencyField));

            //have to add agency_link search data to the filter manually, since there are no bound components for those records
            if (ArrayUtil.isEmptyArray(this.sourceAgencyLink.data))
                return;
            row.addLinkedModel({ model: this.sourceAgencyLink.url, rows: [this.sourceAgencyLink.data[0]] });
        }
    }

    setupOnSelectListeners() {
        this.textboxEquipmentTypeIdGeneral.onSelectItem = () => {
            this.textboxEquipmentTypeIdEquipment.text = this.textboxEquipmentTypeIdGeneral.text;
            return undefined;
        };

        this.textboxEquipmentTypeIdEquipment.onSelectItem = () => {
            this.textboxEquipmentTypeIdGeneral.text = this.textboxEquipmentTypeIdEquipment.text;
            return undefined;
        };

        this.textboxRevenueCodeId.onSelectItem = () => {
            if (this.textboxRevenueCodeId.getFirstLookupModelData()?.get("id") != null)
                this.revenueCodeChanged(this.textboxRevenueCodeId.getFirstLookupModelData().get("id"));
            return undefined;
        }

        this.textboxOrderType.onSelectItem = () => {
            if (this.textboxOrderType.getFirstLookupModelData()?.get("id") != null) {
                this.orderTypeChanged(this.textboxOrderType.getFirstLookupModelData().get("id"));
            }
            return undefined;
        }
    }

    private setupTableOnEditLayoutLoadedListeners() {
        this.stopsTable.onEditLayoutLoaded = this.stopsTableOnEditLayoutLoaded();
    }

    private initializeAdd() {
        this.addStopsToTable();
        this.rfEntryCodeRequired = this.mainDataSource.activeRow.get("rf_entry_code_required", false);
        this.mainDataSource.addValidationListener(this._validateRFDuringAdd);
    }

    private navigateWithAction(action: DataSourceAction) {
        switch (action) {
            case DataSourceAction.ADD:
                this.navigateAfterAdd();
                break;
            case DataSourceAction.SEARCH:
                if (this["create_schedule"]) {
                    this.showScheduleSlideout();
                }
                break;
        }
    }

    private navigateAfterAdd() {
        let key = null;
        switch (this.nextAction) {
            case NextAction.VIEW_REC_ORDER:
                key = this.mainDataSource.activeRow.get("id");
                Navigation.navigateTo(updateModeUrl + key, { newTab: false });
                break;
            case NextAction.CREATE_SCHEDULE:
                key = this.mainDataSource.activeRow.get("id");
                Navigation.navigateTo(updateModeUrl + key, { newTab: false }, { create_schedule: true });
                break;
        }
    }

    stopsTableOnEditLayoutLoaded(): (rowDecorator: EditRowDecorator, tableRow: TableRow) => void {
        return (rowDecorator: EditRowDecorator, tableRow: TableRow) => {
            const tableRowOrderStop = tableRow["orderStop"] as RecOrderStop;
            const slideoutOrderStop = rowDecorator.layout as RecOrderStop;
            if (this.sourceRecurringOrder.isAddingOrUpdating()) {
                slideoutOrderStop.layoutStopNotesAndRefNbrs.tableStopComments.dataSource.mode = DataSourceMode.UPDATE;
            }
            slideoutOrderStop.mainDataSource.activeRow?.set("stop_notes", tableRowOrderStop?.layoutStopNotesAndRefNbrs.tableStopComments.data);
            slideoutOrderStop.mainDataSource.activeRow?.set("reference_numbers", tableRowOrderStop?.layoutStopNotesAndRefNbrs.tableStopReferenceNumbers.data);
            slideoutOrderStop.initialize(true, tableRow, this.sourceRecurringOrder);
            slideoutOrderStop.slideOutTableRow = tableRow;

            rowDecorator.onSave = this.stopSlideoutOnSave(rowDecorator, tableRow);
            rowDecorator.btnDelete.visible = false;
            rowDecorator.layout.width = window.innerWidth * .75;
            rowDecorator.labelTitle.caption = "Recurring Order Stop Details - " + this.title;
            rowDecorator.multiButton.dropdownItems = null;
        };
    }

    stopSlideoutOnSave(rowDecorator: EditRowDecorator, tableRow: TableRow): (updatedData: ModelRow | any) => void {
        const slideoutOrderStop = (rowDecorator.layout as RecOrderStop);
        const tableRowOrderStop = tableRow["orderStop"] as RecOrderStop;
        return async (updatedData: ModelRow | any) => {
            tableRow.data.setValues(updatedData.data);
            if (tableRow.index == 0) {
                this.sourceRecurringOrder.activeRow.set("pallets_how_many", slideoutOrderStop.textboxPalletsHowMany.text);
                this.sourceRecurringOrder.activeRow.set("pallets_required", true === slideoutOrderStop.switchPalletsRequired.checked ? "Y" : "N");
            }
            slideoutOrderStop.layoutStopNotesAndRefNbrs.updateSourceTables(slideoutOrderStop.layoutStopNotesAndRefNbrs.tableStopComments, tableRow["orderStop"].layoutStopNotesAndRefNbrs.tableStopComments);
            tableRowOrderStop.textboxLocationId.displayData(tableRow.data, null, 0);
            tableRowOrderStop.setLocationTextAfterLocation(tableRowOrderStop.textboxLocationId.textCombined, tableRow.data, "location_id");
            if (this.mainDataSource.mode === DataSourceMode.UPDATE)
                await tableRowOrderStop.activeRow?.post();
        };
    }

    /** This is an event handler for the beforeRowSave event of stopsTable.  */
    stopsTableBeforeRowSave(event: TableRowBeforeSaveEvent) {
        const tableRow: TableRow = event.target as TableRow;
        const modelRow: ModelRow = tableRow.data;
        const tableRowOrderStop: RecOrderStop = event.target["orderStop"] as RecOrderStop;
        modelRow.set("stop_notes", tableRowOrderStop.layoutStopNotesAndRefNbrs.tableStopComments?.data || []);
    }

    /** This is an event handler for the onRowDisplay event of stopsTable.  */
    stopsTableOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.getTableRow();
        tableRow.canBeDeleted = tableRow.index != 0;
        const labelSequence = (tableRow.findComponentById("labelSequence")) as Label
        labelSequence.caption = (tableRow.index + 1).toString();
        tableRow.findComponentById((comp: any) => {
            if (comp instanceof RecOrderStop) {
                comp.addLayoutLoadListener(() => {
                    this.sourceStopRecOrder.preventChangeNotifications = true;
                    tableRow["orderStop"] = comp;
                    labelSequence.addClickListener(event => tableRow._showEditLayout());
                    comp.calculateRates = () => this.checkStopsForDistanceChange().then(() => this.layoutRates.calculateRates(false, false));
                    comp.checkScheduledFields = () => this.checkScheduledRequiredFieldsEntered();
                    comp.initialize(false, tableRow, this.sourceRecurringOrder);
                    comp.displayData(tableRow.data, this.sourceStopRecOrder.data, tableRow.index);
                    if (tableRow.index == 0) {
                        comp.switchPalletsRequired.displayData(this.sourceRecurringOrder.activeRow, null, 0);
                        comp.textboxPalletsHowMany.displayData(this.sourceRecurringOrder.activeRow, null, 0);
                        comp.marginRight = 36;
                    }
                    this.sourceStopRecOrder.preventChangeNotifications = false;
                });
                return true;
            }
            return false;
        });
    }

    /** This is an event handler for the onContentsChanged event of stopsTable.  */
    stopsTableOnContentsChanged(event: TableContentsChangedEvent) {
        if (TableAction.DELETE == event.getAction()) {
            this.checkStopsForDistanceChange().then(() => this.layoutRates.calculateRates(false, false));
        }
        this.checkScheduledRequiredFieldsEntered();
    }

    commodityCodeChanged() {
        const lookupData = this.textboxCommodityId.getFirstLookupModelData();
        if (lookupData == null)
            return;
        this.textboxCommodity.text = lookupData?.get("descr");

        this.switchHazmat.checked = (lookupData?.get("is_hazmat") ?? "N") == "N" ? false : true;
        const lmModelRow = new ModelRow(this.textboxHazmatCode.lookupModel, false, { id: lookupData?.get("hazmat_number") });
        this.mainDataSource.activeRow.setLookupModelData("hazmat_code", lmModelRow);
        this.mainDataSource.activeRow.set("hazmat_code", lookupData?.get("hazmat_number"));

        this.mainDataSource.activeRow.set("hazmat", lookupData?.get("hazmat", "N"));
        this.mainDataSource.activeRow.set("temperature_min", lookupData?.get("temperature_min"));
        this.mainDataSource.activeRow.set("temperature_max", lookupData?.get("temperature_max"));
    }

    /* beforeLookupModel Listeners */
    textboxMatchEquipmentTypeIdBeforeLookupModelSearch(event: LookupModelSearchEvent) {
        event.filter.applies_to = "in Y,X,D";
    }

    imageReorderOnClick(event: ClickEvent) {
        this._rearrangeStops();
    }

    /* Button Listeners */

    buttonViewRecurringOrderOnClick(event: ClickEvent) {
        this.setButtonClickedStyling(event.target as Button);
        this.nextAction = NextAction.VIEW_REC_ORDER;
    }

    buttonCreateScheduleOnClick(event: ClickEvent) {
        this.setButtonClickedStyling(event.target as Button);
        this.nextAction = NextAction.CREATE_SCHEDULE;
    }

    /** This is an event handler for the onClick event of buttonAddAnotherStop.  */
    buttonAddAnotherStopOnClick(event: ClickEvent) {
        this.addNewStopToTable().then(row => row.scrollIntoView());
    }

    /** This is an event handler for the onDataDisplay event of buttonAddAnotherStop.  */
    buttonAddAnotherStopOnDataDisplay(event: DataDisplayEvent) {
        (event.target as Button).setProps({ ...(event.target as Button).allProps, "color": "primary" });
    }

    /** This is an event handler for the onClick event of buttonScheduler.  */
    buttonSchedulerOnClick(event: ClickEvent) {
        this.showScheduleSlideout();
    }

    /* Blur Events */
    clearEquipmentIds(event: BlurEvent) {
        const textbox = event.target as Textbox;
        if (textbox.text == "") {
            this.textboxEquipmentTypeIdGeneral.text = "";
            this.textboxEquipmentTypeIdEquipment.text = "";
        }
    }

    /* Helper Methods */

    private setButtonClickedStyling(button: Button) {
        this.panelNextActionButtons.forEveryChildComponent(comp => {
            if (comp instanceof Button) {
                comp.color = comp === button ? "primary.reverse" : "subtle.darker";
                comp.backgroundColor = comp === button ? "primary" : "primary.reverse";
                comp.borderColor = comp === button ? "primary" : undefined;
            }
        });
    }

    private _rearrangeStops() {
        const slideout = new RearrangeStopSlideout("R", this.mainDataSource?.activeRow.get("id"));
        slideout.showSlideout((canceled: boolean) => {
            if (!canceled) {
                Toast.showSuccessToast("Your stops were successfully resequenced");
                this.sourceStopRecOrder.search({ "order_id": this.activeRow.get("id") });
            }
        });
    }

    async checkStopsForDistanceChange() {
        const stopdata = this.sourceStopRecOrder.data;
        let changedText = this.sourceStopRecOrder.deletedData?.length > 0 ? "removed from" : null;
        for (let i = 0; changedText == null && i < stopdata?.length; i++) {
            if (stopdata[i]._appending) changedText = "added to";
            if (stopdata[i].getChangedData()?.city_id != null) changedText = "modified on";
        }
        const locked = this.activeRow?.getBoolean("lock_miles", false)
        if (changedText != null) {
            if (locked) {
                const caption = `A stop has been ${changedText} this order which may affect mileage.  Would you like to unlock the mileage?`;
                const unlock = await CommonDialogs.showYesNo(caption, "Unlock Miles");
                if (unlock) {
                    this.activeRow.set("lock_miles", "N");
                    this.activeRow.set("bill_distance", null);
                }
            } else {
                this.activeRow.set("bill_distance", null);
            }
        }
    }

    private async addStopsToTable() {
        if (this.stopsTable.dataSource.data?.length === 0) {
            await this.addNewStopToTable();
            await this.addNewStopToTable();
            log.debug("rec order stops length " + this.stopsTable.dataSource.data.length)
        }
        this.tabsetAddOrders.expandAll();
    }

    public async addNewStopToTable(): Promise<TableRow> {
        let addRowResult: TableAddRowResult;
        const newRowData = await this.stopsTable._createNewRowData();
        if (newRowData != null) {
            newRowData.set("stop_type", this.stopsTable.data.length > 0 ? "SO" : "PU");
            addRowResult = this.stopsTable.addRow(newRowData, { mode: TableRowMode.ADD }, { display: true, addToData: true });
        }
        return addRowResult.row;
    }

    private revenueCodeChanged(newValue: any) {
        Api.search("lme/dispatch/calculate-order-mode",
            {
                "mode_locked": false,
                "revenue_code_id": newValue,
                "order_type_id": this.mainDataSource.activeRow.get("order_type_id"),
                "current_mode": this.mainDataSource.activeRow.get("order_mode")
            }).then(response => {
                const data = response.data[0];
                const newMode = data["mode"];
                this.mainDataSource.activeRow.set("order_mode", newMode);
            });
    }

    private orderTypeChanged(newValue: any) {
        Api.search("lme/dispatch/calculate-order-mode",
            {
                "mode_locked": false,
                "revenue_code_id": this.mainDataSource.activeRow.get("revenue_code_id"),
                "order_type_id": newValue,
                "current_mode": this.mainDataSource.activeRow.get("order_mode")
            }).then(response => {
                const data = response.data[0];
                const newMode = data["mode"];
                this.mainDataSource.activeRow.set("order_mode", newMode);
            });
    }

    private validateRFDuringAdd(event: DataSourceValidationEvent, saveButton: SaveButton) {
        const missingRFCode = this.rfEntryCodeRequired && this.sourceAgencyLink.data.length === 0;
        const message = "This recurring order does not have any responsibility codes assigned";
        if (missingRFCode) {
            event.validationResults.push({ isValid: false, validationMessage: message, caption: message, component: saveButton });
        }
    }


    displayRecurringOrderUsersSlideout() {
        const urs = new UsersResponsibilitySlideoutMF("lme/dispatch/RecurringOrderUsers", this.getRecurringOrderUsersSlideoutTitle(), this.getRecurringOrderUsersIdFilter(),
            this.getRecurringOrderUsersAgencyField(), this.sourceRecurringOrder, this.sourceAgencyLink);
        urs.show();
    }

    private getRecurringOrderUsersSlideoutTitle(): string {
        const name = this.sourceRecurringOrder.activeRow?.get("name");
        return "Recurring Order Users" + ((StringUtil.isEmptyString(name) === false) ? (" - " + name) : "");
    }

    private getRecurringOrderUsersIdFilter(): any {
        const id = this.sourceRecurringOrder.activeRow?.get("id");
        if (id != null)
            return { id: id };
        return null;
    }

    private getRecurringOrderUsersAgencyField(): string {
        return null;
    }

    showScheduleSlideout() {
        let recurringScheduleLayout: RecurringSchedule;
        const erd = new SlideoutDecorator({
            title: `Recurring Order Schedule`,
            layout: recurringScheduleLayout = Layout.getLayout("lme/dispatch/RecurringSchedule") as RecurringSchedule,
            layoutLoadListeners: async (event) => {
                recurringScheduleLayout.initialize(DataSourceMode.ADD, this.activeRow, this.sourceStopRecOrder.data);
            },
            width: '60%',
            fillVerticalSpace: true,
            overlayProps: { closeOnClickOff: false, greyedBackground: true },
            onClose: (cancelled: boolean) => {
                recurringScheduleLayout.slideOut();
            },
            addlComponents: new Button({
                id: "recSchedSaveButton",
                caption: "Save and Close",
                backgroundColor: "primary",
                color: "primary.reverse",
                minWidth: 128,
                borderWidth: 0,
                rowBreak: false,
                onClick: async (event: ClickEvent) => {
                    if (this["create_schedule"]) {
                        if (this.checkScheduledRequiredFieldsEntered()) {
                            recurringScheduleLayout.activeRow.set("scheduled", "Y");
                        }
                    }
                    const updatedRow = await recurringScheduleLayout.onSave();
                    if (updatedRow) {
                        this.mainDataSource.activeRow.set("frequency", updatedRow.data["frequency"]);
                        this.mainDataSource.activeRow.set("occurrence", updatedRow.data["occurrence"]);
                        await this.sourceRecurringSched.search({ recurring_order_id: this.mainDataSource.activeRow.get("id") });
                        this.checkScheduledRequiredFieldsEntered();
                    }
                },
            })
        });
    }

    tableSchedulerOnContentsChanged() {
        this.checkScheduledRequiredFieldsEntered();
    }

    checkScheduledRequiredFieldsEntered(): boolean {
        const requiredFields = {
            "customer_id": "Customer",
            "collection_method": "Billing Method",
            "order_mode": "Mode"
        };
        const missingFields = []
        for (const key in requiredFields) {
            if (!this.activeRow.data[key]) {
                missingFields.push(requiredFields[key]);
            }
        }
        const shipperStop = this.sourceStopRecOrder.data[0]?.data;
        const consigneeStop = this.sourceStopRecOrder.data[this.sourceStopRecOrder.data.length - 1]?.data;
        if (shipperStop == null || !shipperStop["city_id"] || shipperStop["stop_type"] !== "PU") {
            missingFields.push("Shipper Location");
        }
        if (consigneeStop == null || !consigneeStop["city_id"] || consigneeStop["stop_type"] !== "SO") {
            missingFields.push("Consignee Location");
        }
        const isMissingFields = missingFields.length > 0;
        const isTableEmpty = this.mainDataSource.mode === DataSourceMode.UPDATE && this.layoutRecurringSchedTable.tableRecurringSchedule.data.length === 0;
        let message: string;

        if (isMissingFields) {
            message = "The recurring order must have a " + (missingFields.length == 1 ? missingFields[0] :
                missingFields.slice(0, -1).join(", ") + " and " + missingFields.slice(-1)) + " to be scheduled.";
        }
        else if (isTableEmpty) {
            message = "The recurring order must have at least one schedule assignment to be scheduled.";
        }

        this.switchScheduled.enabled = !isTableEmpty && !isMissingFields;
        this.switchScheduled.disabledTooltip = message;
        if ((isMissingFields || isTableEmpty) && this.switchScheduled.checked) {
            this.switchScheduled.checked = false;
            Snackbar.showWarningSnackbar(message + " Schedule has been turned off.", { targetPanel: this });
        }
        return this.switchScheduled.enabled;
    }
}

