import { DataSourceMode } from "@mcleod/components";
import { AutogenLayoutEdiTenderErrors } from "./autogen/AutogenLayoutEdiTenderErrors";

export class EdiTenderErrors extends AutogenLayoutEdiTenderErrors {
    override onLoad() { }

    sourceEdiMapErrorsAfterExecution() {
        this.sourceEdiMapError.setRowsAndMode(DataSourceMode.NONE, (this.sourceEdiMapError.data.filter(error => error.data["transaction_type"] === "T")));
    }
}
