import { Button, DataSource, Label, Layout, Tab, Tabset, Textbox } from "@mcleod/components";
import { BrokerageMovementTable } from "../BrokerageMovementTable";
import { BrokeragePlanningPeriscope } from "../BrokeragePlanningPeriscope";

export abstract class AutogenLayoutBrokeragePlanning extends Layout {
    buttonFilter: Button;
    labelPageTitle: Label;
    layoutAvailable: BrokerageMovementTable;
    layoutCovered: BrokerageMovementTable;
    layoutDelivered: BrokerageMovementTable;
    layoutHold: BrokerageMovementTable;
    layoutPendingOffers: BrokerageMovementTable;
    layoutPeriscope: BrokeragePlanningPeriscope;
    layoutProgress: BrokerageMovementTable;
    sourceBrokerageMovements: DataSource;
    sourceBrokeragePlanningProfile: DataSource;
    tabAvailableOrders: Tab;
    tabCarrierOffers: Tab;
    tabCoveredOrders: Tab;
    tabDeliveredOrders: Tab;
    tabHoldOrders: Tab;
    tabInProgressOrders: Tab;
    tabPeriscope: Tab;
    tabsetMain: Tabset;
    textBoxDateRange: Textbox;
    textboxId: Textbox;
}
