import { DesignableObject } from "../base/DesignableObject";
import { DomEvent } from "./DomEvent";

export interface EventListener {
    wrapped?: EventListener;
    runsOnce?: boolean;
    (event: Event): void;
}

export class Event {
    private _target: DesignableObject;
    private _domEvent: DomEvent;
    private preventDef: boolean;
    public shouldAutomaticallyStopPropagation: boolean;
    public stoppedImmediatePropagation: boolean;

    constructor(target: DesignableObject, domEvent?: Event | DomEvent) {
        this._target = target;
        this._domEvent = this.findNestedDomEvent(domEvent);
        this.shouldAutomaticallyStopPropagation = true;
    }

    private findNestedDomEvent(event: Event | DomEvent): DomEvent {
        if (event instanceof Event)
            return event._domEvent;
        else
            return event;
    }

    public get target(): DesignableObject {
        return this._target;
    }

    public get domEvent(): DomEvent {
        return this._domEvent;
    }

    public get defaultPrevented(): boolean {
        return this.preventDef === true;
    }

    public stop() {
        this.preventDefault();
        this.stopPropagation();
    }

    public preventDefault(): void {
        this.preventDef = true;
        if (this.domEvent != null)
            this.domEvent.preventDefault();
    }

    public stopPropagation(): void {
        if (this.domEvent != null)
            this.domEvent.stopPropagation();
    }

    public stopImmediatePropagation(): void {
        if (this.domEvent != null)
            this.domEvent.stopImmediatePropagation();
        this.stoppedImmediatePropagation = true;
    }

    public consume(): void {
        this.preventDefault();
        this.stopPropagation();
    }
}
