import { Button, DataSource, HorizontalSpacer, Label, Layout, Panel } from "@mcleod/components";

export abstract class AutogenLayoutRateIndexDataSummary extends Layout {
	buttonExpandDetails: Button;
	buttonFetchOtherResults: Button;
	horizontalspacer1: HorizontalSpacer;
	labelDATContractRateHeader: Label;
	labelDATContractTotalRate: Label;
	labelDATContractTotalTotalRate: Label;
	labelDATSpotRateHeader: Label;
	labelDATSpotTotalRate: Label;
	labelDATSpotTotalTotalRate: Label;
	labelFSCRateDATContract: Label;
	labelFSCRateDATSpot: Label;
	labelFSCRateFreightWaves: Label;
	labelFSCRateGreenscreen: Label;
	labelFSCRateGreenscreenTarget: Label;
	labelFSCRateMpactBuy: Label;
	labelFSCRateMpactSell: Label;
	labelFSCRateScacBuy: Label;
	labelFSCRateScacSell: Label;
	labelFSCRateTruckstopPaid: Label;
	labelFSCRateTruckstopPosted: Label;
	labelFreightWavesRateHeader: Label;
	labelFreightWavesTotalRate: Label;
	labelFreightWavesTotalTotalRate: Label;
	labelFuelSurcharge: Label;
	labelGreenscreenRateHeader: Label;
	labelGreenscreenTargetRateHeader: Label;
	labelGreenscreenTargetTotalRate: Label;
	labelGreenscreenTargetTotalTotalRate: Label;
	labelGreenscreenTotalRate: Label;
	labelGreenscreenTotalTotalRate: Label;
	labelHighLHDATContract: Label;
	labelHighLHDATSpot: Label;
	labelHighLHFreightWaves: Label;
	labelHighLHGreenscreen: Label;
	labelHighLHGreenscreenTarget: Label;
	labelHighLHMpactBuy: Label;
	labelHighLHMpactSell: Label;
	labelHighLHScacBuy: Label;
	labelHighLHScacSell: Label;
	labelHighLHTruckstopPaid: Label;
	labelHighLHTruckstopPosted: Label;
	labelHighLinehaulHeader: Label;
	labelLHRateDATContract: Label;
	labelLHRateDATSpot: Label;
	labelLHRateFreightWaves: Label;
	labelLHRateGreenscreen: Label;
	labelLHRateGreenscreenTarget: Label;
	labelLHRateMpactBuy: Label;
	labelLHRateMpactSell: Label;
	labelLHRateScacBuy: Label;
	labelLHRateScacSell: Label;
	labelLHRateTruckstopPaid: Label;
	labelLHRateTruckstopPosted: Label;
	labelLinehaulRate: Label;
	labelLowLHDATContract: Label;
	labelLowLHDATSpot: Label;
	labelLowLHFreightWaves: Label;
	labelLowLHGreenscreen: Label;
	labelLowLHGreenscreenTarget: Label;
	labelLowLHMpactBuy: Label;
	labelLowLHMpactSell: Label;
	labelLowLHScacBuy: Label;
	labelLowLHScacSell: Label;
	labelLowLHTruckstopPaid: Label;
	labelLowLHTruckstopPosted: Label;
	labelLowLinehaulHeader: Label;
	labelMPactBuyRateHeader: Label;
	labelMPactSellRateHeader: Label;
	labelMarginDATContract: Label;
	labelMarginDATSpot: Label;
	labelMarginFreightWaves: Label;
	labelMarginGreenscreen: Label;
	labelMarginGreenscreenTarget: Label;
	labelMarginHeader: Label;
	labelMarginMpactBuy: Label;
	labelMarginMpactSell: Label;
	labelMarginScacBuy: Label;
	labelMarginScacSell: Label;
	labelMarginTruckstopPaid: Label;
	labelMarginTruckstopPosted: Label;
	labelMpactBuyTotalRate: Label;
	labelMpactBuyTotalTotalRate: Label;
	labelMpactSellTotalRate: Label;
	labelMpactSellTotalTotalRate: Label;
	labelNumCompaniesHeader: Label;
	labelNumOfCompaniesDATContract: Label;
	labelNumOfCompaniesDATSpot: Label;
	labelNumOfCompaniesFreightWaves: Label;
	labelNumOfCompaniesGreenscreen: Label;
	labelNumOfCompaniesGreenscreenTarget: Label;
	labelNumOfCompaniesMpactBuy: Label;
	labelNumOfCompaniesMpactSell: Label;
	labelNumOfCompaniesScacBuy: Label;
	labelNumOfCompaniesScacSell: Label;
	labelNumOfCompaniesTruckstopPaid: Label;
	labelNumOfCompaniesTruckstopPosted: Label;
	labelNumOfReportsDATContract: Label;
	labelNumOfReportsDATSpot: Label;
	labelNumOfReportsFreightWaves: Label;
	labelNumOfReportsGreenscreen: Label;
	labelNumOfReportsGreenscreenTarget: Label;
	labelNumOfReportsMpactBuy: Label;
	labelNumOfReportsMpactSell: Label;
	labelNumOfReportsScacBuy: Label;
	labelNumOfReportsScacSell: Label;
	labelNumOfReportsTruckstopPaid: Label;
	labelNumOfReportsTruckstopPosted: Label;
	labelNumReportsHeader: Label;
	labelODMiles: Label;
	labelOriginDestinationMilesDATContract: Label;
	labelOriginDestinationMilesDATSpot: Label;
	labelOriginDestinationMilesFreightWaves: Label;
	labelOriginDestinationMilesGreenscreen: Label;
	labelOriginDestinationMilesGreenscreenTarget: Label;
	labelOriginDestinationMilesMpactBuy: Label;
	labelOriginDestinationMilesMpactSell: Label;
	labelOriginDestinationMilesScacBuy: Label;
	labelOriginDestinationMilesScacSell: Label;
	labelOriginDestinationMilesTruckstopPaid: Label;
	labelOriginDestinationMilesTruckstopPosted: Label;
	labelRateTimeFrameDATContract: Label;
	labelRateTimeFrameDATSpot: Label;
	labelRateTimeFrameFreightWaves: Label;
	labelRateTimeFrameGreenscreen: Label;
	labelRateTimeFrameGreenscreenTarget: Label;
	labelRateTimeFrameMpactBuy: Label;
	labelRateTimeFrameMpactSell: Label;
	labelRateTimeFrameScacBuy: Label;
	labelRateTimeFrameScacSell: Label;
	labelRateTimeFrameTruckstopPaid: Label;
	labelRateTimeFrameTruckstopPosted: Label;
	labelRequestDateDATContract: Label;
	labelRequestDateDATSpot: Label;
	labelRequestDateFreightWaves: Label;
	labelRequestDateGreenscreen: Label;
	labelRequestDateGreenscreenTarget: Label;
	labelRequestDateHeader: Label;
	labelRequestDateMpactBuy: Label;
	labelRequestDateMpactSell: Label;
	labelRequestDateScacBuy: Label;
	labelRequestDateScacSell: Label;
	labelRequestDateTruckstopPaid: Label;
	labelRequestDateTruckstopPosted: Label;
	labelScacBuyRateHeader: Label;
	labelScacBuyTotalRate: Label;
	labelScacBuyTotalTotalRate: Label;
	labelScacSellRateHeader: Label;
	labelScacSellTotalRate: Label;
	labelScacSellTotalTotalRate: Label;
	labelTimeFrameHeader: Label;
	labelTollsDATContract: Label;
	labelTollsDATSpot: Label;
	labelTollsFreightWaves: Label;
	labelTollsGreenscreen: Label;
	labelTollsGreenscreenTarget: Label;
	labelTollsHeader: Label;
	labelTollsMpactBuy: Label;
	labelTollsMpactSell: Label;
	labelTollsScacBuy: Label;
	labelTollsScacSell: Label;
	labelTollsTruckstopPaid: Label;
	labelTollsTruckstopPosted: Label;
	labelTotalRates: Label;
	labelTotalSellDATContract: Label;
	labelTotalSellDATSpot: Label;
	labelTotalSellFreightWaves: Label;
	labelTotalSellGreenscreen: Label;
	labelTotalSellGreenscreenTarget: Label;
	labelTotalSellHeader: Label;
	labelTotalSellMpactBuy: Label;
	labelTotalSellMpactSell: Label;
	labelTotalSellScacBuy: Label;
	labelTotalSellScacSell: Label;
	labelTotalSellTruckstopPaid: Label;
	labelTotalSellTruckstopPosted: Label;
	labelTruckstopPaidRateHeader: Label;
	labelTruckstopPaidTotalRate: Label;
	labelTruckstopPaidTotalTotalRate: Label;
	labelTruckstopPostedRateHeader: Label;
	labelTruckstopPostedTotalRate: Label;
	labelTruckstopPostedTotalTotalRate: Label;
	panelDATContractExpandable: Panel;
	panelDATContractTotalRate: Panel;
	panelDATSpotExpandable: Panel;
	panelDATSpotTotalRate: Panel;
	panelDatContractTotal: Panel;
	panelDatSpotTotal: Panel;
	panelExpandedDetails: Panel;
	panelFieldsCaption: Panel;
	panelFreightWavesExpandable: Panel;
	panelFreightWavesTotalRate: Panel;
	panelFrtWavesSpotTotal: Panel;
	panelGreenscreenNetworkExpandable: Panel;
	panelGreenscreenNetworkTotal: Panel;
	panelGreenscreenNetworkTotalRate: Panel;
	panelGreenscreenTargetExpandable: Panel;
	panelGreenscreenTargetTotal: Panel;
	panelGreenscreenTargetTotalRate: Panel;
	panelMpactBuyExpandable: Panel;
	panelMpactBuyTotal: Panel;
	panelMpactBuyTotalRate: Panel;
	panelMpactSellExpandable: Panel;
	panelMpactSellTotal: Panel;
	panelMpactSellTotalRate: Panel;
	panelRateIndexDataSummary: Panel;
	panelRateIndexDataSummaryHeader: Panel;
	panelScacButTotal: Panel;
	panelScacBuyExpandable: Panel;
	panelScacBuyTotalRate: Panel;
	panelScacSellExpandable: Panel;
	panelScacSellTotal: Panel;
	panelScacSellTotalRate: Panel;
	panelTruckstopPaidExpandable: Panel;
	panelTruckstopPaidTotal: Panel;
	panelTruckstopPaidTotalRate: Panel;
	panelTruckstopPostedExpandable: Panel;
	panelTruckstopPostedTotal: Panel;
	panelTruckstopPostedTotalRate: Panel;
	sourceRateIndexResultsData: DataSource;
}
