import { Textbox } from "..";
import { DomEvent } from "./DomEvent";
import { Event, EventListener } from "./Event";

export interface LookupModelSearchListener extends EventListener {
    (event: LookupModelSearchEvent): void;
}

export class LookupModelSearchEvent extends Event {
    public _filter: any;

    constructor(target: Textbox, domEvent?: DomEvent) {
        super(target, domEvent);
        this._filter = { text_search: target.text };
    }

    public get filter() {
        return this._filter;
    }

    public set filter(filter: any) {
        this._filter = filter;
    }
}
