import { DataSource, DataSourceAction } from "../databinding/DataSource";
import { Event, EventListener } from "./Event";

export interface DataSourceExecutionListener extends EventListener {
    (event: DataSourceExecutionEvent): void;
}

export class DataSourceExecutionEvent extends Event {
    private _action: DataSourceAction;
    private _isBefore: boolean;
    private _filter: any;

    constructor(dataSource: DataSource, action: DataSourceAction, isBefore: boolean, filter?: any) {
        super(dataSource, null);
        this._action = action;
        this._isBefore = isBefore;
        this._filter = filter || {};
    }

    public getAction() {
        return this._action;
    }

    public isBefore() {
        return this._isBefore;
    }

    public isAfter() {
        return !this.isBefore();
    }

    public get dataSource(): DataSource {
        return super.target as DataSource;
    }

    public get filter(): any {
        return this._filter;
    }

    public set filter(value: any) {
        this._filter = value;
    }
}
