import { Table } from "../components/table/Table";
import { DataSource } from "../databinding/DataSource";
import { DomEvent } from "./DomEvent";
import { Event, EventListener } from "./Event";

export enum TableAction {
    DELETE = "delete",
    ADD = "add",
    UPDATE = "update"
}

export interface TableContentsChangedListener extends EventListener {
    (event: TableContentsChangedEvent): void;
}

export class TableContentsChangedEvent extends Event {
    private _action: TableAction;

    constructor(table: Table, action: TableAction, domEvent?: DomEvent) {
        super(table, domEvent);
        this._action = action;
    }

    public getAction() {
        return this._action;
    }

    public getTable(): Table {
        return this.target as Table;
    }

    public getDataSource(): DataSource {
        return this.getTable().dataSource;
    }
}
