import { CityState, DataSource, Label, Layout, Panel, StarRating, Textbox } from "@mcleod/components";
import { MovementCarrierPay } from "../MovementCarrierPay";

export abstract class AutogenLayoutCarrierOfferCounter extends Layout {
    labelAddress1: Label;
    labelCarrier: Label;
    labelCarrierCity: CityState;
    labelCarrierContact: Label;
    labelCarrierName: Label;
    labelContactName: Label;
    labelContactPhone: Label;
    labelEmail: Label;
    labelRates: Label;
    layoutMovementCarrierPay: MovementCarrierPay;
    panelBrokerCounter: Panel;
    panelCarrier: Panel;
    panelCarrierInfo: Panel;
    panelFirstInfo: Panel;
    panelOffers: Panel;
    panelOrderInfo: Panel;
    panelSecondInfo: Panel;
    panelSummary: Panel;
    sourceCarrierOffer: DataSource;
    sourceCarriersForAssignment: DataSource;
    starRatingCarrier: StarRating;
    textboxAmount: Textbox;
    textboxBrokerCounter: Textbox;
    textboxCarrierCounter: Textbox;
    textboxCommodity: Textbox;
    textboxDistance: Textbox;
    textboxEquipmentType: Textbox;
    textboxMarginAmount: Textbox;
    textboxMarginPct: Textbox;
    textboxWeight: Textbox;
}
