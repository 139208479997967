import { Button, DataSource, HorizontalSpacer, Label, Layout, Panel, Textbox, } from "@mcleod/components";

export abstract class AutogenLayoutSendRateConfirmation extends Layout {
    buttonCancel: Button;
    buttonSend: Button;
    buttonSendLater: Button;
    horizontalspacer1: HorizontalSpacer;
    labelHeaderTitle: Label;
    panel1: Panel;
    panelHeader: Panel;
    panelProgress: Panel;
    sourceSendRateConfirmation: DataSource;
    sourceTemplateRateConfirmation: DataSource;
    textboxComment: Textbox;
    textboxContactName: Textbox;
    textboxDefaultTemplate: Textbox;
    textboxEmailAddress: Textbox;
    textboxSubject: Textbox;
}
