import { CarrierAssignment } from "../../../powerbroker/src/CarrierAssignment";
import { CarrierSelection } from "../CarrierSelection";
import { DataSource, Layout, Step, Stepper } from "@mcleod/components";

export abstract class AutogenLayoutCarrierSelectionStepper extends Layout {
	layoutAssigned: CarrierAssignment;
	layoutSelection: CarrierSelection;
	sourceBrltlBillingFreightGroup: DataSource;
	sourceCarrierInsCtrl: DataSource;
	sourceCarriersForAssignment: DataSource;
	sourceComments: DataSource;
	sourceMovement: DataSource;
	sourceReferenceNumber: DataSource;
	stepAssigned: Step;
	stepCarrierSelection: Step;
	stepperCarrierSelection: Stepper;
}
