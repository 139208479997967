import { Label, Tab, TableRow } from "@mcleod/components";
import { Api } from "@mcleod/core";
import { AutogenLayoutEdiOrderFASeg } from "./autogen/AutogenLayoutEdiOrderFASeg";

export class EdiOrderFASeg extends AutogenLayoutEdiOrderFASeg {
    private _faId: string;
    private _version: string;

    public get version(): string {
        return this._version;
    }

    public set version(value: string) {
        this._version = value;
    }

    public get faId(): string {
        return this._faId;
    }
    public set faId(value: string) {
        this._faId = value;
    }

    override onLoad(): void {

        this.styleTab(this.tabFASegment);
    }

    async tableFASegOnRowDisplay(event) {
        const tableRow: TableRow = event.getTableRow();
        const funcSegRow = tableRow.data.data;
        const labelSegError = (tableRow.findComponentById("labelSegmentError")) as Label
        const code = funcSegRow["segment_error_code"];

        await Api.search("lme/datafusion/standard-code", {
            "version": this.version, "element_id": "720", "standard_code": code
        }).then((response) => {
            const standardCodeRow = response.data[0];
            labelSegError.caption = standardCodeRow["description"];
        });

        const labelElementError = (tableRow.findComponentById("labelElementError")) as Label
        const elementCode = funcSegRow["error_number"];
        const rowId = funcSegRow["id"];

        await Api.search("lme/datafusion/standard-code", {
            "version": this.version, "element_id": "723", "standard_code": elementCode
        }).then((response) => {
            const standardCodeRow = response.data[0];
            labelElementError.caption = standardCodeRow["description"];
        });
    }

    sourceEdiFunctionalSegAfterExecution() {
        this.tabFASegment["countLabel"].caption = this.sourceEdiFunctionalSeg.data.length;
    }

    styleTab(tab: Tab) {
        if (tab["_heading"] != null && tab["_headingLabel"] != null) {
            tab["_heading"].color = "subtle.light";
            tab["_heading"].marginBottom = 10;
            tab["_headingLabel"].setProps({ color: "default", fontBold: true, fillRow: false })
            if (tab["countLabel"] == null) {
                tab["countLabel"] = new Label({
                    fontBold: true, caption: "0", fillRow: true,
                    color: "primary", rowBreak: false, marginLeft: 5
                });
                tab["_heading"].insert(tab["countLabel"], 1);
            }
        }
    }
}
