import { Button, ButtonVariant, ClickListener, Image, Label, Panel } from "@mcleod/components";
import { HomePageEditor } from "./HomePageEditor";
import { HomeWidget } from "./HomeWidget";

export class WidgetMenuItem extends Panel {
    private homeWidget: HomeWidget;
    private homeEditor: HomePageEditor;
    private panelTitle: Panel;
    private panelExpand: Panel;
    private expandButton: Button;
    private labelTitle: Label;
    private panelAddWidgetImage: Image;
    private panelAddWidget: Panel;
    private addWidgetOnClick: ClickListener = () => this.homeEditor.addWidget(this.homeWidget);

    constructor(homeEditor: HomePageEditor, data: any) {
        super({ fillRow: true, padding: 0 });
        this.homeEditor = homeEditor;
        this._getWidgetLayout(data.layout_name).then(widget => {
            this.homeWidget = widget;
            this.panelExpand = new Panel({ fillRow: true, color: "subtle.darker", paddingBottom: 12 });
            this.panelTitle = new Panel({ fillRow: true, color: "subtle.darker", paddingBottom: 0, onClick: () => this.expandClicked() });
            this.labelTitle = new Label({ caption: this.homeWidget.layout.title, padding: 0, allowSelect: false, fillRow: true, rowBreak: false });
            this.expandButton = new Button({
                variant: ButtonVariant.round, imageName: "chevron",
                imageRotation: 90,
                padding: 0,
                onClick: () => this.expandClicked()
            });
            this.panelExpand.collapseNow();
            this.panelTitle.add(this.labelTitle, this.expandButton);
            this.populateExpandPanel();
            this.add(this.panelTitle);
            this.add(this.panelExpand);
            this.homeEditor.syncMenu();
        })
    }

    private _getWidgetLayout(layoutName: string): Promise<HomeWidget> {
        let homeWidget = this.homeEditor.getWidgetFromGrid(layoutName);
        if (homeWidget != null)
            return Promise.resolve(homeWidget);
        return new Promise((resolve) => {
            homeWidget = new HomeWidget({ homeEditor: this.homeEditor, layoutName });
            homeWidget.layout.addLayoutLoadListener(() => resolve(homeWidget));
        });
    }

    expandClicked(): void {
        const collapsed = this.expandButton.imageRotation == 90;
        this.panelExpand.toggleCollapsed();
        this.expandButton.imageRotation = collapsed ? 0 : 90;
    }

    populateExpandPanel() {
        const widgetSize = this.homeWidget.size;
        const addWidth = widgetSize.width * 32;
        const addHeight = widgetSize.height * 32;
        const size = new Label({ caption: this.homeWidget.layout.homePageSize, fontSize: "small", fontBold: true, fillRow: true, color: "subtle" });
        this.panelAddWidgetImage = new Image({ name: "add", height: 24, width: 24, color: "primary" });
        this.panelAddWidget = new Panel({
            height: addHeight, width: addWidth,
            borderWidth: 1, borderColor: "subtle", borderRadius: 4,
            style: { display: "flex", alignItems: "center", justifyContent: "center" },
            components: [this.panelAddWidgetImage]
        });
        const panelWrapper = new Panel({ components: [size, this.panelAddWidget] });
        this.panelExpand.add(panelWrapper)
    }

    public get widgetAdded(): boolean {
        return this.homeEditor.getWidgetFromGrid(this.homeWidget?.layoutName) != null;
    }

    syncMenuItem() {
        if (this.panelAddWidgetImage != null) {
            if (this.widgetAdded) {
                this.panelAddWidgetImage.removeClickListener(this.addWidgetOnClick);
                this.panelAddWidgetImage.name = "circleCheckEmpty";
                this.panelAddWidgetImage.color = "success";
                this.labelTitle.color = "subtle.darker";
            } else {
                this.panelAddWidgetImage.addClickListener(this.addWidgetOnClick);
                this.panelAddWidgetImage.name = "add";
                this.panelAddWidgetImage.color = "primary";
                this.labelTitle.color = "primary";
            }
        }
    }
}
