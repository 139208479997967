import { DataSource, Label, Layout } from "@mcleod/components";

export abstract class AutogenLayoutEDIOrderProfileQuickInfo extends Layout {
    labelAltPartnerID: Label;
    labelLabel2: Label;
    labelLabel4: Label;
    labelPartnerID: Label;
    labelVersion: Label;
    sourceEdiorderProfile: DataSource;
}
