import { Component } from "@mcleod/components";
import { ObjectUtil } from "@mcleod/core";
import { McLeodTailor } from "../custom/McLeodTailor";
import { DesignerAction } from "./DesignerAction";
import { DesignerActionResult } from "./DesignerActionResult";

interface ComponentInfo {
    component: Component,
    baseProps: any,
    undoProps: any
}

export class ActionApplyBaseProps implements DesignerAction {
    private designer: McLeodTailor;
    private component: Component;
    private undoProps: any;

    constructor(designer: McLeodTailor, component: Component) {
        this.designer = designer;
        this.component = component;
    }

    execute(): DesignerActionResult {
        if (ObjectUtil.isEmptyObject(this.component.baseVersionProps))
            return { success: false };
        this.undoProps = {};
        for (const key in this.component.baseVersionProps) {
            if (key in this.component.baseVersionProps) {
                this.undoProps[key] = this.component[key];
                this.component[key] = this.component.baseVersionProps[key];
                this.designer.doAfterPropChanged(this.component, key, this.undoProps[key], this.component[key], true)
            }
            if (ObjectUtil.isEmptyObject(this.component.baseVersionProps))
                break;
        }
        return { success: true };
    }

    undo() {
        for (const key in this.undoProps) {
            const oldValue = this.component[key];
            this.designer.doBeforePropChanged(this.component, key);
            this.component[key] = this.undoProps[key];
            this.designer.doAfterPropChanged(this.component, key, oldValue, this.component[key], true)
        }
    }

    toString() {
        return "Remove all modifications to selected base components";
    }
}
