import { getUnauthSettings } from "@mcleod/core";
import { AutogenLayoutCommodityList } from "./autogen/AutogenLayoutCommodityList";

export class CommodityList extends AutogenLayoutCommodityList {
    override onLoad() {
        this.showLTLColumns();
    }

    showLTLColumns() {
        if (getUnauthSettings()?.company_settings?.["is_brokerage_ltl"] == false) {
            this.removeColumn("nmfcClassCode");
            this.removeColumn("nmfcCode");
        }
    }

    private removeColumn(columnId: String) {
        for (const column of this.tableCommodity.columns) {
            if (column?.cellDef?.def?.id === columnId) {
                this.tableCommodity.removeColumn(column.index); return;
            }
        }
    }
}
