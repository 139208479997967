/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelOtherChargeEdi.ts                *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/datafusion/other-charge-edi";

export class AutogenModelOtherChargeEdi<RowType extends ModelRow> extends Endpoint<RowType, RowOtherChargeEdiFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowOtherChargeEdi extends ModelRow<RowOtherChargeEdiFields> {
    constructor(values?: Partial<RowOtherChargeEdiFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowOtherChargeEdiFields {
    allocated_charge_key: string;
    allocation_method: string;
    amount: number;
    amount_c: string;
    amount_d: Date;
    amount_n: number;
    amount_r: number;
    bill_type: string;
    calc_method: string;
    charge_id: string;
    company_id: string;
    currency_iso_id: string;
    customer_id: string;
    ded_charge_customer_id: string;
    descr: string;
    direction: string;
    driver_id: string;
    edi_charge_code: string;
    empty_distance_units: number;
    empty_distance_units_um: string;
    est_fuel_surcharge: string;
    id: string;
    incl_in_freight: string;
    invoice_id: string;
    is_dedicated_distance: string;
    is_taxable: string;
    jag_rate_id: number;
    loaded_distance_units: number;
    loaded_distance_units_um: string;
    ltl_allocation_method: string;
    maximum_units: number;
    minimum_units: number;
    order_id: string;
    order_oc_id: string;
    rate: number;
    rate_id: number;
    revenue_share_order_id: string;
    sequence: number;
    stop_id: string;
    stop_loc_name: string;
    stop_no: string;
    tli_uid: number;
    units: number;
}

