import { Button, DataSource, HorizontalSpacer, Layout, Table } from "@mcleod/components";
import { QuickSellDetails } from "../../../dispatch/src/QuickSellDetails";

export abstract class AutogenLayoutOfferOrder extends Layout {
    buttonBlastAllOffers: Button;
    horizontalspacer1: HorizontalSpacer;
    layoutQuickSellDetails: QuickSellDetails;
    sourceCarrierOfferGenerator: DataSource;
    sourceMovement: DataSource;
    tableOffers: Table;
}
