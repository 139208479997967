import { CommonTooltips } from "@mcleod/common";
import { Component, Label, TableRowDisplayEvent } from "@mcleod/components";
import { DisplayType, ModelRow } from "@mcleod/core";
import { AutogenLayoutHandlingRequirementsList } from "./autogen/AutogenLayoutHandlingRequirementsList";

export class HandlingRequirementsList extends AutogenLayoutHandlingRequirementsList {
    override onLoad() {
        this.mainDataSource.search({});
    }

    /** This is an event handler for the onRowDisplay event of tableHandlingRequirements.  */
    tableHandlingRequirementsOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        const modelRow = event.getTableRow().data as ModelRow;
        const labelGlDesc = tableRow.findComponentById("labelGlDesc") as Label;
        this.setTooltipCallbacks(labelGlDesc, modelRow);
    }

    setTooltipCallbacks(component: Component, modelRow: ModelRow) {
        if (component.id == null) return;
        switch (component.id) {
            case "labelGlDesc":
                return CommonTooltips.setPanelTooltipCallback(component, () => [
                    { caption: "Code", displayType: DisplayType.INTEGER, text: modelRow.get("gla_uid") }
                ]);
        }
    }
}
