import { BlurEvent, DataSource, DataSourceMode, DropdownItem, ValidationResult } from "@mcleod/components";
import { TextboxValidator } from "@mcleod/components/src/components/textbox/TextboxValidator";
import { Api, DatePart, DateUtil, ModelRow, StringUtil } from "@mcleod/core";
import { ModelServicefail } from "./models/ModelServicefail";
import { AutogenLayoutClearStop } from "./autogen/AutogenLayoutClearStop";

export class ClearStop extends AutogenLayoutClearStop {

    private serviceIncident: ModelRow;
    private lastServiceFailureFilter = null;
    private SERVICE_INCIDENT_CAUSED_TYPE = [{ caption: "Unknown", value: "N" }, { caption: "System User", value: "U" },
    { caption: "Other Employee", value: "E" }, { caption: "Carrier", value: "C" }];

    calculateEta: (dataSource: DataSource, filter: any) => void;
    private _ordersCustomerId;
    public doAfterStopCleared: (ModelRow) => void;

    override onLoad() {
        this.filterStandardCodes();
        this.textboxActualArrival.addlValidationCallback = () => this.arrivalDateValidator();
        this.textboxActualDeparture.addlValidationCallback = () => this.departureDateValidator();
    }
    textboxActualDepartureOnBlur(event: BlurEvent) {
        const stopRow = this.sourceCallinStop.activeRow;
        if (stopRow && !StringUtil.isEmptyString(stopRow.get("actual_departure"))) {
            if (StringUtil.isEmptyString(stopRow.get("actual_arrival"))) {
                stopRow.set("actual_arrival", stopRow.get("actual_departure"));
                this.textboxActualArrival.displayData(stopRow, null, 0);
                this.checkForServiceIncident();
            }


            let nextStop = null;
            if (this.sourceCallinStop?.activeRow?.get("next_stop"))
                nextStop = new ModelRow("lme/dispatch/callin-stop", false, this.sourceCallinStop.activeRow?.get("next_stop"));

            if (this.doAfterStopCleared != null)
                this.doAfterStopCleared(nextStop);

            this.calcNextStopEta();
        } else {
            if (this.doAfterStopCleared != null)
                this.doAfterStopCleared(null);
        }
    }

    calcNextStopEta() {
        if (this.sourceCallin.activeRow?.data && this.calculateEta != null) {
            const cityId = this.sourceCallinStop.activeRow?.get("city_id");
            const moveId = this.sourceDispatch.activeRow?.get("id") || this.sourceCallinStop.activeRow?.get("movement_id");
            const stopId = this.sourceCallinStop.activeRow?.get("id");
            const depart = this.sourceCallinStop.activeRow?.get("actual_departure");
            const filter = {
                ...this.sourceCallin.activeRow?.data, calc_miles_eta: true,
                callin_date_time: depart, movement_id: moveId, stop_id: stopId, city_id: cityId
            };
            this.calculateEta(this.sourceCallin, filter);
        }
    }

    textboxActualArrivalOnBlur(event: BlurEvent) {
        this.checkForServiceIncident();
    }

    arrivalDateValidator(): ValidationResult {
        if (!StringUtil.isEmptyString(this.textboxActualArrival.text)) {
            const arrivalDate = DateUtil.parseDateWithKeywords(this.textboxActualArrival.text, true, true);
            const prev_cleared_stop_date = new Date(this.sourceDispatch.activeRow?.get("prev_cleared_stop_date"));
            const prevDiff = DateUtil.dateDiff(DatePart.SECOND, arrivalDate, prev_cleared_stop_date);

            if (prevDiff < 0) {
                const validDate = TextboxValidator.validate(this.textboxActualArrival, true, true, null);
                this.textboxActualArrival.text = validDate.isValid ? validDate.validatedValue : this.textboxActualArrival.text;
                return { component: this.textboxActualArrival, isValid: false, validationMessage: "This stop cannot be cleared earlier than the previous stop of " + DateUtil.formatDateTime(prev_cleared_stop_date) };
            }
        }

        return null;
    }

    departureDateValidator(): ValidationResult {
        if (!StringUtil.isEmptyString(this.textboxActualArrival.text) && !StringUtil.isEmptyString(this.textboxActualDeparture.text)) {
            const arrivalDate = DateUtil.parseDateWithKeywords(this.textboxActualArrival.text, true, true);
            const departureDate = DateUtil.parseDateWithKeywords(this.textboxActualDeparture.text, true, true);
            const dayDiff = DateUtil.dateDiff(DatePart.SECOND, departureDate, arrivalDate);

            if (dayDiff < 0) {
                const validDate = TextboxValidator.validate(this.textboxActualDeparture, true, true, null);
                this.textboxActualDeparture.text = validDate.isValid ? validDate.validatedValue : this.textboxActualDeparture.text;
                return { component: this.textboxActualDeparture, isValid: false, validationMessage: "The actual departure of the stop cannot be earlier than the actual arrival." };
            }
        }

        return null;
    }

    checkForServiceIncident(): Promise<any> {
        const filter = {
            stop_id: this.sourceCallinStop.activeRow?.get("id"),
            actual_arrival: this.sourceCallinStop.activeRow?.get("actual_arrival")
        };

        if (this.serviceIncident == null && filter != this.lastServiceFailureFilter) {
            this.lastServiceFailureFilter = filter;
            return Api.search("lme/dispatch/check-service-failure", filter).then(response => {
                const row = response?.data?.[0];
                this.panelServiceIncident.visible = row["is_late"];
                if (this.panelServiceIncident.visible)
                    this.sourceServicefail.parentDataSource = this.sourceCallinStop;
                else
                    this.sourceServicefail.parentDataSource = new DataSource(); // just so we won't post it
            });
        }
        return Promise.resolve();
    }

    sourceCallinStopOnDisplay(event) {
        this.textboxCausedByType.items = this.SERVICE_INCIDENT_CAUSED_TYPE;
        this.sourceServicefail.mode = DataSourceMode.ADD;
        this.textboxCausedByType.items.forEach((item: DropdownItem, index: Number, array: DropdownItem[]) => {
            if (item.value == "C") {
                this.textboxCausedByType.selectedItem = item;
            }
        });
        this.checkboxFaultOfCarrierOrDriver.checked = true;

        if (this.serviceIncident != null) {
            new ModelServicefail().searchSingle({ stop_id: this.sourceCallinStop.activeRow?.get("id") }).then((row) => {
                this.serviceIncident = row;
            });
        }
    }

    filterStandardCodes() {
        this.textboxEdiStandardCode.addBeforeLookupModelSearchListener(event => {
            event.filter.customer_id = this.ordersCustomerId;
            event.filter.code_type = (StringUtil.isEmptyString(this.ordersCustomerId)) ? "1651" : "DelayReason";
            event.filter.use_enabled_flag = "Y";
        })
    }

    public set ordersCustomerId(id: String) {
        this._ordersCustomerId = id;
    }

    public get ordersCustomerId(): String {
        return this._ordersCustomerId;
    }
    configureForArrivalDepartEdits(showNextStopEta: boolean) {
        if (!showNextStopEta)
            this.calculateEta = null;
        this.panelCallinTemps.visible = false;
        this.panelCallin.visible = false;
        this.panelApptTime.rowBreak = true;
        this.textboxActualArrival.rowBreak = true;
        this.textboxActualArrival.marginTop = 8;
        this.textboxActualDeparture.marginTop = 8;
        this.textboxActualDeparture.marginBottom = 12;

    }

    textboxEdiStandardCodeOnBlur(event: BlurEvent) {
        if (this.sourceServicefail.activeRow) {
            const ediVersion = this.sourceServicefail.activeRow?.get("edi_code_version");
            if (StringUtil.isEmptyString(ediVersion)) {
                this.sourceServicefail.activeRow.set("edi_code_version", "004010");
            }

            const ediElementId = this.sourceServicefail.activeRow?.get("edi_element_id");
            if (StringUtil.isEmptyString(ediElementId)) {
                this.sourceServicefail.activeRow.set("edi_element_id", "1651");
            }
        }
    }

    bindCallinDataSource(callin: DataSource) {
        this.sourceCallin.rebindComponentsTo(callin);
        callin.attachListeners(this.sourceCallin);
        this.sourceCallin = callin;
        this.layoutCallinScript.sourceCallin = this.sourceCallin;
        this.layoutCallinScript.sourceCallinStop = this.sourceCallinStop;
    }

}
