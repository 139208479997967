import { DataSource, Label, Layout, Panel } from "@mcleod/components";

export abstract class AutogenLayoutCommitmentAutoRating extends Layout {
    labelAccepted: Label;
    labelAcceptedMonth: Label;
    labelAcceptedQuarter: Label;
    labelAcceptedToday: Label;
    labelAcceptedWeek: Label;
    labelAcceptedYear: Label;
    labelAutoRateDetails: Label;
    labelAwardFrequencyCaption: Label;
    labelAwardVolume: Label;
    labelAwardVolumeCaption: Label;
    labelCommitmentId: Label;
    labelCommitmentIdCaption: Label;
    labelDescr: Label;
    labelFrequency: Label;
    labelLaneCommitmentDetails: Label;
    labelLaneDescriptionCaption: Label;
    labelMonth: Label;
    labelOffered: Label;
    labelOfferedMonth: Label;
    labelOfferedQuarter: Label;
    labelOfferedToday: Label;
    labelOfferedWeek: Label;
    labelOfferedYear: Label;
    labelQuarter: Label;
    labelRate: Label;
    labelRateCaption: Label;
    labelRateDescription: Label;
    labelRateMethodCaption: Label;
    labelRateNumber: Label;
    labelRateNumberCaption: Label;
    labelRejected: Label;
    labelRejectedMonth: Label;
    labelRejectedQuarter: Label;
    labelRejectedToday: Label;
    labelRejectedWeek: Label;
    labelRejectedYear: Label;
    labelRemainingNeeded: Label;
    labelRemainingNeededCaption: Label;
    labelTimesUsed: Label;
    labelToday: Label;
    labelWeekToDate: Label;
    labelYear: Label;
    panel4: Panel;
    panel5: Panel;
    panelAutoRateDetails: Panel;
    panelAwardFrequency: Panel;
    panelAwardVolume: Panel;
    panelCommitmentHistory: Panel;
    panelCommitmentId: Panel;
    panelLaneCommitmentDetails: Panel;
    panelMonthRow: Panel;
    panelQuarterRow: Panel;
    panelRate: Panel;
    panelRateNumber: Panel;
    panelRemainingNeeded: Panel;
    panelRowHeaders: Panel;
    panelRows: Panel;
    panelTodayRow: Panel;
    panelWeekToDateRow: Panel;
    panelYearRow: Panel;
    sourceCommitmentAutoRating: DataSource;
}
