import { Button } from "@mcleod/components";
import { Api, PermissionsUtil, getAuthSettings } from "@mcleod/core";
import { AssignedMovementOverview } from "./AssignedMovementOverview";
import { ElectronicAdvances } from "./ElectronicAdvances";

export interface ElectronicAdvancesSlideoutProps {
    payeeId: string, payeeName: string;
}
export class PayAdvance {

    public static validateMovement(movementOverview: AssignedMovementOverview) {
        movementOverview.buttonClassicAdvances.visible = false;
        const sourceMovement = movementOverview.sourceMovement;
        const buttonClassicAdvances = movementOverview.buttonClassicAdvances;
        const hasDailyAdvanceLimits = getAuthSettings().user_settings.daily_advance_limits !== "N";
        // In Permission Manager if User or Group has permission it will return 'false' else return 'true'
        const userHasExplicitPermission = !PermissionsUtil.isUserDeniedAction("Dispatch.Override wire limits");

        Api.search('common/perms-secure-item').then(async response => {
            const isWireLimitSecured = response.data.find(permission => permission.item_name === "Dispatch.Override wire limits") !== undefined;

            if (isWireLimitSecured && !hasDailyAdvanceLimits && !userHasExplicitPermission) {
                buttonClassicAdvances.visible = true;
                disableButtonClassicAdvances(buttonClassicAdvances, "User is not allowed to issue wires");
            }
            else if (!isWireLimitSecured || (isWireLimitSecured && (hasDailyAdvanceLimits || userHasExplicitPermission))) {
                buttonClassicAdvances.visible = true;
                if (!sourceMovement?.activeRow.getBoolean("drs_payee.pay_advances", false)) {
                    disableButtonClassicAdvances(buttonClassicAdvances, `${sourceMovement?.activeRow.get("payee.name")} is not allowed advances`);
                } else {
                    if (!(isWireLimitSecured && userHasExplicitPermission)) {
                        const errorMsg = await checkLimitNotExceeded(buttonClassicAdvances);
                        if (errorMsg != null)
                            disableButtonClassicAdvances(buttonClassicAdvances, errorMsg);
                    }

                    if (buttonClassicAdvances.enabled) {
                        // Commented out code below... not sure if we need these as to actually open the classic screen, but the weren't being used
                        // const className = "com.tms.client.loadmaster.dsp.EntryWire";
                        // const movementId = sourceMovement.activeRow.get("id");
                        // const screenParams = `<ext> add |movement_id=-${movementId}-${sourceMovement?.activeRow.get("payee.id")}-PowerBrokerWeb`;
                        buttonClassicAdvances.addClickListener(() => {
                            ElectronicAdvances.showEditRowDecorator(sourceMovement.activeRow.get("id"), () => movementOverview.setCarrierRateData())
                        });
                    }
                }
            }
        });
    }
}

function checkLimitNotExceeded(buttonClassicAdvances: Button): Promise<string> {
    return new Promise((resolve) => {
        Api.post("lme/dispatch/validate-issued-limit",
            {
                user_id: getAuthSettings().user_settings.user_id,
            })
            .then((result) => resolve(result?.data[0]?.exceed_message));
    })
}

function disableButtonClassicAdvances(button: Button, tooltip: string) {
    button.setProps({ tooltip: tooltip, imageName: "moneyInHandDisabled", enabled: false })
    button._imageUpdated();
}

