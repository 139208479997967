/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelHazmatPlacard.ts                 *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/dispatch/hazmat-placard";

export class AutogenModelHazmatPlacard<RowType extends ModelRow> extends Endpoint<RowType, RowHazmatPlacardFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowHazmatPlacard extends ModelRow<RowHazmatPlacardFields> {
    constructor(values?: Partial<RowHazmatPlacardFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowHazmatPlacardFields {
    company_id: string;
    descr: string;
    id: string;
}

