import { SingleFieldFilter } from "./SingleFieldFilter";

export class TableRowSearchUtil {
    static parseFilterValue(filterValue: string): SingleFieldFilter[] {
        const result = [];
        const singleFieldFilterValues = filterValue.split("+");
        for (const singleFieldFilterValue of singleFieldFilterValues) {
            let fieldNameOrAlias = null;
            let searchValue = singleFieldFilterValue;
            let allValues: string[] = [singleFieldFilterValue];
            const equalPos = singleFieldFilterValue.indexOf("=");
            if (equalPos > 0) {
                fieldNameOrAlias = singleFieldFilterValue.substring(0, equalPos);
                searchValue = singleFieldFilterValue.substring(equalPos + 1);
                allValues = searchValue.split("|");
            }
            result.push({ fieldNameOrAlias: fieldNameOrAlias, searchValues: allValues })
        }
        return result;
    }

    static getOverallResult(filters: SingleFieldFilter[]): boolean {
        for (const filter of filters) {
            if (filter.result !== true)
                return false;
        }
        return true;
    }

    static getSimpleFieldName(fieldName: string) {
        let simpleFieldName = fieldName;
        const fieldNameDotPos = simpleFieldName.indexOf(".");
        if (fieldNameDotPos > 0)
            simpleFieldName = simpleFieldName.substring(fieldNameDotPos + 1);
        return simpleFieldName;
    }
}
