import { CallinScript } from "../../../powerbroker/src/CallinScript";
import { Checkbox, CityState, DataSource, Image, Label, Layout, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutClearStop extends Layout {
	checkboxFaultOfCarrierOrDriver: Checkbox;
	checkboxIsReportable: Checkbox;
	citystateLocation: CityState;
	image1: Image;
	labelSchedEarly: Label;
	labelSchedLate: Label;
	labelServiceIncidentReport: Label;
	layoutCallinScript: CallinScript;
	lblApptCaption: Label;
	lblLocCaption: Label;
	lblLocName: Label;
	panel1: Panel;
	panel2: Panel;
	panel8: Panel;
	panel9: Panel;
	panelApptTime: Panel;
	panelArrivalDeparture: Panel;
	panelArrivalDepartureBoxes: Panel;
	panelCallin: Panel;
	panelCallinTemps: Panel;
	panelCurrentLoc: Panel;
	panelServiceIncident: Panel;
	sourceCallin: DataSource;
	sourceCallinStop: DataSource;
	sourceDispatch: DataSource;
	sourceServicefail: DataSource;
	tbCallinType: Textbox;
	tbPostedNextSchedCall: Textbox;
	tbRemark: Textbox;
	tbSetpoint: Textbox;
	tbTemperature: Textbox;
	textboxActualArrival: Textbox;
	textboxActualDeparture: Textbox;
	textboxCausedByType: Textbox;
	textboxDspComment: Textbox;
	textboxEdiStandardCode: Textbox;
}
