import { DropdownItem } from "@mcleod/components";
import { AutogenLayoutOrderImages } from "./autogen/AutogenLayoutOrderImages";

interface DocumentType {
    id: string,
    description: string
}

export class OrderImages extends AutogenLayoutOrderImages {
    private validFileExtensions = [".tiff", ".pdf", ".png", ".jpg", ".gif", ".bmp"] as string[];

    override onLoad() {
        this.addLayoutDataLoadListener(event => {
            this.attachmentImage["setAttachmentWithUI"] = (file: File) => {
                const maxSize = this.attachmentImage.maxSize;
                const fileName = file.name.toLowerCase();
                const fileExtension = fileName.substring(fileName.lastIndexOf("."));
                if (!this.validFileExtensions.includes(fileExtension))
                    return this.showTooltip("The file must be an image.", { shaking: true, timeout: 5000 });
                else if (maxSize != null && file.size > maxSize)
                    return this.showTooltip("The file is too big to attach. The file must be smaller than " + this.attachmentImage.maxSize / (1024 * 1024) + "MB.", { shaking: true, timeout: 5000 });
                else {
                    this.attachmentImage.setFile(file);
                    if (this.textboxImageTypes.text && this.textboxImageTypes.selectedItem) {
                        this.buttonSave.enabled = true;
                        this.buttonSave.backgroundColor = "primary";
                        this.buttonSaveAndClose.enabled = true;
                        this.buttonSaveAndClose.backgroundColor = "primary";
                    }
                }
            }

            this.attachmentImage["removeAttachment"] = () => {
                if (this.attachmentImage.enabled === true) {
                    this.attachmentImage.fileName = null;
                    this.attachmentImage.fileBase64 = null;
                    this.attachmentImage.updateIfBound();
                    this.attachmentImage._syncDisplay();
                    this.buttonSave.enabled = false;
                    this.buttonSave.backgroundColor = "primary.lightest";
                    this.buttonSaveAndClose.enabled = false;
                    this.buttonSaveAndClose.backgroundColor = "primary.lightest";
                }
            }

            this.textboxImageTypes.addChangeListener(event => {
                if (this.attachmentImage.fileBase64 && this.imageTypeIsValid()) {
                    this.buttonSave.enabled = true;
                    this.buttonSave.backgroundColor = "primary";
                    this.buttonSaveAndClose.enabled = true;
                    this.buttonSaveAndClose.backgroundColor = "primary";
                }
                else {
                    this.buttonSave.enabled = false;
                    this.buttonSave.backgroundColor = "primary.lightest";
                    this.buttonSaveAndClose.enabled = false;
                    this.buttonSaveAndClose.backgroundColor = "primary.lightest";
                }
            });

            this.buttonSave.enabled = false;
            this.buttonSave.backgroundColor = "primary.lightest";
            this.buttonSaveAndClose.enabled = false;
            this.buttonSaveAndClose.backgroundColor = "primary.lightest";
        });
    }

    createDropdownItems(documentTypes: DocumentType[]) {
        const items: DropdownItem[] = [];
        for (const type of documentTypes) {
            items.push({ caption: type.description, value: type.id });
        }

        this.textboxImageTypes.items = items;
    }

    imageTypeIsValid(): boolean {
        const textboxType = this.textboxImageTypes;
        return (textboxType.text && textboxType.selectedItem != null);
    }

    clearInputFields() {
        this.attachmentImage.removeAttachment();
        this.textboxImageTypes.text = null;
        this.textboxImageTypes.selectedItem = null;
    }

    createJSONStringForPOST(orderId: string): string {
        const filter = [
            {
                image: this.attachmentImage?.fileBase64,
                image_type_id: this.textboxImageTypes?.selectedItem?.value,
                row_id: orderId
            }
        ];
        return JSON.stringify(filter);
    }
}
