import { Button, CityState, DataSource, Label, Layout, Panel, Switch, Tab, Tabset, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutOfferManagement extends Layout {
    buttonCarrierRate: Button;
    buttonEquipmentRequired: Button;
    citystateConsigneeStopCityId: CityState;
    citystateOriginStopCityId: CityState;
    citystateTractorCityId: CityState;
    labelDistCounter: Label;
    labelDistDifference: Label;
    labelDistOffer: Label;
    labelHeaderConsigneeName: Textbox;
    labelHeaderConsigneeStopSchedArriveEarly: Textbox;
    labelHeaderOrder: Textbox;
    labelHeaderOriginStopSchedArriveEarly: Textbox;
    labelHeaderShipperAddress: Label;
    labelHeaderShipperName: Textbox;
    labelMarginCounter: Label;
    labelMarginDifference: Label;
    labelMarginOffer: Label;
    labelMaxPay: Textbox;
    labelPayDist: Label;
    labelPercentage: Label;
    labelPercentageCounter: Label;
    labelPercentageDifference: Label;
    labelPercentageOffer: Label;
    labelTargetPay: Textbox;
    labelTemperatureRange: Label;
    labelTotalCharges: Textbox;
    lblMarginHeader: Label;
    panel10: Panel;
    panel11: Panel;
    panel12: Panel;
    panel13: Panel;
    panel14: Panel;
    panel15: Panel;
    panel16: Panel;
    panel17: Panel;
    panel18: Panel;
    panel1: Panel;
    panel2: Panel;
    panel3: Panel;
    panel4: Panel;
    panel5: Panel;
    panel6: Panel;
    panel7: Panel;
    panel8: Panel;
    panel9: Panel;
    panelDimensionSurcharge: Panel;
    panelFinanceCounter: Panel;
    panelFinanceDifference: Panel;
    panelFinanceOffer: Panel;
    panelGeneral1: Panel;
    panelGeneral2: Panel;
    panelGeneral3: Panel;
    panelGeneral4: Panel;
    panelGeneral5: Panel;
    panelGeneral: Panel;
    panelHeader: Panel;
    panelHeaderConsignee: Panel;
    panelHeaderConsigneeDate: Panel;
    panelHeaderDate: Panel;
    panelHeaderOrder: Panel;
    panelHeaderShipper: Panel;
    panelOrderMoveSearch: Panel;
    sourceOfferManagement: DataSource;
    switchHighValue: Switch;
    switchRoundTrip: Switch;
    switchShowDetails: Switch;
    switchTeam: Switch;
    switchWaterfall: Switch;
    tabAdditional: Tab;
    tabCarrier: Tab;
    tabFinancial: Tab;
    tabGeneral: Tab;
    tabsetCarrierOffer: Tabset;
    textCarrierContactPhone: Textbox;
    textCarrierMobilePhone: Textbox;
    textEmptyMilesToOrigin: Textbox;
    textEmptyTractorTrailerType: Textbox;
    textOfferSequence: Textbox;
    textOfferStatus: Textbox;
    textOfferType: Textbox;
    textboxCarrierOfferAmount: Textbox;
    textboxCarrierOfferCarrierRanking: Textbox;
    textboxCarrierOfferCarrierRateId: Textbox;
    textboxCarrierOfferComments: Textbox;
    textboxCarrierOfferCounterOffer: Textbox;
    textboxCarrierOfferDeclineReason: Textbox;
    textboxCarrierOfferDotNumber: Textbox;
    textboxCarrierOfferEmail: Textbox;
    textboxCarrierOfferEnteredUserId: Textbox;
    textboxCarrierOfferIccNumber: Textbox;
    textboxCarrierOfferModifiedDate: Textbox;
    textboxCarrierOfferOfferDate: Textbox;
    textboxCarrierOfferPayeeId: Textbox;
    textboxCarrierOfferRoutingGuideProfileId: Textbox;
    textboxCarrierOfferSalespersonId: Textbox;
    textboxCarrierOfferSource: Textbox;
    textboxCommitmentsMet: Textbox;
    textboxCommitmentsOffered: Textbox;
    textboxCommitmentsPriority: Textbox;
    textboxCommitmentsTotal: Textbox;
    textboxCommodityId: Textbox;
    textboxConsigneeStopAddress: Textbox;
    textboxDifference: Textbox;
    textboxMovementId: Textbox;
    textboxOrderId: Textbox;
    textboxOrdersDimHeight: Textbox;
    textboxOrdersDimLength: Textbox;
    textboxOrdersDimSurcharge: Textbox;
    textboxOrdersDimWidth: Textbox;
    textboxOrdersEquipmentTypeId: Textbox;
    textboxOrdersHazmatCode: Textbox;
    textboxOrdersOrderValue: Textbox;
    textboxOrdersTemperatureMax: Textbox;
    textboxOrdersTemperatureMin: Textbox;
    textboxOrdersTractorType: Textbox;
    textboxOrdersWeight: Textbox;
    texttCarrierContact: Textbox;
}
