import { SingleComponentDemo } from "./SingleComponentDemo";

export class DemoSwitch extends SingleComponentDemo {
    constructor() {
        super();
        this.propsForAllExamples = { caption: "Switch demo" };
        this.examples = {
            ...SingleComponentDemo.getComponentDemoExamples(),

            printable: {
                cases: [
                    { value: true, description: "This example shows how a printable Switch is displayed." },
                    { value: false, description: "This Switch is normal." }
                ]
            }
        }
    }
}
