import { Button, DataSource, HorizontalSpacer, Label, Layout, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutCarrierQuickInfo extends Layout {
    buttonCancel: Button;
    buttonCarrierDetail: Button;
    horizontalspacer1: HorizontalSpacer;
    labelPendingCarrier: Label;
    panelCarrierInfo: Panel;
    panelQuickInfo: Panel;
    sourceCarriersForAssignment: DataSource;
    textboxDotNumber: Textbox;
    textboxIccNumber: Textbox;
    textboxIntrastateCode: Textbox;
    textboxName: Textbox;
    textboxPhoneNumber: Textbox;
}
