import { AutogenLayoutEdiRefNumber } from "./autogen/AutogenLayoutEdiRefNumber";

export class EdiRefNumber extends AutogenLayoutEdiRefNumber {

    private stopID: String;

    override onLoad() {
        this.stopID = this.owner.expandComponent.stopID;
        this.sourceEdiRefNumber.search({ stop_id: this.stopID });
    }
}
