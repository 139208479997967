/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelMmsMedia.ts                 *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/powerbroker/ModelMmsMedia";

export class AutogenModelMmsMedia<RowType extends ModelRow> extends Endpoint<RowType, RowModelMmsMediaFields> {
	constructor(ctor: RowConstructor) {
		super(endpointPath, ctor);
	}
}

export class AutogenRowModelMmsMedia extends ModelRow<RowModelMmsMediaFields> {
	constructor(values?: Partial<RowModelMmsMediaFields>) {
		super(endpointPath, true, values);
	}
}

export interface RowModelMmsMediaFields {
	"mms_media.company_id": string;
	"mms_media.id": string;
	"mms_media.media": string;
	"mms_media.media_name": string;
	"mms_media.media_type": string;
	"mms_media.parent_row_id": string;
}

