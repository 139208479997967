import { HorizontalAlignment } from "@mcleod/core";
import { Button } from "../components/button/Button";
import { Panel } from "../components/panel/Panel";

export class PanelOkCancel extends Panel {
    okButton: Button;
    cancelButton: Button;

    constructor(props?) {
        super({ id: "ok-cancel", fillRow: true, align: HorizontalAlignment.CENTER, ...props });
        if (props == null)
            props = {};
        this.cancelButton = new Button({
            cancel: true,
            caption: "Cancel",
            imageName: "x",
            rowBreak: false,
            marginRight: 16,
            width: 100,
            ...props.cancelButtonProps
        });
        if (props.onCancel != null)
            this.cancelButton.addClickListener(() => props.onCancel());

        this.okButton = new Button({
            caption: "OK",
            default: true,
            imageName: "check",
            backgroundColor: "primary",
            color: "primary.reverse",
            width: 100,
            marginRight: 16,
            ...props.okButtonProps
        });
        if (props.onOk != null)
            this.okButton.addClickListener(() => props.onOk());
        this.add(this.cancelButton);
        this.add(this.okButton);
    }
}
