import { InboundTenderList } from "../../../datafusion/src/InboundTenderList";
import { OutboundTenderList } from "../../../datafusion/src/OutboundTenderList";
import { Button, DataSource, Label, Layout, Tab, Table, Tabset } from "@mcleod/components";

export abstract class AutogenLayoutShipmentStatusSlideout extends Layout {
	buttonInboundClassic: Button;
	buttonOutboundClassic: Button;
	labelInTenders: Label;
	labelInboundShipmentStatus: Label;
	labelOutTenders: Label;
	labelOutboundShipmentStatus: Label;
	layout1: InboundTenderList;
	layout2: OutboundTenderList;
	sourceDelayCode: DataSource;
	sourceEdiCustomer: DataSource;
	sourceEdiMapError: DataSource;
	sourceEdiMovementCarrier: DataSource;
	sourceEdiOrder: DataSource;
	sourceOrderEdistatus: DataSource;
	tabInbound: Tab;
	tabInboundTender: Tab;
	tabOutbound: Tab;
	tabOutboundTender: Tab;
	tableInbound: Table;
	tableOutbound: Table;
	tabset1: Tabset;
}
