/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelCallin.ts                        *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/dispatch/callin";

export class AutogenModelCallin<RowType extends ModelRow> extends Endpoint<RowType, RowCallinFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowCallin extends ModelRow<RowCallinFields> {
    constructor(values?: Partial<RowCallinFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowCallinFields {
    call_date_time: Date;
    callin_script_details: string;
    callin_script_results: string;
    callin_script_template: string;
    callin_template_search_details: string;
    city_id: number;
    city_name: string;
    company_id: string;
    current_stop_id: string;
    driver_id: string;
    entered_by: string;
    entered_by_user_name: string;
    entered_date: Date;
    equipment_group_id: string;
    gps_accuracy: number;
    id: string;
    initiated_type: string;
    latitude: number;
    longitude: number;
    miles2consignee: number;
    miles2consignee_um: string;
    movement_id: string;
    movement_status: string;
    name: string;
    new_eta: Date;
    new_eta_time_string: string;
    next_stop_avg_speed: number;
    next_stop_city_id: number;
    next_stop_city_name: string;
    next_stop_state: string;
    next_stop_time_left: number;
    next_stop_zip_code: string;
    order_id: string;
    payee_id: string;
    remark: string;
    sched_date_time: Date;
    setpoint_temp: number;
    setpoint_temp_um: string;
    state: string;
    temperature: number;
    temperature_um: string;
    tractor_id: string;
    type_description: string;
    zip_code: string;
    calc_miles_eta: boolean;
    callin_date_time: string;
    callin_script_save_data: string;
    event: string;
    include_callin_script_details: boolean;
    include_positions: boolean;
    new_row: boolean;
    newest_first: boolean;
    next_sched_call: Date;
    remark_with_script_remarks: string;
    stop_id: string;
}

