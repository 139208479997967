import {
    BlurEvent, Button, ChangeEvent, ClickEvent, Component, CrudDecorator, DataDisplayEvent, DataSourceMode,
    DataSourceModeChangeEvent, Layout, LookupModelSearchEvent, SaveButton, Textbox, Toast
} from "@mcleod/components";
import { SaveAction } from "@mcleod/components/src/components/savebutton/SaveButton";
import { ModelRow, Navigation, StringUtil } from "@mcleod/core";
import { makeTooltipCallbackFunction } from "@mcleod/dispatch/src/CustomerMakeQuickInfo";
import { OrderPostHist } from "@mcleod/dispatch/src/OrderPostHist";
import { Orders } from "@mcleod/dispatch/src/Orders";
import { UsersResponsibilitySlideoutTrans } from "@mcleod/general/src/UsersResponsibilitySlideoutTrans";
import { QuickQuote } from "./QuickQuote";
import { QuoteSendEmail } from "./QuoteSendEmail";
import { AutogenLayoutQuoteLanding } from "./autogen/AutogenLayoutQuoteLanding";

export const quoteTooltips = {
    convertToOrderDisabledTooltipStatus : "This quote must be approved to convert it to an order.\r\n",
    convertToOrderDisabledTooltipExpirationBlank : "The expiration must be specified.\r\n",
    convertToOrderDisabledTooltipExpirationBadDate : "The expiration must be a valid date.\r\n",
    convertToOrderDisabledTooltipQuoteExpired: "This quote is expired.\r\n",
} as const;

export class QuoteLanding extends AutogenLayoutQuoteLanding {
    public nextAction: () => void = () => { };
    public nextActionButton: Button = null;
    public saveButton: SaveButton = null;
    private convertToOrderDisabledTooltip: string = "Quote must be approved to convert to order";

    override onLoad() {
        this.panelNextActionsUpdate.visible = !this.isNested;
        if (this.isNested) {
            this.panelGroupAndDates.marginTop = 35;
            this.panelGroupAndDates.width = "35vw";
        }
        this.addLayoutDataLoadListener(() => {
            if (this["open_email_slideout"]) {
                this.buttonEmailQuote.clicked();
            }
        });
        this.setupQuoteStopTable();
    }

    textboxCustomerNameOnBlur(event: BlurEvent) {
        const textbox = event.target as Textbox;
        const lookupModelData = textbox.getFirstLookupModelData();
        if (lookupModelData?.get("id") == lookupModelData?.get("name")) {
            this.activeRow.set("customer_id", null);
        }
        else {
            this.activeRow.set("customer_id", lookupModelData?.get("id"));
        }
        this.setCustomerNameTooltip();
    }

    textboxQuoteStatusOnChange(event: ChangeEvent) {
        if (this.buttonConvertToOrderNextAction == this.nextActionButton) {
            this.setNextActionProps(this.buttonConvertToOrderNextAction, false);
            this.nextActionButton = null;
            this.nextAction = null;
        }
        this.buttonConvertToOrderNextAction.enabled = event.newValue === "Approved";
        this.buttonConvertToOrderNextAction.disabledTooltip = this.convertToOrderDisabledTooltip;
    }


    static checkEnableConvertToOrder(convertToOrderComp: Component, expirationDate: Date, quoteStatus: string) {
        let toolTipFailure: string = "";
        let success: boolean = true;

        if (StringUtil.isEmptyString(expirationDate)) {
            success = false;
            toolTipFailure += quoteTooltips.convertToOrderDisabledTooltipExpirationBlank;
        }

        if (expirationDate.valueOf() - Date.now() < 0) {
            success = false;
            toolTipFailure += quoteTooltips.convertToOrderDisabledTooltipQuoteExpired;
        }

        if (quoteStatus !== "A" ) {
            success = false;
            toolTipFailure += quoteTooltips.convertToOrderDisabledTooltipStatus;
        }

        convertToOrderComp.enabled = success;
        convertToOrderComp.disabledTooltip = toolTipFailure;
    }

    textboxCustomerNameOnDataDisplay(event: DataDisplayEvent) {
        this.setCustomerNameTooltip();
    }

    textboxCustomerNameBeforeLookupModelSearch(event: LookupModelSearchEvent) {
        event.filter.is_customer = "Y";
    }

    textboxContactNameBeforeLookupModelSearch(event) {
        const contactName = event.target as Textbox;
        event.filter.parent_row_id = this.activeRow.get("customer_id") ?? null;
        event.filter.parent_row_type = "C";
        event.filter.is_active = "Y";

        if (contactName != null && contactName.onSelectItem == null) {
            contactName.onSelectItem = ((textbox, selection) => {
                if (selection != null) {
                    this.activeRow.set("phone", (selection as ModelRow).get("phone"));
                    this.activeRow.set("email", (selection as ModelRow).get("email"));
                    this.textboxCustomerPhone.text = (selection as ModelRow).get("phone");
                    this.textboxCustomerEmail.text = (selection as ModelRow).get("email");
                }
                return undefined;
            });
        }
    }

    // START - Commodity Code
    /** This is an event handler for the onChange event of textboxCommodityId.  */
    textboxCommodityIdOnChange(event) {
        this.updateCommodityCode();
    }

    private updateCommodityCode() {
        const commodityCleared = this.activeRow.isNull("commodity_id");
        const lookupData = commodityCleared ? null : this.textboxCommodityId.getFirstLookupModelData();
        this.activeRow.set("commodity", lookupData?.get("descr"));
        this.showHazmatWarning();
    }

    private showHazmatWarning() {
        const lookupData = this.textboxCommodityId.getFirstLookupModelData();
        const hazmat = lookupData?.get("is_hazmat");
        this.textboxCommodityId.imagePreName = hazmat === "Y" ? "warning" : null;
        if (this.textboxCommodityId.imagePreName)
            this.textboxCommodityId.imagePre.color = "error";
    }
    // END - Commodity Code

    setCustomerNameTooltip() {
        const lookupModelData = this.textboxCustomerName.getFirstLookupModelData();
        this.textboxCustomerName.tooltipCallback = makeTooltipCallbackFunction(lookupModelData?.get("id"), this.textboxCustomerName);
    }

    private setupQuoteStopTable() {
        this.layoutQuoteStopTable.orderSource = this.mainDataSource;
    }

    sourceQuoteOrderBeforeModeChange(event) {
        if (event.newMode === DataSourceMode.UPDATE) {
            this.title = "Quote Number " + this.mainDataSource.activeRow?.get("id");
        }
    }

    sourceQuoteOrderAfterExecution(event) {
        if (this.activeRow) {
            this.buttonConvertToOrder.enabled = this.activeRow.get("quote_status") === "A";
            this.showHazmatWarning();
            
            QuoteLanding.checkEnableConvertToOrder(this.buttonConvertToOrder, new Date(this.activeRow.get("expiration_date")), this.activeRow.get("quote_status"));
            if (this.buttonConvertToOrderNextAction.visible) {
                QuoteLanding.checkEnableConvertToOrder(this.buttonConvertToOrderNextAction, new Date(this.activeRow.get("expiration_date")), this.activeRow.get("quote_status"));
            }

            if (this.owner instanceof QuickQuote && this.saveButton) {
                this.handleNextActionsQuickQuote();
            }
        }
    }

    sourceQuoteOrderAfterModeChange(event: DataSourceModeChangeEvent) {
        this.textboxWeight.addBlurListener(event => { if (event.changedWhileFocused && this.textboxWeight.validateSimple()) this.layoutQuoteRates.calculateRates(false, false) });
        this.layoutQuoteRates.sourceOrderOtherChargeQuote.mode = DataSourceMode.UPDATE;
    }

    sendQuoteEmail() {
        QuoteSendEmail.show({
            sourceQuote: this.mainDataSource,
            doOnSlideoutClosed: (cancelled: boolean) => {
                if (!cancelled) {
                    Toast.showToast("Quote sent");
                }
            }
        });
    }

    private handleNextActionsQuickQuote() {
        if (this.saveButton.saveAction === SaveAction.SAVE_AND_CLOSE) {
            if (this.nextActionButton) {
                this.nextAction();
            }
            else {
                this.owner.slideOut();
            }
        }
        else if (this.saveButton.saveAction === SaveAction.SAVE) {
            if (this.nextActionButton) {
                this.nextAction();
            }
            else
                Navigation.navigateTo("lme/powerbroker/QuoteLanding?mode=update&key=" + this.activeRow.get("id"));
        }
        this.mainDataSource.mode = DataSourceMode.UPDATE;
    }

    // START - Next Actions for Quote Landing Update Workflow
    buttonEmailQuoteOnClick(event: ClickEvent) {
        this.sendQuoteEmail();
    }

    buttonConvertToSpotOnClick(event: ClickEvent) {
        const layout = Layout.getLayout("lme/dispatch/Orders") as Orders;
        layout["quote_id"] = this.mainDataSource.activeRow.get("id");
        layout["create_spot"] = true;
        const crudDecorator = new CrudDecorator({
            layout: layout,
            mode: DataSourceMode.ADD,
            headerProps: {
                showClose: true,
                showSaveAndClose: true,
                onClose: () => {
                    Navigation.navigateBack();
                }
            },
        })
        Navigation.pseudoNavigateTo("lme/dispatch/Orders", () => crudDecorator.slideOut()).then(() => {
            crudDecorator.slideIn({ speed: 200 });
        });
    }

    buttonConvertToOrderOnClick(event: ClickEvent) {
        const layout = Layout.getLayout("lme/dispatch/Orders") as Orders;
        layout["quote_id"] = this.mainDataSource.activeRow.get("id");
        const crudDecorator = new CrudDecorator({
            layout: layout,
            mode: DataSourceMode.ADD,
            headerProps: {
                showClose: true,
                showSaveAndClose: true,
                onClose: () => {
                    Navigation.navigateBack();
                }
            }
        })
        Navigation.pseudoNavigateTo("lme/dispatch/Orders", () => crudDecorator.slideOut()).then(() => {
            crudDecorator.slideIn({ speed: 200 });
        });
    }
    // END - Next Actions for Quote Landing Update Workflow

    // START - Next Action For Quick Quote Add Workflow
    private postOrderActionButtonClick(event: ClickEvent) {
        const button = event.target as Button;
        button.borderWidth = 1;
        this.panelNextActionButtons.forEveryChildComponent((component) => {
            if (component instanceof Button && component.enabled) {
                if (button === component) {
                    if (button === this.nextActionButton) {
                        this.setNextActionProps(component, false);
                        this.nextActionButton = null;
                    }
                    else {
                        this.setNextActionProps(component, true);
                        this.nextActionButton = button;
                    }
                }
                else {
                    this.setNextActionProps(component, false);
                }
            }
        });
        this.mainDataSource.notifyHasChangedComponents(!!this.nextActionButton);
    }

    setNextActionProps(button: Button, isClicked: boolean) {
        button.color = isClicked ? "primary.reverse" : undefined;
        button.backgroundColor = isClicked ? "primary" : undefined;
        button.borderColor = isClicked ? "primary" : undefined;
        button.zIndex = isClicked ? 10 : 20;
    }

    buttonEmailNextActionOnClick(event: ClickEvent) {
        this.postOrderActionButtonClick(event);
        this.nextAction = () => Navigation.navigateTo("lme/powerbroker/QuoteLanding?mode=update&key=" + this.activeRow.get("id"), { newTab: false }, { open_email_slideout: true });
    }

    buttonConvertToOrderNextActionOnClick(event: ClickEvent) {
        this.postOrderActionButtonClick(event);
        this.nextAction = () => Navigation.navigateTo("lme/dispatch/Orders", { newTab: false }, { quote_id: this.mainDataSource.activeRow.get("id") });
    }

    buttonConvertToSpotNextActionOnClick(event: ClickEvent) {
        this.postOrderActionButtonClick(event);
        this.nextAction = () => Navigation.navigateTo("lme/dispatch/Orders", { newTab: false }, { quote_id: this.mainDataSource.activeRow.get("id"), create_spot: true });
    }
    // END - Next Action For Quick Quote Add Workflow

    // START - USERS Slideout
    /** This is an event handler for the onClick event of buttonUsers.  */
    buttonUsersOnClick(event: ClickEvent) {
        this.displayQuoteUsersSlideout();
    }

    displayQuoteUsersSlideout() {
        const urs = new UsersResponsibilitySlideoutTrans("lme/powerbroker/QuoteUsers", this.getQuoteUsersSlideoutTitle(), this.getQuoteUsersIdFilter(),
            this.getQuoteUsersSharedFieldNames(), this.sourceQuoteOrder, this.sourceResponsibleHist);
        urs.show();
    }

    private getQuoteUsersSlideoutTitle(): string {
        const name = this.sourceQuoteOrder.activeRow?.get("id");
        return "Quote Users" + ((StringUtil.isEmptyString(name) === false) ? (" - " + name) : "");
    }

    private getQuoteUsersIdFilter(): any {
        const id = this.sourceQuoteOrder.activeRow?.get("id");
        if (id != null)
            return { id: id };
        return null;
    }

    private getQuoteUsersSharedFieldNames(): string[] {
        return ["operations_user", "agent_payee_id", "entered_user_id", "billing_user_id"];
    }
    // END - USERS Slideout

    /** This is an event handler for the onClick event of buttonQuoteHistory.  */
    buttonQuoteHistoryOnClick(event: ClickEvent) {
        OrderPostHist.showInSlideout(
            "QUOTE",
            this.mainDataSource.activeRow.get("id"),
            null,
            DataSourceMode.UPDATE, this.buttonQuoteHistory);
    }
}
