import { Label, Table, TableRow, TableRowDisplayEvent } from "@mcleod/components";
import { Api, ModelRow, StringUtil } from "@mcleod/core";
import { AutogenLayoutLoadboardUsersDat } from "./autogen/AutogenLayoutLoadboardUsersDat";

export class LoadboardUsersDat extends AutogenLayoutLoadboardUsersDat {

    onLoad(): void | Promise<void> {
        this.tablePbwUserDat.doOnRowDelete = (row: TableRow) => {
            Api.delete("lme/general/pbw-loadboard-users", { users: [row.data], service_type: "C" })
        }
    }

    loadboardUsersDatOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        const modelRow: ModelRow = tableRow.data;
        const labelDatStatus = tableRow.findComponentById("labelDatStatus") as Label;
        if (tableRow !== this.tablePbwUserDat.quickAddRow) {
            const datError = modelRow.get("dat_error", null);
            if (StringUtil.isEmptyString(datError)) {
                labelDatStatus.imageName = "circleCheck";
                labelDatStatus.imageColor = "success";
            } else {
                labelDatStatus.imageName = "circleX";
                labelDatStatus.imageColor = "error";
                labelDatStatus.tooltip = datError;
            }
        }
    }

    textboxDatPbwUserIdBeforeLookupModelSearch(event) {

        event.filter.loadboard_dat_id = null;
    }

    static isRowExistsForSameUser(newRowUserId: string, table: Table) {
        return table.rows
            .map(row => (row.data as ModelRow).get("user_id"))
            .find(existingRowUserId => StringUtil.equalsIgnoreCase(existingRowUserId, newRowUserId)) == null;
    }
}
