/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelLocation.ts                      *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/dispatch/location";

export class AutogenModelLocation<RowType extends ModelRow> extends Endpoint<RowType, RowLocationFields> {
	constructor(ctor: RowConstructor) {
		super(endpointPath, ctor);
	}
}

export class AutogenRowLocation extends ModelRow<RowLocationFields> {
	constructor(values?: Partial<RowLocationFields>) {
		super(endpointPath, true, values);
	}
}

export interface RowLocationFields {
	address1: string;
	address2: string;
	appt_required: string;
	assigned_to: string;
	bseg: string;
	category: string;
	cbsa_port_id: string;
	chassis_owner: string;
	chassis_pool: string;
	city_id: number;
	city_name: string;
	class_rqmt: string;
	close_time: string;
	comments: string;
	company_id: string;
	"contact.comments": string;
	"contact.company_id": string;
	"contact.contact_name": string;
	"contact.customer_sequence": number;
	"contact.detention_contact": string;
	"contact.ect_uid": number;
	"contact.email": string;
	"contact.fax": string;
	"contact.id": string;
	"contact.is_active": string;
	"contact.is_sms_opted_in": string;
	"contact.mobile_phone": string;
	"contact.name": string;
	"contact.opt_out_date": Date;
	"contact.parent_row_id": string;
	"contact.parent_row_type": string;
	"contact.payable_contact": string;
	"contact.personal_ref": string;
	"contact.phone": string;
	"contact.rapid_alert_notify": string;
	"contact.rec_interval": number;
	"contact.rec_next_date": Date;
	"contact.rec_ops_user_id": string;
	"contact.rec_salesperson_id": string;
	"contact.sequence": number;
	"contact.terms_contact": string;
	"contact.title": string;
	"contact.type_of": string;
	"contact.web_access": string;
	container_pool: string;
	container_vendor: string;
	cust_id: string;
	"customer.activity_count": number;
	"customer.activity_start_date": Date;
	"customer.add_empty_distance_occ": string;
	"customer.add_finance_charge": string;
	"customer.address1": string;
	"customer.address2": string;
	"customer.allow_inc_in_frt": string;
	"customer.allow_quick_dispatch": string;
	"customer.auto_rate": string;
	"customer.available_template": number;
	"customer.average_bill": number;
	"customer.average_bill_c": string;
	"customer.average_bill_d": Date;
	"customer.average_bill_n": number;
	"customer.average_bill_r": number;
	"customer.average_pay_days": number;
	"customer.balance": number;
	"customer.balance_c": string;
	"customer.balance_d": Date;
	"customer.balance_n": number;
	"customer.balance_r": number;
	"customer.benchmark_ctg_uid": number;
	"customer.benchmark_tar_uid": number;
	"customer.bill_due_days": number;
	"customer.bill_format_flag": string;
	"customer.bill_template": number;
	"customer.bill_transfer_when": string;
	"customer.billed_loads": number;
	"customer.bl_logo": string;
	"customer.bl_logo_name": string;
	"customer.blanket_buy_tariff_id": string;
	"customer.blanket_sell_tariff_id": string;
	"customer.bridge_id": string;
	"customer.brokerage_filter": string;
	"customer.business_hours": string;
	"customer.business_number": string;
	"customer.buy_tariff_id": string;
	"customer.category": string;
	"customer.cert_of_ins_date": Date;
	"customer.charge_detention": string;
	"customer.chassis_chargeby": string;
	"customer.chassis_freedays": number;
	"customer.chassis_rate": number;
	"customer.chassis_rate_c": string;
	"customer.chassis_rate_d": Date;
	"customer.chassis_rate_n": number;
	"customer.chassis_rate_r": number;
	"customer.city": string;
	"customer.city_id": number;
	"customer.code": string;
	"customer.coll_thresh_pd1": number;
	"customer.coll_thresh_pd1_c": string;
	"customer.coll_thresh_pd1_d": Date;
	"customer.coll_thresh_pd1_n": number;
	"customer.coll_thresh_pd1_r": number;
	"customer.coll_thresh_pd2": number;
	"customer.coll_thresh_pd2_c": string;
	"customer.coll_thresh_pd2_d": Date;
	"customer.coll_thresh_pd2_n": number;
	"customer.coll_thresh_pd2_r": number;
	"customer.coll_thresh_pd3": number;
	"customer.coll_thresh_pd3_c": string;
	"customer.coll_thresh_pd3_d": Date;
	"customer.coll_thresh_pd3_n": number;
	"customer.coll_thresh_pd3_r": number;
	"customer.coll_thresh_pd4": number;
	"customer.coll_thresh_pd4_c": string;
	"customer.coll_thresh_pd4_d": Date;
	"customer.coll_thresh_pd4_n": number;
	"customer.coll_thresh_pd4_r": number;
	"customer.collections_id": string;
	"customer.collections_ok": string;
	"customer.company_id": string;
	"customer.confidence_level": number;
	"customer.container_chargeby": string;
	"customer.container_freedays": number;
	"customer.container_rate": number;
	"customer.container_rate_c": string;
	"customer.container_rate_d": Date;
	"customer.container_rate_n": number;
	"customer.container_rate_r": number;
	"customer.contract_cbc": string;
	"customer.contract_ccc": string;
	"customer.contract_gbc": string;
	"customer.contract_gcc": string;
	"customer.contract_sbc": string;
	"customer.contract_scc": string;
	"customer.controlling_carrier_code": string;
	"customer.conversion_date": Date;
	"customer.cost_ctg_uid": number;
	"customer.cost_tar_uid": number;
	"customer.covered_template": number;
	"customer.cp_logo": string;
	"customer.cp_logo_name": string;
	"customer.credit_application": string;
	"customer.credit_check_date": Date;
	"customer.credit_limit": number;
	"customer.credit_limit_c": string;
	"customer.credit_limit_d": Date;
	"customer.credit_limit_n": number;
	"customer.credit_limit_r": number;
	"customer.credit_status": string;
	"customer.credit_warning_pct": number;
	"customer.ctg_uid": number;
	"customer.currency_type": string;
	"customer.cust_requestor_load_id": string;
	"customer.cust_stmt_template": number;
	"customer.d_and_b_code": string;
	"customer.d_and_b_date": Date;
	"customer.d_and_b_number": string;
	"customer.days_between_calls": number;
	"customer.deadhead_target": number;
	"customer.ded_last_fixed_cost_billdt": Date;
	"customer.dedicated_cycle": string;
	"customer.dedicated_revenue_code_id": string;
	"customer.dedicated_seg_alloc_code": string;
	"customer.dedicated_start_day": string;
	"customer.dedicated_template": number;
	"customer.def_rate_confirm_temp": number;
	"customer.delivered_template": number;
	"customer.departure_date_at": string;
	"customer.disable_automatic_tracking": string;
	"customer.display_eta": string;
	"customer.display_ysplit_stops": string;
	"customer.distance_method": string;
	"customer.distance_profile": string;
	"customer.distance_source": string;
	"customer.distance_type": string;
	"customer.dot_number": string;
	"customer.driver_load": string;
	"customer.empty_distance_charge_id": string;
	"customer.empty_distance_rate": number;
	"customer.enable_notifications": string;
	"customer.enforce_dep_dates": string;
	"customer.entered_date": Date;
	"customer.excise_taxable": string;
	"customer.expected_close": Date;
	"customer.extend_rates": string;
	"customer.factor_percent": number;
	"customer.factor_receivables": string;
	"customer.factoring_id": string;
	"customer.fak_profile_id": string;
	"customer.fourkites_customer_id": string;
	"customer.fuel_amount": number;
	"customer.fuel_base_price": number;
	"customer.fuel_credit": string;
	"customer.fuel_method": string;
	"customer.fuel_percent": number;
	"customer.fuel_price_region": string;
	"customer.fuel_surcharge_id": string;
	"customer.fuel_surchg_enddt": Date;
	"customer.fuel_surchg_strtdt": Date;
	"customer.fuel_variance": number;
	"customer.global_detention": string;
	"customer.google_place_id": string;
	"customer.gp_exclude": string;
	"customer.gp_exported": Date;
	"customer.grace_period": number;
	"customer.high_balance": number;
	"customer.high_balance_c": string;
	"customer.high_balance_d": Date;
	"customer.high_balance_n": number;
	"customer.high_balance_r": number;
	"customer.ic_company_id": string;
	"customer.id": string;
	"customer.image_indicator": number;
	"customer.include_by_date": string;
	"customer.include_empty_distance": string;
	"customer.include_fuel_surcharge": string;
	"customer.include_split_point": string;
	"customer.intercompany": string;
	"customer.intercompanyintercompany": string;
	"customer.is_active": string;
	"customer.is_ask_dispatcher": string;
	"customer.is_consignee": string;
	"customer.is_distribution_center": string;
	"customer.is_drop_yard": string;
	"customer.is_geocoded": string;
	"customer.is_intercompany": string;
	"customer.is_pallets_required": string;
	"customer.is_shipper": string;
	"customer.is_trailer_pool_required": string;
	"customer.jaguar_id": number;
	"customer.last_bill_date": Date;
	"customer.last_fin_charge_dt": Date;
	"customer.last_pay_date": Date;
	"customer.last_ship_date": Date;
	"customer.latitude": number;
	"customer.level_ref_dup": string;
	"customer.location_id": string;
	"customer.longitude": number;
	"customer.macropoint_requestor_id": string;
	"customer.main_phone": string;
	"customer.manual_exchange": string;
	"customer.mark_plan_id": string;
	"customer.max_pay_percent": number;
	"customer.misc_bill_template": number;
	"customer.monthly_order_goal": number;
	"customer.motor_carrier_id": string;
	"customer.name": string;
	"customer.next_call_date": Date;
	"customer.next_rec_call": Date;
	"customer.nxt_action_code_id": string;
	"customer.nxt_action_date": Date;
	"customer.operations_user": string;
	"customer.order_count": number;
	"customer.order_detail_at": string;
	"customer.order_start_date": Date;
	"customer.ovrride_dupl_cref": string;
	"customer.p44_profile_id": string;
	"customer.paid_loads": number;
	"customer.pay_days_date": Date;
	"customer.pay_days_orders": number;
	"customer.payee_id": string;
	"customer.pd0_alert": number;
	"customer.pd1_np_auto": string;
	"customer.pd1_sp_auto": string;
	"customer.pd1_sp_thresh": number;
	"customer.pd1_sp_thresh_c": string;
	"customer.pd1_sp_thresh_d": Date;
	"customer.pd1_sp_thresh_n": number;
	"customer.pd1_sp_thresh_r": number;
	"customer.pd2_np_auto": string;
	"customer.pd2_sp_auto": string;
	"customer.pd2_sp_thresh": number;
	"customer.pd2_sp_thresh_c": string;
	"customer.pd2_sp_thresh_d": Date;
	"customer.pd2_sp_thresh_n": number;
	"customer.pd2_sp_thresh_r": number;
	"customer.pd3_np_auto": string;
	"customer.pd3_sp_auto": string;
	"customer.pd3_sp_thresh": number;
	"customer.pd3_sp_thresh_c": string;
	"customer.pd3_sp_thresh_d": Date;
	"customer.pd3_sp_thresh_n": number;
	"customer.pd3_sp_thresh_r": number;
	"customer.pd4_np_auto": string;
	"customer.pd4_sp_auto": string;
	"customer.pd4_sp_thresh": number;
	"customer.pd4_sp_thresh_c": string;
	"customer.pd4_sp_thresh_d": Date;
	"customer.pd4_sp_thresh_n": number;
	"customer.pd4_sp_thresh_r": number;
	"customer.plc_uid": number;
	"customer.post_summary_dtl": string;
	"customer.potential": number;
	"customer.potential_c": string;
	"customer.potential_d": Date;
	"customer.potential_n": number;
	"customer.potential_r": number;
	"customer.prev_code": string;
	"customer.primary_agency": string;
	"customer.primary_commodity_id": string;
	"customer.primary_contact": string;
	"customer.primary_sort": string;
	"customer.primary_trailer_type": string;
	"customer.print_containers": string;
	"customer.progress_template": number;
	"customer.prospect_type_id": string;
	"customer.pu_ticket_type": string;
	"customer.quebec_number": string;
	"customer.quote_days_to_expire": number;
	"customer.rate_override_nmfc_code": string;
	"customer.reassign_profile_id": string;
	"customer.rec_call_days": number;
	"customer.remarks": string;
	"customer.require_tracking": string;
	"customer.required_vendor": string;
	"customer.revenue": number;
	"customer.revenue_c": string;
	"customer.revenue_d": Date;
	"customer.revenue_n": number;
	"customer.revenue_r": number;
	"customer.revenue_start_date": Date;
	"customer.routing_guide_profile_id": string;
	"customer.sales_manager_id": string;
	"customer.sales_status_id": string;
	"customer.salesperson_id": string;
	"customer.search_city": string;
	"customer.search_state_id": string;
	"customer.search_zip_code": string;
	"customer.secondary_sort": string;
	"customer.sell_tariff_id": string;
	"customer.service_fail_cons": string;
	"customer.service_fail_drop": string;
	"customer.service_fail_pick": string;
	"customer.service_fail_ship": string;
	"customer.service_failures": string;
	"customer.service_failures_review": string;
	"customer.shipment_ident_type": string;
	"customer.sic_code": string;
	"customer.start_date": Date;
	"customer.state_id": string;
	"customer.summary_billing": string;
	"customer.summary_template": number;
	"customer.tar_uid": number;
	"customer.target_profit": number;
	"customer.tenfour_customer_id": string;
	"customer.tracking_hours_prior": number;
	"customer.trimble_place_id": string;
	"customer.type_of": string;
	"customer.use_local_mile": string;
	"customer.vm_part_update": string;
	"customer.waterfall_auto_route_guides": string;
	"customer.waterfall_lead_time": number;
	"customer.waterfall_notify_email": string;
	"customer.waterfall_process": string;
	"customer.website_url": string;
	"customer.wf_auto_renbill": string;
	"customer.wf_autobill": string;
	"customer.wf_bwop": string;
	"customer.wf_queue": number;
	"customer.zip_code": string;
	"customer.zone_id": string;
	customer_id: string;
	days_between_calls: number;
	ded_rqmt_type: string;
	ded_type: string;
	def_commodity_desc: string;
	def_commodity_id: string;
	delivery_date_option: string;
	directions: string;
	disable_loc_rating: string;
	dl_gen_cost: number;
	dl_gen_repower: number;
	dl_haz_cost: number;
	dl_haz_repower: number;
	dl_hvl_cost: number;
	dl_hvl_repower: number;
	dl_load_time: number;
	dl_svc_cost: number;
	dl_svc_repower: number;
	dl_unload_time: number;
	driver_load_id: string;
	driver_unload_id: string;
	ds_capacity_cost: number;
	ds_model_flag: string;
	email_address: string;
	email_location: string;
	extra_handling_time: number;
	filer_code: string;
	find_near_distance: number;
	foreign_port: string;
	friday_close: Date;
	friday_open: Date;
	fuel_price_region: string;
	google_place_fm_address: string;
	google_place_id: string;
	google_place_type: string;
	id: string;
	include_xref_trailers: string;
	insur_amt_rqrd: number;
	insur_amt_rqrd_c: string;
	insur_amt_rqrd_d: Date;
	insur_amt_rqrd_n: number;
	insur_amt_rqrd_r: number;
	is_active: string;
	is_consignee: string;
	is_customer: string;
	is_customs_broker: string;
	is_dist_center: string;
	is_dl_gen: string;
	is_dl_haz: string;
	is_dl_hvl: string;
	is_dl_svc: string;
	is_drop_yard: string;
	is_geocoded: string;
	is_outside_terminal: string;
	is_prospect: string;
	is_remote_terminal: string;
	is_shipper: string;
	is_steamship: string;
	is_terminal: string;
	is_trailer_pool: string;
	is_trailer_wash: string;
	is_truckstop: string;
	last_used_date: Date;
	latitude: number;
	layover: string;
	lease_number: string;
	load_unload_count: number;
	load_unload_excl: string;
	load_unload_param: string;
	loading_instructs: string;
	loading_time: number;
	longitude: number;
	ltl_optimize_time_per_spot: number;
	ltl_seg_alloc_code: string;
	manual_exchange: string;
	max_drvr_complaint: number;
	max_pallet_balance: number;
	max_payment_code_amt: number;
	max_payment_code_amt_c: string;
	max_payment_code_amt_d: Date;
	max_payment_code_amt_n: number;
	max_payment_code_amt_r: number;
	model_flag: string;
	monday_close: Date;
	monday_open: Date;
	name: string;
	need_clean_trailer: string;
	new_field_ms: string;
	next_call_date: Date;
	no_fuel_radius_inbound: string;
	no_fuel_radius_outbound: number;
	no_stop_miles: number;
	on_duty_not_driving_mins: number;
	open_time: string;
	pallet_balance: number;
	pallets_required: string;
	payment_api_excluded: string;
	pickup_date_option: string;
	plc_uid: number;
	pnn_city_id: number;
	pnn_city_name: string;
	pnn_state: string;
	pnn_zip_code: string;
	port_id: string;
	prev_code: string;
	primary_agency: string;
	radius: number;
	regulated: string;
	reload_rqmt: string;
	reserve_mins: number;
	salesperson: string;
	saturday_close: Date;
	saturday_open: Date;
	shuttle_capacity: string;
	state: string;
	sunday_close: Date;
	sunday_open: Date;
	swap_cost: number;
	tank_operator: string;
	team_rqmt: string;
	thursday_close: Date;
	thursday_open: Date;
	trailer_pool_min: number;
	trailer_pool_radius: number;
	trailer_pool_size: number;
	trailer_wash_vend: string;
	trans_ticket: string;
	trimble_place_id: string;
	trip_starter: string;
	tuesday_close: Date;
	tuesday_open: Date;
	tw_seg_code: string;
	unload_instructs: string;
	unloading_time: number;
	website_url: string;
	wednesday_close: Date;
	wednesday_open: Date;
	worthless_field: string;
	zip_code: string;
	find_near_city_id: number;
	find_near_radius: number;
	updating_stop: boolean;
}

