import { Table } from "../..";
import { ComponentPropDefinitionUtil, ComponentPropDefinitions, ComponentProps } from "../../base/ComponentProps";
import { Cursor } from "../../base/Cursor";
import { PropType } from "../../base/PropType";
import { PanelPropDefinitions } from "../panel/PanelProps";
import { TableColumn } from "./TableColumn";
import { TableRow } from "./TableRow";

export interface TableCellProps extends ComponentProps {
    table: Table;
    col: TableColumn;
    row: TableRow;
    caption: string;
    isHeading: boolean;
}

let tableCellPropDefs: ComponentPropDefinitions;

export class TableCellPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (tableCellPropDefs == null) {
            tableCellPropDefs = {
                caption: { type: PropType.string, visibleInMcLeodTailor: true },
                format: { type: PropType.string, visibleInMcLeodTailor: true },
                ...PanelPropDefinitions.cloneDefs(),
                ...ComponentPropDefinitionUtil.getDataBoundPropertyDefinitions()
            };
            tableCellPropDefs.cursor.defaultValue = Cursor.POINTER;
            ComponentPropDefinitionUtil.populateComponentPropNames(tableCellPropDefs);
            delete tableCellPropDefs.rowBreak;
        }
        return tableCellPropDefs;
    }
}
