/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelWirecodesForUser.ts              *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/powerbroker/wirecodes-for-user";

export class AutogenModelWirecodesForUser<RowType extends ModelRow> extends Endpoint<RowType, RowWirecodesForUserFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowWirecodesForUser extends ModelRow<RowWirecodesForUserFields> {
    constructor(values?: Partial<RowWirecodesForUserFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowWirecodesForUserFields {
    allow_aft_xfer_settlement: string;
    ap_glid: string;
    carrier_limit_amount: number;
    carrier_limit_amount_c: string;
    carrier_limit_amount_d: Date;
    carrier_limit_amount_n: number;
    carrier_limit_amount_r: number;
    carrier_limit_method: string;
    carrier_order_pct_limit: number;
    carrier_valid_move_status: string;
    charge_code_id: string;
    company_id: string;
    company_limit_amount: number;
    company_limit_amount_c: string;
    company_limit_amount_d: Date;
    company_limit_amount_n: number;
    company_limit_amount_r: number;
    company_limit_method: string;
    company_order_pct_limit: number;
    company_valid_move_status: string;
    contract_carrier: number;
    contract_driver: number;
    contract_no_payee: number;
    contract_ownerop: number;
    create_deduction: string;
    create_other_charge: string;
    deduct_code_id: string;
    deduct_in_provider_currency: string;
    deduct_status: string;
    descr: string;
    driver_fee_amt: number;
    driver_fee_amt_c: string;
    driver_fee_amt_d: Date;
    driver_fee_amt_n: number;
    driver_fee_amt_r: number;
    expense_glid: string;
    fuel_interface_id: string;
    id: string;
    include_fee_exp: string;
    is_active: string;
    is_atm_cash: string;
    issuing_user_group: string;
    issuing_users: string;
    msts_addl_fee_percent: number;
    msts_addl_fee_threshold: number;
    msts_addl_fee_threshold_c: string;
    msts_addl_fee_threshold_d: Date;
    msts_addl_fee_threshold_n: number;
    msts_addl_fee_threshold_r: number;
    no_validation: string;
    ownerop_limit_amount: number;
    ownerop_limit_amount_c: string;
    ownerop_limit_amount_d: Date;
    ownerop_limit_amount_n: number;
    ownerop_limit_amount_r: number;
    ownerop_limit_method: string;
    ownerop_order_pct_limit: number;
    ownerop_valid_move_status: string;
    plus_minus: string;
    require_order: string;
    service_charge: number;
    service_charge_c: string;
    service_charge_d: Date;
    service_charge_n: number;
    service_charge_r: number;
    subaccount_carrier: string;
    subaccount_driver: string;
    subaccount_no_payee: string;
    subaccount_ownerop: string;
    unposted_wire_status: string;
    wire_type: string;
    movement_id: string;
}

