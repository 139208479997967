import { DataSourceMode, Label, Panel, TableRow, TableRowMode, Textbox } from "@mcleod/components";
import { ModelDataChangeType } from "@mcleod/components/src/databinding/DataSource";
import { Alignment, DisplayType, ModelRow } from "@mcleod/core";
import { AutogenLayoutOrderHandlingRequirements } from "./autogen/AutogenLayoutOrderHandlingRequirements";

const inputProps = { paddingTop: 0, rowBreak: false, captionVisibleInsideTable: true, marginRight: 8 };

export class OrderHandlingRequirements extends AutogenLayoutOrderHandlingRequirements {
    private hdrTableMap = new Map<number, TableRow>();
    hdrCallback: (row: ModelRow<any>, type: ModelDataChangeType) => void;

    async populateHdrs(hdrRows: ModelRow<any>[], clearRows: boolean = false, hasChanged: boolean = false) {
        const madeFromEdi = "Y" === this.sourceBrltlOrderHdrXFgp["madeFromEdi"];
        if (clearRows || madeFromEdi) {
            this.tableHdrs.clearRows();
            await this.populateTable(null, hdrRows, hasChanged||madeFromEdi);
        }
        this.labelNoneSelected.visible = false;
        this.tableHdrs.visible = true;
        if (hdrRows?.length == 0) {
            this.labelNoneSelected.visible = true//!this.enabled;
            this.tableHdrs.visible = false//!this.labelNoneSelected.visible;
        }
    }

    async populateTable(enabled?: boolean, hdrRows?: ModelRow<any>[], hasChanged: boolean = false): Promise<void> {
        hdrRows = hdrRows.sort((a, b) => a.get("hdr_title", "").localeCompare(b.get("hdr_title", ""))); // sort accessorials alphabetically
        if (enabled != null) this.enabled = enabled;

        this.sourceBrltlOrderHdrXFgp.mode = DataSourceMode.UPDATE;
            await this.sourceHandlingRequirements.search().then((response) => {
                response.modelRows.forEach(hdrDef => {
                    hdrRows.forEach(hdr => {
                        if (hdr?.data["hdr_uid"] == hdrDef?.data["hdr_uid"]) {
                            this.addHdrRow(hdrDef, hdr);
                        }
                    });
                });
            });
        return Promise.resolve();
    }

    addHdrRow(hdrDef: ModelRow<any>, hdrxfgp?: ModelRow<any>) {
        const values = hdrxfgp != null ? hdrxfgp.data : hdrDef.data["hdr_uid"];
        const modelRow = new ModelRow(this.sourceBrltlOrderHdrXFgp.url, true, values);
        const result = this.tableHdrs.addRow(modelRow, { mode: TableRowMode.ADD }, { display: true, addToData: true });
        if (result.row != null) {
            const row = result.row;
            const hdrTitleLabel = row.findComponentById("labelHdrTitle") as Label;
            hdrTitleLabel.caption = hdrDef.get("hdr_title");
            this.hdrTableMap.set(hdrDef.get("hdr_uid"), row);
            row["hdrDef"] = hdrDef;
            this.addInputTextboxes(hdrxfgp != null ? hdrxfgp : hdrDef, row.findComponentById("panelInput") as Panel, false);
        }
    }

    private addInputTextboxes(hdrDef: ModelRow<any>, panel: Panel, focusInput: boolean = true) {
        if (!hdrDef.isNull("text_title")) {
            const textPanel = new Panel({ rowBreak: false, width: 275 });
            const textTitle = new Textbox({ ...inputProps, enabled: this.enabled, field: "text_value", caption: hdrDef.get("text_title"), printable: true, captionAlignment: Alignment.LEFT, width: undefined });
            textTitle.displayData(hdrDef, null, 0);
            textPanel.add(textTitle)
            panel.add(textPanel);
        }
        if (!hdrDef.isNull("integer_title")) {
            const integerPanel = new Panel({ rowBreak: false, width: 300 });
            const integerTitle = new Textbox({ ...inputProps, enabled: this.enabled, field: "integer_value", displayType: DisplayType.INTEGER, caption: hdrDef.get("integer_title"), printable: true, captionAlignment: Alignment.LEFT, width: 275 });
            integerTitle.displayData(hdrDef, null, 0);
            integerPanel.add(integerTitle)
            panel.add(integerPanel);
        }
        if (!hdrDef.isNull("float_title")) {
            const floatPanel = new Panel({ rowBreak: false, width: 300 });
            const floatTitle = new Textbox({ ...inputProps, enabled: this.enabled, field: "float_value", displayType: DisplayType.DECIMAL, caption: hdrDef.get("float_title"), printable: true, captionAlignment: Alignment.LEFT, width: 275 });
            floatTitle.displayData(hdrDef, null, 0);
            floatPanel.add(floatTitle)
            panel.add(floatPanel);
        }
        if (!hdrDef.isNull("date_title")) {
            const datePanel = new Panel({ rowBreak: false, width: 300 });
            const dateTitle = new Textbox({ ...inputProps, enabled: this.enabled, field: "date_value", displayType: DisplayType.DATE, caption: hdrDef.get("date_title"), printable: true, captionAlignment: Alignment.LEFT, width: 275 });
            dateTitle.displayData(hdrDef, null, 0);
            datePanel.add(dateTitle)
            panel.add(datePanel);
        }
        if (!hdrDef.isNull("time_title")) {
            const timePanel = new Panel({ rowBreak: false, width: 300 });
            const timeTitle = new Textbox({ ...inputProps, enabled: this.enabled, field: "time_value", displayType: DisplayType.TIME, caption: hdrDef.get("time_title"), printable: true, captionAlignment: Alignment.LEFT, width: 275 });
            timeTitle.displayData(hdrDef, null, 0);
            timePanel.add(timeTitle)
            panel.add(timePanel);
        }
        if (!hdrDef.isNull("timestamp_title")) {
            const timestampPanel = new Panel({ rowBreak: false, width: 300 });
            const timestampTitle = new Textbox({ ...inputProps, enabled: this.enabled, field: "timestamp_value", displayType: DisplayType.DATETIME, caption: hdrDef.get("timestamp_title"), printable: true, captionAlignment: Alignment.LEFT, width: 275 });
            timestampTitle.displayData(hdrDef, null, 0);
            timestampPanel.add(timestampTitle)
            panel.add(timestampPanel);
        }

        if (panel.components.length > 0) {
            panel.visible = true;
            if (focusInput) {
                panel.components[0].focus();
                panel.components[0].scrollIntoView();
            }
        }
    }
}
