import { Button, DataSource, Label, Layout, Map, Panel, Switch, Table, Textbox } from "@mcleod/components";
import { DistanceCalculatorOverview } from "../DistanceCalculatorOverview";
import { RateIndexDataSummary } from "../RateIndexDataSummary";

export abstract class AutogenLayoutDistanceRateIndexCalculator extends Layout {
    buttonCalculate: Button;
    buttonFetchOtherResults: Button;
    clearStops: Button;
    labelDistanceCalculator: Label;
    labelMapinfo: Label;
    labelRateIndex: Label;
    layoutDistanceCalculationOverview: DistanceCalculatorOverview;
    layoutTotalRatesSummary: RateIndexDataSummary;
    mapRoute: Map;
    panelDisplayMap: Panel;
    panelDistanceCalculator: Panel;
    panelOverview: Panel;
    panelRateIndexConfig: Panel;
    panelTotalRatesSummary: Panel;
    sourceDistanceCalculator: DataSource;
    sourceDistanceControl: DataSource;
    sourceStops: DataSource;
    switchExcludeFuelSurcharge: Switch;
    tableStops: Table;
    textboxMarginPercentage: Textbox;
    textboxTrailerType: Textbox;
}
