import {
    BlurEvent,
    ChangeEvent,
    EditRowDecorator,
    Switch,
    TableRow,
    TableRowDisplayEvent,
    Textbox
} from "@mcleod/components";
import { AutogenLayoutTableDrivers } from "./autogen/AutogenLayoutTableDrivers";
import {StringUtil} from "@mcleod/core";

export class TableDrivers extends AutogenLayoutTableDrivers {

    onLoad() {
        this.tableDrivers.onEditLayoutLoaded = this.onEditLayoutLoadedDriver();
    }

    tableDriversOnRowDisplay(event: TableRowDisplayEvent) {
        event.getTableRow().canBeDeleted = false;
    }

    onEditLayoutLoadedDriver(): (rowDecorator: EditRowDecorator, tableRow: TableRow) => void {
        return (rowDecorator: EditRowDecorator, tableRow: TableRow) => {
            if (rowDecorator.btnDelete) {
                rowDecorator.btnDelete.visible = false;
            }
        };
    }

    switchSmsOptInOnChange(event: ChangeEvent){
        const today = new Date();
        const optInSwitch = event.target as Switch;
        const tableRow = TableRow.getContainingTableRow(optInSwitch);
        const textboxOptOutDate = tableRow.findComponentById("textboxOptOutDate") as Textbox;
        if(textboxOptOutDate !== undefined){
            if(optInSwitch.checked) {
                textboxOptOutDate.clear();
            }else{
                textboxOptOutDate.text = today.toLocaleDateString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric'
                });
            }
        }
    }

    textboxOptOutDateOnBlur(event: BlurEvent){
        const textboxOptOutDate = event.target as Textbox;
        const tableRow = TableRow.getContainingTableRow(textboxOptOutDate);
        const optInSwitch = tableRow.findComponentById("switchSMSOptIn") as Switch;
        if (!StringUtil.isEmptyString(textboxOptOutDate.text)) {
            const optOutDate = new Date(textboxOptOutDate.text);
            const currentDate = new Date();
            if (optOutDate <= currentDate) {
                optInSwitch.checked = false;
            }
        }
    }

}
