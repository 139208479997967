import { DatePart, DateUtil } from "@mcleod/core";
import { AutogenLayoutWidgetOrderSummaryByDeliveryDate } from "./autogen/AutogenLayoutWidgetOrderSummaryByDeliveryDate";

export class WidgetOrderSummaryByDeliveryDate extends AutogenLayoutWidgetOrderSummaryByDeliveryDate {
    override onLoad() {
        let dateStart = DateUtil.dateAdd(DatePart.DAY, new Date(), -400);
        dateStart = DateUtil.justDate(dateStart);
        this.sourceOrderSummaryByDeliveryDate.search({ "stop.actual_arrival.start": dateStart });
    }
}
