/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelStandardCode.ts                  *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/datafusion/standard-code";

export class AutogenModelStandardCode<RowType extends ModelRow> extends Endpoint<RowType, RowStandardCodeFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowStandardCode extends ModelRow<RowStandardCodeFields> {
    constructor(values?: Partial<RowStandardCodeFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowStandardCodeFields {
    company_id: string;
    description: string;
    element_id: string;
    standard_code: string;
    version: string;
    element_id: string;
    standard_code: string;
    version: string;
}

