import { Component, Container } from "@mcleod/components";
import { DesignerAction } from "./DesignerAction";
import { DesignerActionResult } from "./DesignerActionResult";

export class ActionDropWithinParent implements DesignerAction {
    droppedComponent: Component;
    droppedIndex: number;
    originalIndex: number;
    parent: Container;

    constructor(dropped: Component, droppedIndex: number) {
        this.droppedComponent = dropped;
        this.parent = this.droppedComponent.parent;
        this.originalIndex = this.parent.components.indexOf(dropped);
        this.droppedIndex = droppedIndex;
    }

    execute(): DesignerActionResult {
        this.parent.remove(this.droppedComponent);
        this.parent.insert(this.droppedComponent, this.droppedIndex);
        return { success: true };
    }

    undo() {

        this.parent.remove(this.droppedComponent);
        this.parent.insert(this.droppedComponent, this.originalIndex);
    }

    toString() {
        return `Drop ${this.droppedComponent.constructor.name} - ${this.droppedComponent.id}`;
    }
}
