import { Button, Checkbox, Label, Panel, StringOrPropsOrComponent, hideTooltip } from "@mcleod/components";
import { Alignment } from "@mcleod/core";
import { AutogenLayoutDemoTooltip } from "./autogen/AutogenLayoutDemoTooltip";

export class DemoTooltip extends AutogenLayoutDemoTooltip {
    butonShowOnClick(event) {
        let tooltip: StringOrPropsOrComponent = "This is a test tooltip that is just text";
        let timeout: number;
        if (this.checkFancyTooltip.checked) {
            const fancyPanel = new Panel();
            fancyPanel.add(new Label({ caption: "This is a fancy tooltip.  It can have any number of components.", color: "primary" }));
            fancyPanel.add(new Checkbox({ caption: "Like a checkbox" }));
            fancyPanel.add(new Button({ caption: "Close this tooltip" }).addClickListener(() => hideTooltip()));
            tooltip = fancyPanel;
        }
        else
            timeout = 4000;

        this.textTooltipAnchor.showTooltip(tooltip, {
            shaking: this.checkShakeTooltip.checked,
            position: Alignment.RIGHT,
            timeout: timeout
        });
    }

    getDemoPath() {
        return ["Dialogs", "Tooltip"];
    }
}
