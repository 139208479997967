import { DataSource, HorizontalSpacer, Label, Layout, SaveButton, Tab, Table, Tabset, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutInstanceSettings extends Layout {
    buttonSave: SaveButton;
    horizontalspacer1: HorizontalSpacer;
    labelSecurityBreachNotifications: Label;
    sourceInstanceSettings: DataSource;
    sourceNotificationSettings: DataSource;
    tabGeneralSettings: Tab;
    tabSecurityNotifications: Tab;
    tableNotificationSettings: Table;
    tabsetMain: Tabset;
    textboxDevSystemMessage: Textbox;
}
