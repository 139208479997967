import { Button, ClickEvent, Layout, SlideoutDecorator, Snackbar, Toast } from "@mcleod/components";
import { Api, ModelRow, StringUtil, getConfig } from "@mcleod/core";
import { AutogenLayoutOnboardCarrier } from "./autogen/AutogenLayoutOnboardCarrier";

export class OnboardCarrier extends AutogenLayoutOnboardCarrier {
    private _movementId: string;
    private _prequalResult: any;
    private _allowIdentifyCarrier: boolean;
    private static _darkMode: boolean;

    constructor(props?) {
        super(props);
        this._movementId = props.movementId;
        this._prequalResult = props.prequalResult;
        this._allowIdentifyCarrier = props.allowIdentifyCarrier
    }

    override onLoad(): void | Promise<void> {
        if (this._allowIdentifyCarrier) {
            this.panelIdentifyCarrier.visible = true;
            this.panelOnboardGeneral.visible = false;
        } else if (!this._prequalResult) {
            this.tabManual.select();
            this.tabAuto.visible = false;
            this.addLayoutDataLoadListener(() =>
                setTimeout(() =>
                    Snackbar.showWarningSnackbar("Cannot automatically onboard, carrier does not exist in CIM system. You may still invite using email."),
                    500
                )
            )
        } else if (this._prequalResult?.highway_vendor) {
            this._prequalResult = (this._prequalResult as HighwayPrequalResult);
            this.configureForHighway();
        } else if (this._prequalResult?.aa_vendor) {
            this._prequalResult = (this._prequalResult as AAPrequalResult);
            this.configureForAssureAdvantage();
        } else if (this._prequalResult?.rmis_vendor) {
            this._prequalResult = (this._prequalResult as RMISPrequalResult);
            this.configureForRmis();
        }

        if (this._movementId) {
            this.mainDataSource.search({ "movement_id": this._movementId, "prequal": this._prequalResult })
                .then(response => {

                    if (response.modelRows[0].data.pending_carrier_name) {
                        this.textboxManualCarrierName.text = response.modelRows[0].data.pending_carrier_name
                    }

                    if (response.modelRows[0].data.comments) {
                        this.textboxComments.text = response.modelRows[0].data.comments
                    }

                    if (response.modelRows[0].data.pending_carrier_name) {
                        this.textboxManualCarrierName.text = response.modelRows[0].data.pending_carrier_name
                    }

                    if (response.modelRows[0].data.comments) {
                        this.textboxComments.text = response.modelRows[0].data.comments
                    }

                    this.textboxCarrierEmail.addBeforeLookupModelSearchListener(event => {
                        event.filter.prequal = this._prequalResult;
                    });
                });
        }
    }

    private configureForHighway() {
        this.panelOnboardGeneral.visible = true;
        this.panelOnboardRmis.visible = false;
        this.textboxPendingCarrierName.text = this._prequalResult.company_name;
        this.textboxPendingCarrierName.enabled = false;
        this.textboxMyCarrierPacketsUser.visible = false;
    }

    private configureForAssureAdvantage() {
        this.panelOnboardGeneral.visible = true;
        this.textboxMyCarrierPacketsUser.visible = true;
        this.panelOnboardRmis.visible = false;
        this.textboxPendingCarrierName.text = this._prequalResult.company_name;
        this.textboxPendingCarrierName.enabled = false;
    }

    private configureForRmis() {
        this.panelOnboardGeneral.visible = false;
        this.panelOnboardRmis.visible = true;
        this.rmisCarrierName.text = this._prequalResult.company_name;
    }

    private buttonSave = new Button({
        rowBreak: false,
        backgroundColor: "primary",
        color: "primary.reverse",
        caption: "Invite Carrier",
        borderWidth: 0,
        borderColor: "primary",
        onClick: (event: ClickEvent) => this.inviteCarrier()
    })

    createInviteCarrierBtn(darkMode: boolean) {
        if (darkMode) {
            const darkModeColor = "default.reverse";
            const darkModeButton = this.buttonSave;
            darkModeButton.color = darkModeColor;
            return darkModeButton;
        }
        return this.buttonSave;
    }

    static showSlideout(movementId: string, prequalResult: any, allowIdentifyCarrier: boolean) {
        const modelRow = [new ModelRow("common/user-system-config", true, { ...getConfig() })];
        if (!StringUtil.isEmptyString(modelRow[0].get("theme")) && StringUtil.equalsIgnoreCase(modelRow[0].get("theme"), "Dark (experimental)")) {
            this._darkMode = true;
        }
        const layout = Layout.getLayout("lme/dispatch/OnboardCarrier",
            {
                movementId: movementId,
                prequalResult: prequalResult,
                allowIdentifyCarrier: allowIdentifyCarrier
            } as any
        ) as OnboardCarrier;

        const slideoutDecorator = new SlideoutDecorator({
            title: "Invite Carrier",
            layout: layout,
            fillVerticalSpace: true,
            width: 1000,
            overlayProps: { greyedBackground: true },
            addlComponents: layout.createInviteCarrierBtn(this._darkMode),
            onClose: (cancelled: boolean) => layout.slideOut()
        });
    }

    imageDetailOnClick() {
        if (!StringUtil.isEmptyString(this.textboxManualCarrierEmail.text)
            && !StringUtil.isEmptyString(this.textboxSubject.text)
            && !StringUtil.isEmptyString(this.textboxComments.text)) {
            let mailToLink = `mailto:${encodeURI(this.textboxManualCarrierEmail.text)}?`;
            if (!StringUtil.isEmptyString(this.textboxCc.text)) {
                mailToLink = mailToLink + `cc=${this.textboxCc.text}&`;
            }
            if (!StringUtil.isEmptyString(this.textboxBcc.text)) {
                mailToLink = mailToLink + `bcc=${this.textboxBcc.text}&`;
            }
            mailToLink = mailToLink + `subject=${encodeURI(this.textboxSubject.text)}&body=${encodeURI(this.textboxComments.text)}`;
            window.open(mailToLink, '_blank');
            this.slideOut();
        } else {
            Snackbar.showWarningSnackbar("Please provide all fields to invite a carrier.");
        }
    }

    buttonIdentifyCarrierOnClick(event: ClickEvent) {
        this.buttonIdentifyCarrier.busy = true;
        const numberType: string = this.textboxNumberType.text;
        const carrierNumber: string = this.textboxCarrierNumber.text;
        if (StringUtil.isEmptyString(numberType)
            || StringUtil.isEmptyString(carrierNumber)
        ) {
            this.buttonIdentifyCarrier.busy = false;
            Snackbar.showWarningSnackbar("Number Type and Carrier Number required to identify carrier.");
        } else {
            Api.post("lme/powerbroker/prequal-carrier", {
                identifier_type: numberType,
                identifier_number: carrierNumber
            }).then(response => {
                this.buttonIdentifyCarrier.busy = false;
                const existInCarrierSystem = response?.data[0]?.exist_in_carrier_system;
                if (!existInCarrierSystem) {
                    this.panelOnboardGeneral.visible = false;
                    this.panelOnboardRmis.visible = false;
                    Snackbar.showWarningSnackbar("Cannot automatically onboard, carrier does not exist in CIM system. You may still invite using email.");
                } else {
                    const result: any = response.data[0]
                    if (result?.highway_vendor) {
                        this._prequalResult = (result as HighwayPrequalResult);
                        this.configureForHighway();
                    } else if (result?.aa_vendor) {
                        this._prequalResult = (result as AAPrequalResult);
                        this.configureForAssureAdvantage();
                    } else if (result?.rmis_vendor) {
                        this._prequalResult = (result as RMISPrequalResult);
                        this.configureForRmis();
                    }
                    this.textboxCarrierEmail.addBeforeLookupModelSearchListener(event => {
                        event.filter.prequal = this._prequalResult;
                    });
                }
            })
        }
    }

    inviteCarrier() {
        if (this.tabAuto.selected) {
            if (this._prequalResult?.rmis_vendor) {
                if (!StringUtil.isEmptyString(this.rmisCarrierName.text)
                    && !StringUtil.isEmptyString(this.rmisContactName.text)
                    && !StringUtil.isEmptyString(this.rmisContactEmail.text)
                    && !StringUtil.isEmptyString(this.rmisUserName.text)
                    && !StringUtil.isEmptyString(this.rmisUserEmail.text)) {
                    this.onboardWithRmis();
                } else {
                    Snackbar.showWarningSnackbar("Please provide all fields to invite a carrier.");
                }
            } else if (this._prequalResult?.highway_vendor || this._prequalResult?.aa_vendor) {
                if (!StringUtil.isEmptyString(this.textboxCarrierEmail.text)) {
                    if (this._prequalResult?.highway_vendor) {
                        this.onboardWithHighway();
                    } else if (this._prequalResult?.aa_vendor) {
                        this.onboardWithAA();
                    }
                }
            }
        } else if (this.tabManual.selected) {
            if (!StringUtil.isEmptyString(this.textboxManualCarrierEmail.text)
                && !StringUtil.isEmptyString(this.textboxSubject.text)
                && !StringUtil.isEmptyString(this.textboxComments.text)) {
                this.onboardManually();
            } else {
                Snackbar.showWarningSnackbar("Please provide all fields to invite a carrier.");
            }
        }
    }

    onboardManually() {
        this.buttonSave.busy = true;
        Api.post("lme/dispatch/send-carrier-invite", {
            "to": this.textboxManualCarrierEmail.text,
            "cc": this.textboxCc.text,
            "bcc": this.textboxBcc.text,
            "subject": this.textboxSubject.text,
            "body": this.textboxComments.text
        })
            .then(response => {
                this.buttonSave.busy = false;
                if (response?.data[0]?.success) {
                    this.slideOut();
                    setTimeout(() => Toast.showSuccessToast('Success!', `Carrier Invitation sent`), 500);
                } else {
                    Snackbar.showWarningSnackbar("There was an unexpected error inviting the carrier.");
                }

            })
    }

    onboardWithRmis() {
        this.buttonSave.busy = true;
        Api.post("lme/dispatch/onboard-carrier",
            {
                "pending_carrier_name": this.rmisCarrierName.text,
                "contact_name": this.rmisContactName.text,
                "contact_email": this.rmisContactEmail.text,
                "user_name": this.rmisUserName.text,
                "user_email": this.rmisUserEmail.text,
                "prequal": this._prequalResult
            })
            .then(response => {
                this.buttonSave.busy = false;
                if (response?.data[0]?.invite_response && response?.data[0]?.invite_response.status_code == 200 && StringUtil.isEmptyString(response?.data[0]?.invite_response.error_message)) {
                    this.slideOut();
                    setTimeout(() => Toast.showSuccessToast('Success!', `Invitation created with ${this._prequalResult.vendor_name}`), 500);
                } else {
                    Snackbar.showWarningSnackbar("There was an unexpected error inviting the carrier.");
                }
            })

    }

    onboardWithHighway() {
        this.buttonSave.busy = true;
        Api.post("lme/dispatch/onboard-carrier", {
            "carrier_email": this.textboxCarrierEmail.text,
            "prequal": this._prequalResult
        })
            .then(response => {
                this.buttonSave.busy = false;
                if (response?.data[0]?.invite_response && response?.data[0]?.invite_response.status_code == 201) {
                    this.slideOut();
                    setTimeout(() => Toast.showSuccessToast('Success!', `Invitation created with ${this._prequalResult.vendor_name}`), 500);
                } else if (response?.data[0]?.invite_response && response?.data[0]?.invite_response.status_code == 422) {
                    if (response?.data[0]?.invite_response.connection_invitation) {
                        Snackbar.showWarningSnackbar(response?.data[0]?.connection_invitation[0]);
                    } else if (response?.data[0]?.invite_response.email) {
                        Snackbar.showWarningSnackbar("email: " + response?.data[0]?.email);
                    } else {
                        Snackbar.showWarningSnackbar("There was an issue with your request format.");
                    }
                } else {
                    Snackbar.showWarningSnackbar("There was an unexpected error inviting the carrier.");
                }
            })
    }

    onboardWithAA() {
        this.buttonSave.busy = true;
        Api.post("lme/dispatch/onboard-carrier", {
            "carrier_email": this.textboxCarrierEmail.text,
            "carrier_packets_user": this.textboxMyCarrierPacketsUser.text,
            "prequal": this._prequalResult
        })
            .then(response => {
                this.buttonSave.busy = false;
                if (response.data[0].invite_response?.Message) {
                    Snackbar.showWarningSnackbar("Carrier cannot be invited because: " + response.data[0].invite_response?.Message);
                } else if (response.data[0].invite_response?.success) {
                    this.slideOut();
                    setTimeout(() => Toast.showSuccessToast('Success!', `Invitation created with ${this._prequalResult.vendor_name}`), 500);

                } else {
                    Snackbar.showWarningSnackbar("There was an unexpected error inviting the carrier.");
                }
            })
    }
}

class HighwayPrequalResult {
    classifications: string;
    company_name: string;
    dot_number: number;
    exist_in_carrier_system: boolean;
    highway_vendor: boolean;
    mc_number: string;
    overall_result: string;
    qualified_carrier: boolean
    vendor_name: string;
    vendor_url: string;
    highway_id: string;
}

class AAPrequalResult {
    aa_authority: string;
    aa_insurance: string;
    aa_operation: string;
    aa_other: string;
    aa_safety: string;
    aa_vendor: boolean;
    company_name: string;
    dot_number: number;
    exist_in_carrier_system: boolean;
    mc_number: string;
    qualified_carrier: boolean;
    risk_assessment: string;
    vendor_name: string;
    vendor_url: string;
}

class RMISPrequalResult {
    vendor_name: string;
    rmis_vendor: boolean;
    exist_in_carrier_system: boolean;
    company_name: string;
    mc_number: string;
    dot_number: string;
    insurance_ok: string;
    dot_ok: string;
    qualified_carrier: boolean;
    invitation_request: object;
}
