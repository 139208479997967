import { DataDisplayEvent, Label } from "@mcleod/components";
import { AutogenLayoutCallinSearch } from "./autogen/AutogenLayoutCallinSearch";

export class CallinSearch extends AutogenLayoutCallinSearch {
    labelNextStopTimeOnDataDisplay(event: DataDisplayEvent) {
        const minsLeft = event.rowData?.get("next_stop_time_left");
        if (minsLeft != null) {
            const hours = Math.floor(minsLeft / 60);
            const mins = minsLeft % 60;
            let timeStr = mins + "m";
            if (hours > 0)
                timeStr = hours + "h " + timeStr;
            const label = event.target as Label;
            label.caption = " (" + timeStr + ")"
        }
    }

    labelMileUnitsOnDataDisplay(event: DataDisplayEvent) {
        (event.target as Label).visible = event.rowData?.get("miles2consignee") != null;
    }

    labelReturnTempCaptionOnDataDisplay(event) {
        (event.target as Label).visible = event.rowData?.get("temperature") != null;
    }


    labelSetTempCaptionOnDataDisplay(event) {
        (event.target as Label).visible = event.rowData?.get("setpoint_temp") != null;
    }

    labelETACaptionOnDataDisplay(event: DataDisplayEvent) {
        (event.target as Label).visible = event.rowData?.get("new_eta") != null;
    }
}
