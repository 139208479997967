import { PbwLoadboardServices } from "../PbwLoadboardServices";
import { Attachment, Button, DataHeader, DataSource, Image, Layout, Panel, Switch, Tab, Tabset, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutWebControl extends Layout {
	attachmentOfferOrderLogo: Attachment;
	attachmentWaterfallLogo: Attachment;
	buttonOpenClassic: Button;
	buttonOpenClassicBrokerageWorkflow: Button;
	buttonOpenClassicCarrierQualification: Button;
	dataheaderTitle: DataHeader;
	imageMPactSmartTargetWarning: Image;
	layoutLoadboardServices: PbwLoadboardServices;
	panelMaxPay: Panel;
	sourceDispatchControl: DataSource;
	sourcePbwLoadboardServices: DataSource;
	switchAllowDispatchHazmatCertBlank: Switch;
	switchAllowDispatchHazmatExpBlank: Switch;
	switchAutoPayRates: Switch;
	switchCalculateBrokerageMargOnNet: Switch;
	switchCalculateFuelSurchrgePay: Switch;
	switchCarrierSelectExpandAll: Switch;
	switchCreateCarrierCallin: Switch;
	switchDisplayContinuousMovesOnOffers: Switch;
	switchDisplayContinuousMovesOnOffersWaterfall: Switch;
	switchEnableAuthorizedUserOverride: Switch;
	switchEnableCarrierAutoRating: Switch;
	switchEnforceBrokerageWorkflow: Switch;
	switchEnforceDepartureDates: Switch;
	switchEnforceMaxPay: Switch;
	switchEnforceTargetPay: Switch;
	switchIncludeInactivePNNCarriers: Switch;
	switchIncludeInactivePNNCarriersWaterfall: Switch;
	switchMandatoryRatings: Switch;
	switchRequireUnassignmentReasons: Switch;
	switchSaveRateConfToOrderhist: Switch;
	tabAssignCarrier: Tab;
	tabBlastOffers: Tab;
	tabBrokerageLTL: Tab;
	tabBrokerageOperations: Tab;
	tabBrokeragePlanning: Tab;
	tabCarrierPay: Tab;
	tabCarrierPaySecondary: Tab;
	tabCarrierQualifications: Tab;
	tabDispatch: Tab;
	tabElectronicOffers: Tab;
	tabLoadBoardServices: Tab;
	tabMandatoryCarrierRatings: Tab;
	tabRateConfirmation: Tab;
	tabTargetMaxPay: Tab;
	tabWaterfallTender: Tab;
	tabsetBP: Tabset;
	tabsetCarrierPay: Tabset;
	tabsetControlGroups: Tabset;
	tabsetElectronicOffers: Tabset;
	textboxBrkQualProfile: Textbox;
	textboxBrokerageWorkflowId: Textbox;
	textboxCarrierAssignmentPrompt: Textbox;
	textboxCheckCallWarning: Textbox;
	textboxConfFaxTemplate: Textbox;
	textboxContact: Textbox;
	textboxDefaultCallinScriptTempl: Textbox;
	textboxDefaultCounterRespTime: Textbox;
	textboxDefaultPostingUserRole: Textbox;
	textboxDefaultWaterfallRespTime: Textbox;
	textboxDeliveryWarning: Textbox;
	textboxDuplicateCarrierInvCheck: Textbox;
	textboxEnforceCarrierOos: Textbox;
	textboxEnforceHazmatExp: Textbox;
	textboxEnforceProhibitedCarriers: Textbox;
	textboxInsExpTemplateid: Textbox;
	textboxInsWarningDays: Textbox;
	textboxLevelWarningCarrierInv: Textbox;
	textboxLockMinCarrierQual: Textbox;
	textboxLockMinNewCarrier: Textbox;
	textboxLockMinTargetMaxPay: Textbox;
	textboxMPactAPI: Textbox;
	textboxMaxPayPercent: Textbox;
	textboxMpactHostURL: Textbox;
	textboxMpactKeyLastEntered: Textbox;
	textboxOfferContMoveDaysOut: Textbox;
	textboxOfferOrderFormat: Textbox;
	textboxOfferOrderFreeText: Textbox;
	textboxOfferOrderRadius: Textbox;
	textboxPickupWarning: Textbox;
	textboxRatingTriggerCode: Textbox;
	textboxServiceFailures: Textbox;
	textboxTargetPayCalc: Textbox;
	textboxTargetPayCalculation: Panel;
	textboxTargetProfit: Textbox;
	textboxWaterfallContMoveUser: Textbox;
	textboxWaterfallFreeText: Textbox;
	textboxWaterfallRadius: Textbox;
	textboxWaterfallTenderFormat: Textbox;
	textboxWtrfallContMoveDaysOut: Textbox;
}
