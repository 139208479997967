import { Alignment, JSUtil, ObjectUtil, addConfigChangeListener } from "@mcleod/core";
import { Component } from "./Component";
import { McLeodMainPageUtil } from "./McLeodMainPageUtil";
import { TransitionOptions } from "./TransitionOptions";

const _defaultCollapseSpeed = 300;
const _defaultExpandSpeed = 300;
const _defaultSlideSpeed = 300;

let _useSmoothTransitions = true;
setTimeout(() => { // Module for addConfigChangeListener isn't initialized yet unless we do the setTimeout()... gross.
    addConfigChangeListener((config) => {
        _useSmoothTransitions = config == null || config.smooth_transitions !== "false";
    });
}, 0);

interface HeightWidth {
    height: number;
    width: number;
}

export class TransitionsUtil {
    /**
     * This function will reduce the height of the specified to component to zero will a smooth transition.
     * Typically you wouldn't use this function directly but instead should call Component.collapse() (which calls this).
     *
     * @param {Component} component
     * @param {Object} options
     * @param {number} options.speed The number of milliseconds that it should take for the component to collapse.
     * @returns
     */
    public static collapseComponent(component: Component, options: TransitionOptions) {
        const style = component._element.style;
        if (!_useSmoothTransitions) {
            TransitionsUtil._collapseNow(style);
            return Promise.resolve();
        }
        (component as any)._preCollapseProps = {
            style: {
                paddingTop: style.paddingTop,
                paddingBottom: style.paddingBottom,
            },
            overflow: style.overflow,
            size: {
                width: style.width,
                height: style.height
            },
        };
        if (style.height === "")
            style.height = component.getPreferredSize().height + "px";
        const speed = options == null || options.speed == null ? _defaultCollapseSpeed : options.speed;
        setTimeout(() => { // give the height above time to be applied (would be nice to not wrap this in setTimeout() if there was already a set height)
            const preCollapseTransition = style.transition;
            style.transition = "height " + speed + "ms, padding-bottom " + speed + "ms, padding-top " + speed + "ms";
            TransitionsUtil._collapseNow(style);
            setTimeout(() => {
                style.transition = preCollapseTransition;
            }, speed);
        }, 100);
        return JSUtil.delayPromise(speed);
    }

    public static collapseComponentNow(component: Component) {
        const style = component._element.style;
        TransitionsUtil._collapseNow(style);
    }

    private static _collapseNow(style: CSSStyleDeclaration) {
        style.overflow = "hidden";
        style.paddingTop = "0px";
        style.paddingBottom = "0px";
        style.height = "0px";
    }

    /**
     * This function will restore the height of the specified to component that has previously been collapsed.
     * Typically you wouldn't use this function directly but instead should call Component.expand() (which calls this).
     *
     * @param {Component} component
     * @param {Object} options
     * @param {number} options.speed The number of milliseconds that it should take for the component to collapse.
     * @returns
     */

    public static expandComponent(component: Component, options: TransitionOptions) {
        const style = component._element.style;
        if (!_useSmoothTransitions) {
            style.height = "";
            return Promise.resolve();
        }
        const preExpandTransition = style.transition;
        const speed = options == null || options.speed == null ? _defaultExpandSpeed : options.speed;
        style.transition = "height " + speed + "ms, padding-bottom " + speed + "ms, padding-top " + speed + "ms";
        if ((component as any)._preCollapseProps != null)
            ObjectUtil.appendObject(style, (component as any)._preCollapseProps.style);
        style.height = component.getPreferredSize().height + "px";
        setTimeout(() => {
            style.transition = preExpandTransition;
            if ((component as any)._preCollapseProps != null)
                style.overflow = (component as any).overflow;
            style.height = "";
            delete (component as any)._preCollapseProps;
        }, speed);
        return JSUtil.delayPromise(speed);
    }

    public static expandComponentNow(component: Component) {
        const style = component._element.style;
        style.height = "";
    }

    public static slideInComponent(component: Component, options: TransitionOptions): Promise<any> {
        //go ahead and make the element use 'absolute' position
        //this allows the element to calculate its size before we determine to/from values below
        component._element.style.position = "absolute";

        const bounds = component._element.getBoundingClientRect();
        if (options?.direction === Alignment.RIGHT)
            return TransitionsUtil.slideComponent(component, "right", 0 - bounds.width, options?.paddingRight | 0, options);
        else if (options?.direction === Alignment.LEFT)
            return TransitionsUtil.slideComponent(component, "left", 0 - bounds.width, (options?.paddingLeft | 0), options);
        else if (options?.direction === Alignment.TOP)
            return TransitionsUtil.slideComponent(component, "top", 0 - bounds.height, McLeodMainPageUtil.getPageHeaderHeight() + (options?.paddingTop | 0), options);
        else
            return TransitionsUtil.slideComponent(component, "bottom", 0 - bounds.height, options?.paddingBottom | 0, options);
    }

    public static slideOutComponent(component: Component, options: TransitionOptions): Promise<any> {
        const bounds = component._element.getBoundingClientRect();
        if (options?.direction === Alignment.RIGHT)
            return TransitionsUtil.slideComponent(component, "right", bounds.right, 0 - bounds.width, options);
        else if (options?.direction === Alignment.LEFT)
            return TransitionsUtil.slideComponent(component, "left", bounds.left, 0 - bounds.width, options);
        else if (options?.direction === Alignment.TOP)
            return TransitionsUtil.slideComponent(component, "top", bounds.top, 0 - bounds.height, options);
        else
            return TransitionsUtil.slideComponent(component, "bottom", bounds.bottom, 0 - bounds.height, options);
    }

    private static slideComponent(component: Component, edge: "left" | "top" | "right" | "bottom", from: number, to: number, options: TransitionOptions) {
        const style = component._element.style;
        let speed = options == null || options.speed == null ? _defaultSlideSpeed : options.speed;
        const beginOpacity = options?.beginOpacity != null ? options.beginOpacity : "0";
        const endOpacity = options?.endOpacity != null ? options.endOpacity : "1";
        const currRect = component._element.getBoundingClientRect();

        const startStyle: Partial<CSSStyleDeclaration> = {
            [edge]: from + "px",
            width: currRect.width + "px",
            height: currRect.height + "px",
            opacity: beginOpacity
        };
        if (typeof component.width === "string" && component.width.indexOf("%") >= 0)
            startStyle.width = component.width;
        if (typeof component.height === "string" && component.height.indexOf("%") >= 0)
            startStyle.height = component.height;
        if (edge === "left" || edge === "right") //if sliding in from the left or right, set top so that slideout displays below the page header bar
            startStyle.top = McLeodMainPageUtil.getPageHeaderHeight() + "px";
        if (_useSmoothTransitions)
            startStyle.transition = edge + " " + speed + "ms, opacity " + (speed * 2) + "ms";
        else
            speed = 0;
        const preSlideProps = ObjectUtil.appendObject(style, startStyle);
        if (options?.direction === Alignment.TOP)
            McLeodMainPageUtil.overridePageHeaderZIndex(speed);
        setTimeout(() => {
            style[edge] = to + "px";
            style.opacity = endOpacity;
            setTimeout(() => {
                if (options?.keepSlideProps === false)
                    ObjectUtil.appendObject(style, preSlideProps);
            }
                , speed);
            if (options?.direction === Alignment.TOP)
                setTimeout(() => McLeodMainPageUtil.resetPageHeaderZIndex(), speed + 1000);
        }, 0);
        return JSUtil.delayPromise(speed);
    }
}
