import { CityState, DataSource, Image, Label, Layout, Location, Panel, Switch, Textbox } from "@mcleod/components";
import { StopNotesAndReferenceNumbers } from "../StopNotesAndReferenceNumbers";
import { StopShowAsButton } from "../StopShowAsButton";

export abstract class AutogenLayoutOrderStop extends Layout {
    citystate: CityState;
    dfXref: Label;
    imageYSplitPick: Image;
    imageYsplitDrop: Image;
    labelAdditionalStopDetails: Label;
    labelCreatingLocation: Label;
    labelLabelBetween: Label;
    labelPickup: Label;
    layoutStopNotesAndRefNbrs: StopNotesAndReferenceNumbers;
    layoutStopShowAsButton: StopShowAsButton;
    panel1: Panel;
    panelAdditionalDetails: Panel;
    panelLateArrival: Panel;
    panelMain: Panel;
    panelStopCommentsReferenceNumbers: Panel;
    panelStopFreeForm: Panel;
    sourceAppointmentChange: DataSource;
    sourceStop: DataSource;
    switchApptRequired: Switch;
    switchConfirmed: Switch;
    switchPalletsRequired: Switch;
    switchPickupConsignee: Switch;
    textboxAddress2: Textbox;
    textboxAddress: Textbox;
    textboxAppointmentStatusId: Textbox;
    textboxCases: Textbox;
    textboxContactEmail: Textbox;
    textboxContactName: Textbox;
    textboxDriverLoadUnload: Textbox;
    textboxEdiLocCode: Textbox;
    textboxLocationCode: Textbox;
    textboxLocationId: Location;
    textboxLocationName: Textbox;
    textboxPalletsHowMany: Textbox;
    textboxPhone: Textbox;
    textboxSchedArriveEarly: Textbox;
    textboxSchedArriveLate: Textbox;
    textboxStopDateType: Textbox;
    textboxVolume: Textbox;
    textboxWeight: Textbox;
    textboxZoneId: Textbox;
}
