import {
    BlurEvent, DataSource, DataSourceMode, LookupModelSearchEvent, Table, TableRow, TableRowMode, Textbox
} from "@mcleod/components";
import { getDispatchControlBoolean } from "@mcleod/general/src/models/ModelDispatchControl";
import { AutogenLayoutQuoteStop } from "./autogen/AutogenLayoutQuoteStop";

export class QuoteStop extends AutogenLayoutQuoteStop {
    private _orderSource: DataSource;

    set orderSource(value: DataSource) {
        this._orderSource = value;
    }

    get orderSource(): DataSource {
        return this._orderSource;
    }

    initialize(isSlideIn: boolean, tableRow: TableRow, orderSource: DataSource) {
        this.orderSource = orderSource;
        this.layoutStopShowAsButton.orderSource = orderSource;
        this.layoutStopShowAsButton.stopRow = tableRow.data;
        this.labelSequence.caption = (tableRow.index + 1).toString();
        this.setTableParentDataSource(this.layoutStopNotesAndRefNbrs.tableStopComments, orderSource);
        this.setTableParentDataSource(this.layoutStopNotesAndRefNbrs.tableStopReferenceNumbers, orderSource);
        this.layoutStopNotesAndRefNbrs.stopRow = tableRow.data;
        this.setStopLabels(tableRow, tableRow.table.rowCount - 1 === tableRow.index);

        if (tableRow.mode == TableRowMode.ADD) {
            this.locationStop.googlePlacesProps = {
                enableSearch: getDispatchControlBoolean("enable_google_places"),
                createLocations: getDispatchControlBoolean("create_location_from_stop"),
                customerId: "", //overwriting customerId in QuickQuote.ts
                doBeforeGoogleLocationCreated: () => this.visible = true,
                doAfterGoogleLocationCreated: () => this.visible = true
            };
        }

        this.locationStop.textCombined.addBeforeLookupModelSearchListener((event: LookupModelSearchEvent) => {
            event.filter.lme_search = true;
            event.filter.locations_only = false;
        });
    }

    /** This is an event handler for the onBlur event of textboxSchedArriveEarly.  */
    textboxSchedArriveEarlyOnBlur(event: BlurEvent) {
        const textbox = event.target as Textbox;
        textbox._input.value = textbox.text; // this is a workaround for an issue where the input value is not updated after a change as a result of a validation warning
    }

    setStopLabels(tableRow: TableRow, lastStop: boolean) {
        const quoteStopIndex = tableRow.index;
        if (this.labelPickup == null || this.labelDelivery == null || this.switchPickupConsignee == null) return;

        if (quoteStopIndex === 0) {
            tableRow.data.set("stop_type", "PU");
        }
        else if (lastStop) {
            tableRow.data.set("stop_type", "SO");
        }

        this.labelPickup.visible = quoteStopIndex === 0;
        this.labelDelivery.visible = quoteStopIndex !== 0 && lastStop;
        this.switchPickupConsignee.visible = quoteStopIndex !== 0 && !lastStop;
        this.textboxSchedArriveEarly.caption = tableRow.data.get("stop_type") === "PU" ? "Scheduled Pickup" : "Scheduled Delivery";
    }

    setTableParentDataSource(table: Table, parentSource: DataSource) {
        if (parentSource != null) {
            table.dataSource.parentDataSource = parentSource;
            const mode = parentSource.mode;
            //if we are setting the mode to ADD, we don't need the blank/empty ModelRow that setting the mode normally will create
            //the fact that the creation of the blank row is async can only mess us up; it can cause existing rows to be wiped out
            //when the blank row is done creating (which causes the table's data to be redisplayed)
            if (mode !== DataSourceMode.ADD)
                table.dataSource.mode = DataSourceMode.UPDATE;
            else
                table.dataSource.setRowsAndMode(mode, []);
        }
    }




}
