import { CommonDialogs, YesNoDialogProps } from "@mcleod/common";
import {
    Button, ChangeEvent, ClickEvent, DataSourceExecutionEvent, DataSourceMode, Image,
    Snackbar, Switch, TableRow, TableRowCreationEvent, TableRowDisplayEvent
} from "@mcleod/components";
import { Api, DOMUtil, ModelRow, Navigation, getLogger } from "@mcleod/core";
import { AbstractUIDesigner } from "./AbstractUIDesigner";
import { DesignerTab } from "./actions/DesignerTab";
import { AutogenLayoutUILayoutVersions } from "./autogen/AutogenLayoutUILayoutVersions";

const log = getLogger("designer.ui.AbstractUIDesigner");

export class UILayoutVersions extends AutogenLayoutUILayoutVersions {
    public layoutPath: string;
    public layoutCaption: string;
    private switchListenersActive = false;
    public enclosingDesigner: AbstractUIDesigner;
    private positionedBaseComponentsFromRow = false;

    override onLoad(): void | Promise<void> {
        this.tableVersions.busy = true;
        this.mainDataSource.mode = DataSourceMode.UPDATE;
    }

    positionBaseComponents() {
        let activeColumn = null;
        let actionsColumn = null;
        for (const column of this.tableVersions.columns) {
            if (column.headingCell.id === "columnHeaderActive")
                activeColumn = column;
            else if (column.headingCell.id === "columnHeaderActions")
                actionsColumn = column;
        }
        this.labelBaseLayout.left = -9999;
        this.labelBaseLayout.visible = true;
        const labelWidth = DOMUtil.getElementWidth(this.labelBaseLayout._element);
        this.labelBaseLayout.paddingRight = 0;
        this.labelBaseLayout.visible = false;
        this.labelBaseLayout.left = activeColumn.headingCell._element.offsetLeft - labelWidth;
        this.labelBaseLayout.visible = true;
        this.switchBaseVersionActive.left = activeColumn.headingCell._element.offsetLeft - labelWidth + 4;
        this.switchBaseVersionActive.visible = true;
        this.buttonOpenBaseVersion.left = actionsColumn.headingCell._element.offsetLeft - labelWidth - DOMUtil.getElementWidth(this.switchBaseVersionActive._element);
        this.buttonOpenBaseVersion.visible = true;
    }

    /** This is an event handler for the onChange event of switchIsActive.  */
    switchIsActiveOnChange(event: ChangeEvent) {
        if (this.switchListenersActive !== true)
            return;
        const updatedSwitch = event.target as Switch;
        const changedRow = TableRow.getContainingTableRow(updatedSwitch);
        const changedRowData = changedRow.data as ModelRow;
        Api.post("common/custom-layout-set-version-active", { path: changedRowData.get("name"), id: changedRowData.get("id"), is_active: changedRowData.get("is_active") }).then(response => {
            try {
                this.switchListenersActive = false;
                for (const row of this.tableVersions.allRows) {
                    const switchInRow = row.findComponentById("switchIsActive") as Switch;
                    if (changedRow !== row) {
                        if (updatedSwitch.checked === true && switchInRow.checked === true)
                            switchInRow.checked = false;
                    }
                    row.findComponentById("imageDelete").visible = switchInRow.checked === false;
                }
                Snackbar.showSnackbar(response.data[0].message);
            }
            finally {
                this.syncBaseVersionActiveSwitch();
                this.switchListenersActive = true;
            }
        }).catch(error => {
            log.debug("An error occurred while activating the version, resetting switches");
            this.switchListenersActive = false;
            updatedSwitch.checked = !updatedSwitch.checked;
            this.switchListenersActive = true;
        });
    }

    syncBaseVersionActiveSwitch() {
        for (const row of this.tableVersions.allRows) {
            if (row.data.get("is_active", "N") === "Y") {
                this.switchBaseVersionActive.checked = false;
                this.switchBaseVersionActive.enabled = true;
                return;
            }
        }
        this.switchBaseVersionActive.checked = true;
        this.switchBaseVersionActive.enabled = false;
    }

    /** This is an event handler for the afterExecution event of sourceCustomUiLayout.  */
    sourceCustomUiLayoutAfterExecution(event: DataSourceExecutionEvent) {
        try {
            this.switchListenersActive = false;
            this.syncBaseVersionActiveSwitch();
        }
        finally {
            this.switchListenersActive = true;
        }
    }

    /** This is an event handler for the onClick event of imageDelete.  */
    async imageDeleteOnClick(event: ClickEvent) {
        const affectedRow = TableRow.getContainingTableRow(event.target as Image);
        const affectedRowData = affectedRow.data as ModelRow;
        const message = `The version of custom layout '${this.layoutCaption}' with the description '${affectedRowData.get("descr")}' will be deleted.\n\nDo you wish to continue?`;
        const dialogProps: Partial<YesNoDialogProps> = { yesButtonProps: { backgroundColor: "error", color: "error.reverse" } };
        const customLayoutId = affectedRowData.get("id");
        if (await CommonDialogs.showYesNo(message, "Delete Custom Layout?", dialogProps)) {
            Api.delete(this.enclosingDesigner.layoutEndpointPath, { path: affectedRowData.get("name"), id: customLayoutId }).then(response => {
                this.tableVersions.removeRow(affectedRow.index);
                Snackbar.showSnackbar(response.data[0].message);
                const tabs = this.enclosingDesigner.tabset.components;
                for (let x = tabs.length - 1; x >= 0; x--) {
                    const designerTab = tabs[x] as DesignerTab;
                    if (designerTab.customLayoutId === customLayoutId)
                        this.enclosingDesigner.closeTab(designerTab);
                }
            }).catch(error => {
                log.debug("An error occurred while deleting the layout version");
            });
        }
    }

    /** This is an event handler for the onRowDisplay event of tableVersions.  */
    tableVersionsOnRowDisplay(event: TableRowDisplayEvent) {
        const row = event.target as TableRow;
        row.canBeDeleted = false;
        this.setImageWarningVisibility(row);
        this.setImageDeleteVisibility(row);
        this.setImageUpgradeResultsVisibility(row);
        this.switchListenersActive = true;
        if (this.positionedBaseComponentsFromRow === false) {
            this.positionBaseComponents();
            this.positionedBaseComponentsFromRow = true;
        }
    }

    private setImageWarningVisibility(row: TableRow) {
        if (row.data.get("layout_outdated") === true) {
            const imageDelete = row.findComponentById("imageWarning");
            if (imageDelete != null)
                imageDelete.visible = true;
        }
    }

    private setImageDeleteVisibility(row: TableRow) {
        if (row.data.get("is_active") === "Y") {
            const imageDelete = row.findComponentById("imageDelete");
            if (imageDelete != null)
                imageDelete.visible = false;
        }
    }

    private setImageUpgradeResultsVisibility(row: TableRow) {
        const upgradeSuccessful = row.data.get("upgrade_successful", null);
        if (upgradeSuccessful != null) {
            const imageUpgradeResults = row.findComponentById("imageUpgradeResults");
            if (imageUpgradeResults != null) {
                imageUpgradeResults.visible = true;
                if (upgradeSuccessful === "N")
                    imageUpgradeResults.color = "error";
            }
        }
    }

    /** This is an event handler for the onRowCreate event of tableVersions.  */
    tableVersionsOnRowCreate(event: TableRowCreationEvent) {
        this.switchListenersActive = false;
    }

    /** This is an event handler for the onClick event of imageEdit.  */
    imageEditOnClick(event: ClickEvent) {
        const row = TableRow.getContainingTableRow(event.target as Button);
        const rowData = row.data as ModelRow;
        const customLayoutId = rowData.get("id");
        for (const tab of this.enclosingDesigner.tabset.components) {
            const designerTab = tab as DesignerTab;
            if (designerTab.customLayoutId === customLayoutId) {
                designerTab.select();
                return;
            }
        }
        this.enclosingDesigner.openTab(rowData.get("name"), false, true, customLayoutId);
    }

    /** This is an event handler for the onClick event of buttonOpenBaseVersion.  */
    buttonOpenBaseVersionOnClick(event: ClickEvent) {
        this.enclosingDesigner.openTab(this.layoutPath, true, true);
    }

    /** This is an event handler for the onChange event of switchBaseVersionActive.  */
    switchBaseVersionActiveOnChange(event: ChangeEvent) {
        if (this.switchListenersActive !== true)
            return;
        for (const row of this.tableVersions.allRows) {
            const switchInRow = row.findComponentById("switchIsActive") as Switch;
            if (switchInRow.checked === true) {
                switchInRow.checked = false;
                return;
            }
        }
    }

    /** This is an event handler for the onClick event of imageUpgradeResults. */
    async imageUpgradeResultsOnClick(event: ClickEvent) {
        const row = TableRow.getContainingTableRow(event.target as Button);
        const rowData = row.data as ModelRow;
        const url = "designer/ui/UILayoutUpgradeResults?id=" + rowData.get("id");
        Navigation.navigateTo(url, { newTab: true });
    }
}
