import { DataSource, Label, Layout, Panel } from "@mcleod/components";

export abstract class AutogenLayoutShipmentStatusErrors extends Layout {
    labelAvailable: Label;
    labelAvailableCount: Label;
    labelCovered: Label;
    labelCoveredAmount: Label;
    labelDelivered: Label;
    labelDeliveredCount: Label;
    labelInProgress: Label;
    labelInProgressAmount: Label;
    labelToalCount: Label;
    labelTotal: Label;
    panel1: Panel;
    panel2: Panel;
    panel3: Panel;
    panel4: Panel;
    panel5: Panel;
    sourceShipmentStatusErrors: DataSource;
}
