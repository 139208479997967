import { Button, HorizontalSpacer, Label, Layout, Panel, Textbox, } from "@mcleod/components";

export abstract class AutogenLayoutVoidOrderPrompt extends Layout {
    advanceCarriersLabel: Label;
    buttonClose: Button;
    buttonNo: Button;
    buttonYes: Button;
    horizontalspacer4: HorizontalSpacer;
    labelAreYouSureYouWantToVoidThisOrder?: Label;
    labelTitle: Label;
    panel3: Panel;
    panelHeader: Panel;
    panelReason: Panel;
    textboxComments: Textbox;
    textboxReasonCode: Textbox;
}
