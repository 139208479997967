/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelEdiDriverextrapay.ts             *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/datafusion/edi-driverextrapay";

export class AutogenModelEdiDriverextrapay<RowType extends ModelRow> extends Endpoint<RowType, RowEdiDriverextrapayFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowEdiDriverextrapay extends ModelRow<RowEdiDriverextrapayFields> {
    constructor(values?: Partial<RowEdiDriverextrapayFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowEdiDriverextrapayFields {
    amount: number;
    amount_c: string;
    amount_d: Date;
    amount_n: number;
    amount_r: number;
    calc_method: string;
    carrier_rate_id: number;
    charge_id: string;
    company_id: string;
    currency_iso_id: string;
    deduct_code_id: string;
    descr: string;
    direction: string;
    driver_id: string;
    edi_deduct_code: string;
    entered_user_id: string;
    id: string;
    movement_id: string;
    order_id: string;
    payee_id: string;
    rate: number;
    reference_no: string;
    short_desc: string;
    transaction_date: Date;
    units: number;
}

