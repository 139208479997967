import { ClearButtonVisible, Panel, PanelProps, Textbox, Tree, TreeNode } from "@mcleod/components";
import { Alignment, Api, StringUtil } from "@mcleod/core";

export abstract class AbstractPanelOpenTree extends Panel {
    abstract getApiName(): string;
    abstract getCustomizedTooltip(): string;

    tree: Tree;
    search: Textbox;
    data: any;

    constructor(props?: Partial<PanelProps>) {
        super({ fillRow: true, fillHeight: true, minWidth: 400, minHeight: 400, ...props });
        this.tree = new Tree({ fillHeight: true, fillRow: true, borderWidth: 1, borderRadius: 4, borderColor: "strokeSecondary", nodeLeafImageName: "form" });
        this.search = new Textbox({ placeholder: "Search", captionVisible: false, padding: 0, fillRow: true, marginBottom: 8, clearButtonVisible: ClearButtonVisible.YES });
        this.search.addChangeListener(() => this.displayLayouts());
        this.search.addKeyDownListener(event => this.tree.handleKey(event));
        this.add(this.search, this.tree);
        Api.search(this.getApiName(), this.getSearchFilter()).then(response => {
            this.data = response.data[0];
            this.displayLayouts();
        });
    }

    /**
     * Override this to return a filter that should be used when getting the items to open.
     * @returns
     */
    getSearchFilter() {
        return undefined;
    }

    displayLayouts() {
        const root = this.tree.makeTreeNodesFromObject(this.data, "name", "children", (node: TreeNode, data) => {
            if (data.base_version === false)
                node.setProps({ imageName: "formPencil", imageProps: { color: "primary", tooltip: this.getCustomizedTooltip() } });
        });
        if (this.search.text.length > 0) {
            const filter = this.search.text.toLowerCase();
            root.filterChildren(node => node.getChildCount() > 0 || node.caption.toLowerCase().includes(filter));
        }
        root.recurseChildren(node => node.expanded = node.parent.getChildCount() === 1);
        this.tree.getRootNode().setChildren(root.getChildren());
    }

    getValue(): string {
        if (this.tree.selectedNode == null) {
            this.tree.showTooltip("Select an item.", { position: Alignment.RIGHT, shaking: true });
            return undefined;
        }
        let path = "";
        this.tree.selectedNode.path.forEach((node) => { path += node.caption + "/" });
        while (path.startsWith("/"))
            path = path.substring(1);
        path = StringUtil.stringAfter(path, "/"); // the first token in the path should be the project name, which should not be included
        return path.substring(0, path.length - 1);
    }
}
