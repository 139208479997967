import { Button, DataSource, HorizontalSpacer, Label, Layout, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutCarrierPrequalResult extends Layout {
    aaSpacer2: HorizontalSpacer;
    aaSpacer: HorizontalSpacer;
    btnAssureAdvantageSite: Button;
    btnHighwaySite: Button;
    carrierWatchSpacer: HorizontalSpacer;
    highwaySpacer: HorizontalSpacer;
    labelAACompanyName: Label;
    labelAADotNumber: Label;
    labelAAMcNumber: Label;
    labelAAPrequalResult: Label;
    labelAssureAdvantageUrl: Label;
    labelAuthority: Label;
    labelAuthorityInfo: Label;
    labelCWCompanyName: Label;
    labelCWDotNumber: Label;
    labelCWMcNumber: Label;
    labelCWPrequalResult: Label;
    labelCargoAmount: Label;
    labelCargoCompany: Label;
    labelCargoEffectiveDate: Label;
    labelCargoInsuranceInfo: Label;
    labelCarrierWatchGeneralInfo: Label;
    labelCarrierWatchQualified: Label;
    labelCompanyName: Label;
    labelCwBrokerAuth: Label;
    labelCwCommonAuth: Label;
    labelCwContractAuth: Label;
    labelCwEffectiveDate: Label;
    labelCwLiabilityAmount: Label;
    labelCwNoCertificateOnFile: Label;
    labelDotNumber: Label;
    labelDotOk: Label;
    labelFirstClassification: Label;
    labelHWCompanyName: Label;
    labelHWDotNumber: Label;
    labelHWMcNumber: Label;
    labelHWPrequalResult: Label;
    labelHighwayClassifications: Label;
    labelHighwayUrl: Label;
    labelInsurance: Label;
    labelInsuranceOk: Label;
    labelLiabilityCompany: Label;
    labelLiabilityInsuranceInfo: Label;
    labelMcNumber: Label;
    labelOperation: Label;
    labelOther: Label;
    labelOverallResult: Label;
    labelPrequalResult: Label;
    labelRiskAssessment: Label;
    labelSafety: Label;
    labelSecondClassification: Label;
    paneHWCarrierDetails: Panel;
    panelAACarrierDetails: Panel;
    panelAACompanyInfo: Panel;
    panelAAInsuranceInfo: Panel;
    panelAARules: Panel;
    panelCarrierAAQualificationInfo: Panel;
    panelCarrierDetails: Panel;
    panelCarrierQualificationInfo: Panel;
    panelCarrierWatchBasicDetails: Panel;
    panelCarrierWatchQualInfo: Panel;
    panelClassificationDetails: Panel;
    panelCompanyInfo: Panel;
    panelCwAdditionalInfo: Panel;
    panelHWCarrierQualificationInfo: Panel;
    panelHWCompanyInfo: Panel;
    panelHighwayBasicDetails: Panel;
    panelInsuranceInfo: Panel;
    rmisSpacer: HorizontalSpacer;
    sourceCarrierInsCtrl: DataSource;
    textboxAACompanyName: Textbox;
    textboxAADotNumber: Textbox;
    textboxAAMcNumber: Textbox;
    textboxAuthority: Textbox;
    textboxCWCompanyName: Textbox;
    textboxCWDotNumber: Textbox;
    textboxCWMcNumber: Textbox;
    textboxCargoAmount: Textbox;
    textboxCargoEffectiveDate: Textbox;
    textboxCargoUnderwriter: Textbox;
    textboxCompanyName: Textbox;
    textboxCwBrokerAuth: Textbox;
    textboxCwCommonAuth: Textbox;
    textboxCwContractAuth: Textbox;
    textboxDotNumber: Textbox;
    textboxDotOk: Textbox;
    textboxHWCompanyName: Textbox;
    textboxHWDotNumber: Textbox;
    textboxHWMcNumber: Textbox;
    textboxInsurance: Textbox;
    textboxInsuranceOk: Textbox;
    textboxLiabilityAmount: Textbox;
    textboxLiabilityEffectiveDate: Textbox;
    textboxLiabilityUnderwriter: Textbox;
    textboxMcNumber: Textbox;
    textboxOperation: Textbox;
    textboxOther: Textbox;
    textboxOverallResult: Textbox;
    textboxRiskAssessment: Textbox;
    textboxSafety: Textbox;
}
