import { DataSource, Layout, Map, Splitter, Switch } from "@mcleod/components";
import { RecentCallins } from "../RecentCallins";

export abstract class AutogenLayoutMovementCallins extends Layout {
    layoutRecentCallins: RecentCallins;
    mapStatus: Map;
    sourceMapData: DataSource;
    sourceMovement: DataSource;
    splitterTracking: Splitter;
    switchSwitchPositions: Switch;
}
