/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelLocationDetentionHistory.ts      *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/dispatch/location-detention-history";

export class AutogenModelLocationDetentionHistory<RowType extends ModelRow> extends Endpoint<RowType, RowLocationDetentionHistoryFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowLocationDetentionHistory extends ModelRow<RowLocationDetentionHistoryFields> {
    constructor() {
        super(AutogenUserSavedSearchEndpoint);
    }
}

export interface RowLocationDetentionHistoryFields {
    actual_arrival: Date;
    actual_departure: Date;
    arrive_msg_id: number;
    authorization_num: string;
    authorization_status: string;
    bill_approved: string;
    billing_approval: string;
    charges: number;
    comments: string;
    commodity_id: string;
    company_id: string;
    create_charge: string;
    creation_date: Date;
    customer_id: string;
    depart_msg_id: number;
    detention_def_id: string;
    detention_time: string;
    driver2_descr: string;
    driver2_id: string;
    driver_descr: string;
    driver_id: string;
    free_time_end: Date;
    free_time_starts: Date;
    id: string;
    loaded_time: Date;
    movement_id: string;
    name: string;
    notify_arrival_dt: Date;
    notify_charge_dt: Date;
    notify_depart_dt: Date;
    notify_warn_dt: Date;
    order_id: string;
    pay_approval: string;
    pay_approval_descr: string;
    pay_free_time_end: Date;
    require_auth: string;
    sched_arrival: Date;
    sched_arrive_early: Date;
    sched_arrive_late: Date;
    showas_address: string;
    showas_address2: string;
    showas_city_id: number;
    showas_city_name: string;
    showas_location_id: string;
    showas_location_name: string;
    showas_state: string;
    showas_zip_code: string;
    status: string;
    status_descr: string;
    stop_address: string;
    stop_city_id: number;
    stop_city_name: string;
    stop_id: string;
    stop_latitude: number;
    stop_location_id: string;
    stop_location_name: string;
    stop_longitude: number;
    stop_movement_sequence: number;
    stop_state: string;
    stop_type: string;
    stop_type_descr: string;
    stop_zip_code: string;
    summary_charge_average: number;
    summary_charge_count: number;
    summary_charge_total: number;
    summary_count: number;
    tractor_descr: string;
    tractor_id: string;
    trailer_descr: string;
    trailer_id: string;
}

