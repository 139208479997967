import { Api, getLogger, StringUtil, UrlUtil } from "@mcleod/core";
import { AutogenLayoutDeclineCounterOffer } from "./autogen/AutogenLayoutDeclineCounterOffer";

const log = getLogger("powerbroker/DeclineCounterOffer");

export class DeclineCounterOffer extends AutogenLayoutDeclineCounterOffer {

    override async onLoad() {

        const props = UrlUtil.getPropsFromUrl(document.location.search);
        if (!StringUtil.isEmptyString(props.token)) {
            Api.post("lme/powerbroker/decline-counter-offer", { token: props.token }).then(response => {
                if (!StringUtil.isEmptyString(response.data[0].success)) {
                    this.labelStatus.caption = response.data[0].success;
                }
            }).catch(err => {
                log.debug(err);
                if (!StringUtil.isEmptyString(err)) {
                    this.labelStatus.caption = err;
                }
            });
        }
    }
}
