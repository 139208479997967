/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelEdiLog.ts                        *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/datafusion/edi-log";

export class AutogenModelEdiLog<RowType extends ModelRow> extends Endpoint<RowType, RowEdiLogFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowEdiLog extends ModelRow<RowEdiLogFields> {
    constructor(values?: Partial<RowEdiLogFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowEdiLogFields {
    as2_msg_id: string;
    batch: number;
    company_id: string;
    create_date: Date;
    descr: string;
    direction: string;
    directory_local: string;
    edi_comm_id: number;
    file_local_dir: string;
    filename: string;
    func_batch: number;
    func_receiver_id: string;
    func_sender_id: string;
    func_version: string;
    id: string;
    interchange_id: number;
    intercompany: string;
    internal_filename: string;
    message_id: string;
    message_timestamp: number;
    orig_profile_id: string;
    orig_trans_type: string;
    partner_company: string;
    receiver_id: string;
    sender_id: string;
    set_control_no: number;
    status: string;
    template_id: number;
    total_func_grps: number;
    transaction_type: string;
    version: string;
    was_successful: string;
}

