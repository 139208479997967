import { getAuthSettings, StringUtil } from "@mcleod/core";
import { DataDisplayEvent, DataSourceModeChangeEvent } from "@mcleod/components";
import { AutogenLayoutTargetPay } from "./autogen/AutogenLayoutTargetPay";

export class TargetPay extends AutogenLayoutTargetPay {
    /** This is an event handler for the afterModeChange event of sourceMovement.  */
    sourceMovementAfterModeChange(event: DataSourceModeChangeEvent) {
        if (this.mainDataSource.parentDataSource) {
            this.replaceMainDatasource(this.mainDataSource.parentDataSource);
        }
    }

    mpactRatingEnabled(enabled: boolean) {
        if (getAuthSettings().dispatch_control[0].target_pay_calc == "M" &&
            !StringUtil.isEmptyString(getAuthSettings().dispatch_control[0].mpact_smart_api_key) &&
            !StringUtil.isEmptyString(getAuthSettings().dispatch_control[0].mpact_host_url)) {
            this.panelMPact.visible = enabled;
            this.textboxTargetPayCalc.captionVisible = !enabled;
        }
    }


    textboxTargetPayCalcOnDataDisplay(event: DataDisplayEvent) {
        if (this.activeRow?.get("target_pay_method") === "S") {
            this.mpactRatingEnabled(true);
        } else {
            this.mpactRatingEnabled(false);
        }
    }
}
