import { DataSource, ValidationResult } from "@mcleod/components";
import { ModelRow, StringUtil } from "@mcleod/core";
import { AutogenLayoutShipperPONums } from "./autogen/AutogenLayoutShipperPONums";

interface ShipperPONumsProps {
    stopSource: DataSource,
    firstStopRow: ModelRow
}

export class ShipperPONums extends AutogenLayoutShipperPONums {
    initialize(props: ShipperPONumsProps) {
        this.visible = true;
        this.textboxShipperPONums.dataSource = props.stopSource;
        this.textboxShipperPONums.displayData(props.firstStopRow, props.stopSource.data, 0);
        this.textboxShipperPONums.updateBoundData(props.firstStopRow, props.stopSource.mode);
        this.textboxShipperPONums.addlValidationCallback = () => this.validateShipperPONums();
        this.textboxShipperPONums.addBlurListener(() => {
            props.firstStopRow.set("clear_po_nums", StringUtil.isEmptyString(this.textboxShipperPONums.text));
            this.textboxShipperPONums.updateBoundData(props.firstStopRow, props.stopSource.mode);
        });
    }

    validateShipperPONums(): ValidationResult {
        // regex for alphanumeric characters (up to 32 long) and commas (no commas at the beginning or end)
        const regex = /^([ 0-9a-zA-Z]{1,32},)*[ 0-9a-zA-Z]{1,32}$/;
        if (!regex.test(this.textboxShipperPONums.text)) {
            return {
                component: this.textboxShipperPONums,
                isValid: false,
                validationMessage: "Please enter reference numbers (up to 32 characters) delimited by commas."
            };
        }
        else {
            return null;
        }
    }
}
