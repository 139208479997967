/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelMovementAssignCarrier.ts         *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/powerbroker/movement-assign-carrier";

export class AutogenModelMovementAssignCarrier<RowType extends ModelRow> extends Endpoint<RowType, RowMovementAssignCarrierFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowMovementAssignCarrier extends ModelRow<RowMovementAssignCarrierFields> {
    constructor(values?: Partial<RowMovementAssignCarrierFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowMovementAssignCarrierFields {
    active_tracking_vendors_found: boolean;
    authorized: string;
    average_broker_rating: number;
    booking_no: string;
    br_details_link: string;
    br_status_link: string;
    br_track_interval: number;
    br_track_status: string;
    br_track_status_desc: string;
    br_track_vehicle_id: string;
    br_tracking_id: string;
    br_vendor: string;
    broker_avail_tract: string;
    brokerage: string;
    brokerage_status: string;
    carrier_contact: string;
    carrier_email: string;
    carrier_empty_from_city_id: number;
    carrier_fuel_pay_rate_id: number;
    carrier_lane_rate_id: string;
    carrier_override_user: string;
    carrier_phone: string;
    carrier_rate_id: number;
    carrier_tractor: string;
    carrier_trailer: string;
    carrier_trailer_type: string;
    chassis: string;
    checkcall_sched_id: string;
    company_id: string;
    container: string;
    container_returned: string;
    container_status: string;
    controlling_carrier_code: string;
    "customer.activity_count": number;
    "customer.activity_start_date": Date;
    "customer.add_empty_distance_occ": string;
    "customer.add_finance_charge": string;
    "customer.address1": string;
    "customer.address2": string;
    "customer.allow_inc_in_frt": string;
    "customer.auto_rate": string;
    "customer.available_template": number;
    "customer.average_bill": number;
    "customer.average_bill_c": string;
    "customer.average_bill_d": Date;
    "customer.average_bill_n": number;
    "customer.average_bill_r": number;
    "customer.average_pay_days": number;
    "customer.balance": number;
    "customer.balance_c": string;
    "customer.balance_d": Date;
    "customer.balance_n": number;
    "customer.balance_r": number;
    "customer.benchmark_ctg_uid": number;
    "customer.benchmark_tar_uid": number;
    "customer.bill_due_days": number;
    "customer.bill_format_flag": string;
    "customer.bill_template": number;
    "customer.bill_transfer_when": string;
    "customer.billed_loads": number;
    "customer.bl_logo": string;
    "customer.bl_logo_name": string;
    "customer.blanket_buy_tariff_id": string;
    "customer.blanket_sell_tariff_id": string;
    "customer.bridge_id": string;
    "customer.brokerage_filter": string;
    "customer.business_hours": string;
    "customer.business_number": string;
    "customer.buy_tariff_id": string;
    "customer.category": string;
    "customer.cert_of_ins_date": Date;
    "customer.charge_detention": string;
    "customer.chassis_chargeby": string;
    "customer.chassis_freedays": number;
    "customer.chassis_rate": number;
    "customer.chassis_rate_c": string;
    "customer.chassis_rate_d": Date;
    "customer.chassis_rate_n": number;
    "customer.chassis_rate_r": number;
    "customer.city": string;
    "customer.city_id": number;
    "customer.coll_thresh_pd1": number;
    "customer.coll_thresh_pd1_c": string;
    "customer.coll_thresh_pd1_d": Date;
    "customer.coll_thresh_pd1_n": number;
    "customer.coll_thresh_pd1_r": number;
    "customer.coll_thresh_pd2": number;
    "customer.coll_thresh_pd2_c": string;
    "customer.coll_thresh_pd2_d": Date;
    "customer.coll_thresh_pd2_n": number;
    "customer.coll_thresh_pd2_r": number;
    "customer.coll_thresh_pd3": number;
    "customer.coll_thresh_pd3_c": string;
    "customer.coll_thresh_pd3_d": Date;
    "customer.coll_thresh_pd3_n": number;
    "customer.coll_thresh_pd3_r": number;
    "customer.coll_thresh_pd4": number;
    "customer.coll_thresh_pd4_c": string;
    "customer.coll_thresh_pd4_d": Date;
    "customer.coll_thresh_pd4_n": number;
    "customer.coll_thresh_pd4_r": number;
    "customer.collections_id": string;
    "customer.collections_ok": string;
    "customer.company_id": string;
    "customer.confidence_level": number;
    "customer.container_chargeby": string;
    "customer.container_freedays": number;
    "customer.container_rate": number;
    "customer.container_rate_c": string;
    "customer.container_rate_d": Date;
    "customer.container_rate_n": number;
    "customer.container_rate_r": number;
    "customer.contract_cbc": string;
    "customer.contract_ccc": string;
    "customer.contract_gbc": string;
    "customer.contract_gcc": string;
    "customer.contract_sbc": string;
    "customer.contract_scc": string;
    "customer.controlling_carrier_code": string;
    "customer.conversion_date": Date;
    "customer.cost_ctg_uid": number;
    "customer.cost_tar_uid": number;
    "customer.covered_template": number;
    "customer.cp_logo": string;
    "customer.cp_logo_name": string;
    "customer.credit_application": string;
    "customer.credit_check_date": Date;
    "customer.credit_limit": number;
    "customer.credit_limit_c": string;
    "customer.credit_limit_d": Date;
    "customer.credit_limit_n": number;
    "customer.credit_limit_r": number;
    "customer.credit_status": string;
    "customer.credit_warning_pct": number;
    "customer.ctg_uid": number;
    "customer.currency_type": string;
    "customer.cust_requestor_load_id": string;
    "customer.cust_stmt_template": number;
    "customer.d_and_b_code": string;
    "customer.d_and_b_date": Date;
    "customer.d_and_b_number": string;
    "customer.days_between_calls": number;
    "customer.deadhead_target": number;
    "customer.ded_last_fixed_cost_billdt": Date;
    "customer.dedicated_cycle": string;
    "customer.dedicated_revenue_code_id": string;
    "customer.dedicated_seg_alloc_code": string;
    "customer.dedicated_start_day": string;
    "customer.dedicated_template": number;
    "customer.def_rate_confirm_temp": number;
    "customer.delivered_template": number;
    "customer.departure_date_at": string;
    "customer.disable_automatic_tracking": string;
    "customer.display_eta": string;
    "customer.distance_method": string;
    "customer.distance_profile": string;
    "customer.distance_source": string;
    "customer.distance_type": string;
    "customer.dot_number": string;
    "customer.driver_load": string;
    "customer.empty_distance_charge_id": string;
    "customer.empty_distance_rate": number;
    "customer.enable_notifications": string;
    "customer.enforce_dep_dates": string;
    "customer.entered_date": Date;
    "customer.excise_taxable": string;
    "customer.expected_close": Date;
    "customer.extend_rates": string;
    "customer.factor_percent": number;
    "customer.factor_receivables": string;
    "customer.factoring_id": string;
    "customer.fak_profile_id": string;
    "customer.fourkites_customer_id": string;
    "customer.fuel_amount": number;
    "customer.fuel_base_price": number;
    "customer.fuel_credit": string;
    "customer.fuel_method": string;
    "customer.fuel_percent": number;
    "customer.fuel_price_region": string;
    "customer.fuel_surcharge_id": string;
    "customer.fuel_surchg_enddt": Date;
    "customer.fuel_surchg_strtdt": Date;
    "customer.fuel_variance": number;
    "customer.global_detention": string;
    "customer.google_place_id": string;
    "customer.gp_exclude": string;
    "customer.gp_exported": Date;
    "customer.grace_period": number;
    "customer.high_balance": number;
    "customer.high_balance_c": string;
    "customer.high_balance_d": Date;
    "customer.high_balance_n": number;
    "customer.high_balance_r": number;
    "customer.id": string;
    "customer.include_by_date": string;
    "customer.include_empty_distance": string;
    "customer.include_fuel_surcharge": string;
    "customer.include_split_point": string;
    "customer.is_active": string;
    "customer.is_consignee": string;
    "customer.is_distribution_center": string;
    "customer.is_drop_yard": string;
    "customer.is_geocoded": string;
    "customer.is_pallets_required": string;
    "customer.is_shipper": string;
    "customer.is_trailer_pool_required": string;
    "customer.jaguar_id": number;
    "customer.last_bill_date": Date;
    "customer.last_fin_charge_dt": Date;
    "customer.last_pay_date": Date;
    "customer.last_ship_date": Date;
    "customer.latitude": number;
    "customer.location_id": string;
    "customer.longitude": number;
    "customer.macropoint_requestor_id": string;
    "customer.main_phone": string;
    "customer.manual_exchange": string;
    "customer.mark_plan_id": string;
    "customer.max_pay_percent": number;
    "customer.misc_bill_template": number;
    "customer.monthly_order_goal": number;
    "customer.motor_carrier_id": string;
    "customer.name": string;
    "customer.next_call_date": Date;
    "customer.next_rec_call": Date;
    "customer.nxt_action_code_id": string;
    "customer.nxt_action_date": Date;
    "customer.operations_user": string;
    "customer.order_count": number;
    "customer.order_detail_at": string;
    "customer.order_start_date": Date;
    "customer.p44_profile_id": string;
    "customer.paid_loads": number;
    "customer.pay_days_date": Date;
    "customer.pay_days_orders": number;
    "customer.payee_id": string;
    "customer.pd0_alert": number;
    "customer.pd1_np_auto": string;
    "customer.pd1_sp_auto": string;
    "customer.pd1_sp_thresh": number;
    "customer.pd1_sp_thresh_c": string;
    "customer.pd1_sp_thresh_d": Date;
    "customer.pd1_sp_thresh_n": number;
    "customer.pd1_sp_thresh_r": number;
    "customer.pd2_np_auto": string;
    "customer.pd2_sp_auto": string;
    "customer.pd2_sp_thresh": number;
    "customer.pd2_sp_thresh_c": string;
    "customer.pd2_sp_thresh_d": Date;
    "customer.pd2_sp_thresh_n": number;
    "customer.pd2_sp_thresh_r": number;
    "customer.pd3_np_auto": string;
    "customer.pd3_sp_auto": string;
    "customer.pd3_sp_thresh": number;
    "customer.pd3_sp_thresh_c": string;
    "customer.pd3_sp_thresh_d": Date;
    "customer.pd3_sp_thresh_n": number;
    "customer.pd3_sp_thresh_r": number;
    "customer.pd4_np_auto": string;
    "customer.pd4_sp_auto": string;
    "customer.pd4_sp_thresh": number;
    "customer.pd4_sp_thresh_c": string;
    "customer.pd4_sp_thresh_d": Date;
    "customer.pd4_sp_thresh_n": number;
    "customer.pd4_sp_thresh_r": number;
    "customer.plc_uid": number;
    "customer.post_summary_dtl": string;
    "customer.potential": number;
    "customer.potential_c": string;
    "customer.potential_d": Date;
    "customer.potential_n": number;
    "customer.potential_r": number;
    "customer.prev_code": string;
    "customer.primary_agency": string;
    "customer.primary_commodity_id": string;
    "customer.primary_contact": string;
    "customer.primary_sort": string;
    "customer.primary_trailer_type": string;
    "customer.print_containers": string;
    "customer.progress_template": number;
    "customer.prospect_type_id": string;
    "customer.quebec_number": string;
    "customer.quote_days_to_expire": number;
    "customer.rate_override_nmfc_code": string;
    "customer.reassign_profile_id": string;
    "customer.rec_call_days": number;
    "customer.remarks": string;
    "customer.require_tracking": string;
    "customer.required_vendor": string;
    "customer.revenue": number;
    "customer.revenue_c": string;
    "customer.revenue_d": Date;
    "customer.revenue_n": number;
    "customer.revenue_r": number;
    "customer.revenue_start_date": Date;
    "customer.routing_guide_profile_id": string;
    "customer.sales_manager_id": string;
    "customer.sales_status_id": string;
    "customer.salesperson_id": string;
    "customer.search_city": string;
    "customer.search_state_id": string;
    "customer.search_zip_code": string;
    "customer.secondary_sort": string;
    "customer.sell_tariff_id": string;
    "customer.service_fail_cons": string;
    "customer.service_fail_drop": string;
    "customer.service_fail_pick": string;
    "customer.service_fail_ship": string;
    "customer.service_failures": string;
    "customer.service_failures_review": string;
    "customer.shipment_ident_type": string;
    "customer.sic_code": string;
    "customer.start_date": Date;
    "customer.state_id": string;
    "customer.summary_billing": string;
    "customer.summary_template": number;
    "customer.tar_uid": number;
    "customer.target_profit": number;
    "customer.tenfour_customer_id": string;
    "customer.tracking_hours_prior": number;
    "customer.type_of": string;
    "customer.use_local_mile": string;
    "customer.vm_part_update": string;
    "customer.waterfall_auto_route_guides": string;
    "customer.waterfall_lead_time": number;
    "customer.waterfall_notify_email": string;
    "customer.waterfall_process": string;
    "customer.website_url": string;
    "customer.wf_auto_renbill": string;
    "customer.wf_autobill": string;
    "customer.wf_bwop": string;
    "customer.wf_queue": number;
    "customer.zip_code": string;
    "customer.zone_id": string;
    dedicated_order_id: string;
    dest_stop_id: string;
    dispatcher_user_id: string;
    eform_rate_confirmation: string;
    empty2next_order: string;
    equipment_group_id: string;
    est_tolls: number;
    est_tolls_c: string;
    est_tolls_d: Date;
    est_tolls_n: number;
    est_tolls_r: number;
    exclude_movement: string;
    fleet_manager: string;
    freight_matching_override: string;
    fuel_distance: number;
    fuel_distance_um: string;
    fuel_tax_exempt: string;
    hubtran_approved_inv_date: Date;
    hubtran_ok2pay_date: Date;
    id: string;
    integrated_carrier_search: string;
    invoice_recv_date: Date;
    is_container: string;
    is_dray: string;
    is_local_mile: string;
    linked_id: number;
    linked_sequence: number;
    load_booking_eligible: string;
    load_booking_source: string;
    loaded: string;
    locked_user_id: string;
    ltl: string;
    ltl_staging_wrs_uid: number;
    macropoint_status: string;
    manifest_empty_distance: number;
    manifest_estimated_arrival: Date;
    manifest_estimated_depart: Date;
    manifest_id: string;
    manifest_loaded_distance: number;
    manifest_reg_uid: number;
    manifest_spots_capacity: number;
    manifest_spots_consumed: number;
    manifest_weight_capacity: number;
    manifest_weight_consumed: number;
    margin: number;
    margin_percent: string;
    max_buy: number;
    max_buy_c: string;
    max_buy_d: Date;
    max_buy_n: number;
    max_buy_r: number;
    max_margin_percent: string;
    max_pay_calc: number;
    max_pay_method: string;
    max_pay_units: number;
    max_per_mile: string;
    missed_call_sent: string;
    move_distance: number;
    move_distance_um: string;
    movement_type: string;
    must_respond_by: Date;
    next_sched_call: Date;
    operational_status: string;
    operations_user: string;
    "orders.actual_reefer_profile": string;
    "orders.agency_id": string;
    "orders.agent_payee_id": string;
    "orders.allow_relay": string;
    "orders.autorate_status": string;
    "orders.bill_date": Date;
    "orders.bill_distance": number;
    "orders.bill_distance_um": string;
    "orders.billing_empty_distance": number;
    "orders.billing_empty_distance_um": string;
    "orders.billing_loaded_distance": number;
    "orders.billing_loaded_distance_um": string;
    "orders.billing_user_id": string;
    "orders.blnum": string;
    "orders.bol_note": string;
    "orders.bol_received": string;
    "orders.bol_recv_date": Date;
    "orders.booking_no": string;
    "orders.booking_number": string;
    "orders.brk_qual_profile": string;
    "orders.broker_location_id": string;
    "orders.brokerage_workflow_id": string;
    "orders.cbp_crossing_date": Date;
    "orders.cbp_crossing_location_id": string;
    "orders.cbp_crossing_reason": string;
    "orders.cbsa_crossing_date": Date;
    "orders.cbsa_crossing_location_id": string;
    "orders.cbsa_crossing_reason": string;
    "orders.cc_fuel_revenue": number;
    "orders.cc_fuel_revenue_c": string;
    "orders.cc_fuel_revenue_d": Date;
    "orders.cc_fuel_revenue_n": number;
    "orders.cc_fuel_revenue_r": number;
    "orders.cc_net_revenue": number;
    "orders.cc_net_revenue_c": string;
    "orders.cc_net_revenue_d": Date;
    "orders.cc_net_revenue_n": number;
    "orders.cc_net_revenue_r": number;
    "orders.cc_pro_nbr": string;
    "orders.cobroker_location_id": string;
    "orders.collection_method": string;
    "orders.com_setpnt_temp": number;
    "orders.com_setpnt_temp_um": string;
    "orders.commitment_id": string;
    "orders.commodity": string;
    "orders.commodity_id": string;
    "orders.company_id": string;
    "orders.consignee_refno": string;
    "orders.consignee_stop_id": string;
    "orders.container": string;
    "orders.container_planned": string;
    "orders.container_type_id": string;
    "orders.controlling_carrier_code": string;
    "orders.copy_from_company": string;
    "orders.copy_from_order_id": string;
    "orders.copy_to_company": string;
    "orders.copy_to_order_id": string;
    "orders.ctrl_party_id": string;
    "orders.cube": number;
    "orders.curr_movement_id": string;
    "orders.cust_order_no": string;
    "orders.customer_id": string;
    "orders.declared_val": number;
    "orders.def_move_type": string;
    "orders.default_match_id": string;
    "orders.dim_height": string;
    "orders.dim_length": string;
    "orders.dim_surcharge": number;
    "orders.dim_surcharge_c": string;
    "orders.dim_surcharge_d": Date;
    "orders.dim_surcharge_n": number;
    "orders.dim_surcharge_r": number;
    "orders.dim_width": string;
    "orders.dispatch_opt": string;
    "orders.dl_team_reqd": string;
    "orders.dray_del_date": Date;
    "orders.dray_del_pay": number;
    "orders.dray_del_pay_c": string;
    "orders.dray_del_pay_d": Date;
    "orders.dray_del_pay_n": number;
    "orders.dray_del_pay_r": number;
    "orders.dray_del_payee": string;
    "orders.dray_del_xfer_dt": Date;
    "orders.dray_pu_date": Date;
    "orders.dray_pu_pay": number;
    "orders.dray_pu_pay_c": string;
    "orders.dray_pu_pay_d": Date;
    "orders.dray_pu_pay_n": number;
    "orders.dray_pu_pay_r": number;
    "orders.dray_pu_payee": string;
    "orders.dray_pu_xfer_dt": Date;
    "orders.eir1_no": string;
    "orders.eir2_no": string;
    "orders.entered_user_id": string;
    "orders.equipment_type_id": string;
    "orders.equipment_type_options": string;
    "orders.est_tolls": number;
    "orders.est_tolls_c": string;
    "orders.est_tolls_d": Date;
    "orders.est_tolls_n": number;
    "orders.est_tolls_r": number;
    "orders.excise_disable_update": string;
    "orders.excise_taxable": string;
    "orders.excisetax_total": number;
    "orders.excisetax_total_c": string;
    "orders.excisetax_total_d": Date;
    "orders.excisetax_total_n": number;
    "orders.excisetax_total_r": number;
    "orders.external_invoiceno": string;
    "orders.extra_deliveries": number;
    "orders.extra_pickups": number;
    "orders.floor_loaded_freight": string;
    "orders.force_assign": string;
    "orders.forwd_location_id": string;
    "orders.free_days": number;
    "orders.freight_charge": number;
    "orders.freight_charge_c": string;
    "orders.freight_charge_d": Date;
    "orders.freight_charge_n": number;
    "orders.freight_charge_r": number;
    "orders.hazmat": string;
    "orders.hazmat_code": string;
    "orders.high_value": string;
    "orders.hold_reason": string;
    "orders.id": string;
    "orders.image_is_present": string;
    "orders.import_export": string;
    "orders.include_split_point": string;
    "orders.intelliset": string;
    "orders.invoice_recv_date": Date;
    "orders.is_auto_tonu": string;
    "orders.is_autorate_dist": string;
    "orders.is_container": string;
    "orders.is_dedicated": string;
    "orders.is_local_mile": string;
    "orders.is_requested": string;
    "orders.is_tonu": string;
    "orders.jag_carrier_id": string;
    "orders.jag_cost_rate_id": number;
    "orders.ldport_location_id": string;
    "orders.linear_feet_avail": number;
    "orders.load_date": Date;
    "orders.loadboard": string;
    "orders.lock_miles": string;
    "orders.ltl": string;
    "orders.markup_flat": number;
    "orders.markup_flat_c": string;
    "orders.markup_flat_d": Date;
    "orders.markup_flat_n": number;
    "orders.markup_flat_r": number;
    "orders.markup_percent": number;
    "orders.master_order_id": string;
    "orders.master_sequence": number;
    "orders.next_rebill": string;
    "orders.nte_lp_num": string;
    "orders.nte_lp_qual": string;
    "orders.on_hold": string;
    "orders.operational_status": string;
    "orders.operations_user": string;
    "orders.optimize": string;
    "orders.optiset": string;
    "orders.order_mode": string;
    "orders.order_type_id": string;
    "orders.order_value": number;
    "orders.order_value_c": string;
    "orders.order_value_d": Date;
    "orders.order_value_n": number;
    "orders.order_value_r": number;
    "orders.ordered_by": string;
    "orders.ordered_date": Date;
    "orders.ordered_method": string;
    "orders.orig_dest_rate_id": string;
    "orders.otherchargetotal": number;
    "orders.otherchargetotal_c": string;
    "orders.otherchargetotal_d": Date;
    "orders.otherchargetotal_n": number;
    "orders.otherchargetotal_r": number;
    "orders.pallets_how_many": number;
    "orders.pallets_required": string;
    "orders.pay_gross": number;
    "orders.pay_gross_c": string;
    "orders.pay_gross_d": Date;
    "orders.pay_gross_n": number;
    "orders.pay_gross_r": number;
    "orders.pick_up_no": string;
    "orders.pieces": number;
    "orders.placard_required": string;
    "orders.planning_comment": string;
    "orders.pnn_callback": string;
    "orders.pnn_comment": string;
    "orders.pnn_comment2": string;
    "orders.pnn_loadboard_user_id": string;
    "orders.pnn_post_type": string;
    "orders.pnn_rate": number;
    "orders.pnn_rate_type": string;
    "orders.pnn_trailer_length": number;
    "orders.pnn_trailer_width": number;
    "orders.ponum": string;
    "orders.port": string;
    "orders.port_of_discharge": string;
    "orders.preload_trailer_id": string;
    "orders.preloaded": string;
    "orders.rate": number;
    "orders.rate_currency_type": string;
    "orders.rate_id": number;
    "orders.rate_min_weight": number;
    "orders.rate_min_weight_um": string;
    "orders.rate_source": string;
    "orders.rate_type": string;
    "orders.rate_unit_desc": string;
    "orders.rate_units": number;
    "orders.ready_to_bill": string;
    "orders.recurring_order_id": string;
    "orders.reply_transmitted": string;
    "orders.requesting_company": string;
    "orders.return_temp": number;
    "orders.return_temp_um": string;
    "orders.return_temp_var": number;
    "orders.return_temp_var_um": string;
    "orders.revenue_code_id": string;
    "orders.revenue_share_method": string;
    "orders.round_trip": string;
    "orders.sail_date": Date;
    "orders.seal_number": string;
    "orders.seg_alloc_code": string;
    "orders.setpoint_temp": number;
    "orders.setpoint_temp_um": string;
    "orders.setpoint_var": number;
    "orders.setpoint_var_um": string;
    "orders.ship_status_to_edi": string;
    "orders.shipment_id": string;
    "orders.shipper_stop_id": string;
    "orders.shipstatus2edi_dt": Date;
    "orders.ss_location_id": string;
    "orders.status": string;
    "orders.subject_order_number": string;
    "orders.subject_order_status": string;
    "orders.subject_order_void_date": Date;
    "orders.swap": string;
    "orders.teams_required": string;
    "orders.temperature_max": number;
    "orders.temperature_max_um": string;
    "orders.temperature_min": number;
    "orders.temperature_min_um": string;
    "orders.total_charge": number;
    "orders.total_charge_c": string;
    "orders.total_charge_d": Date;
    "orders.total_charge_n": number;
    "orders.total_charge_r": number;
    "orders.totalcharge_and_excisetax": number;
    "orders.totalcharge_and_excisetax_c": string;
    "orders.totalcharge_and_excisetax_d": Date;
    "orders.totalcharge_and_excisetax_n": number;
    "orders.totalcharge_and_excisetax_r": number;
    "orders.tractor_type": string;
    "orders.vessel": string;
    "orders.vessel_cutoff_date": Date;
    "orders.weight": number;
    "orders.weight_um": string;
    "orders.xferred2billing": string;
    "orders.xferred2billing_dt": Date;
    origin_stop_id: string;
    override_crny_type: string;
    override_driver2_id: number;
    override_driver2_nm: string;
    override_driver_id: number;
    override_driver_nm: string;
    override_drvr2_cell: string;
    override_drvr2_email: string;
    override_drvr_cell: string;
    override_drvr_email: string;
    override_max_pay: number;
    override_max_pay_c: string;
    override_max_pay_d: Date;
    override_max_pay_n: number;
    override_max_pay_r: number;
    override_pay_amt: number;
    override_pay_amt_c: string;
    override_pay_amt_d: Date;
    override_pay_amt_n: number;
    override_pay_amt_r: number;
    override_pay_rate: number;
    override_paye_rate: number;
    override_payee_id: string;
    override_targetpay: number;
    override_targetpay_c: string;
    override_targetpay_d: Date;
    override_targetpay_n: number;
    override_targetpay_r: number;
    override_type: string;
    override_unit_desc: string;
    override_units: number;
    pay_distance: number;
    pay_distance_um: string;
    pay_per_mile: string;
    placard_required: string;
    preassign_sequence: number;
    priority: string;
    pro_nbr: string;
    rate_alert_basis: string;
    rate_alert_date: Date;
    rate_alert_days: number;
    rate_alert_hours: number;
    rate_alert_minutes: number;
    rate_confirm_template: number;
    rate_confirmation_sent_date: Date;
    rate_confirmation_status: string;
    rate_expiration_basis: string;
    rate_expiration_date: Date;
    rate_expiration_days: number;
    rate_expiration_hours: number;
    rate_expiration_minutes: number;
    rdy_pickup_date: Date;
    recurring_movement_id: string;
    reminder_sent: string;
    require_tracking: string;
    required_vendor: string;
    reserved: string;
    return_date: Date;
    seg_alloc_code: string;
    shpmt_ident_type: string;
    shpmt_ident_value: string;
    ss_location_id: string;
    status: string;
    "stop_origin.actual_arrival": Date;
    "stop_origin.actual_departure": Date;
    "stop_origin.address": string;
    "stop_origin.address2": string;
    "stop_origin.appointment_status_id": string;
    "stop_origin.appt_contact_name": string;
    "stop_origin.appt_required": string;
    "stop_origin.cases": number;
    "stop_origin.city_id": number;
    "stop_origin.city_name": string;
    "stop_origin.company_id": string;
    "stop_origin.confirmed": string;
    "stop_origin.contact_name": string;
    "stop_origin.dist_from_prev": number;
    "stop_origin.dist_from_prev_um": string;
    "stop_origin.driver_load_unload": string;
    "stop_origin.edi_code": string;
    "stop_origin.edi_loc_code": string;
    "stop_origin.eta": Date;
    "stop_origin.eta_distance": number;
    "stop_origin.eta_late": string;
    "stop_origin.eta_oor": string;
    "stop_origin.google_place_id": string;
    "stop_origin.hub": number;
    "stop_origin.id": string;
    "stop_origin.inbound_status": string;
    "stop_origin.late_eta_colorcode": string;
    "stop_origin.late_route_status": string;
    "stop_origin.latitude": number;
    "stop_origin.location_id": string;
    "stop_origin.location_name": string;
    "stop_origin.longitude": number;
    "stop_origin.manifest_fgp_uid": number;
    "stop_origin.manifest_reg_uid": number;
    "stop_origin.move_dist_from_preassign": number;
    "stop_origin.move_dist_from_preassign_um": string;
    "stop_origin.move_dist_from_previous": number;
    "stop_origin.move_dist_from_previous_um": string;
    "stop_origin.movement_id": string;
    "stop_origin.movement_sequence": number;
    "stop_origin.operational_status": string;
    "stop_origin.order_id": string;
    "stop_origin.order_sequence": number;
    "stop_origin.orig_sched_early": Date;
    "stop_origin.orig_sched_late": Date;
    "stop_origin.pallets_dropped": number;
    "stop_origin.pallets_picked_up": number;
    "stop_origin.phone": string;
    "stop_origin.planned_arrival_time": Date;
    "stop_origin.ponum": string;
    "stop_origin.prior_uncleared_stops": string;
    "stop_origin.projected_arrival": Date;
    "stop_origin.rate_dist_from_previous": number;
    "stop_origin.rate_dist_from_previous_um": string;
    "stop_origin.refno": string;
    "stop_origin.requested_service": string;
    "stop_origin.sched_arrive_early": Date;
    "stop_origin.sched_arrive_late": Date;
    "stop_origin.seal": string;
    "stop_origin.service_date": Date;
    "stop_origin.showas_address": string;
    "stop_origin.showas_address2": string;
    "stop_origin.showas_city_id": number;
    "stop_origin.showas_city_name": string;
    "stop_origin.showas_location_id": string;
    "stop_origin.showas_location_name": string;
    "stop_origin.showas_state": string;
    "stop_origin.showas_zip_code": string;
    "stop_origin.signed_for_name": string;
    "stop_origin.state": string;
    "stop_origin.status": string;
    "stop_origin.stop_type": string;
    "stop_origin.txl_uid": number;
    "stop_origin.volume": number;
    "stop_origin.volume_um": string;
    "stop_origin.weight": number;
    "stop_origin.weight_um": string;
    "stop_origin.zip_code": string;
    "stop_origin.zone_id": string;
    target_extra_stop_pay: number;
    target_extra_stop_pay_c: string;
    target_extra_stop_pay_d: Date;
    target_extra_stop_pay_n: number;
    target_extra_stop_pay_r: number;
    target_fuel_surcharge_pay: string;
    target_margin_percent: string;
    target_pay: number;
    target_pay_c: string;
    target_pay_calc: number;
    target_pay_d: Date;
    target_pay_method: string;
    target_pay_n: number;
    target_pay_r: number;
    target_pay_units: number;
    target_per_mile: string;
    target_rate_id: number;
    time_to_send_msg: Date;
    total_charge: number;
    total_other_pay: number;
    total_pay: number;
    tracking_duration_ext: number;
    tracking_notify_email: string;
    tracking_request_status: string;
    trailer_rent_pct: number;
    trip_lease: string;
    trip_mgt_recalc_datetime: Date;
    trip_mgt_tracking_id: string;
    triumphpay_exclude: string;
    trp_uid: number;
    ts_borrowing_company: string;
    ts_commodity: string;
    ts_movement_id: string;
    unlock_time: Date;
    waterfall_flood_time: Date;
    waterfall_in_progress: string;
    xfer2settle_date: Date;
    xferred2fueltax: string;
    xferred2fueltaxdt: Date;
    xmit_accepted: string;
    xmitted2driver: Date;
    zmit_trip: string;
}

