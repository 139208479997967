import { Button } from "@mcleod/components";
import { Api, getAuthSettings, getLogger, ModelRow, StringUtil } from "@mcleod/core";
import { isSpotOrder } from "./Orders";

export interface MoveData {
    movementId: string;
    orderId: string,
    onHold: boolean;
    status: string;
    isAssigned: boolean;
    isSpot: boolean;
    isBrokerage: boolean;
    isBrokerageLTL: boolean;
}

const endpointMap = {
    "lme/powerbroker/brokerage-movements": {
        id: "movement_id",
        orderId: "order_id",
        onHold: "hold",
        status: "move_status",
        isAssigned: "carrier_id",
        isSpot: "subject_order_status",
        isBrokerage: "true",
        isBrokerageLtl: "is_brokerage_ltl"
    },
    "lme/dispatch/movement": {
        id: "id",
        orderId: "orders.id",
        onHold: "orders.on_hold",
        status: "status",
        isAssigned: "override_payee_id",
        isSpot: "orders.subject_order_status",
        isBrokerage: "brokerage",
        isBrokerageLtl: "is_brokerage_ltl"
    },
    "lme/powerbroker/carrier-offer": {
        id: "movement_id",
        orderId: "orders.id",
        onHold: "orders.on_hold",
        status: "movement.status",
        isAssigned: "movement.override_payee_id",
        isSpot: "orders.subject_order_status",
        isBrokerage: "movement.brokerage"
    },
    "lme/powerbroker/carrier-offer-list": {
        id: "movement_id",
        onHold: "orders.on_hold",
        status: "movement.status",
        isAssigned: "movement.override_payee_id",
        isSpot: "orders.subject_order_status",
        isBrokerage: "movement.brokerage"
    }
};

const log = getLogger("lme.dispatch.DispatchUtil");

export class DispatchUtil {
    /**
     * Returns a MoveData object for use in the isAssignable method.
     * @param row
     */
    private static getMoveData(row: ModelRow): MoveData {
        // get values from row based on datasource type
        const endpointFields = endpointMap[row._modelPath];
        if (!endpointFields) {
            log.error("Endpoint not supported in DispatchUtil map: " + row._modelPath);
            return;
        }
        const isBrkPlanning = row._modelPath === "lme/powerbroker/brokerage-movements";
        return {
            movementId: row.get(endpointFields.id),
            orderId: row.get(endpointFields.orderId),
            onHold: row.getBoolean(endpointFields.onHold),
            status: row.get(endpointFields.status),
            isAssigned: !row.isNull(endpointFields.isAssigned),
            isSpot: isSpotOrder(row.get(endpointFields.isSpot)),
            isBrokerage: isBrkPlanning ? true : row.getBoolean(endpointFields.isBrokerage),
            isBrokerageLTL: row.getBoolean(endpointFields.isBrokerageLtl)
        }
    }

    /**
     * Checks if the movement can be assigned to a carrier.  If the assign and/or dispatch buttons are passed in,
     * they will be enabled/disabled based on the results of the checks and a disabled reason will be set.
     * Returns a boolean if assignment should be allowed in case the caller needs to handle things differently.
     * @param row
     * @param btnAssign
     * @param btnDispatch
     */
    public static async isAssignable(row: ModelRow, btnAssign?: Button, btnDispatch?: Button): Promise<boolean> {
        const moveData = this.getMoveData(row);
        if (moveData == null) {
            const msg = "{0} button is disabled because the movement could not be found.";
            if (btnAssign != null) this.updateButton(btnAssign, false, StringUtil.stringFormat(msg, "Assign Carrier"));
            if (btnDispatch != null) this.updateButton(btnDispatch, false, StringUtil.stringFormat(msg, "Dispatch"));
            return false;
        }

        // check for hold status
        if (moveData.onHold && moveData.status === "A" && getAuthSettings().dispatch_control[0].prevent_dsp_hold === "Y") {
            const msg = "{0} button is disabled because the order is on hold.";
            if (btnAssign != null) this.updateButton(btnAssign, false, StringUtil.stringFormat(msg, "Assign Carrier"));
            if (btnDispatch != null) this.updateButton(btnDispatch, false, StringUtil.stringFormat(msg, "Dispatch"));
            return false;
        }

        // check for spot order
        if (!moveData.isAssigned && moveData.isSpot) {
            const msg = "{0} button is disabled because the order is a spot order.";
            if (btnAssign != null) this.updateButton(btnAssign, false, StringUtil.stringFormat(msg, "Assign Carrier"));
            if (btnDispatch != null) this.updateButton(btnDispatch, false, StringUtil.stringFormat(msg, "Dispatch"));
            return false;
        }

        // check if movement is asset
        if (!moveData.isBrokerage) {
            const msg = "Carriers may not be {0} to a movement with a movement type of asset.";
            if (btnAssign != null) this.updateButton(btnAssign, false, StringUtil.stringFormat(msg, "assigned"));
            if (btnDispatch != null) this.updateButton(btnDispatch, false, StringUtil.stringFormat(msg, "dispatched"));
            return false;
        }

        // check if carrier is already assigned
        if (moveData.isAssigned) {
            if (btnAssign != null) this.updateButton(btnAssign, false,
                "Assign Carrier button is disabled because a carrier has already been assigned.");
            if (btnDispatch != null) this.updateButton(btnDispatch, true)
            return false;
        }

        // check if movement is locked and if the user has the appropriate permissions to unlock it
        const lockResponse = await Api.post("lme/powerbroker/move-locking", {
            movement_id: moveData.movementId,
            action: "checkLock",
            update_brokerage_status: "N"
        });

        // check if movement is locked and if the user has the appropriate permissions to unlock it
        if (lockResponse.data[0].is_locked && !lockResponse.data[0].user_can_unlock) {
            const msg = "{0} {1}";
            const lockMsg = lockResponse.data[0].lock_error_message;
            if (btnAssign != null) this.updateButton(btnAssign, false, StringUtil.stringFormat(msg, lockMsg, "Assign Carrier is disabled."));
            if (btnDispatch != null) this.updateButton(btnDispatch, false, StringUtil.stringFormat(msg, lockMsg, "Dispatch button is disabled."));
            return false;
        }

        // assignment & dispatch allowed
        if (btnAssign != null) this.updateButton(btnAssign, true);
        if (btnDispatch != null) this.updateButton(btnDispatch, true);
        return true;
    }

    public static updateButton(button: Button, status: boolean, message?: string) {
        button.enabled = status;
        button.disabledTooltip = message;
    }
}
