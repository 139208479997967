import { Button, CityState, DataSource, HorizontalSpacer, Label, Layout, Panel, StarRating, Tab, Tabset, Textbox } from "@mcleod/components";
import { CarrierPrequalResult } from "../CarrierPrequalResult";
import { DispatchCommentsErrors } from "../DispatchCommentsErrors";
import { MovementPay } from "../MovementPay";

export abstract class AutogenLayoutCarrierAssignment extends Layout {
    btnPrequalCarrier: Button;
    buttonCancel: Button;
    citystateCarrierEmptyFromCityId: CityState;
    dfLabel: Label;
    horizontalspacer1: HorizontalSpacer;
    horizontalspacer3: HorizontalSpacer;
    labelAddress1: Label;
    labelCarrierCity: CityState;
    labelCarrierContact: Label;
    labelCarrierReference: Label;
    labelDriverInformation: Label;
    labelHeaderTitle: Label;
    labelMilesToPickup: Label;
    layoutCarrierPrequalResult: CarrierPrequalResult;
    layoutDispatchCommentsErrors: DispatchCommentsErrors;
    layoutMovementPay: MovementPay;
    panelCarrierInfo: Panel;
    panelContact: Panel;
    panelEquipment: Panel;
    panelHeader: Panel;
    panelProgress: Panel;
    panelReference: Panel;
    panelUnknownCarrier: Panel;
    savebuttonAssign: Button;
    sourceCarrierInsCtrl: DataSource;
    sourceCarriersForAssignment: DataSource;
    sourceComments: DataSource;
    sourceMovement: DataSource;
    sourceReferenceNumber: DataSource;
    starRating1: StarRating;
    tab1: Tab;
    tabsetContent: Tabset;
    textboxCarrierContact: Textbox;
    textboxCarrierEmail: Textbox;
    textboxCarrierPhone: Textbox;
    textboxCarrierProNum: Textbox;
    textboxCarrierTractor: Textbox;
    textboxCarrierTrailer: Textbox;
    textboxCarrierTrailerType: Textbox;
    textboxOverrideDriver2Nm: Textbox;
    textboxOverrideDriverNm: Textbox;
    textboxOverrideDrvr2Cell: Textbox;
    textboxOverrideDrvr2Email: Textbox;
    textboxOverrideDrvrCell: Textbox;
    textboxOverrideDrvrEmail: Textbox;
    textboxPendingCarrierId: Textbox;
    textboxPendingCarrierIdType: Textbox;
    textboxPendingCarrierName: Textbox;
    textboxPendingPayeeId: Textbox;
    textboxPrequalVendor: Textbox;
    textboxReferenceNumber: Textbox;
}
