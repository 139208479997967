/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelSendRateConfirmation.ts          *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/powerbroker/send-rate-confirmation";

export class AutogenModelSendRateConfirmation<RowType extends ModelRow> extends Endpoint<RowType, RowSendRateConfirmationFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowSendRateConfirmation extends ModelRow<RowSendRateConfirmationFields> {
    constructor() {
        super(AutogenUserSavedSearchEndpoint);
    }
}

export interface RowSendRateConfirmationFields {
    authorized: string;
    average_broker_rating: number;
    booking_no: string;
    br_details_link: string;
    br_status_link: string;
    br_track_interval: number;
    br_track_status: string;
    br_tracking_id: string;
    br_vendor: string;
    broker_avail_tract: string;
    brokerage: string;
    brokerage_status: string;
    carrier_contact: string;
    carrier_email: string;
    carrier_empty_from_city_id: number;
    carrier_fuel_pay_rate_id: number;
    carrier_lane_rate_id: string;
    carrier_override_user: string;
    carrier_phone: string;
    carrier_rate_id: number;
    carrier_tractor: string;
    carrier_trailer: string;
    carrier_trailer_type: string;
    chassis: string;
    checkcall_sched_id: string;
    company_id: string;
    container: string;
    container_returned: string;
    container_status: string;
    controlling_carrier_code: string;
    dedicated_order_id: string;
    dest_stop_id: string;
    dispatcher_user_id: string;
    eform_rate_confirmation: string;
    empty2next_order: string;
    equipment_group_id: string;
    est_tolls: number;
    est_tolls_c: string;
    est_tolls_d: Date;
    est_tolls_n: number;
    est_tolls_r: number;
    exclude_movement: string;
    fleet_manager: string;
    freight_matching_override: string;
    fuel_distance: number;
    fuel_distance_um: string;
    fuel_tax_exempt: string;
    hubtran_approved_inv_date: Date;
    hubtran_ok2pay_date: Date;
    id: string;
    integrated_carrier_search: string;
    invoice_recv_date: Date;
    is_container: string;
    is_dray: string;
    is_local_mile: string;
    linked_id: number;
    linked_sequence: number;
    load_booking_eligible: string;
    loaded: string;
    locked_user_id: string;
    ltl: string;
    ltl_staging_wrs_uid: number;
    macropoint_status: string;
    manifest_empty_distance: number;
    manifest_estimated_arrival: Date;
    manifest_estimated_depart: Date;
    manifest_id: string;
    manifest_loaded_distance: number;
    manifest_reg_uid: number;
    manifest_spots_capacity: number;
    manifest_spots_consumed: number;
    manifest_weight_capacity: number;
    manifest_weight_consumed: number;
    max_buy: number;
    max_buy_c: string;
    max_buy_d: Date;
    max_buy_n: number;
    max_buy_r: number;
    max_pay_method: string;
    max_pay_units: number;
    missed_call_sent: string;
    move_distance: number;
    move_distance_um: string;
    movement_type: string;
    must_respond_by: Date;
    next_sched_call: Date;
    operational_status: string;
    operations_user: string;
    origin_stop_id: string;
    override_crny_type: string;
    override_driver_id: number;
    override_driver_nm: string;
    override_drvr_cell: string;
    override_drvr_email: string;
    override_max_pay: number;
    override_max_pay_c: string;
    override_max_pay_d: Date;
    override_max_pay_n: number;
    override_max_pay_r: number;
    override_pay_amt: number;
    override_pay_amt_c: string;
    override_pay_amt_d: Date;
    override_pay_amt_n: number;
    override_pay_amt_r: number;
    override_pay_rate: number;
    override_paye_rate: number;
    override_payee_id: string;
    override_targetpay: number;
    override_targetpay_c: string;
    override_targetpay_d: Date;
    override_targetpay_n: number;
    override_targetpay_r: number;
    override_type: string;
    override_unit_desc: string;
    override_units: number;
    pay_distance: number;
    pay_distance_um: string;
    placard_required: string;
    preassign_sequence: number;
    priority: string;
    pro_nbr: string;
    rate_alert_basis: string;
    rate_alert_date: Date;
    rate_alert_days: number;
    rate_alert_hours: number;
    rate_alert_minutes: number;
    rate_confirm_template: number;
    rate_confirmation_sent_date: Date;
    rate_confirmation_status: string;
    rate_expiration_basis: string;
    rate_expiration_date: Date;
    rate_expiration_days: number;
    rate_expiration_hours: number;
    rate_expiration_minutes: number;
    rdy_pickup_date: Date;
    recurring_movement_id: string;
    reminder_sent: string;
    require_tracking: string;
    required_vendor: string;
    reserved: string;
    return_date: Date;
    seg_alloc_code: string;
    shpmt_ident_type: string;
    shpmt_ident_value: string;
    ss_location_id: string;
    status: string;
    target_extra_stop_pay: number;
    target_extra_stop_pay_c: string;
    target_extra_stop_pay_d: Date;
    target_extra_stop_pay_n: number;
    target_extra_stop_pay_r: number;
    target_fuel_surcharge_pay: string;
    target_pay: number;
    target_pay_c: string;
    target_pay_d: Date;
    target_pay_method: string;
    target_pay_n: number;
    target_pay_r: number;
    target_pay_units: number;
    target_rate_id: number;
    time_to_send_msg: Date;
    tracking_duration_ext: number;
    tracking_notify_email: string;
    tracking_request_status: string;
    trailer_rent_pct: number;
    trip_lease: string;
    trip_mgt_recalc_datetime: Date;
    trip_mgt_tracking_id: string;
    triumphpay_exclude: string;
    trp_uid: number;
    ts_borrowing_company: string;
    ts_commodity: string;
    ts_movement_id: string;
    unlock_time: Date;
    waterfall_flood_time: Date;
    waterfall_in_progress: string;
    xfer2settle_date: Date;
    xferred2fueltax: string;
    xferred2fueltaxdt: Date;
    xmit_accepted: string;
    xmitted2driver: Date;
    zmit_trip: string;
}

