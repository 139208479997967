/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelEdiStopNote.ts                   *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/datafusion/edi-stop-note";

export class AutogenModelEdiStopNote<RowType extends ModelRow> extends Endpoint<RowType, RowEdiStopNoteFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowEdiStopNote extends ModelRow<RowEdiStopNoteFields> {
    constructor(values?: Partial<RowEdiStopNoteFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowEdiStopNoteFields {
    comment_type: string;
    comments: string;
    company_id: string;
    entered_user_id: string;
    id: string;
    location_id: string;
    order_id: string;
    sequence: number;
    stop_id: string;
}

