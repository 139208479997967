import { ChangeEvent, Component, EditRowDecorator, TableRow, TableRowDisplayEvent, Textbox } from "@mcleod/components";
import { AutogenLayoutPbwLoadboardServices } from "./autogen/AutogenLayoutPbwLoadboardServices";

const TRUCKSTOP_REST_TYPE = 'R';
const disabledTooltip = "Disabled for Truckstop REST service type";

export class PbwLoadboardServices extends AutogenLayoutPbwLoadboardServices {

    override onLoad() {
        this.tablePbwLoadboardServices.onEditLayoutLoaded = this._controlFileLoaded;
    }

    private _controlFileLoaded(rowDecorator: EditRowDecorator, tableRow: TableRow): void {
        rowDecorator.labelTitle.caption = `Load Board Service`;
        if (rowDecorator.btnDelete) {
            rowDecorator.btnDelete.visible = false;
        }
    }

    /** This is an event handler for the onRowDisplay event of tablePbwLoadboardServices.  */
    tablePbwLoadboardServicesOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        const textboxFileType = tableRow.findComponentById("textboxServiceType") as Textbox;
        const textboxUserId = tableRow.findComponentById("textboxUserId") as Textbox;
        const textboxPassword = tableRow.findComponentById("textboxPassword") as Textbox;
        textboxFileType.addChangeListener((event: ChangeEvent) => {
            if (event.userInitiatedChange)
                this.enableCredentialComps(tableRow, [textboxUserId, textboxPassword]);
        });
        this.enableCredentialComps(tableRow, [textboxUserId, textboxPassword]);

    }

    enableCredentialComps(tableRow: TableRow, components: Component[]) {
        const isEnableUserAndPassword =
            TRUCKSTOP_REST_TYPE != tableRow.data?.get("service_type");
        components?.forEach((comp) => {
            comp.enabled = isEnableUserAndPassword;
            if (!isEnableUserAndPassword) tableRow.data?.set(comp.field, null);
            comp.disabledTooltip = disabledTooltip;
        });
    }
}

