import { DynamicLoader, ObjectUtil } from "@mcleod/core";
import { DataSource } from "..";
import { ComponentPropDefinitionUtil } from "../base/ComponentProps";
import { DesignableObjectPropsCollection } from "../base/DesignableObjectProps";
import { PropType } from "../base/PropType";
import { stringArrayEditor } from "../components/textbox/TextboxProps";

export enum ParentSearchMode {
    none = "none",
    onlyWhenVisible = "onlyWhenVisible",
    onParentDisplay = "onParentDisplay"
}

let dataSourcePropDefs: DesignableObjectPropsCollection;

export class DataSourcePropDefinitions {
    public static getDefinitions(): DesignableObjectPropsCollection {
        if (dataSourcePropDefs == null) {
            dataSourcePropDefs = {
                id: { type: PropType.string },
                autoSearch: { type: PropType.bool, description: "This specifies whether this DataSource will be automatically searched when its page is displayed." },
                extraFields: { type: PropType.string, editor: stringArrayEditor, description: "This property specifies the fields that are needed from the model besides the ones displayed in the layout.  These fields are typically ones required by the Layout class." },
                parentDataSource: { type: PropType.string, category: "Parent", items: ComponentPropDefinitionUtil.getDataSourceList, description: "If this DataSource is intended to search and/or post with another DataSource, select that DataSource here." },
                parentSearchMode: { type: PropType.string, category: "Parent", items: Object.keys(ParentSearchMode), description: "This specifies how this child DataSource should be searched as its parent displays new records.\n\n'none' means it will not be automatically searched.\n\n'onParentDisplay' means it will be searched when its parent displays a new record.\n\n'onlyWhenVisible' means it will be searched after the parent displays a new record, but only when one of its bound components is or becomes visible (lazy loading)." },
                subscribeToChanges: { type: PropType.bool, description: "This specifies that the DataSource should listen for changes in data that it is displaying.  This requires that the underlying model support broadcasting changes." },
                maxResults: { type: PropType.number, description: "This specifies the max number of records this datasource will return." },
                url: { type: PropType.string, editor: endpointSelector, description: "This specifies the endpoint to which this DataSource is bound." },
                beforeExecution: { type: PropType.event, category: "Events", eventSignature: "BeforeExecution(event: DataSourceExecutionEvent)", addListenerMethod: "addBeforeExecutionListener", removeListenerMethod: "removeBeforeExecutionListener", "description": "This event will be fired before the DataSource executes an action (search/add/update/delete)." },
                afterExecution: { type: PropType.event, category: "Events", eventSignature: "AfterExecution(event: DataSourceExecutionEvent)", addListenerMethod: "addAfterExecutionListener", removeListenerMethod: "removeAfterExecutionListener", "description": "This event will be fired after the DataSource executes an action (search/add/update/delete)." },
                beforeModeChange: { type: PropType.event, category: "Events", eventSignature: "BeforeModeChange(event: DataSourceModeChangeEvent)", addListenerMethod: "addBeforeModeChangeListener", removeListenerMethod: "removeBeforeModeChangeListener", "description": "This event will be fired before the DataSource changes its execution mode." },
                afterModeChange: { type: PropType.event, category: "Events", eventSignature: "AfterModeChange(event: DataSourceModeChangeEvent)", addListenerMethod: "addAfterModeChangeListener", removeListenerMethod: "removeAfterModeChangeListener", "description": "This event will be fired after the DataSource changes its execution mode." },
                onDisplay: { type: PropType.event, category: "Events", eventSignature: "OnDisplay(event: DataDisplayEvent)", addListenerMethod: "addDisplayListener", removeListenerMethod: "removeDisplayListener", "description": "This event will be fired when the DataSource displays data in its bound components." },
                onRemoteDataChange: { type: PropType.event, category: "Events", eventSignature: "OnRemoteDataChange(event: DataSourceRemoteDataChangeEvent)", addListenerMethod: "addRemoteDataChangeListener", removeListenerMethod: "removeRemoteDataChangeListener", "description": "This event will be fired when the changes to the DataSource's data is published through messaging.  This is only valid for DataSources that set the subscribeToChanges property to true." },
                onSubscriberError: { type: PropType.event, category: "Events", eventSignature: "OnSubscriberError(event: DataSubscriberErrorEvent)", addListenerMethod: "addSubscriberErrorListener", removeListenerMethod: "removeSubscriberErrorListener", "description": "This event is fired if the DataSource's message subscriber encounters an error.  This is only valid for DataSources that set the subscribeToChanges property to true." },
                onValidate: { type: PropType.event, category: "Events", eventSignature: "OnValidate(event: DataSourceValidationEvent)", addListenerMethod: "addValidationListener", removeListenerMethod: "removeValidationListener", "description": "This event is fired when the DataSource is validated.  Its purpose is to allow for adhoc validation that is not related to a bound component." },
            };

            for (const key in dataSourcePropDefs) {
                const prop = dataSourcePropDefs[key];
                prop.name = key;
                prop.source = "dataSource";
            }
        }
        return dataSourcePropDefs;
    }
}

export function getDataSourcePropDefinitions(): DataSourcePropDefinitions {
    return ObjectUtil.deepCopy(dataSourcePropDefs);
}

export interface DataSourceProps {
    id: string;
    autoSearch: boolean;
    parentDataSource: DataSource;
    parentSearchMode: ParentSearchMode;
    maxResults: number;
    url: string;
}

export function endpointSelector(value, prop) {
    // can't make a direct reference to PanelOpenModel at compile time
    const cls = DynamicLoader.getClassForPath("designer/ui/PanelOpenModel");
    return new cls();
}
