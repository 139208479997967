import { ClickEvent, DataSourceExecutionEvent } from "@mcleod/components";
import { CarrierOfferInfo } from "@mcleod/dispatch/src/CarrierOfferSlideout";
import { DispatchUtil } from "@mcleod/dispatch/src/DispatchUtil";
import { CarrierAssignmentStepper } from "./CarrierAssignmentStepper";
import { AutogenLayoutCarrierOfferListDetail } from "./autogen/AutogenLayoutCarrierOfferListDetail";

export class CarrierOfferListDetail extends AutogenLayoutCarrierOfferListDetail {
    /** This is an event handler for the afterExecution event of sourceCarrierOfferList.  */
    sourceCarrierOfferListAfterExecution(event: DataSourceExecutionEvent) {
        this.checkNegativeValues();
        DispatchUtil.isAssignable(this.activeRow, this.buttonAssignCarrier);
    }

    /** This is an event handler for the onClick event of buttonAssignCarrier.  */
    buttonAssignCarrierOnClick(event: ClickEvent) {
        const carrierOfferInfo: CarrierOfferInfo = {
            payee_id: this.activeRow.get("payee_id", null),
            carrier_name: this.activeRow.get("carrier_name", ""),
            icc_number: this.activeRow.get("icc_number", null),
            dot_number: this.activeRow.get("dot_number", null),
            carrier_contact: this.activeRow.get("contact_name", ""),
            carrier_phone: this.activeRow.get("contact_phone", ""),
            carrier_email: this.activeRow.get("email", ""),
            dispatch_offer: this.activeRow.get("counter_offer", this.activeRow.get("amount")),
            carrier_empty_from_city_id: this.activeRow.get("tractor_city_id", "")
        }

        CarrierAssignmentStepper.showAsSlideout({
            movementId: this.activeRow.get("movement_id"),
            orderId: this.activeRow.get("order_id"),
            carrierAssignmentButton: this.buttonAssignCarrier,
            carrierOfferInfo,
            onClose: (canceled: boolean) => { if (!canceled) this.mainDataSource.search({ id: this.activeRow.get("id") }) },
        });
    }

    private checkNegativeValues() {
        this.labelMaxPercentage.color = this.labelMaxPercentage.getDataValue() < 0 ? "error" : "default";
        this.labelTargetPercentage.color = this.labelTargetPercentage.getDataValue() < 0 ? "error" : "default";
        this.labelDispatchPercentage.color = this.labelDispatchPercentage.getDataValue() < 0 ? "error" : "default";
        this.labelCounterPercentage.color = this.labelCounterPercentage.getDataValue() < 0 ? "error" : "default";
    }
}

