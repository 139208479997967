/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelHandlingRequirement.ts           *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/dispatch/handling-requirement";

export class AutogenModelHandlingRequirement<RowType extends ModelRow> extends Endpoint<RowType, RowHandlingRequirementFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowHandlingRequirement extends ModelRow<RowHandlingRequirementFields> {
    constructor(values?: Partial<RowHandlingRequirementFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowHandlingRequirementFields {
    add_timestamp: Date;
    add_userid: string;
    company_id: string;
    date_title: string;
    description: string;
    float_title: string;
    gla_uid: number;
    hdr_description: string;
    hdr_id: string;
    hdr_move_type: string;
    hdr_order: number;
    hdr_title: string;
    hdr_uid: number;
    hdr_web_order: number;
    include_mobile: string;
    include_on_dr: string;
    include_on_manifest: string;
    integer_title: string;
    mod_timestamp: Date;
    mod_userid: string;
    need_date: string;
    need_float: string;
    need_integer: string;
    need_text: string;
    need_time: string;
    need_timestamp: string;
    text_title: string;
    time_title: string;
    timestamp_title: string;
}

