import { Button, CityState, DataHeader, DataSource, Label, Layout, Panel, Switch, Tab, Table, Tabset, Textbox } from "@mcleod/components";
import { TableComment } from "../../../general/src/TableComment";
import { TableContact } from "../../../general/src/TableContact";
import { TableCustomClassifications } from "../../../general/src/TableCustomClassifications";
import { TableDrivers } from "../../../general/src/TableDrivers";
import { CarrierQualification } from "../CarrierQualification";

export abstract class AutogenLayoutCarrier extends Layout {
	LastContactedBy: Textbox;
	btnCimVendorUrl: Button;
	buttonCarrierUsers: Button;
	buttonOpenClassic: Button;
	buttonOverrideCarrier: Button;
	citystatePayeeCityId: CityState;
	citystate_: CityState;
	contAutoLiabilityPanel: Panel;
	contCargoLiabilityPanel: Panel;
	dataheaderCarrier: DataHeader;
	generalLiabilityAmount: Textbox;
	generalLiabilityCancellationDate: Textbox;
	generalLiabilityCompany: Textbox;
	generalLiabilityExpirationDate: Textbox;
	generalLiabilityInsurancePanel: Panel;
	generalLiabilityPhone: Textbox;
	generalLiabilityPolicyNum: Textbox;
	interchangeInsurancePanel: Panel;
	labelAddress2: Label;
	labelAuthorityGranted: Label;
	labelAuthorityReinstated: Label;
	labelAuthorityStatus: Label;
	labelCargoAuthorization: Label;
	labelCargoInsurance: Label;
	labelContingentAutoInsurance: Label;
	labelContingentCargoInsurance: Label;
	labelGeneralLiabilityInsurance: Label;
	labelHeaderCarAddr1: Label;
	labelHeaderCarCSZ: CityState;
	labelHeaderCarName: Textbox;
	labelHeaderContactEmail: Label;
	labelHeaderContactName: Textbox;
	labelHeaderContactPhone: Label;
	labelLiabilityInsurance: Label;
	labelPendingAuthority: Label;
	labelPendingRevocation: Label;
	labelTrailerInterchangeInsurance: Label;
	labelVendor: Label;
	labelWorkersCompensationInsurance: Label;
	layoutCustomClassifiications: TableCustomClassifications;
	layoutQualification: CarrierQualification;
	layoutTableComment: TableComment;
	layoutTableContact: TableContact;
	layoutTableDrivers: TableDrivers;
	panel10: Panel;
	panel22: Panel;
	panel23: Panel;
	panel24: Panel;
	panel25: Panel;
	panel26: Panel;
	panel28: Panel;
	panel29: Panel;
	panel31: Panel;
	panel32: Panel;
	panel33: Panel;
	panel34: Panel;
	panel35: Panel;
	panel8: Panel;
	panelAuthorityGranted: Panel;
	panelAuthorityReinstated: Panel;
	panelBasicInfo1: Panel;
	panelBasicInfo2: Panel;
	panelBasicInfo3: Panel;
	panelCargoInsurance: Panel;
	panelCarrierInsMonitoringLicensed: Panel;
	panelCarrierOverride: Panel;
	panelCategory: Panel;
	panelContingentAutoInsurance: Panel;
	panelContingentCargoInsurance: Panel;
	panelCreditApproval: Panel;
	panelDOTSafety: Panel;
	panelDataHeaderAddlLeft: Panel;
	panelDispatch: Panel;
	panelGeneral: Panel;
	panelGeneralLiabilityInsurance: Panel;
	panelHazmat: Panel;
	panelHeader: Panel;
	panelHeaderCarrier: Panel;
	panelHeaderContact: Panel;
	panelInsuranceInfo1: Panel;
	panelInsuranceInfo3: Panel;
	panelInsuranceInfo4: Panel;
	panelLiabilityInsurance: Panel;
	panelMonitor: Panel;
	panelP44Settings: Panel;
	panelPendingRevocation: Panel;
	panelPerformance: Panel;
	panelRating: Panel;
	panelRiskAssessment: Panel;
	panelSafety: Panel;
	panelSafetyRegistration: Panel;
	panelStatus: Panel;
	sourceAgencyLink: DataSource;
	sourceCarrier: DataSource;
	sourceCarrierDriver: DataSource;
	sourceCarrierInsCtrl: DataSource;
	sourceCarrierInsurance: DataSource;
	sourceClassifications: DataSource;
	sourceComments: DataSource;
	sourceContacts: DataSource;
	sourceDrsPayeeEquip: DataSource;
	switchAnyAutos: Switch;
	switchBondFiled: Switch;
	switchBondRequired: Switch;
	switchCargoInsOnFile: Switch;
	switchCargoInsuranceReq: Switch;
	switchCarrierContract: Switch;
	switchCertificationofInsuranceonFile: Switch;
	switchCreditApproved: Switch;
	switchDisableAutomatedTracking: Switch;
	switchDisableElectronicOffers: Switch;
	switchHiredAutos: Switch;
	switchHouseholdGoods: Switch;
	switchInsuranceUpdates: Switch;
	switchIntrastateOnly: Switch;
	switchIsCertifiedRMI: Switch;
	switchMonitor: Switch;
	switchNoDispatch: Switch;
	switchNonOwnedAutos: Switch;
	switchOutOfService: Switch;
	switchOverrideQualificationEnforcement: Switch;
	switchOwnedAutos: Switch;
	switchPassenger: Switch;
	switchPayAdvances: Switch;
	switchPayeeFreightMatchingOverride: Switch;
	switchPendBrokerAuth: Switch;
	switchPendCommonAuth: Switch;
	switchPendContractAuth: Switch;
	switchPendRevBroker: Switch;
	switchPendRevCommon: Switch;
	switchPendRevContract: Switch;
	switchProperty: Switch;
	switchQuickpayEnabled: Switch;
	switchReeferIns: Switch;
	switchRequirementDispatchOverride: Switch;
	switchScheduledAutos: Switch;
	switchWatchdogReport: Switch;
	tabAdditionalInsuranceTypes: Tab;
	tabAuthority: Tab;
	tabComments: Tab;
	tabContacts: Tab;
	tabCustomClassifications: Tab;
	tabDrivers: Tab;
	tabGeneral: Tab;
	tabInsurance: Tab;
	tabOffers: Tab;
	tabQualifications: Tab;
	tabRating: Tab;
	tabTrackingOptions: Tab;
	tabTrailerTypes: Tab;
	tableTrailerTypes: Table;
	tabsetAdditionalInsurance: Tabset;
	tabsetMain: Tabset;
	textboxAgreementDt: Textbox;
	textboxAuthReinstateDate: Textbox;
	textboxBrokerAuthGrantDate: Textbox;
	textboxBrokerAuthStatus: Textbox;
	textboxCancellationDate: Textbox;
	textboxCargoAmount: Textbox;
	textboxCargoCancelDate: Textbox;
	textboxCargoCompany: Textbox;
	textboxCargoEmail: Textbox;
	textboxCargoExpDate: Textbox;
	textboxCargoFax: Textbox;
	textboxCargoPhone: Textbox;
	textboxCargoPolicyNum: Textbox;
	textboxCarrierManager: Textbox;
	textboxCategory: Textbox;
	textboxComAuthReinstateDate: Textbox;
	textboxCommonAuthGrantDate: Textbox;
	textboxCommonAuthStatus: Textbox;
	textboxCompDate: Textbox;
	textboxContAuthReinstateDate: Textbox;
	textboxContAutoAmount: Textbox;
	textboxContAutoCancellationDate: Textbox;
	textboxContAutoCompany: Textbox;
	textboxContAutoExpirationDate: Textbox;
	textboxContAutoPhone: Textbox;
	textboxContAutoPolicyNum: Textbox;
	textboxContCargoAmount: Textbox;
	textboxContCargoCancellationDate: Textbox;
	textboxContCargoCompany: Textbox;
	textboxContCargoExpirationDate: Textbox;
	textboxContCargoPhone: Textbox;
	textboxContCargoPolicyNum: Textbox;
	textboxContractAuthGrantDate: Textbox;
	textboxContractAuthStat: Textbox;
	textboxCreditCheckDate: Textbox;
	textboxDotNumber: Textbox;
	textboxHazmatCertNumber: Textbox;
	textboxHazmatExpireDate: Textbox;
	textboxHighwayIdNumber: Textbox;
	textboxHighwayRuleAssessment: Textbox;
	textboxHighwayStatus: Textbox;
	textboxIccNumber: Textbox;
	textboxId: Textbox;
	textboxInsLastUpdDt: Textbox;
	textboxInsuranceExpEmails: Textbox;
	textboxInterchangeExpirationDate: Textbox;
	textboxIntrastateCode: Textbox;
	textboxIntrastateState: Textbox;
	textboxLastContacted: Textbox;
	textboxLiabilityAmount: Textbox;
	textboxLiabilityEmail: Textbox;
	textboxLiabilityExpDate: Textbox;
	textboxLiabilityInsurFax: Textbox;
	textboxLiabilityInsurPhone: Textbox;
	textboxLiabilityInsurer: Textbox;
	textboxLiabilityPolicy: Textbox;
	textboxLiabilityRequired: Textbox;
	textboxOverAllRisk: Textbox;
	textboxOverrideQualificationDate: Textbox;
	textboxOverrideReason: Textbox;
	textboxP44CapacityProviderID: Textbox;
	textboxPayeeAddress1: Textbox;
	textboxPayeeAddress2: Textbox;
	textboxPayeeLegalName: Textbox;
	textboxPayeeName: Textbox;
	textboxPayeePhoneNumber: Textbox;
	textboxPayeeStatus: Textbox;
	textboxPerformRating: Textbox;
	textboxPerformRatingDt: Textbox;
	textboxSafetyRateRevDt: Textbox;
	textboxSafetyRating: Textbox;
	textboxSafetyRatingDt: Textbox;
	textboxSafetyRegDate: Textbox;
	textboxSafetyRegStatus: Textbox;
	textboxSafetyReviewType: Textbox;
	textboxSalesperson: Textbox;
	textboxScac: Textbox;
	textboxTrailerAmount: Textbox;
	textboxTrailerCancelDate: Textbox;
	textboxTrailerCompany: Textbox;
	textboxTrailerPhone: Textbox;
	textboxTrailerPolicyNum: Textbox;
	textboxWorkersCompAmount: Textbox;
	textboxWorkersCompCancellationDate: Textbox;
	textboxWorkersCompCompany: Textbox;
	textboxWorkersCompExpirationDate: Textbox;
	textboxWorkersCompPhone: Textbox;
	textboxWorkersCompPolicyNum: Textbox;
	toggleCARB: Switch;
	toggleOnboarded: Switch;
	workersCompInsurancePanel: Panel;
}
