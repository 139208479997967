import { CommonDialogs } from "@mcleod/common";
import { Label, Layout, Panel } from "@mcleod/components";

export class DemoMessageDialog extends Layout {
    getDemoPath() {
        return ["Dialogs", "Messages"];
    }

    buttonSimpleClicked() {
        CommonDialogs.showMessage("Test");
    }

    buttonPropsClicked() {
        CommonDialogs.showMessage({ caption: "Document saved.", imageName: "disk", fontBold: true });
    }

    buttonComponentClicked() {
        const panel = new Panel();
        const label = new Label({ caption: "You have been logged out.", imageName: "person", fontBold: true });
        const link = new Label({ caption: "Go to login page", link: "/common/Login" });
        panel.add(label);
        panel.add(link);
        CommonDialogs.showMessage(panel);
    }
}
