/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelAgentAccessCtrl.ts               *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/dispatch/agent-access-ctrl";

export class AutogenModelAgentAccessCtrl<RowType extends ModelRow> extends Endpoint<RowType, RowAgentAccessCtrlFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowAgentAccessCtrl extends ModelRow<RowAgentAccessCtrlFields> {
    constructor(values?: Partial<RowAgentAccessCtrlFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowAgentAccessCtrlFields {
    agent_access_ctrl: string;
    auto_assign: string;
    billing_filter: string;
    callin_script_agency_filter: string;
    carr_agency_filter: string;
    carr_confirmation: string;
    carr_contact_email: string;
    company_contacts: string;
    company_id: string;
    cust_agency_filter: string;
    cust_confirmation: string;
    cust_contact_email: string;
    dispatch_assign: string;
    driver_filter: string;
    edi_filter: string;
    id: number;
    loc_agency_filter: string;
    order_filter: string;
    order_rows: number;
    order_update_move: string;
    osd_filter: string;
    quote_filter: string;
    resp_brok_assign: string;
    resp_disp_assign: string;
    resp_edi_assign: string;
    resp_order_assign: string;
    resp_plan_assign: string;
    resp_quote_assign: string;
    settlement_filter: string;
    tractor_filter: string;
    tractor_rows: number;
    trailer_filter: string;
}

