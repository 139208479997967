import { Label, Panel, TreeNode } from "@mcleod/components";
import { HomePageEditor } from "./HomePageEditor";
import { WidgetMenuItem } from "./WidgetMenuItem";


export class WidgetMenuGroup extends Panel {
    node: TreeNode;
    labelCount: Label;
    homeEditor: HomePageEditor;
    constructor(node: TreeNode) {
        super({ padding: 0 });
        this.node = node;
        node.expanded = true;
        const label = (node._component as Label);
        label.setProps({ imageName: "addInBox", color: "subtle.darker", fontBold: true, rowBreak: false });
        this.labelCount = new Label({ paddingLeft: 0, fontBold: true, })
        this.add(node._component, this.labelCount);
        this.node.onCreateImage = () => label.imageName = this.node.expanded ? "minusInBox" : "addInBox";
    }

    syncMenu() {
        let widgetAddedCount = 0;
        this.node.recurseChildren(child => {
            if (child._component instanceof WidgetMenuItem) {
                child._component.syncMenuItem();
                if (child._component.widgetAdded)
                    widgetAddedCount++;
            }
        })
        this.labelCount.caption = widgetAddedCount > 0 ? widgetAddedCount.toString() : null;
    }
}
