import { Checkbox, DataSource, Image, Label, Layout, Panel, Textbox, } from "@mcleod/components";

export abstract class AutogenLayoutServiceIncident extends Layout {
    checkboxFaultOfCarrierOrDriver: Checkbox;
    checkboxIsReportable: Checkbox;
    image1: Image;
    labelServiceIncidentReport: Label;
    panel8: Panel;
    panel9: Panel;
    panelServiceIncident: Panel;
    sourceServicefail: DataSource;
    textboxCausedByType: Textbox;
    textboxDspComment: Textbox;
    textboxEdiStandardCode: Textbox;
}
