import { ClickEvent, Snackbar, TableRowDisplayEvent } from "@mcleod/components";
import { Clipboard, ModelRow } from "@mcleod/core";
import { AutogenLayoutMovementAdvancesQuickInfo } from "./autogen/AutogenLayoutMovementAdvancesQuickInfo";

export class MovementAdvancesQuickInfo extends AutogenLayoutMovementAdvancesQuickInfo {

    /** This is an event handler for the onRowDisplay event of table1.  */
    table1OnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        if (tableRow.index > 0) {
            tableRow.table.rows[tableRow.index - 1].borderBottomWidth = 1;
        }
    }

    buttonCopyCheckCodeClicked(event: ClickEvent) {
        event.preventDefault();
        const modelRow = (event.target as any).row.data as ModelRow;
        const checkCode = modelRow.get("check_code", "");
        Clipboard.copyText(checkCode);
        Snackbar.showSnackbar("Check code copied to clipboard");
    }
}
