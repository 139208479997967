import { DataSource, Label, Layout, Location, Panel, Switch, Textbox } from "@mcleod/components";
import { StopNotesAndReferenceNumbers } from "../../../dispatch/src/StopNotesAndReferenceNumbers";
import { StopShowAsButton } from "../../../dispatch/src/StopShowAsButton";

export abstract class AutogenLayoutQuoteStop extends Layout {
    labelDelivery: Label;
    labelPickup: Label;
    labelSequence: Label;
    layoutStopNotesAndRefNbrs: StopNotesAndReferenceNumbers;
    layoutStopShowAsButton: StopShowAsButton;
    locationStop: Location;
    panel1: Panel;
    panelContainer: Panel;
    panelContent: Panel;
    panelStopCommentsReferenceNumbers: Panel;
    sourceQuoteStop: DataSource;
    switchPickupConsignee: Switch;
    textboxSchedArriveEarly: Textbox;
}
