/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelBrokerageTrackingCtrl.ts         *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/powerbroker/brokerage-tracking-ctrl";

export class AutogenModelBrokerageTrackingCtrl<RowType extends ModelRow> extends Endpoint<RowType, RowBrokerageTrackingCtrlFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowBrokerageTrackingCtrl extends ModelRow<RowBrokerageTrackingCtrlFields> {
    constructor(values?: Partial<RowBrokerageTrackingCtrlFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowBrokerageTrackingCtrlFields {
    account_id: string;
    allow_driver_update: string;
    auth_token: string;
    auto_send: string;
    automatic_tracking: string;
    broker_recipient_id: string;
    brokerage_status_filter: string;
    callin_frequency: number;
    carrier_updates: string;
    client_id: string;
    client_token: string;
    company_id: string;
    dont_save_docs_to_hist: string;
    dont_send_docs_imaging: string;
    enable_billto_cust_id: string;
    enable_coop_searching: string;
    export_address: string;
    force_cancel: string;
    icc_number: string;
    id: string;
    imaging_drop_path: string;
    import_address: string;
    inbound_call_info: string;
    interval_minutes: number;
    is_active: string;
    is_default: string;
    last_upload_date: Date;
    log_detail_level: number;
    master_user: string;
    mc_auto_dispatch: string;
    passwd: string;
    rate_conf_status_filter: string;
    require_tracking: string;
    send_email: string;
    shpmt_ident_type: string;
    track_interval_mins: string;
    tracking_duration_ext: number;
    tracking_hours_prior: number;
    tracking_url: string;
    use_vendor_eta: string;
    vendor: string;
    vendor_url: string;
    version: number;
}

