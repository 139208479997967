import { Table } from "@mcleod/components/src/components/table/Table";
import { TableRow } from "@mcleod/components/src/components/table/TableRow";
import { DataSource, DataSourceMode } from "@mcleod/components/src/databinding/DataSource";
import { TableRowDisplayEvent } from "@mcleod/components/src/events/TableRowDisplayEvent";
import { ModelRow } from "@mcleod/core";
import { ChangeStopCompareItems } from "./ChangeStopCompareItems";
import { AutogenLayoutCompareOrder2 } from "./autogen/AutogenLayoutCompareOrder2";

export class CompareOrder2 extends AutogenLayoutCompareOrder2 {
    public compareData: ModelRow[];

    public loadFromApiData(apiData: any, mode: DataSourceMode) {
        try {
            this.compareData = apiData;
            const changeGroups = new ModelRow("lme/datafusion/edi-compare-group", true, apiData);
            //set the stop data first so that when we set the order dataSource mode, we can not load the default 2 stops
            this.loadChildTableData(this.tableChangeGroups, this._getArrayAndNull(changeGroups, "change_groups"), mode);
            this.mainDataSource.setRowsAndMode(mode, [changeGroups], () => this._displayDataCallback(this.mainDataSource));
        }
        finally {
            //   ratesLayout.calculatingCharges = false
        }
    }

    onGroupRowDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.getTableRow();
        const layoutItems = tableRow.findComponentById((comp: any) => {
            if (comp instanceof ChangeStopCompareItems) {
                const compareItems: ChangeStopCompareItems = comp;
                const changeItems = new ModelRow("lme/datafusion/edi-compare-item", true, this.compareData["change_groups"][tableRow.index]);
                const rows = [];
                this._getArrayAndNull(changeItems, "change_items").forEach((element: any) => {
                    const newRow = new ModelRow("lme/datafusion/edi-compare-item", true, element);
                    rows.push(newRow);
                    // tableCompareItems.addRow(newRow, { mode: TableRowMode.NONE }, { display: true, addToData: true });
                });

                compareItems.setupTableData(rows);
            }
        });
    }

    private _displayDataCallback(dataSource: DataSource) {
    }

    private loadChildTableData(table: Table, data: any[], mode: DataSourceMode) {
        const tableDataSource = table.dataSource;
        tableDataSource.data = [];
        const modelRows = [];
        data.forEach((element: any) => {
            modelRows.push(new ModelRow(tableDataSource.url, true, element));
        });
        tableDataSource.setRowsAndMode(mode, modelRows);
    }


    private _getArrayAndNull(row: ModelRow, field: string): ModelRow[] {
        const result = row.get(field) || [];
        row.set(field, null);
        return result;
    }
}
