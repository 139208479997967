import { MovementCallins } from "../../../powerbroker/src/MovementCallins";
import { MovementCarrierPay } from "../../../powerbroker/src/MovementCarrierPay";
import { TopMatchList } from "../../../powerbroker/src/TopMatchList";
import { AssignedMovementOverview } from "../AssignedMovementOverview";
import { AvailableMovementOverview } from "../AvailableMovementOverview";
import { StopList } from "../StopList";
import { StopProgress } from "../StopProgress";
import {
    Button, CityState, DataHeader, DataSource, Label, Layout, Panel, Tab, Tabset, Textbox
} from "@mcleod/components";

export abstract class AutogenLayoutMovement extends Layout {
    buttonAssignCarrier: Button;
    buttonClassicMode: Button;
    buttonDispatch: Button;
    buttonOrderDetails: Button;
    buttonUsers: Button;
    dataheader1: DataHeader;
    labelAddress1: Label;
    labelDash: Label;
    labelDimensions: Label;
    labelHoldBadge: Label;
    labelMoveId: Label;
    labelMovementStatus: Label;
    labelOrdersId: Label;
    labelPendingAssignment: Label;
    labelTemperatureRange: Label;
    labelYsplitBadge: Label;
    layoutAssignedOverview: AssignedMovementOverview;
    layoutAvailableOverview: AvailableMovementOverview;
    layoutCarrierRates: MovementCarrierPay;
    layoutMovementCallins: MovementCallins;
    layoutStopList: StopList;
    layoutStopProgress: StopProgress;
    layoutTopMatchList: TopMatchList;
    panel6: Panel;
    panelCustomerInfo: Panel;
    panelDataHeaderAddlLeft: Panel;
    panelDataHeaderAddlRight: Panel;
    panelDimensions: Panel;
    panelDimensionsAndSurcharge: Panel;
    panelMovementStatus: Panel;
    panelMovementTabController: Panel;
    panelOverview: Panel;
    panelRates: Panel;
    sourceBrokerageTracking: DataSource;
    sourceCustomer: DataSource;
    sourceMovement: DataSource;
    sourceResponsibleHist: DataSource;
    tabOrderDetails: Tab;
    tabOverview: Tab;
    tabRates: Tab;
    tabTopMatch: Tab;
    tabTracking: Tab;
    tabsetMain: Tabset;
    textboxBrokerageStatus: Textbox;
    textboxCityId: CityState;
    textboxCustomerId: Textbox;
    textboxDimHeight: Textbox;
    textboxDimLength: Textbox;
    textboxDimSurcharge: Textbox;
    textboxDimWidth: Textbox;
    textboxOrderValue: Textbox;
    textboxRoundTrip: Textbox;
    textboxTeamsRequired: Textbox;
    textboxTemperatureMax: Textbox;
    textboxTemperatureMin: Textbox;
}
