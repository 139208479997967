/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelCurrentUserPowerbroker.ts        *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "common/current-user-powerbroker";

export class AutogenModelCurrentUserPowerbroker<RowType extends ModelRow> extends Endpoint<RowType, RowCurrentUserPowerbrokerFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowCurrentUserPowerbroker extends ModelRow<RowCurrentUserPowerbrokerFields> {
    constructor(values?: Partial<RowCurrentUserPowerbrokerFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowCurrentUserPowerbrokerFields {
    bp_profile: string;
    company_id: string;
    dat_password: string;
    dat_user_id: string;
    default_dispatch_layout: string;
    loadboard_dat_id: string;
    new_order_next_action: string;
    tm_profile: string;
    user_id: string;
    loadboard_truckstop_rest_id: string;
    access_token: string;
    refresh_token: string;
    token_expires: Date;
    truckstop_user_id: string;
}

