import { Layout } from "@mcleod/components";
import { Collection } from "@mcleod/core";

export class SingleComponentDemo extends Layout {
    propsForAllExamples: any;
    examples: Collection<ComponentDemoProperty>;

    constructor(props?) {
        super({ needsServerLayout: false, ...props });
    }

    public static getComponentDemoExamples(): Collection<ComponentDemoProperty> {
        return {
            cursor: {
                cases: [
                    { value: undefined },
                    { value: "pointer" },
                ]
            },

            enabled: {
                cases: [
                    { value: undefined },
                    { value: false },
                ]
            },

            padding: {
                cases: [
                    { value: 0 },
                    { value: 4 },
                    { value: 16 }
                ]
            },

            tooltip: {
                cases: [
                    { value: "Here is some helpful text to explain what to do with this component!" }
                ]
            },

            visible: {
                cases: [
                    { value: undefined },
                    { value: false },
                ]
            },
        }
    }
}

export interface ComponentDemoPropValueCase {
    value: any;
    description?: string;
    props?: any;
}

export interface ComponentDemoProperty {
    cases: ComponentDemoPropValueCase[];
    props?: any;
    propName?: string;
}
