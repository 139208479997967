import { DataSource, HorizontalSpacer, Layout, SearchButton, Table, Textbox, } from "@mcleod/components";

export abstract class AutogenLayoutCallinSearch extends Layout {
    buttonSearch: SearchButton;
    horizontalspacer1: HorizontalSpacer;
    sourceCallin: DataSource;
    tableCallin: Table;
    textboxMovementId: Textbox;
    textboxOrderId: Textbox;
}
