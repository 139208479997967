/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelBrokerageStatus.ts               *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/powerbroker/brokerage-status";

export class AutogenModelBrokerageStatus<RowType extends ModelRow> extends Endpoint<RowType, RowBrokerageStatusFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowBrokerageStatus extends ModelRow<RowBrokerageStatusFields> {
    constructor(values?: Partial<RowBrokerageStatusFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowBrokerageStatusFields {
    company_id: string;
    descr: string;
    display_callin_screen: string;
    display_portal: string;
    id: string;
    is_active: string;
    movement_status: string;
    planning_color: number;
    movement_id: string;
}

