import { Component, Container, deserializeComponents, TableCell } from "../..";
import { ComponentTypes } from "../../base/ComponentTypes";
import { Table } from "./Table";
import { TableCellProps } from "./TableCellProps";
import { TableColumnProps } from "./TableColumnProps";

export interface ColumnComponent {
    field: string;
    caption?: string;
    sortDescendingByDefault: boolean;
    sortNullsAtEnd: boolean;
}

export class TableColumn implements TableColumnProps {
    id: string;
    index: number;
    heading: TableCell | Partial<TableCellProps>;
    headingCell: TableCell;
    cell: any;
    cellDef: any;
    table: Table;

    constructor(props?: Partial<TableColumnProps>) {
        if (props != null)
            for (const key in props)
                this[key] = props[key];
    }

    public getFields(): string[] {
        const result: string[] = [];
        if (this.cellDef != null)
            this._addFieldsFromCellDef(result, this.cellDef.def);
        else if (typeof this.cell === "function")
            this._addFieldsFromComponents(result, this.cell());
        return result;
    }

    private _addFieldsFromCellDef(target: string[], def: any) {
        if (!ComponentTypes.getComponentType(def.type)?.isCompound) {
            if (def.field != null && !target.includes(def.field))
                target.push(def.field);
        }
        else {
            //we have to deserialize compound components to get their field list
            const compoundComponent = deserializeComponents(null, def, null, null, [], null)[0] as Component;
            const fields = compoundComponent.getFieldNames();
            if (fields != null) {
                for (const fieldName of fields) {
                    if (fieldName != null && !target.includes(fieldName))
                        target.push(fieldName);
                }
            }
        }
        if (def.components != null)
            for (const comp of def.components)
                this._addFieldsFromCellDef(target, comp);
    }

    private _addFieldsFromComponents(target: string[], components: any) {
        if (components instanceof Component) {
            if (components.field != null && !target.includes(components.field))
                target.push(components.field);
        }
        else if (components instanceof Container)
            this._addFieldsFromComponents(target, components.getRecursiveChildren());
        else if (Array.isArray(components)) {
            for (const comp of components) {
                this._addFieldsFromComponents(target, comp);
            }
        }
    }

    public getSortFields(): ColumnComponent[] {
        const result: ColumnComponent[] = [];
        if (this.cellDef != null)
            this._addSortFieldsFromCellDef(result, this.cellDef.def);
        else if (typeof this.cell === "function")
            this._addSortFieldsFromComponents(result, this.cell());
        return result;
    }

    private _addSortFieldsFromCellDef(target: ColumnComponent[], def: any) {
        if (!ComponentTypes.getComponentType(def.type)?.isCompound) {
            let fieldName = def.sortField;
            if (fieldName == null && def.field != null)
                fieldName = def.field;
            if (fieldName != null && (!Array.isArray(def.excludeFromSortFields) || !def.excludeFromSortFields.includes(fieldName)))
                this.pushColumnComponent(target, {
                    field: fieldName,
                    caption: def.displayLabel || def.caption,
                    sortDescendingByDefault: def.sortDescendingByDefault || false,
                    sortNullsAtEnd: def.sortNullsAtEnd || false
                });
        }
        else {
            //we have to deserialize compound components to get their field list
            const compoundComponent = deserializeComponents(null, def, null, null, [], null)[0] as Component;
            this.pushCompSortFields(target, compoundComponent);
        }
        if (def.components != null)
            for (const comp of def.components)
                this._addSortFieldsFromCellDef(target, comp);
    }

    private _addSortFieldsFromComponents(target: ColumnComponent[], components: Component | Component[]) {
        if (components instanceof Component)
            this.pushCompSortFields(target, components);
        else if (components instanceof Container)  // this condition will never pass because we checked instanceof Component already
            this._addSortFieldsFromComponents(target, components.getRecursiveChildren());
        else if (Array.isArray(components))
            for (const comp of components)
                this._addSortFieldsFromComponents(target, comp);
    }

    private pushCompSortFields(target: ColumnComponent[], comp: Component) {
        const sortFields = comp.getSortFieldNames();
        if (sortFields != null)
            for (const sortField of sortFields)
                if (!Array.isArray(comp.excludeFromSortFields) || !comp.excludeFromSortFields.includes(sortField))
                    this.pushColumnComponent(target, {
                        field: sortField,
                        caption: comp.getDisplayLabel(sortField, /*this.headingCell?.caption*/ ""), //we need to unify this logic for compound components, and use here, and in table filtering, and in export logic
                        sortDescendingByDefault: comp.sortDescendingByDefault,
                        sortNullsAtEnd: comp.sortNullsAtEnd
                    });
    }

    private pushColumnComponent(target: ColumnComponent[], comp: ColumnComponent) {
        if (comp?.field == null)
            return;
        for (const existing of target)
            if (existing.field === comp.field)
                return;
        target.push(comp);
    }
}
