import { Label, Textbox } from "@mcleod/components";
import { AutogenLayoutBrokeragePlanningLocationQuickInfo } from "./autogen/AutogenLayoutBrokeragePlanningLocationQuickInfo";

export class BrokeragePlanningLocationQuickInfo extends AutogenLayoutBrokeragePlanningLocationQuickInfo {

    /** This is an event handler for the onDisplay event of sourceOrderStop.  */
    sourceOrderStopOnDisplay(event) {
        if (this.mainDataSource.activeRow != null) {
            this.panelArrivalDeparture.visible = this.mainDataSource.activeRow.get("actual_arrival") != null;
            this.addReferenceNumbers();
        }
    }
    addReferenceNumbers() {
        const refNbrs = this.mainDataSource.activeRow.get("reference_numbers") || [];
        refNbrs.forEach(refNbr => {
            const textbox = new Textbox({ fillRow: true, caption: refNbr.description, text: refNbr.reference_number, printable: true, fontBold: true });
            this.panelRefNbrs.add(textbox);
        });

        if (this.panelRefNbrs.components.length == 0) {
            this.panelRefNbrs.paddingBottom = 0;
            this.panelRefNbrs.paddingTop = 0;
            this.panelRefNbrs.add(new Label({ caption: "--", padding: 0 }))
        }
    }
}
