/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelEdiOrder.ts                      *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/datafusion/edi-order";

export class AutogenModelEdiOrder<RowType extends ModelRow> extends Endpoint<RowType, RowEdiOrderFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowEdiOrder extends ModelRow<RowEdiOrderFields> {
    constructor(values?: Partial<RowEdiOrderFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowEdiOrderFields {
    agency_id: string;
    agent_payee_id: string;
    alt_partner_id: string;
    app_type: string;
    autorate_status: string;
    bill_date: Date;
    bill_distance: number;
    bill_distance_um: string;
    billing_empty_distance: number;
    billing_empty_distance_um: string;
    billing_loaded_distance: number;
    billing_loaded_distance_um: string;
    billing_user_id: string;
    blnum: string;
    bol_received: string;
    booking_no: string;
    booking_number: string;
    bplant: string;
    brokerage_status_color: string;
    cancelled_order: string;
    car_address: string;
    car_city: string;
    car_code: string;
    car_code_qualifier: string;
    car_name: string;
    car_pay_method: string;
    car_phone_number: string;
    car_pro_nbr: string;
    car_state: string;
    car_trip_lease: string;
    car_zip_code: string;
    carrier_id: string;
    carrier_tractor: string;
    carrier_trailer: string;
    changed_order: string;
    collection_method: string;
    commitment_id: string;
    commodity: string;
    commodity_id: string;
    company_id: string;
    complete_document: string;
    cons_ediloc: string;
    consignee_ediloc: string;
    consignee_refno: string;
    consignee_stop_id: string;
    container_planned: string;
    container_type_id: string;
    copy_from_company: string;
    copy_from_order_id: string;
    create_date: Date;
    ctrl_party_id: string;
    cube: number;
    curr_movement_id: string;
    currency_iso_id: string;
    currency_type_id: string;
    cust_order_no: string;
    customer_ediloc: string;
    customer_id: string;
    default_match_id: string;
    dim_height: string;
    dim_length: string;
    dim_surcharge: number;
    dim_surcharge_c: string;
    dim_surcharge_d: Date;
    dim_surcharge_n: number;
    dim_surcharge_r: number;
    dim_width: string;
    direction: string;
    dispatcher_user_id: string;
    dray_del_date: Date;
    dray_del_pay: number;
    dray_del_pay_c: string;
    dray_del_pay_d: Date;
    dray_del_pay_n: number;
    dray_del_pay_r: number;
    dray_del_payee: string;
    dray_del_xfer_dt: Date;
    dray_pu_date: Date;
    dray_pu_pay: number;
    dray_pu_pay_c: string;
    dray_pu_pay_d: Date;
    dray_pu_pay_n: number;
    dray_pu_pay_r: number;
    dray_pu_payee: string;
    dray_pu_xfer_dt: Date;
    edi_commodity_id: string;
    edi_equip_type_id: string;
    edi_error: number;
    edi_order_type_id: string;
    edi_revenue_code: string;
    edi_seg_alloc_code: string;
    eir1_no: string;
    eir2_no: string;
    entered_user_id: string;
    equipment_type_id: string;
    error_count: string;
    est_tolls: number;
    est_tolls_c: string;
    est_tolls_d: Date;
    est_tolls_n: number;
    est_tolls_r: number;
    external_invoiceno: string;
    extra_deliveries: number;
    extra_pickups: number;
    fleet_manager: string;
    forwd_location_id: string;
    free_days: number;
    freight_charge: number;
    freight_charge_c: string;
    freight_charge_d: Date;
    freight_charge_n: number;
    freight_charge_r: number;
    fuel_distance: number;
    fuel_distance_um: string;
    func_ack_batch: number;
    func_ack_id: string;
    func_ack_rec: string;
    func_ack_rec_date: Date;
    func_ack_received: string;
    func_ack_status: string;
    gs06_group_cntlno: string;
    gs_date_time: Date;
    hazmat: string;
    hazmat_code: string;
    high_value: string;
    hold_reason: string;
    id: string;
    ignore_map_errors: string;
    image_is_present: string;
    import_export: string;
    include_split_point: string;
    initial_purpose: string;
    intercompany: string;
    intercompany_key: string;
    invoice_recv_date: Date;
    is_autorate_dist: string;
    is_billed: boolean;
    is_container: string;
    is_dedicated: string;
    is_dray: string;
    is_local_mile: string;
    isa13_intr_cntlno: string;
    jag_carrier_id: string;
    jag_cost_rate_id: number;
    ldport_location_id: string;
    linear_feet_avail: number;
    load_date: Date;
    lock_miles: string;
    ltl: string;
    manifest_id: string;
    "movement.authorized": string;
    "movement.average_broker_rating": number;
    "movement.booking_no": string;
    "movement.br_details_link": string;
    "movement.br_status_link": string;
    "movement.br_track_interval": number;
    "movement.br_track_status": string;
    "movement.br_track_status_desc": string;
    "movement.br_track_vehicle_id": string;
    "movement.br_tracking_id": string;
    "movement.br_vendor": string;
    "movement.broker_avail_tract": string;
    "movement.brokerage": string;
    "movement.brokerage_status": string;
    "movement.carrier_contact": string;
    "movement.carrier_email": string;
    "movement.carrier_empty_from_city_id": number;
    "movement.carrier_fuel_pay_rate_id": number;
    "movement.carrier_lane_rate_id": string;
    "movement.carrier_override_carrier": string;
    "movement.carrier_override_user": string;
    "movement.carrier_phone": string;
    "movement.carrier_rate_id": number;
    "movement.carrier_tractor": string;
    "movement.carrier_trailer": string;
    "movement.carrier_trailer_type": string;
    "movement.chassis": string;
    "movement.checkcall_sched_id": string;
    "movement.company_id": string;
    "movement.container": string;
    "movement.container_returned": string;
    "movement.container_status": string;
    "movement.controlling_carrier_code": string;
    "movement.dedicated_order_id": string;
    "movement.dest_stop_id": string;
    "movement.digital_booking": string;
    "movement.dispatcher_user_id": string;
    "movement.eform_rate_confirmation": string;
    "movement.empty2next_order": string;
    "movement.equipment_group_id": string;
    "movement.est_tolls": number;
    "movement.est_tolls_c": string;
    "movement.est_tolls_d": Date;
    "movement.est_tolls_n": number;
    "movement.est_tolls_r": number;
    "movement.exclude_movement": string;
    "movement.fleet_manager": string;
    "movement.freight_matching_override": string;
    "movement.fuel_distance": number;
    "movement.fuel_distance_um": string;
    "movement.fuel_tax_exempt": string;
    "movement.hubtran_approved_inv_date": Date;
    "movement.hubtran_ok2pay_date": Date;
    "movement.ic_company_to": string;
    "movement.ic_order_to": string;
    "movement.id": string;
    "movement.integrated_carrier_search": string;
    "movement.invoice_recv_date": Date;
    "movement.is_container": string;
    "movement.is_dray": string;
    "movement.is_intercompany": string;
    "movement.is_local_mile": string;
    "movement.linked_id": number;
    "movement.linked_sequence": number;
    "movement.load_booking_eligible": string;
    "movement.load_booking_source": string;
    "movement.loaded": string;
    "movement.locked_user_id": string;
    "movement.ltl": string;
    "movement.ltl_staging_wrs_uid": number;
    "movement.macropoint_status": string;
    "movement.manifest_empty_distance": number;
    "movement.manifest_estimated_arrival": Date;
    "movement.manifest_estimated_depart": Date;
    "movement.manifest_id": string;
    "movement.manifest_loaded_distance": number;
    "movement.manifest_reg_uid": number;
    "movement.manifest_spots_capacity": number;
    "movement.manifest_spots_consumed": number;
    "movement.manifest_weight_capacity": number;
    "movement.manifest_weight_consumed": number;
    "movement.max_buy": number;
    "movement.max_buy_c": string;
    "movement.max_buy_d": Date;
    "movement.max_buy_n": number;
    "movement.max_buy_r": number;
    "movement.max_pay_method": string;
    "movement.max_pay_units": number;
    "movement.missed_call_sent": string;
    "movement.move_distance": number;
    "movement.move_distance_um": string;
    "movement.movement_type": string;
    "movement.must_respond_by": Date;
    "movement.next_sched_call": Date;
    "movement.operational_status": string;
    "movement.operations_user": string;
    "movement.origin_stop_id": string;
    "movement.override_crny_type": string;
    "movement.override_driver2_id": number;
    "movement.override_driver2_nm": string;
    "movement.override_driver_id": number;
    "movement.override_driver_nm": string;
    "movement.override_drvr2_cell": string;
    "movement.override_drvr2_email": string;
    "movement.override_drvr_cell": string;
    "movement.override_drvr_email": string;
    "movement.override_max_pay": number;
    "movement.override_max_pay_c": string;
    "movement.override_max_pay_d": Date;
    "movement.override_max_pay_n": number;
    "movement.override_max_pay_r": number;
    "movement.override_pay_amt": number;
    "movement.override_pay_amt_c": string;
    "movement.override_pay_amt_d": Date;
    "movement.override_pay_amt_n": number;
    "movement.override_pay_amt_r": number;
    "movement.override_pay_rate": number;
    "movement.override_paye_rate": number;
    "movement.override_payee_id": string;
    "movement.override_targetpay": number;
    "movement.override_targetpay_c": string;
    "movement.override_targetpay_d": Date;
    "movement.override_targetpay_n": number;
    "movement.override_targetpay_r": number;
    "movement.override_type": string;
    "movement.override_unit_desc": string;
    "movement.override_units": number;
    "movement.pay_distance": number;
    "movement.pay_distance_um": string;
    "movement.pending_carrier_name": string;
    "movement.pending_carrier_nbr": string;
    "movement.pending_carrier_type": string;
    "movement.pending_payee_id": string;
    "movement.placard_required": string;
    "movement.preassign_sequence": number;
    "movement.priority": string;
    "movement.pro_nbr": string;
    "movement.rate_alert_basis": string;
    "movement.rate_alert_date": Date;
    "movement.rate_alert_days": number;
    "movement.rate_alert_hours": number;
    "movement.rate_alert_minutes": number;
    "movement.rate_confirm_template": number;
    "movement.rate_confirmation_sent_date": Date;
    "movement.rate_confirmation_status": string;
    "movement.rate_expiration_basis": string;
    "movement.rate_expiration_date": Date;
    "movement.rate_expiration_days": number;
    "movement.rate_expiration_hours": number;
    "movement.rate_expiration_minutes": number;
    "movement.rdy_pickup_date": Date;
    "movement.recurring_movement_id": string;
    "movement.reminder_sent": string;
    "movement.require_tracking": string;
    "movement.required_vendor": string;
    "movement.reserved": string;
    "movement.return_date": Date;
    "movement.seg_alloc_code": string;
    "movement.shipper_email": string;
    "movement.shipper_notify_frequency": string;
    "movement.shpmt_ident_type": string;
    "movement.shpmt_ident_value": string;
    "movement.ss_location_id": string;
    "movement.status": string;
    "movement.target_extra_stop_pay": number;
    "movement.target_extra_stop_pay_c": string;
    "movement.target_extra_stop_pay_d": Date;
    "movement.target_extra_stop_pay_n": number;
    "movement.target_extra_stop_pay_r": number;
    "movement.target_fuel_surcharge_pay": string;
    "movement.target_pay": number;
    "movement.target_pay_c": string;
    "movement.target_pay_d": Date;
    "movement.target_pay_method": string;
    "movement.target_pay_n": number;
    "movement.target_pay_r": number;
    "movement.target_pay_units": number;
    "movement.target_rate_data": string;
    "movement.target_rate_id": number;
    "movement.time_to_send_msg": Date;
    "movement.track_start_date": Date;
    "movement.tracking_duration_ext": number;
    "movement.tracking_notify_email": string;
    "movement.tracking_request_status": string;
    "movement.trailer_rent_pct": number;
    "movement.trip_lease": string;
    "movement.trip_mgt_recalc_datetime": Date;
    "movement.trip_mgt_tracking_id": string;
    "movement.triumphpay_exclude": string;
    "movement.trp_uid": number;
    "movement.ts_borrowing_company": string;
    "movement.ts_commodity": string;
    "movement.ts_movement_id": string;
    "movement.unlock_time": Date;
    "movement.waterfall_flood_time": Date;
    "movement.waterfall_in_progress": string;
    "movement.xfer2settle_date": Date;
    "movement.xferred2fueltax": string;
    "movement.xferred2fueltaxdt": Date;
    "movement.xmit_accepted": string;
    "movement.xmitted2driver": Date;
    "movement.zmit_trip": string;
    no_display: string;
    nte_lp_num: string;
    nte_lp_qual: string;
    on_hold: string;
    operational_status: string;
    operations_user: string;
    order_compared: string;
    order_id: string;
    order_mode: string;
    order_type_id: string;
    order_value: number;
    order_value_c: string;
    order_value_d: Date;
    order_value_n: number;
    order_value_r: number;
    ordered_by: string;
    ordered_date: Date;
    ordered_method: string;
    "orders.actual_reefer_profile": string;
    "orders.agency_id": string;
    "orders.agent_payee_id": string;
    "orders.allow_relay": string;
    "orders.autorate_status": string;
    "orders.bill_date": Date;
    "orders.bill_distance": number;
    "orders.bill_distance_um": string;
    "orders.billing_empty_distance": number;
    "orders.billing_empty_distance_um": string;
    "orders.billing_loaded_distance": number;
    "orders.billing_loaded_distance_um": string;
    "orders.billing_user_id": string;
    "orders.blnum": string;
    "orders.bol_note": string;
    "orders.bol_received": string;
    "orders.bol_recv_date": Date;
    "orders.booking_no": string;
    "orders.booking_number": string;
    "orders.brk_qual_profile": string;
    "orders.broker_location_id": string;
    "orders.brokerage_workflow_id": string;
    "orders.cbp_crossing_date": Date;
    "orders.cbp_crossing_location_id": string;
    "orders.cbp_crossing_reason": string;
    "orders.cbsa_crossing_date": Date;
    "orders.cbsa_crossing_location_id": string;
    "orders.cbsa_crossing_reason": string;
    "orders.cc_fuel_revenue": number;
    "orders.cc_fuel_revenue_c": string;
    "orders.cc_fuel_revenue_d": Date;
    "orders.cc_fuel_revenue_n": number;
    "orders.cc_fuel_revenue_r": number;
    "orders.cc_net_revenue": number;
    "orders.cc_net_revenue_c": string;
    "orders.cc_net_revenue_d": Date;
    "orders.cc_net_revenue_n": number;
    "orders.cc_net_revenue_r": number;
    "orders.cc_pro_nbr": string;
    "orders.cobroker_location_id": string;
    "orders.collection_method": string;
    "orders.com_setpnt_temp": number;
    "orders.com_setpnt_temp_um": string;
    "orders.commitment_id": string;
    "orders.commodity": string;
    "orders.commodity_id": string;
    "orders.company_id": string;
    "orders.consignee_refno": string;
    "orders.consignee_stop_id": string;
    "orders.container": string;
    "orders.container_planned": string;
    "orders.container_type_id": string;
    "orders.controlling_carrier_code": string;
    "orders.copy_from_company": string;
    "orders.copy_from_order_id": string;
    "orders.copy_to_company": string;
    "orders.copy_to_order_id": string;
    "orders.ctrl_party_id": string;
    "orders.cube": number;
    "orders.curr_movement_id": string;
    "orders.cust_order_no": string;
    "orders.customer_id": string;
    "orders.declared_val": number;
    "orders.def_move_type": string;
    "orders.default_match_id": string;
    "orders.dim_height": string;
    "orders.dim_length": string;
    "orders.dim_surcharge": number;
    "orders.dim_surcharge_c": string;
    "orders.dim_surcharge_d": Date;
    "orders.dim_surcharge_n": number;
    "orders.dim_surcharge_r": number;
    "orders.dim_width": string;
    "orders.dispatch_opt": string;
    "orders.dl_team_reqd": string;
    "orders.dray_del_date": Date;
    "orders.dray_del_pay": number;
    "orders.dray_del_pay_c": string;
    "orders.dray_del_pay_d": Date;
    "orders.dray_del_pay_n": number;
    "orders.dray_del_pay_r": number;
    "orders.dray_del_payee": string;
    "orders.dray_del_xfer_dt": Date;
    "orders.dray_pu_date": Date;
    "orders.dray_pu_pay": number;
    "orders.dray_pu_pay_c": string;
    "orders.dray_pu_pay_d": Date;
    "orders.dray_pu_pay_n": number;
    "orders.dray_pu_pay_r": number;
    "orders.dray_pu_payee": string;
    "orders.dray_pu_xfer_dt": Date;
    "orders.eir1_no": string;
    "orders.eir2_no": string;
    "orders.entered_user_id": string;
    "orders.equipment_type_id": string;
    "orders.equipment_type_options": string;
    "orders.est_tolls": number;
    "orders.est_tolls_c": string;
    "orders.est_tolls_d": Date;
    "orders.est_tolls_n": number;
    "orders.est_tolls_r": number;
    "orders.excise_disable_update": string;
    "orders.excise_taxable": string;
    "orders.excisetax_total": number;
    "orders.excisetax_total_c": string;
    "orders.excisetax_total_d": Date;
    "orders.excisetax_total_n": number;
    "orders.excisetax_total_r": number;
    "orders.external_invoiceno": string;
    "orders.extra_deliveries": number;
    "orders.extra_pickups": number;
    "orders.floor_loaded_freight": string;
    "orders.force_assign": string;
    "orders.forwd_location_id": string;
    "orders.free_days": number;
    "orders.freight_charge": number;
    "orders.freight_charge_c": string;
    "orders.freight_charge_d": Date;
    "orders.freight_charge_n": number;
    "orders.freight_charge_r": number;
    "orders.hazmat": string;
    "orders.hazmat_code": string;
    "orders.high_value": string;
    "orders.hold_reason": string;
    "orders.ic_company_from": string;
    "orders.ic_movement_from": string;
    "orders.ic_order_from": string;
    "orders.id": string;
    "orders.image_is_present": string;
    "orders.import_export": string;
    "orders.include_split_point": string;
    "orders.intelliset": string;
    "orders.invoice_recv_date": Date;
    "orders.is_auto_tonu": string;
    "orders.is_autorate_dist": string;
    "orders.is_container": string;
    "orders.is_dedicated": string;
    "orders.is_intercompany": string;
    "orders.is_local_mile": string;
    "orders.is_requested": string;
    "orders.is_tonu": string;
    "orders.jag_carrier_id": string;
    "orders.jag_cost_rate_id": number;
    "orders.ldport_location_id": string;
    "orders.linear_feet_avail": number;
    "orders.load_date": Date;
    "orders.loadboard": string;
    "orders.lock_miles": string;
    "orders.ltl": string;
    "orders.markup_flat": number;
    "orders.markup_flat_c": string;
    "orders.markup_flat_d": Date;
    "orders.markup_flat_n": number;
    "orders.markup_flat_r": number;
    "orders.markup_percent": number;
    "orders.master_order_id": string;
    "orders.master_sequence": number;
    "orders.next_rebill": string;
    "orders.nte_lp_num": string;
    "orders.nte_lp_qual": string;
    "orders.on_hold": string;
    "orders.operational_status": string;
    "orders.operations_user": string;
    "orders.optimize": string;
    "orders.optiset": string;
    "orders.order_mode": string;
    "orders.order_type_id": string;
    "orders.order_value": number;
    "orders.order_value_c": string;
    "orders.order_value_d": Date;
    "orders.order_value_n": number;
    "orders.order_value_r": number;
    "orders.ordered_by": string;
    "orders.ordered_by_id": string;
    "orders.ordered_date": Date;
    "orders.ordered_method": string;
    "orders.orig_dest_rate_id": string;
    "orders.otherchargetotal": number;
    "orders.otherchargetotal_c": string;
    "orders.otherchargetotal_d": Date;
    "orders.otherchargetotal_n": number;
    "orders.otherchargetotal_r": number;
    "orders.override_posting_city_id": number;
    "orders.pallets_how_many": number;
    "orders.pallets_required": string;
    "orders.pay_gross": number;
    "orders.pay_gross_c": string;
    "orders.pay_gross_d": Date;
    "orders.pay_gross_n": number;
    "orders.pay_gross_r": number;
    "orders.pick_up_no": string;
    "orders.pieces": number;
    "orders.placard_required": string;
    "orders.planning_comment": string;
    "orders.pnn_callback": string;
    "orders.pnn_comment": string;
    "orders.pnn_comment2": string;
    "orders.pnn_loadboard_user_id": string;
    "orders.pnn_post_type": string;
    "orders.pnn_rate": number;
    "orders.pnn_rate_type": string;
    "orders.pnn_trailer_length": number;
    "orders.pnn_trailer_width": number;
    "orders.ponum": string;
    "orders.port": string;
    "orders.port_of_discharge": string;
    "orders.preload_trailer_id": string;
    "orders.preloaded": string;
    "orders.rate": number;
    "orders.rate_currency_type": string;
    "orders.rate_id": number;
    "orders.rate_min_weight": number;
    "orders.rate_min_weight_um": string;
    "orders.rate_source": string;
    "orders.rate_type": string;
    "orders.rate_unit_desc": string;
    "orders.rate_units": number;
    "orders.ready_to_bill": string;
    "orders.recurring_order_id": string;
    "orders.reply_transmitted": string;
    "orders.requesting_company": string;
    "orders.return_temp": number;
    "orders.return_temp_um": string;
    "orders.return_temp_var": number;
    "orders.return_temp_var_um": string;
    "orders.revenue_code_id": string;
    "orders.revenue_share_method": string;
    "orders.round_trip": string;
    "orders.sail_date": Date;
    "orders.seal_number": string;
    "orders.seg_alloc_code": string;
    "orders.setpoint_temp": number;
    "orders.setpoint_temp_um": string;
    "orders.setpoint_var": number;
    "orders.setpoint_var_um": string;
    "orders.ship_status_to_edi": string;
    "orders.shipment_id": string;
    "orders.shipper_stop_id": string;
    "orders.shipstatus2edi_dt": Date;
    "orders.ss_location_id": string;
    "orders.status": string;
    "orders.subject_order_number": string;
    "orders.subject_order_status": string;
    "orders.subject_order_void_date": Date;
    "orders.swap": string;
    "orders.teams_required": string;
    "orders.temperature_max": number;
    "orders.temperature_max_um": string;
    "orders.temperature_min": number;
    "orders.temperature_min_um": string;
    "orders.total_charge": number;
    "orders.total_charge_c": string;
    "orders.total_charge_d": Date;
    "orders.total_charge_n": number;
    "orders.total_charge_r": number;
    "orders.totalcharge_and_excisetax": number;
    "orders.totalcharge_and_excisetax_c": string;
    "orders.totalcharge_and_excisetax_d": Date;
    "orders.totalcharge_and_excisetax_n": number;
    "orders.totalcharge_and_excisetax_r": number;
    "orders.tractor_type": string;
    "orders.vessel": string;
    "orders.vessel_cutoff_date": Date;
    "orders.voided_load_transmitted": string;
    "orders.voided_load_transmitted_dt": Date;
    "orders.weight": number;
    "orders.weight_um": string;
    "orders.xferred2billing": string;
    "orders.xferred2billing_dt": Date;
    orig_dest_rate_id: string;
    original_order: string;
    other_pay_total: number;
    other_pay_total_c: string;
    other_pay_total_d: Date;
    other_pay_total_n: number;
    other_pay_total_r: number;
    otherchargetotal: number;
    otherchargetotal_c: string;
    otherchargetotal_d: Date;
    otherchargetotal_n: number;
    otherchargetotal_r: number;
    override_pay_amt: number;
    override_pay_amt_c: string;
    override_pay_amt_d: Date;
    override_pay_amt_n: number;
    override_pay_amt_r: number;
    override_pay_rate: number;
    override_pay_units: number;
    override_type: string;
    override_units: number;
    pallets_how_many: number;
    pallets_required: string;
    partner_id: string;
    partner_name: string;
    pay_gross: number;
    pay_gross_c: string;
    pay_gross_d: Date;
    pay_gross_n: number;
    pay_gross_r: number;
    pick_up_no: string;
    pieces: number;
    placard_required: string;
    planning_comment: string;
    port_of_discharge: string;
    preload_trailer_id: string;
    preloaded: string;
    purpose: string;
    purpose_descr: string;
    purpose_type: string;
    rate: number;
    rate_currency_type: string;
    rate_id: number;
    rate_min_weight: number;
    rate_min_weight_um: string;
    rate_type: string;
    rate_unit_desc: string;
    rate_units: number;
    rbd_timezone_id: string;
    ready_to_bill: string;
    ready_to_send: string;
    record_in_use: string;
    recurring_order_id: string;
    reply_action: string;
    reply_action_descr: string;
    reply_altpartnerid: string;
    reply_batch_id: number;
    reply_created: string;
    reply_date: Date;
    reply_error: number;
    reply_error_descr: string;
    reply_gs_date_time: Date;
    reply_partner_id: string;
    reply_reason: string;
    reply_received: string;
    reply_received_ts: string;
    reply_remarks: string;
    reply_st02: string;
    reply_tid: number;
    reply_trans_count: number;
    reply_transmitted: string;
    reply_version: string;
    require_tracking: string;
    required_vendor: string;
    respond_by_date: Date;
    revenue_code_id: string;
    revenue_share_method: string;
    rf_entry_code_required: boolean;
    round_trip: string;
    sail_date: Date;
    scac: string;
    seal_number: string;
    seg_alloc_code: string;
    ship_status_to_edi: string;
    shipment_id: string;
    shipper_ediloc: string;
    shipper_stop_id: string;
    shipstatus2edi_dt: Date;
    show_equipment_type_options: boolean;
    ss_location_id: string;
    st02_trxset_cntlno: string;
    status: string;
    "stop_origin.actual_arrival": Date;
    "stop_origin.actual_departure": Date;
    "stop_origin.address": string;
    "stop_origin.address2": string;
    "stop_origin.appointment_status_id": string;
    "stop_origin.appt_contact_name": string;
    "stop_origin.appt_required": string;
    "stop_origin.cases": number;
    "stop_origin.city_id": number;
    "stop_origin.city_name": string;
    "stop_origin.company_id": string;
    "stop_origin.confirmed": string;
    "stop_origin.contact_email": string;
    "stop_origin.contact_name": string;
    "stop_origin.dist_from_prev": number;
    "stop_origin.dist_from_prev_um": string;
    "stop_origin.driver_load_unload": string;
    "stop_origin.edi_code": string;
    "stop_origin.edi_loc_code": string;
    "stop_origin.eta": Date;
    "stop_origin.eta_distance": number;
    "stop_origin.eta_late": string;
    "stop_origin.eta_oor": string;
    "stop_origin.google_place_id": string;
    "stop_origin.grouped_id": number;
    "stop_origin.hub": number;
    "stop_origin.ic_stop_id": string;
    "stop_origin.id": string;
    "stop_origin.inbound_status": string;
    "stop_origin.late_eta_colorcode": string;
    "stop_origin.late_route_status": string;
    "stop_origin.latitude": number;
    "stop_origin.location_id": string;
    "stop_origin.location_name": string;
    "stop_origin.longitude": number;
    "stop_origin.manifest_fgp_uid": number;
    "stop_origin.manifest_reg_uid": number;
    "stop_origin.move_dist_from_preassign": number;
    "stop_origin.move_dist_from_preassign_um": string;
    "stop_origin.move_dist_from_previous": number;
    "stop_origin.move_dist_from_previous_um": string;
    "stop_origin.movement_id": string;
    "stop_origin.movement_sequence": number;
    "stop_origin.operational_status": string;
    "stop_origin.order_id": string;
    "stop_origin.order_sequence": number;
    "stop_origin.orig_sched_early": Date;
    "stop_origin.orig_sched_late": Date;
    "stop_origin.pallets_dropped": number;
    "stop_origin.pallets_picked_up": number;
    "stop_origin.payment_code_reviewed": string;
    "stop_origin.phone": string;
    "stop_origin.planned_arrival_time": Date;
    "stop_origin.ponum": string;
    "stop_origin.prior_uncleared_stops": string;
    "stop_origin.projected_arrival": Date;
    "stop_origin.pu_override_arrive_early": Date;
    "stop_origin.pu_override_arrive_late": Date;
    "stop_origin.rate_dist_from_previous": number;
    "stop_origin.rate_dist_from_previous_um": string;
    "stop_origin.refno": string;
    "stop_origin.requested_service": string;
    "stop_origin.sched_arrive_early": Date;
    "stop_origin.sched_arrive_late": Date;
    "stop_origin.seal": string;
    "stop_origin.service_date": Date;
    "stop_origin.showas_address": string;
    "stop_origin.showas_address2": string;
    "stop_origin.showas_city_id": number;
    "stop_origin.showas_city_name": string;
    "stop_origin.showas_location_id": string;
    "stop_origin.showas_location_name": string;
    "stop_origin.showas_state": string;
    "stop_origin.showas_zip_code": string;
    "stop_origin.signed_for_name": string;
    "stop_origin.state": string;
    "stop_origin.status": string;
    "stop_origin.stop_type": string;
    "stop_origin.trimble_place_id": string;
    "stop_origin.txl_uid": number;
    "stop_origin.volume": number;
    "stop_origin.volume_um": string;
    "stop_origin.weight": number;
    "stop_origin.weight_um": string;
    "stop_origin.zip_code": string;
    "stop_origin.zone_id": string;
    teams_required: string;
    temperature_max: number;
    temperature_max_um: string;
    temperature_min: number;
    temperature_min_um: string;
    tender_ignored: string;
    tender_status: string;
    total_charge: number;
    total_charge_c: string;
    total_charge_d: Date;
    total_charge_n: number;
    total_charge_r: number;
    total_pay: number;
    total_pay_c: string;
    total_pay_d: Date;
    total_pay_n: number;
    total_pay_r: number;
    tractor_type: string;
    trans_count: number;
    transaction_set: string;
    transit_warning_id: string;
    transmission_status: string;
    ts_borrowing_company: string;
    ts_commodity: string;
    user_id: string;
    version: string;
    vessel: string;
    vessel_cutoff_date: Date;
    warning_count: string;
    weight: number;
    weight_um: string;
    xferred2billing: string;
    xferred2billing_dt: Date;
    days_back: number;
    movement_id: string;
}

