import { AutogenModelBrokerRating, AutogenRowBrokerRating } from "./autogen/AutogenModelBrokerRating";

export class ModelBrokerRating extends AutogenModelBrokerRating<RowBrokerRating> {
    constructor() {
        super(RowBrokerRating);
    }
}

export class RowBrokerRating extends AutogenRowBrokerRating {
}
