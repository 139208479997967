import { Button, ClickEvent, DataSource, Layout, SlideoutDecorator, Textbox } from "@mcleod/components";
import { ModelRow, getUserSettings } from "@mcleod/core";
import { getDispatchControlValue } from "@mcleod/general/src/models/ModelDispatchControl";
import { ModelReportTemplate } from "@mcleod/general/src/models/ModelReportTemplate";
import { AutogenLayoutQuoteSendEmail } from "./autogen/AutogenLayoutQuoteSendEmail";

export class QuoteSendEmail extends AutogenLayoutQuoteSendEmail {
    private doOnSlideoutClosed: (cancelled: boolean) => void;

    public static show(props: QuoteSendEmailProps) {
        const layout = Layout.getLayout("lme/powerbroker/QuoteSendEmail") as QuoteSendEmail;
        layout.addLayoutLoadListener(async () => {
            const rowEmail = new ModelRow("lme/dispatch/send-quote-email", false);
            layout.doOnSlideoutClosed = props.doOnSlideoutClosed;
            const rowQuote = props.sourceQuote.data[0];
            const sourceStop = props.sourceQuote.childDataSources.find(ds => ds.id === "sourceQuoteStop");
            const shipperStop = sourceStop.data[0];
            const consigneeStop = sourceStop.data[sourceStop.data.length - 1];

            const quoteID = rowQuote.get("id", "");
            const fromCityState = `${shipperStop.get("city_name")}, ${shipperStop.get("state")}`;
            const toCityState = `${consigneeStop.get("city_name")}, ${consigneeStop.get("state")}`;

            // set default values
            rowEmail.set("to_address", rowQuote.get("customer_email", null));
            rowEmail.set("subject", `Quote ${quoteID} for ${fromCityState} to ${toCityState}`);
            rowEmail.set("plain_text", rowQuote.get("notes"));
            const contactName = rowQuote.get("customer_contact_name", null)
            rowEmail.setLookupModelData("contact_name", rowQuote.getFirstLookupModelData("customer_contact_name"));
            rowEmail.set("contact_name", contactName);
            rowEmail.set("order_id", quoteID);
            rowEmail.set("is_ltl", props.isLtl);

            const templateNum = props.isLtl ? getUserSettings().ltl_quote_template ?? getDispatchControlValue("brk_quote_template") : getUserSettings().quote_template;
            if (templateNum != null) {
                new ModelReportTemplate().searchSingle({ template_number: templateNum, document_type: rowEmail.get("is_ltl") ? "Y" : "Q" }).then(tempRow => {
                    rowEmail.setLookupModelData("template_num", tempRow)
                    layout.mainDataSource.displayDataInBoundComponents();
                });
                rowEmail.set("template_num", templateNum);
            }

            layout.mainDataSource.addRow(rowEmail, 0, true);
            layout.mainDataSource.rowIndex = 0;

            layout.mainDataSource.addAfterExecutionListener(() => layout.closeOverlay(false));

            // show the slideout
            new SlideoutDecorator({
                layout: layout,
                width: 1200,
                zIndex: 5,
                fillVerticalSpace: true,
                backgroundColor: "defaultBackground",
                title: "Send Email",
                addlComponents: [layout.getSendButton()],
                onClose: (cancelled: boolean) => layout.closeOverlay(cancelled),
                ...props
            });
        });
    }

    private getSendButton(): Button {
        return new Button({
            caption: "Send",
            width: 150,
            paddingLeft: 20,
            paddingRight: 20,
            rowBreak: false,
            color: "default.reverse",
            backgroundColor: "primary",
            borderWidth: 0,
            busyWhenDataSourceBusy: true,
            dataSource: this.mainDataSource,
            onClick: async (event: ClickEvent) => {
                this.mainDataSource.post();
            }
        });
    }

    public async closeOverlay(cancelled: boolean) {
        this.slideOut().then(() => {
            if (this.doOnSlideoutClosed != null)
                this.doOnSlideoutClosed(cancelled);
        });
    }

    textboxTemplateNumBeforeLookupModelSearch(event) {
        event.filter.document_type = this.activeRow.get("is_ltl") ? "Y" : "Q";
        event.filter.template_number = ">0";
    }

    textboxContactNameBeforeLookupModelSearch(event) {
        const contactName = event.target as Textbox;
        event.filter.parent_row_id = this.activeRow.get("customer_id") ?? null;
        event.filter.parent_row_type = "C";
        event.filter.is_active = "Y";

        if (contactName != null && contactName.onSelectItem == null) {
            contactName.onSelectItem = ((textbox, selection) => {
                if (selection != null) {
                    this.activeRow.set("email", (selection as ModelRow).get("to_address"));
                    this.mainDataSource.displayDataInBoundComponents();
                }
                return undefined;
            });
        }
    }
}

export interface QuoteSendEmailProps {
    sourceQuote: DataSource;
    doOnSlideoutClosed?: (cancelled: boolean) => void;
    isLtl?: boolean;
}
