import { BlurEvent, Button, ChangeEvent, Checkbox, ClickEvent, ForcedCase, Layout, Panel, SlideoutDecorator, TableRow, Textbox } from "@mcleod/components";
import { DataSource, DataSourceMode, ModelDataChangeType } from "@mcleod/components/src/databinding/DataSource";
import { Alignment, DOMUtil, DateUtil, DisplayType, ModelRow } from "@mcleod/core";
import { AutogenLayoutOrderHandlingRequirementsSlideout } from "./autogen/AutogenLayoutOrderHandlingRequirementsSlideout";

const inputProps = { paddingTop: 0, paddingLeft: 30, captionVisibleInsideTable: true, width: undefined };

interface OrderHandlingRequirementsSlideoutProps {
    hdrDataSource: DataSource;
    hdrChanged: (row: ModelRow<any>, type: ModelDataChangeType) => void;
    onSave: (hasChanged: boolean) => void;
    invokedFromEdi: boolean;
}

export class OrderHandlingRequirementsSlideout extends AutogenLayoutOrderHandlingRequirementsSlideout {
    static showSlideout(props: OrderHandlingRequirementsSlideoutProps) {
        const layout = Layout.getLayout("lme/dispatch/OrderHandlingRequirementsSlideout") as OrderHandlingRequirementsSlideout;
        const hdrDataSource = props.hdrDataSource;
        const dataCopy: ModelRow[] = hdrDataSource.getDataCopy();
        const invokedFromEDI = props.invokedFromEdi;
        const decorator = new SlideoutDecorator({
            layout: layout,
            width: window.innerWidth * .70,
            title: "Accessorials",
            fillVerticalSpace: true,
            overlayProps: { closeOnClickOff: false, greyedBackground: true },
            layoutLoadListeners: () => {
                layout.orderHdrXFgpSource = hdrDataSource;
                layout.hdrCallback = (row: ModelRow<any>, type: ModelDataChangeType) => props.hdrChanged(row, type);
                layout.populateTable(null, hdrDataSource.data, hdrDataSource.activeRow?.get("id"));
            },
            onClose: (cancelled: boolean) => {
                decorator.slideOut();
                if (cancelled === true){
                    hdrDataSource.data = dataCopy;
                    if(invokedFromEDI){
                        hdrDataSource.data.forEach(hdrDef => {
                            if(hdrDef._appending === false){
                                hdrDef._appending = true;
                            }
                        });
                    }
                }
            },
            addlComponents: new Button({
                id: "handReqSaveButton",
                caption: "Save and Close",
                backgroundColor: "primary",
                color: "primary.reverse",
                minWidth: 128,
                borderWidth: 0,
                rowBreak: false,
                onClick: async (event: ClickEvent) => {
                    if (decorator.validateSimple()) {
                        //layout.resetOriginalData(); //leaving this for the time being
                        decorator.slideOut();
                        let hasChanged: boolean = false;
                        if (hdrDataSource.hasChanged() === true) (
                            hasChanged = true
                        )
                        props.onSave(hasChanged);
                    }
                },
            })
        });
    }

    public hdrCallback: (row: ModelRow<any>, type: ModelDataChangeType) => void;
    private hdrTableMap = new Map<number, TableRow>();
    private _orderHdrXFgpSource: DataSource;

    async populateTable(enabled?: boolean, hdrRows?: ModelRow<any>[], orderId?: string): Promise<void> {
        if (enabled != null)
            this.enabled = enabled;
        this.sourceBrltlOrderHdrXFgp.mode = DataSourceMode.UPDATE;
        await this.sourceHandlingRequirements.search().then(() => {
            this.sourceHandlingRequirements.data.forEach(hdrDef => {
                this.addHdrRow(hdrDef, this.orderHdrXFgpSource.data);
            });
        })
        return Promise.resolve();
    }

    addHdrRow(hdrDef: ModelRow<any>, modelRowsHdrXFgp: ModelRow<any>[]) {
        let checked = false;
        const hdrxfgpRow: ModelRow<any>[] = modelRowsHdrXFgp.filter((row) => row.data["hdr_uid"] === hdrDef.get("hdr_uid"));
        if (hdrxfgpRow.length > 0) {
            checked = true;
        }
        const checkBox = new Checkbox(
            {
                id: `${"checkBoxHdr"}${hdrDef.get("hdr_uid")}`, caption: hdrDef.get("hdr_title"), fillHeight: false, maxHeight: 28,
                checkSize: 28, valueChecked: "Y", valueUnchecked: "N", checked: checked, rowBreak: true, boundRow: hdrDef
            })
        checkBox.addChangeListener((event: ChangeEvent) => this.checkHdrOnChange(event));
        const textboxes = this.addInputTextboxes(hdrxfgpRow.length != 0 ? hdrxfgpRow[0] : hdrDef, this.panelHandReqCol1, false);
        const result = this.panelHandReqCol1.add(checkBox);
        if (textboxes !== undefined && textboxes.length > 0)
            textboxes.forEach((textbox) => this.panelHandReqCol1.add(textbox));
        this.checkBoxVisibilityCheck(checkBox, textboxes, this.panelHandReqCol1, 1);
    }

    checkBoxVisibilityCheck(checkBox: Checkbox, textboxes: Textbox[], panel: Panel, index = 1) {
        let visibilityPercent: number;
        if (textboxes !== undefined && textboxes.length > 0)
            visibilityPercent = DOMUtil.getPercentVerticallyVisible(textboxes[textboxes.length - 1].element, panel.element)
        else
            visibilityPercent = DOMUtil.getPercentVerticallyVisible(checkBox.element, panel.element);
        if (visibilityPercent < 1) {
            panel.remove(checkBox);
            if (textboxes !== undefined && textboxes.length > 0)
                textboxes.forEach((textbox) => panel.remove(textbox));
            index++;
            const panelId = `${"panelHandReqCol"}${(index)}`;
            const nextPanel = this.panelHandReq.findComponentById(panelId) as Panel;
            if (nextPanel !== null && nextPanel instanceof Panel) {
                nextPanel.add(checkBox);
                if (textboxes !== undefined && textboxes.length > 0)
                    textboxes.forEach((textbox) => nextPanel.add(textbox));
                this.checkBoxVisibilityCheck(checkBox, textboxes, nextPanel, index);
            }
            else {
                const newPanel = new Panel({ id: `${"panelHandReqCol"}${(index++)}`, fillHeight: true, fillRow: true, rowBreak: false });
                this.panelHandReq.add(newPanel);
                newPanel.add(checkBox);
                if (textboxes !== undefined && textboxes.length > 0)
                    textboxes.forEach((textbox) => newPanel.add(textbox));
                return;
            }
        }
    }

    checkHdrOnChange(event: ChangeEvent) {
        if (event.userInitiatedChange) {
            const check = event.target as Checkbox;
            const row = check.boundRow
            check.parent.forEveryChildComponent((component) => {
                if (component instanceof Textbox) {
                    this.checkRequired(component);
                }
            });
            if (this.hdrCallback)
                this.hdrCallback(row, event.newValue ? ModelDataChangeType.ADD : ModelDataChangeType.DELETE);
        }
    }

    checkRequired(component: Textbox) {
        const parent = component.parent;
        const hdrUid = component.boundRow.data["hdr_uid"];
        const check = parent.findComponentById(`${"checkBoxHdr"}${hdrUid}`) as Checkbox;
        component.required = check.checked;
    }

    textboxInputOnChange(event: BlurEvent) {
        const textboxInput = event.target as Textbox;
        if (textboxInput.isDropdownVisible()) return;
        const row = textboxInput.boundRow;
        if (textboxInput.displayType === DisplayType.DATETIME) {
            row.set(textboxInput.field, DateUtil.parseDateWithKeywords(textboxInput.text, true, true));
        }
        else if (textboxInput.displayType === DisplayType.DATE) {
            row.set(textboxInput.field, DateUtil.parseDateWithKeywords(textboxInput.text, true, false));
        }
        else if (textboxInput.displayType === DisplayType.TIME) {
            row.set(textboxInput.field, DateUtil.parseDateWithKeywords(textboxInput.text, false, true));
        }
        else {
            row.set(textboxInput.field, textboxInput.text);
        }
        if (this.hdrCallback)
            this.hdrCallback(row, ModelDataChangeType.UPDATE);
        this.checkRequired(textboxInput);
    }

    private addInputTextboxes(hdrDef: ModelRow<any>, panel: Panel, focusInput: boolean = true): Textbox[] {
        const textboxes: Textbox[] = []
        if (!hdrDef.isNull("text_title")) {
            textboxes.push(new Textbox({ ...inputProps, id: `${"textboxInputText"}${hdrDef.get("hdr_uid")}`, captionAlignment: Alignment.LEFT, enabled: this.enabled, field: "text_value", caption: hdrDef.get("text_title"), forcedCase: ForcedCase.UPPER, dataSource: this.sourceBrltlOrderHdrXFgp, boundRow: hdrDef }));
        }

        if (!hdrDef.isNull("integer_title")) {
            textboxes.push(new Textbox({ ...inputProps, id: `${"textboxInputInteger"}${hdrDef.get("hdr_uid")}`, captionAlignment: Alignment.LEFT, enabled: this.enabled, field: "integer_value", displayType: DisplayType.INTEGER, caption: hdrDef.get("integer_title"), dataSource: this.sourceBrltlOrderHdrXFgp, boundRow: hdrDef }))
        }
        if (!hdrDef.isNull("float_title")) {
            textboxes.push(new Textbox({ ...inputProps, id: `${"textboxInputFloat"}${hdrDef.get("hdr_uid")}`, captionAlignment: Alignment.LEFT, enabled: this.enabled, field: "float_value", displayType: DisplayType.DECIMAL, caption: hdrDef.get("float_title"), dataSource: this.sourceBrltlOrderHdrXFgp, boundRow: hdrDef }));
        }
        if (!hdrDef.isNull("date_title")) {
            textboxes.push(new Textbox({ ...inputProps, id: `${"textboxInputDate"}${hdrDef.get("hdr_uid")}`, captionAlignment: Alignment.LEFT, enabled: this.enabled, field: "date_value", displayType: DisplayType.DATE, caption: hdrDef.get("date_title"), dataSource: this.sourceBrltlOrderHdrXFgp, boundRow: hdrDef }));
        }
        if (!hdrDef.isNull("time_title")) {
            textboxes.push(new Textbox({ ...inputProps, id: `${"textboxInputTime"}${hdrDef.get("hdr_uid")}`, captionAlignment: Alignment.LEFT, enabled: this.enabled, field: "time_value", displayType: DisplayType.TIME, caption: hdrDef.get("time_title"), dataSource: this.sourceBrltlOrderHdrXFgp, boundRow: hdrDef }));
        }
        if (!hdrDef.isNull("timestamp_title")) {
            textboxes.push(new Textbox({ ...inputProps, id: `${"textboxInputDateTime"}${hdrDef.get("hdr_uid")}`, captionAlignment: Alignment.LEFT, enabled: this.enabled, field: "timestamp_value", displayType: DisplayType.DATETIME, caption: hdrDef.get("timestamp_title"), dataSource: this.sourceBrltlOrderHdrXFgp, boundRow: hdrDef }));
        }
        if (textboxes.length > 0) {
            textboxes.forEach((textbox) => {
                textbox.displayData(hdrDef, null, 0);
                textbox.addBlurListener((event: BlurEvent) => this.textboxInputOnChange(event))
            });
        }
        return textboxes;
    }

    public get orderHdrXFgpSource(): DataSource {
        return this._orderHdrXFgpSource;
    }

    public set orderHdrXFgpSource(value: DataSource) {
        this._orderHdrXFgpSource = value;
    }
    //***Leaving this for now as I'm not sure I won't need this */
    // public resetOriginalData() {
    //   //this._orderHdrXFgpSource._deletedData = null;
    //   if (this._orderHdrXFgpSource.data == null)
    //     return;
    //   for (const row of this._orderHdrXFgpSource.data) {
    //     //row._appending = false;
    //     //row.resetOriginalData();
    //   }
    // }
}
