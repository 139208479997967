import { DataSource } from "../..";
import { HorizontalSpacer } from "../../page/HorizontalSpacer";
import { Button } from "../button/Button";
import { Label } from "../label/Label";
import { Panel } from "../panel/Panel";
import { Layout } from "./Layout";

export class WidgetTitleBar extends Panel {
    private _layout: Layout;
    private _titleLabel: Label;
    private _refreshButton: Button;
    constructor(layout: Layout) {
        super({ fillRow: true, backgroundColor: "primary.darker", height: 42 });
        this._layout = layout;
        this.addTitleLable();
        this.addRefreshButton();
        this.refresh();
    }
    public get refreshButton(): Button {
        return this._refreshButton;
    }

    public get layout(): Layout {
        return this._layout;
    }

    private addRefreshButton() {
        if (this.layout.displayRefresh === true) {
            this._refreshButton = new Button({
                busyWhenDataSourceBusy: true, dataSource: this.layout.mainDataSource,
                imageName: "refresh", onClick: event => this.refresh(), borderWidth: 0, color: "primary.reverse", padding: 4, rowBreak: false
            });
            this._refreshButton._interactionEnabled = this.__designer == null;
            this.add(new HorizontalSpacer());
            this.add(this._refreshButton);
        }
    }

    private addTitleLable() {
        this._titleLabel = new Label({ fontSize: "xlarge", color: "primary.reverse", rowBreak: false, caption: this.layout.title });
        this._titleLabel._interactionEnabled = this.__designer == null;
        this.add(this._titleLabel);
    }

    refresh(): void {
        if (this._layout?.dataSourceIDs != null) {
            for (const dataSourceId of this._layout.dataSourceIDs) {
                (this._layout[dataSourceId] as DataSource).search();
            }
        }
    }
}
