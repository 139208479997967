/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelBrltlCommodity.ts                *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/dispatch/brltl-commodity";

export class AutogenModelBrltlCommodity<RowType extends ModelRow> extends Endpoint<RowType, RowBrltlCommodityFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowBrltlCommodity extends ModelRow<RowBrltlCommodityFields> {
    constructor(values?: Partial<RowBrltlCommodityFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowBrltlCommodityFields {
    descr: string;
    fgi_data: string;
    handling_units: number;
    handling_units_is_per_piece: string;
    hazmat: string;
    hazmat_class_code: string;
    hazmat_contact: string;
    hazmat_erg_number: string;
    hazmat_is_residue: string;
    hazmat_number: string;
    hazmat_packing_group: string;
    hazmat_phone: string;
    hazmat_proper_shipname: string;
    hazmat_ref_type_code: string;
    hazmat_subclass_code: string;
    height: number;
    id: string;
    is_hazmat: string;
    is_poison: string;
    length: number;
    nmfc_class_code: string;
    nmfc_code: string;
    packaging_type_code: string;
    poison_zone: string;
    req_spots: number;
    sort_column: number;
    spots_is_per_piece: string;
    temperature_max: number;
    temperature_min: number;
    weight: number;
    weight_is_per_piece: string;
    weight_uom_type_code: string;
    width: number;
    product_book_customer: string;
    product_book_location: string;
    search_product_book: boolean;
}

