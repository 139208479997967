import { Label, Panel } from "@mcleod/components";
import { Api, StringUtil } from "@mcleod/core";
import { DispatchCommentFormat } from "../../dispatch/src/DispatchCommentFormat";
import { AutogenLayoutDispatchCommentsErrors } from "./autogen/AutogenLayoutDispatchCommentsErrors";

export class DispatchCommentsErrors extends AutogenLayoutDispatchCommentsErrors {

    private _showWarnings: boolean = true;

    public get showWarnings(): boolean {
        return this._showWarnings;
    }

    public set showWarnings(value: boolean) {
        this._showWarnings = value;
    }

    public displayDispatchComments(payeeId: string, maxChars: number = 95) {
        this.panelCommentsList.removeAll();
        this.panelDispatchComments.visible = false;
        const commentFormat = new DispatchCommentFormat(maxChars);
        if (!StringUtil.isEmptyString(payeeId)) {
            let hasComments: boolean = false;
            this.sourceComments.search({
                display_atdispatch: "Y",
                parent_row_id: payeeId
            }, null, "lme/general/Comments").then(result => {
                this.sourceComments.data.forEach(row => {
                    hasComments = true;
                    const commentPanel = new Panel({ fillRow: true });
                    commentFormat.displayComment(commentPanel, row);
                    this.panelCommentsList.add(commentPanel);
                });
            }).finally(() => {
                this.panelDispatchComments.visible = hasComments;
            });
        }
    }

    async displayValidationErrors(movementId: string, payeeId: string, driver1?: string, driver2?: string): Promise<any> {
        this.clearErrors();
        let data = null;
        await Api.search("lme/powerbroker/carrier-dispatch-validator",
            {
                "movement_id": movementId,
                "payee_id": payeeId,
                "driver1_id": driver1,
                "driver2_id": driver2
            }).then(result => {
            data = result?.data[0];
            const errors = data?.errors;
            const warnings = data?.warnings;
            if (errors?.length > 0) {
                this.addValidationErrors(errors)
            }
            if (warnings?.length > 0) {
                this.addValidationWarnings(warnings);
            }
        })
        return data;
    }

    clearErrors() {
        this.panelError.visible = false;
        this.panelErrorList.removeAll();
        this.panelWarning.visible = false;
        this.panelWarningList.removeAll();
        this.panelDispatchComments.visible = false;
        this.panelCommentsList.removeAll();
    }

    addValidationErrors(errors: any[]) {
        if (errors?.length > 0) {
            errors?.forEach(error => {
                if (error.message != null) {
                    this.panelErrorList.add(new Label({
                        caption: error.message, fontSize: "medium", fontBold: true
                    }));

                    this.addValidationLabels(this.panelErrorList, error.details);
                }
            });

            this.panelError.visible = this.panelErrorList.getComponentCount() > 0;
        }
    }

    private addValidationWarnings(warnings: any[]) {
        if (this.showWarnings == true) {
            this.addValidationLabels(this.panelWarningList, warnings);
            this.panelWarning.visible = this.panelWarningList.getComponentCount() > 0;
        }
    }

    private addValidationLabels(panel: Panel, captions: any[]) {
        captions?.forEach(caption => {
            if (!StringUtil.isEmptyString(caption))
                panel.add(new Label({ caption: caption, fontSize: "medium", fontBold: true }))
        });
    }
}
