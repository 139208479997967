import { Button, CityState, DataSource, HorizontalSpacer, Image, Label, Layout, Panel, StarRating, Switch, Tab, Table, Tabset, Textbox } from "@mcleod/components";
import { MovementDataFusionSummary } from "../MovementDataFusionSummary";

export abstract class AutogenLayoutAssignedMovementOverview extends Layout {
    buttonClassicAdvances: Button;
    buttonModifyPay: Button;
    cityStateCarrierEmptyFromCityId: CityState;
    citystateCarrier: CityState;
    horizontalspacer1: HorizontalSpacer;
    imageNextStopType: Image;
    labelCarrier: Label;
    labelCarrierContact: Label;
    labelCarrierEmail: Label;
    labelCarrierPhone: Label;
    labelContactHeader: Label;
    labelDistanceToNextStop: Label;
    labelDriverInformation: Label;
    labelEquipmentRequired: Label;
    labelEtaToNextStop: Label;
    labelMilesToPickup: Label;
    labelNextSchedCall: Label;
    labelNextScheduledHeader: Label;
    labelNextStop: Label;
    labelNextStopAddress2: Label;
    labelNextStopAddress: Label;
    labelNextStopCity: Label;
    labelNextStopDistance: Label;
    labelNextStopEta: Label;
    labelNextStopLocation: Label;
    labelNextStopScheduled: Label;
    labelNextStopTypeCaption: Label;
    labelOrderstemperatureMax: Label;
    labelOrderstemperatureMin: Label;
    labelOverrideDriverCell: Label;
    labelOverrideDriverName: Label;
    labelOverrideDrvrEmail: Label;
    labelPayeeName: Label;
    labelReferenceNumbers: Label;
    labelTeam: Label;
    labelTemperatureRange: Label;
    labelTo: Label;
    labelTotalPayPerMile: Label;
    labelTrackingCaption: Label;
    labelTrackingRequired: Label;
    layoutMovementDataFusionSummary: MovementDataFusionSummary;
    panelCarrier: Panel;
    panelCarrierInfo: Panel;
    panelContactInfo: Panel;
    panelContent: Panel;
    panelDataFusion: Panel;
    panelDriver: Panel;
    panelDriverInfo: Panel;
    panelDriverSummary: Panel;
    panelDriverSummaryInner: Panel;
    panelEquipment: Panel;
    panelEquipmentRequired: Panel;
    panelMargin: Panel;
    panelMoveInfo: Panel;
    panelNextStopHeader: Panel;
    panelNextStopLocationInfo: Panel;
    panelNextStopType: Panel;
    panelRefNbrs: Panel;
    panelReferenceNumbers: Panel;
    panelStopInfo: Panel;
    panelTempRange: Panel;
    panelTempRangeInner: Panel;
    panelTotalAdvances: Panel;
    panelTotalCharges: Panel;
    panelTotalPay: Panel;
    panelTracking: Panel;
    panelTrackingRequirements: Panel;
    sourceDataFusionMovementSummary: DataSource;
    sourceEquipMatchDetail: DataSource;
    sourceMovement: DataSource;
    starratingRatingStarCount: StarRating;
    switchTrackingRequired: Switch;
    tabCarrierInfo: Tab;
    tabDriverInfo: Tab;
    tabTrackingRequirements: Tab;
    tableEquipmentRequired: Table;
    tabsetCarrierDriver: Tabset;
    textboxCarrierContact: Textbox;
    textboxCarrierEmail: Textbox;
    textboxCarrierPhone: Textbox;
    textboxCarrierTractor: Textbox;
    textboxCarrierTrailer: Textbox;
    textboxCarrierTrailerType: Textbox;
    textboxFreightCharges: Textbox;
    textboxMargin: Textbox;
    textboxMarginPercentage: Textbox;
    textboxNetPay: Textbox;
    textboxOtherCharges: Textbox;
    textboxOtherPay: Textbox;
    textboxOverrideDriver2Nm: Textbox;
    textboxOverrideDriverNm: Textbox;
    textboxOverrideDrvr2Cell: Textbox;
    textboxOverrideDrvr2Email: Textbox;
    textboxOverrideDrvrCell: Textbox;
    textboxOverrideDrvrEmail: Textbox;
    textboxOverridePayAmt: Textbox;
    textboxProNbr: Textbox;
    textboxRequiredVendor: Textbox;
    textboxTotalAdvances: Textbox;
    textboxTotalCharge: Textbox;
    textboxTotalPay: Textbox;
}
