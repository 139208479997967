/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelCarrierPerformanceDetail.ts      *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/powerbroker/carrier-performance-detail";

export class AutogenModelCarrierPerformanceDetail<RowType extends ModelRow> extends Endpoint<RowType, RowCarrierPerformanceDetailFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowCarrierPerformanceDetail extends ModelRow<RowCarrierPerformanceDetailFields> {
    constructor(values?: Partial<RowCarrierPerformanceDetailFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowCarrierPerformanceDetailFields {
    additional_weight: number;
    average_minutes_late: number;
    claim_reason_codes: string;
    company_id: string;
    comparison: string;
    comparison_value: number;
    count_carriercalls_ontime: string;
    customer_id: string;
    descr: string;
    end_of_period: string;
    exclude_customers: string;
    exclude_order_type: string;
    exclude_reason_codes: string;
    exclude_service_failure_nf: string;
    grace_period: number;
    id: string;
    incld_brokerinitiated_calls: string;
    incld_carr_initiated_calls: string;
    include_consignee_stops: string;
    include_extra_stops: string;
    include_partial_movements: string;
    include_shipper_stops: string;
    measure: string;
    minimum_records: number;
    order_type: string;
    parent_row_id: string;
    pay_to_compare: string;
    performance_metric: string;
    points: number;
    prompt: string;
    reason_codes: string;
    sequence: number;
    start_of_period: string;
    weight: number;
    brokrate_only: boolean;
}

