import { Component, Layout, Tab, Table } from "@mcleod/components";
import { getLogger } from "@mcleod/core";

const log = getLogger("common.DataLoadActionHandler");

export enum DataLoadActionType {
    SELECT_TAB = "selectTab",
    FILTER_TABLE = "filterTable"
}

export class DataLoadActionHandler {
    private actionType: DataLoadActionType;
    private componentId: string;

    constructor(actionType: DataLoadActionType, componentId: string) {
        this.actionType = actionType;
        this.componentId = componentId;
    }

    /**
     * Create the portion of the URL params that is specific to the data load action.
     * 
     * We do not assume that this portion of the URL will come first.  As a result, the leading ? is not included in the result.
     * 
     * Example output: dataLoadAction=selectTab&dataLoadActionId=someTabComponentId
     * 
     * @param actionType: The DataLoadActionType that defines the action to be taken
     * @param componentId: The ID of the component that is the target of the defined action
     * @param addlInputs: A Map of string key/value pairs, which can be used to add more key/value pairs to the URL param string
     */
    public static createUrlQueryParamString(actionType: DataLoadActionType, componentId: string, count: number = 1, addlInputs?: Map<string, string>): string {
        if (count == null)
            count = 1; //in case someone passes in null for the count param
        let result = "dataLoadAction" + count + "=" + actionType;
        result += "&dataLoadActionId" + count + "=" + componentId;
        if (addlInputs != null) {
            for (const key of addlInputs.keys()) {
                result += "&" + key + "=" + addlInputs.get(key);
            }
        }
        return result;
    }

    public static gatherFromUrlProps(props: any): DataLoadActionHandler[] {
        const result: DataLoadActionHandler[] = [];
        for (let x = 1; x < 10; x++) {
            const dlaKey = "dataLoadAction" + x;
            const dlaId = "dataLoadActionId" + x;
            if (props[dlaKey] == null || props[dlaId] == null)
                return result;
            else
                result.push(new DataLoadActionHandler(props[dlaKey], props[dlaId]));
        }
        return result;
    }

    /**
     * Invokes the defined data load action.
     * 
     * @param layout The layout on which the action should be taken.  This layout will include the component that is the target of the action (specified by the dataLoadActionId).
     * @param dataLoadAction The action that should be taken
     * @param dataLoadActionId The component that is the target of the action
     * @param props All the URL params; needed in case we use an additional param
     */
    public static handleDataLoadActions(layout: Layout, actionHandlers: DataLoadActionHandler[], props: any) {
        for (const actionHandler of actionHandlers) {
            let component = layout[actionHandler.componentId];
            if (component == null)
                component = layout.findComponentById(actionHandler.componentId);
            if (component == null) {
                log.debug("Attempted to execute data load action %o on component with ID %o, but the component was not found", actionHandler.actionType, actionHandler.componentId);
                return;
            }
            switch (actionHandler.actionType) {
                case DataLoadActionType.SELECT_TAB:
                    DataLoadActionHandler.selectTab(component);
                    break;
                case DataLoadActionType.FILTER_TABLE:
                    DataLoadActionHandler.filterTable(component, props["filterValue"]);
                    break;
                default:
                    break;
            }
        }
    }

    private static selectTab(component: Component) {
        if (component != null && component instanceof Tab) {
            log.debug("Attempting to select tab with ID %o", component.id);
            component.selectAndScroll();
        }
    }

    private static filterTable(component: Component, filterValue: string) {
        if (component != null && component instanceof Table) {
            log.debug("Attempting to populate filter for table with ID %o with filter value %s", component.id, filterValue);
            component.setFilter(filterValue);
        }
    }
}