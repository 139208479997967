import { Button, ClickEvent, Layout, SlideoutDecorator } from "@mcleod/components";
import { Api, StringUtil, getUserSettings } from "@mcleod/core";
import { AutogenLayoutCarrierOverride } from "./autogen/AutogenLayoutCarrierOverride";

interface CarrierOverrideProps {
    payee_id: string
}

export class CarrierOverride extends AutogenLayoutCarrierOverride {
    doOnSlideoutClosed: (dataPosted: boolean) => void;
    private _acTimeoutHandle: number;
    public saveButton: Button;
    public payee_id: string;

    override onLoad() {
        this.textboxMovementId.onSelectItem = (textbox, selectedItem) => {
            if (!StringUtil.isEmptyString(selectedItem["value"])) {
                this.setMovement(selectedItem["value"])
            } else {
                this.sourceMovement.clear();
            }

            return selectedItem["value"];
        };
    }

    public static show(props: CarrierOverrideProps) {
        const layout = Layout.getLayout("lme/dispatch/CarrierOverride") as CarrierOverride;
        const saveButton = this.getSaveButton(layout);

        layout.saveButton = saveButton;
        layout.payee_id = props.payee_id;

        new SlideoutDecorator({
            layout: layout,
            width: 600,
            fillVerticalSpace: true,
            backgroundColor: "defaultBackground",
            title: `Override Carrier`,
            addlComponents: saveButton,
            onClose: (cancelled: boolean) => layout.closeOverlay(cancelled)
        });
    }

    private static getSaveButton(layout: CarrierOverride): Button {
        const bttn = new Button({
            id: "buttonSave",
            caption: "Save",
            rowBreak: false,
            enabled: false,
            width: 75,
            backgroundColor: "primary",
            color: "primary.reverse",
            borderWidth: 0,
            dataSource: layout.mainDataSource,
            busyWhenDataSourceBusy: true
        })

        bttn.addClickListener(((event: ClickEvent) => {
            layout.saveMovement().then(saved => { if (saved) layout.closeOverlay(false) });
        }));

        return bttn;
    }

    public async closeOverlay(cancelled: boolean) {
        this.slideOut().then(() => {
            if (this.doOnSlideoutClosed != null)
                this.doOnSlideoutClosed(cancelled);
        });
    }

    private async saveMovement(): Promise<boolean> {
        return new Promise(async resolve => {
            if (this.mainDataSource != null && this.mainDataSource.activeRow != null) {
                this.activeRow?.set("carrier_override_carrier", this.payee_id);
                this.activeRow?.set("carrier_override_user", getUserSettings().user_id);
                await this.activeRow.post();
                resolve(true);
                return;
            }
            resolve(false);
        })
    }

    private setMovement(movementID: string) {
        this.sourceMovement.search({ id: movementID }).then(result => {
            this.saveButton.enabled = true;
        });
    }

    /** This is an event handler for the onChange event of textboxOrderId.  */
    textboxOrderIdOnChange(event) {
        this.textboxMovementId.selectedItem = null;
        this.textboxMovementId.items = [];
        this.mainDataSource.clear();
        this.saveButton.enabled = false;

        if (this._acTimeoutHandle != null)
            window.clearTimeout(this._acTimeoutHandle);
        this._acTimeoutHandle = window.setTimeout(() => {
            if (event.newValue.length >= 7) {
                this.setMovementIdOptions();
            }
        }, this.textboxOrderId.lookupModelInputDelay);
    }

    async setMovementIdOptions() {
        Api.post("lme/dispatch/order-movements",
            { order_id: this.textboxOrderId.text, brokerage_only: false }
        ).then(response => {
            if (response.data == null || response.data.length == 0)
                return;

            const moveIds = response.data.map(move => {
                return { caption: move.id, value: move.id };
            });
            this.textboxMovementId.items = moveIds;
            this.textboxMovementId.selectedItem = moveIds[0];
            this.setMovement(moveIds[0].value)
        });
    }
}
