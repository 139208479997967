/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelMatchTractorType.ts              *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/dispatch/match-tractor-type";

export class AutogenModelMatchTractorType<RowType extends ModelRow> extends Endpoint<RowType, RowMatchTractorTypeFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowMatchTractorType extends ModelRow<RowMatchTractorTypeFields> {
    constructor(values?: Partial<RowMatchTractorTypeFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowMatchTractorTypeFields {
    applies_to: string;
    company_id: string;
    descr: string;
    equip_length: number;
    equip_width: number;
    exchange_type: string;
    fms_length: string;
    fms_type: string;
    id: string;
    picture_resource: string;
    pnn_code_id: string;
    web_order: number;
}

