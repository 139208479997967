/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelEdiMultistop.ts                  *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/datafusion/edi-multistop";

export class AutogenModelEdiMultistop<RowType extends ModelRow> extends Endpoint<RowType, RowEdiMultistopFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowEdiMultistop extends ModelRow<RowEdiMultistopFields> {
    constructor(values?: Partial<RowEdiMultistopFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowEdiMultistopFields {
    agency_id: string;
    agent_payee_id: string;
    alt_partner_id: string;
    app_type: string;
    autorate_status: string;
    bill_date: Date;
    bill_distance: number;
    bill_distance_um: string;
    billing_empty_distance: number;
    billing_empty_distance_um: string;
    billing_loaded_distance: number;
    billing_loaded_distance_um: string;
    billing_user_id: string;
    blnum: string;
    bol_received: string;
    booking_no: string;
    booking_number: string;
    bplant: string;
    cancelled_order: string;
    car_address: string;
    car_city: string;
    car_code: string;
    car_code_qualifier: string;
    car_name: string;
    car_pay_method: string;
    car_phone_number: string;
    car_pro_nbr: string;
    car_state: string;
    car_trip_lease: string;
    car_zip_code: string;
    carrier_id: string;
    carrier_tractor: string;
    carrier_trailer: string;
    changed_order: string;
    collection_method: string;
    commitment_id: string;
    commodity: string;
    commodity_id: string;
    company_id: string;
    complete_document: string;
    cons_ediloc: string;
    consignee_ediloc: string;
    consignee_refno: string;
    consignee_stop_id: string;
    container_planned: string;
    container_type_id: string;
    copy_from_company: string;
    copy_from_order_id: string;
    create_date: Date;
    ctrl_party_id: string;
    cube: number;
    curr_movement_id: string;
    currency_iso_id: string;
    currency_type_id: string;
    cust_order_no: string;
    "customer.activity_count": number;
    "customer.activity_start_date": Date;
    "customer.add_empty_distance_occ": string;
    "customer.add_finance_charge": string;
    "customer.address1": string;
    "customer.address2": string;
    "customer.allow_inc_in_frt": string;
    "customer.auto_rate": string;
    "customer.available_template": number;
    "customer.average_bill": number;
    "customer.average_bill_c": string;
    "customer.average_bill_d": Date;
    "customer.average_bill_n": number;
    "customer.average_bill_r": number;
    "customer.average_pay_days": number;
    "customer.balance": number;
    "customer.balance_c": string;
    "customer.balance_d": Date;
    "customer.balance_n": number;
    "customer.balance_r": number;
    "customer.benchmark_ctg_uid": number;
    "customer.benchmark_tar_uid": number;
    "customer.bill_due_days": number;
    "customer.bill_format_flag": string;
    "customer.bill_template": number;
    "customer.bill_transfer_when": string;
    "customer.billed_loads": number;
    "customer.bl_logo": string;
    "customer.bl_logo_name": string;
    "customer.blanket_buy_tariff_id": string;
    "customer.blanket_sell_tariff_id": string;
    "customer.bridge_id": string;
    "customer.brokerage_filter": string;
    "customer.business_hours": string;
    "customer.business_number": string;
    "customer.buy_tariff_id": string;
    "customer.category": string;
    "customer.cert_of_ins_date": Date;
    "customer.charge_detention": string;
    "customer.chassis_chargeby": string;
    "customer.chassis_freedays": number;
    "customer.chassis_rate": number;
    "customer.chassis_rate_c": string;
    "customer.chassis_rate_d": Date;
    "customer.chassis_rate_n": number;
    "customer.chassis_rate_r": number;
    "customer.city": string;
    "customer.city_id": number;
    "customer.coll_thresh_pd1": number;
    "customer.coll_thresh_pd1_c": string;
    "customer.coll_thresh_pd1_d": Date;
    "customer.coll_thresh_pd1_n": number;
    "customer.coll_thresh_pd1_r": number;
    "customer.coll_thresh_pd2": number;
    "customer.coll_thresh_pd2_c": string;
    "customer.coll_thresh_pd2_d": Date;
    "customer.coll_thresh_pd2_n": number;
    "customer.coll_thresh_pd2_r": number;
    "customer.coll_thresh_pd3": number;
    "customer.coll_thresh_pd3_c": string;
    "customer.coll_thresh_pd3_d": Date;
    "customer.coll_thresh_pd3_n": number;
    "customer.coll_thresh_pd3_r": number;
    "customer.coll_thresh_pd4": number;
    "customer.coll_thresh_pd4_c": string;
    "customer.coll_thresh_pd4_d": Date;
    "customer.coll_thresh_pd4_n": number;
    "customer.coll_thresh_pd4_r": number;
    "customer.collections_id": string;
    "customer.collections_ok": string;
    "customer.company_id": string;
    "customer.confidence_level": number;
    "customer.container_chargeby": string;
    "customer.container_freedays": number;
    "customer.container_rate": number;
    "customer.container_rate_c": string;
    "customer.container_rate_d": Date;
    "customer.container_rate_n": number;
    "customer.container_rate_r": number;
    "customer.contract_cbc": string;
    "customer.contract_ccc": string;
    "customer.contract_gbc": string;
    "customer.contract_gcc": string;
    "customer.contract_sbc": string;
    "customer.contract_scc": string;
    "customer.controlling_carrier_code": string;
    "customer.conversion_date": Date;
    "customer.cost_ctg_uid": number;
    "customer.cost_tar_uid": number;
    "customer.covered_template": number;
    "customer.cp_logo": string;
    "customer.cp_logo_name": string;
    "customer.credit_application": string;
    "customer.credit_check_date": Date;
    "customer.credit_limit": number;
    "customer.credit_limit_c": string;
    "customer.credit_limit_d": Date;
    "customer.credit_limit_n": number;
    "customer.credit_limit_r": number;
    "customer.credit_status": string;
    "customer.credit_warning_pct": number;
    "customer.ctg_uid": number;
    "customer.currency_type": string;
    "customer.cust_requestor_load_id": string;
    "customer.cust_stmt_template": number;
    "customer.d_and_b_code": string;
    "customer.d_and_b_date": Date;
    "customer.d_and_b_number": string;
    "customer.days_between_calls": number;
    "customer.deadhead_target": number;
    "customer.ded_last_fixed_cost_billdt": Date;
    "customer.dedicated_cycle": string;
    "customer.dedicated_revenue_code_id": string;
    "customer.dedicated_seg_alloc_code": string;
    "customer.dedicated_start_day": string;
    "customer.dedicated_template": number;
    "customer.def_rate_confirm_temp": number;
    "customer.delivered_template": number;
    "customer.departure_date_at": string;
    "customer.disable_automatic_tracking": string;
    "customer.display_eta": string;
    "customer.display_ysplit_stops": string;
    "customer.distance_method": string;
    "customer.distance_profile": string;
    "customer.distance_source": string;
    "customer.distance_type": string;
    "customer.dot_number": string;
    "customer.driver_load": string;
    "customer.empty_distance_charge_id": string;
    "customer.empty_distance_rate": number;
    "customer.enable_notifications": string;
    "customer.enforce_dep_dates": string;
    "customer.entered_date": Date;
    "customer.excise_taxable": string;
    "customer.expected_close": Date;
    "customer.extend_rates": string;
    "customer.factor_percent": number;
    "customer.factor_receivables": string;
    "customer.factoring_id": string;
    "customer.fak_profile_id": string;
    "customer.fourkites_customer_id": string;
    "customer.fuel_amount": number;
    "customer.fuel_base_price": number;
    "customer.fuel_credit": string;
    "customer.fuel_method": string;
    "customer.fuel_percent": number;
    "customer.fuel_price_region": string;
    "customer.fuel_surcharge_id": string;
    "customer.fuel_surchg_enddt": Date;
    "customer.fuel_surchg_strtdt": Date;
    "customer.fuel_variance": number;
    "customer.global_detention": string;
    "customer.google_place_id": string;
    "customer.gp_exclude": string;
    "customer.gp_exported": Date;
    "customer.grace_period": number;
    "customer.high_balance": number;
    "customer.high_balance_c": string;
    "customer.high_balance_d": Date;
    "customer.high_balance_n": number;
    "customer.high_balance_r": number;
    "customer.ic_company_id": string;
    "customer.id": string;
    "customer.include_by_date": string;
    "customer.include_empty_distance": string;
    "customer.include_fuel_surcharge": string;
    "customer.include_split_point": string;
    "customer.is_active": string;
    "customer.is_consignee": string;
    "customer.is_distribution_center": string;
    "customer.is_drop_yard": string;
    "customer.is_geocoded": string;
    "customer.is_intercompany": string;
    "customer.is_pallets_required": string;
    "customer.is_shipper": string;
    "customer.is_trailer_pool_required": string;
    "customer.jaguar_id": number;
    "customer.last_bill_date": Date;
    "customer.last_fin_charge_dt": Date;
    "customer.last_pay_date": Date;
    "customer.last_ship_date": Date;
    "customer.latitude": number;
    "customer.location_id": string;
    "customer.longitude": number;
    "customer.macropoint_requestor_id": string;
    "customer.main_phone": string;
    "customer.manual_exchange": string;
    "customer.mark_plan_id": string;
    "customer.max_pay_percent": number;
    "customer.misc_bill_template": number;
    "customer.monthly_order_goal": number;
    "customer.motor_carrier_id": string;
    "customer.name": string;
    "customer.next_call_date": Date;
    "customer.next_rec_call": Date;
    "customer.nxt_action_code_id": string;
    "customer.nxt_action_date": Date;
    "customer.operations_user": string;
    "customer.order_count": number;
    "customer.order_detail_at": string;
    "customer.order_start_date": Date;
    "customer.p44_profile_id": string;
    "customer.paid_loads": number;
    "customer.pay_days_date": Date;
    "customer.pay_days_orders": number;
    "customer.payee_id": string;
    "customer.pd0_alert": number;
    "customer.pd1_np_auto": string;
    "customer.pd1_sp_auto": string;
    "customer.pd1_sp_thresh": number;
    "customer.pd1_sp_thresh_c": string;
    "customer.pd1_sp_thresh_d": Date;
    "customer.pd1_sp_thresh_n": number;
    "customer.pd1_sp_thresh_r": number;
    "customer.pd2_np_auto": string;
    "customer.pd2_sp_auto": string;
    "customer.pd2_sp_thresh": number;
    "customer.pd2_sp_thresh_c": string;
    "customer.pd2_sp_thresh_d": Date;
    "customer.pd2_sp_thresh_n": number;
    "customer.pd2_sp_thresh_r": number;
    "customer.pd3_np_auto": string;
    "customer.pd3_sp_auto": string;
    "customer.pd3_sp_thresh": number;
    "customer.pd3_sp_thresh_c": string;
    "customer.pd3_sp_thresh_d": Date;
    "customer.pd3_sp_thresh_n": number;
    "customer.pd3_sp_thresh_r": number;
    "customer.pd4_np_auto": string;
    "customer.pd4_sp_auto": string;
    "customer.pd4_sp_thresh": number;
    "customer.pd4_sp_thresh_c": string;
    "customer.pd4_sp_thresh_d": Date;
    "customer.pd4_sp_thresh_n": number;
    "customer.pd4_sp_thresh_r": number;
    "customer.plc_uid": number;
    "customer.post_summary_dtl": string;
    "customer.potential": number;
    "customer.potential_c": string;
    "customer.potential_d": Date;
    "customer.potential_n": number;
    "customer.potential_r": number;
    "customer.prev_code": string;
    "customer.primary_agency": string;
    "customer.primary_commodity_id": string;
    "customer.primary_contact": string;
    "customer.primary_sort": string;
    "customer.primary_trailer_type": string;
    "customer.print_containers": string;
    "customer.progress_template": number;
    "customer.prospect_type_id": string;
    "customer.quebec_number": string;
    "customer.quote_days_to_expire": number;
    "customer.rate_override_nmfc_code": string;
    "customer.reassign_profile_id": string;
    "customer.rec_call_days": number;
    "customer.remarks": string;
    "customer.require_tracking": string;
    "customer.required_vendor": string;
    "customer.revenue": number;
    "customer.revenue_c": string;
    "customer.revenue_d": Date;
    "customer.revenue_n": number;
    "customer.revenue_r": number;
    "customer.revenue_start_date": Date;
    "customer.routing_guide_profile_id": string;
    "customer.sales_manager_id": string;
    "customer.sales_status_id": string;
    "customer.salesperson_id": string;
    "customer.search_city": string;
    "customer.search_state_id": string;
    "customer.search_zip_code": string;
    "customer.secondary_sort": string;
    "customer.sell_tariff_id": string;
    "customer.service_fail_cons": string;
    "customer.service_fail_drop": string;
    "customer.service_fail_pick": string;
    "customer.service_fail_ship": string;
    "customer.service_failures": string;
    "customer.service_failures_review": string;
    "customer.shipment_ident_type": string;
    "customer.sic_code": string;
    "customer.start_date": Date;
    "customer.state_id": string;
    "customer.summary_billing": string;
    "customer.summary_template": number;
    "customer.tar_uid": number;
    "customer.target_profit": number;
    "customer.tenfour_customer_id": string;
    "customer.tracking_hours_prior": number;
    "customer.trimble_place_id": string;
    "customer.type_of": string;
    "customer.use_local_mile": string;
    "customer.vm_part_update": string;
    "customer.waterfall_auto_route_guides": string;
    "customer.waterfall_lead_time": number;
    "customer.waterfall_notify_email": string;
    "customer.waterfall_process": string;
    "customer.website_url": string;
    "customer.wf_auto_renbill": string;
    "customer.wf_autobill": string;
    "customer.wf_bwop": string;
    "customer.wf_queue": number;
    "customer.zip_code": string;
    "customer.zone_id": string;
    customer_ediloc: string;
    customer_id: string;
    default_match_id: string;
    dim_height: string;
    dim_length: string;
    dim_surcharge: number;
    dim_surcharge_c: string;
    dim_surcharge_d: Date;
    dim_surcharge_n: number;
    dim_surcharge_r: number;
    dim_width: string;
    direction: string;
    dispatcher_user_id: string;
    dray_del_date: Date;
    dray_del_pay: number;
    dray_del_pay_c: string;
    dray_del_pay_d: Date;
    dray_del_pay_n: number;
    dray_del_pay_r: number;
    dray_del_payee: string;
    dray_del_xfer_dt: Date;
    dray_pu_date: Date;
    dray_pu_pay: number;
    dray_pu_pay_c: string;
    dray_pu_pay_d: Date;
    dray_pu_pay_n: number;
    dray_pu_pay_r: number;
    dray_pu_payee: string;
    dray_pu_xfer_dt: Date;
    edi_commodity_id: string;
    edi_equip_type_id: string;
    edi_error: number;
    edi_order_type_id: string;
    edi_revenue_code: string;
    edi_seg_alloc_code: string;
    eir1_no: string;
    eir2_no: string;
    entered_user_id: string;
    equipment_type_id: string;
    est_tolls: number;
    est_tolls_c: string;
    est_tolls_d: Date;
    est_tolls_n: number;
    est_tolls_r: number;
    external_invoiceno: string;
    extra_deliveries: number;
    extra_pickups: number;
    fleet_manager: string;
    forwd_location_id: string;
    free_days: number;
    freight_charge: number;
    freight_charge_c: string;
    freight_charge_d: Date;
    freight_charge_n: number;
    freight_charge_r: number;
    fuel_distance: number;
    fuel_distance_um: string;
    func_ack_batch: number;
    func_ack_id: string;
    func_ack_rec: string;
    func_ack_rec_date: Date;
    func_ack_received: string;
    func_ack_status: string;
    gs06_group_cntlno: string;
    gs_date_time: Date;
    hazmat: string;
    hazmat_code: string;
    high_value: string;
    hold_reason: string;
    id: string;
    ignore_map_errors: string;
    image_is_present: string;
    import_export: string;
    include_split_point: string;
    initial_purpose: string;
    intercompany: string;
    intercompany_key: string;
    invoice_recv_date: Date;
    is_autorate_dist: string;
    is_container: string;
    is_dedicated: string;
    is_dray: string;
    is_local_mile: string;
    isa13_intr_cntlno: string;
    jag_carrier_id: string;
    jag_cost_rate_id: number;
    ldport_location_id: string;
    linear_feet_avail: number;
    load_date: Date;
    lock_miles: string;
    ltl: string;
    manifest_id: string;
    no_display: string;
    nte_lp_num: string;
    nte_lp_qual: string;
    on_hold: string;
    operational_status: string;
    operations_user: string;
    order_compared: string;
    order_id: string;
    order_mode: string;
    order_type_id: string;
    order_value: number;
    order_value_c: string;
    order_value_d: Date;
    order_value_n: number;
    order_value_r: number;
    ordered_by: string;
    ordered_date: Date;
    ordered_method: string;
    orig_dest_rate_id: string;
    original_order: string;
    other_pay_total: number;
    other_pay_total_c: string;
    other_pay_total_d: Date;
    other_pay_total_n: number;
    other_pay_total_r: number;
    otherchargetotal: number;
    otherchargetotal_c: string;
    otherchargetotal_d: Date;
    otherchargetotal_n: number;
    otherchargetotal_r: number;
    override_pay_amt: number;
    override_pay_amt_c: string;
    override_pay_amt_d: Date;
    override_pay_amt_n: number;
    override_pay_amt_r: number;
    override_pay_rate: number;
    override_pay_units: number;
    override_type: string;
    override_units: number;
    pallets_how_many: number;
    pallets_required: string;
    partner_id: string;
    pay_gross: number;
    pay_gross_c: string;
    pay_gross_d: Date;
    pay_gross_n: number;
    pay_gross_r: number;
    pick_up_no: string;
    pieces: number;
    placard_required: string;
    planning_comment: string;
    port_of_discharge: string;
    preload_trailer_id: string;
    preloaded: string;
    purpose: string;
    purpose_type: string;
    rate: number;
    rate_currency_type: string;
    rate_id: number;
    rate_min_weight: number;
    rate_min_weight_um: string;
    rate_type: string;
    rate_unit_desc: string;
    rate_units: number;
    rbd_timezone_id: string;
    ready_to_bill: string;
    ready_to_send: string;
    record_in_use: string;
    recurring_order_id: string;
    reply_action: string;
    reply_altpartnerid: string;
    reply_batch_id: number;
    reply_created: string;
    reply_date: Date;
    reply_error: number;
    reply_error_descr: string;
    reply_gs_date_time: Date;
    reply_partner_id: string;
    reply_reason: string;
    reply_received: string;
    reply_received_ts: string;
    reply_remarks: string;
    reply_st02: string;
    reply_tid: number;
    reply_trans_count: number;
    reply_transmitted: string;
    reply_version: string;
    require_tracking: string;
    required_vendor: string;
    respond_by_date: Date;
    revenue_code_id: string;
    revenue_share_method: string;
    round_trip: string;
    sail_date: Date;
    scac: string;
    seal_number: string;
    seg_alloc_code: string;
    ship_status_to_edi: string;
    shipment_id: string;
    shipper_ediloc: string;
    shipper_stop_id: string;
    shipstatus2edi_dt: Date;
    ss_location_id: string;
    st02_trxset_cntlno: string;
    status: string;
    "stop_dest.actual_arrival": Date;
    "stop_dest.actual_departure": Date;
    "stop_dest.address": string;
    "stop_dest.address2": string;
    "stop_dest.appointment_status_id": string;
    "stop_dest.appt_required": string;
    "stop_dest.cases": number;
    "stop_dest.city_id": number;
    "stop_dest.city_name": string;
    "stop_dest.company_id": string;
    "stop_dest.confirmed": string;
    "stop_dest.contact_name": string;
    "stop_dest.dist_from_prev": number;
    "stop_dest.dist_from_prev_um": string;
    "stop_dest.driver_load_unload": string;
    "stop_dest.edi_code": string;
    "stop_dest.edi_loc_code": string;
    "stop_dest.eta": Date;
    "stop_dest.hub": number;
    "stop_dest.id": string;
    "stop_dest.latitude": number;
    "stop_dest.location_id": string;
    "stop_dest.location_name": string;
    "stop_dest.longitude": number;
    "stop_dest.manifest_reg_uid": number;
    "stop_dest.movement_id": string;
    "stop_dest.movement_sequence": number;
    "stop_dest.operational_status": string;
    "stop_dest.order_id": string;
    "stop_dest.order_sequence": number;
    "stop_dest.orig_sched_early": Date;
    "stop_dest.orig_sched_late": Date;
    "stop_dest.pallets_dropped": number;
    "stop_dest.pallets_picked_up": number;
    "stop_dest.parent_trans_type": string;
    "stop_dest.payment_code_reviewed": string;
    "stop_dest.phone": string;
    "stop_dest.ponum": string;
    "stop_dest.projected_arrival": Date;
    "stop_dest.refno": string;
    "stop_dest.sched_arrive_early": Date;
    "stop_dest.sched_arrive_late": Date;
    "stop_dest.seal": string;
    "stop_dest.showas_address": string;
    "stop_dest.showas_address2": string;
    "stop_dest.showas_city_id": number;
    "stop_dest.showas_city_name": string;
    "stop_dest.showas_location_id": string;
    "stop_dest.showas_location_name": string;
    "stop_dest.showas_state": string;
    "stop_dest.showas_zip_code": string;
    "stop_dest.signed_for_name": string;
    "stop_dest.state": string;
    "stop_dest.status": string;
    "stop_dest.stop_type": string;
    "stop_dest.volume": number;
    "stop_dest.volume_um": string;
    "stop_dest.weight": number;
    "stop_dest.weight_um": string;
    "stop_dest.zip_code": string;
    "stop_dest.zone_id": string;
    "stop_origin.actual_arrival": Date;
    "stop_origin.actual_departure": Date;
    "stop_origin.address": string;
    "stop_origin.address2": string;
    "stop_origin.appointment_status_id": string;
    "stop_origin.appt_required": string;
    "stop_origin.cases": number;
    "stop_origin.city_id": number;
    "stop_origin.city_name": string;
    "stop_origin.company_id": string;
    "stop_origin.confirmed": string;
    "stop_origin.contact_name": string;
    "stop_origin.dist_from_prev": number;
    "stop_origin.dist_from_prev_um": string;
    "stop_origin.driver_load_unload": string;
    "stop_origin.edi_code": string;
    "stop_origin.edi_loc_code": string;
    "stop_origin.eta": Date;
    "stop_origin.hub": number;
    "stop_origin.id": string;
    "stop_origin.latitude": number;
    "stop_origin.location_id": string;
    "stop_origin.location_name": string;
    "stop_origin.longitude": number;
    "stop_origin.manifest_reg_uid": number;
    "stop_origin.movement_id": string;
    "stop_origin.movement_sequence": number;
    "stop_origin.operational_status": string;
    "stop_origin.order_id": string;
    "stop_origin.order_sequence": number;
    "stop_origin.orig_sched_early": Date;
    "stop_origin.orig_sched_late": Date;
    "stop_origin.pallets_dropped": number;
    "stop_origin.pallets_picked_up": number;
    "stop_origin.parent_trans_type": string;
    "stop_origin.payment_code_reviewed": string;
    "stop_origin.phone": string;
    "stop_origin.ponum": string;
    "stop_origin.projected_arrival": Date;
    "stop_origin.refno": string;
    "stop_origin.sched_arrive_early": Date;
    "stop_origin.sched_arrive_late": Date;
    "stop_origin.seal": string;
    "stop_origin.showas_address": string;
    "stop_origin.showas_address2": string;
    "stop_origin.showas_city_id": number;
    "stop_origin.showas_city_name": string;
    "stop_origin.showas_location_id": string;
    "stop_origin.showas_location_name": string;
    "stop_origin.showas_state": string;
    "stop_origin.showas_zip_code": string;
    "stop_origin.signed_for_name": string;
    "stop_origin.state": string;
    "stop_origin.status": string;
    "stop_origin.stop_type": string;
    "stop_origin.volume": number;
    "stop_origin.volume_um": string;
    "stop_origin.weight": number;
    "stop_origin.weight_um": string;
    "stop_origin.zip_code": string;
    "stop_origin.zone_id": string;
    teams_required: string;
    temperature_max: number;
    temperature_max_um: string;
    temperature_min: number;
    temperature_min_um: string;
    tender_ignored: string;
    tender_status: string;
    total_charge: number;
    total_charge_c: string;
    total_charge_d: Date;
    total_charge_n: number;
    total_charge_r: number;
    total_pay: number;
    total_pay_c: string;
    total_pay_d: Date;
    total_pay_n: number;
    total_pay_r: number;
    tractor_type: string;
    trans_count: number;
    transaction_set: string;
    transit_warning_id: string;
    ts_borrowing_company: string;
    ts_commodity: string;
    user_id: string;
    version: string;
    vessel: string;
    vessel_cutoff_date: Date;
    weight: number;
    weight_um: string;
    xferred2billing: string;
    xferred2billing_dt: Date;
}

