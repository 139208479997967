import { DataSource, DataSourceMode, Dialog, DialogProps, Label, McLeodMainPageUtil, Panel, SaveButton, ScreenStack, Snackbar, StringOrPropsOrComponent, Textbox } from "@mcleod/components";
import { SaveAction } from "@mcleod/components/src/components/savebutton/SaveButton";
import { Api, HorizontalAlignment, RowUpdateEvent, getLogger } from "@mcleod/core";
import { SuccessFail } from "@mcleod/core/src/SuccessFail";
import { Orders } from "./Orders";


const log = getLogger("lme.dispatch.CheckDuplicateBOL");

export enum DuplicateBOLSource {
    ADD = "add",
    ADD_RECURRING = "addRecurring",
    UPDATE = "update",
    DUPLICATE = "duplicate"
}
export class CheckDuplicateBOL {
    private mainDataSource: DataSource;
    private ordersLayout: Orders;
    private validatingBOL = false;
    private duplicateBOLSnackbar: Snackbar;
    private userDismissedDuplicateBOLSnackbar;
    private DUPE_BOL_WARN: String;
    private DUPE_BOL_NOTALLOWED: String;

    constructor(order: Orders) {
        this.ordersLayout = order;
        this.mainDataSource = order.mainDataSource;
        this.DUPE_BOL_WARN = "W";
        this.DUPE_BOL_NOTALLOWED = "N"
    }

    public async validate(source: DuplicateBOLSource, saveButton?: SaveButton): Promise<SuccessFail> {
        if (!this.validatingBOL) {
            this.validatingBOL = true;
            let snackbarTarget = null;

            return new Promise((resolve) => {
                this.getCheckDuplicateBOLResult(source).then(result => {
                    const data = result?.data?.[0];
                    if (data.duplication_message != null) {
                        const duplicateBOLWarnLevel = data.warn_level;
                        if (duplicateBOLWarnLevel == this.DUPE_BOL_WARN) {

                            if (saveButton.saveAction == SaveAction.SAVE_AND_CLOSE || source == DuplicateBOLSource.ADD_RECURRING)
                                snackbarTarget = McLeodMainPageUtil.getRouterPanel();
                            else
                                snackbarTarget = ScreenStack.getSnackbarTarget();

                            const duplicateBOLStatusKey = this.mainDataSource.activeRow.get("blnum");
                            this.userDismissedDuplicateBOLSnackbar = null;
                            const panel = CheckDuplicateBOL.createDuplicateBOLPanel(data);
                            // if currently displaying a snackbar, update the message
                            if (this.duplicateBOLSnackbar != null) {
                                const displayedPanel = this.duplicateBOLSnackbar.findComponentById("snackPanel") as Panel;
                                displayedPanel.removeAll();
                                panel.components.forEach(comp => displayedPanel.add(comp));
                            } else {
                                this.showDuplicateBOLSnackbar(panel, duplicateBOLStatusKey, snackbarTarget);
                            }
                            resolve(new SuccessFail(true));
                        } else if (duplicateBOLWarnLevel == this.DUPE_BOL_NOTALLOWED && source != DuplicateBOLSource.DUPLICATE) {
                            resolve(new SuccessFail(false, data.duplication_message));
                        } else {
                            resolve(new SuccessFail(true));
                        }
                    } else {
                        resolve(new SuccessFail(true));
                    }
                }).finally(() => this.validatingBOL = false);
            });
        }
    }

    public showDuplicateBOLSnackbar(snackText: StringOrPropsOrComponent, statusKey?: string, snackbarTarget?: Panel) {
        Snackbar.showWarningSnackbar(snackText, {
            id: statusKey, targetPanel: snackbarTarget, persist: true, onDismiss: (() => {
                this.duplicateBOLSnackbar = null;
                this.userDismissedDuplicateBOLSnackbar = statusKey;
            })
        });
        this.duplicateBOLSnackbar = ScreenStack.getOldestSnackbar(statusKey) as Snackbar
    }

    async getCheckDuplicateBOLResult(source: DuplicateBOLSource): Promise<any> {
        return await Api.search("lme/dispatch/check-duplicate-bol", {
            order_row: this.mainDataSource.getDataboundValues(null, true, null, null),
            order_id: this.mainDataSource.mode === DataSourceMode.UPDATE ? this.mainDataSource.activeRow.get("id") : null,
            source_mode: source
        });
    }

    private static createDuplicateBOLPanel(data: any): Panel {
        const panel = new Panel({ id: "snackPanel" });
        if (data.duplication_message != null) {
            panel.add(new Label({ caption: `${data.duplication_message}`, rowBreak: false, paddingTop: 0, paddingBottom: 0 }));
        }
        return panel;
    }

    async rowUpdatedBySource(postEvent: RowUpdateEvent, saveButton: SaveButton): Promise<any> {
        const source = this.getDuplicateBOLSource();

        if (source == DuplicateBOLSource.DUPLICATE)
            return this.rowUpdatedWithoutDialog(postEvent, source, saveButton);
        else
            return this.rowUpdated(postEvent, source, saveButton);
    }

    async rowUpdatedWithoutDialog(postEvent: RowUpdateEvent, source: DuplicateBOLSource, saveButton: SaveButton): Promise<any> {
        return new Promise((resolve) => {
            this.validate(source, saveButton).then(async result => {
                if (!result.success) {
                    postEvent.preventDefault("Duplicate BOL!!", () => { });
                    resolve(result.reason);
                } else {
                    resolve(result);
                }
            });
        });
    }

    async rowUpdated(postEvent: RowUpdateEvent, source: DuplicateBOLSource, saveButton: SaveButton): Promise<SuccessFail> {
        return new Promise((resolve) => {
            this.validate(source, saveButton).then(async result => {
                if (result instanceof SuccessFail && !result.success) {
                    await this.showDuplicateBOLValidationDialog(result?.reason, postEvent)
                        .then(result => {
                            const blnum: Textbox = this.ordersLayout.textboxBlnum;
                            blnum.focus().selectText();
                        })
                        .then(result => resolve(new SuccessFail(false, "")));
                } else {
                    resolve(new SuccessFail(true));
                }
            });
        });
    }

    async showDuplicateBOLValidationDialog(reason: string, postEvent: RowUpdateEvent): Promise<Dialog> {
        const dialog = this.createDuplicateBOLValidationDialog(true, "Duplicate BOL", reason);
        postEvent.preventDefault(reason, () => { });
        return await dialog.show();
    }

    createDuplicateBOLValidationDialog(preventSave: boolean, title: string, message: string): Dialog {
        const dialog = new Dialog(this.getDialogProps(preventSave, title));
        dialog.add(new Label({ caption: message, align: HorizontalAlignment.LEFT, padding: 0 }));
        return dialog;
    }

    getDialogProps(preventSave: boolean, title: string): Partial<DialogProps> {
        return {
            okVisible: preventSave, xVisible: false, maxWidth: 450,
            titleProps: {
                height: 40, paddingTop: 0, paddingBottom: 0, caption: title,
                imageHeight: 24, imageMarginRight: 8, imageName: "warning"
            }
        };
    }

    getDuplicateBOLSource(): DuplicateBOLSource {
        let sourceMode = (this.mainDataSource.mode == DataSourceMode.UPDATE) ? DuplicateBOLSource.UPDATE : DuplicateBOLSource.ADD;

        if (this.mainDataSource.mode == DataSourceMode.ADD && this.ordersLayout.switchRecurringOrder.checked)
            sourceMode = DuplicateBOLSource.ADD_RECURRING;

        return sourceMode;
    }

}
