import { Label, Panel, Textbox } from "@mcleod/components";
import { HorizontalAlignment } from "@mcleod/core";
import { AutogenLayoutDemoCollapse } from "./autogen/AutogenLayoutDemoCollapse";

export class DemoCollapse extends AutogenLayoutDemoCollapse {
    buttonExpandClicked() {
        this.panelDemo.expand();
    }

    buttonCollapseClicked() {
        this.panelDemo.collapse();
    }

    buttonCollapseThenExpandClicked() {
        this.panelDemo.collapse({ speed: 500 }).then(unused => {
            setTimeout(() => {
                const index = this.panelDemo.indexOf(this.panelCollapse);
                if (this.panelDemo.indexOf(this.panelCollapse) >= 0) {
                    this.panelDemo.removeAll();
                    this.panelDemo.add(this.createReplacementPanel());
                } else {
                    this.panelDemo.removeAll();
                    this.panelDemo.add(this.panelCollapse);
                }
                this.panelDemo.expand({ speed: 500 });
            }, 200);
        });
    }

    createReplacementPanel() {
        return new Panel({
            components: [
                new Label({ caption: "We completely replaced the contents of the panel while the panel was collapsed.", align: HorizontalAlignment.CENTER, width: 400 }),
                new Textbox({ caption: "Replacement textbox" })
            ]
        });
    }

    getDemoPath() {
        return ["Concepts", "Panel transitions"];
    }
}
