import { Component, Table, Tabset } from "@mcleod/components";
import { ArrayUtil, StringUtil } from "@mcleod/core";
import { DesignerAction } from "./DesignerAction";
import { DesignerActionResult } from "./DesignerActionResult";

export class ActionSwitchAdjacentComponents implements DesignerAction {
    component: Component;
    by: number;

    constructor(component, by: number) {
        this.component = component;
        this.by = by;
    }

    execute(): DesignerActionResult {
        return { success: this.switchComps(this.by) };
    }

    switchComps(by: number): boolean {
        const parent = this.component.parent;
        if (parent instanceof Table || parent instanceof Tabset) {
            return parent._handleSpecialSwitch(this.component, by);
        }
        else if (parent.indexOf != null) {
            const index = parent.indexOf(this.component);
            const switchIndex = index + by;
            if (switchIndex >= 0 && switchIndex < parent.components.length) {
                ArrayUtil.switchArrayElements(parent.components, index, switchIndex);
                parent.reLayout();
            }
            return true;
        }
    }

    undo() {
        this.switchComps(this.by * -1);
    }

    toString() {
        return "Switch " + StringUtil.toProperCase(this.component.serializationName);
    }
}
