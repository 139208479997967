import { Api, ArrayUtil, DisplayValue, getLogger } from "@mcleod/core";
import { DataSource } from "../databinding/DataSource";

const log = getLogger("components.page.TailorExtension");

export interface TailorExtensionParam {
    id: string;
    value: string;
}

export class TailorExtension {
    private id: string;
    private descr: string;
    private params: TailorExtensionParam[];

    constructor(id: string, descr: string, params?: TailorExtensionParam[]) {
        this.id = id;
        this.descr = descr;
        this.params = params;
    }

    public async invoke(dataSource: DataSource): Promise<void> {
        const requestBody = this.buildRequestBody(dataSource);
        log.debug("Invoking Tailor Extension with body: %o", requestBody);
        const response = await Api.post("tailor/invoke-extension", requestBody);
        const data = ArrayUtil.isEmptyArray(response?.data) !== true ? response.data[0] : null;
        log.debug("Response from Tailor Extension: %o", data);
    }

    private buildRequestBody(dataSource: DataSource): any {
        const result: any = { id: this.id };
        if (ArrayUtil.isEmptyArray(this.params) === false) {
            const finalizedParams: any[] = [];
            for (const param of this.params) {
                const displayValue = DisplayValue.getFormattedDataString(param.value, dataSource?.activeRow);
                finalizedParams.push({ ...param, value: displayValue })
            }
            result.params = finalizedParams;
        }
        return result;
    }
}
