import { DataSource, HorizontalSpacer, Layout, Panel, SearchButton, Table, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutLocationDetentionHistory extends Layout {
    horizontalspacer1: HorizontalSpacer;
    panel1: Panel;
    panel2: Panel;
    searchbuttonGetData: SearchButton;
    sourceLocationDetentionHistory: DataSource;
    table1: Table;
    textboxDateRange: Textbox;
    textboxStopLocationId: Textbox;
    textboxSummaryAverageCharge: Textbox;
    textboxSummaryChargeCount: Textbox;
    textboxSummaryCount: Textbox;
    textboxSummaryTotalCharges: Textbox;
}
