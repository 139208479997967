/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelBrkQualProfile.ts                *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/powerbroker/brk-qual-profile";

export class AutogenModelBrkQualProfile<RowType extends ModelRow> extends Endpoint<RowType, RowBrkQualProfileFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowBrkQualProfile extends ModelRow<RowBrkQualProfileFields> {
    constructor(values?: Partial<RowBrkQualProfileFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowBrkQualProfileFields {
    company_id: string;
    descr: string;
    disqualified_pts: number;
    id: string;
    is_active: string;
    warning_level_pts: number;
}

