import { ModelRow } from "@mcleod/core";
import { Table } from "../components/table/Table";
import { TableRow } from "../components/table/TableRow";
import { DataSource } from "../databinding/DataSource";
import { DomEvent } from "./DomEvent";
import { Event, EventListener } from "./Event";

export interface TableRowBeforeSaveListener extends EventListener {
    (event: TableRowBeforeSaveEvent): void;
}

export class TableRowBeforeSaveEvent extends Event {
    private table: Table = null;
    private parentDataSource: DataSource = null;

    constructor(component: any, table: Table, parentDataSource: DataSource, domEvent?: DomEvent) {
        super(component, domEvent);
        this.table = table;
        this.parentDataSource = parentDataSource;
    }

    public getTableRow(): TableRow {
        return this.target as TableRow;
    }

    public getTable(): Table {
        return this.table;
    }

    public getDataSource(): DataSource {
        return this.table.dataSource;
    }

    public getParentDataSource(): DataSource {
        return this.parentDataSource;
    }

    public getParentRow(): ModelRow {
        return this.parentDataSource.activeRow;
    }
}
