import { AutogenModelUsers, AutogenRowUsers } from "./autogen/AutogenModelUsers";

export class ModelUsers extends AutogenModelUsers<RowUsers> {
    constructor() {
        super(RowUsers);
    }
}

export class RowUsers extends AutogenRowUsers {
}
