import { ClickEvent } from "@mcleod/components";
import { McLeodClassicIntegration } from "@mcleod/core";
import { AutogenLayoutCarrierRateQuickInfo } from "./autogen/AutogenLayoutCarrierRateQuickInfo";

export class CarrierRateQuickInfo extends AutogenLayoutCarrierRateQuickInfo {

    /** This is an event handler for the onClick event of buttonCarrierRate.  */
    buttonCarrierRateOnClick(event: ClickEvent) {
        McLeodClassicIntegration.openClassicScreen("com.tms.client.loadmaster.brdsp.EntryCarrierRate", this.mainDataSource.activeRow.get("id"));
    }
}
