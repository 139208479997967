import { CarrierSelectionExpand } from "../../CarrierSelectionExpand";
import { Label, Layout, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutCarrierSelectionRateChange extends Layout {
	labelCarrierName: Label;
	labelCurrentBlanketRate: Label;
	labelCurrentRate: Label;
	labelNewBlanketRate: Label;
	labelNewRate: Label;
	layoutNewRevenueDetails: CarrierSelectionExpand;
	layoutOldRevenueDetails: CarrierSelectionExpand;
	panelCarrierLogo: Panel;
	panelCurrent: Panel;
	panelNew: Panel;
	textboxCurrentSource: Textbox;
	textboxCurrentTotalCostAmount: Textbox;
	textboxCurrentTotalMarginAmount: Textbox;
	textboxCurrentTotalMarkupAmount: Textbox;
	textboxCurrentTotalProfitAmount: Textbox;
	textboxCurrentTotalRevenueAmount: Textbox;
	textboxNewSource: Textbox;
	textboxNewTotalCostAmount: Textbox;
	textboxNewTotalMarginAmount: Textbox;
	textboxNewTotalMarkupAmount: Textbox;
	textboxNewTotalProfitAmount: Textbox;
	textboxNewTotalRevenueAmount: Textbox;
}
