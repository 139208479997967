import { Label, Tab } from "@mcleod/components";
import { ModelRow } from "@mcleod/core";
import { AutogenLayoutCommentsAndReferenceNumbers } from "./autogen/AutogenLayoutCommentsAndReferenceNumbers";


export class CommentsAndReferenceNumbers extends AutogenLayoutCommentsAndReferenceNumbers {
    private _ediStopId: string;
    private _stopRow: ModelRow<any>;
    private _refnoCount: number;
    private _commentCount: number;

    override onLoad() {
        this.sourceEdiRefNumber.search({ stop_id: this.parentStopExpandedComponent.ediStopId });
        this.sourceEdiStopNote.search({ stop_id: this.parentStopExpandedComponent.ediStopId });

        this.styleTab(this.tabStopComments);
        this.styleTab(this.tabStopRefNumbers);
    }
    get ediStopId(): string {
        return this._ediStopId;
    }
    set ediStopId(value: string) {
        this._ediStopId = value;
    }

    styleTab(tab: Tab) {
        if (tab["_heading"] != null && tab["_headingLabel"] != null) {
            tab["_heading"].color = "subtle.light";
            tab["_heading"].marginBottom = 10;
            tab["_headingLabel"].setProps({ color: "default", fontBold: true, fillRow: false })
            if (tab["countLabel"] == null) {
                tab["countLabel"] = new Label({
                    fontBold: true, caption: "0", fillRow: true,
                    color: "primary", rowBreak: false, marginLeft: 5
                });
                tab["_heading"].insert(tab["countLabel"], 1);
            }
        }
    }

    sourceEdiRefNumberAfterExecution() {
        this._refnoCount = this.sourceEdiRefNumber.data.length;
        if (this.tabStopRefNumbers != null && this.tabStopRefNumbers["countLabel"] != null) {
            this.tabStopRefNumbers["countLabel"].caption = this._refnoCount;
        }
    }

    sourceEdiStopNoteAfterExecution() {
        this._commentCount = this.sourceEdiStopNote.data.length;
        if (this.tabStopComments != null && this.tabStopComments["countLabel"] != null) {
            this.tabStopComments["countLabel"].caption = this._commentCount;
        }
    }

    get parentStopExpandedComponent() {
        return this.parent as CommentsAndReferenceNumbers;
    }
}
