/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelOrderHistType.ts                 *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/dispatch/order-hist-type";

export class AutogenModelOrderHistType<RowType extends ModelRow> extends Endpoint<RowType, RowOrderHistTypeFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowOrderHistType extends ModelRow<RowOrderHistTypeFields> {
    constructor(values?: Partial<RowOrderHistTypeFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowOrderHistTypeFields {
    allow_manual_entry: string;
    company_id: string;
    descr: string;
    id: string;
    remarks: string;
    screen_class_name: string;
}

