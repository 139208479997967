import { DataSourceMode, Layout, SaveButton, SlideoutDecorator, TableRow, TableRowDisplayEvent, Textbox } from "@mcleod/components";
import { ReflectiveDialogs } from "@mcleod/components/src/base/ReflectiveDialogs";
import { DataSourceValidationEvent } from "@mcleod/components/src/events/DataSourceValidationEvent";
import { ModelRow, getLogger, getUserSettings } from "@mcleod/core";
import { getDispatchControlBoolean } from "../../general/src/models/ModelDispatchControl";
import { AutogenLayoutCarrierRatings } from "./autogen/AutogenLayoutCarrierRatings";
import { ModelCarrierPerformanceDetail } from "./models/ModelCarrierPerformanceDetail";
const log = getLogger("lme.powerbroker.CarrierRatings")

export class CarrierRatings extends AutogenLayoutCarrierRatings {
    doOnSlideoutClosed: (dataPosted: boolean) => void;

    /**
     * Shows the slideout.
     * @param props
     */
    public static show(props: CarrierRatingsProps) {
        if (props.carrierRatingHeaderData.order_id == null || props.carrierRatingHeaderData.movement_id == null) {
            log.debug("No order or movement found.");
            return;
        }
        const mandatory_carrier_ratings = getDispatchControlBoolean("mandatory_carrier_ratings");
        const layout = Layout.getLayout("lme/powerbroker/CarrierRatings") as CarrierRatings;
        layout.doOnSlideoutClosed = props.doOnSlideoutClosed;
        layout.addLayoutLoadListener(async () => {
            new SlideoutDecorator({
                layout: layout,
                width: 1200,
                fillVerticalSpace: true,
                backgroundColor: "defaultBackground",
                title: `Carrier Ratings`,
                addlComponents: [layout.getSaveButton()],
                ...(mandatory_carrier_ratings ? { overlayProps: { closeOnClickOff: false, greyedBackground: true } } : {}),
                onClose: () => layout.closeOverlay(mandatory_carrier_ratings),
                doAfterSlideIn: () => {
                    layout.createBrokerRatingHeaderRow(props);
                    layout.createBrokerRatingRows();
                },
                doAfterSlideOut: props?.doAfterSlideOut
            });
        });
    }

    getSaveButton(): SaveButton {
        const saveButton = new SaveButton({
            dataSource: this.mainDataSource,
            allowSave: false,
            allowSaveAndClose: true,
            rowBreak: false
        })
        saveButton.onClose = () => this.closeOverlay(false);
        return saveButton;
    }

    public async closeOverlay(mandatoryRatings: boolean) {
        if (mandatoryRatings) {

            ReflectiveDialogs.showDialog({ caption: "Carrier ratings must be entered and saved.", paddingBottom: 12 }, { left: 600, marginLeft: "calc(100% - 1200px)", title: "Invalid Data", panelContentProps: { maxHeight: 400, scrollY: true } })
            return false;
        }
        else {
            this.slideOut().then(() => {
                if (this.doOnSlideoutClosed != null)
                    this.doOnSlideoutClosed(true);
            });
        }
    }

    /**
     * Creates the broker_rating_header row.
     * @param props
     * @private
     */
    private createBrokerRatingHeaderRow(props: CarrierRatingsProps) {
        const userSettings = getUserSettings();
        const newRow = new ModelRow("lme/powerbroker/broker-rating-header", true, {
            ...props.carrierRatingHeaderData,
            rated_by: userSettings.user_id,
            rated_date: new Date()
        });
        newRow.setLookupModelData("payee_id", new ModelRow(this.textboxPayeeId.lookupModel, false, { id: props.carrierRatingHeaderData.payee_id, name: props.carrier_name }));
        newRow.setLookupModelData("rated_by", new ModelRow(this.textboxRatedBy.lookupModel, false, { id: userSettings.user_id, name: userSettings.user_name }));
        this.mainDataSource.setRowsAndMode(DataSourceMode.ADD, [newRow]);
    }

    /**
     * Creates the broker_rating rows (the questions) with the rating stars.
     * @private
     */
    private async createBrokerRatingRows() {
        const results = await new ModelCarrierPerformanceDetail().search({ brokrate_only: true });
        results.modelRows.forEach((detailRow: ModelRow) => {
            const ratingRow = new ModelRow("lme/powerbroker/broker-rating", true, {
                prompt: detailRow.get("prompt"),
                rating: 0.0
            });
            this.tableRatings.addRow(ratingRow, null, { addToData: true });
        });
        this.sourceBrokerRating.addValidationListener((event: DataSourceValidationEvent) => {
            this.tableRatings.rows.some((tableRow: TableRow) => {
                if (tableRow.data.get("rating", 0) == 0) {
                    event.validationResults.push({ isValid: false, validationMessage: `All questions must have a rating greater than 0 to save.`, component: tableRow.findComponentById("starratingRating"), caption: `Question ${tableRow.index + 1}` });
                }
            });
        })
        this.sourceBrokerRating.displayDataInBoundComponents();
    }

    /**
     * Adds the number to the Question caption.
     * @param event
     * @private
     */
    private tableRatingsOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        (tableRow.findComponentById("textboxPrompt") as Textbox).caption = `Question ${tableRow.index + 1}`;
    }

}

export interface CarrierRatingsProps {
    carrierRatingHeaderData: {
        order_id: string,
        movement_id: string,
        payee_id: string
    },
    carrier_name: string,
    doOnSlideoutClosed?: (cancelled: boolean) => void,
    doAfterSlideOut?: (decorator: SlideoutDecorator) => void
}
