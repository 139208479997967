import { Collection } from "@mcleod/core";
import { StringOrPropsOrComponent } from "../..";
import { ListenerListDef } from "../../base/ListenerListDef";
import { ChangeEvent, ChangeListener } from "../../events/ChangeEvent";
import { Event } from "../../events/Event";
import { getComponentFromStringOrPropsOrComponent } from "../../page/getComponentFromStringOrPropsOrComponent";
import { Label } from "../label/Label";
import { Panel } from "../panel/Panel";
import { MultiSwitchProps } from "./MultiSwitchProps";

const _changeListenerDef: ListenerListDef = { listName: "_changeListeners" };

export class MultiSwitch extends Panel implements MultiSwitchProps {
    _items: StringOrPropsOrComponent[] = [];
    _selectedIndex: number = 0;
    private _caption: Label;
    private _panelSwitch: Panel;

    constructor(props?: Partial<MultiSwitchProps>) {
        super({ id: props.id, padding: 0 }, false);
        this._panelSwitch = new Panel({ borderRadius: 4, borderWidth: 1, borderColor: "subtle.darker", rowBreakDefault: false, backgroundColor: "background4", ...props });
        this._caption = new Label({ fontSize: "small", padding: 0, marginBottom: 4 });
        this.add(this._caption);
        this.add(this._panelSwitch);
        const superProps = { ...props };
        delete superProps.backgroundColor, superProps.color;
        this.setProps(superProps);
        if (props?.selectedIndex === undefined)
            this.selectedIndex = 0;
    }

    get items(): StringOrPropsOrComponent[] {
        return this._items;
    }

    set items(value: StringOrPropsOrComponent[]) {
        this._items = value;
        this._panelSwitch.removeAll();
        for (let i = 0; i < value.length; i++) {
            const comp = getComponentFromStringOrPropsOrComponent(value[i], { padding: 12, borderRadius: 4 });
            comp.addClickListener(event => this._internalSetSelectedIndex(i, event));
            this._panelSwitch.add(comp);
        }
    }

    get selectedIndex(): number {
        return this._selectedIndex;
    }

    set selectedIndex(value: number) {
        this._internalSetSelectedIndex(value, null);
    }

    private _internalSetSelectedIndex(value: number, originatingEvent: Event) {
        const oldValue = this._selectedIndex;
        this._selectedIndex = value;
        for (let i = 0; i < this._panelSwitch.components.length; i++) {
            this._panelSwitch.components[i].backgroundColor = i === value ? "primary" : "transparent";
            this._panelSwitch.components[i].color = i === value ? "primary.reverse" : "subtle.darker";
        }
        if (oldValue !== value) {
            const changeEvent = new ChangeEvent(this, !value, value, originatingEvent == null ? null : originatingEvent.domEvent);
            this.fireListeners(_changeListenerDef, changeEvent);
        }
    }

    addItem(item: StringOrPropsOrComponent) {
        this._items.push(item);
        this.items = this._items;
    }

    set caption(value: string) {
        const oldValue = this._caption.caption;
        this._caption.caption = value;
        this._matchIdToValue(oldValue, value, "multi");
    }

    get caption(): string {
        return this._caption.caption;
    }

    addChangeListener(value: ChangeListener) {
        this.addEventListener(_changeListenerDef, value);
    }

    removeChangeListener(value: ChangeListener) {
        this.removeEventListener(_changeListenerDef, value);
    }

    override getListenerDefs(): Collection<ListenerListDef> {
        return {
            ...super.getListenerDefs(),
            "change": { ..._changeListenerDef }
        };
    }

    override getBasicValue(): any {
        if (this.selectedIndex === -1 || this.items == null || this.items.length === 0)
            return null;
        return this.items[this.selectedIndex];
    }
}
