/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelCommodity.ts                     *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/dispatch/commodity";

export class AutogenModelCommodity<RowType extends ModelRow> extends Endpoint<RowType, RowCommodityFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowCommodity extends ModelRow<RowCommodityFields> {
    constructor(values?: Partial<RowCommodityFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowCommodityFields {
    is_hazmat: string;
    product_book_location: string;
}

