import { Api, Collection, DataType, DateUtil, FileUtil, MetadataField, StringUtil } from "@mcleod/core";
import { Component } from "../../base/Component";
import { ReflectiveDialogs } from "../../base/ReflectiveDialogs";
import { Image } from "../image/Image";
import { Label } from "../label/Label";
import { Textbox } from "../textbox/Textbox";
import { Table } from "./Table";
import { TableCell } from "./TableCell";
import { TableColumn } from "./TableColumn";
import { TableRow } from "./TableRow";

interface Column {
    heading: string;
    type: string;
    alignment: string;
}

interface ExportData {
    columns: Collection<Column>;
    data: Collection<string>[];
    exportFileBase64?: string;
}

export enum ExportType {
    Excel = "XLSX", CSV = "CSV"
}

export class TableExport {
    private table: Table;

    constructor(table: Table) {
        this.table = table;
    }

    async exportTable(showSnackBar = true, type: ExportType = ExportType.Excel): Promise<ExportData> {
        const data: any = this.getFlattenedTableData();
        data.type = type;
        let snackbar;
        if (showSnackBar === true)
            snackbar = ReflectiveDialogs.snackbarClass.showDownloadSnackbar("Exporting " + this.getListingName(true),
                "Your list is being downloaded.  You can close this and continue working and we'll notify you when it is finished.",
                { persist: true });
        return Api.post("lib/list-export", data).then(response => {
            data.exportFileBase64 = response.data[0].export_data;
            FileUtil.downloadBase64AsFile(data.exportFileBase64, this.getListingName(false) + "." + type.toLowerCase());
            if (snackbar != null)
                snackbar.dismiss();
            ReflectiveDialogs.showSnackbar("Your list has been exported.  Check the download area of your browser.");
            return data;
        }).catch(reason => {
            if (snackbar != null)
                snackbar.dismiss();
            return data;
        });
    }

    getListingName(stripTokens: boolean): string {
        let result = this.table.exportName || "Listing Export";
        const tokens = [
            { token: "{timestamp}", replacement: DateUtil.formatDateTime(new Date(), "yyyy-MM-dd HHmm") }
        ];
        for (const token of tokens)
            result = result.replace(token.token, stripTokens ? "" : token.replacement);
        return result.trim();
    }

    getFlattenedTableData(): ExportData {
        const result = { columns: {}, data: [] };
        for (const row of this.table.filteredRows)
            this.addFlattenedRowData(result, row);
        return result;
    }

    addFlattenedRowData(target: ExportData, row: TableRow): void {
        const exportRow = {};
        target.data.push(exportRow);
        for (const column of row.table.columns) {
            const cell: TableCell = row.createCell(column);
            const children = cell.getRecursiveChildren();
            for (const child of children) {
                this.addExportComponent(exportRow, child, target, column);
            }
        }
    }

    addExportComponent(exportRow: Collection<any>, comp: Component, target: ExportData, column: TableColumn) {
        const fields = comp.getFieldNames();
        const modelRow = comp.getRelevantModelRow();
        if (modelRow == null || comp instanceof Image)
            return;
        if (fields?.length === 1) {
            this.addExportField(exportRow, fields[0], comp);
            if (target.columns[fields[0]] == null)
                target.columns[fields[0]] = this.getColumnDefinition(comp, column, modelRow.getMetadata().output[fields[0]]);
        }
        else if (fields != null) {
            for (const field of fields) {
                exportRow[field] = modelRow.get(field, "");
                if (target.columns[field] == null)
                    target.columns[field] = this.getColumnDefinition(comp, column, modelRow.getMetadata().output[field]);
            }
        }
    }

    addExportField(exportRow: Collection<String>, fieldName: string, comp: Component) {
        if (comp instanceof Textbox) // TODO: make a method in Component to return its field value as a string or maybe its primitve type (I bet one already exists?)
            exportRow[fieldName] = comp.text;
        else if (comp instanceof Label)
            exportRow[fieldName] = comp.caption;
        else {
            const modelRow = comp.getRelevantModelRow();
            exportRow[fieldName] = modelRow.get(fieldName);
        }
        if (exportRow[fieldName] === "hide" || exportRow[comp.field] == null)
            exportRow[fieldName] = "";
    }

    private getColumnDefinition(component: Component, column: TableColumn, field: MetadataField): Column {
        let heading = component.getDisplayLabel(field?.name, /*column.heading?.caption*/ ""); //ick
        if (StringUtil.isEmptyString(heading) === true)
            heading = column.heading?.caption;
        let alignment = "left";
        let type = "string";
        if (field != null) {
            type = field.dataType;
            if (field.dataType === DataType.INT || field.dataType === DataType.FLOAT)
                alignment = "right";
        }
        alignment = (column.headingCell.align as unknown) as string;
        return { heading, type, alignment };
    }
}
