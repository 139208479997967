import { DataSource, HorizontalSpacer, Label, Layout, Table } from "@mcleod/components";

export abstract class AutogenLayoutEdiBrltlFreightItemTable extends Layout {
	horizontalspacer1: HorizontalSpacer;
	labelHandlingUnitsCaption: Label;
	labelTotalUnits: Label;
	labelTotalWeight: Label;
	labelWeightCaption: Label;
	sourceEdiBrltlOrderFreightGroupItem: DataSource;
	tableFreightItems: Table;
}
