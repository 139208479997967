import { AutogenModelOrders, AutogenRowOrders } from "./autogen/AutogenModelOrders";

export class ModelOrders extends AutogenModelOrders<RowOrders> {
    constructor() {
        super(RowOrders);
    }
}

export class RowOrders extends AutogenRowOrders {
}
