import { ClickEvent, DataDisplayEvent } from "@mcleod/components";
import { Api } from "@mcleod/core";
import { AutogenLayoutMovementLockQuickInfo } from "./autogen/AutogenLayoutMovementLockQuickInfo";

export class MovementLockQuickInfo extends AutogenLayoutMovementLockQuickInfo {

    /** This is an event handler for the onDisplay event of sourceBrokerageMovements.  */
    sourceBrokerageMovementsOnDisplay(event: DataDisplayEvent) {
        this.textboxWaterfall.visible = this.buttonCancel.visible = this.activeRow?.get("waterfall_in_progress", false);
    }

    /** This is an event handler for the onClick event of buttonCancel.  */
    async buttonCancelOnClick(event: ClickEvent) {
        await Api.post("lme/powerbroker/move-locking", { movement_id: this.activeRow?.get("movement_id"), action: "cancel_waterfall" });
    }
}
