import { Checkbox, Label, Table, TableRowDisplayEvent, TableRowMode } from "@mcleod/components";
import { Api, ModelRow } from "@mcleod/core";
import { AutogenLayoutCompareResults } from "./autogen/AutogenLayoutCompareResults";

export class CompareResults extends AutogenLayoutCompareResults {
    public compareData: ModelRow[];
    public dataMap;
    public tablesArray: Table[];
    public orderId: String;
    public tenderId: String;

    slideoutUnmountListener: () => void;

    public async applyData(data: ModelRow[]) {
        try {
            this.compareData = data;
            this.dataMap = new Map();
            this.tablesArray = [];

            //add order changes to the table first, so that they show up first in the list of changes
            const orderChanges = data.filter(dataRow => dataRow["change_type"] == "OF");

            if (orderChanges.length > 0) {
                this.dataMap.set('Order Changes', orderChanges);
                await this.addChildToTable(this.tableCompareChanges, 'Order Changes');
            }

            //add a row to the overall table that for each stop that has changed
            const stopNames = [];
            const stopChanges = data.filter(dataRow => dataRow["change_type"] != "OF");
            stopChanges.forEach(stopChange => {
                if (!stopNames.includes(stopChange["sc_stop_name"]))
                    stopNames.push(stopChange["sc_stop_name"]);
            })

            for (const stopName of stopNames) {
                this.dataMap.set(stopName, stopChanges.filter(stopChange => stopChange["sc_stop_name"] == stopName));
                await this.addChildToTable(this.tableCompareChanges, stopName);
            }
        }
        finally {
            this._makeTableCompareChangesNotBusy();
        }
    }

    public async addChildToTable(table: Table, key: any) {
        const newRowData = new ModelRow("lme/datafusion/compare-order", true);
        if (newRowData != null) {
            this._makeTableCompareChangesNotBusy();
            newRowData.set("key", key);
            table.addRow(newRowData, { mode: TableRowMode.NONE }, { display: true, addToData: true });
        }
    }

    private _makeTableCompareChangesNotBusy() {
        if (this.tableCompareChanges.busy === true)
            this.tableCompareChanges.busy = false;
    }

    tableComparesOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        const label = tableRow.findComponentById("labelTestDEEP") as Label;
        label.caption = tableRow.data.get("key");
        const table = tableRow.findComponentById("tableDEEP") as Table;
        table.data = [];
        table.dataSource = this.sourceCompareOrder;

        const tableTabset = tableRow.findComponentById("tabset1");
        if (!(tableRow.data.get("key") as String).startsWith("Stop Changed"))
            tableTabset.visible = false;

        if (table != null) {

            let addingStop = false;
            let removingStop = false;

            this.dataMap.get(tableRow.data.get("key")).forEach(compareRow => {
                this.addTableRow(table, compareRow);
                if (compareRow.change_type == "SN")
                    addingStop = true;
                else if (compareRow.change_type == "SR")
                    removingStop = true;

            });

            if (addingStop) {
                table.removeColumn(0);
                table.removeColumn(1);
                const checkbox = tableRow.findComponentById("checkbox1") as Checkbox;
                checkbox.printable = false;
                checkbox.visible = true;
            }

            if (removingStop) {
                table.removeColumn(0);
                table.removeColumn(2);
                const checkbox = tableRow.findComponentById("checkbox1") as Checkbox;
                checkbox.printable = false;
                checkbox.visible = true;
            }

            this.tablesArray.push(table);
        }
    }

    private addTableRow(table: Table, value: any): Promise<any> {
        return table._createNewRowData().then(modelRow => {
            modelRow.setValues(value instanceof ModelRow ? value.data : value);
            modelRow._appending = value instanceof ModelRow ? value._appending : false;
            if (modelRow.get("is_child_group")) {

                if (modelRow.get("change_type") == "NN" || (modelRow.get("change_type") == "ON")) {
                    const commentTable = table.parent.findComponentById("tableStopComments") as Table;
                    commentTable.data = [];
                    commentTable.dataSource = this.sourceCompareOrder;
                    commentTable.addRow(modelRow, { mode: TableRowMode.NONE }, { display: true, addToData: true });
                }

                else if (modelRow.get("change_type") == "NR" || (modelRow.get("change_type") == "OR")) {
                    const referenceTable = table.parent.findComponentById("tableStopReferenceNumbers") as Table;
                    referenceTable.data = [];
                    referenceTable.dataSource = this.sourceCompareOrder;
                    referenceTable.addRow(modelRow, { mode: TableRowMode.NONE }, { display: true, addToData: true });
                }

            }
            else
                table.addRow(modelRow, { mode: TableRowMode.NONE }, { display: true, addToData: true });
        });
    }

    public applyChanges(): number {

        const acceptedCompareRows: ModelRow[] = [];
        const tables: ModelRow[] = [];
        this.tablesArray.forEach(table => {
            const checkbox = table.parent.findComponentById("checkbox1") as Checkbox;
            const key = table.parent.boundRow.get("key")
            tables.push(table.parent.boundRow);
            table.rows.forEach(tableRow => {
                if ("Y" == tableRow.data.get("oc_apply")
                    || (("SN" == tableRow.data.get("change_type") || "SR" == tableRow.data.get("change_type")) && checkbox.checked)) {
                    tableRow.data.set("order_id", this.orderId);
                    tableRow.data.set("tender_id", this.tenderId);
                    tableRow.data.set("key", key);
                    acceptedCompareRows.push(tableRow.data);
                }
            })

            const commentTable = table.parent.findComponentById("tableStopComments") as Table;

            commentTable.rows.forEach(tableRow => {
                if ("Y" == tableRow.data.get("oc_apply")) {
                    tableRow.data.set("order_id", this.orderId);
                    tableRow.data.set("tender_id", this.tenderId);
                    tableRow.data.set("key", key);

                    acceptedCompareRows.push(tableRow.data);
                }
            })
            const referenceTable = table.parent.findComponentById("tableStopReferenceNumbers") as Table;
            referenceTable.rows.forEach(tableRow => {
                if ("Y" == tableRow.data.get("oc_apply")) {
                    tableRow.data.set("order_id", this.orderId);
                    tableRow.data.set("tender_id", this.tenderId);
                    tableRow.data.set("key", key);

                    acceptedCompareRows.push(tableRow.data);
                }
            })
        });

        if (acceptedCompareRows.length > 0)
            Api.post("lme/datafusion/accept-compare", { data: acceptedCompareRows, tables: tables });

        return acceptedCompareRows.length;
    }

    selectAll() {
        this.tablesArray.forEach(table => {
            const checkbox = table.parent.findComponentById("checkbox1") as Checkbox;
            checkbox.checked = true;
            table.rows.forEach(tableRow => {
                tableRow.data.set("oc_apply", 'Y');
            })
            const commentTable = table.parent.findComponentById("tableStopComments") as Table;
            commentTable.rows.forEach(tableRow => {
                tableRow.data.set("oc_apply", 'Y');
            })
            const referenceTable = table.parent.findComponentById("tableStopReferenceNumbers") as Table;
            referenceTable.rows.forEach(tableRow => {
                tableRow.data.set("oc_apply", 'Y');
            })
        })
    }

    unselectAll() {
        this.tablesArray.forEach(table => {
            const checkbox = table.parent.findComponentById("checkbox1") as Checkbox;
            checkbox.checked = false;
            table.rows.forEach(tableRow => {
                tableRow.data.set("oc_apply", 'N');
            })
            const commentTable = table.parent.findComponentById("tableStopComments") as Table;
            commentTable.rows.forEach(tableRow => {
                tableRow.data.set("oc_apply", 'N');
            })
            const referenceTable = table.parent.findComponentById("tableStopReferenceNumbers") as Table;
            referenceTable.rows.forEach(tableRow => {
                tableRow.data.set("oc_apply", 'N');
            })
        })
    }

    commentsOnDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        const label = tableRow.findComponentById("labelChangeType") as Label;
        if (tableRow.data.get("change_type") == "NN") {
            label.caption = "Add";
        }
        if (tableRow.data.get("change_type") == "ON") {
            label.caption = "Remove";
        }
    }

    refNosOnDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        const label = tableRow.findComponentById("labelChangeTypeR") as Label;
        if (tableRow.data.get("change_type") == "NR") {
            label.caption = "Add";
        }
        if (tableRow.data.get("change_type") == "OR") {
            label.caption = "Remove";
        }
    }
}
