import { CommonDialogs } from "@mcleod/common";
import {
    Button, ChangeEvent, ClickEvent, DataDisplayEvent, DataSource, DataSourceAction, DataSourceExecutionEvent,
    DataSourceMode, DropdownItem, Label, Layout, Panel, SlideoutDecorator, Snackbar, Textbox, ValidationResult
} from "@mcleod/components";
import { DateUtil, getAuthSettings, getLogger, ModelRow, ProcessLock, StringUtil, Timezone } from "@mcleod/core";
import { CarrierRatings } from "@mcleod/powerbroker/src/CarrierRatings";
import { DispatchCommentsErrors } from "../../powerbroker/src/DispatchCommentsErrors";
import { MovementCallins } from "../../powerbroker/src/MovementCallins";
import { RecentCallins } from "../../powerbroker/src/RecentCallins";
import { ClearStop } from "./ClearStop";
import { OrderCreditValidator } from "./OrderCreditValidator";
import { AutogenLayoutDispatchSlideout } from "./autogen/AutogenLayoutDispatchSlideout";
import { ModelCallin } from "./models/ModelCallin";

const log = getLogger("lme.dispatch.DispatchSlideout");

export class DispatchSlideout extends AutogenLayoutDispatchSlideout {
    doOnSlideoutClosed: (dataPosted: boolean) => void;
    private _origBrokerageStatusValue: object;
    private _origBrokerageStatusText: string;
    private _onHold = false;
    private _orderId: string;
    private lastCalcEtaFilter: any;
    private _creditValidationPromise: Promise<Snackbar>;
    private saveButton: Button = new Button({
        id: "dispatchSaveButton",
        caption: "Save",
        backgroundColor: "primary",
        color: "default.reverse",
        minWidth: 128,
        borderWidth: 0,
        rowBreak: false
    });

    override onLoad(): void {
        const header = this.tabsetContent["_header"];
        if (header != null)
            header.visible = false
        const checked = this.switchToggleLayout.checked;
        this.textboxBrokerageStatus.enabled = !checked;
        this.layoutClearStop.visible = checked;
        this.layoutCallinEntry.visible = !checked;
        this.textboxBrokerageStatus.addBeforeLookupModelSearchListener((event) => {
            if (this.sourceDispatch.activeRow?.get("id"))
                event.filter = { movement_id: this.sourceDispatch.activeRow.get("id") };
        })

        const calcEta = (ds: DataSource, filter: any) => this.calcNextStopEta(ds, filter);
        this.layoutCallinEntry.calculateEta = calcEta;
        this.layoutClearStop.calculateEta = calcEta;
        this.setEtaAndMilesTextboxDataSource();
        this.sourceCallin.addAfterExecutionListener(event => {
            this.layoutCallinEntry.compareNextSchedToCallinDate();

            const filterItems = (tb: Textbox) => {
                tb.items = (tb.items as DropdownItem[]).filter(item => item.value !== "T");
            }
            if (this.sourceCallin.activeRow?.get("initiated_type") !== "T") {
                filterItems(this.layoutCallinEntry.textboxInitiatedType);
                filterItems(this.layoutClearStop.tbCallinType);
            }
        });

        this.layoutClearStop.bindCallinDataSource(this.sourceCallin);
        this.layoutCallinEntry.bindCallinDataSource(
        this.sourceCallin);

        this.sourceCallin.displayDataInBoundComponents();

        this.sourceDispatch.addAfterExecutionListener(event => {
            this.displayDispatchComments();
            (this.layoutRecentCallins as RecentCallins).doSearch(this.activeRow?.get("id"));
        })

        this.saveButton.addClickListener((event: ClickEvent) => this.onSave(event));
    }

    public get onHold() {
        return this._onHold;
    }

    private set onHold(value) {
        this._onHold = value;
        if (value) {
            const preventDisptachHold = getAuthSettings().dispatch_control[0].prevent_dsp_hold;
            if (preventDisptachHold == "Y") {
                this.switchToggleLayout.enabled = false;
                this.switchToggleLayout.disabledTooltip = "You cannot switch to Arrival/Departure Update because the order is on hold.";
            }
        }
    }

    private displayDispatchComments() {
        const commentsErrors = this.layoutDispatchCommentsErrors as DispatchCommentsErrors;
        commentsErrors.showWarnings = false;
        const payeeId = this.activeRow?.get("override_payee_id");
        if (!StringUtil.isEmptyString(payeeId)) {
            commentsErrors.displayDispatchComments(payeeId, 115);
            commentsErrors.displayValidationErrors(this.activeRow.get("id"), payeeId).then(data => {
                if (data?.errors?.length > 0 && (this.layoutClearStop as ClearStop).textboxActualArrival.isEmpty()) {
                    this.switchToggleLayout.enabled = false;
                    this.switchToggleLayout.disabledTooltip = "You cannot switch to Arrival/Departure Update because of the Dispatch Errors listed above."
                }
            });
        }
    }

    switchToggleLayoutOnChange(event: ChangeEvent) {
        this.layoutClearStop.visible = event.newValue;
        this.layoutCallinEntry.visible = !event.newValue;

        if (this.textboxBrokerageStatus.changedWhileFocused() && event.newValue) {
            this.sourceDispatch.activeRow.setValues({ "brokerage_status": this._origBrokerageStatusValue });
            this.textboxBrokerageStatus.text = this._origBrokerageStatusText;
        }
        this.textboxBrokerageStatus.enabled = !event.newValue
        this.setEtaAndMilesTextboxDataSource();

        if (event.userInitiatedChange)
            this.validateCreditAndShowWarning()
    }

    private validateCreditAndShowWarning() {
        this.creditValidationPromise.then((snackbar: Snackbar) => {
            if (snackbar) snackbar.visible = this.layoutClearStop.visible;
        })
    }


    private get creditValidationPromise(): Promise<Snackbar> {
        if (this._creditValidationPromise === undefined && this.layoutClearStop.visible === true) {
            this._creditValidationPromise = OrderCreditValidator.validateCreditAndShowWarning(this.layoutClearStop.sourceCallinStop.activeRow?.get("order_id"));
        }
        return this._creditValidationPromise;
    }

    calcNextStopEta(sourceCallin: DataSource, filter: any) {
        if (filter.city_id != null && filter.movement_id != null && (this.lastCalcEtaFilter == null || this.lastCalcEtaFilter !== filter)) {
            this.lastCalcEtaFilter = filter;
            this.panelLate.visible = false;
            new ModelCallin().searchSingle(filter)
            .then(result => {
                this.setNextStopEta(sourceCallin, result)
                this.setEtaLate(sourceCallin);
            }).catch(() => {
                this.setNextStopEta(sourceCallin, null)
                this.setEtaLate(sourceCallin);
            });
        }
    }

    setNextStopEta(sourceCallin: DataSource, result: ModelRow<any>) {
        sourceCallin?.activeRow?.set("new_eta", result?.get("new_eta"));
        sourceCallin?.activeRow?.set("new_eta_date_string", result?.get("new_eta_date_string"));
        sourceCallin?.activeRow?.set("new_eta_time_string", result?.get("new_eta_time_string"));
        sourceCallin?.activeRow?.set("miles2consignee", result?.get("miles2consignee"));
        sourceCallin?.activeRow?.set("miles2consignee_um", result?.get("miles2consignee_um"));
    }

    setEtaAndMilesTextboxDataSource() {
        const sourceCallin = this.layoutCallinEntry.visible ? this.layoutCallinEntry.sourceCallin : this.layoutClearStop.sourceCallin;
        this.panelEta.getRecursiveChildren().forEach(comp => {
            if (comp.field != null && comp.dataSource != this.layoutClearStop.sourceCallinStop) {
                comp.dataSource = sourceCallin;
                comp.displayData(sourceCallin.activeRow, null, 0);
            }
        });
    }

    setEtaLate(sourceCallin: DataSource) {
        if (!this.mainDataSource.activeRow?.get("stop_id") || !this.layoutClearStop.sourceCallinStop?.activeRow) return;

        const eta: Date = sourceCallin?.activeRow?.get("new_eta");
        const late: Date = this.layoutClearStop.sourceCallinStop?.activeRow?.get("sched_arrive_late")
            ? this.layoutClearStop.sourceCallinStop?.activeRow?.get("sched_arrive_late")
            : this.layoutClearStop.sourceCallinStop?.activeRow?.get("sched_arrive_early")

        const difference = new Date(eta).getTime() - new Date(late).getTime();

        if (difference > 0) {
            const diffObj = DateUtil.msToTime(difference);
            if (diffObj.hours <= 0 && diffObj.minutes <= 0) return;

            const hr = diffObj.hours > 0 ? `${diffObj.hours} hour${diffObj.hours > 1 ? "s" : ""}` : "";
            const and = diffObj.hours > 0 && diffObj.minutes > 0 ? " and " : "";
            const min = diffObj.minutes > 0 ? `${diffObj.minutes} minute${diffObj.hours > 1 ? "s" : ""}` : "";
            const warningText = `Estimated time of arrival is ${hr}${and}${min} late`;

            this.panelLate.visible = true;
            this.labelLate.tooltip = warningText;
        }
    }

    async onSave(event: ClickEvent) {
        this.isValid().then(async result => {
            if (result == true) {
                try {
                    this.saveButton.busy = true;
                    const postClearStop = this.switchToggleLayout.checked;
                    this.updateCallinFromScript();
                    if (postClearStop)
                        await this._saveClearedStop();
                    else
                        await this._saveCallin();
                } finally {
                    this.saveButton.busy = false
                }
            }
        });
    }

    updateCallinFromScript() {
        this.sourceCallin.activeRow.set("remark_with_script_remarks", this.sourceCallin.activeRow.get("remark"));
        let callinScriptData;

        if (this.layoutClearStop.visible)
            callinScriptData = this.layoutClearStop.layoutCallinScript.getPostData();
        else
            callinScriptData = this.layoutCallinEntry.layoutCallinScript.getPostData();

        if (callinScriptData != null) {
            if (callinScriptData.remarks?.length > 0) {
                if (!this.sourceCallin.activeRow.isNull("remark")) {
                    callinScriptData.remarks.unshift(this.sourceCallin.activeRow.get("remark"))
                    this.sourceCallin.activeRow.set("remark_with_script_remarks", callinScriptData.remarks.join(", "));
                }
            }
            this.sourceCallin.activeRow.set("callin_script_save_data", callinScriptData.table_data);
        }
    }

    async isValid(checkRequired: boolean = true, showErrors: boolean = true): Promise<boolean> {
        let results: ValidationResult[];

        if (this.layoutClearStop.visible) {
            this.layoutClearStop.layoutCallinScript.resetValidation();
            results = this.layoutClearStop.layoutCallinScript.validate(checkRequired, showErrors);
        } else {
            this.layoutCallinEntry.layoutCallinScript.resetValidation();
            results = this.layoutCallinEntry.layoutCallinScript.validate(checkRequired, showErrors);
        }

        let isValid = this.sourceDispatch.validate() && (results == null || results.length == 0 || !results.some(value => value.isValid == false));
        if (isValid) {
            if (this.layoutClearStop.visible) {
                await this.layoutClearStop.checkForServiceIncident();
                isValid = this.layoutClearStop.sourceCallinStop.validate()
            } else {
                isValid = this.layoutCallinEntry.sourceCallin.validate()
            }
        }

        return isValid;
    }

    private async _saveCallin() {
        if (this.sourceCallin.activeRow.get("movement_id") == null)
            return;
        const nextSchedCall = this.layoutCallinEntry.sourceCallin.activeRow.get("sched_date_time");
        if (nextSchedCall)
            this.mainDataSource.activeRow.set("next_sched_call", nextSchedCall);
        await this.sourceCallin.post().then(response => {
            this.mainDataSource.post().then(response => {
                this.closeOverlay(false);
            });
        }).catch(error => {
            this.saveButton.busy = false;
            throw error
        });
    }

    private async _saveClearedStop() {
        const stopLayout = this.layoutClearStop as ClearStop;
        const nextSchedCall = this.sourceCallin.activeRow.get("sched_date_time");
        if (nextSchedCall)
            this.mainDataSource.activeRow.set("next_sched_call", nextSchedCall);
        stopLayout.sourceCallinStop.activeRow.set("callin_to_post_data", this.sourceCallin.activeRow);
        await stopLayout.sourceCallinStop.post().then(async stopRow => {
            if (!stopRow) return;
            if (this.mainDataSource.hasChanged()) {
                log.debug("Posting sourceDispatch", this.mainDataSource);
                await this.mainDataSource.post();
            }
            this.closeOverlay(false);
        });
    }

    /** This is an event handler for the afterExecution event of sourceDispatch.  */
    sourceDispatchAfterExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() === DataSourceAction.SEARCH) {
            this._origBrokerageStatusValue = this.sourceDispatch.activeRow?.get("brokerage_status");
            this._origBrokerageStatusText = this.textboxBrokerageStatus.text;

            if (this.mainDataSource.activeRow?.isNull("override_payee_id")) {
                this.switchToggleLayout.enabled = false;
                this.switchToggleLayout.disabledTooltip = "You cannot switch to Arrival/Departure Update because no carrier is assigned to this movement.";
            }
        }
    }

    public async closeOverlay(cancelled: boolean) {
        this.slideOut().then(async () => {
            if (!cancelled) {
                await this.showCarrierRatings(this._origBrokerageStatusValue);
            }
            if (this.doOnSlideoutClosed != null)
                this.doOnSlideoutClosed(cancelled);
        });
    }

    async showCarrierRatings(origStatCode) {
        await this.mainDataSource.search(this.mainDataSource.lastSearch);
        const triggerCode = getAuthSettings().dispatch_control[0].rating_trigger_code;
        const brkStatus = this.activeRow?.get("brokerage_status");
        if (!this.activeRow.isNull("_lookup_override_payee_id")) {
            const payeeData: ModelRow = this.activeRow.get("_lookup_override_payee_id")[0];
            if (!StringUtil.isEmptyString(triggerCode) && !StringUtil.isEmptyString(brkStatus)
                && brkStatus !== origStatCode && triggerCode === brkStatus) {
                CarrierRatings.show({
                    carrierRatingHeaderData: {
                        order_id: this._orderId,
                        movement_id: this.activeRow.get("id"),
                        payee_id: payeeData.get("id")
                    },
                    carrier_name: payeeData.get("name")
                });
            }
        }
    }

    configureForArrivalDepartEdits(showNextStopEta: boolean) {
        this.panelHeader.components.forEach(comp => {
            if (comp == this.panelEta && showNextStopEta) {
                this.panelEta.marginLeft = 8;
            } else {
                comp.dataSource = null;
                comp.visible = false;
            }
        });

        if (!showNextStopEta)
            this.panelHeader.borderWidth = 0;

        this.panelMain.components.forEach(comp => comp.visible = comp == this.layoutClearStop);
        this.layoutClearStop.configureForArrivalDepartEdits(showNextStopEta);
    }

    private prepareLayoutForSlideout(props: DispatchSlideoutProps): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.addLayoutLoadListener(async () => {
                try {
                    this.onHold = props.orderOnHold;
                    this._orderId = props.orderId;
                    this.layoutClearStop.ordersCustomerId = props.customerId;
                    await this.setActiveRowFromProps(props).then(async () => {
                        if (this.mainDataSource.activeRow == null) {
                            CommonDialogs.showDialog(`Movement was not found.`);
                            return resolve(false);
                        }
                        this.mainDataSource.mode = DataSourceMode.UPDATE;

                        if (props.callinRequiredBrkStatusCode != null)
                            this.setCallinRequired(props.callinRequiredBrkStatusCode);

                        if (this.onHold !== true) {
                            await OrderCreditValidator.customerCreditLimitUndefined(props.orderId).then(creditUndefined => {
                                if (creditUndefined) {
                                    this.switchToggleLayout.enabled = false;
                                    this.switchToggleLayout.disabledTooltip = "You cannot switch to Arrival/Departure Update because the customer does not have a defined credit limit.";
                                }
                            });
                        }

                        if (this.mainDataSource.activeRow.get("stop_id") != null) {
                            await this.layoutClearStop.sourceCallinStop.search({ id: this.mainDataSource.activeRow.get("stop_id") }).then(async () => {
                                this.layoutClearStop.doAfterStopCleared = (nextStop) => {
                                    // Update tooltip and panel with ModelRow or sourceCallinStop
                                    let tempDS = this.layoutClearStop.sourceCallinStop;

                                    if (nextStop != null) {
                                        tempDS = new DataSource();
                                        tempDS.setRowsAndMode(DataSourceMode.UPDATE, [nextStop], null);
                                    }

                                    this.setNextStopPanel(tempDS);
                                    this.setNextStopToolTip(tempDS);
                                }

                                this.layoutClearStop.sourceCallinStop.mode = DataSourceMode.UPDATE;
                                this.switchToggleLayout.checked = this.layoutClearStop.sourceCallinStop?.activeRow?.get("actual_arrival") != null && this.switchToggleLayout.enabled == true;

                                this.setNextStopPanel(this.layoutClearStop.sourceCallinStop);
                                this.setNextStopToolTip(this.layoutClearStop.sourceCallinStop);
                            })
                        } else {
                            this.switchToggleLayout.visible = false;
                        }
                        await this.sourceCallin.search({
                            movement_id: this.mainDataSource.activeRow?.get("id"),
                            new_row: true,
                            include_callin_script_details: true
                        }).then(result => {
                            this.sourceCallin.mode = DataSourceMode.UPDATE;
                            this.sourceCallin.activeRow._appending = true;
                            this.layoutClearStop.layoutCallinScript.buildScript();
                            this.layoutCallinEntry.layoutCallinScript.buildScript();
                        });

                        if (this.switchToggleLayout.enabled && this.switchToggleLayout.visible) {
                            this.switchToggleLayout.checked = this.layoutClearStop.sourceCallinStop?.activeRow?.get("actual_arrival") != null ? true : getAuthSettings()?.user_settings?.default_dispatch_layout == "A";
                        }

                        resolve(true);
                    });
                } catch (error) {
                    CommonDialogs.showError(error);
                    resolve(false);
                }
            });
        });
    }

    private setCallinRequired(callinRequiredBrkStatusCode: ModelRow) {
        this.switchToggleLayout.enabled = false;
        this.textboxBrokerageStatus.enabled = false;
        const status = callinRequiredBrkStatusCode.get("id");
        const statusDescr = callinRequiredBrkStatusCode.get("descr");

        this.mainDataSource.activeRow.setLookupModelData("brokerage_status",
            new ModelRow(this.textboxBrokerageStatus.lookupModel, false, { id: status, descr: statusDescr }));
        this.mainDataSource.activeRow.set("brokerage_status", status);
        this.textboxBrokerageStatus.tooltipCallback = null;
        const disabledTooltip = "A callin is required after updating the brokerage status to " + statusDescr;
        this.textboxBrokerageStatus.disabledTooltip = disabledTooltip;
        this.switchToggleLayout.disabledTooltip = disabledTooltip;
    }

    private setActiveRowFromProps(props: DispatchSlideoutProps): Promise<any> {
        if (props.movementOrId instanceof ModelRow) {
            const movement = props.movementOrId as ModelRow;
            return this.search({ id: movement.get("id") }).then(() => {
                this.mainDataSource.activeRow.setValues({ ...movement.data });
            })
        }
        return this.search({ id: props.movementOrId })
    }

    public static async showSlideout(movementId: string, props: DispatchSlideoutProps) {
        if (movementId == null) return;
        ProcessLock.aquireLock("Carrier dispatch", movementId).then(lockResult => {
            if (lockResult.success === true) {
                const layout = Layout.getLayout("lme/dispatch/DispatchSlideout") as DispatchSlideout;
                layout.doOnSlideoutClosed = props.doOnSlideoutClosed
                layout.prepareLayoutForSlideout(props).then(slideout => {
                    const fromCityState = StringUtil.toProperCase(props.originCity) + ", " + props.originState;
                    const toCityState = StringUtil.toProperCase(props.destinationCity) + ", " + props.destinationState;
                    new SlideoutDecorator({
                        layout: layout,
                        minWidth: 1400,
                        width: "65%",
                        fillVerticalSpace: true,
                        backgroundColor: "defaultBackground",
                        title: `Dispatch - Order ${props.orderId}\t\t${fromCityState} to ${toCityState}`,
                        addlComponents: layout.saveButton,
                        onClose: (cancelled: boolean) => {
                            props?.onClose?.(cancelled);
                            layout.closeOverlay(cancelled);
                        },
                        doAfterSlideIn: () => {
                            if (layout.layoutClearStop.visible)
                                layout.validateCreditAndShowWarning();
                        },
                        doAfterSlideOut: (decorator) => {
                            ProcessLock.releaseLock("Carrier dispatch", movementId);
                            props?.doAfterSlideOut;
                        }
                    });
                });
            } else {
                CommonDialogs.showDialog(lockResult.message, { title: "Dispatch in Progress" });
            }
        });
        return false;
    }

    /** This is an event handler for the onDataDisplay event of labelNextStopCity.  */
    labelNextStopCityOnDataDisplay(event: DataDisplayEvent) {
        const abbr = this.sourceDispatch.activeRow?.get("stop_timezone_id");
        const zone = Timezone.getFromAbbreviation(abbr);
        this.layoutClearStop.textboxActualArrival.timezone = zone;
        this.layoutClearStop.textboxActualDeparture.timezone = zone;
    }

    /** This is an event handler for the beforeTabSelection event of tabset1.  */
    tabset1BeforeTabSelection(event) {
        if (event.newValue == 1) {
            const movementCallins = (this.layoutMovementCallins as MovementCallins);
            movementCallins.movementId = this.activeRow?.get("id");
        }
    }

    setNextStopPanel(nextStopSource: DataSource) {
        this.panelNextStop.getRecursiveChildren().forEach(comp => {
            if (comp.field != null) {
                comp.dataSource = nextStopSource;
                comp.displayData(nextStopSource.activeRow, null, 0);
                comp.visible = nextStopSource.activeRow.get(comp.field) != null;
            }
        });
    }

    setNextStopToolTip(nextStopSource: DataSource) {
        this.panelNextStop.tooltipCallback = (baseTooltip, originatingEvent) => {
            let customAddress = nextStopSource?.activeRow.get("address");
            if (!nextStopSource?.activeRow.isNull("address2"))
                customAddress += "\n" + nextStopSource.activeRow.get("address2");

            nextStopSource.activeRow.set("custom_address", customAddress);

            const sched_arrive_early_string = DateUtil.formatDateTime(DateUtil.parseDateTime(nextStopSource.activeRow.get("sched_arrive_early")), "iiii, MMMM dd h:mm a");
            const sched_arrive_late_string = DateUtil.formatDateTime(DateUtil.parseDateTime(nextStopSource.activeRow.get("sched_arrive_late")), "iiii, MMMM dd h:mm a");
            nextStopSource.activeRow.set("sched_arrive_early_string", sched_arrive_early_string);
            nextStopSource.activeRow.set("sched_arrive_late_string", sched_arrive_late_string);

            const panel = new Panel({
                components: [
                    new Textbox({
                        dataSource: nextStopSource,
                        caption: "Street Address",
                        field: "address",
                        printable: true
                    }),
                    new Label({
                        dataSource: nextStopSource,
                        field: "address2",
                        marginTop: -5,
                        nullDisplayValue: "hide"
                    }),
                    new Textbox({ dataSource: nextStopSource, caption: "Phone", field: "phone", printable: true }),
                    new Textbox({
                        dataSource: nextStopSource,
                        caption: "Scheduled Arrival",
                        field: "sched_arrive_early_string",
                        printable: true
                    }),
                    new Textbox({
                        dataSource: nextStopSource,
                        caption: "Latest Arrival",
                        field: "sched_arrive_late_string",
                        printable: true,
                        nullDisplayValue: "hide"
                    })
                ]
            });

            nextStopSource.displayDataInBoundComponents();

            return this.panelNextStop.showTooltip(panel, null, { themeKey: "quickInfo", color: null });
        };
    }
}

export interface DispatchSlideoutProps {
    movementOrId: ModelRow | string,
    orderId: string,
    customerId: string,
    orderOnHold: boolean,
    originCity: string,
    originState: string,
    destinationCity: string,
    destinationState: string,
    callinRequiredBrkStatusCode?: ModelRow,
    onClose?: (cancelled: boolean) => void,
    doAfterSlideOut?: (decorator: SlideoutDecorator) => void
    doOnSlideoutClosed?: (cancelled: boolean) => void,
}
