import { CallinEntry } from "../../../dispatch/src/CallinEntry";
import { ClearStop } from "../../../dispatch/src/ClearStop";
import { RecentCallins } from "../RecentCallins";
import { Button, DataSource, Label, Layout, Panel, Switch, Tab, Table, Tabset, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutP44ShipmentTracking extends Layout {
	buttonDownloadImages: Button;
	buttonSaveRefValues: Button;
	buttonSaveShipmentInfo: Button;
	labelBOL: Label;
	labelCarrierName: Label;
	labelCustomer: Label;
	labelDeliveryReceipt: Label;
	labelDownloads: Label;
	labelInvoice: Label;
	labelTrackingStatuses: Label;
	labelValueSelection: Label;
	labelWeightCert: Label;
	layoutCallinEntry: CallinEntry;
	layoutClearStop: ClearStop;
	layoutRecentCallins: RecentCallins;
	panelBOL: Panel;
	panelCarrier: Panel;
	panelDeliveryReceipt: Panel;
	panelDownload: Panel;
	panelImage: Panel;
	panelImagesToDownload: Panel;
	panelInvoice: Panel;
	panelMain: Panel;
	panelOverview: Panel;
	panelReferenceValues: Panel;
	panelShipmentInformation: Panel;
	panelSpacer1: Panel;
	panelSpacer2: Panel;
	panelSpacer: Panel;
	panelStatusDates: Panel;
	panelWeightCert: Panel;
	sourceCallin: DataSource;
	sourceDispatch: DataSource;
	switchBOL: Switch;
	switchDeliveryReceipt: Switch;
	switchInvoice: Switch;
	switchTableControl: Switch;
	switchToggleLayout: Switch;
	switchWeightCert: Switch;
	tabReferenceValues: Tab;
	tabShipmentInformation: Tab;
	tabStatusHistory: Tab;
	tableReferenceValues: Table;
	tableStatusUpdates: Table;
	tableStopStatus: Table;
	tabsetShipmentTracking: Tabset;
	textboxActDeliveryDate: Textbox;
	textboxCurrentStatus: Textbox;
	textboxEstDeliveryDate: Textbox;
	textboxPickupDate: Textbox;
}
