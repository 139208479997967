import { Label, TableRowDisplayEvent } from "@mcleod/components";
import { AutogenLayoutBrokeragePlanningProfileLookup } from "./autogen/AutogenLayoutBrokeragePlanningProfileLookup";

export class BrokeragePlanningProfileLookup extends AutogenLayoutBrokeragePlanningProfileLookup {

    tableLookupOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        const label = tableRow.findComponentById("labelTitle") as Label;
        label.caption = tableRow.data?.get("title", tableRow.data?.get("id"));
    }
}
