import { Button, Checkbox, DataHeader, DataSource, HorizontalSpacer, Image, Label, Layout, Panel, Switch, Tab, Table, Tabset, Textbox } from "@mcleod/components";
import { RecOrderRates } from "../RecOrderRates";
import { RecurringScheduleTable } from "../RecurringScheduleTable";

export abstract class AutogenLayoutRecurringOrders extends Layout {
    buttonAddAnotherStop: Button;
    buttonCreateSchedule: Button;
    buttonScheduler: Button;
    buttonUsers: Button;
    buttonViewRecurringOrder: Button;
    checkboxFloorLoadedFreight: Checkbox;
    checkboxHazmatRouting: Checkbox;
    checkboxHighValue: Checkbox;
    checkboxPreloaded: Checkbox;
    checkboxRoundTrip: Checkbox;
    checkboxTeamsRequired: Checkbox;
    dataheader1: DataHeader;
    horizontalspacer1: HorizontalSpacer;
    imageReorder: Image;
    labelAfterThisOrderIsSavedIWantTo: Label;
    labelCreditBadge: Label;
    labelTemperatureRange: Label;
    layoutRates: RecOrderRates;
    layoutRecurringSchedTable: RecurringScheduleTable;
    panel1: Panel;
    panel2: Panel;
    panel3: Panel;
    panelCommodityCheckboxes: Panel;
    panelDataHeader2: Panel;
    panelDataHeaderAddlLeft: Panel;
    panelNextActionButtons: Panel;
    panelNextActions: Panel;
    sourceAgencyLink: DataSource;
    sourceEquipMatchDetail: DataSource;
    sourceRecurringOrder: DataSource;
    sourceRecurringSched: DataSource;
    sourceStopRecOrder: DataSource;
    stopsTable: Table;
    switchHazmat: Switch;
    switchIsActive: Switch;
    switchScheduled: Switch;
    tabCommodity: Tab;
    tabEquipment: Tab;
    tabGeneral: Tab;
    tabNextActions: Tab;
    tabRate: Tab;
    tabScheduler: Tab;
    tabStops: Tab;
    tableEquipmentRequirements: Table;
    tabsetAddOrders: Tabset;
    textboxBlnum: Textbox;
    textboxBrkQualProfile: Textbox;
    textboxBrokerageWorkflowId: Textbox;
    textboxCommodity: Textbox;
    textboxCommodityId: Textbox;
    textboxCustomerId: Textbox;
    textboxDefMoveType: Textbox;
    textboxDefaultEquipMatchId: Textbox;
    textboxEquipmentTypeIdEquipment: Textbox;
    textboxEquipmentTypeIdGeneral: Textbox;
    textboxHazmatCode: Textbox;
    textboxId: Textbox;
    textboxOrderMode: Textbox;
    textboxOrderOrderedBy: Textbox;
    textboxOrderType: Textbox;
    textboxOrderValue: Textbox;
    textboxPieces: Textbox;
    textboxRateSource: Textbox;
    textboxRevenueCodeId: Textbox;
    textboxTemperatureMax: Textbox;
    textboxTemperatureMin: Textbox;
    textboxTractorType: Textbox;
    textboxWeight: Textbox;
}
