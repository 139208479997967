import { Image, Label, Panel, Textbox, ValidationResult } from "@mcleod/components";
import { Alignment, HorizontalAlignment, Keys } from "@mcleod/core";

export class ImageSelector extends Panel {
    panel: Panel;
    caption: string;
    search: Textbox;
    allImages: string[];
    _selectedImage: Panel;

    constructor(props) {
        super(props)
        this.panel = new Panel({ height: 400, width: 564, scrollY: true });
        this.caption = "Select Image";
        this.search = new Textbox({
            width: 260,
            captionVisible: false,
            warningLabelVisible: false,
            marginLeft: 16,
            marginBottom: 12,
            fillRow: true,
            placeholder: "Search",
        });
        this.search.addKeyDownListener(event => this.searchKeyDown(event));
        this.search.addChangeListener(event => this.addImages());

        this.add(this.search);
        this.add(this.panel);
        this.allImages = require["context"]("@mcleod/images/src", true).keys();
        this.addImages();
    }

    addImages() {
        let index = 0;
        this.panel.removeAll();
        this.selectedImage = null;
        for (const name of this.allImages)
            if (name.startsWith("./images/") && !name.endsWith(".ts") &&
                (this.search.text.length === 0 || name.toLowerCase().includes(this.search.text.toLowerCase()))) {
                index++;
                const panel = new Panel({
                    rowBreak: index % 4 === 0,
                    width: 108,
                    margin: 8,
                    borderRadius: 8,
                    align: HorizontalAlignment.CENTER
                });
                const imageName = name.substring(9);
                (panel as any).imageName = imageName;
                panel.add(new Image({
                    name: imageName,
                    width: 64,
                    height: 64,
                    padding: 6
                }));
                panel.add(new Label({ caption: imageName, wrap: true }));
                panel.addClickListener((event) => this.selectedImage = panel);
                this.panel.add(panel);
            }
        if (this.panel.getComponentCount() > 0)
            this.selectedImage = this.panel.getComponent(0) as Panel;
    }

    searchKeyDown(event) {
        const key = event.key;
        let curr = this.panel.indexOf(this.selectedImage);
        if (key === Keys.ARROW_UP)
            curr -= 4;
        else if (key === Keys.ARROW_DOWN)
            curr += 4;
        else if (key === Keys.ARROW_LEFT)
            curr--;
        else if (key === Keys.ARROW_RIGHT)
            curr++;
        if (curr < 0)
            curr = 0;
        if (curr >= this.panel.getComponentCount())
            curr = this.panel.getComponentCount() - 1;
        this.selectedImage = this.panel.getComponent(curr) as any;
        if (this.selectedImage != null)
            this.selectedImage.scrollIntoView();
    }

    get selectedImage() {
        return this._selectedImage;
    }

    set selectedImage(value) {
        if (this._selectedImage != null)
            this._selectedImage.backgroundColor = "unset";
        this._selectedImage = value;
        if (value != null)
            value.backgroundColor = "primary.light";
    }

    override validate(): ValidationResult[] {
        if (this.selectedImage == null) {
            this.panel.showTooltip("Select an image.", { position: Alignment.RIGHT, shaking: true });
            return [{ isValid: false, validationMessage: "Select an image." }];
        }
    }

    getValue() {
        return (this.selectedImage as any).imageName;
    }

    focus() {
        this.search.focus();
    }
}
