import { Button, ClickEvent, Textbox } from "@mcleod/components";
import { Api, ModelRow, getAuthSettings, getLogger } from "@mcleod/core";
import { AutogenLayoutVoidOrderPrompt } from "./autogen/AutogenLayoutVoidOrderPrompt";

const log = getLogger("lme.dispatch.VoidOrderPrompt")
export class VoidOrderPrompt extends AutogenLayoutVoidOrderPrompt {
    private _orderId: string;
    private reasonRequired: boolean;
    success: boolean;
    onCancel: () => void;
    onSave: (success: boolean) => void;

    override async onLoad(): Promise<void> {
        this.reasonRequired = "Y" === getAuthSettings().dispatch_control[0].enforce_void_hist
        if (this.reasonRequired) {
            this.textboxReasonCode.visible = true
            this.textboxComments.visible = true
            this.textboxReasonCode.onSelectItem = (textbox: Textbox, selectedItem: string | ModelRow<any>) => {
                textbox.text = (selectedItem as ModelRow)?.get("descr");
                return undefined;
            }
            this.textboxReasonCode.addBeforeLookupModelSearchListener(event => {
                event.filter.is_active = "Y";
                event.filter.code_type = "V";
            })
        }

        const cancel = () => {
            if (this.onCancel != null)
                this.onCancel();
        }
        this.buttonClose.addClickListener(cancel);
        this.buttonNo.addClickListener(cancel);

        this.buttonYes.addClickListener((event) => this.voidOrder(event));

        //Search for carriers with advanced already listed for this order, and list those carries if found
        return Api.search("lme/dispatch/carriers-with-advances", { order_id: this.orderId })
            .then(resp => {
                const data = resp.data[0]
                if (data.carriers && data.carriers.length > 0) {
                    this.advanceCarriersLabel.visible = true
                    this.advanceCarriersLabel.caption =
                        `Advance(s) issued to:\n ${data.carriers.join(", ")}`
                }
            })
    }

    voidOrder(event: ClickEvent) {
        const button = (event.target as Button)
        if (!this.reasonRequired || this.validateSimple()) {
            button.busy = true;
            return Api.post("lme/dispatch/void-order", {
                order_id: this.orderId,
                reason_code: this.textboxReasonCode.getFirstLookupModelData()?.get("id"),
                comment: this.textboxComments.text
            }, null, null).then(response => {
                this.success = response?.data[0]?.success;
                log.debug(`VOID was a ${this.success ? "success" : "failure"}!`)
                button.busy = false;
                this.onSave(this.success)
            }).finally(() => {
                button.busy = false;
            });
        }
    }

    get orderId(): string {
        return this._orderId
    }

    set orderId(value: string) {
        this._orderId = value
    }
}
