import { ChangeEvent, DataDisplayEvent } from "@mcleod/components";
import { getLogger } from "@mcleod/core";
import { AutogenLayoutCarrierRateHistory } from "./autogen/AutogenLayoutCarrierRateHistory";

const log = getLogger("lme.powerbroker.CarrierRateHistory");

export class CarrierRateHistory extends AutogenLayoutCarrierRateHistory {
    override onLoad() {
        const components = this.components;
        this.panel3.style.top = "100vh";
        this.panel3.style.position = "sticky";
        this.textboxOriginType.text = "State";
        this.textboxDestType.text = "State";
    }

    /** This is an event handler for the onChange event of textboxOriginType
     *
     * @param event The event that has taken place.  The event's target is usually the component on which the event has fired.
     */
    textboxOriginTypeOnChange(event: ChangeEvent) {
        if (event && event.newValue === "City") {
            this.textboxOriginState.visible = false;
            this.originCityStateZip.visible = true;
            this.textboxRadius.visible = true;
            this.textboxOrigZone.visible = false;
            this.textboxOriginMarketArea.visible = false;
            this.textboxOriginZip.visible = false;
        }
        else if (event && event.newValue === "State") {
            this.originCityStateZip.visible = false;
            this.textboxOriginState.visible = true;
            this.textboxRadius.visible = false;
            this.textboxOrigZone.visible = false;
            this.textboxOriginMarketArea.visible = false;
            this.textboxOriginZip.visible = false;
        }
        else if (event && event.newValue === "Zone") {
            this.originCityStateZip.visible = false;
            this.textboxOriginState.visible = false;
            this.textboxRadius.visible = false;
            this.textboxOrigZone.visible = true;
            this.textboxOriginMarketArea.visible = false;
            this.textboxOriginZip.visible = false;
        }
        else if (event && event.newValue === "Market") {
            this.originCityStateZip.visible = false;
            this.textboxOriginState.visible = false;
            this.textboxRadius.visible = false;
            this.textboxOrigZone.visible = false;
            this.textboxOriginMarketArea.visible = true;
            this.textboxOriginZip.visible = false;
        }
        else if (event && event.newValue === "Zip") {
            this.originCityStateZip.visible = false;
            this.textboxOriginState.visible = false;
            this.textboxRadius.visible = false;
            this.textboxOrigZone.visible = false;
            this.textboxOriginMarketArea.visible = false;
            this.textboxOriginZip.visible = true;
        }
        else {
            this.textboxRadius.visible = false;
        }
    }

    /** This is an event handler for the onChange event of textboxDestType
     *
     * @param event The event that has taken place.  The event's target is usually the component on which the event has fired.
     */
    textboxDestTypeOnChange(event: ChangeEvent) {
        if (event && event.newValue === "City") {
            this.textboxDestState.visible = false;
            this.destCityStateZip.visible = true;
            this.textboxRadius.visible = true;
            this.textboxDestZone.visible = false;
            this.textboxDestZip.visible = false;
            this.textboxDestMarketArea.visible = false;
        }
        else if (event && event.newValue === "State") {
            this.destCityStateZip.visible = false;
            this.textboxDestState.visible = true;
            this.textboxRadius.visible = false;
            this.textboxDestZone.visible = false;
            this.textboxDestZip.visible = false;
            this.textboxDestMarketArea.visible = false;
        }
        else if (event && event.newValue === "Zone") {
            this.destCityStateZip.visible = false;
            this.textboxDestState.visible = false;
            this.textboxRadius.visible = false;
            this.textboxDestZone.visible = true;
            this.textboxDestZip.visible = false;
            this.textboxDestMarketArea.visible = false;
        }
        else if (event && event.newValue === "Market") {
            this.destCityStateZip.visible = false;
            this.textboxDestState.visible = false;
            this.textboxRadius.visible = false;
            this.textboxDestZone.visible = false;
            this.textboxDestZip.visible = false;
            this.textboxDestMarketArea.visible = true;
        }
        else if (event && event.newValue === "Zip") {
            this.destCityStateZip.visible = false;
            this.textboxDestState.visible = false;
            this.textboxRadius.visible = false;
            this.textboxDestZone.visible = false;
            this.textboxDestMarketArea.visible = false;
            this.textboxDestZip.visible = true;
        }
    }

    /** This is an event handler for the onDisplay event of 
     *
     * @param event The event that has taken place.  The event's target is usually the component on which the event has fired.
     */
    sourceCarrierRateHistoryOnDisplay(event: DataDisplayEvent) {
        const mostPaid = this.sourceCarrierRateHistory?.summaryData?.get("most_paid");
        if (this.textboxMostPaid && !isNaN(mostPaid)) {
            //need to handle multicurrency
            this.textboxMostPaid.text = "$" + mostPaid.toFixed(2);
        }
        const leastPaid = this.sourceCarrierRateHistory?.summaryData?.get("least_paid");
        if (this.textboxLeastPaid && !isNaN(leastPaid)) {
            //need to handle multicurrency
            this.textboxLeastPaid.text = "$" + leastPaid.toFixed(2);
        }
    }
}

