import { SingleComponentDemo } from "./SingleComponentDemo";

export class DemoLabel extends SingleComponentDemo {
    constructor() {
        super();
        this.propsForAllExamples = { caption: "Test caption" };
        this.examples = {
            ...SingleComponentDemo.getComponentDemoExamples(),

            allowSelect: {
                cases: [
                    { value: true, description: "Note how you can select the text here." },
                    { value: false, description: "Note how you can't select the text here." },
                ]
            },

            displayType: {
                cases: [
                    { value: "date" },
                ]
            },

            format: {
                cases: [
                    { value: "long", props: { displayType: "date" }, description: "Setting the format to \"long\" with a displayType of DisplayType.DATE will cause the date to display as a full day name, month name, and year." },
                ]
            },

            link: {
                cases: [
                    { value: "https://google.com" },
                ]
            },

            imageName: {
                cases: [
                    { value: "person" },
                    { value: "circleCheck" },
                ]
            },

            verticalAlign: {
                props: { height: 120, borderWidth: 1, borderColor: "primary" },
                cases: [
                    { value: "top", description: "verticalAlign = \"top\"" },
                    { value: "center", description: "verticalAlign = \"center\"" },
                    { value: "bottom", description: "verticalAlign = \"bottom\"" },
                ]
            },

            wrap: {
                cases: [
                    { value: false, props: { caption: "The quick brown fox jumped over the lazy dog", width: 140 } },
                    { value: true, props: { caption: "The quick brown fox jumped over the lazy dog", width: 140 } },
                ]
            },
        }
    }
}
