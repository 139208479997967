import { CommonDialogs, CommonTooltips } from "@mcleod/common";
import {
    Button, ClickEvent, Component, ComponentSearcher, DataDisplayEvent, DataSourceMode, EditRowDecorator, Image, Label,
    Layout, Panel, SlideoutDecorator, TableColumn, TableRow, TableRowDisplayEvent, TableRowExpansionEvent, Textbox
} from "@mcleod/components";
import { ComponentListenerDefs } from "@mcleod/components/src/base/ComponentListenerDefs";
import { LabelStyles } from "@mcleod/components/src/components/label/LabelStyles";
import {
    Alignment, Api, DisplayType, getLogger, HorizontalAlignment, Model, ModelRow, PermissionsUtil, StringUtil
} from "@mcleod/core";
import { ThemeCommonPage } from "@mcleod/core/src/themes/common/ThemeCommonPage";
import { PostToLoadBoards } from "@mcleod/dispatch/src/PostToLoadBoards";
import { SendSmsMessage } from "@mcleod/dispatch/src/SendSmsMessage";
import { ShipmentStatusSlideout } from "@mcleod/dispatch/src/ShipmentStatusSlideout";
import { Customer } from "../../ar/src/Customer";
import { MultiStop } from "../../dispatch/src/MultiStop";
import { OrderStop } from "../../dispatch/src/OrderStop";
import { Orders } from "../../dispatch/src/Orders";
import { ModelOrders } from "../../dispatch/src/models/ModelOrders";
import { BrokerageMovementTableType, SecondaryFilter } from "./BrokerageMovementTableType";
import { BrokerageMovementsGridExpansion } from "./BrokerageMovementsGridExpansion";
import { BrokeragePlanning } from "./BrokeragePlanning";
import { BrokeragePlanningToolPanel } from "./BrokeragePlanningToolPanel";
import {
    displayBrkStatusDropDowns, populateAndDisplayBrkStatusDropDowns, rowMinuteUpdate,
    setCommodityTooltipFromLayoutCallback, setRowComponentVisible, setTooltipCallbacks, setupBrkStatusPanel,
    tableSearcherCreationCallback
} from "./BrokeragePlanningUtil";
import { AutogenLayoutBrokerageMovementTable } from "./autogen/AutogenLayoutBrokerageMovementTable";
import { RowBrokeragePlanningProfile } from "./models/ModelBrokeragePlanningProfile";
import { BrTrackingQuickInfoPanel } from "./tracking/BrTrackingQuickInfoPanel";
import { Columns } from "./BrokerageMovementTableColumns";


const log = getLogger("lme.powerbroker.BrokerageMovementTable");

export class BrokerageMovementTable extends AutogenLayoutBrokerageMovementTable {
    private _type: BrokerageMovementTableType;
    private selectedSecondary: string;
    private _brokeragePlanning: BrokeragePlanning;
    private tooltipRightAlignProps = {
        position: Alignment.RIGHT,
        pointerColor: ThemeCommonPage.quickInfo.borderLeftColor
    };
    private _toolsPanel: BrokeragePlanningToolPanel;

    changeLoadboardFlag(tableRow: TableRow) {
        const data = tableRow.data;
        const orderId = data?.get("order_id");
        this.updateLoadboardFlag(orderId);
    }

    openLoadBoardSlide(tableRow: TableRow): void {
        const row = tableRow.data;
        const data = tableRow.data;
        const orderId = data?.get("order_id");
        if (orderId != null) {
            Layout.getLayout("lme/dispatch/PostToLoadBoards").addLayoutLoadListener(
                (event) => {
                    const layout = event.target as PostToLoadBoards;
                    layout.mainDataSource.search({ id: orderId }).then((result) => {
                        const erd = new EditRowDecorator({
                            title: "Post to Load Boards - Order " + orderId,
                            layout: layout,
                            layoutLoadListeners: (event) => { },
                            width: 900,
                            overlayProps: { greyedBackground: true },
                            data: layout.mainDataSource.activeRow,
                            onSave: () => {
                                erd.multiButton.busy = true;
                                layout
                                .postDataSources()
                                .then(() => layout.slideOut())
                                .finally(() => (erd.multiButton.busy = false));
                            },
                            onClose: (cancelled: boolean) => layout.slideOut(),
                            doAfterSlideIn: () => {
                                layout.mainDataSource.mode = DataSourceMode.UPDATE;
                                layout.mainDataSource.activeRow.set("loadboard", "Y");
                                layout.sourceStop.mode = DataSourceMode.UPDATE;
                                layout.setDefaultById(data.get("origin_stop_id"));
                            }
                        });
                    });
                }
            );
        }
    }

    get toolsPanel(): BrokeragePlanningToolPanel {
        return this._toolsPanel;
    }

    configureTableForType(type: BrokerageMovementTableType) {
        const columnsNeeded: Columns[] = type.getColumns();
        this._type = type;
        this.tableMovements.exportName = "Brokerage Planning Listing (" + type.caption + ") - {timestamp}";
        this.tableMovements.id += type.tableIdSuffix;
        if (columnsNeeded) {
            const columns: TableColumn[] = [...this.tableMovements.columns];
            this.tableMovements.clearColumns();

            for (const column of columnsNeeded) {
                const columnToAdd = columns.find((col: TableColumn) => col.heading.id.toLowerCase().endsWith(column.toString().toLowerCase()));
                if (columnToAdd != null) {
                    type.configureColumn(columnToAdd);
                    this.tableMovements.addColumn(columnToAdd, this.tableMovements.columns.length == 0, false);
                }
            }
        }

        this.setupSecondaryFilters();
        this._toolsPanel = new BrokeragePlanningToolPanel(this)
        this.tableMovements.addTool(this._toolsPanel);
        this.tableMovements.dataSource.orderBy = this._type.defaultSort;
    }

    /** This is an event handler for the onRowDisplay event of tableMovements.  */
    tableMovementsOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.getTableRow();
        const modelRow = tableRow.data;
        this.setupPlanningCommentEdit(tableRow);
        const orderIdLabel = tableRow.findComponentById("labelOrderId") as Label;
        if (orderIdLabel != null) {
            orderIdLabel.addUnderlineWhenMouseOver();
            orderIdLabel.visible = modelRow.isNull("manifest_id");
        }
        const customerLabel = tableRow.findComponentById("labelCustomer") as Label;
        if (customerLabel != null)
            customerLabel.addUnderlineWhenMouseOver();
        setupBrkStatusPanel(tableRow);
        this.tableMovements.addlSearcherCallback = (): ComponentSearcher[] => tableSearcherCreationCallback();
        rowMinuteUpdate(tableRow, this.type);
        this.displayBadges(tableRow);
        this.displayEmailLabel(tableRow);
        this.displaySmsBtn(tableRow);
        this.displayIcons(tableRow);

        const panelCommodity = tableRow.findComponentById("panelCommodity") as Panel;
        const imageHazmat = tableRow.findComponentById("imageHazmat") as Image;
        if (imageHazmat != null) {
            imageHazmat.visible = !modelRow.isNull("hazmat_code");
        }

        if (modelRow.get("hazmat_code") != null)
            CommonTooltips.setPanelTooltipCallback(panelCommodity, () => [
                { caption: "Hazmat Code", displayType: DisplayType.CURRENCY, text: modelRow.get("hazmat_code") }
            ])

        this.displayArriveLateTime(tableRow, "labelOriginLateTime", "origin_late", modelRow.get("same_day_pickup_window"), "panelOriginLate");
        this.displayArriveLateTime(tableRow, "labelDestLateTime", "destination_late", modelRow.get("same_day_delivery_window"), "panelDestLate");

        this.setAppointmentLabel(tableRow, modelRow, "labelOrigAppt", "origin_appt_confirmed", "origin_appt_required");
        this.setAppointmentLabel(tableRow, modelRow, "labelDestAppt", "destination_appt_confirmed", "destination_appt_required");

        const labelStopsPickDrop = tableRow.findComponentById("labelStopsPickDrop") as Label;
        if (labelStopsPickDrop != null) {
            labelStopsPickDrop.setClassIncluded(LabelStyles.link, true);
        }

        const originStopType = modelRow.get("origin_stop_type", "");
        const destStopType = modelRow.get("destination_stop_type", "");
        if (originStopType == "SP" || destStopType == "SD") {
            const imageName = originStopType === "SP" && destStopType === "SD" ? "imageYsplitComplete" : originStopType === "SP" ? "imageYsplitPick" : destStopType === "SD" ? "imageYsplit" : null;
            const imageYsplit = tableRow.findComponentById(imageName) as Image;
            if (imageYsplit != null) {
                imageYsplit.visible = true;
            }
        }

        tableRow.forEveryChildComponent(comp => {
            if (comp instanceof Textbox && (comp.displayType === DisplayType.CURRENCY || comp.displayType === DisplayType.DECIMAL) && comp.printableLabel != null)
                comp.printableLabel.align = HorizontalAlignment.LEFT;
            this.setupComponentsOnRowDisplay(comp, modelRow);
        });
    }

    private setAppointmentLabel(tableRow: TableRow, modelRow: any, labelId: string, confirmedField: string, requiredField: string) {
        const labelAppt = tableRow.findComponentById(labelId) as Label;
        if (labelAppt == null) return;
        labelAppt.imageMarginLeft = 0;
        const apptRequired = modelRow.getBoolean(requiredField);
        if (apptRequired) {
            if (modelRow.getBoolean(confirmedField)) {
                labelAppt.caption = "Confirmed";
                labelAppt.imageColor = "green";
            } else {
                labelAppt.caption = "Required";
                labelAppt.imageColor = "orange";
            }
            labelAppt.imageName = "clock";
        }
        labelAppt.visible = apptRequired;
    }


    setupComponentsOnRowDisplay(comp: Component, modelRow: ModelRow) {
        setTooltipCallbacks(comp, modelRow);
        const isAvailable = "A" === modelRow?.get("move_status");
        switch (comp.id) {
            case "textboxLastCallinEta": {
                const textboxLastCallinEta = comp as Textbox;
                if (textboxLastCallinEta.text != null && modelRow.get("last_callin_eta_timezone") != null) {
                    textboxLastCallinEta.text += " " + modelRow.get("last_callin_eta_timezone");
                }
                break;
            }
            case "textboxPnnRate":
            case "textboxTargetPay":
            case "textboxCalcMaxPay": {
                comp.visible = isAvailable && modelRow?.get(comp.field) != null;
                break;
            }
            case "textboxTotalPay":
            case "textboxMargin": {
                comp.visible = !isAvailable && modelRow?.get(comp.field) != null;
                break;
            }
        }

    }

    displayArriveLateTime(tableRow: TableRow, lateTimeComponentId: string, lateField: string, sameDayWindow: boolean, lateDatePanelId: string) {
        const timeLabel = tableRow.findComponentById(lateTimeComponentId) as Label;
        if (timeLabel != null) {
            timeLabel.caption = " - " + timeLabel.caption;
            timeLabel.visible = sameDayWindow;
        }
        const lateDatePanel = tableRow.findComponentById(lateDatePanelId) as Panel;
        if (lateDatePanel != null) {
            lateDatePanel.visible = !sameDayWindow && tableRow.data.get(lateField) != null;
        }
    }

    displayBadges(tableRow: TableRow) {
        tableRow.cells[0]?.forEveryChildComponent(comp => {
            if ("badgeHold" == comp.id) {
                CommonTooltips.setPanelTooltipCallback(comp, () => [{
                    caption: "Reason",
                    text: tableRow.data.get("hold_reason")
                }]);
                setRowComponentVisible(tableRow, comp, comp.field, true);
                if (comp.visible)
                    (comp as Label).caption = "HOLD";
            } else if ("badgePending" == comp.id) {
                setRowComponentVisible(tableRow, comp, comp.sortField, "Y");
                if (comp.visible) {
                    let tooltipData = null;
                    if (tableRow.data.get("pending_carrier_assigned") == true) {
                        tooltipData = new ModelRow("lme/powerbroker/carriers-for-assignment", false, {
                            movement_id: tableRow.data.get("movement_id"),
                            locked_user: tableRow.data.get("locked_user"),
                            name: tableRow.data.get("pending_carrier_name"),
                            icc_number: tableRow.data.get("pending_carrier_mc"),
                            dot_number: tableRow.data.get("pending_carrier_dot"),
                            intrastate_code: tableRow.data.get("pending_carrier_isc"),
                            pending_carrier: true,
                            id: tableRow.data.get("pending_payee_id")
                        })
                        CommonTooltips.setTooltipFromLayoutCallback(comp, tooltipData, "lme/powerbroker/CarrierQuickInfo", {
                            minWidth: 100,
                            minHeight: 100
                        }, this.tooltipRightAlignProps);
                    } else {
                        Api.search("lme/powerbroker/carriers-for-assignment", { id: tableRow.data.get("pending_payee_id") })
                        .then(result => {
                            const payeeData = result?.data[0];
                            tooltipData = new ModelRow("lme/powerbroker/carriers-for-assignment", false, {
                                movement_id: tableRow.data.get("movement_id"),
                                locked_user: tableRow.data.get("locked_user"),
                                name: payeeData.name,
                                phone_number: payeeData.phone_number ?? "",
                                icc_number: payeeData.icc_number,
                                dot_number: payeeData.dot_number,
                                intrastate_code: payeeData.intrastate_code,
                                pending_carrier: false,
                                id: tableRow.data.get("pending_payee_id")
                            });
                            CommonTooltips.setTooltipFromLayoutCallback(comp, tooltipData, "lme/powerbroker/CarrierQuickInfo", {
                                minWidth: 100,
                                minHeight: 100
                            }, this.tooltipRightAlignProps);
                        });
                    }
                    CommonTooltips.setTooltipFromLayoutCallback(comp, tooltipData ?? tableRow.data.get("pending_payee_id"), "lme/powerbroker/CarrierQuickInfo", {
                        minWidth: 100,
                        minHeight: 100
                    }, this.tooltipRightAlignProps);
                }
            }
            tableRow.cells[1]?.forEveryChildComponent(comp => {
                if ("badgeBrTrackStatus" == comp.id) {
                    setRowComponentVisible(tableRow, comp, comp.field);
                }
            })
        })
    }

    private setupPlanningCommentEdit(tableRow: TableRow) {
        const planningComment: Textbox = tableRow.findComponentById("textboxPlanningComment") as Textbox;
        if (planningComment != null)
            planningComment.printableLabelCreationCallback = (label: Label) => label.style.display = "block";
    }

    textboxPlanningCommentOnClick(event: ClickEvent) {
        const planningComment = event.target as Textbox;
        const tableRow = TableRow.getContainingTableRow(planningComment);
        if (planningComment.printable !== true && document.activeElement === planningComment.getEventTarget())
            event.stopPropagation();
        else
            tableRow.rowClicked(event.domEvent);
    }

    textboxPlanningCommentOnDblClick(event: ClickEvent) {
        const planningComment = event.target as Textbox;
        const tableRow = TableRow.getContainingTableRow(planningComment);
        if (planningComment.printable !== true && document.activeElement === planningComment.getEventTarget())
            event.stopPropagation();
    }

    buttonEditCommentOnClick(event: ClickEvent) {
        const editButton = event.target as Button;
        const tableRow = TableRow.getContainingTableRow(editButton);
        const planningComment = BrokerageMovementTable._getPlanningCommentTextbox(tableRow);
        const saveButton: Button = BrokerageMovementTable._getPlanningCommentSaveButton(tableRow);
        const cancelButton: Button = BrokerageMovementTable._getPlanningCommentCancelButton(tableRow);
        editButton["originalText"] = planningComment.text;
        planningComment.printable = false;
        planningComment.enabled = true;
        planningComment.focus();
        saveButton.visible = true;
        cancelButton.visible = true;
        editButton.visible = false;
    }

    async buttonSaveCommentOnClick(event: ClickEvent) {
        const saveButton = event.target as Button;
        saveButton.busy = true;
        const tableRow = TableRow.getContainingTableRow(saveButton);
        const planningComment = BrokerageMovementTable._getPlanningCommentTextbox(tableRow);
        const editButton: Button = BrokerageMovementTable._getPlanningCommentEditButton(tableRow);
        const cancelButton: Button = BrokerageMovementTable._getPlanningCommentCancelButton(tableRow);
        const orderId: string = tableRow.data.get("order_id");
        await Api.post("lme/dispatch/update-order-planning-comment",
            { order_id: orderId, planning_comment: planningComment.text })
        .finally(() => saveButton.busy = false);
        planningComment.enabled = false;
        planningComment.printable = true;
        saveButton.visible = false;
        cancelButton.visible = false;
        editButton.visible = true;
    }

    buttonCancelCommentOnClick(event: ClickEvent) {
        const cancelButton = event.target as Button;
        const tableRow = TableRow.getContainingTableRow(cancelButton);
        const planningComment = BrokerageMovementTable._getPlanningCommentTextbox(tableRow);
        const editButton: Button = BrokerageMovementTable._getPlanningCommentEditButton(tableRow);
        const saveButton: Button = BrokerageMovementTable._getPlanningCommentSaveButton(tableRow);
        planningComment.text = editButton["originalText"];
        planningComment.enabled = false;
        planningComment.printable = true;
        saveButton.visible = false;
        cancelButton.visible = false;
        editButton.visible = true;
    }

    panelBrkStatusOnClick(event: ClickEvent) {
        const panel = event.target as Panel;
        panel.ripple("primary.lighter");
        if (panel["statusDropDownItems"] != null)
            displayBrkStatusDropDowns(panel);
        else {
            const row = TableRow.getContainingTableRow(panel);
            populateAndDisplayBrkStatusDropDowns(panel, row?.data?.get("movement_id"), this);
        }
    }

    private static _getPlanningCommentTextbox(tableRow: TableRow): Textbox {
        return tableRow.findComponentById("textboxPlanningComment") as Textbox;
    }

    private static _getPlanningCommentEditButton(tableRow: TableRow): Button {
        return tableRow.findComponentById("buttonEditComment") as Button;
    }

    private static _getPlanningCommentSaveButton(tableRow: TableRow): Button {
        return tableRow.findComponentById("buttonSaveComment") as Button;
    }

    private static _getPlanningCommentCancelButton(tableRow: TableRow): Button {
        return tableRow.findComponentById("buttonCancelComment") as Button;
    }

    onRowExpand(event: TableRowExpansionEvent) {
        this.tableMovements.expandComponent = new Panel();
        if ((event.target as TableRow).data != null) {
            const row = (event.target as TableRow).data;
            const expansion = Layout.getLayout("lme/powerbroker/BrokerageMovementsGridExpansion") as BrokerageMovementsGridExpansion;
            expansion.addLayoutLoadListener(() => {
                expansion.id = "ExpandedBrokerageMovementsGrid";
                expansion.initialize(
                    event.target,
                    row.get("movement_id"),
                    row.get("order_id"),
                    row.get("move_status"),
                    this._brokeragePlanning.mainDataSource,
                    this.type
                );
                (event.expandComponentParent as Panel).add(expansion);
            });
        }
    }

    public set brokeragePlanning(planning: BrokeragePlanning) {
        this._brokeragePlanning = planning;
    }

    public get type(): BrokerageMovementTableType {
        return this._type;
    }

    textboxPlanningCommentOnChange(event) {
        if (event.newValue.length > 80) {
            const textbox: Textbox = event.target as Textbox;
            textbox.showTooltip("You have exceeded the 80 character limit.", {
                position: Alignment.RIGHT,
                shaking: true,
                timeout: 5000
            });
            if (event.oldValue.length <= 80)
                textbox.text = event.oldValue;
            else
                textbox.text = null;
        }
    }

    orderNumberOnClick(event: ClickEvent) {
        Orders.navigateTo((event.target as Label).caption);
    }

    customerIdOnClick(event: ClickEvent) {
        const row: TableRow = TableRow.getContainingTableRow(event.target as Component);
        Customer.navigateTo(row.data.get("customer_id"));
    }

    async displaySmsBtn(tableRow: TableRow) {
        if (tableRow.data.getBoolean("is_sms_licensed")) {
            const smsBtn = tableRow?.findComponentById("sendSmsBtn") as Button;
            const carrierDriverName = tableRow?.findComponentById("labelCarrierDriverName") as Label;
            const carrierDriverCell = tableRow?.findComponentById("labelCarrierDriverCell") as Label;
            const orderId = tableRow.data.get("order_id");
            if (carrierDriverName && carrierDriverName.caption === undefined) {
                carrierDriverName.tooltip = "";
                const parentNode = carrierDriverName.parent;
                parentNode.remove(carrierDriverName);
            }
            if (carrierDriverCell && carrierDriverCell.caption === undefined) {
                carrierDriverCell.tooltip = "";
                const parentNode = carrierDriverCell.parent;
                parentNode.remove(carrierDriverCell);
            }

            if (smsBtn) {
                smsBtn.visible = true;
                if (tableRow.data.get("inbound_unread_text_messages_exist") > 0) {
                    smsBtn.imageName = "unReadChatBubble";
                    CommonTooltips.setTooltipFromLayoutCallback(smsBtn, null, "lme/powerbroker/SmsQuickInfo", {
                        maxWidth: 400,
                        maxHeight: 400
                    }, this.tooltipRightAlignProps, { order_id: orderId });
                } else {
                    smsBtn.imageName = "chatBubble";
                    smsBtn.showTooltip("Send Message");
                }
            }
        }
    }

    sendSmsBtnOnClick(event: ClickEvent) {
        const row: TableRow = TableRow.getContainingTableRow(event.target as Component);
        const driverName = row.data.get("carrier_driver");
        const carrierId = row.data.get("carrier_id");
        const movementId = row.data.get("movement_id");
        const overrideDriverCell = row.data.get("carrier_driver_cell");
        if (movementId && carrierId) {
            SendSmsMessage.showEditRowDecorator(movementId, carrierId, driverName, overrideDriverCell);
        }
    }

    displayEmailLabel(tableRow: TableRow) {
        const labelEmail = tableRow?.findComponentById("labelCarrierDriverEmail") as Label;
        setRowComponentVisible(tableRow, labelEmail, "carrier_driver_email");
        if (labelEmail && labelEmail.visible) {
            const email = tableRow.data.get("carrier_driver_email");
            if (email == null) return;
            labelEmail.link = "mailto:" + email;
            CommonTooltips.setTooltipCallback(labelEmail, new Label({
                caption: email,
                displayType: DisplayType.EMAIL
            }), null, false);
        }
    }

    displayIcons(tableRow: TableRow) {
        this.displayRateConImage(tableRow.findComponentById("imageRateCon") as Image, tableRow.data);
        this.displayLockImage(tableRow.findComponentById("imageLock") as Image, tableRow.data);
        this.displayLoadBoardImage(tableRow);
        this.displayDigitalOfferImage(tableRow);
        this.displayServiceFailureImage(tableRow);
        this.displayPackageErrorImage(tableRow);
    }

    displayRateConImage(imageRateCon: Image, modelRow: ModelRow) {
        if (imageRateCon == null) return;
        const rateConStatus = modelRow.get("rate_con_status");
        const imageName = modelRow.get("rate_con_status_image_name");
        imageRateCon.visible = imageName != null;
        if (rateConStatus != null) {
            imageRateCon.name = imageName;
            imageRateCon.color = modelRow.get("rate_con_status_image_color", "default");
            CommonTooltips.setPanelTooltipCallback(imageRateCon, () => [
                { caption: "Rate Con Status", text: modelRow.get("rate_con_status_desc") }
            ], this.tooltipRightAlignProps)
        }
    }

    displayLockImage(imageRateLock: Image, modelRow: ModelRow) {
        if (imageRateLock == null) return;
        const locked = modelRow.get("locked", false);
        imageRateLock.visible = locked;
        if (locked) {
            const waterfallInProgress = modelRow.get("waterfall_in_progress", false);
            imageRateLock.name = waterfallInProgress ? "waterfall" : "lock";
            CommonTooltips.setTooltipFromLayoutCallback(imageRateLock, modelRow, "lme/powerbroker/MovementLockQuickInfo", {
                minWidth: 100,
                minHeight: 100
            }, this.tooltipRightAlignProps);
        }
    }

    async displayLoadBoardImage(tableRow: TableRow) {
        const imageLoadboard = tableRow.findComponentById(
            "imageLoadboard"
        ) as Image;
        const imageLoadboardError = tableRow.findComponentById(
            "imagePostingLoadboardError"
        ) as Image;

        try {
            if (
                tableRow.data.getBoolean("loadboard") &&
                tableRow.data.get("move_status") === "A" &&
                !StringUtil.isEmptyString(tableRow.data.get("order_id"))
            ) {
                const response = await Api.post(
                    "lme/powerbroker/get-loadboard-prevent-posting-reasons",
                    {
                        order: tableRow.data.get("order_id")
                    }
                );

                const reasonsList = response?.data?.[0].prevent_posting_reasons;
                if (reasonsList) {
                    const formattedReasons = reasonsList.map((reason) => `${reason}`).join("\n");
                    const uniqueReasons = [...new Set(reasonsList)]; // Remove duplicates from reasonsList
                    const uniqueFormattedReasons = uniqueReasons.map((reason) => `• ${reason}`).join("\n");
                    if (uniqueFormattedReasons) {
                        imageLoadboardError.visible = true;
                        this.setUpLoadBoardErrorTooltip(
                            tableRow,
                            uniqueFormattedReasons,
                            imageLoadboardError
                        );
                    } else {
                        this.displayPostingIcon(
                            tableRow,
                            imageLoadboard,
                            imageLoadboardError
                        );
                    }
                } else {
                    this.displayPostingIcon(
                        tableRow,
                        imageLoadboard,
                        imageLoadboardError
                    );
                }
            } else {
                this.displayPostingIcon(tableRow, imageLoadboard, imageLoadboardError);
            }
        } catch (error) {
            // Handle API call error here
            console.error("API call failed:", error);
        }
    }

    displayDigitalOfferImage(tableRow: TableRow) {
        const imageDigitalOffer = tableRow.findComponentById("imageDigitalOffer") as Image;
        if (imageDigitalOffer == null) return;
        if (tableRow.data.getBoolean("digitally_booked")) {
            imageDigitalOffer.visible = true;
        } else {
            imageDigitalOffer.visible = false;
        }
    }

    displayPackageErrorImage(tableRow: TableRow) {
        const imagePackageError = tableRow.findComponentById("imagePackageError") as Image;
        if (imagePackageError == null) return;
        if (tableRow.data.getBoolean("has_shipment_error")) {
            imagePackageError.visible = true;
            CommonTooltips.setPanelTooltipCallback(imagePackageError, () => [
                { caption: "Shipment Status Errors", text: "Click to view statuses" }
            ], this.tooltipRightAlignProps);
        } else {
            imagePackageError.visible = false;
        }
    }

    openStatusSlideout(event: ClickEvent) {
        const row: TableRow = TableRow.getContainingTableRow(event.target as Component);
        const id = row.data.get("order_id");
        const moveId = row.data.get("movement_id");
        const customerId = row.data.get("customer_id");
        if (id) {
            const layout = Layout.getLayout("lme/dispatch/ShipmentStatusSlideout", {
                width: window.innerWidth * .70,
                backgroundColor: "defaultBackground",
                fillHeight: true,
                paddingBottom: 50
            }) as ShipmentStatusSlideout;
            new SlideoutDecorator({
                layout: layout,
                title: "DataFusion - Order " + id,
                fillVerticalSpace: true,
                addlComponents: [layout.saveButton],
                doAfterSlideIn: (decorator: SlideoutDecorator) => {
                    layout.sourceOrderEdistatus.search({ order_id: id });
                    layout.sourceEdiOrder.search({ order_id: id, movement_id: moveId });
                    layout.tabset1.selectedIndex = layout.tabset1.indexOf(layout.tabOutbound);
                    layout.saveButton.addClickListener(event => {
                        if (decorator.validateSimple()) {
                            const dataLayout = layout.outboundDataSource.data;
                            dataLayout?.forEach((data, index) => {
                                if (data.hasChanged()) {
                                    layout.outboundDataSource.data[index].post();
                                }
                            });
                        }
                    });
                }
            });
            layout.orderId = id;
            layout.movementId = moveId;
            layout.customerId = customerId;
        }
    }

    /** This is an event handler for the onDataDisplay event of badgeBrTrackStatus.  */
    badgeBrTrackStatusOnDataDisplay(event: DataDisplayEvent) {
        const badgeBrTrackStatus = event.target as Label;
        const brTrackStatusForBadge = event.rowData?.get("br_track_status_for_badge");
        badgeBrTrackStatus.caption = brTrackStatusForBadge || "--";
        badgeBrTrackStatus.backgroundColor = event.rowData?.get("br_track_status_color");
        CommonTooltips.setTooltipCallback(badgeBrTrackStatus, () => new BrTrackingQuickInfoPanel(event.rowData));
    }

    badgeBrTrackStatusOnDblClick(event: ClickEvent) {
        const row: TableRow = TableRow.getContainingTableRow(event.target as Component);
        const brDetailsLink: string = row.data.get("br_details_link");
        if (!StringUtil.isEmptyString(brDetailsLink)) {
            window.open(brDetailsLink, "_blank");
        }
    }

    labelOrigApptOnClick(event: ClickEvent) {
        this.showMultiStopSlideout(event);
    }

    labelDestApptOnClick(event: ClickEvent) {
        this.showMultiStopSlideout(event);
    }

    showStopEditLayout(event, origin: boolean) {
        const label = event.target as Label;
        label.busy = true;
        const tableRow: TableRow = TableRow.getContainingTableRow(event.target);
        tableRow.table.selectedRow = tableRow;
        const row = tableRow.data;
        let stopId: string;
        let confirmed: boolean;
        if (origin) {
            stopId = row.get("origin_stop_id");
            confirmed = row.get("origin_appt_confirmed");
        } else {
            stopId = row.get("destination_stop_id");
            confirmed = row.get("destination_appt_confirmed");
        }
        let stopLayout: OrderStop;
        Model.searchSingleRecord("lme/dispatch/stop", { id: stopId }, "lme/dispatch/OrderStop", null, null).then(stopRow => {
            const erd = new EditRowDecorator({
                title: `Stop Details - Order ${row.get("order_id")}`,
                layout: stopLayout = Layout.getLayout("lme/dispatch/OrderStop") as OrderStop,
                layoutLoadListeners: event => {
                    stopLayout.switchConfirmed.visibleDuringUpdate = true;
                    stopLayout.validateApptChanges = true;
                    stopLayout.setFreeFormVisibility();
                    stopLayout.panelAdditionalDetails.visible = true;
                    stopLayout.switchPickupConsignee.enabled = false;
                    if (confirmed) {
                        this.disableSomeFields(stopLayout);
                    }
                    stopLayout.textboxLocationCode.text = stopRow.get("location_id");
                    stopLayout.mainDataSource.mode = DataSourceMode.UPDATE;
                    stopLayout.commentsAndRefNbrs.stopRow = stopRow;
                    stopLayout.setTableParentDataSource(stopLayout.commentsAndRefNbrs.tableStopComments, stopLayout.mainDataSource);
                    stopLayout.setTableParentDataSource(stopLayout.commentsAndRefNbrs.tableStopReferenceNumbers, stopLayout.mainDataSource);
                    if (stopLayout.mainDataSource.activeRow?.get("sched_arrive_late") == null)
                        stopLayout.textboxStopDateType.selectedItem = stopLayout.stopDateTypeOn;
                },
                width: 1300,
                overlayProps: { closeOnClickOff: false, greyedBackground: true },
                data: stopRow,
                onSave: (updatedData: ModelRow | any) => {
                    stopLayout.mainDataSource.layoutName = "lme/dispatch/OrderStop";
                    const apptRow = stopLayout.getAppointmentChangedRow();
                    updatedData.set("bp_stop_slideout", true);
                    if (apptRow != null)
                        updatedData.set("appointment_change", apptRow);
                    stopLayout.mainDataSource.post().then(result => { stopLayout.slideOut(); });
                },
                onClose: (cancelled: boolean) => stopLayout.slideOut(),
                doAfterSlideIn: () => label.busy = false
            });
        }).catch((error) => {
            label.busy = false;
            CommonDialogs.showError(error);
        })
    }

    private disableSomeFields(stopLayout: Panel) {
        const editableFields = ["address2", "cases", "weight", "volume", "edi_loc_code", "appt_required", "appointment_status_id", "sched_arrive_early", "sched_arrive_late", "confirmed"];
        if (stopLayout instanceof OrderStop)
            (stopLayout as OrderStop).textboxLocationId.enabled = false;
        stopLayout.components.forEach((component: Component) => {
            if (component instanceof Panel) {
                this.disableSomeFields(component);
            } else if (!(component instanceof Label) && !editableFields.includes(component.field) && component.id != "textboxStopDateType") {
                component.enabled = false;
            } else if (component.field == "confirmed" && PermissionsUtil.isUserDeniedAction("Dispatch.Unconfirm appointments")) {
                component.enabled = false;
                component.disabledTooltip = "You do not have permission to unconfirm appointments";
            }
        });
    }

    imageLoadboardOnClick(event: ClickEvent) {
        const clickedRow = TableRow.getContainingTableRow(event.target as Component);
        const orderId = clickedRow.data.get("order_id");
        this.updateLoadboardFlag(orderId);
    }

    private async updateLoadboardFlag(orderId: string) {
        const rowOrder = await new ModelOrders().searchSingle({ id: orderId });
        rowOrder.set("loadboard", false);
        await rowOrder.post();
    }

    displayServiceFailureImage(tableRow: TableRow) {
        const imageServiceIncident = tableRow.findComponentById("imageServiceIncident") as Image;
        if (imageServiceIncident == null) return;
        if (tableRow.data.getBoolean("has_service_failures")) {
            imageServiceIncident.visible = true;
        } else {
            imageServiceIncident.visible = false;
        }
    }

    public get filter() {
        return (row: ModelRow, profile: RowBrokeragePlanningProfile) => {
            let result = this.type.filter(row, profile);

            // if a secondary filter is selected add its result
            if (this.selectedSecondary != null && !this.getSecondaryFilter()?.filter(row))
                result = false;

            return result;
        }
    }

    public getSecondaryFilter(): SecondaryFilter {
        return this.type.secondaryFilters?.find(f => f.id === this.selectedSecondary);
    }

    private setupSecondaryFilters() {
        log.debug("Setting up secondary filters", this._type.secondaryFilters);
        this.selectedSecondary = null;
        this._type.secondaryFilters?.forEach(sFilter => this.panelSecondaryFilters.add(this.getSecondaryFilterButton(sFilter)));
    }

    private getSecondaryFilterButton(sFilter: SecondaryFilter): Button {
        const button = new Button({
            id: `btn${sFilter.id}`,
            caption: sFilter.caption,
            rowBreak: false,
            color: "McLeodTertiary",
            marginRight: 12,
            paddingTop: 6,
            paddingBottom: 6,
            onClick: (event => this.toggleSecondaryFilter(event, sFilter))
        });
        return button;
    }

    private toggleSecondaryFilter(event: ClickEvent, sFilter: SecondaryFilter) {
        log.debug(`Toggling secondary filter: ${sFilter.caption}`);

        if (this.selectedSecondary == null || this.selectedSecondary !== sFilter.id) {
            if (this.selectedSecondary != null) {
                const btn = this.panelSecondaryFilters.findComponentById(`btn${this.getSecondaryFilter().id}`) as Button;
                this.updateFilterBtnStyle(btn, false);
            }
            this.selectedSecondary = sFilter.id;
            this.updateFilterBtnStyle((event.target as Button), true);
        } else {
            this.selectedSecondary = null;
            this.updateFilterBtnStyle((event.target as Button), false);
        }
        this._brokeragePlanning.populateFilteredTables(this._type);
    }

    private updateFilterBtnStyle(btn: Button, selected: boolean) {
        btn.color = selected ? "success.reverse" : "McLeodTertiary";
        btn.backgroundColor = selected ? "success" : null;
    }

    cellStopsOnClick(event: ClickEvent) {
        this.showMultiStopSlideout(event);
    }

    showMultiStopSlideout(event) {
        const label = event.target as Label;
        label.busy = true;
        const tableRow: TableRow = TableRow.getContainingTableRow(event.target);
        tableRow.table.selectedRow = tableRow;
        const row = tableRow.data;
        let multiStopLayout: MultiStop;
        const erd = new EditRowDecorator({
            title: `Stop Details - Order ${row.get("order_id")}`,
            layout: multiStopLayout = Layout.getLayout("lme/dispatch/MultiStop") as MultiStop,
            layoutLoadListeners: async (event) => {
                await multiStopLayout.mainDataSource.search({ id: row.get("order_id") });
                multiStopLayout.mainDataSource.mode = DataSourceMode.UPDATE;
                multiStopLayout.activeRow.set("calc_target_pay", row.get("calc_target_pay"));
                multiStopLayout.activeRow.set("calc_max_pay", row.get("calc_max_pay"));
                if (row.get("target_pay_method") === "S") {
                    multiStopLayout.textboxTargetPay.captionVisible = false;
                    multiStopLayout.panelMPact.visible = true;
                }
                // Commodity Quick Info
                multiStopLayout.activeRow.set("order_id", row.get("order_id"));
                setCommodityTooltipFromLayoutCallback(multiStopLayout.textboxCommodityId, multiStopLayout.activeRow, "lme/dispatch/CommodityQuickInfo");
            },
            width: '70%',
            overlayProps: { closeOnClickOff: false, greyedBackground: true },
            onSave: async () => {
                multiStopLayout.sourceMultiStop.post();
            },
            onClose: (cancelled: boolean) => multiStopLayout.slideOut(),
            doAfterSlideIn: () => label.busy = false
        });
    }

    displayPostingIcon(
        tableRow: TableRow,
        imageLoadboard: Image,
        imageLoadboardError: Image
    ) {
        if (tableRow.data.getBoolean("loadboard") && tableRow.data.get("move_status") === "A") {
            imageLoadboard.visible = true;
            imageLoadboardError.visible = false;
        } else {
            imageLoadboard.visible = false;
            imageLoadboardError.visible = false;
        }

        imageLoadboard.tooltipCallback = (baseTooltip, originatingEvent) => {
            return imageLoadboard.showTooltip(imageLoadboard.tooltip, {
                position: Alignment.RIGHT
            });
        };
    }


    setUpLoadBoardErrorTooltip(tableRow: TableRow, message: string, imageLoadboardError: Image) {
        imageLoadboardError.tooltipCallback = () => {
            const panel = new Panel({ padding: 0, margin: 0, wrap: true, maxWidth: 500 });
            panel.add(new Label({
                caption: "Load Board Error",
                fontBold: true,
                color: "error",
                imageName: "danger",
                rowBreak: true
            }));
            panel.add(new Label({
                caption: "There is an error posting to the Load Board. See below for the reasons:",
                fontBold: true,
                rowBreak: true,
                marginLeft: 24
            }));
            panel.add(new Label({
                caption: message,
                marginLeft: 28,
                marginTop: 1,
                marginBottom: 1,
                padding: 0,
                rowBreak: true
            }));
            const pencil = new Image({
                name: "pencil",
                visible: true,
                height: 22,
                width: 22,
                marginLeft: 24,
                marginTop: 12,
                tooltip: "Edit load board posting",
                rowBreak: false
            });
            const cancel = new Image({
                name: "circleX",
                visible: true,
                height: 22,
                width: 22,
                marginTop: 12,
                tooltip: "Cancel load board posting",
                rowBreak: false
            });

            panel.add(pencil);
            if (!pencil.hasListeners(ComponentListenerDefs.click)) {
                pencil.addClickListener(() => this.openLoadBoardSlide(tableRow));
            }

            panel.add(cancel);
            cancel.addClickListener(() => {
                this.changeLoadboardFlag(tableRow);
                imageLoadboardError.visible = false;
            });

            return imageLoadboardError.showTooltip(panel, null, {
                themeKey: "quickInfo"
            });
        };
    }
}
