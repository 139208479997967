import { CurrencyUtil } from "./CurrencyUtil";

export interface CurrencyProps {
    amount: number;
    base_amount?: number;
    currency_code?: string;
    conversion_rate?: number;
    conversion_date?: Date;
    operand?: string;
}

export class Currency {
    currencySettings = CurrencyUtil.getCurrencySettings();
    public amount: number
    public base_amount: number
    public currency_code: string
    public conversion_rate: number
    public conversion_date: Date
    public operand: string

    constructor(currency: CurrencyProps) {
        this.amount = currency.amount;
        this.base_amount = currency.base_amount;
        this.currency_code = currency.currency_code
            ? currency.currency_code
            : this.currencySettings.effective_currency_code;
        this.conversion_rate = currency.conversion_rate;
        this.conversion_date = currency.conversion_date
            ? currency.conversion_date
            : this.currencySettings.override_conversion_date;
        this.operand = currency.operand;
    }

    update(amount: number): void {
        this.amount = Number.parseFloat(amount.toFixed(2));
        this.base_amount = undefined;
        this.conversion_rate = undefined;
    }

    toString(formatted: boolean = true): string {
        if (!formatted)
            return this.amount.toString();

        const formatter = new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: this.currency_code,
        })
        return formatter.format(this.amount);
    }

    static createCurrency(amount: number | string): Currency {
        return new Currency({
            amount: (typeof amount === "string")
                ? parseFloat(amount)
                : amount
        })
    }
}
