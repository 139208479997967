import { AutogenLayoutDistanceCalculatorOverview } from "./autogen/AutogenLayoutDistanceCalculatorOverview";

const QUERY_MODEL_ENDPOINT = "lme/dispatch/distance-control";
export class DistanceCalculatorOverview extends AutogenLayoutDistanceCalculatorOverview {
    override onLoad(): void {
        this.lookupModelShortAndPracticalDistance();
    }

    /** When Screen loads call the QUERY_MODEL_ENDPOINT and populate the list of distance Profile */
    private lookupModelShortAndPracticalDistance() {
        this.sourceDistanceControl.search();
    }

    public showMilesAndTollAmount(response: any) {
        if (response?.short_mileage != undefined) {
            const shortTollAmount = response?.short_toll_amount;
            this.labelShortMilesCalculateValue.caption = response?.short_mileage;
            this.labelShortTollAmntCalculateValue.caption = shortTollAmount.symbol + shortTollAmount.amount;
            this.labelShortTollAmntCalculateValue.color = response?.short_toll_amount_color;
        }

        if (response?.practical_mileage != undefined) {
            const practicalTollAmount = response?.practical_toll_amount;
            this.labelPracticalMilesCalculateValue.caption = response?.practical_mileage;
            this.labelPracticalTollAmntCalculateValue.caption = practicalTollAmount.symbol + practicalTollAmount.amount;
            this.labelPracticalTollAmntCalculateValue.color = response?.practical_toll_amount_color;
        }
    }

    public setMainPanelShortDistanceVisible(visible: boolean) {
        this.mainPanelShortDistance.visible = visible;
    }

    public setPanelShortDistanceVisible(visible: boolean) {
        this.panelShortDistance.visible = visible;
    }

    public setTextboxShortDistProfileVisible(visible: boolean) {
        this.textboxShortDistProfile.visible = visible;
    }

    public setMainPanelPracticalDistanceVisible(visible: boolean) {
        this.mainPanelPracticalDistance.visible = visible;
    }

    public setPanelPracticalDistanceVisible(visible: boolean) {
        this.panelPracticalDistance.visible = visible;
    }

    public setPanelOverviewSummaryVisible(visible: boolean) {
        this.panelOverviewSummary.visible = visible;
    }

    public setTextboxPracticalDistProfileVisible(visible: boolean) {
        this.textboxPracticalProfile.visible = visible;
    }
}
