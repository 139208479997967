import { ClickEvent, DataSource, DataSourceAction, DataSourceExecutionEvent, DataSourceMode, Event, Layout, SlideoutDecorator } from "@mcleod/components";
import { ArrayUtil, ModelRow, Navigation, StringUtil, getUnauthSettings, McLeodClassicIntegration } from "@mcleod/core";
import { CallRecordTable } from "@mcleod/dispatch/src/CallRecordTable";
import { UsersResponsibilitySlideoutMF } from "../../general/src/UsersResponsibilitySlideoutMF";
import { AutogenLayoutARInquiry } from "./autogen/AutogenLayoutARInquiry";
import { AutogenLayoutCustomer } from "./autogen/AutogenLayoutCustomer";

const viewCustomerURL = "lme/ar/Customer?mode=update&key=";

export class Customer extends AutogenLayoutCustomer {
    private validFileExtensions = [".tiff", ".png", ".jpg", ".gif", ".bmp"] as string[];

    override onLoad() {
        this.sourceCustomer.afterGetDataboundValues = (row: ModelRow, dataSource: DataSource) => this.sourceCustomerAfterGetDataboundValues(row, dataSource);
        this.setAllowedImageTypes();
        if (!getUnauthSettings().company_settings["is_brokerage_ltl"]) this.hideLtlTabs();
    }

    buttonOpenClassicOnClick(event: ClickEvent) {
        McLeodClassicIntegration.openClassicScreen("com.tms.client.loadmaster.ar.EntryCustomer", this.mainDataSource.activeRow.get("id"));
    }

    hideLtlTabs() {
        this.tabAutoRate.visible = false;
        this.tabBillofLading.visible = false;
    }

    textboxCategoryBeforeLookupModelSearch(event) {
        event.filter.customer = 'Y';
    }

    textboxOperationsUserBeforeLookupModelSearch(event) {
        event.filter.web_user_type = 'U';
    }

    displayCustomerUsersSlideout() {
        const urs = new UsersResponsibilitySlideoutMF("lme/ar/CustomerUsers", this.getCustomersUsersSlideoutTitle(), this.getCustomerUsersIdFilter(),
            this.getCustomerUsersAgencyField(), this.sourceCustomer, this.sourceAgencyLink);
        urs.show();
    }

    private getCustomersUsersSlideoutTitle(): string {
        const name = this.sourceCustomer.activeRow?.get("name");
        return "Customer Users" + ((StringUtil.isEmptyString(name) === false) ? (" - " + name) : "");
    }

    private getCustomerUsersIdFilter(): any {
        const id = this.sourceCustomer.activeRow?.get("id");
        if (id != null)
            return { id: id };
        return null;
    }

    private getCustomerUsersAgencyField(): string {
        return "primary_agency";
    }

    /** This is an event handler for the beforeExecution event of sourceLocation.  It's gross. */
    sourceCustomerAfterGetDataboundValues(row: ModelRow, dataSource: DataSource) {
        if (dataSource.mode === DataSourceMode.SEARCH) {
            //have to set primary_agency in search mode because the field isn't bound
            const primaryAgencyField = this.getCustomerUsersAgencyField();
            row.set(primaryAgencyField, dataSource.searchRow.get(primaryAgencyField));

            //have to add agency_link search data to the filter manually, since there are no bound components for those records
            if (ArrayUtil.isEmptyArray(this.sourceAgencyLink.data))
                return;
            row.addLinkedModel({ model: this.sourceAgencyLink.url, rows: [this.sourceAgencyLink.data[0]] });
        }
    }

    sourceCustomerAfterExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() == DataSourceAction.SEARCH) {
            (this.layoutCallRecord as CallRecordTable).customerId = this.activeRow?.get("id");
        }
    }

    buttonAllCallsOnClick(event: ClickEvent) {
        const id = this.activeRow?.get("id");
        if (!StringUtil.isEmptyString(id)) {
            const layout = Layout.getLayout("lme/dispatch/CallRecordTable") as CallRecordTable;
            new SlideoutDecorator({
                layout: layout,
                width: window.innerWidth * .75,
                fillVerticalSpace: true,
                title: `Customer Sales Calls - ${this.activeRow.get("name")}`,
                layoutLoadListeners: (event: Event) => layout.tableCallRecords.headerVisible = true,
                doAfterSlideIn: (decorator: SlideoutDecorator) => layout.mainDataSource.search({ "parent_row_type": "C", "parent_row_id": id })
            })
        }
    }

    unpostedOrdersOnClick(event: ClickEvent) {
        const id = this.mainDataSource.activeRow?.get("id");
        if (id) {
            const layout = Layout.getLayout("lme/ar/ARInquiry") as AutogenLayoutARInquiry;
            new SlideoutDecorator({
                layout: layout,
                width: window.innerWidth * .75,
                fillVerticalSpace: true,
                title: "Customer Unposted Orders - " + this.mainDataSource.activeRow.get("name"),
                layoutLoadListeners: (event: Event) => layout.panelSearch.visible = false,
                doAfterSlideIn: (decorator: SlideoutDecorator) => layout.mainDataSource.search({ "orders.customer_id": id })
            });
        }
    }

    public static navigateTo(id: string): void {
        Navigation.navigateTo(viewCustomerURL + id, { newTab: true });
    }

    textboxSellTariffBeforeLookupModelSearch(event) {
        event.filter.tariff_type_code = 'S';
    }

    textboxBuyTariffBeforeLookupModelSearch(event) {
        event.filter.tariff_type_code = 'B';
    }

    textboxP44ProfileBeforeLookupModelSearch(event) {
        event.filter.config_type = 'N';
    }

    private setAllowedImageTypes() {
        this.attachmentBlLogo["setAttachmentWithUI"] = (file: File) => {
            const maxSize = this.attachmentBlLogo.maxSize;
            const fileName = file.name.toLowerCase();
            const fileExtension = fileName.substring(fileName.lastIndexOf("."));
            if (!this.validFileExtensions.includes(fileExtension))
                return this.attachmentBlLogo.showTooltip("The file must be an image.", { shaking: true, timeout: 5000 });
            else if (maxSize != null && file.size > maxSize)
                return this.attachmentBlLogo.showTooltip("The file is too big to attach. The file must be smaller than " + this.attachmentBlLogo.maxSize / (1024 * 1024) + "MB.", { shaking: true, timeout: 5000 });
            else {
                this.attachmentBlLogo.setFile(file);
            }
        }
    }
}
