/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelEdiFunctionalAck.ts              *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/datafusion/edi-functional-ack";

export class AutogenModelEdiFunctionalAck<RowType extends ModelRow> extends Endpoint<RowType, RowEdiFunctionalAckFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowEdiFunctionalAck extends ModelRow<RowEdiFunctionalAckFields> {
    constructor(values?: Partial<RowEdiFunctionalAckFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowEdiFunctionalAckFields {
    ak101_func_id_code: string;
    ak102_group_ctl_no: string;
    ak201_trx_id_code: string;
    ak202_trx_ctl_no: string;
    ak501_trx_ack_cd: string;
    ak502_trx_set_err: string;
    ak503_trx_set_err: string;
    ak504_trx_set_err: string;
    ak505_trx_set_err: string;
    ak506_trx_set_err: string;
    ak901_func_ack_cd: string;
    ak902_sets: number;
    ak903_recvd_sets: number;
    ak904_accptd_sets: number;
    ak905_func_grp_err: string;
    ak906_func_grp_err: string;
    ak907_func_grp_err: string;
    ak908_func_grp_err: string;
    ak909_func_grp_err: string;
    alt_partner_id: string;
    batch_id: number;
    company_id: string;
    create_date: Date;
    direction: string;
    edi_error: number;
    error_descr: string;
    fa_trans_status: string;
    fa_transmitted: string;
    first_ak5_error: number;
    first_ak9_error: number;
    first_segment: number;
    func_ack_tid: number;
    id: string;
    last_ak5_error: number;
    last_ak9_error: number;
    last_segment: number;
    partner_id: string;
    processing_status: string;
    rec_trans_set_code: string;
    receiver_id: string;
    sender_id: string;
    st02_trxset_cntlno: string;
    trans_count: number;
    transaction_set: string;
    version: string;
}

