import { CityState, DataSource, Label, Layout, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutQuickSellDetails extends Layout {
    citystateStopConsignee: CityState;
    citystateStopOrigin: CityState;
    labelDistance: Label;
    labelMoveDistance: Label;
    labelOriginAppt: Label;
    panel1: Panel;
    panelDate: Panel;
    panelLeft: Panel;
    panelMain: Panel;
    panelRight: Panel;
    panelRightMain: Panel;
    sourceMovement: DataSource;
    textboxCommodity: Textbox;
    textboxPickDrop: Textbox;
    textboxStopOriginOrigSchedEarly: Textbox;
    textboxWeight: Textbox;
}
