import { CommonDialogs, CommonTooltips } from "@mcleod/common";
import {
    Component, ComponentSearcher, Image, Label, Layout, LayoutProps, Overlay, Panel, TableRow, TooltipOptions
} from "@mcleod/components";
import {
    Alignment, CurrencyUtil, DatePart, DateUtil, DisplayType, getAuthSettings, getLogger, ModelRow, StringUtil
} from "@mcleod/core";
import { CommodityQuickInfo } from "@mcleod/dispatch/src/CommodityQuickInfo";
import { ModelMovement } from "../../dispatch/src/models/ModelMovement";
import { BrokerageMovementTable } from "./BrokerageMovementTable";
import { BrokerageMovementTableType } from "./BrokerageMovementTableType";
import { CarrierRatings } from "./CarrierRatings";
import { ModelBrokerageStatus } from "./models/ModelBrokerageStatus";
import { RowBrokeragePlanningProfileFields } from "./models/autogen/AutogenModelBrokeragePlanningProfile";

interface IncludeExcludeList {
    isExclude: boolean;
    dataField: string;
    values: string[];
}

const log = getLogger("lme.powerbroker.BrokeragePlanningUtil");

export class BrokeragePlanningFilter {
    private filterRow: ModelRow;
    private includeLists: IncludeExcludeList[] = [];
    private orderStartDate: Date;
    private orderEndDate: Date;

    constructor(filterRow: ModelRow) {
        this.filterRow = filterRow;
        this.addIncludeExcludeList("revenue_code");
        this.addIncludeExcludeList("order_type");
        this.addIncludeExcludeList("order_customers", "customer_id");
        this.addIncludeExcludeList("order_trlr_type", "equipment_type");
        this.addIncludeExcludeList("operations_user");
        this.addIncludeExcludeList("brokerage_status");
        this.addIncludeExcludeList("dispatcher_user_id", "dispatcher");
        this.addIncludeExcludeList("order_salesperson", "salesperson");
        if (!filterRow.isNull("start_day"))
            this.orderStartDate = DateUtil.parseDateWithKeywords(filterRow.get("start_day"), true, false);
        if (!filterRow.isNull("order_days")) {
            const daysOutBaseDate = this.orderStartDate || new Date();
            this.orderEndDate = DateUtil.dateAdd(DatePart.DAY, daysOutBaseDate, parseInt(filterRow.get("order_days")));
        }
        log.debug("Created filter", this);
    }

    public dataPasses(data: ModelRow): boolean {
        log.debug("Checking data", data);
        for (const list of this.includeLists) {
            const listMatches = this.findItem(list.values, data.get(list.dataField));
            if ((list.isExclude && listMatches) || (!list.isExclude && !listMatches))
                return false;
        }
        if (!this.filterRow.getBoolean("preass_order", false) && !data.isNull("override_payee_id"))
            return false;
        if (!this.filterRow.getBoolean("show_ltl_orders", false) && data.getBoolean("ltl"))
            return false;
        if (!this.movementTypeMatches(data))
            return false;
        if (!this.inboundOutboundMatches(data))
            return false;
        if (!this.dateTimeMatches(data))
            return false;

        // unimplemented filters from Java

        // the filtering for hold orders is now handled on the bucket's filter because the hold bucket needs to ignore the profile setting
        // if (!this.filterRow.getBoolean("include_on_hold_orders", true) && data.getBoolean("hold", false))
        //   return false;

        // 	if (TreeResponsibilityHierarchy.isLicensed())
        // 	{
        // 		if(RowAgentAccessCtrl.getSingletonForCompany( data.getCompanyId() ).getBoolean("order_filter",false))
        // 		{
        // 			if (!isDataOkForUserAgencies(data.getResponsibility()))
        // 				return false;
        // 			if (!RowPlanningProfile.isDataOkForAgencies(getOrderResponsibilityList(), data.getResponsibility()))
        // 			{
        // 				return false;
        // 			}
        // 		}
        // 	}
        // if (deliveredMoveTooEarly(data) && !shouldDisplayUntilReadyToBill(data))
        //    return false;

        return true;
    }

    private movementTypeMatches(data: ModelRow): boolean {
        const moveTypeList = this.tokenizeField("movement_type", false, "|");
        switch (data.get("movement_type")) {
            case "TKLD":
                if (!this.findItem(moveTypeList, "T"))
                    return false;
                break;
            case "PDDL":
                if (!this.findItem(moveTypeList, "P"))
                    return false;
                break;
            case "LINE":
                if (!this.findItem(moveTypeList, "L"))
                    return false;
                break;
        }
        return true;
    }

    private inboundOutboundMatches(data: ModelRow): boolean {
        const orderIBList = this.tokenizeField("order_ib_zones", "Z" === this.filterRow.get("order_ib_type"));
        const orderOBList = this.tokenizeField("order_ob_zones", "Z" === this.filterRow.get("order_ob_type"));
        let inbound = this.findItem(orderIBList, this.getFilterValue(data, "ib", "origin"));
        let outbound = this.findItem(orderOBList, this.getFilterValue(data, "ob", "destination"));
        if (this.filterRow.getBoolean("order_ib_exclude", false))
            inbound = !inbound;
        if (this.filterRow.getBoolean("order_ob_exclude", false))
            outbound = !outbound;
        return inbound && outbound;
    }

    private dateTimeMatches(data: ModelRow) {
        let filterDate: Date;
        if (!this.filterRow.isNull("order_ib_zones") && !"*" === this.filterRow.get("order_ib_zones") &&
            (this.filterRow.isNull("order_ob_zones") || "*" === this.filterRow.get("order_ob_zones")))
            filterDate = data.get("destination_early");
        else
            filterDate = data.get("origin_early");
        if (filterDate == null)
            return false;
        if (typeof filterDate === "string") // bummer - was hoping this would have already been a date in the ModelRow
            filterDate = DateUtil.parseDate(filterDate);
        filterDate = DateUtil.justDate(filterDate);
        if (this.orderStartDate > filterDate || this.orderEndDate < filterDate)
            return false;
        return true;
    }

    private getFilterValue(data: ModelRow, inboundOutboundPart: "ib" | "ob", origDestPart: "origin" | "destination"): string {
        const type = this.filterRow.get(`order_${inboundOutboundPart}_type`);
        switch (type) {
            case "Z": return data.get(origDestPart + "_zone"); // zone isn't currently in the data
            case "S": return data.get(origDestPart + "_state");
            case "C": return data.get(origDestPart + "zip");
            case "L": return data.get(origDestPart + "_location_id"); // location_id isn't currently in the data
        }
        return null;
    }

    protected findItem(itemList: string[], item: string): boolean {
        if (itemList == null || itemList.length === 0 || "*" === itemList[0])
            return true;
        if (StringUtil.isEmptyString(item))
            return false;
        return itemList.indexOf(item) >= 0;
    }

    private addIncludeExcludeList(includeExcludeListFieldName: keyof RowBrokeragePlanningProfileFields, dataFieldName?: string, includeExcludeFlagFieldName?: string) {
        this.includeLists.push({
            isExclude: this.filterRow.getBoolean(includeExcludeFlagFieldName || includeExcludeListFieldName + "_exclude", false),
            values: this.tokenizeField(includeExcludeListFieldName, false, ","),
            dataField: dataFieldName || includeExcludeListFieldName
        });
    }

    private tokenizeField(field: keyof RowBrokeragePlanningProfileFields, useZoneParsing: boolean, delimiter: string = ","): string[] {
        if (this.filterRow.isNull(field))
            return [];
        const result = this.filterRow.get(field).split(delimiter);
        for (let i = 0; i < result.length; i++)
            result[i] = result[i].trim();
        return result;
    }

}


export function setTooltipCallbacks(comp: Component, modelRow: ModelRow) {
    if (comp.id == null) return;
    switch (comp.id) {
        case "panelNextStopLoc":
            return CommonTooltips.setTooltipFromLayoutCallback(comp, modelRow.get("next_stop_id"), "lme/powerbroker/BrokeragePlanningLocationQuickInfo", { minWidth: 128, minHeight: 140 });
        case "panelDestLoc":
            return CommonTooltips.setTooltipFromLayoutCallback(comp, modelRow.get("destination_stop_id"), "lme/powerbroker/BrokeragePlanningLocationQuickInfo", { minWidth: 128, minHeight: 140 });
        case "panelOriginLoc":
            return CommonTooltips.setTooltipFromLayoutCallback(comp, modelRow.get("origin_stop_id"), "lme/powerbroker/BrokeragePlanningLocationQuickInfo", { minWidth: 128, minHeight: 140 });
        case "textboxTotalCharge":
            return CommonTooltips.setPanelTooltipCallback(comp, () => [
                { caption: "Freight Charge", displayType: DisplayType.CURRENCY, text: CurrencyUtil.formatCurrency(modelRow.get("freight_charge")) },
                { caption: "Other Charges", displayType: DisplayType.CURRENCY, text: CurrencyUtil.formatCurrency(modelRow.get("other_charge_total")) }]
            );
        case "textboxTotalPay":
            return CommonTooltips.setPanelTooltipCallback(comp, () => [
                { caption: "Carrier Pay", displayType: DisplayType.CURRENCY, text: CurrencyUtil.formatCurrency(modelRow.get("override_pay")) },
                { caption: "Other Pay", displayType: DisplayType.CURRENCY, text: CurrencyUtil.formatCurrency(modelRow.get("other_pay")) }]
            );
        case "textboxMargin":
            return CommonTooltips.setPanelTooltipCallback(comp, () => [{ caption: "Margin Percent", text: modelRow.get("margin_percent") }]);
        case "panelCarrierEta":
            return CommonTooltips.setPanelTooltipCallback(comp, () => [
                { caption: "Last Callin Date", text: modelRow.get("last_callin_date"), displayType: DisplayType.DATETIME },
                { caption: "Last Callin City/State", text: modelRow.get("last_callin_loc") },
                { caption: "Last Callin Comment", text: modelRow.get("last_callin_comment") },
                { caption: "Last Callin User", text: modelRow.get("last_callin_user") },
                { caption: "Distance to Next Stop", text: modelRow.get("next_stop_miles") }
            ]);
        case "labelPlanningComment":
            return CommonTooltips.setTooltipCallback(comp, modelRow.get("planning_comment"));
        case "textboxPlanningComment":
            return CommonTooltips.setTooltipCallback(comp, modelRow.get("planning_comment"));
        case "labelCarrierName":
            return CommonTooltips.setTooltipFromLayoutCallback(comp, modelRow.get("carrier_id"), "lme/powerbroker/CarrierQuickInfo", { minWidth: 128, minHeight: 140 });
        case "labelLastCallinComment":
            return CommonTooltips.setTooltipCallback(comp, modelRow.get("last_callin_comment"));
        case "labelTrailer":
            return CommonTooltips.setTooltipFromLayoutCallback(comp, modelRow.get("equipment_type"), "lme/dispatch/TrailerTypeQuickInfo", { minWidth: 128, minHeight: 140 });
        case "labelCommodity":
            return setCommodityTooltipFromLayoutCallback(comp, modelRow, "lme/dispatch/CommodityQuickInfo", { minWidth: 128, minHeight: 140 });
        case "labelCustomerName":
            return CommonTooltips.setTooltipFromLayoutCallback(comp, modelRow.get("customer_id"), "lme/ar/CustomerQuickInfo", { minWidth: 128, minHeight: 140 });
        case "panelPickupCityState":
            return CommonTooltips.setTooltipFromLayoutCallback(comp, modelRow.get("origin_location_id"), "lme/dispatch/LocationQuickInfo", { minWidth: 128, minHeight: 140 });
        case "panelDelCityState":
            return CommonTooltips.setTooltipFromLayoutCallback(comp, modelRow.get("destination_location_id"), "lme/dispatch/LocationQuickInfo", { minWidth: 128, minHeight: 140 });
        case "labelOrigAppt":
            return CommonTooltips.setTooltipFromLayoutCallback(comp, modelRow.get("origin_appt_status"), "lme/general/AppointmentStatusQuickInfo", { minWidth: 128, minHeight: 50 });
        case "labelDestAppt":
            return CommonTooltips.setTooltipFromLayoutCallback(comp, modelRow.get("destination_appt_status"), "lme/general/AppointmentStatusQuickInfo", { minWidth: 128, minHeight: 50 });
        case "textboxCalcMaxPay":
            return CommonTooltips.setPanelTooltipCallback(comp, () => [{ caption: "Per Mile", displayType: DisplayType.CURRENCY, text: CurrencyUtil.formatCurrency(modelRow.get("max_per_mile")) }]);
        case "textboxTargetPay":
            return CommonTooltips.setPanelTooltipCallback(comp, () => [{ caption: "Per Mile", displayType: DisplayType.CURRENCY, text: CurrencyUtil.formatCurrency(modelRow.get("target_per_mile")) }]);
    }
}

export function setCommodityTooltipFromLayoutCallback(component: Component, idOrData: string | ModelRow, layoutName: string, layoutProps?: Partial<LayoutProps>, options?: Partial<TooltipOptions>) {
    if (idOrData == null) return;
    CommonTooltips.setTooltipCallback(component, () => {
        const layout = Layout.getLayout("lme/dispatch/CommodityQuickInfo", layoutProps) as CommodityQuickInfo;
        layout.addLayoutLoadListener(async event => {
            if (idOrData instanceof ModelRow) {
                await (event.target as Layout).mainDataSource.search({ id: idOrData.get("commodity_id") });
                layout.mainDataSource.rowIndex = 0;
                if (!idOrData.isNull("order_id")) {
                    await layout.sourceEquipMatchDetail.search({
                        _parent_link: {
                            model: "lme/dispatch/movement",
                            "orders.id": idOrData.get("order_id")
                        }
                    });
                    layout.panelLoadRequirements.visible = layout.sourceEquipMatchDetail.data.length > 0;
                }
            } else {
                (event.target as Layout).mainDataSource.search({ id: idOrData });
            }
        });
        return layout;
    }, options);
}

export function setRowComponentVisible(tableRow: TableRow, componentOrId: string | Component, fieldName: string, valueForVisible?: any) {
    if (fieldName != null) {
        const component = (typeof componentOrId === "string") ? tableRow?.findComponentById(componentOrId) : componentOrId;
        const fieldValue = tableRow.data?.get(fieldName);
        if (component != null) {
            component.visible = valueForVisible != null ? valueForVisible === fieldValue : fieldValue != null;
        }
    }
}

export function updateBrokerageStatus(brokerageStatusPanel: Panel, movementId: string, statusRow: ModelRow, brkMovementTable: BrokerageMovementTable) {
    const statusCode = statusRow.get("id");
    const tableRow = TableRow.getContainingTableRow(brokerageStatusPanel);
    brkMovementTable.tableMovements.selectedRow = tableRow;
    if (statusRow.getBoolean("display_callin_screen")) {
        postBrokerageStatusWithCallin(tableRow, movementId, statusRow, brkMovementTable);
    } else {
        postBrokerageStatusUpdate(movementId, statusCode, brokerageStatusPanel);
    }

    const triggerCode = getAuthSettings().dispatch_control[0].rating_trigger_code;
    if (!StringUtil.isEmptyString(triggerCode) && triggerCode === statusCode) {
        CarrierRatings.show({
            carrierRatingHeaderData: {
                order_id: tableRow.data.get("order_id"),
                movement_id: movementId,
                payee_id: tableRow.data.get("carrier_id")
            },
            carrier_name: tableRow.data.get("carrier_name")
        });
    }
}

export function postBrokerageStatusWithCallin(tableRow: TableRow, movementId: string, statusRow: ModelRow, brkMovementTable: BrokerageMovementTable) {
    const data = tableRow.data;
    const oldData = {
        brokerage_status_descr: data.get("brokerage_status_descr"),
        brokerage_status: data.get("brokerage_status"),
        brokerage_status_color: data.get("brokerage_status_color")
    };
    data.setValues({
        brokerage_status_descr: statusRow.get("descr"),
        brokerage_status: statusRow.get("id"),
        brokerage_status_color: statusRow.get("planning_color")
    });
    setupBrkStatusPanel(tableRow);
    brkMovementTable.toolsPanel.callinRequiredBrkStatusCode = statusRow;
    brkMovementTable.toolsPanel.findComponentById("buttonDispatch").clicked();
}

export function setupBrkStatusPanel(tableRow: TableRow) {
    const labelBrkStatus = tableRow?.findComponentById("labelBrkStatus") as Label;
    const brkStatusDesc = tableRow?.data?.get("brokerage_status_descr");
    const brkStatus = tableRow?.data?.get("brokerage_status");
    const panel = tableRow?.findComponentById("panelBrkStatus") as Panel;
    const imageBrokerageStatus = tableRow.findComponentById("imageBrokerageStatus") as Image;
    if (imageBrokerageStatus?.busy === true)
        imageBrokerageStatus.busy = false;
    if (panel != null) {
        const bgColor = tableRow.data.get("brokerage_status_color");
        if (bgColor === "#ffffff") {
            panel.borderWidth = 1;
            panel.borderColor = "primary";
            panel.backgroundColor = bgColor;
            labelBrkStatus.color = "primary";
            if (imageBrokerageStatus) {
                imageBrokerageStatus.color = "primary";
            }
        } else {
            panel.borderWidth = 0;
            panel.backgroundColor = bgColor;
            labelBrkStatus.color = "default.reverse";
            if (imageBrokerageStatus) {
                imageBrokerageStatus.color = "default.reverse";
            }
        }
        labelBrkStatus.caption = brkStatus || "--";

        if (brkStatusDesc != null)
            CommonTooltips.setTooltipCallback(panel, brkStatusDesc);
        else {
            labelBrkStatus.color = "default";
            panel.borderWidth = 1;
            panel.borderColor = "subtle";
            panel.tooltip = "Click the drop down to set the brokerage status code";
            if (imageBrokerageStatus) {
                imageBrokerageStatus.color = "subtle";
            }
        }
    }
}

export function tableSearcherCreationCallback(): ComponentSearcher[] {
    return [
        new ComponentSearcher("brokerage_status"),
        new ComponentSearcher("brokerage_status_descr")
    ];
}

export function postBrokerageStatusUpdate(movementId: string, statusCode: string, brokerageStatusPanel: Panel) {
    const brkStatusImg = brokerageStatusPanel.findComponentById("imageBrokerageStatus") as Image;
    if (brkStatusImg != null)
        brkStatusImg.busy = true;
    new ModelMovement().searchSingle({ id: movementId }, "lme/dispatch/Movement").then((movementRow) => {
        movementRow.set("brokerage_status", statusCode);
        movementRow.post().then(row => {
            log.debug("movement posted");
        }).catch(reason => {
            CommonDialogs.showError(reason);
        }).finally(() => {
            if (brkStatusImg != null)
                brkStatusImg.busy = false;
        });
    });
}

export function populateAndDisplayBrkStatusDropDowns(panel: Panel, movementId: string, brkMovementTable: BrokerageMovementTable) {
    const dropDownItems = [];
    new ModelBrokerageStatus().search({ movement_id: movementId }).then(result => {
        if (result?.modelRows?.length > 0) {
            result.modelRows.forEach(row => {
                dropDownItems.push({
                    caption: row.get("descr", "id"), onClick: () => updateBrokerageStatus(panel, movementId, row, brkMovementTable)
                });
            });
        }
    }).then(() => {
        panel["statusDropDownItems"] = dropDownItems;
        displayBrkStatusDropDowns(panel);
    });
}

export function displayBrkStatusDropDowns(panel: Panel) {
    const dropDownItems = panel["statusDropDownItems"];
    if (dropDownItems != null) {
        Overlay.showDropdown(panel, dropDownItems, null, { width: 225 }, {
            align: Alignment.LEFT,
            onClose: () => {
                panel.borderShadow = false;
            }
        });
    }
}

// START - Minute updater stuff
/**
 * Returns the component that the late functionality will be applied to.
 * @param tableRow The TableRow the component is on.
 * @param stop Determines if this is for the origin or destination.
 * @param type The table type.
 * @returns
 */
export function getLateDateComp(tableRow: TableRow, stop: "origin" | "destination", type: BrokerageMovementTableType) {
    if (BrokerageMovementTableType.PERISCOPE === type) {
        return tableRow.findComponentById(stop === "origin" ? "panelPickupDate" : "panelDelDate") as Panel;
    }
    else {
        return tableRow.findComponentById(stop === "origin" ? "badgeOriginLateIn" : "badgeDestinationLateIn") as Label;
    }
}

/**
 * Returns the stop's scheduled arrival time as a string with the timezone.
 * @param row The TableRow the data is in.
 * @param stop Determines if this is for the origin or destination.
 * @returns
 */
export function getStopTime(row: ModelRow, stop: "origin" | "destination"): string {
    if (row == null)
        return null;

    let time = row.get(`${stop}_late`);
    if (time == null)
        time = row.get(`${stop}_early`);

    if (StringUtil.isEmptyString(time))
        return null;

    const tz = row.get(`${stop}_timezone`);
    return time + (tz != null ? (" " + tz) : "");
}

/**
 * This function gets called on every tab/bucket with every minute change.  Updates to the TableRow should be called from updateTableRow(...).
 * @param table
 */
export async function tableMinuteUpdate(table: BrokerageMovementTable) {
    table?.tableMovements.rows.forEach(row => rowMinuteUpdate(row, table.type))
}

/**
 * This function will get called when the time's minute changes.  It also gets called from the TableRow's onRowDisplay event.
 * Call functions that need to happen with the time's minute change here.
 * @param tableRow The TableRow that will be effected.
 * @param type The table type / bucket.
 */
export function rowMinuteUpdate(tableRow: TableRow, type: BrokerageMovementTableType) {
    if (!tableRow.populatedDOM)
        return
    updateLateBadge(tableRow, "origin", type);
    updateLateBadge(tableRow, "destination", type);
    updatePendingBadge(tableRow, type);
}

/**
 * Updates the badge the listener is tied to.
 * @param badgeListener The listener that contains the badge data.
 */
function updateLateBadge(tableRow: TableRow, stop: "origin" | "destination", type: BrokerageMovementTableType) {
    const comp = getLateDateComp(tableRow, stop, type);

    if (comp == null || [BrokerageMovementTableType.DELIVERED].includes(type))
        return;

    const isOrigin = "origin" === stop;

    // if there is an actual arrival, we no long need late badges or styling
    if (tableRow.data == null || (tableRow.data as ModelRow).get(`${stop}_actual_arrival`)) {
        if (BrokerageMovementTableType.PERISCOPE === type) {
            comp.backgroundColor = "";
            comp.fontBold = false;
        }
        else {
            comp.visible = false;
        }
        return;
    }

    const dispatchControl = getAuthSettings().dispatch_control[0];
    const warning = isOrigin ? dispatchControl.pickup_warning : dispatchControl.delivery_warning;
    const diff = getTimeDiff(new Date(getStopTime(tableRow?.data, stop)));

    if (diff == null) return;
    if (diff > (warning * 60 * 60 * 1000)) {
        if (BrokerageMovementTableType.PERISCOPE === type) {
            comp.backgroundColor = "";
            return;
        }
        else {
            comp.visible = false;
            return;
        }
    }
    const isLate = diff < 0;
    comp.backgroundColor = isLate ? "error" : "warning";
    if (BrokerageMovementTableType.PERISCOPE === type) {
        comp.fontBold = true;
        if (!isLate)
            comp.tooltip
    }
    else {
        (comp as Label).caption = isLate ? "LATE" : getLateBadgeCaption(diff);
        comp.visible = true;
    }
    comp.color = isLate ? "error.reverse" : "warning.reverse";
    comp.tooltip = isLate ? `This movement's ${isOrigin ? "pickup" : "delivery"} time has passed` :
        BrokerageMovementTableType.PERISCOPE === type ? getLateBadgeCaption(diff) : null;
}

export function getLateBadgeCaption(millis: number): string {
    const hh = Math.floor(millis / 1000 / 60 / 60);
    millis -= hh * 1000 * 60 * 60;
    const mm = Math.ceil(millis / 1000 / 60);
    let result = "Late in";

    // Set the hours
    if (mm == 60 && hh == 0) result += " 1 hr";
    else if (mm == 60) result += ` ${hh + 1} hrs`;
    else if (hh > 0) {
        result += ` ${hh} hr`
        if (hh > 1) result += "s";
    }

    // Set the minutes
    if (mm < 60 && mm > 0) {
        result += " ";
        if (hh > 0 && mm.toString().length == 1) result += "0";
        result += `${mm} min`;
        if (mm > 1) result += "s";
    }
    return result;
}

function updatePendingBadge(tableRow: TableRow, type: BrokerageMovementTableType) {
    const badge = tableRow.findComponentById("badgePending");
    const unlockTime = tableRow.data.get("unlock_time");

    if (BrokerageMovementTableType.AVAILABLE !== type || badge == null || unlockTime == null)
        return;
    const minutesUntilLate = Math.ceil(getTimeDiff(new Date(unlockTime)) / (1000 * 60) % 60);
    if (minutesUntilLate <= 5) {
        badge.backgroundColor = "error";
    }
}

/**
 * Returns the difference in time from the given time to the current time (relative to user's timezone).
 * Returned number is in milliseconds.
 * If the returned value is negative, it means the given time has passed.
 * @param date The time to compare against.
 * @returns
 */
export function getTimeDiff(date: Date): number {
    return date.getTime() - new Date().getTime();
}
// END - Minute updater stuff

export enum MovementStatus {
    AVAILABLE = "A",
    COVERED = "C",
    PROGRESS = "P",
    DELIVERED = "D"
}
