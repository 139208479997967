import { ClickEvent } from "@mcleod/components";
import { ModelRow } from "@mcleod/core";
import { AutogenLayoutLocationManualAdd } from "./autogen/AutogenLayoutLocationManualAdd";

export class LocationManualAdd extends AutogenLayoutLocationManualAdd {
    override onLoad() {
        this.citystate1.textCombined.onSelectItem = (textbox, selectedItem) => {
            textbox.tooltipCallback = null;
            return undefined;
        }
    }

    /** This is an event handler for the onClick event of buttonUseThisAddress.  */
    buttonUseThisAddressOnClick(event: ClickEvent) {
        const modelRow = new ModelRow("lme/dispatch/location", false, null);

        if (this.textboxCompanyName.text) {
            modelRow.set("name", this.textboxCompanyName.text);
        }
        if (this.textboxStreetName.text) {
            modelRow.set("address1", this.textboxStreetName.text);
        }
        const cityStateData = this.citystate1.textCombined.getFirstLookupModelData()?.data;
        if (cityStateData) {
            modelRow.set("city_name", cityStateData["name"]);
            modelRow.set("state", cityStateData["state_id"]);
            modelRow.set("zip_code", cityStateData["zip_code"]);
            modelRow.set("city_id", cityStateData["id"]);
        }
        this.mainDataSource.data = [modelRow];
    }
}
