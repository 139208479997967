import { CommonTooltips } from "@mcleod/common";
import { Component, TableRow, TableRowDisplayEvent } from "@mcleod/components";
import { CurrencyUtil, DisplayType, ModelRow } from "@mcleod/core";
import { AutogenLayoutLaneHistoryTable } from "./autogen/AutogenLayoutLaneHistoryTable";

export class LaneHistoryTable extends AutogenLayoutLaneHistoryTable {
    tableLaneHistoryOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.getTableRow();
        const modelRow = tableRow.data;
        tableRow.forEveryChildComponent(comp => {
            this.setTooltipCallbacks(comp, modelRow);
        });
    }

    setTooltipCallbacks(comp: Component, modelRow: ModelRow) {
        if (comp.id == null) return;
        switch (comp.id) {
            case "labelCarrierName":
            case "labelCarrierNamePrimary":
            case "labelCarrierNameSecondary":
            case "labelCarrierNameThird":
                return CommonTooltips.setTooltipFromLayoutCallback(comp, modelRow.get("override_payee_id"), "lme/powerbroker/CarrierQuickInfo", { minWidth: 128, minHeight: 140 });
            case "labelCustomerName":
            case "labelCustomerNamePrimary":
                return CommonTooltips.setTooltipFromLayoutCallback(comp, modelRow.get("customer.id"), "lme/ar/CustomerQuickInfo", { minWidth: 128, minHeight: 140 });
            case "labelTotalCharge":
                return CommonTooltips.setPanelTooltipCallback(comp, () => [
                    { caption: "Freight Charge", displayType: DisplayType.CURRENCY, text: CurrencyUtil.formatCurrency(modelRow.get("orders.freight_charge")) },
                    { caption: "Other Charges", displayType: DisplayType.CURRENCY, text: CurrencyUtil.formatCurrency(modelRow.get("orders.otherchargetotal")) }]
                );
            case "labelTotalPay":
                return CommonTooltips.setPanelTooltipCallback(comp, () => [
                    { caption: "Carrier Pay", displayType: DisplayType.CURRENCY, text: CurrencyUtil.formatCurrency(modelRow.get("override_pay_amt")) },
                    { caption: "Other Pay", displayType: DisplayType.CURRENCY, text: CurrencyUtil.formatCurrency(modelRow.get("other_pay")) }]
                );
        }
    }
}
