import { Api } from "@mcleod/core";
import { InitiateBrokerageTracking } from "./InitiateBrokerageTracking";

const TYPE_BOTH = "BOTH";
const TYPE_BOL = "BILL_OF_LADING";
const TYPE_PHONE = "MOBILE_PHONE_NUMBER";
const TYPE_TRACTOR = "VEHICLE_ID";
const TYPE_ORDER = "ORDER";

export class P44Util {
    trackingResponse: String;
    layout: InitiateBrokerageTracking;

    constructor(layout: InitiateBrokerageTracking) {
        this.layout = layout;
    }

    public async setP44RequiredLabel(type: string): Promise<any> {
        const carrierName = this.layout.mainDataSource.activeRow.get("payee.name");

        this.layout.labelTrackingNote.color = "default";
        this.layout.labelTrackingNote.caption = "Tracking Note: Carrier " + carrierName + " requires "
            + this.getRequiredTypeValue(type);

        if (type === TYPE_TRACTOR) {
            this.layout.textboxCarrierTractor.required = true;

            this.getCarrierVehicles(this.layout.mainDataSource.activeRow.get("id"), this.layout.mainDataSource.activeRow.get("override_payee_id"));
        }
    }

    public async getTrackingMethods(movementId: string): Promise<any> {
        return await Api.post("lme/powerbroker/p44-get-tracking-methods", { movement_id: movementId })
            .then(response => {
                this.trackingResponse = response?.data[0]?.message;
                return response
            });
    }

    public getCarrierVehicles(movementId: string, carrierId: string) {
        Api.post("lme/powerbroker/p44-update-carrier-vehicles",
            {
                movement_id: movementId,
                carrier_id: carrierId
            });
    }

    public getRequiredTypeValue(type: string): string {
        if (type === TYPE_BOTH) return "either a BOL number or an Order ID";
        else if (type === TYPE_BOL) return "a BOL number";
        else if (type === TYPE_ORDER) return "an Order ID";
        else if (type === TYPE_PHONE) return "a driver mobile number";
        else if (type === TYPE_TRACTOR) return "a Vehicle ID";
        else return "";
    }
}
