import { Button, ClickEvent, DataDisplayEvent, DataSource, DataSourceExecutionEvent, Label, Panel, TableRow, TableRowDisplayEvent } from "@mcleod/components";
import { Api, ModelRow } from "@mcleod/core";
import { CarrierOfferCounter, CounterOfferSummaryInfo } from "@mcleod/powerbroker/src/CarrierOfferCounter";
import { OfferTabEvent, OfferTabEventAction, OfferTabType } from "./MovementOfferTabs";
import { OfferSource, OfferUtil } from "./OfferUtil";
import { AutogenLayoutCounterOffers } from "./autogen/AutogenLayoutCounterOffers";
import { ModelMovement } from "./models/ModelMovement";

export class CounterOffers extends AutogenLayoutCounterOffers {
    private offerUtil: OfferUtil;
    private moveRow: ModelRow;
    public summaryInfo: CounterOfferSummaryInfo;
    public onTabEvent: (offerTabEvent: OfferTabEvent) => void = () => { };

    private sendTabEvent(offerTabEvent: OfferTabEvent) {
        this.onTabEvent(offerTabEvent);
    }

    public initializeOfferUtil(movementID: string, moveRow: ModelRow) {
        this.offerUtil = new OfferUtil(movementID, moveRow, this);
    }

    public async initialize(summaryInfo: CounterOfferSummaryInfo, sourceMovement: DataSource) {
        this.summaryInfo = summaryInfo;
        this.sourceMovement = sourceMovement;
        this.moveRow = sourceMovement.activeRow;
        this.initializeOfferUtil(this.moveRow.get("id"), this.moveRow);
        await this.search();
    }

    public async setMovementRow() {
        this.moveRow = await new ModelMovement().searchSingle({ id: this.moveRow.get("id") }, "lme/dispatch/Movement");
    }

    public async search() {
        await this.sourceCarrierOffer.search({ "movement_id": this.moveRow.get("id"), "status": "O" });
        this.sendTabEvent({ type: OfferTabType.COUNTER, action: OfferTabEventAction.SEARCH });
    }

    private showCounterOfferSlideout(tableRow: TableRow) {
        CarrierOfferCounter.showCounterOfferSlideout({
            summaryInfo: this.summaryInfo,
            data: tableRow.data,
            doAfterSlideIn: (layout) => this.doAfterCounterOfferSlideoutLoaded(layout, tableRow.index),
            onSave: () => this.counterOfferSlideoutOnSave()
        });
    }

    private async doAfterCounterOfferSlideoutLoaded(layout: CarrierOfferCounter, tableRowIndex: number) {
        layout.setupCarrierInfo(this.activeRow.get("payee_id", null), this.moveRow.get("is_carrier_scorecard_licensed", false));
        layout.layoutMovementCarrierPay.displayData(this.moveRow, null, 0);
    }

    private counterOfferSlideoutOnSave() {
        this.search();
    }

    // Component Events
    buttonAssignCarrierOnClick(event: ClickEvent) {
        this.offerUtil.assignCarrierEvent(event);
    }

    buttonDeclineCounterOnClick(event: ClickEvent) {
        const offerId = TableRow.getContainingTableRow(event.target as Button).data.get("id");
        Api.post("lme/powerbroker/decline-counter-offer", { offer_id: offerId });
    }

    buttonCounterOfferOnClick(event: ClickEvent) {
        const buttonCounterOffer = event.target as Button;
        const tableRow = TableRow.getContainingTableRow(buttonCounterOffer) as TableRow;
        this.showCounterOfferSlideout(tableRow);
    }

    tableOffersOnRowDisplay(event: TableRowDisplayEvent) {
        this.offerUtil.tableOffersOnRowDisplay(event);

        const tableRow = event.getTableRow();
        const data = tableRow.data as ModelRow;
        const status = data.get("status");
        const isCountered = status == "O";
        const buttonCounterCounter = tableRow.findComponentById("buttonCounterOffer") as Button;
        const buttonDeclineCounter = tableRow.findComponentById("buttonDeclineCounter") as Button;
        const buttonAssignCarrier = tableRow.findComponentById("buttonAssignCarrier") as Button;
        const panelStatus = tableRow.findComponentById("panelStatus") as Panel;
        buttonCounterCounter.visible = isCountered;
        buttonDeclineCounter.visible = isCountered;
        buttonAssignCarrier.visible = ['I', 'E', 'O'].includes(status);
        this.offerUtil.setOfferStatusLabel(status, panelStatus);
    }

    showTooltipOnDataDisplay(event: DataDisplayEvent) {
        this.offerUtil.showTooltipOnDataDisplay(event);
    }

    labelStatusOnDataDisplay(event: DataDisplayEvent) {
        const label = event.target as Label;
        const panel = label.parent as Panel;
        const tableRow: TableRow = TableRow.getContainingTableRow(label);
        this.offerUtil.setOfferStatusLabel(tableRow.data.get("status"), panel);
    }

    sourceCarrierOfferAfterExecution(event: DataSourceExecutionEvent) {
        this.offerUtil.sortOffers(OfferSource.BLAST);
    }
}
