import { DataSource, Layout, Tab, Table, Tabset } from "@mcleod/components";

export abstract class AutogenLayoutCommentsAndReferenceNumbers extends Layout {
    sourceEdiRefNumber: DataSource;
    sourceEdiStop: DataSource;
    sourceEdiStopNote: DataSource;
    tabStopComments: Tab;
    tabStopRefNumbers: Tab;
    tableStopComments: Table;
    tableStopReferenceNumbers: Table;
    tabsetCommentsRefNos: Tabset;
}
