import { Api, StringUtil } from ".";

export interface ProcessLockResult {
    success: boolean,
    message: string
}

export class ProcessLock {
    public static async aquireLock(type: string, key: string): Promise<ProcessLockResult> {
        const response = await Api.post("process-lock", { type: type, key: key });
        return ProcessLock.createLockResult(response);
    }

    public static async releaseLock(type: string, key: string): Promise<ProcessLockResult> {
        const response = await Api.delete("process-lock", { type: type, key: key });
        return ProcessLock.createLockResult(response);
    }

    private static createLockResult(response: any) {
        const message = response?.data[0]?.message;
        return {
            success: StringUtil.isEmptyString(message) === true,
            message: message
        }
    }
}