/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelEdiUser.ts                       *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/datafusion/edi-user";

export class AutogenModelEdiUser<RowType extends ModelRow> extends Endpoint<RowType, RowEdiUserFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowEdiUser extends ModelRow<RowEdiUserFields> {
    constructor(values?: Partial<RowEdiUserFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowEdiUserFields {
    company_id: string;
    dbd_color: number;
    id: string;
    if_color: number;
    ltexpress_prof_id: string;
    whl_color: number;
}

