import { Button, ButtonVariant, ClickEvent, KeyEvent } from "@mcleod/components";
import { Keys, Navigation } from "@mcleod/core";
import { AutogenLayoutMovementSearch } from "./autogen/AutogenLayoutMovementSearch";

export class MovementSearch extends AutogenLayoutMovementSearch {
    private buttonDetail: Button;

    onLoad() {
        this.buttonDetail = new Button({ variant: ButtonVariant.round, rowBreak: false, color: "primary", imageWidth: 20, imageHeight: 20, enabled: false, imageName: "detail", hotkey: "Alt-D", tooltip: "Detail the selected row" });
        this.buttonDetail.addClickListener(event => this.showDetail());
        this.tableMovements.addTool(this.buttonDetail);
    }

    showDetail() {
        const key = this.sourceMovement.activeRow.get("id");
        Navigation.navigateTo("lme/dispatch/Movement?mode=update&key=" + key);
    }

    /** This is an event handler for the onDblClick event of tableMovements.  */
    tableMovementsOnDblClick(event: ClickEvent) {
        this.showDetail();
    }

    /** This is an event handler for the onKeyDown event of tableMovements.  */
    tableMovementsOnKeyDown(event: KeyEvent) {
        if (event.key === Keys.ENTER && this.tableMovements.selectedIndexes.length === 1) {
            this.showDetail();
            event.stopPropagation();
        }
    }

    /** This is an event handler for the onSelect event of tableMovements.  */
    tableMovementsOnSelect(event) {
        const sel = this.tableMovements.selectedRows.length === 1;
        this.buttonDetail.enabled = sel;
        const selectedRowDataSourceIndex = this.tableMovements.selectedAllRowsIndex;
        if (selectedRowDataSourceIndex !== -1)
            this.sourceMovement.setRowIndexWithoutUpdate(selectedRowDataSourceIndex);
    }
}
