import { DataSource, Label, Layout, Table } from "@mcleod/components";

export abstract class AutogenLayoutFreightGroupItemCompare extends Layout {
	labelAddsAndDeletes: Label;
	labelChanges: Label;
	sourceEdiCompareAddDeleteItem: DataSource;
	sourceEdiCompareChangeItem: DataSource;
	tableFreightGroupItemAdd: Table;
	tableFreightGroupItemChange: Table;
}
