import { DataSource, Label, Layout, Panel } from "@mcleod/components";

export abstract class AutogenLayoutNoNextCheckCall extends Layout {
    labelCovered: Label;
    labelCoveredCheckCalls: Label;
    labelInProgress: Label;
    labelOverallCheckCalls: Label;
    labelProgressCheckCalls: Label;
    labelTotal: Label;
    panel1: Panel;
    panel2: Panel;
    panel3: Panel;
    sourceNoNextCheckCall: DataSource;
}
