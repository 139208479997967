import { CityState, DataSource, Label, Layout, Location, Panel, Tab, Tabset, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutEdiStopShowAs extends Layout {
	citystateShowAs: CityState;
	labelCreatingLocation: Label;
	labelDevInfo: Label;
	labelShowAs: Label;
	locationShowas: Location;
	panelShowAs: Panel;
	sourceEdiStop: DataSource;
	tabShowAs: Tab;
	tabStop: Tab;
	tabsetShowAs: Tabset;
	textboxShowasAddress2: Textbox;
	textboxShowasAddress: Textbox;
	textboxShowasLocationId: Textbox;
	textboxShowasLocationName: Textbox;
}