/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelDispatchControl.ts               *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/general/dispatch-control";

export class AutogenModelDispatchControl<RowType extends ModelRow> extends Endpoint<RowType, RowDispatchControlFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowDispatchControl extends ModelRow<RowDispatchControlFields> {
    constructor(values?: Partial<RowDispatchControlFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowDispatchControlFields {
    allow_blank_dot: string;
    allow_driver_modification: string;
    allow_dup_contain: string;
    allow_loaded_borrow: string;
    allow_macropoint: string;
    allow_none_dot: string;
    allow_overrides: string;
    allow_reserved_movements: string;
    allow_split_avail: string;
    apply_feasibility: string;
    appt_confirmation: string;
    ask_dist_center: string;
    attempted_pickup_hdr_uid: number;
    auto_avail_tractor: string;
    auto_avltract_stat: string;
    auto_carrier_call: string;
    auto_check_call: string;
    auto_home_callin: string;
    auto_home_driverdt: string;
    auto_home_radius: number;
    auto_home_starthrs: number;
    auto_mark_dray: string;
    auto_mile_lookup: string;
    auto_next_lock: string;
    auto_pay_rates: string;
    auto_rate: string;
    average_speed: number;
    average_speed_um: string;
    balance_map_colors: string;
    balance_map_days_out: number;
    balance_map_height: number;
    balance_map_profile: string;
    balance_map_width: number;
    bill_template_id: number;
    blanket_buy_tariff_id: string;
    blanket_sell_tariff_id: string;
    bol_processing_screen: string;
    borrow_option: string;
    borrowed_trailer_sequence: string;
    break_hours: number;
    brk_qual_profile: string;
    brk_quote_template: number;
    brokerage_workflow_id: string;
    cache_days: number;
    calc_weight_from_tli_tariff: string;
    calculate_fuel_surchrge_pay: string;
    call_in_number: string;
    canada_break_hours: number;
    canada_driving_hours: number;
    canada_onduty_hours: number;
    carr_contact_email: string;
    carrier_assignment_prompt: string;
    carrier_authority: string;
    carrier_perf_email_address: string;
    carrier_select_expand_all: string;
    carrier_statistics_end: string;
    carrier_statistics_start: string;
    chas_pool_template: number;
    check_call_warning: number;
    choice_driver_types: string;
    claim_ack_template: number;
    closer_user_id: string;
    company_chassis_location_id: string;
    company_id: string;
    conf_cntr_template: number;
    conf_fax_template: number;
    conf_template: number;
    cont_del_tmpl_id: number;
    cont_pool_template: number;
    container: string;
    continuous_radius: number;
    copy_move_loaded: string;
    create_avail_tract: string;
    create_empty_batch: string;
    create_location_from_stop: string;
    credit_warning_pct: number;
    days_out: number;
    days_to_expire: number;
    deadhead_target: number;
    def_avltract_stat: string;
    def_incl_bfg_on_ltl_quotes: string;
    def_load_to_radius: number;
    def_loc_radius: number;
    def_move_status: string;
    def_quote_status: string;
    default_buy_tariff_id: string;
    default_callin_script_templ: string;
    default_charge_tariff: string;
    default_cost_tariff: string;
    default_counter_resp_time: number;
    default_exp_date: string;
    default_fleet: string;
    default_free_days: number;
    default_load_search: string;
    default_loadtime: number;
    default_posting_user_role: string;
    default_rev_share_view_days: number;
    default_schedule: string;
    default_sell_tariff_id: string;
    default_unloadtime: number;
    default_view_days: number;
    default_waterfall_resp_time: number;
    del_template_id: number;
    delivery_warning: number;
    dimension_surchg: string;
    directions_disclaimer: string;
    dispatch_by: string;
    display_msgs_when: string;
    display_trailr_type_warning: string;
    dm_last_dist_1: number;
    dm_last_dist_2: number;
    dm_last_dist_3: number;
    do_not_call_notice: string;
    doctype_cargo_ins: number;
    doctype_hazmat_cert: number;
    doctype_liab_ins: number;
    doctype_workmans_comp: number;
    dot_safety_rating: string;
    dr_on_ltl_bill: string;
    dray_del_co_deduct: string;
    dray_del_co_earn: string;
    dray_del_oo_deduct: string;
    dray_del_oo_earn: string;
    dray_disp_drop: string;
    dray_disp_return: string;
    dray_pu_co_deduct: string;
    dray_pu_co_earn: string;
    dray_pu_oo_deduct: string;
    dray_pu_oo_earn: string;
    driver_assignments: string;
    driver_days1_color: number;
    driver_days2_color: number;
    driver_home: string;
    driver_hours: string;
    driverhours_format: string;
    driving_hours: number;
    dspflag: string;
    dup_bol: string;
    duplicate_carrier_inv_check: string;
    edi_revenue_id: string;
    eforms_qual_profile: string;
    eforms_to_address: string;
    empty_check_call: string;
    enable_authorized: string;
    enable_auto_home: string;
    enable_customer_add_google: string;
    enable_empty_dist_override: string;
    enable_estimated_pay: string;
    enable_google_places: string;
    enable_location_add_google: string;
    enable_manifesting: string;
    enable_prorated_revenue: string;
    enable_remote_driver_choice: string;
    enable_slp: string;
    enable_stop_add_google: string;
    enforce_billto: string;
    enforce_brokerage_workflow: string;
    enforce_carrier_oos: string;
    enforce_contract: string;
    enforce_credit: string;
    enforce_hazmat_exp: string;
    enforce_inter_cont: string;
    enforce_max_pay: string;
    enforce_preassign_regs: string;
    enforce_prohibited_carriers: string;
    enforce_regulatory: string;
    enforce_revenu_cod: string;
    enforce_shipper_id: string;
    enforce_stop_dates: string;
    enforce_target_pay: string;
    enforce_void_hist: string;
    enforce_work_comp: string;
    equip_requirement: string;
    equipment_match: string;
    est_fuel_surcharge: string;
    exclude_dedicated_choice: string;
    exclude_highvalue_choice: string;
    exclude_order_types: string;
    exclude_ordertypes_choice: string;
    exclude_revenue_code_choice: string;
    exclude_revenue_codes: string;
    feasibility_dwell_hithresh: number;
    feasibility_dwell_lothresh: number;
    feasibility_mph: number;
    feasibility_prompt_avail_hr: string;
    feasibility_rate_basis: string;
    feasibility_zmit_min_time: number;
    fill_chassis_values: string;
    filtered_brokerage_codes: string;
    findnear_method: string;
    fms_friday_close: Date;
    fms_friday_open: Date;
    fms_monday_close: Date;
    fms_monday_open: Date;
    fms_saturday_close: Date;
    fms_saturday_open: Date;
    fms_sunday_close: Date;
    fms_sunday_open: Date;
    fms_thursday_close: Date;
    fms_thursday_open: Date;
    fms_tuesday_close: Date;
    fms_tuesday_open: Date;
    fms_wednesday_close: Date;
    fms_wednesday_open: Date;
    freeastray_customer_id: string;
    friday_close: Date;
    friday_open: Date;
    fuel_distance: number;
    goal_margin: number;
    goal_marginpercent: number;
    goal_momentum: number;
    goal_ontime: number;
    goal_revmile: number;
    goal_velocitydistance: number;
    goal_velocityrevenue: number;
    google_api_key: string;
    google_places_entry_delay: number;
    grace_period: number;
    hazmat_cer_isblank: string;
    hazmat_contract: string;
    hazmat_exp_isblank: string;
    hazmat_phone: string;
    hazmat_placard_prompt: string;
    id: number;
    incl_inspect_time: string;
    incl_refuel_time: string;
    include_comments: string;
    include_dedicated_choice: string;
    include_highvalue_choice: string;
    include_inactive_pnn_oo: string;
    include_inactive_pnn_wt: string;
    ins_exp_templateid: number;
    ins_warning_days: number;
    inspect_time: number;
    is_container_active: string;
    is_intercompany: string;
    is_physical_trailer: string;
    last_loc_type: string;
    level_warn: string;
    level_warning_carrier_inv: string;
    load_accept: string;
    lock_brok_status: string;
    lock_clock_minutes: number;
    ltl_autoplan_over_capacity: string;
    ltl_autoroute_over_capacity: string;
    ltl_blink_leadtime: number;
    ltl_blink_pickups: string;
    ltl_brk_bol_billing_terms: string;
    ltl_calculate_service: string;
    ltl_carrier_brk_status: string;
    ltl_class_warning: string;
    ltl_clear_crossdock_when: string;
    ltl_cubic_greater_than: string;
    ltl_cubic_units_warning: number;
    ltl_customer_pickup: string;
    ltl_default_carrier_id: string;
    ltl_default_grid_capacity: number;
    ltl_default_open_close: string;
    ltl_del_routing_refresh: number;
    ltl_deliveries_by_linehaul: string;
    ltl_density_greater_than: string;
    ltl_density_warning: number;
    ltl_dispatch_refresh: number;
    ltl_enable_linehaul_warn_bl: string;
    ltl_enable_linehaul_warning: string;
    ltl_enable_longhaul_peddles: string;
    ltl_enable_optimize_route: string;
    ltl_enable_seats: string;
    ltl_force_clear: string;
    ltl_freight_dimension_uom: string;
    ltl_high_volume_spots: number;
    ltl_high_volume_weight: number;
    ltl_high_volume_weight_um: string;
    ltl_icon_deliveries: string;
    ltl_icon_pickups: string;
    ltl_include_pickup_day: string;
    ltl_lh_routing_refresh: number;
    ltl_map_all_moves_threshold: number;
    ltl_operate_partner_move_as: string;
    ltl_optimize_delivery_pct: number;
    ltl_optimize_time_per_spot: number;
    ltl_optimize_use_appts: string;
    ltl_persist_zero_amt_revs: string;
    ltl_pro_allow_duplicates: string;
    ltl_pro_luhn_check: string;
    ltl_pro_max_length: number;
    ltl_pro_min_length: number;
    ltl_pro_mod7_check: string;
    ltl_pu_warn_handled_stops: string;
    ltl_req_reason_service: string;
    ltl_return_frt_to_default: string;
    ltl_route_needing_del_appt: string;
    ltl_route_needing_pu_appt: string;
    ltl_transit_day_rounding: string;
    ltl_trip_aware_prompt_by: string;
    maint_a_miles: number;
    maint_a_range: number;
    maint_a_range_um: string;
    maint_b_miles: number;
    maint_b_range: number;
    maint_b_range_um: string;
    mandatory_carrier_ratings: string;
    max_findnear_rows: number;
    max_loads_per_tractor: number;
    max_pay_percent: number;
    max_preassigns: number;
    max_rev_dist_rows: number;
    maximum_drivers: number;
    maximum_trailers: number;
    mc_distance_hours: number;
    message_reply_to: string;
    minimum_ranking_1_star: number;
    minimum_ranking_2_star: number;
    minimum_ranking_3_star: number;
    minimum_ranking_4_star: number;
    minimum_ranking_5_star: number;
    missed_call_msgs: string;
    mode_assignment: string;
    monday_close: Date;
    monday_open: Date;
    msg_reply_to_email_addr: string;
    new_driver_days1: number;
    new_driver_days2: number;
    nextcall_calc_time: number;
    notification_days: number;
    notification_method: string;
    notify_template: number;
    offer_broke_status: string;
    offer_cont_move_days_out: number;
    offer_cont_move_user: string;
    offer_order_data: string;
    offer_order_format: string;
    offer_order_free_text: string;
    offer_order_incl_cont_moves: string;
    offer_order_logo: string;
    offer_order_logo_name: string;
    offer_order_radius: number;
    offer_order_rt_guide_rate: string;
    onduty_hours: number;
    order_mode: string;
    order_nbr_as_pro_nbr: string;
    order_type_choice: string;
    pallet_tracking: string;
    pickup_info: string;
    pickup_warning: number;
    placard_prompt_when_regchk: string;
    placard_prompt_when_routing: string;
    position_display: string;
    position_format: string;
    prevent_dsp_hold: string;
    profile: string;
    profit_on_net: string;
    prompt_addl_orders: string;
    prompt_cm_assign_rev_share: string;
    prompt_cont_moves: string;
    prompt_revenue_share_when: string;
    qualify_weightbreaks_by: string;
    queue_contract_sent: number;
    queue_doc_request_sent: number;
    queue_exception: number;
    queue_expiring_insurance: number;
    queue_over_warning_level: number;
    queue_packet_sent: number;
    queue_verify_docs: number;
    rate_carrier: string;
    rate_exception_uom_list: string;
    rate_using_tariff: string;
    rate_using_tariff_codes: string;
    rating_trigger_code: string;
    record_pta: string;
    recur_tractr_create_days: number;
    recurring_override: string;
    recurring_tractor_status: string;
    refuel_time: number;
    reminder_messages: string;
    remote_planning_profile: string;
    req_container_num: string;
    req_unassign_reason: string;
    require_carrier: string;
    require_departure: string;
    require_hubs: string;
    require_pickup_trailer: string;
    require_pro_nbr_on_arrival: string;
    require_pro_nbr_on_pickup: string;
    require_signed_for_on_delv: string;
    rerate_consigned_bfg: string;
    return_acceptance_option: string;
    return_location: string;
    return_option: string;
    revenue_code_choice: string;
    saturday_close: Date;
    saturday_open: Date;
    save_image_to_order_history: string;
    save_rate_conf_to_orderhist: string;
    service_fail_cons: string;
    service_fail_date: string;
    service_fail_drop: string;
    service_fail_pick: string;
    service_fail_ship: string;
    service_failures: string;
    set_bol_processed: string;
    set_bol_received: string;
    set_brokerage_flg: string;
    set_brokerage_for: string;
    setpoint_control: string;
    show_trap_trl_on_dnd_scrn: string;
    showas_location: string;
    split_container: string;
    stoptime_msg: string;
    subject_order_cust_rate_typ: string;
    sunday_close: Date;
    sunday_open: Date;
    target_profit: number;
    thursday_close: Date;
    thursday_open: Date;
    tie_empty: string;
    trailer_continuity: string;
    trailer_return_email: string;
    trailer_sharing: string;
    transfax_comments: string;
    trl_assign_check: string;
    trm_minutes: number;
    tuesday_close: Date;
    tuesday_open: Date;
    unassign_reason: string;
    unassignment_msg: string;
    unlock_brok_status: string;
    use_load_city: string;
    use_matching_locations: string;
    use_primary_eq: string;
    verify_cargo_ins: string;
    waterfall_auto_route_guides: string;
    waterfall_cont_move_user: string;
    waterfall_free_text: string;
    waterfall_incl_cont_moves: string;
    waterfall_lead_time: number;
    waterfall_logo: string;
    waterfall_logo_name: string;
    waterfall_notify_email: string;
    waterfall_process: string;
    waterfall_radius: number;
    waterfall_rt_guide_rate: string;
    waterfall_tender_data: string;
    waterfall_tender_format: string;
    wednesday_close: Date;
    wednesday_open: Date;
    wtrfall_cont_move_days_out: number;
    xref_auto_create: string;
}

