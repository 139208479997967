/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelEdiLtxexpressProf.ts             *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/datafusion/edi-ltxexpress-prof";

export class AutogenModelEdiLtxexpressProf<RowType extends ModelRow> extends Endpoint<RowType, RowEdiLtxexpressProfFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowEdiLtxexpressProf extends ModelRow<RowEdiLtxexpressProfFields> {
    constructor(values?: Partial<RowEdiLtxexpressProfFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowEdiLtxexpressProfFields {
    company_id: string;
    customer_ids: string;
    days_previous: number;
    dest_type: string;
    dest_zones: string;
    id: string;
    include_customers: string;
    include_empty_dest: string;
    include_empty_orig: string;
    include_partners: string;
    intercompany_filter: string;
    no_display: string;
    no_reply_needed: string;
    orig_type: string;
    orig_zones: string;
    partner_ids: string;
    partner_status: string;
    refresh_rate: number;
    revenue_codes: string;
    show_cancel: string;
    show_change: string;
    show_original: string;
    show_result_panel: string;
    sort_descend: string;
    title: string;
    use_express_mode: string;
}

