export interface Padding {
    paddingAll?: number;
    paddingBottom?: number;
    paddingLeft?: number;
    paddingRight?: number;
    paddingTop?: number;
}

export class PaddingResolver {
    public static getPaddingBottom(padding: Partial<Padding>): number {
        return padding?.paddingBottom == null ? padding?.paddingAll : padding?.paddingBottom;
    }

    public static getPaddingLeft(padding: Partial<Padding>): number {
        return padding?.paddingLeft == null ? padding?.paddingAll : padding?.paddingLeft;
    }

    public static getPaddingRight(padding: Partial<Padding>): number {
        return padding?.paddingRight == null ? padding?.paddingAll : padding?.paddingRight;
    }

    public static getPaddingTop(padding: Partial<Padding>): number {
        return padding?.paddingTop == null ? padding?.paddingAll : padding?.paddingTop;
    }
}
