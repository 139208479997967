import { CommonTooltips } from "@mcleod/common";
import { ClickEvent, Component, DataSourceAction, DataSourceExecutionEvent, DataSourceMode, EditRowDecorator, ForcedCase, Label, Layout, TableRow, TableRowDisplayEvent } from "@mcleod/components";
import { LabelStyles } from "@mcleod/components/src/components/label/LabelStyles";
import { CurrencyUtil, DisplayType, Keys, ModelRow, Navigation, StringUtil, UrlUtil } from "@mcleod/core";
import { Orders } from "@mcleod/dispatch/src/Orders";
import { EdiMultiStop } from "./EdiMultiStop";
import { AutogenLayoutTenderList } from "./autogen/AutogenLayoutTenderList";

export class TenderList extends AutogenLayoutTenderList {
    override onLoad() {
        this.textboxEdiOrderOrderId.addKeyUpListener(event => {
            if (event.domEvent != null && event.key === Keys.ENTER && this.textboxEdiOrderOrderId.text?.length > 0)
                this.mainDataSource.search(this.mainDataSource.getDataboundValues(DataSourceMode.SEARCH, null, true)).finally(() => {
                    if (!StringUtil.isEmptyString(window.location.search)) // remove param ids from url if needed.
                        Navigation.pseudoNavigateTo(window.location.pathname, null)
                });
        });

        this.textboxReferenceNumbers.addKeyUpListener(event => {
            if (event.domEvent != null && event.key === Keys.ENTER && this.textboxReferenceNumbers.text?.length > 0)
                this.mainDataSource.search(this.mainDataSource.getDataboundValues(DataSourceMode.SEARCH, null, true)).finally(() => {
                    if (!StringUtil.isEmptyString(window.location.search)) // remove param ids from url if needed.
                        Navigation.pseudoNavigateTo(window.location.pathname, null)
                });
        });

        this.textboxEdiOrderShipmentId.addKeyUpListener(event => {
            if (event.domEvent != null && event.key === Keys.ENTER && this.textboxEdiOrderShipmentId.text?.length > 0)
                this.mainDataSource.search(this.mainDataSource.getDataboundValues(DataSourceMode.SEARCH, null, true)).finally(() => {
                    if (!StringUtil.isEmptyString(window.location.search)) // remove param ids from url if needed.
                        Navigation.pseudoNavigateTo(window.location.pathname, null)
                });
        });

        this.textboxEdiOrderId.addKeyUpListener(event => {
            if (event.domEvent != null && event.key === Keys.ENTER && this.textboxEdiOrderId.text?.length > 0)
                this.mainDataSource.search(this.mainDataSource.getDataboundValues(DataSourceMode.SEARCH, null, true)).finally(() => {
                    if (!StringUtil.isEmptyString(window.location.search)) // remove param ids from url if needed.
                        Navigation.pseudoNavigateTo(window.location.pathname, null)
                });
        });

        this.setSearchBordersExpanded();
        this.citystateEdiStopOriginCityId.dataSourceModeChanged(DataSourceMode.SEARCH);
        this.citystateEdiStopDestCityId.dataSourceModeChanged(DataSourceMode.SEARCH);
        this.citystateEdiStopOriginCityId.textCity.forcedCase = ForcedCase.UPPER;
        this.citystateEdiStopDestCityId.textCity.forcedCase = ForcedCase.UPPER;
        this.checkboxEdiOrderNoDisplay.allowIndeterminate = true;
        this.checkboxEdiOrderNoDisplay.checked = undefined;
        this.buttonClear.enabled = false;

        const urlProp = UrlUtil.getPropsFromUrl(window.location.search);
        if (urlProp != null && urlProp.id != null)
            this.mainDataSource.search({ order_id: urlProp.id });
    }

    tableTendersOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        const modelRow = event.getTableRow().data as ModelRow;
        const labelOrderId = tableRow.findComponentById("labelOrderId") as Label;
        const labelPartnerName = tableRow.findComponentById("labelPartnerName") as Label;
        const labelStops = tableRow.findComponentById("labelStops") as Label;
        const labelTotalCharges = tableRow.findComponentById("labelTotalCharges") as Label;
        if (modelRow.get("order_id")) labelOrderId.addUnderlineWhenMouseOver();

        labelStops.setClassIncluded(LabelStyles.link, true);
        this.setTooltipCallbacks(labelPartnerName, modelRow);
        this.setTooltipCallbacks(labelTotalCharges, modelRow);
    }

    /** This is an event handler for the afterExecution event of sourceOrders.  */
    sourceEdiOrdersListAfterExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() == DataSourceAction.SEARCH && event.dataSource.data.length > 0) {
            const transitionProps = { speed: 100, paddingTop: 0 };
            this.panelSearch.collapse(transitionProps).then(() => { this.setSearchBordersCollapsed() });
            this.panelSearchHeader.visible = true;
        }
        this.buttonClear.enabled = true;
    }

    /** This is an event handler for the onClick event of buttonSearchExpand.  */
    buttonSearchExpandOnClick(event: ClickEvent) {
        const transitionProps = { speed: 100, paddingTop: 0 };
        if (this.panelSearch.style.height == "0px")
            this.panelSearch.expand(transitionProps).then(() => {
                this.setSearchBordersExpanded();
            });
        else
            this.panelSearch.collapse(transitionProps).then(() => {
                this.setSearchBordersCollapsed();
            });
    }

    setSearchBordersCollapsed() {
        this.buttonSearchExpand.imageRotation = 90;
        this.panelSearch.borderWidth = 0;
        this.panelSearchHeader.borderBottomWidth = 1;
        this.panelSearchHeader.borderColor = "strokeSecondary";
        this.panelSearchHeader.borderBottomRightRadius = 4;
        this.panelSearchHeader.borderBottomLeftRadius = 4;
    }

    setSearchBordersExpanded() {
        this.buttonSearchExpand.imageRotation = 0;
        this.panelSearch.borderWidth = 1
        this.panelSearch.borderColor = "strokeSecondary";
        this.panelSearch.borderRadius = 4;
        this.panelSearch.borderTopWidth = 0;
        this.panelSearch.borderTopLeftRadius = 0;
        this.panelSearch.borderTopRightRadius = 0;
        this.panelSearchHeader.borderBottomWidth = 0;
        this.panelSearchHeader.borderBottomRightRadius = 0;
        this.panelSearchHeader.borderBottomLeftRadius = 0;
    }

    setTooltipCallbacks(component: Component, modelRow: ModelRow) {
        if (component.id == null) return;
        switch (component.id) {
            case "labelPartnerName":
                return CommonTooltips.setPanelTooltipCallback(component, () => [
                    { caption: "Partner ID", displayType: DisplayType.STRING, text: modelRow.get("partner_id") },
                    { caption: "Alt Partner ID", displayType: DisplayType.STRING, text: modelRow.get("alt_partner_id") },
                    { caption: "Version", displayType: DisplayType.STRING, text: modelRow.get("version") }
                ]);
            case "labelTotalCharges":
                return CommonTooltips.setPanelTooltipCallback(component, () => [
                    { caption: "Type", displayType: DisplayType.STRING, text: modelRow.get("rate_type_descr"), rowBreak: false, fillRow: true },
                    { caption: "Rate", displayType: DisplayType.FLOAT, text: modelRow.get("rate"), marginLeft: 55 },
                    { caption: "Units", displayType: DisplayType.STRING, text: modelRow.get("rate_units"), rowBreak: false, fillRow: true },
                    { caption: "Freight Charge", displayType: DisplayType.FLOAT, text: CurrencyUtil.formatCurrency(modelRow.get("freight_charge")), marginLeft: 20 },
                    { caption: "Rate ID", displayType: DisplayType.STRING, text: modelRow.get("rate_id"), rowBreak: false, fillRow: true },
                    { caption: "Other Charges", displayType: DisplayType.FLOAT, text: CurrencyUtil.formatCurrency(modelRow.get("otherchargetotal")), marginLeft: 10 }
                ]);
        }
    }

    /** This is an event handler for the onClick event of buttonClear.  */
    buttonClearOnClick(event: ClickEvent) {
        this.tableTenders.displayData(null, null, 0);
        this.mainDataSource.searchRow.clear();
        this.textboxEdiOrderCustomerId.clear();
        this.textboxEdiOrderRevenueCodeId.clear();
        this.textboxEdiOrderPartnerId.clear();
        this.textboxEdiOrderDirection.selectedItem = null;
        this.textboxEdiOrderPurposeType.selectedItem = null;
        this.textboxEdiOrderReplyAction.selectedItem = null;
        this.textboxOriginLocation["_lookupModelDataForUpdate"] = null;
        this.textboxDestLocation["_lookupModelDataForUpdate"] = null;

        this.mainDataSource.searchRow.setValues({
            search_status_available: "Y",
            search_status_in_progress: "Y",
            search_status_delivered: "Y",
            search_status_void: "Y",
        });
        this.panelSearch.displayData(this.mainDataSource.searchRow, null, 0);
        this.buttonClear.enabled = false;
        this.textboxEdiOrderOrderId.focus();
    }

    labelStopsOnClick(event: ClickEvent) {
        const label = event.target as Label;
        label.busy = true;
        const tableRow: TableRow = TableRow.getContainingTableRow(label);
        tableRow.table.selectedRow = tableRow;
        const row = tableRow.data;
        let ediMultiStopLayout: EdiMultiStop;
        const erd = new EditRowDecorator({
            title: `Stop Details - Tender ${row.get("id")}`,
            layout: ediMultiStopLayout = Layout.getLayout("lme/datafusion/EdiMultiStop") as EdiMultiStop,
            layoutLoadListeners: async (event) => {
                await ediMultiStopLayout.mainDataSource.search({ id: row.get("id") });
                ediMultiStopLayout.mainDataSource.mode = DataSourceMode.NONE;
            },
            width: '70%',
            overlayProps: { closeOnClickOff: false, greyedBackground: true },
            onClose: (cancelled: boolean) => ediMultiStopLayout.slideOut(),
            doAfterSlideIn: () => label.busy = false
        });
    }

    orderNumberOnClick(event: ClickEvent) {
        if ((event.target as Label).caption) {
            Orders.navigateTo((event.target as Label).caption);
        }
    }
}
