import { Api } from "./Api";
import { DisplayType } from "./constants/DisplayType";
import { DbDisplayValue } from "./DbDisplayValue";
import { StringUtil } from "./StringUtil";

export enum DataType {
    STRING = "string",
    INT = "int",
    DATE = "date",
    TIME = "time",
    DATETIME = "datetime",
    BOOL = "bool",
    FLOAT = "float",
    CURRENCY = "currency",
    LIST = "list",
    OBJECT = "object",
    DATERANGE = 'daterange'
}

export enum ExtendedDataType {
    CITY = "city",
    STATE = "state",
    LOCATION = "location",
    PHONE = "phone",
    EMAIL = "email",
    YES_NO = "yes_no"
}

interface MetadataFieldProps {
    name: string;
    dataType: DataType;
    extDataType: ExtendedDataType;
    length: number;
    precision: number;
    scale: number;
    caption: string;
    tableName: string;
    required: boolean;
    lookupModel: string;
    upshifted: boolean;
    allowAdd: boolean;
    allowUpdate: boolean;
    allowSearch: boolean;
}

export class MetadataField implements MetadataFieldProps {
    name: string;
    dataType: DataType;
    extDataType: ExtendedDataType;
    length: number;
    precision: number;
    scale: number;
    caption: string;
    tableName: string;
    tableAlias: string;
    required: boolean;
    lookupModel: string;
    upshifted: boolean;
    allowAdd: boolean;
    allowUpdate: boolean;
    allowSearch: boolean;
    private _displayType: DisplayType;
    private _dbDisplayValues: DbDisplayValue[];
    dynamicDbDisplayValues: boolean;

    constructor(props?: Partial<MetadataFieldProps>) {
        if (props != null)
            for (const key in props)
                this[key] = props[key];
    }

    fillFromApiResponse(apiResponse: any) {
        for (const key in apiResponse)
            this[key] = apiResponse[key];
    }

    getDisplayValue(dbValue: string): string {
        if (dbValue != null && this.dbDisplayValues != null)
            for (const dbDisplayValue of this._dbDisplayValues)
                if (dbDisplayValue.dbValue === dbValue)
                    return dbDisplayValue.displayValue;
        return null;
    }

    get displayType() {
        if (this._displayType == null) {
            if (this.extDataType != null) {
                switch (this.extDataType) {
                    case "city": this._displayType = DisplayType.CITY; break;
                    case "email": this._displayType = DisplayType.EMAIL; break;
                    case "location": this._displayType = DisplayType.LOCATION; break;
                    case "phone": this._displayType = DisplayType.PHONE; break;
                    case "state": this._displayType = DisplayType.STATE; break;
                }
            }
            else {
                switch (this.dataType) {
                    // case "bool": this._displayType = DisplayType.BOOLEAN; break;
                    case DataType.CURRENCY: this._displayType = DisplayType.CURRENCY; break;
                    case DataType.DATE: this._displayType = DisplayType.DATE; break;
                    case DataType.DATERANGE: this._displayType = DisplayType.DATERANGE; break;
                    case DataType.TIME: this._displayType = DisplayType.TIME; break;
                    case DataType.DATETIME: this._displayType = DisplayType.DATETIME; break;
                    case DataType.FLOAT: this._displayType = DisplayType.DECIMAL; break;
                    case DataType.INT: this._displayType = DisplayType.INTEGER; break;
                }
            }
        }
        if (this._displayType == null)
            this._displayType = DisplayType.STRING;
        return this._displayType;
    }

    get dbDisplayValues(): DbDisplayValue[] {
        return this._dbDisplayValues;
    }

    set dbDisplayValues(values: any) {
        if (values != null)
            this._dbDisplayValues = [];
        for (const value of values) {
            const dbDisplayValue = new DbDisplayValue(value["dbValue"], value["displayValue"]);
            this._dbDisplayValues.push(dbDisplayValue);
        }
    }
}

interface FieldList {
    [key: string]: MetadataField;
}

export class ApiMetadata {
    public auth: string;
    public keyFields: string[];
    public displayField: string;
    public entryLayout: string;
    public lookupLayout: string;
    public quickInfoLayout: string;
    public input: FieldList = {};
    public output: FieldList = {};

    fillFromApiResponse(apiResponse: any) {
        if (apiResponse == null)
            return;
        this.auth = apiResponse.auth;
        this.keyFields = apiResponse.key_fields;
        this.displayField = apiResponse.display_format;
        this.entryLayout = apiResponse.entry_layout;
        this.lookupLayout = apiResponse.lookup_layout;
        this.quickInfoLayout = apiResponse.quick_info_layout;
        this.fillFieldList(this.input, apiResponse.input);
        this.fillFieldList(this.output, apiResponse.output);
    }

    fillFieldList(list: FieldList, apiList: any) {
        for (const name in apiList) {
            const field = new MetadataField();
            field.fillFromApiResponse(apiList[name]);
            list[name] = field;
        }
    }

    getFieldFromInput(field: string): MetadataField {
        return this.input[field];
    }

    getFieldFromOutput(field: string): MetadataField {
        return this.output[field];
    }
}

interface MetadataCache {
    [key: string]: ApiMetadata;
}
interface InFlight {
    [endpoint: string]: Promise<ApiMetadata>;
}
const inFlightApiCalls: InFlight = {};

const cache: MetadataCache = {};

export function getApiMetadataFromCache(endpoint: string): ApiMetadata {
    if (StringUtil.isEmptyString(endpoint))
        return null;
    const result = cache[endpoint];
    if (result == null)
        getApiMetadata(endpoint);
    return result;
}

export function getApiMetadata(endpoint: string): Promise<ApiMetadata> {
    if (endpoint == null)
        return Promise.resolve(null);
    if (inFlightApiCalls[endpoint] != null)
        return inFlightApiCalls[endpoint].then(unused => {
            return cache[endpoint];
        });
    const cached = cache[endpoint];
    if (cached != null)
        return Promise.resolve(cached);
    inFlightApiCalls[endpoint] = Api.search("metadata", { endpoint: endpoint }).then(response => {
        const result: ApiMetadata = new ApiMetadata();
        result.fillFromApiResponse(response);
        cache[endpoint] = result;
        delete inFlightApiCalls[endpoint];
        return result;
    });
    return inFlightApiCalls[endpoint];
}
