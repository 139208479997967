import { DataDisplayEvent, DataSourceExecutionEvent, DataSourceMode, Label, Layout, Tab } from "@mcleod/components";
import { Alignment, StringUtil } from "@mcleod/core";
import { ThemeCommonPage } from "@mcleod/core/src/themes/common/ThemeCommonPage";
import { TransmissionStatusQuickInfo } from "./TransmissionStatusQuickInfo";
import { AutogenLayoutEDIDeclineCodeQuickInfo } from "./autogen/AutogenLayoutEDIDeclineCodeQuickInfo";
import { AutogenLayoutEdiOrderReply } from "./autogen/AutogenLayoutEdiOrderReply";



export class EdiOrderReply extends AutogenLayoutEdiOrderReply {

    override onLoad(): void {
        this.styleTab(this.tabErrors);
        this.viewTransmissionStatusQuickInfo();
    }

    reasonOnDisplay(event: DataDisplayEvent) {
        this.setQuickInfoTooltip(event);
    }

    sourceErrorAfterExecution() {
        this.sourceEdiMapError.setRowsAndMode(DataSourceMode.NONE, (this.sourceEdiMapError.data.filter(error => error.data["transaction_type"] === "R")));
        this.tabErrors["countLabel"].caption = this.sourceEdiMapError.data.length;
        if (this.sourceEdiMapError.data.length == 0) {
            this.tableErrors.visible = false;
        } else {
            this.tableErrors.visible = true;
        }
    }

    setQuickInfoTooltip(event: DataDisplayEvent) {

        this.textboxReplyReason.tooltipCallback = (baseTooltip, originatingEvent) => {
            const replyVersion = this.sourceViewEdiOrder.activeRow.data["version"];
            const replyReason = this.sourceViewEdiOrder.activeRow.data["reply_reason"];
            const partnerId = this.sourceViewEdiOrder.activeRow.data["partner_id"];
            const layout = Layout.getLayout("lme/datafusion/EDIDeclineCodeQuickInfo") as AutogenLayoutEDIDeclineCodeQuickInfo;
            layout.addLayoutLoadListener(() => {
                if (layout.sourceEdiDeclineCodes != null) {
                    layout.sourceEdiDeclineCodes?.search({ version: replyVersion, partner_code: replyReason, partner_id: partnerId }).then(r => {
                        if (!StringUtil.isEmptyString(replyReason)) {
                            layout.textboxReasonCode.text = r.modelRows[0].get("standard_code");
                            layout.textboxDescription.text = r.modelRows[0].get("description");
                            layout.textboxVersion.text = r.modelRows[0].get("version");
                            layout.textboxElementID.text = r.modelRows[0].get("element_id");
                            this.textboxReplyReason.showTooltip(layout,
                                { position: this.textboxReplyReason.tooltipPosition == null ? Alignment.BOTTOM : this.textboxReplyReason.tooltipPosition, anchorToMouse: true, originatingEvent: originatingEvent },
                                { themeKey: "quickInfo", backgroundColor: "background5", color: null });
                        } else {
                            layout.textboxReasonCode.text = "";
                            layout.textboxDescription.text = "";
                            layout.textboxVersion.text = "";
                            layout.textboxElementID.text = "";
                        }
                    });
                }
            });
            return layout;
        }
    }

    styleTab(tab: Tab) {
        if (tab["_heading"] != null && tab["_headingLabel"] != null) {
            tab["_heading"].color = "subtle.light";
            tab["_heading"].marginBottom = 10;
            tab["_headingLabel"].setProps({ color: "default", fontBold: true, fillRow: false })
            if (tab["countLabel"] == null) {
                tab["countLabel"] = new Label({
                    fontBold: true, caption: "0", fillRow: true,
                    color: "primary", rowBreak: false, marginLeft: 5
                });
                tab["_heading"].insert(tab["countLabel"], 1);
            }
        }
    }

    viewTransmissionStatusQuickInfo() {
        this.checkboxReplyTransmitted.tooltipCallback = (base, originatingEvent) => {
            if (this.mainDataSource?.activeRow.get("reply_batch_id") > "0") {
                const layout = Layout.getLayout("lme/datafusion/TransmissionStatusQuickInfo") as TransmissionStatusQuickInfo;
                layout.addLayoutLoadListener(() => {
                    if (layout.sourceEdiLog !== null) {
                        layout.sourceEdiLog.search({
                            direction: "O",
                            func_batch: this.mainDataSource.activeRow.get("reply_batch_id"),
                            batch: this.mainDataSource.activeRow.get("reply_batch_id"),
                            func_receiver_id: this.mainDataSource.activeRow.get("reply_partner_id"),
                            receiver_id: this.mainDataSource.activeRow.get("reply_altpartnerid"),
                            func_version: this.mainDataSource.activeRow.get("reply_version"),
                            transaction_type: 'R',
                            search_receiver_id: this.mainDataSource.activeRow.get("reply_partner_id")
                        });
                    }
                });
                return this.checkboxReplyTransmitted.showTooltip(layout,
                    {
                        position: this.checkboxReplyTransmitted.tooltipPosition == null ? Alignment.BOTTOM : this.checkboxReplyTransmitted.tooltipPosition,
                        pointerColor: ThemeCommonPage.quickInfo.borderLeftColor, originatingEvent: originatingEvent
                    },
                    { themeKey: "quickinfo", backgroundColor: "background5", borderRightColor: ThemeCommonPage.quickInfo.borderLeftColor, borderRightWidth: 12, color: null });
            }
        }
    }
    /** This is an event handler for the afterExecution event of sourceViewEdiOrder.  */
    sourceViewEdiOrderAfterExecution(event: DataSourceExecutionEvent) {
        const row = this.mainDataSource.activeRow;
        const tenderDirection = row?.get("direction");
        this.checkboxReplyTransmitted.visible = ("I" == tenderDirection);
        if ("O" == tenderDirection) {
            this._setInboundReplyStatus();
            this.checkboxReplyTransmitted.visible = false;
            this.labelInboundReplyStatus.visible = true;
        } else {
            this.checkboxReplyTransmitted.visible = true;
            this.labelInboundReplyStatus.visible = false;
        }
    }
    private _setInboundReplyStatus() {
        const replyReceived = this.mainDataSource.activeRow?.get("reply_received");
        const requiresReply = this.mainDataSource.activeRow?.get("requires_reply_outbound");
        let replyStatus = "Not Required";

        if (replyReceived == "Y") {
            replyStatus = "Processed";
        } else if (replyReceived == "N" && requiresReply == "Y") {
            replyStatus = "Not Received";
        } else if (requiresReply == "N") {
            replyStatus = "Not Required";
        }

        this.labelInboundReplyStatus.caption = replyStatus;
    }
}
