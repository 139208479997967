import { Label, Panel } from "@mcleod/components";
import { Alignment } from "@mcleod/core";
import { AutogenLayoutDemoSlideInDialog } from "./autogen/AutogenLayoutDemoSlideInDialog";

export class DemoSlideInDialog extends AutogenLayoutDemoSlideInDialog {
    getDemoPath() {
        return ["Dialogs", "Slide In"];
    }

    buttonSlideInBottomClicked() {
        this.slideInAction(Alignment.BOTTOM);
    }

    buttonSlideInRightClicked() {
        this.slideInAction(Alignment.RIGHT);
    }

    buttonSlideInLeftClicked() {
        this.slideInAction(Alignment.LEFT);
    }

    buttonSlideInTopClicked() {
        this.slideInAction(Alignment.TOP);
    }

    slideInAction(fromDirectionAlign: Alignment) {
        const topLabel = new Label({ caption: "Slide in - From " + fromDirectionAlign, fontBold: true });
        const closeLabel = new Label({ caption: "Click outside to close" });
        const panel = new Panel({
            padding: 0,
            width: 500,
            height: window.innerHeight,
            backgroundColor: "backgroundSubtle",
            borderRadius: 4,
            borderWidth: 1,
            borderShadow: true,
            fillHeight: true,
            borderColor: "strokeSecondary",
            paddingBottom: 8
        });
        panel.add(topLabel);
        panel.add(closeLabel);

        panel.slideIn({ direction: fromDirectionAlign }, true);

        /*
        *   The speed parameter is set for demo purposes and is not required. It will use the default speed if not specified.
        *    If no direction is specified, Alignment.BOTTOM will be used as the default.
        *    keepSlideProps will skip reverting back to the original component position props after the transition is complete.
        *
        *   Typical usage examples:
        *
            panel.slideIn();
    
            panel.slideIn({ fromDirection: Alignment.RIGHT });
    
            // Can also accept a promise on completion
            panel.slideIn().then(unused => {
              // do something else
            });
        */
    }
}
