import { Table } from "../components/table/Table";
import { DragSession, TableRow } from "../components/table/TableRow";
import { DataSource } from "../databinding/DataSource";
import { DomEvent } from "./DomEvent";
import { Event, EventListener } from "./Event";

export interface TableRowMoveListener extends EventListener {
    (event: TableRowMoveEvent): void;
}

export class TableRowMoveEvent extends Event {
    private _tableRow: TableRow;
    private _newIndex: number;
    private _oldIndex: number;
    private _dragSession: DragSession;

    constructor(table: Table, tableRow: TableRow, newIndex: number, oldIndex: number, dragSession?: DragSession, domEvent?: DomEvent) {
        super(table, domEvent);
        this._tableRow = tableRow;
        this._newIndex = newIndex;
        this._oldIndex = oldIndex;
        this._dragSession = dragSession;
    }

    public getTable(): Table {
        return this.target as Table;
    }

    public getTableRow(): TableRow {
        return this._tableRow;
    }

    public getDataSource(): DataSource {
        return this.getTable().dataSource;
    }

    public get dragSession(): DragSession {
        return this._dragSession;
    }

    public get newIndex(): number {
        return this._newIndex;
    }

    public get oldIndex(): number {
        return this._oldIndex;
    }
}
