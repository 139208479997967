import { getThemeColor, makeStyles } from "@mcleod/core";

export const TextboxStyles = makeStyles("txt", () => {
    return {
        base: {
            display: "flex",
            flexDirection: "column",
            width: "240px",
        },
        unsetWidth: {
            width: "unset"
        },
        inputBase: {
            width: "99%",
            border: "none",
            paddingTop: "4px",
            paddingBottom: "4px",
            paddingLeft: "8px",
            paddingRight: "1px",
            backgroundColor: "transparent",
            color: getThemeColor("textbox.enabled.color"),
            height: "34px",
            fontWeight: "400",
            outline: "none",
            "&::placeholder": {
                color: "#9E9E9E"
            }
        },
        textboxBase: {
            padding: "1px",
            display: "flex",
            flex: "1",
            border: "1px solid " + getThemeColor("strokeSecondary"),
            alignItems: "center",
            borderRadius: "4px",
            position: "relative",
            "&:focus-within": {
                borderColor: getThemeColor("primary")
            },
            backgroundColor: "transparent"
        },
        textarea: {
            fontFamily: "Arial",
            resize: "none",
            border: "none",
            outline: "none",
            width: "100%",
            height: "100%",
            borderRadius: "4px",
            padding: "8px",
            backgroundColor: "transparent",
            color: getThemeColor("textbox.enabled.color"),
            "&:disabled": {
                backgroundColor: getThemeColor("textbox.disabled.backgroundColor"),
                color: getThemeColor("textbox.disabled.color")
            },
            "&::placeholder": {
                color: "#9E9E9E"
            },
            "&::-webkit-scrollbar": {
                display: "none"
            }
        },
        inputUnderlined: {
            borderBottom: "2px solid " + getThemeColor("strokeSecondary"),
            borderRadius: "0px",
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            transition: "border-bottom 0.3s ease",
            display: "flex",
            backgroundColor: "transparent", // do this in imagePre of Input instead
            color: getThemeColor("textbox.enabled.color"),
            alignItems: "center",
            "&:hover": {
                borderBottom: "2px solid #8D8DCD",
            },
            "&:focus-within": {
                borderBottom: "2px solid #137CDD",
            }
        },
        inputNoLines: {
            borderBottom: "none",
            borderRadius: "0px",
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            display: "flex",
            backgroundColor: "transparent", // do this in imagePre of Input instead
            color: getThemeColor("textbox.enabled.color"),
            alignItems: "center"
        },
        negativeCurrency: {
            color: getThemeColor("error")
        },
        disabled: {
            backgroundColor: getThemeColor("textbox.disabled.backgroundColor"),
            color: getThemeColor("textbox.disabled.color"),
        },
        disablePointerEvents: {
            pointerEvents: "none"
        },
    }
});
