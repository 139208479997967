/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelOrders.ts                        *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/dispatch/orders";

export class AutogenModelOrders<RowType extends ModelRow> extends Endpoint<RowType, RowOrdersFields> {
	constructor(ctor: RowConstructor) {
		super(endpointPath, ctor);
	}
}

export class AutogenRowOrders extends ModelRow<RowOrdersFields> {
	constructor(values?: Partial<RowOrdersFields>) {
		super(endpointPath, true, values);
	}
}

export interface RowOrdersFields {
	actual_reefer_profile: string;
	agency_id: string;
	agent_payee_id: string;
	all_in_rate: string;
	allow_relay: string;
	app_status_id: string;
	autorate_status: string;
	bill_date: Date;
	bill_distance: number;
	bill_distance_um: string;
	billing_clerk: string;
	billing_empty_distance: number;
	billing_empty_distance_um: string;
	billing_loaded_distance: number;
	billing_loaded_distance_um: string;
	billing_user_id: string;
	blnum: string;
	blnum2: string;
	blnum3: string;
	bol_note: string;
	bol_received: string;
	bol_recv_date: Date;
	booking_no: string;
	booking_number: string;
	brk_qual_profile: string;
	broker_location_id: string;
	brokerage: string;
	brokerage_workflow_id: string;
	cbp_crossing_date: Date;
	cbp_crossing_location_id: string;
	cbp_crossing_reason: string;
	cbsa_crossing_date: Date;
	cbsa_crossing_location_id: string;
	cbsa_crossing_reason: string;
	cc_fuel_revenue: number;
	cc_fuel_revenue_c: string;
	cc_fuel_revenue_d: Date;
	cc_fuel_revenue_n: number;
	cc_fuel_revenue_r: number;
	cc_net_revenue: number;
	cc_net_revenue_c: string;
	cc_net_revenue_d: Date;
	cc_net_revenue_n: number;
	cc_net_revenue_r: number;
	cc_pro_nbr: string;
	cobroker_location_id: string;
	collection_method: string;
	com_setpnt_temp: number;
	com_setpnt_temp_um: string;
	commitment_id: string;
	commodity: string;
	commodity_id: string;
	company_id: string;
	consgne_refno_stop: string;
	consignee_refno: string;
	consignee_stop_id: string;
	container: string;
	container_planned: string;
	container_type_id: string;
	controlling_carrier_code: string;
	copy_from_company: string;
	copy_from_order_id: string;
	copy_to_company: string;
	copy_to_order_id: string;
	credit_override_status: string;
	"ctrl_party.address1": string;
	"ctrl_party.address2": string;
	"ctrl_party.appt_required": string;
	"ctrl_party.assigned_to": string;
	"ctrl_party.bseg": string;
	"ctrl_party.category": string;
	"ctrl_party.cbsa_port_id": string;
	"ctrl_party.chassis_owner": string;
	"ctrl_party.chassis_pool": string;
	"ctrl_party.city_id": number;
	"ctrl_party.city_name": string;
	"ctrl_party.class_rqmt": string;
	"ctrl_party.comments": string;
	"ctrl_party.company_id": string;
	"ctrl_party.container_pool": string;
	"ctrl_party.container_vendor": string;
	"ctrl_party.cust_id": string;
	"ctrl_party.customer_id": string;
	"ctrl_party.days_between_calls": number;
	"ctrl_party.ded_rqmt_type": string;
	"ctrl_party.ded_type": string;
	"ctrl_party.def_commodity_desc": string;
	"ctrl_party.def_commodity_id": string;
	"ctrl_party.delivery_date_option": string;
	"ctrl_party.directions": string;
	"ctrl_party.disable_loc_rating": string;
	"ctrl_party.dl_gen_cost": number;
	"ctrl_party.dl_gen_repower": number;
	"ctrl_party.dl_haz_cost": number;
	"ctrl_party.dl_haz_repower": number;
	"ctrl_party.dl_hvl_cost": number;
	"ctrl_party.dl_hvl_repower": number;
	"ctrl_party.dl_load_time": number;
	"ctrl_party.dl_svc_cost": number;
	"ctrl_party.dl_svc_repower": number;
	"ctrl_party.dl_unload_time": number;
	"ctrl_party.driver_load_id": string;
	"ctrl_party.driver_unload_id": string;
	"ctrl_party.ds_capacity_cost": number;
	"ctrl_party.ds_model_flag": string;
	"ctrl_party.email_address": string;
	"ctrl_party.email_location": string;
	"ctrl_party.extra_handling_time": number;
	"ctrl_party.filer_code": string;
	"ctrl_party.foreign_port": string;
	"ctrl_party.friday_close": Date;
	"ctrl_party.friday_open": Date;
	"ctrl_party.fuel_price_region": string;
	"ctrl_party.google_place_fm_address": string;
	"ctrl_party.google_place_id": string;
	"ctrl_party.google_place_type": string;
	"ctrl_party.id": string;
	"ctrl_party.include_xref_trailers": string;
	"ctrl_party.insur_amt_rqrd": number;
	"ctrl_party.insur_amt_rqrd_c": string;
	"ctrl_party.insur_amt_rqrd_d": Date;
	"ctrl_party.insur_amt_rqrd_n": number;
	"ctrl_party.insur_amt_rqrd_r": number;
	"ctrl_party.is_active": string;
	"ctrl_party.is_consignee": string;
	"ctrl_party.is_customer": string;
	"ctrl_party.is_customs_broker": string;
	"ctrl_party.is_dist_center": string;
	"ctrl_party.is_dl_gen": string;
	"ctrl_party.is_dl_haz": string;
	"ctrl_party.is_dl_hvl": string;
	"ctrl_party.is_dl_svc": string;
	"ctrl_party.is_drop_yard": string;
	"ctrl_party.is_geocoded": string;
	"ctrl_party.is_outside_terminal": string;
	"ctrl_party.is_prospect": string;
	"ctrl_party.is_remote_terminal": string;
	"ctrl_party.is_shipper": string;
	"ctrl_party.is_steamship": string;
	"ctrl_party.is_terminal": string;
	"ctrl_party.is_trailer_pool": string;
	"ctrl_party.is_trailer_wash": string;
	"ctrl_party.is_truckstop": string;
	"ctrl_party.last_used_date": Date;
	"ctrl_party.latitude": number;
	"ctrl_party.layover": string;
	"ctrl_party.lease_number": string;
	"ctrl_party.load_unload_count": number;
	"ctrl_party.load_unload_excl": string;
	"ctrl_party.load_unload_param": string;
	"ctrl_party.loading_instructs": string;
	"ctrl_party.loading_time": number;
	"ctrl_party.longitude": number;
	"ctrl_party.ltl_optimize_time_per_spot": number;
	"ctrl_party.ltl_seg_alloc_code": string;
	"ctrl_party.manual_exchange": string;
	"ctrl_party.max_drvr_complaint": number;
	"ctrl_party.max_pallet_balance": number;
	"ctrl_party.max_payment_code_amt": number;
	"ctrl_party.max_payment_code_amt_c": string;
	"ctrl_party.max_payment_code_amt_d": Date;
	"ctrl_party.max_payment_code_amt_n": number;
	"ctrl_party.max_payment_code_amt_r": number;
	"ctrl_party.model_flag": string;
	"ctrl_party.monday_close": Date;
	"ctrl_party.monday_open": Date;
	"ctrl_party.name": string;
	"ctrl_party.need_clean_trailer": string;
	"ctrl_party.new_field_ms": string;
	"ctrl_party.next_call_date": Date;
	"ctrl_party.no_fuel_radius_inbound": string;
	"ctrl_party.no_fuel_radius_outbound": number;
	"ctrl_party.no_stop_miles": number;
	"ctrl_party.on_duty_not_driving_mins": number;
	"ctrl_party.pallet_balance": number;
	"ctrl_party.pallets_required": string;
	"ctrl_party.payment_api_excluded": string;
	"ctrl_party.pickup_date_option": string;
	"ctrl_party.plc_uid": number;
	"ctrl_party.pnn_city_id": number;
	"ctrl_party.pnn_city_name": string;
	"ctrl_party.pnn_state": string;
	"ctrl_party.pnn_zip_code": string;
	"ctrl_party.port_id": string;
	"ctrl_party.prev_code": string;
	"ctrl_party.primary_agency": string;
	"ctrl_party.radius": number;
	"ctrl_party.regulated": string;
	"ctrl_party.reload_rqmt": string;
	"ctrl_party.reserve_mins": number;
	"ctrl_party.salesperson": string;
	"ctrl_party.saturday_close": Date;
	"ctrl_party.saturday_open": Date;
	"ctrl_party.shuttle_capacity": string;
	"ctrl_party.state": string;
	"ctrl_party.sunday_close": Date;
	"ctrl_party.sunday_open": Date;
	"ctrl_party.swap_cost": number;
	"ctrl_party.tank_operator": string;
	"ctrl_party.team_rqmt": string;
	"ctrl_party.thursday_close": Date;
	"ctrl_party.thursday_open": Date;
	"ctrl_party.trailer_pool_min": number;
	"ctrl_party.trailer_pool_radius": number;
	"ctrl_party.trailer_pool_size": number;
	"ctrl_party.trailer_wash_vend": string;
	"ctrl_party.trans_ticket": string;
	"ctrl_party.trimble_place_id": string;
	"ctrl_party.trip_starter": string;
	"ctrl_party.tuesday_close": Date;
	"ctrl_party.tuesday_open": Date;
	"ctrl_party.tw_seg_code": string;
	"ctrl_party.unload_instructs": string;
	"ctrl_party.unloading_time": number;
	"ctrl_party.website_url": string;
	"ctrl_party.wednesday_close": Date;
	"ctrl_party.wednesday_open": Date;
	"ctrl_party.worthless_field": string;
	"ctrl_party.zip_code": string;
	ctrl_party_id: string;
	cube: number;
	curr_movement_id: string;
	cust_order_no: string;
	customer_id: string;
	customer_po_num: string;
	declared_val: number;
	def_move_type: string;
	default_match_id: string;
	dim_height: string;
	dim_length: string;
	dim_surcharge: number;
	dim_surcharge_c: string;
	dim_surcharge_d: Date;
	dim_surcharge_n: number;
	dim_surcharge_r: number;
	dim_width: string;
	dispatch_opt: string;
	dl_team_reqd: string;
	dray_del_date: Date;
	dray_del_pay: number;
	dray_del_pay_c: string;
	dray_del_pay_d: Date;
	dray_del_pay_n: number;
	dray_del_pay_r: number;
	dray_del_payee: string;
	dray_del_xfer_dt: Date;
	dray_pu_date: Date;
	dray_pu_pay: number;
	dray_pu_pay_c: string;
	dray_pu_pay_d: Date;
	dray_pu_pay_n: number;
	dray_pu_pay_r: number;
	dray_pu_payee: string;
	dray_pu_xfer_dt: Date;
	duplicate_orders: string;
	edi_statuses_in: string;
	edi_statuses_out: string;
	eir1_no: string;
	eir2_no: string;
	entered_user_id: string;
	equipment_type_id: string;
	equipment_type_options: string;
	est_tolls: number;
	est_tolls_c: string;
	est_tolls_d: Date;
	est_tolls_n: number;
	est_tolls_r: number;
	excise_disable_update: string;
	excise_taxable: string;
	excisetax_total: number;
	excisetax_total_c: string;
	excisetax_total_d: Date;
	excisetax_total_n: number;
	excisetax_total_r: number;
	external_invoiceno: string;
	external_refno: string;
	extra_deliveries: number;
	extra_pickups: number;
	fgp_uid: number;
	floor_loaded_freight: string;
	force_assign: string;
	forwd_location_id: string;
	free_days: number;
	freight_charge: number;
	freight_charge_c: string;
	freight_charge_d: Date;
	freight_charge_n: number;
	freight_charge_r: number;
	has_edi: boolean;
	has_failed_orders: boolean;
	has_log_statuses: boolean;
	has_log_tenders: boolean;
	has_logistics: boolean;
	has_statuses: boolean;
	hazmat: string;
	hazmat_code: string;
	high_value: string;
	hold_reason: string;
	ic_company_from: string;
	ic_movement_from: string;
	ic_order_from: string;
	id: string;
	image_is_present: string;
	import_export: string;
	in_tender_count: string;
	in_tender_error: boolean;
	include_split_point: string;
	intelliset: string;
	invoice_recv_date: Date;
	is_auto_tonu: string;
	is_autorate_dist: string;
	is_billed: boolean;
	is_brokerage_ltl: boolean;
	is_container: string;
	is_dedicated: string;
	is_intercompany: string;
	is_local_mile: string;
	is_requested: string;
	is_split: string;
	is_tonu: string;
	jag_carrier_id: string;
	jag_cost_rate_id: number;
	last_location: string;
	ld_issue_code: string;
	ldport_location_id: string;
	linear_feet_avail: number;
	load_date: Date;
	load_issue_code: string;
	load_issue_descr: string;
	loadboard: string;
	lock_miles: string;
	ltl: string;
	markup_flat: number;
	markup_flat_c: string;
	markup_flat_d: Date;
	markup_flat_n: number;
	markup_flat_r: number;
	markup_percent: number;
	master_order_id: string;
	master_sequence: number;
	"movement.authorized": string;
	"movement.average_broker_rating": number;
	"movement.booking_no": string;
	"movement.br_details_link": string;
	"movement.br_status_link": string;
	"movement.br_track_interval": number;
	"movement.br_track_status": string;
	"movement.br_track_status_desc": string;
	"movement.br_track_vehicle_id": string;
	"movement.br_tracking_id": string;
	"movement.br_vendor": string;
	"movement.broker_avail_tract": string;
	"movement.brokerage": string;
	"movement.brokerage_status": string;
	"movement.carrier_contact": string;
	"movement.carrier_email": string;
	"movement.carrier_empty_from_city_id": number;
	"movement.carrier_fuel_pay_rate_id": number;
	"movement.carrier_lane_rate_id": string;
	"movement.carrier_override_carrier": string;
	"movement.carrier_override_user": string;
	"movement.carrier_phone": string;
	"movement.carrier_rate_id": number;
	"movement.carrier_tractor": string;
	"movement.carrier_trailer": string;
	"movement.carrier_trailer_type": string;
	"movement.chassis": string;
	"movement.checkcall_sched_id": string;
	"movement.company_id": string;
	"movement.container": string;
	"movement.container_returned": string;
	"movement.container_status": string;
	"movement.controlling_carrier_code": string;
	"movement.dedicated_order_id": string;
	"movement.dest_stop_id": string;
	"movement.digital_booking": string;
	"movement.dispatcher_user_id": string;
	"movement.eform_rate_confirmation": string;
	"movement.empty2next_order": string;
	"movement.equipment_group_id": string;
	"movement.est_tolls": number;
	"movement.est_tolls_c": string;
	"movement.est_tolls_d": Date;
	"movement.est_tolls_n": number;
	"movement.est_tolls_r": number;
	"movement.estimated_pay": number;
	"movement.estimated_pay_c": string;
	"movement.estimated_pay_d": Date;
	"movement.estimated_pay_n": number;
	"movement.estimated_pay_r": number;
	"movement.exclude_movement": string;
	"movement.fleet_manager": string;
	"movement.freight_matching_override": string;
	"movement.fuel_distance": number;
	"movement.fuel_distance_um": string;
	"movement.fuel_tax_exempt": string;
	"movement.hubtran_approved_inv_date": Date;
	"movement.hubtran_ok2pay_date": Date;
	"movement.ic_company_to": string;
	"movement.ic_order_to": string;
	"movement.id": string;
	"movement.integrated_carrier_search": string;
	"movement.invoice_recv_date": Date;
	"movement.is_container": string;
	"movement.is_dray": string;
	"movement.is_intercompany": string;
	"movement.is_local_mile": string;
	"movement.is_this_a_demo": string;
	"movement.linked_id": number;
	"movement.linked_sequence": number;
	"movement.load_booking_eligible": string;
	"movement.load_booking_source": string;
	"movement.loaded": string;
	"movement.lock_ts": number;
	"movement.locked_user_id": string;
	"movement.ltl": string;
	"movement.ltl_staging_wrs_uid": number;
	"movement.macropoint_status": string;
	"movement.manifest_empty_distance": number;
	"movement.manifest_estimated_arrival": Date;
	"movement.manifest_estimated_depart": Date;
	"movement.manifest_id": string;
	"movement.manifest_loaded_distance": number;
	"movement.manifest_reg_uid": number;
	"movement.manifest_spots_capacity": number;
	"movement.manifest_spots_consumed": number;
	"movement.manifest_weight_capacity": number;
	"movement.manifest_weight_consumed": number;
	"movement.max_buy": number;
	"movement.max_buy_c": string;
	"movement.max_buy_d": Date;
	"movement.max_buy_n": number;
	"movement.max_buy_r": number;
	"movement.max_pay_method": string;
	"movement.max_pay_units": number;
	"movement.missed_call_sent": string;
	"movement.move_distance": number;
	"movement.move_distance_um": string;
	"movement.movement_type": string;
	"movement.must_respond_by": Date;
	"movement.next_sched_call": Date;
	"movement.operational_status": string;
	"movement.operations_user": string;
	"movement.origin_stop_id": string;
	"movement.override_crny_type": string;
	"movement.override_driver2_id": number;
	"movement.override_driver2_nm": string;
	"movement.override_driver_id": number;
	"movement.override_driver_nm": string;
	"movement.override_drvr2_cell": string;
	"movement.override_drvr2_email": string;
	"movement.override_drvr_cell": string;
	"movement.override_drvr_email": string;
	"movement.override_max_pay": number;
	"movement.override_max_pay_c": string;
	"movement.override_max_pay_d": Date;
	"movement.override_max_pay_n": number;
	"movement.override_max_pay_r": number;
	"movement.override_pay_amt": number;
	"movement.override_pay_amt_c": string;
	"movement.override_pay_amt_d": Date;
	"movement.override_pay_amt_n": number;
	"movement.override_pay_amt_r": number;
	"movement.override_pay_rate": number;
	"movement.override_paye_rate": number;
	"movement.override_payee_id": string;
	"movement.override_targetpay": number;
	"movement.override_targetpay_c": string;
	"movement.override_targetpay_d": Date;
	"movement.override_targetpay_n": number;
	"movement.override_targetpay_r": number;
	"movement.override_type": string;
	"movement.override_unit_desc": string;
	"movement.override_units": number;
	"movement.pay_distance": number;
	"movement.pay_distance_um": string;
	"movement.pending_carrier_name": string;
	"movement.pending_carrier_nbr": string;
	"movement.pending_carrier_type": string;
	"movement.pending_payee_id": string;
	"movement.placard_required": string;
	"movement.preassign_sequence": number;
	"movement.priority": string;
	"movement.pro_nbr": string;
	"movement.rate_alert_basis": string;
	"movement.rate_alert_date": Date;
	"movement.rate_alert_days": number;
	"movement.rate_alert_hours": number;
	"movement.rate_alert_minutes": number;
	"movement.rate_confirm_template": number;
	"movement.rate_confirmation_sent_date": Date;
	"movement.rate_confirmation_status": string;
	"movement.rate_expiration_basis": string;
	"movement.rate_expiration_date": Date;
	"movement.rate_expiration_days": number;
	"movement.rate_expiration_hours": number;
	"movement.rate_expiration_minutes": number;
	"movement.rdy_pickup_date": Date;
	"movement.recurring_movement_id": string;
	"movement.reminder_sent": string;
	"movement.require_tracking": string;
	"movement.required_vendor": string;
	"movement.reserved": string;
	"movement.return_date": Date;
	"movement.seg_alloc_code": string;
	"movement.shipper_email": string;
	"movement.shipper_notify_frequency": string;
	"movement.shpmt_ident_type": string;
	"movement.shpmt_ident_value": string;
	"movement.ss_location_id": string;
	"movement.status": string;
	"movement.target_extra_stop_pay": number;
	"movement.target_extra_stop_pay_c": string;
	"movement.target_extra_stop_pay_d": Date;
	"movement.target_extra_stop_pay_n": number;
	"movement.target_extra_stop_pay_r": number;
	"movement.target_fuel_surcharge_pay": string;
	"movement.target_pay": number;
	"movement.target_pay_c": string;
	"movement.target_pay_d": Date;
	"movement.target_pay_method": string;
	"movement.target_pay_n": number;
	"movement.target_pay_r": number;
	"movement.target_pay_units": number;
	"movement.target_rate_data": string;
	"movement.target_rate_id": number;
	"movement.time_to_send_msg": Date;
	"movement.track_start_date": Date;
	"movement.tracking_duration_ext": number;
	"movement.tracking_notify_email": string;
	"movement.tracking_request_status": string;
	"movement.trailer_rent_pct": number;
	"movement.trip_lease": string;
	"movement.trip_mgt_recalc_datetime": Date;
	"movement.trip_mgt_tracking_id": string;
	"movement.triumphpay_exclude": string;
	"movement.trp_uid": number;
	"movement.ts_borrowing_company": string;
	"movement.ts_commodity": string;
	"movement.ts_movement_id": string;
	"movement.unlock_time": Date;
	"movement.waterfall_flood_time": Date;
	"movement.waterfall_in_progress": string;
	"movement.xfer2settle_date": Date;
	"movement.xferred2fueltax": string;
	"movement.xferred2fueltaxdt": Date;
	"movement.xmit_accepted": string;
	"movement.xmitted2driver": Date;
	"movement.zmit_trip": string;
	next_rebill: string;
	nte_lp_num: string;
	nte_lp_qual: string;
	on_hold: string;
	operational_status: string;
	operations_user: string;
	optimize: string;
	optiset: string;
	order_mode: string;
	order_type_id: string;
	order_value: number;
	order_value_c: string;
	order_value_d: Date;
	order_value_n: number;
	order_value_r: number;
	ordered_by: string;
	ordered_by_id: string;
	ordered_date: Date;
	ordered_method: string;
	orig_dest_rate_id: string;
	"origin_location.address1": string;
	"origin_location.address2": string;
	"origin_location.appt_required": string;
	"origin_location.assigned_to": string;
	"origin_location.bseg": string;
	"origin_location.category": string;
	"origin_location.cbsa_port_id": string;
	"origin_location.chassis_owner": string;
	"origin_location.chassis_pool": string;
	"origin_location.city_id": number;
	"origin_location.city_name": string;
	"origin_location.class_rqmt": string;
	"origin_location.comments": string;
	"origin_location.company_id": string;
	"origin_location.container_pool": string;
	"origin_location.container_vendor": string;
	"origin_location.cust_id": string;
	"origin_location.customer_id": string;
	"origin_location.days_between_calls": number;
	"origin_location.ded_rqmt_type": string;
	"origin_location.ded_type": string;
	"origin_location.def_commodity_desc": string;
	"origin_location.def_commodity_id": string;
	"origin_location.delivery_date_option": string;
	"origin_location.directions": string;
	"origin_location.disable_loc_rating": string;
	"origin_location.dl_gen_cost": number;
	"origin_location.dl_gen_repower": number;
	"origin_location.dl_haz_cost": number;
	"origin_location.dl_haz_repower": number;
	"origin_location.dl_hvl_cost": number;
	"origin_location.dl_hvl_repower": number;
	"origin_location.dl_load_time": number;
	"origin_location.dl_svc_cost": number;
	"origin_location.dl_svc_repower": number;
	"origin_location.dl_unload_time": number;
	"origin_location.driver_load_id": string;
	"origin_location.driver_unload_id": string;
	"origin_location.ds_capacity_cost": number;
	"origin_location.ds_model_flag": string;
	"origin_location.email_address": string;
	"origin_location.email_location": string;
	"origin_location.extra_handling_time": number;
	"origin_location.filer_code": string;
	"origin_location.foreign_port": string;
	"origin_location.friday_close": Date;
	"origin_location.friday_open": Date;
	"origin_location.fuel_price_region": string;
	"origin_location.google_place_fm_address": string;
	"origin_location.google_place_id": string;
	"origin_location.google_place_type": string;
	"origin_location.id": string;
	"origin_location.include_xref_trailers": string;
	"origin_location.insur_amt_rqrd": number;
	"origin_location.insur_amt_rqrd_c": string;
	"origin_location.insur_amt_rqrd_d": Date;
	"origin_location.insur_amt_rqrd_n": number;
	"origin_location.insur_amt_rqrd_r": number;
	"origin_location.is_active": string;
	"origin_location.is_consignee": string;
	"origin_location.is_customer": string;
	"origin_location.is_customs_broker": string;
	"origin_location.is_dist_center": string;
	"origin_location.is_dl_gen": string;
	"origin_location.is_dl_haz": string;
	"origin_location.is_dl_hvl": string;
	"origin_location.is_dl_svc": string;
	"origin_location.is_drop_yard": string;
	"origin_location.is_geocoded": string;
	"origin_location.is_outside_terminal": string;
	"origin_location.is_prospect": string;
	"origin_location.is_remote_terminal": string;
	"origin_location.is_shipper": string;
	"origin_location.is_steamship": string;
	"origin_location.is_terminal": string;
	"origin_location.is_trailer_pool": string;
	"origin_location.is_trailer_wash": string;
	"origin_location.is_truckstop": string;
	"origin_location.last_used_date": Date;
	"origin_location.latitude": number;
	"origin_location.layover": string;
	"origin_location.lease_number": string;
	"origin_location.load_unload_count": number;
	"origin_location.load_unload_excl": string;
	"origin_location.load_unload_param": string;
	"origin_location.loading_instructs": string;
	"origin_location.loading_time": number;
	"origin_location.longitude": number;
	"origin_location.ltl_optimize_time_per_spot": number;
	"origin_location.ltl_seg_alloc_code": string;
	"origin_location.manual_exchange": string;
	"origin_location.max_drvr_complaint": number;
	"origin_location.max_pallet_balance": number;
	"origin_location.max_payment_code_amt": number;
	"origin_location.max_payment_code_amt_c": string;
	"origin_location.max_payment_code_amt_d": Date;
	"origin_location.max_payment_code_amt_n": number;
	"origin_location.max_payment_code_amt_r": number;
	"origin_location.model_flag": string;
	"origin_location.monday_close": Date;
	"origin_location.monday_open": Date;
	"origin_location.name": string;
	"origin_location.need_clean_trailer": string;
	"origin_location.new_field_ms": string;
	"origin_location.next_call_date": Date;
	"origin_location.no_fuel_radius_inbound": string;
	"origin_location.no_fuel_radius_outbound": number;
	"origin_location.no_stop_miles": number;
	"origin_location.on_duty_not_driving_mins": number;
	"origin_location.pallet_balance": number;
	"origin_location.pallets_required": string;
	"origin_location.payment_api_excluded": string;
	"origin_location.pickup_date_option": string;
	"origin_location.plc_uid": number;
	"origin_location.pnn_city_id": number;
	"origin_location.pnn_city_name": string;
	"origin_location.pnn_state": string;
	"origin_location.pnn_zip_code": string;
	"origin_location.port_id": string;
	"origin_location.prev_code": string;
	"origin_location.primary_agency": string;
	"origin_location.radius": number;
	"origin_location.regulated": string;
	"origin_location.reload_rqmt": string;
	"origin_location.reserve_mins": number;
	"origin_location.salesperson": string;
	"origin_location.saturday_close": Date;
	"origin_location.saturday_open": Date;
	"origin_location.shuttle_capacity": string;
	"origin_location.state": string;
	"origin_location.sunday_close": Date;
	"origin_location.sunday_open": Date;
	"origin_location.swap_cost": number;
	"origin_location.tank_operator": string;
	"origin_location.team_rqmt": string;
	"origin_location.thursday_close": Date;
	"origin_location.thursday_open": Date;
	"origin_location.trailer_pool_min": number;
	"origin_location.trailer_pool_radius": number;
	"origin_location.trailer_pool_size": number;
	"origin_location.trailer_wash_vend": string;
	"origin_location.trans_ticket": string;
	"origin_location.trimble_place_id": string;
	"origin_location.trip_starter": string;
	"origin_location.tuesday_close": Date;
	"origin_location.tuesday_open": Date;
	"origin_location.tw_seg_code": string;
	"origin_location.unload_instructs": string;
	"origin_location.unloading_time": number;
	"origin_location.website_url": string;
	"origin_location.wednesday_close": Date;
	"origin_location.wednesday_open": Date;
	"origin_location.worthless_field": string;
	"origin_location.zip_code": string;
	otherchargetotal: number;
	otherchargetotal_c: string;
	otherchargetotal_d: Date;
	otherchargetotal_n: number;
	otherchargetotal_r: number;
	out_tender_count: string;
	out_tender_error: boolean;
	override_posting_city_id: number;
	override_rating: string;
	pallets_how_many: number;
	pallets_required: string;
	pay_gross: number;
	pay_gross_c: string;
	pay_gross_d: Date;
	pay_gross_n: number;
	pay_gross_r: number;
	pick_up_no: string;
	pieces: number;
	placard_required: string;
	planning_comment: string;
	pnn_callback: string;
	pnn_comment: string;
	pnn_comment2: string;
	pnn_loadboard_user_id: string;
	pnn_post_type: string;
	pnn_rate: number;
	pnn_rate_type: string;
	pnn_trailer_length: number;
	pnn_trailer_width: number;
	ponum: string;
	port: string;
	port_of_discharge: string;
	preload_trailer_id: string;
	preloaded: string;
	progress_bill: string;
	pta_number: string;
	rate: number;
	rate_currency_type: string;
	rate_id: number;
	rate_min_weight: number;
	rate_min_weight_um: string;
	rate_source: string;
	rate_type: string;
	rate_unit_desc: string;
	rate_units: number;
	ready_to_bill: string;
	recurring_order_id: string;
	reply_transmitted: string;
	requesting_company: string;
	return_temp: number;
	return_temp_um: string;
	return_temp_var: number;
	return_temp_var_um: string;
	revenue_code_id: string;
	revenue_share_method: string;
	rf_entry_code_required: boolean;
	round_trip: string;
	sail_date: Date;
	seal_number: string;
	seg_alloc_code: string;
	setpoint_temp: number;
	setpoint_temp_um: string;
	setpoint_var: number;
	setpoint_var_um: string;
	ship_status_to_edi: string;
	shipment_id: string;
	shipper_stop_id: string;
	shipstatus2edi_dt: Date;
	show_equipment_type_options: boolean;
	show_tracking_badge: boolean;
	spot_rate: string;
	ss_location_id: string;
	status: string;
	status_appt_date: Date;
	status_code: string;
	status_date: Date;
	status_delay_code: string;
	status_error: string;
	status_stop_id: string;
	"stop_consignee.actual_arrival": Date;
	"stop_consignee.actual_departure": Date;
	"stop_consignee.address": string;
	"stop_consignee.address2": string;
	"stop_consignee.appointment_status_id": string;
	"stop_consignee.appt_contact_name": string;
	"stop_consignee.appt_required": string;
	"stop_consignee.cases": number;
	"stop_consignee.city_id": number;
	"stop_consignee.city_name": string;
	"stop_consignee.company_id": string;
	"stop_consignee.confirmed": string;
	"stop_consignee.contact_email": string;
	"stop_consignee.contact_name": string;
	"stop_consignee.dist_from_prev": number;
	"stop_consignee.dist_from_prev_um": string;
	"stop_consignee.driver_load_unload": string;
	"stop_consignee.edi_code": string;
	"stop_consignee.edi_loc_code": string;
	"stop_consignee.eta": Date;
	"stop_consignee.eta_distance": number;
	"stop_consignee.eta_late": string;
	"stop_consignee.eta_oor": string;
	"stop_consignee.google_place_id": string;
	"stop_consignee.grouped_id": number;
	"stop_consignee.hub": number;
	"stop_consignee.ic_stop_id": string;
	"stop_consignee.id": string;
	"stop_consignee.inbound_status": string;
	"stop_consignee.late_eta_colorcode": string;
	"stop_consignee.late_route_status": string;
	"stop_consignee.latitude": number;
	"stop_consignee.lease_number": string;
	"stop_consignee.location_id": string;
	"stop_consignee.location_name": string;
	"stop_consignee.longitude": number;
	"stop_consignee.manifest_fgp_uid": number;
	"stop_consignee.manifest_reg_uid": number;
	"stop_consignee.move_dist_from_preassign": number;
	"stop_consignee.move_dist_from_preassign_um": string;
	"stop_consignee.move_dist_from_previous": number;
	"stop_consignee.move_dist_from_previous_um": string;
	"stop_consignee.movement_id": string;
	"stop_consignee.movement_sequence": number;
	"stop_consignee.operational_status": string;
	"stop_consignee.order_id": string;
	"stop_consignee.order_sequence": number;
	"stop_consignee.orig_sched_early": Date;
	"stop_consignee.orig_sched_late": Date;
	"stop_consignee.pallets_dropped": number;
	"stop_consignee.pallets_picked_up": number;
	"stop_consignee.payment_code_reviewed": string;
	"stop_consignee.phone": string;
	"stop_consignee.planned_arrival_time": Date;
	"stop_consignee.ponum": string;
	"stop_consignee.prior_uncleared_stops": string;
	"stop_consignee.projected_arrival": Date;
	"stop_consignee.pu_override_arrive_early": Date;
	"stop_consignee.pu_override_arrive_late": Date;
	"stop_consignee.rate_dist_from_previous": number;
	"stop_consignee.rate_dist_from_previous_um": string;
	"stop_consignee.refno": string;
	"stop_consignee.requested_service": string;
	"stop_consignee.sched_arrive_early": Date;
	"stop_consignee.sched_arrive_late": Date;
	"stop_consignee.seal": string;
	"stop_consignee.service_date": Date;
	"stop_consignee.showas_address": string;
	"stop_consignee.showas_address2": string;
	"stop_consignee.showas_city_id": number;
	"stop_consignee.showas_city_name": string;
	"stop_consignee.showas_location_id": string;
	"stop_consignee.showas_location_name": string;
	"stop_consignee.showas_state": string;
	"stop_consignee.showas_zip_code": string;
	"stop_consignee.signed_for_name": string;
	"stop_consignee.state": string;
	"stop_consignee.status": string;
	"stop_consignee.stop_type": string;
	"stop_consignee.tank_operator": string;
	"stop_consignee.trimble_place_id": string;
	"stop_consignee.txl_uid": number;
	"stop_consignee.volume": number;
	"stop_consignee.volume_um": string;
	"stop_consignee.weight": number;
	"stop_consignee.weight_um": string;
	"stop_consignee.zip_code": string;
	"stop_consignee.zone_id": string;
	"stop_origin.actual_arrival": Date;
	"stop_origin.actual_departure": Date;
	"stop_origin.address": string;
	"stop_origin.address2": string;
	"stop_origin.appointment_status_id": string;
	"stop_origin.appt_contact_name": string;
	"stop_origin.appt_required": string;
	"stop_origin.cases": number;
	"stop_origin.city_id": number;
	"stop_origin.city_name": string;
	"stop_origin.company_id": string;
	"stop_origin.confirmed": string;
	"stop_origin.contact_email": string;
	"stop_origin.contact_name": string;
	"stop_origin.dist_from_prev": number;
	"stop_origin.dist_from_prev_um": string;
	"stop_origin.driver_load_unload": string;
	"stop_origin.edi_code": string;
	"stop_origin.edi_loc_code": string;
	"stop_origin.eta": Date;
	"stop_origin.eta_distance": number;
	"stop_origin.eta_late": string;
	"stop_origin.eta_oor": string;
	"stop_origin.google_place_id": string;
	"stop_origin.grouped_id": number;
	"stop_origin.hub": number;
	"stop_origin.ic_stop_id": string;
	"stop_origin.id": string;
	"stop_origin.inbound_status": string;
	"stop_origin.late_eta_colorcode": string;
	"stop_origin.late_route_status": string;
	"stop_origin.latitude": number;
	"stop_origin.lease_number": string;
	"stop_origin.location_id": string;
	"stop_origin.location_name": string;
	"stop_origin.longitude": number;
	"stop_origin.manifest_fgp_uid": number;
	"stop_origin.manifest_reg_uid": number;
	"stop_origin.move_dist_from_preassign": number;
	"stop_origin.move_dist_from_preassign_um": string;
	"stop_origin.move_dist_from_previous": number;
	"stop_origin.move_dist_from_previous_um": string;
	"stop_origin.movement_id": string;
	"stop_origin.movement_sequence": number;
	"stop_origin.operational_status": string;
	"stop_origin.order_id": string;
	"stop_origin.order_sequence": number;
	"stop_origin.orig_sched_early": Date;
	"stop_origin.orig_sched_late": Date;
	"stop_origin.pallets_dropped": number;
	"stop_origin.pallets_picked_up": number;
	"stop_origin.payment_code_reviewed": string;
	"stop_origin.phone": string;
	"stop_origin.planned_arrival_time": Date;
	"stop_origin.ponum": string;
	"stop_origin.prior_uncleared_stops": string;
	"stop_origin.projected_arrival": Date;
	"stop_origin.pu_override_arrive_early": Date;
	"stop_origin.pu_override_arrive_late": Date;
	"stop_origin.rate_dist_from_previous": number;
	"stop_origin.rate_dist_from_previous_um": string;
	"stop_origin.refno": string;
	"stop_origin.requested_service": string;
	"stop_origin.sched_arrive_early": Date;
	"stop_origin.sched_arrive_late": Date;
	"stop_origin.seal": string;
	"stop_origin.service_date": Date;
	"stop_origin.showas_address": string;
	"stop_origin.showas_address2": string;
	"stop_origin.showas_city_id": number;
	"stop_origin.showas_city_name": string;
	"stop_origin.showas_location_id": string;
	"stop_origin.showas_location_name": string;
	"stop_origin.showas_state": string;
	"stop_origin.showas_zip_code": string;
	"stop_origin.signed_for_name": string;
	"stop_origin.state": string;
	"stop_origin.status": string;
	"stop_origin.stop_type": string;
	"stop_origin.tank_operator": string;
	"stop_origin.trimble_place_id": string;
	"stop_origin.txl_uid": number;
	"stop_origin.volume": number;
	"stop_origin.volume_um": string;
	"stop_origin.weight": number;
	"stop_origin.weight_um": string;
	"stop_origin.zip_code": string;
	"stop_origin.zone_id": string;
	subject_order_number: string;
	subject_order_status: string;
	subject_order_void_date: Date;
	swap: string;
	tarp_type: string;
	teams_required: string;
	temperature_max: number;
	temperature_max_um: string;
	temperature_min: number;
	temperature_min_um: string;
	test: string;
	total_charge: number;
	total_charge_c: string;
	total_charge_d: Date;
	total_charge_n: number;
	total_charge_r: number;
	totalcharge_and_excisetax: number;
	totalcharge_and_excisetax_c: string;
	totalcharge_and_excisetax_d: Date;
	totalcharge_and_excisetax_n: number;
	totalcharge_and_excisetax_r: number;
	tractor_type: string;
	vessel: string;
	vessel_cutoff_date: Date;
	voided_load_transmitted: string;
	voided_load_transmitted_dt: Date;
	weight: number;
	weight_um: string;
	xferred2billing: string;
	xferred2billing_dt: Date;
	check_credit_limit: boolean;
	contact: string;
	days_back: number;
	descr: string;
	fail_on_credit_warning: boolean;
	ids: string;
	override_max_pay: number;
	override_targetpay: number;
	search_reference_numbers: string;
	search_status_available: string;
	search_status_delivered: string;
	search_status_in_progress: string;
	search_status_void: string;
	total_orders: number;
    quote_status: string;
}

