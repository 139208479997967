import { ModelRow } from "@mcleod/core";
import { Textbox } from "./Textbox";

export class TextboxMultiValueSelector {

    private textBeforeLookup: string;

    constructor(textboxToPopulate: Textbox) {
        const fieldName = textboxToPopulate.field;
        const dataSource = textboxToPopulate.dataSource;
        textboxToPopulate.lookupModelDisplayField = "id";
        dataSource.addDisplayListener(event => {
            const value = dataSource.activeRow?.get(fieldName);
            if (value)
                dataSource.activeRow.setLookupModelData(fieldName, { id: value });
        });
        textboxToPopulate.onSelectItem = ((textbox, selection) => {
            if (selection instanceof ModelRow) {
                const selectedId: string = selection.get("id");
                let newValue;
                if (this.textBeforeLookup && this.textBeforeLookup != "*")
                    newValue = this.textBeforeLookup + "," + selectedId;
                else
                    newValue = selectedId;
                dataSource.activeRow.set(fieldName, newValue);
                dataSource.activeRow.setLookupModelData(fieldName, { id: newValue });
                textbox.displayData(dataSource.activeRow, null, 0);
                return newValue;
            }
            return null;
        });
        textboxToPopulate.addBeforeLookupModelSearchListener(event => {
            this.textBeforeLookup = event.target.text;
            event.filter.lm_search = "";
        });
    }

}
