import { DesignableObject } from "../base/DesignableObject";
import { DomEvent } from "./DomEvent";
import { Event, EventListener } from "./Event";

export interface ChangeListener extends EventListener {
    (event: ChangeEvent): void;
}

export class ChangeEvent extends Event {
    public oldValue: any;
    public newValue: any;

    constructor(target: DesignableObject, oldValue: any, newValue: any, domEvent?: DomEvent) {
        super(target, domEvent);
        this.oldValue = oldValue;
        this.newValue = newValue;
    }

    get userInitiatedChange() {
        return this.domEvent != null;
    }
}
