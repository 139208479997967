import { ComponentPropDefinitionUtil, ComponentPropDefinitions, ComponentProps } from "../../base/ComponentProps";
import { PropType } from "../../base/PropType";
import { PanelPropDefinitions } from "../panel/PanelProps";

export interface NumberEditorProps extends ComponentProps {
    buttonTheme: string;
    caption: string;
    captionVisible: boolean;
    changeValue: number;
}

let numberEditorProps: ComponentPropDefinitions;

export class NumberEditorPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (numberEditorProps == null) {
            numberEditorProps = {
                ...PanelPropDefinitions.cloneDefs(),
                buttonTheme: { type: PropType.string, defaultValue: "numberEditor.buttons", description: "This specifies a theme key to use for the plus and minus buttons." },
                caption: { type: PropType.string, visibleInMcLeodTailor: true, description: "This controls the label that appears near the input." },
                captionVisible: { type: PropType.bool, defaultValue: true, visibleInMcLeodTailor: true, description: "This toggles whether or not the caption of the Textbox will be shown.  If set to false, there will be no caption or additional whitespace where the caption would normally go." },
                changeValue: { type: PropType.number, defaultValue: 1, visibleInMcLeodTailor: true, description: "This specifies how much the value changes where the user clicks the plus or minus button" },
                maxValue: { type: PropType.number, defaultValue: Number.MAX_SAFE_INTEGER, visibleInMcLeodTailor: true, description: "This specifies the maximum value that can be entered." },
                minValue: { type: PropType.number, defaultValue: Number.MIN_SAFE_INTEGER, visibleInMcLeodTailor: true, description: "This specifies the minimum value that can be entered." },
                onChange: { type: PropType.event, category: "Events", addListenerMethod: "addChangeListener", removeListenerMethod: "removeChangeListener", description: "This specifies a function to be called when the value changes." },
            };
            ComponentPropDefinitionUtil.populateComponentPropNames(numberEditorProps);
        }
        return numberEditorProps;
    }
}
