import { DataSource, Label, Layout, Panel } from "@mcleod/components";

export abstract class AutogenLayoutDispatchCommentsErrors extends Layout {
    labelComments: Label;
    labelDispatchWarnings: Label;
    lableDispatchErrors: Label;
    panelCommentsList: Panel;
    panelDispatchComments: Panel;
    panelError: Panel;
    panelErrorList: Panel;
    panelWarning: Panel;
    panelWarningList: Panel;
    sourceComments: DataSource;
}
