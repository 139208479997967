import { DataSource, HorizontalSpacer, Label, Layout, Panel, Table, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutUILayoutUpgradeResults extends Layout {
	horizontalspacerHeader: HorizontalSpacer;
	labelTitle: Label;
	panelSummary: Panel;
	panelSummaryLeft: Panel;
	panelSummaryRight: Panel;
	sourceUpgradeResults: DataSource;
	tableResults: Table;
	textboxCreateDate: Textbox;
	textboxCreateUser: Textbox;
	textboxLayoutDescr: Textbox;
	textboxUpdateDate: Textbox;
}
