/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelLocationCommodity.ts             *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/dispatch/location-commodity";

export class AutogenModelLocationCommodity<RowType extends ModelRow> extends Endpoint<RowType, RowLocationCommodityFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowLocationCommodity extends ModelRow<RowLocationCommodityFields> {
    constructor(values?: Partial<RowLocationCommodityFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowLocationCommodityFields {
    "commodity.company_id": string;
    "commodity.cubic_units": number;
    "commodity.cubic_units_is_per_piece": string;
    "commodity.descr": string;
    "commodity.dunnage_is_per_piece": string;
    "commodity.dunnage_weight": number;
    "commodity.fgi_value": number;
    "commodity.fgi_value_c": string;
    "commodity.fgi_value_d": Date;
    "commodity.fgi_value_is_per_piece": string;
    "commodity.fgi_value_n": number;
    "commodity.fgi_value_r": number;
    "commodity.handling_units": number;
    "commodity.handling_units_is_per_piece": string;
    "commodity.hazmat": string;
    "commodity.hazmat_class_code": string;
    "commodity.hazmat_contact": string;
    "commodity.hazmat_erg_number": string;
    "commodity.hazmat_is_residue": string;
    "commodity.hazmat_number": string;
    "commodity.hazmat_packing_group": string;
    "commodity.hazmat_phone": string;
    "commodity.hazmat_proper_shipname": string;
    "commodity.hazmat_qty_code": string;
    "commodity.hazmat_ref_type_code": string;
    "commodity.hazmat_subclass_code": string;
    "commodity.height": number;
    "commodity.id": string;
    "commodity.intelliset": string;
    "commodity.is_hazmat": string;
    "commodity.is_poison": string;
    "commodity.length": number;
    "commodity.nmfc_class_code": string;
    "commodity.nmfc_code": string;
    "commodity.optiset": string;
    "commodity.packaging_type_code": string;
    "commodity.placard_required": string;
    "commodity.poison_zone": string;
    "commodity.product_sku": string;
    "commodity.req_spots": number;
    "commodity.return_var": number;
    "commodity.return_var_um": string;
    "commodity.scan_unit_type": string;
    "commodity.seats": number;
    "commodity.seats_is_per_piece": string;
    "commodity.setpoint_temp": number;
    "commodity.setpoint_temp_um": string;
    "commodity.setpoint_var": number;
    "commodity.setpoint_var_um": string;
    "commodity.signoff_text": string;
    "commodity.spots_is_per_piece": string;
    "commodity.temperature_max": number;
    "commodity.temperature_max_um": string;
    "commodity.temperature_min": number;
    "commodity.temperature_min_um": string;
    "commodity.training_url": string;
    "commodity.type_code": string;
    "commodity.weight": number;
    "commodity.weight_is_per_piece": string;
    "commodity.weight_uom_type_code": string;
    "commodity.width": number;
    "location_commodity.commodity_id": string;
    "location_commodity.company_id": string;
    "location_commodity.descr": string;
    "location_commodity.id": string;
    "location_commodity.location_id": string;
    "location_commodity.parent_row_id": string;
    "location_commodity.parent_row_type": string;
}

