import { AutogenModelOrderEdistatus, AutogenRowOrderEdistatus } from "./autogen/AutogenModelOrderEdistatus";

export class ModelOrderEdistatus extends AutogenModelOrderEdistatus<RowOrderEdistatus> {
    constructor() {
        super(RowOrderEdistatus);
    }
}

export class RowOrderEdistatus extends AutogenRowOrderEdistatus {
}
