import { FreightGroupItemCompare } from "../FreightGroupItemCompare";
import { HandlingRequirementsCompare } from "../HandlingRequirementsCompare";
import { OtherChargeCompare } from "../OtherChargeCompare";
import { Button, DataSource, HorizontalSpacer, Label, Layout, Panel, Tab, Table, Tabset } from "@mcleod/components";

export abstract class AutogenLayoutCompareOrder extends Layout {
	buttonDeselectAll: Button;
	buttonSelectAll: Button;
	horizontalspacer1: HorizontalSpacer;
	horizontalspacer5: HorizontalSpacer;
	horizontalspacer6: HorizontalSpacer;
	labelAddStops: Label;
	labelOrderChanges: Label;
	labelOtherCharges: Label;
	labelRemovedStops: Label;
	labelStopChanges: Label;
	layoutFreightGroupItemChange: FreightGroupItemCompare;
	layoutHandlingRequirementChange: HandlingRequirementsCompare;
	layoutOtherChargeCompare: OtherChargeCompare;
	panelFreightGroupItems: Panel;
	panelHandlingRequirements: Panel;
	panelOrderChanges: Panel;
	panelOtherCharges: Panel;
	panelStopAdd: Panel;
	panelStopChanges: Panel;
	panelStopRemove: Panel;
	sourceCompareGroup: DataSource;
	sourceEdiCompareItem: DataSource;
	sourceEdiCompareStopAdd: DataSource;
	sourceEdiCompareStopDelete: DataSource;
	tabAccessorials: Tab;
	tabFreightGroupItems: Tab;
	tabNewStops: Tab;
	tabOrderChanges: Tab;
	tabOtherCharges: Tab;
	tabRemovedStops: Tab;
	tabStopChanges: Tab;
	tableOrderChanges: Table;
	tableStopAdd: Table;
	tableStopChanges: Table;
	tableStopRemove: Table;
	tabsetCompare: Tabset;
}
