import { DataSource, Label, Layout, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutTerminalInfo extends Layout {
    labelDestination: Label;
    labelDestinationAddress: Textbox;
    labelDestinationContact: Textbox;
    labelDestinationEmail: Textbox;
    labelDestinationLocation: Textbox;
    labelDestinationPhoneNumber: Textbox;
    labelDestinationTerminalName: Textbox;
    labelOrigin: Label;
    panelDestination: Panel;
    panelOrigin: Panel;
    sourceTerminalInfo: DataSource;
    textboxOriginAddress: Textbox;
    textboxOriginContact: Textbox;
    textboxOriginEmail: Textbox;
    textboxOriginLocation: Textbox;
    textboxOriginPhoneNumber: Textbox;
    textboxOriginTerminalName: Textbox;
}
