import { DataSource, Layout, Panel, SearchButton, Table, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutLocationOrderHistory extends Layout {
    panel1: Panel;
    panel2: Panel;
    searchbuttonGetData: SearchButton;
    sourceLocationOrderHistory: DataSource;
    table_: Table;
    textboxBillOrderedDistance: Textbox;
    textboxDateRange: Textbox;
    textboxLocationId: Textbox;
    textboxStopType: Textbox;
}
