import { ClickEvent, DataSourceExecutionEvent, DataSourceMode, DataSourceModeChangeEvent, DropdownItem } from "@mcleod/components";
import { getUnauthSettings } from "@mcleod/core";
import { AutogenLayoutCommodity } from "./autogen/AutogenLayoutCommodity";

export class Commodity extends AutogenLayoutCommodity {
    override onLoad() {
        this.layoutTableComments.displayCopyOrderFields = false

        this.showLTLTab()

        const dropdownPerson = { caption: "Person", value: "CNID" } as DropdownItem;
        const dropdownContract = { caption: "Contract", value: "CNTC" } as DropdownItem;
        this.textboxContactType.items = [dropdownPerson, dropdownContract] as DropdownItem[];
    }

    sourceCommodityAfterModeChange(event: DataSourceModeChangeEvent) {
        if (event.newMode == DataSourceMode.SEARCH) {
            this.textboxZone.visible = true;
            this.textboxZone.text = '';
            this.textboxTypeCode.text = '';
        }
        else if (event.newMode == DataSourceMode.ADD || event.newMode == DataSourceMode.UPDATE)
            this.sourceCommodityImage.mode = event.newMode
    }

    sourceCommodityAfterExecution(event: DataSourceExecutionEvent) {
        this.configureTextboxZone();
    }

    switchPoisonInhalationHazardOnChange(event: ClickEvent) {
        this.configureTextboxZone();
    }

    configureTextboxZone() {
        this.textboxZone.visible = this.textboxZone.required = this.showPoisonZone();
    }

    showPoisonZone(): boolean {
        if (this.activeRow == null) return false;

        if (this.activeRow.getBoolean("is_poison")) return true;

        return false;
    }

    showLTLTab() {
        if (getUnauthSettings()?.company_settings?.["is_brokerage_ltl"] == true) {
            this.tabLTL.visible = true
            this.textboxTypeCode.visible = true
            const dropdownTypeCodeLTL = { caption: "LTL", value: "LTL" } as DropdownItem;
            const dropdownTypeCodeTL = { caption: "Truckload", value: "TL" } as DropdownItem;
            this.textboxTypeCode.items = [dropdownTypeCodeLTL, dropdownTypeCodeTL] as DropdownItem[];
        }
        else {
            this.tabLTL.visible = false
            this.textboxTypeCode.visible = false
        }
    }
}
