import { Switch } from "@mcleod/components";
import { AutogenLayoutComments } from "./autogen/AutogenLayoutComments";

export class Comments extends AutogenLayoutComments {

    switchCopyToOrderOnChange(event) {
        const switchCopyToOrder = event.target as Switch;
        this.textboxOrderCommentType.required = switchCopyToOrder.checked;
        if (!switchCopyToOrder.checked)
            this.textboxOrderCommentType.text = null;
    }
}
