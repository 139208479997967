import { Api } from "@mcleod/core";
import { InitiateBrokerageTracking } from "./InitiateBrokerageTracking";

export class MacroPointDataUtils {

    public async getNotificationEmails(movementId: string, panel: InitiateBrokerageTracking) {
        return Api.post("lme/powerbroker/macropoint-notification-emails",
            { movement_id: movementId })
            .then(result => {
                const notificationEmails: string[] = result?.data[0]?.notification_emails;
                if (notificationEmails != null) {
                    panel.textboxEmailAddress.text = notificationEmails.join(";");
                } else {
                    panel.textboxEmailAddress.text = "";
                }
            })
            .catch(error => panel.textboxEmailAddress.text = "");
    }

    public async getTrackingMethod(movementId: string, panel: InitiateBrokerageTracking) {
        return Api.post("lme/powerbroker/macropoint-tracking-methods",
            { movement_id: movementId })
            .then(result => {
                const trackingMethod: string = result?.data[0]?.tracking_method;
                if (trackingMethod != null && trackingMethod !== "") {
                    panel.labelMPTrackingNote.color = "default";
                    panel.labelMPTrackingNote.caption = "Carrier tracks vehicles using: " + trackingMethod;
                }
            }).catch(error => {
                panel.labelMPTrackingNote.color = "error.light";
                panel.labelMPTrackingNote.caption = "Error retrieving carrier tracking method";
            });
    }

    public async isTrackingStarted(movementId: string, panel: InitiateBrokerageTracking) {
        return Api.post("lme/powerbroker/macropoint-tracking-started", {
            movement_id: movementId
        }).then(result => {
            const isTrackingStarted: boolean = result?.data[0]?.is_tracking_started;
            const fieldsbgColor = result?.data[0]?.is_disabled_fields_bg_color;
            panel.textboxTrackStartDate.enabled = !isTrackingStarted;
            panel.textboxTrackDurationExt.enabled = !isTrackingStarted;
            panel.textboxTrackIntervalMins.enabled = !isTrackingStarted;
            panel.textboxTrackStartDate.backgroundColor = fieldsbgColor;
            panel.textboxTrackDurationExt.backgroundColor = fieldsbgColor;
            panel.textboxTrackIntervalMins.backgroundColor = fieldsbgColor;
        })
    }

    public async getDurationExt(movementId: string, panel: InitiateBrokerageTracking) {
        return Api.post("lme/powerbroker/macropoint-duration-ext", {
            movement_id: movementId
        }).then(result => {
            const durationExt: Number = result?.data[0]?.duration_ext;
            if (durationExt != null) {
                panel.textboxTrackDurationExt.text = durationExt.toString();
            } else {
                panel.textboxTrackDurationExt.text = "0";
            }
        })
            .catch(error => panel.textboxTrackDurationExt.text = "0");
    }

    public updateEquipmentIdentifiers(movementId: string) {
        Api.post("lme/powerbroker/macropoint-update-equip-ids", {
            movement_id: movementId
        });
    }

}
