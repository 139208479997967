import { Snackbar } from "@mcleod/components";
import { getLogger } from "@mcleod/core";

const log = getLogger("common/CodeEditor");

export class CodeEditor {
    public static baseURL = "http://localhost:28080";

    public static openCodeEditor(path: string, options?: any): Promise<Response> {
        if (path == null)
            Snackbar.showSnackbar("You need to save this layout before you can open the code editor.");
        else {
            let url = this.baseURL + "/open?fileName=" + encodeURI(path);
            for (const key in options)
                url += "&" + key + "=" + encodeURI(options[key].toString());
            log.info("openCodeEditor fetching", url);
            return fetch(url);
        }
    }

    public static addCodeFunction(path: string, functionName: string, options?: any): Promise<Response> {
        if (path == null)
            Snackbar.showSnackbar("You need to save this layout before you can open the code editor.");
        else {
            let url = this.baseURL + "/function?fileName=" + encodeURI(path) + "&function=" + encodeURI(functionName);
            for (const key in options)
                url += "&" + key + "=" + encodeURI(options[key].toString());
            log.info("addCodeFunction fetching", url);
            return fetch(url);
        }
    }
}
