import { Cursor, Image, ImageType, ValidationResult } from "@mcleod/components";
import { Api, Navigation, getLogger, getUnauthSettings } from "@mcleod/core";
import { AutogenLayoutSetupUser } from "./autogen/AutogenLayoutSetupUser";

const log = getLogger("common.SetupUser");
export class SetupUser extends AutogenLayoutSetupUser {

    private _token: String;
    private _minLength: number;
    private _minSpecials: number;

    onLoad() {
        this._token = new URLSearchParams(window.location.search).get('token');

        Api.post("validate-token", { token: this._token, check_user_key: true }).then(response => {
            if (response.data[0].success == true) {
                this.panelMain._element.parentElement.style.height = "100%";
                const settings = getUnauthSettings();
                this.panelContent.backgroundColor = "backgroundSubtle";
                if (settings.company_settings.header_bg_color != null)
                    this.panelHeader.backgroundColor = settings.company_settings.header_bg_color;
                if (settings.company_settings.header_logo != null)
                    this.panelLogo.add(new Image({ imageType: ImageType.IMG, imageBytes: settings.company_settings.header_logo, height: 60, width: "auto" }))
                this.imgRequirements.cursor = Cursor.POINTER;
                this.loadPasswordRequirements();
                this.setBackgroundImage();
            }
            else
                Navigation.navigateTo("common/Login");
        }).catch(reason => {
            Navigation.navigateTo("common/Login");
        });
    }

    getRouterProps() {
        return { padding: 0 };
    }

    setBackgroundImage() {
        const bytes = localStorage.getItem("loginBackground");
        if (bytes != null)
            this.panelPage.backgroundImageBytes = bytes;
        Api.search("lib/login-background", { cached_length: bytes?.length || -1 }).then(response => {
            const data = response?.login_background;
            if (data != null) {
                this.panelPage.backgroundImageBytes = data;
                localStorage.setItem("loginBackground", data);
            }
        });
    }

    buttonSaveClicked() {
        if (this.textPassword.text !== this.textConfirmPassword.text) {
            this.textPassword.validationWarning = "Passwords must match.";
            this.textConfirmPassword.validationWarning = "Passwords must match.";
        }
        else if (this.textPassword.text.length < this._minLength) {
            this.textPassword.validationWarning = "Password must have a length of at least " + this._minLength + " characters.";
            this.textConfirmPassword.validationWarning = "Password must have a length of at least " + this._minLength + " characters.";
        }
        else if (!this.meetsSpecialCharCount()) {
            this.textPassword.validationWarning = "Password must have at least " + this._minSpecials + " special character(s).";
            this.textConfirmPassword.validationWarning = "Password must have at least " + this._minSpecials + " special character(s).";
        }
        else if (this.panelContent.validateSimple()) {
            this.buttonSave.busy = true;
            this.setPassword(this.textPassword.text);
        }
    }

    buttonCancelClicked() {
        Navigation.navigateTo("");
    }

    loadPasswordRequirements() {
        Api.search("users/password/requirements", { appendPrefix: false }).then(response => {
            this._minLength = response.data[0].password_length;
            this._minSpecials = response.data[0].special_chars;
        }).catch(err => {
            log.error("Error", err);
        })
    }

    showPasswordCriteria() {
        if (this._minLength > 0) {
            this.labelMinimumLength.caption = "Password must be at least " + this._minLength + " characters long";
            this.labelMinimumLength.visible = true;
        }
        if (this._minSpecials > 0) {
            this.labelMinimumSpecials.caption = "Password must have at least " + this._minSpecials + " special character(s)";
            this.labelMinimumSpecials.visible = true;
        }
    }

    passwordLengthValidator(length: number): ValidationResult {
        if (this.textPassword.text.length < length)
            return { component: this.textPassword, isValid: false, validationMessage: "Password must have a length of at least " + length + " characters." };
    }

    meetsSpecialCharCount(): boolean {
        if (this.textPassword.text.length > 0) {
            const specialChars = "~`!#$%^&*+=-[]\\\';,/{}|\":<>?";
            let specialCharCounter = 0;
            for (let i = 0; i < this.textPassword.text.length; i++) {
                if (specialChars.includes(this.textPassword.text.charAt(i)))
                    specialCharCounter++;
            }

            if (specialCharCounter < this._minSpecials)
                return false;
        }
        return true;
    }

    setPassword(password: string) {
        Api.post("user/setup-account", { password: password, token: this._token }, { appendPrefix: false }).then(response => {
            Navigation.navigateTo("common/Login");
        }).catch(err => {
            log.error("Error", err)
        })
    }

}
