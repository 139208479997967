import { CommonTooltips } from "@mcleod/common";
import {
    ChangeEvent, CityState, ClickEvent, Component, DataDisplayEvent, DataSourceAction, DataSourceExecutionEvent,
    DataSourceMode, ForcedCase, Textbox
} from "@mcleod/components";
import {
    Api, CityUtil, CurrencyUtil, DatePart, DateUtil, DisplayType, DisplayValue, ModelRow, StringUtil
} from "@mcleod/core";
import { ExpandableFilterController } from "@mcleod/general/src/ExpandableFilterController";
import { CarrierQualification } from "./CarrierQualification";
import { AutogenLayoutLaneHistory } from "./autogen/AutogenLayoutLaneHistory";

export class LaneHistory extends AutogenLayoutLaneHistory {
    private expandableFilter: ExpandableFilterController = new ExpandableFilterController();

    override onLoad() {
        this.defaultDateRange();
        this.setupExpandableFilter();
        this.setupCityState(this.citystateStopOriginCityId, this.switchSearchMode.checked);
        this.setupCityState(this.citystateStopDestCityId, this.switchSearchMode.checked);
        this.buttonClear.enabled = false;
        this.textboxOverridePayeeId.onSelectItem = (textbox, selection) => {
            (this.layoutQualifications as CarrierQualification).clearQualificationData();
            const payeeId = (selection as ModelRow).get("id");
            if (!StringUtil.isEmptyString(payeeId)) {
                Api.search("lme/powerbroker/carrier-qualifications", { "payee_id": payeeId }).then(result => {
                    const carrierValidationData = result?.data[0];
                    (this.layoutQualifications as CarrierQualification).setQualificationData(carrierValidationData);
                    (this.layoutQualifications as CarrierQualification).textboxProfile.visible = false;
                    (this.layoutQualifications as CarrierQualification).panelResults.borderRadius = 0;
                    this.layoutQualifications.visible = true;
                });
                Api.search("lme/dispatch/carrier-unassignment-history", { "carrier_id": payeeId }).then(result => {
                    const unAssignmentHistoryData = result?.data[0];
                    this.labelAssignmentCount.caption = unAssignmentHistoryData["total_assignments"];
                    this.labelUnassignmentCount.caption = unAssignmentHistoryData["num_unassignments"];
                    this.labelPercentUnassigned.caption = unAssignmentHistoryData["percent_unassignment"] + "%";
                });
                this.sourceCarrier.search({ id: payeeId });
            }
            this.setSummaryPanel();
            return undefined;
        }
        this.textboxCustomerId.onSelectItem = (textbox, selection) => {
            const customerId = (selection as ModelRow).get("id");
            if (!StringUtil.isEmptyString(customerId)) {
                this.sourceCustomer.search({ id: customerId });
            }
            this.setSummaryPanel();
            return undefined;
        }
    }

    private defaultDateRange() {
        const firstDateString = DisplayValue.getDisplayValue(DateUtil.dateAdd(DatePart.DAY, new Date(), -365), DisplayType.DATERANGE, this.textboxDateRange.format);
        const secondDateString = DisplayValue.getDisplayValue(new Date(), DisplayType.DATERANGE, this.textboxDateRange.format);
        this.textboxDateRange.text = firstDateString + "-" + secondDateString;
    }

    private setupCityState(comp: CityState, isChecked: boolean) {
        comp.dataSourceModeChanged(DataSourceMode.SEARCH);
        comp.textCity.text = comp.textZip.text = null;
        comp.textCity.forcedCase = ForcedCase.UPPER;
        comp.textCity.addChangeListener((event: ChangeEvent) => {
            this.refreshLaneSummary(event.target as Component);
        });
        comp.textState.onSelectItem = (textbox: Textbox) => {
            this.refreshLaneSummary(textbox);
            return undefined;
        };
        comp.textZip.addChangeListener((event: ChangeEvent) => {
            this.refreshLaneSummary(event.target as Component);
        });

        if (isChecked) {
            comp.textState.caption = "State";
            comp.textZip.caption = "Zip";
        } else {
            if (comp.caption.startsWith("Origin")) {
                comp.textState.caption = "Origin State";
            } else if (comp.caption.startsWith("Dest")) {
                comp.textState.caption = "Destination State";
            }
            this.textboxRadius.text = null;
        }
        comp.textCity.visible = comp.textZip.visible = this.textboxRadius.visible = isChecked;
        comp.width = isChecked ? 550 : null;
        comp.marginRight = !isChecked ? null : 30;
    }

    private refreshLaneSummary(comp: Component) {
        const parent = comp.parent as CityState;
        let summaryComps = [];
        if (parent.caption.startsWith("Origin")) {
            summaryComps = [this.citystateOriginSummary, this.citystateOriginSummary2];
        } else if (parent.caption.startsWith("Dest")) {
            summaryComps = [this.citystateDestSummary, this.citystateDestSummary2];
        }
        summaryComps.forEach(summaryComp => {
            summaryComp.textCombined.text = CityUtil.formatCityStateZip(parent.textCity.text, parent.textState.text, parent.textZip.text);
            summaryComp.textCombined._captionLabel.color = "subtle.light";
        })
        this.setSummaryPanel()
    }

    sourceLaneHistoryAfterExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() == DataSourceAction.SEARCH && this.mainDataSource.data?.length > 0) {
            this.expandableFilter.toggle();
        }
        this.buttonClear.enabled = true;
    }

    textboxCustomerIdOnChange(event: ChangeEvent) {
        if (StringUtil.isEmptyString(event.newValue)) {
            this.sourceLaneHistory.searchRow.set("customer.id", null);
            this.setSummaryPanel();
        } else if (event.domEvent?.type === "click") {
            const textbox = event.target as Textbox;
            this.sourceLaneHistory.searchRow.set("customer.id", textbox.getFirstLookupModelData().get("id"));
        }
    }

    textboxOverridePayeeIdOnChange(event: ChangeEvent) {
        if (StringUtil.isEmptyString(event.newValue)) {
            this.sourceLaneHistory.searchRow.set("override_payee_id", null);
            this.setSummaryPanel();
        } else if (event.domEvent?.type === "click") {
            const textbox = event.target as Textbox;
            this.sourceLaneHistory.searchRow.set("override_payee_id", textbox.getFirstLookupModelData().get("id"));
        }
    }

    private setSummaryPanel() {
        const customerEntered = this.sourceLaneHistory.searchRow.get("customer.id") != null;
        const carrierEntered = this.sourceLaneHistory.searchRow.get("override_payee_id") != null;
        const laneEntered = this.citystateStopOriginCityId.textCity.text != ""
            || this.citystateStopOriginCityId.textState.text != ""
            || this.citystateStopOriginCityId.textZip.text != ""
            || this.citystateStopDestCityId.textCity.text != ""
            || this.citystateStopDestCityId.textState.text != ""
            || this.citystateStopDestCityId.textZip.text != "";

        this.labelSummary.visible = !carrierEntered && !customerEntered && !laneEntered;

        // Carrier Panels
        this.panelCarrierPrimary.visible = (carrierEntered && !customerEntered && !laneEntered);
        this.panelCarrierSecondary.visible = (carrierEntered && !laneEntered && customerEntered) || (carrierEntered && laneEntered && !customerEntered);
        this.panelCarrierThird.visible = carrierEntered && customerEntered && laneEntered;
        this.panelCarrierInfo.visible = carrierEntered;

        // Customer Panels
        this.panelCustomerPrimary.visible = customerEntered;

        // Lane Panels
        this.panelLanePrimary.visible = laneEntered && !customerEntered;
        this.panelLaneSecondary.visible = laneEntered && customerEntered;

        this.panelSecondarySummary.visible = this.panelLaneSecondary.visible || this.panelCarrierSecondary.visible;
    }

    labelCustomerNameOnDataDisplay(event: DataDisplayEvent) {
        this.setTooltipCallbacks(this.labelCustomerNamePrimary, this.sourceLaneHistory.searchRow);
    }

    labelCarrierNameOnDataDisplay(event: DataDisplayEvent) {
        this.setTooltipCallbacks(this.labelCarrierNamePrimary, this.sourceLaneHistory.searchRow);
        this.setTooltipCallbacks(this.labelCarrierNameSecondary, this.sourceLaneHistory.searchRow);
        this.setTooltipCallbacks(this.labelCarrierNameThird, this.sourceLaneHistory.searchRow);
    }

    // START - Expandable Filter Logic
    private setupExpandableFilter() {
        this.expandableFilter.initialize(this.panelFilterHeader, this.panelFilterContent);
        this.expandableFilter.setSearchBordersExpanded();
        this.expandableFilter.onClear = (event) => this.buttonClearOnClick(event);
    }
    // END - Expandable Filter Logic

    /** This is an event handler for the onChange event of switchSearchMode.  */
    switchSearchModeOnChange(event) {
        this.setupCityState(this.citystateStopOriginCityId, this.switchSearchMode.checked);
        this.setupCityState(this.citystateStopDestCityId, this.switchSearchMode.checked);
    }

    buttonSearchOnClick(event: ClickEvent) {
    if (this.inputsAreValid()) {
      this.sourceLaneHistory.search(this.sourceLaneHistory.getDataboundValues(DataSourceMode.SEARCH, null, true));
    }
  }

  inputsAreValid(): boolean {
    this.citystateStopOriginCityId.textCity.required = this.citystateStopOriginCityId.textState.required = !StringUtil.isEmptyString(this.textboxRadius.text);
    const isValid: boolean[] = [];
    isValid.push(this.citystateStopOriginCityId.textCity.validateSimple());
    isValid.push(this.citystateStopOriginCityId.textState.validateSimple());
    return isValid.every(x => x);
  }

  /** This is an event handler for the onClick event of buttonClear.  */
  buttonClearOnClick(event: ClickEvent) {
    this.layoutLaneHistoryTable.tableLaneHistory.displayData(null, null, 0);

        this.mainDataSource.searchRow.clear();
        this.textboxOverridePayeeId.clear();
        this.textboxCustomerId.clear();
        this.textboxOrdersId.clear();
        this.textboxDateRange.clear();
        this.textboxMovementStatus.clear();
        this.textboxMovementStatus.selectedItem = null;
        this.textboxRadius.clear();
        this.citystateStopOriginCityId.textState.clear();
        this.citystateStopOriginCityId.textCity.clear();
        this.citystateStopOriginCityId.textZip.clear();
        this.citystateStopDestCityId.textState.clear();
        this.citystateStopDestCityId.textCity.clear();
        this.citystateStopDestCityId.textZip.clear();
        this.citystateStopOriginCityId["_lookupModelDataForUpdate"] = null;
        this.citystateStopDestCityId["_lookupModelDataForUpdate"] = null;
        this.setSummaryPanel();

        this.setupCityState(this.citystateStopOriginCityId, this.switchSearchMode.checked);
        this.setupCityState(this.citystateStopDestCityId, this.switchSearchMode.checked);
        this.defaultDateRange();
        this.buttonClear.enabled = false;
        this.textboxOverridePayeeId.focus();
    }

    private setTooltipCallbacks(comp: Component, modelRow: ModelRow) {
        if (comp.id == null) return;
        switch (comp.id) {
            case "labelCarrierName":
            case "labelCarrierNamePrimary":
            case "labelCarrierNameSecondary":
            case "labelCarrierNameThird":
                return CommonTooltips.setTooltipFromLayoutCallback(comp, modelRow.get("override_payee_id"), "lme/powerbroker/CarrierQuickInfo", {
                    minWidth: 128,
                    minHeight: 140
                });
            case "labelCustomerName":
            case "labelCustomerNamePrimary":
                return CommonTooltips.setTooltipFromLayoutCallback(comp, modelRow.get("customer.id"), "lme/ar/CustomerQuickInfo", {
                    minWidth: 128, minHeight: 140
                });
            case "labelTotalCharge":
                return CommonTooltips.setPanelTooltipCallback(comp, () => [
                    {
                        caption: "Freight Charge",
                        displayType: DisplayType.CURRENCY,
                        text: CurrencyUtil.formatCurrency(modelRow.get("orders.freight_charge"))
                    },
                    {
                        caption: "Other Charges",
                        displayType: DisplayType.CURRENCY,
                        text: CurrencyUtil.formatCurrency(modelRow.get("orders.otherchargetotal"))
                    }]
                );
            case "labelTotalPay":
                return CommonTooltips.setPanelTooltipCallback(comp, () => [{
                        caption: "Carrier Pay",
                        displayType: DisplayType.CURRENCY,
                        text: CurrencyUtil.formatCurrency(modelRow.get("override_pay_amt"))
                    },
                        {
                            caption: "Other Pay",
                            displayType: DisplayType.CURRENCY,
                            text: CurrencyUtil.formatCurrency(modelRow.get("other_pay"))
                        }]
                );
        }
    }
}
