import { DateUtil } from "./Date";

export class DateRange extends Date {

    private _dates: Date[];
    private _beginningDate: Date;
    private _endDate: Date;

    constructor() {
        super();
        this._dates = [];
        this._dates.push(this._beginningDate);
        this._dates.push(this._endDate);
    }

    get beginningDate(): Date {
        return this._beginningDate;
    }

    set beginningDate(value: Date) {
        this._beginningDate = value;
    }

    get endDate(): Date {
        return this._endDate;
    }

    set endDate(value: Date) {
        this._endDate = value;
    }

    public static parseDateRangeString(dateRangeString: string): DateRange {
        const dateRange = new DateRange();
        if (dateRangeString != null) {
            if (dateRangeString.toString() != null && dateRangeString.toString().indexOf('~') > -1) {
                dateRange.beginningDate = DateUtil.parseDateTime(dateRangeString.split('~')[0]);
                dateRange.endDate = DateUtil.parseDateTime(dateRangeString.split('~')[1]);
            }
            else
                dateRange.beginningDate = DateUtil.parseDateTime(dateRangeString);
        }
        return dateRange;
    }

    public static parseNumericDateRange(dateRangeString: string): DateRange {
        const dateRange = new DateRange();
        if (dateRangeString != null) {
            dateRangeString = dateRangeString.replace(/\s/g, '');
            if (dateRangeString.toString().indexOf('-') > -1) {
                dateRange.beginningDate = DateUtil.parseNumericDate(DateUtil.convertDateToNumeric(dateRangeString.split('-')[0]));
                dateRange.endDate = DateUtil.parseNumericDate(DateUtil.convertDateToNumeric(dateRangeString.split('-')[1]));
            }
            else
                dateRange.beginningDate = DateUtil.parseNumericDate(DateUtil.convertDateToNumeric(dateRangeString.split('-')[0]));
        }
        return dateRange;
    }
}
