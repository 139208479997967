import { Component } from "../base/Component";
import { Panel } from "../components/panel/Panel";
import { Table } from "../components/table/Table";
import { TableRow } from "../components/table/TableRow";
import { DomEvent } from "./DomEvent";
import { Event, EventListener } from "./Event";

export interface TableRowExpansionListener extends EventListener {
    (event: TableRowExpansionEvent): void;
}

export class TableRowExpansionEvent extends Event {
    private _expandComponentParent: any = null;
    private _table: Table = null;
    private _rowIsExpanding: boolean = null;

    constructor(component: any, expandComponentParent: Component, table: Table, rowIsExpanding: boolean, domEvent?: DomEvent) {
        super(component, domEvent);
        this._expandComponentParent = expandComponentParent;
        this._table = table;
        this._rowIsExpanding = rowIsExpanding;
    }

    public get target(): TableRow {
        return super.target as TableRow;
    }

    public getTableRow(): TableRow {
        return this.target;
    }

    /**
     * This is the component that is managed by the library that is the parent 
     * for all the components that were added to the table's expansion area.
     * It's uncertain why you need it.
     */
    public get expandComponentParent(): Component {
        return this._expandComponentParent;
    }

    /**
     * Most of the time (maybe always), the event's expandComponent is a Panel and we would
     * usually be interested in the children of that Panel, which are the components that 
     * are added in the expansion area in the UI designer.
     */
    public get expandComponents(): Component[] {
        if (this._expandComponentParent instanceof Panel)
            return this._expandComponentParent.components;
        return [this.expandComponentParent];
    }

    public get table(): Table {
        return this._table;
    }

    public get isExpanding(): boolean {
        return this._rowIsExpanding;
    }
}
