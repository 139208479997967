import { Api } from "@mcleod/core/src/Api";
import { getAuthSettings } from "@mcleod/core/src/Settings";
import { AutogenLayoutCarrierQuickInfo } from "./autogen/AutogenLayoutCarrierQuickInfo";

export class CarrierQuickInfo extends AutogenLayoutCarrierQuickInfo {

    public pendingCancelled = (lock_changed: boolean, user_can_unlock: boolean) => { };

    /** This is an event handler for the onDisplay event of sourceCarriersForAssignment.  */
    sourceCarriersForAssignmentOnDisplay(event) {
        this.buttonCarrierDetail.visible = this.mainDataSource.activeRow?.get("id") != null;
        this.labelPendingCarrier.visible = this.mainDataSource.activeRow?.get("pending_carrier") == true;

        const userId = getAuthSettings()?.user_settings.user_id;
        this.buttonCancel.visible = userId == this.mainDataSource.activeRow?.get("locked_user");
    }

    async cancelPendingAssignment() {
        const response = await Api.post("lme/powerbroker/move-locking", { movement_id: this.mainDataSource.activeRow?.get("movement_id"), action: "cancel_pending" });
        this.pendingCancelled(response.data[0]?.lock_changed, response.data[0]?.user_can_unlock);
    }
}
