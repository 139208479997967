import { DataSource, DataSourceAction, DataSourceExecutionEvent, Snackbar, Table, ValidationResult } from "@mcleod/components";
import { ArrayUtil } from "@mcleod/core";
import { AutogenLayoutAgenciesWithPrimary } from "./autogen/AutogenLayoutAgenciesWithPrimary";

export class AgenciesWithPrimary extends AutogenLayoutAgenciesWithPrimary {
    private parentMainDataSource: DataSource;
    private agencyField: string;
    public displaySnackbars: boolean = false;

    override onLoad() {
        this.agencyLinkTable.onValidate = (table: Table): ValidationResult[] => {
            return this.validateTableRecords();
        }
    }

    public setup(parentAgencyLinkDataSource: DataSource, parentMainDataSource: DataSource, agencyField: string) {
        this.useProvidedAgencyLinkDataSource(parentAgencyLinkDataSource);
        this.parentMainDataSource = parentMainDataSource;
        this.agencyField = agencyField;
        if (agencyField == null) {
            const primaryColumnIndex = this.agencyLinkTable.columns.findIndex(c => c.heading.caption === "Primary");
            this.agencyLinkTable.removeColumn(primaryColumnIndex);
        } else {
            this.parentMainDataSource.addBeforeExecutionListener((event: DataSourceExecutionEvent) => this.setPrimaryAgencyOnParent());
            this.setPrimaryTogglesFromParent();
            this.sourceAgencyLink.addAfterExecutionListener((event: DataSourceExecutionEvent) => this.setPrimaryTogglesFromParent(event));
        }
    }

    public useProvidedAgencyLinkDataSource(sourceAgencyLink: DataSource) {
        this.sourceAgencyLink = sourceAgencyLink;
        this.replaceMainDatasource(this.sourceAgencyLink);
        this.displayData(null, this.mainDataSource.data, null);
    }

    private setPrimaryAgencyOnParent() {
        const primaryAgencyID = this.getPrimaryAgencyId();
        if (primaryAgencyID != null)
            this.parentMainDataSource.activeRow.set("primary_agency", primaryAgencyID);
    }

    public setPrimaryTogglesFromParent(event?: DataSourceExecutionEvent) {
        this.sourceAgencyLink.preventChangeNotifications = true;
        try {
            if (event == null || event.getAction() === DataSourceAction.SEARCH) {
                for (const row of this.sourceAgencyLink.data) {
                    const primaryAgencyOnParent = this.parentMainDataSource.activeRow.get(this.agencyField);
                    const isPrimary: boolean = row.get("agency_id") === primaryAgencyOnParent;
                    row.set("is_primary", isPrimary === true ? "Y" : "N");
                }
            }
        }
        finally {
            this.sourceAgencyLink.preventChangeNotifications = false;
        }
    }

    private validateTableRecords(): ValidationResult[] {
        return [this.validatePrimary(), this.validateDuplicates()];
    }

    private validatePrimary(): ValidationResult {
        if (this.agencyField !== null) {
            let primaryCount = 0;
            for (const row of this.agencyLinkTable.data) {
                if (row.get("is_primary") === "Y")
                    primaryCount++;
            }

            if (primaryCount > 1) {
                const message = "More than one Responsibility User record is set as primary. Please select only one user to be the primary user.";
                if (this.displaySnackbars === true)
                    this.showWarningSnackbar(message, "invalidPrimaryRF");
                return { isValid: false, validationMessage: message, component: this.agencyLinkTable };
            }
            else if (primaryCount === 0) {
                const message = "This record currently does not have a primary Responsibility User assigned.  Please choose one Responsibility User to be the primary user.";
                if (this.displaySnackbars === true)
                    this.showWarningSnackbar(message, "invalidPrimaryRF");
                return { isValid: false, validationMessage: message, component: this.agencyLinkTable };
            }
        }
        return { isValid: true, component: this.agencyLinkTable };
    }

    private validateDuplicates(): ValidationResult {
        const countPerCode: Map<string, number> = new Map();
        for (const row of this.agencyLinkTable.data) {
            const agencyId = row.get("agency_id");
            const count = countPerCode.get(agencyId);
            countPerCode.set(agencyId, count == null ? 1 : count + 1);
        }
        const duplicates: string[] = [];
        for (const entry of countPerCode.entries()) {
            if (entry[1] > 1)
                duplicates.push(entry[0]);
        }

        if (ArrayUtil.isEmptyArray(duplicates))
            return { isValid: true, component: this.agencyLinkTable };

        const message = "The following have been entered more than once: " + duplicates.join(", ") + ".  Please remove duplicate entries.";
        if (this.displaySnackbars === true)
            this.showWarningSnackbar(message, null);
        return { isValid: false, validationMessage: message, component: this.agencyLinkTable };
    }

    private showWarningSnackbar(message: string, id: string) {
        Snackbar.showWarningSnackbar(message, { persist: true, id: id })
    }

    public getPrimaryAgencyId(): string {
        for (const row of this.agencyLinkTable.data) {
            if (row.get("is_primary") === "Y")
                return row.get("agency_id");
        }
        return null;
    }

    public getPrimaryAgencyField(): string {
        if (this.parentMainDataSource["extraFields"]) {
            for (const field of this.parentMainDataSource["extraFields"]) {
                if (field.includes("primary_agency")) {
                    return field;
                }
            }
        }
        return null;
    }

    /** This is an event handler for the beforeLookupModelSearch event of textboxAgencyId.  */
    textboxAgencyIdBeforeLookupModelSearch(event) {
        event.filter = { is_active: "Y" };
    }
}
