import { ArrayUtil } from "./ArrayUtil";

export type LoginListener = (isAuthenticated: boolean) => void;

export class AuthToken {
    private static STORAGE_LOGIN_TOKEN_KEY = "auth-token";
    private static loginListeners: LoginListener[] = [];

    public static set(value: string) {
        const parsed = AuthToken.parseToken(value);
        const keepSignedIn = parsed.keep_signed_in;
        const storage = keepSignedIn ? localStorage : sessionStorage;
        const unusedStorage = keepSignedIn ? sessionStorage : localStorage;
        if (value == null)
            storage.removeItem(AuthToken.STORAGE_LOGIN_TOKEN_KEY);
        else
            storage.setItem(AuthToken.STORAGE_LOGIN_TOKEN_KEY, value);
        unusedStorage.removeItem(AuthToken.STORAGE_LOGIN_TOKEN_KEY);
        AuthToken.loginListeners.forEach(listener => listener(value != null));
    }

    public static clear() {
        AuthToken.getStorages().forEach(storage => storage.removeItem(AuthToken.STORAGE_LOGIN_TOKEN_KEY));
        AuthToken.loginListeners.forEach(listener => listener(false));
    }

    public static get(): string {
        let result = sessionStorage.getItem(AuthToken.STORAGE_LOGIN_TOKEN_KEY);
        if (result == null)
            result = localStorage.getItem(AuthToken.STORAGE_LOGIN_TOKEN_KEY);
        return result;
    }

    private static getStorages(keepSignedIn?: boolean): Storage[] {
        const storages = [localStorage, sessionStorage];
        if (keepSignedIn === false)
            storages.splice(0, 1);
        else if (keepSignedIn === true)
            storages.splice(1, 1);
        return storages;
    }

    public static isAuthenticated(): boolean {
        return AuthToken.get() != null;
    }

    public static addLoginListener(listener: LoginListener) {
        AuthToken.loginListeners.push(listener);
    }

    public static removeLoginListener(listener: LoginListener) {
        ArrayUtil.removeFromArray(AuthToken.loginListeners, listener);
    }

    public static parseToken(token: string): any {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }
}