import { AutogenLayoutCarrierInformationQuickInfo } from "./autogen/AutogenLayoutCarrierInformationQuickInfo";
import { DataDisplayEvent } from "@mcleod/components";

export class CarrierInformationQuickInfo extends AutogenLayoutCarrierInformationQuickInfo {

    labelP44VendorInfoOnDataDisplay(event: DataDisplayEvent) {
        this.labelP44VendorInfo._element.innerHTML = this.mainDataSource.data[0].get("vendor_info");

    }

    sourceQuoteBillingFreightGroupOnDisplay() {
        this.labelType.visible = this.labelContractDescr.visible = !this.sourceQuoteBillingFreightGroup.data[0].isNull("contract_type_descr")
    }
}
