import { EdiStopNotesAndReferenceNumbers } from "../EdiStopNotesAndReferenceNumbers";
import { EdiStopShowAsButton } from "../EdiStopShowAsButton";
import { CityState, DataSource, Label, Layout, Location, Panel, Switch, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutEdiStop extends Layout {
	dfXref: Label;
	ediCityState: CityState;
	ediStopPanel: Panel;
	labelAdditionalStopDetails: Label;
	labelCreatingLocation: Label;
	labelLabelBetween: Label;
	labelPickup: Label;
	layoutStopNotesAndRefNbrs: EdiStopNotesAndReferenceNumbers;
	layoutStopShowAsButton: EdiStopShowAsButton;
	panelAdditionalDetails: Panel;
	panelLateArrival: Panel;
	panelMain: Panel;
	panelStopCommentsReferenceNumbers: Panel;
	panelStopFreeForm: Panel;
	sourceAppointmentChange: DataSource;
	sourceAppointmentStatus: DataSource;
	sourceEdiStop: DataSource;
	switchApptRequired: Switch;
	switchConfirmed: Switch;
	switchPalletsRequired: Switch;
	switchPickupConsignee: Switch;
	textboxAddress2: Textbox;
	textboxAddress: Textbox;
	textboxAppointmentStatusId: Textbox;
	textboxCases: Textbox;
	textboxContactEmail: Textbox;
	textboxContactName: Textbox;
	textboxDriverLoadUnload: Textbox;
	textboxEdiLocCode: Textbox;
	textboxLocationCode: Textbox;
	textboxLocationId: Location;
	textboxLocationName: Textbox;
	textboxPalletsHowMany: Textbox;
	textboxPhone: Textbox;
	textboxSchedArriveEarly: Textbox;
	textboxSchedArriveLate: Textbox;
	textboxStopDateType: Textbox;
	textboxVolume: Textbox;
	textboxWeight: Textbox;
}
