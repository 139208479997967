import { CommonDialogs } from "@mcleod/common";
import { Layout } from "@mcleod/components";

export class DemoInputDialog extends Layout {
    getDemoPath() {
        return ["Dialogs", "Input"];
    }

    buttonShowSnackbarOnClicked() {
        CommonDialogs.showInputDialog("Enter a value").then(response => CommonDialogs.showMessage("You entered " + response));
    }
}
