import { Checkbox, DataSource, Label, Layout, Panel, Switch, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutLoadTenderExpressProfile extends Layout {
    checkboxIncludeCustomers: Checkbox;
    checkboxIncludeDest: Checkbox;
    checkboxIncludeOrig: Checkbox;
    checkboxIncludePartners: Checkbox;
    checkboxIncludeRevenue: Checkbox;
    labelMinutes: Label;
    labelRefreshTenderExpressgridevery: Label;
    panel1: Panel;
    sourceEdiLtxexpressProf: DataSource;
    switchIncludeEmpty: Switch;
    switchIncludeEmptyDest1: Switch;
    switchIncludecanceltenders: Switch;
    switchIncludechangetenders: Switch;
    switchIncludeoriginaltenders: Switch;
    switchIncludetendersmarkedforexclusion: Switch;
    switchIncludetenderswhichdonotneedreplies: Switch;
    switchMyProfile: Switch;
    switchSortDescend: Switch;
    switchUseexpressmodewhencreatingorders: Switch;
    textboxCustomerIds: Textbox;
    textboxDaysPrevious: Textbox;
    textboxDestType: Textbox;
    textboxDestZones: Textbox;
    textboxId: Textbox;
    textboxIntercompanyFilter: Textbox;
    textboxOrigType: Textbox;
    textboxOrigZones: Textbox;
    textboxPartnerIds: Textbox;
    textboxPartnerStatus: Textbox;
    textboxRefreshRate: Textbox;
    textboxRevenueCodes: Textbox;
    textboxTitle: Textbox;
}
