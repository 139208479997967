import { DataSource, Label, Layout, Panel } from "@mcleod/components";

export abstract class AutogenLayoutTodaySalesCalls extends Layout {
    labelCustomer: Label;
    labelHeaderTotal: Label;
    labelLocationProspect: Label;
    labelTotal: Label;
    labelTotalCustomer: Label;
    labelTotalProspect: Label;
    panel1: Panel;
    panelCustomer: Panel;
    panelTotal: Panel;
    sourceSalesCalls: DataSource;
}
