import { CommonTooltips } from "@mcleod/common";
import { ClickEvent, Component, DataSourceAction, DataSourceExecutionEvent, DataSourceMode, EditRowDecorator, Label, Layout, TableRowDisplayEvent } from "@mcleod/components";
import { LabelStyles } from "@mcleod/components/src/components/label/LabelStyles";
import { ForcedCase } from "@mcleod/components/src/components/textbox/ForcedCase";
import { CurrencyUtil, DisplayType, Keys, Navigation, StringUtil, UrlUtil } from "@mcleod/core";
import { makeTooltipCallbackFunction } from "./CustomerMakeQuickInfo";
import { MultiStop } from "./MultiStop";
import { AutogenLayoutOrderList } from "./autogen/AutogenLayoutOrderList";

export class OrderList extends AutogenLayoutOrderList {
    override onLoad() {
        this.textboxId.addKeyUpListener(event => {
            if (event.domEvent != null && event.key === Keys.ENTER && this.textboxId.text?.length > 0)
                this.mainDataSource.search(this.mainDataSource.getDataboundValues(DataSourceMode.SEARCH, null, true)).finally(() => {
                    if (!StringUtil.isEmptyString(window.location.search)) // remove param ids from url if needed.
                        Navigation.pseudoNavigateTo(window.location.pathname, null)
                });
        });
        this.textboxReferenceNumbers.addKeyUpListener(event => {
            if (event.domEvent != null && event.key === Keys.ENTER && this.textboxReferenceNumbers.text?.length > 0)
                this.mainDataSource.search(this.mainDataSource.getDataboundValues(DataSourceMode.SEARCH, null, true)).finally(() => {
                    if (!StringUtil.isEmptyString(window.location.search)) // remove param ids from url if needed.
                        Navigation.pseudoNavigateTo(window.location.pathname, null)
                });
        });
        const urlProp = UrlUtil.getPropsFromUrl(window.location.search)
        if (urlProp != null && urlProp.ids != null)
            this.mainDataSource.search({ ids: urlProp.ids });
        else {
            this.setSearchBordersExpanded();
        }
        this.citystateStopOriginCityId.dataSourceModeChanged(DataSourceMode.SEARCH);
        this.citystateStopDestCityId.dataSourceModeChanged(DataSourceMode.SEARCH);
        this.citystateStopOriginCityId.textCity.forcedCase = ForcedCase.UPPER;
        this.citystateStopDestCityId.textCity.forcedCase = ForcedCase.UPPER;
        this.panelOrderListContainer.rows[0].style.height = "100%"; // pretty nasty hack to make the vertical scroll bar behave
        this.buttonClear.enabled = false;
        this.switchCheckHazmat.allowIndeterminate = true;
        this.switchCheckHazmat.checked = undefined;
    }

    tableOrdersOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        if (tableRow.data != null) {
            const modelRow = tableRow.data;
            const labelMultipleMoves: Label = tableRow.findComponentById("labelImageMoveCount") as Label;
            labelMultipleMoves.visible = modelRow.get("movement_count") > 1;
            const labelCustomerName: Label = tableRow.findComponentById("labelCustomerName") as Label
            labelCustomerName.tooltipCallback = makeTooltipCallbackFunction(modelRow.get("customer_id"), labelCustomerName);
            const badgeHold: Label = tableRow.findComponentById("badgeHold") as Label;
            badgeHold.visible = modelRow.get("on_hold") === "Y";
            badgeHold.tooltipCallback = (baseTooltip, originatingEvent) => {
                return badgeHold.showTooltip(modelRow.get("hold_reason"), null, { themeKey: "quickInfo" });
            }
            const labelStops: Label = tableRow.findComponentById("labelStops") as Label;
            labelStops.setClassIncluded(LabelStyles.link, true);
            labelStops.addClickListener(() => {
                labelStops.busy = true;
                let multiStopLayout: MultiStop;
                new EditRowDecorator({
                    title: `Stop Details - Order ${modelRow.get("id")}`,
                    layout: multiStopLayout = Layout.getLayout("lme/dispatch/MultiStop") as MultiStop,
                    layoutLoadListeners: async () => {
                        await multiStopLayout.mainDataSource.search({ id: modelRow.get("id") });
                        multiStopLayout.mainDataSource.mode = DataSourceMode.NONE;
                    },
                    width: '70%',
                    overlayProps: { closeOnClickOff: false, greyedBackground: true },
                    onClose: () => multiStopLayout.slideOut(),
                    doAfterSlideIn: () => labelStops.busy = false
                });
            })
            const labelTotalCharge = tableRow.findComponentById("labelTotalCharge") as Component
            CommonTooltips.setPanelTooltipCallback(labelTotalCharge, () => [
                { caption: "Freight Charge", displayType: DisplayType.CURRENCY, text: CurrencyUtil.formatCurrency(modelRow.get("freight_charge")) },
                { caption: "Other Charges", displayType: DisplayType.CURRENCY, text: CurrencyUtil.formatCurrency(modelRow.get("otherchargetotal")) }]
            );

            const movePay = tableRow.findComponentById("labelMovePay") as Component
            CommonTooltips.setPanelTooltipCallback(movePay, () => [
                { caption: "Carrier Pay", displayType: DisplayType.CURRENCY, text: CurrencyUtil.formatCurrency(modelRow.get("movement.override_pay_amt")) },
                { caption: "Other Pay", displayType: DisplayType.CURRENCY, text: CurrencyUtil.formatCurrency(modelRow.get("other_pay")) }]
            )
        }
    }

    /** This is an event handler for the afterExecution event of sourceOrders.  */
    sourceOrdersAfterExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() == DataSourceAction.SEARCH && event.dataSource.data.length > 0) {
            const transitionProps = { speed: 100, paddingTop: 0 };
            this.panelSearch.collapse(transitionProps).then(() => { this.setSearchBordersCollapsed() });
            this.panelSearchHeader.visible = true;
        }
        this.buttonClear.enabled = true;
    }

    /** This is an event handler for the onClick event of buttonSearchExpand.  */
    buttonSearchExpandOnClick(event: ClickEvent) {
        const transitionProps = { speed: 100, paddingTop: 0 };
        if (this.panelSearch.style.height == "0px")
            this.panelSearch.expand(transitionProps).then(() => {
                this.setSearchBordersExpanded();
            });
        else
            this.panelSearch.collapse(transitionProps).then(() => {
                this.setSearchBordersCollapsed();
            });
    }

    setSearchBordersCollapsed() {
        this.buttonSearchExpand.imageRotation = 90;
        this.panelSearch.borderWidth = 0;
        this.panelSearchHeader.borderBottomWidth = 1;
        this.panelSearchHeader.borderColor = "strokeSecondary";
        this.panelSearchHeader.borderBottomRightRadius = 4;
        this.panelSearchHeader.borderBottomLeftRadius = 4;
    }

    setSearchBordersExpanded() {
        this.buttonSearchExpand.imageRotation = 0;
        this.panelSearch.borderWidth = 1
        this.panelSearch.borderColor = "strokeSecondary";
        this.panelSearch.borderRadius = 4;
        this.panelSearch.borderTopWidth = 0;
        this.panelSearch.borderTopLeftRadius = 0;
        this.panelSearch.borderTopRightRadius = 0;
        this.panelSearchHeader.borderBottomWidth = 0;
        this.panelSearchHeader.borderBottomRightRadius = 0;
        this.panelSearchHeader.borderBottomLeftRadius = 0;
    }

    /** This is an event handler for the onClick event of buttonClear.  */
    buttonClearOnClick(event: ClickEvent) {
        this.tableOrders.displayData(null, null, 0);

        this.mainDataSource.searchRow.clear();
        this.textboxCustomerId.clear();
        this.textboxRevenueCodeId.clear();
        this.textboxOriginLocation["_lookupModelDataForUpdate"] = null;
        this.textboxDestLocation["_lookupModelDataForUpdate"] = null;

        this.mainDataSource.searchRow.setValues({
            search_status_available: "Y",
            search_status_in_progress: "Y",
            search_status_delivered: "Y",
            search_status_void: "Y",
        });
        this.panelSearch.displayData(this.mainDataSource.searchRow, null, 0);
        this.buttonClear.enabled = false;
        this.textboxId.focus();
    }
}
