import { AutogenLayoutEdiStopNote } from "./autogen/AutogenLayoutEdiStopNote";

export class EdiStopNote extends AutogenLayoutEdiStopNote {

    private stopID: String;

    override onLoad() {
        this.stopID = this.owner.expandComponent.stopID;
        this.sourceEdiStopNote.search({ stop_id: this.stopID });
    }
}
