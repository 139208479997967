import { Alignment, Color, DisplayValue, Keys, ModuleLogger, Navigation, getLogger, getThemeColor, getThemeForKey } from "@mcleod/core";
import { ImageName } from "@mcleod/images";
import { ClickEvent, DomEvent, StringOrPropsOrComponent } from "../..";
import { Component } from "../../base/Component";
import { ComponentListenerDefs } from "../../base/ComponentListenerDefs";
import { ComponentPropDefinitions } from "../../base/ComponentProps";
import { ComponentTypes } from "../../base/ComponentTypes";
import { Cursor } from "../../base/Cursor";
import { ListenerListDef } from "../../base/ListenerListDef";
import { Image } from "../../components/image/Image";
import { ClickListener } from "../../events/ClickEvent";
import { KeyHandler } from "../../events/KeyHandler";
import { ModifierKeys } from "../../events/KeyModifiers";
import { DropdownProps, Overlay } from "../../page/Overlay";
import { OverlayProps } from "../../page/OverlayProps";
import { ImageProps } from "../image/ImageProps";
import { ListProps } from "../list/ListProps";
import { ButtonPropDefinitions, ButtonProps } from "./ButtonProps";
import { ButtonSize } from "./ButtonSize";
import { ButtonStyles } from "./ButtonStyles";
import { ButtonVariant } from "./ButtonVariant";

let log: ModuleLogger;
function getLog() {
    if (log == null)
        log = getLogger("components/Button");
    return log;
}

type DropdownType = StringOrPropsOrComponent[] | (() => StringOrPropsOrComponent[]);
export class Button extends Component implements ButtonProps {
    private _span: HTMLElement;
    private _dropdownClickHandler: ClickListener;

    private _cancel: boolean;
    private _caption: string;
    private _dropdownItems: DropdownType
    private _dropdownOverlayProps: Partial<OverlayProps>;
    private _dropdownProps: Partial<DropdownProps>;
    private _overlay: Overlay;
    private _default: boolean;
    private _focusable: boolean;
    private _hotkey: string;
    private _image: Image;
    private _imageAlign: Alignment;
    private _imageName: ImageName;
    private _imageNameBeforeBusy: ImageName;
    private _busy: boolean;
    private _busyWhenDataSourceBusy: boolean;
    private _size: ButtonSize;
    private _variant: ButtonVariant;
    private _wrap: boolean;
    private _imageWidth: number;
    private _imageHeight: number;
    private _imageRotation: number;
    private _imageColor: Color;
    private _imageProps: Partial<ImageProps>;
    private _allProps: Partial<ButtonProps>;
    private _link: string;
    private _linkClickHandler: ClickListener;
    private _cancelKeyHandler: KeyHandler;
    private _defaultKeyHandler: KeyHandler;
    private _hotKeyHandler: KeyHandler;

    constructor(props?: Partial<ButtonProps> | string) {
        if (typeof props === "string")
            props = { caption: props };
        super("button", props);
        this._allProps = { ...props };
        this._element.setAttribute("type", "button");
        this._span = document.createElement("span");
        this._element.append(this._span);
        this.setClassIncluded(ButtonStyles.base);
        this.setProps(props);
        if (props == null || props.cursor === undefined)
            this.cursor = Cursor.POINTER;
        if (this.wrap === false)
            this._element.style.whiteSpace = "nowrap"
        this.addClickListener(event => this.ripple(getThemeForKey("button.rippleColor"), { event: event }));
    }

    setProps(props: Partial<ButtonProps>) {
        super.setProps(props);
    }

    get allProps(): Partial<ButtonProps> {
        return this._allProps;
    }

    private handleDesignerAutoSize() {
        // if (this._designer != null) {
        //   // removing class to calculate width
        //   if (this._size != null)
        //     for (let key in ButtonSize)
        //       this.element.classList.remove("size-" + key);
        //   const compWidth = this._element.getBoundingClientRect().width;
        //   //If this method is run before DOM is loaded, all buttons set to small
        //   if (compWidth === 0)
        //     return;
        //   for (let key in ButtonSize) {
        //     const btnSizeWidth = parseInt(ButtonStyles["size-" + key].width);
        //     if (btnSizeWidth >= compWidth)

        //     if (compWidth > btnSizeWidth) {
        //       let btnSizeArray = Object.values(btnSizes);
        //       const max = btnSizeArray.reduce(function (prev, current) {
        //         return (parseInt(prev.width) > parseInt(current.width)) ? prev : current
        //       })
        //       if (btnSizeWidth === parseInt(max.width)) {
        //         this.size = key;
        //         break;
        //       }
        //     }
        //     else {
        //       this.size = key;
        //       break;
        //     }
        //   }
        // }
    }

    get caption(): string {
        return this._caption;
    }

    set caption(value: string) {
        const oldValue = this._caption;
        this._caption = value;
        this._span.innerHTML = value;
        this._adjustImageCaption(false);
        this.handleDesignerAutoSize();
        this._matchIdToValue(oldValue, value);
    }

    protected _getDefaultEventProp(): string {
        return "onClick";
    }

    get link(): string {
        return this._link;
    }

    set link(value: string) {
        this._link = value;
        if (this._linkClickHandler == null) {
            this._linkClickHandler = event => this.linkClicked();
            this.addClickListener(this._linkClickHandler)
        } else
            this.removeClickListener(this._linkClickHandler);
    }

    private linkClicked() {
        if (this.link != null) {
            const row = this?.dataSource?.activeRow;
            const link = DisplayValue.getFormattedDataString(this.link, row);
            Navigation.navigateTo(link, { newTab: true });
        }
    }

    private _triggerClickFromKeyEvent(event: ClickEvent) {
        getLog().debug("Clicked from key handler", this);
        this.clicked(event);
    }

    override createKeyHandler(key: string, listener?: (event) => void, modifiers?: ModifierKeys, scope?: HTMLElement): KeyHandler {
        if (listener == null)
            return super.createKeyHandler(key, (event) => this._triggerClickFromKeyEvent(event), modifiers, scope);
        return super.createKeyHandler(key, listener, modifiers, scope);
    }

    get hotkey(): string {
        return this._hotkey;
    }

    set hotkey(value: string) {
        if (value === this._hotkey)
            return;
        this._hotkey = value;
        if (value == null)
            this._hotKeyHandler = null;
        else
            this._hotKeyHandler = this.createKeyHandler(value);
    }

    get cancel(): boolean {
        return this._cancel;
    }

    set cancel(value: boolean) {
        if (value === this._cancel)
            return;
        this._cancel = value;
        if (value !== true)
            this._cancelKeyHandler = null;
        else
            this._cancelKeyHandler = this.createKeyHandler(Keys.ESCAPE);
    }

    get default(): boolean {
        return this._default;
    }

    set default(value: boolean) {
        if (value === this._default)
            return;
        this._default = value;
        if (value !== true)
            this._defaultKeyHandler = null;
        else
            this._defaultKeyHandler = this.createKeyHandler(Keys.ENTER);
    }

    override getKeyHandlers(): KeyHandler[] {
        let result = [];
        if (this.keyHandlers != null)
            result = [...this.keyHandlers];
        if (this._hotKeyHandler != null)
            result.push(this._hotKeyHandler);
        if (this._cancelKeyHandler != null)
            result.push(this._cancelKeyHandler);
        if (this._defaultKeyHandler != null)
            result.push(this._defaultKeyHandler);
        return result;
    }

    get focusable(): boolean {
        return this._focusable;
    }

    set focusable(value: boolean) {
        this._focusable = value;
        if (value === true)
            this._element.tabIndex = null;
        else
            this._element.tabIndex = -1;
    }

    override _applyEnabled(value: boolean): void {
        if (value) {
            this.color = this.color;
            this.backgroundColor = this.backgroundColor;
            this.borderColor = this.borderColor;
            this.cursor = this.cursor;
        }
        else {
            this._element.style.color = getThemeColor("subtle.light");
            this._element.style.cursor = "";
        }
    }

    get wrap(): boolean {
        return this._wrap;
    }

    set wrap(value: boolean) {
        this._wrap = value;
    }

    get variant(): ButtonVariant {
        return this._variant;
    }

    set variant(value: ButtonVariant) {
        this._variant = value;
        if (value === ButtonVariant.round) {
            this._element.classList.add(ButtonStyles.round);
            this._element.classList.remove(ButtonStyles.withImage);
        }
        else if (value === ButtonVariant.text) {
            this._element.classList.remove(ButtonStyles.round);
            this._element.classList.add(ButtonStyles.text);
        }
        else
            this._element.classList.remove(ButtonStyles.round);
    }

    get busyWhenDataSourceBusy(): boolean {
        return this._busyWhenDataSourceBusy;
    }

    set busyWhenDataSourceBusy(value: boolean) {
        this._busyWhenDataSourceBusy = value;
    }

    get busy(): boolean {
        return this._busy == null ? false : this._busy;
    }

    set busy(value: boolean) {
        this._busy = value;
        if (value === true) {
            this._imageNameBeforeBusy = this._imageName;
            this.imageName = "spinner";
        }
        else {
            this.imageName = this._imageNameBeforeBusy;
            delete this._imageNameBeforeBusy;
        }
        if (this._image != null)
            this._image.rotate = value;
    }

    get dropdownItems(): DropdownType {
        return this._dropdownItems;
    }

    set dropdownItems(value: DropdownType) {
        this._dropdownItems = value;
        if (value == null || value.length === 0 && typeof value !== "function") {
            if (this._dropdownClickHandler != null) {
                this.removeClickListener(this._dropdownClickHandler);
                this._dropdownClickHandler = null;
            }
        } else if (this._dropdownClickHandler == null) {
            this._dropdownClickHandler = () => this.handleDropdownClick();
            this.addClickListener(this._dropdownClickHandler);
        }
    }

    get dropdownProps() {
        return this._dropdownProps;
    }

    set dropdownProps(value) {
        this._dropdownProps = value;
    }

    get dropdownOverlayProps(): Partial<OverlayProps> {
        return this._dropdownOverlayProps;
    }

    set dropdownOverlayProps(value: Partial<OverlayProps>) {
        this._dropdownOverlayProps = value;
    }

    get dropdownListProps(): Partial<ListProps> {
        return this._dropdownOverlayProps;
    }

    set dropdownListProps(value: Partial<ListProps>) {
        this._dropdownOverlayProps = value;
    }

    get size(): ButtonSize {
        return this._size;
    }

    set size(value: ButtonSize) {
        // if ((value == null || !(value in btnSizes)) && value.indexOf("auto") === -1)
        //   return;
        // // start with a clean slate. this doesn't seem to throw any errors
        // // removing classes that aren't in list.
        // for (let key in btnSizes) {
        //   this._element.classList.remove(classes[key]);
        // }
        // if (value === "auto-size-up") {
        //   this.handleDesignerAutoSize();
        // }
        // else if (value !== "auto" && value !== "auto-size-up") {
        //   this.element.classList.add(classes[value]);
        // }
        this._size = value;
    }

    get imageName(): ImageName {
        return this._imageName;
    }

    set imageName(value: ImageName) {
        this._imageName = value;
        this._imageUpdated();
    }

    get imageWidth(): number {
        return this._imageWidth;
    }

    set imageWidth(value: number) {
        this._imageWidth = value;
        this._imageUpdated();
    }

    get imageHeight(): number {
        return this._imageHeight;
    }

    set imageHeight(value: number) {
        this._imageHeight = value;
        this._imageUpdated();
    }

    get imageRotation(): number {
        return this._imageRotation;
    }

    set imageRotation(value: number) {
        this._imageRotation = value;
        this._imageUpdated();
    }

    get imageAlign() {
        return this._imageAlign;
    }

    set imageAlign(value) {
        if (value === this._imageAlign)
            return;
        this._imageAlign = value;
        this._adjustImageCaption(true);
    }

    get imageProps(): Partial<ImageProps> {
        return this._imageProps;
    }

    set imageProps(value: Partial<ImageProps>) {
        this._imageProps = value;
        this._imageUpdated();
    }

    get imageColor(): Color {
        return this._imageColor;
    }

    set imageColor(value: Color) {
        this._imageColor = value;
    }

    _imageUpdated() {
        if (this._imageName == null && this._imageProps?.name == null) {
            if (this._image != null && this._element.contains(this._image._element))
                this._element.removeChild(this._image._element);
            this._image = null;
        }
        else {
            const props = {
                name: this._imageName,
                width: this._imageWidth,
                height: this._imageHeight,
                color: this._imageColor,
                rotation: this._imageRotation,
                ...this.imageProps
            };
            if (this._image == null)
                this._image = ComponentTypes.createComponentOfType("image", props);
            else
                this._image.setProps(props);
        }

        this._adjustImageCaption(false);
        this.handleDesignerAutoSize();
    }

    private _adjustImageCaption(alignmentChanged) {
        if (alignmentChanged && this._image != null && this._image._element.parentElement === this._element)
            this._element.removeChild(this._image._element);
        if (this._image == null) {
            this._element.classList.remove(ButtonStyles.withImage);
            return;
        }
        else if (this._image != null && !this._element.classList.contains(ButtonStyles.round)) {
            this._element.classList.add(ButtonStyles.withImage);
        }
        if (this._image._element != null && this._image._element.parentElement !== this._element) {
            if (this._imageAlign === Alignment.RIGHT)
                this._element.appendChild(this._image._element);
            else // should handle other alignments
                this._element.insertBefore(this._image._element, this._span);
        }
        if (this.caption != null) {
            if ((this.imageAlign === undefined || this.imageAlign === Alignment.LEFT) && this._image.marginRight === undefined)
                this._image.marginRight = 4;
            else if (this.imageAlign === Alignment.RIGHT && this._image.marginLeft === undefined)
                this._image.marginLeft = 4;
        }
    }

    _elementEventOverride(eventName, handler, event) {
        if (eventName === "onClick" && this.default === true)
            event.preventDefault();
    }

    async handleDropdownClick() {
        let items = this.dropdownItems;
        if (typeof items === "function")
            items = items();
        if (items instanceof Promise)
            items = await items;
        this._overlay = Overlay.showDropdown(this, items, null, { width: null, ...this.dropdownListProps }, { width: null, ...this.dropdownProps }, this.dropdownOverlayProps)._overlay;
    }

    hideDropdown() {
        if (this._overlay != null) {
            Overlay.hideOverlay(this._overlay);
        }
        this._overlay = null;
    }

    override getPropertyDefinitions(): ComponentPropDefinitions {
        return ButtonPropDefinitions.getDefinitions();
    }

    override get serializationName() {
        return "button";
    }

    override domEventFiringListeners(event: DomEvent, eventDef: ListenerListDef): boolean {
        if (eventDef === ComponentListenerDefs.click && this.busy)
            return false;
        return super.domEventFiringListeners(event, eventDef)
    }
}

ComponentTypes.registerComponentType("button", Button.prototype.constructor);
