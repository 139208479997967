import { CommonDialogs } from "@mcleod/common";
import { BlurEvent, Button, ChangeEvent, ClickEvent, DataDisplayEvent, Snackbar, Textbox } from "@mcleod/components";
import { getRelevantModelRow } from "@mcleod/components/src/base/ComponentDataLink";
import { Api, Currency, ModelRow, ProcessLock, StringUtil } from "@mcleod/core";
import { CarrierAssignmentStepper } from "@mcleod/powerbroker/src/CarrierAssignmentStepper";
import { OfferField, RowCarrierOffer } from "../../powerbroker/src/models/ModelCarrierOffer";
import { makeTooltipCallbackFunction } from "./CarrierMakeQuickInfo";
import { CONTACT_NAME_LOOKUP_MODEL, CONTACT_NAME_LOOKUP_MODEL_LAYOUT } from "./MovementOffers";
import { AutogenLayoutCarrierOfferSlideout } from "./autogen/AutogenLayoutCarrierOfferSlideout";

export type CarrierOfferInfo = {
    payee_id: string,
    carrier_name?: string,
    icc_number?: string,
    dot_number?: string,
    carrier_contact?: string,
    carrier_phone?: string,
    carrier_email?: string,
    dispatch_offer?: Currency,
    carrier_empty_from_city_id?: number
}

export class CarrierOfferSlideout extends AutogenLayoutCarrierOfferSlideout {
    public assignButtonClicked: () => void;
    public carrierAssigned: () => void;
    public assignableCheck: () => void;

    override onLoad() {
        this.tbCarrierName.onSelectItem = this._carrierSelected;
        this.tbContactName.onSelectItem = this._contactSelected;
        this.addDataDisplayListener((event: DataDisplayEvent) => {
            if (!StringUtil.isEmptyString(event.rowData.get("icc_number"))) {
                this.layoutCarrierPrequalResult.prequalCarrier("MC", event.rowData.get("icc_number"));
            } else if (!StringUtil.isEmptyString(event.rowData.get("dot_number"))) {
                this.layoutCarrierPrequalResult.prequalCarrier("DOT", event.rowData.get("dot_number"));
            }
        });

    }

    private _carrierSelected = (textbox: any, value: any): string => {
        this.mainDataSource.activeRow.setValues({
            "carrier_name": value.get("name"),
            "payee_id": value.get("id"),
            "icc_number": value.get("icc_number"),
            "dot_number": value.get("dot_number")
        });
        this.mainDataSource.displayDataInBoundComponents();
        this.tbContactName.lookupModel = CONTACT_NAME_LOOKUP_MODEL;
        this.tbContactName.lookupModelLayout = CONTACT_NAME_LOOKUP_MODEL_LAYOUT;
        this.tbCarrierName.tooltipCallback = makeTooltipCallbackFunction(value.get("id"), this.tbCarrierName);
        return undefined;
    };

    private _contactSelected = (textbox: any, value: any): string => {
        this.mainDataSource.activeRow.setValues({
            "contact_name": value.get("name"),
            "contact_phone": value.get("phone"),
            "email": value.get("email")
        });
        this.mainDataSource.displayDataInBoundComponents();
        return undefined;
    }

    public updateMargins(offerField: OfferField, marginField: string, marginTextbox: Textbox, percentTextbox: Textbox, blockSnackbar: boolean = false) {
        RowCarrierOffer.getMarginData(this.mainDataSource.activeRow, offerField).then(marginData => {
            if (!marginData) return;
            this.mainDataSource.activeRow.set(marginField, marginData.margin);
            marginTextbox.displayData(this.mainDataSource.activeRow, this.mainDataSource.data, this.mainDataSource.rowIndex);
            percentTextbox.text = marginData.margin_pct ? `${Math.round(marginData.margin_pct)}%` : "--";
        }).catch(error => {
            if (error.name === "nullTotalCharge") {
                if (!blockSnackbar) Snackbar.showWarningSnackbar(error.message)
            } else if (error.name === "nullOffer") {
                return;
            } else {
                throw error;
            }
        });
    }

    private _findSetCarrier(icc_number, dot_number) {
        if (!icc_number && !dot_number) return;
        Api.search("lme/dispatch/carrier", icc_number ? { icc_number } : { dot_number }).then(response => {
            const carrierRow = response?.data?.[0];
            if (carrierRow) {
                this.mainDataSource.activeRow.setLookupModelData("carrier_name",
                    new ModelRow(this.tbCarrierName.lookupModel, false, { name: carrierRow["payee.name"] }))
                this.mainDataSource.activeRow.setLookupModelData("contact_name",
                    new ModelRow(this.tbContactName.lookupModel, false, { name: carrierRow["contact.name"] }))
                this.mainDataSource.activeRow.setValues({
                    "carrier_name": carrierRow["payee.name"],
                    "payee_id": carrierRow["id"],
                    "icc_number": carrierRow["icc_number"],
                    "dot_number": carrierRow["dot_number"],
                    "contact_name": carrierRow["contact.name"],
                    "contact_phone": carrierRow["contact.phone"],
                    "email": carrierRow["contact.email"]
                })
                this.mainDataSource.displayDataInBoundComponents();
                if (!this.tbContactName.hasLookupModel) {
                    this.tbContactName.lookupModel = CONTACT_NAME_LOOKUP_MODEL;
                    this.tbContactName.lookupModelLayout = CONTACT_NAME_LOOKUP_MODEL_LAYOUT;
                }
            }
        });
    }

    textboxAmountOnBlur(event: BlurEvent) {
        this.updateMargins(OfferField.OFFER, "offer_margin", this.tbDispatchMargin, this.tbDispatchMarginPrcnt);
    }

    textboxCounterOfferOnBlur(event: BlurEvent) {
        this.updateMargins(OfferField.COUNTER, "counter_margin", this.tbCounterMargin, this.tbCounterMarginPrcnt);
    }

    tbContactNameBeforeLookupModelSearch(event) {
        const carrierId = (this.mainDataSource?.activeRow?.getFirstLookupModelData("carrier_name") as ModelRow)?.get("id") || this.mainDataSource?.activeRow?.get("payee_id");
        if (carrierId) {
            event.filter.parent_row_type = "P";
            event.filter.parent_row_id = carrierId;
            event.filter.is_active = "Y";
        } else {
            event.filter.id = null;
        }
    }

    tbCarrierNameOnChange(event: ChangeEvent) {
        this.mainDataSource.activeRow.set("payee_id", null);
        this.tbContactName.lookupModel = null;
        this.tbContactName.lookupModelLayout = null;
    }

    tbMcNumberOnBlur(event: BlurEvent) {
        if (event.changedWhileFocused) {
            const iccNumber = this.mainDataSource.activeRow.get("icc_number", null);
            this._findSetCarrier(iccNumber, null);
            this.assignCarrierBtnEnabled();
            if (!StringUtil.isEmptyString(iccNumber)) {
                this.layoutCarrierPrequalResult.prequalCarrier("MC", iccNumber);
            }
        }
    }

    tbDotNumberOnBlur(event: BlurEvent) {
        if (event.changedWhileFocused) {
            const dotNumber = this.mainDataSource.activeRow.get("dot_number", null);
            this._findSetCarrier(null, dotNumber);
            this.assignCarrierBtnEnabled();
            if (!StringUtil.isEmptyString(dotNumber)) {
                this.layoutCarrierPrequalResult.prequalCarrier("DOT", dotNumber);
            }
        }
    }

    tbDotNumberOnChange(event: ChangeEvent) {
        this.tbMcNumber.required = StringUtil.isEmptyString(event.newValue);
        this.tbMcNumber.validate(false, false);
    }

    assignCarrierBtnEnabled() {
        const dotNumber = this.mainDataSource.activeRow.get("dot_number", null);
        const iccNumber = this.mainDataSource.activeRow.get("icc_number", null);
        const carrierExists = !StringUtil.isEmptyString(dotNumber) || !StringUtil.isEmptyString(iccNumber);
        const disabledMessage = !carrierExists ? "Enter carrier information." : "";

        if (!StringUtil.isEmptyString(disabledMessage)) {
            this.buttonAssignCarrier.enabled = disabledMessage.length === 0;
            this.buttonAssignCarrier.disabledTooltip = disabledMessage;
        }
        else {
            if (this.assignableCheck) {
                this.assignableCheck();
            }
        }
    }

    /** This is an event handler for the onBlur event of csEmptyCity.  */
    csEmptyCityOnBlur(event: BlurEvent) {
        const cityId = this.csEmptyCity?.textCombined?.getFirstLookupModelData()?.get("id");
        const moveId = this.mainDataSource.activeRow?.get("movement_id")
        if (!StringUtil.isEmptyString(cityId) && moveId != null) {
            Api.post("lme/dispatch/get-next-stop-distance", { city_id: cityId, movement_id: moveId }).then(response => {
                this.mainDataSource.activeRow?.set("miles_to_origin", response?.data[0]?.distance);
                this.mainDataSource.displayDataInBoundComponents();
            });
        }
    }

    tbCarrierNameOnDataDisplay(event: DataDisplayEvent) {
        const textbox = event.target as Textbox;
        const modelRow = getRelevantModelRow(textbox);
        (event.target as Textbox).tooltipCallback = makeTooltipCallbackFunction(modelRow.get("payee_id"), textbox);
        this.assignCarrierBtnEnabled();
    }

    /** This is an event handler for the onClick event of buttonAssignCarrier.  */
    buttonAssignCarrierOnClick(event: ClickEvent) {
        const modelRow = this.mainDataSource.activeRow;
        if (modelRow == null)
            return;
        const workflowMessage = "Assign Carrier workflow was initiated.";
        const comments = modelRow.get("comments", "");
        modelRow.set("comments", StringUtil.isEmptyString(comments) ? workflowMessage : !comments.includes(workflowMessage) ? comments + "\n" + workflowMessage : comments);
        this.assignButtonClicked();
        ProcessLock.aquireLock("Carrier dispatch", modelRow.get("movement_id")).then(lockResult => {
            if (lockResult.success === true)
                this.assignCarrierSlideout(event.target as Button);
            else
                CommonDialogs.showDialog(lockResult.message, { title: "Assignment in Progress" });
        });
    }

    private assignCarrierSlideout(carrierAssignBtn: Button) {
        const carrierOfferInfo: CarrierOfferInfo = {
            payee_id: this.mainDataSource.activeRow?.get("payee_id", null),
            carrier_name: this.mainDataSource.activeRow?.get("carrier_name", ""),
            icc_number: this.mainDataSource.activeRow?.get("icc_number", null),
            dot_number: this.mainDataSource.activeRow?.get("dot_number", null),
            carrier_contact: this.mainDataSource.activeRow?.get("contact_name", ""),
            carrier_phone: this.mainDataSource.activeRow?.get("contact_phone", ""),
            carrier_email: this.mainDataSource.activeRow?.get("email", ""),
            dispatch_offer: this.mainDataSource.activeRow?.get("counter_offer", this.mainDataSource.activeRow?.get("amount", null))
        }

        CarrierAssignmentStepper.showAsSlideout({
            movementId: this.mainDataSource.activeRow?.get("movement_id"),
            orderId: this.mainDataSource.activeRow?.get("order_id"),
            carrierAssignmentButton: carrierAssignBtn,
            carrierOfferInfo,
            onClose: (canceled) => {
                const movementId = this.mainDataSource.activeRow.get("movement_id");
                if (!canceled) {
                    this.carrierAssigned();
                }

                ProcessLock.releaseLock("Carrier dispatch", movementId);
            }
        });
    }
}
