import { DataSource, DataSourceMode, Table, TableRow, Textbox } from "@mcleod/components";
import { CityUtil, FieldUpdateEvent, getAuthSettings, Model, ModelRow, ModelRowType } from "@mcleod/core";
import { ModelContact } from "@mcleod/general/src/models/ModelContact";
import { getDispatchControlBoolean } from "@mcleod/general/src/models/ModelDispatchControl";
import { ModelZones } from "@mcleod/general/src/models/ModelZones";
import { makeTooltipCallbackFunction } from "./LocationMakeQuickInfo";
import { AutogenLayoutRecOrderStop } from "./autogen/AutogenLayoutRecOrderStop";
import { RowLocation } from "./models/ModelLocation";

export class RecOrderStop extends AutogenLayoutRecOrderStop {
    private enforceShipper: boolean = getAuthSettings().dispatch_control[0].enforce_shipper_id == "Y";
    private _slideOutTableRow: TableRow;
    private _orderSource: DataSource;
    public calculateRates: () => void = () => { };
    public checkScheduledFields: () => void = () => { };

    public get slideOutTableRow(): TableRow {
        return this._slideOutTableRow;
    }

    public set slideOutTableRow(value: TableRow) {
        this._slideOutTableRow = value;
    }

    public get orderSource(): DataSource {
        return this._orderSource;
    }

    public set orderSource(value: DataSource) {
        this._orderSource = value;
    }

    override onLoad(): void {
        this.mainDataSource.mode = DataSourceMode.UPDATE;
        this.setFreeFormVisibility(true);
        this.setupListeners();
        this.layoutStopNotesAndRefNbrs.tabStopRefNumbers.visible = false;
    }

    initialize(isSlideIn: boolean, tableRow: TableRow, orderDataSource: DataSource) {
        this.layoutStopShowAsButton.orderSource = orderDataSource;
        this.layoutStopShowAsButton.stopRow = tableRow.data;
        const stopIndex = tableRow.index;
        const isPickupStop = stopIndex == 0;

        if (!isSlideIn) {
            if (tableRow != null) {
                this.mainDataSource.data.push(tableRow.data);
                this.mainDataSource.setRowIndexWithoutUpdate(0);
            }
            this.setTableParentDataSource(this.layoutStopNotesAndRefNbrs.tableStopComments, orderDataSource);
        }
        this.textboxLocationId.textCombined.manualAddLayout = null;
        if (this.mainDataSource.activeRow != null) {
            this.textboxLocationCode.text = this.mainDataSource.activeRow.get("location_id");
            this.textboxLocationCode.tooltipCallback = makeTooltipCallbackFunction(this.textboxLocationCode.text, this.textboxLocationCode);
        }

        if (isPickupStop) {
            this.labelPickup.visible = true;
            this.switchPickupConsignee.visible = false;
            this.textboxLocationId.visible = true;
            this.textboxLocationId.required = this.enforceShipper;

            this.switchPalletsRequired.visible = true;
            this.textboxPalletsHowMany.visible = "Y" === orderDataSource?.activeRow.get("pallets_required");
            if (isSlideIn) {
                this.switchPalletsRequired.checked = this.textboxPalletsHowMany.visible;
                if (orderDataSource?.activeRow?.get("pallets_how_many") != null)
                    this.textboxPalletsHowMany.text = orderDataSource?.activeRow?.get("pallets_how_many").toString();
            } else {
                this.textboxPalletsHowMany.dataSource = orderDataSource;
                this.switchPalletsRequired.dataSource = orderDataSource;
            }
        }

        this.textboxLocationId.googlePlacesProps = {
            customerId: "",
            enableSearch: getDispatchControlBoolean("enable_google_places"),
            createLocations: getDispatchControlBoolean("create_location_from_stop"),
            doBeforeGoogleLocationCreated: () => this.labelCreatingLocation.visible = true,
            doAfterGoogleLocationCreated: () => this.labelCreatingLocation.visible = false
        };
        if (!orderDataSource?.activeRow?.isNull("customer_id"))
            this.textboxLocationId.googlePlacesProps.customerId = orderDataSource?.activeRow?.get("customer_id");

        this.layoutStopNotesAndRefNbrs.stopRow = this.mainDataSource.activeRow;
        this.addFieldUpdateListener(this.mainDataSource.activeRow);

        this.orderSource = orderDataSource;
    }

    private setupListeners() {
        this.textboxContactName.onSelectItem = ((textbox, selection) => {
            this.mainDataSource.activeRow.set("phone", (selection as ModelRow).get("phone"));
            this.mainDataSource.activeRow.set("contact_email", (selection as ModelRow).get("email"));
            this.mainDataSource.displayDataInBoundComponents()
            return undefined;
        })
        this.textboxLocationId.addBlurListener(event => {
            const selection = this.textboxLocationId.textCombined.getFirstLookupModelData();
            this.setLocationTextAfterLocation(this.textboxLocationId.textCombined, selection as ModelRow);
            const location_id = this.mainDataSource.activeRow?.get("location_id")
            if (location_id) {
                Model.searchSingleRecord("lme/dispatch/location", { id: location_id, "updating_stop": true }).then(rowLocation => {
                    this.setDriverUnloadTextAfterLookup(new ModelRow(this.textboxDriverLoadUnload.lookupModel, false, this.getDriverLoadUnloadData(rowLocation)));
                });
            }
            this.calculateRates();
            this.setFreeFormVisibility(true);
            this.checkScheduledFields();
            return undefined;
        });
        this.citystate.textCombined.addBlurListener(event => {
            this.calculateRates();
            this.checkScheduledFields();
            return undefined;
        });
        this.switchPickupConsignee.addChangeListener((event) => {
            if (event.userInitiatedChange) {
                this.calculateRates()
                this.checkScheduledFields();
            }
        });
    }

    addFieldUpdateListener(row: ModelRow<any>) {
        if (row["_stopListener"] == null) { // keep a reference in each row to the listener to prevent adding multiple listeners if the same row is used somehow
            row["_stopListener"] = (event: FieldUpdateEvent) => {
                if (event.oldValue != event.newValue) {
                    if (event.fieldName === "location_id") {
                        this.doAfterLocationIdChanged();
                    } else if (event.fieldName === "city_id") {
                        if (event.newValue == null) {
                            row.setLookupModelData("zone_id", null);
                            row.set("zone_id", null);
                            this.textboxZoneId.displayData(row, null, 0);
                        } else {
                            new ModelZones().searchSingle({ city_id: event.newValue }).then(response => {
                                if (response != null) {
                                    const lmModelRow = new ModelRow(this.textboxZoneId.lookupModel, false, { id: response.get("id"), descr: response.get("descr") });
                                    lmModelRow.type = ModelRowType.LOOKUP_MODEL_DATA;
                                    row.setLookupModelData("zone_id", lmModelRow);
                                    row.set("zone_id", response.get("id"));
                                    this.textboxZoneId.displayData(row, null, 0);
                                }
                            });
                        }
                    }
                }
            }
            row.addAfterFieldUpdateListener(row["_stopListener"]);
        }
    }

    setTableParentDataSource(table: Table, parentSource: DataSource) {
        if (parentSource != null) {
            table.dataSource.parentDataSource = parentSource;
            const mode = parentSource.mode;
            //if we are setting the mode to ADD, we don't need the blank/empty ModelRow that setting the mode normally will create
            //the fact that the creation of the blank row is async can only mess us up; it can cause existing rows to be wiped out
            //when the blank row is done creating (which causes the table's data to be redisplayed)
            if (mode !== DataSourceMode.ADD)
                table.dataSource.mode = mode;
            else
                table.dataSource.setRowsAndMode(mode, []);
        }
    }

    /* onChange Listeners */

    doAfterLocationIdChanged() {
        const location_id = this.mainDataSource.activeRow?.get("location_id")
        if (location_id) {
            Model.searchSingleRecord("lme/dispatch/location", { id: location_id, "updating_stop": true }).then(rowLocation => {
                this.setApptRequired(rowLocation);

                this.textboxLocationId.textCombined.tooltipCallback = makeTooltipCallbackFunction(location_id, this.textboxLocationId.textCombined);
            });
        }
        this.setStopContactInfo();
    }

    switchPickupConsigneeOnChange(event) {
        const location_id = this.mainDataSource.activeRow?.get("location_id")
        if (location_id) {
            Model.searchSingleRecord("lme/dispatch/location", { id: location_id, "updating_stop": true }).then(rowLocation => {
                if (rowLocation != null)
                    this.setDriverUnloadTextAfterLookup(new ModelRow(this.textboxDriverLoadUnload.lookupModel, false, this.getDriverLoadUnloadData(rowLocation)));
            });
        }
    }

    switchPalletsRequiredOnChange(event) {
        this.textboxPalletsHowMany.visible = this.switchPalletsRequired.visible && this.switchPalletsRequired.checked;
    }

    /* beforeLookupModel Listeners */

    textboxContactNameBeforeLookupModelSearch(event) {
        if (this.mainDataSource.activeRow.get("location_id")) {
            event.filter.parent_row_type = "L";
            event.filter.parent_row_id = this.mainDataSource.activeRow.get("location_id");
            event.filter.is_active = "Y";
        } else {
            event.filter.id = null;
        }
    }

    /* Helper Methods */

    isDelivery(): boolean {
        return this.switchPickupConsignee.visible && this.switchPickupConsignee.checked;
    }

    getData(): ModelRow<any> {
        return TableRow.getContainingTableRow(this)?.data;
    }

    private setApptRequired(rowLocation: ModelRow<any>) {
        this.mainDataSource.activeRow.set("appt_required", rowLocation?.get("appt_required"));
        this.switchApptRequired.displayData(this.mainDataSource.activeRow, null, 0);
    }

    setLocationTextAfterLocation(textBox: Textbox, row: ModelRow, locationIdField?: string) {
        const data = row?.data
        const source = row?.data["source_name"];

        if (locationIdField == null)
            locationIdField = "id";

        if (source == "CITY") {
            textBox.text = CityUtil.formatCityStateZip(data?.["city_name"], data?.["state"], data?.["zip_code"]);
        }
        else {
            this.textboxLocationCode.text = data?.[locationIdField];
            this.textboxLocationCode.tooltipCallback = makeTooltipCallbackFunction(data?.[locationIdField], this.textboxLocationCode);
            this.textboxLocationName.displayData(this.mainDataSource.activeRow, null, 0);
            this.textboxAddress.displayData(this.mainDataSource.activeRow, null, 0);
            this.textboxAddress2.displayData(this.mainDataSource.activeRow, null, 0);
        }
        this.citystate.displayData(this.mainDataSource.activeRow, null, 0);
    }

    private setStopContactInfo() {
        const location_id = this.mainDataSource.activeRow.get("location_id")
        this.textboxContactName.clear();
        this.textboxPhone.clear();
        this.textboxContactEmail.clear();
        if (!location_id) {
            this.mainDataSource.activeRow.setLookupModelData("contact_name", null);
            this.mainDataSource.activeRow.set({ "contact_name": null, "phone": null, "contact_email": null });
            this.textboxContactName.displayData(this.mainDataSource.activeRow, null, 0);
            this.textboxPhone.displayData(this.mainDataSource.activeRow, null, 0)
            this.textboxContactEmail.displayData(this.mainDataSource.activeRow, null, 0)
        } else {
            new ModelContact().searchSingle({ "parent_row_type": "L", "parent_row_id": location_id }).then(result => {

                const contactData = result?.data;
                if (!contactData)
                    return;

                this.mainDataSource.activeRow.setLookupModelData("contact_name", contactData);

                if (contactData["name"]) {
                    this.mainDataSource.activeRow.set("contact_name", contactData["name"]);
                    this.textboxContactName.validate(false, false);
                    this.textboxContactName.displayData(this.mainDataSource.activeRow, null, null);
                }

                if (contactData["phone"]) {
                    this.mainDataSource.activeRow.set("phone", contactData["phone"]);
                    this.textboxPhone.validate(false, false);
                    this.textboxPhone.displayData(this.mainDataSource.activeRow, null, null);
                }

                if (contactData["email"]) {
                    this.mainDataSource.activeRow.set("contact_email", contactData["email"]);
                    this.textboxContactEmail.validate(false, false);
                    this.textboxContactEmail.displayData(this.mainDataSource.activeRow, null, null);
                }
            });
        }

    }

    getDriverLoadUnloadData(rowLocation: RowLocation): any {
        const loadUnloadField = this.isDelivery() ? "driver_unload_id" : "driver_load_id";
        let loadUnloadModelRowData = rowLocation.getFirstLookupModelData(loadUnloadField);
        if (loadUnloadModelRowData == null)
            loadUnloadModelRowData = { descr: 'No driver loading or unload', id: 'N' };
        else if (loadUnloadModelRowData instanceof ModelRow)
            loadUnloadModelRowData = loadUnloadModelRowData.data;
        return loadUnloadModelRowData;
    }

    setDriverUnloadTextAfterLookup(driverUnloadLookupData: ModelRow) {
        this.mainDataSource.activeRow.setLookupModelData("driver_load_unload", driverUnloadLookupData);
        this.mainDataSource.activeRow.set("driver_load_unload", driverUnloadLookupData.get("id"));
        if (this.mainDataSource.activeRow._appending)
            this.textboxDriverLoadUnload.displayData(this.mainDataSource.activeRow, null, 0);
    }

    setFreeFormVisibility(value: boolean) {
        this.panelStopFreeForm.visible = value;
        if (value) {
            const enable: boolean = this.mainDataSource.activeRow?.get("location_id") == null;
            this.citystate.enabled = enable;
            this.citystate.enabledDuringAdd = enable;
            this.citystate.enabledDuringUpdate = enable;
        }
    }
}
