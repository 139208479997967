import { getThemeColor } from "@mcleod/core";
import { HorizontalSpacer } from "../../page/HorizontalSpacer";
import { Button } from "../button/Button";
import { Image } from "../image/Image";
import { Label } from "../label/Label";
import { Panel } from "../panel/Panel";

export class EllipsisPopup extends Panel {
    constructor(components: Label[], anchor: Button, onItemSelect: (event) => void) {
        super({
            minWidth: 150,
            borderRadius: 4,
            backgroundColor: getThemeColor("subtle.lightest"),
            borderWidth: 1,
            borderColor: getThemeColor("strokeSecondary"),
        });
        this._element.style.overflowY = "auto";
        this._element.style.overflowX = "hidden";
        this._element.style.outline = "none";
        this._element.style.boxShadow = "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)";
        this.add(new HorizontalSpacer());
        this.add(new Image({ name: "ellipsis", height: 18, width: 18, color: "primary", marginTop: 4, marginBottom: 4 }))
        if (components != null) {
            for (const label of components) {
                label.addClickListener(onItemSelect);
                this.add(label);
            }
        }
    }
}
