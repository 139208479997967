import { ChangeEvent, ClickEvent, DataDisplayEvent, DataSourceMode, Label, Snackbar, TableRowBeforeSaveEvent, Textbox } from "@mcleod/components";
import { Api, ModelRow, StringUtil } from "@mcleod/core";
import { AutogenLayoutPbwLoadboardServicesSlideout } from "./autogen/AutogenLayoutPbwLoadboardServicesSlideout";

const API_SERVICE_TYPES = ['C', 'R'];

export class PbwLoadboardServicesSlideout extends AutogenLayoutPbwLoadboardServicesSlideout {

    override onLoad(): void | Promise<void> {

        this.addDataDisplayListener((event: DataDisplayEvent) => {
            if (this.mainDataSource?.activeRow?.get("id", null)
                && this.mainDataSource?.activeRow?.get("service_type", null)) {
                const serviceType = this.mainDataSource?.activeRow?.get("service_type", null);
                this.configureFieldsByServiceType(serviceType);
            }

            if (this.mainDataSource.activeRow.get("truckstop_tokens_exist")) {
                this.labelResult.imageName = "circleCheck";
                this.labelResult.imageColor = "success";
            } else {
                this.labelResult.imageName = "circleX";
                this.labelResult.imageColor = "error";
                this.labelResult.tooltip = "Tokens do not exist for this service. Please authorize the service."
            }
        })


        this.layoutLoadboardUsersDat.tablePbwUserDat.addRowBeforeSaveListener((event: TableRowBeforeSaveEvent) => {
            Api.post("lme/general/pbw-loadboard-users", {
                loadboard_id: this.mainDataSource?.activeRow?.get("id", null),
                users: [event.getTableRow().data],
                service_type: "C"
            }).then(response => response?.data[0])
                .then((data) => {
                    const targetRow = this.layoutLoadboardUsersDat.tablePbwUserDat.allRows
                        .find(row => (row.data as ModelRow).get("user_id") === data["user_id"]);
                    const datError = data["dat_error"];
                    const labelDatStatus = targetRow.findComponentById("labelDatStatus") as Label;
                    if (StringUtil.isEmptyString(datError)) {
                        labelDatStatus.imageName = "circleCheck";
                        labelDatStatus.imageColor = "success";
                    } else {
                        labelDatStatus.imageName = "circleX";
                        labelDatStatus.imageColor = "error";
                        labelDatStatus.tooltip = datError;
                        (targetRow.data as ModelRow).set("dat_error", datError);
                    }
                })
        });

        this.layoutLoadboardUsersTruckstop.tablePbwUserTruckstop.addRowBeforeSaveListener((event: TableRowBeforeSaveEvent) => {
            Api.post("lme/general/pbw-loadboard-users", {
                loadboard_id: this.mainDataSource?.activeRow?.get("id", null),
                users: [event.getTableRow().data],
                service_type: "R"
            }).then(response => response?.data[0])
                .then((data) => {
                    const targetRow = this.layoutLoadboardUsersTruckstop.tablePbwUserTruckstop.allRows
                        .find(row => (row.data as ModelRow).get("user_id") === data["user_id"]);
                    const labelTruckstopStatus = targetRow.findComponentById("labelTruckstopStatus") as Label;

                    if (!StringUtil.isEmptyString(data.truckstop_access_token)
                        && !StringUtil.isEmptyString(data.truckstop_refresh_token)) {
                        labelTruckstopStatus.imageName = "circleCheck";
                        labelTruckstopStatus.imageColor = "success";
                    } else {
                        labelTruckstopStatus.imageName = "circleX";
                        labelTruckstopStatus.imageColor = "error";
                        labelTruckstopStatus.tooltip = "Tokens not found. Please authorize for new credentials.";
                    }
                })
        })
    }

    switchShowHideSftpOnClick() {
        this.panelSftpOptions.visible = this.switchShowHideSftp.checked;
    }

    textboxServiceTypeOnChange(event: ChangeEvent) {
        const serviceType: string = (event.target as Textbox).selectedItem.value;
        this.configureFieldsByServiceType(serviceType);
    }

    configureFieldsByServiceType(serviceType: string) {
        this.configureFieldsForApi(serviceType);
        this.configureFieldsForDatConnexion(serviceType);
        this.configureFieldsForTruckstopRest(serviceType);
    }

    configureFieldsForApi(serviceType: string) {
        const isApiServiceType = API_SERVICE_TYPES.includes(serviceType);
        this.labelAssociatedUsers.visible = isApiServiceType;
        this.panelOptions.visible = !isApiServiceType;
    }

    configureFieldsForDatConnexion(serviceType: string) {
        const isDatConnexionServiceType: boolean = serviceType == 'C';
        this.panelDatUserTable.visible = isDatConnexionServiceType;
        if (isDatConnexionServiceType) {
            this.layoutLoadboardUsersDat.mainDataSource.mode = DataSourceMode.UPDATE;
            this.layoutLoadboardUsersDat.mainDataSource.search({
                loadboard_id: this.mainDataSource?.activeRow?.get("id", null),
                service_type: serviceType
            })
        }
    }

    configureFieldsForTruckstopRest(serviceType: string) {
        const isTruckstopRestServiceType: boolean = serviceType == 'R';
        this.textboxUserId.enabled = !isTruckstopRestServiceType;
        this.textboxPassword.enabled = !isTruckstopRestServiceType;
        this.panelTruckstop.visible = isTruckstopRestServiceType;
        this.panelTruckstopUserTable.visible = isTruckstopRestServiceType;
        if (isTruckstopRestServiceType) {
            this.textboxUserId.clear();
            this.textboxPassword.clear();
            this.textboxVendorUserId.caption = "Truckstop User ID";
            this.layoutLoadboardUsersTruckstop.mainDataSource.mode = DataSourceMode.UPDATE;
            this.layoutLoadboardUsersTruckstop.mainDataSource.search({
                loadboard_id: this.mainDataSource?.activeRow?.get("id", null),
                service_type: serviceType
            })
        } else {
            this.textboxClientId.clear();
            this.textboxClientSecret.clear();
        }
    }

    buttonAuthorizeOnClick(event: ClickEvent) {
        this.labelResult.imageColor = "primary";
        this.labelResult.busy = true;
        const [clientId, clientSecret, tUser, tPass] = [
            this.textboxClientId.text,
            this.textboxClientSecret.text,
            this.textboxVendorUserId.text,
            this.textboxTranscorePassword.text
        ];
        this.validateTruckstop(clientId, clientSecret, tUser, tPass, this.labelResult,
            (response) => {
                this.labelResult.busy = false;
                if (response.data[0].success) {
                    this.mainDataSource.activeRow.set("access_token", response.data[0].access);
                    this.mainDataSource.activeRow.set("refresh_token", response.data[0].refresh);
                    this.mainDataSource.activeRow.set("token_expires", response.data[0].expire);

                    this.labelResult.imageName = "circleCheck";
                    this.labelResult.imageColor = "success";
                    this.textboxTranscorePassword.clear();
                    Snackbar.showSnackbar("Success: Access tokens saved, Truckstop password not saved");
                } else {
                    this.labelResult.busy = false;
                    this.labelResult.imageName = "circleX";
                    this.labelResult.imageColor = "error";
                    this.labelResult.tooltip = response.data[0].error ? response.data[0].error : "There was an unexpected error Authorizing access."
                }
            }
        );
    }

    validateTruckstop(clientId: string, clientSecret: string, tUser: string, tPass: string, labelTruckstopResult: Label, responseCallback) {
        if (StringUtil.isEmptyString(clientId)
            || StringUtil.isEmptyString(clientSecret)
            || StringUtil.isEmptyString(tUser)
            || StringUtil.isEmptyString(tPass)
        ) {
            labelTruckstopResult.imageName = null;
            labelTruckstopResult.imageColor = "primary";
            labelTruckstopResult.busy = false;
            labelTruckstopResult.tooltip = null;
            Snackbar.showWarningSnackbar("Please provide all Truckstop fields (Client ID, Secret, Truckstop User ID, Password");
        } else {
            Api.post("lme/powerbroker/truckstop-auth", {
                "id": this.mainDataSource.activeRow.get("id", ""),
                "client_id": clientId,
                "client_secret": clientSecret,
                "truckstop_user": tUser,
                "truckstop_pass": tPass
            }).then(responseCallback)
                .catch(reason => {
                    labelTruckstopResult.busy = false
                    labelTruckstopResult.tooltip = reason;
                });
        }
    }
}
