import { HorizontalAlignment } from "@mcleod/core";
import { ComponentPropDefinitionUtil, ComponentPropDefinitions, ComponentProps } from "../../base/ComponentProps";
import { TableRowMode } from "./TableRow";

export interface TableRowProps extends ComponentProps {
    data: any;
    expandable: boolean;
    expanded: boolean;
    index: number;
    mode: TableRowMode;
    placeholderHeight: number;
    virtualized: boolean;
    toolsAlignment: HorizontalAlignment;
}

let tableRowProps: ComponentPropDefinitions;

export class TableRowPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (tableRowProps == null) {
            tableRowProps = {
                ...ComponentPropDefinitionUtil.getComponentPropertyDefinitions(),
            };
            ComponentPropDefinitionUtil.populateComponentPropNames(tableRowProps);
        }
        return tableRowProps;
    }
}
