import { BlurEvent, Component, LookupModelSearchEvent, Switch, TableRow, TableRowMode, Textbox } from "@mcleod/components";
import { StringUtil, getUnauthSettings } from "@mcleod/core";
import { AutogenLayoutProhibitedTable } from "./autogen/AutogenLayoutProhibitedTable";

export class ProhibitedTable extends AutogenLayoutProhibitedTable {
    override onLoad() {
        if (getUnauthSettings()?.company_settings?.["is_brokerage_ltl"] == false) {
            this.removeColumn("fromLane");
            this.removeColumn("toLane");
            this.removeColumn("bidirectional");
        }
    }

    tableProhibitedOnRowDisplay(event) {
        const tableRow = event.target as TableRow;
        const modelRow = tableRow.data;
        const textboxProhibitDriverName = tableRow.findComponentById("textboxProhibitDriverName") as Textbox;
        const textboxProhibitedRowType = tableRow.findComponentById("textboxProhibitedRowType") as Textbox;
        const textboxProhibitRowId = tableRow.findComponentById("textboxProhibitRowId") as Textbox;
        const textboxFromRegion = tableRow.findComponentById("textboxFromRegionUid") as Textbox;
        const textboxToRegion = tableRow.findComponentById("textboxToRegionUid") as Textbox;
        const switchBidirectionalLane = tableRow.findComponentById("switchBidirectionalLane") as Switch;
        const isBrokerageLtl = getUnauthSettings()?.company_settings?.["is_brokerage_ltl"] == true
        textboxProhibitDriverName.tooltipCallback = null;

        textboxProhibitedRowType.onSelectItem = ((textbox, selectedItem) => {
            const carrierType = "C" == selectedItem["value"];
            this.setDriverTextboxEnabled(textboxProhibitDriverName, carrierType && tableRow.data?.get("prohibited_row_id") != null);
            textboxProhibitDriverName.required = !carrierType;
            if (isBrokerageLtl) {
                textboxFromRegion.enabled = carrierType;
                textboxToRegion.enabled = carrierType;
                switchBidirectionalLane.enabled = carrierType;
                if (!carrierType)
                    this.clearLaneTextboxes(modelRow, textboxFromRegion, textboxToRegion);
                this.setBidirectionalLane(switchBidirectionalLane, carrierType, tableRow.data?.get("bidirectional_lane"));
            }
            return undefined;
        });

        textboxProhibitRowId.onSelectItem = ((textbox, selectedItem) => {
            this.setDriverTextboxEnabled(textboxProhibitDriverName, "D" === tableRow.data?.get("prohibited_row_type"));
            return undefined;
        });

        const isDriverType = "D" === tableRow.data?.get("prohibited_row_type");
        textboxProhibitDriverName.enabled = isDriverType;
        textboxProhibitDriverName.required = isDriverType;
        if (isBrokerageLtl) {
            textboxFromRegion.enabled = !isDriverType;
            textboxToRegion.enabled = !isDriverType;
            switchBidirectionalLane.enabled = !isDriverType;
        }

        if (TableRowMode.QUICK_ADD === tableRow.mode) {
            tableRow.findComponentById("labelUserName").visible = false;
            tableRow.findComponentById("labelProhibitEnterDate").visible = false;
        }

        if (isBrokerageLtl) {
            if (modelRow.get("from_region_uid") != null)
                textboxFromRegion.text = modelRow.get("from_region")
            if (modelRow.get("to_region_uid") != null)
                textboxToRegion.text = modelRow.get("to_region")

            textboxFromRegion.onSelectItem = ((from, selectedItem) => {
                modelRow.set("from_region", from.text)
                return undefined;
            });

            textboxToRegion.onSelectItem = ((to, selectedItem) => {
                modelRow.set("to_region", to.text)
                return undefined;
            });
        }
    }

    setDriverTextboxEnabled(textboxProhibitDriverName: Textbox, enable: boolean) {
        textboxProhibitDriverName.enabled = enable;
        if (!textboxProhibitDriverName.enabled)
            textboxProhibitDriverName.text = null;
    }

    textboxProhibitDriverNameBeforeLookupModelSearch(event: LookupModelSearchEvent) {
        const tableRow = TableRow.getContainingTableRow(event.target as Component);
        if ("D" == tableRow.data?.get("prohibited_row_type") && tableRow.data?.get("prohibited_row_id") != null)
            event.filter.carrier_id = tableRow.data?.get("prohibited_row_id");
        else
            event.filter.carrier_id = null;
    }

    textboxProhibitRowIdOnBlur(event: BlurEvent) {
        const textboxCommodityId = event.target as Textbox;
        if (event.changedWhileFocused && StringUtil.isEmptyString(textboxCommodityId.text)) {
            const textboxProhibitDriverName = TableRow.getContainingTableRow(textboxCommodityId).findComponentById("textboxProhibitDriverName") as Textbox;
            this.setDriverTextboxEnabled(textboxProhibitDriverName, false);
        }
    }

    setBidirectionalLane(switchBidirectionalLane: Switch, allowed: boolean, bidirectional: String) {
        switchBidirectionalLane.enabled = allowed;
        if (allowed) {
            switchBidirectionalLane.checked = "Y" === bidirectional;
            switchBidirectionalLane.enabled = true;
        }
        else {
            switchBidirectionalLane.checked = switchBidirectionalLane.enabled = false;
        }
    }

    clearLaneTextboxes(modelRow, textboxFromRegion: Textbox, textboxToRegion: Textbox) {
        modelRow.set("from_region", null);
        modelRow.set("from_region_uid", null);
        textboxFromRegion.clear();
        modelRow.set("to_region", null);
        modelRow.set("to_region_uid", null);
        textboxToRegion.clear();
    }

    private removeColumn(columnId: String) {
        for (const column of this.tableProhibited.columns) {
            if (column?.cellDef?.def?.id === columnId) {
                this.tableProhibited.removeColumn(column.index); return;
            }
        }
    }
}
