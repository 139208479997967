import { DataLoadActionHandler, DataLoadActionType } from "@mcleod/common";
import { ClickEvent, DataSourceExecutionEvent } from "@mcleod/components";
import { Navigation, UrlUtil } from "@mcleod/core";
import { AutogenLayoutTodaySalesCalls } from "./autogen/AutogenLayoutTodaySalesCalls";

export class TodaySalesCalls extends AutogenLayoutTodaySalesCalls {

    /** This is an event handler for the onClick event of labelSalesCalls.  */
    labelTotalOnClick(event: ClickEvent) {
        const urlParams: string[] = [];
        let dlaCount = 0;
        urlParams.push(DataLoadActionHandler.createUrlQueryParamString(DataLoadActionType.SELECT_TAB, "tabToday", ++dlaCount));
        Navigation.navigateTo("lme/dispatch/SalesCallList" + UrlUtil.buildQueryString(urlParams));
    }

    labelProspectSalesCallsOnClick(event: ClickEvent) {
        const urlParams: string[] = [];
        let dlaCount = 0;
        urlParams.push("locationProspect=true")
        urlParams.push(DataLoadActionHandler.createUrlQueryParamString(DataLoadActionType.SELECT_TAB, "tabToday", ++dlaCount));
        Navigation.navigateTo("lme/dispatch/SalesCallList" + UrlUtil.buildQueryString(urlParams));
    }

    labelCustomerSalesCallsOnClick(event: ClickEvent) {
        const urlParams: string[] = [];
        let dlaCount = 0;
        urlParams.push("customer=true")
        urlParams.push(DataLoadActionHandler.createUrlQueryParamString(DataLoadActionType.SELECT_TAB, "tabToday", ++dlaCount));
        Navigation.navigateTo("lme/dispatch/SalesCallList" + UrlUtil.buildQueryString(urlParams));
    }

    sourceSalesCallsAfterExecution(event: DataSourceExecutionEvent) {
        this.mainDataSource.activeRow?.set("total_prospect", this.mainDataSource.summaryData.get("total_prospect"));
        this.mainDataSource.activeRow?.set("total_customer", this.mainDataSource.summaryData.get("total_customer"));
        this.mainDataSource.activeRow?.set("total", this.mainDataSource.summaryData.get("total"));
    }
}
