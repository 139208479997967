import { Button, ClickEvent, Label, Snackbar, Table, TableRow, TableRowDisplayEvent, Textbox } from "@mcleod/components";
import { Api, ModelRow, StringUtil } from "@mcleod/core";
import { PbwLoadboardServicesSlideout } from "./PbwLoadboardServicesSlideout";
import { AutogenLayoutLoadboardUsersTruckstop } from "./autogen/AutogenLayoutLoadboardUsersTruckstop";

export class LoadboardUsersTruckstop extends AutogenLayoutLoadboardUsersTruckstop {
    onLoad(): void | Promise<void> {
        this.tablePbwUserTruckstop.doOnRowDelete = (row: TableRow) => {
            Api.delete("lme/general/pbw-loadboard-users", { users: [row.data], service_type: "R" })
        }
    }

    loadboardUsersTruckstopOnRowDisplay(tableRowEvent: TableRowDisplayEvent) {
        const tableRow = tableRowEvent.getTableRow();
        const modelRow: ModelRow = tableRow.data;
        const parentSlideout = this.parent.parent as PbwLoadboardServicesSlideout;

        const textboxClientId: Textbox = parentSlideout.textboxClientId;
        const textboxClientSecret: Textbox = parentSlideout.textboxClientSecret;
        const textboxTruckstopUserId: Textbox = tableRow.findComponentById("textboxTruckstopUserId") as Textbox;
        const textboxTruckstopPassword: Textbox = tableRow.findComponentById("textboxTruckstopPassword") as Textbox;
        const buttonAuthorize: Button = tableRow.findComponentById("buttonAuthorize") as Button;
        const labelTruckstopStatus: Label = tableRow.findComponentById("labelTruckstopStatus") as Label;

        if (tableRow !== tableRowEvent.getTable().quickAddRow) {
            if (!modelRow.isNull("truckstop_access_token") && !modelRow.isNull("truckstop_refresh_token")) {
                labelTruckstopStatus.imageName = "circleCheck";
                labelTruckstopStatus.imageColor = "success";
            } else {
                labelTruckstopStatus.imageName = "circleX";
                labelTruckstopStatus.imageColor = "error";
                labelTruckstopStatus.tooltip = "Tokens not found. Please authorize for new credentials.";
            }
        }

        buttonAuthorize.addClickListener((clickEvent: ClickEvent) => {
            labelTruckstopStatus.imageColor = "primary";
            labelTruckstopStatus.busy = true;
            const [clientId, clientSecret, tUser, tPass] = [
                textboxClientId.text,
                textboxClientSecret.text,
                textboxTruckstopUserId.text,
                textboxTruckstopPassword.text
            ];
            parentSlideout.validateTruckstop(clientId, clientSecret, tUser, tPass, labelTruckstopStatus,
                (response) => {
                    labelTruckstopStatus.busy = false;
                    if (response.data[0].success) {
                        modelRow.set("truckstop_user_id", tUser);
                        modelRow.set("truckstop_access_token", response.data[0].access);
                        modelRow.set("truckstop_refresh_token", response.data[0].refresh);
                        modelRow.set("truckstop_token_expires", response.data[0].expire);
                        labelTruckstopStatus.imageName = "circleCheck";
                        labelTruckstopStatus.imageColor = "success";
                        textboxTruckstopPassword.clear();
                        Snackbar.showSnackbar("Success: Access tokens saved, Truckstop password not saved");
                        this.updateTableRowsByTruckstopUserId(tUser, response.data[0].access, response.data[0].refresh, response.data[0].expire)
                    } else {
                        labelTruckstopStatus.busy = false;
                        labelTruckstopStatus.imageName = "circleX";
                        labelTruckstopStatus.imageColor = "error";
                        labelTruckstopStatus.tooltip = (response.data[0].error)
                            ? response.data[0].error
                            : "There was an unexpected error Authorizing access."
                    }
                    const quickAddButton = tableRow.findComponentById("quickAddButton");
                    if (quickAddButton != null) {
                        (quickAddButton as Button).clicked();
                    }
                }
            );

        })
    }

    updateTableRowsByTruckstopUserId(tUser: string, access: string, refresh: string, expire: string) {
        this.tablePbwUserTruckstop.allRows.forEach(tableRow => {
            const modelRow = tableRow.data as ModelRow;
            if (tUser == modelRow.get("truckstop_user_id")) {
                modelRow.set("truckstop_access_token", access);
                modelRow.set("truckstop_refresh_token", refresh);
                modelRow.set("truckstop_token_expires", expire);
                const labelTruckstopStatus: Label = tableRow.findComponentById("labelTruckstopStatus") as Label;
                labelTruckstopStatus.imageName = "circleCheck";
                labelTruckstopStatus.imageColor = "success";
            }
        })
    }

    textboxTruckstopPbwUserIdBeforeLookupModelSearch(event) {
        event.filter.loadboard_truckstop_rest_id = null;
    }

    static isRowExistsForSameUser(newRowUserId: string, table: Table) {
        return table.rows
            .map(row => (row.data as ModelRow).get("user_id"))
            .find(existingRowUserId => StringUtil.equalsIgnoreCase(existingRowUserId, newRowUserId)) == null;
    }
}
