import { DataLoadActionHandler, DataLoadActionType } from "@mcleod/common";
import { ClickEvent, DataSourceExecutionEvent } from "@mcleod/components";
import { Navigation, UrlUtil } from "@mcleod/core";
import { AutogenLayoutNoNextCheckCall } from "./autogen/AutogenLayoutNoNextCheckCall";

export class NoNextCheckCall extends AutogenLayoutNoNextCheckCall {

    /** This is an event handler for the onClick event of labelCoveredCheckCalls.  */
    labelCoveredCheckCallsOnClick(event: ClickEvent) {
        const urlParams: string[] = [];
        let dlaCount = 0;
        urlParams.push("missingNextCheckCall=true");
        urlParams.push(DataLoadActionHandler.createUrlQueryParamString(DataLoadActionType.SELECT_TAB, "tabCoveredOrders", ++dlaCount));

        Navigation.navigateTo("lme/powerbroker/BrokeragePlanning" + UrlUtil.buildQueryString(urlParams));
    }

    /** This is an event handler for the onClick event of labelProgressCheckCalls.  */
    labelProgressCheckCallsOnClick(event: ClickEvent) {
        const urlParams: string[] = [];
        let dlaCount = 0;
        urlParams.push("missingNextCheckCall=true");
        urlParams.push(DataLoadActionHandler.createUrlQueryParamString(DataLoadActionType.SELECT_TAB, "tabInProgressOrders", ++dlaCount));

        Navigation.navigateTo("lme/powerbroker/BrokeragePlanning" + UrlUtil.buildQueryString(urlParams));
    }

    /** This is an event handler for the afterExecution event of sourceNoNextCheckCall.  */
    sourceNoNextCheckCallAfterExecution(event: DataSourceExecutionEvent) {
        this.labelCoveredCheckCalls._element.style.display = ''
        this.labelProgressCheckCalls._element.style.display = ''
        this.labelOverallCheckCalls._element.style.display = ''
    }
}
