import { Button, Checkbox, DataSource, HorizontalSpacer, Image, Label, Layout, Panel, SaveButton, Switch, Tab, Table, Tabset, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutUserSettings extends Layout {
    buttonAuthorize: Button;
    buttonSave: SaveButton;
    checkboxAllowNewDevices: Checkbox;
    checkboxSelTextOnFocus: Checkbox;
    horizontalspacer2: HorizontalSpacer;
    image11: Image;
    labelDATResult: Label;
    labelDevices: Label;
    labelSample: Label;
    labelSampleDateTime: Label;
    labelTruckstopResult: Label;
    labelUserSettings: Label;
    panel1: Panel;
    panel2: Panel;
    panel3: Panel;
    panel4: Panel;
    panel5: Panel;
    sourceCurrentUserPowerbroker: DataSource;
    sourcePbwLoadboardServices: DataSource;
    sourceSettings: DataSource;
    sourceUserRecognizedDevice: DataSource;
    sourceUsers: DataSource;
    switchGoogleMapLayer: Switch;
    switchTrimbleMapTraffic: Switch;
    tabGeneral: Tab;
    tabLoadboardServices: Tab;
    tabLogin: Tab;
    tabOperations: Tab;
    tableUserDevices: Table;
    tabset1: Tabset;
    textDateFormat: Textbox;
    textTheme: Textbox;
    textTimeFormat: Textbox;
    textboxBpProfile: Textbox;
    textboxCellPhone: Textbox;
    textboxDATService: Textbox;
    textboxDatPassword: Textbox;
    textboxDatUserId: Textbox;
    textboxDefaultDispatchLayout: Textbox;
    textboxDefaultDistanceMarginPercentage: Textbox;
    textboxDefaultWirecode: Textbox;
    textboxEmailAddress: Textbox;
    textboxExtension: Textbox;
    textboxGoogleMapTypes: Textbox;
    textboxOrderNextAction: Textbox;
    textboxPhone: Textbox;
    textboxTmProfile: Textbox;
    textboxTruckstopPassword: Textbox;
    textboxTruckstopRESTService: Textbox;
    textboxTruckstopUserId: Textbox;
}
