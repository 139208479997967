import { ClickEvent } from "@mcleod/components";
import { McLeodClassicIntegration } from "@mcleod/core";
import { AutogenLayoutRevenueDetailQuickInfo } from "./autogen/AutogenLayoutRevenueDetailQuickInfo";

export class RevenueDetailQuickInfo extends AutogenLayoutRevenueDetailQuickInfo {

    buttonOpenClassicOnClick(event: ClickEvent) {
        McLeodClassicIntegration.openClassicScreen("com.tms.client.loadmaster.lmltl.EntryTariffManagement", this.activeRow.get("tariff_id") + ",false," + this.activeRow.get("tli_uid"));
    }
}
