import { Button, DataSource, HorizontalSpacer, Label, Layout, Panel, Switch, } from "@mcleod/components";

export abstract class AutogenLayoutRequestDetail extends Layout {
    buttonCopy: Button;
    buttonPopout: Button;
    horizontalspacer1: HorizontalSpacer;
    labelDetail: Label;
    panel1: Panel;
    panelDetail: Panel;
    sourceLogReader: DataSource;
    switchWrap: Switch;
}
