import { CityState, DataSource, Layout, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutEdiStopShowasQuickInfo extends Layout {
	citystateShowAs: CityState;
	panelShowas: Panel;
	sourceEdiStop: DataSource;
	textboxAddress1: Textbox;
	textboxAddress2: Textbox;
	textboxLocationId: Textbox;
	textboxLocationName: Textbox;
}
