import { Button, DataSource, HorizontalSpacer, Image, Label, Layout, Panel, Textbox } from "@mcleod/components";
import { EdiStopProgress } from "../EdiStopProgress";

export abstract class AutogenLayoutEdiOrderOverview extends Layout {
    buttonMoveDetails: Button;
    horizontalspacer10: HorizontalSpacer;
    horizontalspacer11: HorizontalSpacer;
    horizontalspacer1: HorizontalSpacer;
    horizontalspacer2: HorizontalSpacer;
    horizontalspacer3: HorizontalSpacer;
    horizontalspacer4: HorizontalSpacer;
    horizontalspacer5: HorizontalSpacer;
    horizontalspacer6: HorizontalSpacer;
    horizontalspacer7: HorizontalSpacer;
    horizontalspacer8: HorizontalSpacer;
    horizontalspacer9: HorizontalSpacer;
    imageInboundTenderError: Image;
    imageOutboundTenderError: Image;
    imageReplyError: Image;
    labelAction: Label;
    labelAppType: Label;
    labelAppTypeCaption: Label;
    labelBOL: Label;
    labelBatchID: Label;
    labelBillDistanceInbound: Label;
    labelBillDistanceOutbound: Label;
    labelBillOfLading: Label;
    labelBillingDistanceInbound: Label;
    labelCarrierPay: Label;
    labelConsRefno: Label;
    labelConsigneeReferenceNumber: Label;
    labelCreateDate: Label;
    labelCreateDateCaption: Label;
    labelCreateDtCaption: Label;
    labelDirection: Label;
    labelDirectionCaption: Label;
    labelEquipmentTypeId: Label;
    labelFreightCharge: Label;
    labelFreightCharges: Label;
    labelGSDateCaption: Label;
    labelGsDateTime: Label;
    labelMoveStatus: Label;
    labelMustRespondBy: Label;
    labelOtherCharges: Label;
    labelOtherPay: Label;
    labelOtherPayTotal: Label;
    labelOtherchargetotal: Label;
    labelOverridePayAmt: Label;
    labelOverview: Label;
    labelPartnerId: Label;
    labelPartnerName: Label;
    labelPartnerNameID: Label;
    labelPurpose: Label;
    labelPurposeCaption: Label;
    labelRatingUnits: Label;
    labelRbdTimezoneId: Label;
    labelReferenceNumbers: Label;
    labelReply: Label;
    labelReplyAction: Label;
    labelReplyBatchId: Label;
    labelReplyDate: Label;
    labelRespondByDate: Label;
    labelSID: Label;
    labelShipmentId: Label;
    labelTempDelim: Label;
    labelTempMax: Label;
    labelTempMin: Label;
    labelTemperatureRange: Label;
    labelTenderID: Label;
    labelTenderId: Label;
    labelTotalCharge: Label;
    labelTotalCharges: Label;
    labelTotalPay: Label;
    labelTotalPayCaption: Label;
    labelTrackingRequired: Label;
    labelTrailerType: Label;
    labelTransmitStatus: Label;
    labelTransmitted: Label;
    layoutStopProgress: EdiStopProgress;
    moveLabel: Label;
    panel6: Panel;
    panelCarrierPay: Panel;
    panelCommodity: Panel;
    panelErrorIcons: Panel;
    panelInboundCharges: Panel;
    panelLeft: Panel;
    panelOrderInfo: Panel;
    panelOverviewOuter: Panel;
    panelPartnerInfo: Panel;
    panelRefNbrs: Panel;
    panelReply: Panel;
    panelReplyInfo: Panel;
    panelReplyTransmit: Panel;
    panelRight: Panel;
    panelStopInfo: Panel;
    panelTemp: Panel;
    panelTempRange: Panel;
    pannelOverviewInner: Panel;
    sourceCarrierPayee: DataSource;
    sourceCarriersForAssignment: DataSource;
    sourceCustomer: DataSource;
    sourceViewEdiOrder: DataSource;
    textboxCarrierName: Textbox;
    textboxCommodity: Textbox;
    textboxName: Textbox;
    textboxOrderValue: Textbox;
    textboxWeight: Textbox;
}
