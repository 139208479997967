import { Component, DataSource, DataSourceMode, DialogProps, Panel, PanelProps, Table, TableColumn, Textbox } from "@mcleod/components";
import { AddType } from "@mcleod/components/src/components/table/AddType";
import { ArrayUtil, ModelRow, StringUtil } from "@mcleod/core";

export class HelpLinkEditor extends Panel {
    private ds: DataSource;
    private table: Table;
    dialogProps: Partial<DialogProps> = { okDefault: false, cursor: null, title: "Help Links Setup" };

    constructor(props: Partial<PanelProps> = {}, valueAsJson: string) {
        super({ fillRow: true, fillHeight: true, minWidth: 500, minHeight: 500, ...props });
        this.ds = new DataSource();
        this.createTable();
        this.add(this.table);
        const tableData = this.readData(valueAsJson);
        this.ds.setRowsAndMode(DataSourceMode.UPDATE, tableData);
    }

    private readData(valueAsJson: string): ModelRow[] {
        const result: ModelRow[] = [];
        if (StringUtil.isEmptyString(valueAsJson) === false) {
            const helpLinkDefs: any[] = JSON.parse(valueAsJson);
            if (helpLinkDefs != null) {
                for (const def of helpLinkDefs) {
                    result.push(new ModelRow("", false, { caption: def.caption, url: def.url }));
                }
            }
        }
        return result;
    }

    private createTable() {
        this.table = new Table({
            id: "itemsTable",
            headerVisible: false,
            allowEdit: true,
            allowAdd: true,
            addType: AddType.QUICK,
            fillRow: true,
            fillHeight: true,
            rowBreak: false,
            allowDbSearch: false,
            emptyCaption: "Help links can be defined in this table",
            dataSource: this.ds
        });
        const captionColumn = new TableColumn({
            id: "columnCaption",
            index: 0,
            heading: {
                caption: "Caption",
                isHeading: true
            },
            cell: this.createCaptionComponent
        });
        const urlColumn = new TableColumn({
            id: "columnUrl",
            index: 0,
            heading: {
                caption: "URL",
                isHeading: true
            },
            cell: this.createUrlComponent
        });

        this.table.addColumn(captionColumn, true, false);
        this.table.addColumn(urlColumn, true, false);
    }

    private createCaptionComponent(): Component {
        return new Textbox({ caption: "Caption", field: "caption", id: "textboxCaption", required: true, placeholder: "Required", captionVisible: false, fillRow: true });
    }

    private createUrlComponent(): Component {
        return new Textbox({ caption: "URL", field: "url", id: "textboxUrl", required: true, placeholder: "Required", captionVisible: false, fillRow: true });
    }

    getValue(): string {
        const result: any[] = [];
        for (const row of this.table.rows) {
            result.push({ caption: row.data.get("caption", null), url: row.data.get("url", null) });
        }
        if (ArrayUtil.isEmptyArray(result) === false)
            return JSON.stringify(result);
        return null;
    }
}
