import { BlurEvent, Button, ClickEvent, DropdownItem, Layout, SlideoutDecorator, Snackbar, Toast } from "@mcleod/components";
import { Api, StringUtil } from "@mcleod/core";
import { AutogenLayoutAddCarrier } from "./autogen/AutogenLayoutAddCarrier";

export class AddCarrier extends AutogenLayoutAddCarrier {
    private _movementId: string;
    private _vendorId: string;
    private _carrierName: string;
    private _carrierNumber: string;
    private _carrierIdentifier: string;
    private _carrierOnboardingServer: string;
    private _websiteUrl: string;

    constructor(props?) {
        super(props);
        this._movementId = props.movementId;
        this._vendorId = props.vendorId;
        this._carrierName = props.carrierName;
        this._carrierNumber = props.carrierNumber;
        this._carrierIdentifier = props.carrierIdentifier;
        this._carrierOnboardingServer = props.carrierInsServer;
        this._websiteUrl = props.websiteUrl;
    }

    override onLoad(): void | Promise<void> {
        this.setAddCarrierScreen(this._movementId, this._carrierName, this._carrierNumber, this._carrierIdentifier, this._carrierOnboardingServer, this._websiteUrl);
    }

    private buttonAdd = new Button({
        rowBreak: false,
        backgroundColor: "primary",
        color: "primary.reverse",
        caption: "Add Carrier",
        borderWidth: 0,
        borderColor: "primary",
        onClick: (event: ClickEvent) => this.addCarrier()
    })

    static showSlideout(movementId: string, vendorId: string, carrierName: string, carrierType: string, carrierNumber: string, carrierInsServer: string, websiteUrl: string) {

        const layout = Layout.getLayout("lme/dispatch/AddCarrier", {
            movementId: movementId, vendorId: vendorId, carrierName: carrierName,
            carrierIdentifier: carrierType, carrierNumber: carrierNumber, carrierInsServer: carrierInsServer, websiteUrl: websiteUrl
        } as any) as AddCarrier;

        const slideoutDecorator = new SlideoutDecorator({
            title: "Add Carrier",
            layout: layout,
            fillVerticalSpace: true,
            width: 1000,
            overlayProps: { greyedBackground: true },
            addlComponents: layout.buttonAdd,
            onClose: (cancelled: boolean) => layout.slideOut()
        });
    }


    addCarrier() {
        if (!StringUtil.isEmptyString(this.textboxAddCarrierName.text)
            && !StringUtil.isEmptyString(this.textboxAddCarrierNbr.text)
            && !StringUtil.isEmptyString(this.textboxAddCarrierType.text)) {
            this.addCarrierInfo();

        } else {
            Snackbar.showWarningSnackbar("Please provide all fields to add a carrier.");
        }
    }

    addCarrierInfo() {
        this.buttonAdd.busy = true;
        Api.post("lme/dispatch/add-carrier",
            {
                "carrier_name": this.textboxAddCarrierName.text,
                "carrier_type": this.textboxAddCarrierType.text,
                "carrier_identifier": this.textboxAddCarrierNbr.text,
                "packet_contact_name": this.textboxPacketContactName.text,
                "email_address": this.textboxEmailAddress.text
            })
            .then(response => {
                this.buttonAdd.busy = false;
                if (StringUtil.isEmptyString(response?.data[0]?.add_carrier_error_message)) {
                    this.slideOut();
                    const carrierName = response?.data[0]?.add_carrier_response?.carrier_name;
                    const carrierId = response?.data[0]?.add_carrier_response?.carrier_id;
                    const vendorName = response?.data[0]?.add_carrier_response?.vendor_name;
                    const successMessage = "Carrier, " + carrierName + " (" + carrierId + ") " + "has been added. \n\n Carrier is now being monitored with " + vendorName + ".";
                    setTimeout(() => Toast.showSuccessToast(successMessage), 500);
                } else {
                    Snackbar.showWarningSnackbar("There was an unexpected error adding the carrier.");
                }
            }).catch(error => {
                this.buttonAdd.busy = false;
                Snackbar.showWarningSnackbar("There was an unexpected error adding the carrier - " + error);
            });
    }

    async setAddCarrierScreen(_movementId: string, _carrierName: string, _carrierNumber: string, _carrierType: string, _carrierOnboardingServer: string, _websiteUrl: string) {
        await this.mainDataSource.search({ "movement_id": this._movementId, "carrier_name": this._carrierName, "carrier_type": this._carrierIdentifier, "carrier_identifier": this._carrierNumber })
            .then(response => {
                if (response.modelRows[0].data.pending_carrier_type) {
                    const lookupItems: DropdownItem[] = this.textboxAddCarrierType.items as DropdownItem[]
                    this.textboxAddCarrierType.selectedItem = lookupItems.filter(item => item.value == response.modelRows[0].data.pending_carrier_type)[0]
                }
                if (response.modelRows[0].data.is_logix_licensed && StringUtil.isEmptyString(_carrierOnboardingServer) && StringUtil.isEmptyString(_websiteUrl)) {
                    this.textboxPacketContactName.visible = true;
                    this.textboxEmailAddress.visible = true;
                } else {
                    this.textboxPacketContactName.visible = false;
                    this.textboxEmailAddress.visible = false;
                }
                if (response.modelRows[0].data.carrier_found) {
                    Snackbar.showWarningSnackbar("Carrier: " + response.modelRows[0].data.carrier_id + " already exist in the system.");
                }
            });
    }

    async textboxAddCarrierNbrOnBlur(event: BlurEvent) {
        this.buttonAdd.enabled = false;
        await Api.post("lme/dispatch/validate-carrier-exist",
            {
                "carrier_type": this.textboxAddCarrierType.text,
                "carrier_identifier": this.textboxAddCarrierNbr.text,
            })
            .then(response => {
                this.buttonAdd.enabled = true;
                if (response?.data[0]?.carrier_found) {
                    Snackbar.showWarningSnackbar("Carrier: " + response?.data[0]?.carrier_id + " already exist in the system.");
                }
            });
    }
}
