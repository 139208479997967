import { CommonDialogs } from "@mcleod/common";
import { DataSourceMode, Layout, Overlay, Toast } from "@mcleod/components";
import { Alignment, Api } from "@mcleod/core";
import { InitiateBrokerageTracking } from "../InitiateBrokerageTracking";
import { SendTrackingMessage } from "./SendTrackingMessage";

export class BrokerageTrackingActions {

    public sendTrackingMessage(
        movementId: string,
        vendorName: string,
        onClose: (overlay: Overlay) => void,
        doAfterMessageSent: (overlay: Overlay, response: any) => void): void {
        if (movementId != null && vendorName != null) {
            Layout.getLayout("lme/powerbroker/tracking/SendTrackingMessage").addLayoutLoadListener(event => {
                const sendMsgLayout = event.target as SendTrackingMessage;
                sendMsgLayout.movementId = movementId;
                sendMsgLayout.brVendorName = vendorName;
                sendMsgLayout.onClose = () => onClose(overlay);
                sendMsgLayout.doAfterMessageSent = (response: any) => doAfterMessageSent(overlay, response);

                const overlay = Overlay.showInOverlay(sendMsgLayout, { closeOnClickOff: false, greyedBackground: true, centered: true });
                sendMsgLayout.textboxComment.focus();
            });
        }
    }

    public editTracking(movementId: string, orderId: string, { ...editTrackingSlideoutProps }, doAfterAction?: () => void) {
        Layout.getLayout("lme/powerbroker/InitiateBrokerageTracking", editTrackingSlideoutProps).addLayoutLoadListener(event => {
            const layout = event.target as InitiateBrokerageTracking;
            layout.editTracking = true;
            layout.buttonInitiateLater.visible = false;
            layout.buttonCancel.addClickListener(() => layout.slideOut());
            layout.search({
                "id": movementId,
                "orders.id": orderId
            }).then(result => {
                if (result?.modelRows?.length > 0) {
                    layout.setAllDataSourcesToMode(DataSourceMode.UPDATE);
                    layout.slideIn({ direction: Alignment.RIGHT }, true, { closeOnClickOff: false, greyedBackground: true });
                    layout.doAfterTrackingInitiated = () => {
                        layout.slideOut();
                        if (layout.mainDataSource.activeRow?.get("br_vendor") != null && doAfterAction != null) {
                            doAfterAction();
                        }
                    }
                }
            });
        });
    }

    public cancelTracking(movementId: string, brVendorName: string, doAfterAction?: () => void) {
        if (brVendorName) {
            CommonDialogs.showYesNo(
                `Movement ${movementId} will no longer be tracked by ${brVendorName}. Would you like to continue?`,
                "Cancel Tracking",
                { titleProps: { imageName: "circleX2", imageColor: "primary.light" } })
                .then(okClicked => {
                    if (okClicked) {
                        Api.post("lme/powerbroker/tracking/cancel-tracking", { "movement_id": movementId }).then(response => {
                            const cancelToastMessage = `Tracking Canceled for Movement ${movementId}`;
                            Toast.showSuccessToast(cancelToastMessage);
                            if (doAfterAction) {
                                doAfterAction();
                            }
                        });
                    }
                });
        }
    }
}
