/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelBrltlBillingFreightGroup.ts      *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/dispatch/brltl-billing-freight-group";

export class AutogenModelBrltlBillingFreightGroup<RowType extends ModelRow> extends Endpoint<RowType, RowBrltlBillingFreightGroupFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowBrltlBillingFreightGroup extends ModelRow<RowBrltlBillingFreightGroupFields> {
    constructor(values?: Partial<RowBrltlBillingFreightGroupFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowBrltlBillingFreightGroupFields {
    add_timestamp: Date;
	add_userid: string;
	bfg_comment: string;
	bfg_status_code: string;
	bfg_type_code: string;
	bfg_uid: number;
	bol_address1: string;
	bol_address2: string;
	bol_city: string;
	bol_name: string;
	bol_phone: string;
	bol_state: string;
	bol_zip_code: string;
	calc_type_code: string;
	can_move_hazmat: boolean;
	carrier_display_name: string;
	carrier_key: string;
	carrier_qualification: string;
	carrier_scac: string;
	company_id: string;
	contract_descr: string;
	contract_type_descr: string;
	currency_type_id: string;
	delivery_lane: string;
	dispatch_to_p44: boolean;
	dispatch_type: string;
	est_delivery_date: Date;
	fgp_uid: string;
	image_bytes: string;
	inadequate_transit_time: boolean;
	is_expired: boolean;
	isp44quote: boolean;
	lane: string;
	mod_timestamp: Date;
	mod_userid: string;
	movement_id: string;
	name: string;
	p44_capacity_provider_acct: string;
	p44_direction_override: string;
	p44_payment_terms: string;
	p44_scac: string;
	payee_id: string;
	pickup_lane: string;
	qualification_details: string;
	quote_expire_date: Date;
	quote_number: string;
	rating_mode: string;
	rating_source: string;
	reference_id: string;
	revenue_details: string;
	selected_for_dispatch: boolean;
	service_descr: string;
	shipment_id: string;
	total_charge_amount: number;
	total_margin_amount: string;
	total_markup_amount: string;
	total_pieces: number;
	total_profit_amount: number;
	total_req_spots: number;
	total_revenue_amount: number;
	total_weight: number;
	transit_days: number;
	transit_days_desc: string;
	new_chosen_bfg_rate: string;
	order_id: string;
}

