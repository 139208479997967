/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelExtraPayDeductCode.ts            *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/powerbroker/extra-pay-deduct-code";

export class AutogenModelExtraPayDeductCode<RowType extends ModelRow> extends Endpoint<RowType, RowExtraPayDeductCodeFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowExtraPayDeductCode extends ModelRow<RowExtraPayDeductCodeFields> {
    constructor(values?: Partial<RowExtraPayDeductCodeFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowExtraPayDeductCodeFields {
    advance_limit: number;
    advance_limit_c: string;
    advance_limit_d: Date;
    advance_limit_n: number;
    advance_limit_r: number;
    amount: number;
    amount_c: string;
    amount_d: Date;
    amount_n: number;
    amount_r: number;
    city_tax_flag: string;
    code_type: string;
    company_id: string;
    county_tax_flag: string;
    create_other_pay: string;
    dispatcher_flag: string;
    emp_fica_hi_flag: string;
    emp_fica_oadi_flag: string;
    empr_fica_hi_flag: string;
    empr_fica_oadi_flg: string;
    empr_train_flag: string;
    federal_tax_flag: string;
    fui_flag: string;
    glid: string;
    id: string;
    is_cost_business: string;
    overweight_pay: string;
    sdi_flag: string;
    short_desc: string;
    state_tax_flag: string;
    sui_flag: string;
    weight_cutoff: number;
    weight_cutoff_um: string;
}

