import { Button, Checkbox, DataSource, HorizontalSpacer, Label, Layout, Location, Panel, Table, Textbox } from "@mcleod/components";
import { MovementCarrierPay } from "../../../powerbroker/src/MovementCarrierPay";

export abstract class AutogenLayoutEdiOrderRates extends Layout {
    buttonAvailableCredit: Button;
    buttonEstimateFuelSurcharge: Button;
    buttonExpandCharges: Button;
    buttonRate: Button;
    checkboxLockMiles: Checkbox;
    horizontalspacer3: HorizontalSpacer;
    horizontalspacer4: HorizontalSpacer;
    labelAdditionalCharges: Label;
    labelAutoRated: Label;
    labelAvailableCredit: Label;
    labelFreightCharge: Label;
    labelTotalCharge: Label;
    layoutMovementRates: MovementCarrierPay;
    panelAdditionalCharges: Panel;
    panelAdditionalInfo: Panel;
    panelAvailableCredit: Panel;
    panelCollapse: Panel;
    panelEdiRates: Panel;
    panelFreightCharge: Panel;
    panelFreightChargeTotal: Panel;
    panelOtherChargeTable: Panel;
    panelOtherCharges: Panel;
    panelOtherChargesHeader2: Panel;
    panelOtherChargesHeader: Panel;
    panelRateTotals: Panel;
    panelRatesHeader: Panel;
    panelTotalCharges: Panel;
    sourceOtherChargeEdi: DataSource;
    sourceViewEdiOrder: DataSource;
    tableOtherCharges: Table;
    textboxBillDistance: Textbox;
    textboxCollectionMethod: Textbox;
    textboxCtrlParty: Location;
    textboxFreightCharge2: Textbox;
    textboxFreightCharge: Textbox;
    textboxOtherchargetotal: Textbox;
    textboxPieces: Textbox;
    textboxRate: Textbox;
    textboxRateMinWeight: Textbox;
    textboxRateType: Textbox;
    textboxRateUnits: Textbox;
    textboxSegAllocCode: Textbox;
    textboxTotalCharge: Textbox;
    textboxWeight: Textbox;
}
