import { StringUtil } from "@mcleod/core";
import { AutogenLayoutDriver } from "./autogen/AutogenLayoutDriver";

export class Driver extends AutogenLayoutDriver {

    override onLoad(): void | Promise<void> {
        this.textboxOptOutDate.addBlurListener((event) => {
            if (StringUtil.isEmptyString(this.textboxOptOutDate.text)) {
                this.switchSMSOptIn.checked = false;
            }
        });

        this.switchSMSOptIn.addChangeListener((event) => {
            this.textboxOptOutDate.clear();
        });
    }

}
