/**
 * This is a list of the image resources in the @mcleod/images package that can
 * be used to help with code completion and some compile time checking of constants.
*/

/*
Here is a groovy script that can generate the contents of this enum:

import java.io.*;

File dir = new File(System.getProperty("user.home") + "/git/loadmaster/mcleod-ui/images/src/images");
addDirectory(dir, "");

private void addDirectory(File dir, String prefix) {
    for (File f : dir.listFiles())
        if (!f.isDirectory()) {
            String n = f.getName().replace(".ts", "");
            System.out.println("\t\"" + prefix + n + "\" : \"" + prefix +  n + "\",");
        }

    for (File f : dir.listFiles())
        if (f.isDirectory())
            addDirectory(f, f.getName() + "/");
}

*/

export type ImageName = keyof typeof ImageNames | string;

export const ImageNames = {
    "add": "add",
    "addInBox": "addInBox",
    "arrow": "arrow",
    "arrowDown": "arrowDown",
    "arrowSplit": "arrowSplit",
    "arrowUp": "arrowUp",
    "ascending": "ascending",
    "brokenPallet": "brokenPallet",
    "calendar": "calendar",
    "chartLine": "chartLine",
    "chatEmpty": "chatEmpty",
    "chatImportant": "chatImportant",
    "chatLines": "chatLines",
    "chatPerson": "chatPerson",
    "chatBubble": "chatBubble",
    "unReadChatBubble": "unReadChatBubble",
    "check": "check",
    "checkboxChecked": "checkboxChecked",
    "checkboxIndeterminate": "checkboxIndeterminate",
    "checkboxUnchecked": "checkboxUnchecked",
    "chevron": "chevron",
    "circleCheck": "circleCheck",
    "circleCheckEmpty": "circleCheckEmpty",
    "circleEmpty": "circleEmpty",
    "circleFilled": "circleFilled",
    "circlePlusFilled": "circlePlusFilled",
    "circleQuestion": "circleQuestion",
    "circleWarning": "circleWarning",
    "circleX": "circleX",
    "circleX2": "circleX2",
    "classicModel": "classicMode",
    "clipboard": "clipboard",
    "clock": "clock",
    "codeTags": "codeTags",
    "curlyBrackets": "curlyBrackets",
    "danger": "danger",
    "dash": "dash",
    "delete": "delete",
    "delivery": "delivery",
    "descending": "descending",
    "detail": "detail",
    "disk": "disk",
    "diskX": "diskX",
    "document": "document",
    "doubleChevron": "doubleChevron",
    "download": "download",
    "duplicateDisk": "duplicateDisk",
    "ellipsis": "ellipsis",
    "expand": "expand",
    "exit": "exit",
    "fan": "fan",
    "folder": "folder",
    "form": "form",
    "formPencil": "formPencil",
    "formPencilHigh": "formPencilHigh",
    "funnel": "funnel",
    "hamburger": "hamburger",
    "heart": "heart",
    "hierarchy": "hierarchy",
    "hmmm": "hmmm",
    "home": "home",
    "inboundStatus": "inboundStatus",
    "inboundShipmentStatusError": "inboundShipmentStatusError",
    "layout": "layout",
    "link": "link",
    "listSearch": "listSearch",
    "listStar": "listStar",
    "ltlPalletQuote": "ltlPalletQuote",
    "magnifyingGlass": "magnifyingGlass",
    "magnifyingGlassPage": "magnifyingGlassPage",
    "mail": "mail",
    "mapPinPerson": "mapPinPerson",
    "minusInBox": "minusInBox",
    "money": "money",
    "multipleSelected": "multipleSelected",
    "noPencil": "noPencil",
    "number": "number",
    "offered": "offered",
    "oneToMany": "oneToMany",
    "oneToOne": "oneToOne",
    "outboundShipmentStatusError": "outboundShipmentStatusError",
    "outboundStatus": "outboundStatus",
    "overlappingWindows": "overlappingWindows",
    "package": "package",
    "packageError": "packageError",
    "padlock": "padlock",
    "pencil": "pencil",
    "shipmentLocation": "shipmentLocation",
    "loadBoardPostingError": "loadBoardPostingError",
    "people": "people",
    "person": "person",
    "pickup": "pickup",
    "pictureOutlined": "pictureOutlined",
    "mPact": "mPact",
    "topMatch": "topMatch",
    "pinOutlined": "pinOutlined",
    "pointer": "pointer",
    "pop": "pop",
    "pushPin": "pushPin",
    "redo": "redo",
    "refresh": "refresh",
    "run": "run",
    "settings": "settings",
    "share": "share",
    "shrink": "shrink",
    "sorting_off": "sorting_off",
    "spinner": "spinner",
    "star": "star",
    "tab": "tab",
    "tenders": "tenders",
    "timeExpired": "timeExpired",
    "timeWarning15": "timeWarning15",
    "timeWarning30": "timeWarning30",
    "timeWarning60": "timeWarning60",
    "truck": "truck",
    "unavailable": "unavailable",
    "undo": "undo",
    "visibilityOff": "visibilityOff",
    "visibilityOn": "visibilityOn",
    "warning": "warning",
    "x": "x",
    "designer/attachment": "designer/attachment",
    "designer/button": "designer/button",
    "designer/checkbox": "designer/checkbox",
    "designer/citystate": "designer/citystate",
    "designer/dataheader": "designer/dataheader",
    "designer/datasource": "designer/datasource",
    "designer/function": "designer/function",
    "designer/horizontalspacer": "designer/horizontalspacer",
    "designer/image": "designer/image",
    "designer/innerJoin": "designer/innerJoin",
    "designer/input": "designer/input",
    "designer/label": "designer/label",
    "designer/layout": "designer/layout",
    "designer/list": "designer/list",
    "designer/map": "designer/map",
    "designer/numbereditor": "designer/numbereditor",
    "designer/outerJoin": "designer/outerJoin",
    "designer/panel": "designer/panel",
    "designer/savebutton": "designer/savebutton",
    "designer/searchbutton": "designer/searchbutton",
    "designer/splitter": "designer/splitter",
    "designer/starrating": "designer/starrating",
    "designer/stepper": "designer/stepper",
    "designer/switch": "designer/switch",
    "designer/tab": "designer/tab",
    "designer/table": "designer/table",
    "designer/tabset": "designer/tabset",
    "designer/textbox": "designer/textbox",
    "designer/tree": "designer/tree",
    "designer/widget": "designer/widget",
};
