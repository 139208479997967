/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelCarrierDriver.ts                 *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/powerbroker/carrier-driver";

export class AutogenModelCarrierDriver<RowType extends ModelRow> extends Endpoint<RowType, RowCarrierDriverFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowCarrierDriver extends ModelRow<RowCarrierDriverFields> {
    constructor() {
        super(AutogenUserSavedSearchEndpoint);
    }
}

export interface RowCarrierDriverFields {
    carrier_id: string;
    cell_phone_number: string;
    company_id: string;
    driver_name: string;
    email_address: string;
    id: number;
    is_active: string;
    notes: string;
    tractor_number: string;
}

