import { Button, DataSource, Layout, Panel, Table } from "@mcleod/components";

export abstract class AutogenLayoutTopMatchTable extends Layout {
    buttonBlastAllOffers: Button;
    buttonWaterfallTender: Button;
    panelFilterButtons: Panel;
    sourceMovement: DataSource;
    sourceTopMatch: DataSource;
    tableTopMatch: Table;
}
