import { Button, CityState, DataHeader, DataSource, HorizontalSpacer, Label, Layout, Location, Panel, SearchButton, Table, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutRecurringOrderList extends Layout {
    buttonClear: Button;
    buttonSearchExpand: Button;
    citystateStopDestCityId: CityState;
    citystateStopOriginCityId: CityState;
    dataheader1: DataHeader;
    horizontalspacer3: HorizontalSpacer;
    horizontalspacerLocation: HorizontalSpacer;
    horizontalspacerSearch: HorizontalSpacer;
    labelOrders: Label;
    panelConsigneeLocation: Panel;
    panelOrderListContainer: Panel;
    panelSearch: Panel;
    panelSearchHeader: Panel;
    panelShipperLocation: Panel;
    searchbuttonSearch: SearchButton;
    sourceRecurringOrdersList: DataSource;
    tableRecurringOrders: Table;
    textboxCustomerId: Textbox;
    textboxDestLocation: Location;
    textboxId: Textbox;
    textboxOrderTypeId: Textbox;
    textboxOriginLocation: Location;
    textboxRevenueCodeId: Textbox;
    textboxStopDestAddress2: Textbox;
    textboxStopDestAddress: Textbox;
    textboxStopDestLocationId: Textbox;
    textboxStopDestLocationName: Textbox;
    textboxStopOriginAddress2: Textbox;
    textboxStopOriginAddress: Textbox;
    textboxStopOriginLocationId: Textbox;
    textboxStopOriginLocationName: Textbox;
}
