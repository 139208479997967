import { ClickEvent } from "@mcleod/components";
import { McLeodClassicIntegration } from "@mcleod/core";
import { AutogenLayoutStatusQuickInfo } from "./autogen/AutogenLayoutStatusQuickInfo";

export class StatusQuickInfo extends AutogenLayoutStatusQuickInfo {
    openClassicStatus(event: ClickEvent) {
        const statusId = this.sourceEdistatus.activeRow.get("id");
        McLeodClassicIntegration.openClassicScreen("com.tms.client.loadmaster.edi.EntryEdiStatus", "<ext> search id='" + statusId + "'");
    }

    afterExecution() {
        if (this.sourceEdistatus.activeRow != null) {
            if (this.sourceEdistatus.activeRow.get("is_resend") !== "Y") {
                this.imageIsResend.visible = false;
            }
            if (this.sourceEdistatus.activeRow.get("delay_code") === null ||
                this.sourceEdistatus.activeRow.get("delay_code") === undefined) {
                this.labelDelayCode.caption = "--";
            }
            if (this.sourceEdistatus.activeRow.get("event_type") === "AP"
                || this.sourceEdistatus.activeRow.get("event_type") === "LC") {
                this.labelAppointmentDate.visible = false;
                this.labelAppointment.visible = false;
                this.labelApptTZ.visible = false;
            }
        }
    }
}
