import { getLogger, ModuleLogger } from "./Logger";
import { ObjectUtil } from "./ObjectUtil";
import { StringUtil } from "./StringUtil";
import { recomputeThemedStyles } from "./Styles";
import { ThemeDark } from "./themes/dark/ThemeDark";
import { ThemeDefaults } from "./themes/default/ThemeDefault";

let themeElements;

let log: ModuleLogger;

export function setThemeNameOrValue(value: string): void {
    value = StringUtil.stringBefore(value, " (experimental)");
    if (value === "Dark")
        themeElements = ThemeDark;
    else if (value === "Default")
        themeElements = ThemeDefaults;
    else
        themeElements = JSON.parse(value);
    themeElements = ObjectUtil.deepCopy(themeElements);
    populateTopLevelTheme();
}

function populateTopLevelTheme() {
    populateThemeElements(themeElements, themeElements);
    document.body.style.backgroundColor = themeElements.defaultBackground;
    document.body.style.color = themeElements.defaultColor;
    recomputeThemedStyles();
}

export function getThemeKeys(): string[] {
    const result = [];
    for (const key in ThemeDefaults.palette) {
        result.push(key);
        result.push(key + ".light");
        result.push(key + ".lighter");
        result.push(key + ".lightest");
        result.push(key + ".dark");
        result.push(key + ".darker");
        result.push(key + ".darkest");
        result.push(key + ".reverse");
    }
    for (const key in ThemeDefaults) {
        const value = ThemeDefaults[key];
        if (typeof value === "string")
            result.push(key);
    }
    ThemeDefaults.palette
    return result;
}

export function getThemeColor(paletteName: string, shade?: string): string {
    const theme = getTheme();
    if (theme == null || paletteName == null)
        return paletteName;
    if (paletteName.length === 0)
        return null;
    if (shade == null) {
        const directTheme = getThemeForKey(paletteName);
        if (directTheme != null)
            paletteName = directTheme;
        shade = "base";
    }
    if (typeof paletteName !== "string") {
        getLog().error("Attempt to getThemeColor with non-string value", paletteName);
        throw new Error("Attempt to getThemeColor with non-string value.");
    }
    const dotPos = paletteName.indexOf(".");
    if (dotPos >= 0) {
        shade = paletteName.substring(dotPos + 1);
        paletteName = paletteName.substring(0, dotPos);
    }
    if (theme[paletteName] != null)
        return theme[paletteName];
    const palette = theme.palette[paletteName];
    if (palette != null)
        return palette[shade];
    return paletteName;
}

export function getTheme() {
    if (themeElements == null) {
        themeElements = ThemeDefaults;
        populateTopLevelTheme();
    }
    return themeElements;
}

export function getThemeForKey(key: string) {
    return ObjectUtil.getNestedObject(getTheme(), key);
}

export function getThemeFontSize(value: string | number): string {
    if (typeof value === "number")
        return value + "px";
    else if (value === "xxsmall")
        return "8px";
    else if (value === "xsmall")
        return "10px";
    else if (value === "small")
        return "12px";
    else if (value === "medium")
        return "14px";
    else if (value === "large")
        return "16px";
    else if (value === "xlarge")
        return "18px";
    else if (value === "xxlarge")
        return "20px";
    else if (value === "xxxlarge")
        return "24px";
    else if (value === "skyline")
        return "32px";
    else if (value === "billboard")
        return "48px";
    else
        return value;
}

/**
 * This function is called to populate theme elements that are dependent on other elements.  For example,
 * a theme defines a palette of colors and then each of the more specific items (like dialog.backgroundColor)
 * will reference that palette by its key instead of defining a literal value.
 * So this function will replace all the references to previously-defined theme elements with their actual values.
 */
function populateThemeElements(themeElements, mainThemeElements) {
    for (const key in themeElements) {
        const value = themeElements[key];
        if (typeof value === "string") {
            const nested = ObjectUtil.getNestedObject(mainThemeElements, value);
            if (nested != null)
                themeElements[key] = nested;
        } else if (typeof value === "object")
            populateThemeElements(value, mainThemeElements);
    }
}

function getLog(): ModuleLogger {
    if (log == null)
        log = getLogger("core.Theme");
    return log;
}
