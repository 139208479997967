import { Button, ButtonProps, Component, ComponentProps, Cursor, DragEvent, Layout, MouseEvent, Panel, PanelProps } from "@mcleod/components";
import { HeightWidth } from "@mcleod/core";
import { HomePageEditor } from "./HomePageEditor";
import { colWidth, gap, rowHeight } from "./WidgetGrid";

const editButtonProps: Partial<ButtonProps> = { rowBreak: false, height: 28, width: 110, paddingBottom: 0, paddingTop: 0 }
const mouseUpListener = (event: MouseEvent) => (event.target as Component).cursor = Cursor.GRAB;
const mouseDownListener = (event: MouseEvent) => (event.target as Component).cursor = Cursor.GRABBING;

export interface HomeWidgetProps extends PanelProps {
    homeEditor?: HomePageEditor,
    layoutName: string
}

export class HomeWidget extends Panel implements HomeWidgetProps {
    private _homeEditor: HomePageEditor;
    widgetBorderProps: Partial<PanelProps>;
    private _layout: Layout;
    private _layoutName: string;

    private _selected: boolean;
    onWidgetLoaded: (widgetLayout?: Layout) => void;
    constructor(props: Partial<HomeWidgetProps>) {
        super({
            ...props,
            padding: 0,
            backgroundColor: "defaultBackground"
        });
    }

    public get layoutName(): string {
        return this._layoutName;
    }

    public set layoutName(value: string) {
        this._layoutName = value;
        this._layout = Layout.getLayout(this._layoutName, { padding: 0 });
        this.layout.addLayoutLoadListener(() => this.widgetLoaded())
    }

    public get layout(): Layout {
        return this._layout;
    }

    private widgetLoaded() {
        this.layout.isNested = true;
        this.add(this.layout);
        this._layout.setProps(this.getHeightWidthProps());
        this.setSizeProps();
        this.widgetBorderProps = { borderColor: this.layout.borderColor, borderWidth: this.layout.borderWidth };
        if (this.homeEditor != null) {
            this.addClickListener(() => this.selected = true);
            this.draggable = this.homeEditor != null;
            this.cursor = Cursor.GRAB;
            this.disableEvents();
            const buttonDelete = new Button({
                imageHeight: 24, imageWidth: 24,
                imageName: "delete",
                borderWidth: 0, color: "primary.reverse", padding: 4,
                onClick: () => this.homeEditor.removeWidget(this)
            });
            this.layout.titleBar.replace(this.layout.titleBar.refreshButton, buttonDelete);
        }
        if (this.onWidgetLoaded)
            this.onWidgetLoaded();
    }

    get size(): HeightWidth {
        const sizeParts = this.layout.homePageSize.split("x");
        return { width: parseInt(sizeParts[0]), height: parseInt(sizeParts[1]) };
    }

    private setSizeProps() {
        const props = this.getHeightWidthProps();
        this.setProps({ ...props });
        this.style.gridColumn = `span ${this.size.width}`;
        this.style.gridRow = `span ${this.size.height}`;
    }

    private getHeightWidthProps(): Partial<ComponentProps> {
        const actualHeight = (this.size.height * rowHeight) + ((this.size.height - 1) * gap);
        const actualWidth = (this.size.width * colWidth) + ((this.size.width - 1) * gap);
        return {
            minHeight: actualHeight, minWidth: actualWidth, maxHeight: null, maxWidth: null, height: actualHeight, width: actualWidth
        }
    }

    public get homeEditor(): HomePageEditor {
        return this._homeEditor;
    }

    public set homeEditor(value: HomePageEditor) {
        this._homeEditor = value;
        if (this._homeEditor != null) {
            this.addDragStartListener(() => { this.selected = true });
            this.addDragEndListener(() => this.cursor = Cursor.GRAB);
            this.addDropListener((event: DragEvent) => this.dropped(event));
            this.addDragOverListener((event: DragEvent) => this.dragOver(event));
            this.addDragLeaveListener((event: DragEvent) => this.dragLeave(event));
        }
    }

    public get selected(): boolean {
        return this._selected || false;
    }

    public set selected(value: boolean) {
        if (this.selected != value) {
            this._selected = value;
            this.resetWidgetBorder();
            if (value) {
                this.addMouseDownListener(mouseDownListener);
                this.addMouseUpListener(mouseUpListener);
                this.homeEditor.selectedWidget = this;
                this.layout.setProps({ borderColor: "warning", borderWidth: 3 });
            } else {
                this.removeMouseDownListener(mouseDownListener);
                this.removeMouseUpListener(mouseUpListener);
            }
        }
    }

    resetWidgetBorder() {
        this.layout.setProps({ ...this.widgetBorderProps })
    }

    private disableEvents() {
        this.layout.components.forEach(comp => {
            if (comp != this.layout.titleBar) {
                comp.style.userSelect = "none";
                comp.style.pointerEvents = "none";
                comp.style.userSelect = "none";
            }
        })
    }

    dropped(event: DragEvent) {
        if (this.selected) {
            this.cursor = Cursor.GRAB;
        } else {
            this.resetWidgetBorder();
            if (event.target instanceof Component)
                this.homeEditor.selectedDropped(event.target);
        }
    }

    dragOver(event: DragEvent) {
        if (!this.selected)
            this.layout.setProps({ borderColor: "success.lighter", borderWidth: 4 });
        event.preventDefault();
    }

    dragLeave(event: DragEvent) {
        if (!this.selected)
            this.resetWidgetBorder();
    }
}
