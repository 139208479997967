import {
    Button, ChangeEvent, ClickEvent, DataSource, DataSourceAction, DataSourceExecutionEvent, DataSourceMode,
    DropdownItem, Image, ImageType, Label, Layout, Panel, SlideoutDecorator, Snackbar, Switch, TableRow,
    TableRowDisplayEvent, Textbox
} from "@mcleod/components";
import { AutogenLayoutP44ShipmentTracking } from "./autogen/AutogenLayoutP44ShipmentTracking";
import { Api, DateUtil, FileUtil, getAuthSettings, ModelRow, ProcessLock, StringUtil } from "@mcleod/core";
import { OrderCreditValidator } from "@mcleod/dispatch/src/OrderCreditValidator";
import { ModelCallin } from "@mcleod/dispatch/src/models/ModelCallin";
import { DispatchSlideoutProps } from "@mcleod/dispatch/src/DispatchSlideout";
import { CommonDialogs } from "@mcleod/common";
import { SearchFilterVisible } from "@mcleod/components/src/components/table/Table";


export class P44ShipmentTracking extends AutogenLayoutP44ShipmentTracking {
    doOnSlideoutClosed: (dataPosted: boolean) => void;
    private _onHold = false;
    private _orderId: string;
    private lastCalcEtaFilter: any;
    private _creditValidationPromise: Promise<Snackbar>;
    calculateEta: (dataSource: DataSource, filter: any) => void;
    private _movementId: string;

    public get onHold() {
        return this._onHold;
    }

    private set onHold(value) {
        this._onHold = value;
        if (value) {
            const preventDisptachHold = getAuthSettings().dispatch_control[0].prevent_dsp_hold;
            if (preventDisptachHold == "Y") {
                this.switchToggleLayout.enabled = false;
                this.switchToggleLayout.disabledTooltip = "You cannot switch to Arrival/Departure Update because the order is on hold.";
            }
        }
    }

    public setMovementId(movementId: string) {
        this._movementId = movementId;
    }

    public setOrderId(orderId: string) {
        this._orderId = orderId;
    }

    override onLoad(): void {
        const checked = this.switchToggleLayout.checked;
        this.layoutClearStop.visible = checked;
        this.layoutCallinEntry.visible = !checked;
        this.sourceCallin.addAfterExecutionListener(() => {
            this.layoutCallinEntry.compareNextSchedToCallinDate();
            const filterItems = (tb: Textbox) => {
                tb.items = (tb.items as DropdownItem[]).filter(item => item.value !== "T");
            }
            if (this.sourceCallin.activeRow?.get("initiated_type") !== "T") {
                filterItems(this.layoutCallinEntry.textboxInitiatedType);
                filterItems(this.layoutClearStop.tbCallinType);
            }
        });

        // tie parent sourceCallin to nested layouts
        this.layoutClearStop.bindCallinDataSource(this.sourceCallin);
        this.layoutCallinEntry.bindCallinDataSource(this.sourceCallin);

        this.sourceCallin.displayDataInBoundComponents();
    }

    public async closeOverlay(cancelled: boolean) {
        this.slideOut().then(async () => {
            if (this.doOnSlideoutClosed != null)
                this.doOnSlideoutClosed(cancelled);
        });
    }

    private setActiveRowFromProps(props: DispatchSlideoutProps): Promise<any> {
        if (props.movementOrId instanceof ModelRow) {
            const movement = props.movementOrId as ModelRow;
            return this.search({ id: movement.get("id") }).then(() => {
                this.mainDataSource.activeRow.setValues({ ...movement.data });
            })
        }
        return this.sourceDispatch.search({ id: props.movementOrId })
    }

    private prepareLayoutForSlideout(movementId: string, props: DispatchSlideoutProps): Promise<boolean> {
        return new Promise((resolve) => {
            this.addLayoutLoadListener(async () => {
                try {
                    this.onHold = props.orderOnHold;
                    this._orderId = props.orderId;
                    this.layoutClearStop.ordersCustomerId = props.customerId;
                    this._movementId = movementId;
                    await this.setActiveRowFromProps(props).then(async () => {
                        if (this.mainDataSource.activeRow == null) {
                            CommonDialogs.showDialog(`Movement was not found.`);
                            return resolve(false);
                        }
                        this.mainDataSource.mode = DataSourceMode.UPDATE;

                        if (this.onHold !== true) {
                            await OrderCreditValidator.customerCreditLimitUndefined(props.orderId).then(creditUndefined => {
                                if (creditUndefined) {
                                    this.switchToggleLayout.enabled = false;
                                    this.switchToggleLayout.disabledTooltip = "You cannot switch to Arrival/Departure Update because the customer does not have a defined credit limit.";
                                }
                            });
                        }

                        if (this.mainDataSource.activeRow.get("stop_id") != null) {
                            await this.layoutClearStop.sourceCallinStop.search({ id: this.mainDataSource.activeRow.get("stop_id") }).then(async () => {
                                this.layoutClearStop.doAfterStopCleared = (nextStop) => {
                                    // Update tooltip and panel with ModelRow or sourceCallinStop
                                    let tempDS = this.layoutClearStop.sourceCallinStop;
                                    if (nextStop != null) {
                                        tempDS = new DataSource();
                                        tempDS.setRowsAndMode(DataSourceMode.UPDATE, [nextStop], null);
                                    }
                                }
                                this.layoutClearStop.sourceCallinStop.mode = DataSourceMode.UPDATE;
                                this.switchToggleLayout.checked = this.layoutClearStop.sourceCallinStop?.activeRow?.get("actual_arrival") != null && this.switchToggleLayout.enabled == true;
                            })
                        } else {
                            this.switchToggleLayout.visible = false;
                        }
                        await this.sourceCallin.search({
                            movement_id: this.mainDataSource.activeRow?.get("id"),
                            new_row: true,
                            include_callin_script_details: true
                        }).then(() => {
                            this.sourceCallin.mode = DataSourceMode.UPDATE;
                            this.sourceCallin.activeRow._appending = true;
                            this.layoutClearStop.layoutCallinScript.buildScript();
                            this.layoutCallinEntry.layoutCallinScript.buildScript();
                        });
                        this.layoutRecentCallins.sourceCallin.search({ movement_id: this._movementId });
                        this.layoutRecentCallins.sourceCallin.displayDataInBoundComponents();

                        if (this.switchToggleLayout.enabled && this.switchToggleLayout.visible) {
                            this.switchToggleLayout.checked = this.layoutClearStop.sourceCallinStop?.activeRow?.get("actual_arrival") != null ? true : getAuthSettings()?.user_settings?.default_dispatch_layout == "A";
                        }

                        resolve(true);
                    });
                } catch (error) {
                    CommonDialogs.showError(error);
                    resolve(false);
                }
            });
        });
    }

    async prepareP44TabInformation(movementId: string, orderId: string): Promise<boolean> {
        let overviewData;
        let referenceData;
        let images_supported, dr_supported, bol_supported, invoice_supported, weight_cert_supported;
        let status_history;
        let stop_history;
        let success: boolean = true;
        await Api.post("lme/powerbroker/p44-ltl-tracking", {
            "action": "get-p44-tracking-data", "movement_id": movementId, "order_id": orderId
        }).then(async response => {
            overviewData = response.data[0];
            referenceData = response.data[0].reference_values;
            images_supported = response.data[0].images_supported;
            dr_supported = response.data[0].dr_supported;
            bol_supported = response.data[0].bol_supported;
            invoice_supported = response.data[0].invoice_supported;
            weight_cert_supported = response.data[0].weight_cert_supported;
            status_history = response.data[0].status_history;
            stop_history = response.data[0].stop_history;

            const headerInfo = new ModelRow("lme/dispatch/brltl-billing-freight-group", false, {
                "current_status": overviewData.current_status,
                "pickup_date": overviewData.pickup_date,
                "est_delivery_date": overviewData.est_delivery_date,
                "act_delivery_date": overviewData.act_delivery_date,
                "image_bytes": overviewData.image_bytes,
                "carrier_name": overviewData.carrier_name
            });
            this.loadImage(overviewData.image_bytes);
            this.panelOverview.displayData(headerInfo, null, 0);

            this.tableReferenceValues.searchFilterVisible = SearchFilterVisible.NEITHER;
            if (referenceData) {
                this.tableReferenceValues.data = referenceData;
                this.tableReferenceValues.displayData(null, referenceData, 0);
            }
            if (!images_supported) {
                this.panelImagesToDownload.visible = false;
                Snackbar.showSnackbar("Unable to verify that this carrier supports imaging.");
            } else {
                this.panelDeliveryReceipt.visible = dr_supported ? dr_supported : false;
                this.panelBOL.visible = bol_supported ? bol_supported : false;
                this.panelInvoice.visible = invoice_supported ? invoice_supported : false;
                this.panelWeightCert.visible = weight_cert_supported ? weight_cert_supported : false;
            }
            this.tableStatusUpdates.searchFilterVisible = SearchFilterVisible.NEITHER;
            this.tableStopStatus.searchFilterVisible = SearchFilterVisible.NEITHER;
            if (status_history) {
                this.tableStatusUpdates.data = status_history;
                this.tableStatusUpdates.displayData(null, status_history, 0);
            }
            if (stop_history) {
                this.tableStopStatus.data = stop_history;
                this.tableStopStatus.displayData(null, stop_history, 0);
            }
        }).catch(reason => {
            Snackbar.showWarningSnackbar("Project44: " + reason.messages[0]);
            success = false;
        });
        return success;
    }

    public static async showSlideout(movementId: string, props: DispatchSlideoutProps): Promise<boolean> {
        if (movementId == null) return;
        const intialized = await this.initializeP44Tracking(movementId);
        if (!intialized) return false;
        ProcessLock.aquireLock("Carrier dispatch", movementId).then(lockResult => {
            if (lockResult.success === true) {
                let outcome: boolean = true;
                const layout = Layout.getLayout("lme/powerbroker/P44ShipmentTracking") as P44ShipmentTracking;
                layout.prepareLayoutForSlideout(movementId, props).then(async () => {
                    const fromCityState = StringUtil.toProperCase(props.originCity) + ", " + props.originState;
                    const toCityState = StringUtil.toProperCase(props.destinationCity) + ", " + props.destinationState;
                    await layout.prepareP44TabInformation(movementId, props.orderId).then((success) => {
                        outcome = success;
                        new SlideoutDecorator({
                            title: `Project44 Tracking - Order ${props.orderId}\t\t${fromCityState} to ${toCityState}`,
                            layout: layout,
                            width: 1200,
                            fillVerticalSpace: true,
                            backgroundColor: "defaultBackground",
                            onClose: (cancelled: boolean) => {
                                props?.onClose?.(cancelled);
                                layout.closeOverlay(cancelled);
                            },
                            doAfterSlideIn: async () => {
                                if (layout.layoutClearStop.visible)
                                    layout.validateCreditAndShowWarning();
                            },
                            doAfterSlideOut: () => {
                                ProcessLock.releaseLock("Carrier dispatch", movementId);
                                props?.doAfterSlideOut;
                            }
                        });
                    });
                });
                return outcome;
            } else {
                CommonDialogs.showDialog(lockResult.message, { title: "Dispatch in Progress" });
            }
        });
        return false;
    }

    calcNextStopEta(sourceCallin: DataSource, filter: any) {
        if (filter.city_id != null && filter.movement_id != null && (this.lastCalcEtaFilter == null || this.lastCalcEtaFilter !== filter)) {
            this.lastCalcEtaFilter = filter;
            new ModelCallin().searchSingle(filter)
            .then(result => {
                this.setNextStopEta(sourceCallin, result)
                this.setEtaLate(sourceCallin);
            }).catch(() => {
                this.setNextStopEta(sourceCallin, null)
                this.setEtaLate(sourceCallin);
            });
        }
    }

    setNextStopEta(sourceCallin: DataSource, result: ModelRow<any>) {
        sourceCallin?.activeRow?.set("new_eta", result?.get("new_eta"));
        sourceCallin?.activeRow?.set("new_eta_date_string", result?.get("new_eta_date_string"));
        sourceCallin?.activeRow?.set("new_eta_time_string", result?.get("new_eta_time_string"));
        sourceCallin?.activeRow?.set("miles2consignee", result?.get("miles2consignee"));
        sourceCallin?.activeRow?.set("miles2consignee_um", result?.get("miles2consignee_um"));
    }

    setEtaLate(sourceCallin: DataSource) {
        if (!this.mainDataSource.activeRow?.get("stop_id") || !this.layoutClearStop.sourceCallinStop?.activeRow) return;

        const eta: Date = sourceCallin?.activeRow?.get("new_eta");
        const late: Date = this.layoutClearStop.sourceCallinStop?.activeRow?.get("sched_arrive_late")
            ? this.layoutClearStop.sourceCallinStop?.activeRow?.get("sched_arrive_late")
            : this.layoutClearStop.sourceCallinStop?.activeRow?.get("sched_arrive_early")

        const difference = new Date(eta).getTime() - new Date(late).getTime();

        if (difference > 0) {
            const diffObj = DateUtil.msToTime(difference);
            if (diffObj.hours <= 0 && diffObj.minutes <= 0) return;

        }
    }

    /** This is an event handler for the afterExecution event of sourceDispatch.  */
    sourceDispatchAfterExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() === DataSourceAction.SEARCH) {
            if (this.mainDataSource.activeRow?.get("brokerage_status") === "AVAIL") {
                this.switchToggleLayout.enabled = false;
                this.switchToggleLayout.disabledTooltip = "You cannot switch to Arrival/Departure Update because the status is Available."
            }
        }
    }

    async buttonSaveShipmentInfoOnClick() {
        try {
            this.buttonSaveShipmentInfo.busy = true;
            const postClearStop = this.switchToggleLayout.checked;
            if (postClearStop)
                await this._saveClearedStop();
            else
                await this._saveCallin();
        } finally {
            this.buttonSaveShipmentInfo.busy = false
        }
    }

    private async _saveCallin() {
        this.sourceCallin.activeRow.set("movement_id", this._movementId);
        this.sourceCallin.activeRow.set("order_id", this._orderId);
        if (this.sourceCallin.activeRow.get("movement_id") == null)
            return;
        const nextSchedCall = this.sourceCallin.activeRow.get("sched_date_time");
        if (nextSchedCall)
            this.sourceDispatch.activeRow.set("next_sched_call", nextSchedCall);

        await this.sourceCallin.post().then(() => {
            this.sourceDispatch.post().then(async () => {
                this.layoutRecentCallins.sourceCallin.search({ movement_id: this._movementId });
                this.layoutRecentCallins.sourceCallin.displayDataInBoundComponents();
                this.layoutCallinEntry.sourceCallin.activeRow.clear();
                this.layoutCallinEntry.sourceCallin.activeRow.set("call_date_time", new Date());
                this.layoutCallinEntry.sourceCallin.displayDataInBoundComponents();
            });
        }).catch(error => {
            this.buttonSaveShipmentInfo.busy = false;
            throw error
        });
    }

    private async _saveClearedStop() {
        const nextSchedCall = this.sourceCallin.activeRow.get("sched_date_time");
        if (nextSchedCall)
            this.mainDataSource.activeRow.set("next_sched_call", nextSchedCall);
        this.layoutClearStop.sourceCallinStop.activeRow.set("callin_to_post_data", this.sourceCallin.activeRow);
        await this.layoutClearStop.sourceCallinStop.post().then(async stopRow => {
            if (!stopRow) return;
            if (this.mainDataSource.hasChanged()) {
                await this.mainDataSource.post();
            }
            this.closeOverlay(false);
        });
    }

    switchToggleLayoutOnChange(event: ChangeEvent) {
        this.layoutClearStop.visible = event.newValue;
        this.layoutCallinEntry.visible = !event.newValue;

        if (event.userInitiatedChange)
            this.validateCreditAndShowWarning()
    }

    private validateCreditAndShowWarning() {
        this.creditValidationPromise.then((snackbar: Snackbar) => {
            if (snackbar) snackbar.visible = this.layoutClearStop.visible;
        })
    }

    private get creditValidationPromise(): Promise<Snackbar> {
        if (this._creditValidationPromise === undefined && this.layoutClearStop.visible === true) {
            this._creditValidationPromise = OrderCreditValidator.validateCreditAndShowWarning(this.layoutClearStop.sourceCallinStop.activeRow?.get("order_id"));
        }
        return this._creditValidationPromise;
    }

    loadImage(imageBytes: any) {
        const panel = this.findComponentById("panelImage") as Panel;
        if (panel.getComponentCount() != 0)
            return;
        if (imageBytes != null) {
            const img = new Image({
                id: "carrierImg", imageType: ImageType.IMG,
                preserveAspectRatio: true, height: 50, width: 100
            });
            img.imageBytes = imageBytes;
            panel.add(img);
        } else {
            panel.visible = false;
            const labelCarrierName = this.findComponentById("labelCarrierName") as Label;
            labelCarrierName.marginLeft = 12;
        }
    }

    switchTableControlOnChange(event: ChangeEvent) {
        if (event.userInitiatedChange) {
            const switchControl = event.target as Switch;
            this.tableStatusUpdates.visible = !switchControl.checked;
            this.tableStopStatus.visible = switchControl.checked;
        }
    }

    buttonDownloadImagesOnClick(event: ClickEvent) {
        const buttonDownload = event.target as Button;
        buttonDownload.busy = true;
        this.downloadPDF(buttonDownload);
    }

    private downloadPDF(buttonDownload: Button) {
        let imageTypes: string = "";
        if (this.switchDeliveryReceipt.checked)
            imageTypes = "DELIVERY_RECEIPT";
        if (this.switchBOL.checked)
            imageTypes = (imageTypes.length == 0) ? "BILL_OF_LADING" : imageTypes + ",BILL_OF_LADING";
        if (this.switchInvoice.checked)
            imageTypes = (imageTypes.length == 0) ? "INVOICE" : imageTypes + ",INVOICE";
        if (this.switchWeightCert.checked)
            imageTypes = (imageTypes.length == 0) ? "WEIGHT_CERTIFICATE" : imageTypes + ",WEIGHT_CERTIFICATE";
        const todaysDate = DateUtil.formatDate(new Date(), "MM/dd/yyyy");
        const fileName = `Move-${this._movementId}-p44-docs-${todaysDate}.pdf`;
        Api.post("lme/powerbroker/p44-ltl-tracking", {
            action: "download-images",
            image_types: imageTypes,
            movement_id: this._movementId
        }).then(async response => {
            if (response.data[0].download_message)
                await Snackbar.showSnackbar(response.data[0].download_message, { millisUntilDimissal: 5000 });
            const pdf = response?.data[0]?.pdf_file;
            if (pdf != null) {
                FileUtil.downloadBase64AsFile(pdf, fileName);
                Snackbar.showSnackbar("Downloaded " + fileName, { millisUntilDimissal: 5000 });
            }
        }).finally(() => (buttonDownload.busy = false));
    }

    processReferenceNumberUpdate(referenceInfo: string, button: Button) {
        button.busy = true;
        Api.post("lme/powerbroker/p44-ltl-tracking", {
            action: "update-reference_value",
            reference_update_info: referenceInfo,
            movement_id: this._movementId
        }).then(async response => {
            const success: boolean = response.data[0].update_success;
            if (success) {
                if (response.data[0].reference_values) {
                    this.tableReferenceValues.data = response.data[0].reference_values;
                    this.tableReferenceValues.displayData(null, response.data[0].reference_values, 0);
                }
            }
            Snackbar.showSnackbar(response.data[0].update_message, { millisUntilDimissal: 10000 });
        }).finally(() => (button.busy = false));
    }

    buttonOrderChoiceOnClick(event: ClickEvent) {
        const button: Button = event.target as Button;
        const tableRow = TableRow.getContainingTableRow(button);
        const refInfo = tableRow.data["type"] + "," + tableRow.data["orderValue"] + "," + "updateP44";
        this.processReferenceNumberUpdate(refInfo, button);
    }

    buttonP44ChoiceOnClick(event: ClickEvent) {
        const button: Button = event.target as Button;
        const tableRow = TableRow.getContainingTableRow(button);
        const refInfo = tableRow.data["type"] + "," + tableRow.data["p44Value"] + "," + "updateOrder";
        this.processReferenceNumberUpdate(refInfo, button);
    }

    tableReferenceValuesOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        const labelMatchingStatus = tableRow.findComponentById("labelMatchingStatus") as Label;
        if (tableRow.data["p44Value"] != tableRow.data["orderValue"]) {
            const buttonP44Choice = tableRow.findComponentById("buttonP44Choice") as Button;
            buttonP44Choice.visible = true;
            const buttonOrderChoice = tableRow.findComponentById("buttonOrderChoice") as Button;
            buttonOrderChoice.visible = true;
            labelMatchingStatus.visible = true;
        } else if (tableRow.data["p44Value"] != null && tableRow.data["p44Value"] != null &&
            tableRow.data["p44Value"] == tableRow.data["orderValue"]) {
            labelMatchingStatus.imageName = "circleCheck";
            labelMatchingStatus.imageColor = "success";
            labelMatchingStatus.visible = true;
        }
    }

    public static async initializeP44Tracking(movementId: string): Promise<boolean> {
        let success: boolean = false;
        let alreadyInitialized: boolean = false;
        await Api.post("lme/powerbroker/p44-ltl-tracking", {
            "action": "p44-initialized", "movement_id": movementId
        }).then(async response => {
            const promptMessage = response.data[0]["can_lookup_msg"];
            alreadyInitialized = response.data[0]["initialized"];
            if (promptMessage) {
                success = await CommonDialogs.showYesNo(promptMessage, "Project44 Initialize");
            }
        });
        if (alreadyInitialized) return true;
        if (!success) return false;

        await Api.post("lme/powerbroker/p44-ltl-tracking", {
            "action": "init_p44_tracking", "movement_id": movementId
        }).then(response => {
            const message: string = response.data[0]["init_message"];
            if (message.startsWith("Success")) success = true;
            else {
                CommonDialogs.showDialog(message, { title: "P44 Initialization error" });
                success = false;
            }
        }).catch(exception => {
            CommonDialogs.showDialog(exception.messages[0], { title: "P44 Initialization error" });
            success = false;
        });
        return success;
    }
}
