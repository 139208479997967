import { ClickEvent, DataDisplayEvent } from "@mcleod/components";
import { ModelRow } from "@mcleod/core";
import { LogicBrokeragePlanningPerformance } from "./LogicBrokeragePlanningPerformance";
import { AutogenLayoutBrokeragePlanningPerformanceOrders } from "./autogen/AutogenLayoutBrokeragePlanningPerformanceOrders";
import { BrokeragePlanningPerformance } from "./widgets/BrokeragePlanningPerformance";

export class BrokeragePlanningPerformanceOrders extends AutogenLayoutBrokeragePlanningPerformanceOrders {
    public perfHeaderRow: ModelRow;
    private brokeragePlanningPerformance: BrokeragePlanningPerformance;

    onLoad() {
        this.chartOrders.renderDefaultData = false;
        this.brokeragePlanningPerformance = this.owner as BrokeragePlanningPerformance;
    }

    /** This is an event handler for the onDisplay event of sourceBPPerfOrders.  */
    sourceBPPerfOrdersOnDisplay(event: DataDisplayEvent) {
        LogicBrokeragePlanningPerformance.setTableColumnHeaders(this.tableBPPerfOrders, this.perfHeaderRow);
        const brokerageStatusesToChart = ["Available", "Spot Orders", "Covered", "In Progress", "Delivered", "Total"];
        LogicBrokeragePlanningPerformance.createChartData(this.tableBPPerfOrders, this.chartOrders, this.mainDataSource.data, this.perfHeaderRow, brokerageStatusesToChart);
        this.chartOrders.renderChart();
    }

    cellLabelOnClick(event: ClickEvent) {
        LogicBrokeragePlanningPerformance.cellLabelOnClick(event, this.perfHeaderRow, this.brokeragePlanningPerformance.currentProfile);
    }
}
