import { DataSource, Label, Layout, Panel, Tab, Table, Tabset, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutEdiOrderCarrierPay extends Layout {
    labelBillDistance: Label;
    labelRateDesc: Label;
    labelTotalPay: Label;
    panel1: Panel;
    panelMain: Panel;
    sourceEdiDriverextrapay: DataSource;
    sourceViewEdiOrder: DataSource;
    tabOtherPay: Tab;
    tableDriveExtraPay: Table;
    tabsetEdiDriverExtraPay: Tabset;
    textboxCarProNbr: Textbox;
    textboxOtherPayTotal: Textbox;
    textboxOverridePayAmt: Textbox;
    textboxOverridePayRate: Textbox;
    textboxOverridePayUnits: Textbox;
    textboxOverrideType: Textbox;
    textboxTotalPay: Textbox;
}
