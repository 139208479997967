import { Button, DataHeader, DataSource, HorizontalSpacer, Label, Layout, Location, Panel, Tab, Tabset, Textbox } from "@mcleod/components";
import { QuoteRates } from "../../../dispatch/src/QuoteRates";
import { QuoteStopTable } from "../QuoteStopTable";
import { QuoteSummary } from "../QuoteSummary";

export abstract class AutogenLayoutQuoteLanding extends Layout {
    LabelComments: Textbox;
    buttonConvertToOrder: Button;
    buttonConvertToOrderNextAction: Button;
    buttonConvertToSpot: Button;
    buttonConvertToSpotNextAction: Button;
    buttonEmailNextAction: Button;
    buttonEmailQuote: Button;
    buttonQuoteHistory: Button;
    buttonUsers: Button;
    dataheaderQuote: DataHeader;
    horizontalspacer1: HorizontalSpacer;
    labelGeneralInformationHeader: Label;
    labelQuoteNextActions: Label;
    labelTemperatureRange: Label;
    layoutQuoteRates: QuoteRates;
    layoutQuoteStopTable: QuoteStopTable;
    layoutQuoteSummary: QuoteSummary;
    panel2: Panel;
    panel3: Panel;
    panelDataHeaderAddlLeft: Panel;
    panelGeneralInfo: Panel;
    panelGeneralInfoTab: Panel;
    panelGroupAndDates: Panel;
    panelNextActionButtons: Panel;
    panelNextActionsAdd: Panel;
    panelNextActionsUpdate: Panel;
    panelRightGroup: Panel;
    sourceQuoteOrder: DataSource;
    sourceQuoteStop: DataSource;
    sourceResponsibleHist: DataSource;
    tabAdditionalInformation: Tab;
    tabGeneral: Tab;
    tabPickupandDeliveries: Tab;
    tabRates: Tab;
    tabset1: Tabset;
    textboxCommodityDescription: Textbox;
    textboxCommodityId: Textbox;
    textboxCtrlParty: Location;
    textboxCustomerContactName: Textbox;
    textboxCustomerEmail: Textbox;
    textboxCustomerName: Textbox;
    textboxCustomerPhone: Textbox;
    textboxEffectiveDate: Textbox;
    textboxEquipmentTypeId: Textbox;
    textboxExpirationDate: Textbox;
    textboxGroupId: Textbox;
    textboxOrderMode: Textbox;
    textboxOrderTypeId: Textbox;
    textboxPlanningComment: Textbox;
    textboxQuoteStatus: Textbox;
    textboxRevenueCodeId: Textbox;
    textboxTemperatureMax: Textbox;
    textboxTemperatureMin: Textbox;
    textboxWeight: Textbox;
}
