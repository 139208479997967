/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelTemplateRateConfirmation.ts      *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/powerbroker/template-rate-confirmation";

export class AutogenModelTemplateRateConfirmation<RowType extends ModelRow> extends Endpoint<RowType, RowTemplateRateConfirmationFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowTemplateRateConfirmation extends ModelRow<RowTemplateRateConfirmationFields> {
    constructor() {
        super(AutogenUserSavedSearchEndpoint);
    }
}

export interface RowTemplateRateConfirmationFields {
    background_filename: string;
    background_frequency: string;
    background_image: string;
    company_id: string;
    descr: string;
    document_type: string;
    engine_type: string;
    html_output_type: string;
    is_secure: string;
    label_type: string;
    manufacturer: string;
    margin_bottom: number;
    margin_left: number;
    margin_right: number;
    margin_top: number;
    not_void_no: string;
    page_length: number;
    page_width: number;
    pathname: string;
    print_device_id: string;
    print_device_type: string;
    query_id: string;
    sql_statement: string;
    template_number: number;
}

