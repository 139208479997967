import { TableColumn } from "@mcleod/components";
import { DateUtil, getAuthSettings, ModelRow, OrderByInfo } from "@mcleod/core";
import { isSpotOrder } from "@mcleod/dispatch/src/Orders";
import { Columns } from "./BrokerageMovementTableColumns";
import { getStopTime, getTimeDiff } from "./BrokeragePlanningUtil";
import { RowBrokeragePlanningProfile } from "./models/ModelBrokeragePlanningProfile";

const availableColumns = [Columns.ORDER, Columns.STATUS, Columns.PLANNING, Columns.REF_NBR, Columns.ORIGIN, Columns.DEST,
Columns.DISTANCE, Columns.COMMODITY, Columns.REVENUE, Columns.CUSTOMER, Columns.USERS, Columns.CODES];

const coveredColumns = [Columns.ORDER, Columns.STATUS, Columns.PLANNING, Columns.CARRIER, Columns.CARRIER_COMMUNICATIONS,
Columns.REF_NBR, Columns.ORIGIN, Columns.DEST, Columns.DISTANCE, Columns.COMMODITY, Columns.REVENUE, Columns.CUSTOMER, Columns.USERS, Columns.CODES];

export type Filter = (row: ModelRow, profile: RowBrokeragePlanningProfile) => boolean;

export interface SecondaryFilter {
    id: string;
    caption: string;
    filter: (row: ModelRow) => boolean;
}

interface BrokerageMovementTableTypeProps {
    getColumns?: () => Columns[];
    orderColumnBadgeIds: string[];
    revenueColumnFields: string[];
    caption: string;
    tableIdSuffix: string;
    filter: Filter;
    secondaryFilters?: SecondaryFilter[];
    configureColumn?: (column: TableColumn) => void;
    defaultSort?: OrderByInfo[];
    moveStatus?: string;
}

export class BrokerageMovementTableType implements BrokerageMovementTableTypeProps {
    // secondary filters
    static readonly FILTER_LATE = {
        id: "isLate",
        caption: "Late",
        filter: (row: ModelRow) => {
            return (this.isLateWarning(row, "origin")
                || this.isLateWarning(row, "destination"));
        }
    }

    static readonly FILTER_SERV_FAILURE = {
        id: "serviceFailure",
        caption: "Service Failure",
        filter: (row: ModelRow) => row.getBoolean("has_service_failures", false),
    }

    static readonly FILTER_APPT_REQUIRED = {
        id: "apptRequired",
        caption: "Appointment Required",
        filter: (row: ModelRow) =>
        ((row.getBoolean("origin_appt_required", false) && !row.getBoolean("origin_appt_confirmed", false))
            || (row.getBoolean("destination_appt_required", false) && !row.getBoolean("destination_appt_confirmed", false))),
    }

    static readonly FILTER_APPT_CONFIRMED = {
        id: "apptConfirmed",
        caption: "Appointment Confirmed",
        filter: (row: ModelRow) =>
        ((row.getBoolean("origin_appt_required", false) && row.getBoolean("origin_appt_confirmed", false))
            || (row.getBoolean("destination_appt_required", false) && row.getBoolean("destination_appt_confirmed", false))),
    }

    static readonly FILTER_CHECK_CALL_APPR = {
        id: "chkCallApproaching",
        caption: "Approaching Check Call",
        filter: (row: ModelRow) => {
            const warningTime = getAuthSettings().dispatch_control[0].check_call_warning;
            const call = row.data["next_sched_call"];
            if (warningTime == null) return false;
            if (call == null) return false;
            const callDate = DateUtil.parseDateTime(call);
            callDate.setMinutes(callDate.getMinutes() + warningTime);
            return callDate > new Date();
        },
    }

    static readonly FILTER_CHECK_CALL_MISS = {
        id: "chkCallMissed",
        caption: "Missed Check Call",
        filter: (row: ModelRow) => {
            const call = row.data["next_sched_call"];
            if (call == null) return false;
            return new Date() > DateUtil.parseDateTime(call);
        }
    }

    static readonly FILTER_SPOT_ORDERS = {
        id: "spotOrders",
        caption: "Spot Orders",
        filter: (row: ModelRow) => {
            return isSpotOrder(row);
        }
    }

    static readonly FILTER_COMMITTED_ORDERS = {
        id: "committedOrders",
        caption: "Committed Orders",
        filter: (row: ModelRow) => {
            return !isSpotOrder(row);
        }
    }

    static readonly FILTER_MISS_NEXT_CALL_DATE = {
        id: "missNextCheckCall",
        caption: "Missing Next Check Call",
        filter: (row: ModelRow) => {
            const call = row.data["last_broker_carrier_sched_callin_date"];
            return call === undefined;
        },
    }

    static readonly FILTER_NO_DRIVER_INFO_TODAY = {
        id: "noDriverInfoToday",
        caption: "No Driver Info Today",
        filter: (row: ModelRow) => {
            const noDriverCell = row.data["carrier_driver_cell"];

            let originStopEarly = row.data["origin_early"];
            if (originStopEarly !== undefined) {
                originStopEarly = new Date(originStopEarly.split(" ")[0]).setUTCHours(0, 0, 0, 0)
            }

            let originStopLate = row.data["origin_late"];
            if (originStopLate !== undefined) {
                originStopLate = new Date(originStopLate.split(" ")[0]).setUTCHours(0, 0, 0, 0)
            }

            const today = new Date().setUTCHours(0, 0, 0, 0);
            return (noDriverCell === undefined)
                && ((originStopEarly !== undefined && today === originStopEarly)
                    || (originStopLate !== undefined && today === originStopLate)
                    || (originStopEarly !== undefined && originStopLate !== undefined && today > originStopEarly && today < originStopLate))
        },
    }

    static readonly FILTER_NO_DRIVER_INFO_TOMORROW = {
        id: "noDriverInfoTomorrow",
        caption: "No Driver Info Tomorrow",
        filter: (row: ModelRow) => {
            const noDriverCell = row.data["carrier_driver_cell"];

            let originStopEarly = row.data["origin_early"];
            if (originStopEarly !== undefined) {
                originStopEarly = new Date(originStopEarly.split(" ")[0]).setUTCHours(0, 0, 0, 0)
            }

            let originStopLate = row.data["origin_late"];
            if (originStopLate !== undefined) {
                originStopLate = new Date(originStopLate.split(" ")[0]).setUTCHours(0, 0, 0, 0);
            }

            let tomorrow: number | Date = new Date();
            tomorrow.setDate((new Date()).getDate() + 1);
            tomorrow = tomorrow.setUTCHours(0, 0, 0, 0);
            return (noDriverCell === undefined)
                && ((originStopEarly !== undefined && tomorrow === originStopEarly)
                    || (originStopLate !== undefined && tomorrow === originStopLate)
                    || (originStopEarly !== undefined && originStopLate !== undefined && tomorrow > originStopEarly && tomorrow < originStopLate))
        },
    }

    static readonly FILTER_NO_DRIVER_INFO_TOTAL = {
        id: "noDriverInfoTotal",
        caption: "No Driver Info",
        filter: (row: ModelRow) => {
            const noDriverCell = row.data["carrier_driver_cell"];

            let originStopEarly = row.data["origin_early"];
            if (originStopEarly !== undefined) {
                originStopEarly = new Date(originStopEarly.split(" ")[0]).setUTCHours(0, 0, 0, 0)
            }

            let originStopLate = row.data["origin_late"];
            if (originStopLate !== undefined) {
                originStopLate = new Date(originStopLate.split(" ")[0]).setUTCHours(0, 0, 0, 0)
            }

            const today = new Date().setUTCHours(0, 0, 0, 0);
            let tomorrow: number | Date = new Date();
            tomorrow.setDate((new Date()).getDate() + 1);
            tomorrow = tomorrow.setUTCHours(0, 0, 0, 0)
            return (noDriverCell === undefined)
                && ((originStopEarly !== undefined && today === originStopEarly)
                    || (originStopLate !== undefined && today === originStopLate)
                    || (originStopEarly !== undefined && originStopLate !== undefined && today > originStopEarly && today < originStopLate)
                    || (originStopEarly !== undefined && tomorrow === originStopEarly)
                    || (originStopLate !== undefined && tomorrow === originStopLate)
                    || (originStopEarly !== undefined && originStopLate !== undefined && tomorrow > originStopEarly && tomorrow < originStopLate))
        },
    }

    static readonly FILTER_ARRIVED_AT_SHIPPER = {
        id: "arrivedAtShipper",
        caption: "Arrived At Shipper",
        filter: (row: ModelRow) => {
            return (row.get("origin_actual_arrival") !== undefined)
                && (row.get("origin_actual_departure") === undefined)
                && (row.get("origin_stop_type") === "PU")
        },
    }

    static readonly FILTER_SHIPMENT_STATUS_ERROR = {
        id: "shipmentStatusError",
        caption: "Shipment Status Error",
        filter: (row: ModelRow) => row.getBoolean("has_shipment_error", false),
    }

    static readonly FILTER_WATERFALL_IN_PROGRESS = {
        id: "waterfallInProgress",
        caption: "Waterfall In Progress",
        filter: (row: ModelRow) => row.getBoolean("waterfall_in_progress", false)
    }

    // buckets/tabs
    static readonly PERISCOPE = new BrokerageMovementTableType({
        revenueColumnFields: ["total_charge"],
        caption: "Periscope",
        tableIdSuffix: "All",
        filter: (row, profile) => this.holdFilter(row, profile),
        defaultSort: [{ field: "origin_early", sort: "asc" }],
        secondaryFilters: [
            BrokerageMovementTableType.FILTER_CHECK_CALL_MISS,
        ]
    });

    static readonly AVAILABLE = new BrokerageMovementTableType({
        getColumns: () => availableColumns,
        revenueColumnFields: ["pnn_rate", "calc_target_pay", "calc_max_pay"],
        caption: "Available Orders",
        tableIdSuffix: "Available",
        filter: (row, profile) => row.data["move_status"] === "A"
            && this.holdFilter(row, profile),
        secondaryFilters: [
            BrokerageMovementTableType.FILTER_LATE,
            BrokerageMovementTableType.FILTER_APPT_REQUIRED,
            BrokerageMovementTableType.FILTER_APPT_CONFIRMED,
            BrokerageMovementTableType.FILTER_SHIPMENT_STATUS_ERROR,
            BrokerageMovementTableType.FILTER_SPOT_ORDERS,
            BrokerageMovementTableType.FILTER_COMMITTED_ORDERS,
        ],
        configureColumn: (column: TableColumn) => {
            if (column.headingCell.id.toLowerCase().endsWith(Columns.USERS.valueOf().toLowerCase()))
                column.cellDef.def.components = column.cellDef.def.components.filter(comp => (comp.field !== "dispatcher_name"));
            BrokerageMovementTableType.AVAILABLE._internalConfigureColumn(column);
        },
        defaultSort: [{ field: "origin_early", sort: "asc" }],
        moveStatus: "A"
    });

    static readonly COVERED = new BrokerageMovementTableType({
        getColumns: () => coveredColumns,
        caption: "Covered Orders",
        tableIdSuffix: "Covered",
        filter: (row, profile) => row.data["move_status"] === "C"
            && this.holdFilter(row, profile),
        secondaryFilters: [
            BrokerageMovementTableType.FILTER_LATE,
            BrokerageMovementTableType.FILTER_CHECK_CALL_MISS,
            BrokerageMovementTableType.FILTER_CHECK_CALL_APPR,
            BrokerageMovementTableType.FILTER_MISS_NEXT_CALL_DATE,
            BrokerageMovementTableType.FILTER_APPT_REQUIRED,
            BrokerageMovementTableType.FILTER_APPT_CONFIRMED,
            BrokerageMovementTableType.FILTER_ARRIVED_AT_SHIPPER,
            BrokerageMovementTableType.FILTER_NO_DRIVER_INFO_TODAY,
            BrokerageMovementTableType.FILTER_NO_DRIVER_INFO_TOMORROW,
            BrokerageMovementTableType.FILTER_NO_DRIVER_INFO_TOTAL,
            BrokerageMovementTableType.FILTER_SHIPMENT_STATUS_ERROR,
        ],
        defaultSort: [{ field: "origin_early", sort: "asc" }],
        moveStatus: "C"
    });

    static readonly PROGRESS = new BrokerageMovementTableType({
        getColumns: () => {
            return [...coveredColumns.slice(0, 5), Columns.NEXT_STOP, ...coveredColumns.slice(5)];
        },
        caption: "In Progress Orders",
        tableIdSuffix: "InProgress",
        filter: (row, profile) => row.data["move_status"] === "P",
        secondaryFilters: [
            BrokerageMovementTableType.FILTER_LATE,
            BrokerageMovementTableType.FILTER_CHECK_CALL_MISS,
            BrokerageMovementTableType.FILTER_CHECK_CALL_APPR,
            BrokerageMovementTableType.FILTER_MISS_NEXT_CALL_DATE,
            BrokerageMovementTableType.FILTER_APPT_REQUIRED,
            BrokerageMovementTableType.FILTER_APPT_CONFIRMED,
            BrokerageMovementTableType.FILTER_SERV_FAILURE,
            BrokerageMovementTableType.FILTER_SHIPMENT_STATUS_ERROR,
        ],
        defaultSort: [{ field: "destination_early", sort: "asc" }],
        moveStatus: "P"
    });

    static readonly DELIVERED = new BrokerageMovementTableType({
        getColumns: () => coveredColumns,
        caption: "Delivered Orders",
        tableIdSuffix: "Delivered",
        filter: (row, profile) => row.data["move_status"] === "D",
        secondaryFilters: [
            BrokerageMovementTableType.FILTER_SERV_FAILURE,
            BrokerageMovementTableType.FILTER_APPT_REQUIRED,
            BrokerageMovementTableType.FILTER_APPT_CONFIRMED,
            BrokerageMovementTableType.FILTER_SHIPMENT_STATUS_ERROR,
        ],
        defaultSort: [{ field: "destination_early", sort: "asc" }],
        moveStatus: "D"
    });

    static readonly OFFERS = new BrokerageMovementTableType({
        // inject Columns.OFFERS into the available columns after Reference Numbers
        getColumns: () => [...availableColumns.slice(0, 4), Columns.OFFERS, ...availableColumns.slice(4)],
        caption: "Carrier Offers",
        tableIdSuffix: "Offers",
        revenueColumnFields: ["pnn_rate", "calc_target_pay", "calc_max_pay"],
        filter: (row, profile) => row.data["move_status"] === "A"
            && this.hasActiveOffer(row)
            && this.holdFilter(row, profile),
        configureColumn: (column: TableColumn) => {
            if (column.headingCell.id.endsWith(Columns.USERS.valueOf().toString()))
                column.cellDef.def.components = column.cellDef.def.components.filter(comp => (comp.field !== "dispatcher_name"));
            BrokerageMovementTableType.AVAILABLE._internalConfigureColumn(column);
        },
        secondaryFilters: [BrokerageMovementTableType.FILTER_WATERFALL_IN_PROGRESS]
    });

    static readonly HOLD = new BrokerageMovementTableType({
        getColumns: () => {
            return [...availableColumns.slice(0, 2), Columns.HOLD, ...availableColumns.slice(2)];
        },
        caption: "On Hold Orders",
        tableIdSuffix: "OnHold",
        revenueColumnFields: ["pnn_rate", "calc_target_pay", "calc_max_pay"],
        filter: (row, profile) => row.data["hold"] === true,
        secondaryFilters: [
            BrokerageMovementTableType.FILTER_CHECK_CALL_MISS,
            BrokerageMovementTableType.FILTER_CHECK_CALL_APPR,
            BrokerageMovementTableType.FILTER_APPT_REQUIRED,
            BrokerageMovementTableType.FILTER_APPT_CONFIRMED,
            BrokerageMovementTableType.FILTER_SHIPMENT_STATUS_ERROR,
        ],
        defaultSort: [{ field: "origin_early", sort: "asc" }]
    });

    public getColumns?: () => Columns[];
    public revenueColumnFields: string[];
    public orderColumnBadgeIds: string[];
    public statusColumnBadgeIds: string[];
    public caption: string;
    public tableIdSuffix: string;
    public filter: Filter;
    public secondaryFilters?: SecondaryFilter[];
    public configureColumn: (column: TableColumn) => void;
    public defaultSort: OrderByInfo[];
    public moveStatus?: string;

    private constructor(props: Partial<BrokerageMovementTableTypeProps>) {
        this.getColumns = props.getColumns;
        this.revenueColumnFields = props.revenueColumnFields ?? ["total_charge", "total_pay", "margin"];
        this.orderColumnBadgeIds = props.orderColumnBadgeIds ?? ["badgeHold", "badgePending"];
        this.statusColumnBadgeIds = ["badgeBrTrackStatus"];
        this.caption = props?.caption;
        this.tableIdSuffix = props?.tableIdSuffix;
        this.filter = props.filter;
        this.secondaryFilters = props.secondaryFilters;
        this.configureColumn = props.configureColumn ?? ((column: TableColumn) => this._internalConfigureColumn(column));
        this.defaultSort = props.defaultSort;
        this.moveStatus = props.moveStatus;
    }

    private _internalConfigureColumn(column: TableColumn) {
        // get ID number at the end of the column headingCell id string

        const columnId = column.headingCell.id.toLowerCase();
        if (columnId.endsWith(Columns.ORDER.valueOf().toLowerCase())) {
            if (this.orderColumnBadgeIds) {
                column.cellDef.def.components = column.cellDef.def.components.filter(comp =>
                    comp.id == null || !(comp.id as string).startsWith("badge") || this.orderColumnBadgeIds.includes(comp.id)
                );
            }
        } else if (columnId.endsWith(Columns.STATUS.valueOf().toLowerCase())) {
            if (this.statusColumnBadgeIds) {
                column.cellDef.def.components = column.cellDef.def.components.filter(comp =>
                    comp.id == null || !(comp.id as string).startsWith("badge") || this.statusColumnBadgeIds.includes(comp.id)
                );
            }
        } else if (columnId.endsWith(Columns.REVENUE.valueOf().toLowerCase())) {
            column.cellDef.def.components = column.cellDef.def.components.filter(comp => this.revenueColumnFields.includes(comp.field))
        }
    }

    static setColumnWidth(column: TableColumn, width: number) {
        column.headingCell.width = width;
        column.cellDef.def.width = width;
    }

    private static isLateWarning(row: ModelRow, stop: "origin" | "destination"): boolean {
        if (row.get(`${stop}_actual_arrival`) != null) return false;

        const diff = getTimeDiff(new Date(getStopTime(row, stop)));
        if (diff == null) return false;
        const warning = stop === "origin"
            ? getAuthSettings().dispatch_control[0].pickup_warning
            : getAuthSettings().dispatch_control[0].delivery_warning;

        return diff < (warning * 60 * 60 * 1000)
    }

    // return false if the the row should not show
    public static holdFilter(row: ModelRow, profile: RowBrokeragePlanningProfile): boolean {
        if (profile.getBoolean("include_on_hold_orders", false))
            return true;
        return !row.getBoolean("hold", false);
    }

    public static hasActiveOffer(row: ModelRow): boolean {
        if (row.get("move_status") === "A") {
            const offerCount = row.get("offer_count");
            const inactiveCount = row.get("inactive_offer_count", 0); // Skipped/Expired/Cancelled
            const electronicallyOfferedCount = row.get("electronic_offer_count", 0);
            const counterCount = row.get("counters", 0);

            // Offers that were sent electronically (blast/waterfall) and do not have a counter should be filtered out
            const isInvalidElectronicOffer = (electronicallyOfferedCount == offerCount) && electronicallyOfferedCount > 0 && counterCount == 0;

            return offerCount > 0 && offerCount != inactiveCount && !isInvalidElectronicOffer;
        }
        return false;
    }
}
