import { Alignment, ArrayUtil, Collection, HorizontalAlignment } from "@mcleod/core";
import { Component } from "../../base/Component";
import { ComponentProps } from "../../base/ComponentProps";
import { ReflectiveDialogs } from "../../base/ReflectiveDialogs";
import { DataSource } from "../../databinding/DataSource";
import { ModelUserSavedSearch, RowUserSavedSearch } from "../../models/ModelUserSavedSearch";
import { Overlay } from "../../page/Overlay";
import { Button } from "../button/Button";
import { ButtonVariant } from "../button/ButtonVariant";
import { Panel } from "../panel/Panel";

const basicDropdownProps: Partial<ComponentProps> = {
    borderWidth: 0,
    borderRadius: 0,
    marginLeft: 0,
    paddingLeft: 8,
    marginTop: 0,
    paddingTop: 8,
    align: HorizontalAlignment.LEFT,
    fontSize: "medium"
};

export class PinnedSearch {
    private static cachedSearches: Collection<RowUserSavedSearch[]> = {};

    public static executePinnedSearch(searchParentName: string, searchName: string, dataSource: DataSource) {
        dataSource.search({ pinned_search_name: searchName, pinned_search_path: searchParentName });//.then(response => this.tableList.focusSearch());
    }

    public static async getDropdownComponents(searchParentName: string, dataSource: DataSource): Promise<Component[]> {
        const result = [];
        const labelPin = new Button({ ...basicDropdownProps, width: "100%", caption: "Pin this search", imageName: "pushPin" });
        labelPin.addClickListener(event => PinnedSearch.pinSearch(searchParentName, dataSource, event.target));
        result.push(labelPin);
        const pinned = await PinnedSearch.getPinnedSearches(searchParentName);
        for (const pin of pinned)
            result.push(PinnedSearch.getPinnedSearchComponent(pin, dataSource));
        return result;
    }

    public static getPinnedSearchComponent(pin: RowUserSavedSearch, dataSource: DataSource) {
        const result = new Panel({ width: "100%", padding: 0 });
        const searchParentName = pin.get("screen_class_name");
        const name = pin.get("search_name");
        result.add(new Button({
            ...basicDropdownProps,
            rowBreak: false,
            fillRow: true,
            caption: "Search " + name,
            imageName: "listStar",
            onClick: (event) => {
                PinnedSearch.executePinnedSearch(searchParentName, name, dataSource);
                event.shouldAutomaticallyStopPropagation = false; // don't stop propagation so the overlay will also get the event and close itself
            }
        }));
        result.add(new Button({
            variant: ButtonVariant.round,
            color: "subtle.light",
            imageName: "delete",
            onClick: async (event) => {
                event.shouldAutomaticallyStopPropagation = false;
                if (await ReflectiveDialogs.showYesNo(`Are you sure you want to delete the pinned search named ${name}?`)) {
                    await pin.delete();
                    ArrayUtil.removeFromArray(this.cachedSearches[searchParentName], pin);
                }
            }
        }));
        return result;
    }

    public static async getPinnedSearches(searchParentName: string): Promise<RowUserSavedSearch[]> {
        let result = PinnedSearch.cachedSearches[searchParentName];
        if (result == null) {
            const rows = await new ModelUserSavedSearch().search({ screen_class_name: searchParentName });
            result = rows.modelRows;
            PinnedSearch.cachedSearches[searchParentName] = result;
        }
        return result;
    }

    public static async pinSearch(searchParentName: string, dataSource: DataSource, anchor: Component): Promise<RowUserSavedSearch> {
        if (dataSource.lastSearch == null)
            anchor.showTooltip("You need to perform an advanced search first.", { shaking: true, position: Alignment.RIGHT });
        else {
            Overlay.hideAllOverlays(); //not sure if hiding all overlays is right, but I don't see where we display one here
            const name = await ReflectiveDialogs.showInputDialog("Enter a name for this pinned search", "Pin Search");
            const rowSavedSearch = new RowUserSavedSearch();
            rowSavedSearch.set({
                screen_class_name: searchParentName,
                search_name: name,
                where_clause: JSON.stringify(dataSource.lastSearch)
            });
            await rowSavedSearch.post()//.then(savedRow => this.addPinnedSearch(rowSavedSearch));
            this.cachedSearches[rowSavedSearch.get("screen_class_name")].push(rowSavedSearch);
            return rowSavedSearch;
        }
    }
}
