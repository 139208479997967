/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelEdiPartnerCode.ts                *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/dispatch/edi-partner-code";

export class AutogenModelEdiPartnerCode<RowType extends ModelRow> extends Endpoint<RowType, RowEdiPartnerCodeFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowEdiPartnerCode extends ModelRow<RowEdiPartnerCodeFields> {
    constructor(values?: Partial<RowEdiPartnerCodeFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowEdiPartnerCodeFields {
    code_enabled: string;
    company_id: string;
    description: string;
    element_id: string;
    fault_of_carrier_or_driver: string;
    id: string;
    include_mobile_pu: string;
    include_mobile_so: string;
    partner_id: string;
    send_to_driver: string;
    standard_code: string;
    transaction_set: string;
    version: string;
    customer_id: string;
}

