import { AutogenModelEdiMapError, AutogenRowEdiMapError } from "./autogen/AutogenModelEdiMapError";

export class ModelEdiMapError extends AutogenModelEdiMapError<RowEdiMapError> {
    constructor() {
        super(RowEdiMapError);
    }
}

export class RowEdiMapError extends AutogenRowEdiMapError {
}
