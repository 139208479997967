import { Button, DataSource, HorizontalSpacer, Layout, Panel, Table } from "@mcleod/components";

export abstract class AutogenLayoutCompareResults extends Layout {
    buttonSelectAll: Button;
    buttonUnselectAll: Button;
    horizontalspacer1: HorizontalSpacer;
    horizontalspacer2: HorizontalSpacer;
    panel1: Panel;
    panel4: Panel;
    sourceCompareOrder: DataSource;
    tableCompareChanges: Table;
}
