import { JSUtil, LocationUtil, ModelRow, StringUtil } from "@mcleod/core";
import { ChangeEvent, DataSourceMode } from "../..";
import { Captioned } from "../../base/CaptionedComponent";
import { ComponentTypes } from "../../base/ComponentTypes";
import { ListenerListDef } from "../../base/ListenerListDef";
import { Printable } from "../../base/PrintableComponent";
import { Location } from "../location/Location";
import { LocationProps } from "./LocationProps";

const _changeListenerDef: ListenerListDef = { listName: "_changeListeners" };

export class EdiLocation extends Location {

    constructor(props?: Partial<LocationProps>) {
        super(props);
        this.textCombined.lookupModel = "lme/dispatch/location-lookup";
        this.textCombined.lookupModelLayout = "lme/dispatch/LocationLookup";
        if (this.required) {
            this.textCombined.placeholder = "Required";
        }

    }

    get caption(): string {
        return this["_mixin-Captioned-caption"];
    }

    set caption(value: string) {
        if (this["captionValueMatches"](value) === true) {
            return;
        }
        this["_mixin-Captioned-caption"] = value;
        this.textCity.caption = value;
        this.syncCaption();
    }

    public override updateBoundData(data: ModelRow, mode: DataSourceMode) {
        if (this.printable === true)
            return;
        const value = this.textCombined.text;
        if (mode === DataSourceMode.SEARCH && this.contains(this.textCity)) {
            this.textLocationId.updateBoundData(data, mode);
        }
        else if (data != null) {
            let oldData = null;
            if (this.lookupModelDataForUpdate != null) {
                oldData = { ...data };
                if (this.locationIdField != null)
                    data.set(this.locationIdField, this.lookupModelDataForUpdate.get("id"), this);
                if (this._isForStop) {
                    data.set("location_id", this.lookupModelDataForUpdate.get("id"), this);
                }
                const event = new ChangeEvent(this, oldData, { ...data });
                this.fireListeners(_changeListenerDef, event)
            }
        }
    }

    displayData(row: ModelRow, allData: ModelRow[], rowIndex: number) {
        if (row == null) {
            this.textCombined.text = "";
            this.textCity.text = "";
            this.textState.text = "";
            this.textZip.text = "";
            this.textLocationId.text = "";
            this.textLocationName.text = "";
            this.textAddress.text = "";
        }
        else
            if (!StringUtil.isEmptyString(row.get(this.locationIdField)))
                this.textCombined.text = LocationUtil.formatLocation(row.get(this.locationNameField), null, row.get(this.addressField), row.get(this.cityField), row.get(this.stateField), row.get(this.zipField));
    }

    override get serializationName() {
        return "edilocation";
    }

}

JSUtil.applyMixins(EdiLocation, [Captioned, Printable]);
ComponentTypes.registerComponentType("edilocation", EdiLocation.prototype.constructor, true);
