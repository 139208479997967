import { CommonDialogs, CommonTooltips } from "@mcleod/common";
import {
    ChangeEvent, Checkbox, ClickEvent, Component, DataSource, EditRowDecorator, Image, Label, Panel, Table, TableCell,
    TableColumn, TableRow, TableRowDisplayEvent, Textbox
} from "@mcleod/components";
import {
    Api, FieldUpdateEvent, getAuthSettings, getLogger, HorizontalAlignment, ModelRow, StringUtil
} from "@mcleod/core";
import { makeTooltipCallbackFunction } from "@mcleod/dispatch/src/CarrierMakeQuickInfo";
import { CarrierOfferSlideout } from "@mcleod/dispatch/src/CarrierOfferSlideout";
import { DispatchUtil } from "@mcleod/dispatch/src/DispatchUtil";
import { OfferSource } from "@mcleod/dispatch/src/OfferUtil";
import { QuickSellDetails } from "@mcleod/dispatch/src/QuickSellDetails";
import { MovementCarrierPay } from "./MovementCarrierPay";
import { RoutingGuidesTable } from "./RoutingGuidesTable";
import { TopMatchList } from "./TopMatchList";
import { TopMatchTable } from "./TopMatchTable";
import { OfferField } from "./models/ModelCarrierOffer";

const log = getLogger("lme.powerbroker.TopMatchUtil");

export class TopMatchUtil {
    private tableLayout: TopMatchTable | RoutingGuidesTable;
    private table: Table;
    private topMatchList: TopMatchList;
    private checkboxSelectAll: Checkbox;
    private allUnchecked = true;
    private origColumns: TableColumn[];

    public initialize(tableLayout: TopMatchTable | RoutingGuidesTable, topMatchList: TopMatchList) {
        this.topMatchList = topMatchList;
        this.tableLayout = tableLayout;
        if (this.tableLayout instanceof TopMatchTable) {
            this.table = this.tableLayout.tableTopMatch;
        } else {
            this.table = this.tableLayout.tableRoutingGuides;
        }
        this.origColumns = [...this.table.columns];
        this.tableLayout.buttonBlastAllOffers.addClickListener((event: ClickEvent) => this.buttonBlastAllOffersOnClick(event));
        this.tableLayout.buttonWaterfallTender.addClickListener((event: ClickEvent) => this.buttonWaterfallTenderOnClick(event));
        this.table.onEditLayoutLoaded = this._offerLayoutLoaded();
        this.table.addRowDisplayListener((event: TableRowDisplayEvent) => this.tableTopMatchOnRowDisplay(event));
    }

    setMovement(sourceMovement: DataSource) {
        if (!this.tableLayout || sourceMovement == null)
            return;
        this.tableLayout.sourceMovement = sourceMovement;
        let isAvailable: boolean;
        if (sourceMovement == null || sourceMovement.activeRow == null) {
            isAvailable = false;
        } else {
            isAvailable = "A" === this.tableLayout.sourceMovement.activeRow.get("status");
        }
        this.table.allowEdit = isAvailable;

        const columns = [...this.origColumns].filter(col => {
            if (isAvailable) {
                return true;
            } else {
                return !["colHeaderSelected", "colHeaderOffer"].includes(col.headingCell.id);
            }
        });
        this.table.clearColumns();
        columns.forEach((col, index) => this.table.addColumn(col, index == 0, index == columns.length - 1));
        this.tableLayout.buttonBlastAllOffers.visible = isAvailable;
        this.tableLayout.buttonWaterfallTender.visible = isAvailable;
        if (isAvailable) {
            this.configureSelectColumn();
            this.syncSelectAllCheckbox();
        }
    }

    /** This is an event handler for the onRowDisplay event of tableTopMatch.  */
    tableTopMatchOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.getTableRow();
        const modelRow: ModelRow = tableRow.data;
        this.setupTableRowListeners(tableRow);
        // Remove delete button
        tableRow.canBeDeleted = false;

        // Email can only be edited if status is Available
        const isAvailable = "A" === this.tableLayout.sourceMovement?.activeRow?.get("status", null);
        const tbEmail = this.getEmailTextbox(tableRow);
        if (tbEmail != null)
            tbEmail.printable = !isAvailable;

        // Enable/Disable offers
        if (isAvailable) {
            this.enableDisableOffers(tableRow);
            const exclude = modelRow.getBoolean("exclude_from_selectall", false);
            this.getEmailOfferLabel(tableRow).visible = !exclude;
            this.getEmailSentLabel(tableRow).visible = exclude;
            this.getEmailSentLabel(tableRow).enabled = exclude;
            if (this.getDataFusionImage(tableRow)) {
                this.getDataFusionImage(tableRow).visible = modelRow.getBoolean("is_datafusion_carrier", false);
            }
        }
        // add the sources labels
        const cellSource = tableRow.cells.find(c => "colSource" === c.id);
        const sources = modelRow.get("sources", null);
        if (sources) {
            Object.entries(sources)?.forEach(([source, score]) => {
                cellSource.add(this.createLabel(source, score as number, modelRow.get("total_score")));
            })
        }

        // add tooltips
        tableRow.forEveryChildComponent((comp: Component) => {
            this.setTooltipCallbacks(comp, modelRow);
        });

        // show offer icon is offer has been sent in the past for this movement
        TopMatchUtil.displayOfferIcon(tableRow, modelRow.get("offer_id"));
    }

    static displayOfferIcon(tableRow: TableRow, offerId: string) {
        if (tableRow == null || offerId == null) return;
        const lblOffered = tableRow.findComponentById("lblOffered");
        lblOffered.visible = true;
        CommonTooltips.setTooltipFromLayoutCallback(lblOffered, offerId, "lme/powerbroker/CarrierOfferQuickInfo", { width: 225 });
    }

    setupTableRowListeners(tableRow: TableRow) {
        const labelEmailOffer = this.getEmailOfferLabel(tableRow);
        labelEmailOffer?.addClickListener((event: ClickEvent) => this.labelEmailOfferOnClick(event));
        const checkbox = this.getSelectedCheckbox(tableRow);
        checkbox?.addChangeListener((event: ChangeEvent) => this.checkboxSelectedOnChange(event));
        this.addFieldUpdateListener(tableRow);
    }

    private addFieldUpdateListener(tableRow: TableRow) {
        const modelRow: ModelRow = tableRow.data;
        modelRow.addAfterFieldUpdateListener((event: FieldUpdateEvent) => {
            if (event.fieldName === "email" && event.originator instanceof Textbox) {
                const canEnable = this.canEnableCheckbox(tableRow);
                modelRow.set("selected", canEnable);
                this.enableDisableOffers(tableRow, canEnable);
            }
        });
    }

    // START: Carrier Offer Functions
    private _offerLayoutLoaded(): (rowDecorator: EditRowDecorator, tableRow: TableRow) => void {
        return (rowDecorator: EditRowDecorator, tableRow: TableRow) => {

            const loCarrierPay = (rowDecorator.layout as CarrierOfferSlideout).loCarrierPay as MovementCarrierPay;
            const loCarrierOffer = (rowDecorator.layout as CarrierOfferSlideout)
            const quickSellDetails = loCarrierOffer.layoutQuickSellDetails as QuickSellDetails;

            loCarrierOffer.tbMcNumber.enabled = false;
            loCarrierOffer.tbCarrierName.enabled = false;
            loCarrierOffer.tbPayeeId.enabled = false;
            loCarrierOffer.tbDotNumber.enabled = false;

            const rowMovement: ModelRow = this.tableLayout.sourceMovement.activeRow;
            quickSellDetails.configureForSlideout(this.tableLayout.stopCount);
            quickSellDetails.replaceMainDatasource(this.tableLayout.sourceMovement);
            quickSellDetails.displayData(rowMovement, null, 0);

            rowDecorator.btnDelete.visible = false;
            rowDecorator.multiButton.dropdownItems = null;
            rowDecorator.multiButton.primaryButton.paddingLeft = 7;
            rowDecorator.labelTitle.caption = `Create Quick Offer - Order ${rowMovement?.get("orders.id")}`;
            rowDecorator.onSave = (updatedRow: ModelRow) => {
                // const lblOffered = tableRow.findComponentById("lblOffered") as Label;
                // const isAppending = !lblOffered.visible;
                // Logic for checking if offer is appending is commented out as we may need to check for it in the future. As of now all offers will be in add mode
                this.onSaveCallback(tableRow, updatedRow, true);
            };

            loCarrierPay.replaceMainDatasource(this.tableLayout.sourceMovement);
            loCarrierOffer.assignableCheck = () => DispatchUtil.isAssignable(rowMovement, loCarrierOffer.buttonAssignCarrier, null);
            loCarrierPay.layoutTargetPay.mpactRatingEnabled(rowMovement.get("target_pay_method", "") === "S");
            this._loadSlideoutData(loCarrierOffer, loCarrierPay);
            loCarrierPay.mainDataSource.displayDataInBoundComponents();
            loCarrierPay.buttonOverrideTargetPay.enabled = false;
            loCarrierPay.buttonOverrideMaxPay.enabled = false;
            loCarrierOffer.updateMargins(OfferField.OFFER, "offer_margin", loCarrierOffer.tbDispatchMargin, loCarrierOffer.tbDispatchMarginPrcnt, true);
            loCarrierOffer.assignButtonClicked = () => rowDecorator.multiButton.primaryButton.clicked();
            loCarrierOffer.carrierAssigned = () => this.topMatchList.carrierAssigned();
        }
    }

    private _loadSlideoutData(layout: CarrierOfferSlideout, loCarrierPay: MovementCarrierPay): void {
        const offerRow = layout.mainDataSource.activeRow;
        const carrierName = offerRow?.get("carrier_name");
        const contactName = offerRow?.get("contact_name");
        const tractorCityId = offerRow?.get("tractor_city_id");
        const carrierId = offerRow?.get("payee_id");

        offerRow.set("stop_origin.appt_required", this.tableLayout.sourceMovement.activeRow.get("stop_origin.appt_required", null));
        offerRow.set("stop_origin.confirmed", this.tableLayout.sourceMovement.activeRow.get("stop_origin.confirmed", null));
        offerRow.set("orders_on_hold", this.tableLayout.sourceMovement.activeRow.get("orders.on_hold", null));
        offerRow.set("pending_payee", this.tableLayout.sourceMovement.activeRow.get("pending_payee_id", null) ?? this.tableLayout.sourceMovement.activeRow.get("has_pending_assignment", false));
        offerRow.set("is_subject_order", this.tableLayout.sourceMovement.activeRow.get("orders.subject_order_number", null) != null && this.tableLayout.sourceMovement.activeRow.get("orders.subject_order_status", null) != "W");
        offerRow.set("order_total_chg", loCarrierPay.mainDataSource.activeRow.get("orders.total_charge"));
        loCarrierPay.displayCalculatedFields();
        layout.updateMargins(OfferField.OFFER, "offer_margin", layout.tbDispatchMargin, layout.tbDispatchMarginPrcnt, true);
        layout.updateMargins(OfferField.COUNTER, "counter_margin", layout.tbCounterMargin, layout.tbCounterMarginPrcnt, true);
        offerRow.set("movement_id", loCarrierPay.mainDataSource.activeRow?.get("id"));

        if (carrierName) {
            offerRow.setLookupModelData("carrier_name", new ModelRow(layout.tbCarrierName.lookupModel, false, { "name": carrierName }));
            layout.tbCarrierName.displayData(offerRow, null, 0);
            if (carrierId)
                layout.tbCarrierName.tooltipCallback = makeTooltipCallbackFunction(carrierId, layout.tbCarrierName);
        }
        if (contactName) {
            offerRow.setLookupModelData("contact_name", new ModelRow(layout.tbContactName.lookupModel, false, { "name": contactName }));
            layout.tbContactName.displayData(offerRow, null, 0);
        }
        if (tractorCityId && loCarrierPay.mainDataSource.activeRow?.get("id") != null) {
            Api.post("lme/dispatch/get-next-stop-distance", {
                city_id: tractorCityId,
                movement_id: loCarrierPay.mainDataSource.activeRow?.get("id")
            }).then(response => {
                offerRow.set("miles_to_origin", response?.data[0]?.distance);
                layout.tbMilesToPickup.text = response?.data[0]?.distance;
            })
        }
    }

    onSaveCallback = async (tableRow: TableRow, updatedRow: ModelRow, isAppending: boolean) => {
        const offer = updatedRow.createBasicCopy();
        offer._appending = isAppending;
        offer.set("entered_user_id", getAuthSettings()?.user_settings?.user_id);
        offer.set("electronic_offer", "N");
        offer.set("offer_date", new Date());
        offer.set("source", OfferSource.QUICKOFFER);
        offer.set("amount_n", updatedRow.get("amount")?.amount);
        const postedOffer = await offer.post();
        if (postedOffer) {
            TopMatchUtil.displayOfferIcon(tableRow, postedOffer.get("id"));
        } else
            log.error("Unable to save carrier offer");
    }
    // END: Carrier Offer Functions

    /* Helper Functions  */

    // Create a label for the source column
    private createLabel(source: string, score: number, totalScore: number): Label {
        return new Label({
            id: `${source.toLowerCase()}Id`,
            caption: source,
            tooltip: `Score: ${score} ${score != totalScore ? `of ${totalScore}` : ""}`
        })
    }

    // START: Tooltips & QuickInfo
    setTooltipCallbacks(comp: Component, modelRow: ModelRow) {
        if (comp.id == null) return;
        switch (comp.id) {
            case "textboxCarrierName":
                CommonTooltips.setTooltipFromLayoutCallback(comp, modelRow.get("payee_id"), "lme/powerbroker/CarrierQuickInfo", {
                    minWidth: 128,
                    minHeight: 140
                });
                break;
            case "textboxAmount":
                // Set Rate ID tooltip
                if (!modelRow.isNull("carrier_rate_id")) {
                    comp.tooltip = `Rate ID: ${modelRow.get("carrier_rate_id")}`;
                }
                break;
            case "lblOffered":
                // show offer icon is offer has been sent in the past for this movement
                if (modelRow.get("offer_id", null)) {
                    comp.visible = true;
                    CommonTooltips.setTooltipFromLayoutCallback(comp, modelRow.get("offer_id"), "lme/powerbroker/CarrierOfferQuickInfo", {
                        width: 225,
                        height: 40
                    });
                }
                break;
            case "imageDisqualified":
                if (modelRow.get("qualification_status") === "Disqualified") {
                    comp.visible = true;
                    CommonTooltips.setTooltipFromLayoutCallback(comp, modelRow, "lme/dispatch/CarrierQualificationDetailsQuickInfo");
                }
                break;
            case "imageWarning":
                if (modelRow.get("qualification_status") === "Warning") {
                    comp.visible = true;
                    CommonTooltips.setTooltipFromLayoutCallback(comp, modelRow, "lme/dispatch/CarrierQualificationDetailsQuickInfo");
                }
                break;
        }
    }

    // END: Tooltips & QuickInfo

    // START: Blast/Email Offer Functions
    buttonBlastAllOffersOnClick(event: ClickEvent) {
        this.sendOffers();
    }

    buttonWaterfallTenderOnClick(event: ClickEvent) {
        this.sendOffers(true);
    }

    toggleWaterfall() {
        this.table.rows.forEach((tableRow: TableRow) => this.enableDisableOffers(tableRow, false));
        this.toggleBlastWaterfallOffers(false);
        this.checkboxSelectAll.checked = undefined;
        this.checkboxSelectAll.enabled = false;
    }

    async sendOffers(isWaterfall = false) {
        this.checkboxSelectAll.enabled = false;

        // get everything that is being offered
        const offering = this.table.allRows.filter(tableRow => {
            tableRow.editRow();
            if (tableRow.populatedDOM) {
                this.getSelectedCheckbox(tableRow).enabled = false;
            }
            if (!tableRow.validateSimple()) {
                tableRow.data.set("selected", false);
                return false;
            } else {
                return tableRow.data.getBoolean("selected", false);
            }
        });

        // allow the chance to fix duplicate emails
        if (await this.handleDuplicates(offering)) {
            this.toggleAllCheckboxEditability(true, offering);
            const offeringData: ModelRow[] = [];
            offering.forEach(tableRow => {
                if (tableRow.populatedDOM) {
                    if (!tableRow.validateSimple()) return;  // tablerow might have changed during duplicate dialog
                    this.getEmailOfferLabel(tableRow).busy = true;
                }
                const offerCopy = this.createOfferCopy(tableRow);
                offerCopy.set("source", isWaterfall ? OfferSource.WATERFALL : OfferSource.BLAST);
                offeringData.push(offerCopy);
            });

            // post the offers
            Api.post("lme/powerbroker/topmatch/sendoffers", { offers: offeringData, is_waterfall: isWaterfall })
            .then((res) => {
                isWaterfall = res.data[0]?.waterfall_started;
            }).catch((err) => {
                CommonDialogs.showServerError(err);
            })
            .finally(() => {
                offering.forEach(tableRow => {
                    this.offerSentActions(tableRow);
                    if (tableRow.populatedDOM) {
                        this.getSelectedCheckbox(tableRow).enabled = true;
                        this.getEmailOfferLabel(tableRow).busy = false;
                    }
                });
                this.topMatchList.showOfferLogIcon(this.tableLayout.activeRow.get("order_id"));
                if (isWaterfall) {
                    this.topMatchList.waterfallStarted();
                }
            })
        } else {
            this.toggleAllCheckboxEditability(true);
        }

    }

    toggleAllCheckboxEditability(enabled: boolean, excluded?: TableRow[]) {
        this.checkboxSelectAll.enabled = enabled;
        this.table.rows.forEach(tableRow => {
            if (excluded == null || !excluded.includes(tableRow))
                this.enableDisableOffers(tableRow);
        });
        this.syncSelectAllCheckbox();
    }

    async handleDuplicates(offering: TableRow[]): Promise<boolean> {
        const duplicates: TableRow[] = [];

        offering.forEach(row => {
            const modelRow = row.data as ModelRow;
            if (offering.some(o => {
                const mr = o.data as ModelRow;
                return row.index != o.index && modelRow.get("email") === mr.get("email")
            })) {
                duplicates.push(row);
            }
        });

        if (duplicates.length == 0)
            return true;

        const container = new Panel();
        const duplicateLabels = duplicates.map(dup => this.getDuplicateLabel(dup));
        const descr = new Label({
            id: "description",
            caption: "The following carriers have duplicate emails.  Continue sending the offers?",
            fontBold: true,
            rowBreak: true
        });

        container.add(descr, ...duplicateLabels);
        return await CommonDialogs.showYesNo(container, "Duplicate Emails", {
            modal: false,
            movable: true
        });
    }

    getDuplicateLabel(tableRow: TableRow): Label {
        const modelRow = tableRow.data as ModelRow;
        const carrierName = modelRow.get("carrier_name");
        return new Label({
            id: `dup${modelRow.get("payee_id")}`,
            caption: StringUtil.isEmptyString(carrierName) ? modelRow.get("payee_id") : carrierName,
            color: "primary",
            paddingLeft: 12,
            wrap: false,
            rowBreak: true,
            onClick: (event: ClickEvent) => {
                const tb = this.getEmailTextbox(tableRow);
                tb?.focus();
                tb?.selectText();
            }
        });
    }

    labelEmailOfferOnClick(event: ClickEvent) {
        this.emailOffer(TableRow.getContainingTableRow(event.target as Label));
    }

    emailOffer(tableRow: TableRow): boolean {
        const labelEmailOffer = this.getEmailOfferLabel(tableRow);
        const checkboxSelected = this.getSelectedCheckbox(tableRow);
        if (labelEmailOffer.visible && labelEmailOffer.enabled) {
            tableRow.editRow();
            if (tableRow.validateSimple()) {
                labelEmailOffer.busy = true;
                checkboxSelected.enabled = false;
                const offer = this.createOfferCopy(tableRow);
                offer.set("source", "PBWEBOFFER");
                offer.set("electronic_offer", "Y");
                offer.set("send_mail", true);
                offer.post().then(modelRow => {
                    this.offerSentActions(tableRow, false);
                }).finally(() => {
                    if (labelEmailOffer.busy) labelEmailOffer.busy = false;
                    if (!checkboxSelected.enabled) checkboxSelected.enabled = true;
                })
                return true;
            } else {
                labelEmailOffer.enabled = true;
            }
        }
        return false;
    }

    createOfferCopy(tableRow: TableRow): ModelRow {
        const offer = (tableRow.data as ModelRow).createBasicCopy();
        offer._appending = true;
        offer.set("id", null);

        return offer;
    }

    public clearSentIds() {
        this.table.allRows.forEach(tableRow => tableRow.data.set("exclude_from_selectall", false));
    }

    offerSentActions(tableRow: TableRow, isWaterfall: boolean = false) {
        const modelRow = tableRow.data as ModelRow;
        modelRow.set("exclude_from_selectall", true);
        modelRow.set("selected", false);

        if (tableRow.populatedDOM) {
            const labelEmailOffer = this.getEmailOfferLabel(tableRow);
            const labelEmailSent = this.getEmailSentLabel(tableRow);
            const checkboxSelected = this.getSelectedCheckbox(tableRow);
            this.toggleLabelEmailOffers(labelEmailOffer, false);
            labelEmailOffer.visible = false;
            labelEmailSent.visible = true;
            checkboxSelected.enabled = true;
            if(isWaterfall && modelRow.getBoolean("is_datafusion_carrier", false)){
                this.getEmailSentLabel(tableRow).caption = "Tender Sent";
            }
        }

        this.syncSelectAllCheckbox();
    }

    // END: Blast/Email Offer Functions

    // Start: Toggles/Checkbox Functions
    configureSelectColumn() {
        const column: TableColumn = this.table.columns.find(col => "colHeaderSelected" === col.headingCell.id);
        const headingCell: TableCell = column.headingCell;
        this.checkboxSelectAll = new Checkbox({
            id: "checkboxSelectAll",
            align: HorizontalAlignment.LEFT,
            color: "primary",
            checkSize: 32,
            padding: 0,
            onClick: (event) => this.checkboxSelectAllOnClick(event)
        });
        this.checkboxSelectAll.allowIndeterminate = true;
        headingCell.components = [];
        headingCell.add(this.checkboxSelectAll);
    }

    checkboxSelectAllOnClick(event: ClickEvent) {
        this.table.allRows.forEach(tableRow => {
            const modelRow = tableRow.data as ModelRow;
            if (!this.allUnchecked || this.canEnableCheckbox(tableRow)) {
                if (!modelRow.getBoolean("exclude_from_selectall", false))
                    modelRow.set("selected", this.allUnchecked);
            }
        });
        this.syncSelectAllCheckbox();
    }

    syncSelectAllCheckbox() {
        if (this.table.allRows.length == 0) {
            this.checkboxSelectAll.enabled = false;
            this.toggleBlastWaterfallOffers(false);
            this.allUnchecked = true;
            return;
        } else {
            this.checkboxSelectAll.enabled = true;
        }

        if (this.table.rows.every(tableRow => {
            return !(tableRow.data.getBoolean("selected", false));
        })) { // check if all checkboxes are not checked
            this.checkboxSelectAll.checked = false;
            this.toggleBlastWaterfallOffers(false);
            this.allUnchecked = true;
        } else if (this.table.rows.every(tableRow => {
            return (!this.canEnableCheckbox(tableRow))
                ? true
                : (tableRow.data.getBoolean("exclude_from_selectall", false) || tableRow.data.getBoolean("selected", false));
        })) { // check if all checkboxes that can be checked are checked
            this.checkboxSelectAll.checked = true;
            this.toggleBlastWaterfallOffers(true);
            this.allUnchecked = false;
        } else {
            this.checkboxSelectAll.checked = undefined;
            this.toggleBlastWaterfallOffers(true);
            this.allUnchecked = false;
        }
    }

    toggleBlastWaterfallOffers(blastEnabled: boolean) {
        const waterfallInProgress = this.tableLayout.sourceMovement.activeRow?.getBoolean("waterfall_in_progress", false);
        const waterfallEnabled = blastEnabled && !waterfallInProgress;
        this.tableLayout.buttonWaterfallTender.enabled = waterfallEnabled;
        this.tableLayout.buttonWaterfallTender.color = waterfallEnabled ? "primary" : "primary.lightest";
        this.tableLayout.buttonWaterfallTender.tooltip = waterfallInProgress ? null : "Start waterfall tendering with selected carriers";
        if (waterfallInProgress)
            this.tableLayout.buttonWaterfallTender.disabledTooltip = this.tableLayout.buttonBlastAllOffers.disabledTooltip = "Waterfall tendering in progress";

        this.tableLayout.buttonBlastAllOffers.enabled = blastEnabled && waterfallEnabled;
        this.tableLayout.buttonBlastAllOffers.backgroundColor = blastEnabled && waterfallEnabled ? "primary" : "primary.lightest";
        this.tableLayout.buttonBlastAllOffers.tooltip = waterfallInProgress ? null : "Send offer to selected carriers";
    }

    toggleLabelEmailOffers(label: Label, enabled: boolean) {
        if (label == null) return;
        label.enabled = enabled;
        label.color = enabled ? "primary" : "McLeodDisable";
    }

    getEmailTextbox(row: TableRow): Textbox {
        return row.findComponentById("textboxEmail") as Textbox;
    }

    getEmailOfferLabel(row: TableRow): Label {
        return row.findComponentById("labelEmailOffer") as Label;
    }

    getEmailSentLabel(row: TableRow): Label {
        return row.findComponentById("labelEmailSent") as Label;
    }

    getSelectedCheckbox(row: TableRow): Checkbox {
        return row.findComponentById("checkboxSelected") as Checkbox;
    }

    getDataFusionImage(row: TableRow): Image {
        return row.findComponentById("imageDataFusionPartner") as Image;
    }

    checkboxSelectedOnChange(event: ChangeEvent) {
        const tableRow = TableRow.getContainingTableRow(event.target as Checkbox);

        if (event.newValue) {
            if (tableRow.populatedDOM && tableRow.data.getBoolean("exclude_from_selectall", false)) {
                const labelEmailOffer = this.getEmailOfferLabel(tableRow);
                const labelEmailSent = this.getEmailSentLabel(tableRow);
                labelEmailSent.visible = false;
                labelEmailOffer.visible = true;
                this.toggleLabelEmailOffers(labelEmailOffer, true);
            }
            tableRow.data.set("exclude_from_selectall", false);
        }

        if (event.userInitiatedChange)
            this.syncSelectAllCheckbox();
    }

    canEnableCheckbox(tableRow: TableRow, waterfallInProgress: boolean = false): boolean {
        const modelRow = tableRow.data as ModelRow;
        const email = modelRow.get("email", null);
        const disableElectronicOffers = modelRow.getBoolean("disable_electronic_offers", false);
        const emailOfferLabel = this.getEmailOfferLabel(tableRow);
        if (emailOfferLabel != null) {
            if (email == null) {
                emailOfferLabel["disabledTooltip"] = "A Carrier Email is required.";
            }
            else if (disableElectronicOffers) {
                emailOfferLabel["disabledTooltip"] = "Electronic offers are disabled for this carrier.";
            }
        }

        return !StringUtil.isEmptyString(email) && this.validEmail(email) && !disableElectronicOffers && !waterfallInProgress;
    }

    enableDisableOffers(tableRow: TableRow, value?: boolean) {
        // Determine whether offers should be enabled for this row
        const modelRow = tableRow.data as ModelRow;
        const waterfallInProgress = this.tableLayout.sourceMovement.activeRow?.getBoolean("waterfall_in_progress", false);
        if (waterfallInProgress) {
            modelRow.set("selected", false);
            value = false;
        }
        if (value == null) {
            value = this.canEnableCheckbox(tableRow, waterfallInProgress);
        }

        // Enable/disable the select checkbox
        if (!waterfallInProgress && modelRow.getBoolean("exclude_from_selectall", false)) {
            modelRow.set("selected", false); // skip over enabling/disabling the checkbox
        } else if (tableRow.populatedDOM) {
            this.getSelectedCheckbox(tableRow).enabled = value;
        }

        // Enable/disable the offer label
        if (tableRow.populatedDOM) {
            this.toggleLabelEmailOffers(this.getEmailOfferLabel(tableRow), value);
        }

        if (!waterfallInProgress)
            this.syncSelectAllCheckbox();
    }

    // END: Toggles/Checkbox Functions

    // Move this into an EmailUtil class in a refactor card.
    private validEmail(email: string): boolean {
        return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+$/.test(email);
    }
}


