/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelFakProfile.ts                    *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/dispatch/fak-profile";

export class AutogenModelFakProfile<RowType extends ModelRow> extends Endpoint<RowType, RowFakProfileFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowFakProfile extends ModelRow<RowFakProfileFields> {
    constructor(values?: Partial<RowFakProfileFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowFakProfileFields {
    company_id: string;
    description: string;
    id: string;
}

