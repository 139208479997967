import { CommonDialogs } from "@mcleod/common";
import { Button, ButtonVariant, ChangeEvent, ClickEvent, DataSourceMode, Dialog, Event, HorizontalSpacer, Label, Layout, Overlay, Panel, Snackbar, TableRow } from "@mcleod/components";
import { Toast } from "@mcleod/components/src/page/Toast";
import { CrudDecorator } from "@mcleod/components/src/page/decorators/CrudDecorator";
import { Alignment, Api, ArrayUtil, HorizontalAlignment, ModelRow, Navigation, PermissionsUtil, StringUtil, VerticalAlignment, WindowTitle, getAuthSettings, getLogger } from "@mcleod/core";
import { Orders } from "../../dispatch/src/Orders";
import { ModelOrders } from "../../dispatch/src/models/ModelOrders";
import { CompareTender } from "./CompareTender";
import { DeclineTender } from "./DeclineTender";
import { LinkTenderDialog } from "./LinkTenderDialog";
import { LoadTenderExpressProfileFilter } from "./LoadTenderExpressProfileFilter";
import { ReplyResults } from "./ReplyResults";
import { TenderGridLayout } from "./TenderGridLayout";
import { AutogenLayoutLoadTenderExpress } from "./autogen/AutogenLayoutLoadTenderExpress";
import { ModelEdiLtxexpressProf } from "./models/ModelEdiLtxexpressProf";
import { ModelEdiOrder, RowEdiOrder } from "./models/ModelEdiOrder";
import { ModelEdiUser } from "./models/ModelEdiUser";
import { ModelEdiorderProfile, RowEdiorderProfile } from "./models/ModelEdiorderProfile";
import { MakeOrderUtility } from "./MakeOrderUtility";
import { OrderHandlingRequirementsSlideout } from "@mcleod/dispatch/src/OrderHandlingRequirementsSlideout";
import { ModelDataChangeType } from "@mcleod/components/src/databinding/DataSource";
import { ModelOtherChargeEdi } from "./models/ModelOtherChargeEdi";

const log = getLogger("lme.datafusion.LoadTenderExpress");
const dateTimeOptions = { month: "2-digit", day: "2-digit", year: "2-digit", hour: "numeric", minute: "numeric", hour12: false } as const;
export class LoadTenderExpress extends AutogenLayoutLoadTenderExpress {
    public selectedRow: TableRow;
    public selectedRows: TableRow[];
    private _ltxProfileId: string;
    private _refreshAllowed: boolean;
    private _interval: number;
    private _intervalId: number;
    private _ltxProfile: RowEdiorderProfile;
    private _isFilterSet: boolean;
    private _expressMode: string;
    private _showResultPanel: any;
    private _showNewestFirst: string;
    private _readyForNext: boolean;
    private _waitingOnInput: boolean;
    private resultsToastPanel: Panel;

    get ltxProfileId(): String {
        return this._ltxProfileId
    }

    override async onLoad(): Promise<void> {
        (this.layoutAll as TenderGridLayout).LoadTenderExpress = this;
        (this.layoutOriginal as TenderGridLayout).LoadTenderExpress = this;
        (this.layoutChange as TenderGridLayout).LoadTenderExpress = this;
        (this.layoutCancel as TenderGridLayout).LoadTenderExpress = this;

        this._refreshAllowed = true;
        this._interval = 0;
        this.defaultProfileId();
        this.disableReplyOptions();
        await this.refreshScreen();
        this.ltxProfileTextbox.addBeforeLookupModelSearchListener(event => {
            if (this._ltxProfile != null && this._ltxProfile.get("id", "") === this.ltxProfileTextbox.text) {
                event.filter = null;
            }
        });

        this.ltxProfileTextbox.onSelectItem = ((textbox, selectedItem) => {
            if (this.ltxProfileTextbox.getFirstLookupModelData()?.get("id") != this._ltxProfile?.get("id") || this._isFilterSet) {
                this._ltxProfileId = textbox.lookupModelData[0].data.id;
                this._isFilterSet = false;
                this.setLtxTitle(this._ltxProfileId);
                this.refreshScreen();
                this.buttonFilter.imageName = "profile_filter";
                this.disableReplyOptions();
                this.findSelectedProfile();
            }

            return undefined;
        });

        Navigation.addNavigationListener({
            onNavigate: () => {
                if (this._intervalId) {
                    clearInterval(this._intervalId);
                }
            },
            persistAcrossPages: false
        })
    }

    buttonFilterOnClick(event: ClickEvent) {
        Layout.getLayout("lme/datafusion/LoadTenderExpressProfileFilter", {
            backgroundColor: "defaultBackground", borderShadow: true, paddingBottom: 50, fillHeight: false, width: 1125
        }).addLayoutLoadListener(event => {
            this.showFilter(event.target as LoadTenderExpressProfileFilter);
        });
    }

    private showFilter(filterLayout: LoadTenderExpressProfileFilter) {
        filterLayout.mainDataSource.createBlankRow().then(row => {
            row._appending = false;
            row.setValues(this._ltxProfile.data);
            filterLayout.mainDataSource.data = [row];
            filterLayout.mainDataSource.setRowIndexWithoutUpdate(0);
            filterLayout.mainDataSource.mode = DataSourceMode.UPDATE;
            filterLayout.buttonCancel.addClickListener(() => Overlay.hideOverlay(overlay));
            filterLayout.buttonApply.addClickListener(async () => {
                if (filterLayout.validateSimple()) {
                    Overlay.hideOverlay(overlay);
                    if (row.hasChanged()) {
                        this._isFilterSet = true;
                        this.resetToPrevious(row);
                        await this.setEdiOrderProfileRow(row, true);
                    }
                }
            });
            const overlay = Overlay.showInOverlay(filterLayout, { closeOnClickOff: false, greyedBackground: true, centered: true });
        })
    }

    public async setEdiOrderProfileRow(value: ModelRow, profileFiltered: boolean = false) {
        if (value !== this._ltxProfile) {
            this._ltxProfile = value;
            this.buttonFilter.visible = this._ltxProfile !== null;
            if (this._ltxProfile != null) {
                this.setLtxTitle();
                await this.searchMainDatasource(true);
                this.ltxProfileTextbox.text = profileFiltered ? "" : this._ltxProfile.get("id");
                this.buttonFilter.imageName = profileFiltered ? "profile_filtered" : "profile_filter";
            }
        }
    }

    private async searchMainDatasource(searchByFilter = false) {
        await this.mainDataSource.search(searchByFilter ? { profile_row: this._ltxProfile } : { id: this.ltxProfileId });
    }

    private resetToPrevious(row: ModelRow<any>) {
        this.setModelRowField("dest_zones", row);
        this.setModelRowField("orig_zones", row);
        this.setModelRowField("customer_ids", row);
        this.setModelRowField("partner_ids", row);
        this.setModelRowField("revenue_codes", row);
    }

    private setModelRowField(field: string, row: ModelRow<any>) {
        const currentValue = row?.get(field);
        const originalValue = this._ltxProfile?.data[field]
        if (currentValue !== originalValue) {
            if (!currentValue) {
                row.set(field, "*");
            }
        }
    }

    private async getLoadTenderExpressProfile(value: string) {
        return await new ModelEdiLtxexpressProf().searchSingle({ id: value });
    }

    async acceptTenders() {
        let refreshStopped = false;
        let origTenderList: string = "";
        this.buttonAccept.busy = true;

        if (this._refreshAllowed) {
            log.info("Refresh currently active.  Stopping refresh.");
            refreshStopped = true;
            clearInterval(this._intervalId);
        }

        const selectedRows = this.selectedRows;
        if (ArrayUtil.isEmptyArray(selectedRows) === true) {
            Snackbar.showWarningSnackbar("No rows selected.  Please select a row before accepting.")
            return;
        }

        const processingSingleRow: boolean = selectedRows.length == 1 ? true : false;

        if (this._expressMode == "Y") {
            log.info("Processing " + selectedRows.length + " tenders using express mode.");

            for (let x = 0; x < selectedRows.length; x++) {
                const tender = selectedRows[x];
                const ediOrderId: string = tender.data.get("tender_id");
                const orderId: string = tender.data.get("order_id");
                const changeReplyReq = tender.data.get("requires_reply_change");
                const cancelReplyReq = tender.data.get("requires_reply_cancel");
                const direction = tender.data.get("direction");
                const ediOrderRow = await this.findEdiOrderRow(ediOrderId);
                if ("Y" == ediOrderRow.get("record_in_use")) {
                    Snackbar.showSnackbar("Shipment ID: " + ediOrderRow.get("shipment_id") + ". This tender is currently locked, and can not be accepted.", { persist: true });
                    this.buttonAccept.busy = false;
                    return;
                }

                if ("N" == ediOrderRow.get("ignore_map_errors") && 0 < ediOrderRow.get("error_count")[0]) {
                    Snackbar.showWarningSnackbar("Load tender " + ediOrderId + " has errors that must be reviewed prior to order creation.");
                    this.buttonAccept.busy = false;
                    return;
                }

                if (await this.hasInvalidChargeCodes(ediOrderId, direction)) {
                    Snackbar.showWarningSnackbar("Load tender " + ediOrderId + " has invalid/missing other charge codes which must be corrected before creating an order. " +
                        "Please use the View Load Tender screen to correct/process this tender.");
                    this.buttonAccept.busy = false;
                    return;
                }

                if (tender.data.get("is_original") === "Y") {
                    if (!StringUtil.isEmptyString(origTenderList)) {
                        origTenderList += ",";
                    }
                    origTenderList += ediOrderId;
                }
                else if (tender.data.get("is_change") == "Y") {
                    if (processingSingleRow) {
                        if (!StringUtil.isEmptyString(orderId)) {
                            await this.allowedToCompare(orderId, ediOrderRow, changeReplyReq);
                            log.info("Finished compare.");
                        }
                        else {
                            const dialog = new LinkTenderDialog({ okVisible: false, title: "Missing Order ID On Change Tender" });
                            dialog.ediOrderRow = ediOrderRow;

                            dialog.show().then(response => {
                                ediOrderRow.set("record_in_use", "Y");
                                ediOrderRow.post();
                                if (response) {
                                    this.allowedToCompare(ediOrderRow.get("order_id"), ediOrderRow, changeReplyReq);
                                }
                                else {
                                    ediOrderRow.set("record_in_use", "N");
                                    ediOrderRow.post();
                                }
                            });
                            dialog.addUnmountListener(async () => {
                                this.buttonAccept.busy = false;
                                await this.refreshScreen();
                            });
                        }
                    }
                    else {
                        Snackbar.showSnackbar("Change tenders are not eligible for multi-select at this time.  Skipping tender [id: " + ediOrderId + "].");
                    }
                }
                else if (tender.data.get("is_cancel") == "Y") {
                    if (processingSingleRow) {
                        if (!StringUtil.isEmptyString(orderId)) {
                            await this.voidOrder(orderId, ediOrderId, cancelReplyReq).then(async () => {
                                log.info("Finished voidOrder - in 'then'.");
                                this.unlockTender(ediOrderId, await this.refreshScreen());
                                this.waitingOnInput = false;
                                this.readyForNext = true;
                            });
                        }
                        else {
                            const prompt = "The following tender does not match an existing Order. Manual action may be necessary " +
                                "to void any related Order.\n\n" +
                                "Bill of Lading: " + tender.data.get("bol") + "\n" +
                                "Shipment ID: " + tender.data.get("shipment_id") + "\n" +
                                "Consignee Reference: " + tender.data.get("cons_refno") + "\n" +
                                "Tender ID: " + tender.data.get("tender_id") + "\n\n" +
                                "Do you wish for this tender to remain in Tender Express?";
                            const dlg = this.buildCustomYesNoDialog("Reply to Tender", prompt, "Yes", "No", 490) as Dialog;
                            dlg.addUnmountListener(() => this.buttonAccept.busy = false);
                            dlg.show().then(async yes => {
                                if (!yes) {
                                    const ediOrderRow = await new ModelEdiOrder().searchSingle({ id: ediOrderId });

                                    if (ediOrderRow.get("no_display") != "Y") {
                                        log.info("Excluding edi_order with id [" + ediOrderId + "]");
                                        ediOrderRow.set("no_display", "Y");
                                        ediOrderRow.set("record_in_use", "N");
                                        await ediOrderRow.post().then(async () => {
                                            log.info("Starting delay (3s)...")
                                            await this.delay(3000).then(async () => {
                                                log.info("Delay done.  Refreshing.")
                                                await this.refreshScreen();
                                            });
                                        });
                                    }
                                }
                                else {
                                    log.info("Unlocking edi_order with id [" + ediOrderId + "]");
                                    ediOrderRow.set("record_in_use", "N");
                                    await ediOrderRow.post().then(async () => {
                                        log.info("Starting delay (3s)...")
                                        await this.delay(3000).then(async () => {
                                            log.info("Delay done.  Refreshing.")
                                            await this.refreshScreen();
                                        });
                                    });
                                }
                                if (this.selectedRow.data.get("requires_reply_cancel") === "Y") {
                                    await this.acceptLoadTender(ediOrderId).then(async response => {
                                        const batch = response.data[0].reply_batch;

                                        if (batch != "-1" && batch != "0") {
                                            Toast.showSuccessToast("Reply sent with batch " + batch + ".");
                                        }
                                        else {
                                            const error = response.data[0].description;
                                            Snackbar.showWarningSnackbar("Unable to send reply.  " + error);
                                        }

                                        const refreshedEdiOrderRow = await new ModelEdiOrder().searchSingle({ id: ediOrderId });
                                        refreshedEdiOrderRow.set("record_in_use", "N");
                                        await refreshedEdiOrderRow.post().then(async () => {
                                            log.info("Starting delay (3s)...")
                                            await this.delay(3000).then(async () => {
                                                log.info("Delay done.  Refreshing.")
                                                await this.refreshScreen();
                                            });
                                        });
                                    });
                                }
                            });
                        }
                    }
                    else {
                        Snackbar.showSnackbar("Cancel tenders are not eligible for multi-select at this time.  Skipping tender [id: " + ediOrderId + "].");
                    }
                }
            }

            let allResults: ModelRow<any>[] = null;

            await Api.post("lme/datafusion/process-originals", {
                "edi_order_list": origTenderList
            }).then(async origResponse => {
                allResults = origResponse.data;

                if (allResults.length > 0) {
                    this.setResults(allResults, null, false);
                }
            });
        } //end express mode
        else {
            log.info("Processing " + selectedRows.length + " tenders using non-express mode.");
            let tendersProcessed = 0;
            const orderResults = [];

            for (let x = 0; x < selectedRows.length; x++) {
                this.readyForNext = false;
                this.waitingOnInput = false;
                const tender = selectedRows[x];
                const ediOrderId: string = tender.data.get("tender_id");
                const orderId: string = tender.data.get("order_id");
                const originalReplyReq = tender.data.get("requires_reply_original");
                const changeReplyReq = tender.data.get("requires_reply_change");
                const cancelReplyReq = tender.data.get("requires_reply_cancel");
                const replyCreated = tender.data.get("reply_created");
                const direction = tender.data.get("direction");
                const ediOrderRow = await this.findEdiOrderRow(ediOrderId);
                const ediOrderProfile = await this.findEdiOrderProfile(ediOrderRow);

                if ("Y" == ediOrderRow.get("record_in_use")) {
                    Snackbar.showSnackbar("Shipment ID: " + ediOrderRow.get("shipment_id") + ". This tender is currently locked, and can not be accepted.", { persist: true });
                    this.buttonAccept.busy = false;
                    return;
                }
                if ("N" == ediOrderRow.get("ignore_map_errors") && 0 < ediOrderRow.get("error_count")[0]) {
                    Snackbar.showWarningSnackbar("Load tender " + ediOrderId + " has errors that must be reviewed prior to order creation.");
                    this.buttonAccept.busy = false;
                    return;
                }
                if (await this.hasInvalidChargeCodes(ediOrderId, direction)) {
                    Snackbar.showWarningSnackbar("Load tender " + ediOrderId + " has invalid/missing other charge codes which must be corrected before creating an order. " +
                        "Please use the View Load Tender screen to correct/process this tender.");
                    this.buttonAccept.busy = false;
                    return;
                }
                if (tender.data.get("is_original") === "Y") {
                    const existingOrderId = await Promise.resolve(new ModelOrders().searchSingle({ shipment_id: ediOrderRow.get("shipment_id") })).then(result => {
                        if (result != null)
                            return result.get("id");
                        else
                            return "";
                    });
                    if (!StringUtil.isEmptyString(existingOrderId)) {
                        await this.handleOrderExists(existingOrderId, ediOrderRow.get("shipment_id"), ediOrderRow, orderResults);
                        tendersProcessed++;
                        // this.buttonAccept.busy = false;
                    } else {
                        await Api.search("lme/datafusion/make-order", {
                            "edi_order_id": ediOrderId, "calc_max_target": true
                        }).then(async response => {
                            const orderLayout = Layout.getLayout("lme/dispatch/Orders") as Orders;
                            orderLayout.invokedFromEdi = true;
                            orderLayout.imageAddHandlingReqOnClick = () => {
                                if( orderLayout.sourceBrltlOrderHdrXFgp.data.length == 0 && orderLayout.layoutHandlingReqs.sourceBrltlOrderHdrXFgp.data.length >0){
                                    orderLayout.sourceBrltlOrderHdrXFgp.data = orderLayout.layoutHandlingReqs.sourceBrltlOrderHdrXFgp.data;
                                    orderLayout.layoutHandlingReqs.sourceBrltlOrderHdrXFgp.clear();
                                    orderLayout.layoutHandlingReqs.sourceBrltlOrderHdrXFgp.setProp("Y", "madeFromEdi", null);
                                }
                                OrderHandlingRequirementsSlideout.showSlideout({
                                    hdrDataSource: orderLayout.sourceBrltlOrderHdrXFgp,
                                    hdrChanged: (row: ModelRow, type: ModelDataChangeType) => orderLayout.hdrChanged(row, type),
                                    onSave: (hasChanged: boolean) => {
                                        orderLayout.layoutHandlingReqs.populateHdrs(orderLayout.sourceBrltlOrderHdrXFgp.data, true, hasChanged).then(() => {
                                            orderLayout.sourceBrltlOrderHdrXFgp.notifyHasChangedComponents(true);
                                        });
                                    },
                                    invokedFromEdi: true
                                })
                            };
                            const orderPanel = new CrudDecorator({
                                layout: orderLayout,
                                providedApiData: response.data[0].order,
                                mode: DataSourceMode.ADD,
                                headerProps: {
                                    showClose: true,
                                    showSaveAndClose: true,
                                    showSave: true,
                                    showExecute: true,
                                    onClose: () => {
                                        tendersProcessed++;
                                        orderPanel.slideOut();
                                        this.buttonAccept.busy = false;
                                        if (this.switchRefresh.checked && x == selectedRows.length - 1) {
                                            //this.refreshScreen();
                                            log.info("Delay done.  Restarting refresh.");
                                            this.startRefreshInterval();
                                        }
                                    },
                                    onSaveButtonClose: () => { orderPanel.slideOut(); this.buttonAccept.busy = false; },
                                    onExecute: () => {
                                        const orderId = orderPanel.layout.mainDataSource.activeRow.get("id");
                                        const origDestRateId = ediOrderRow.get("orig_dest_rate_id");
                                        const commitmentId = ediOrderRow.get("commitment_id");
                                        MakeOrderUtility.addHandlingRequirements(orderId, orderLayout);
                                        Api.post("lme/datafusion/after-create-order-tasks", {
                                            "edi_order_id": ediOrderId,
                                            "order_id": orderId,
                                            "orig_dest_rate_id": origDestRateId,
                                            "commitment_id": commitmentId
                                        }).then(async () => {
                                            tendersProcessed++;
                                            if (refreshStopped && tendersProcessed == selectedRows.length) {
                                                WindowTitle.set("LTX");
                                            }
                                            const rate: string = orderPanel.layout.mainDataSource.activeRow.get("rate");

                                            if (!StringUtil.isEmptyString(rate)) {
                                                if (parseFloat(rate) != ediOrderRow.get("rate")) {
                                                    orderPanel.layout.mainDataSource.activeRow.post(); //repost so rates recalculate
                                                }
                                            }

                                            if (this.switchRefresh.checked && x == selectedRows.length - 1) {
                                                this.postMakeOrderRefresh(ediOrderRow, originalReplyReq);
                                                this.buttonAccept.busy = false;
                                            }
                                        }).catch(error => {
                                            log.debug(error);
                                            CommonDialogs.showServerError(error);
                                        });
                                    }
                                },
                                doAfterSlideOut: async () => {
                                    const orderId = orderPanel.layout.mainDataSource.activeRow.get("id");

                                    if (StringUtil.isEmptyString(orderId) !== true) {
                                        if (originalReplyReq == "Y") {
                                            this.acceptLoadTender(ediOrderId).then(response => {
                                                if (response != null) {
                                                    const batch = response.data[0].reply_batch;
                                                    const description = response.data[0].description;

                                                    if (batch != "-1" && batch != "0") {
                                                        if (selectedRows.length == 1) {
                                                            this.resultsToastPanel = this.getResultsToastPanel(orderId, description, "created");
                                                            Toast.showToast(this.resultsToastPanel, null, { millisUntilDismissal: 3000 });
                                                        }
                                                        const newRow = orderId + "," + ediOrderId + "," + "O" + "," + description;
                                                        orderResults.push(newRow);
                                                    }
                                                    else {
                                                        const error = response.data[0].description;
                                                        const message = "Order " + orderId + " created.  Unable to send reply.  " + error;
                                                        Snackbar.showWarningSnackbar(message);
                                                        const newRow = orderId + "," + ediOrderId + "," + "O" + "," + message;
                                                        orderResults.push(newRow);
                                                    }
                                                }
                                                else {
                                                    const message = "Order " + orderId + "created.  Unable to send reply.";
                                                    Snackbar.showWarningSnackbar(message);
                                                    const newRow = orderId + "," + ediOrderId + "," + "O" + "," + message;
                                                    orderResults.push(newRow);
                                                }

                                            }).catch(error => {
                                                const message = "Order " + orderId + "created.  Unable to send reply.";
                                                Snackbar.showWarningSnackbar(message);
                                                const newRow = orderId + "," + ediOrderId + "," + "O" + "," + message;
                                                orderResults.push(newRow);
                                            });
                                        }
                                        else {
                                            const message = "Order " + orderId + " created.  Reply not required.";
                                            if (selectedRows.length == 1) Toast.showSuccessToast(message);
                                            const newRow = orderId + "," + ediOrderId + "," + "O" + "," + message;
                                            orderResults.push(newRow);
                                        }
                                    }
                                    else {
                                        if (originalReplyReq == "Y" && replyCreated != "Y") {
                                            this.waitingOnInput = true;
                                            CommonDialogs.showYesNo("Order not created.  Would you still like to accept the tender?", "Reply to Tender",
                                                { titleProps: { imageName: "circleX2", imageColor: "primary.light" } }).then(async yes => {
                                                    if (yes) {
                                                        await this.acceptLoadTender(ediOrderId).then(response => {
                                                            if (response != null) {
                                                                const batch = response.data[0].reply_batch;

                                                                if (batch != "-1" && batch != "0") {
                                                                    const message = "Order not created.  Reply sent with batch " + batch + ".";
                                                                    if (selectedRows.length == 1) Toast.showSuccessToast(message);
                                                                    const newRow = "N/A" + "," + ediOrderId + "," + "O" + "," + message;
                                                                    orderResults.push(newRow);
                                                                    this.waitingOnInput = false;
                                                                }
                                                                else {
                                                                    const error = response.data[0].description;
                                                                    const message = "Order not created.  Unable to send reply.  " + error;
                                                                    Snackbar.showWarningSnackbar(message);
                                                                    const newRow = "N/A" + "," + ediOrderId + "," + "O" + "," + message;
                                                                    orderResults.push(newRow);
                                                                    this.waitingOnInput = false;
                                                                }
                                                            }
                                                            else {
                                                                const message = "Unable to send reply.";
                                                                Snackbar.showWarningSnackbar(message);
                                                                const newRow = "N/A" + "," + ediOrderId + "," + "O" + "," + message;
                                                                orderResults.push(newRow);
                                                                this.waitingOnInput = false;
                                                            }

                                                        });
                                                        this.buttonAccept.busy = false;
                                                    }
                                                    else {
                                                        const newRow = orderId + "," + ediOrderId + "," + "O" + "," + "Tender skipped.";
                                                        orderResults.push(newRow);
                                                        this.waitingOnInput = false;
                                                    }
                                                }).catch(async () => {
                                                    this.waitingOnInput = false;
                                                    ediOrderRow.set("record_in_use", "N");
                                                    await ediOrderRow.post().then(() => { this.readyForNext = true; });
                                                });
                                        }
                                        else {
                                            Snackbar.showWarningSnackbar("Unable to create the order.");
                                        }
                                    }
                                    ediOrderRow.set("record_in_use", "N");
                                    await ediOrderRow.post().then(() => { this.readyForNext = true; });
                                    if (x == selectedRows.length - 1) this.buttonAccept.busy = false;
                                },
                                layoutLoadListeners: (event: Event) => {
                                    const l = event.target as Orders;
                                    l.switchRecurringOrder.visible = false;
                                    l.buttonEnterNewOrder.visible = false;
                                    l.panelHowManyOrders.visible = false;
                                    l.postOrderAction = "";
                                    l.tabNextActions.visible = selectedRows.length == 1;
                                    l.mainDataSource.setProp(true, "isEdi", null);
                                    l.mainDataSource.setProp(ediOrderProfile.get("id"), "ediProfileId", null);
                                    l.postOrderNavigateTo = () => {
                                        if (l.postOrderAction == l.viewOrderUrl) {
                                            const orderId = orderPanel.layout.mainDataSource.activeRow.get("id");
                                            return Navigation.navigateTo(l.postOrderAction + orderId, { newTab: true });
                                        }
                                        else if (!StringUtil.isEmptyString(l.postOrderAction) && l.postOrderAction === l.findCarrierURL) {
                                            const key = orderPanel.layout.mainDataSource.activeRow?.get("curr_movement_id");
                                            return Navigation.navigateTo(l.postOrderAction + key, { newTab: false }, { findCarrier: true });
                                        }
                                        else if (!StringUtil.isEmptyString(l.postOrderAction) && l.postOrderAction === l.assignCarrierURL) {
                                            const key = orderPanel.layout.mainDataSource.activeRow?.get("curr_movement_id");
                                            return Navigation.navigateTo(l.findCarrierURL + key, { newTab: false }, { assignCarrier: true, isEdi: true });
                                        }
                                        else {
                                            return Promise.resolve();
                                        }
                                    }
                                }
                            });
                            orderPanel.slideIn({ speed: 200 });

                            ediOrderRow.set("record_in_use", "Y");
                            await ediOrderRow.post();

                        }).catch(reason => {
                            log.info("Exception making order.  " + reason);
                            Snackbar.showWarningSnackbar("Error encountered creating order. Load tender " + ediOrderId
                                + " has errors that must be reviewed prior to order creation. Please use the View Load Tender screen to correct/process the tender.");
                            this.buttonAccept.busy = false;
                        });
                    }
                }
                else if (tender.data.get("is_change") == "Y") {
                    tendersProcessed++;
                    if (!processingSingleRow) {
                        CommonDialogs.showError("Change tenders not eligible for multi-select at this time.");
                        this.readyForNext = true;
                        this.buttonAccept.busy = false;
                        return;
                    } else {
                        if (!StringUtil.isEmptyString(orderId)) {
                            ediOrderRow.set("record_in_use", "Y");
                            await ediOrderRow.post();
                            await this.allowedToCompare(orderId, ediOrderRow, changeReplyReq).then(() => {
                                log.info("Finished compare.");
                            }).catch(async () => {
                                this.unlockTender(ediOrderId, await this.refreshScreen());
                            });
                        }
                        else if (StringUtil.isEmptyString(orderId)) {
                            const dialog = new LinkTenderDialog({ okVisible: false, title: "Missing Order ID On Change Tender" });
                            dialog.ediOrderRow = ediOrderRow;

                            dialog.show().then(response => {
                                ediOrderRow.set("record_in_use", "Y");
                                ediOrderRow.post();
                                if (response) {
                                    this.allowedToCompare(ediOrderRow.get("order_id"), ediOrderRow, changeReplyReq);
                                }
                                else {
                                    ediOrderRow.set("record_in_use", "N");
                                    ediOrderRow.post();
                                }
                            });
                            dialog.addUnmountListener(async () => {
                                this.buttonAccept.busy = false;
                            });
                        }
                    }
                }
                else if (tender.data.get("is_cancel") == "Y") {
                    tendersProcessed++;
                    if (!processingSingleRow) {
                        CommonDialogs.showError("Cancel tenders not eligible for multi-select at this time.");
                        this.readyForNext = true;
                        this.buttonAccept.busy = false;
                        return;
                    }

                    if (!StringUtil.isEmptyString(orderId)) {
                        await this.voidOrder(orderId, ediOrderId, cancelReplyReq).then(async () => {
                            log.info("Finished voidOrder - in 'then'.");
                            this.unlockTender(ediOrderId, await this.refreshScreen());
                            this.waitingOnInput = false;
                            this.readyForNext = true;
                        });
                    }
                    else {
                        log.info("Order ID not on tender.  Looking for related tenders...");

                        await this.findOrderId(ediOrderRow).then(result => {
                            const lookupOrderId = (result.data.length > 0) ? result.data[0].order_id : "";

                            if (!StringUtil.isEmptyString(lookupOrderId)) {
                                this.voidOrder(lookupOrderId, ediOrderId, cancelReplyReq);
                            }
                            else {
                                const prompt = "The following tender does not match an existing Order.  Manual action may be necessary " +
                                    "to void any related Order.\n\n" +
                                    "Bill of Lading: " + tender.data.get("bol") + "\n" +
                                    "Shipment ID: " + tender.data.get("shipment_id") + "\n" +
                                    "Consignee Reference: " + tender.data.get("cons_refno") + "\n" +
                                    "Tender ID: " + tender.data.get("tender_id") + "\n\n" +
                                    "Do you wish for this tender to remain in Tender Express?";
                                const dlg = this.buildCustomYesNoDialog("Reply to Tender", prompt, "Yes", "No", 490) as Dialog;
                                dlg.addUnmountListener(() => this.buttonAccept.busy = false);
                                dlg.show().then(async yes => {
                                    if (!yes) {
                                        //const ediOrderRow = await new ModelEdiOrder().searchSingle({ id: ediOrderId });

                                        if (ediOrderRow.get("no_display") != "Y") {
                                            log.info("Excluding edi_order with id [" + ediOrderId + "]");
                                            ediOrderRow.set("no_display", "Y");
                                            ediOrderRow.set("record_in_use", "N");
                                            await ediOrderRow.post().then(async () => {
                                                log.info("Starting delay (3s)...")
                                                await this.delay(3000).then(async () => {
                                                    log.info("Delay done.  Refreshing.")
                                                    await this.refreshScreen();
                                                });
                                            });
                                        }
                                    }
                                    else {
                                        log.info("Unlocking edi_order with id [" + ediOrderId + "]");
                                        ediOrderRow.set("record_in_use", "N");
                                        await ediOrderRow.post().then(async () => {
                                            log.info("Starting delay (3s)...")
                                            await this.delay(3000).then(async () => {
                                                log.info("Delay done.  Refreshing.")
                                                await this.refreshScreen();
                                            });
                                        });
                                        this.buttonAccept.busy = false;
                                    }
                                    if (this.selectedRow.data.get("requires_reply_cancel") === "Y") {
                                        await this.acceptLoadTender(ediOrderId).then(async response => {
                                            const batch = response.data[0].reply_batch;
                                            Toast.showSuccessToast("Reply sent with batch " + batch + ".");
                                            await this.delay(2000).then(async () => {
                                                log.info("Delay done.  Refreshing.")
                                                await this.refreshScreen();
                                            });
                                        });
                                    }
                                });
                            }
                        });
                    }
                }  //end purpose if/else

                while (!this.readyForNext || this.waitingOnInput) {
                    log.debug("Waiting for user input or processing to complete...");
                    await this.delay(1000);
                }
            }  //end for loop - done processing tenders

            if (!processingSingleRow) {
                let counter = 0;
                while (orderResults.length < selectedRows.length && counter < 60 * 5) {
                    log.info("Waiting for orderResults to be populated. Orders processed: " + orderResults.length + " of " + selectedRows.length);
                    await this.delay(1000);
                    counter++;
                }
            }

            if (selectedRows.length > 1 && tendersProcessed == selectedRows.length) {
                this.setResults(null, orderResults, true);
            }

        }
        this.buttonAccept.busy = false;
    }

    createReplyModelRow(comp: string): ModelRow<any> {
        const compResult: string[] = comp.split(",");

        return new ModelRow<any>("lme/datafusion/reply-results", true, {
            orderid_out: compResult[0],
            tenderid_out: compResult[1],
            purpose_out: compResult[2],
            success_out: compResult[3]
        });
    }

    async setResults(results: ModelRow[], resultAsString: string[], forceResultPanel: boolean) {
        if (((results != null && results.length > 1) || (resultAsString != null && resultAsString.length > 1)) || forceResultPanel) {
            const replyResultPanel = Layout.getLayout("lme/datafusion/ReplyResults", {
                width: window.innerWidth * 0.60,
                height: window.innerHeight * 0.80,
                scrollY: true,
                backgroundColor: "defaultBackground",
                borderWidth: 1,
                borderShadow: true,
                borderColor: "strokeSecondary",
                fillHeight: true,
                paddingBottom: 50
            }) as ReplyResults;

            replyResultPanel.addLayoutLoadListener(() => {
                replyResultPanel.insert(headingPanel, 0);
                replyResultPanel.sourceReplyResults.search({ result_list: resultString });
                replyResultPanel.slideIn({ direction: Alignment.RIGHT }, true);
            });

            const headingPanel = new Panel({ id: "result-panel-header", fillRow: true, backgroundColor: "primary.darker", paddingLeft: 10, color: "primary.reverse" });
            const replyTitle = new Label({
                caption: "Processing Results",
                imageMarginRight: 8,
                rowBreak: false,
                align: HorizontalAlignment.LEFT,
                fontBold: true,
                fontSize: "large"
            });

            const resultCloseButton = new Button({ imageName: "x", variant: ButtonVariant.round, imageHeight: 28, imageWidth: 28, cancel: true, rowBreak: false });
            resultCloseButton.addClickListener(_event => {
                replyResultPanel.slideOut();
            });

            const goToOrderButton = new Button({ imageName: "detail", rowBreak: false });
            goToOrderButton.addClickListener(() => {
                goToOrderButton.tooltip = "Click to view the selected order";
                goToOrderButton.tooltipPosition = Alignment.LEFT;

                if (replyResultPanel.replyResultsTable.selectedRow !== null) {
                    const orderId = replyResultPanel.replyResultsTable.selectedRow.data.get("orderid_out");
                    if (!StringUtil.isEmptyString(orderId) && orderId != "N/A" && orderId != "undefined") {
                        Orders.navigateTo(orderId);
                    } else {
                        Snackbar.showSnackbar("Selected tender is not bound to an order.", { persist: true });
                    }
                }
            });

            headingPanel.add(replyTitle, new HorizontalSpacer(), goToOrderButton, resultCloseButton);

            let resultString: string = "";

            if (StringUtil.isEmptyString(resultAsString)) {
                let needSeparator: boolean = false;

                results.forEach(line => {
                    if (needSeparator)
                        resultString += "|";
                    else
                        needSeparator = true;

                    const purposeDesc = this.getPurposeDesc(line["purpose"]);
                    const successDesc = this.getSuccessDesc(line["success"]);
                    resultString += line["order_id"] + "," + line["tender_id"] + "," + purposeDesc + "," + line["description"];
                });
            }
            else {
                let needSeparator: boolean = false;

                resultAsString.forEach(line => {
                    if (needSeparator)
                        resultString += "|";
                    else
                        needSeparator = true;

                    const orderArray: string[] = line.split(",");
                    resultString += orderArray[0] + "," + orderArray[1] + "," + orderArray[2] + "," + orderArray[3] + "," + orderArray[4];
                });
            }

            await Api.search("lme/datafusion/reply-results", {
                result_list: resultString
            }).then(async () => {
                // replyResultPanel.slideOut();
                await this.searchMainDatasource();
            });
        }
        else {
            results.forEach(line => {
                const resultOrderId = line["order_id"];
                const description = StringUtil.stringAfter(line["description"], "Order created. ");

                this.resultsToastPanel = this.getResultsToastPanel(resultOrderId, description, "created");
                Toast.showToast(this.resultsToastPanel, null, { millisUntilDismissal: 3000 });
            });

            await this.searchMainDatasource();
        }
    }

    async findOrderId(ediOrderRow): Promise<any> {
        const partnerId = ediOrderRow.get("partner_id");
        const shipmentId = ediOrderRow.get("shipment_id");
        const version = ediOrderRow.get("version");
        const tenderId = ediOrderRow.get("id");

        return await Api.search("lme/datafusion/find-order-id", { partner_id: partnerId, shipment_id: shipmentId, version: version, tender_id: tenderId });
    }

    async findEdiOrderRow(ediOrderId: string): Promise<RowEdiOrder> {
        const ediOrderRow = await new ModelEdiOrder().searchSingle({ id: ediOrderId });
        return ediOrderRow;
    }

    async findEdiOrderProfile(ediOrderRow: RowEdiOrder): Promise<RowEdiorderProfile> {
        const profile = await new ModelEdiorderProfile().searchSingle({ partner_id: ediOrderRow.data["partner_id"], version: ediOrderRow.data["version"] });
        return profile;
    }

    async updateOrderId(ediOrderRow: RowEdiOrder, orderId: string) {
        if (!StringUtil.isEmptyString(orderId)) {
            ediOrderRow.set("order_id", orderId);
            await ediOrderRow.post();
        }
    }

    async acceptLoadTender(ediOrderId: string): Promise<any> {
        return new Promise(async (resolve) => {
            resolve(await this.sendReply(ediOrderId));
        });
    }

    async sendReply(ediOrderId: string): Promise<any> {
        log.info("Replying to tender " + ediOrderId + ".")
        return await Api.post("lme/datafusion/accept-tender", {
            edi_order_id: ediOrderId
        });
    }

    async voidOrder(orderId: string, ediOrderId: string, needsReply: boolean, callback?): Promise<any> {
        this.waitingOnInput = true;

        return new Promise(async (resolve) => {
            const orderRowStatus = await this.getOrderRowStatus(orderId);
            const ediOrderRow = await new ModelEdiOrder().searchSingle({ id: ediOrderId });
            const replyBatch = ediOrderRow.get("reply_batch_id");

            if (orderRowStatus == "V") {
                if (needsReply && replyBatch == "0") {
                    const prompt = "Order is already voided.  Would you still like to send the reply?";
                    const voidDialog = this.buildCustomYesNoDialog("Void Tender", prompt, "Yes", "No", 490);
                    voidDialog.addUnmountListener(() => {
                        resolve(true);
                    });
                    voidDialog.show().then(async yes => {
                        if (yes) {
                            this.acceptLoadTender(ediOrderRow.get("id")).then(result => {
                                this.processReplyStatus(orderId, result, "already voided");
                            });
                        }
                        resolve(true);
                    });
                }
                else {
                    await CommonDialogs.showMessage("Order is already voided.  Removing from Tender Express.").then(() => {
                        this.excludeExistingTender(ediOrderRow, orderId);
                        resolve(true);
                    });
                }
            }
            else {
                Api.post("lme/datafusion/df-void-order", { order_id: orderId }).then(async result => {
                    const status = result.data[0].new_status;
                    const text = result.data[0].text;

                    if (status == "V") {
                        if (needsReply) {
                            await this.acceptLoadTender(ediOrderId).then(response => {
                                const batch = response.data[0].reply_batch;

                                if (batch != "-1" && batch != "0") {
                                    Toast.showSuccessToast("Order " + orderId + " successfully voided.  Reply sent with batch " + batch + ".");
                                }
                                else {
                                    Snackbar.showWarningSnackbar("Order " + orderId + " successfully voided.  Unable to send reply.");
                                }

                                this.waitingOnInput = false;
                                resolve(true);
                            });
                        }
                        else {
                            Toast.showSuccessToast("Order " + orderId + " successfully voided.  Partner does not require a reply.");
                            this.waitingOnInput = false;
                            resolve(true);
                        }
                    }
                    else {
                        if (needsReply) {
                            CommonDialogs.showYesNo("Order " + orderId + " not voided.  " + text + "\nWould you still like to accept the tender?", "Reply to Tender",
                                { titleProps: { imageName: "circleX2", imageColor: "primary.light" } }).then(async yes => {
                                    if (yes) {
                                        await this.acceptLoadTender(ediOrderId).then(response => {
                                            const batch = response.data[0].reply_batch;

                                            if (batch != "-1" && batch != "0") {
                                                Toast.showSuccessToast("Reply sent with batch " + batch + ".");
                                            }
                                            else {
                                                const error = response.data[0].description;
                                                Snackbar.showWarningSnackbar("Unable to send reply.  " + error);
                                            }
                                        });
                                    }

                                    this.waitingOnInput = false;
                                    resolve(true);
                                });
                        }
                    }
                });
            }
        });
    }

    async excludeTender(event: ClickEvent) {
        for (const currRow of this.selectedRows) {
            const ediOrderId = currRow.data.get("tender_id");
            const ediOrderRow = await new ModelEdiOrder().searchSingle({ id: ediOrderId });

            if (ediOrderRow.get("no_display") != "Y") {
                log.info("Excluding edi_order with id [" + ediOrderId + "]");
                ediOrderRow.set("no_display", "Y");
                await ediOrderRow.post();
            }
        }

        await this.refreshScreen();
        this.disableReplyOptions();
    }

    async declineTender(event: ClickEvent) {
        if (this.selectedRows.length > 1) {
            Snackbar.showSnackbar("Multi-select is not permitted for this feature yet.  Please select an individual row.", { persist: true });
            return;
        }

        const ediOrderId: string = this.selectedRow.data.get("tender_id");
        const ediOrderRow = await this.findEdiOrderRow(ediOrderId);
        const dt = new DeclineTender();
        dt.ediOrderRow = ediOrderRow;
        dt.decline(async () => await this.refreshScreen());
    }

    async defaultProfileId() {
        const userId = getAuthSettings()?.user_settings?.user_id;

        if (!StringUtil.isEmptyString(userId)) {
            await new ModelEdiUser().searchSingle({ id: userId }).then(row => {
                if (row != null) {
                    this.setProfileRow(row.get("ltexpress_prof_id"));
                }
            });
        }

        if (this.ltxProfileId == null) {
            Snackbar.showSnackbar("You do not have a default Tender Express profile defined.  " +
                "You can select a default profile by checking 'My profile' on  " +
                "the Tender Express Profile screen", { persist: true });

            this.setProfileRow("DEFAULT PROFILE");
        }
    }

    private async findSelectedProfile() {
        this._ltxProfile = await this.getLoadTenderExpressProfile(this._ltxProfileId);
    }

    public async setProfileRow(value: string) {
        if (!StringUtil.isEmptyString(value)) {
            this._ltxProfileId = value;
            this.setLtxTitle(this._ltxProfileId);
            this.findSelectedProfile();
            await this.refreshScreen();
        }
    }

    async setLtxTitle(value: string = "") {
        const ltxProfile = value ? await this.getLoadTenderExpressProfile(value) : this._ltxProfile;
        if (ltxProfile != null) {
            this.ltxProfileTextbox.text = ltxProfile.get("title", ltxProfile.get("id"));
            this._expressMode = ltxProfile.get("use_express_mode", "N");
            this._showResultPanel = ltxProfile.get("show_result_panel", "Y");
            this._showNewestFirst = ltxProfile.get("sort_descend", "N");  //recently updated LME to default this to 'N'
            if (ltxProfile.get("refresh_rate") > 0) {
                this._interval = ltxProfile.get("refresh_rate");
                log.info("Set refresh rate for " + this.ltxProfileId + " to " + this._interval);
                this.startRefreshInterval();
            }
        }
    }

    async refreshScreen() {
        if (!StringUtil.isEmptyString(this.ltxProfileId)) {
            log.info("Refreshing screen [current interval id: " + this._intervalId + "].");
            await this.searchMainDatasource(this._isFilterSet);
            this.labelLastRefreshDateTime.caption = new Date().toLocaleString("en-US", dateTimeOptions).replace(":", "").replace(",", "");
        }
    }

    onChangeProfile(event: ChangeEvent) {
        this.switchRefresh.checked = true;
        this.switchRefreshOnChange();
    }

    switchRefreshOnChange() {
        if (this.switchRefresh.checked && this._interval > 0) {
            log.info("Refresh switch turned on.");
            clearInterval(this._intervalId);
            this.startRefreshInterval();
        }
        else {
            log.info("Refresh switch turned off.");
            this._refreshAllowed = false;
            clearInterval(this._intervalId);
        }
    }

    turnOffRefresh() {
        this._refreshAllowed = false;
        clearInterval(this._intervalId);
        log.info("Refresh turned off.");
    }

    async postMakeOrderRefresh(ediOrderRow: ModelRow<any>, needsReply: string) {
        let readyForRefresh: boolean = false;
        let counter: number = 0;

        while (!readyForRefresh && counter < 10) {
            counter++;
            await this.delay(1000).then(async () => {
                log.info("Delay done.  Try #" + counter);
                const orderId = ediOrderRow.get("order_id", "");
                const ediOrderId = ediOrderRow.get("id", "");

                if (!StringUtil.isEmptyString(orderId)) {
                    if (needsReply == "Y") {
                        await Api.search("lme/datafusion/verify-reply", { tender_id: ediOrderId }).then(async result => {
                            const replyCreated = result.data[0].reply_created;
                            if (replyCreated == "Y") {
                                readyForRefresh = true;
                            }
                        });
                    }
                    else {
                        readyForRefresh = true;
                    }
                }
            });
        }

        if (readyForRefresh) {
            await this.refreshScreen();
            this.startRefreshInterval();
        }
    }

    async updateInterval() {
        const ltxProfileRow = await this.getLoadTenderExpressProfile(this._ltxProfileId);
        clearInterval(this._intervalId);
        log.info("Using profile " + this.ltxProfileId)
        if (ltxProfileRow.get("refresh_rate") > 0) {
            this._interval = ltxProfileRow.get("refresh_rate");
            log.info("Set refresh rate for " + this.ltxProfileId + " to " + this._interval);
            this.startRefreshInterval();
        }
    }

    startRefreshInterval() {
        if (this._intervalId > 0) {
            clearInterval(this._intervalId);
            this._intervalId = null;
        }
        this._refreshAllowed = true;
        this._intervalId = window.setInterval(async () => await this.refreshScreen(), this._interval * 60 * 1000);
        log.info("Starting auto-refresh with interval of " + this._interval + " with interval id: " + this._intervalId);
    }

    async compareTender(ediOrderRow: RowEdiOrder, needsReply: boolean) {
        const compare = new CompareTender();
        await compare.compareTender(ediOrderRow, needsReply).then(async result => {
            this.buttonAccept.busy = false;
            log.info("Starting delay (3s)...")
            this.delay(3000).then(async () => {
                log.info("Delay done.  Refreshing.");
                await this.refreshScreen();
                if (result) {
                    log.info("Compare tender completed successfully.");
                } else {
                    log.info("Compare not completed.");
                }
            });
        });
    }

    processReplyStatus(orderId: string, response: any, action: string): void {
        const batch = response.data[0].reply_batch;
        const description = response.data[0].description;

        if (batch != "-1" && batch != 0) {
            this.resultsToastPanel = this.getResultsToastPanel(orderId, description, action);
            Toast.showToast(this.resultsToastPanel, null, { millisUntilDismissal: 3000 });
        }
        else if (batch != 0) {
            Snackbar.showWarningSnackbar("Unable to send reply.");
        }
    }

    getResultsToastPanel(orderId: string, message: string, action: string): Panel {
        const labelProps = { color: "white", fontSize: "xlarge", rowBreak: false, paddingLeft: 1, paddingRight: 1, marginLeft: 0, marginRight: 0, wrap: false };
        const labelLinkProps = { ...labelProps, style: { textDecoration: "underline" } };

        if (!StringUtil.isEmptyString(orderId)) {
            return new Panel({
                align: HorizontalAlignment.CENTER, fillRow: true,
                components: [
                    new Label({ ...labelProps, caption: "Order " }),
                    new Label({ ...labelLinkProps, caption: orderId, onClick: event => Orders.navigateTo(orderId) }),
                    new Label({ ...labelProps, caption: " " + action + ".  " + message })
                ]
            });
        }
        else {
            return new Panel({
                align: HorizontalAlignment.CENTER, fillRow: true,
                components: [
                    new Label({ ...labelProps, caption: "Order not " + action + ".  " + message })
                ]
            });
        }
    }

    async allowedToCompare(orderId: string, ediOrderRow: RowEdiOrder, needsReply?: boolean): Promise<boolean> {
        if (StringUtil.isEmptyString(orderId)) {
            return false;
        }

        const orderRowStatus = await this.getOrderRowStatus(orderId);
        if (orderRowStatus == "D") {
            if (PermissionsUtil.isUserDeniedAction("Dispatch.Update orders after delivery")) {
                CommonDialogs.showYesNo("Order is delivered.  Compare not allowed due to permissions settings.  Would you like to remove this tender from Tender Express (exclude)?", "Tender Express",
                    { titleProps: { imageName: "circleX2", imageColor: "primary.light" } }).then(response => {
                        if (response) {
                            if (ediOrderRow.get("no_display") != "Y") {
                                ediOrderRow.set("no_display", "Y");
                                ediOrderRow.post();
                            }
                        }
                    });
                return false;
            }
            else {
                CommonDialogs.showYesNo("Order " + orderId + " is delivered.  Would you still like to run the compare?", "Compare Delivered Order",
                    { titleProps: { imageName: "circleX2", imageColor: "primary.light" } }).then(yes => {
                        if (yes) {
                            //set record in use to Y before comparing
                            ediOrderRow.set("record_in_use", "Y");
                            ediOrderRow.post();
                            if (!StringUtil.isEmptyString(orderId)) {
                                this.compareTender(ediOrderRow, needsReply);
                            } else if (StringUtil.isEmptyString(orderId)) {
                                const dialog = new LinkTenderDialog({ okVisible: false, title: "Missing Order ID On Change Tender" });
                                dialog.ediOrderRow = ediOrderRow;

                                dialog.show().then(response => {
                                    if (response)
                                        this.compareTender(ediOrderRow, needsReply).then(result => {
                                            if ("Y" == ediOrderRow.get("order_compared")) {
                                                Toast.showSuccessToast("Successful Reply and Order Comparing.", "Order Number " + ediOrderRow.get("order_id"), "magnifyingGlass", { millisUntilDismissal: 7000 });
                                            }
                                        });
                                });
                            }

                            return true;
                        }
                        else {
                            CommonDialogs.showYesNo("Would you like to remove this tender from Tender Express (exclude)?", "Tender Express",
                                { titleProps: { imageName: "circleX2", imageColor: "primary.light" } }).then(response => {
                                    if (response) {
                                        if (ediOrderRow.get("no_display") != "Y") {
                                            ediOrderRow.set("no_display", "Y");
                                            ediOrderRow.post();
                                        }

                                    } else {
                                        if (needsReply) {
                                            CommonDialogs.showYesNo("Send reply?").then(response => {
                                                if (response) {
                                                    this.acceptLoadTender(ediOrderRow.get("id")).then(async (result) => {
                                                        this.processReplyStatus(orderId, result, "not compared");
                                                        await this.refreshScreen();
                                                    });
                                                }
                                            });
                                        }
                                    }
                                });
                            return false;
                        }
                    });
            }
        }
        else if (orderRowStatus == "V") {
            CommonDialogs.showYesNo("Order is voided.  Compare not allowed.  Would you like to remove this tender from Tender Express (exclude)?", "Tender Express",
                { titleProps: { imageName: "circleX2", imageColor: "primary.light" } }).then(response => {
                    if (response) {
                        if (ediOrderRow.get("no_display") != "Y") {
                            ediOrderRow.set("no_display", "Y");
                            ediOrderRow.post();
                        }
                    } else {
                        if (needsReply) {
                            CommonDialogs.showYesNo("Send reply?").then(response => {
                                if (response) {
                                    this.acceptLoadTender(ediOrderRow.get("id")).then(async (result) => {
                                        this.processReplyStatus(orderId, result, "not compared - voided");
                                        await this.refreshScreen();
                                    });
                                }
                            });
                        }
                    }
                });
        }
        else {
            if (!StringUtil.isEmptyString(orderId)) {
                this.compareTender(ediOrderRow, needsReply).catch(async (exception) => {
                    CommonDialogs.showError(exception);
                    this.unlockTender(ediOrderRow.get("id"), await this.refreshScreen()).then(() => {
                        return;
                    });
                });
            } else if (StringUtil.isEmptyString(orderId)) {
                //set record in use to Y before comparing
                if (ediOrderRow.get("record_in_use") != "Y") {
                    ediOrderRow.set("record_in_use", "Y");
                    ediOrderRow.post();
                }

                const dialog = new LinkTenderDialog({ okVisible: false, title: "Missing Order ID On Change Tender" });
                dialog.ediOrderRow = ediOrderRow;
                dialog.addUnmountListener(async () => {
                    this.unlockTender(ediOrderRow.get("id"), await this.refreshScreen());
                    log.info("Delay started.");
                    await this.delay(3000).then(() => {
                        log.info("Delay done.  Refreshing.");
                        this.refreshScreen();
                    });
                });

                dialog.show().then(async (response) => {
                    if (response) {
                        this.compareTender(ediOrderRow, needsReply).then(event => {
                            log.info("Compare completed.");
                        }).then(async () => {
                            this.unlockTender(ediOrderRow.get("id"), await this.refreshScreen());
                        }).catch(reason => {
                            CommonDialogs.showError(reason);
                        });
                    }
                    else
                        await this.refreshScreen();
                });
            }
        }
        ediOrderRow.set("record_in_use", "N");
        ediOrderRow.post();
        return true;
    }

    async getOrderRowStatus(orderId: string): Promise<string> {
        const rowOrder = await new ModelOrders().searchSingle({ id: orderId }, "lme/dispatch/Orders");
        const status = rowOrder.get("status", "");
        return status;
    }

    async unlockTender(tenderId: string, callback?) {
        const ediOrderRow = await new ModelEdiOrder().searchSingle({ id: tenderId });
        const locked = ediOrderRow.get("record_in_use", "");
        if (locked == "Y") {
            ediOrderRow.set("record_in_use", "N");
            ediOrderRow.post();
        }
        this.buttonAccept.busy = false;
    }

    async delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    getPurposeDesc(purpose: string) {
        let purp: string = "";

        if (purpose == "O") {
            purp = "Original";
        }
        else if (purpose == "U") {
            purp = "Change";
        }
        else if (purpose == "C") {
            purp = "Cancel";
        }
        else {
            purp = "Unknown";
        }

        return purp;
    }

    getSuccessDesc(success: string) {
        let suc: string = "";

        if (success == "Y") {
            suc = "Yes";
        }
        else {
            suc = "No";
        }
        return suc;
    }

    enableReplyOptions() {
        if (this.buttonAccept != null) this.buttonAccept.enabled = true;
        if (this.buttonAccept != null) this.buttonAccept.visible = true;
        if (this.buttonDecline != null) this.buttonDecline.enabled = true;
        if (this.buttonDecline != null) this.buttonDecline.visible = true;
        if (this.buttonExclude != null) this.buttonExclude.enabled = true;
    }

    disableReplyOptions() {
        if (this.buttonAccept != null) this.buttonAccept.enabled = false;
        if (this.buttonDecline != null) this.buttonDecline.enabled = false;
        if (this.buttonExclude != null) this.buttonExclude.enabled = false;
    }

    disableAndHideReplyOptions() {
        if (this.buttonAccept != null) this.buttonAccept.enabled = false;
        if (this.buttonAccept != null) this.buttonAccept.visible = false;
        if (this.buttonDecline != null) this.buttonDecline.enabled = false;
        if (this.buttonDecline != null) this.buttonDecline.visible = false;
        if (this.buttonExclude != null) this.buttonExclude.enabled = false;
    }

    enableProcessOption() {
        if (this.buttonAccept != null) this.buttonAccept.enabled = true;
        if (this.buttonAccept != null) this.buttonAccept.visible = true;
        if (this.buttonDecline != null) this.buttonDecline.enabled = false;
        if (this.buttonExclude != null) this.buttonExclude.enabled = true;
    }

    sourceLoadTenderExpressAfterExecution() {
        const ds = this.mainDataSource.data;
        this.layoutAll.mainDataSource.data = ds;
        this.layoutAll.mainDataSource.displayDataInBoundComponents();
        this.tabAll.caption = "All " + ds.length;

        this.layoutOriginal.mainDataSource.data = (ds.filter(tender => tender.data["is_original"] === "Y"));
        this.tabOriginal.caption = "Original " + this.layoutOriginal.mainDataSource.data.length;
        this.layoutOriginal.mainDataSource.displayDataInBoundComponents();

        this.layoutChange.mainDataSource.data = (ds.filter(tender => tender.data["is_change"] === "Y"));
        this.tabChange.caption = "Change " + this.layoutChange.mainDataSource.data.length;
        this.layoutChange.mainDataSource.displayDataInBoundComponents();

        this.layoutCancel.mainDataSource.data = (ds.filter(tender => tender.data["is_cancel"] === "Y"));
        this.tabCancel.caption = "Cancel " + this.layoutCancel.mainDataSource.data.length;
        this.layoutCancel.mainDataSource.displayDataInBoundComponents();
    }

    public async handleOrderExists(orderId: string, shipmentId: string, ediOrderRow: RowEdiOrder, orderResults: Array<string>) {
        const prompt = "   Order [" + orderId + "] exists for shipment ID " + shipmentId.trim();
        const dlg = this.buildCustomYesNoDialog("Order creation not allowed", prompt, "Change Purpose", "Exclude Tender", 400) as Dialog;

        dlg.show().then(clickedYes => {
            const defaultRow = "N/A" + "," + ediOrderRow.data.id + "," + "O" + "," + "Tender skipped.";
            let newRow: string = "";
            if (clickedYes) {
                this.swapPurposeExistingTender(ediOrderRow, orderId);
                newRow = "N/A" + "," + ediOrderRow.data.id + "," + "O" + "," + "Purpose swapped.";
            } else {
                this.excludeExistingTender(ediOrderRow, orderId);
                newRow = "N/A" + "," + ediOrderRow.data.id + "," + "O" + "," + "Tender excluded.";
            }
            //blech blech blech - have to do this because unmount listener fires before button click logic.
            const index = orderResults.indexOf(defaultRow, 0);
            if (index > -1) {
                orderResults.splice(index, 1);
            }
            orderResults.push(newRow);
            this.readyForNext = true;
        });
        dlg.addUnmountListener(() => {
            // this.buttonAccept.busy = false;
            this.readyForNext = true;
            const newRow = "N/A" + "," + ediOrderRow.data.id + "," + "O" + "," + "Tender skipped.";
            orderResults.push(newRow);
        });
    }

    async excludeExistingTender(ediOrderRow: RowEdiOrder, orderId: string) {
        ediOrderRow.set("no_display", "Y");
        await ediOrderRow.post();
        await this.refreshScreen();
        return;
    }
    async swapPurposeExistingTender(ediOrderRow: RowEdiOrder, orderId: string) {
        ediOrderRow.set("order_id", orderId);
        ediOrderRow.set("purpose_type", "U");
        ediOrderRow.set("original_order", "N");
        ediOrderRow.set("changed_order", "Y");
        await ediOrderRow.post();
        await this.refreshScreen();
        return;
    }

    public get readyForNext(): boolean {
        return this._readyForNext;
    }

    public set readyForNext(value: boolean) {
        this._readyForNext = value;
    }

    public get waitingOnInput(): boolean {
        return this._waitingOnInput;
    }

    public set waitingOnInput(value: boolean) {
        this._waitingOnInput = value;
    }
    async hasInvalidChargeCodes(tenderId: string, direction: string): Promise<boolean> {
        let invalidChargeCode = false;
        const otherCharges = await new ModelOtherChargeEdi().search({ order_id: tenderId, direction: direction });
        if (otherCharges) {
            for (const modelRow of otherCharges?.modelRows) {
                if (modelRow.get("charge_id") === null || modelRow.get("charge_id") === undefined) {
                    invalidChargeCode = true;
                    break;
                }
            }
        }
        return invalidChargeCode;
    }

    buildCustomYesNoDialog(titleText: string, prompt: string, yesLabel: string, noLabel: string, customWidth?: number): Dialog {

        const labelCaption = new Label({ caption: prompt, align: HorizontalAlignment.LEFT, fontSize: "medium" });
        const panelPrompt = new Panel({ marginRight: 8, verticalAlign: VerticalAlignment.TOP, fillHeight: true, rowBreak: false });
        panelPrompt.add(labelCaption);
        const width = (customWidth > 0) ? customWidth : 900;
        const panelContainer = new Panel({ fillRow: true });
        const buttonNo = new Button({ caption: noLabel, backgroundColor: "", imageName: "x", rowBreak: false });
        buttonNo.align = HorizontalAlignment.CENTER;
        buttonNo.addClickListener(() => dialog.close(false));
        const buttonYes = new Button({ caption: yesLabel, color: "primary.reverse", backgroundColor: "primary", imageName: "check" });
        buttonYes.addClickListener(() => {
            if (dialog.validateSimple())
                dialog.close(true);
        });
        const panelButtons = new Panel({ align: HorizontalAlignment.CENTER, fillRow: true });
        panelButtons.add(buttonNo);
        panelButtons.add(buttonYes);
        panelContainer.add(panelPrompt);
        panelContainer.add(panelButtons);

        const props = { width: width, titleProps: { imageName: "circleX2", imageColor: "primary.lighter", color: "McLeodWhite" }, panelTitleProps: { backgroundColor: "primary.darker", color: "primary.reverse" } };
        const dialog = CommonDialogs.createDialog(panelContainer, { title: titleText, okVisible: false, ...props });
        return dialog;
    }
}
