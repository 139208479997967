import { DataSourceMode } from "@mcleod/components/src/databinding/DataSource";
import { ModelRow } from "@mcleod/core/src/ModelRow";
import { AutogenLayoutAddStopCompareItems } from "./autogen/AutogenLayoutAddStopCompareItems";

export class AddStopCompareItems extends AutogenLayoutAddStopCompareItems {

    private _compareItems: ModelRow[];
    private _key: string;

    override onLoad(): void | Promise<void> {

        this.tableCompareItems.dataSource;
        this.tableCompareItems.dataSource = this.sourceEdiCompareItem;
        this.tableCompareItems.dataSource.data = [];
        const rows = [];
        if (this._compareItems != null) {
            this.tableCompareItems.dataSource.setRowsAndMode(DataSourceMode.NONE, this._compareItems);
        }
        if (this._compareItems != null && this._compareItems.length == 0) {
            this.tableCompareItems.visible = false;
        }

    }

    public async setupTableData(data: ModelRow[]) {
        this._compareItems = data;
    }

}
