import { AutogenModelPbwLoadboardUsers, AutogenRowPbwLoadboardUsers, } from "./autogen/AutogenModelPbwLoadboardUsers";


export class ModelPbwLoadboardUsers extends AutogenModelPbwLoadboardUsers<RowPbwLoadboardUsers> {
    constructor() {
        super(RowPbwLoadboardUsers);
    }
}

export class RowPbwLoadboardUsers extends AutogenRowPbwLoadboardUsers {
}