const modules = require["context"]("./images", true);
const svgs = require["context"]("../svg", true);

export function getImageModule(name: string): any { // returns NodModule but eslint can't find the type
    try {
        return modules("./" + name);
    } catch (err) {
        return null;
    }
}

export function getSVGUrl(name: string) {
    try {
        const result = svgs("./" + name + ".svg");
        return result.default;
    } catch (err) {
        console.error("Couldn't find SVG resource", name, svgs?.keys());
        return null;
    }
}


