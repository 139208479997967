import {
    Button, ChangeEvent, CityState, ClickEvent, Component, DataSourceMode, Label, Panel, ScreenStack, Snackbar,
    StringOrPropsOrComponent, Textbox
} from "@mcleod/components";
import {
    Api, CityUtil, FieldUpdateEvent, getAuthSettings, getLogger, HorizontalAlignment, ModelRow, ModelSearchResult,
    PermissionsUtil, StringUtil
} from "@mcleod/core";
import { LOOKUP_MODEL_PREFIX } from "@mcleod/core/src/ModelRow";
import { CarrierOfferInfo } from "@mcleod/dispatch/src/CarrierOfferSlideout";
import { SendLoadTender } from "../../datafusion/src/SendLoadTender";
import { AddCarrier } from "../../dispatch/src/AddCarrier";
import { OnboardCarrier } from "../../dispatch/src/OnboardCarrier";
import { DispatchCommentsErrors } from "./DispatchCommentsErrors";
import { AutogenLayoutCarrierAssignment } from "./autogen/AutogenLayoutCarrierAssignment";

const log = getLogger("powerbroker/CarrierAssignment");

export class CarrierAssignment extends AutogenLayoutCarrierAssignment {
    private _origPendingPayeeId;
    private origPendingCarrierNbr;
    public doAfterClose: (canceled: boolean) => void;
    public carrierPayCallback: (canceled: boolean) => void;
    private _listenersActive = false;
    private preventAssignment: boolean;
    private _qualError: string;
    private _payError: string;
    private carrierQualSnackbar: Snackbar;
    private postingRow: boolean;
    public carrierOfferInfo: CarrierOfferInfo;

    private vendor_id: string;
    private packet_website_url: string;
    private carrierins_control_onboarding_server: string;

    private auto_onboard_vendors = ['D', 'H', 'R'];

    private add_vendors = ['S', 'C', 'P'];

    private get layoutCommentsErrors(): DispatchCommentsErrors {
        return this.layoutDispatchCommentsErrors as DispatchCommentsErrors;
    }

    public get origPendingPayeeId() {
        return this._origPendingPayeeId;
    }

    public set origPendingPayeeId(value) {
        this._origPendingPayeeId = value;
    }

    private get listenersActive() {
        return this._listenersActive;
    }

    private set listenersActive(value) {
        this._listenersActive = value ? value : !this._listenersActive;
    }

    private get qualError(): string {
        return this._qualError;
    }

    private set qualError(value: string) {
        if (value != this.qualError) {
            this._qualError = value;
            this.layoutDispatchCommentsErrors.addValidationErrors([{ message: this.payError }]);
            if (this.qualError == null && this.carrierQualSnackbar != null) {
                this.carrierQualSnackbar.dismiss();
                this.carrierQualSnackbar = null;
            }
            this.configureSaveButton();
        }
    }

    public get payError(): string {
        return this._payError;
    }

    private set payError(value: string) {
        if (value != this.payError) {
            this._payError = value;
            if (value == null) {
                const payeeId = this.activeRow?.get("pending_payee_id", this.activeRow?.get("override_payee_id"));
                this.layoutDispatchCommentsErrors.displayValidationErrors(this.activeRow?.get("id"), payeeId);
            } else {
                this.layoutDispatchCommentsErrors.addValidationErrors([{ message: this.payError }]);
            }
            this.configureSaveButton();
            if (this.carrierPayCallback)
                this.carrierPayCallback(this._payError != null);
        }
    }

    override onLoad() {
        this.dfLabel.visible = false;
        this.panelUnknownCarrier.visible = false;
        this.textboxPendingPayeeId.addDropdownAdditionalAction({
            caption: "Create Pending Assignment",
            fontSize: "large", fontBold: true,
            onClick: () => {
                this.setPanelUnknownCarrierVisible(true);
                this.calculatePay(false, false, true);
            }
        });
        this.textboxPendingPayeeId.onSelectItem = ((textbox, selectedItem) => {
            const payeeId = (selectedItem as ModelRow).get("id");
            this.layoutCommentsErrors.displayDispatchComments(payeeId);
            return undefined;
        });
        const header = this.tabsetContent["_header"];
        if (header != null)
            header.visible = false
        this.setupDriverName(this.textboxOverrideDriverNm, "");
        this.setupDriverName(this.textboxOverrideDriver2Nm, "2");
        this.textboxOverrideDriverNm.addBlurListener(event => {
            const driverOneName = this.mainDataSource?.activeRow.get("override_driver_nm");
            this.enableDriverTwoComponents(driverOneName);
        })
        this.mainDataSource.addDisplayListener(event => {
            if (this.mainDataSource.activeRow?.get("carrier_contact") != null) {
                this.mainDataSource.activeRow.setLookupModelData("carrier_contact", {
                    "name": this.mainDataSource.activeRow.get("carrier_contact", "")
                });
            }
            if (this.mainDataSource.activeRow?.get("override_driver_nm") != null) {
                this.mainDataSource.activeRow.setLookupModelData("override_driver_nm", {
                    "driver_name": this.mainDataSource.activeRow.get("override_driver_nm", "")
                });
            }
            if (this.mainDataSource.activeRow?.get("override_driver2_nm") != null) {
                this.mainDataSource.activeRow.setLookupModelData("override_driver2_nm", {
                    "driver_name": this.mainDataSource.activeRow.get("override_driver2_nm", "")
                });
            }
        })
        this.textboxCarrierContact.addBeforeLookupModelSearchListener(event => {
            const payeeId = this.activeRow?.get("pending_payee_id", this.activeRow?.get("override_payee_id"));
            event.filter.parent_row_id = payeeId;
            event.filter.parent_row_type = "P";
        });

        this.textboxCarrierContact.onSelectItem = ((textbox, selection) => {
            if (selection instanceof ModelRow) {
                this.mainDataSource.activeRow.set("carrier_phone", selection.get("phone"))
                this.mainDataSource.activeRow.set("carrier_email", selection.get("email"))
            }
            return undefined;
        });

        this.sourceCarriersForAssignment.addDisplayListener((event) => {
            this.starRating1.visible = this.sourceMovement?.activeRow?.get("is_carrier_scorecard_licensed", false) && (event.rowData != null || false);
        });

        this.layoutMovementPay.layoutCarrierRates.replaceMainDatasource(this.mainDataSource);
        this.layoutMovementPay.carrierPayCallback = (errorMessage: string, errorDetails: string) => {
            if (errorDetails != null && this.activeRow?.isNull("override_payee_id")) {
                if (this.origPendingPayeeId == null)
                    errorDetails += ", assign the carrier as Pending, or select X to cancel."
                else
                    errorDetails += ", update the Pending assignment, or cancel it by removing the carrier.";
            }
            this.payError = errorMessage;
            return errorDetails;
        };
    }

    async loadTbPendingPayeeId(modelRow: ModelRow) {
        this.clearFields();
        this.setPanelUnknownCarrierVisible(false);
        if (modelRow) {
            const payeeId = modelRow.get("id");
            this.sourceCarriersForAssignment.setRowsAndMode(DataSourceMode.NONE, [modelRow]);
            this.sourceCarriersForAssignment.displayDataInBoundComponents();
            const extraPayTable = this.layoutMovementPay.tableExtraPay;
            extraPayTable.quickAddData.set("payee_id", payeeId);
            extraPayTable.quickAddData.set("_lookup_payee_id", { id: payeeId });
            const textboxPayeeId = extraPayTable.quickAddRow.findComponentById("textboxOtherPayPayeeId");
            textboxPayeeId.displayData(extraPayTable.quickAddData, null, 0);
            if (getAuthSettings().dispatch_control[0].rate_carrier == "Y") {
                this.calculatePay(false, true, true);
            }
        }
    }

    setPanelUnknownCarrierVisible(visible: boolean, clearData: boolean = true) {
        this.panelUnknownCarrier.visible = visible;
        this.textboxPendingCarrierIdType.required = visible;
        this.textboxPendingCarrierId.required = visible;
        this.btnPrequalCarrier.color = 'primary';
        if (visible) {
            if (this.origPendingCarrierNbr == null)
                this.clearBoundData(this.panelUnknownCarrier);

            const onlyDigitsEntered = /^\d+$/.test(this.textboxPendingPayeeId.text);
            if (StringUtil.isEmptyString(this.textboxPendingPayeeId.text)) {
                if (clearData) {
                    this.clearFields(true);
                }
            } else if (onlyDigitsEntered) {
                this.mainDataSource.activeRow.set("pending_carrier_nbr", this.textboxPendingPayeeId.text);
                this.mainDataSource.activeRow.set("pending_payee_id", null);
            } else {
                this.mainDataSource.activeRow.set("pending_carrier_name", this.textboxPendingPayeeId.text);
                this.mainDataSource.activeRow.set("pending_payee_id", null);
            }

            this.textboxPendingCarrierIdType.selectedItem = this.textboxPendingCarrierIdType.items[0];
            if (this.mainDataSource.activeRow?.getBoolean("has_pending_assignment", false))
                this.textboxPendingPayeeId.enabled = false;

            this.textboxPendingPayeeId.hideDropdown(true);
            this.textboxPendingCarrierName.focus();
            this.configureSaveButton();
            this.sourceCarrierInsCtrl.search()
            .then((result: ModelSearchResult) => {
                if (result.modelRows[0] !== undefined) {
                    this.vendor_id = result.modelRows[0].get('vendor_id');
                    this.packet_website_url = result.modelRows[0].get('packet_website_url');
                    this.carrierins_control_onboarding_server = result.modelRows[0].get('onboarding_server');
                    const vendorDesc: string = setVendorDesc(this.vendor_id);
                    this.textboxPrequalVendor.text = vendorDesc;
                    if (!StringUtil.isEmptyString(vendorDesc)) {
                        this.setQuickInfoTooltip(this.btnPrequalCarrier, new Label({
                            caption: "Pre-qualify this carrier with " + vendorDesc + ".",
                            align: HorizontalAlignment.CENTER
                        }));
                    } else {
                        this.btnPrequalCarrier.visible = false;
                        this.textboxPrequalVendor.visible = false;
                    }
                } else {
                    this.btnPrequalCarrier.visible = false;
                    this.textboxPrequalVendor.visible = false;
                }
            });
            if (!StringUtil.isEmptyString(this.textboxPrequalVendor.text) && !StringUtil.isEmptyString(this.textboxPendingCarrierId.text) && !StringUtil.isEmptyString(this.textboxPendingCarrierIdType.text)) {
                this.layoutCarrierPrequalResult.configurePreQualBtn(this.textboxPendingCarrierId, this.textboxPendingCarrierIdType, this.textboxPrequalVendor, this.btnPrequalCarrier, this.vendor_id);
            } else {
                this.btnPrequalCarrier.visible = false;
                this.textboxPrequalVendor.visible = false;
            }
        }
        this.setupLookupsForPendingCarriers(visible);
        if (!StringUtil.isEmptyString(this.textboxPendingCarrierId.text) && !StringUtil.isEmptyString(this.textboxPendingCarrierIdType.text)) {
            this.btnPrequalCarrier.visible = visible;
            this.textboxPrequalVendor.visible = visible;
        }
    }

    setPendingValuesFromOffer() {
        const pending_carrier_nbr = this.carrierOfferInfo.icc_number ?? this.carrierOfferInfo.dot_number;
        const pendingCarrierIdTypeIndex = !!this.carrierOfferInfo.icc_number ? 0 : 1;
        this.textboxPendingCarrierIdType.selectedItem = this.textboxPendingCarrierIdType.items[pendingCarrierIdTypeIndex];
        this.mainDataSource.activeRow.set("pending_carrier_nbr", pending_carrier_nbr);
        this.mainDataSource.activeRow.set("pending_carrier_name", this.carrierOfferInfo.carrier_name);
    }

    private setupDriverName(textbox: Textbox, driverNumberSuffix: string) {
        textbox.addBeforeLookupModelSearchListener(event => {
            event.filter.carrier_id = this.activeRow?.get("pending_payee_id", this.activeRow?.get("override_payee_id"));
            event.filter.is_active = "Y";
        });

        textbox.onSelectItem = ((textbox, selection) => {
            this.setDriverData(selection as ModelRow, driverNumberSuffix);
            return undefined;
        });

        textbox.tooltipCallback = null;    
    }

    fieldUpdated(event: FieldUpdateEvent) {
        if (!this.postingRow && event.fieldName == "pending_payee_id" && event.originator != null && event.oldValue != event.newValue) {
            this.loadTbPendingPayeeId(event.originator.getFirstLookupModelData()).then(() => {
                const carrierId = event.newValue;
                if (carrierId == null) {
                    this.configureSaveButton();
                    return;
                }
                this.setupLookupsForPendingCarriers(false);
                this.savebuttonAssign.enabled = false;
                this.validateCarrierForDispatch(carrierId, false, true, true);
                this.setDFLabel(carrierId);
            });
        }
    }

    clearFields(clearCarrier: boolean = false) {
        this.listenersActive = false;
        this.clearErrors();
        this.clearBoundData(this.panelCarrierInfo);
        this.clearBoundData(this.panelContact);
        this.clearBoundData(this.panelEquipment);
        this.labelMilesToPickup.caption = null;
        this.dfLabel.visible = false;
        this.sourceCarriersForAssignment.clear();
        this.sourceCarriersForAssignment.displayDataInBoundComponents();
        this.layoutMovementPay.tableExtraPay.quickAddRow.forEveryChildComponent(comp => comp.displayData(null, null, 0));
        this.layoutMovementPay.labelAutoRated.visible = false;
        if (clearCarrier) {
            this.mainDataSource.activeRow.set(LOOKUP_MODEL_PREFIX + "pending_payee_id", undefined);
            this.mainDataSource.activeRow.set("pending_payee_id", null);
        }
        this.layoutCarrierPrequalResult.hidePrequalPanels();
    }

    private clearBoundData(panel: Panel) {
        panel.forEveryChildComponent(comp => {
            if ("textboxPendingPayeeId" != comp.id && (comp instanceof Textbox || comp instanceof CityState) &&
                comp.enabled && !comp.printable && comp.field && this.mainDataSource.activeRow.get(comp.field) != null) {
                this.mainDataSource.activeRow.set(LOOKUP_MODEL_PREFIX + comp.field, undefined);
                this.mainDataSource.activeRow.set(comp.field, null);
            }
        });
    }

    clearErrors(clearPayErrors: boolean = false) {
        this.layoutCommentsErrors.clearErrors();
        this.preventAssignment = false;
        this.qualError = null;
        if (!clearPayErrors)
            this.payError = null;
    }

    validateCarrierForDispatch(carrierId: string, validatePay: boolean = false, clearPayErrors: boolean = false, showQualError: boolean = false) {
        this.clearErrors(clearPayErrors);
        this.textboxPendingPayeeId.enabled = false;
        this.layoutCommentsErrors.displayValidationErrors(
            this.activeRow?.get("id"),
            carrierId,
            this.activeRow?.get("override_driver_nm"),
            this.activeRow?.get("override_driver2_nm"))
        .then(data => {
            this.preventAssignment = data?.errors?.length > 0;
            this.qualError = !this.preventAssignment ? null : data?.errors;
            if (!this.preventAssignment) {
                if (this.qualError != null && showQualError) {
                    this.showDisqualSnackbar();
                }
            }
        }).finally(() => {
            this.textboxPendingPayeeId.enabled = true;
            if (validatePay)
                this.calculatePay(false);
            else
                this.configureSaveButton();
        });
    }


    calculatePay(editedByUser: boolean, autoRate?: boolean, calculateFuelSurcharge?: boolean): Promise<any> {
        return this.layoutMovementPay.calculatePay(editedByUser, autoRate, false, calculateFuelSurcharge);
    }

    configureSaveButton() {
        let enableSave = true;
        this.savebuttonAssign.width = 160;
        if (this.requiresPendingAssignment) {
            if (this.showSaveCaption()) {
                this.savebuttonAssign.caption = "Save";
            } else {
                this.savebuttonAssign.width = 200;
                this.savebuttonAssign.caption = "Pending Assignment";
            }
        } else {
            this.savebuttonAssign.caption = "Assign";
            enableSave = this.mainDataSource.activeRow.get("pending_payee_id") != null;
        }

        this.savebuttonAssign.enabled = enableSave && (!this.preventAssignment || this.requiresPendingAssignment);
    }

    private showSaveCaption(): boolean {
        return (this.origPendingPayeeId != null &&
                (this.origPendingPayeeId == this.mainDataSource.activeRow.get("pending_payee_id") ||
                    this.mainDataSource.activeRow.get("pending_payee_id") == null)) ||
            (this.panelUnknownCarrier.visible &&
                this.origPendingCarrierNbr != null &&
                this.origPendingCarrierNbr == this.mainDataSource.activeRow.get("pending_carrier_nbr"));
    }

    savebuttonAssignOnClick(event: ClickEvent) {
        // the following makes sure carrier pay is vaidated. If the user
        // modifies textboxOverridePayRate, doesn't tab out of the field,
        // and presses the save button, we end up posting the update before
        // validating carrier pay
        if (this.payError == null) {
            this.calculatePay(false).then(() => {
                if (this.payError == null)
                    this.postUpdate(event.target as Button);
            });
        } else {
            this.postUpdate(event.target as Button);
        }
    }

    get requiresPendingAssignment(): boolean {
        return this.qualError != null || this.payError != null || this.assigningToPendingCarrier;
    }

    private setPendingAssignmentLockFields() {
        this.mainDataSource.activeRow.set("lock_carrier_qual", this.qualError != null);
        this.mainDataSource.activeRow.set("lock_target_max_pay", this.payError != null);
        this.mainDataSource.activeRow.set("lock_new_carrier", this.assigningToPendingCarrier);
    }

    get assigningToPendingCarrier(): boolean {
        return this.panelUnknownCarrier.visible;
    }

    async postUpdate(button: Button) {
        button.busy = true;

        if (this.mainDataSource != null) {
            this.postingRow = true;
            if (!this.panelUnknownCarrier.visible)
                this.clearBoundData(this.panelUnknownCarrier);

            if (this.pendingAssignmentChanged()) {
                this.mainDataSource.activeRow.set("locked_user_id", null);
                this.mainDataSource.activeRow.set("unlock_time", null);
            }

            if (!this.requiresPendingAssignment && this.mainDataSource.activeRow.get("pending_payee_id") != null) {
                this.mainDataSource.activeRow?.set("override_payee_id", this.mainDataSource.activeRow.get("pending_payee_id"));
                this.mainDataSource.activeRow.set("pending_payee_id", null);
                this.textboxPendingPayeeId.dataSource = null;
            } else {
                this.setPendingAssignmentLockFields();
            }

            this.mainDataSource.execute().finally(() => {
                button.busy = false;
                this.postingRow = false;
                this.textboxPendingPayeeId.dataSource = this.sourceMovement;
                const pendingCarrierNbr = this.mainDataSource.activeRow.get("pending_carrier_nbr");
                if (!StringUtil.isEmptyString(pendingCarrierNbr)) {
                    Api.search("lme/dispatch/carrier",
                        this.textboxPendingCarrierIdType.text.includes("MC") ? { icc_number: this.mainDataSource.activeRow.get("pending_carrier_nbr") } :
                            this.textboxPendingCarrierIdType.text.includes("DOT") ? { dot_number: this.mainDataSource.activeRow.get("pending_carrier_nbr") } :
                                this.textboxPendingCarrierIdType.text.includes("Intrastate") ? { intrastate_code: this.mainDataSource.activeRow.get("pending_carrier_nbr") } : null
                    ).then(response => {
                        const carrierRow = response?.data?.[0];
                        if (!carrierRow) {
                            this.addOrInviteCarrier();
                        }
                    })
                }
            })
        }
    }

    addOrInviteCarrier() {
        if (this.requiresPendingAssignment
            && (!this.showSaveCaption() || this.pendingAssignmentChanged())
            && !PermissionsUtil.isUserDeniedAction("Brokerage.Invite or Add Carrier")) {
            if (this.auto_onboard_vendors.includes(this.vendor_id)) {
                Api.post("lme/powerbroker/prequal-carrier", {
                    identifier_type: this.textboxPendingCarrierIdType.text,
                    identifier_number: this.textboxPendingCarrierId.text
                }).then(response => {
                    const existInCarrierSystem = response?.data[0]?.exist_in_carrier_system;
                    if (existInCarrierSystem) {
                        OnboardCarrier.showSlideout(this.mainDataSource.activeRow?.get("id"), response.data[0], false);
                    } else {
                        OnboardCarrier.showSlideout(this.mainDataSource.activeRow?.get("id"), null, false);
                    }
                });
            } else if (this.add_vendors.includes(this.vendor_id)) {
                AddCarrier.showSlideout(this.mainDataSource.activeRow?.get("id"), this.vendor_id, this.textboxPendingCarrierName.text,
                    this.textboxPendingCarrierIdType.text, this.textboxPendingCarrierId.text, this.carrierins_control_onboarding_server, this.packet_website_url);
            } else {
                OnboardCarrier.showSlideout(this.mainDataSource.activeRow?.get("id"), null, false);

            }
        }
    }

    private pendingAssignmentChanged(): boolean {
        return (this.origPendingPayeeId != null && this.origPendingPayeeId != this.mainDataSource.activeRow.get("pending_payee_id")) ||
            (this.origPendingCarrierNbr != null && this.origPendingCarrierNbr != this.mainDataSource.activeRow.get("pending_carrier_nbr"));
    }

    close(canceled: boolean) {
        if (canceled) {
            this.layoutMovementPay.sourceExtraPay.data.forEach((row: ModelRow) => {
                if (!this.layoutMovementPay.cachedOtherPay.some((cachedRow: ModelRow) => cachedRow.get("id") === row.get("id"))) {
                    row.delete();
                }
            });
        }
        if (this.doAfterClose != null)
            this.doAfterClose(canceled);
    }

    showDisqualSnackbar() {
        let message = "This carrier does not meet the qualification requirements. ";

        if (this.origPendingPayeeId == null)
            message += "You may assign the carrier as Pending or select X to cancel.";
        else
            message += "You may update the Pending assignment or cancel it by removing the carrier.";


        const pnlContainer = new Panel({
            width: 450, rowBreak: false, padding: 0,
            components: [
                new Label({
                    caption: "Carrier Qualifications",
                    align: HorizontalAlignment.CENTER,
                    fontBold: true,
                    fontSize: "medium"
                }),
                new Label({ caption: message })
            ]
        })
        Snackbar.showWarningSnackbar(pnlContainer, { id: "carrierQual", persist: true });
        this.carrierQualSnackbar = ScreenStack.getOldestSnackbar("carrierQual") as Snackbar;
    }

    setDriverData(selectedRow: ModelRow, driverNumberSuffix) {
        const movementTractorNumber = this.mainDataSource.activeRow?.get("carrier_tractor");
        const driverOneName = this.mainDataSource?.activeRow.get("override_driver_nm");
        const carrierDriverTractor = selectedRow?.get("tractor_number");
        this.mainDataSource.activeRow.set(`override_driver${driverNumberSuffix}_id`, selectedRow?.get("id"));
        this.mainDataSource.activeRow.set(`override_drvr${driverNumberSuffix}_cell`, selectedRow?.get("cell_phone_number"));
        this.mainDataSource.activeRow.set(`override_drvr${driverNumberSuffix}_email`, selectedRow?.get("email_address"));
        this.dynamicallySetCarrierTractor(driverNumberSuffix, carrierDriverTractor, movementTractorNumber);
        this.enableDriverTwoComponents(driverOneName);
        if (!this.activeRow.isNull("pending_payee_id"))
            this.validateCarrierForDispatch(this.activeRow?.get("pending_payee_id"), false, true);
    }

    private dynamicallySetCarrierTractor(driverNumberSuffix: any, carrierDriverTractor: any, movementTractorNumber: any) {
        if (driverNumberSuffix === "") {
            this.mainDataSource.activeRow.set(`carrier_tractor`, carrierDriverTractor);
        } else if (driverNumberSuffix === "2") {
            // When driver one does not have tractor
            if (StringUtil.isEmptyString(movementTractorNumber)) {
                this.mainDataSource.activeRow.set(`carrier_tractor`, carrierDriverTractor);
            } else {
                this.mainDataSource.activeRow.set(`carrier_tractor`, movementTractorNumber);
            }
        }
    }

    private enableDriverTwoComponents(driverOneName: any) {
        const isDriverOneEmpty: boolean = StringUtil.isEmptyString(driverOneName);
        this.textboxOverrideDriver2Nm.enabled = !isDriverOneEmpty;
        this.textboxOverrideDrvr2Cell.enabled = !isDriverOneEmpty;
        this.textboxOverrideDrvr2Email.enabled = !isDriverOneEmpty;
        if (isDriverOneEmpty) {
            this.textboxOverrideDriver2Nm.clear();
            this.textboxOverrideDrvr2Cell.clear();
            this.textboxOverrideDrvr2Email.clear();
        }
    }

    /** This is an event handler for the onClick event of buttonCancel.  */
    buttonCancelOnClick(event: ClickEvent) {
        this.close(true);
        this.mainDataSource.cancel();
    }

    textboxPendingCarrierIdTypeOnChange(event: ChangeEvent) {
        this.layoutCarrierPrequalResult.panelCarrierQualificationInfo.visible = false;
        this.layoutCarrierPrequalResult.panelCarrierAAQualificationInfo.visible = false;
        if (!StringUtil.isEmptyString(this.textboxPrequalVendor.text)) {
            this.layoutCarrierPrequalResult.configurePreQualBtn(this.textboxPendingCarrierId, this.textboxPendingCarrierIdType, this.textboxPrequalVendor, this.btnPrequalCarrier, this.vendor_id);
        }
    }

    textboxPendingCarrierIdOnChange(event: ChangeEvent) {
        this.layoutCarrierPrequalResult.panelCarrierQualificationInfo.visible = false;
        this.layoutCarrierPrequalResult.panelCarrierAAQualificationInfo.visible = false;
        if (!StringUtil.isEmptyString(this.textboxPrequalVendor.text)) {
            this.layoutCarrierPrequalResult.configurePreQualBtn(this.textboxPendingCarrierId, this.textboxPendingCarrierIdType, this.textboxPrequalVendor, this.btnPrequalCarrier, this.vendor_id);
        }
    }


    async btnPrequalCarrierOnClick(event: ClickEvent) {
        this.btnPrequalCarrier.visible = false;
        this.layoutCarrierPrequalResult.prequalCarrier(this.textboxPendingCarrierIdType.text, this.textboxPendingCarrierId.text);

    }


    /** This is an event handler for the afterExecution event of sourceMovement.  */
    sourceMovementAfterExecution(event) {
        if (event._action == DataSourceMode.SEARCH && this.mainDataSource.activeRow != null) {
            this.checkForCarrierOfferInfo();
            this.origPendingPayeeId = this.mainDataSource.activeRow.get("pending_payee_id", null);
            this.origPendingCarrierNbr = this.mainDataSource.activeRow.get("pending_carrier_nbr");
            if (this.mainDataSource.activeRow.get("carrier_to_assign_id") != null) {
                this.mainDataSource.activeRow.set("pending_payee_id", this.mainDataSource.activeRow.get("carrier_to_assign_id"));
                this.setupLookupsForPendingCarriers(true);
            } else if (this.origPendingCarrierNbr != null) {
                this.setPanelUnknownCarrierVisible(true, false);
            } else {
                this.layoutMovementPay.defaultPayUnitsAndMethod();
            }

            if (this.mainDataSource.activeRow.get("pending_payee_id") != null)
                this.sourceCarriersForAssignment.search({ "id": this.mainDataSource.activeRow.get("pending_payee_id") });

            this.mainDataSource.activeRow.addAfterFieldUpdateListener(event => this.fieldUpdated(event));
            const movementId = this.mainDataSource.activeRow.get("id");
            if (movementId != null)
                this.labelHeaderTitle.caption = "Assign Carrier - Order " + this.mainDataSource.activeRow.get("orders.id", "");
            this.setCarrierEmptyCityState();
            this.layoutMovementPay.layoutCarrierRates.layoutTargetPay.mpactRatingEnabled(this.mainDataSource.activeRow.get("target_pay_method", "") === "S");
        }
    }

    checkForCarrierOfferInfo() {
        if (!!this.carrierOfferInfo) {
            this.mainDataSource.activeRow.set("carrier_to_assign_id", this.carrierOfferInfo.payee_id);
            this.mainDataSource.activeRow.set("carrier_contact", this.carrierOfferInfo.carrier_contact);
            this.mainDataSource.activeRow.set("carrier_phone", this.carrierOfferInfo.carrier_phone);
            this.mainDataSource.activeRow.set("carrier_email", this.carrierOfferInfo.carrier_email);
            this.mainDataSource.activeRow.set("carrier_name", this.carrierOfferInfo.carrier_name);
            this.mainDataSource.activeRow.set("carrier_empty_from_city_id", this.carrierOfferInfo.carrier_empty_from_city_id);
            this.textboxPendingPayeeId.enabledDuringUpdate = false;
        }
    }

    setupLookupsForPendingCarriers(remove: boolean) {
        for (const comp of [this.textboxCarrierContact, this.textboxOverrideDriverNm, this.textboxOverrideDriver2Nm]) {
            if (remove) {
                comp["old_lookupModel"] = comp.lookupModel;
                comp["old_lookupLayout"] = comp.lookupModelLayout;
                comp.lookupModel = null;
                comp.lookupModelLayout = null;
            } else if (comp["old_lookupModel"] && comp["old_lookupLayout"]) {
                comp.lookupModel = comp["old_lookupModel"];
                comp.lookupModelLayout = comp["old_lookupLayout"];
            }
        }
    }

    /** This is an event handler for the onBlur event of citystateCarrierEmptyFromCityId.  */
    citystateCarrierEmptyFromCityIdOnBlur() {
        const cityId = this.sourceMovement.activeRow.get("carrier_empty_from_city_id") || this.citystateCarrierEmptyFromCityId?.textCombined?.getFirstLookupModelData()?.get("id");
        const moveId = this.mainDataSource.activeRow?.get("id");
        this.labelMilesToPickup.caption = "";
        if (!StringUtil.isEmptyString(cityId) && moveId != null) {
            Api.post("lme/dispatch/get-next-stop-distance", {
                city_id: cityId,
                movement_id: moveId,
                use_load_dist_profile: true
            }).then(response => {
                if (!StringUtil.isEmptyString(response?.data[0]?.distance))
                    this.labelMilesToPickup.caption = `${response?.data[0]?.distance} Miles to Pick-up`;
            });
        }
    }

    setCarrierEmptyCityState() {
        const cityId = this.sourceMovement.activeRow.get("carrier_empty_from_city_id");
        if (cityId)
            Api.search("common/city", { id: cityId }).then(result => {
                const data = result?.data[0];
                this.citystateCarrierEmptyFromCityId.textCombined.text = CityUtil.formatCityStateZip(data.name, data.state_id, data.zip_code);
                this.citystateCarrierEmptyFromCityIdOnBlur();
            });
    }

    static async checkCarrierForDataFusion(payeeId: String): Promise<any> {
        let result = "";
        await Api.search("lme/datafusion/df-carrier-search", {
            payee_id: payeeId
        }).then(response => {
            const data = response.data[0];
            result = data.is_datafusion_carrier;
        });
        return result;
    }

    static async sendAutoTender(carrierId: string, moveId: string): Promise<any> {
        log.info("Sending AUTO tender");
        return await Api.post("lme/datafusion/auto-send-tender", {
            carrier: carrierId,
            move_id: moveId
        }).then(response => {
            return response;
        });
    }

    static async sendManualTender(carrierId: string, moveId: string, layout: SendLoadTender): Promise<any> {
        log.info("Sending MANUAL tender");
        const contacts = layout.textboxContact.text as unknown;
        const addlContacts = layout.textboxAddContact.text;
        const purpose = layout.labelPurpose.caption;
        const mrb = layout.textboxMustRespondBy.text;
        let allContacts: Array<string>;
        let cString = "";

        if (!StringUtil.isEmptyString(contacts)) {
            allContacts = contacts as Array<string>;
        }

        if (!StringUtil.isEmptyString(allContacts)) {
            for (let i = 0; i < allContacts.length; i++) {
                if (i > 0) cString += ",";
                cString += allContacts[i];
            }
        }

        if (!StringUtil.isEmptyString(addlContacts)) {
            const addContactList: string[] = addlContacts.split(",");

            addContactList.forEach((item) => {
                if (!StringUtil.isEmptyString(cString)) cString += ",";
                cString += item as string;
            });
        }

        return await Api.post("lme/datafusion/send-tender", {
            carrier: carrierId, move_id: moveId, respond_by: mrb, purpose: purpose, contacts: cString
        });
    }

    public setDFLabel(carrierId: string) {
        CarrierAssignment.checkCarrierForDataFusion(carrierId).then(result => {
            this.dfLabel.visible = (result == "auto" || result == "manual");
        });
    }

    setQuickInfoTooltip(component: Component, tooltip: StringOrPropsOrComponent) {
        if (component)
            component.tooltipCallback = (baseTooltip, originatingEvent) => {
                if (component["_internalShowTooltip"] != null)
                    return component["_internalShowTooltip"](tooltip, originatingEvent);
                return component.showTooltip(tooltip, null, { themeKey: "quickInfo", color: null });
            };
    }

}

function setVendorDesc(vendorId: string): string {
    let vendorDesc: string = "";

    if (vendorId === 'R') {
        vendorDesc = 'Registry Monitoring Service';
    } else if (vendorId === 'D') {
        vendorDesc = 'Assure Advantage';
    } else if (vendorId === 'H') {
        vendorDesc = 'Highway';
    } else if (vendorId === 'C') {
        vendorDesc = 'Carrier Watch';
    }

    return vendorDesc;
}


