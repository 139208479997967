import { CurrencyUtil, ModelRow, ModuleLogger } from ".";

export type SortDirection = "asc" | "desc";

export class SortUtil {
    public static getSortTestValue(data: ModelRow | any, fieldName: string): any {
        let value = data instanceof ModelRow ? data.get(fieldName) : data.data[fieldName];
        if (typeof value === "string" && value != null) {
            value = value.toUpperCase();
        }
        return value;
    }

    public static compareTwoValues(valueA: any, valueB: any, sortMethod: SortDirection, sortNullsAtEnd = false, log?: ModuleLogger): number {
        log?.debug(() => ["sort: %o  test value A: [%o]  test value B: [%o]", sortMethod, valueA, valueB]);
        let result = 0;
        //hope we can change this currency business when we start using the real Currency class
        if (CurrencyUtil.isCurrency(valueA) && CurrencyUtil.isCurrency(valueB)) {
            result = CurrencyUtil.compareCurrencys(valueA, valueB, sortNullsAtEnd);
            if (sortMethod === "desc") {
                if (result === 1)
                    result = -1;
                else if (result === -1)
                    result = 1;
            }
        }
        else {
            if (valueA == null && valueB != null)
                result = sortNullsAtEnd ? 1 : -1;
            else if (valueB == null && valueA != null)
                result = sortNullsAtEnd ? -1 : 1;
            else if (valueA == null && valueB == null)
                result = 0;
            else if (valueA === valueB)
                result = 0;
            else
                result = valueA > valueB ? 1 : -1;
            if (sortMethod === "desc")
                result *= -1;
        }
        log?.debug(() => ["sort result: %d", result]);
        return result;
    }
}
