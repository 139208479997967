import { Button, Checkbox, CityState, DataHeader, DataSource, HorizontalSpacer, Label, Layout, Location, Panel, SearchButton, Table, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutTenderList extends Layout {
	buttonClear: Button;
	buttonSearchExpand: Button;
	checkboxEdiOrderNoDisplay: Checkbox;
	citystateEdiStopDestCityId: CityState;
	citystateEdiStopOriginCityId: CityState;
	dataheaderTendersList: DataHeader;
	horizontalspacer1: HorizontalSpacer;
	horizontalspacer2: HorizontalSpacer;
	horizontalspacer3: HorizontalSpacer;
	labelReply: Label;
	labelSearchTenders: Label;
	labelTenders: Label;
	panelConsigneeLocation: Panel;
	panelSearch: Panel;
	panelSearchHeader: Panel;
	panelShipperLocation: Panel;
	searchbuttonSearch: SearchButton;
	sourceEdiOrdersList: DataSource;
	tableTenders: Table;
	textboxDestLocation: Location;
	textboxEdiOrderCreateDate: Textbox;
	textboxEdiOrderCustomerId: Textbox;
	textboxEdiOrderDirection: Textbox;
	textboxEdiOrderId: Textbox;
	textboxEdiOrderOrderId: Textbox;
	textboxEdiOrderPartnerId: Textbox;
	textboxEdiOrderPurposeType: Textbox;
	textboxEdiOrderReplyAction: Textbox;
	textboxEdiOrderReplyBatchId: Textbox;
	textboxEdiOrderReplyDate: Textbox;
	textboxEdiOrderReplyReason: Textbox;
	textboxEdiOrderRevenueCodeId: Textbox;
	textboxEdiOrderShipmentId: Textbox;
	textboxEdiStopDestAddress2: Textbox;
	textboxEdiStopDestAddress: Textbox;
	textboxEdiStopDestLocationId: Textbox;
	textboxEdiStopDestLocationName: Textbox;
	textboxEdiStopDestSchedArriveEarly: Textbox;
	textboxEdiStopOriginAddress2: Textbox;
	textboxEdiStopOriginAddress: Textbox;
	textboxEdiStopOriginLocationId: Textbox;
	textboxEdiStopOriginLocationName: Textbox;
	textboxEdiStopOriginSchedArriveEarly: Textbox;
	textboxOriginLocation: Location;
	textboxReferenceNumbers: Textbox;
}
