import { DesignerTool } from "./DesignerTool";
import { UIDesigner } from "./UIDesigner";

export class DevUIDesigner extends UIDesigner {

    constructor(props?) {
        super({ ...props, title: "UI Designer" })
    }

    get localStorageKeys() { return { openTabs: "designer.ui.open", selectedTab: "designer.ui.selected" } }


    get designerTools(): DesignerTool[] {
        return [
            new DesignerTool(this, "Label"),
            new DesignerTool(this, "Textbox"),
            new DesignerTool(this, "Button"),
            new DesignerTool(this, "Checkbox"),
            new DesignerTool(this, "Switch"),
            new DesignerTool(this, "Table"),
            new DesignerTool(this, "Image"),
            new DesignerTool(this, "CityState"),
            new DesignerTool(this, "Splitter"),
            new DesignerTool(this, "Tree"),
            new DesignerTool(this, "Map"),
            new DesignerTool(this, "Panel"),
            new DesignerTool(this, "Stepper"),
            new DesignerTool(this, "Tabset"),
            new DesignerTool(this, "List"),
            new DesignerTool(this, "Attachment"),
            new DesignerTool(this, "Layout", { isNested: true }),
            new DesignerTool(this, "HorizontalSpacer"),
            new DesignerTool(this, "SearchButton"),
            new DesignerTool(this, "SaveButton"),
            new DesignerTool(this, "DataHeader"),
            new DesignerTool(this, "NumberEditor"),
            new DesignerTool(this, "StarRating"),
            new DesignerTool(this, "Chart"),
            new DesignerTool(this, "Location", null, "CityState")
        ];
    }
}
