import { ChangeEvent, Textbox } from "@mcleod/components";
import { AutogenLayoutRecurringOrderPrompt } from "./autogen/AutogenLayoutRecurringOrderPrompt";

export class RecurringOrderPrompt extends AutogenLayoutRecurringOrderPrompt {
    textboxRecurringOrderIdOnChange(event: ChangeEvent) {
        const textbox = event.target as Textbox;
        textbox.text = textbox.text.replace(/['&;,%]/g, "") // remove invalid characters
        if (textbox.text.length > 8) {
            textbox.text = textbox.text.substring(0, 8);
        }
    }
}
