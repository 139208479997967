import { Image, Label, Panel } from "@mcleod/components";
import { MetadataField, StringUtil, VerticalAlignment } from "@mcleod/core";
import { AbstractUIDesigner } from "./AbstractUIDesigner";

export class DesignerTool extends Panel {
    image: Image;
    toolName: string;
    private _toolProps: any;

    constructor(designer: AbstractUIDesigner, name: string, field?: MetadataField | any, imageName?: String, overrideToolName?: string) {
        super({ fillRow: true, draggable: true, allowSelect: false, wrap: false, color: "subtle.darker", verticalAlign: VerticalAlignment.CENTER });
        if (field == null)
            this._toolProps = { name: name };
        else if (field instanceof MetadataField)
            this._toolProps = designer.getToolPropsForField(field);
        else
            this._toolProps = field;
        name = this._toolProps?.toolType || name;
        const proper = name;
        name = name.replace(" ", "");
        this.toolName = overrideToolName || name;
        this.image = new Image({
            name: "designer/" + (StringUtil.isEmptyString(imageName) !== true ?
                imageName.toLowerCase() : name.toLowerCase()), height: 20, width: 20, rowBreak: false
        });
        this.add(this.image);
        this.add(new Label({ caption: this._toolProps.name || proper, fontSize: "small" }));
    }

    public get toolProps(): any {
        return { ...this._toolProps };
    }
}
