import {
    Attachment, Button, Checkbox, CityState, DataHeader, DataSource, Label, Layout, Panel, Tab, Tabset, Textbox
} from "@mcleod/components";
import { BillToOverrideTable } from "../../../dispatch/src/BillToOverrideTable";
import { CallRecordTable } from "../../../dispatch/src/CallRecordTable";
import { ProductBookTable } from "../../../dispatch/src/ProductBookTable";
import { ProhibitedTable } from "../../../dispatch/src/ProhibitedTable";
import { TableComment } from "../../../general/src/TableComment";
import { TableContact } from "../../../general/src/TableContact";

export abstract class AutogenLayoutCustomer extends Layout {
    attachmentBlLogo: Attachment;
    buttonAllCalls: Button;
    buttonCustomerUsers: Button;
    buttonOpenClassic: Button;
    checkboxCreditApplication: Checkbox;
    checkboxDisableAutomaticTracking: Checkbox;
    checkboxRequireTracking: Checkbox;
    dataheader1: DataHeader;
    headingAvailableCredit: Label;
    headingCashReceiptsTotal: Label;
    headingMiscBillTotal: Label;
    headingOrdersNotRated: Label;
    headingPastDueTotal: Label;
    headingUnbilledTotal: Label;
    labelAvailableCredit: Label;
    labelBillToOverride: Label;
    labelBillofLadingImage: Label;
    labelCashReceiptsTotal: Label;
    labelHeaderContactEmail: Label;
    labelHeaderContactName: Textbox;
    labelHeaderContactPhone: Label;
    labelHeaderLocAddr: Label;
    labelHeaderLocCSZ: CityState;
    labelHeaderLocName: Textbox;
    labelMiscBillTotal: Label;
    labelOrdersNotRated: Label;
    labelPastDueTotal: Label;
    labelSalespersonEmail: Label;
    labelSalespersonPhone: Label;
    labelUnbilledTotal: Label;
    layoutBillToOverrideTable: BillToOverrideTable;
    layoutCallRecord: CallRecordTable;
    layoutContactTable: TableContact;
    layoutProductBookTable: ProductBookTable;
    layoutProhibited: ProhibitedTable;
    layoutTableComment: TableComment;
    panelAutoRate1: Panel;
    panelAutoRate2: Panel;
    panelDataHeaderAddlLeft: Panel;
    panelFinancials1: Panel;
    panelFinancials2: Panel;
    panelFinancials3: Panel;
    panelGeneral1: Panel;
    panelGeneral2: Panel;
    panelGeneral3: Panel;
    panelGeneral4: Panel;
    panelHeader: Panel;
    panelHeaderContact: Panel;
    panelHeaderCustomer: Panel;
    panelHeaderSalesperson: Panel;
    panelSalesCalls: Panel;
    panelTotals1: Panel;
    panelTotals2: Panel;
    panelTotals3: Panel;
    panelTotals4: Panel;
    panelTotals5: Panel;
    panelTotals6: Panel;
    panelTotals: Panel;
    sourceAgencyLink: DataSource;
    sourceBolBillToOverride: DataSource;
    sourceCallRecord: DataSource;
    sourceComments: DataSource;
    sourceContacts: DataSource;
    sourceCustomer: DataSource;
    sourceCustomerAging: DataSource;
    sourceLocationCommodity: DataSource;
    sourceProhibited: DataSource;
    tabAutoRate: Tab;
    tabBillofLading: Tab;
    tabComments: Tab;
    tabContacts: Tab;
    tabFinancials: Tab;
    tabGeneral: Tab;
    tabProductBook: Tab;
    tabProhibited: Tab;
    tabSalesCalls: Tab;
    tabTrackingOptions: Tab;
    tabsetMain: Tabset;
    textCityState: CityState;
    textboxAddress1: Textbox;
    textboxAddress2: Textbox;
    textboxAverageBill: Textbox;
    textboxAveragePayDays: Textbox;
    textboxBalance: Textbox;
    textboxBilledLoads: Textbox;
    textboxBlanketBuyTariffId: Textbox;
    textboxBlanketSellTariffId: Textbox;
    textboxBusinessHours: Textbox;
    textboxBuyTariffId: Textbox;
    textboxCategory: Textbox;
    textboxCertOfInsDate: Textbox;
    textboxCreditCheckDate: Textbox;
    textboxCreditLimit: Textbox;
    textboxCreditStatus: Textbox;
    textboxCreditWarningPct: Textbox;
    textboxDaysBetweenCalls: Textbox;
    textboxDotNumber: Textbox;
    textboxEnteredDate: Textbox;
    textboxFakProfileId: Textbox;
    textboxHighBalance: Textbox;
    textboxId: Textbox;
    textboxIsActive: Textbox;
    textboxLastBillDate: Textbox;
    textboxLastPayDate: Textbox;
    textboxLastShipDate: Textbox;
    textboxMotorCarrierId: Textbox;
    textboxName: Textbox;
    textboxNextCallDate: Textbox;
    textboxOperationsUser: Textbox;
    textboxP44ProfileId: Textbox;
    textboxPaidLoads: Textbox;
    textboxPayDaysDate: Textbox;
    textboxRateOverrideNmfcCode: Textbox;
    textboxRequiredVendor: Textbox;
    textboxSalespersonId: Textbox;
    textboxSalespersonName: Textbox;
    textboxSellTariffId: Textbox;
    textboxShipmentIdentType: Textbox;
    textboxTrackingHoursPrior: Textbox;
    textboxWebsiteUrl: Textbox;
}
