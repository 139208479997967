import { DataSource, EditRowDecorator, Label, TableRow, TableRowDisplayEvent } from "@mcleod/components";
import { Api, ModelRow, StringUtil, getAuthSettings, getLogger } from "@mcleod/core";
import { OfferSource } from "@mcleod/dispatch/src/OfferUtil";
import { isSpotOrder } from "@mcleod/dispatch/src/Orders";
import { CarrierOfferSlideout } from "../../dispatch/src/CarrierOfferSlideout";
import { QuickSellDetails } from "../../dispatch/src/QuickSellDetails";
import { MovementCarrierPay } from "./MovementCarrierPay";
import { TopMatchList } from "./TopMatchList";
import { TopMatchUtil } from "./TopMatchUtil";
import { AutogenLayoutLoadBoardsTable } from "./autogen/AutogenLayoutLoadBoardsTable";
import { OfferField } from "./models/ModelCarrierOffer";
import { DispatchUtil } from "@mcleod/dispatch/src/DispatchUtil";

const log = getLogger("lme.powerbroker.LoadBoardsTable");

export class LoadBoardsTable extends AutogenLayoutLoadBoardsTable {
    private _stopCount: string;
    public updateTabTitle: (rowCount: number) => void;
    private topMatchList: TopMatchList;

    initialize(topMatchList: TopMatchList) {
        this.topMatchList = topMatchList;
    }

    override onLoad() {
        this.tableLoadboards.onEditLayoutLoaded = this._offerLayoutLoaded();
    }

    override async search(searchValues: ModelRow) {
        if (this.tableLoadboards.emptyCaption == null)
            this.tableLoadboards.emptyCaption = "No results found";
        await this.mainDataSource.search({
            order_id: searchValues.get("order_id"),
            movement_id: searchValues.get("movement_id"),
            trailer_type: searchValues.get("trailer_type"),
            offer_history: searchValues.get("offer_history")
        });
        this.mainDataSource.displayDataInBoundComponents();
        if (this.updateTabTitle != null) {
            this.updateTabTitle(this.tableLoadboards.rowCount);
        }
        return Promise.resolve();
    }

    public clear() {
        this.mainDataSource.data = [];
        this.movement = null;
        this.tableLoadboards.resetFilter();
        this.tableLoadboards.emptyCaption = null;
        this.updateTabTitle(null);
        this.mainDataSource.displayDataInBoundComponents();
    }

    set movement(sourceMovement: DataSource) {
        this.sourceMovement = sourceMovement;
        let isAvailable: boolean;
        if (sourceMovement == null || sourceMovement.activeRow == null) {
            isAvailable = false;
        } else {
            isAvailable = "A" === this.sourceMovement.activeRow.get("status");
        }

        this.tableLoadboards.allowEdit = isAvailable;
    }

    set stopCount(stopCount: string) {
        this._stopCount = stopCount;
    }

    private _offerLayoutLoaded(): (rowDecorator: EditRowDecorator, tableRow: TableRow) => void {
        return async (rowDecorator: EditRowDecorator, tableRow: TableRow) => {
            rowDecorator.btnDelete.visible = false;
            rowDecorator.multiButton.dropdownItems = null;
            rowDecorator.multiButton.primaryButton.paddingLeft = 7;
            rowDecorator.labelTitle.caption = `Quick Offer - Order ${this.sourceMovement.activeRow?.get("orders.id")}`;
            const CarrierOfferLayout = rowDecorator.layout as CarrierOfferSlideout;
            const CarrierPayLayout = CarrierOfferLayout.loCarrierPay as MovementCarrierPay;
            const QuickSellLayout = CarrierOfferLayout.layoutQuickSellDetails as QuickSellDetails;

            CarrierOfferLayout.tbMcNumber.enabled = false;
            CarrierOfferLayout.tbPayeeId.enabled = false;
            CarrierOfferLayout.tbDotNumber.enabled = false;

            CarrierPayLayout.replaceMainDatasource(this.sourceMovement);
            QuickSellLayout.replaceMainDatasource(this.sourceMovement);
            QuickSellLayout.configureForSlideout(this._stopCount);
            QuickSellLayout.displayData(this.sourceMovement.activeRow, null, 0);

            const modelRow: ModelRow = CarrierOfferLayout.mainDataSource.activeRow as ModelRow;

            modelRow.set("order_id", this.sourceMovement.activeRow.get("orders.id"));
            modelRow.set("movement_id", this.sourceMovement.activeRow.get("id"));
            modelRow.set("status", "I");
            modelRow.set("orders_on_hold", this.sourceMovement.activeRow.get("orders.on_hold", null));
            modelRow.set("pending_payee", this.sourceMovement.activeRow.get("pending_payee_id", null) ?? this.sourceMovement.activeRow.get("has_pending_assignment", false));
            modelRow.set("is_subject_order", isSpotOrder(this.sourceMovement.activeRow));
            modelRow.set("order_total_chg", this.sourceMovement.activeRow.get("orders.total_charge"));
            CarrierOfferLayout.assignableCheck = () => DispatchUtil.isAssignable(this.sourceMovement.activeRow, CarrierOfferLayout.buttonAssignCarrier, null);
            if (modelRow.get("lme_carrier", false)) {
                const carrierId = modelRow.get("payee_id");
                modelRow.setLookupModelData("carrier_name", new ModelRow(CarrierOfferLayout.tbCarrierName.lookupModel, false, { id: carrierId, name: modelRow.get("carrier_name") }));
                if (carrierId != null) {
                    CarrierOfferLayout.tbCarrierName.displayData(modelRow, null, 0);
                    CarrierOfferLayout.tbCarrierName.enabled = false;
                }

                if (!StringUtil.isEmptyString(modelRow.get("equipment_type_id", ""))) {
                    modelRow.setLookupModelData("equipment_type_id", new ModelRow(CarrierOfferLayout.tbTrailerType.lookupModel, false, { id: modelRow.get("equipment_type_id") }));
                    CarrierOfferLayout.tbTrailerType.displayData(modelRow, null, 0);
                }
            } else {
                modelRow.set("carrier_name", modelRow.get("company", ""));
                modelRow.setLookupModelData("carrier_name", new ModelRow(CarrierOfferLayout.tbCarrierName.lookupModel, false, { id: modelRow.get('icc_number'), name: modelRow.get("company", "") }));
                CarrierOfferLayout.tbCarrierName.displayData(modelRow, null, 0);
            }

            this.setContactInfo(modelRow);
            if (!StringUtil.isEmptyString(modelRow.get("contact_name", ""))) {
                modelRow.setLookupModelData("contact_name", new ModelRow(CarrierOfferLayout.tbContactName.lookupModel, false, { name: modelRow.get("contact_name") }));
                CarrierOfferLayout.tbContactName.displayData(modelRow, null, 0);
            }
            CarrierOfferLayout.tbContactPhone.displayData(modelRow, null, 0);
            CarrierOfferLayout.tbEmail.displayData(modelRow, null, 0);

            const originCity = modelRow.get("origin_city", "");
            const originState = modelRow.get("origin_state", "");
            const tractorCityId = await this.getCity(originCity, originState);
            if (tractorCityId != null) {
                modelRow.set("tractor_city_id", tractorCityId);
                modelRow.set("tractor_city", originCity);
                modelRow.set("tractor_state", originState);
                CarrierOfferLayout.csEmptyCity.displayData(modelRow, null, 0);
            }

            if (tractorCityId && CarrierPayLayout.mainDataSource.activeRow?.get("id") != null) {
                Api.post("lme/dispatch/get-next-stop-distance", { city_id: tractorCityId, movement_id: CarrierPayLayout.mainDataSource.activeRow?.get("id") }).then(response => {
                    modelRow.set("miles_to_origin", response?.data[0]?.distance);
                    CarrierOfferLayout.tbMilesToPickup.text = response?.data[0]?.distance;
                })
            }
            CarrierOfferLayout.updateMargins(OfferField.OFFER, "offer_margin", CarrierOfferLayout.tbDispatchMargin, CarrierOfferLayout.tbDispatchMarginPrcnt, true);
            CarrierOfferLayout.updateMargins(OfferField.COUNTER, "counter_margin", CarrierOfferLayout.tbCounterMargin, CarrierOfferLayout.tbCounterMarginPrcnt, true);
            CarrierOfferLayout.assignButtonClicked = () => rowDecorator.multiButton.primaryButton.clicked();
            CarrierOfferLayout.carrierAssigned = () => this.topMatchList.carrierAssigned()

            CarrierPayLayout.layoutTargetPay.mpactRatingEnabled(this.sourceMovement.activeRow.get("target_pay_method", "") === "S");
            CarrierPayLayout.displayCalculatedFields();
            CarrierPayLayout.mainDataSource.displayDataInBoundComponents();
            CarrierPayLayout.buttonOverrideTargetPay.enabled = false;
            CarrierPayLayout.buttonOverrideMaxPay.enabled = false;

            rowDecorator.onSave = (offerRow: ModelRow) => {
                const lblOffered = tableRow.findComponentById("lblOffered") as Label;
                this.onSaveCallback(offerRow).then((modelRow) => {
                    TopMatchUtil.displayOfferIcon(tableRow, modelRow.get("id"));
                });
            };
        }
    }

    async getCity(name: string, state_id: string): Promise<string> {
        if (!StringUtil.isEmptyString(name) && !StringUtil.isEmptyString(state_id)) {
            const res = await Api.search("common/city", { name, state_id });
            if (res.data && res.data.length > 0) {
                return res.data[0].id;
            }
        }
        return null;
    }

    setContactInfo(modelRow: ModelRow) {
        if (modelRow.get("vendor") === 3) {
            // Project44COOP
            modelRow.set("email", modelRow.get("contact", ""));
        } else {
            const contactName = modelRow.get("contact", "");
            const contactPhone = modelRow.get("callback", "");
            modelRow.set("contact_name", contactName);
            modelRow.set("contact_phone", contactPhone);
        }
    }

    async onSaveCallback(offerRow: ModelRow): Promise<ModelRow> {
        const offer = offerRow.createBasicCopy();
        offer._appending = true;
        offer.set("entered_user_id", getAuthSettings()?.user_settings?.user_id);
        offer.set("electronic_offer", "N");
        offer.set("offer_date", new Date());
        offer.set("source", OfferSource.QUICKOFFER);
        offer.set("amount_n", offerRow.get("amount")?.amount);
        return offer.post();
    }

    /** This is an event handler for the onRowDisplay event of tableLoadboards.  */
    tableLoadboardsOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.getTableRow();
        const modelRow: ModelRow = tableRow.data;
        tableRow.canBeDeleted = false;

        if (modelRow.get("lme_carrier", false)) {
            const carrierLabel = tableRow.findComponentById("labelCarrier");
            const carrierIcon = tableRow.findComponentById("labelCarrierExists");
            carrierLabel.color = "success";
            carrierLabel.fontBold = true;
            carrierIcon.visible = true;

        }

        // show offer icon is offer has been sent in the past for this movement
        TopMatchUtil.displayOfferIcon(tableRow, modelRow.get("offer_id"));
    }
}
