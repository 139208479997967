export const props = { viewBox: "0 0 29 24" };
export const innerHTML =
    '<g clip-path="url(#clip0_332_250)">' +
    '<path d="M19.9237 10.4375C20.6274 10.4375 21.3022 10.158 21.7997 9.66048C22.2972 9.16295 22.5767 8.48815 22.5767 7.78453V2.77213H22.6397L27.6206 7.75307V18.082H19.2526V19.8751H27.5786C27.814 19.8765 28.0473 19.831 28.2649 19.7413C28.4825 19.6515 28.6801 19.5193 28.846 19.3524C29.012 19.1855 29.143 18.9871 29.2315 18.769C29.32 18.5509 29.3641 18.3173 29.3613 18.082V7.01904L23.3108 0.99997H12.2583C12.0252 0.998579 11.7942 1.04347 11.5785 1.13203C11.3629 1.2206 11.167 1.35108 11.0021 1.51592C10.8373 1.68076 10.7068 1.87668 10.6182 2.09231C10.5297 2.30795 10.4848 2.53902 10.4862 2.77213V3.61103H12.2583V2.77213H13.632V4.28214L15.4042 5.99139V2.77213H20.7312V7.82647C20.7326 7.94303 20.7108 8.05869 20.6672 8.16678C20.6235 8.27486 20.5588 8.3732 20.4769 8.45611C20.395 8.53902 20.2974 8.60484 20.1899 8.64976C20.0823 8.69468 19.9669 8.71781 19.8503 8.7178H18.2984L19.5148 9.94468V10.448L19.9237 10.4375Z"/>' +
    '<path d="M18.8751 6.94567C19.1087 6.94567 19.3328 6.85287 19.498 6.68768C19.6632 6.52249 19.756 6.29845 19.756 6.06483V5.01621C19.756 4.7826 19.6632 4.55856 19.498 4.39337C19.3328 4.22818 19.1087 4.13538 18.8751 4.13538C18.6415 4.13538 18.4175 4.22818 18.2523 4.39337C18.0871 4.55856 17.9943 4.7826 17.9943 5.01621V6.06483C17.9943 6.29845 18.0871 6.52249 18.2523 6.68768C18.4175 6.85287 18.6415 6.94567 18.8751 6.94567Z"/>' +
    '<path d="M12.8246 4.14584H1.77216C1.53905 4.14445 1.30798 4.18934 1.09234 4.27791C0.876704 4.36647 0.680789 4.49696 0.51595 4.6618C0.351112 4.82663 0.220628 5.02255 0.132061 5.23819C0.0434947 5.45382 -0.00139353 5.6849 -1.77399e-06 5.91801V21.2278C-0.00277691 21.4623 0.0410125 21.695 0.128829 21.9124C0.216645 22.1299 0.346742 22.3277 0.51158 22.4945C0.676417 22.6613 0.872718 22.7937 1.08911 22.8841C1.30549 22.9745 1.53766 23.021 1.77216 23.021H17.082C17.3182 23.0238 17.5527 22.9793 17.7715 22.8902C17.9903 22.8011 18.1891 22.6691 18.3562 22.502C18.5233 22.335 18.6552 22.1362 18.7444 21.9173C18.8335 21.6985 18.8779 21.4641 18.8751 21.2278V10.1649L12.8246 4.14584ZM4.93899 5.91801H10.266V10.9723C10.2674 11.0889 10.2456 11.2046 10.202 11.3126C10.1583 11.4207 10.0936 11.5191 10.0117 11.602C9.92978 11.6849 9.83221 11.7507 9.72465 11.7956C9.61709 11.8406 9.50169 11.8637 9.38513 11.8637H5.81983C5.58525 11.8609 5.36123 11.7658 5.19633 11.5989C5.03144 11.4321 4.93897 11.2069 4.93899 10.9723V5.91801ZM17.082 21.2278H1.77216V5.91801H3.14585V10.9723C3.1569 11.6723 3.44416 12.3396 3.94503 12.8286C4.44591 13.3177 5.11979 13.589 5.81983 13.5834H9.43756C10.1412 13.5834 10.816 13.3039 11.3135 12.8064C11.811 12.3088 12.0906 11.634 12.0906 10.9304V5.91801H12.1535L17.1344 10.8989V21.2278H17.082ZM8.38894 10.0915C8.62255 10.0915 8.8466 9.9987 9.01179 9.83351C9.17698 9.66833 9.26978 9.44428 9.26978 9.21067V8.16205C9.26978 7.92844 9.17698 7.70439 9.01179 7.5392C8.8466 7.37401 8.62255 7.28121 8.38894 7.28121C8.15533 7.28121 7.93128 7.37401 7.76609 7.5392C7.6009 7.70439 7.5081 7.92844 7.5081 8.16205V9.21067C7.5081 9.44428 7.6009 9.66833 7.76609 9.83351C7.93128 9.9987 8.15533 10.0915 8.38894 10.0915Z"/>' +
    '</g>' +
    '<defs>' +
    '<clipPath id="clip0_332_250">' +
    '<rect width="29" height="24" fill="white"/>' +
    '</clipPath>' +
    '</defs>';

