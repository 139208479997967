import { Label, TableRow, TableRowDisplayEvent } from "@mcleod/components";
import { Model } from "@mcleod/core";
import { AutogenLayoutRoutingGuideList } from "./autogen/AutogenLayoutRoutingGuideList";

export class RoutingGuideList extends AutogenLayoutRoutingGuideList {
    tableRoutingGuideListOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.getTableRow();

        this.setupMultiSelectDescriptionLabels(tableRow);
    }

    async setupMultiSelectDescriptionLabels(tableRow: TableRow) {
        const modelRow = tableRow.data;

        const customerIds = modelRow.get("customer_id");
        if (customerIds != null) {
            const customerNameList = [];
            for (const customerId of customerIds.split(",")) {
                const response = await Model.searchSingleRecord("lme/ar/customer", { id: customerId });
                customerNameList.push(response.get("name"));
            }
            (tableRow.findComponentById("labelCustomerList") as Label).caption = customerNameList.join(", ");
        }

        const commodityIds = modelRow.get("commodity_id");
        if (commodityIds != null) {
            const commodityDescrList = [];
            for (const commodityId of commodityIds.split(",")) {
                const response = await Model.searchSingleRecord("lme/dispatch/commodity", { id: commodityId });
                commodityDescrList.push(response.get("descr"));
            }
            (tableRow.findComponentById("labelCommodityList") as Label).caption = commodityDescrList.join(", ");
        }


        const equipmentTypeIds = modelRow.get("equipment_type_id");
        (tableRow.findComponentById("labelTrailerType") as Label).caption = equipmentTypeIds ? equipmentTypeIds.split(",").join(", ") : null;
    }
}
