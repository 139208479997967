import { DataSource, Layout, Textbox } from "@mcleod/components";
import { ResponsibleHist } from "../../../general/src/ResponsibleHist";

export abstract class AutogenLayoutOrderUsers extends Layout {
    layoutResponsibleHist: ResponsibleHist;
    sourceOrders: DataSource;
    textboxAgentPayeeId: Textbox;
    textboxBillingUserId: Textbox;
    textboxEnteredUserId: Textbox;
    textboxOperationsUser: Textbox;
}
