import { CommonDialogs } from "@mcleod/common";
import { BlurEvent, ChangeEvent, Checkbox, Component, ComponentProps, Cursor, Switch, TableRow, Textbox } from "@mcleod/components";
import { ModelRow, StringUtil, getLogger } from "@mcleod/core";
import { PropertiesTable } from "./PropertiesTable";
import { doDesignerAction } from "./UIDesignerActionHistory";
import { ActionChangeComponentProperty } from "./actions/ActionChangeComponentProperty";

const log = getLogger("designer/ui/DefaultValuePropertyEditor");

const compProps: Partial<ComponentProps> = { _designer: null, field: "value", borderWidth: 0, paddingLeft: 8, fillRow: true, rowBreak: false };

export class DefaultValuePropertyEditor {
    propsTable: PropertiesTable;
    tableRow: TableRow;
    private _component: Component;
    selectedComponent: Component;

    constructor(propsTable: PropertiesTable, tableRow: TableRow) {
        this.propsTable = propsTable;
        this.tableRow = tableRow;
        const selected = this.propsTable.getSelectedComponent(0);
        if (this.propsTable.selectedComponents.length == 1 && selected instanceof Component) {
            this.selectedComponent = selected;
            this._component = this.createComponent();
        }
    }

    get component(): Component {
        return this._component;
    }

    private createComponent(): Component {
        let comp: Component = null;
        if (this.selectedComponent instanceof Textbox)
            comp = this.createTextbox(this.selectedComponent);
        else if (this.selectedComponent instanceof Checkbox)
            comp = this.createCheck(this.selectedComponent);
        else if (this.selectedComponent instanceof Switch)
            comp = this.createSwitch(this.selectedComponent);
        else
            comp = new Textbox({ ...compProps, enabled: false });

        comp.addFocusListener(() => this.propsTable.selectedTableRow = this.tableRow);
        comp.addKeyUpListener((event) => this.propsTable.keyUp(event));
        (comp as any).index = this.tableRow.index;
        comp.displayData(this.tableRow.data, null, null);
        return comp;
    }

    createCheck(source: Checkbox): Checkbox {
        const checkbox = new Checkbox({ ...compProps });
        checkbox.addChangeListener((event: ChangeEvent) => {
            if (event.userInitiatedChange)
                this.propChanged(event.newValue);
        });
        return checkbox;
    }

    private createSwitch(source: Switch): Switch {
        const comp = new Switch({
            leftCaption: source.leftCaption,
            rightCaption: source.rightCaption,
            leftValue: source.leftValue,
            rightValue: source.rightValue,
            ...compProps
        });

        comp.addChangeListener((event: ChangeEvent) => {
            if (event.userInitiatedChange)
                this.propChanged(comp.checked ? comp.rightValue : comp.leftValue);
        });
        return comp;
    }

    private createTextbox(source: Textbox): Textbox {
        const textbox = new Textbox({
            lookupModel: source.lookupModel,
            lookupModelLayout: source.lookupModelLayout,
            lookupModelResultField: source.lookupModelResultField,
            lookupModelDisplayField: source.lookupModelDisplayField,
            lookupModelAllowSearchAll: true,
            displayType: source.displayType, items: source.items,
            paddingLeft: 4, captionVisible: false, allowDropdownBlank: true,
            maxValue: source.maxValue, minValue: source.minValue,
            ...compProps
        });
        this.addTextboxListeners(textbox);
        return textbox;
    }

    private addTextboxListeners(textbox: Textbox) {
        if (textbox.resolveItems()?.length > 0) {
            textbox._input.readOnly = true;
            textbox._input.style.cursor = Cursor.DEFAULT;
            textbox.addChangeListener((event: ChangeEvent) => {
                if (event.userInitiatedChange)
                    this.propChanged(event.newValue);
            });
        } else {
            if (textbox.hasLookupModel()) {
                textbox.lookupModelLayoutWidth = 300;
                textbox.text = this.tableRow.data.value;
                textbox.onSelectItem = (textbox: Textbox, selectedItem: ModelRow) => {
                    textbox.text = selectedItem.get(textbox.lookupModelResultField);
                    this.propChanged(textbox.text);
                    return undefined;
                }
                textbox.addBlurListener((event: BlurEvent) => this.textboxWithLookupOnBlur(event))
            } else {
                textbox.addBlurListener((event: BlurEvent) => {
                    if (!this.hasValidationWarning(textbox))
                        this.propChanged(textbox.text);
                });
            }
        }
    }

    private textboxWithLookupOnBlur(event: any) {
        const textbox = event.target as Textbox;
        const newValue = textbox.isEmpty() ? null : textbox.text;
        if (textbox.isDropdownVisible() || newValue == this.tableRow.data.value)
            return;

        if (newValue != null && textbox.lookupModelAllowFreeform === false && !textbox._element.contains(event.domEvent.relatedTarget)) {
            textbox.validationWarning = "Free-form text is not allowed in this field.  Please select a value from the list.";
            textbox.tooltip = null;
            this.hasValidationWarning(textbox);
        } else if ((textbox.hasLookupModel())) {
            this.propChanged(newValue);
        }
    }

    hasValidationWarning(textbox: Textbox): boolean {
        if (textbox.validationWarning)
            CommonDialogs.showDialog(textbox.validationWarning, { title: "Invalid defaultDataValue" }).then(() => textbox.focus());
        return !StringUtil.isEmptyString(textbox.validationWarning);
    }

    propChanged(newValue: any) {
        const data = this.tableRow.data;
        this.propsTable.designer.applyChangeToSelectedComponents(data, newValue);
        const action = new ActionChangeComponentProperty(this.selectedComponent, data.prop_name, newValue, data.value);
        doDesignerAction(this.propsTable.designer, action);
    }
}
