/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelTopmatchProfile.ts               *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/powerbroker/topmatch-profile";

export class AutogenModelTopmatchProfile<RowType extends ModelRow> extends Endpoint<RowType, RowTopmatchProfileFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowTopmatchProfile extends ModelRow<RowTopmatchProfileFields> {
    constructor(values?: Partial<RowTopmatchProfileFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowTopmatchProfileFields {
    company_id: string;
    descr: string;
    id: string;
    is_active: string;
    search_results_maximum: number;
}

