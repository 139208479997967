/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelEdiDeclineCodes.ts               *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/datafusion/edi-decline-codes";

export class AutogenModelEdiDeclineCodes<RowType extends ModelRow> extends Endpoint<RowType, RowEdiDeclineCodesFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowEdiDeclineCodes extends ModelRow<RowEdiDeclineCodesFields> {
    constructor(values?: Partial<RowEdiDeclineCodesFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowEdiDeclineCodesFields {
    company_id: string;
    description: string;
    element_id: string;
    standard_code: string;
    version: string;
}

