export const props = { viewBox: "0 0 19 24" };
export const innerHTML =
    '<path d="M11.4035 15.5219V13.3289C11.4035 13.1254 11.3226 12.9302 11.1787 12.7862C11.0348 12.6423 10.8395 12.5614 10.636 12.5614C10.4324 12.5614 10.2372 12.6423 10.0932 12.7862C9.94929 12.9302 9.86842 13.1254 9.86842 13.3289V16.2018V16.2697L9.76096 16.3333C9.19298 16.6667 9.00438 16.6667 8.80702 16.6667C8.60965 16.6667 8.06798 16.3838 7.75219 16.1294L7.41009 15.9101C6.97892 15.6527 6.48195 15.527 5.98026 15.5482C5.65553 15.5482 5.33259 15.5962 5.02193 15.6908V15.3882V14.0724C5.02193 13.8688 4.94106 13.6736 4.79712 13.5296C4.65318 13.3857 4.45795 13.3048 4.25438 13.3048C4.05082 13.3048 3.85559 13.3857 3.71165 13.5296C3.56771 13.6736 3.48684 13.8688 3.48684 14.0724V16.4825C3.28502 16.6078 3.05112 16.6718 2.81359 16.6667C2.61003 16.6667 2.4148 16.7475 2.27086 16.8915C2.12692 17.0354 2.04605 17.2306 2.04605 17.4342C2.04605 17.6378 2.12692 17.833 2.27086 17.9769C2.4148 18.1209 2.61003 18.2018 2.81359 18.2018C3.33333 18.2018 3.78289 18.1096 4.56798 17.6382L5.07675 17.3246L5.05921 17.2917L5.08114 17.3246L5.12061 17.2982C5.38277 17.1505 5.67938 17.0749 5.98026 17.0789C6.18271 17.0618 6.38597 17.1005 6.56798 17.1908L6.81798 17.3553C7.05921 17.5439 7.96053 18.2018 8.80702 18.2018C9.32456 18.2018 9.77631 18.1096 10.5482 17.6382L11.0636 17.3246L11.0439 17.2917L11.0658 17.3246L11.1053 17.2982C11.3676 17.1509 11.6641 17.0753 11.9649 17.0789C12.1654 17.0625 12.3667 17.0996 12.5482 17.1864L13.1776 17.6075L13.2149 17.6338L13.2697 17.5504L13.2193 17.6338L13.7325 17.9452C14.5066 18.4167 14.9561 18.511 15.4759 18.511C15.6794 18.511 15.8747 18.4301 16.0186 18.2862C16.1626 18.1422 16.2434 17.947 16.2434 17.7434C16.2434 17.5399 16.1626 17.3446 16.0186 17.2007C15.8747 17.0567 15.6794 16.9759 15.4759 16.9759C15.2763 16.9759 15.0877 16.9759 14.5329 16.636L14.0943 16.3684L14.0351 16.3268L13.3947 15.8882C12.9637 15.6306 12.4666 15.5048 11.9649 15.5263C11.773 15.5265 11.5815 15.5434 11.3925 15.5768L11.4035 15.5219Z"/>' +
    '<path d="M15.4759 24C14.9561 24 14.5066 23.9079 13.7325 23.4364L13.2193 23.1228L13.25 23.0702L13.2149 23.1228L13.1776 23.0987L12.5482 22.6776C12.3671 22.5898 12.1655 22.5526 11.9649 22.5702C11.664 22.5657 11.3673 22.6414 11.1053 22.7895L11.0658 22.8136L11.0307 22.7632L11.0614 22.8158L10.5482 23.1272C9.77632 23.5987 9.32456 23.6908 8.80702 23.6908C7.96053 23.6908 7.05263 23.0329 6.81798 22.8377L6.57895 22.6798C6.39666 22.5905 6.19346 22.5526 5.99123 22.5702C5.69029 22.5657 5.39358 22.6414 5.13158 22.7895L5.09211 22.8136L5.05702 22.7632L5.08772 22.8158L4.57456 23.1272C3.80044 23.5987 3.35088 23.6908 2.83114 23.6908C1.69079 23.6908 0.905702 22.8136 0.719298 22.5724L0 21.8531V20.7851V2.36404C0.00231918 1.73663 0.253033 1.13568 0.697296 0.692648C1.14156 0.249619 1.7432 0.000575576 2.37061 0L15.8509 0C16.4794 0.000580645 17.0821 0.250528 17.5265 0.694978C17.971 1.13943 18.2209 1.74207 18.2215 2.37061V22.1491L17.6075 22.8706C17.4561 23.0504 16.6667 24 15.4759 24ZM14.0351 21.8246L14.0943 21.8575L14.5329 22.125C15.0877 22.4649 15.2763 22.4649 15.4759 22.4649C15.8816 22.4649 16.2785 22.0658 16.3969 21.9123L16.4232 21.8794L16.6864 21.5702V2.37061C16.6858 2.1492 16.5976 1.93701 16.441 1.78045C16.2845 1.62388 16.0723 1.53567 15.8509 1.53509H2.37061C2.1492 1.53567 1.93701 1.62388 1.78045 1.78045C1.62388 1.93701 1.53567 2.1492 1.53509 2.37061V21.2303L1.90132 21.6162C2.01535 21.7654 2.41447 22.1645 2.81798 22.1645C3.01754 22.1645 3.20614 22.1645 3.76096 21.8268L4.19956 21.5592C4.21729 21.5462 4.23641 21.5351 4.25658 21.5263C4.77128 21.2026 5.36786 21.0329 5.97588 21.0373C6.47756 21.016 6.97453 21.1418 7.4057 21.3991L7.74781 21.6294C8.0636 21.8816 8.57456 22.1645 8.80263 22.1645C9.0307 22.1645 9.1886 22.1645 9.74342 21.8268L10.182 21.5592C10.2 21.5466 10.2191 21.5356 10.239 21.5263C10.7539 21.2029 11.3504 21.0333 11.9583 21.0373C12.4601 21.0155 12.9572 21.1413 13.3882 21.3991L14.0351 21.8246Z"/>' +
    '<path d="M4.16665 11.035C3.96309 11.035 3.76786 10.9542 3.62392 10.8102C3.47997 10.6663 3.39911 10.4711 3.39911 10.2675V3.87277C3.39911 3.6692 3.47997 3.47398 3.62392 3.33003C3.76786 3.18609 3.96309 3.10522 4.16665 3.10522C4.37022 3.10522 4.56545 3.18609 4.70939 3.33003C4.85333 3.47398 4.9342 3.6692 4.9342 3.87277V10.2675C4.9342 10.4711 4.85333 10.6663 4.70939 10.8102C4.56545 10.9542 4.37022 11.035 4.16665 11.035Z"/>' +
    '<path d="M7.375 6.14039C7.17143 6.14039 6.9762 6.05952 6.83226 5.91558C6.68832 5.77164 6.60745 5.57641 6.60745 5.37285V2.78732C6.60745 2.58375 6.68832 2.38853 6.83226 2.24458C6.9762 2.10064 7.17143 2.01978 7.375 2.01978C7.57856 2.01978 7.77379 2.10064 7.91773 2.24458C8.06167 2.38853 8.14254 2.58375 8.14254 2.78732V5.36188C8.14399 5.46359 8.12521 5.56458 8.08729 5.65898C8.04937 5.75337 7.99306 5.83928 7.92165 5.91172C7.85023 5.98416 7.76512 6.04168 7.67128 6.08093C7.57743 6.12019 7.47672 6.1404 7.375 6.14039Z"/>' +
    '<path d="M7.29385 14.2939C7.09028 14.2939 6.89506 14.213 6.75111 14.0691C6.60717 13.9251 6.52631 13.7299 6.52631 13.5263V7.89474C6.52631 7.69118 6.60717 7.49595 6.75111 7.35201C6.89506 7.20806 7.09028 7.1272 7.29385 7.1272C7.49741 7.1272 7.69264 7.20806 7.83659 7.35201C7.98053 7.49595 8.06139 7.69118 8.06139 7.89474V13.5263C8.06139 13.7299 7.98053 13.9251 7.83659 14.0691C7.69264 14.213 7.49741 14.2939 7.29385 14.2939Z"/>' +
    '<path d="M10.5417 11.035C10.3381 11.035 10.1429 10.9542 9.99892 10.8102C9.85498 10.6663 9.77411 10.4711 9.77411 10.2675V3.87277C9.77411 3.6692 9.85498 3.47398 9.99892 3.33003C10.1429 3.18609 10.3381 3.10522 10.5417 3.10522C10.7452 3.10522 10.9404 3.18609 11.0844 3.33003C11.2283 3.47398 11.3092 3.6692 11.3092 3.87277V10.2675C11.3092 10.4711 11.2283 10.6663 11.0844 10.8102C10.9404 10.9542 10.7452 11.035 10.5417 11.035Z"/>' +
    '<path d="M13.2829 6.14039C13.0793 6.14039 12.8841 6.05952 12.7402 5.91558C12.5962 5.77164 12.5154 5.57641 12.5154 5.37285V2.78732C12.5154 2.58375 12.5962 2.38853 12.7402 2.24458C12.8841 2.10064 13.0793 2.01978 13.2829 2.01978C13.4865 2.01978 13.6817 2.10064 13.8256 2.24458C13.9696 2.38853 14.0504 2.58375 14.0504 2.78732V5.36188C14.0519 5.46359 14.0331 5.56458 13.9952 5.65898C13.9573 5.75337 13.901 5.83928 13.8295 5.91172C13.7581 5.98416 13.673 6.04168 13.5792 6.08093C13.4853 6.12019 13.3846 6.1404 13.2829 6.14039Z"/>' +
    '<path d="M14.1689 14.2939C13.9653 14.2939 13.7701 14.213 13.6261 14.0691C13.4822 13.9251 13.4013 13.7299 13.4013 13.5263V7.89474C13.4013 7.69118 13.4822 7.49595 13.6261 7.35201C13.7701 7.20806 13.9653 7.1272 14.1689 7.1272C14.3724 7.1272 14.5676 7.20806 14.7116 7.35201C14.8555 7.49595 14.9364 7.69118 14.9364 7.89474V13.5263C14.9364 13.7299 14.8555 13.9251 14.7116 14.0691C14.5676 14.213 14.3724 14.2939 14.1689 14.2939Z"/>' +
    '<path d="M15.4759 21.2522C14.9562 21.2522 14.5066 21.1601 13.7325 20.6886L13.2193 20.375L13.2697 20.2917L13.2149 20.375L13.1776 20.3487L12.5483 19.9299C12.3674 19.841 12.1657 19.8031 11.9649 19.8202C11.6639 19.815 11.367 19.8907 11.1053 20.0395L11.0658 20.0636L11.0395 20.0264L11.0614 20.0658L10.5483 20.3948C9.77633 20.8663 9.32457 20.9584 8.80703 20.9584C7.96054 20.9584 7.05264 20.3005 6.81799 20.1053L6.57896 19.9321C6.39695 19.8418 6.19368 19.8031 5.99124 19.8202C5.69011 19.8141 5.39298 19.8899 5.13159 20.0395L5.09212 20.0636L5.0658 20.0264L5.08773 20.0658L4.56361 20.3948C3.78948 20.8663 3.33992 20.9584 2.82019 20.9584C2.61662 20.9584 2.42139 20.8775 2.27745 20.7336C2.13351 20.5896 2.05264 20.3944 2.05264 20.1908C2.05264 19.9873 2.13351 19.792 2.27745 19.6481C2.42139 19.5042 2.61662 19.4233 2.82019 19.4233C3.01975 19.4233 3.20834 19.4233 3.76317 19.0834L4.20177 18.8158L4.25659 18.7851C4.77179 18.4605 5.36913 18.29 5.97808 18.2939C6.47895 18.2678 6.97656 18.3882 7.4101 18.6404L7.7522 18.8597C8.06799 19.1141 8.57896 19.397 8.80703 19.397C9.0351 19.397 9.19299 19.397 9.74782 19.0571L10.1864 18.7895L10.2434 18.7588C10.7576 18.4337 11.3544 18.2631 11.9627 18.2676C12.4663 18.2492 12.9641 18.3788 13.3947 18.6404L14.0351 19.079L14.0943 19.1119L14.5329 19.3794C15.0877 19.7193 15.2763 19.7193 15.4759 19.7193C15.6795 19.7193 15.8747 19.8002 16.0186 19.9442C16.1626 20.0881 16.2434 20.2833 16.2434 20.4869C16.2434 20.6905 16.1626 20.8857 16.0186 21.0296C15.8747 21.1736 15.6795 21.2544 15.4759 21.2544V21.2522Z"/>'
