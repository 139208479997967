import { Alignment } from "@mcleod/core";
import { ImageName } from "@mcleod/images";
import { StringOrPropsOrComponent } from "../..";
import { ComponentPropDefinitionUtil, ComponentPropDefinitions, ComponentProps } from "../../base/ComponentProps";
import { PropType } from "../../base/PropType";
import { DropdownProps } from "../../page/Overlay";
import { OverlayProps } from "../../page/OverlayProps";
import { ImagePropDefinitions, ImageProps } from "../image/ImageProps";
import { ListProps } from "../list/ListProps";
import { ButtonVariant } from "./ButtonVariant";

export interface ButtonProps extends ComponentProps {
    busy: boolean;
    busyWhenDataSourceBusy: boolean;
    cancel: boolean;
    caption: string;
    default: boolean;
    dropdownItems: StringOrPropsOrComponent[] | (() => StringOrPropsOrComponent[]) | (() => Promise<StringOrPropsOrComponent[]>);
    dropdownProps: Partial<DropdownProps>;
    dropdownListProps: Partial<ListProps>;
    dropdownOverlayProps: Partial<OverlayProps>;
    focusable: boolean;
    hotkey: string;
    imageAlign: Alignment;
    imageName: ImageName;
    imageHeight: number;
    imageProps: Partial<ImageProps>,
    imageWidth: number;
    imageRotation: number;
    link: string;
    size: string;
    variant: ButtonVariant;
    wrap: boolean;
}

let buttonProps: ComponentPropDefinitions;

export class ButtonPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (buttonProps == null) {
            buttonProps = {
                ...ComponentPropDefinitionUtil.getComponentPropertyDefinitions(),
                ...ImagePropDefinitions.getContainedImagePropDefs(),
                busy: { type: PropType.bool, defaultValue: false, visibleInDesigner: false, description: "This toggles whether or not the button displays the spinner animation." },
                busyWhenDataSourceBusy: { type: PropType.bool, category: "Data", defaultValue: false, visibleInMcLeodTailor: true, description: "This toggles whether or not the button will automatically be set to busy when its bound datasource is busy." },
                cancel: { type: PropType.bool, visibleInMcLeodTailor: true, description: "This toggles whether or not this button is considered the 'cancel' button.  The cancel button's onClick event will be fired when the user presses the Escape key." },
                caption: { type: PropType.string, visibleInMcLeodTailor: true, description: "This specifies the text that appears in the button." },
                default: { type: PropType.bool, visibleInMcLeodTailor: true, description: "This toggles whether or not this button is considered the 'default' button.  The default button's onClick event will be fired when the user presses the Enter key." },
                focusable: { type: PropType.bool, visibleInMcLeodTailor: true, category: "Image", defaultValue: true, description: "This toggles whether or not this Button can receive focus." },
                hotkey: { type: PropType.string, visibleInMcLeodTailor: true, description: "This specifies a key combination that can be pressed to trigger this buttons onClick event." },
                imageAlign: { type: PropType.string, items: [Alignment.RIGHT, Alignment.LEFT, Alignment.TOP, Alignment.BOTTOM], defaultValue: Alignment.LEFT, visibleInMcLeodTailor: true, description: "This controls where the Button's image (if present) will appear relative to the text." },
                link: { type: PropType.string, visibleInMcLeodTailor: true, description: "This specifies a page that will be opened when the button is clicked.  If the Button's dataSource is set, this link can specify a data format string to include data from the dataSource's activeRow." },
                size: { type: PropType.string, items: ["auto", "auto-size-up", "small", "medium", "large"], defaultValue: "auto", visibleInMcLeodTailor: true, description: "This specifies the width and font size of the button." },
                variant: { type: PropType.string, items: ["round", "text"], visibleInMcLeodTailor: true, description: "This is a shortcut to set several properties at once.  The only allowed values currently are null, 'round', and 'text'  If set to round, no border is painted and the borderRadius is set to 50%.  Usually this is only done if a Button has an image but no text." },
            };
            buttonProps.cursor.defaultValue = "pointer";
            ComponentPropDefinitionUtil.populateComponentPropNames(buttonProps);
        }
        return buttonProps;
    }
}


