/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelCarrierDriverMessage.ts          *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/powerbroker/carrier-driver-message";

export class AutogenModelCarrierDriverMessage<RowType extends ModelRow> extends Endpoint<RowType, RowCarrierDriverMessageFields> {
	constructor(ctor: RowConstructor) {
		super(endpointPath, ctor);
	}
}

export class AutogenRowCarrierDriverMessage extends ModelRow<RowCarrierDriverMessageFields> {
	constructor(values?: Partial<RowCarrierDriverMessageFields>) {
		super(endpointPath, true, values);
	}
}

export interface RowCarrierDriverMessageFields {
	asset_driver_id: string;
	"carrier_driver.carrier_id": string;
	"carrier_driver.cell_phone_number": string;
	"carrier_driver.company_id": string;
	"carrier_driver.driver_name": string;
	"carrier_driver.email_address": string;
	"carrier_driver.id": number;
	"carrier_driver.is_active": string;
	"carrier_driver.is_sms_opted_in": string;
	"carrier_driver.notes": string;
	"carrier_driver.opt_out_date": Date;
	"carrier_driver.tractor_number": string;
	carrier_id: string;
	company_id: string;
	direction: string;
	direction_display: string;
	driver_application_id: string;
	driver_id: number;
	error_code: number;
	error_detail: string;
	id: string;
	media_message_url: string;
	message_count: number;
	message_id: string;
	message_read: string;
	message_read_display: string;
	message_text: string;
	movement_id: string;
	number_of_update_attempts: number;
	order_id: string;
	protected: string;
	protected_display: string;
	recipient: string;
	send_date: Date;
	sender: string;
	status: string;
	status_display: string;
	user_id: string;
	"users.acct_lock_date": Date;
	"users.active_date": Date;
	"users.admin_user": string;
	"users.agent": string;
	"users.agree_fieldmarshal": Date;
	"users.agree_tailor": Date;
	"users.agree_tailor_ext": Date;
	"users.all_contacts": string;
	"users.allow_new_devices": string;
	"users.alternative_user": string;
	"users.ap_division_id": string;
	"users.appt_sched_id": string;
	"users.auth_subject": string;
	"users.auto_edit_type": string;
	"users.avail_tract_path": string;
	"users.avail_tract_sample": string;
	"users.available": string;
	"users.bridge_customer_access": string;
	"users.brokerage_divider": number;
	"users.brokerage_planning": string;
	"users.brokerage_planning2": string;
	"users.brokerage_planning3": string;
	"users.brokerage_planning4": string;
	"users.brokerage_planning_contnr": string;
	"users.call_list_profile": string;
	"users.carrier_call_list_id": string;
	"users.carrier_mgr_profil": string;
	"users.carrier_mgr_profil2": string;
	"users.carrier_mgr_profil3": string;
	"users.carrier_mgr_profil4": string;
	"users.cell_phone": string;
	"users.close_window": string;
	"users.commission_payee_id": string;
	"users.company_id": string;
	"users.company_name": string;
	"users.conf_cntr_template": number;
	"users.conf_template": number;
	"users.confirm_record": string;
	"users.contact_id": string;
	"users.continuous_radius": number;
	"users.crm_vs_profile": string;
	"users.csm_profile_id": string;
	"users.csm_profile_id2": string;
	"users.csm_profile_id3": string;
	"users.csm_profile_id4": string;
	"users.currency_reporting": string;
	"users.currency_transaction": string;
	"users.daily_advance_limits": string;
	"users.daily_brok_profile": string;
	"users.daily_snap_profile": string;
	"users.date_format": string;
	"users.default_search": number;
	"users.default_wirecode": string;
	"users.delivered_load_day": number;
	"users.department_id": string;
	"users.detention_profile_id": string;
	"users.detention_profile_id2": string;
	"users.detention_profile_id3": string;
	"users.detention_profile_id4": string;
	"users.disp_new_order_screen": string;
	"users.distance_calc_vendor": string;
	"users.dock_enable_wfmd_mode_swtch": string;
	"users.dock_login_id": string;
	"users.dragndrop_profile_id": string;
	"users.driver_manager_profile": string;
	"users.driver_manager_profile2": string;
	"users.driver_manager_profile3": string;
	"users.driver_manager_profile4": string;
	"users.driver_manager_vendor": string;
	"users.driver_managers": string;
	"users.drivers_wo_manager": string;
	"users.ds_cap_profile": string;
	"users.ds_mv_eta_profile": string;
	"users.ds_mv_eval_profile": string;
	"users.dsp_override_user": string;
	"users.email_address": string;
	"users.email_bcc": string;
	"users.email_client": string;
	"users.email_host": string;
	"users.email_imap_host": string;
	"users.email_imap_password": string;
	"users.email_imap_sent_folder": string;
	"users.email_imap_ssl": string;
	"users.email_imap_username": string;
	"users.email_override_company": string;
	"users.email_password": string;
	"users.email_port": number;
	"users.email_protocol": string;
	"users.email_use_sent_folder": string;
	"users.email_username": string;
	"users.employee_type": string;
	"users.enable_alerts": string;
	"users.extension": string;
	"users.external_password": string;
	"users.external_user_id": string;
	"users.failed_logins": number;
	"users.fax": string;
	"users.fileimport_profile": string;
	"users.findnear_vendor": string;
	"users.fleetmgmt_prof_id": string;
	"users.fm_account_id": string;
	"users.fm_can_view_metadata": string;
	"users.fm_profile_id": string;
	"users.fm_webhook_url": string;
	"users.google_map_layer": string;
	"users.google_map_type": string;
	"users.grid_even_row_color": number;
	"users.grid_insert_sort": string;
	"users.grid_odd_row_color": number;
	"users.id": string;
	"users.img_category": string;
	"users.inactive_date": Date;
	"users.is_active": string;
	"users.is_clearer": string;
	"users.is_closer": string;
	"users.is_dock_supervisor": string;
	"users.is_dock_worker": string;
	"users.is_email_confirmed": string;
	"users.is_mcleod": string;
	"users.issue_atm_cash": string;
	"users.lock_status": string;
	"users.log_sql": string;
	"users.login_id": string;
	"users.ltl_carrier_brk_status": string;
	"users.ltl_mapping_vendor": string;
	"users.ltl_quote_template": number;
	"users.manifest_profile": string;
	"users.margin_percentage": number;
	"users.max_payment_code_amt": number;
	"users.max_payment_code_amt_c": string;
	"users.max_payment_code_amt_d": Date;
	"users.max_payment_code_amt_n": number;
	"users.max_payment_code_amt_r": number;
	"users.mc_position_vendor": string;
	"users.menu_tree_view": string;
	"users.mfa_device_id": string;
	"users.mfa_last_prompted": Date;
	"users.mfa_secret": string;
	"users.mfa_status": string;
	"users.mobcomm_profile_id": string;
	"users.mobile_pw_reset_token": string;
	"users.mobile_pw_token_expire_ms": number;
	"users.move_dates": string;
	"users.movement_map_vendor": string;
	"users.name": string;
	"users.notification": string;
	"users.notify_alerts": string;
	"users.notify_frequency": string;
	"users.notify_method": string;
	"users.notify_occurance": string;
	"users.operations_user_id": string;
	"users.order_mode": string;
	"users.order_planning2": string;
	"users.order_planning3": string;
	"users.order_planning4": string;
	"users.order_planning_vendor": string;
	"users.order_tran_request": string;
	"users.orderhist_profile": string;
	"users.outbound_notification": string;
	"users.override_vendor_division": string;
	"users.payee_id": string;
	"users.pbw_brokerage_planning": string;
	"users.phone": string;
	"users.planner": string;
	"users.planning": string;
	"users.planning_container": string;
	"users.planning_divider": number;
	"users.primary_agency": string;
	"users.prompt_cont_moves": string;
	"users.quote_comp_act_create": string;
	"users.quote_comp_act_id": string;
	"users.quote_pend_act_create": string;
	"users.quote_pend_act_id": string;
	"users.quote_template": number;
	"users.rapid_alert_expand": string;
	"users.rapid_alert_sound": string;
	"users.receive_alerts": string;
	"users.recruiting_profile": string;
	"users.revenue_code_id": string;
	"users.route_detail_vendor": string;
	"users.save_screen_sizes": string;
	"users.search_history_limit": number;
	"users.sec_ans1": string;
	"users.sec_ans2": string;
	"users.sec_question1": number;
	"users.sec_question2": number;
	"users.seg_alloc_code": string;
	"users.sel_text_on_focus": string;
	"users.show_active": string;
	"users.show_inactive": string;
	"users.single_point_vendor": string;
	"users.support_inactivate_date": Date;
	"users.system_tray": string;
	"users.telephony_number": string;
	"users.telephony_password": string;
	"users.terminal_id": string;
	"users.time_format": string;
	"users.token_key": number;
	"users.toolbar_multi_rows": string;
	"users.toolbar_setting": string;
	"users.trailer_loan_profile_id": string;
	"users.trailer_wash_loc": string;
	"users.trailer_wash_user": string;
	"users.trimble_map_traffic": string;
	"users.tw_seg_code": string;
	"users.tw_ticket_template": number;
	"users.type_agents": string;
	"users.type_carrier": string;
	"users.type_company_drs": string;
	"users.type_number": string;
	"users.type_owner_oper": string;
	"users.user_group": string;
	"users.vendor_user_id": string;
	"users.view_movement_vendor": string;
	"users.waterfall_default_rt_guide": string;
	"users.waterfall_include_rates": string;
	"users.waterfall_include_rt_guides": string;
	"users.waterfall_prevent_tendering": string;
	"users.web_acct_status": string;
	"users.web_agent": string;
	"users.web_created_on": Date;
	"users.web_enabled_on": Date;
	"users.web_last_login": Date;
	"users.web_locked_on": Date;
	"users.web_map": string;
	"users.web_password": string;
	"users.web_user_id": string;
	"users.web_user_type": string;
	"users.window_location": string;
	"users.windows_login": string;
	"users.wire_trans_per_day": number;
	"users.zone_mapping_vendor": string;
	vendor_id: string;
	vendor_id_display: string;
}

