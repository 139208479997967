/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelRevenueDetail.ts                 *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/dispatch/revenue-detail";

export class AutogenModelRevenueDetail<RowType extends ModelRow> extends Endpoint<RowType, RowRevenueDetailFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowRevenueDetail extends ModelRow<RowRevenueDetailFields> {
    constructor(values?: Partial<RowRevenueDetailFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowRevenueDetailFields {
    charge_cost_as_amount: number;
    description: string;
    fgp_uid: number;
    margin_amount: string;
    markup_amount: string;
    profit_amount: string;
    revenue_amount: number;
    short_description: string;
}

