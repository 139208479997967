import { DataSource, Label, TableAddRowResult, TableRow, TableRowDisplayEvent, TableRowMode, Textbox } from "@mcleod/components";
import { Api, ModelRow, getLogger } from "@mcleod/core";
import { AutogenLayoutResponsibleHist } from "./autogen/AutogenLayoutResponsibleHist";

const log = getLogger("lme.general.ResponsibleHist");

export class ResponsibleHist extends AutogenLayoutResponsibleHist {
    private _levels: Array<string>;
    private _parentHash: Map<string, string>;
    private _hierarchyDescriptions: Map<string, string>;

    override onLoad() {
        const titleLabel = new Label({
            themeKey: "label.sectionHeading",
            id: "labelUsersResponsibilitySlideoutTitle",
            caption: "Responsibility Users",
            rowBreak: true,
            marginTop: 12,
            marginBottom: 8
        });
        this.insert(titleLabel, 0);
        this.tableResponsibleHist.addRowDisplayListener(event => {
            const row: TableRow = (event as TableRowDisplayEvent).getTableRow();
            if (row.mode == TableRowMode.QUICK_ADD)
                this.setDefaultRole(row);
            this.addHierarchyLevelFilters(row);
            if (row.data.get("responsible_role", null) === "E")
                row.canBeDeleted = false;
        })
    }

    public async setup(parentResponsibleHistDataSource: DataSource) {
        this.useProvidedResponsibleHistDataSource(parentResponsibleHistDataSource);
        await this.getResponsibilityConfiguration();
    }

    public useProvidedResponsibleHistDataSource(sourceResponsibleHist: DataSource) {
        this.sourceResponsibleHist = sourceResponsibleHist;
        this.replaceMainDatasource(this.sourceResponsibleHist);
        this.displayData(null, this.mainDataSource.data, null);
    }

    public async getResponsibilityConfiguration(): Promise<void | Array<string>> {
        return Api.search("lme/general/responsibility-configuration").then(result => {
            const data = result?.data[0];
            this.levels = data["responsibility_levels"];
            if (this._levels != null) {
                const parentHash = data["responsibility_parent_hash"];
                this._parentHash = parentHash;
                this._hierarchyDescriptions = data["responsibility_hierarchy_descriptions"];
            }
            return this._levels;
        });
    }

    private addHierarchyLevelFilters(row: TableRow) {
        for (let i = 1; i <= 5; ++i) {
            const textbox: Textbox = row.findComponentById("textboxHierarchyLevel" + i) as Textbox;
            if (textbox != null) {
                if (row.data != null) {
                    const value: string = row.data.get(textbox.field);
                    let descr: string;
                    if (this._hierarchyDescriptions != null)
                        descr = this._hierarchyDescriptions[value];
                    else
                        descr = value;
                    row.data.setLookupModelData(textbox.field, { "id": value, "description": descr });
                    textbox.displayData(row.data, null, 0);
                }
                textbox.addBeforeLookupModelSearchListener(event => {
                    event.filter.hierarchy_level = i;
                });
                if (i == 1) {
                    textbox.onSelectItem = () => {
                        const data: ModelRow = row.data;
                        this.populateOtherLevelValues(textbox, row, data);
                        return undefined;
                    }
                }
            }
        }
    }

    private populateOtherLevelValues(textbox: Textbox, row: TableRow, data: ModelRow<any>) {
        let lastValue = textbox.getFirstLookupModelData().get("id");
        for (let i = 2; i <= this._levels.length; ++i) {
            const otherTextbox: Textbox = row.findComponentById("textboxHierarchyLevel" + i) as Textbox;
            const newValue = this._parentHash[lastValue];
            const newDescription = this._hierarchyDescriptions[newValue];
            data.set(otherTextbox.field, newValue);
            data.setLookupModelData(otherTextbox.field, { "id": newValue, "description": newDescription });
            otherTextbox.displayData(data, null, 0);
            lastValue = newValue;
        }
    }

    private setDefaultRole(row: TableRow): void {
        const role: Textbox = row.findComponentById("textboxResponsibleRole") as Textbox;
        row.data.set("responsible_role", "M");
        const items = role.items;
        for (let i = 0; i < items.length; ++i) {
            if (items[i].value == "M") {
                role.selectedItem = items[i];
                break;
            }
        }
    }

    public set levels(list: string[]) {
        this._levels = list;
        for (let i = this.tableResponsibleHist.columns.length - 1; i > list.length; --i)
            this.tableResponsibleHist.removeColumn(i);
        for (let i = 1; i <= list.length; ++i)
            this.tableResponsibleHist.columns[i].headingCell.caption = list[i - 1];
        this.tableResponsibleHist.columns[0].headingCell.caption = "Role";
    }

    public set parentHash(hash: Map<string, string>) {
        this._parentHash = hash;
    }

    public set hierarchyDescriptions(hash: Map<string, string>) {
        this._hierarchyDescriptions = hash;
    }

    public async addBlankEntryRecordToTable(): Promise<TableRow> {
        if (this.getTableEntryRow() != null)
            return;
        let addRowResult: TableAddRowResult;
        const newRowData = await this.tableResponsibleHist._createNewRowData();
        if (newRowData != null) {
            newRowData.set("responsible_role", "E");
            addRowResult = this.tableResponsibleHist.addRow(newRowData, { mode: TableRowMode.ADD }, { display: true, addToData: true });
        }
        addRowResult?.row.editRow();
        return addRowResult?.row;
    }

    private getTableEntryRow(): TableRow {
        for (const row of this.tableResponsibleHist.rows) {
            if (row.data.get("responsible_role", null) === "E")
                return row;
        }
        return null;
    }
}
