import { CommonDialogs } from "@mcleod/common";
import {
    BlurEvent, ChangeEvent, ClickEvent, DataDisplayEvent, DataSource, DataSourceMode, DropdownItem, Label, Snackbar,
    Table, TableRow, Textbox, ValidationResult
} from "@mcleod/components";
import { TextboxValidator } from "@mcleod/components/src/components/textbox/TextboxValidator";
import {
    Api, CityUtil, DatePart, DateUtil, FieldUpdateEvent, getAuthSettings, getUnauthSettings, Model, ModelRow, ModelRowType,
    PermissionsUtil, StringUtil, Timezone
} from "@mcleod/core";
import { ModelEdilocation } from "../../datafusion/src/models/ModelEdilocation";
import { ModelContact } from "../../general/src/models/ModelContact";
import { getDispatchControlBoolean } from "../../general/src/models/ModelDispatchControl";
import { ModelZones } from "../../general/src/models/ModelZones";
import { checkAppointment, isApptConfirmed, showAppointmentChangeDialog } from "./AppointmentUtil";
import { makeTooltipCallbackFunction } from "./LocationMakeQuickInfo";
import { StopNotesAndReferenceNumbers } from "./StopNotesAndReferenceNumbers";
import { AutogenLayoutOrderStop } from "./autogen/AutogenLayoutOrderStop";
import { ModelLocation, RowLocation } from "./models/ModelLocation";

const DATE_ON = { caption: "On", value: "O" };
const DATE_BETWEEN = { caption: "Between", value: "B" };

export class OrderStop extends AutogenLayoutOrderStop {
    private enforceShipper: boolean = getAuthSettings().dispatch_control[0].enforce_shipper_id == "Y";
    commentsAndRefNbrs: StopNotesAndReferenceNumbers;
    private _validateApptChanges: boolean = false;
    private _loadingRecurringOrder: boolean = false;
    private _invokedFromEDI: boolean = false;
    private _slideOutTableRow: TableRow;
    public calculateRates: () => void = () => { };

    public get validateApptChanges(): boolean {
        return this._validateApptChanges;
    }

    public set validateApptChanges(value: boolean) {
        this._validateApptChanges = value;
    }

    public get loadingRecurringOrder(): boolean {
        return this._loadingRecurringOrder;
    }

    public set loadingRecurringOrder(value: boolean) {
        this._loadingRecurringOrder = value;
    }

    public get invokedFromEDI(): boolean {
        return this._invokedFromEDI;
    }

    public set invokedFromEDI(value: boolean) {
        this._invokedFromEDI = value;
    }

    public get slideOutTableRow(): TableRow {
        return this._slideOutTableRow;
    }

    public set slideOutTableRow(value: TableRow) {
        this._slideOutTableRow = value;
    }

    public get stopDateTypeBetween(): DropdownItem { return DATE_BETWEEN }

    public get stopDateTypeOn(): DropdownItem { return DATE_ON }

    private _orderSource: DataSource;

    override onLoad(): void {
        this.commentsAndRefNbrs = this.layoutStopNotesAndRefNbrs as StopNotesAndReferenceNumbers;
        this.textboxStopDateType.items = [DATE_ON, DATE_BETWEEN];
        this.textboxStopDateType.selectedItem = DATE_BETWEEN;
        this.textboxSchedArriveLate.required = true;
        this.setupListeners();
        this.textboxSchedArriveEarly.addlValidationCallback = () => this.scheduleArriveValidator();
    }

    setupListeners() {
        this.textboxContactName.onSelectItem = ((textbox, selection) => {
            this.mainDataSource.activeRow.set("phone", (selection as ModelRow).get("phone"));
            this.mainDataSource.activeRow.set("contact_email", (selection as ModelRow).get("email"));
            this.mainDataSource.displayDataInBoundComponents()
            return undefined;
        })
        this.textboxLocationId.addBlurListener((event: BlurEvent) => {
        });
        this.textboxSchedArriveEarly.addBlurListener(event => this.stopDateOnBlur(event));
        this.textboxSchedArriveLate.addBlurListener(event => this.stopDateOnBlur(event));
        this.switchPickupConsignee.addChangeListener((event) => { if (event.userInitiatedChange) this.calculateRates() });
    }

    /** This is an event handler for the onBlur event of textboxSchedArriveLate and textboxSchedArriveEarly.  */
    stopDateOnBlur(event: BlurEvent) {
        const textbox = event.target as Textbox;
        if (textbox.isDropdownVisible()) return;
        checkAppointment(event, this)
        textbox._input.value = textbox.text; // this is a workaround for an issue where the input value is not updated after a change as a result of a validation warning
        if (textbox.field === "sched_arrive_late")
            this.textboxSchedArriveEarly.validateSimple(false, true);
    }

    setLocationTextAfterLocation(textBox: Textbox, row: ModelRow, locationIdField?: string) {
        const data = row?.data
        const source = row?.data["source_name"];

        if (locationIdField == null)
            locationIdField = "id";

        if (source == "CITY") {
            textBox.text = CityUtil.formatCityStateZip(data?.["city_name"], data?.["state"], data?.["zip_code"]);
        } else {
            this.textboxLocationCode.text = data?.[locationIdField];
            this.textboxLocationCode.tooltipCallback = makeTooltipCallbackFunction(data?.[locationIdField], this.textboxLocationCode);
            this.textboxLocationName.displayData(this.mainDataSource.activeRow, null, 0);
            this.textboxAddress.displayData(this.mainDataSource.activeRow, null, 0);
            this.textboxAddress2.displayData(this.mainDataSource.activeRow, null, 0);
        }
        this.citystate.displayData(this.mainDataSource.activeRow, null, 0);
    }

    private setApptRequired(rowLocation: ModelRow<any>) {
        this.mainDataSource.activeRow.set("appt_required", rowLocation?.get("appt_required"));
        this.switchApptRequired.displayData(this.mainDataSource.activeRow, null, 0);
    }

    initialize(isSlideIn: boolean, tableRow: TableRow, orderDataSource: DataSource) {
        this.layoutStopShowAsButton.orderSource = orderDataSource;
        this.layoutStopShowAsButton.stopRow = tableRow.data;
        this.panelAdditionalDetails.visible = isSlideIn;
        const stopIndex = tableRow.index;
        const isPickupStop = stopIndex == 0;
        const stopType = tableRow.data.get("stop_type");

        if (stopType === "SD" || stopType === "SP") {
            this.labelPickup.caption = stopType == "SD" ? "Split Drop" : "Split Pickup";
            this.labelPickup.visible = true;
            if (stopType === "SD") {
                this.imageYsplitDrop.visible = true;
            } else if (stopType == "SP") {
                this.imageYSplitPick.visible = true;
            }
            this.switchPickupConsignee.visible = false;
            this.switchPickupConsignee.field = null; // Prevent setting of default value
        }

        this.mainDataSource.setRowsAndMode(orderDataSource.mode, [tableRow.data]);
        this.setTableParentDataSource(this.commentsAndRefNbrs.tableStopComments, orderDataSource);
        this.setTableParentDataSource(this.commentsAndRefNbrs.tableStopReferenceNumbers, orderDataSource);
        this.textboxLocationId.textCombined.manualAddLayout = null;
        if (this.mainDataSource.activeRow != null) {
            this.textboxLocationCode.text = this.mainDataSource.activeRow.get("location_id");
            this.textboxLocationCode.tooltipCallback = makeTooltipCallbackFunction(this.textboxLocationCode.text, this.textboxLocationCode);
        }

        if (isPickupStop) {
            this.labelPickup.visible = true;
            this.switchPickupConsignee.visible = false;
            this.textboxLocationId.visible = true;
            this.textboxLocationId.required = this.enforceShipper;

            this.switchPalletsRequired.visible = true;
            this.textboxPalletsHowMany.visible = "Y" === orderDataSource?.activeRow.get("pallets_required");
            if (isSlideIn) {
                this.slideOutTableRow = tableRow;
                this.switchPalletsRequired.checked = this.textboxPalletsHowMany.visible;
                if (orderDataSource?.activeRow?.get("pallets_how_many") != null)
                    this.textboxPalletsHowMany.text = orderDataSource?.activeRow?.get("pallets_how_many").toString();
            } else {
                this.textboxPalletsHowMany.dataSource = orderDataSource;
                this.switchPalletsRequired.dataSource = orderDataSource;
            }
        }

        this.textboxLocationId.googlePlacesProps = {
            enableSearch: getDispatchControlBoolean("enable_google_places"),
            createLocations: getDispatchControlBoolean("create_location_from_stop"),
            customerId: "",
            doBeforeGoogleLocationCreated: () => this.labelCreatingLocation.visible = true,
            doAfterGoogleLocationCreated: () => this.labelCreatingLocation.visible = false
        };

        if (!orderDataSource?.activeRow?.isNull("customer_id"))
            this.textboxLocationId.googlePlacesProps.customerId = orderDataSource?.activeRow?.get("customer_id");

        this.commentsAndRefNbrs.stopRow = this.mainDataSource.activeRow;
        this.addFieldUpdateListener(this.mainDataSource.activeRow);

        if (!this.mainDataSource.activeRow._appending) {
            const selectedItem = this.mainDataSource.activeRow?.get("sched_arrive_late") != null ? DATE_BETWEEN : DATE_ON;
            this.textboxStopDateType.selectedItem = selectedItem;

            if (PermissionsUtil.isUserDeniedAction("Dispatch.Unconfirm appointments") && this.activeRow.getBoolean("confirmed", false)) {
                this.switchConfirmed.enabled = false;
                this.switchConfirmed.disabledTooltip = "You do not have permission to unconfirm appointments";
            }
        }
        const updatingOrder = DataSourceMode.UPDATE === orderDataSource?.mode;
        if (updatingOrder && this.mainDataSource.activeRow?.get("sched_arrive_late") == null)
            this.textboxStopDateType.selectedItem = this.stopDateTypeOn;
        this.validateApptChanges = updatingOrder && this.mainDataSource.activeRow._appending == false;
        this.orderSource = orderDataSource;
    }

    addFieldUpdateListener(row: ModelRow<any>) {
        if (row["_stopListener"] == null) { // keep a reference in each row to the listener to prevent adding multiple listeners if the same row is used somehow
            row["_stopListener"] = (event: FieldUpdateEvent) => {
                if (event.oldValue != event.newValue) {
                    if (event.fieldName === "location_id") {
                        this.doAfterLocationIdChanged();
                    } else if (event.fieldName === "city_id") {
                        this.calculateRates();
                        if (event.newValue == null) {
                            row.setLookupModelData("zone_id", null);
                            row.set("zone_id", null);
                            this.textboxZoneId.displayData(row, null, 0);
                        } else {
                            new ModelZones().searchSingle({ city_id: event.newValue }).then(response => {
                                if (response != null) {
                                    const lmModelRow = new ModelRow(this.textboxZoneId.lookupModel, false, {
                                        id: response.get("id"),
                                        descr: response.get("descr")
                                    });
                                    lmModelRow.type = ModelRowType.LOOKUP_MODEL_DATA;
                                    row.setLookupModelData("zone_id", lmModelRow);
                                    row.set("zone_id", response.get("id"));
                                    this.textboxZoneId.displayData(row, null, 0);
                                }
                            });
                        }
                    }
                    if (event.fieldName == "sched_arrive_late") {
                        if (event.newValue != null && event.oldValue == null)
                            this.textboxStopDateType.selectedItem = this.stopDateTypeBetween;
                        else if (event.newValue == null && event.oldValue != null)
                            this.textboxStopDateType.selectedItem = this.stopDateTypeOn;
                    }
                }
            }
            row.addAfterFieldUpdateListener(row["_stopListener"]);
        }
    }

    doAfterLocationIdChanged() {
        this.setFreeFormVisibility();
        const location_id = this.mainDataSource.activeRow.get("location_id");
        if (!this.loadingRecurringOrder) this.setStopContactInfo();
        if (location_id) {
            new ModelLocation().searchSingle({
                id: location_id,
                "updating_stop": true
            }).then(rowLocation => {
                if (rowLocation == null) {
                    Snackbar.showWarningSnackbar("Location not found in database.");
                    return;
                }
                if (this.loadingRecurringOrder && this.orderSource?.activeRow?.isNull("recurring_order_id"))
                    this.setApptRequired(rowLocation);
                this.textboxLocationId.textCombined.tooltipCallback = makeTooltipCallbackFunction(location_id, this.textboxLocationId.textCombined);

                // only apply the below logic for the first pickup
                const tableRow: TableRow = TableRow.getContainingTableRow(this) ?? this.slideOutTableRow;
                if (tableRow.index != 0 || !getUnauthSettings()?.company_settings["is_brokerage_ltl"] || this.invokedFromEDI) return;

                const defaultStopDateType: string = this.activeRow.get("stop_type") === "PU" ? rowLocation.get("pickup_date_option") : rowLocation.get("delivery_date_option");

                // If the location does not have any default option (On/Between) set, we want to use the user's choice
                if (defaultStopDateType == undefined) {
                    this.textboxStopDateType.selectedItem = this.activeRow.get("sched_arrive_late") != null ? DATE_BETWEEN : DATE_ON;
                } else {
                    this.textboxStopDateType.selectedItem = defaultStopDateType === DATE_ON.value ? DATE_ON : DATE_BETWEEN;
                }

                if (rowLocation.get("close_time") != null && this.textboxStopDateType.selectedItem === DATE_BETWEEN) {
                    this.activeRow.set("sched_arrive_late", rowLocation.get("close_time"));
                    this.textboxSchedArriveLate.updateBoundData(this.mainDataSource.activeRow, this.mainDataSource.mode);
                } else {
                    this.activeRow.set("sched_arrive_late", null);
                }

                if (rowLocation.get("open_time") != null) {
                    this.activeRow.set("sched_arrive_early", rowLocation.get("open_time"));
                    this.textboxSchedArriveEarly.updateBoundData(this.activeRow, this.mainDataSource.mode);
                }
            });
        }
    }

    initShipperStop(value: boolean) {
        if (value) {
            this.labelPickup.visible = true;
            this.switchPickupConsignee.visible = false;
            this.textboxLocationId.visible = true;

            this.textboxLocationId.textCombined.manualAddLayout = null;
        }
    }

    setTableParentDataSource(table: Table, parentSource: DataSource) {
        if (parentSource != null) {
            table.dataSource.parentDataSource = parentSource;
            const mode = parentSource.mode;
            //if we are setting the mode to ADD, we don't need the blank/empty ModelRow that setting the mode normally will create
            //the fact that the creation of the blank row is async can only mess us up; it can cause existing rows to be wiped out
            //when the blank row is done creating (which causes the table's data to be redisplayed)
            if (mode !== DataSourceMode.ADD)
                table.dataSource.mode = mode;
            else
                table.dataSource.setRowsAndMode(mode, []);
        }
    }

    switchPickupConsigneeOnChange(event) {
        this.setStopDateCaptions(this.switchPickupConsignee.checked);
        const location_id = this.mainDataSource.activeRow?.get("location_id")
        if (location_id) {
            Model.searchSingleRecord("lme/dispatch/location", {
                id: location_id,
                "updating_stop": true
            }).then(rowLocation => {
                if (rowLocation != null)
                    this.setDriverUnloadTextAfterLookup(new ModelRow(this.textboxDriverLoadUnload.lookupModel, false, this.getDriverLoadUnloadData(rowLocation)));
            });
        }
    }

    setStopDateCaptions(isDelivery: boolean) {
        const caption = isDelivery ? "Delivery" : "Pickup";
        const isDateWindow = this.textboxStopDateType.selectedItem == DATE_BETWEEN;

        this.textboxStopDateType.caption = caption;
        this.textboxSchedArriveEarly.caption = isDateWindow ? `${caption} Window` : `${caption} Appointment`;
    }

    getDriverLoadUnloadData(rowLocation: RowLocation): any {
        const loadUnloadField = this.isDelivery() ? "driver_unload_id" : "driver_load_id";
        let loadUnloadModelRowData = rowLocation.getFirstLookupModelData(loadUnloadField);
        if (loadUnloadModelRowData == null)
            loadUnloadModelRowData = { descr: 'No driver loading or unload', id: 'N' };
        else if (loadUnloadModelRowData instanceof ModelRow)
            loadUnloadModelRowData = loadUnloadModelRowData.data;
        return loadUnloadModelRowData;
    }

    setDriverUnloadTextAfterLookup(driverUnloadLookupData: ModelRow) {
        this.mainDataSource.activeRow.setLookupModelData("driver_load_unload", driverUnloadLookupData);
        this.mainDataSource.activeRow.set("driver_load_unload", driverUnloadLookupData.get("id"));
        if (this.mainDataSource.activeRow._appending)
            this.textboxDriverLoadUnload.displayData(this.mainDataSource.activeRow, null, 0);
    }

    switchApptRequiredOnChange(event) {
        this.switchConfirmed.visible = this.switchApptRequired.checked;
    }

    switchPalletsRequiredOnChange(event) {
        this.textboxPalletsHowMany.visible = this.switchPalletsRequired.visible && this.switchPalletsRequired.checked;
    }

    isDelivery(): boolean {
        return this.switchPickupConsignee.visible && this.switchPickupConsignee.checked;
    }

    getData(): ModelRow<any> {
        return TableRow.getContainingTableRow(this)?.data;
    }

    /** This is an event handler for the onChange event of textboxStopDateType.  */
    textboxStopDateTypeOnChange(event: ChangeEvent) {
        const between = event.newValue === DATE_BETWEEN.caption;
        this.panelLateArrival.visible = between;
        this.textboxSchedArriveLate.required = false;
        if (between) {
            this.textboxSchedArriveLate["_captionLabel"].preventCollapse = true;
            this.textboxSchedArriveLate["_captionLabel"].visible = false;
            this.textboxSchedArriveLate.required = true;
            this.textboxSchedArriveLate.placeholder = "Required";
        } else if (event.userInitiatedChange) {
            this.mainDataSource.activeRow?.set("sched_arrive_late", null);
            this.textboxSchedArriveEarly.validateSimple(false, true);
        }
        this.setStopDateCaptions(this.isDelivery());
        if (this.mainDataSource.activeRow != null) {
            this.mainDataSource.activeRow["stop_date_type"] = this.textboxStopDateType.selectedItem;
        }
    }

    private setStopContactInfo() {
        const location_id = this.mainDataSource.activeRow.get("location_id")
        if (!location_id) {
            if (!this.invokedFromEDI) {
                this.mainDataSource.activeRow.setLookupModelData("contact_name", null);
                this.mainDataSource.activeRow.set({ "contact_name": null, "phone": null, "contact_email": null });
                this.textboxContactName.displayData(this.mainDataSource.activeRow, null, 0);
                this.textboxPhone.displayData(this.mainDataSource.activeRow, null, 0)
                this.textboxContactEmail.displayData(this.mainDataSource.activeRow, null, 0)
            }
        } else {
            new ModelContact().searchSingle({ "parent_row_type": "L", "parent_row_id": location_id }).then(result => {

                const contactData = result?.data;
                if (!contactData)
                    return;

                this.mainDataSource.activeRow.setLookupModelData("contact_name", contactData);

                if (contactData["name"]) {
                    this.mainDataSource.activeRow.set("contact_name", contactData["name"]);
                    this.textboxContactName.validate(false, false);
                    this.textboxContactName.displayData(this.mainDataSource.activeRow, null, null);
                }

                if (contactData["phone"]) {
                    this.mainDataSource.activeRow.set("phone", contactData["phone"]);
                    this.textboxPhone.validate(false, false);
                    this.textboxPhone.displayData(this.mainDataSource.activeRow, null, null);
                }

                if (contactData["email"]) {
                    this.mainDataSource.activeRow.set("contact_email", contactData["email"]);
                    this.textboxContactEmail.validate(false, false);
                    this.textboxContactEmail.displayData(this.mainDataSource.activeRow, null, null);
                }
            });
        }

    }

    textboxContactNameBeforeLookupModelSearch(event) {
        if (this.mainDataSource.activeRow.get("location_id")) {
            event.filter.parent_row_type = "L";
            event.filter.parent_row_id = this.mainDataSource.activeRow.get("location_id");
            event.filter.is_active = "Y";
        } else {
            event.filter.id = null;
        }
    }

    setFreeFormVisibility() {
        const locationIdExists: boolean = this.mainDataSource.activeRow.get("location_id") == null;
        this.citystate.enabled = locationIdExists;
        this.citystate.enabledDuringAdd = locationIdExists;
        this.citystate.enabledDuringUpdate = locationIdExists;
    }

    /** This is an event handler for the onChange event of switchConfirmed.  */
    switchConfirmedOnChange(event: ChangeEvent) {
        const changedData = this.mainDataSource.activeRow.getChangedData();
        if (event.userInitiatedChange && this.validateApptChanges
            && changedData["confirmed"] != null && changedData["confirmed"] === "N") {
            showAppointmentChangeDialog({ "confirmed": "Y" }, this.sourceAppointmentChange, this.mainDataSource.activeRow, { switchConfirmed: this.switchConfirmed }).then(() => {
                this.switchApptRequired.enabled = !this.activeRow?.getBoolean("confirmed");
            });
        } else {
            this.switchApptRequired.enabled = !this.activeRow?.getBoolean("confirmed");
        }
    }

    getAppointmentChangedRow(): ModelRow<any> {
        const changedValues = this.mainDataSource.activeRow.getChangedData();
        if (this.apptChanged(changedValues)) {
            const row = this.mainDataSource.activeRow.get("appointment_change", null);
            if (row != null) {
                row.set("stop_id", this.mainDataSource.activeRow?.get("id"));
                row.set("sequence", this.mainDataSource.activeRow?.get("order_sequence"));
                if (changedValues["sched_arrive_early"] != null)
                    row.set("revised_early", this.mainDataSource.activeRow?.get("sched_arrive_early"));
                if (changedValues["sched_arrive_late"] != null)
                    row.set("revised_late", this.mainDataSource.activeRow?.get("sched_arrive_late"));
            }
            return row;
        }
    }

    private apptChanged(changedData: any) {
        return this.validateApptChanges && this.mainDataSource.activeRow.get("appointment_change", null) != null &&
            ((changedData["confirmed"] != null && changedData["confirmed"] === "N") ||
                (isApptConfirmed(this.mainDataSource.activeRow) && (changedData["sched_arrive_early"] != null || changedData["sched_arrive_late"] != null)))
    }

    /** This is an event handler for the afterModeChange event of mainDataSource.  */
    sourceStopAfterModeChange(event) {
        this.validateApptChanges = event.newMode === DataSourceMode.UPDATE;
    }

    sourceStopAfterExecution(event) {
        this.setFreeFormVisibility();
    }

    private setTimezone(comp: Textbox) {
        const abbr = this.mainDataSource.activeRow?.get("timezone_id");
        comp.timezone = Timezone.getFromAbbreviation(abbr);
    }

    /** This is an event handler for the onDataDisplay event of citystate.  */
    citystateOnDataDisplay(event: DataDisplayEvent) {
        this.setTimezone(this.textboxSchedArriveEarly);
        this.setTimezone(this.textboxSchedArriveLate);
    }

    scheduleArriveValidator(): ValidationResult {
        if (!StringUtil.isEmptyString(this.textboxSchedArriveEarly.text) && !StringUtil.isEmptyString(this.textboxSchedArriveLate.text)) {
            const arrivalDate = DateUtil.parseDateWithKeywords(this.textboxSchedArriveEarly.text, true, true);
            const departureDate = DateUtil.parseDateWithKeywords(this.textboxSchedArriveLate.text, true, true);
            const dayDiff = DateUtil.dateDiff(DatePart.SECOND, departureDate, arrivalDate);

            if (dayDiff < 0) {
                const validDate = TextboxValidator.validate(this.textboxSchedArriveEarly, true, true, null);
                this.textboxSchedArriveEarly.text = validDate.isValid ? validDate.validatedValue : this.textboxSchedArriveEarly.text;
                return {
                    component: this.textboxSchedArriveEarly,
                    isValid: false,
                    validationMessage: "The early date cannot be later than the late date."
                };
            }
        }

        return null;
    }

    override displayData(data: ModelRow, allData: ModelRow[], rowIndex: number) {
        super.displayData(data, allData, rowIndex);
        // When a stop is deleted from the Orders page, each row in the stops table is rebuilt and
        // textboxStopDateType.selectedItem selected by the user is wiped out.
        const stopType = this?.activeRow?.["stop_date_type"];
        if (stopType != null) {
            this.textboxStopDateType.selectedItem = stopType;
        }
    }

    onComponentValidationFailed(result: ValidationResult) {
        const comp = result.component;
        const stopPrefix = this.getStopPrefix();
        result.caption = stopPrefix + result.caption;
        if (comp == this.textboxSchedArriveLate) {
            if (this.textboxSchedArriveEarly.validationWarning) {
                // "Pickup/Delivery Window" will already be displayed as missing or invalid field
                result.caption = null;
            } else {
                result.caption = stopPrefix + this.textboxSchedArriveEarly.caption;
            }
        } else {

            if (comp == this.citystate && this.textboxLocationId.required) {
                result.caption = null;
            } else {
                if (comp.parent == this.panelStopFreeForm && !this.panelStopFreeForm.visible)
                    result.componentToFocus = this.textboxLocationId;
            }
        }
    }

    getStopPrefix() {
        const tableRow = TableRow.getContainingTableRow(this);
        if (tableRow != null) {
            if (tableRow.index == 0)
                return "Origin ";
            else if (tableRow.index == tableRow.table.rowCount - 1)
                return "Destination ";
            return `Stop ${(tableRow.index + 1)} `;
        }
        return "";
    }

    async dfXrefOnDataDisplay(event: DataDisplayEvent) {
        const labelXref = <Label>event.target;
        const currentRow = <ModelRow>event.rowData;
        labelXref.caption = "";

        if (!StringUtil.isEmptyString(currentRow.get("edi_loc_code"))) {
            if (!StringUtil.isEmptyString(currentRow.get("location_id"))) {
                const edilocRow = await new ModelEdilocation().searchSingle({
                    location_id: currentRow.get("location_id"),
                    edi_location: currentRow.get("edi_loc_code")
                });
                this.setLabelXref(edilocRow == null ? false : true);
            } else {
                this.setLabelXref(false);
            }
        } else {
            labelXref.visible = false;
        }
    }

    async checkDFXref() {
        const locId = this.textboxLocationCode.text;
        const ediLoc = this.textboxEdiLocCode.text;
        let edilocRow = null;

        if (!StringUtil.isEmptyString(ediLoc) && !StringUtil.isEmptyString(locId)) {
            edilocRow = await new ModelEdilocation().searchSingle({ location_id: locId, edi_location: ediLoc });
        }

        if (edilocRow == null) {
            await this.promptForXref().then(async result => {
                const needsPrompt: boolean = result.data[0].needs_xref_prompt == "Y" ? true : false;
                const partnerId: string = result.data[0].partner_id;
                const version: string = result.data[0].version;
                const stopRow = this.mainDataSource.data[0].data;
                const edilocRow2 = await new ModelEdilocation().searchSingle({ location_id: locId });  //xref exists for same location id if present
                const edilocRow3 = await new ModelEdilocation().searchSingle({ edi_location: ediLoc });  //xref exists for same edi location if present

                //Duplicate checks - allow for multiple partner codes to xref to same location_id, but do not allow the same partner code to point to multiple location_ids
                if (needsPrompt && edilocRow2 != null) {
                    //Check for multiple xref for same LME location id - warn but allow multiples
                    if (edilocRow2.get("edi_location") == ediLoc) {
                        return;
                    } else {
                        CommonDialogs.showYesNo("Cross reference exists for partner code " + edilocRow2.get("edi_location") + " and location " + locId +
                            " on the corresponding DataFusion profile.  Would you still like to add this cross reference (" + ediLoc + "/" + locId + ")?",
                            "Add DataFusion Cross Reference",
                            { titleProps: { imageName: "circleX2", imageColor: "primary.light" } }).then(yes => {
                            if (yes) {
                                //add additional xref to LME location id
                                return this.addDataFusionCrossReference(stopRow, partnerId, version);
                            } else {
                                return;
                            }
                        });
                    }
                } else if (needsPrompt && edilocRow3 != null) {
                    //Check for multiple xref for same partner code - only allow update
                    if (edilocRow3.get("location_id") == locId) {
                        return "Record Exists";
                    } else {
                        CommonDialogs.showYesNo("Cross reference exists for partner code " + ediLoc + " and location " + edilocRow3.get("location_id") +
                            " on the corresponding DataFusion profile.  Would you like to update this cross reference (" + ediLoc + "/" + locId + ")?",
                            "Add DataFusion Cross Reference",
                            { titleProps: { imageName: "circleX2", imageColor: "primary.light" } }).then(yes => {
                            if (yes) {
                                //update existing xref - update edi_location
                                edilocRow3.set("location_id", locId);
                                edilocRow3.post();
                            } else {
                                return "Record Exists";
                            }
                        });
                    }
                } else if (needsPrompt) {
                    if (!StringUtil.isEmptyString(ediLoc)) {
                        if (!StringUtil.isEmptyString(locId)) {
                            CommonDialogs.showYesNo("Would you like to add a cross reference for partner code " + ediLoc + " and location " + locId +
                                " on the corresponding DataFusion profile(s)?", "Add DataFusion Cross Reference",
                                {
                                    titleProps: {
                                        imageName: "circleX2",
                                        imageColor: "primary.light"
                                    }
                                }).then(async yes => {
                                if (yes) {
                                    const stopRow = this.mainDataSource.data[0].data;

                                    this.addDataFusionCrossReference(stopRow, partnerId, version).then(result => {
                                        const recAdded = result.data[0].result;

                                        if (recAdded == "Record Added") {
                                            Snackbar.showSnackbar("Successfully added cross reference for " + ediLoc + " and " + locId + ".", { targetPanel: this.owner });
                                            this.setLabelXref(true);
                                        } else {
                                            Snackbar.showWarningSnackbar("Unable to add cross reference for " + ediLoc + " and " + locId + ".", { targetPanel: this.owner });
                                            this.setLabelXref(false);
                                        }
                                    });
                                } else {
                                    this.setLabelXref(false);
                                }
                            });
                        } else {
                            this.setLabelXref(false);
                        }
                    } else if (!StringUtil.isEmptyString(locId) && !StringUtil.isEmptyString(ediLoc)) {
                        this.setLabelXref(edilocRow == null ? false : true);
                    }
                }
            });
        } else {
            this.setLabelXref(true);
        }
    }

    async dfXrefOnClick(event: ClickEvent) {
        const stopRow = this.mainDataSource.data[0].data;
        const locId = this.mainDataSource.data[0].data["location_id"];
        const ediLoc = this.mainDataSource.data[0].data["edi_loc_code"];

        const edilocRow = await new ModelEdilocation().searchSingle({ location_id: locId, edi_location: ediLoc });
        if (edilocRow != null) {
            this.setLabelXref(true);
            return;
        }

        this.promptForXref().then(async result => {
            const partnerId: string = result.data[0].partner_id;
            const version: string = result.data[0].version;

            const labelXref = this.dfXref;

            if (!StringUtil.isEmptyString(locId) && !StringUtil.isEmptyString(ediLoc)) {
                const edilocRow2 = await new ModelEdilocation().searchSingle({ location_id: locId });  //xref exists for same location id if present
                const edilocRow3 = await new ModelEdilocation().searchSingle({ edi_location: ediLoc });  //xref exists for same edi location if present

                //Duplicate checks - allow for multiple partner codes to xref to same location_id, but do not allow the same partner code to point to multiple location_ids
                if (edilocRow2 != null) {
                    //Check for multiple xref for same LME location id - warn but allow multiples
                    if (edilocRow2.get("edi_location") == ediLoc) {
                        return;
                    } else {
                        CommonDialogs.showYesNo("Cross reference exists for partner code " + edilocRow2.get("edi_location") + " and location " + locId +
                            " on the corresponding DataFusion profile.  Would you still like to add this cross reference (" + ediLoc + "/" + locId + ")?",
                            "Add DataFusion Cross Reference",
                            { titleProps: { imageName: "circleX2", imageColor: "primary.light" } }).then(yes => {
                            if (yes) {
                                //add additional xref to LME location id
                                return this.addDataFusionCrossReference(stopRow, partnerId, version).then(() => {
                                    this.setLabelXref(true);
                                });

                            } else {
                                return;
                            }
                        });
                    }
                } else if (edilocRow3 != null) {
                    //Check for multiple xref for same partner code - only allow update
                    if (edilocRow3.get("location_id") == locId) {
                        return;
                    } else {
                        CommonDialogs.showYesNo("Cross reference exists for partner code " + ediLoc + " and location " + edilocRow3.get("location_id") +
                            " on the corresponding DataFusion profile.  Would you like to update this cross reference (" + ediLoc + "/" + locId + ")?",
                            "Add DataFusion Cross Reference",
                            { titleProps: { imageName: "circleX2", imageColor: "primary.light" } }).then(yes => {
                            if (yes) {
                                //update existing xref - update edi_location
                                edilocRow3.set("location_id", locId);
                                edilocRow3.post();
                                this.setLabelXref(true);
                            } else {
                                return "Record Exists";
                            }
                        });
                    }
                } else {
                    CommonDialogs.showYesNo("Would you like to add a cross reference for partner code " + this.textboxEdiLocCode.text + " and location " + this.textboxLocationCode.text +
                        " on the corresponding DataFusion profile(s)?", "Add DataFusion Cross Reference",
                        { titleProps: { imageName: "circleX2", imageColor: "primary.light" } }).then(async yes => {
                        if (yes) {
                            if (!StringUtil.isEmptyString(locId) && !StringUtil.isEmptyString(ediLoc)) {
                                this.addDataFusionCrossReference(stopRow, partnerId, version).then(result => {
                                    const recAdded = result.data[0].result;

                                    if (recAdded == "Record Added") {
                                        Snackbar.showSnackbar("Successfully added cross reference for " + ediLoc + " and " + locId + ".", { targetPanel: this.owner });
                                        this.setLabelXref(true);
                                    } else {
                                        Snackbar.showWarningSnackbar("Unable to add cross reference for " + ediLoc + " and " + locId + ".", { targetPanel: this.owner });
                                        this.setLabelXref(false);
                                    }
                                });
                            } else {
                                labelXref.visible = false;
                            }
                        }
                    });
                }
            } else {
                Snackbar.showWarningSnackbar("Location ID and DataFusion partner code must be present to create a cross reference.")
            }
        });
    }

    async promptForXref(): Promise<any> {
        const stopId = this.mainDataSource.data[0].data["id"];
        const stopType = this.mainDataSource.data[0].data["stop_type"];
        const orderId = this.orderSource.data[0].data["id"];
        const profileId = this.orderSource["ediProfileId"];

        return await Api.search("lme/datafusion/xref-profile", {
            stop_id: stopId,
            stop_type: stopType,
            order_id: orderId,
            profile_id: profileId
        });
    }

    async addDataFusionCrossReference(stopRow: any, partnerId: string, version: string): Promise<any> {
        const locId = this.textboxLocationCode.text;
        const ediLoc = this.textboxEdiLocCode.text;

        return Api.post("lme/datafusion/add-location-xref", {
            "partner_id": partnerId,
            "version": version,
            "edi_location": ediLoc,
            "location_id": locId
        });
    }

    setLabelXref(xrefExists: boolean) {
        const labelXref = this.dfXref;

        if (xrefExists) {
            labelXref.visible = true;
            labelXref.imageName = "linked";
            labelXref.imageColor = "default.darkest";
            labelXref.tooltip = "Location cross reference successfully applied";
        } else {
            labelXref.visible = true;
            labelXref.imageName = "notLinked";
            labelXref.imageColor = "default.darkest";
            labelXref.tooltip = "Location is missing a cross reference to DataFusion location.";
        }
    }

    public get orderSource(): DataSource {
        return this._orderSource;
    }

    public set orderSource(value: DataSource) {
        this._orderSource = value;
    }

    public setStopDateTypeForEdi(){
        this.textboxStopDateType.selectedItem = this.mainDataSource.activeRow?.get("sched_arrive_late") != null ? DATE_BETWEEN :DATE_ON;
    }
}
