import { DataDisplayEvent, DataSourceMode, Label } from "@mcleod/components";
import { ModelRow } from "@mcleod/core/src/ModelRow";
import { StringUtil } from "@mcleod/core/src/StringUtil";
import { AutogenLayoutOtherChargeCompareItem } from "./autogen/AutogenLayoutOtherChargeCompareItem";


export class OtherChargeCompareItem extends AutogenLayoutOtherChargeCompareItem {

    private _compareItems: ModelRow[];

    public async setupTableData(data: ModelRow[]) {
        this._compareItems = data;
    }

    override onLoad(): void | Promise<void> {

        this.tableCompareItems.dataSource;
        this.tableCompareItems.dataSource = this.sourceEdiCompareItem;
        this.tableCompareItems.dataSource.data = [];
        const rows = [];
        if (this._compareItems != null) {
            this.tableCompareItems.dataSource.setRowsAndMode(DataSourceMode.NONE, this._compareItems);
        }
        if (this._compareItems != null && this._compareItems.length == 0) {
            this.tableCompareItems.visible = false;
        }

    }

    onDescrDisplay(event: DataDisplayEvent) {
        const labelDescr = <Label>event.target
        if (StringUtil.isEmptyString(event.rowData.get("target_descr"))) {
            labelDescr.caption = event.rowData.get("source_descr");
        }
    }
}