/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelLoadTenderExpress.ts             *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/datafusion/load-tender-express";

export class AutogenModelLoadTenderExpress<RowType extends ModelRow> extends Endpoint<RowType, RowLoadTenderExpressFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowLoadTenderExpress extends ModelRow<RowLoadTenderExpressFields> {
    constructor(values?: Partial<RowLoadTenderExpressFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowLoadTenderExpressFields {
    alt_partner_id: string;
    app_type: string;
    batch: string;
    bol: string;
    commodity_desc: string;
    commodity_id: string;
    cons_refno: string;
    create_date: string;
    customer_id: string;
    customer_name: string;
    destination_address: string;
    destination_city_id: string;
    destination_city_name: string;
    destination_name: string;
    destination_sched_early: string;
    destination_sched_late: string;
    destination_state: string;
    destination_zip: string;
    direction: string;
    extra_deliveries: string;
    extra_pickups: string;
    fa_batch: string;
    fa_status: string;
    hazmat_id: string;
    intercompany: string;
    ltx_profile_id: string;
    must_respond_by: string;
    must_respond_by_tz: string;
    order_id: string;
    order_type: string;
    origin_address: string;
    origin_city_id: string;
    origin_city_name: string;
    origin_name: string;
    origin_sched_early: string;
    origin_sched_late: string;
    origin_state: string;
    origin_zip: string;
    partner_id: string;
    partner_name: string;
    purpose: string;
    purpose_desc: string;
    reply_batch: string;
    reply_created: string;
    reply_reason: string;
    reply_remarks: string;
    requires_reply: string;
    requires_reply_cancel: string;
    requires_reply_change: string;
    requires_reply_original: string;
    revenue_code: string;
    scac: string;
    shipment_id: string;
    temp_range_high: string;
    temp_range_low: string;
    tender_id: string;
    tractor_type: string;
    trailer_desc: string;
    trailer_type: string;
    version: string;
}

