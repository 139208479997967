export class DesignerTabDescriptor {
    name: string;
    baseVersion: boolean;
    customLayoutId: string;

    constructor(name: string, baseVersion: boolean, customLayoutId?: string) {
        this.name = name;
        this.baseVersion = baseVersion;
        this.customLayoutId = customLayoutId;
    }

    public equals(otherDescriptor: DesignerTabDescriptor): boolean {
        if (otherDescriptor == null)
            return false;
        return this.name === otherDescriptor.name &&
            this.baseVersion === otherDescriptor.baseVersion &&
            ((this.customLayoutId == null && otherDescriptor.customLayoutId == null) || (this.customLayoutId === otherDescriptor.customLayoutId));
    }

    public static createFromObject(o: any): DesignerTabDescriptor {
        return new DesignerTabDescriptor(o.name, o.baseVersion, o.customLayoutId);
    }
}