import { CommonTooltips } from "@mcleod/common";
import {
    Button, ClickEvent, Component, CrudDecorator, DataSourceAction, DataSourceExecutionEvent, DataSourceMode, Layout,
    TableRow, TableRowDisplayEvent
} from "@mcleod/components";
import { CurrencyUtil, DisplayType, ModelRow, Navigation } from "@mcleod/core";
import { Orders } from "@mcleod/dispatch/src/Orders";
import { AutogenLayoutQuoteList } from "./autogen/AutogenLayoutQuoteList";
import { QuoteLanding } from "./QuoteLanding";

export class QuoteList extends AutogenLayoutQuoteList {
    private convertToOrderDisabledTooltip: string = "Quote must be approved to convert to order";

    override onLoad() {
        this.setPanelSearchProps(true)
        this.panelQuoteListContainer.rows[0].style.height = "100%"; // pretty nasty hack to make the vertical scroll bar behave
        this.tableQuoteList.toolsPanel.determineDetailLayout = () => this.getDetailLayout();
        this.tableQuoteList.addSelectionListener(() => {
        });
    }

    getDetailLayout() {
        const tableRow = this.tableQuoteList.selectedRow;
        let layout: string;
        if (tableRow.data.getBoolean("is_brokerage_ltl")) {
            layout =  "lme/dispatch/brltl/QuoteLTL";
        }
        else {
            layout =  "lme/powerbroker/QuoteLanding";
        }
        this.tableQuoteList.generalLayout = layout;
        return layout;
    }

    /** This is an event handler for the afterExecution event of sourceOrders.  */
    sourceQuotesAfterExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() == DataSourceAction.SEARCH && event.dataSource.data.length > 0) {
            const transitionProps = { speed: 100, paddingTop: 0 };
            this.panelSearch.collapse(transitionProps).then(() => { this.setPanelSearchProps(false) });
            this.panelSearchHeader.visible = true;
        }
        this.buttonClear.enabled = true;
    }

    tableQuotesOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        const modelRow = tableRow.data;
        tableRow.forEveryChildComponent(comp => {
            this.setTooltipCallbacks(comp, tableRow.data);
            if (comp.id == "buttonCreateOrder") {
                const quoteStatus = modelRow.get("quote_status")
                const expirationDate = modelRow.get("expiration_date");
                QuoteLanding.checkEnableConvertToOrder(comp, new Date(expirationDate), quoteStatus);
            }
        });
        if (modelRow.getBoolean("is_brokerage_ltl")) {
            tableRow.findComponentById("buttonCreateOrder").visible = false;
        }
    }

    setPanelSearchProps(expanded: boolean) {
        this.buttonSearchExpand.imageRotation = expanded ? 0 : 90;

        this.panelSearch.borderWidth = expanded ? 1 : 0;
        this.panelSearch.borderColor = expanded ? "strokeSecondary" : null;
        this.panelSearch.borderRadius = expanded ? 4 : null;
        this.panelSearch.borderTopWidth = expanded ? 0 : null;
        this.panelSearch.borderTopLeftRadius = expanded ? 0 : null;
        this.panelSearch.borderTopRightRadius = expanded ? 0 : null;

        this.panelSearchHeader.borderBottomWidth = expanded ? 0 : 1;
        this.panelSearchHeader.borderColor = expanded ? null : "strokeSecondary";
        this.panelSearchHeader.borderBottomRightRadius = expanded ? 0 : 4;
        this.panelSearchHeader.borderBottomLeftRadius = expanded ? 0 : 4;
    }

    // Button Listeners

    /** This is an event handler for the onClick event of buttonSearchExpand.  */
    buttonSearchExpandOnClick(event: ClickEvent) {
        const transitionProps = { speed: 100, paddingTop: 0 };
        if (this.panelSearch.style.height == "0px")
            this.panelSearch.expand(transitionProps).then(() => {
                this.setPanelSearchProps(true);
            });
        else
            this.panelSearch.collapse(transitionProps).then(() => {
                this.setPanelSearchProps(false);
            });
    }

    /** This is an event handler for the onClick event of buttonClear.  */
    buttonClearOnClick(event: ClickEvent) {
        this.tableQuoteList.displayData(null, null, 0);

        this.mainDataSource.searchRow.clear();
        this.textboxCustomerName.clear();
        this.textboxOriginLocation["_lookupModelDataForUpdate"] = null;
        this.textboxDestLocation["_lookupModelDataForUpdate"] = null;

        this.panelSearch.displayData(this.mainDataSource.searchRow, null, 0);
        this.buttonClear.enabled = false;
        this.textboxId.focus();
    }

    setTooltipCallbacks(comp: Component, modelRow: ModelRow) {
        if (comp.id == null) return;
        switch (comp.id) {
            case "labelOriginLocationName":
                return CommonTooltips.setTooltipFromLayoutCallback(comp, modelRow.get("shipper.location_id"), "lme/dispatch/LocationQuickInfo", {
                    minWidth: 128,
                    minHeight: 140
                });
            case "labelDestinationLocationName":
                return CommonTooltips.setTooltipFromLayoutCallback(comp, modelRow.get("consignee.location_id"), "lme/dispatch/LocationQuickInfo", {
                    minWidth: 128,
                    minHeight: 140
                });
            case "textboxCustName":
                return CommonTooltips.setTooltipFromLayoutCallback(comp, modelRow.get("customer_id"), "lme/ar/CustomerQuickInfo", {
                    minWidth: 128,
                    minHeight: 140
                });
            case "labelTotalCharge":
                return CommonTooltips.setPanelTooltipCallback(comp, () => [{
                    caption: "Freight Charge",
                    displayType: DisplayType.CURRENCY,
                    text: CurrencyUtil.formatCurrency(modelRow.get("freight_charge"))
                }, {
                    caption: "Other Charges",
                    displayType: DisplayType.CURRENCY,
                    text: CurrencyUtil.formatCurrency(modelRow.get("otherchargetotal"))
                }])
        }
    }

    /** This is an event handler for the onClick event of buttonCreateOrder for each row in tableQuoteList.  */
    buttonCreateOrderOnClick(event: ClickEvent) {
        const tableRow = TableRow.getContainingTableRow(event.target as Button);
        const modelRow = tableRow.data as ModelRow;
        const layout = Layout.getLayout("lme/dispatch/Orders") as Orders;
        layout["quote_id"] = modelRow.get("id");
        const crudDecorator = new CrudDecorator({
            layout: layout,
            mode: DataSourceMode.ADD,
            headerProps: {
                showClose: true,
                showSaveAndClose: true,
                showSave: false,
                onClose: () => {
                    Navigation.navigateBack();
                }
            },
            layoutLoadListeners: [() => {
                layout.enableNextActions = false;
                layout.displayingFromToolbox = true;
            }]
        })
        Navigation.pseudoNavigateTo("lme/dispatch/Orders", () => crudDecorator.slideOut()).then(() => {
            crudDecorator.slideIn({ speed: 200 });
        });
    }
}
