import { Button, DataSource, Layout, Tab, Tabset } from "@mcleod/components";
import { CounterOffers } from "../CounterOffers";
import { MovementOffers } from "../MovementOffers";
import { WaterfallOffers } from "../WaterfallOffers";

export abstract class AutogenLayoutMovementOfferTabs extends Layout {
    buttonCancelWaterfall: Button;
    buttonSkipCarrier: Button;
    layoutCounterOffers: CounterOffers;
    layoutOfferHistory: MovementOffers;
    layoutWaterfallOffers: WaterfallOffers;
    sourceMovement: DataSource;
    tabCounterOffers: Tab;
    tabOfferHistory: Tab;
    tabWaterfallProgress: Tab;
    tabsetOfferTables: Tabset;
}
