import { AutogenModelReportTemplate, AutogenRowReportTemplate } from "./autogen/AutogenModelReportTemplate";

export class ModelReportTemplate extends AutogenModelReportTemplate<RowReportTemplate> {
    constructor() {
        super(RowReportTemplate);
    }
}

export class RowReportTemplate extends AutogenRowReportTemplate {
}
