import { Alignment, HorizontalAlignment, addConfigChangeListener } from "@mcleod/core";
import { Button, Component, Panel } from "..";
import { ButtonVariant } from "../components/button/ButtonVariant";

interface GlobalSlideouts {
    [key: string]: Slideout;
}

const _slideouts: GlobalSlideouts = {};
let speed = 300;

export interface SlideoutOptions {
    component: Component;
    align: Alignment;
    description: string;
    expanded: boolean;
}

export class Slideout extends Panel {
    filler: Panel;
    panelControl: Panel;
    buttonControl: Button;
    expanded: boolean = false;
    content: Component;
    padComponent: Component;
    description: string;

    constructor(props) {
        super({
            fillHeight: true,
            padding: 0,
            paddingTop: 8,
            visible: false,
            borderColor: "subtle.darker",
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            style: { transition: "width " + speed + "ms" },
            ...props
        });
        this.filler = new Panel({ fillRow: true, fillHeight: true, padding: 0 });
        this.panelControl = new Panel({ fillRow: true, marginBottom: 8, align: HorizontalAlignment.RIGHT });
        this.buttonControl = new Button({
            imageName: "doubleChevron", tooltipPosition: Alignment.RIGHT,
            imageProps: { style: { transitionDuration: speed + "ms" } }, color: "success", margin: 0, padding: 2, variant: ButtonVariant.round
        });
        this.buttonControl.addClickListener(() => this.toggleExpand());
        this.panelControl.add(this.buttonControl);
        if (props != null && props.align === Alignment.RIGHT)
            this.buttonControl.imageRotation = 180;
        this.add(this.filler);
        this.add(this.panelControl);
        addConfigChangeListener((newConfig) => {
            if (newConfig == null) {
                return;
            }
            speed = 0;
            if (newConfig?.use_smooth_transitions === "false")
                this.buttonControl.style.transitionDuration = "";
            else
                this.buttonControl.style.transitionDuration = speed + "ms";
            this.style.transition = newConfig.use_smooth_transitions === "false" ? null : "width " + speed + "ms";
        });
    }

    toggleExpand() {
        this.setExpanded(!this.expanded);
    }

    setExpanded(value) {
        if (this.expanded === value)
            return;
        this.expanded = value;
        if (value) {
            this.setProps({ borderWidth: 1, borderShadow: true });
            this.filler.add(this.content);
            this.filler.addToKeyHandlerStack();
            this.width = 30;
            if (this.padComponent != null)
                this.padComponent.paddingLeft = 12;
            if (this.description != null)
                this.buttonControl.tooltip = "Hide " + this.description;
            setTimeout(() => {
                this.width = this.content.width;
                this.buttonControl.imageRotation = 180;
            }, 10);
        }
        else {
            this.setProps({ borderWidth: 0, borderShadow: false, width: 30 });
            this.buttonControl.imageRotation = 0;
            if (this.padComponent != null)
                this.padComponent.paddingLeft = 0;
            if (this.description != null)
                this.buttonControl.tooltip = "Show " + this.description;
            setTimeout(() => {
                this.filler.remove(this.content);
                this.filler.removeFromKeyHandlerStack();
                this.width = null;
            }, speed);
        }
    }
}

export function clearGlobalSlideouts() {
    for (const key in _slideouts) {
        const slideout = _slideouts[key];
        if (slideout.content != null) {
            slideout.filler.removeAll();
            slideout.content = undefined;
            slideout.description = undefined;
            slideout.visible = false;
        }
    }
}

export function setGlobalSlideoutContent(options: Partial<SlideoutOptions>) {
    const slideout = _slideouts[options?.align];
    if (slideout != null) {
        slideout.content = options?.component;
        slideout.visible = true;
        slideout.description = options?.description;
        slideout.setExpanded(options?.expanded);
    }
    else
        slideout.visible = false;
}

export function createGlobalSlideout(align, props) {
    const result = new Slideout({ align: align, ...props });
    _slideouts[align] = result;
    return result;
}
