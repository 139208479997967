import { ForcedCase } from "@mcleod/components";
import { getLogger } from "@mcleod/core";
import { AutogenLayoutLoadTenderExpressProfile } from "./autogen/AutogenLayoutLoadTenderExpressProfile";

const log = getLogger("lme.datafusion.LoadTenderExpressProfile");

export class LoadTenderExpressProfile extends AutogenLayoutLoadTenderExpressProfile {

    override async onLoad() {
        this.textboxOrigType.addChangeListener(event => this.setValueLookup("origin", this.textboxOrigType.selectedItem, event.userInitiatedChange));
        this.textboxDestType.addChangeListener(event => this.setValueLookup("destination", this.textboxDestType.selectedItem, event.userInitiatedChange));
    }

    private setValueLookup(type: "origin" | "destination", item: any, clearValues = true) {
        log.debug("Item", item);
        const currentTextBox = "origin" == type ? this.textboxOrigZones : this.textboxDestZones;
        const value = typeof item === "string" ? item : item.value;
        if (clearValues) {
            currentTextBox.clear();
        }
        switch (value) {
            case "Z": // Zone
                currentTextBox.lookupModel = "lme/general/zones";
                currentTextBox.lookupModelLayout = "lme/general/Zones";
                currentTextBox.lookupModelResultField = "id";
                currentTextBox.lookupModelDisplayField = "descr";
                currentTextBox.lookupModelMinChars = 1;
                currentTextBox.forcedCase = ForcedCase.NONE;
                break;
            case "S": // States
                currentTextBox.lookupModel = "common/states";
                currentTextBox.lookupModelLayout = "common/States";
                currentTextBox.lookupModelResultField = "id";
                currentTextBox.lookupModelDisplayField = "id";
                currentTextBox.lookupModelMinChars = 2;
                currentTextBox.forcedCase = ForcedCase.UPPER
                break;
            case "L": // Location
                currentTextBox.lookupModel = "lme/dispatch/location-lookup";
                currentTextBox.lookupModelLayout = "lme/dispatch/LocationLookup";
                currentTextBox.lookupModelResultField = "id";
                currentTextBox.lookupModelDisplayField = "name";
                currentTextBox.lookupModelMinChars = 3;
                currentTextBox.forcedCase = ForcedCase.NONE;
                break;
            case "C": // Zip code
            default:
                currentTextBox.lookupModel = null;
                currentTextBox.lookupModelLayout = null;
                currentTextBox.lookupModelResultField = null;
                currentTextBox.lookupModelDisplayField = null;
                currentTextBox.forcedCase = ForcedCase.NONE;
                break;
        }
        currentTextBox.lookupModelAllowFreeform = false;
        currentTextBox.lookupModelAllowSearchAll = true;

        return item;
    }

}