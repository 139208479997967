import { DataSource, Label, Layout, Panel, Switch, Tab, Tabset, Textbox } from "@mcleod/components";
import { DispatchCommentsErrors } from "../../../powerbroker/src/DispatchCommentsErrors";
import { MovementCallins } from "../../../powerbroker/src/MovementCallins";
import { RecentCallins } from "../../../powerbroker/src/RecentCallins";
import { CallinEntry } from "../CallinEntry";
import { ClearStop } from "../ClearStop";

export abstract class AutogenLayoutDispatchSlideout extends Layout {
    labelDistanceCaption: Label;
    labelEtaCaption: Label;
    labelLate: Label;
    labelNextStopCaption: Label;
    labelNextStopCity: Label;
    labelNextStopEtaDate: Label;
    labelNextStopEtaTime: Label;
    labelNextStopLoc: Label;
    labelNextStopMiles: Label;
    labelNextStopMilesUom: Label;
    labelNextStopState: Label;
    labelNextStopZip: Label;
    layoutCallinEntry: CallinEntry;
    layoutDispatchCommentsErrors: DispatchCommentsErrors;
    layoutMovementCallins: MovementCallins;
    layoutRecentCallins: RecentCallins;
    layoutClearStop: ClearStop;
    panel10: Panel;
    panel11: Panel;
    panel9: Panel;
    panelEta: Panel;
    panelHeader: Panel;
    panelLate: Panel;
    panelMain: Panel;
    panelNextStop: Panel;
    panelNextStopCityState: Panel;
    sourceCallin: DataSource;
    sourceComments: DataSource;
    sourceDispatch: DataSource;
    switchToggleLayout: Switch;
    tab1: Tab;
    tabCallin: Tab;
    tabTracking: Tab;
    tabset1: Tabset;
    tabsetContent: Tabset;
    textboxBrokerageStatus: Textbox;
}
