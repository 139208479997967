import { ModelRow, StringUtil } from "@mcleod/core";
import { AutogenLayoutFreightGroupItemCompareItem } from "./autogen/AutogenLayoutFreightGroupItemCompareItem";
import { DataDisplayEvent, DataSourceMode, Label, TableRow } from "@mcleod/components";

export class FreightGroupItemCompareItem extends AutogenLayoutFreightGroupItemCompareItem {
    private _compareItems: ModelRow[];

    public async setupTableData(data: ModelRow[]) {
        this._compareItems = data;
    }

    override onLoad(): void | Promise<void> {
        this.tableCompareItems.dataSource = this.sourceEdiCompareItem;
        this.tableCompareItems.dataSource.data = [];
        const newTable = this.filterTable();
        if (newTable != null) {
            if (newTable.length === 0) {
                this.tableCompareItems.visible = false;
            } else {
                this.tableCompareItems.dataSource.setRowsAndMode(DataSourceMode.NONE, newTable);
            }
        }
    }

    onDescrDisplay(event: DataDisplayEvent) {
        const labelDescr = <Label>event.target
        if (StringUtil.isEmptyString(event.rowData.get("target_descr"))) {
            labelDescr.caption = event.rowData.get("source_descr");
        }
        if (event.rowData.get("freight_item_value") !== null && event.rowData.get("freight_item_value") !== undefined) {
            labelDescr.caption = event.rowData.get("freight_item_value");
        }
    }

    valueOnDisplay(event: DataDisplayEvent) {
        const label = event.target as Label;
        if (label.caption == "1900-01-01") {
            label.caption = "";
        }
    }

    filterTable() {
        let newTable = [];
        newTable = this._compareItems;
        return newTable;
    }

    freightItemOnRowDisplay(event: DataDisplayEvent) {
        const currRow = event.target as TableRow;
        if(currRow.data.get("change_type") == "NF") {
            currRow.data.set("target_value", null);

            //Normally we would use the standard caption lookup in fieldinfo but it doesn't look like that is still dependable for this table
            //(only 4 fields listed and no captions)
            if(currRow.data.get("source_fieldname") == "commodity_id") { currRow.data.set("target_descr", "Commodity ID"); }
            else if(currRow.data.get("source_fieldname") == "cubic_units") { currRow.data.set("target_descr", "Cubic Units"); }
            else if(currRow.data.get("source_fieldname") == "density") { currRow.data.set("target_descr", "Density"); }
            else if(currRow.data.get("source_fieldname") == "description") { currRow.data.set("target_descr", "Description"); }
            else if(currRow.data.get("source_fieldname") == "dunnage_weight") { currRow.data.set("target_descr", "Dunnage Weight"); }
            else if(currRow.data.get("source_fieldname") == "exceeds_volume") { currRow.data.set("target_descr", "Exceeds Volume"); }
            else if(currRow.data.get("source_fieldname") == "fgi_packaging_type_code") { currRow.data.set("target_descr", "Packaging Type Code"); }
            else if(currRow.data.get("source_fieldname") == "freight_weight") { currRow.data.set("target_descr", "Freight Weight"); }
            else if(currRow.data.get("source_fieldname") == "handling_units") { currRow.data.set("target_descr", "Handling Units"); }
            else if(currRow.data.get("source_fieldname") == "hazmat") { currRow.data.set("target_descr", "Hazmat"); }
            else if(currRow.data.get("source_fieldname") == "hazmat_class_code") { currRow.data.set("target_descr", "Hazmat Class Code"); }
            else if(currRow.data.get("source_fieldname") == "hazmat_emergency_number") { currRow.data.set("target_descr", "Hazmat Emergency Number"); }
            else if(currRow.data.get("source_fieldname") == "hazmat_erg_number") { currRow.data.set("target_descr", "Hazmat ERG Number"); }
            else if(currRow.data.get("source_fieldname") == "hazmat_is_residue") { currRow.data.set("target_descr", "Hazmat Is Residue"); }
            else if(currRow.data.get("source_fieldname") == "hazmat_packing_group") { currRow.data.set("target_descr", "Hazmat Packing Group"); }
            else if(currRow.data.get("source_fieldname") == "hazmat_proper_shipname") { currRow.data.set("target_descr", "Hazmat Proper Shipname"); }
            else if(currRow.data.get("source_fieldname") == "hazmat_qty_code") { currRow.data.set("target_descr", "Hazmat Quantity Code"); }
            else if(currRow.data.get("source_fieldname") == "hazmat_ref_type_code") { currRow.data.set("target_descr", "Hazmat Reference Type Code"); }
            else if(currRow.data.get("source_fieldname") == "hazmat_reference_data") { currRow.data.set("target_descr", "Hazmat Reference Data"); }
            else if(currRow.data.get("source_fieldname") == "hazmat_subclass_code") { currRow.data.set("target_descr", "Hazmat Subclass Code"); }
            else if(currRow.data.get("source_fieldname") == "hazmat_unna_nbr") { currRow.data.set("target_descr", "Hazmat UNNA Number"); }
            else if(currRow.data.get("source_fieldname") == "height") { currRow.data.set("target_descr", "Height"); }
            else if(currRow.data.get("source_fieldname") == "is_poison") { currRow.data.set("target_descr", "Is Poison"); }
            else if(currRow.data.get("source_fieldname") == "length") { currRow.data.set("target_descr", "Length"); }
            else if(currRow.data.get("source_fieldname") == "linear_units") { currRow.data.set("target_descr", "Linear Units"); }
            else if(currRow.data.get("source_fieldname") == "nmfc_class_code") { currRow.data.set("target_descr", "NMFC Class Code"); }
            else if(currRow.data.get("source_fieldname") == "nmfc_code") { currRow.data.set("target_descr", "NMFC Code"); }
            else if(currRow.data.get("source_fieldname") == "override_class_code") { currRow.data.set("target_descr", "Override Class Code"); }
            else if(currRow.data.get("source_fieldname") == "pieces") { currRow.data.set("target_descr", "Pieces"); }
            else if(currRow.data.get("source_fieldname") == "placard_required") { currRow.data.set("target_descr", "Placard Required"); }
            else if(currRow.data.get("source_fieldname") == "poison_zone") { currRow.data.set("target_descr", "Poison Zone"); }
            else if(currRow.data.get("source_fieldname") == "product_sku") { currRow.data.set("target_descr", "Product SKU"); }
            else if(currRow.data.get("source_fieldname") == "req_spots") { currRow.data.set("target_descr", "Required Spots"); }
            else if(currRow.data.get("source_fieldname") == "weight") { currRow.data.set("target_descr", "Weight"); }
            else if(currRow.data.get("source_fieldname") == "weight_uom_type_code") { currRow.data.set("target_descr", "Weigth Units of Measurement"); }
            else if(currRow.data.get("source_fieldname") == "width") { currRow.data.set("target_descr", "Width"); }
        }
        else if(currRow.data.get("change_type") == "FR") {
            if(currRow.data.get("target_fieldname") == "commodity_id") { currRow.data.set("target_descr", "Commodity ID"); }
            else if(currRow.data.get("target_fieldname") == "cubic_units") { currRow.data.set("target_descr", "Cubic Units"); }
            else if(currRow.data.get("target_fieldname") == "density") { currRow.data.set("target_descr", "Density"); }
            else if(currRow.data.get("target_fieldname") == "description") { currRow.data.set("target_descr", "Description"); }
            else if(currRow.data.get("target_fieldname") == "dunnage_weight") { currRow.data.set("target_descr", "Dunnage Weight"); }
            else if(currRow.data.get("target_fieldname") == "exceeds_volume") { currRow.data.set("target_descr", "Exceeds Volume"); }
            else if(currRow.data.get("target_fieldname") == "fgi_packaging_type_code") { currRow.data.set("target_descr", "Packaging Type Code"); }
            else if(currRow.data.get("target_fieldname") == "freight_weight") { currRow.data.set("target_descr", "Freight Weight"); }
            else if(currRow.data.get("target_fieldname") == "handling_units") { currRow.data.set("target_descr", "Handling Units"); }
            else if(currRow.data.get("target_fieldname") == "hazmat") { currRow.data.set("target_descr", "Hazmat"); }
            else if(currRow.data.get("target_fieldname") == "hazmat_class_code") { currRow.data.set("target_descr", "Hazmat Class Code"); }
            else if(currRow.data.get("target_fieldname") == "hazmat_emergency_number") { currRow.data.set("target_descr", "Hazmat Emergency Number"); }
            else if(currRow.data.get("target_fieldname") == "hazmat_erg_number") { currRow.data.set("target_descr", "Hazmat ERG Number"); }
            else if(currRow.data.get("target_fieldname") == "hazmat_is_residue") { currRow.data.set("target_descr", "Hazmat Is Residue"); }
            else if(currRow.data.get("target_fieldname") == "hazmat_packing_group") { currRow.data.set("target_descr", "Hazmat Packing Group"); }
            else if(currRow.data.get("target_fieldname") == "hazmat_proper_shipname") { currRow.data.set("target_descr", "Hazmat Proper Shipname"); }
            else if(currRow.data.get("target_fieldname") == "hazmat_qty_code") { currRow.data.set("target_descr", "Hazmat Quantity Code"); }
            else if(currRow.data.get("target_fieldname") == "hazmat_ref_type_code") { currRow.data.set("target_descr", "Hazmat Reference Type Code"); }
            else if(currRow.data.get("target_fieldname") == "hazmat_reference_data") { currRow.data.set("target_descr", "Hazmat Reference Data"); }
            else if(currRow.data.get("target_fieldname") == "hazmat_subclass_code") { currRow.data.set("target_descr", "Hazmat Subclass Code"); }
            else if(currRow.data.get("target_fieldname") == "hazmat_unna_nbr") { currRow.data.set("target_descr", "Hazmat UNNA Number"); }
            else if(currRow.data.get("target_fieldname") == "height") { currRow.data.set("target_descr", "Height"); }
            else if(currRow.data.get("target_fieldname") == "is_poison") { currRow.data.set("target_descr", "Is Poison"); }
            else if(currRow.data.get("target_fieldname") == "length") { currRow.data.set("target_descr", "Length"); }
            else if(currRow.data.get("target_fieldname") == "linear_units") { currRow.data.set("target_descr", "Linear Units"); }
            else if(currRow.data.get("target_fieldname") == "nmfc_class_code") { currRow.data.set("target_descr", "NMFC Class Code"); }
            else if(currRow.data.get("target_fieldname") == "nmfc_code") { currRow.data.set("target_descr", "NMFC Code"); }
            else if(currRow.data.get("target_fieldname") == "override_class_code") { currRow.data.set("target_descr", "Override Class Code"); }
            else if(currRow.data.get("target_fieldname") == "pieces") { currRow.data.set("target_descr", "Pieces"); }
            else if(currRow.data.get("target_fieldname") == "placard_required") { currRow.data.set("target_descr", "Placard Required"); }
            else if(currRow.data.get("target_fieldname") == "poison_zone") { currRow.data.set("target_descr", "Poison Zone"); }
            else if(currRow.data.get("target_fieldname") == "product_sku") { currRow.data.set("target_descr", "Product SKU"); }
            else if(currRow.data.get("target_fieldname") == "req_spots") { currRow.data.set("target_descr", "Required Spots"); }
            else if(currRow.data.get("target_fieldname") == "weight") { currRow.data.set("target_descr", "Weight"); }
            else if(currRow.data.get("target_fieldname") == "weight_uom_type_code") { currRow.data.set("target_descr", "Weigth Units of Measurement"); }
            else if(currRow.data.get("target_fieldname") == "width") { currRow.data.set("target_descr", "Width"); }
        }
    }
}
