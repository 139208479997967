/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelRecurringOrder.ts                *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/dispatch/recurring-order";

export class AutogenModelRecurringOrder<RowType extends ModelRow> extends Endpoint<RowType, RowRecurringOrderFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowRecurringOrder extends ModelRow<RowRecurringOrderFields> {
    constructor(values?: Partial<RowRecurringOrderFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowRecurringOrderFields {
    agency_id: string;
    agent_payee_id: string;
    autorate_status: string;
    bill_distance: number;
    bill_distance_um: string;
    blnum: string;
    booking_no: string;
    brk_qual_profile: string;
    brokerage_workflow_id: string;
    collection_method: string;
    commodity: string;
    commodity_id: string;
    company_id: string;
    company_pay_method: string;
    company_pay_pct: number;
    company_pay_rate: number;
    company_pay_rate_c: string;
    company_pay_rate_d: Date;
    company_pay_rate_n: number;
    company_pay_rate_r: number;
    consignee_refno: string;
    consignee_stop_id: string;
    container_type_id: string;
    ctrl_party_id: string;
    cube: number;
    cust_order_no: string;
    customer_id: string;
    def_move_type: string;
    dim_height: string;
    dim_length: string;
    dim_surcharge: number;
    dim_surcharge_c: string;
    dim_surcharge_d: Date;
    dim_surcharge_n: number;
    dim_surcharge_r: number;
    dim_width: string;
    dray_del_pay: number;
    dray_del_pay_c: string;
    dray_del_pay_d: Date;
    dray_del_pay_n: number;
    dray_del_pay_r: number;
    dray_pu_pay: number;
    dray_pu_pay_c: string;
    dray_pu_pay_d: Date;
    dray_pu_pay_n: number;
    dray_pu_pay_r: number;
    eir1_no: string;
    eir2_no: string;
    entered_user_id: string;
    equipment_type_id: string;
    est_tolls: number;
    est_tolls_c: string;
    est_tolls_d: Date;
    est_tolls_n: number;
    est_tolls_r: number;
    expiration_date: Date;
    floor_loaded_freight: string;
    forwd_location_id: string;
    free_days: number;
    freight_charge: number;
    freight_charge_c: string;
    freight_charge_d: Date;
    freight_charge_n: number;
    freight_charge_r: number;
    frequency: string;
    hazmat: string;
    hazmat_code: string;
    high_value: string;
    id: string;
    image_is_present: string;
    is_active: string;
    is_autorate_dist: string;
    is_billed: boolean;
    is_container: string;
    is_local_mile: string;
    ldport_location_id: string;
    linear_feet_avail: number;
    lock_miles: string;
    ltl: string;
    month_frequency: string;
    nte_lp_num: string;
    nte_lp_qual: string;
    occurrence: number;
    ocurrency: number;
    order_mode: string;
    order_type_id: string;
    order_value: number;
    order_value_c: string;
    order_value_d: Date;
    order_value_n: number;
    order_value_r: number;
    ordered_by: string;
    ordered_date: Date;
    ordered_method: string;
    otherchargetotal: number;
    otherchargetotal_c: string;
    otherchargetotal_d: Date;
    otherchargetotal_n: number;
    otherchargetotal_r: number;
    owner_pay_method: string;
    owner_pay_pct: number;
    owner_pay_rate: number;
    owner_pay_rate_c: string;
    owner_pay_rate_d: Date;
    owner_pay_rate_n: number;
    owner_pay_rate_r: number;
    pallets_how_many: number;
    pallets_required: string;
    pay_gross: number;
    pay_gross_c: string;
    pay_gross_d: Date;
    pay_gross_n: number;
    pay_gross_r: number;
    pick_up_no: string;
    pieces: number;
    ponum: string;
    port_of_discharge: string;
    preload_trailer_id: string;
    preloaded: string;
    rate: number;
    rate_currency_type: string;
    rate_id: number;
    rate_min_weight: number;
    rate_min_weight_um: string;
    rate_source: string;
    rate_type: string;
    rate_unit_desc: string;
    rate_units: number;
    revenue_code_id: string;
    rf_entry_code_required: boolean;
    round_trip: string;
    scheduled: string;
    seal_number: string;
    seg_alloc_code: string;
    ship_status_to_edi: string;
    shipper_stop_id: string;
    show_equipment_type_options: boolean;
    ss_location_id: string;
    "stop_consignee.actual_arrival": Date;
    "stop_consignee.actual_departure": Date;
    "stop_consignee.address": string;
    "stop_consignee.address2": string;
    "stop_consignee.appointment_status_id": string;
    "stop_consignee.appt_required": string;
    "stop_consignee.cases": number;
    "stop_consignee.city_id": number;
    "stop_consignee.city_name": string;
    "stop_consignee.company_id": string;
    "stop_consignee.confirmed": string;
    "stop_consignee.contact_email": string;
    "stop_consignee.contact_name": string;
    "stop_consignee.dist_from_prev": number;
    "stop_consignee.dist_from_prev_um": string;
    "stop_consignee.driver_load_unload": string;
    "stop_consignee.edi_code": string;
    "stop_consignee.edi_loc_code": string;
    "stop_consignee.eta": Date;
    "stop_consignee.google_place_id": string;
    "stop_consignee.hub": number;
    "stop_consignee.id": string;
    "stop_consignee.latitude": number;
    "stop_consignee.location_id": string;
    "stop_consignee.location_name": string;
    "stop_consignee.longitude": number;
    "stop_consignee.movement_id": string;
    "stop_consignee.movement_sequence": number;
    "stop_consignee.order_id": string;
    "stop_consignee.order_sequence": number;
    "stop_consignee.pallets_dropped": number;
    "stop_consignee.pallets_picked_up": number;
    "stop_consignee.phone": string;
    "stop_consignee.ponum": string;
    "stop_consignee.projected_arrival": Date;
    "stop_consignee.refno": string;
    "stop_consignee.sched_arrive_early": Date;
    "stop_consignee.sched_arrive_late": Date;
    "stop_consignee.seal": string;
    "stop_consignee.showas_address": string;
    "stop_consignee.showas_address2": string;
    "stop_consignee.showas_city_id": number;
    "stop_consignee.showas_city_name": string;
    "stop_consignee.showas_location_id": string;
    "stop_consignee.showas_location_name": string;
    "stop_consignee.showas_state": string;
    "stop_consignee.showas_zip_code": string;
    "stop_consignee.signed_for_name": string;
    "stop_consignee.state": string;
    "stop_consignee.status": string;
    "stop_consignee.stop_type": string;
    "stop_consignee.volume": number;
    "stop_consignee.volume_um": string;
    "stop_consignee.weight": number;
    "stop_consignee.weight_um": string;
    "stop_consignee.zip_code": string;
    "stop_consignee.zone_id": string;
    "stop_origin.actual_arrival": Date;
    "stop_origin.actual_departure": Date;
    "stop_origin.address": string;
    "stop_origin.address2": string;
    "stop_origin.appointment_status_id": string;
    "stop_origin.appt_required": string;
    "stop_origin.cases": number;
    "stop_origin.city_id": number;
    "stop_origin.city_name": string;
    "stop_origin.company_id": string;
    "stop_origin.confirmed": string;
    "stop_origin.contact_email": string;
    "stop_origin.contact_name": string;
    "stop_origin.dist_from_prev": number;
    "stop_origin.dist_from_prev_um": string;
    "stop_origin.driver_load_unload": string;
    "stop_origin.edi_code": string;
    "stop_origin.edi_loc_code": string;
    "stop_origin.eta": Date;
    "stop_origin.google_place_id": string;
    "stop_origin.hub": number;
    "stop_origin.id": string;
    "stop_origin.latitude": number;
    "stop_origin.location_id": string;
    "stop_origin.location_name": string;
    "stop_origin.longitude": number;
    "stop_origin.movement_id": string;
    "stop_origin.movement_sequence": number;
    "stop_origin.order_id": string;
    "stop_origin.order_sequence": number;
    "stop_origin.pallets_dropped": number;
    "stop_origin.pallets_picked_up": number;
    "stop_origin.phone": string;
    "stop_origin.ponum": string;
    "stop_origin.projected_arrival": Date;
    "stop_origin.refno": string;
    "stop_origin.sched_arrive_early": Date;
    "stop_origin.sched_arrive_late": Date;
    "stop_origin.seal": string;
    "stop_origin.showas_address": string;
    "stop_origin.showas_address2": string;
    "stop_origin.showas_city_id": number;
    "stop_origin.showas_city_name": string;
    "stop_origin.showas_location_id": string;
    "stop_origin.showas_location_name": string;
    "stop_origin.showas_state": string;
    "stop_origin.showas_zip_code": string;
    "stop_origin.signed_for_name": string;
    "stop_origin.state": string;
    "stop_origin.status": string;
    "stop_origin.stop_type": string;
    "stop_origin.volume": number;
    "stop_origin.volume_um": string;
    "stop_origin.weight": number;
    "stop_origin.weight_um": string;
    "stop_origin.zip_code": string;
    "stop_origin.zone_id": string;
    teams_required: string;
    temperature_max: number;
    temperature_max_um: string;
    temperature_min: number;
    temperature_min_um: string;
    total_charge: number;
    total_charge_c: string;
    total_charge_d: Date;
    total_charge_n: number;
    total_charge_r: number;
    tractor_type: string;
    vessel: string;
    weight: number;
    weight_um: string;
    days_back: number;
    edi_order_id: string;
}

