import { DataSource, Label, Layout, Panel, Table } from "@mcleod/components";

export abstract class AutogenLayoutChangeStopCompareItems extends Layout {
	labelReferenceNumbers: Label;
	labelStopComments: Label;
	panelComments: Panel;
	panelRefnos: Panel;
	sourceEdiCompareGroupRefno: DataSource;
	sourceEdiCompareGroupStopNote: DataSource;
	sourceEdiCompareItem: DataSource;
	tableCompareItems: Table;
	tableStopComment: Table;
	tableStopRefNo: Table;
}
