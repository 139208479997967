/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelRecurringSched.ts                *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/dispatch/recurring-sched";

export class AutogenModelRecurringSched<RowType extends ModelRow> extends Endpoint<RowType, RowRecurringSchedFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowRecurringSched extends ModelRow<RowRecurringSchedFields> {
    constructor(values?: Partial<RowRecurringSchedFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowRecurringSchedFields {
    city_id: number;
    city_name: string;
    company_id: string;
    days_out: number;
    earliest: Date;
    effective_from: Date;
    effective_to: Date;
    friday: string;
    id: string;
    last_gen_order_dt: Date;
    latest: Date;
    location_id: string;
    location_name: string;
    monday: string;
    month_day: string;
    month_frequency: string;
    monthly_date: number;
    orders_count: number;
    recur_sched_id: string;
    recurring_by: string;
    recurring_order_id: string;
    saturday: string;
    sequence: number;
    state: string;
    stop_id: string;
    sunday: string;
    thursday: string;
    tuesday: string;
    wednesday: string;
}

