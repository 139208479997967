import { ComponentPropDefinitions } from "@mcleod/components/src/base/ComponentProps";
import { MultiButtonPropDefinitions, MultiButtonProps } from "@mcleod/components/src/components/multibutton/MultiButtonProps";
import { ObjectUtil } from "@mcleod/core";

let sendLoadTenderButtonPropDefs: ComponentPropDefinitions;

export interface SendLoadTenderButtonProps extends MultiButtonProps {
    allowSendTender: boolean;
    allowSendRateCon: boolean;
}

export class SendLoadTenderButtonPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (sendLoadTenderButtonPropDefs == null) {
            sendLoadTenderButtonPropDefs = {
                ...ObjectUtil.deepCopy(MultiButtonPropDefinitions.getDefinitions())
            };
            sendLoadTenderButtonPropDefs.caption.defaultValue = "Send Tender";
            sendLoadTenderButtonPropDefs.minWidth.defaultValue = 100;
            sendLoadTenderButtonPropDefs.color.defaultValue = "success";
        }
        return sendLoadTenderButtonPropDefs;
    }
}
