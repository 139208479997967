import { CommonDialogs } from "@mcleod/common";
import { Button, DataSourceMode, Label, Layout, Panel, SlideoutDecorator, Snackbar, Toast } from "@mcleod/components";
import { Api, HorizontalAlignment, StringUtil, getLogger } from "@mcleod/core";
import { Orders } from "@mcleod/dispatch/src/Orders";
import { CompareOrder } from "./CompareOrder";
import { ModelEdiOrder, RowEdiOrder } from "./models/ModelEdiOrder";
import { ViewEdiOrder } from "./ViewEdiOrder";

const log = getLogger("lme.datafusion.CompareTender");

export class CompareTender {
    private resultsToastPanel: Panel;

    async compareTender(ediOrderRow: RowEdiOrder, needsReply: boolean, VEO?:ViewEdiOrder): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const tenderId = ediOrderRow.get("id");
            const orderId = ediOrderRow.get("order_id");
            let appliedChanges: number = 0;
            let allowReturn: boolean = false;

            if (tenderId && orderId) {
                await Api.search("lme/datafusion/compare-order", { edi_order_id: tenderId, order_id: orderId }).then(async result => {
                    if (result.data[0].no_changes) {
                        ediOrderRow.set("order_compared", "Y");
                        ediOrderRow.post().then(result => {
                            if (needsReply) {
                                CommonDialogs.showYesNo("No changes were found.  Would you still like to accept this tender?", "Reply to Tender",
                                    { titleProps: { imageName: "circleX2", imageColor: "primary.light" } }).then(async yes => {
                                        if (yes) {
                                            await this.acceptLoadTender(tenderId).then(response => {
                                                this.processReplyStatus(orderId, response, "not compared - no changes");
                                            }).catch(reason => {
                                                log.info("Could not reply.  " + reason);
                                            });
                                            allowReturn = true;
                                        }

                                    });
                            }
                        });
                    } else {
                        const compareLayout = Layout.getLayout("lme/datafusion/CompareOrder") as CompareOrder;
                        compareLayout.loadFromApiData(result.data[0], DataSourceMode.ADD);
                        compareLayout.orderId = orderId;
                        compareLayout.tenderId = tenderId;
                        let applyButtonClicked: boolean = false;

                        const btnApplyChanges = new Button({ caption: "Apply Changes", rowBreak: false, backgroundColor: "primary", color: "McLeodWhite" });
                        btnApplyChanges.addClickListener(async event => {
                            applyButtonClicked = true;
                            btnApplyChanges.busy = true;
                            appliedChanges = compareLayout.applyChanges(btnApplyChanges);

                        });

                        const comparePanel = this.getCompareSlideOutDecorator(compareLayout, orderId, btnApplyChanges, tenderId, applyButtonClicked, ediOrderRow, needsReply, VEO);
                        compareLayout.buttonApply = btnApplyChanges;
                        (await comparePanel).addUnmountListener(async () => {
                            this.unlockTender(ediOrderRow.get("id"));
                        });
                    }
                }).then(result => {
                    resolve(result)
                }).catch(error => {
                    reject(error);
                });
            }
        });
    }

    getCompareSlideOutDecorator(compareLayout: CompareOrder, orderId: string, btnApplyChanges: Button, tenderId: string, applyButtonClicked: boolean, ediOrderRow: RowEdiOrder, needsReply: boolean, VEO?:ViewEdiOrder): Promise<SlideoutDecorator> {
        return new Promise(async (resolve, reject) => {
            const compareSOD = new SlideoutDecorator({
                layout: compareLayout,
                title: "Compare Order - " + orderId,
                fillVerticalSpace: true,
                fillHorizontalSpace: false,
                width: window.innerWidth * 0.95,
                doAfterSlideIn: (decorator: SlideoutDecorator) => {
                    compareLayout.insert(btnApplyChanges, 3);
                },
                doAfterSlideOut: (decorator: SlideoutDecorator) => {
                    if (compareLayout.changesApplied > 0) { //changes have been applied, process reply
                        this.acceptLoadTender(tenderId).then(res => {
                            const batch = res.data[0].reply_batch;
                            const desc = res.data[0].description;
                            if (batch != "-1" && batch != "0" && desc != "Tender does not require a reply") {
                                this.resultsToastPanel = this.getResultsToastPanel(orderId, desc, "compared")
                                Toast.showToast(this.resultsToastPanel, null, { millisUntilDismissal: 7000 });
                            } else if (batch == "0" && desc == "Tender does not require a reply") {
                                Toast.showSuccessToast("Successfully updated order " + orderId, null, null, { millisUntilDismissal: 7000 });
                            } else {
                                Snackbar.showWarningSnackbar("Unable to send reply, but order " + orderId + " was successfully updated by the compare process", { millisUntilDimissal: 7000 });
                            }
                        }).catch(reason => {
                            log.info("Could not reply.  " + reason);
                            Snackbar.showWarningSnackbar("Unable to send reply [" + reason + "], but order " + orderId + " was successfully updated by the compare process", { millisUntilDimissal: 7000 });
                        }).finally(() => {
                            if (ediOrderRow.get("order_compared") == "N") {
                                ediOrderRow.set("order_compared", "Y");
                            }
                            this.delay(2000).then(() => {
                                if(VEO) {
                                    VEO.mainDataSource.search( { id: tenderId } );
                                }
                            });
                        });
                    } else if (!applyButtonClicked || compareLayout.changesApplied == 0) { //either button was not clicked or no changes accepted
                        if (needsReply) {
                            CommonDialogs.showYesNo("Compare Not Performed or no changes applied.  Would you still like to accept this tender?", "Reply to Tender",
                                { titleProps: { imageName: "circleX2", imageColor: "primary.light" } }).then(async yes => {
                                    if (yes) {
                                        await this.acceptLoadTender(tenderId).then(response => {
                                            const batch = response.data[0].reply_batch;
                                            const desc = response.data[0].description;
                                            if (batch != "-1" && batch != "0" && desc != "Tender does not require a reply") {
                                                //Timeout ignored for showToast so using successToast for now (can't use panel)
                                                // this.resultsToastPanel = this.getResultsToastPanel(orderId, desc, "compared");
                                                // Toast.showToast(this.resultsToastPanel, null, {millisUntilDismissal: 7000});
                                                Toast.showSuccessToast("Successfully updated order " + orderId + ".  " + desc, null, null, { millisUntilDismissal: 5000 });
                                            } else if (batch == "-1" && desc != "Tender does not require a reply") {
                                                Snackbar.showWarningSnackbar("Unable to send reply, but order " + orderId + " was successfully updated by the compare process", { millisUntilDimissal: 7000 });
                                            }
                                            if(VEO) {
                                                VEO.mainDataSource.search( { id: tenderId } );
                                            }
                                        });
                                    }
                                    if (!applyButtonClicked) { //give user option to exclude tender
                                        CommonDialogs.showYesNo("Would you like to remove this tender from Tender Express (exclude)?").then(response => {
                                            if (response) {
                                                ediOrderRow.set("no_display", "Y");
                                                ediOrderRow.post().then(result => {
                                                    this.refreshScreen();
                                                });
                                            }
                                        });
                                    }
                                });
                        }
                        else {
                            if (!applyButtonClicked) { //give user option to exclude tender
                                CommonDialogs.showYesNo("Would you like to remove this tender from Tender Express (exclude)?").then(response => {
                                    if (response) {
                                        ediOrderRow.set("no_display", "Y");
                                        ediOrderRow.post().then(result => {
                                            this.refreshScreen();
                                        });
                                    }
                                });
                            }
                        }
                    }
                    resolve(compareSOD);
                    // this.delay(2000).then(()=>{
                    //   log.info("Delay done.  Refreshing.");
                    // });
                }
            });
        });
    }

    processReplyStatus(orderId: any, response: any, arg2: string) {
        throw new Error("Method not implemented.");
    }

    async unlockTender(tenderId: string, callback?) {
        const ediOrderRow = await new ModelEdiOrder().searchSingle({ id: tenderId });
        const locked = ediOrderRow.get("record_in_use", "");
        if (locked == "Y") {
            ediOrderRow.set("record_in_use", "N");
            ediOrderRow.post();
        }

    }

    async acceptLoadTender(ediOrderId: string): Promise<any> {
        return new Promise(async (resolve) => {
            resolve(await this.sendReply(ediOrderId));
        });
    }

    async sendReply(ediOrderId: string): Promise<any> {
        log.info("Replying to tender " + ediOrderId + ".")
        return await Api.post("lme/datafusion/accept-tender", {
            edi_order_id: ediOrderId
        });
    }

    getResultsToastPanel(orderId: string, message: string, action: string): Panel {
        const labelProps = { color: "white", fontSize: "xlarge", rowBreak: false, paddingLeft: 1, paddingRight: 1, marginLeft: 0, marginRight: 0, wrap: false };
        const labelLinkProps = { ...labelProps, style: { textDecoration: "underline" } };

        if (!StringUtil.isEmptyString(orderId)) {
            return new Panel({
                align: HorizontalAlignment.CENTER, fillRow: true,
                components: [
                    new Label({ ...labelProps, caption: "Order " }),
                    new Label({ ...labelLinkProps, caption: orderId, onClick: event => Orders.navigateTo(orderId) }),
                    new Label({ ...labelProps, caption: " " + action + ".  " + message })
                ]
            });
        }
        else {
            return new Panel({
                align: HorizontalAlignment.CENTER, fillRow: true,
                components: [
                    new Label({ ...labelProps, caption: "Order not " + action + ".  " + message })
                ]
            });
        }
    }

    async delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    refreshScreen() {

    }
}
