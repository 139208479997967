import { ClickEvent } from "@mcleod/components";
import { ModelSearchResult, PermissionsUtil } from "@mcleod/core";
import { AddCarrier } from "./AddCarrier";
import { OnboardCarrier } from "./OnboardCarrier";
import { AutogenLayoutCarrierList } from "./autogen/AutogenLayoutCarrierList";

export class CarrierList extends AutogenLayoutCarrierList {

    private vendor_id: string;
    private packet_website_url: string;
    private carrierins_control_onboarding_server: string;
    private add_vendors = ['S', 'C', 'P'];
    private auto_onboard_vendors = ['D', 'H', 'R'];

    override async onLoad(): Promise<void> {
        await this.setInstanceFields();
        if (!(this.add_vendors.includes(this.vendor_id) || this.auto_onboard_vendors.includes(this.vendor_id))) {
            this.btnAddCarrier.visible = false;
        }
        this.btnAddCarrier.enabled = !PermissionsUtil.isUserDeniedAction("Brokerage.Invite or Add Carrier");
    }

    btnAddCarrierOnClick(event: ClickEvent) {

        if (this.auto_onboard_vendors.includes(this.vendor_id)) {
            OnboardCarrier.showSlideout(null, null, true);
        } else if (this.add_vendors.includes(this.vendor_id)) {
            AddCarrier.showSlideout("", this.vendor_id, "", "", "", this.carrierins_control_onboarding_server, this.packet_website_url);
        }
    }
    async setInstanceFields() {
        await this.sourceCarrierInsCtrl.search()
            .then((result: ModelSearchResult) => {
                if (result.modelRows[0] !== undefined) {
                    this.vendor_id = result.modelRows[0].get('vendor_id');
                    this.packet_website_url = result.modelRows[0].get('packet_website_url');
                    this.carrierins_control_onboarding_server = result.modelRows[0].get('onboarding_server');
                }
            });
    }
}


