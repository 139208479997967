import { ClickEvent } from "@mcleod/components";
import { Api } from "@mcleod/core";
import { AutogenLayoutSendTrackingMessage } from "./autogen/AutogenLayoutSendTrackingMessage";

export class SendTrackingMessage extends AutogenLayoutSendTrackingMessage {

    private _movementId: string;
    private _brVendorName: string;

    public doAfterMessageSent: (response: any) => void;
    public onClose: () => void;

    public set movementId(movementId: string) {
        this._movementId = movementId;
    }

    public get movementId(): string {
        return this._movementId;
    }

    public set brVendorName(brVendorName: string) {
        this._brVendorName = brVendorName;
        this.textboxTrackingVendor.text = this.brVendorName;
    }

    public get brVendorName(): string {
        return this._brVendorName;
    }

    buttonCancelOnClick(event: ClickEvent) {
        if (this.onClose != null)
            this.onClose();
    }

    btnHeaderCancelOnClick(event: ClickEvent) {
        if (this.onClose != null)
            this.onClose();
    }

    buttonSendMessageOnClick(event: ClickEvent) {
        if (this.validateSimple()) {
            this.buttonSendMessage.busy = true;
            Api.post("lme/powerbroker/tracking/send-message",
                { "movement_id": this.movementId, "comment": this.textboxComment.text }).then(response => {
                    if (this.doAfterMessageSent != null)
                        this.doAfterMessageSent(response);
                }).finally(() => this.buttonSendMessage.busy = false);
        }
    }
}
