import { Button, DataSource, Layout, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutMovementLockQuickInfo extends Layout {
    buttonCancel: Button;
    panelLock: Panel;
    sourceBrokerageMovements: DataSource;
    textboxLockedUser: Textbox;
    textboxUnlockTime: Textbox;
    textboxWaterfall: Textbox;
}
