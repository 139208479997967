import { DataSource, HorizontalSpacer, Image, Label, Layout, Panel } from "@mcleod/components";

export abstract class AutogenLayoutTransmissionStatusQuickInfo extends Layout {
	horizontalspacer1: HorizontalSpacer;
	labelCreateDate: Label;
	labelStatus: Label;
	labelStatusCaption: Label;
	labelTransmissionLogCreateDate: Label;
	linkToClassic: Image;
	panel1: Panel;
	sourceEdiLog: DataSource;
}
