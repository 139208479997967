import { ButtonProps } from "@mcleod/components";
import { Alignment } from "@mcleod/core";
import { SingleComponentDemo } from "./SingleComponentDemo";

// this documentation belongs somewhere else, but I'll stick it here for now since this is "the demo of how to create demos" :>

// this.propsForAllExamples is a props object that will apply to every component created in the demo
//    (like for a Button, you would likely always want a caption; for a Panel, you might always want a border so the person can see it)
// this.examples (should be renamed to this.properties) is a list of properties
//    each property has a list of cases
//    each property can also have a props object that contains props to set for all its cases
//        each case can have an optional description where you describe what you are trying to demonstrate in that case
//        each case can either have a value or code
//        if the case is code, you can have preCode and postCode that don't appear in the code snippet
//          the preCode might be imports?
//          the postCode would be returning the component (maybe your code created a Panel with a few components - you need to return that Panel)

export class DemoButton extends SingleComponentDemo {
    propsForAllExamples: Partial<ButtonProps>;

    constructor() {
        super();
        this.propsForAllExamples = { caption: "Click me!" };
        this.examples = {
            ...SingleComponentDemo.getComponentDemoExamples(),

            caption: {
                cases: [
                    { value: "Click me!" },
                    {
                        description: "Unless a button has a specified width, it will grow to the size of caption (plus padding and an image, if set)",
                        value: "Some other caption to demonstrate what changing it does"
                    },
                ]
            },

            default: {
                cases: [
                    { value: true },
                    { value: false },
                ]
            },

            focusable: {
                cases: [
                    { value: true, description: "Note how you can tab into this Button and that it maintains focus after clicking." },
                    { value: false, description: "And this Button doesn't allow focus." },
                ]
            },

            imageAlign: {
                props: { imageName: "circleCheck" },
                cases: [
                    { value: Alignment.LEFT, description: "The image is to the left of the Button's text." },
                    { value: Alignment.TOP, description: "The image is centered on top of the Button's text." },
                    { value: Alignment.BOTTOM, description: "The image is centered underneath the Button's text." },
                    { value: Alignment.RIGHT, description: "The image is to the right of the Button's text." },
                ]
            },

            imageColor: {
                props: { imageName: "circleCheck" },
                cases: [
                    { value: "primary", description: "Typically you would use colors available in the user's theme.  (provide link to theme documentation)" },
                    { value: "red", description: "When necessary, you can also use colors by name." },
                    { value: "#612965", description: "Or any other valid HTML color notation, like hex values.  (provide link to color documentation)" },
                ]
            },

            variant: {
                props: { imageName: "circleCheck" },
                cases: [
                    { value: undefined, description: "Not setting a variant yields a rectangular button with a 1px border of the current color, unless the border is changed specifically." },
                    { value: "round", props: { caption: undefined }, description: "The \"round\" variant is a shortcut to remove the border, adjust padding, and make the Button round.  It is more often used for Buttons that contain just an image." },
                ]
            },

            loading: {
                props: {
                    onClick: (event) => {
                        event.target.loading = !event.target.loading;
                    }
                },
                cases: [
                    { value: true, props: { imageAlign: Alignment.RIGHT }, description: "Button with loading spinner animation on - right aligned." },
                    { value: true, props: { imageAlign: Alignment.LEFT }, description: "Button with loading spinner animation on - left aligned." },
                    { value: false, description: "Button without loading spinner animation on by default." }
                ]
            },

        }
    }
}
