import { Button, DataSource, HorizontalSpacer, Label, Layout, Panel } from "@mcleod/components";
import { LoadTenderExpressProfile } from "../LoadTenderExpressProfile";

export abstract class AutogenLayoutLoadTenderExpressProfileFilter extends Layout {
    buttonApply: Button;
    buttonCancel: Button;
    horizontalspacer1: HorizontalSpacer;
    labelTenderExpressAdvancedFilter: Label;
    layoutProfile: LoadTenderExpressProfile;
    panel1: Panel;
    sourceEdiLtxexpressProf: DataSource;
}
