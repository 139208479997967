import { Button, ClickEvent, DataSourceAction, DataSourceExecutionEvent, DataSourceMode, ForcedCase, Label, Layout, Panel, SlideoutDecorator, Snackbar, Table, TableContentsChangedEvent, TableRow, TableRowDisplayEvent, TableRowExpansionEvent } from "@mcleod/components";
import { TableAction } from "@mcleod/components/src/events/TableContentsChangedEvent";
import { Api, ModelRow } from "@mcleod/core";
import { RecurringSchedule } from "./RecurringSchedule";
import { RecurringScheduleTable } from "./RecurringScheduleTable";
import { AutogenLayoutRecurringOrderList } from "./autogen/AutogenLayoutRecurringOrderList";

export class RecurringOrderList extends AutogenLayoutRecurringOrderList {
    private transitionProps = { speed: 100, paddingTop: 0 };

    override onLoad() {
        this.citystateStopOriginCityId.dataSourceModeChanged(DataSourceMode.SEARCH);
        this.citystateStopOriginCityId.textCity.forcedCase = ForcedCase.UPPER;
        this.citystateStopDestCityId.dataSourceModeChanged(DataSourceMode.SEARCH);
        this.citystateStopDestCityId.textCity.forcedCase = ForcedCase.UPPER;
        this.setPanelSearchProps(true);
        this.panelOrderListContainer.rows[0].style.height = "100%"; // pretty nasty hack to make the vertical scroll bar behave
    }
    /** This is an event handler for the onClick event of buttonSearchExpand.  */
    buttonSearchExpandOnClick(event: ClickEvent) {
        if (this.panelSearch.style.height == "0px")
            this.panelSearch.expand(this.transitionProps).then(() => {
                this.setPanelSearchProps(true);
            });
        else
            this.panelSearch.collapse(this.transitionProps).then(() => {
                this.setPanelSearchProps(false);
            });
    }

    setPanelSearchProps(expanded: boolean) {
        this.buttonSearchExpand.imageRotation = expanded ? 0 : 90;

        this.panelSearch.borderWidth = expanded ? 1 : 0;
        this.panelSearch.borderColor = expanded ? "strokeSecondary" : null;
        this.panelSearch.borderRadius = expanded ? 4 : null;
        this.panelSearch.borderTopWidth = expanded ? 0 : null;
        this.panelSearch.borderTopLeftRadius = expanded ? 0 : null;
        this.panelSearch.borderTopRightRadius = expanded ? 0 : null;

        this.panelSearchHeader.borderBottomWidth = expanded ? 0 : 1;
        this.panelSearchHeader.borderColor = expanded ? null : "strokeSecondary";
        this.panelSearchHeader.borderBottomRightRadius = expanded ? 0 : 4;
        this.panelSearchHeader.borderBottomLeftRadius = expanded ? 0 : 4;
    }

    /** This is an event handler for the onClick event of buttonClear.  */
    buttonClearOnClick(event: ClickEvent) {
        this.tableRecurringOrders.displayData(null, null, 0);
        this.tableRecurringOrders.setFilter(null);

        this.mainDataSource.searchRow.clear();
        this.textboxCustomerId.clear();
        this.textboxRevenueCodeId.clear();
        this.textboxOrderTypeId.clear();
        this.textboxOriginLocation["_lookupModelDataForUpdate"] = null;
        this.textboxDestLocation["_lookupModelDataForUpdate"] = null;

        this.panelSearch.displayData(this.mainDataSource.searchRow, null, 0);
        this.buttonClear.enabled = false;
        this.textboxId.focus();
    }

    /** This is an event handler for the afterExecution event of sourceOrders.  */
    sourceRecurringOrdersListAfterExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() == DataSourceAction.SEARCH && event.dataSource.data.length > 0) {
            this.panelSearch.collapse(this.transitionProps).then(() => {
                this.setPanelSearchProps(false);
            });
            this.panelSearchHeader.visible = true;
        }
        this.buttonClear.enabled = true;
    }

    tableRecurringOrdersOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow()
        if (tableRow.expanded) {
            const expandComponent = tableRow.getExpansionComponent() as Panel
            const layoutRecScheduleTable: RecurringScheduleTable = expandComponent.findComponentById("layoutRecScheduleTable") as RecurringScheduleTable;
            const id = tableRow.data.get("id");
            layoutRecScheduleTable.mainDataSource.search({ recurring_order_id: id });
        }
    }

    async onRowExpand(event: TableRowExpansionEvent) {
        if (event.isExpanding) {
            const tableRow: TableRow = event.target as TableRow;
            const modelRow: ModelRow = tableRow.data
            const stopData = (await Api.search("lme/dispatch/stop-rec-order", { order_id: modelRow.get("id") })).data.map((row: any) => new ModelRow('lme/dispatch/recur-sched-stop', true, row));
            const expandPanel = event.expandComponentParent as Panel;
            const layoutRecScheduleTable: RecurringScheduleTable = expandPanel.findComponentById("layoutRecScheduleTable") as RecurringScheduleTable;
            const id = tableRow.data.get("id");
            layoutRecScheduleTable.addLayoutLoadListener(() => {
                layoutRecScheduleTable.tableRecurringSchedule.persistChangesImmediately = true;
                layoutRecScheduleTable.tableRecurringSchedule.addContentsChangedListener((event: TableContentsChangedEvent) => this.tableRecurringScheduleOnContentsChanged(event, tableRow));
                layoutRecScheduleTable.mainDataSource.search({ recurring_order_id: id });
                layoutRecScheduleTable.initialize(modelRow, stopData);
            });
            this._closeOtherExpansions(tableRow.index);
        }
    }

    tableRecurringScheduleOnContentsChanged(event: TableContentsChangedEvent, tableRow: TableRow) {
        const scheduleTable = event.target as Table;
        if (TableAction.DELETE == event.getAction() && scheduleTable.rows.length == 0 && tableRow.data.get("scheduled") == "Y") {
            Snackbar.showWarningSnackbar("The recurring order must have at least one schedule assignment to be scheduled. Schedule has been turned off.");
            tableRow.data.set("scheduled", "N");
            tableRow.data.post();
        }
    }

    buttonSchedulerOnClick(event: ClickEvent) {
        const label = event.target as Label;
        this.showScheduleSlideout(label);
    }


    async showScheduleSlideout(label: Label) {
        let recurringScheduleLayout: RecurringSchedule;
        const tableRow = TableRow.getContainingTableRow(label);
        const modelRow = tableRow.data;
        const stopData = (await Api.search("lme/dispatch/stop-rec-order", { order_id: modelRow.get("id") })).data.map((row: any) => new ModelRow('lme/dispatch/recur-sched-stop', true, row));
        const erd = new SlideoutDecorator({
            title: `Recurring Order Schedule Assignments`,
            layout: recurringScheduleLayout = Layout.getLayout("lme/dispatch/RecurringSchedule") as RecurringSchedule,
            layoutLoadListeners: async (event) => {
                recurringScheduleLayout.initialize(DataSourceMode.ADD, modelRow, stopData);
            },
            width: '60%',
            fillVerticalSpace: true,
            overlayProps: { closeOnClickOff: false, greyedBackground: true },
            onClose: (cancelled: boolean) => {
                recurringScheduleLayout.slideOut();
            },
            addlComponents: new Button({
                id: "recSchedSaveButton",
                caption: "Save and Close",
                backgroundColor: "primary",
                color: "primary.reverse",
                minWidth: 128,
                borderWidth: 0,
                rowBreak: false,
                onClick: async (event: ClickEvent) => {
                    const updatedRow = await recurringScheduleLayout.onSave();
                    if (updatedRow) {
                        tableRow.data.set("frequency", updatedRow.data["frequency"]);
                        tableRow.data.set("occurrence", updatedRow.data["occurrence"]);
                        this._openNewScheduleExpansion(tableRow.index);
                        const expandPanel = tableRow.getExpansionComponent() as Panel
                        const layoutRecScheduleTable: RecurringScheduleTable = expandPanel.findComponentById("layoutRecScheduleTable") as RecurringScheduleTable;
                        layoutRecScheduleTable.addLayoutLoadListener(() => {
                            layoutRecScheduleTable.mainDataSource.search({ recurring_order_id: tableRow.data.get("id") });
                        });
                    }
                },
            })
        });
    }

    private _closeOtherExpansions(thisIndex: number) {
        for (const tableRow of this.tableRecurringOrders.rows) {
            if (tableRow.index === thisIndex) continue;
            tableRow.setExpanded(false);
        }
    }

    private _openNewScheduleExpansion(thisIndex: number) {
        for (const tableRow of this.tableRecurringOrders.rows) {
            if (tableRow.index !== thisIndex) continue;
            tableRow.setExpanded(true);
        }
    }
}
