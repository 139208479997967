import { MovementCarrierPay } from "../../../powerbroker/src/MovementCarrierPay";
import { Button, Checkbox, DataSource, HorizontalSpacer, Label, Layout, Location, Panel, Switch, Table, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutOrderRates extends Layout {
	buttonAvailableCredit: Button;
	buttonEstimateFuelSurcharge: Button;
	buttonExpandCharges: Button;
	buttonExpandDetails: Button;
	buttonRate: Button;
	checkboxLockMiles: Checkbox;
	chevronSpacer2: HorizontalSpacer;
	horizontalspacer3: HorizontalSpacer;
	horizontalspacer4: HorizontalSpacer;
	labelAdditionalCharges: Label;
	labelAutoRated: Label;
	labelAvailableCredit: Label;
	labelFreightCharge: Label;
	labelRatingDetails: Label;
	labelTotalCharge: Label;
	layoutMovementRates: MovementCarrierPay;
	panel1: Panel;
	panel2: Panel;
	panel3: Panel;
	panel4: Panel;
	panel5: Panel;
	panel6: Panel;
	panelChevron2: Panel;
	panelChevron: Panel;
	panelFreightCharge: Panel;
	panelOrderRates: Panel;panelOtherChargeTable: Panel;
	panelOtherCharges: Panel;
	panelOtherChargesHeader2: Panel;
	panelOtherChargesHeader: Panel;
	panelRateTotals: Panel;
	panelRatesHeader: Panel;
	panelRatingDetails: Panel;
	panelRatingDetailsHeader2: Panel;
	panelRatingDetailsHeader: Panel;
	panelRatingDetailsTable: Panel;
	panelSwitchSpacer: Panel;
	sourceOrderOtherCharge: DataSource;
	sourceOrders: DataSource;
	sourceRatingDetails: DataSource;
	sourceRatingDetailsSummary: DataSource;
	spacerRatingDetailsHeader: HorizontalSpacer;
	switchByFreightItem: Switch;
	tableOtherCharges: Table;
	tableRevPerFgi: Table;
	tableRevSummary: Table;
	textboxBillDistance: Textbox;
	textboxCollectionMethod: Textbox;
	textboxCtrlParty: Location;
	textboxFreightCharge2: Textbox;
	textboxFreightCharge: Textbox;
	textboxOtherchargetotal: Textbox;
	textboxPieces: Textbox;
	textboxRate: Textbox;
	textboxRateMinWeight: Textbox;
	textboxRateType: Textbox;
	textboxRateUnits: Textbox;
	textboxSegAllocCode: Textbox;
	textboxTotalCharge: Textbox;
	textboxWeight: Textbox;
    panel8: Panel;
}
