import { DataHeader, DataSource, Layout, Tab, Tabset, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutEquipmentTypeMaster extends Layout {
    dataheader1: DataHeader;
    sourceEquipmentType: DataSource;
    tabGeneral: Tab;
    tabsetEquipmentType: Tabset;
    textboxAppliesTo: Textbox;
    textboxDescr: Textbox;
    textboxEquipLength: Textbox;
    textboxEquipWidth: Textbox;
    textboxExchangeType: Textbox;
    textboxId: Textbox;
    textboxPnnCodeId: Textbox;
    textboxWebOrder: Textbox;
}
