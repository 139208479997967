/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelEdiBrltlOrderFreightGroupItem.ts  *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/datafusion/edi-brltl-order-freight-group-item";

export class AutogenModelEdiBrltlOrderFreightGroupItem<RowType extends ModelRow> extends Endpoint<RowType, RowEdiBrltlOrderFreightGroupItemFields> {
	constructor(ctor: RowConstructor) {
		super(endpointPath, ctor);
	}
}

export class AutogenRowEdiBrltlOrderFreightGroupItem extends ModelRow<RowEdiBrltlOrderFreightGroupItemFields> {
	constructor(values?: Partial<RowEdiBrltlOrderFreightGroupItemFields>) {
		super(endpointPath, true, values);
	}
}

export interface RowEdiBrltlOrderFreightGroupItemFields {
	commodity_id: string;
	cubic_units: number;
	density: number;
	description: string;
	fgi_packaging_type_code: string;
	fgi_sequence_nbr: number;
	fgi_uid: number;
	fgp_uid: number;
	freight_group_item_description: string;
	freight_group_item_weight_uom_type_code: string;
	handling_units: number;
	hazmat: string;
	hazmat_class_code: string;
	hazmat_emergency_number: string;
	hazmat_erg_number: string;
	hazmat_is_residue: string;
	hazmat_packing_group: string;
	hazmat_proper_shipname: string;
	hazmat_qty_code: string;
	hazmat_ref_type_code: string;
	hazmat_reference_data: string;
	hazmat_subclass_code: string;
	hazmat_subsidiary_code: string;
	hazmat_unna_nbr: string;
	height: number;
	is_poison: string;
	length: number;
	linear_units: number;
	lme_order_id: string;
	nmfc_class_code: string;
	nmfc_code: string;
	pieces: number;
	placard_required: string;
	poison_zone: string;
	product_sku: string;
	req_spots: number;
	weight: number;
	width: number;
}

