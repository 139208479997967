import { AutogenLayoutOrderUsers } from "./autogen/AutogenLayoutOrderUsers";

export class OrderUsers extends AutogenLayoutOrderUsers {

    override onLoad() {
        const LME_USER_FILTER = event => { event.filter.web_user_type = "U" };
        this.textboxBillingUserId.addBeforeLookupModelSearchListener(LME_USER_FILTER);
        this.textboxOperationsUser.addBeforeLookupModelSearchListener(LME_USER_FILTER);
        this.textboxEnteredUserId.addBeforeLookupModelSearchListener(LME_USER_FILTER);
        if (!this.mainDataSource.isSearching) {
            this.textboxBillingUserId.buttonImageName = "";
            this.textboxEnteredUserId.buttonImageName = "";
        }
    }
}
