import { StringUtil } from ".";


export class CityUtil {
    /**
     * Formats the given city, state and zip in a way that is presentable to the user.  This allows for any of those pieces to be null and
     * will still return a presentable string (i.e. there won't be a trailing comma if there is a null state)
     *
     * @param city
     * @param state
     * @param zip
     * @returns
     */
    public static formatCityStateZip(city: string, state: string, zip?: string): string {
        let result = "";
        if (!StringUtil.isEmptyString(city))
            result += city;
        if (!StringUtil.isEmptyString(state)) {
            if (result.length > 0)
                result += ", ";
            result += state;
        }
        if (!StringUtil.isEmptyString(zip)) {
            if (result.length > 0)
                result += " ";
            result += zip;
        }
        return result;
    }
}
