import { MovementCarrierPay } from "../MovementCarrierPay";
import { DataSource, Label, Layout, Panel, Tab, Table, Tabset, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutMovementPay extends Layout {
	labelAutoRated: Label;
	labelMoveDistance: Label;
	labelRates: Label;
	labelTotalCharges: Label;
	layoutCarrierRates: MovementCarrierPay;
	panelFooter: Panel;
	panelMovementPay: Panel;
	panelRates: Panel;
	panelTotalCharges: Panel;
	sourceExtraPay: DataSource;
	sourceMovement: DataSource;
	tabOtherPay: Tab;
	tableExtraPay: Table;
	tabsetOtherPay: Tabset;
	textboxCarrierPay: Textbox;
	textboxMargin: Textbox;
	textboxMarginPercentage: Textbox;
	textboxOtherPay: Textbox;
	textboxOverridePayAmt: Textbox;
	textboxOverridePayRate: Textbox;
	textboxOverrideType: Textbox;
	textboxOverrideUnits: Textbox;
	textboxPayPerMile: Textbox;
	textboxTotalPay: Textbox;
}
