import { CommonDialogs } from "@mcleod/common";
import { DataSource, DropdownItem, Layout, Snackbar } from "@mcleod/components";
import { Api, DateUtil, FileUtil, NumberUtil, getAuthSettings, getUnauthSettings } from "@mcleod/core";
import { RowBrltlOrderFreightGroupItem } from "./src/models/ModelBrltlOrderFreightGroupItem";
import { RowBrltlOrderFreightGroupItemFields } from "./src/models/autogen/AutogenModelBrltlOrderFreightGroupItem";

export interface BRLTLOrder {
    revenue_code_id: string;
    order_type_id: string;
    no_freight_group_items?: boolean;
    order_mode?: string,
}

export class BrltlUtil {

    public static isLtlEnabled(): boolean {
        return getUnauthSettings()?.company_settings["is_ltl"] == true;
    }

    public static isAsset(): boolean {
        return getUnauthSettings()?.company_settings["is_asset_ltl"] == true;
    }

    public static isBrokerageLtl(): boolean {
        return getUnauthSettings()?.company_settings["is_brokerage_ltl"] == true;
    }

    public static getTotalFormattedWeight(dataSource: DataSource<RowBrltlOrderFreightGroupItem>): string {
        return NumberUtil.formatDecimal(this.getTotalWeight(dataSource) ?? 0, "#,###.#") + " lbs"
    }

    public static getTotalWeight(dataSource: DataSource<RowBrltlOrderFreightGroupItem>): number {
        return this.getFgiTotal(dataSource, "weight");
    }

    public static formatTotalWeight(weight: number): string {
        return NumberUtil.formatDecimal(weight ?? 0, "#,###.#") + " lbs"
    }

    public static isBRLTLOrder(data: BRLTLOrder): boolean {
        return this.isBrokerageLtl() && this.shouldRateUsingTariff(data);
    }

    public static shouldRateUsingTariff(data: BRLTLOrder): boolean {
        const authSettings = getAuthSettings().dispatch_control[0];
        let rate_codes = "";
        if (authSettings.rate_using_tariff_codes != undefined) {
            rate_codes = authSettings.rate_using_tariff_codes;
        }

        switch (authSettings.rate_using_tariff) {
            case "A":
                return true;
            case "L":
                return data.order_mode == "L";
            case "P":
                return data.order_mode == "P";
            case "O":
                return this.verifyTariffCodes(data.order_type_id, rate_codes);
            case "R":
                return this.verifyTariffCodes(data.revenue_code_id, rate_codes);
        }
    }

    private static verifyTariffCodes(field: string, codes: string) {
        return codes.split(",").findIndex(code => code.trim() == field) >= 0;
    }

    public static getFgiTotal(dataSource: DataSource<RowBrltlOrderFreightGroupItem>, field: keyof RowBrltlOrderFreightGroupItemFields): number {
        const data = dataSource?.data;
        if (data != null && data.length > 0) return data.map(row => parseFloat(row.get(field, 0).toString().replace(/,/g, ''))).reduce((prev, next) => prev + next);
        return 0;
    }

    public static getPickupDateItems(date: any): DropdownItem[] {
        const stopDate = DateUtil.parseDateTime(date);
        const timeItems = [];
        let start = new Date(getUnauthSettings().company_settings["earliest_pickup"]);
        let end = new Date(getUnauthSettings().company_settings["ord_latest_pickup_time"]);
        if (isNaN(start.getTime())) {
            start = new Date(stopDate);
            start.setHours(8, 0, 0, 0);
        }
        else {
            const temp = start;
            start = new Date(stopDate);
            start.setHours(temp.getHours(), temp.getMinutes(), 0, 0);
        }
        if (isNaN(end.getTime())) {
            end = new Date(stopDate);
            end.setHours(17, 0, 0, 0);
        }
        else {
            const temp = end;
            end = new Date(stopDate);
            end.setHours(temp.getHours(), temp.getMinutes(), 0, 0);
        }
        for (let d = start; d <= end; d.setMinutes(d.getMinutes() + 30)) {
            const value = DateUtil.formatDateTime(d, "HH:mm");
            timeItems.push({ caption: value, value: d.toString() });
        }
        return timeItems;
    }

    public static downloadBOL(orderId: string) {
        if (orderId != null) {
            const todaysDate = DateUtil.formatDate(new Date(), "MM/dd/yyyy");
            const fileName = `Order ${orderId}-BOL-${todaysDate}.pdf`;
            const progressSnack: Snackbar = Snackbar.showDownloadSnackbar("Bill of Lading", "We are generating your Bill of Lading and it will download automatically.", { persist: true });
            Api.post("portal/reports/bill-of-lading", { order_id: orderId }).then(response => {
                const report = response?.data[0]?.bol_report;
                if (report != null) {
                    FileUtil.downloadBase64AsFile(report, fileName);
                    Snackbar.showSnackbar("Downloaded " + fileName);
                    progressSnack.dismiss();
                }
            });
        }
    }

    public static async getTerminalInfo(orderId: string) {
        const layout = Layout.getLayout("lme/powerbroker/TerminalInfo");
        layout.addLayoutLoadListener(async () => {
            const response = await layout.mainDataSource.search({ order_id: orderId })
            if (response == null) return; // error was likely thrown from the server side and a dialog should have been shown automatically
            const errorReason = layout.activeRow.get("error_reason", null);
            if (errorReason != null) {
                Snackbar.showWarningSnackbar(errorReason);
            }
            else {
                CommonDialogs.showDialog(layout, { title: "Terminal Info", panelContentProps: { width: 600 } });
            }
        });
    }
}
