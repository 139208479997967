import { AutogenModelLayoutLookup, AutogenRowLayoutLookup } from "./autogen/AutogenModelLayoutLookup";

export class ModelLayoutLookup extends AutogenModelLayoutLookup<RowLayoutLookup> {
    constructor() {
        super(RowLayoutLookup);
    }
}

export class RowLayoutLookup extends AutogenRowLayoutLookup {
}
