/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelEdiStandardCode.ts               *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/datafusion/edi-standard-code";

export class AutogenModelEdiStandardCode<RowType extends ModelRow> extends Endpoint<RowType, RowEdiStandardCodeFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowEdiStandardCode extends ModelRow<RowEdiStandardCodeFields> {
    constructor(values?: Partial<RowEdiStandardCodeFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowEdiStandardCodeFields {
    company_id: string;
    description: string;
    element_id: string;
    standard_code: string;
    version: string;
}

