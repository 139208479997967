import { AutogenLayoutRecentCallins } from "./autogen/AutogenLayoutRecentCallins";

export class RecentCallins extends AutogenLayoutRecentCallins {
    private _maxCallins = 3;
    private _newestFirst: boolean = true;
    private _includePositionCallins: boolean = false;

    override onLoad(): void {
        this.buttonButton1.visible = false;
        this.textbox1.visible = false;
        this.buttonButton1.addClickListener(event => {
            this.mainDataSource.search({ "_max_search_results": 3, "movement_id": this.textbox1.text })
        })
    }

    public doSearch(movementId: string) {
        if (movementId) {
            this.mainDataSource.orderBy = [{ field: "call_date_time", sort: "desc" }];
            this.mainDataSource.search({ "newest_first": this._newestFirst, "_max_search_results": this._maxCallins, "movement_id": movementId, "include_positions": this._includePositionCallins });
        }
    }

    set newestFirst(value: boolean) {
        this._newestFirst = value;
    }

    set maxCallins(value: number) {
        this._maxCallins = value;
    }

    set includePositions(value: boolean) {
        this._includePositionCallins = value;
    }
}
