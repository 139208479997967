import { Button, ClickEvent, DataDisplayEvent, DataSource, DataSourceExecutionEvent, DataSourceRemoteDataChangeEvent, Label, Panel, TableRow, TableRowDisplayEvent, TableSelectionEvent } from "@mcleod/components";
import { Api, ModelRow, getLogger } from "@mcleod/core";
import { CarrierOfferCounter, CounterOfferSummaryInfo } from "@mcleod/powerbroker/src/CarrierOfferCounter";
import { OfferTabEvent, OfferTabEventAction, OfferTabType } from "./MovementOfferTabs";
import { OfferSource, OfferUtil } from "./OfferUtil";
import { AutogenLayoutWaterfallOffers } from "./autogen/AutogenLayoutWaterfallOffers";
import { ModelMovement } from "./models/ModelMovement";

const log = getLogger("lme.dispatch.WaterfallOffers");

export class WaterfallOffers extends AutogenLayoutWaterfallOffers {
    private offerUtil: OfferUtil;
    private moveRow: ModelRow;
    public summaryInfo: CounterOfferSummaryInfo;
    public onTabEvent: (offerTabEvent: OfferTabEvent) => void = () => { };

    private sendTabEvent(offerTabEvent: OfferTabEvent) {
        this.onTabEvent(offerTabEvent);
    }

    public initializeOfferUtil(movementID: string, moveRow: ModelRow) {
        this.offerUtil = new OfferUtil(movementID, moveRow, this);
    }

    public async initialize(summaryInfo: CounterOfferSummaryInfo, sourceMovement: DataSource) {
        this.summaryInfo = summaryInfo;
        this.sourceMovement = sourceMovement;
        this.moveRow = sourceMovement.activeRow;
        this.initializeOfferUtil(this.moveRow.get("id"), this.moveRow);
        await this.search();
    }

    public async setMovementRow() {
        this.moveRow = await new ModelMovement().searchSingle({ id: this.moveRow.get("id") }, "lme/dispatch/Movement");
    }

    public async search() {
        await this.sourceWaterfallTender.search({ "movement_id": this.moveRow.get("id"), "waterfall_offer": "Y" });
        this.sendTabEvent({ type: OfferTabType.WATERFALL, action: OfferTabEventAction.SEARCH });
    }

    /** This is an event handler for the onRemoteDataChange event of sourceWaterfallTender.  */
    sourceWaterfallTenderOnRemoteDataChange(event: DataSourceRemoteDataChangeEvent) {
        log.info("Waterfall Offers Remote Data Change");
        const changedRow = event.change.data as ModelRow;
        const changedTableRowIndex = this.tableOffers.rows.findIndex(tableRow => tableRow.data.get("id") === changedRow.get("id"));
        if (changedRow.get("status") === "E" || changedRow.get("status") === "D") { // Expired or Declined - Refresh Table to show next Initial Offer
            const offerSequence = changedRow.get("offer_sequence", null);
            const nextOffer = this.getNextOffer(offerSequence);
            if (offerSequence && nextOffer) {
                nextOffer.data.set("status", "I");
            }
        }
        this.tableOffers.displayData(changedRow, this.tableOffers.data, changedTableRowIndex);
        this.sendTabEvent({ type: OfferTabType.WATERFALL, action: OfferTabEventAction.REMOTE_DATA_CHANGE });
    }

    private getNextOffer(offerSequence: number) {
        let nextOfferSequence = offerSequence + 1;
        return this.tableOffers.rows.find((tableRow) => {
            const offerSequence = tableRow.data.get("offer_sequence", null);
            const status = tableRow.data.get("status", null);
            if (offerSequence === nextOfferSequence && status !== "P") {
                nextOfferSequence++;
            }
            return offerSequence === nextOfferSequence && status === "P";
        })
    }

    /** This is an event handler for the onSubscriberError event of sourceWaterfallTender.  */
    sourceWaterfallTenderOnSubscriberError(event) {
        log.error(event.error);
    }

    // Component Events
    buttonAssignCarrierOnClick(event: ClickEvent) {
        this.offerUtil.assignCarrierEvent(event);
    }

    buttonDeclineCounterOnClick(event: ClickEvent) {
        const offerId = TableRow.getContainingTableRow(event.target as Button).data.get("id");
        Api.post("lme/powerbroker/decline-counter-offer", { offer_id: offerId });
    }

    buttonCounterOfferOnClick(event: ClickEvent) {
        const buttonCounterOffer = event.target as Button;
        const tableRow = TableRow.getContainingTableRow(buttonCounterOffer) as TableRow;
        this.showCounterOfferSlideout(tableRow);
    }

    private showCounterOfferSlideout(tableRow: TableRow) {
        CarrierOfferCounter.showCounterOfferSlideout({
            summaryInfo: this.summaryInfo,
            data: tableRow.data,
            doAfterSlideIn: (layout) => this.doAfterCounterOfferSlideoutLoaded(layout, tableRow.index),
            onSave: () => this.counterOfferSlideoutOnSave()
        });
    }

    private async doAfterCounterOfferSlideoutLoaded(layout: CarrierOfferCounter, tableRowIndex: number) {
        layout.setupCarrierInfo(this.activeRow.get("payee_id", null), this.moveRow.get("is_carrier_scorecard_licensed", false));
        layout.layoutMovementCarrierPay.displayData(this.moveRow, null, 0);
    }

    private counterOfferSlideoutOnSave() {
        this.search();
    }

    sourceCarrierOfferAfterExecution(event: DataSourceExecutionEvent) {
        this.offerUtil.sortOffers(OfferSource.WATERFALL);
    }

    tableOffersOnRowDisplay(event: TableRowDisplayEvent) {
        this.offerUtil.tableOffersOnRowDisplay(event);

        const tableRow = event.getTableRow();
        const data = tableRow.data as ModelRow;
        const status = data.get("status");
        const isCountered = status == "O";
        const buttonCounterCounter = tableRow.findComponentById("buttonCounterOffer") as Button;
        const buttonDeclineCounter = tableRow.findComponentById("buttonDeclineCounter") as Button;
        const buttonAssignCarrier = tableRow.findComponentById("buttonAssignCarrier") as Button;
        const panelStatus = tableRow.findComponentById("panelStatus") as Panel;
        buttonCounterCounter.visible = isCountered;
        buttonDeclineCounter.visible = isCountered;
        buttonAssignCarrier.visible = ['I', 'E', 'O'].includes(status);
        this.offerUtil.setOfferStatusLabel(status, panelStatus);
    }

    tableOffersOnSelect(event: TableSelectionEvent) {
        this.sendTabEvent({ type: OfferTabType.WATERFALL, action: OfferTabEventAction.SELECT_OFFER, changedRow: event.newRow });
    }

    showTooltipOnDataDisplay(event: DataDisplayEvent) {
        this.offerUtil.showTooltipOnDataDisplay(event);
    }

    labelStatusOnDataDisplay(event: DataDisplayEvent) {
        const label = event.target as Label;
        const panel = label.parent as Panel;
        const tableRow: TableRow = TableRow.getContainingTableRow(label);
        this.offerUtil.setOfferStatusLabel(tableRow.data.get("status"), panel);
    }
}
