import { Component, Label, Layout, MouseEvent, Textbox } from "@mcleod/components";
import { StringUtil } from "@mcleod/core";

export function makeTooltipCallbackFunction(id: string, component: Component) {
    return (baseTooltip: Component, originatingEvent: MouseEvent): Component => {
        const layout = getQuickInfoLayout(baseTooltip, id);
        if (layout == null)
            return layout;
        layout.onLoad = () => {
            layout.mainDataSource?.search({ search: id }).then(response => {
                if (component instanceof Textbox && (component as Textbox).printable) {
                    const label: Label = (component as Textbox)["_printableLabel"];
                    return label["_internalShowTooltip"](layout, originatingEvent);
                }
                else
                    return component["_internalShowTooltip"](layout, originatingEvent);
            });
        };
        return layout;
    };
}

function getQuickInfoLayout(baseTooltip: Component, id: string): Layout | null {
    if (StringUtil.isEmptyString(id) === true)
        return null;
    return Layout.getLayout("lme/datafusion/EDITenderProfileQuickInfo");
}
