/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelEdiMapError.ts                   *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/datafusion/edi-map-error";

export class AutogenModelEdiMapError<RowType extends ModelRow> extends Endpoint<RowType, RowEdiMapErrorFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowEdiMapError extends ModelRow<RowEdiMapErrorFields> {
    constructor(values?: Partial<RowEdiMapErrorFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowEdiMapErrorFields {
    company_id: string;
    direction: string;
    error_code: string;
    error_descr: string;
    error_type: string;
    id: string;
    parent_id: string;
    parent_tablename: string;
    transaction_type: string;
}

