import { TableColumn } from "@mcleod/components";
import { ModelRow, OrderByInfo } from "@mcleod/core";
export type Filter = (row: ModelRow) => boolean;

export interface SecondaryFilter {
    id: string;
    caption: string;
    filter: (row: ModelRow, mainFilter: Filter) => boolean;
}

interface SalesCallListTableTypeProps {
    caption: string;
    tableIdSuffix: string;
    filter: Filter;
    secondaryFilters?: SecondaryFilter[];
    configureColumn?: (column: TableColumn) => void;
    defaultSort?: OrderByInfo[];
}

export class SalesCallListTableType implements SalesCallListTableTypeProps {
    public caption: string;
    public tableIdSuffix: string;
    public filter: (row: ModelRow) => boolean;
    public secondaryFilters?: SecondaryFilter[];
    public defaultSort: OrderByInfo[];

    private constructor(props: Partial<SalesCallListTableTypeProps>) {
        this.caption = props?.caption;
        this.tableIdSuffix = props?.tableIdSuffix;
        this.filter = props.filter;
        this.secondaryFilters = props.secondaryFilters;
        this.defaultSort = props.defaultSort;
    }

    // Secondary Filters
    static readonly LOCATION_PROSPECT = {
        id: "locationProspect",
        caption: "Location Prospect",
        tableIdSuffix: "LocationProspect",
        filter: (row: ModelRow, mainFilter: Filter) => mainFilter(row) && row.data["from_customer_table"] !== "Y"
    }

    static readonly CUSTOMER = {
        id: "customer",
        caption: "Customer",
        tableIdSuffix: "Customer",
        filter: (row: ModelRow, mainFilter: Filter) => mainFilter(row) && row.data["from_customer_table"] === "Y"
    }


    // Primary Filters (Table Queues)
    static readonly ALL = new SalesCallListTableType({
        caption: "All",
        tableIdSuffix: "All",
        filter: () => true,
        secondaryFilters: [
            SalesCallListTableType.LOCATION_PROSPECT,
            SalesCallListTableType.CUSTOMER,
        ],
        defaultSort: [{ field: "next_call_date", sort: "asc" }]
    });

    static readonly TODAY = new SalesCallListTableType({
        caption: "Today",
        tableIdSuffix: "Today",
        filter: (row: ModelRow) => {
            let nextCallDate = row.data["next_call_date"];
            if (nextCallDate === undefined) {
                return false;
            }
            nextCallDate = new Date(nextCallDate).setUTCHours(0, 0, 0, 0)
            const today = new Date().setUTCHours(0, 0, 0, 0);
            return nextCallDate <= today;
        },
        secondaryFilters: [
            SalesCallListTableType.LOCATION_PROSPECT,
            SalesCallListTableType.CUSTOMER,
        ],
        defaultSort: [{ field: "next_call_date", sort: "asc" }]
    });
}
