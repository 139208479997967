/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelClassifications.ts               *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/general/classifications";

export class AutogenModelClassifications<RowType extends ModelRow> extends Endpoint<RowType, RowClassificationsFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowClassifications extends ModelRow<RowClassificationsFields> {
    constructor(values?: Partial<RowClassificationsFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowClassificationsFields {
    adp_co_code: string;
    adp_e_no: string;
    agreement_dt: Date;
    auth_reinstate_date: Date;
    authority_reg_date: Date;
    authority_reg_status: string;
    authority_reg_type: string;
    auto_create_dispatch_tractr: string;
    bank_acct_type_name: string;
    bank_address_1: string;
    bank_address_2: string;
    bank_city_name: string;
    bank_state: string;
    bank_zip_code: string;
    basic_update: Date;
    begin_vacation_dt: Date;
    bond_filed: string;
    bond_required: string;
    box_for_1099_total: string;
    broker_auth_grant_date: Date;
    broker_auth_status: string;
    c_tpat_flag: string;
    c_tpat_svi: string;
    calc_agent_pay: string;
    carb_flag: string;
    cargo_agri_farm: string;
    cargo_beverages: string;
    cargo_building_mat: string;
    cargo_cancel_date: Date;
    cargo_chemicals: string;
    cargo_coal_coke: string;
    cargo_commod_dry: string;
    cargo_construction: string;
    cargo_drive_tow: string;
    cargo_email: string;
    cargo_garbage: string;
    cargo_gen_freight: string;
    cargo_grain_feed: string;
    cargo_household: string;
    cargo_ins_amount: number;
    cargo_ins_amount_c: string;
    cargo_ins_amount_d: Date;
    cargo_ins_amount_n: number;
    cargo_ins_amount_r: number;
    cargo_ins_on_file: string;
    cargo_ins_renew_dt: Date;
    cargo_ins_required: string;
    cargo_insur_fax: string;
    cargo_insur_phone: string;
    cargo_insurer: string;
    cargo_intermodal: string;
    cargo_liquids_gas: string;
    cargo_livestock: string;
    cargo_logs_lumber: string;
    cargo_machinery: string;
    cargo_meat: string;
    cargo_metal: string;
    cargo_mobile_homes: string;
    cargo_oil_equip: string;
    cargo_other: string;
    cargo_other_descr: string;
    cargo_paper_prod: string;
    cargo_passengers: string;
    cargo_policy: string;
    cargo_produce: string;
    cargo_reefer_breakdown: string;
    cargo_reefer_food: string;
    cargo_related_oa: string;
    cargo_related_ot: string;
    cargo_related_pct: number;
    cargo_related_sv: string;
    cargo_us_mail: string;
    cargo_utilities: string;
    cargo_vehicles: string;
    cargo_water_well: string;
    carrier: string;
    carrier_cont_type: string;
    carrier_contract: string;
    carrier_flat_count: number;
    carrier_has_flats: string;
    carrier_has_refers: string;
    carrier_has_vans: string;
    carrier_mgr: string;
    carrier_ranking: number;
    carrier_refe_count: number;
    carrier_status_id: string;
    carrier_van_count: number;
    category: string;
    child_contract: number;
    classification_name: string;
    classification_result: string;
    com_auth_reinstate_date: Date;
    common_auth_grant_date: Date;
    common_auth_status: string;
    company_id: string;
    con_substance_oa: string;
    con_substance_ot: string;
    con_substance_sv: string;
    cont_auth_reinstate_date: Date;
    cont_substance_pct: number;
    contact: string;
    contract_auth_grant_date: Date;
    contract_auth_stat: string;
    contract_authority: string;
    contract_date: Date;
    cr_union_wh: number;
    cr_union_wh_c: string;
    cr_union_wh_d: Date;
    cr_union_wh_n: number;
    cr_union_wh_r: number;
    crashes_fatal: number;
    crashes_injury: number;
    crashes_total: number;
    crashes_tow: number;
    credit_approved: string;
    credit_check_date: Date;
    cxna_id: number;
    date_entered: Date;
    def_load_to_radius: number;
    def_loc_radius: number;
    default_bank_account: string;
    department: string;
    detention_max_pay_amt: number;
    detention_max_pay_amt_c: string;
    detention_max_pay_amt_d: Date;
    detention_max_pay_amt_n: number;
    detention_max_pay_amt_r: number;
    detention_pay_rate: number;
    detention_tm_earning_code: string;
    detention_tm_max_pay_amt: number;
    detention_tm_max_pay_amt_c: string;
    detention_tm_max_pay_amt_d: Date;
    detention_tm_max_pay_amt_n: number;
    detention_tm_max_pay_amt_r: number;
    detention_tm_pay_rate: number;
    disable_automatic_tracking: string;
    disable_electronic_offers: string;
    distance_method: string;
    distance_profile: string;
    distance_source: string;
    distance_type: string;
    division_id: string;
    dob_processed: string;
    dot_number: string;
    dray_rate: number;
    driver_fitness_oa: string;
    driver_fitness_ot: string;
    driver_fitness_pct: number;
    driver_fitness_sv: string;
    driver_inspection: number;
    driver_rating: string;
    drivers: number;
    drv_oos_inspection: number;
    earning_code: string;
    eforms_carrier_onboarding: string;
    eforms_contract_alert: number;
    eforms_contract_expire: number;
    eforms_docrequest_alert: number;
    eforms_docrequest_expire: number;
    eforms_expirations: string;
    eforms_profile_alert: number;
    eforms_profile_expire: number;
    eforms_qual_profile: string;
    efs_account: string;
    email: string;
    empty_pay_rate: number;
    equipment_type_id: string;
    exclude_from_perf_calc: string;
    expense_glid: string;
    experience_date: Date;
    factor_name: string;
    factoring_company_id: string;
    fatigue_drive_pct: number;
    fatigued_drive_oa: string;
    fatigued_drive_ot: string;
    fatigued_drive_sv: string;
    fax: string;
    fuel_reimburse_id: string;
    fuel_surcharge_pay: string;
    futa: number;
    futa_c: string;
    futa_d: Date;
    futa_n: number;
    futa_r: number;
    gross_non_taxed: number;
    gross_non_taxed_c: string;
    gross_non_taxed_d: Date;
    gross_non_taxed_n: number;
    gross_non_taxed_r: number;
    haz_oos_inspection: number;
    hazmat_cert_number: string;
    hazmat_expire_date: Date;
    hazmat_inspection: number;
    highway_id_number: string;
    highway_rule_assessment: string;
    highway_status: string;
    holiday_hours_pd: number;
    hourly_pay_allocation: string;
    household_goods: string;
    ic_company_id: string;
    icc_number: string;
    id: string;
    ins_auto_update: string;
    ins_expire_notify: string;
    ins_last_upd_dt: Date;
    ins_on_file: string;
    insurance_oa: string;
    insurance_sv: string;
    intrastate_code: string;
    intrastate_only: string;
    intrastate_state: string;
    is_air: string;
    is_any_auto: string;
    is_broker: string;
    is_certified: string;
    is_expedited: string;
    is_factored: string;
    is_hired_autos: string;
    is_intercompany: string;
    is_intermodal: string;
    is_ltl: string;
    is_non_owned_autos: string;
    is_ocean: string;
    is_owned_autos: string;
    is_partial: string;
    is_rail: string;
    is_scheduled_autos: string;
    is_truckload: string;
    its_cpr_reports: string;
    its_suspension_reason: string;
    job_class_code_id: string;
    last_contacted: Date;
    last_contacted_by: string;
    last_upd_rate: Date;
    leased_tractors: number;
    leased_trailers: number;
    liab_cancel_date: Date;
    liab_expire_date: Date;
    liab_insur_fax: string;
    liab_insur_phone: string;
    liability_amount: number;
    liability_amount_c: string;
    liability_amount_d: Date;
    liability_amount_n: number;
    liability_amount_r: number;
    liability_email: string;
    liability_insurer: string;
    liability_percent: number;
    liability_policy: string;
    liability_reqd: number;
    liability_reqd_c: string;
    liability_reqd_d: Date;
    liability_reqd_n: number;
    liability_reqd_r: number;
    loaded_pay_rate: number;
    loadpay_accepted_status: string;
    loadpay_acct_id: string;
    loadpay_contact_email: string;
    loadpay_contact_name: string;
    loadpay_date: Date;
    logo: string;
    logo_name: string;
    macropoint_provider_code: string;
    mark_plan_id: string;
    mc_payroll_pin: string;
    mcs_150_date: Date;
    mcs_150_mileage: number;
    mcs_150_mileage_yr: number;
    method: string;
    mileage_code: string;
    minimum_pay: number;
    minimum_pay_c: string;
    minimum_pay_d: Date;
    minimum_pay_n: number;
    minimum_pay_r: number;
    monitor_ins: string;
    monitor_ins_basic: string;
    mpw_carrier_contact_method: string;
    mpw_carrier_high: number;
    mpw_carrier_low: number;
    mpw_exclude: string;
    mpw_last_contact: Date;
    mpw_num_attempts: number;
    mpw_num_orders: number;
    night_phone_number: string;
    no_dispatch: string;
    num_days_to_pay: number;
    onboarded: string;
    onboarding_status: string;
    operations_class: string;
    ops_class_descr: string;
    order_entry_url: string;
    out_of_service: string;
    override_qual_date: Date;
    override_qual_enf: string;
    override_reason: string;
    overtime_cutoff: number;
    overtime_hours_pd: number;
    overtime_rate: number;
    owned_tractors: number;
    owned_trailers: number;
    p44_cap_provider_code: string;
    p44_profile_id: string;
    parent_contract: number;
    partner_tar_uid: number;
    partner_tariff_id: string;
    passenger: string;
    pay_advances: string;
    pay_factoring_company: string;
    pay_method: string;
    pay_option: string;
    pay_rate_group: string;
    payee_id: string;
    payment_frequency: string;
    payroll_run: string;
    pend_broker_auth: string;
    pend_common_auth: string;
    pend_contract_auth: string;
    pend_rev_broker: string;
    pend_rev_common: string;
    pend_rev_contract: string;
    perdiem_rate: number;
    perform_rating: string;
    perform_rating_dt: Date;
    period_loads: number;
    period_miles: number;
    period_revenue: number;
    period_revenue_c: string;
    period_revenue_d: Date;
    period_revenue_n: number;
    period_revenue_r: number;
    power_units: number;
    print_1099: string;
    print_driver_orders_dtl: string;
    process_status: string;
    property: string;
    pu_pay_amount: number;
    pu_pay_amount_c: string;
    pu_pay_amount_d: Date;
    pu_pay_amount_n: number;
    pu_pay_amount_r: number;
    puc_number: string;
    quickpay_amount: number;
    quickpay_basis: string;
    quickpay_contract: Date;
    quickpay_daystopay: number;
    quickpay_deduct_id: string;
    quickpay_enabled: string;
    quickpay_min_chg: number;
    quickpay_min_chg_c: string;
    quickpay_min_chg_d: Date;
    quickpay_min_chg_n: number;
    quickpay_min_chg_r: number;
    recur_tractor_last_created: Date;
    regular_hours_pd: number;
    regular_rate: number;
    reimburse_fuel: string;
    req_dispatch_over: string;
    risk_assessment: string;
    rmis_carrier_id: string;
    safety_fitness: string;
    safety_fitness_dt: Date;
    safety_rate_rev_dt: Date;
    safety_rating: string;
    safety_rating_dt: Date;
    safety_reg_date: Date;
    safety_reg_status: string;
    safety_review_type: string;
    salesperson_id: string;
    scac: string;
    sea_accident: number;
    sea_driver: number;
    sea_safety_mgmt: number;
    sea_vehicle: number;
    search_city_id: number;
    search_city_name: string;
    search_state: string;
    search_zip_code: string;
    send_to: string;
    sick_earned: number;
    sick_hours_due: number;
    sick_hours_pd: number;
    smartway_certified: string;
    smartway_score: number;
    so_pay_amount: number;
    so_pay_amount_c: string;
    so_pay_amount_d: Date;
    so_pay_amount_n: number;
    so_pay_amount_r: number;
    start_date: Date;
    taxable_owed: number;
    taxable_owed_c: string;
    taxable_owed_d: Date;
    taxable_owed_n: number;
    taxable_owed_r: number;
    team_empty_rate: number;
    team_method: string;
    team_pay_rate: number;
    term_code: string;
    term_is_quick_pay: string;
    terminal_id: string;
    tiered_stop_id: string;
    total_inspection: number;
    trackid_carrier_onboarding: number;
    trackid_document_request: number;
    trackid_expiring_insurance: number;
    trailer_rent: number;
    trainee_rate: number;
    trainer_rate: number;
    type_of: string;
    unsafe_driving_oa: string;
    unsafe_driving_ot: string;
    unsafe_driving_pct: number;
    unsafe_driving_sv: string;
    vacation_earned: number;
    vacation_hours_due: number;
    vacation_hours_pd: number;
    vacation_hrs_base: number;
    vacation_pay_rate: number;
    veh_oos_inspection: number;
    vehicle_inspection: number;
    vehicle_maint_oa: string;
    vehicle_maint_ot: string;
    vehicle_maint_pct: number;
    vehicle_maint_sv: string;
    via: string;
    w9_on_file: string;
    watchdog_report: string;
    wats_phone_number: string;
    workmans_comp: number;
    workmanscomp_date: Date;
    ytd_401k_catchup: number;
    ytd_401k_catchup_c: string;
    ytd_401k_catchup_d: Date;
    ytd_401k_catchup_n: number;
    ytd_401k_catchup_r: number;
    ytd_401k_contrib: number;
    ytd_401k_contrib_c: string;
    ytd_401k_contrib_d: Date;
    ytd_401k_contrib_n: number;
    ytd_401k_contrib_r: number;
    ytd_city_gross: number;
    ytd_city_gross_c: string;
    ytd_city_gross_d: Date;
    ytd_city_gross_n: number;
    ytd_city_gross_r: number;
    ytd_city_tax_wh: number;
    ytd_city_tax_wh_c: string;
    ytd_city_tax_wh_d: Date;
    ytd_city_tax_wh_n: number;
    ytd_city_tax_wh_r: number;
    ytd_county_wages: number;
    ytd_county_wages_c: string;
    ytd_county_wages_d: Date;
    ytd_county_wages_n: number;
    ytd_county_wages_r: number;
    ytd_county_wh: number;
    ytd_county_wh_c: string;
    ytd_county_wh_d: Date;
    ytd_county_wh_n: number;
    ytd_county_wh_r: number;
    ytd_ett_wages: number;
    ytd_ett_wages_c: string;
    ytd_ett_wages_d: Date;
    ytd_ett_wages_n: number;
    ytd_ett_wages_r: number;
    ytd_fed_gross: number;
    ytd_fed_gross_c: string;
    ytd_fed_gross_d: Date;
    ytd_fed_gross_n: number;
    ytd_fed_gross_r: number;
    ytd_fed_withheld: number;
    ytd_fed_withheld_c: string;
    ytd_fed_withheld_d: Date;
    ytd_fed_withheld_n: number;
    ytd_fed_withheld_r: number;
    ytd_fica_hi_gros: number;
    ytd_fica_hi_gros_c: string;
    ytd_fica_hi_gros_d: Date;
    ytd_fica_hi_gros_n: number;
    ytd_fica_hi_gros_r: number;
    ytd_fica_hi_wh: number;
    ytd_fica_hi_wh_c: string;
    ytd_fica_hi_wh_d: Date;
    ytd_fica_hi_wh_n: number;
    ytd_fica_hi_wh_r: number;
    ytd_fica_oadi_gr: number;
    ytd_fica_oadi_gr_c: string;
    ytd_fica_oadi_gr_d: Date;
    ytd_fica_oadi_gr_n: number;
    ytd_fica_oadi_gr_r: number;
    ytd_fica_oadi_wh: number;
    ytd_fica_oadi_wh_c: string;
    ytd_fica_oadi_wh_d: Date;
    ytd_fica_oadi_wh_n: number;
    ytd_fica_oadi_wh_r: number;
    ytd_fui_gross: number;
    ytd_fui_gross_c: string;
    ytd_fui_gross_d: Date;
    ytd_fui_gross_n: number;
    ytd_fui_gross_r: number;
    ytd_holiday_hrs: number;
    ytd_loads: number;
    ytd_miles: number;
    ytd_overtime_hrs: number;
    ytd_puc_gross: number;
    ytd_puc_gross_c: string;
    ytd_puc_gross_d: Date;
    ytd_puc_gross_n: number;
    ytd_puc_gross_r: number;
    ytd_puc_withheld: number;
    ytd_puc_withheld_c: string;
    ytd_puc_withheld_d: Date;
    ytd_puc_withheld_n: number;
    ytd_puc_withheld_r: number;
    ytd_reg_hrs_paid: number;
    ytd_revenue: number;
    ytd_revenue_c: string;
    ytd_revenue_d: Date;
    ytd_revenue_n: number;
    ytd_revenue_r: number;
    ytd_sdi_gross: number;
    ytd_sdi_gross_c: string;
    ytd_sdi_gross_d: Date;
    ytd_sdi_gross_n: number;
    ytd_sdi_gross_r: number;
    ytd_sdi_withheld: number;
    ytd_sdi_withheld_c: string;
    ytd_sdi_withheld_d: Date;
    ytd_sdi_withheld_n: number;
    ytd_sdi_withheld_r: number;
    ytd_sick_hrs_paid: number;
    ytd_state_gross: number;
    ytd_state_gross_c: string;
    ytd_state_gross_d: Date;
    ytd_state_gross_n: number;
    ytd_state_gross_r: number;
    ytd_state_wh: number;
    ytd_state_wh_c: string;
    ytd_state_wh_d: Date;
    ytd_state_wh_n: number;
    ytd_state_wh_r: number;
    ytd_sui_gross: number;
    ytd_sui_gross_c: string;
    ytd_sui_gross_d: Date;
    ytd_sui_gross_n: number;
    ytd_sui_gross_r: number;
    ytd_sui_withheld: number;
    ytd_sui_withheld_c: string;
    ytd_sui_withheld_d: Date;
    ytd_sui_withheld_n: number;
    ytd_sui_withheld_r: number;
    ytd_total_gross: number;
    ytd_total_gross_c: string;
    ytd_total_gross_d: Date;
    ytd_total_gross_n: number;
    ytd_total_gross_r: number;
    ytd_vacation_hrs: number;
}

