import { Button, DataSource, HorizontalSpacer, Label, Layout, Panel, Table, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutQuoteRates extends Layout {
    buttonEstimateFuelSurcharge: Button;
    buttonExpand: Button;
    buttonRate: Button;
    horizontalspacer3: HorizontalSpacer;
    labelAdditionalCharges: Label;
	labelAutoRated: Label;
	labelTotalCharge: Label;
	panelOtherChargeTable: Panel;
	panelOtherCharges: Panel;
	panelOtherChargesHeader2: Panel;
	panelOtherChargesHeader: Panel;
	panelRateInfo: Panel;
	sourceOrderOtherChargeQuote: DataSource;
	sourceQuoteOrder: DataSource;
	tableOtherCharges: Table;
	textboxBillDistance: Textbox;
	textboxFreightCharge: Textbox;
	textboxRate: Textbox;
	textboxRateType: Textbox;
	textboxRateUnits: Textbox;
}
