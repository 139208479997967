import { McLeodMainPage } from "@mcleod/common";

const rootElement = document.getElementById("root");
if (rootElement == null)
    throw new Error("Could not mount component because DOM element named root could not be found.")
const mainPage = new McLeodMainPage({
    modules: [
        { path: "designer", context: require["context"]("@mcleod/designer/src", true) },
        { path: "component-demo", context: require["context"]("@mcleod/component-demo/src", true) },
        { path: "common", context: require["context"]("@mcleod/common/src", true) },
        { path: "lme/ar", context: require["context"]("@mcleod/ar/src", true) },
        { path: "lme/dispatch", context: require["context"]("@mcleod/dispatch/src", true) },
        { path: "lme/general", context: require["context"]("@mcleod/general/src", true) },
        { path: "lme/datafusion", context: require["context"]("@mcleod/datafusion/src", true) },
        { path: "lme/powerbroker", context: require["context"]("@mcleod/powerbroker/src", true) }
    ]
});
rootElement.appendChild(mainPage._element);
