import { Button, Checkbox, Label, Layout, Panel, Textbox, } from "@mcleod/components";

export abstract class AutogenLayoutDemoTooltip extends Layout {
    butonShow: Button;
    checkFancyTooltip: Checkbox;
    checkShakeTooltip: Checkbox;
    labelTooltips: Label;
    panel1: Panel;
    panel2: Panel;
    textTooltipAnchor: Textbox;
}
