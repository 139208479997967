/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelGroupMembership.ts               *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "common/group-membership";

export class AutogenModelGroupMembership<RowType extends ModelRow> extends Endpoint<RowType, RowGroupMembershipFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowGroupMembership extends ModelRow<RowGroupMembershipFields> {
    constructor(values?: Partial<RowGroupMembershipFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowGroupMembershipFields {
    company_id: string;
    user_group_id: string;
    user_id: string;
}

