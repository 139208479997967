/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelModelPermsAction.ts              *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "common/ModelPermsAction";

export class AutogenModelModelPermsAction<RowType extends ModelRow> extends Endpoint<RowType, RowModelPermsActionFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowModelPermsAction extends ModelRow<RowModelPermsActionFields> {
    constructor(values?: Partial<RowModelPermsActionFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowModelPermsActionFields {
    company_id: string;
    id: string;
}

