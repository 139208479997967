/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelBrokeragePlanningProfile.ts      *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/powerbroker/brokerage-planning-profile";

export class AutogenModelBrokeragePlanningProfile<RowType extends ModelRow> extends Endpoint<RowType, RowBrokeragePlanningProfileFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowBrokeragePlanningProfile extends ModelRow<RowBrokeragePlanningProfileFields> {
    constructor(values?: Partial<RowBrokeragePlanningProfileFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowBrokeragePlanningProfileFields {
    brk_qual_profile: string;
    brk_qual_status: string;
    broke_avail_type: string;
    broke_avail_zones: string;
    broke_carrier: string;
    broke_days: number;
    broke_perform_rate: string;
    broke_safety_rate: string;
    broke_team_drs: string;
    broke_trlr_type: string;
    brokerage_status: string;
    brokerage_status_exclude: string;
    carrier_cont_type: string;
    company_id: string;
    contract_signed: string;
    default_dest_filter: string;
    default_origin_filter: string;
    default_tabsetting: string;
    default_view: string;
    dispatcher_user_id: string;
    dispatcher_user_id_exclude: string;
    display_until_ready_to_bill: string;
    exact_lane_type_search: string;
    id: string;
    include_on_hold_orders: boolean;
    insurance_current: string;
    int_search_profile_id: string;
    minimum_score: number;
    movement_type: string;
    operations_user: string;
    operations_user_exclude: string;
    ops_status: string;
    order_customers: string;
    order_customers_exclude: string;
    order_days: number;
    order_gridconfig: string;
    order_ib_exclude: string;
    order_ib_type: string;
    order_ib_zones: string;
    order_ob_exclude: string;
    order_ob_type: string;
    order_ob_zones: string;
    order_resp_codes: string;
    order_resp_level: number;
    order_salesperson: string;
    order_salesperson_exclude: string;
    order_trlr_type: string;
    order_trlr_type_exclude: string;
    order_type: string;
    order_type_exclude: string;
    override_payee_id: string;
    performance_rating: string;
    preass_order: string;
    progress_move: string;
    revenue_code: string;
    revenue_code_exclude: string;
    safety_rating: string;
    show_broker_moves: string;
    show_exp_trk: string;
    show_ltl_orders: string;
    show_outside_tract: string;
    start_day: string;
    title: string;
    tractor_gridconfig: string;
    tractor_resp_codes: string;
    tractor_resp_level: number;
    tractr_salesperson: string;
    user_id: string;
}

