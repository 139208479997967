import { CommonDialogs } from "@mcleod/common";
import { ClickEvent, Toast } from "@mcleod/components";
import { Api } from "@mcleod/core";
import { StopList } from "./StopList";
import { AutogenLayoutCancelStopSelection } from "./autogen/AutogenLayoutCancelStopSelection";

export class CancelStopSelection extends AutogenLayoutCancelStopSelection {
    private _doAfterClose: (canceled: boolean) => void;

    override onLoad() {
        (this.layout1 as StopList).showCommentsAndReference = false;
    }

    private unclearStops() {
        this.savebutton1.primaryButton.busy = true;
        return Api.search("lme/dispatch/unclear-stops",
            {
                "movement_id": this.sourceMovement.activeRow.get("id"),
                "stop_id": (this.layout1 as StopList).tableStop.selectedRow.data.get("id"),
                "leave_carrier_assigned": true
            }).then(response => {
                this.close(false);
                Toast.showSuccessToast("Your stops were successfully rolled back");
            }).catch(error => CommonDialogs.showError(error))
            .finally(() => { this.savebutton1.primaryButton.busy = false });
    }


    savebutton1OnClick(event: ClickEvent) {
        this.unclearStops();
    }

    close(canceled: boolean) {
        this.slideOut().then(() => {
            if (this.doAfterClose != null)
                this.doAfterClose(canceled);
        });
    }

    public set doAfterClose(f) {
        this._doAfterClose = f;
    }

    public get doAfterClose() {
        return this._doAfterClose;
    }
}
