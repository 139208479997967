/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelOrderType.ts                     *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/dispatch/order-type";

export class AutogenModelOrderType<RowType extends ModelRow> extends Endpoint<RowType, RowOrderTypeFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowOrderType extends ModelRow<RowOrderTypeFields> {
    constructor() {
        super(AutogenUserSavedSearchEndpoint);
    }
}

export interface RowOrderTypeFields {
    company_id: string;
    descr: string;
    id: string;
    is_active: string;
    order_mode: string;
    planning_color: number;
}

