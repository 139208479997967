export enum SelectionMode {
    NONE = "none",
    SINGLE = "single",
    MULTI = "multi"
}

export enum SelectionType {
    SINGLE = "basic",
    RANGE = "range",
    DISTINCT = "distinct"
}
