import { Button, DataSource, Layout, Panel, Table, Textbox, } from "@mcleod/components";

export abstract class AutogenLayoutCarrierOfferSearch extends Layout {
    buttonSearch: Button;
    panel1: Panel;
    panel2: Panel;
    sourceCarrierOffer: DataSource;
    table1: Table;
    textboxOfferDate: Textbox;
    textboxOrderId: Textbox;
}
