import { Button, ClickEvent, HorizontalSpacer, Image, Label, Panel, Table } from "@mcleod/components";
import { ComponentProps } from "@mcleod/components/src/base/ComponentProps";
import { DataSourceAction } from "@mcleod/components/src/databinding/DataSource";
import { HorizontalAlignment, ModelRow } from "@mcleod/core";
import { CLEARED_STATUS_CODE } from "./Movement";
import { AutogenLayoutStopProgress } from "./autogen/AutogenLayoutStopProgress";

export class StopProgress extends AutogenLayoutStopProgress {
    private onStopClick: (event: ClickEvent, indexClicked: number) => void;

    setStopsTable(stopsTable: Table) {
        if (stopsTable?.dataSource == null) return;

        const onStopClick = (event: ClickEvent, indexClicked: number) => {
            indexClicked = indexClicked ?? 1;
            if (indexClicked < stopsTable.rowCount)
                stopsTable.rows[indexClicked].scrollIntoView();
        }

        if (stopsTable.dataSource.data?.length > 0) {
            this.setStops(stopsTable.dataSource.data, onStopClick);
        } else {
            stopsTable.dataSource.addAfterExecutionListener(event => {
                if (event.getAction() === DataSourceAction.SEARCH)
                    this.setStops(stopsTable.dataSource.data, onStopClick);
            });
        }
    }

    setStops(stops: ModelRow<any>[], onStopClick: (event: ClickEvent, indexClicked: number) => void) {
        this.onStopClick = onStopClick;
        this.panelStopHeader.removeAll();
        const addSpacer = function (stopStatusPanel: Panel, props?: Partial<ComponentProps>) {
            if (props == null) props = {};
            props.fillHeight = true;
            stopStatusPanel.add(new HorizontalSpacer(props));
        }

        const addTopSpacer = function (stopStatusPanel: Panel, props: Partial<ComponentProps>) {
            if (props.borderBottomColor != null)
                props.borderBottomWidth = 3;
            props.height = 20;
            props.maxHeight = 20;
            addSpacer(stopStatusPanel, props);
        }

        let prevStopCleared = false;

        const smallFillerProps: Partial<ComponentProps> = { width: 75, fillRow: false };

        stops?.forEach((stop, index) => {
            const lastStop = index + 1 == stops.length;
            const stopCityState = stop.get("city_name", "") + ", " + stop.get("state", "");
            const cleared = stop.get("status") == CLEARED_STATUS_CODE;

            const stopStatusPanel = new Panel({ fillHeight: true, fillRow: true, padding: 0, rowBreak: false, wrap: false });

            if (index == 0)
                addTopSpacer(stopStatusPanel, smallFillerProps);
            else if (stops.length == 3 || lastStop || index == 1)
                addTopSpacer(stopStatusPanel, { borderBottomColor: prevStopCleared ? "warning" : "strokeSecondary" });

            if (index == 0 || stops.length == 3 || lastStop)
                this.addStopBubble(cleared, stopStatusPanel, stop, index);
            else if (index == 1)
                this.addMoreStopsButton(stops, stopStatusPanel);

            if (lastStop)
                addTopSpacer(stopStatusPanel, { ...smallFillerProps, rowBreak: true });
            else if (index == 0 || index == 1)
                addTopSpacer(stopStatusPanel, { rowBreak: true, borderBottomColor: cleared ? "warning" : "strokeSecondary" });

            if (index == 0 || stops.length == 3 || lastStop)
                this.addCityStateInfo(addSpacer, stopStatusPanel, index, smallFillerProps, stopCityState, lastStop);

            prevStopCleared = stop.get("actual_departure") != null;
        });
    }

    private addCityStateInfo(addSpacer: (stopStatusPanel: Panel, props?: Partial<ComponentProps>) => void, stopStatusPanel: Panel, index: number, smallFillerProps: Partial<ComponentProps>, stopCityState: string, lastStop: boolean) {
        addSpacer(stopStatusPanel, (index == 0) ? smallFillerProps : null);
        stopStatusPanel.add(new Label({ rowBreak: false, caption: stopCityState, fontSize: "small", fontBold: true }));
        addSpacer(stopStatusPanel, lastStop ? smallFillerProps : null);
        this.panelStopHeader.add(stopStatusPanel);
    }

    private addStopBubble(cleared: boolean, stopStatusPanel: Panel, stop: ModelRow<any>, index: number) {

        const stopClicked = (event) => {
            if (this.onStopClick != null)
                this.onStopClick(event, index);
        };

        if (cleared) {
            stopStatusPanel.add(new Image({
                borderRadius: 50,
                color: "warning",
                height: 30,
                width: 30,
                marginTop: 5,
                rowBreak: false,
                name: "circleCheck",
                onClick: stopClicked
            }));
        } else {
            stopStatusPanel.add(new Label({
                align: HorizontalAlignment.CENTER,
                borderWidth: 3,
                borderColor: "strokeSecondary",
                borderRadius: 50,
                color: "subtle.darker",
                fontSize: "xlarge",
                height: 30,
                width: 30,
                marginTop: 5,
                rowBreak: false,
                caption: (index + 1) + "",
                onClick: stopClicked
            }));

        }
    }

    private addMoreStopsButton(stops: ModelRow<any>[], stopStatusPanel: Panel) {
        const button: Button = this.makeExtraStopsButton(stops);
        stopStatusPanel.add(button);
        this.panelStopHeader.add(stopStatusPanel);
    }

    private makeExtraStopsButton(stops: ModelRow<any>[]) {
        const moreStopsButton: Button = new Button({ width: 300, height: 40, rowBreak: false });
        moreStopsButton.caption = "See " + (stops.length - 2) + " Other Stops";
        moreStopsButton.borderRadius = 20;
        moreStopsButton.paddingBottom = 10;
        moreStopsButton.fontBold = true;
        moreStopsButton.fontSize = "large";
        moreStopsButton.backgroundColor = "warning";
        moreStopsButton.color = "white";
        moreStopsButton.padding = 0;
        moreStopsButton.margin = 0;
        if (this.onStopClick != null)
            moreStopsButton.addClickListener((event) => this.onStopClick(event, null));
        return moreStopsButton;
    }
}
