import { Dialog, Label, Layout } from "@mcleod/components";

export class DemoMovableDialog extends Layout {
    getDemoPath() {
        return ["Dialogs", "Movable"];
    }

    buttonTryItOnClick(event) {
        const dlg = new Dialog({
            movable: true,
            resizable: true,
            modal: false
        });
        dlg.add(new Label({ caption: "Test" }));
        dlg.show();
    }
}
