import { CommonTooltips } from "@mcleod/common";
import { ClickEvent, CrudDecorator, DataSourceMode, Layout, SlideoutDecorator, Textbox } from "@mcleod/components";
import { Api, Currency, CurrencyUtil, FieldUpdateEvent, ModelRow, StringUtil } from "@mcleod/core";
import { Movement } from "./Movement";
import { ShipmentStatusSlideout } from "./ShipmentStatusSlideout";
import { AutogenLayoutOrderOverview } from "./autogen/AutogenLayoutOrderOverview";

export class OrderOverview extends AutogenLayoutOrderOverview {

    override onLoad() {
        this.textboxContactName._captionLabel.color = "primary.reverse";
        this.textboxSalespersonId._captionLabel.color = "primary.reverse";
        this.textboxName._captionLabel.color = "primary.reverse";
    }

    sourceOrdersAfterExecution(event) {
        const row = this.mainDataSource.activeRow;
        this.textboxCommodity.text = row.getFirstLookupModelData("commodity_id")?.get("descr");
        this.addReferenceNumberTextboxes();
        if (event?.getAction() == "search") {
            this.setCarrierRateData();
            const customerId = row?.get("customer_id");
            if (customerId != null)
                this.sourceCustomer.search({ "id": customerId });

            row.addAfterFieldUpdateListener((event: FieldUpdateEvent) => this.orderFieldUpdated(event))

            this.panelDelivered.visible = "D" == this.mainDataSource.activeRow.get("status");
            this.panelCommodity.visible = "D" != this.mainDataSource.activeRow.get("status");
        }

        this.updateContactInformation();
        this.updateDataFusionInformation();
    }

    updateDataFusionInformation() {
        const hasEdi: boolean = this.mainDataSource.activeRow?.get("has_edi");
        const hasLogistics: boolean = this.mainDataSource.activeRow?.get("has_logistics");
        const hasOutStatuses: boolean = this.mainDataSource.activeRow?.get("has_statuses");
        const hasInStatuses: boolean = this.mainDataSource.activeRow?.get("has_log_statuses");
        const hasLogTenders: boolean = this.mainDataSource.activeRow?.get("has_log_tenders");
        const inTenderCount: number = this.mainDataSource.activeRow?.get("in_tender_count");
        const outTenderCount: number = this.mainDataSource.activeRow?.get("out_tender_count");
        const inTenderError = this.mainDataSource.activeRow?.get("in_tender_error");
        const outTenderError = this.mainDataSource.activeRow?.get("out_tender_error");

        if (!hasEdi && !hasLogistics || (!hasOutStatuses && !hasInStatuses && !hasLogTenders && inTenderCount == 0)) {
            this.panelDataFusion.visible = false;
        }
        else {
            if (hasLogTenders) {
                this.labelOutTenderCount.caption = outTenderCount?.toString();
                if (outTenderError) {
                    this.labelOutTenderCount.color = "error";
                    this.outTenderErrorImage.visible = true;
                    this.outTenderErrorImage.tooltip = "One or more tenders have errors";
                }
                else {
                    this.outTenderErrorImage.visible = false;
                }
            }
            else {
                this.labelOutTenders.visible = false;
                this.labelOutTenderCount.visible = false;
                this.outTenderErrorImage.visible = false;
            }

            if (inTenderCount > 0) {
                this.labelInTenders.visible = true;
                this.labelInLoadTenderCount.caption = inTenderCount.toString();
                if (inTenderError) {
                    this.labelInLoadTenderCount.color = "error";
                    this.inTenderErrorImage.visible = true;
                    this.inTenderErrorImage.tooltip = "One or more tenders have errors";
                }
                else {
                    this.inTenderErrorImage.visible = false;
                }
            }
            else {
                this.labelInTenders.visible = false;
                this.labelInLoadTenderCount.visible = false;
                this.inTenderErrorImage.visible = false;
            }

            if (hasOutStatuses) {
                const outboundRows = this.mainDataSource.activeRow?.get("edi_statuses_out");
                this.labelOutboundStatusCount.visible = true;
                this.labelOutboundStatusCount.color = "primary.dark";
                this.outStatusErrorImage.visible = false;

                if (outboundRows && outboundRows.length > 0) {
                    this.labelOutboundStatusCount.caption = outboundRows.length.toString();

                    for (let i = 0; i < outboundRows.length; i++) {
                        const statusRow: ModelRow = outboundRows[i];
                        const error = statusRow["status_error"];
                        if (error > 0) {
                            this.labelOutboundStatusCount.color = "error";
                            this.outStatusErrorImage.color = "error";
                            this.outStatusErrorImage.visible = true;
                            this.outStatusErrorImage.tooltip = "One or more outbound shipment statuses have errors";
                            break;
                        }
                        else {
                            this.outStatusErrorImage.visible = false;
                            this.labelOutboundStatusCount.color = "primary.dark";
                        }
                    }
                }
            }
            else {
                this.labelOutboundShipmentStatuses.visible = false;
                this.labelOutboundStatusCount.visible = false;
                this.outStatusErrorImage.visible = false;
            }

            if (hasInStatuses) {
                this.labelInboundShipmentStatuses.visible = true;

                const inboundRows: ModelRow[] = this.mainDataSource.activeRow?.get("edi_statuses_in");
                this.labelInboundStatusCount.visible = true;
                this.labelInboundStatusCount.color = "primary.dark";
                this.inStatusErrorImage.visible = false;
                let logisticsErrorExists: boolean = false;

                if (inboundRows?.length > 0) {
                    this.labelInboundStatusCount.caption = inboundRows.length.toString();
                    for (let i = 0; i < inboundRows.length; i++) {
                        const statusRow: ModelRow = inboundRows[i];
                        const error = statusRow["status_error"];

                        if (error > 0) {
                            logisticsErrorExists = true;
                        }
                    }

                    if (logisticsErrorExists) {
                        this.labelInboundStatusCount.color = "error";
                        this.inStatusErrorImage.color = "error";
                        this.inStatusErrorImage.visible = true;
                        this.inStatusErrorImage.tooltip = "One or more inbound shipment statuses have errors";
                    }
                    else {
                        this.inStatusErrorImage.visible = false;
                    }
                }
            }
            else {
                this.labelInboundShipmentStatuses.visible = false;
                this.labelInboundStatusCount.visible = false;
                this.inStatusErrorImage.visible = false;
            }
        }
    }

    orderFieldUpdated(event: FieldUpdateEvent) {
        if ("customer_id" == event.fieldName && StringUtil.isEmptyString(event.newValue))
            this.customerChanged(null);
        else if ("commodity_id" == event.fieldName)
            this.textboxCommodity.text = event.originator?.text || "--";
        else if ("ordered_by" == event.fieldName)
            this.updateContactInformation();
    }

    updateContactInformation() {
        this.textboxContactName.text = this.mainDataSource.activeRow?.getFirstLookupModelData("ordered_by")?.get("name");
        this.labelContactEmail.caption = this.mainDataSource.activeRow?.getFirstLookupModelData("ordered_by")?.get("email");
        this.labelContactPhone.caption = this.mainDataSource.activeRow?.getFirstLookupModelData("ordered_by")?.get("phone");

        if (this.mainDataSource.activeRow?.getFirstLookupModelData("ordered_by")?.get("id") == null)
            this.mainDataSource.activeRow.set("ordered_by_id", null);
    }

    textboxNameOnDataDisplay() {
        CommonTooltips.setTooltipFromLayoutCallback(this.textboxName, this.activeRow.get("customer_id"), "lme/ar/CustomerQuickInfo");
    }

    customerChanged(customerData: ModelRow<any>) {
        this.sourceCustomer.data = [];
        if (customerData != null) {
            this.sourceCustomer.data = [customerData];
            this.sourceCustomer.setRowIndexWithoutUpdate(0);
        }
        this.sourceCustomer.displayDataInBoundComponents();
    }

    private addReferenceNumberTextboxes(referenceNumbers?: ModelRow<any>[]) {
        this.panelRefNbrs.removeAll();
        const row = this.sourceOrders.activeRow;
        this.addReferenceNumberTextbox("BOL", row.get("blnum"), "blnum");
        this.addReferenceNumberTextbox("Consignee Reference Number", row.get("consignee_refno"), "consignee_refno");
    }

    addReferenceNumberTextbox = function (caption: string, text: string, field?: string) {
        if (caption != null && text != null) {
            const textbox = new Textbox({ marginBottom: 3, caption: caption, text: text, printable: true, fontSize: "large", fontBold: true, fillRow: true });
            textbox._captionLabel.fontSize = "medium";
            if (field != null) {
                textbox.field = field;
                textbox.dataSource = this.mainDataSource;
            }
            this.panelRefNbrs.add(textbox);
        }
    }

    setCarrierRateData() {
        const moveId = this.mainDataSource.activeRow.get("movement.id");
        if (moveId != null) {
            Api.search("lme/dispatch/calculate-override-pay",
                {
                    "movement_id": moveId
                }).then(response => {
                    const setCurrency = function (textbox: Textbox, currency: Currency) {
                        if (currency != null) {
                            textbox.text = CurrencyUtil.formatCurrency(currency);
                        }
                    }
                    const data = response?.data?.[0];
                    setCurrency(this.textboxTotalPay, data?.total_pay);
                    setCurrency(this.textboxMargin, data?.margin);
                });
        }

    }

    buttonMoveDetailsOnClick(event: ClickEvent) {
        const movePanel = new CrudDecorator({
            layout: Layout.getLayout("lme/dispatch/Movement") as Movement,
            mode: DataSourceMode.UPDATE,
            key: this.mainDataSource?.activeRow.get("curr_movement_id"),
            headerProps: {
                showClose: true,
                showSaveAndClose: true,
                onSaveButtonClose: () => {
                    movePanel.slideOut().then(() => {
                        this.mainDataSource.search({ id: this.mainDataSource.activeRow.get("id") });
                    });
                }
            }
        });
        movePanel.slideIn({ speed: 200 });
    }

    datafusionCompCountOnClick(event: ClickEvent) {
        const id = this.mainDataSource.activeRow?.data?.["id"];
        const moveId = this.mainDataSource.activeRow?.data?.["movement.id"];
        const customerId = this.mainDataSource.activeRow?.data?.["customer_id"];
        const selectedComp = event.target.id;

        if (id) {
            const layout = Layout.getLayout("lme/dispatch/ShipmentStatusSlideout", {
                //maxWidth: 900,
                width: window.innerWidth * .80,
                backgroundColor: "defaultBackground",
                fillHeight: true,
                paddingBottom: 50
            }) as ShipmentStatusSlideout;

            const decorator = new SlideoutDecorator({
                layout: layout,
                title: "DataFusion - Order " + id,
                fillVerticalSpace: true,
                addlComponents: [layout.saveButton],
                doAfterSlideIn: (decorator: SlideoutDecorator) => {
                    layout.sourceOrderEdistatus.search({ order_id: id });
                    layout.sourceEdiOrder.search({ order_id: id, movement_id: moveId });
                    let selIndex: number = 0;
                    layout.saveButton.addClickListener(event => {
                        if (decorator.validateSimple()) {
                            const dataLayout = layout.outboundDataSource.data;
                            dataLayout?.forEach((data, index) => {
                                if (data.hasChanged()) {
                                    layout.outboundDataSource.data[index].post();
                                }
                            });
                        }
                    });
                    if (selectedComp == this.labelInboundStatusCount.id) {
                        selIndex = layout.tabset1.indexOf(layout.tabInbound);
                    } else if (selectedComp == this.labelInLoadTenderCount.id) {
                        selIndex = layout.tabset1.indexOf(layout.tabInboundTender);
                    } else if (selectedComp == this.labelOutboundStatusCount.id) {
                        selIndex = layout.tabset1.indexOf(layout.tabOutbound);
                    } else if (selectedComp == this.labelOutTenderCount.id) {
                        selIndex = layout.tabset1.indexOf(layout.tabOutboundTender);
                    }
                    layout.tabset1.selectedIndex = selIndex;
                },
                onClose: () => {
                    decorator.slideOut();
                }
            });

            layout.orderId = id;
            layout.movementId = moveId;
            layout.customerId = customerId;
        }
    }
}
