import { TableRowDisplayEvent, TableRow, DataSourceMode, Table, Checkbox, Label } from "@mcleod/components";
import { AutogenLayoutFreightGroupItemCompare } from "./autogen/AutogenLayoutFreightGroupItemCompare";
import { ModelRow } from "@mcleod/core";
import { FreightGroupItemCompareChangeItem } from "./FreightGroupItemCompareChangeItem";
import { FreightGroupItemCompareItem } from "./FreightGroupItemCompareItem";
import { RowEdiCompareItem } from "./models/ModelEdiCompareItem";

export class FreightGroupItemCompare extends AutogenLayoutFreightGroupItemCompare {
    private _newOrRemovedFreightGroupItems: ModelRow[];
    private _changedFreightGroupItems: ModelRow[];

    setupTableData(mode: DataSourceMode) {
        if (this.newOrRemovedFreightGroupItems == null || this.newOrRemovedFreightGroupItems.length == 0) {
            this.labelAddsAndDeletes.visible = false;
            this.tableFreightGroupItemAdd.visible = false;
        } else {
            this.loadChildTableData(this.tableFreightGroupItemAdd, this.newOrRemovedFreightGroupItems, mode)
        }
        if (this.changedFreightGroupItems == null || this.changedFreightGroupItems.length == 0) {
            this.labelChanges.visible = false;
            this.tableFreightGroupItemChange.visible = false;
        } else {
            this.loadChildTableData(this.tableFreightGroupItemChange, this.changedFreightGroupItems, mode);
        }
    }

    onFreightGroupItemAddDeleteRowDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.getTableRow();
        const checkboxApply = tableRow.findComponentById("checkboxApply") as Checkbox;
        checkboxApply.printable = false;
        const labelChecked = tableRow.findComponentById("labelChecked") as Label;
        checkboxApply.addClickListener(() => labelChecked.visible = checkboxApply.checked);
        this.setCompareItems(this.newOrRemovedFreightGroupItems, tableRow);
    }

    onFreightGroupItemChangeRowDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.getTableRow();
        this.setCompareItems(this.changedFreightGroupItems, tableRow);
        if (tableRow.index % 2 == 0) {
            event.target.backgroundColor = "#FFFFFF";
        } else {
            event.target.backgroundColor = "subtle.lightest";
        }
    }

    private setCompareItems(modelRow: ModelRow[], tableRow: TableRow) {
        tableRow.findComponentById((comp: any) => {
            if (comp instanceof FreightGroupItemCompareItem || comp instanceof FreightGroupItemCompareChangeItem) {
                const compareItems: FreightGroupItemCompareItem | FreightGroupItemCompareChangeItem = comp;
                const rows = [];
                modelRow[tableRow.index]["compare_items"].forEach((element: any) => {
                    const newRow = new RowEdiCompareItem(element);
                    rows.push(newRow);
                });
                compareItems.setupTableData(rows);
            }
        });
    }

    private loadChildTableData(table: Table, data: any[], mode: DataSourceMode) {
        const tableDataSource = table.dataSource;
        tableDataSource.data = [];
        const modelRows = [];
        data.forEach((element: any) => {
            modelRows.push(new ModelRow(tableDataSource.url, true, element));
        });
        tableDataSource.setRowsAndMode(mode, modelRows);
    }

    public get changedFreightGroupItems(): ModelRow[] {
        return this._changedFreightGroupItems;
    }

    public set changedFreightGroupItems(value: ModelRow[]) {
        this._changedFreightGroupItems = value;
    }

    public get newOrRemovedFreightGroupItems(): ModelRow[] {
        return this._newOrRemovedFreightGroupItems;
    }

    public set newOrRemovedFreightGroupItems(value: ModelRow[]) {
        this._newOrRemovedFreightGroupItems = value;
    }

}
