import { DataSource, Label, Layout } from "@mcleod/components";

export abstract class AutogenLayoutEDITenderProfileQuickInfo extends Layout {
    labelAltPartnerID: Label;
    labelLabel2: Label;
    labelLabel4: Label;
    labelLabel6: Label;
    labelPartnerID: Label;
    labelVersion: Label;
    sourceEdiOrder: DataSource;
}
