import { TargetPay } from "../../../dispatch/src/TargetPay";
import { LoadBoardsTable } from "../LoadBoardsTable";
import { RoutingGuidesTable } from "../RoutingGuidesTable";
import { TopMatchTable } from "../TopMatchTable";
import { Button, CityState, DataSource, HorizontalSpacer, Label, Layout, Panel, Tab, Tabset, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutTopMatchList extends Layout {
	buttonClear: Button;
	buttonExpand: Button;
	citystateConsignee: CityState;
	citystateCustomer: CityState;
	citystateShipper: CityState;
	horizontalspacer1: HorizontalSpacer;
	horizontalspacer2: HorizontalSpacer;
	label5: Label;
	labelCommodity: Label;
	labelConsignee: Label;
	labelCustomer: Label;
	labelCustomerAddress: Label;
	labelCustomerInformation: Label;
	labelDistance: Label;
	labelFilter: Label;
	labelLatestArrival: Label;
	labelOfferLog: Label;
	labelPageTitle: Label;
	labelPickup: Label;
	labelScheduledArrival: Label;
	labelShipper: Label;
	labelStopSummary: Label;
	labelTrailerType: Label;
	labelWeight: Label;
	layoutLoadBoards: LoadBoardsTable;
	layoutRoutingGuides: RoutingGuidesTable;
	layoutTargetPay: TargetPay;
	layoutTopMatch: TopMatchTable;
	panel1: Panel;
	panel2: Panel;
	panel3: Panel;
	panel4: Panel;
	panelMoney: Panel;
	panelOverview: Panel;
	panelPickup: Panel;
	panelPickupTimes: Panel;
	panelSearchFilter: Panel;
	panelSearchHeader: Panel;
	panelSummaryIcons: Panel;
	searchButton: Button;
	sourceMovement: DataSource;
	sourceTopMatch: DataSource;
	tabLoadBoards: Tab;
	tabRoutingGuides: Tab;
	tabTopMatch: Tab;
	tabsetTopMatch: Tabset;
	textboxDateRange: Textbox;
	textboxMaxPay: Textbox;
	textboxMoveDistance: Textbox;
	textboxMovementId: Textbox;
	textboxOrderId: Textbox;
	textboxOrdersEquipmentTypeId: Textbox;
	textboxOrdersWeight: Textbox;
	textboxOrderscommodityId: Textbox;
	textboxOrderspnnRate: Textbox;
	textboxStopOriginschedArriveEarly: Textbox;
	textboxTopMatchProfile: Textbox;
	textboxTrailerType: Textbox;
}
