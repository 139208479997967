/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelOrdersList.ts                    *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/dispatch/orders-list";

export class AutogenModelOrdersList<RowType extends ModelRow> extends Endpoint<RowType, RowOrdersListFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowOrdersList extends ModelRow<RowOrdersListFields> {
    constructor(values?: Partial<RowOrdersListFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowOrdersListFields {
    actual_reefer_profile: string;
    agency_id: string;
    agent_payee_id: string;
    allow_relay: string;
    autorate_status: string;
    bill_date: Date;
    bill_distance: number;
    bill_distance_um: string;
    billing_empty_distance: number;
    billing_empty_distance_um: string;
    billing_loaded_distance: number;
    billing_loaded_distance_um: string;
    billing_user_id: string;
    blnum: string;
    bol_note: string;
    bol_received: string;
    bol_recv_date: Date;
    booking_no: string;
    booking_number: string;
    brk_qual_profile: string;
    broker_location_id: string;
    brokerage_workflow_id: string;
    cbp_crossing_date: Date;
    cbp_crossing_location_id: string;
    cbp_crossing_reason: string;
    cbsa_crossing_date: Date;
    cbsa_crossing_location_id: string;
    cbsa_crossing_reason: string;
    cc_fuel_revenue: number;
    cc_fuel_revenue_c: string;
    cc_fuel_revenue_d: Date;
    cc_fuel_revenue_n: number;
    cc_fuel_revenue_r: number;
    cc_net_revenue: number;
    cc_net_revenue_c: string;
    cc_net_revenue_d: Date;
    cc_net_revenue_n: number;
    cc_net_revenue_r: number;
    cc_pro_nbr: string;
    cobroker_location_id: string;
    collection_method: string;
    com_setpnt_temp: number;
    com_setpnt_temp_um: string;
    commitment_id: string;
    commodity: string;
    commodity_id: string;
    company_id: string;
    consignee_refno: string;
    consignee_stop_id: string;
    container: string;
    container_planned: string;
    container_type_id: string;
    controlling_carrier_code: string;
    copy_from_company: string;
    copy_from_order_id: string;
    copy_to_company: string;
    copy_to_order_id: string;
    ctrl_party_id: string;
    cube: number;
    curr_movement_id: string;
    cust_order_no: string;
    "customer.activity_count": number;
    "customer.activity_start_date": Date;
    "customer.add_empty_distance_occ": string;
    "customer.add_finance_charge": string;
    "customer.address1": string;
    "customer.address2": string;
    "customer.allow_inc_in_frt": string;
    "customer.auto_rate": string;
    "customer.available_template": number;
    "customer.average_bill": number;
    "customer.average_bill_c": string;
    "customer.average_bill_d": Date;
    "customer.average_bill_n": number;
    "customer.average_bill_r": number;
    "customer.average_pay_days": number;
    "customer.balance": number;
    "customer.balance_c": string;
    "customer.balance_d": Date;
    "customer.balance_n": number;
    "customer.balance_r": number;
    "customer.benchmark_ctg_uid": number;
    "customer.benchmark_tar_uid": number;
    "customer.bill_due_days": number;
    "customer.bill_format_flag": string;
    "customer.bill_template": number;
    "customer.bill_transfer_when": string;
    "customer.billed_loads": number;
    "customer.bl_logo": string;
    "customer.bl_logo_name": string;
    "customer.blanket_buy_tariff_id": string;
    "customer.blanket_sell_tariff_id": string;
    "customer.bridge_id": string;
    "customer.brokerage_filter": string;
    "customer.business_hours": string;
    "customer.business_number": string;
    "customer.buy_tariff_id": string;
    "customer.category": string;
    "customer.cert_of_ins_date": Date;
    "customer.charge_detention": string;
    "customer.chassis_chargeby": string;
    "customer.chassis_freedays": number;
    "customer.chassis_rate": number;
    "customer.chassis_rate_c": string;
    "customer.chassis_rate_d": Date;
    "customer.chassis_rate_n": number;
    "customer.chassis_rate_r": number;
    "customer.city": string;
    "customer.city_id": number;
    "customer.coll_thresh_pd1": number;
    "customer.coll_thresh_pd1_c": string;
    "customer.coll_thresh_pd1_d": Date;
    "customer.coll_thresh_pd1_n": number;
    "customer.coll_thresh_pd1_r": number;
    "customer.coll_thresh_pd2": number;
    "customer.coll_thresh_pd2_c": string;
    "customer.coll_thresh_pd2_d": Date;
    "customer.coll_thresh_pd2_n": number;
    "customer.coll_thresh_pd2_r": number;
    "customer.coll_thresh_pd3": number;
    "customer.coll_thresh_pd3_c": string;
    "customer.coll_thresh_pd3_d": Date;
    "customer.coll_thresh_pd3_n": number;
    "customer.coll_thresh_pd3_r": number;
    "customer.coll_thresh_pd4": number;
    "customer.coll_thresh_pd4_c": string;
    "customer.coll_thresh_pd4_d": Date;
    "customer.coll_thresh_pd4_n": number;
    "customer.coll_thresh_pd4_r": number;
    "customer.collections_id": string;
    "customer.collections_ok": string;
    "customer.company_id": string;
    "customer.confidence_level": number;
    "customer.container_chargeby": string;
    "customer.container_freedays": number;
    "customer.container_rate": number;
    "customer.container_rate_c": string;
    "customer.container_rate_d": Date;
    "customer.container_rate_n": number;
    "customer.container_rate_r": number;
    "customer.contract_cbc": string;
    "customer.contract_ccc": string;
    "customer.contract_gbc": string;
    "customer.contract_gcc": string;
    "customer.contract_sbc": string;
    "customer.contract_scc": string;
    "customer.controlling_carrier_code": string;
    "customer.conversion_date": Date;
    "customer.cost_ctg_uid": number;
    "customer.cost_tar_uid": number;
    "customer.covered_template": number;
    "customer.cp_logo": string;
    "customer.cp_logo_name": string;
    "customer.credit_application": string;
    "customer.credit_check_date": Date;
    "customer.credit_limit": number;
    "customer.credit_limit_c": string;
    "customer.credit_limit_d": Date;
    "customer.credit_limit_n": number;
    "customer.credit_limit_r": number;
    "customer.credit_status": string;
    "customer.credit_warning_pct": number;
    "customer.ctg_uid": number;
    "customer.currency_type": string;
    "customer.cust_requestor_load_id": string;
    "customer.cust_stmt_template": number;
    "customer.d_and_b_code": string;
    "customer.d_and_b_date": Date;
    "customer.d_and_b_number": string;
    "customer.days_between_calls": number;
    "customer.deadhead_target": number;
    "customer.ded_last_fixed_cost_billdt": Date;
    "customer.dedicated_cycle": string;
    "customer.dedicated_revenue_code_id": string;
    "customer.dedicated_seg_alloc_code": string;
    "customer.dedicated_start_day": string;
    "customer.dedicated_template": number;
    "customer.def_rate_confirm_temp": number;
    "customer.delivered_template": number;
    "customer.departure_date_at": string;
    "customer.disable_automatic_tracking": string;
    "customer.display_eta": string;
    "customer.display_ysplit_stops": string;
    "customer.distance_method": string;
    "customer.distance_profile": string;
    "customer.distance_source": string;
    "customer.distance_type": string;
    "customer.dot_number": string;
    "customer.driver_load": string;
    "customer.empty_distance_charge_id": string;
    "customer.empty_distance_rate": number;
    "customer.enable_notifications": string;
    "customer.enforce_dep_dates": string;
    "customer.entered_date": Date;
    "customer.excise_taxable": string;
    "customer.expected_close": Date;
    "customer.extend_rates": string;
    "customer.factor_percent": number;
    "customer.factor_receivables": string;
    "customer.factoring_id": string;
    "customer.fak_profile_id": string;
    "customer.fourkites_customer_id": string;
    "customer.fuel_amount": number;
    "customer.fuel_base_price": number;
    "customer.fuel_credit": string;
    "customer.fuel_method": string;
    "customer.fuel_percent": number;
    "customer.fuel_price_region": string;
    "customer.fuel_surcharge_id": string;
    "customer.fuel_surchg_enddt": Date;
    "customer.fuel_surchg_strtdt": Date;
    "customer.fuel_variance": number;
    "customer.global_detention": string;
    "customer.google_place_id": string;
    "customer.gp_exclude": string;
    "customer.gp_exported": Date;
    "customer.grace_period": number;
    "customer.high_balance": number;
    "customer.high_balance_c": string;
    "customer.high_balance_d": Date;
    "customer.high_balance_n": number;
    "customer.high_balance_r": number;
    "customer.ic_company_id": string;
    "customer.id": string;
    "customer.include_by_date": string;
    "customer.include_empty_distance": string;
    "customer.include_fuel_surcharge": string;
    "customer.include_split_point": string;
    "customer.is_active": string;
    "customer.is_consignee": string;
    "customer.is_distribution_center": string;
    "customer.is_drop_yard": string;
    "customer.is_geocoded": string;
    "customer.is_intercompany": string;
    "customer.is_pallets_required": string;
    "customer.is_shipper": string;
    "customer.is_trailer_pool_required": string;
    "customer.jaguar_id": number;
    "customer.last_bill_date": Date;
    "customer.last_fin_charge_dt": Date;
    "customer.last_pay_date": Date;
    "customer.last_ship_date": Date;
    "customer.latitude": number;
    "customer.location_id": string;
    "customer.longitude": number;
    "customer.macropoint_requestor_id": string;
    "customer.main_phone": string;
    "customer.manual_exchange": string;
    "customer.mark_plan_id": string;
    "customer.max_pay_percent": number;
    "customer.misc_bill_template": number;
    "customer.monthly_order_goal": number;
    "customer.motor_carrier_id": string;
    "customer.name": string;
    "customer.next_call_date": Date;
    "customer.next_rec_call": Date;
    "customer.nxt_action_code_id": string;
    "customer.nxt_action_date": Date;
    "customer.operations_user": string;
    "customer.order_count": number;
    "customer.order_detail_at": string;
    "customer.order_start_date": Date;
    "customer.p44_profile_id": string;
    "customer.paid_loads": number;
    "customer.pay_days_date": Date;
    "customer.pay_days_orders": number;
    "customer.payee_id": string;
    "customer.pd0_alert": number;
    "customer.pd1_np_auto": string;
    "customer.pd1_sp_auto": string;
    "customer.pd1_sp_thresh": number;
    "customer.pd1_sp_thresh_c": string;
    "customer.pd1_sp_thresh_d": Date;
    "customer.pd1_sp_thresh_n": number;
    "customer.pd1_sp_thresh_r": number;
    "customer.pd2_np_auto": string;
    "customer.pd2_sp_auto": string;
    "customer.pd2_sp_thresh": number;
    "customer.pd2_sp_thresh_c": string;
    "customer.pd2_sp_thresh_d": Date;
    "customer.pd2_sp_thresh_n": number;
    "customer.pd2_sp_thresh_r": number;
    "customer.pd3_np_auto": string;
    "customer.pd3_sp_auto": string;
    "customer.pd3_sp_thresh": number;
    "customer.pd3_sp_thresh_c": string;
    "customer.pd3_sp_thresh_d": Date;
    "customer.pd3_sp_thresh_n": number;
    "customer.pd3_sp_thresh_r": number;
    "customer.pd4_np_auto": string;
    "customer.pd4_sp_auto": string;
    "customer.pd4_sp_thresh": number;
    "customer.pd4_sp_thresh_c": string;
    "customer.pd4_sp_thresh_d": Date;
    "customer.pd4_sp_thresh_n": number;
    "customer.pd4_sp_thresh_r": number;
    "customer.plc_uid": number;
    "customer.post_summary_dtl": string;
    "customer.potential": number;
    "customer.potential_c": string;
    "customer.potential_d": Date;
    "customer.potential_n": number;
    "customer.potential_r": number;
    "customer.prev_code": string;
    "customer.primary_agency": string;
    "customer.primary_commodity_id": string;
    "customer.primary_contact": string;
    "customer.primary_sort": string;
    "customer.primary_trailer_type": string;
    "customer.print_containers": string;
    "customer.progress_template": number;
    "customer.prospect_type_id": string;
    "customer.quebec_number": string;
    "customer.quote_days_to_expire": number;
    "customer.rate_override_nmfc_code": string;
    "customer.reassign_profile_id": string;
    "customer.rec_call_days": number;
    "customer.remarks": string;
    "customer.require_tracking": string;
    "customer.required_vendor": string;
    "customer.revenue": number;
    "customer.revenue_c": string;
    "customer.revenue_d": Date;
    "customer.revenue_n": number;
    "customer.revenue_r": number;
    "customer.revenue_start_date": Date;
    "customer.routing_guide_profile_id": string;
    "customer.sales_manager_id": string;
    "customer.sales_status_id": string;
    "customer.salesperson_id": string;
    "customer.search_city": string;
    "customer.search_state_id": string;
    "customer.search_zip_code": string;
    "customer.secondary_sort": string;
    "customer.sell_tariff_id": string;
    "customer.service_fail_cons": string;
    "customer.service_fail_drop": string;
    "customer.service_fail_pick": string;
    "customer.service_fail_ship": string;
    "customer.service_failures": string;
    "customer.service_failures_review": string;
    "customer.shipment_ident_type": string;
    "customer.sic_code": string;
    "customer.start_date": Date;
    "customer.state_id": string;
    "customer.summary_billing": string;
    "customer.summary_template": number;
    "customer.tar_uid": number;
    "customer.target_profit": number;
    "customer.tenfour_customer_id": string;
    "customer.tracking_hours_prior": number;
    "customer.trimble_place_id": string;
    "customer.type_of": string;
    "customer.use_local_mile": string;
    "customer.vm_part_update": string;
    "customer.waterfall_auto_route_guides": string;
    "customer.waterfall_lead_time": number;
    "customer.waterfall_notify_email": string;
    "customer.waterfall_process": string;
    "customer.website_url": string;
    "customer.wf_auto_renbill": string;
    "customer.wf_autobill": string;
    "customer.wf_bwop": string;
    "customer.wf_queue": number;
    "customer.zip_code": string;
    "customer.zone_id": string;
    customer_id: string;
    declared_val: number;
    def_move_type: string;
    default_match_id: string;
    dim_height: string;
    dim_length: string;
    dim_surcharge: number;
    dim_surcharge_c: string;
    dim_surcharge_d: Date;
    dim_surcharge_n: number;
    dim_surcharge_r: number;
    dim_width: string;
    dispatch_opt: string;
    dl_team_reqd: string;
    dray_del_date: Date;
    dray_del_pay: number;
    dray_del_pay_c: string;
    dray_del_pay_d: Date;
    dray_del_pay_n: number;
    dray_del_pay_r: number;
    dray_del_payee: string;
    dray_del_xfer_dt: Date;
    dray_pu_date: Date;
    dray_pu_pay: number;
    dray_pu_pay_c: string;
    dray_pu_pay_d: Date;
    dray_pu_pay_n: number;
    dray_pu_pay_r: number;
    dray_pu_payee: string;
    dray_pu_xfer_dt: Date;
    eir1_no: string;
    eir2_no: string;
    entered_user_id: string;
    equipment_type_id: string;
    equipment_type_options: string;
    est_tolls: number;
    est_tolls_c: string;
    est_tolls_d: Date;
    est_tolls_n: number;
    est_tolls_r: number;
    excise_disable_update: string;
    excise_taxable: string;
    excisetax_total: number;
    excisetax_total_c: string;
    excisetax_total_d: Date;
    excisetax_total_n: number;
    excisetax_total_r: number;
    external_invoiceno: string;
    extra_deliveries: number;
    extra_pickups: number;
    floor_loaded_freight: string;
    force_assign: string;
    forwd_location_id: string;
    free_days: number;
    freight_charge: number;
    freight_charge_c: string;
    freight_charge_d: Date;
    freight_charge_n: number;
    freight_charge_r: number;
    hazmat: string;
    hazmat_code: string;
    high_value: string;
    hold_reason: string;
    ic_company_from: string;
    ic_movement_from: string;
    ic_order_from: string;
    id: string;
    image_is_present: string;
    import_export: string;
    include_split_point: string;
    intelliset: string;
    invoice_recv_date: Date;
    is_auto_tonu: string;
    is_autorate_dist: string;
    is_container: string;
    is_dedicated: string;
    is_intercompany: string;
    is_local_mile: string;
    is_requested: string;
    is_tonu: string;
    jag_carrier_id: string;
    jag_cost_rate_id: number;
    ldport_location_id: string;
    linear_feet_avail: number;
    load_date: Date;
    loadboard: string;
    lock_miles: string;
    ltl: string;
    markup_flat: number;
    markup_flat_c: string;
    markup_flat_d: Date;
    markup_flat_n: number;
    markup_flat_r: number;
    markup_percent: number;
    master_order_id: string;
    master_sequence: number;
    move_pay: number;
    "movement.authorized": string;
    "movement.average_broker_rating": number;
    "movement.booking_no": string;
    "movement.br_details_link": string;
    "movement.br_status_link": string;
    "movement.br_track_interval": number;
    "movement.br_track_status": string;
    "movement.br_track_status_desc": string;
    "movement.br_track_vehicle_id": string;
    "movement.br_tracking_id": string;
    "movement.br_vendor": string;
    "movement.broker_avail_tract": string;
    "movement.brokerage": string;
    "movement.brokerage_status": string;
    "movement.carrier_contact": string;
    "movement.carrier_email": string;
    "movement.carrier_empty_from_city_id": number;
    "movement.carrier_fuel_pay_rate_id": number;
    "movement.carrier_lane_rate_id": string;
    "movement.carrier_override_user": string;
    "movement.carrier_phone": string;
    "movement.carrier_rate_id": number;
    "movement.carrier_tractor": string;
    "movement.carrier_trailer": string;
    "movement.carrier_trailer_type": string;
    "movement.chassis": string;
    "movement.checkcall_sched_id": string;
    "movement.company_id": string;
    "movement.container": string;
    "movement.container_returned": string;
    "movement.container_status": string;
    "movement.controlling_carrier_code": string;
    "movement.dedicated_order_id": string;
    "movement.dest_stop_id": string;
    "movement.digital_booking": string;
    "movement.dispatcher_user_id": string;
    "movement.eform_rate_confirmation": string;
    "movement.empty2next_order": string;
    "movement.equipment_group_id": string;
    "movement.est_tolls": number;
    "movement.est_tolls_c": string;
    "movement.est_tolls_d": Date;
    "movement.est_tolls_n": number;
    "movement.est_tolls_r": number;
    "movement.exclude_movement": string;
    "movement.fleet_manager": string;
    "movement.freight_matching_override": string;
    "movement.fuel_distance": number;
    "movement.fuel_distance_um": string;
    "movement.fuel_tax_exempt": string;
    "movement.hubtran_approved_inv_date": Date;
    "movement.hubtran_ok2pay_date": Date;
    "movement.ic_company_to": string;
    "movement.ic_order_to": string;
    "movement.id": string;
    "movement.integrated_carrier_search": string;
    "movement.invoice_recv_date": Date;
    "movement.is_container": string;
    "movement.is_dray": string;
    "movement.is_intercompany": string;
    "movement.is_local_mile": string;
    "movement.linked_id": number;
    "movement.linked_sequence": number;
    "movement.load_booking_eligible": string;
    "movement.load_booking_source": string;
    "movement.loaded": string;
    "movement.locked_user_id": string;
    "movement.ltl": string;
    "movement.ltl_staging_wrs_uid": number;
    "movement.macropoint_status": string;
    "movement.manifest_empty_distance": number;
    "movement.manifest_estimated_arrival": Date;
    "movement.manifest_estimated_depart": Date;
    "movement.manifest_id": string;
    "movement.manifest_loaded_distance": number;
    "movement.manifest_reg_uid": number;
    "movement.manifest_spots_capacity": number;
    "movement.manifest_spots_consumed": number;
    "movement.manifest_weight_capacity": number;
    "movement.manifest_weight_consumed": number;
    "movement.max_buy": number;
    "movement.max_buy_c": string;
    "movement.max_buy_d": Date;
    "movement.max_buy_n": number;
    "movement.max_buy_r": number;
    "movement.max_pay_method": string;
    "movement.max_pay_units": number;
    "movement.missed_call_sent": string;
    "movement.move_distance": number;
    "movement.move_distance_um": string;
    "movement.movement_type": string;
    "movement.must_respond_by": Date;
    "movement.next_sched_call": Date;
    "movement.operational_status": string;
    "movement.operations_user": string;
    "movement.origin_stop_id": string;
    "movement.override_crny_type": string;
    "movement.override_driver2_id": number;
    "movement.override_driver2_nm": string;
    "movement.override_driver_id": number;
    "movement.override_driver_nm": string;
    "movement.override_drvr2_cell": string;
    "movement.override_drvr2_email": string;
    "movement.override_drvr_cell": string;
    "movement.override_drvr_email": string;
    "movement.override_max_pay": number;
    "movement.override_max_pay_c": string;
    "movement.override_max_pay_d": Date;
    "movement.override_max_pay_n": number;
    "movement.override_max_pay_r": number;
    "movement.override_pay_amt": number;
    "movement.override_pay_amt_c": string;
    "movement.override_pay_amt_d": Date;
    "movement.override_pay_amt_n": number;
    "movement.override_pay_amt_r": number;
    "movement.override_pay_rate": number;
    "movement.override_paye_rate": number;
    "movement.override_payee_id": string;
    "movement.override_targetpay": number;
    "movement.override_targetpay_c": string;
    "movement.override_targetpay_d": Date;
    "movement.override_targetpay_n": number;
    "movement.override_targetpay_r": number;
    "movement.override_type": string;
    "movement.override_unit_desc": string;
    "movement.override_units": number;
    "movement.pay_distance": number;
    "movement.pay_distance_um": string;
    "movement.pending_carrier_name": string;
    "movement.pending_carrier_nbr": string;
    "movement.pending_carrier_type": string;
    "movement.pending_payee_id": string;
    "movement.placard_required": string;
    "movement.preassign_sequence": number;
    "movement.priority": string;
    "movement.pro_nbr": string;
    "movement.rate_alert_basis": string;
    "movement.rate_alert_date": Date;
    "movement.rate_alert_days": number;
    "movement.rate_alert_hours": number;
    "movement.rate_alert_minutes": number;
    "movement.rate_confirm_template": number;
    "movement.rate_confirmation_sent_date": Date;
    "movement.rate_confirmation_status": string;
    "movement.rate_expiration_basis": string;
    "movement.rate_expiration_date": Date;
    "movement.rate_expiration_days": number;
    "movement.rate_expiration_hours": number;
    "movement.rate_expiration_minutes": number;
    "movement.rdy_pickup_date": Date;
    "movement.recurring_movement_id": string;
    "movement.reminder_sent": string;
    "movement.require_tracking": string;
    "movement.required_vendor": string;
    "movement.reserved": string;
    "movement.return_date": Date;
    "movement.seg_alloc_code": string;
    "movement.shipper_email": string;
    "movement.shipper_notify_frequency": string;
    "movement.shpmt_ident_type": string;
    "movement.shpmt_ident_value": string;
    "movement.ss_location_id": string;
    "movement.status": string;
    "movement.target_extra_stop_pay": number;
    "movement.target_extra_stop_pay_c": string;
    "movement.target_extra_stop_pay_d": Date;
    "movement.target_extra_stop_pay_n": number;
    "movement.target_extra_stop_pay_r": number;
    "movement.target_fuel_surcharge_pay": string;
    "movement.target_pay": number;
    "movement.target_pay_c": string;
    "movement.target_pay_d": Date;
    "movement.target_pay_method": string;
    "movement.target_pay_n": number;
    "movement.target_pay_r": number;
    "movement.target_pay_units": number;
    "movement.target_rate_id": number;
    "movement.time_to_send_msg": Date;
    "movement.track_start_date": Date;
    "movement.tracking_duration_ext": number;
    "movement.tracking_notify_email": string;
    "movement.tracking_request_status": string;
    "movement.trailer_rent_pct": number;
    "movement.trip_lease": string;
    "movement.trip_mgt_recalc_datetime": Date;
    "movement.trip_mgt_tracking_id": string;
    "movement.triumphpay_exclude": string;
    "movement.trp_uid": number;
    "movement.ts_borrowing_company": string;
    "movement.ts_commodity": string;
    "movement.ts_movement_id": string;
    "movement.unlock_time": Date;
    "movement.waterfall_flood_time": Date;
    "movement.waterfall_in_progress": string;
    "movement.xfer2settle_date": Date;
    "movement.xferred2fueltax": string;
    "movement.xferred2fueltaxdt": Date;
    "movement.xmit_accepted": string;
    "movement.xmitted2driver": Date;
    "movement.zmit_trip": string;
    movement_count: number;
    next_rebill: string;
    nte_lp_num: string;
    nte_lp_qual: string;
    on_hold: string;
    operational_status: string;
    operations_user: string;
    optimize: string;
    optiset: string;
    order_mode: string;
    order_type_id: string;
    order_value: number;
    order_value_c: string;
    order_value_d: Date;
    order_value_n: number;
    order_value_r: number;
    ordered_by: string;
    ordered_by_id: string;
    ordered_date: Date;
    ordered_method: string;
    orig_dest_rate_id: string;
    otherchargetotal: number;
    otherchargetotal_c: string;
    otherchargetotal_d: Date;
    otherchargetotal_n: number;
    otherchargetotal_r: number;
    pallets_how_many: number;
    pallets_required: string;
    pay_gross: number;
    pay_gross_c: string;
    pay_gross_d: Date;
    pay_gross_n: number;
    pay_gross_r: number;
    pick_up_no: string;
    pieces: number;
    placard_required: string;
    planning_comment: string;
    pnn_callback: string;
    pnn_comment: string;
    pnn_comment2: string;
    pnn_loadboard_user_id: string;
    pnn_post_type: string;
    pnn_rate: number;
    pnn_rate_type: string;
    pnn_trailer_length: number;
    pnn_trailer_width: number;
    ponum: string;
    port: string;
    port_of_discharge: string;
    preload_trailer_id: string;
    preloaded: string;
    rate: number;
    rate_currency_type: string;
    rate_id: number;
    rate_min_weight: number;
    rate_min_weight_um: string;
    rate_source: string;
    rate_type: string;
    rate_unit_desc: string;
    rate_units: number;
    ready_to_bill: string;
    recurring_order_id: string;
    reply_transmitted: string;
    requesting_company: string;
    return_temp: number;
    return_temp_um: string;
    return_temp_var: number;
    return_temp_var_um: string;
    revenue_code_id: string;
    revenue_share_method: string;
    round_trip: string;
    sail_date: Date;
    sched_delivery_date: string;
    sched_pickup_date: string;
    seal_number: string;
    seg_alloc_code: string;
    setpoint_temp: number;
    setpoint_temp_um: string;
    setpoint_var: number;
    setpoint_var_um: string;
    ship_status_to_edi: string;
    shipment_id: string;
    shipper_stop_id: string;
    shipstatus2edi_dt: Date;
    ss_location_id: string;
    status: string;
    "stop_consignee.actual_arrival": Date;
    "stop_consignee.actual_departure": Date;
    "stop_consignee.address": string;
    "stop_consignee.address2": string;
    "stop_consignee.appointment_status_id": string;
    "stop_consignee.appt_contact_name": string;
    "stop_consignee.appt_required": string;
    "stop_consignee.cases": number;
    "stop_consignee.city_id": number;
    "stop_consignee.city_name": string;
    "stop_consignee.company_id": string;
    "stop_consignee.confirmed": string;
    "stop_consignee.contact_name": string;
    "stop_consignee.dist_from_prev": number;
    "stop_consignee.dist_from_prev_um": string;
    "stop_consignee.driver_load_unload": string;
    "stop_consignee.edi_code": string;
    "stop_consignee.edi_loc_code": string;
    "stop_consignee.eta": Date;
    "stop_consignee.eta_distance": number;
    "stop_consignee.eta_late": string;
    "stop_consignee.eta_oor": string;
    "stop_consignee.google_place_id": string;
    "stop_consignee.grouped_id": number;
    "stop_consignee.hub": number;
    "stop_consignee.ic_stop_id": string;
    "stop_consignee.id": string;
    "stop_consignee.inbound_status": string;
    "stop_consignee.late_eta_colorcode": string;
    "stop_consignee.late_route_status": string;
    "stop_consignee.latitude": number;
    "stop_consignee.location_id": string;
    "stop_consignee.location_name": string;
    "stop_consignee.longitude": number;
    "stop_consignee.manifest_fgp_uid": number;
    "stop_consignee.manifest_reg_uid": number;
    "stop_consignee.move_dist_from_preassign": number;
    "stop_consignee.move_dist_from_preassign_um": string;
    "stop_consignee.move_dist_from_previous": number;
    "stop_consignee.move_dist_from_previous_um": string;
    "stop_consignee.movement_id": string;
    "stop_consignee.movement_sequence": number;
    "stop_consignee.operational_status": string;
    "stop_consignee.order_id": string;
    "stop_consignee.order_sequence": number;
    "stop_consignee.orig_sched_early": Date;
    "stop_consignee.orig_sched_late": Date;
    "stop_consignee.pallets_dropped": number;
    "stop_consignee.pallets_picked_up": number;
    "stop_consignee.payment_code_reviewed": string;
    "stop_consignee.phone": string;
    "stop_consignee.planned_arrival_time": Date;
    "stop_consignee.ponum": string;
    "stop_consignee.prior_uncleared_stops": string;
    "stop_consignee.projected_arrival": Date;
    "stop_consignee.rate_dist_from_previous": number;
    "stop_consignee.rate_dist_from_previous_um": string;
    "stop_consignee.refno": string;
    "stop_consignee.requested_service": string;
    "stop_consignee.sched_arrive_early": Date;
    "stop_consignee.sched_arrive_late": Date;
    "stop_consignee.seal": string;
    "stop_consignee.service_date": Date;
    "stop_consignee.showas_address": string;
    "stop_consignee.showas_address2": string;
    "stop_consignee.showas_city_id": number;
    "stop_consignee.showas_city_name": string;
    "stop_consignee.showas_location_id": string;
    "stop_consignee.showas_location_name": string;
    "stop_consignee.showas_state": string;
    "stop_consignee.showas_zip_code": string;
    "stop_consignee.signed_for_name": string;
    "stop_consignee.state": string;
    "stop_consignee.status": string;
    "stop_consignee.stop_type": string;
    "stop_consignee.trimble_place_id": string;
    "stop_consignee.txl_uid": number;
    "stop_consignee.volume": number;
    "stop_consignee.volume_um": string;
    "stop_consignee.weight": number;
    "stop_consignee.weight_um": string;
    "stop_consignee.zip_code": string;
    "stop_consignee.zone_id": string;
    "stop_origin.actual_arrival": Date;
    "stop_origin.actual_departure": Date;
    "stop_origin.address": string;
    "stop_origin.address2": string;
    "stop_origin.appointment_status_id": string;
    "stop_origin.appt_contact_name": string;
    "stop_origin.appt_required": string;
    "stop_origin.cases": number;
    "stop_origin.city_id": number;
    "stop_origin.city_name": string;
    "stop_origin.company_id": string;
    "stop_origin.confirmed": string;
    "stop_origin.contact_name": string;
    "stop_origin.dist_from_prev": number;
    "stop_origin.dist_from_prev_um": string;
    "stop_origin.driver_load_unload": string;
    "stop_origin.edi_code": string;
    "stop_origin.edi_loc_code": string;
    "stop_origin.eta": Date;
    "stop_origin.eta_distance": number;
    "stop_origin.eta_late": string;
    "stop_origin.eta_oor": string;
    "stop_origin.google_place_id": string;
    "stop_origin.grouped_id": number;
    "stop_origin.hub": number;
    "stop_origin.ic_stop_id": string;
    "stop_origin.id": string;
    "stop_origin.inbound_status": string;
    "stop_origin.late_eta_colorcode": string;
    "stop_origin.late_route_status": string;
    "stop_origin.latitude": number;
    "stop_origin.location_id": string;
    "stop_origin.location_name": string;
    "stop_origin.longitude": number;
    "stop_origin.manifest_fgp_uid": number;
    "stop_origin.manifest_reg_uid": number;
    "stop_origin.move_dist_from_preassign": number;
    "stop_origin.move_dist_from_preassign_um": string;
    "stop_origin.move_dist_from_previous": number;
    "stop_origin.move_dist_from_previous_um": string;
    "stop_origin.movement_id": string;
    "stop_origin.movement_sequence": number;
    "stop_origin.operational_status": string;
    "stop_origin.order_id": string;
    "stop_origin.order_sequence": number;
    "stop_origin.orig_sched_early": Date;
    "stop_origin.orig_sched_late": Date;
    "stop_origin.pallets_dropped": number;
    "stop_origin.pallets_picked_up": number;
    "stop_origin.payment_code_reviewed": string;
    "stop_origin.phone": string;
    "stop_origin.planned_arrival_time": Date;
    "stop_origin.ponum": string;
    "stop_origin.prior_uncleared_stops": string;
    "stop_origin.projected_arrival": Date;
    "stop_origin.rate_dist_from_previous": number;
    "stop_origin.rate_dist_from_previous_um": string;
    "stop_origin.refno": string;
    "stop_origin.requested_service": string;
    "stop_origin.sched_arrive_early": Date;
    "stop_origin.sched_arrive_late": Date;
    "stop_origin.seal": string;
    "stop_origin.service_date": Date;
    "stop_origin.showas_address": string;
    "stop_origin.showas_address2": string;
    "stop_origin.showas_city_id": number;
    "stop_origin.showas_city_name": string;
    "stop_origin.showas_location_id": string;
    "stop_origin.showas_location_name": string;
    "stop_origin.showas_state": string;
    "stop_origin.showas_zip_code": string;
    "stop_origin.signed_for_name": string;
    "stop_origin.state": string;
    "stop_origin.status": string;
    "stop_origin.stop_type": string;
    "stop_origin.trimble_place_id": string;
    "stop_origin.txl_uid": number;
    "stop_origin.volume": number;
    "stop_origin.volume_um": string;
    "stop_origin.weight": number;
    "stop_origin.weight_um": string;
    "stop_origin.zip_code": string;
    "stop_origin.zone_id": string;
    subject_order_number: string;
    subject_order_status: string;
    subject_order_void_date: Date;
    swap: string;
    teams_required: string;
    temperature_max: number;
    temperature_max_um: string;
    temperature_min: number;
    temperature_min_um: string;
    total_charge: number;
    total_charge_c: string;
    total_charge_d: Date;
    total_charge_n: number;
    total_charge_r: number;
    totalcharge_and_excisetax: number;
    totalcharge_and_excisetax_c: string;
    totalcharge_and_excisetax_d: Date;
    totalcharge_and_excisetax_n: number;
    totalcharge_and_excisetax_r: number;
    tractor_type: string;
    vessel: string;
    vessel_cutoff_date: Date;
    weight: number;
    weight_um: string;
    xferred2billing: string;
    xferred2billing_dt: Date;
    ids: string;
    search_reference_numbers: string;
    search_status_available: string;
    search_status_delivered: string;
    search_status_in_progress: string;
    search_status_void: string;
}

