import { DataSourceMode, DataSourceModeChangeEvent, DropdownItem, Textbox } from "@mcleod/components";
import { ModelRow } from "@mcleod/core";
import { AutogenLayoutEquipmentTypeMaster } from "./autogen/AutogenLayoutEquipmentTypeMaster";

export class EquipmentTypeMaster extends AutogenLayoutEquipmentTypeMaster {
    override onLoad() {
        const dropdownTractor = { caption: "Tractor", value: "T" } as DropdownItem;
        const dropdownTrailer = { caption: "Trailer", value: "L" } as DropdownItem;
        const dropdownTractorItem = { caption: "Tractor Item", value: "Y" } as DropdownItem;
        const dropdownTrailerItem = { caption: "Trailer Item", value: "X" } as DropdownItem;
        const dropdownDriverItem = { caption: "Driver Item", value: "D" } as DropdownItem;
        this.textboxAppliesTo.items = [dropdownTractor, dropdownTrailer, dropdownTractorItem, dropdownTrailerItem, dropdownDriverItem] as DropdownItem[];

        const dropdownVan = { caption: "Van", value: "V" } as DropdownItem;
        const dropdownReefer = { caption: "Reefer", value: "R" } as DropdownItem;
        const dropdownFlat = { caption: "Flat", value: "F" } as DropdownItem;
        const dropdownOther = { caption: "Other", value: "O" } as DropdownItem;
        this.textboxExchangeType.items = [dropdownVan, dropdownReefer, dropdownFlat, dropdownOther] as DropdownItem[];
        this.textboxAppliesTo.onSelectItem = (textbox: Textbox, selectedItem: string | ModelRow<any>) => {
            this.textboxAppliesToOnChange((selectedItem as ModelRow)['caption'])
            return undefined
        };
    }

    enableTrailerFields() {
        this.enableTextBox(this.textboxEquipLength);
        this.enableTextBox(this.textboxEquipWidth);
        this.enableTextBox(this.textboxPnnCodeId);
        this.textboxExchangeType.required = true;
        this.enableTextBox(this.textboxExchangeType);
        this.enableTextBox(this.textboxWebOrder);
    }

    disableAndClearTrailerFields() {
        this.resetTextbox(this.textboxEquipLength);
        this.resetTextbox(this.textboxEquipWidth);
        this.resetTextbox(this.textboxPnnCodeId);
        this.textboxExchangeType.required = false;
        this.resetTextbox(this.textboxExchangeType);
        this.resetTextbox(this.textboxWebOrder);
    }

    sourceEquipmentTypeAfterModeChange(event: DataSourceModeChangeEvent) {
        if (event.newMode == DataSourceMode.ADD) {
            this.textboxId.visible = true;
            this.textboxDescr.visible = true;
            this.textboxAppliesTo.visible = true;

            this.textboxEquipLength.visible = false;
            this.textboxEquipWidth.visible = false;
            this.textboxPnnCodeId.visible = false;
            this.textboxExchangeType.visible = false;
            this.textboxWebOrder.visible = false;
        }
        else {
            this.textboxAppliesToOnChange(this.textboxAppliesTo.text);
        }
    }

    private resetTextbox(textBox: Textbox): void {
        textBox.enabled = false;
        textBox.text = "";
        textBox.visible = false;
    }

    private enableTextBox(textBox: Textbox): void {
        textBox.enabled = true;
        textBox.visible = true;
    }

    private textboxAppliesToOnChange(enteredValue: string) {
        enteredValue === "Trailer" ? this.enableTrailerFields() : this.disableAndClearTrailerFields();
    }
}
