import { DataSource, Layout, Switch, Textbox, } from "@mcleod/components";

export abstract class AutogenLayoutContact extends Layout {
    sourceContacts: DataSource;
    switchIsActive: Switch;
    switchWebAccess: Switch;
    textboxEmail: Textbox;
    textboxMobilePhone: Textbox;
    textboxName: Textbox;
    textboxPhone: Textbox;
    textboxTitle: Textbox;
}
