export const innerHTML = '<path fill-rule="evenodd" clip-rule="evenodd" d="M12.112 0C14.8737 0 17.112 2.23828 17.112 5C17.112 7.76172 14.8737 10 12.112 10C9.35025 10 7.11197 7.76172 7.11197 5C7.11197 2.23828 9.35025 0 12.112 0Z" fill="#EF7200"/>' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M12.112 1.05499C12.4011 1.05499 12.6354 1.28272 12.6354 1.56389V6.25848C12.6354 6.53965 12.4011 6.76738 12.112 6.76738C11.8228 6.76738 11.5886 6.53965 11.5886 6.25848V1.56389C11.5886 1.28272 11.8228 1.05499 12.112 1.05499Z" fill="white"/>' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M12.112 7.7674C12.4372 7.7674 12.7008 8.03101 12.7008 8.3562C12.7008 8.68139 12.4372 8.94501 12.112 8.94501C11.7868 8.94501 11.5232 8.68139 11.5232 8.3562C11.5232 8.03101 11.7868 7.7674 12.112 7.7674Z" fill="white"/>' +
    '<mask id="mask0_47_317" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="26">' +
    '<path d="M24.224 0H0V25.284H24.224V0Z" fill="#252525"/>' +
    '<path d="M24.224 6.767H0V24.767H24.224V6.767Z" fill="white"/></mask>' +
    '<g mask="url(#mask0_47_317)">' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M17.9852 6.76739H21.8529C22.1438 6.72248 22.4176 6.91741 22.4704 7.20701L24.1054 11.6527C24.1783 11.8842 24.2117 12.1263 24.2042 12.3689V24.1104C24.2226 24.2879 24.1607 24.4643 24.0355 24.5914C23.9103 24.7185 23.7348 24.7831 23.5571 24.7674H0.612506C0.442948 24.7736 0.278839 24.7069 0.161729 24.5842C0.0446192 24.4614 -0.0142627 24.2943 -7.95722e-06 24.1252V12.4924C-0.00368376 12.1565 0.049767 11.8225 0.15806 11.5045C0.28649 11.1291 0.523592 10.2251 1.53128 7.17738C1.58815 6.91132 1.83931 6.73314 2.10921 6.76739H6.06091V7.93808H2.88967L2.19813 10.477H9.05433C9.1201 10.4721 9.18614 10.4721 9.25191 10.477C9.50135 10.5072 9.68842 10.7198 9.6866 10.971C9.68975 11.1265 9.70295 11.2816 9.72612 11.4353C10.0077 12.9963 10.8227 14.0089 12.4034 13.8755C13.663 13.7718 14.2508 12.7839 14.478 11.5391C14.5094 11.3761 14.5275 11.2109 14.5324 11.0451C14.5324 10.6499 14.7201 10.4622 15.1202 10.4622H21.9665L21.927 10.3289L21.2108 7.93808H18.0099L17.9852 6.76739Z" fill="#252525"/>' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M1.88693 23.0929V12.4628H8.30845C8.36089 12.718 8.42685 12.9703 8.50603 13.2186C8.96894 14.6638 10.2467 15.6956 11.757 15.8437C13.2673 15.9919 14.7212 15.228 15.4561 13.9002C15.6908 13.4841 15.8481 13.0289 15.9204 12.5566C15.9204 12.4826 15.955 12.4529 16.034 12.4529H22.332V23.0929H1.88693Z" fill="white"/>' +
    '</g>'
