import { DataSource, Label, Layout, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutDistanceCalculatorOverview extends Layout {
    labelHeader: Label;
    labelMilesHeader: Label;
    labelPracticalMilesCalculateValue: Label;
    labelPracticalTollAmntCalculateValue: Label;
    labelShortMilesCalculateValue: Label;
    labelShortTollAmntCalculateValue: Label;
    labelTollAmountHeader: Label;
    mainPanelPracticalDistance: Panel;
    mainPanelShortDistance: Panel;
    panelOverviewSummary: Panel;
    panelPracticalDistance: Panel;
    panelShortDistance: Panel;
    sourceDistanceControl: DataSource;
    textboxPracticalProfile: Textbox;
    textboxShortDistProfile: Textbox;
}
