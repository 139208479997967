import { Switch, TableRow, TableRowDisplayEvent, Textbox } from "@mcleod/components";
import { TableRowMode } from "@mcleod/components/src/components/table/TableRow";
import { EditRowDecorator } from "@mcleod/components/src/page/decorators/EditRowDecorator";
import { Carrier } from "../../dispatch/src/Carrier";
import { Comments } from "./Comments";
import { AutogenLayoutTableComment } from "./autogen/AutogenLayoutTableComment";

export class TableComment extends AutogenLayoutTableComment {
    private _displayCopyOrderFields: boolean = true;
    private _displayDispatchField: boolean = true;

    override onLoad(): void {
        this.tableComments.onEditLayoutLoaded = (rowDecorator: EditRowDecorator, tableRow: TableRow) => {
            const comments = rowDecorator.layout as Comments;
            comments.panelOrderFields.visible = this._displayCopyOrderFields;
            comments.textboxOrderCommentType.required = "Y" === tableRow.data?.get("copy_to_order");
            comments.switchDispatch.visible = this._displayDispatchField;
        };

        if (!(this.owner instanceof Carrier)) {
            this.displayDispatchField = false;
        } else {
            this.displayCopyOrderFields = false;
        }
    }


    public set displayDispatchField(value: boolean) {
        if (value != this._displayDispatchField)
            this._displayDispatchField = value;
        this.syncColumns();
    }

    public set displayCopyOrderFields(value: boolean) {
        if (value != this._displayCopyOrderFields)
            this._displayCopyOrderFields = value;
        this.syncColumns();
    }

    syncColumns() {
        if (!this._displayCopyOrderFields) {
            this.removeColumn("orderCommentType");
            this.removeColumn("copyToOrder");
        }
        if (!this._displayDispatchField)
            this.removeColumn("displayAtDispatch");
    }

    private removeColumn(columnId: String) {
        for (const column of this.tableComments.columns) {
            if (column?.cellDef?.def?.id === columnId) {
                this.tableComments.removeColumn(column.index); return;
            }
        }
    }

    switchCopyToOrderOnChange(event) {
        if (event.userInitiatedChange) {
            const switchCopyToOrder = event.target as Switch;
            const row: TableRow = TableRow.getContainingTableRow(switchCopyToOrder);
            const textboxOrderCommentType = row.findComponentById("textboxOrderCommentType") as Textbox;
            if (textboxOrderCommentType != null)
                textboxOrderCommentType.required = switchCopyToOrder.checked;
        }
    }

    tableCommentsOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.target as TableRow;
        if (TableRowMode.QUICK_ADD === tableRow.mode) {
            tableRow.findComponentById("labelUserName").visible = false;
            tableRow.findComponentById("labelDate").visible = false;
        }
    }
}
