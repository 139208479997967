import { CommonTooltips } from "@mcleod/common";
import { ClickEvent, DataDisplayEvent, DataSourceExecutionEvent, Label, Layout, Textbox } from "@mcleod/components";
import { Alignment, Api, Currency, CurrencyUtil, FieldUpdateEvent, ModelRow, Navigation, StringUtil } from "@mcleod/core";
import { ThemeCommonPage } from "@mcleod/core/src/themes/common/ThemeCommonPage";
import { ModelOrders } from "@mcleod/dispatch/src/models/ModelOrders";
import { CustomerQuickInfo } from "../../ar/src/CustomerQuickInfo";
import { EDIOrderProfileQuickInfo } from "./EdiOrderProfileQuickInfo";
import { TransmissionStatusQuickInfo } from "./TransmissionStatusQuickInfo";
import { AutogenLayoutEdiOrderOverview } from "./autogen/AutogenLayoutEdiOrderOverview";

export class EdiOrderOverview extends AutogenLayoutEdiOrderOverview {

    private tooltipRightAlignProps = { position: Alignment.RIGHT, pointerColor: ThemeCommonPage.quickInfo.borderLeftColor };


    labelTransStatusOnDataDisplay() {
        if (this.mainDataSource?.activeRow.get("reply_batch_id") > "0") {
            this.labelTransmitStatus.tooltipCallback = (base, originatingEvent) => {
                const layout = Layout.getLayout("lme/datafusion/TransmissionStatusQuickInfo") as TransmissionStatusQuickInfo;
                layout.addLayoutLoadListener(() => {
                    if (layout.sourceEdiLog !== null) {
                        layout.sourceEdiLog.search({
                            direction: "O",
                            func_batch: this.mainDataSource.activeRow.get("reply_batch_id"),
                            batch: this.mainDataSource.activeRow.get("reply_batch_id"),
                            func_receiver_id: this.mainDataSource.activeRow.get("reply_partner_id"),
                            receiver_id: this.mainDataSource.activeRow.get("reply_altpartnerid"),
                            func_version: this.mainDataSource.activeRow.get("reply_version"),
                            transaction_type: 'R',
                            search_receiver_id: this.mainDataSource.activeRow.get("reply_partner_id")
                        });
                    }
                });
                return this.labelTransmitStatus.showTooltip(layout,
                    {
                        position: this.labelTransmitStatus.tooltipPosition == null ? Alignment.BOTTOM : this.labelTransmitStatus.tooltipPosition,
                        pointerColor: ThemeCommonPage.quickInfo.borderLeftColor, originatingEvent: originatingEvent
                    },
                    { themeKey: "quickinfo", backgroundColor: "background5", borderRightColor: ThemeCommonPage.quickInfo.borderLeftColor, borderRightWidth: 12, color: null });
            }
        }
    }

    sourceOrdersAfterExecution(event) {
        const row = this.mainDataSource.activeRow;

        this.textboxCommodity.text = row.getFirstLookupModelData("commodity_id")?.get("descr");
        if (event?.getAction() == "search") {
            //this.addReferenceNumberTextboxes();
            this.setCarrierRateData();
            row.addAfterFieldUpdateListener((event: FieldUpdateEvent) => this.orderFieldUpdated(event))
            this.panelCommodity.visible = "D" != this.mainDataSource.activeRow.get("status");

            this._showFieldsByDirection(row);
        }

        //this.updateContactInformation();
        this.updateDataFusionInformation();
    }

    private _addCarrierToolTip() {
        const carrierId = this.mainDataSource.activeRow?.get("carrier_id");
        const carrierAssignmentRow = this.sourceCarriersForAssignment.activeRow;
        const name = this.sourceCarrierPayee.activeRow?.get("name");
        let tooltipData = null;

        tooltipData = new ModelRow("lme/powerbroker/carriers-for-assignment", false, {
            id: carrierId,
            name: name,
            phone_number: carrierAssignmentRow?.get("phone_number"),
            icc_number: carrierAssignmentRow?.get("icc_number"),
            dot_number: carrierAssignmentRow?.get("dot_number")
        })
        CommonTooltips.setTooltipFromLayoutCallback(this.textboxCarrierName, tooltipData, "lme/powerbroker/CarrierQuickInfo", { minWidth: 100, minHeight: 100 }, this.tooltipRightAlignProps);

    }
    private _showFieldsByDirection(row: ModelRow<any>) {
        const customerId = row?.get("customer_id");
        const tenderDirection = row?.get("direction");
        const carrierId = row?.get("carrier_id");
        const requiresReply = row?.get("requires_reply_outbound");
        const replyReceived = row?.get("reply_received");
        const replyTransmitted = row?.get("reply_transmitted");
        if ("I" == tenderDirection) {
            this.textboxCarrierName.visible = false;
            if (customerId != null)
                this.sourceCustomer.search({ "id": customerId });

            this.labelTransmitted.caption = "Transmitted";
            if ("N" == replyTransmitted)
                this.labelTransmitStatus.caption = "No";
            else if ("Y" == replyTransmitted)
                this.labelTransmitStatus.caption = "Yes";

            this.panelCarrierPay.visible = false;
            this.labelTransStatusOnDataDisplay();
        } else if ("O" == tenderDirection) {
            this.textboxName.visible = false;
            if (carrierId != null) {
                this.sourceCarrierPayee.search({ "id": carrierId });
            }
            this.labelReply.caption = "Carrier Reply";
            this.labelTransmitted.caption = "Status";

            if ("N" == requiresReply) {
                this.labelTransmitStatus.caption = "Not Required";
            } else if ("Y" == replyReceived) {
                this.labelTransmitStatus.caption = "Received";
            } else {
                this.labelTransmitStatus.caption = "Not Received";
            }
            this.panelInboundCharges.visible = false;
        }

    }

    updateDataFusionInformation() {
        const hasEdi: boolean = this.mainDataSource.activeRow?.get("has_edi");
        const hasLogistics: boolean = this.mainDataSource.activeRow?.get("has_logistics");
        const hasOutStatuses: boolean = this.mainDataSource.activeRow?.get("has_statuses");
        const hasInStatuses: boolean = this.mainDataSource.activeRow?.get("has_log_statuses");
        const hasLogTenders: boolean = this.mainDataSource.activeRow?.get("has_log_tenders");
        const inTenderCount: number = this.mainDataSource.activeRow?.get("in_tender_count");
        const outTenderCount: number = this.mainDataSource.activeRow?.get("out_tender_count");
        const inTenderError = this.mainDataSource.activeRow?.get("in_tender_error");
        const outTenderError = this.mainDataSource.activeRow?.get("out_tender_error");
    }

    orderFieldUpdated(event: FieldUpdateEvent) {
        if ("customer_id" == event.fieldName && StringUtil.isEmptyString(event.newValue))
            this.customerChanged(null);
        else if ("commodity_id" == event.fieldName)
            this.textboxCommodity.text = event.originator?.text || "--";
        // else if ("ordered_by" == event.fieldName)
        //   this.updateContactInformation();
    }

    updateContactInformation() {
        //this.textboxContactName.text = this.mainDataSource.activeRow?.getFirstLookupModelData("ordered_by")?.get("name");
        // this.labelContactEmail.caption = this.mainDataSource.activeRow?.getFirstLookupModelData("ordered_by")?.get("email");//TODO sync weirdness
        // this.labelContactPhone.caption = this.mainDataSource.activeRow?.getFirstLookupModelData("ordered_by")?.get("phone");

        // if (this.mainDataSource.activeRow?.getFirstLookupModelData("ordered_by")?.get("id") == null)
        //   this.mainDataSource.activeRow.set("ordered_by_id", null);
    }

    customerChanged(customerData: ModelRow<any>) {
        this.sourceCustomer.data = [];
        if (customerData != null) {
            this.sourceCustomer.data = [customerData];
            this.sourceCustomer.setRowIndexWithoutUpdate(0);
        }
        this.sourceCustomer.displayDataInBoundComponents();
    }

    displayPartnerName(event: DataDisplayEvent) {
        const partnerNameLabel = <Label>event.target;
        partnerNameLabel.tooltipCallback = (base, originatingEvent) => {
            const row = this.sourceViewEdiOrder.activeRow;
            const partnerID = row?.get("partner_id");
            const version = row?.get("version");
            const direction = row?.get("direction");
            if (partnerID != null && version != null && direction != null) {
                const layout = Layout.getLayout("lme/datafusion/EDIOrderProfileQuickInfo") as EDIOrderProfileQuickInfo;
                layout.addLayoutLoadListener(() => {
                    if (layout.sourceEdiorderProfile != null) {
                        layout.sourceEdiorderProfile?.search({ partner_id: partnerID, version: version, direction: direction }).then(r => {
                            partnerNameLabel.showTooltip(layout,
                                { position: partnerNameLabel.tooltipPosition == null ? Alignment.BOTTOM : partnerNameLabel.tooltipPosition, anchorToMouse: true, originatingEvent: originatingEvent },
                                { themeKey: "quickInfo", backgroundColor: "background5", color: null });
                        });
                    }
                });
                return layout;
            }
        }
    }

    displayCustomer(event: DataDisplayEvent) {
        const partnerNameLabel = <Label>event.target;
        partnerNameLabel.tooltipCallback = (base, originatingEvent) => {
            const row = this.sourceViewEdiOrder.activeRow;
            const customerID = row?.get("customer_id");
            const layout = Layout.getLayout("lme/ar/CustomerQuickInfo") as CustomerQuickInfo;
            layout.addLayoutLoadListener(() => {
                if (layout.sourceCustomer != null) {
                    layout.sourceCustomer?.search({ id: customerID }).then(r => {
                        partnerNameLabel.showTooltip(layout,
                            { position: partnerNameLabel.tooltipPosition == null ? Alignment.RIGHT : partnerNameLabel.tooltipPosition, anchorToMouse: true, originatingEvent: originatingEvent },
                            { themeKey: "quickInfo", backgroundColor: "background5", color: null });
                    });
                }
            });
            return layout;
        }
    }


    // setReferenceNumbers(referenceNumbers: ModelRow<any>[] = []) {
    //   for (const comp of this.panelRefNbrs.components) {
    //     if (comp.field == null)
    //       this.panelRefNbrs.remove(comp);
    //   }
    //   for (const refNbr of referenceNumbers) {
    //     const lmData = refNbr.getFirstLookupModelData("reference_qual");
    //     let caption = lmData.description;
    //     if (caption == null && lmData instanceof ModelRow)
    //       caption = lmData?.get("description");
    //     this.addReferenceNumberTextbox(caption, refNbr.get("reference_number"));
    //   }
    // }

    // private addReferenceNumberTextboxes(referenceNumbers?: ModelRow<any>[]) {
    //   const row = this.sourceViewEdiOrder.activeRow;
    //   this.addReferenceNumberTextbox("BOL", row.get("blnum"), "blnum");
    //   this.addReferenceNumberTextbox("Consignee Reference Number", row.get("consignee_refno"), "consignee_refno");
    // }

    // addReferenceNumberTextbox = function (caption: string, text: string, field?: string) {
    //   if (caption != null && text != null) {
    //     const textbox = new Textbox({ marginBottom: 3, caption: caption, text: text, printable: true, fontSize: "large", fontBold: true, fillRow: true });
    //     textbox._captionLabel.fontSize = "medium";
    //     if (field != null) {
    //       textbox.field = field;
    //       textbox.dataSource = this.mainDataSource;
    //     }
    //     this.panelRefNbrs.add(textbox);
    //   }
    // }

    setCarrierRateData() {
        const moveId = this.mainDataSource.activeRow.get("movement.id");
        if (moveId != null) {
            Api.search("lme/dispatch/calculate-override-pay",
                {
                    "movement_id": moveId
                }).then(response => {
                    const setCurrency = function (textbox: Textbox, currency: Currency) {
                        if (currency != null) {
                            textbox.text = CurrencyUtil.formatCurrency(currency);
                        }
                    }
                    const data = response?.data?.[0];
                    // setCurrency(this.textboxTotalPay, data?.total_pay);
                    // setCurrency(this.textboxMargin, data?.margin);
                });
        }

    }

    sourceCustomerOnDisplay(event) {
        // this.labelContactEmail.visible = this.sourceCustomer.activeRow?.get("contact.email");//TODO not needed
    }

    sourceOrdersOnDisplay(event) {
        this.labelTrackingRequired.visible = this.mainDataSource.activeRow?.get("show_tracking_badge", false);
    }

    async buttonMoveDetailsOnClick(event: ClickEvent) {
        //   const movePanel = new CrudDecorator({
        //     layout: Layout.getLayout("lme/dispatch/Movement") as Movement,
        //     mode: DataSourceMode.UPDATE,
        //     key: this.mainDataSource?.activeRow.get("curr_movement_id"),
        //     headerProps: {
        //       showClose: true,
        //       showSaveAndClose: true,
        //       onSaveButtonClose: () => {
        //         movePanel.slideOut().then(() => {
        //           this.mainDataSource.search({ id: this.mainDataSource.activeRow.get("id") });
        //         });
        //       }
        //     }
        //   });
        //   movePanel.slideIn({ speed: 200 });

        const orderId = this.sourceViewEdiOrder.activeRow?.get("order_id");
        const orderRow = await new ModelOrders().searchSingle({ id: orderId }, "lme/dispatch/Orders");
        const moveId = orderRow.get("curr_movement_id");
        if (moveId != null) {
            Navigation.navigateTo("lme/dispatch/Movement?mode=update&key=" + moveId, { newTab: true });
        }
    }

    /** This is an event handler for the afterExecution event of sourceCarrierPayee.  */
    sourceCarrierPayeeAfterExecution(event: DataSourceExecutionEvent) {
        this.sourceCarriersForAssignment.search({ "id": this.mainDataSource.activeRow?.get("carrier_id") });
    }

    /** This is an event handler for the afterExecution event of sourceCarriersForAssignment.  */
    sourceCarriersForAssignmentAfterExecution(event: DataSourceExecutionEvent) {
        this._addCarrierToolTip();
    }

    displayPurposeDesc() {
        const row = this.sourceViewEdiOrder.activeRow;
        const purposeType = this.sourceViewEdiOrder.activeRow?.get("purpose_type");

        switch (purposeType) {
            case "O": this.labelPurpose.caption = "00 - Original"; break;
            case "U": this.labelPurpose.caption = "04 - Change"; break;
            case "C": this.labelPurpose.caption = "01 - Cancel"; break;
            default: this.labelPurpose.caption = "Unknown"; break;
        }
    }
}
