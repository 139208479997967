import { DataSource, Label, Layout, Table } from "@mcleod/components";

export abstract class AutogenLayoutPanelOtherChargeCompare extends Layout {
    labelOtherChargeAddsAndDeletes: Label;
    labelOtherChargeChanges: Label;
    sourceEdiCompareItemChanges: DataSource;
    sourceOtherChargeCompareGroup: DataSource;
    tableCompareItems: Table;
    tableOtherCharge: Table;
}
