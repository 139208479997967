import { DataDisplayEvent, Label, Tab, Table, TableRowDisplayEvent, Textbox } from "@mcleod/components";
import { TableRow, TableRowMode } from "@mcleod/components/src/components/table/TableRow";
import { ModelRow, ModelRowType, StringUtil, getAuthSettings, getLogger } from "@mcleod/core";
import { AutogenLayoutStopNotesAndReferenceNumbers } from "./autogen/AutogenLayoutStopNotesAndReferenceNumbers";

const log = getLogger("lme/dispatch/SendLoadTenderButton");

export class StopNotesAndReferenceNumbers extends AutogenLayoutStopNotesAndReferenceNumbers {
    private _stopRow: ModelRow<any>;

    override onLoad(): void {
        if (this._designer != null) return;
        this.tabsetStopNotesRefNos.selectedIndex = -1;
        this.styleTab(this.tabStopComments);
        this.styleTab(this.tabStopRefNumbers);
    }

    set stopRow(data: ModelRow<any>) {
        if (data != this._stopRow) {
            this._stopRow = data;
            this.syncStop();
        }
    }

    get stopRow(): ModelRow<any> {
        return this._stopRow;
    }

    private syncStop() {
        this.tableStopComments.clearRows();
        this.tableStopReferenceNumbers.clearRows();
        if (this.stopRow != null) {
            this.addTableRowCreationListener(this.tableStopComments)
            this.addTableRowCreationListener(this.tableStopReferenceNumbers)
            this.addTableRows(this.tableStopComments, this.stopRow.get("stop_notes"));
            this.addTableRows(this.tableStopReferenceNumbers, this.stopRow.get("reference_numbers"));
        }
    }

    private addTableRowCreationListener(table: Table) {
        table.addRowCreateListener(event => {
            this.updateStopsTabCaption(table)
            if (event.getTableRow().data._appending)
                event.getTableRow().data.set("stop_id", this.stopRow.get("id"));
            if (table.quickAddRow?.findComponentById("fireImage") != null && table.quickAddRow?.findComponentById("fireImage").visible == true)
                table.quickAddRow.findComponentById("fireImage").visible = false;
        });
    }

    private addTableRows(table: Table, data: any[]) {
        table.dataSource.preventChangeNotifications = data != null;
        if (data != null) {
            for (const [key, value] of Object.entries(data))
                this.addTableRow(table, value, data.length);
        }
    }

    private addTableRow(table: Table, value: any, rowCount: number = -1): Promise<any> {
        return table._createNewRowData().then(modelRow => {
            modelRow.setValues(value instanceof ModelRow ? value.data : value);
            modelRow._appending = value instanceof ModelRow ? value._appending : false;
            table.addRow(modelRow, { mode: TableRowMode.NONE }, { display: true, addToData: table.dataSource.isAddingOrUpdating() });
            if (table.rows.length == rowCount) {
                table.dataSource.updateOriginalRowCount();
                table.dataSource.preventChangeNotifications = false;
            }
        });
    }

    tableStopReferenceNumbersOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        if (tableRow?.mode === TableRowMode.QUICK_ADD && tableRow.data?.get("send_to_driver") == null) {
            const textboxSendToDriver = tableRow.findComponentById("textboxSendToDriver") as Textbox;
            textboxSendToDriver.selectedItem = { value: "N", caption: "No" };
            textboxSendToDriver.updateBoundData(tableRow.data, tableRow.table.dataSource.mode);
        }
        const tbRefQual = tableRow.findComponentById("textboxReferenceQual") as Textbox;
        tbRefQual.onSelectItem = ((tb, selection) => {
            if (selection != null) {
                tableRow.data.set("description", (selection as ModelRow).get("description"))
            }
            return undefined;
        });
    }

    updateStopsTabCaption(table: Table) {
        const tab = table.parent as Tab;
        let hotCommentCount = 0;
        if (tab != null && tab["countLabel"] != null) {
            tab["countLabel"].caption = table.rows.length + "";
            for (const tableRow of table.rows) {
                if (!tableRow.data?.isNull("comment_type") && "HC" === tableRow.data?.get("comment_type"))
                    hotCommentCount++;
            }
            if (hotCommentCount > 0 && !tab["_heading"].contains(tab["hotCommentCountLabel"])) {
                tab["countLabel"].fillRow = false;
                tab["countLabel"].marginRight = 18;
                tab["hotCommentCountLabel"] = new Label({
                    fontBold: true, caption: hotCommentCount.toString(), fillRow: true,
                    color: "primary", rowBreak: false
                });
                tab["hotCommentCountLabel"].imageName = "fire";
                tab["_heading"].insert(tab["hotCommentCountLabel"], 2);
            }
            else if (hotCommentCount == 0 && tab["_heading"].contains(tab["hotCommentCountLabel"])) {
                tab["_heading"].remove(tab["hotCommentCountLabel"]);
                tab["countLabel"].fillRow = true;
            }
            else if (hotCommentCount > 0 && tab["_heading"].contains(tab["hotCommentCountLabel"]))
                tab["hotCommentCountLabel"].caption = hotCommentCount.toString();

        }
    }

    textboxReferenceQualBeforeLookupModelSearch(event) {
        const searchFilter = { text_search: (event.target as Textbox).text };
        const layoutStop = this.owner;
        if (layoutStop.owner != null) {
            let customerId;
            if (layoutStop.owner instanceof Table)
                customerId = layoutStop.orderSource.data[0].get("customer_id");
            else
                customerId = layoutStop.orderSource.data[0].get("customer_id");
            if (!StringUtil.isEmptyString(customerId)) {
                log.debug("Found customer " + customerId);
                searchFilter["customer_id"] = customerId;
                searchFilter["code_type"] = "Qualifier";
                searchFilter["use_enabled_flag"] = "Y";
            }
        } else if (layoutStop.sourceStop != null) {
            const stopId = layoutStop.sourceStop.data[0].get("id");
            if (!StringUtil.isEmptyString(stopId)) {
                log.debug("Using stop  " + stopId);
                searchFilter["stop_id"] = stopId;
                searchFilter["code_type"] = "Qualifier";
                searchFilter["use_enabled_flag"] = "Y";
            }
        }
        event.filter = searchFilter;
    }

    async textboxReferenceQualOnBlur(event) {
        let qualifier = "";
        let sendToDriver = "N";
        try {
            qualifier = event.target.lookupModelData[0].data.standard_code;
            sendToDriver = (StringUtil.isEmptyString(event.target.lookupModelData[0].data.send_to_driver)) ? "N" : event.target.lookupModelData[0].data.send_to_driver;
        } catch (reason) { }

        const currRefnoRow = event.target.row as TableRow;
        const textbox = currRefnoRow.findComponentById("textboxSendToDriver") as Textbox;
        textbox.selectedItem = { value: sendToDriver, caption: "Y" == sendToDriver ? "Yes" : "No" };

    }

    saveChangesFromEdit(source: StopNotesAndReferenceNumbers) {
        this.updateSourceTables(this.tableStopComments, source.tableStopComments)
        this.updateSourceTables(this.tableStopReferenceNumbers, source.tableStopReferenceNumbers)
    }

    updateSourceTables(thisTable: Table, sourceTable: Table) {
        const dataSource = thisTable.dataSource;
        const sourceTableMap = new Map(sourceTable.rows.map((row) => [row.data.get("id"), row]));
        if (dataSource.deletedData != null) {
            for (const row of dataSource.deletedData) {
                const rowToDelete = sourceTableMap.get(row.get("id"));
                if (rowToDelete != null)
                    rowToDelete.deleteRow();
            }
        }
        for (const modelRow of thisTable.data) {
            const sourceTableRow = sourceTableMap.get(modelRow.get("id"));
            if (sourceTableRow == null)
                this.addTableRow(sourceTable, modelRow).then(() => this.updateStopsTabCaption(sourceTable));
            else
                sourceTableRow.data.setValues(modelRow.data);
        }
        sourceTable.displayData(null, sourceTable.data, 0);
        this.updateStopsTabCaption(sourceTable);
    }

    styleTab(tab: Tab) {
        if (tab["_heading"] != null && tab["_headingLabel"] != null) {
            tab["_heading"].color = "subtle.light";
            tab["_heading"].marginBottom = 10;
            tab["_headingLabel"].setProps({ color: "default", fontBold: true, fillRow: false })
            if (tab["countLabel"] == null) {
                tab["countLabel"] = new Label({
                    fontBold: true, caption: "0", fillRow: true,
                    color: "primary", rowBreak: false, marginLeft: 5
                });
                tab["_heading"].insert(tab["countLabel"], 1);
            }
        }
    }

    /** This is an event handler for the onDataDisplay event of labelEnteredUserId.  */
    labelEnteredUserIdOnDataDisplay(event: DataDisplayEvent) {
        let name = event.rowData.getFirstLookupModelData("entered_user_id")?.name;
        if (name == null) {
            const lmData = new ModelRow("common/users", false, { name: getAuthSettings()?.user_settings?.user_name, id: getAuthSettings()?.user_settings?.user_id });
            lmData.type = ModelRowType.LOOKUP_MODEL_DATA;
            event.rowData.setLookupModelData("entered_user_id", lmData);
            name = lmData.get("name") || event.rowData?.get("entered_user_id");
        }
        (event.target as Label).caption = name;
    }

    /** This is an event handler for the onDataDisplay event of textboxComments.  */
    textboxCommentsOnDataDisplay(event: DataDisplayEvent) {
        if (event.rowData?.get("comment_type") == "HC") {
            const tableRow = TableRow.getContainingTableRow(event.target as Textbox);
            tableRow.findComponentById("fireImage").visible = true;
        }
    }
    /** This is an event handler for the onChange event of textboxCommentType.  */
    textboxCommentTypeOnChange(event) {
        const component = event.target.row?.cells[1] as Textbox;
        if (event.newValue == "Hot comment" && event.userInitiatedChange == true) {
            event.target.row.findComponentById("fireImage").visible = true;
        }
        else if (event.newValue != "Hot comment" && event.userInitiatedChange == true) {
            event.target.row.findComponentById("fireImage").visible = false;
        }
    }

    /** This is an event handler for the onContentsChanged event of tableStopComments.  */
    tableStopCommentsOnContentsChanged(event) {
        this.updateStopsTabCaption(event.getTable());
    }
}
