import { Button, DataSource, HorizontalSpacer, Label, Layout, Panel, Table } from "@mcleod/components";

export abstract class AutogenLayoutTenderRates extends Layout {
    buttonExpandCharges: Button;
    horizontalspacer3: HorizontalSpacer;
    labelAdditionalCharges: Label;
    labelNumberOfCharges: Label;
    panelExpand: Panel;
    panelOtherChargeTable: Panel;
    panelOtherCharges: Panel;
    panelOtherChargesHeader2: Panel;
    panelOtherChargesHeader: Panel;
    sourceOtherChargeEdi: DataSource;
    tableOtherCharges: Table;
}
