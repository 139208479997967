import { Button, Checkbox, CityState, DataHeader, DataSource, HorizontalSpacer, Label, Layout, Map, Panel, Switch, Tab, Tabset, Textbox } from "@mcleod/components";
import { TableComment } from "../../../general/src/TableComment";
import { TableContact } from "../../../general/src/TableContact";
import { CallRecordTable } from "../CallRecordTable";
import { ProductBookTable } from "../ProductBookTable";
import { ProhibitedTable } from "../ProhibitedTable";

export abstract class AutogenLayoutLocation extends Layout {
    buttonAllCalls: Button;
    buttonLocationUsers: Button;
    buttonOpenClassic: Button;
    checkApptRequired: Checkbox;
    checkConsignee: Checkbox;
    checkCustomer: Checkbox;
    checkDistCenter: Checkbox;
    checkShipper: Checkbox;
    checkboxIsDropYard: Checkbox;
    checkboxIsProspect: Checkbox;
    dataheader1: DataHeader;
    horizontalspacer1: HorizontalSpacer;
    labbelThursdayHours: Label;
    labelFridayHours: Label;
    labelHeaderContactEmail: Label;
    labelHeaderContactName: Textbox;
    labelHeaderContactPhone: Label;
    labelHeaderLocAddr: Label;
    labelHeaderLocCSZ: CityState;
    labelHeaderLocName: Textbox;
    labelLocationHours: Label;
    labelMondayHours: Label;
    labelSaturdayHours: Label;
    labelSundayHours: Label;
    labelTuesdayHours: Label;
    laebelWednesdayHours: Label;
    layout1: ProhibitedTable;
    layoutCallRecord: CallRecordTable;
    layoutContactTable: TableContact;
    layoutProductBookTable: ProductBookTable;
    layoutTableComment: TableComment;
    mapLocation: Map;
    panel1: Panel;
    panel2: Panel;
    panel3: Panel;
    panel4: Panel;
    panel5: Panel;
    panelDataHeaderAddlLeft: Panel;
    panelDetailCenter: Panel;
    panelHeader: Panel;
    panelHeaderContact: Panel;
    panelHeaderLocation: Panel;
    sourceAgencyLink: DataSource;
    sourceCallRecord: DataSource;
    sourceComments: DataSource;
    sourceContacts: DataSource;
    sourceLocation: DataSource;
    sourceLocationCommodity: DataSource;
    sourceProhibited: DataSource;
    switchIsActive: Switch;
    tabComments: Tab;
    tabContacts: Tab;
    tabDetail: Tab;
    tabGeneral: Tab;
    tabMap: Tab;
    tabProductBook: Tab;
    tabProhibited: Tab;
    tabSalesCalls: Tab;
    tabsetLocation: Tabset;
    textAddress1: Textbox;
    textAddress2: Textbox;
    textCity: CityState;
    textCommodityId: Textbox;
    textCustomerId: Textbox;
    textDirections: Textbox;
    textFridayClose: Textbox;
    textFridayOpen: Textbox;
    textId: Textbox;
    textLoadingInstructions: Textbox;
    textMondayClose: Textbox;
    textMondayOpen: Textbox;
    textName: Textbox;
    textPNNCity: CityState;
    textReceivingInstructions: Textbox;
    textSaturdayClose: Textbox;
    textSaturdayOpen: Textbox;
    textSundayClose: Textbox;
    textSundayOpen: Textbox;
    textThursdayClose: Textbox;
    textThursdayOpen: Textbox;
    textTuesdayClose: Textbox;
    textTuesdayOpen: Textbox;
    textWebsiteURL: Textbox;
    textWednesdayClose: Textbox;
    textboxAssignedTo: Textbox;
    textboxDaysBetweenCalls: Textbox;
    textboxDeliveryOptions: Textbox;
    textboxDriverLoadId: Textbox;
    textboxDriverUnloadId: Textbox;
    textboxGooglePlacesSearch: Textbox;
    textboxNextCallDate: Textbox;
    textboxPickupOptions: Textbox;
    textdnesdayOpen: Textbox;
}
