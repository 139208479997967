import { Label, TableRowDisplayEvent } from "@mcleod/components";
import { ModelRow } from "@mcleod/core";
import { makeTooltipCallbackFunction } from "./EDITenderProfileMakeQuickInfo";
import { AutogenLayoutOrderLoadTenderHistory } from "./autogen/AutogenLayoutOrderLoadTenderHistory";

export class OrderLoadTenderHistory extends AutogenLayoutOrderLoadTenderHistory {
    private _orderId;

    override onLoad(): void {
        this.table1.toolsPanel.rowBreak = false;
        this.mainDataSource.orderBy = [{ field: "create_date", sort: "desc" }];
    }

    tableTendersOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        const modelRow: ModelRow = tableRow.data as ModelRow;

        const labelPartnerName = tableRow.findComponentById("labelPartnerName") as Label;
        if (tableRow.data != null) {
            labelPartnerName.tooltipCallback = makeTooltipCallbackFunction(modelRow.get("id"), labelPartnerName);
        }
    }

    set orderId(value: string) {
        this._orderId = value;
    }

    get orderId(): string {
        return this._orderId;
    }
}
