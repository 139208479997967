/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelServicefail.ts                   *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/dispatch/servicefail";

export class AutogenModelServicefail<RowType extends ModelRow> extends Endpoint<RowType, RowServicefailFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowServicefail extends ModelRow<RowServicefailFields> {
    constructor(values?: Partial<RowServicefailFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowServicefailFields {
    actual_arrival: Date;
    actual_departure: Date;
    appt_required: string;
    caused_by_type: string;
    company_id: string;
    customer_id: string;
    dispatcher_user_id: string;
    driver_id: string;
    dsp_comment: string;
    edi_code_version: string;
    edi_element_id: string;
    edi_standard_code: string;
    entered_date: Date;
    entered_user_id: string;
    fault_of_carrier_or_driver: string;
    fleet_manager: string;
    id: string;
    is_reportable: string;
    minutes_late: number;
    movement_id: string;
    name: string;
    operations_user: string;
    order_id: string;
    override_payee_id: string;
    resolution: string;
    review_reqd: string;
    reviewed_by: string;
    reviewed_date: Date;
    sched_arrive_early: Date;
    sched_arrive_late: Date;
    status: string;
    stop_id: string;
    stop_no: number;
    stop_type: string;
    terminal_id: string;
    tractor_id: string;
    trailer_id: string;
    user_id: string;
}

