/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelOrdersTrailerType.ts             *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/dispatch/orders-trailer-type";

export class AutogenModelOrdersTrailerType<RowType extends ModelRow> extends Endpoint<RowType, RowOrdersTrailerTypeFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowOrdersTrailerType extends ModelRow<RowOrdersTrailerTypeFields> {
    constructor() {
        super(AutogenUserSavedSearchEndpoint);
    }
}

export interface RowOrdersTrailerTypeFields {
    applies_to: string;
    company_id: string;
    descr: string;
    equip_length: number;
    equip_width: number;
    exchange_type: string;
    fms_length: string;
    fms_type: string;
    id: string;
    picture_resource: string;
    pnn_code_id: string;
    web_order: number;
}

