import { Button, ClickEvent, DataSource, DataSourceMode, Event, Layout, Panel, PanelProps, SlideoutDecorator } from "@mcleod/components";
import { SelectionMode } from "@mcleod/components/src/base/SelectionMode";
import { RearrangeStops } from "./RearrangeStops";
import { RecRearrangeStops } from "./RecRearrangeStops";

export class RearrangeStopSlideout extends Panel {
    private orderId: string;
    private orderType: string;
    private rearrangeLayoutPath: string;
    private rearrangeLayout: RearrangeStops | RecRearrangeStops;
    private saveButton: Button = new Button({ id: "rearrangeSaveButton", caption: "Save", enabled: false, disabledTooltip: "You cannot save this record because nothing has changed.", backgroundColor: "primary", minWidth: 128, borderWidth: 0, rowBreak: false, });

    constructor(orderType: string, orderId: string, props?: Partial<PanelProps>) {
        super({
            maxWidth: 1200, width: 1200, height: window.innerHeight,
            backgroundColor: "defaultBackground", top: 30, padding: 0,
            borderRadius: 4, borderWidth: 1, borderShadow: true, borderColor: "strokeSecondary"
        });
        this.orderType = orderType;
        this.orderId = orderId;
        this.rearrangeLayoutPath = orderType == "R" ? "lme/dispatch/RecRearrangeStops" : "lme/dispatch/RearrangeStops";
    }


    public showSlideout(doAfterClose: (canceled: boolean) => void) {
        if (this.orderId) {
            this.rearrangeLayout = Layout.getLayout(this.rearrangeLayoutPath, { fillHeight: true, width: 1200 }) as RearrangeStops | RecRearrangeStops;
            this.rearrangeLayout.addLayoutLoadListener(event => {
                this.showRearrangeStops(doAfterClose);
            })
        }
    }

    private showRearrangeStops(doAfterClose) {
        new SlideoutDecorator({
            layout: this.rearrangeLayout,
            title: `Reorder Stops - ${this.orderType == "R" ? "Recurring" : ""} Order ${this.orderId}`,
            fillHeight: true,
            layoutLoadListeners: (event: Event) => {
                const layout = event.target as RearrangeStops | RecRearrangeStops;
                const tableStop = layout.tableStop;
                tableStop.dataSource.mode = DataSourceMode.UPDATE;
                tableStop.selectionMode = SelectionMode.SINGLE;
                tableStop.fillHeight = true;
            },
            doAfterSlideIn: async (decorator: SlideoutDecorator) => {
                this.rearrangeLayout.doAfterClose = doAfterClose;
                this.rearrangeLayout.orderId = this.orderId;
                await this.rearrangeLayout.mainDataSource.search({ "order_id": this.orderId, "order_type": this.orderType });
                this.saveButton["doOnDataSourceChanged"] = (ds: DataSource, value: boolean) => {
                    this.saveButton.enabled = value;
                }
                this.rearrangeLayout.mainDataSource.addHasChangedComponent(this.saveButton);
                this.saveButton.addClickListener(async (event: ClickEvent) => {
                    this.saveButton.busy = true;
                    this.rearrangeLayout.onSave().finally(() => {
                        this.saveButton.busy = false;
                    });
                });
            },
            addlComponents: this.saveButton,
            overlayProps: { width: 1200, height: "60%", closeOnClickOff: false, greyedBackground: true }
        });
    }

}
