import { CommonTooltips } from "@mcleod/common";
import { ClickEvent, Component, TableRowDisplayEvent, Textbox } from "@mcleod/components";
import { CurrencyUtil, DisplayType, ModelRow } from "@mcleod/core";
import { ExpandableFilterController } from "@mcleod/general/src/ExpandableFilterController";
import { AutogenLayoutCarrierOfferList } from "@mcleod/powerbroker/src/autogen/AutogenLayoutCarrierOfferList";

export class CarrierOfferList extends AutogenLayoutCarrierOfferList {
    private expandableFilter: ExpandableFilterController = new ExpandableFilterController();

    override onLoad() {
        this.panelOffersListContainer.rows[0].style.height = "100%"; // pretty nasty hack to make the vertical scroll bar behave
        this.setupExpandableFilter();
    }

    /** This is an event handler for the onRowDisplay event of tableOffers.  */
    tableOffersOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        tableRow.forEveryChildComponent(comp => {
            this.setTooltipCallback(comp, tableRow.data);
        });
    }

    setupExpandableFilter() {
        this.expandableFilter.initialize(this.panelFilterHeader, this.panelFilterContent);
        this.expandableFilter.setSearchBordersExpanded();
        this.expandableFilter.onClear = (event) => this.buttonClearOnClick(event);
    }

    setTooltipCallback(comp: Component, modelRow: ModelRow) {
        switch (comp.id) {
            case "labelOfferDate":
                return CommonTooltips.setTooltipFromLayoutCallback(comp, modelRow.get("entered_user_id"), "common/UserQuickInfo", { minWidth: 128, minHeight: 140 });
            case "labelOriginLocationName":
                return CommonTooltips.setTooltipFromLayoutCallback(comp, modelRow.get("origin.location_id"), "lme/dispatch/LocationQuickInfo", { minWidth: 128, minHeight: 140 });
            case "labelDestinationLocationName":
                return CommonTooltips.setTooltipFromLayoutCallback(comp, modelRow.get("destination.location_id"), "lme/dispatch/LocationQuickInfo", { minWidth: 128, minHeight: 140 });
            case "labelCarrierName":
                if (!modelRow.isNull("payee_id")) {
                    return CommonTooltips.setTooltipFromLayoutCallback(comp, modelRow.get("payee_id"), "lme/powerbroker/CarrierQuickInfo", { minWidth: 128, minHeight: 140 });
                }
            case "labelOrderTotalChg":
                return CommonTooltips.setPanelTooltipCallback(comp, () => [
                    { caption: "Freight Charge", displayType: DisplayType.CURRENCY, text: CurrencyUtil.formatCurrency(modelRow.get("orders.freight_charge")) },
                    { caption: "Other Charges", displayType: DisplayType.CURRENCY, text: CurrencyUtil.formatCurrency(modelRow.get("orders.otherchargetotal")) }]
                );
        }
    }

    /** This is an event handler for the onClick event of buttonClear.  */
    buttonClearOnClick(event: ClickEvent) {
        this.tableOffers.displayData(null, null, 0);
        this.panelFilterComponents.forEveryChildComponent(comp => {
            if (comp instanceof Textbox) {
                comp.clear();
            }
        })
    }

}
