import { DataSource, Label, Layout, Switch, Tab, Tabset, Textbox } from "@mcleod/components";
import { BrokeragePlanningPerformanceOrders } from "../../BrokeragePlanningPerformanceOrders";
import { BrokeragePlanningPerformanceProfit } from "../../BrokeragePlanningPerformanceProfit";
import { BrokeragePlanningPerformanceRevenue } from "../../BrokeragePlanningPerformanceRevenue";

export abstract class AutogenLayoutBrokeragePlanningPerformance extends Layout {
    labelMessage: Label;
    layoutBPPerfOrders: BrokeragePlanningPerformanceOrders;
    layoutBPPerfProfit: BrokeragePlanningPerformanceProfit;
    layoutBPPerfRevenue: BrokeragePlanningPerformanceRevenue;
    sourceBrokeragePlanningPerf: DataSource;
    switchView: Switch;
    tabOrders: Tab;
    tabProfit: Tab;
    tabRevenue: Tab;
    tabsetBPPerf: Tabset;
    textboxProfileId: Textbox;
}
