import { AutogenLayoutCarrierSelectionRateChange } from "./brltl/autogen/AutogenLayoutCarrierSelectionRateChange";
import { Alignment, Api, ModelRow } from "@mcleod/core";
import { Button, ClickEvent, Image, ImageType, Layout, SlideoutDecorator, Toast } from "@mcleod/components";
import { RowBrltlBillingFreightGroup } from "./models/ModelBrltlBillingFreightGroup";

export class CarrierSelectionRateChange extends AutogenLayoutCarrierSelectionRateChange {

    static showSlideout(currentRate: RowBrltlBillingFreightGroup, newRate: RowBrltlBillingFreightGroup, afterRerate?: () => Promise<any>) {
        const layout = Layout.getLayout("lme/dispatch/brltl/CarrierSelectionRateChange") as CarrierSelectionRateChange;
        new SlideoutDecorator({
            layout: layout,
            width: "50%",
            onClose: null,
            layoutLoadListeners: async () => {
                const imageBytes = currentRate.get("image_bytes");
                if (!!imageBytes) {
                    const carrierLogo = new Image({
                        id: "imageCarrierLogo",
                        imageType: ImageType.IMG,
                        preserveAspectRatio: true,
                        height: 50,
                        width: 100
                    });
                    carrierLogo.imageBytes = imageBytes;
                    layout.panelCarrierLogo.add(carrierLogo);
                }
                layout.panelCurrent.displayData(currentRate, null, 0);
                layout.labelCurrentBlanketRate.visible = currentRate.get("bfg_type_code") === "BCST";
                const currentRevenueDetails = currentRate.get("revenue_details");
                layout.layoutOldRevenueDetails.chosenCarrier = currentRate;
                layout.layoutOldRevenueDetails.tableRevenueDetails.dataSource.data = currentRevenueDetails.map((row: Partial<any>) => new ModelRow("lme/dispatch/revenue-detail", false, row));
                layout.layoutOldRevenueDetails.tableRevenueDetails.dataSource.displayDataInBoundComponents();

                layout.panelNew.displayData(newRate, null, 0);
                layout.labelNewBlanketRate.visible = newRate.get("bfg_type_code") === "BCST";
                const newRevenueDetails = newRate.get("revenue_details");
                layout.layoutNewRevenueDetails.chosenCarrier = newRate;
                layout.layoutNewRevenueDetails.tableRevenueDetails.dataSource.data = newRevenueDetails.map((row: Partial<any>) => new ModelRow("lme/dispatch/revenue-detail", false, row));
                layout.layoutNewRevenueDetails.tableRevenueDetails.dataSource.displayDataInBoundComponents();
            },
            fillVerticalSpace: true,
            transitionOptions: { direction: Alignment.RIGHT },
            overlayProps: { greyedBackground: true, closeOnClickOff: false },
            title: "Consigned Order Rate Change",
            addlComponents: [
                new Button({
                    id: "buttonDecline",
                    caption: "Decline",
                    fontSize: "medium",
                    backgroundColor: "primary.reverse",
                    onClick: async (event: ClickEvent) => {
                        handleRateChangeOnClick(event, false);
                    },
                    color: "primary",
                    minWidth: 128,
                    borderWidth: 0,
                    rowBreak: false
                }),
                new Button({
                    id: "buttonAccept",
                    caption: "Accept",
                    fontSize: "medium",
                    backgroundColor: "primary",
                    onClick: async (event: ClickEvent) => {
                        handleRateChangeOnClick(event, true);
                    },
                    color: "primary.reverse",
                    minWidth: 128,
                    borderWidth: 0,
                    rowBreak: false
                })
            ]
        });

        function handleRateChangeOnClick(event: ClickEvent, accept: boolean) {
            const button = event.target as Button;
            button.busy = true;
            Api.post("lme/dispatch/brltl/consigned-rate-change", { order_id: newRate.get("order_id"), bfg_uid: newRate.get("bfg_uid"), accept_new_rate: accept }).finally(async () => {
                if (afterRerate) {
                    await afterRerate();
                }
                button.busy = false;

                layout.slideOut().then(() => {
                    if (accept) {
                        Toast.showSuccessToast("Rate change accepted.");
                    }
                });
            });
        }
    }
}
