import { DataDisplayEvent, ParentSearchMode } from "@mcleod/components";
import { Api, DatePart, DateUtil } from "@mcleod/core";
import { MovementOffers } from "./MovementOffers";
import { AutogenLayoutAvailableMovementOverview } from "./autogen/AutogenLayoutAvailableMovementOverview";

export class AvailableMovementOverview extends AutogenLayoutAvailableMovementOverview {

    private setupOffersRelationship(movementId, orderId) {
        const movementOffers = this.layoutOffers as MovementOffers;
        const sourceOffers = movementOffers.mainDataSource;
        sourceOffers.parentDataSource = this.sourceMovement;
        sourceOffers.parentSearchMode = ParentSearchMode.onlyWhenVisible;
        movementOffers.initialize(orderId, this.sourceMovement, movementId);
    }

    set stopCountText(value: string) {
        this.labelIntermediateStops.caption = value;
        (this.layoutOffers as MovementOffers).stopCountText = value
    }

    labelOriginApptRequiredOnDataDisplay(event: DataDisplayEvent) {
        this.labelOriginApptRequired.visible = event.rowData?.get("stop_origin.appt_required") === "Y" && event.rowData?.get("stop_origin.confirmed") === "N";
        this.labelOriginApptConfirmed.visible = event.rowData?.get("stop_origin.appt_required") === "Y" && event.rowData?.get("stop_origin.confirmed") === "Y";
    }

    labelDestApptRequiredOnDataDisplay(event: DataDisplayEvent) {
        this.labelDestApptRequired.visible = event.rowData?.get("stop_dest.appt_required") === "Y" && event.rowData?.get("stop_dest.confirmed") === "N";
        this.labelDestApptConfirmed.visible = event.rowData?.get("stop_dest.appt_required") === "Y" && event.rowData?.get("stop_dest.confirmed") === "Y";
    }

    sourceMovementAfterExecution(event) {
        if (event?.getAction() == "search") {
            const orderId = this.sourceMovement.activeRow?.get("orders.id");
            if (orderId != null)
                this.sourceEquipMatchDetail.search({ _parent_link: { model: this.sourceMovement.url, "orders.id": orderId } });
            this.labelTrackingRequired.visible = this.sourceMovement.activeRow?.get("show_tracking_badge", false);

            const originSchedArriveEarly = this.sourceMovement.activeRow?.get("stop_origin.sched_arrive_early");
            const originSchedArriveLate = this.sourceMovement.activeRow?.get("stop_origin.sched_arrive_late");
            if (originSchedArriveLate == null || this.sameDate(originSchedArriveEarly, originSchedArriveLate)) {
                this.labelOriginSchedArriveLateDay.visible = false;
                this.labelOriginSchedArriveLateMonth.visible = false;
                this.labelOriginSchedArriveLateDate.visible = false;
                this.labelOriginTo.visible = false;
                if (originSchedArriveLate == null || DateUtil.timesEqual(originSchedArriveEarly, originSchedArriveLate)) {
                    this.labelOriginTimeLate.visible = false;
                    this.labelOriginDash.visible = false;
                }
            }
            const destSchedArriveEarly: Date = this.sourceMovement.activeRow?.get("stop_dest.sched_arrive_early");
            const destSchedArriveLate: Date = this.sourceMovement.activeRow?.get("stop_dest.sched_arrive_late");
            if (destSchedArriveLate == null || this.sameDate(destSchedArriveEarly, destSchedArriveLate)) {
                this.labelDestSchedArriveLateDay.visible = false;
                this.labelDestSchedArriveLateMonth.visible = false;
                this.labelDestSchedArriveLateDate.visible = false;
                this.labelDestTo.visible = false;
                if (destSchedArriveLate == null || DateUtil.timesEqual(destSchedArriveEarly, destSchedArriveLate)) {
                    this.labelDestTimeLate.visible = false;
                    this.labelDestDash.visible = false;
                }
            }
        }
        this.setupOffersRelationship(this.mainDataSource.activeRow.get("id"), this.mainDataSource.activeRow.get("orders.id"));
    }

    sourceMovementOnDisplay() {
        const movementId = this.mainDataSource.activeRow?.get("id") as string;
        const orderId = this.mainDataSource.activeRow?.get("orders.id") as string;
        const customerId = this.mainDataSource.activeRow?.get("customer.id") as string;

        const layoutMovementDataFusionSummary = this.layoutMovementDataFusionSummary;
        if (movementId && orderId && customerId) {
            layoutMovementDataFusionSummary.movementId = movementId;
            layoutMovementDataFusionSummary.orderId = orderId;
            layoutMovementDataFusionSummary.customerId = customerId;
            const filter = [
                {
                    movement_id: movementId,
                    order_id: orderId,
                    customer_id: customerId
                }
            ];
            const body = JSON.stringify(filter);
            Api.search("lme/dispatch/movement-datafusion-summary", body).then(response => {
                if (response) {
                    layoutMovementDataFusionSummary.setEdiData(response.data[0]);
                    this.panelDataFusion.visible = layoutMovementDataFusionSummary.displayingData;
                }
            });
        }
    }

    private sameDate(originSchedArriveEarly: Date, originSchedArriveLate: Date): boolean {
        const early = DateUtil.parseDate(originSchedArriveEarly);
        const late = DateUtil.parseDate(originSchedArriveLate);
        const diff = DateUtil.dateDiff(DatePart.DAY, late, early);
        return diff == 0;
    }
}
