import { ModelRow, StringUtil } from "@mcleod/core";
import { AutogenLayoutHandlingRequirementsCompareItem } from "./autogen/AutogenLayoutHandlingRequirementsCompareItem";
import { DataDisplayEvent, DataSourceMode, Label } from "@mcleod/components";

export class HandlingRequirementsCompareItem extends AutogenLayoutHandlingRequirementsCompareItem {
    private _compareItems: ModelRow[];

    public async setupTableData(data: ModelRow[]) {
        this._compareItems = data;
    }

    override onLoad(): void | Promise<void> {
        this.tableCompareItems.dataSource = this.sourceEdiCompareItem;
        this.tableCompareItems.dataSource.data = [];
        const newTable = this.filterTable();
        if (newTable != null) {
            if (newTable.length === 0) {
                this.tableCompareItems.visible = false;
            } else {
                this.tableCompareItems.dataSource.setRowsAndMode(DataSourceMode.NONE, newTable);
            }
        }
    }

    onDescrDisplay(event: DataDisplayEvent) {
        const labelDescr = <Label>event.target
        if (StringUtil.isEmptyString(event.rowData.get("target_descr"))) {
            labelDescr.caption = event.rowData.get("source_descr");
        }
        if (event.rowData.get("handling_req_value") !== null && event.rowData.get("handling_req_value") !== undefined) {
            labelDescr.caption = event.rowData.get("handling_req_value");
        }
    }

    valueOnDisplay(event: DataDisplayEvent) {
        const label = event.target as Label;
        if (label.caption == "1900-01-01") {
            label.caption = "";
        }
    }

    filterTable() {
        let newTable = [];
        newTable = this._compareItems?.filter((item) => {
            return item?.data?.handling_req_value !== null && item?.data?.handling_req_value !== undefined;
        });
        return newTable;
    }
}
