import { BlurEvent, ClickEvent, DataSourceExecutionEvent, DataSourceModeChangeEvent, LookupModelSearchEvent, Panel, Snackbar, Textbox } from "@mcleod/components";
import { TableRowMode } from "@mcleod/components/src/components/table/TableRow";
import { TextboxValidator } from "@mcleod/components/src/components/textbox/TextboxValidator";
import { DataSource, DataSourceAction, DataSourceMode } from "@mcleod/components/src/databinding/DataSource";
import { Api, ArrayUtil, McLeodClassicIntegration, ModelRow, ModelSearchResult, Navigation, PermissionsUtil, StringUtil } from "@mcleod/core";
import { UsersResponsibilitySlideoutMF } from "../../general/src/UsersResponsibilitySlideoutMF";
import { getDispatchControlBoolean } from "../../general/src/models/ModelDispatchControl";
import { CarrierOverride } from "./CarrierOverride";
import { CarrierQualification } from "./CarrierQualification";
import { AutogenLayoutCarrier } from "./autogen/AutogenLayoutCarrier";

type InsuranceInfo = {
    insurer_name: string,
    insurer_phone: string,
    insurance_policy_number: string,
    insurance_amount: number,
    expiration_date: string
}

export class Carrier extends AutogenLayoutCarrier {
    private static viewCarrierURL = "lme/dispatch/Carrier?mode=update&key=";
    private _ovrrdTimeoutHandle: number;

    override onLoad(): void {
        this.textboxId.lookupModel = null;
        this.textboxId.lookupModelLayout = null;
        this.addCimVendorUrl();
        this.sourceCarrier.afterGetDataboundValues = (row: ModelRow, dataSource: DataSource) => this.sourceCarrierAfterGetDataboundValues(row, dataSource);
    }

    displayCarrierUsersSlideout() {
        const urs = new UsersResponsibilitySlideoutMF("lme/dispatch/CarrierUsers", this.getCarrierUsersSlideoutTitle(), this.getCarrierUsersIdFilter(),
            this.getCarrierUsersAgencyField(), this.sourceCarrier, this.sourceAgencyLink);
        urs.show();
    }

    private getCarrierUsersSlideoutTitle(): string {
        const name = this.sourceCarrier.activeRow?.get("payee.name");
        return "Carrier Users" + ((StringUtil.isEmptyString(name) === false) ? (" - " + name) : "");
    }

    private getCarrierUsersIdFilter(): any {
        const id = this.sourceCarrier.activeRow?.get("id");
        if (id != null)
            return { id: id };
        return null;
    }

    private getCarrierUsersAgencyField(): string {
        return "payee.primary_agency";
    }

    /** This is an event handler for the beforeExecution event of sourceLocation.  It's gross. */
    sourceCarrierAfterGetDataboundValues(row: ModelRow, dataSource: DataSource) {
        if (dataSource.mode === DataSourceMode.SEARCH) {
            //have to set primary_agency in search mode because the field isn't bound
            const primaryAgencyField = this.getCarrierUsersAgencyField();
            row.set(primaryAgencyField, dataSource.searchRow.get(primaryAgencyField));

            //have to add agency_link search data to the filter manually, since there are no bound components for those records
            if (ArrayUtil.isEmptyArray(this.sourceAgencyLink.data))
                return;
            row.addLinkedModel({ model: this.sourceAgencyLink.url, rows: [this.sourceAgencyLink.data[0]] });
        }
    }

    tableTrailerTypesOnRowDisplay(event) {
        const tableRow = event.getTableRow();
        const lableDescr = tableRow.findComponentById("labelDescr");
        if (tableRow.mode === TableRowMode.QUICK_ADD && tableRow.data?.get("default_trailer") == null && this.mainDataSource.mode != null) {
            const textboxSendToDriver = tableRow.findComponentById("textboxDefaultTrailer") as Textbox;
            textboxSendToDriver.selectedItem = { value: "N", caption: "No" };
            textboxSendToDriver.updateBoundData(tableRow.data, tableRow.table.dataSource.mode);
        } else {
            if (tableRow.data.getLookupModelData("equipment_type_id") != null) {
                lableDescr.caption = tableRow.data.getFirstLookupModelData("equipment_type_id")?.get("descr");
            }
        }
        const textboxEquipmentTypeId = tableRow.findComponentById("textboxEquipmentTypeId") as Textbox;
        textboxEquipmentTypeId.onSelectItem = (textboxEquipmentTypeId, selection) => {
            lableDescr.caption = textboxEquipmentTypeId.boundRow.getFirstLookupModelData("equipment_type_id")?.get("descr");
            return undefined;
        };
        textboxEquipmentTypeId.addBlurListener(event => {
            if (event.changedWhileFocused && StringUtil.isEmptyString(textboxEquipmentTypeId.text))
                lableDescr.caption = textboxEquipmentTypeId.boundRow.getFirstLookupModelData("equipment_type_id")?.get("descr");
        });

    }

    sourceCarrierAfterExecution(event: DataSourceExecutionEvent) {
        if (this.sourceCarrier.activeRow != null) {
            if (!StringUtil.isEmptyString(this.activeRow?.get("active_vendor_name"))) {
                this.labelVendor.visible = true;
                this.labelVendor.caption = this.activeRow?.get("active_vendor_name");

                if (this.activeRow?.getBoolean("is_highway_active_vendor", false)) {
                    this.textboxHighwayIdNumber.visible = this.activeRow?.getBoolean("is_highway_active_vendor", false);
                    this.textboxHighwayRuleAssessment.visible = this.activeRow?.getBoolean("is_highway_active_vendor", false);
                    this.textboxHighwayStatus.visible = this.activeRow?.getBoolean("is_highway_active_vendor", false);
                } else {
                    this.switchIsCertifiedRMI.visible = this.activeRow?.getBoolean("is_certified_visible", false);
                    this.switchWatchdogReport.visible = this.activeRow?.getBoolean("watchdog_report_visible", false);
                    this.textboxOverAllRisk.visible = this.activeRow?.getBoolean("overall_risk_assessment_visible", false);

                    this.textboxHighwayIdNumber.visible = false;
                    this.textboxHighwayRuleAssessment.visible = false;
                    this.textboxHighwayStatus.visible = false;
                }
            } else {
                this.labelVendor.visible = false;
                this.switchIsCertifiedRMI.visible = false;
                this.switchWatchdogReport.visible = false;
                this.textboxOverAllRisk.visible = false;
                this.textboxHighwayIdNumber.visible = false;
                this.textboxHighwayRuleAssessment.visible = false;
                this.textboxHighwayStatus.visible = false;
            }

            Api.search("lme/powerbroker/carrier-qualifications", { "payee_id": this.sourceCarrier.activeRow.get("id") }).then(result => {
                const carrierValidationData = result?.data[0];
                const passed = carrierValidationData?.passed || false;
                const warning = carrierValidationData?.warning || false;
                this.tabQualifications.imageName = (passed && warning) ? "ribbon_warning" : passed ? "ribbon_check" : "ribbon_alert";
                const tabHeader = this.tabQualifications?.["_heading"];
                if (tabHeader != null)
                    tabHeader.tooltip = passed ? "Carrier passed all qualifications \nfor the carrier qualification profile." : "Carrier is disqualified based on\nthe carrier qualification profile.";
                (this.layoutQualification as CarrierQualification).setQualificationData(carrierValidationData);
                this.configureBtnOverrideCarrier();
            });

            this.sourceCarrierInsCtrl.search().then(async (result: ModelSearchResult) => {
                const data: ModelRow[] = result.modelRows;
                if (data.length !== 0 && data[0].get("vendor_id") === "H") {
                    await this.sourceCarrierInsurance.search({ "payee_id": this.sourceCarrier.activeRow.get("id") });

                    this.tabsetAdditionalInsurance.visible = true;

                    const generalInsuranceInfo = this.sourceCarrierInsurance.activeRow?.get("general_insurance_information");
                    const trailerInsuranceInfo = this.sourceCarrierInsurance.activeRow?.get("trailer_insurance_information");
                    const workersCompInsuranceInfo = this.sourceCarrierInsurance.activeRow?.get("workers_insurance_information");
                    const contingentAutoInsuranceInfo = this.sourceCarrierInsurance.activeRow?.get("contingent_auto_insurance_information");
                    const contingentCargoInsuranceInfo = this.sourceCarrierInsurance.activeRow?.get("contingent_cargo_insurance_information");

                    if (generalInsuranceInfo) {
                        this.populateInsurancePanels(this.generalLiabilityInsurancePanel, generalInsuranceInfo, "general_insurance");
                    }
                    if (trailerInsuranceInfo) {
                        this.populateInsurancePanels(this.interchangeInsurancePanel, trailerInsuranceInfo, "trailer_insurance");
                    }
                    if (workersCompInsuranceInfo) {
                        this.populateInsurancePanels(this.workersCompInsurancePanel, workersCompInsuranceInfo, "workers_comp_insurance");
                    }
                    if (contingentAutoInsuranceInfo) {
                        this.populateInsurancePanels(this.contAutoLiabilityPanel, contingentAutoInsuranceInfo, "contingent_auto_insurance_information");
                    }
                    if (contingentCargoInsuranceInfo) {
                        this.populateInsurancePanels(this.contCargoLiabilityPanel, contingentCargoInsuranceInfo, "contingent_cargo_insurance_information");
                    }
                }
            });

        }
    }

    public static navigateTo(id: string): void {
        Navigation.navigateTo(this.viewCarrierURL + id, { newTab: true });
    }

    buttonOpenClassicOnClick(event: ClickEvent) {
        McLeodClassicIntegration.openClassicScreen("com.tms.client.loadmaster.brdsp.EntryCarrierPayee", this.mainDataSource.activeRow.get("id"));
    }

    addCimVendorUrl() {
        const cimVendor = this.sourceCarrierInsCtrl.search()
            .then((result: ModelSearchResult) => {
                const data: ModelRow[] = result.modelRows;
                if (data.length == 0) {
                    this.btnCimVendorUrl.visible = false;
                }
                else {
                    const vendorId = data[0].get("vendor_id");
                    if (vendorId === 'D') {
                        this.btnCimVendorUrl.tooltip = 'Assure Advantage web site';
                    } else if (vendorId === "H") {
                        this.btnCimVendorUrl.tooltip = 'Highway Carrier Monitoring web site';
                        this.tabCustomClassifications.visible = true;
                    } else if (vendorId === "S") {
                        this.btnCimVendorUrl.tooltip = 'Safer Watch web site';
                    } else if (vendorId === "P") {
                        this.btnCimVendorUrl.tooltip = 'Transportation Data Source web site';
                    } else {
                        this.btnCimVendorUrl.visible = false;
                    }

                    this.btnCimVendorUrl.addClickListener((event) => {
                        let webUrl: string = "";
                        let mcNumber = this.activeRow?.get("icc_number");
                        if (!StringUtil.isEmptyString(mcNumber) && mcNumber.length > 2 && mcNumber.substring(0, 2) !== "MC") {
                            mcNumber = "MC" + mcNumber;
                        }
                        const dotNumber = this.activeRow?.get("dot_number");

                        if (vendorId === "D") {
                            webUrl = 'https://mycarrierpackets.com/CarrierInformation';
                            if (!StringUtil.isEmptyString(dotNumber)) {
                                webUrl = webUrl + '/DotNumber/' + dotNumber;
                            }
                            else if (!StringUtil.isEmptyString(mcNumber)) {
                                webUrl = webUrl + '/DocketNumber/' + mcNumber;
                            }
                            else {
                                webUrl = "https://mycarrierpackets.com";
                            }

                            if (!StringUtil.isEmptyString(webUrl)) {
                                window.open(webUrl, '_blank');
                            }
                        }
                        else if (vendorId === "H") {
                            const host = data[0].get("server");
                            webUrl = host + '/broker';
                            const highwayIdNumber = this.mainDataSource.activeRow.get("highway_id_number");
                            if (!StringUtil.isEmptyString(highwayIdNumber)) {
                                webUrl = webUrl + '/carriers/' + highwayIdNumber;
                                window.open(webUrl, '_blank');
                            }
                            else {
                                window.open(webUrl, '_blank');
                            }
                        }
                        else if (vendorId === "S") {
                            webUrl = 'http://www.saferwatch.com/swCarrierDetailsLink.php?';
                            if (!StringUtil.isEmptyString(dotNumber)) {
                                webUrl = webUrl + '&dotNumber=' + dotNumber;
                            }
                            else if (!StringUtil.isEmptyString(mcNumber)) {
                                webUrl = webUrl + '&docketNumber=' + mcNumber;
                            }
                            window.open(webUrl, '_blank');
                        }
                        else if (vendorId === "P") {
                            this.getTdsUrl(dotNumber)
                                .then(url => {
                                    if (!StringUtil.isEmptyString(url)) {
                                        window.open(url, '_blank');
                                    }
                                });
                        }

                    })
                }
            })

    }

    public async getTdsUrl(dotNumber: string) {
        return Api.post("lme/powerbroker/get-carrier-monitor-url", { "vendor_id": "P", "dot_number": dotNumber })
            .then(response => {
                const url = response.data[0]?.url;
                const errorMessage = response.data[0]?.error_msg;
                if (!StringUtil.isEmptyString(errorMessage)) {
                    Snackbar.showWarningSnackbar("DOT Number is required to access monitoring service website for this carrier");
                    return "";
                } else {
                    return url;
                }
            });
    }


    /** This is an event handler for the beforeModeChange event of sourceCarrier.  */
    sourceCarrierBeforeModeChange(event) {
        this.buttonOpenClassic.visible = event.newMode === DataSourceMode.UPDATE;
    }

    textboxCategoryBeforeLookupModelSearch(event: LookupModelSearchEvent) {
        event.filter.carrier = "Y";
    }

    checkExpiryDate(comp: Textbox) {
        let value: string | number = comp.text;
        const today = new Date().setUTCHours(0, 0, 0, 0);
        value = new Date(value).setUTCHours(0, 0, 0, 0)
        if (comp.validationWarning == null) {
            const expired: boolean = value < today;
            comp.color = expired ? "warning" : "default";
            comp.borderWidth = expired ? 1 : undefined;
            comp.borderColor = expired ? "warning" : undefined;
            comp.tooltip = expired ? "The expiry date is in the past." : undefined;
        }
    }

    /** This is an event handler for the onBlur event of textboxLiabilityExpDate.  */
    textboxLiabilityExpDateOnBlur(event: BlurEvent) {
        const component = event.target as Textbox;
        this.checkExpiryDate(component);
    }

    /** This is an event handler for the onBlur event of textboxCargoExpDate.  */
    textboxCargoExpDateOnBlur(event: BlurEvent) {
        const component = event.target as Textbox;
        this.checkExpiryDate(component);
    }

    /** This is an event handler for the afterModeChange event of sourceCarrier.  */
    sourceCarrierAfterModeChange(event: DataSourceModeChangeEvent) {
        this.checkExpiryDate(this.textboxCargoExpDate);
        this.checkExpiryDate(this.textboxLiabilityExpDate);
    }

    /** This is an event handler for the onClick event of buttonOverrideOrder.  */
    buttonOverrideCarrierOnClick(event: ClickEvent) {
        CarrierOverride.show({ payee_id: this.activeRow.get("id") });
    }

    /** This is an event handler for the onChange event of switchRequirementDispatchOverride.  */
    switchRequirementDispatchOverrideOnChange(event) {
        this.configureBtnOverrideCarrier();
    }

    /** This is an event handler for the onChange event of switchOverrideQualificationEnforcement.  */
    switchOverrideQualificationEnforcementOnChange(event) {
        this.configureBtnOverrideCarrier();
    }

    /** This is an event handler for the onChange event of textboxOverrideQualificationDate.  */
    textboxOverrideQualificationDateOnChange(event) {
        this.buttonOverrideCarrier.visible = false;

        if (this._ovrrdTimeoutHandle != null)
            window.clearTimeout(this._ovrrdTimeoutHandle);
        this._ovrrdTimeoutHandle = window.setTimeout(() => {
            this.configureBtnOverrideCarrier();
        }, this.textboxOverrideQualificationDate.lookupModelInputDelay);

    }

    configureBtnOverrideCarrier() {
        this.buttonOverrideCarrier.visible = this.showCarrierOverride();
    }

    showCarrierOverride(): boolean {
        if (this.activeRow == null)
            return false;

        if (!PermissionsUtil.isUserDeniedAction("Brokerage.Carrier dispatch override")) {
            if (this.activeRow.getBoolean("override_qual_enf")
                && getDispatchControlBoolean("enable_authorized")
                && (this.activeRow.isNull("override_qual_date") || this.isQualifiedDate())
            ) {
                return true;
            }

            if (this.activeRow.getBoolean("req_dispatch_over")) {
                return true;
            }
        }

        return false;
    }

    isQualifiedDate(): boolean {
        const validationResult = TextboxValidator.validate(this.textboxOverrideQualificationDate, true, false, null);
        if (validationResult != null && !validationResult.isValid)
            return false;

        const ovrrdQualDate = new Date(this.activeRow.get("override_qual_date")).setUTCHours(0, 0, 0, 0);
        const currentDate = new Date().setUTCHours(0, 0, 0, 0);

        return ovrrdQualDate > currentDate;

    }


    populateInsurancePanels(panel: Panel, insuranceData: InsuranceInfo, insuranceType: string) {
        if (StringUtil.isEmptyString(insuranceType)) {
            return;
        }

        const USDollar = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        if (StringUtil.equalsIgnoreCase("trailer_insurance", insuranceType)) {
            panel.visible = true;
            if (!StringUtil.isEmptyString(insuranceData.expiration_date)) {
                const date = this.formatExpirationDate(insuranceData.expiration_date);
                this.textboxInterchangeExpirationDate.text = date;
                this.textboxTrailerCancelDate.text = date;
            }
            this.textboxTrailerAmount.text = USDollar.format(insuranceData.insurance_amount);
            this.textboxTrailerCompany.text = insuranceData.insurer_name;
            this.textboxTrailerPolicyNum.text = insuranceData.insurance_policy_number;
            this.textboxTrailerPhone.text = insuranceData.insurer_phone;
        } else if (StringUtil.equalsIgnoreCase("general_insurance", insuranceType)) {
            panel.visible = true;
            if (!StringUtil.isEmptyString(insuranceData.expiration_date)) {
                const date = this.formatExpirationDate(insuranceData.expiration_date);
                this.generalLiabilityExpirationDate.text = date;
                this.generalLiabilityCancellationDate.text = date;
            }
            this.generalLiabilityAmount.text = USDollar.format(insuranceData.insurance_amount);
            this.generalLiabilityCompany.text = insuranceData.insurer_name;
            this.generalLiabilityPolicyNum.text = insuranceData.insurance_policy_number;
            this.generalLiabilityPhone.text = insuranceData.insurer_phone;
        } else if (StringUtil.equalsIgnoreCase("workers_comp_insurance", insuranceType)) {
            panel.visible = true;
            if (!StringUtil.isEmptyString(insuranceData.expiration_date)) {
                const date = this.formatExpirationDate(insuranceData.expiration_date);
                this.textboxWorkersCompExpirationDate.text = date;
                this.textboxWorkersCompCancellationDate.text = date;
            }
            this.textboxWorkersCompAmount.text = USDollar.format(insuranceData.insurance_amount);
            this.textboxWorkersCompCompany.text = insuranceData.insurer_name;
            this.textboxWorkersCompPolicyNum.text = insuranceData.insurance_policy_number;
            this.textboxWorkersCompPhone.text = insuranceData.insurer_phone;

        } else if (StringUtil.equalsIgnoreCase("contingent_auto_insurance_information", insuranceType)) {
            panel.visible = true;
            if (!StringUtil.isEmptyString(insuranceData.expiration_date)) {
                const date = this.formatExpirationDate(insuranceData.expiration_date);
                this.textboxContAutoExpirationDate.text = date;
                this.textboxContAutoCancellationDate.text = date;
            }
            this.textboxContAutoAmount.text = USDollar.format(insuranceData.insurance_amount);
            this.textboxContAutoCompany.text = insuranceData.insurer_name;
            this.textboxContAutoPolicyNum.text = insuranceData.insurance_policy_number;
            this.textboxContAutoPhone.text = insuranceData.insurer_phone;

        } else if (StringUtil.equalsIgnoreCase("contingent_cargo_insurance_information", insuranceType)) {
            panel.visible = true;
            if (!StringUtil.isEmptyString(insuranceData.expiration_date)) {
                const date = this.formatExpirationDate(insuranceData.expiration_date);
                this.textboxContCargoExpirationDate.text = date;
                this.textboxContCargoCancellationDate.text = date;
            }
            this.textboxContCargoAmount.text = USDollar.format(insuranceData.insurance_amount);
            this.textboxContCargoCompany.text = insuranceData.insurer_name;
            this.textboxContCargoPolicyNum.text = insuranceData.insurance_policy_number;
            this.textboxContCargoPhone.text = insuranceData.insurer_phone;
        }
    }

    formatExpirationDate(expiration_date: string) {
        const date = new Date(expiration_date);
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return [day, month, year].join('/');
    }
}

