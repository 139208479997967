import { Checkbox, CityState, DataSource, HorizontalSpacer, Label, Layout, Panel, Textbox } from "@mcleod/components";
import { MovementCarrierPay } from "../../../powerbroker/src/MovementCarrierPay";
import { lbReasonsPanel } from "../LbReasonsPanel";

export abstract class AutogenLayoutPostToLoadBoards extends Layout {
    checkboxExpedited: Checkbox;
    checkboxTarps: Checkbox;
    horizontalspacer1: HorizontalSpacer;
    labelAnd: Label;
    labelComments: Label;
    labelEquipment: Label;
    labelPostingInformation: Label;
    labelRates: Label;
    labelUserInformation: Label;
    layoutCarrierRates: MovementCarrierPay;
    layoutLbReasonsPanel: lbReasonsPanel;
    panel1: Panel;
    panel2: Panel;
    panel3: Panel;
    panel4: Panel;
    panel5: Panel;
    panelEquipTypeOptions: Panel;
    panelMainLbReasons: Panel;
    panelRatesTitle: Panel;
    postingCityState: CityState;
    sourceCity: DataSource;
    sourceLocation: DataSource;
    sourceOrders: DataSource;
    sourceStop: DataSource;
    textboxPnnCallback: Textbox;
    textboxPnnComment2: Textbox;
    textboxPnnComment: Textbox;
    textboxPnnLoadboardUserId: Textbox;
    textboxPnnRate: Textbox;
    textboxPnnRateType: Textbox;
    textboxPnnTrailerLength: Textbox;
    textboxPnnTrailerWidth: Textbox;
    textboxPuOverrideArriveEarly: Textbox;
    textboxPuOverrideArriveLate: Textbox;
}
