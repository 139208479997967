/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelCommentType.ts                   *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/general/commentType";

export class AutogenModelCommentType<RowType extends ModelRow> extends Endpoint<RowType, RowCommentTypeFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowCommentType extends ModelRow<RowCommentTypeFields> {
    constructor() {
        super(AutogenUserSavedSearchEndpoint);
    }
}

export interface RowCommentTypeFields {
    company_id: string;
    descr: string;
    id: string;
    is_active: string;
}

