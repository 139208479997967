import { Button, ClickEvent, DataSource, DataSourceMode, DataSourceModeChangeEvent, Layout, SlideoutDecorator, Snackbar } from "@mcleod/components";
import { ModelRow } from "@mcleod/core";
import { AgenciesWithPrimary } from "./AgenciesWithPrimary";

/**
 * The purpose of this class is to display the provided layout in a SlideoutDecorator.  The provided layout is expected
 * to be a 'users info' layout from a master file.  For example, this would be the LocationUsers layout from the Location
 * master screen.  The important thing about the provided layout is that it is expected to contain an instance of
 * AgenciesWithPrimary as a nested layout.
 */
export class UsersResponsibilitySlideoutMF {
    private layout: Layout;
    private title: string;
    private idFilter: any;
    private primaryAgencyField: string;
    private agenciesWithPrimary: AgenciesWithPrimary;
    private layoutAgencyLinkDataSource: DataSource;
    private parentMainDataSource: DataSource;
    private parentAgencyLinkDataSource: DataSource;

    public constructor(layoutName: string, title: string, idFilter: any, primaryAgencyField: string, parentMainDataSource: DataSource, parentAgencyLinkDataSource: DataSource) {
        this.title = title;
        this.idFilter = idFilter;
        this.primaryAgencyField = primaryAgencyField;
        this.parentMainDataSource = parentMainDataSource;
        this.parentAgencyLinkDataSource = parentAgencyLinkDataSource;
        this.layout = Layout.getLayout(layoutName);
        this.layout.addLayoutLoadListener(event => {
            this.layout.mainDataSource.addAfterModeChangeListener((event: DataSourceModeChangeEvent) => {
                if (event.newMode === DataSourceMode.ADD && this.primaryAgencyField != null)
                    this.layout.mainDataSource.activeRow.set(this.primaryAgencyField, this.parentMainDataSource.activeRow.get(primaryAgencyField));
                this.agenciesWithPrimary?.setPrimaryTogglesFromParent();
            });
            this.agenciesWithPrimary = this.findAgenciesWithPrimaryNestedLayout(this.layout);
            if (this.agenciesWithPrimary != null) {
                this.layoutAgencyLinkDataSource = this.findAgencyLinkDataSource(this.layout);
                this.agenciesWithPrimary.displaySnackbars = true;
                this.agenciesWithPrimary.setup(this.layoutAgencyLinkDataSource, this.layout.mainDataSource, primaryAgencyField);
            }
        });
    }

    public show() {
        const sod = new SlideoutDecorator({
            layout: this.layout,
            width: window.innerWidth * .75,
            fillVerticalSpace: true,
            title: this.title,
            displayOverlay: true,
            overlayProps: { closeOnClickOff: false, greyedBackground: false },
            addlComponents: new Button({
                id: "ursSaveButton",
                caption: "Save",
                backgroundColor: "primary",
                color: "primary.reverse",
                minWidth: 128,
                borderWidth: 0,
                rowBreak: false,
                onClick: (event: ClickEvent) => {
                    this.processSaveClick(event.target as Button, sod);
                },
            }),
            onClose: () => {
                sod.slideOut();
            },
            doAfterSlideIn: (sod: SlideoutDecorator) => {
                if (this.parentMainDataSource.mode === DataSourceMode.UPDATE)
                    this.layout.mainDataSource.search({ ...this.idFilter });
                else if (this.parentMainDataSource.mode === DataSourceMode.ADD) {
                    if (this.layoutAgencyLinkDataSource != null) {
                        this.layoutAgencyLinkDataSource.data = this.parentAgencyLinkDataSource.getDataCopy();
                        this.layoutAgencyLinkDataSource.displayDataInBoundComponents();
                    }
                }
                this.layout.mainDataSource.mode = this.parentMainDataSource.mode;
                if (this.layoutAgencyLinkDataSource != null)
                    this.layoutAgencyLinkDataSource.mode = this.parentMainDataSource.mode;
            }
        });
    }

    private async processSaveClick(button: Button, sod: SlideoutDecorator) {
        try {
            button.busy = true;
            if (this.parentMainDataSource.isAddingOrUpdating() === true) {
                if (sod.validateSimple() !== true)
                    return;
                if (this.parentMainDataSource.mode === DataSourceMode.UPDATE) {
                    if (this.parentMainDataSource.activeRow.get("rf_entry_code_required", false) && this.layoutAgencyLinkDataSource.data.length === 0) {
                        Snackbar.showWarningSnackbar("Cannot save without responsibility code");
                        return;
                    }
                    await this.layout.mainDataSource.post();
                }
                if (this.agenciesWithPrimary != null) {
                    if (this.primaryAgencyField != null)
                        this.parentMainDataSource.activeRow.set(this.primaryAgencyField, this.agenciesWithPrimary.getPrimaryAgencyId());
                    if (this.parentMainDataSource.mode === DataSourceMode.UPDATE)
                        this.parentMainDataSource.activeRow.resetOriginalData(this.primaryAgencyField);
                    this.cleanPrimaryField(this.layoutAgencyLinkDataSource.data);
                    this.parentAgencyLinkDataSource.data = this.layoutAgencyLinkDataSource.data;
                }
                sod.slideOut();
            }
            else if (this.parentMainDataSource.mode === DataSourceMode.SEARCH) {
                if (this.agenciesWithPrimary != null) {
                    const primaryAgency = this.agenciesWithPrimary.getPrimaryAgencyId();
                    if (primaryAgency != null)
                        this.parentMainDataSource.searchRow.set(this.primaryAgencyField, primaryAgency);
                    this.cleanPrimaryField(this.layoutAgencyLinkDataSource.data);
                    this.parentAgencyLinkDataSource.data = this.layoutAgencyLinkDataSource.data;
                }
                sod.slideOut();
            }
        }
        finally {
            button.busy = false;
        }
    }

    private cleanPrimaryField(rows: ModelRow[]) {
        for (const row of rows) {
            delete row.data.is_primary;
        }
    }

    private findAgenciesWithPrimaryNestedLayout(layout: Layout): AgenciesWithPrimary {
        for (const variable of Object.keys(layout)) {
            if (layout[variable] instanceof AgenciesWithPrimary)
                return layout[variable];
        }
        return null; //can return null if AgenciesWithPrimary nested layout was removed due to licensing
    }

    private findAgencyLinkDataSource(layout: Layout): DataSource {
        for (const dataSourceId of layout.dataSourceIDs) {
            if ((layout[dataSourceId] as DataSource).url === "lme/general/agency-link")
                return layout[dataSourceId];
        }
        throw new Error("The provided layout does not contain the expected Responsbility Codes DataSource.")
    }
}
