import { ChangeEvent, DropdownItem, ForcedCase } from "@mcleod/components";
import { Api, DatePart, DateUtil, StringUtil, getLogger } from "@mcleod/core";
import { AutogenLayoutBrokeragePlanningProfile } from "./autogen/AutogenLayoutBrokeragePlanningProfile";

const log = getLogger("lme.powerbroker.BrokeragePlanningProfile");

export class BrokeragePlanningProfile extends AutogenLayoutBrokeragePlanningProfile {

    private LOCATION_FILTER_TYPE_ITEMS = [{ caption: "Zone", value: "Z" }, { caption: "States", value: "S" },
    { caption: "Zip code", value: "C" }, { caption: "Location", value: "L" }];
    private textBeforeEdit: any;

    override onLoad() {
        this.getLevels();

        // Inbound
        this.textboxOrderIbType.items = this.LOCATION_FILTER_TYPE_ITEMS;
        this.textboxOrderIbType.addChangeListener(event => this.setValueLookup("ib", this.textboxOrderIbType.selectedItem, event.userInitiatedChange));
        // Outbound
        this.textboxOrderObType.items = this.LOCATION_FILTER_TYPE_ITEMS;
        this.textboxOrderObType.addChangeListener(event => this.setValueLookup("ob", this.textboxOrderObType.selectedItem, event.userInitiatedChange));

        this.textboxBrokerageStatus.addBeforeLookupModelSearchListener(event => event.filter.is_active = "Y");
    }

    private setValueLookup(type: "ib" | "ob", item: any, clearValues = true) {
        log.debug("Item", item);
        const tb = "ib" == type ? this.textboxOrderIbZones : this.textboxOrderObZones;
        const v = typeof item === "string" ? item : item.value;
        if (clearValues) {
            tb.clear();
        }
        switch (v) {
            case "Z": // Zone
                tb.lookupModel = "lme/general/zones";
                tb.lookupModelLayout = "lme/general/Zones";
                tb.lookupModelResultField = "id";
                tb.lookupModelDisplayField = "descr";
                tb.lookupModelMinChars = 1;
                tb.forcedCase = ForcedCase.NONE;
                break;
            case "S": // States
                tb.lookupModel = "common/states";
                tb.lookupModelLayout = "common/States";
                tb.lookupModelResultField = "id";
                tb.lookupModelDisplayField = "id";
                tb.lookupModelMinChars = 2;
                tb.forcedCase = ForcedCase.UPPER
                break;
            case "L": // Location
                tb.lookupModel = "lme/dispatch/location-lookup";
                tb.lookupModelLayout = "lme/dispatch/LocationLookup";
                tb.lookupModelResultField = "id";
                tb.lookupModelDisplayField = "name";
                tb.lookupModelMinChars = 3;
                tb.forcedCase = ForcedCase.NONE;
                break;
            case "C": // Zip code
            default:
                tb.lookupModel = null;
                tb.lookupModelLayout = null;
                tb.lookupModelResultField = null;
                tb.lookupModelDisplayField = null;
                tb.forcedCase = ForcedCase.NONE;
                break;
        }
        tb.lookupModelAllowFreeform = false;
        tb.lookupModelAllowSearchAll = true;

        return item;
    }

    private getLevels() {
        Api.search("lme/general/responsibility-configuration").then(result => {
            const data = result?.data[0];
            const levels = data["responsibility_levels"];
            if (levels != null) {
                const levelArray: string[] = levels as string[];
                const items = [];
                for (let i = 0; i < levelArray.length; ++i) {
                    const item: DropdownItem = { caption: levelArray[i], value: "" + (i + 1) };
                    items.push(item);
                }
                this.textboxOrderRespLevel.items = items;
            }
        });
    }

    public addDateRangeListeners() {
        this.showDateRange(this.mainDataSource.activeRow?.get("start_day"), this.mainDataSource.activeRow?.get("order_days"));
        this.textboxStartDay.addChangeListener((event: ChangeEvent) => {
            const orderDays = this.mainDataSource.activeRow?.get("order_days");
            this.showDateRange(event.newValue, orderDays);
        });
        this.textboxOrderDays.addChangeListener((event: ChangeEvent) => {
            const startDay = this.mainDataSource.activeRow?.get("start_day");
            this.showDateRange(startDay, event.newValue);
        });
    }

    public showDateRange(startDay: string, daysOut: string) {
        let orderStartDate: Date;
        let orderEndDate: Date;
        let dateRange: string;
        try {
            if (!StringUtil.isEmptyString(startDay))
                orderStartDate = DateUtil.parseDateWithKeywords(startDay, true, false);
            else
                throw "Invalid start date"

            if (!StringUtil.isEmptyString(daysOut)) {
                const daysOutBaseDate = orderStartDate || new Date();
                orderEndDate = DateUtil.dateAdd(DatePart.DAY, daysOutBaseDate, parseInt(daysOut));
            } else {
                throw "No days out"
            }

            if (orderStartDate != null && orderStartDate.toString() != null
                && orderEndDate != null && orderEndDate.toString() != null) {
                dateRange = `Date range:  ${DateUtil.formatDate(orderStartDate)} - ${DateUtil.formatDate(orderEndDate)}`;
            } else {
                this.labelDateRange.visible = false;
            }
            this.labelDateRange.caption = dateRange;
            this.labelDateRange.visible = true;
        } catch (error) {
            this.labelDateRange.visible = false;
        }
    }
}
