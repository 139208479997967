import { CarrierQualification } from "../CarrierQualification";
import { LaneHistoryTable } from "../LaneHistoryTable";
import { Button, CityState, DataSource, HorizontalSpacer, Label, Layout, Panel, Switch, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutLaneHistory extends Layout {
    buttonClear: Button;
    buttonExpand: Button;
    buttonSearch: Button;
    citystateCarrier2: CityState;
    citystateCarrier: CityState;
    citystateDestSummary2: CityState;
    citystateDestSummary: CityState;
    citystateOriginSummary2: CityState;
    citystateOriginSummary: CityState;
	citystatePrimary: CityState;
	citystateSecondaryCarrier: CityState;
	citystateStopDestCityId: CityState;
	citystateStopOriginCityId: CityState;
	horizontalspacer1: HorizontalSpacer;
	labelAddress1: Label;
	labelAssignmentCount: Label;
	labelAssignments: Label;
	labelCarrier2: Label;
	labelCarrier: Label;
	labelCarrierNamePrimary: Label;
	labelCarrierNameSecondary: Label;
	labelCarrierNameThird: Label;
	labelCustomer: Label;
	labelCustomerNamePrimary: Label;
	labelFilters: Label;
	labelLane: Label;
	labelPageTitle: Label;
	labelPayeeaddress1: Label;
	labelPercentUnassigned: Label;
	labelPercentUnassignedTitle: Label;
	labelPrimaryPayeeAddress1: Label;
	labelSecondaryCarrier: Label;
	labelSecondaryLane: Label;
	labelSecondaryPayeeAddress1: Label;
	labelSummary: Label;
	labelUnassignmentCount: Label;
	labelUnassignments: Label;
	layoutLaneHistoryTable: LaneHistoryTable;
	layoutQualifications: CarrierQualification;
	panelCarrierAssignmentHistory: Panel;
	panelCarrierInfo: Panel;
	panelCarrierPrimary: Panel;
	panelCarrierSecondary: Panel;
	panelCarrierThird: Panel;
	panelCustomerPrimary: Panel;
	panelFilterComponents: Panel;
	panelFilterContent: Panel;
	panelFilterHeader: Panel;
	panelFilters: Panel;
	panelLanePrimary: Panel;
	panelLaneSecondary: Panel;
	panelPercentUnassigned: Panel;
	panelPrimarySummary: Panel;
	panelQualifications: Panel;
	panelSecondarySummary: Panel;
	panelSummary: Panel;
	panelUnassignments: Panel;
    sourceCarrier: DataSource;
    sourceCustomer: DataSource;
    sourceLaneHistory: DataSource;
    switchSearchMode: Switch;
    textboxCustomerId: Textbox;
    textboxDateRange: Textbox;
    textboxMovementStatus: Textbox;
    textboxOrdersId: Textbox;
    textboxOrdersequipmentTypeId: Textbox;
    textboxOverridePayeeId: Textbox;
    textboxRadius: Textbox;
}
