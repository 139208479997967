import { CommonDialogs, CommonTooltips } from "@mcleod/common";
import { Button, ClickEvent, Component, DataDisplayEvent, DataSourceMode, Image, Label, Layout, Snackbar, Table, TableRow, TableRowDisplayEvent, TableRowExpansionEvent, TableSelectionEvent, Textbox } from "@mcleod/components";
import { SlideoutDecorator } from "@mcleod/components/src/page/decorators/SlideoutDecorator";
import { Alignment, Api, CurrencyUtil, DisplayType, HorizontalAlignment, ModelRow, StringUtil, getLogger } from "@mcleod/core";
import { CustomerQuickInfo } from "../../ar/src/CustomerQuickInfo";
import { Orders } from "../../dispatch/src/Orders";
import { DataFusionLocationCrossReference } from "./DataFusionLocationCrossReference";
import { EDIOrderProfileQuickInfo } from "./EdiOrderProfileQuickInfo";
import { EdiSuggestedPricing } from "./EdiSuggestedPricing";
import { LoadTenderExpress } from "./LoadTenderExpress";
import { LoadTenderExpressExpanded } from "./LoadTenderExpressExpanded";
import { AutogenLayoutTenderGridLayout } from "./autogen/AutogenLayoutTenderGridLayout";
import { ModelEdiOrder } from "./models/ModelEdiOrder";

const log = getLogger("lme/datafusion/TenderGridLayout");

export class TenderGridLayout extends AutogenLayoutTenderGridLayout {
    selectedRow: TableRow;
    selectedRows: TableRow[];
    private _loadTenderExpress: LoadTenderExpress;
    private _shipperLocReq: string = "N";
    public recordCount: number;
    public isBusy: boolean;


    override onLoad() {
        this.loadControlSettings();
        this.tableLtx.doOnResolveRowEdit = async (updatedRow: ModelRow) => {
            this._loadTenderExpress.buttonRefresh.busy = true;
            const selectedRowId: string = this.tableLtx.selectedRow.data.get("id");
            await this._loadTenderExpress.refreshScreen();
            const foundIndex: number = this.tableLtx.rows.findIndex(record => 
                record.data.get("tender_id") === selectedRowId
            );
            this.tableLtx.selectedIndex = foundIndex < 0 ? 0 : foundIndex;
            this._loadTenderExpress.buttonRefresh.busy = false;
            return null;
        };
    }

    onRowDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.getTableRow();
        const modelRow = tableRow.data;

        // Drill down option requires modelRow to have a field called "id"
        const id = modelRow?.get("tender_id");
        modelRow?.set("id", id);

        const shipmentTenders: Label = tableRow.findComponentById("labelDupTenderID") as Label;
        const lockedLabel = tableRow.findComponentById("labelLocked") as Label;
        const txtTotalCharge = tableRow.findComponentById("txtTotalCharge") as Textbox;

        if ("Y" == tableRow.data.get("record_in_use")) {
            lockedLabel.visible = true;
            lockedLabel.tooltipPosition = Alignment.RIGHT;
            lockedLabel.caption = '';
        }
        else {
            lockedLabel.visible = false;
        }

        if (tableRow.data.get("shipment_id_count") <= 1) {
            shipmentTenders.visible = false;
        } else {
            shipmentTenders.visible = true;
        }

        this.setTooltipCallbacks(txtTotalCharge, modelRow);

        const labelOrderId = tableRow.findComponentById("labelOrderId") as Label;
        const orderId = modelRow.data["order_id"];
        if (!StringUtil.isEmptyString(orderId)) {
            labelOrderId.caption = "[" + orderId + "]";
            labelOrderId.addUnderlineWhenMouseOver();
        } else {
            labelOrderId.visible = false;
        }

        const buttonCommitment = tableRow.findComponentById("buttonCommitment") as Button;
        const commitmentId = modelRow.get("commitment_id");
        const origDestRateId = modelRow.get("orig_dest_rate_id");
        if (!commitmentId || !origDestRateId) buttonCommitment.visible = false;
    }

    public set LoadTenderExpress(ltx: LoadTenderExpress) {
        this._loadTenderExpress = ltx;
    }

    onClickTenderCount(event: ClickEvent) {
        if (event.target instanceof Label) {
            const shipment_id = event.target.boundRow.get("shipment_id");
            const allLayout = this._loadTenderExpress.layoutAll;
            const allTab = this._loadTenderExpress.tabAll;

            allTab.select();
            allLayout.tableLtx.setFilter(`shipment_id=${shipment_id}`);
        }
    }

    displayPartnerName(event: DataDisplayEvent) {
        const partnerNameLabel = <Label>event.target;
        partnerNameLabel.tooltipCallback = (base, originatingEvent) => {
            const profileID = (<Label>originatingEvent.target).boundRow.get("edi_profile_id");
            const layout = Layout.getLayout("lme/datafusion/EDIOrderProfileQuickInfo") as EDIOrderProfileQuickInfo;
            layout.addLayoutLoadListener(() => {
                if (layout.sourceEdiorderProfile != null) {
                    layout.sourceEdiorderProfile?.search({ id: profileID }).then(r => {
                        partnerNameLabel.showTooltip(layout,
                            { position: partnerNameLabel.tooltipPosition == null ? Alignment.BOTTOM : partnerNameLabel.tooltipPosition, anchorToMouse: true, originatingEvent: originatingEvent },
                            { themeKey: "quickInfo", backgroundColor: "background5", color: null });
                    });
                }
            });
            return layout;
        }
    }

    displayCustomer(event: DataDisplayEvent) {
        const partnerNameLabel = <Label>event.target;
        partnerNameLabel.tooltipCallback = (base, originatingEvent) => {
            const customerID = (<Label>originatingEvent.target).boundRow.get("customer_id");
            const layout = Layout.getLayout("lme/ar/CustomerQuickInfo") as CustomerQuickInfo;
            layout.addLayoutLoadListener(() => {
                if (layout.sourceCustomer != null) {
                    layout.sourceCustomer?.search({ id: customerID }).then(r => {
                        partnerNameLabel.showTooltip(layout,
                            { position: partnerNameLabel.tooltipPosition == null ? Alignment.BOTTOM : partnerNameLabel.tooltipPosition, anchorToMouse: true, originatingEvent: originatingEvent },
                            { themeKey: "quickInfo", backgroundColor: "background5", color: null });
                    });
                }
            });
            return layout;
        }
    }

    displayMissingXref(event: DataDisplayEvent) {
        const labelXref = <Label>event.target;
        const currentRow = <ModelRow>event.rowData;

        labelXref.caption = "";

        if ("Y" == currentRow.get("missing_xref_label")) {
            labelXref.imageName = "cross_ref_missing";//"cross_ref_missing";
            labelXref.imageColor = "error";
            labelXref.tooltip = "One or more stops is missing a location cross reference.";
            labelXref.tooltipPosition = Alignment.RIGHT;
        }
    }
    displayStatusIcon(event: DataDisplayEvent) {
        const statusLabel = <Image>event.target;
        const currentRow = <ModelRow>event.rowData;
        const REPLY_NOT_SENT = "RU";
        const REPLY_SENT = "RS";
        const REPLY_ERROR = "RE";
        const REPLY_NOT_REQUIRED = "RN";
        const TENDER_SUCCESSFUL = "TS";
        const TENDER_ERROR = "TE";
        const TENDER_NEEDS_ATTENTION = "TA";
        const TENDER_NOT_PROCESSED = "TN";

        statusLabel.tooltipPosition = Alignment.RIGHT;

        if ((REPLY_NOT_REQUIRED + TENDER_SUCCESSFUL) == currentRow.get("status_label") || (REPLY_NOT_REQUIRED + TENDER_NOT_PROCESSED) == currentRow.get("status_label") ||
            (REPLY_NOT_SENT + TENDER_SUCCESSFUL) == currentRow.get("status_label") || (REPLY_NOT_SENT + TENDER_NOT_PROCESSED) == currentRow.get("status_label")) { //reply successful (or not required), transaction successful
            statusLabel.visible = false;
        }
        else if ((REPLY_ERROR + TENDER_NOT_PROCESSED) == currentRow.get("status_label")) {
            statusLabel.name = "dfReplyErrorNeedsAtt";
            statusLabel.marginBottom = 1;
            statusLabel.tooltip = "Reply has errors, tender needs attention";
        }
        else if ((REPLY_ERROR + TENDER_SUCCESSFUL) == currentRow.get("status_label")) {
            statusLabel.name = "dfReplyError";
            statusLabel.marginBottom = 1;
            statusLabel.tooltip = "Reply has errors";
        }
        else if ((REPLY_SENT + TENDER_ERROR) == currentRow.get("status_label")) { //reply successful, transaction error
            statusLabel.name = "dfReplyGoodTransError";
            statusLabel.tooltip = "Tender has errors, tender replied to successfully";
        }
        else if ((REPLY_SENT + TENDER_NEEDS_ATTENTION) == currentRow.get("status_label") || (REPLY_SENT + TENDER_NOT_PROCESSED) == currentRow.get("status_label")) { //reply successful, transaction needs attention (failed auto-processing)
            statusLabel.name = "dfReplyGoodNeedsAtt";
            statusLabel.tooltip = "Reply successful, order needs attention";
        }
        else if ((REPLY_ERROR + TENDER_ERROR) == currentRow.get("status_label")) { //reply error, transaction error
            //statusLabel.name = "dfReplyErrorTransError";
            statusLabel.name = "dfReplyErrorTransError";
            statusLabel.tooltip = "Tender and reply have errors";
        }
        else if ((REPLY_ERROR + TENDER_NEEDS_ATTENTION) == currentRow.get("status_label")) { //reply error, transaction needs attention (failed auto-processing)
            statusLabel.name = "dfReplyErrorNeedsAtt";

            if (currentRow.get("is_original") == "Y") {
                statusLabel.tooltip = "Reply has errors, order has not been created";
            }
            else if (currentRow.get("is_change") == "Y") {
                statusLabel.tooltip = "Reply has errors, order has not been compared";
            }
            else if (currentRow.get("is_cancel") == "Y") {
                statusLabel.tooltip = "Reply has errors, order has not been voided";
            }
            else {
                statusLabel.tooltip = "Unknown transaction type failed, reply has errors";
            }
        }
        else if ((REPLY_NOT_REQUIRED + TENDER_ERROR) == currentRow.get("status_label") || (REPLY_NOT_SENT + TENDER_ERROR) == currentRow.get("status_label")) { //reply not required, transaction error
            statusLabel.name = "dfNoReplyTransError";
            statusLabel.tooltip = "Tender has errors";
        }
        else if ((REPLY_NOT_REQUIRED + TENDER_NEEDS_ATTENTION) == currentRow.get("status_label") || (REPLY_NOT_SENT + TENDER_NEEDS_ATTENTION) == currentRow.get("status_label")) { //reply not required, transaction needs attention (failed auto-processing)
            statusLabel.name = "dfNoReplyNeedsAtt";  //this needs a new image when available

            if (currentRow.get("is_original") == "Y") {
                statusLabel.tooltip = "Order has not been created";
            }
            else if (currentRow.get("is_change") == "Y") {
                statusLabel.tooltip = "Order has not been compared";
            }
            else if (currentRow.get("is_cancel") == "Y") {
                statusLabel.tooltip = "Order has not been voided";
            }
            else {
                statusLabel.tooltip = "Transaction failed";
            }
        }
        else {
            statusLabel.visible = false;
        }
    }

    displayWarningClock(event: DataDisplayEvent) {
        const clockLabel = <Label>event.target;
        const currentRow = <ModelRow>event.rowData;
        const respondBy = currentRow.get("must_respond_by");
        const timeRemaining = currentRow.get("must_respond_time_left");

        if (timeRemaining > 30 && timeRemaining <= 60) {
            clockLabel.imageName = "timeWarning60";
            clockLabel.imageColor = "default.lighter";
            clockLabel.tooltip = "Time remaining: " + timeRemaining + " minutes";
        }
        else if (timeRemaining > 15 && timeRemaining <= 30) {
            clockLabel.imageName = "timeWarning30";
            clockLabel.imageColor = "caution";
            clockLabel.tooltip = "Time remaining: " + timeRemaining + " minutes";
        }
        else if (timeRemaining > 0 && timeRemaining <= 15) {
            clockLabel.imageName = "timeWarning15";
            clockLabel.imageColor = "warning";
            clockLabel.tooltip = "Time remaining: " + timeRemaining + " minutes";
        }
        else if (timeRemaining <= 0) {
            clockLabel.imageName = "timeExpired";
            clockLabel.imageColor = "error";
            clockLabel.tooltip = "Time expired";
        }
        clockLabel.visible = (respondBy != null && respondBy != "");
    }

    displayRespondByTimezone(event: DataDisplayEvent) {
        const timezoneLabel = <Label>event.target;
        const currentRow = <ModelRow>event.rowData;
        const respondBy = currentRow.get("must_respond_by");
        timezoneLabel.visible = (respondBy != null && respondBy != "");
    }

    tableLtxOnRowExpand(event: TableRowExpansionEvent) {
        if ((event.target as TableRow).data != null) {
            const row = (event.target as TableRow).data;
            const tenderID = row.get("tender_id");
            const purpose = row.get("purpose_desc");
            const orderID = row.get("order_id");
            //const expandComp = (event.expandComponentParent as Panel).components[0];
            const expandComp = event.expandComponentParent as LoadTenderExpressExpanded;
            expandComp.tenderID = tenderID;
            expandComp.purposeType = purpose;
            expandComp.orderID = orderID;
            expandComp.loadTenderExpress = this._loadTenderExpress;
        }
    }

    tableLtxOnSelect(event: TableSelectionEvent) {
        this.selectedRows = (event.target as Table).selectedRows;
        this._loadTenderExpress.selectedRows = this.selectedRows;
        this.selectedRow = (event.target as Table).selectedRow;
        this._loadTenderExpress.selectedRow = this.selectedRow;

        if (this.selectedRow != null) {
            if ((this.selectedRow.data.get("initial_purpose") === "O" && this.selectedRow.data.get("requires_reply_original") === "Y") && this.selectedRow.data.get("reply_created") !== "Y") {
                this._loadTenderExpress.enableReplyOptions();
            }
            else if ((this.selectedRow.data.get("initial_purpose") === "U" && this.selectedRow.data.get("requires_reply_change") === "Y") && this.selectedRow.data.get("reply_created") !== "Y") {
                this._loadTenderExpress.enableReplyOptions();
            }
            else if ((this.selectedRow.data.get("initial_purpose") === "C" && this.selectedRow.data.get("requires_reply_cancel") === "Y") && this.selectedRow.data.get("reply_created") !== "Y") {
                this._loadTenderExpress.enableReplyOptions();
            }
            else {
                this._loadTenderExpress.enableProcessOption();
            }
        }
    }

    createXref(event: ClickEvent) {
        const xref = event.target["row"].data.get("missing_xref_label");
        if ("N" == xref) {
            return;
        }

        log.debug("In createXref");
        const tenderID = event.target["row"].data.get("tender_id");
        const shipmentId = event.target["row"].data.get("shipment_id");
        const layout = Layout.getLayout("lme/datafusion/DataFusionLocationCrossReference", { paddingBottom: 50 }) as DataFusionLocationCrossReference;
        layout.tenderId = tenderID;
        layout.ediOrderRow = event.target["row"];
        layout.shipperLocReq = this._shipperLocReq;
        layout.fillHeight = true;
        //need to look at main datasource, get stops and pass to layout
        new SlideoutDecorator({
            layout: layout,
            title: "Location Cross Reference - Shipment ID " + shipmentId,
            fillVerticalSpace: true,
            width: window.innerWidth * 0.35,
            addlComponents: new Button({
                caption: "Save",
                align: HorizontalAlignment.RIGHT,
                color: "McLeodWhite",
                id: "buttonSave",
                onClick: (event) => layout.saveOnClick(event),
                rowBreak: false
            }),
            doAfterSlideOut: (decorator: SlideoutDecorator) => {
                if (layout.saveButtonClicked == true)
                    this._loadTenderExpress.mainDataSource.search({ id: this._loadTenderExpress.ltxProfileId });
            }
        });
    }

    loadControlSettings() {
        const input = { _field_list: { extraFields: "lme/general/dispatch-control-enforce-shipper-id" } };
        Api.search("lme/general/dispatch-control", input).then(response => {
            const data = response?.data?.[0];
            this._shipperLocReq = data?.enforce_shipper_id;
        });
    }

    async unlockTender(event: ClickEvent) {
        const row = event.target as Label;
        const ediOrderRow = await new ModelEdiOrder().searchSingle({ id: row.boundRow.get("tender_id") });
        CommonDialogs.showYesNo("Are you sure you want to unlock this tender?", "Confirm Unlock").then(async clickedYes => {
            if (clickedYes) {
                ediOrderRow.set("record_in_use", "N");
                await ediOrderRow.post();
                this._loadTenderExpress.refreshScreen();
            }
        })
    }

    setTooltipCallbacks(comp: Component, modelRow: ModelRow) {
        if (comp.id == null) return;
        switch (comp.id) {
            case "txtTotalCharge":
                return CommonTooltips.setPanelTooltipCallback(comp, () => [
                    { caption: "Type", displayType: DisplayType.STRING, text: modelRow.get("rate_type"), rowBreak: false, fillRow: true },
                    { caption: "Rate", displayType: DisplayType.FLOAT, text: modelRow.get("rate"), marginLeft: 75 },
                    { caption: "Units", displayType: DisplayType.STRING, text: modelRow.get("rate_units"), rowBreak: false, fillRow: true },
                    { caption: "Freight Charge", displayType: DisplayType.FLOAT, text: CurrencyUtil.formatCurrency(modelRow.get("freight_charge")), marginLeft: 20 },
                    { caption: "Rate ID", displayType: DisplayType.STRING, text: modelRow.get("rate_id"), rowBreak: false, fillRow: true },
                    { caption: "Other Charges", displayType: DisplayType.FLOAT, text: CurrencyUtil.formatCurrency(modelRow.get("other_charge_total")), marginLeft: 10 }
                ]);
        }
    }

    labelOrderIdOnClick(event: ClickEvent) {
        const orderId = (event.target as Label).caption.replace("[", "").replace("]", "");
        Orders.navigateTo(orderId);

    }

    buttonSuggestedPricingOnClick(event: ClickEvent) {
        if (this.selectedRows == null || this.selectedRows.length == 0 || this.tableLtx.selectedRow == null) {
            Snackbar.showWarningSnackbar("Please select a tender to view its suggested pricing");
            return;
        }
        else if (this.selectedRows.length > 1) {
            Snackbar.showWarningSnackbar("You must select exactly one tender to view its suggested pricing")
            return;
        }
        const button = event.target as Button;
        button.busy = true;
        const tableRow: TableRow = this.tableLtx?.selectedRow;
        const row: ModelRow = tableRow.data;
        this.openSuggestedPricingSlideup(event, button, row, "tabLaneHistory");
    }

    buttonCommitmentOnClick(event: ClickEvent) {
        const button = event.target as Button;
        button.busy = true;
        const tableRow = TableRow.getContainingTableRow(button);
        tableRow.table.selectedRow = tableRow;
        const row: ModelRow = tableRow.data;
        this.openSuggestedPricingSlideup(event, button, row, "tabCommitmentAutoRating");
    }

    openSuggestedPricingSlideup(event: ClickEvent, button: Button, row: ModelRow, tabId: string) {
        const tenderID = row.get("tender_id");
        const ediSuggestedPricingLayout: EdiSuggestedPricing = Layout.getLayout("lme/datafusion/EdiSuggestedPricing") as EdiSuggestedPricing;

        const excludeButton = ediSuggestedPricingLayout.excludeButton;

        const setButtonsActive = (declineButtonEnabled: boolean) => {
            if (acceptButton != null) acceptButton.enabled = true;
            if (acceptButton != null) acceptButton.visible = true;
            if (declineButton != null) declineButton.enabled = declineButtonEnabled;
            if (declineButton != null) declineButton.visible = true;
            if (excludeButton != null) excludeButton.enabled = true;
            if (excludeButton != null) excludeButton.visible = true;
        }
        ediSuggestedPricingLayout.excludeButtonCallback = () => {
            ediSuggestedPricingLayout.slideOut();
            setTimeout(() => this._loadTenderExpress.excludeTender(event), 250);
        };

        const declineButton = ediSuggestedPricingLayout.declineButton;
        ediSuggestedPricingLayout.declineButtonCallback = () => {
            ediSuggestedPricingLayout.slideOut();
            setTimeout(() => this._loadTenderExpress.declineTender(event), 250);
        };

        const acceptButton = ediSuggestedPricingLayout.acceptButton;
        ediSuggestedPricingLayout.acceptButtonCallback = () => {
            ediSuggestedPricingLayout.slideOut();
            setTimeout(() => this._loadTenderExpress.acceptTenders(), 250);
        };
        if (row != null) {
            if (((row.get("initial_purpose") === "O" && row.get("requires_reply_original") === "Y") ||
                (row.get("initial_purpose") === "U" && row.get("requires_reply_change") === "Y") ||
                (row.get("initial_purpose") === "C" && row.get("requires_reply_cancel") === "Y"))
                && row.get("reply_created") !== "Y") {
                setButtonsActive(true);
            }
            else {
                setButtonsActive(false);
            }
        }
        ediSuggestedPricingLayout.initialSelectedTab = tabId;

        const slideout = new SlideoutDecorator({
            title: `Suggested Pricing - Tender ${tenderID}`,
            layout: ediSuggestedPricingLayout,
            layoutLoadListeners: async (event) => {
                await ediSuggestedPricingLayout.mainDataSource.search({ id: tenderID });
                ediSuggestedPricingLayout.mainDataSource.mode = DataSourceMode.NONE;
            },
            fillVerticalSpace: true,
            fillHorizontalSpace: true,
            transitionOptions: { direction: Alignment.CENTER },
            overlayProps: { closeOnClickOff: false, greyedBackground: true },
            addlComponents: [excludeButton, declineButton, acceptButton],
            onClose: (cancelled: boolean) => ediSuggestedPricingLayout.slideOut(),
            doAfterSlideIn: () => button.busy = false
        });
    }
}
