import { Button, DataHeader, DataSource, Layout, Panel, SaveButton, Step, Stepper, Textbox } from "@mcleod/components";
import { CarrierSelection } from "../../CarrierSelection";
import { QuoteEntryLTL } from "../QuoteEntryLTL";

export abstract class AutogenLayoutQuoteLTL extends Layout {
	buttonEmail: Button;
	buttonQuoteHistory: Button;
	buttonSave: SaveButton;
	buttonUsers: Button;
	dataheaderQuote: DataHeader;
	layoutCarrierSelection: CarrierSelection;
	layoutQuoteEntry: QuoteEntryLTL;
	panelDataHeaderAddlLeft: Panel;
	panelDataHeaderAddlRight: Panel;
	panelQuoteStatus: Panel;
	sourceQuoteBrltlBillingFreightGroup: DataSource;
	sourceQuoteOrder: DataSource;
	sourceResponsibleHist: DataSource;
	stepCarrierSelection: Step;
	stepQuoteEntry: Step;
	stepperQuote: Stepper;
	textboxQuoteStatus: Textbox;
}
