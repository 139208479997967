import { CommonDialogs } from "@mcleod/common";
import { Layout } from "@mcleod/components";
import { Api, getLogger } from "@mcleod/core";

const log = getLogger("component-demo/dialogs/DemoErrorDialog");

export class DemoErrorDialog extends Layout {
    getDemoPath() {
        return ["Dialogs", "Errors"];
    }

    buttonServerErrorClicked() {
        Api.search("this/will/fail").then(response => log.info(response)).catch(err => CommonDialogs.showError(err));
    }
}
