import { Api} from "@mcleod/core";
import { Orders } from "@mcleod/dispatch/src/Orders";

export class MakeOrderUtility
{
    static addHandlingRequirements(orderId:string, orderLayout: Orders ){
        Api.search("lme/dispatch/brltl/brltl-freight-group",{
            "lme_order_id": orderId
        }).then( result => {
            const fgp_uid = result.data[0]["fgp_uid"];
            orderLayout.layoutHandlingReqs.sourceBrltlOrderHdrXFgp?.data.forEach( hdr => {
                if(hdr._appending === false){//only do post if the records haven't been edited in the panel
                    hdr.set("fgp_uid", fgp_uid);
                    hdr.set("order_id",orderId);
                    hdr._appending = true;
                    hdr.post();
                }
            });
        });
    }
}
