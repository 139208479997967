import { Button, ClickEvent, CrudDecorator, DataSource, DataSourceMode, DataSourceModeChangeEvent, Label, Layout, Panel, SlideoutDecorator, TableRow, TableRowBeforeSaveEvent, TableRowDisplayEvent, TableRowExpansionEvent } from "@mcleod/components";
import { DateUtil, ModelRow, StringUtil, getLogger } from "@mcleod/core";
import { QuickQuote } from "@mcleod/powerbroker/src/QuickQuote";
import { CallRecordTable } from "./CallRecordTable";
import { makeTooltipCallbackFunction as makeCustomerTooltip } from "./CustomerMakeQuickInfo";
import { makeTooltipCallbackFunction as makeLocationTooltip } from "./LocationMakeQuickInfo";
import { SalesCallList } from "./SalesCallList";
import { SalesCallListTableType, SecondaryFilter } from "./SalesCallListTableType";
import { AutogenLayoutSalesCallListTable } from "./autogen/AutogenLayoutSalesCallListTable";

const log = getLogger("lme.dispatch.SalesCallListTable");

export class SalesCallListTable extends AutogenLayoutSalesCallListTable {
    private _type: SalesCallListTableType;
    private _salesCallList: SalesCallList;
    private selectedSecondaryFilter: string;
    private sourceSalesCallsCopy: DataSource = new DataSource();

    public get type(): SalesCallListTableType {
        return this._type;
    }

    configureTableForType(type: SalesCallListTableType) {
        this._type = type;
        this.tableSalesCalls.exportName = "Sales Call Listing (" + type.caption + ") - {timestamp}";
        this.tableSalesCalls.id += type.tableIdSuffix;
        this.setupSecondaryFilters();
        this.tableSalesCalls.dataSource.orderBy = this._type.defaultSort;
    }

    get salesCallList(): SalesCallList {
        return this._salesCallList;
    }

    set salesCallList(value: SalesCallList) {
        this._salesCallList = value;
    }

    /** This is an event handler for the onRowDisplay event of tableSalesCalls.  */
    tableSalesCallsOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.target as TableRow;
        this.tableSalesCallCheckDates(tableRow);
        const labelName = tableRow.findComponentById("labelName") as Label
        const labelEmail = tableRow.findComponentById("labelContactEmail");
        if (labelEmail instanceof Label && !StringUtil.isEmptyString(tableRow.data.get("contact.email"))) {
            labelEmail.tooltipCallback = () => { return labelEmail.showTooltip(tableRow.data.get("contact.email"), {}, { themeKey: "quickInfo", color: null }) };
        }
        const labelCode = tableRow.findComponentById("labelCode") as Label
        const buttonAllCalls = tableRow.findComponentById("buttonAllCalls") as Label
        const isFromCustomerTable = tableRow.data.get("from_customer_table") === "Y";
        labelCode.quickInfoLayout = `lme/${isFromCustomerTable ? "ar/Customer" : "dispatch/Location"}QuickInfo`;
        labelName.tooltipCallback = isFromCustomerTable ? makeCustomerTooltip(tableRow.data.get("id"), labelName) : makeLocationTooltip(tableRow.data.get("id"), labelName);
        buttonAllCalls.tooltip = `Show all sales calls for this ${isFromCustomerTable ? "customer" : "location"}`;
    }

    tableSalesCallCheckDates(tableRow: TableRow) {
        const labelNextCallDate: Label = tableRow.findComponentById("labelNextCallDate") as Label;
        const labelPastDue: Label = tableRow.findComponentById("labelPastDue") as Label;
        labelPastDue.visible = false;
        const dateNextCall: Date = DateUtil.justDate(DateUtil.parseDate(tableRow.data?.get("next_call_date"))) as Date;
        const dateToday: Date = DateUtil.justDate(new Date()) as Date;
        if (!!dateNextCall && dateNextCall < dateToday) {
            labelNextCallDate.color = "error.light";
            labelPastDue.visible = true;
        }
    }

    public getSecondaryFilter(): SecondaryFilter {
        return this.type.secondaryFilters?.find(f => f.id === this.selectedSecondaryFilter);
    }

    private setupSecondaryFilters() {
        log.debug("Setting up secondary filters", this._type.secondaryFilters);
        this.selectedSecondaryFilter = null;
        this._type.secondaryFilters?.forEach(sFilter => this.panelSecondaryFilters.add(this.getSecondaryFilterButton(sFilter)));
    }

    private getSecondaryFilterButton(sFilter: SecondaryFilter): Button {
        return new Button({
            id: `btn${sFilter.id}`,
            caption: sFilter.caption,
            rowBreak: false,
            color: "McLeodTertiary",
            marginRight: 12,
            paddingTop: 6,
            paddingBottom: 6,
            onClick: (event => this.toggleSecondaryFilter(event, sFilter))
        });
    }

    private toggleSecondaryFilter(event: ClickEvent, sFilter: SecondaryFilter) {
        log.debug(`Toggling secondary filter: ${sFilter.caption}`);

        if (this.selectedSecondaryFilter == null || this.selectedSecondaryFilter !== sFilter.id) {
            if (this.selectedSecondaryFilter != null) {
                const btn = this.panelSecondaryFilters.findComponentById(`btn${this.getSecondaryFilter().id}`) as Button;
                this.updateFilterBtnStyle(btn, false);
            }
            this.selectedSecondaryFilter = sFilter.id;
            this.updateFilterBtnStyle((event.target as Button), true);
        } else {
            this.selectedSecondaryFilter = null;
            this.updateFilterBtnStyle((event.target as Button), false);
        }
        this._salesCallList.populateFilteredTables(this._type);
    }

    private updateFilterBtnStyle(btn: Button, selected: boolean) {
        btn.color = selected ? "success.reverse" : "McLeodTertiary";
        btn.backgroundColor = selected ? "success" : null;
    }

    public get filter() {
        if (this.selectedSecondaryFilter == null)
            return this.type.filter;
        else
            return (row: ModelRow) => this.getSecondaryFilter()?.filter(row, this.type.filter);
    }

    private _closeOtherExpansions(thisIndex: number) {
        for (const tableRow of this.tableSalesCalls.rows) {
            if (tableRow.index === thisIndex) continue;
            tableRow.setExpanded(false);
        }
    }

    /** This is an event handler for the onRowExpand event of table1.  */
    onRowExpand(event: TableRowExpansionEvent) {
        if (event.isExpanding) {
            const tableRow: TableRow = event.target as TableRow;
            const expandPanel = event.expandComponentParent as Panel;
            const layoutCallRecord: CallRecordTable = expandPanel.findComponentById("layoutCallRecord") as CallRecordTable;
            const id = tableRow.data.get("id");
            const parentRowType = tableRow.data.get("from_customer_table") === "Y" ? "C" : "L";
            layoutCallRecord.addLayoutLoadListener(() => {
                layoutCallRecord.tableCallRecords.persistChangesImmediately = true;
                layoutCallRecord.tableCallRecords.addRowBeforeSaveListener((event: TableRowBeforeSaveEvent) => {
                    tableRow.data.set("next_call_date", event.getTableRow().data.get("next_call_date"));
                    tableRow.displayComponentValues();
                })

                layoutCallRecord.mainDataSource.mode = DataSourceMode.UPDATE;
                layoutCallRecord.mainDataSource.maxResults = 3;
                layoutCallRecord.mainDataSource.orderBy = [{ field: "call_date", sort: "desc" }];
                layoutCallRecord.mainDataSource.search({ "parent_row_type": parentRowType, "parent_row_id": id });

                if (tableRow.data.get("from_customer_table") === "Y") {
                    layoutCallRecord.customerId = tableRow.data.get("id");
                }
                else {
                    layoutCallRecord.locationId = tableRow.data.get("id");
                }
                layoutCallRecord.daysBetweenCalls = tableRow.data.get("call_interval", 0);
            });
            this._closeOtherExpansions(tableRow.index);
        }
    }
    /** This is an event handler for the onClick event of buttonToday.  */
    buttonSecondaryFilterOnClick(event: ClickEvent) {
        const button = event.target as Button
        if (this.selectedSecondaryFilter == null || this.selectedSecondaryFilter != button.id) {
            if (this.selectedSecondaryFilter != null) {
                const btn = this.panelSecondaryFilters.findComponentById(this.selectedSecondaryFilter) as Button;
                this.updateFilterBtnStyle(btn, false);
            }
            this.selectedSecondaryFilter = button.id;
            this.updateFilterBtnStyle(button, true);
        }
        else {
            this.selectedSecondaryFilter = null;
            this.updateFilterBtnStyle(button, false);
        }
        const selected = button.backgroundColor === "success";
        if (selected) {
            const modelRows = this.sourceSalesCalls.data.filter(button["filter"]);
            this.sourceSalesCallsCopy.setRowsAndMode(DataSourceMode.NONE, modelRows, null);
            this.tableSalesCalls.dataSource = this.sourceSalesCallsCopy;
            this.tableSalesCalls.dataSource.displayDataInBoundComponents();
        }
        else {
            this.tableSalesCalls.dataSource = this.mainDataSource;
            this.tableSalesCalls.dataSource.displayDataInBoundComponents();
        }
    }

    public async updateFilterCounts() {
        const sFilters = this._type.secondaryFilters;
        if (sFilters == null) return;
        const countMap = new Map();

        sFilters.forEach(sFilter => {
            countMap.set(sFilter.id, 0);
        });
        // I don't want to loop over the datasource data multiple times in case there are tons of rows,
        // so I loop over the datasource data once and the small secondary filters array for each row.
        this.salesCallList.mainDataSource.data.forEach(row => {
            sFilters.forEach(sFilter => {
                if (sFilter.filter(row, this._type.filter))
                    countMap.set(sFilter.id, countMap.get(sFilter.id) + 1)
            });
        });
        sFilters.forEach(sFilter => {
            const btn = this.panelSecondaryFilters.findComponentById(`btn${sFilter.id}`) as Button;
            btn.caption = `${countMap.get(sFilter.id)} ${sFilter.caption}`;
        });
    }

    /** This is an event handler for the onClick event of buttonAllCalls.  */
    buttonAllCallsOnClick(event: ClickEvent) {
        const tableRow = TableRow.getContainingTableRow(event.target as Button);
        const id = tableRow.data.get("id");
        const parentRowType = tableRow.data.get("from_customer_table") === "Y" ? "C" : "L";
        if (!StringUtil.isEmptyString(id)) {
            const layout = Layout.getLayout("lme/dispatch/CallRecordTable") as CallRecordTable;
            new SlideoutDecorator({
                layout: layout,
                width: window.innerWidth * .75,
                fillVerticalSpace: true,
                title: `Sales Calls - ${tableRow.data.get("name")}`,
                doAfterSlideIn: (decorator: SlideoutDecorator) => layout.mainDataSource.search({ "parent_row_type": parentRowType, "parent_row_id": id })
            })
        }
    }

    buttonQuoteOnClick(event: ClickEvent) {
        const button = event.target as Button;
        const rowData = TableRow.getContainingTableRow(button).data;
        const quoteData = {
            "customer_phone": rowData.get("contact_phone"),
            "customer_email": rowData.get("contact_email"),
            "customer_contact_name": rowData.get("contact_name"),
            "customer_name": rowData.get("name"),
        }
        if (rowData.get("from_customer_table") === "Y") {
            quoteData["customer_id"] = rowData.get("id");
        }
        else {
            quoteData["location_id"] = rowData.get("id");
        }
        const layout = Layout.getLayout("lme/powerbroker/QuickQuote") as QuickQuote;
        layout.addLayoutLoadListener(() => {
            layout.mainDataSource.addAfterModeChangeListener((event: DataSourceModeChangeEvent) => {
                if (event.newMode === DataSourceMode.ADD) {
                    layout.activeRow.setLookupModelData("customer_name", { name: quoteData["customer_name"] });
                    layout.activeRow.setLookupModelData("customer_contact_name", { name: quoteData["customer_contact_name"] });
                    layout.activeRow.setValues(quoteData);
                }
            })
            new CrudDecorator({
                mode: DataSourceMode.ADD,
                layout: layout,
                headerProps: { showClose: true }
            }).slideIn({ speed: 200 });
        });
    }
}
