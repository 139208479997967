import { Event } from "../../events/Event";
import { Button } from "../button/Button";
import { Panel } from "../panel/Panel";
import { Table } from "./Table";
import { TableRow } from "./TableRow";

export class EllipsisPopup extends Panel {
    constructor(row: TableRow, table: Table) {
        super({ width: 75 });

        if (row.canBeEdited) {
            const editButton = new Button({ caption: "Edit", focusable: false, color: "black", margin: 0, borderWidth: 0, fillRow: true });
            editButton.addClickListener((event: Event) => {
                row.editRow();
                row.displayComponentValues(); // clears out the default nullDisplayValue for printable textboxes
            });
            this.add(editButton);
        }
        if (row.canBeDeleted) {
            const deleteButton = new Button({ caption: "Delete", focusable: false, color: "black", margin: 0, borderWidth: 0, fillRow: true });
            deleteButton.addClickListener((event: Event) => {
                row.deleteRow();
            });
            this.add(deleteButton);
        }
    }
}
