import { Button, ClickEvent, HorizontalSpacer, Image, Label, Panel, Table } from "@mcleod/components";
import { ComponentProps } from "@mcleod/components/src/base/ComponentProps";
import { Api, HorizontalAlignment, ModelRow, getLogger } from "@mcleod/core";
import { CLEARED_STATUS_CODE } from "@mcleod/dispatch/src/Movement";
import { AutogenLayoutEdiStopProgress } from "./autogen/AutogenLayoutEdiStopProgress";
const log = getLogger("lme.datafusion.ViewEdiOrder");

export class EdiStopProgress extends AutogenLayoutEdiStopProgress {

    private onStopClick: (event: ClickEvent, indexClicked: number) => void;
    private _stopsTable: Table;

    setStopsData(orderId?: string, moveId?: string, direction?: string) {
        const onStopClick = (event: ClickEvent, indexClicked: number) => {
            indexClicked = indexClicked ?? 1;
            if (indexClicked < this.stopsTable.rowCount)
                this.stopsTable.rows[indexClicked].scrollIntoView();
        }

        if (direction == "I" && orderId != null) {
            Api.search("lme/datafusion/order-stops", { order_id: orderId }).then(response => {
                const stopsData: ModelRow<any>[] = response.data;

                if (stopsData.length > 0) {
                    this.setStops(stopsData, onStopClick);
                }
            });
        }
        else if (direction == "O" && moveId != null) {
            Api.search("lme/datafusion/order-stops", { move_id: moveId }).then(response => {
                const stopsData: ModelRow<any>[] = response.data;

                if (stopsData.length > 0) {
                    this.setStops(stopsData, onStopClick);
                }
            });
        }
        else {
            if (this.stopsTable.dataSource.data?.length > 0) {
                const stopsData = this.stopsTable.dataSource.data;
                const stops = [];

                for (let i = 0; i < stopsData.length; i++) {
                    const s = stopsData[i] as ModelRow;
                    stops.push(s.data);
                }

                this.setStops(stops, onStopClick);
            }
        }
    }

    setStops(stops: ModelRow<any>[], onStopClick: (event: ClickEvent, indexClicked: number) => void) {
        this.onStopClick = onStopClick;
        this.panelStopHeader.removeAll();
        const addSpacer = function (stopStatusPanel: Panel, props?: Partial<ComponentProps>) {
            if (props == null) props = {};
            props.fillHeight = true;
            stopStatusPanel.add(new HorizontalSpacer(props));
        }

        const addTopSpacer = function (stopStatusPanel: Panel, props: Partial<ComponentProps>) {
            if (props.borderBottomColor != null)
                props.borderBottomWidth = 3;
            props.height = 20;
            props.maxHeight = 20;
            addSpacer(stopStatusPanel, props);
        }

        let prevStopCleared = false;

        const smallFillerProps: Partial<ComponentProps> = { width: 75, fillRow: false };

        for (let i = 0; i < stops.length; i++) {
            const stop = stops[i];
            const index = i;
            const lastStop = index + 1 == stops.length;
            const stopCityState = stop["city_name"] + ", " + stop["state"];
            const cleared = stop["status"] == CLEARED_STATUS_CODE;
            log.info("stopCityState: " + stopCityState + ", cleared: " + cleared);

            const stopStatusPanel = new Panel({ fillHeight: true, fillRow: true, padding: 0, rowBreak: false, wrap: false });
            if (index == 0) {
                addTopSpacer(stopStatusPanel, smallFillerProps);
            }
            else if (stops.length == 3 || lastStop || index == 1) {
                addTopSpacer(stopStatusPanel, { borderBottomColor: prevStopCleared ? "warning" : "strokeSecondary" });
            }

            if (index == 0 || stops.length == 3 || lastStop)
                this.addStopBubble(cleared, stopStatusPanel, stop, index);
            else if (index == 1)
                this.addMoreStopsButton(stops, stopStatusPanel);

            if (lastStop)
                addTopSpacer(stopStatusPanel, { ...smallFillerProps, rowBreak: true });
            else if (index == 0 || index == 1)
                addTopSpacer(stopStatusPanel, { rowBreak: true, borderBottomColor: cleared ? "warning" : "strokeSecondary" });

            if (index == 0 || stops.length == 3 || lastStop)
                this.addCityStateInfo(addSpacer, stopStatusPanel, index, smallFillerProps, stopCityState, lastStop);

            prevStopCleared = stop["actual_departure"] != null;

        }
    }

    private addCityStateInfo(addSpacer: (stopStatusPanel: Panel, props?: Partial<ComponentProps>) => void, stopStatusPanel: Panel, index: number, smallFillerProps: Partial<ComponentProps>, stopCityState: string, lastStop: boolean) {
        addSpacer(stopStatusPanel, (index == 0) ? smallFillerProps : null);
        stopStatusPanel.add(new Label({ rowBreak: false, caption: stopCityState, fontSize: "small", fontBold: true }));
        addSpacer(stopStatusPanel, lastStop ? smallFillerProps : null);
        this.panelStopHeader.add(stopStatusPanel);
    }

    private addStopBubble(cleared: boolean, stopStatusPanel: Panel, stop: ModelRow<any>, index: number) {

        const stopClicked = (event) => {
            if (this.onStopClick != null)
                this.onStopClick(event, index);
        };

        if (cleared) {
            stopStatusPanel.add(new Image({
                borderRadius: 50,
                color: "warning",
                height: 30,
                width: 30,
                marginTop: 5,
                rowBreak: false,
                name: "circleCheck",
                onClick: stopClicked
            }));
        } else {
            stopStatusPanel.add(new Label({
                align: HorizontalAlignment.CENTER,
                borderWidth: 3,
                borderColor: "strokeSecondary",
                borderRadius: 50,
                color: "subtle.darker",
                fontSize: "xlarge",
                height: 30,
                width: 30,
                marginTop: 5,
                rowBreak: false,
                caption: (index + 1) + "",
                onClick: stopClicked
            }));

        }
    }

    private addMoreStopsButton(stops: ModelRow<any>[], stopStatusPanel: Panel) {
        const button: Button = this.makeExtraStopsButton(stops);
        stopStatusPanel.add(button);
        this.panelStopHeader.add(stopStatusPanel);
    }

    private makeExtraStopsButton(stops: ModelRow<any>[]) {
        const moreStopsButton: Button = new Button({ width: 300, height: 40, rowBreak: false });
        moreStopsButton.caption = "See " + (stops.length - 2) + " Other Stops";
        moreStopsButton.borderRadius = 20;
        moreStopsButton.paddingBottom = 10;
        moreStopsButton.fontBold = true;
        moreStopsButton.fontSize = "large";
        moreStopsButton.backgroundColor = "warning";
        moreStopsButton.color = "white";
        moreStopsButton.padding = 0;
        moreStopsButton.margin = 0;
        if (this.onStopClick != null)
            moreStopsButton.addClickListener((event) => this.onStopClick(event, null));
        return moreStopsButton;
    }

    public get stopsTable(): Table {
        return this._stopsTable;
    }

    public set stopsTable(value: Table) {
        this._stopsTable = value;
    }
}
