/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-sidel    *
 * model behavior, you should modify: ../ModelComments.ts                      *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/general/comments";

export class AutogenModelComments<RowType extends ModelRow> extends Endpoint<RowType, RowCommentsFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowComments extends ModelRow<RowCommentsFields> {
    constructor() {
        super(AutogenUserSavedSearchEndpoint);
    }
}

export interface RowCommentsFields {
    attach_filename: string;
    attachment: string;
    comment_type_descr: string;
    comment_type_id: string;
    comments: string;
    company_id: string;
    copy_to_order: boolean;
    display_atdispatch: boolean;
    entered_date: Date;
    entered_user_id: string;
    id: string;
    onboard_lock: boolean;
    order_comment_type: string;
    parent_row_id: string;
    parent_row_type: string;
    rmis_image_id: string;
    user_name: string;
}

