import { DateUtil, DisplayType, DisplayValue, getUnauthSettings, StringUtil } from "@mcleod/core";
import { ValidationResult } from "../../base/ValidationResult";
import { Textbox } from "./Textbox";

export class TextboxValidator {
    public static validate(textbox: Textbox, checkRequired: boolean, showErrors: boolean = true, addlValidationCallback?: (value: string) => ValidationResult): ValidationResult {
        const isEmpty = StringUtil.isEmptyString(textbox.text);
        if (checkRequired !== false && textbox.required && isEmpty && textbox._designer == null) {
            if (showErrors === true) {
                return { component: textbox, isValid: false, validationMessage: "Required" };
            }
            return { component: textbox, isValid: false };
        } else if (!isEmpty) {
            if (!textbox.lookupModelAllowFreeform && textbox.userSelectedFromDropdown === false)
                return { component: textbox, isValid: false, validationMessage: "Free-form text is not allowed in this field.  Please select a value from the list." };

            let result: ValidationResult;
            switch (textbox.displayType) {
                case DisplayType.INTEGER: result = TextboxValidator._validateDecimal(textbox, 0); break;
                case DisplayType.CURRENCY: result = TextboxValidator._validateCurrency(textbox); break;
                case DisplayType.DECIMAL: result = TextboxValidator._validateDecimal(textbox, 4); break;
                case DisplayType.DISTANCE: result = TextboxValidator._validateDecimal(textbox, 1); break;
                case DisplayType.FLOAT: result = TextboxValidator._validateDecimal(textbox, 6); break;
                case DisplayType.LENGTH: result = TextboxValidator._validateDecimal(textbox, 1); break;
                case DisplayType.TEMPERATURE: result = TextboxValidator._validateDecimal(textbox, 1); break;
                case DisplayType.WEIGHT: result = TextboxValidator._validateDecimal(textbox, 4); break;

                case DisplayType.DATE: result = TextboxValidator._validateDate(textbox, true, false); break;
                case DisplayType.DATERANGE: result = TextboxValidator._validateDateRange(textbox); break;
                case DisplayType.DATETIME: result = TextboxValidator._validateDate(textbox, true, true); break;
                case DisplayType.TIME: result = TextboxValidator._validateDate(textbox, false, true); break;

                case DisplayType.EMAIL: result = TextboxValidator._validateEmail(textbox); break;
                case DisplayType.CITY: result = TextboxValidator._validateCity(textbox); break;
                case DisplayType.LOCATION: result = TextboxValidator._validateLocation(textbox); break;
                case DisplayType.STATE: result = TextboxValidator._validateState(textbox); break;
                case DisplayType.PHONE: result = TextboxValidator._validatePhone(textbox); break;
                case DisplayType.LINK: result = TextboxValidator._validateLink(textbox); break;
            }
            if (result != null) {
                if (result?.isValid === false || addlValidationCallback == null)
                    return result;
                const addlValidationResult = addlValidationCallback(textbox.text);
                if (addlValidationResult == null || addlValidationResult.isValid === true)
                    return result;
                return addlValidationResult;
            } else if (addlValidationCallback != null) {
                return addlValidationCallback(textbox.text);
            }
        }
        if (showErrors === true) {
            textbox.validationWarning = null;
        }
        return null;
    }


    private static _validateCurrency(textbox: Textbox): ValidationResult {
        return TextboxValidator._validateDecimalText(textbox, textbox.text.replace(/\s*\$\s*/, ''), 2, null, textbox.minValue, textbox.maxValue);
    }

    private static _validateDecimal(textbox: Textbox, defaultScale: number): ValidationResult {
        const scale = textbox.scale != null ? textbox.scale : defaultScale;
        let textToTest;
        if (textbox.selectedItem != null)
            textToTest = textbox.selectedItem.value;
        else
            textToTest = textbox.text;
        return TextboxValidator._validateDecimalText(textbox, textToTest, scale, textbox.precision, textbox.minValue, textbox.maxValue);
    }

    private static _validateDecimalText(textbox: Textbox, text: string, scale: number, precision?: number, minValue?: number, maxValue?: number): ValidationResult {
        let textToTest = text;
        let leftOfTheDecimal: string;
        let rightOfTheDecimal: string;
        if (textToTest.indexOf(".") == 0)
            textToTest = "0" + textToTest;
        const dotPos = textToTest.indexOf(".");
        if (textToTest.indexOf(".") >= 0) {
            leftOfTheDecimal = textToTest.substring(0, dotPos);
            rightOfTheDecimal = textToTest.substring(dotPos + 1);
        }
        else {
            leftOfTheDecimal = textToTest;
            rightOfTheDecimal = "";
        }
        const isNAN = isNaN(Number(textToTest.replace(/,/g, "")));
        let msg: string;
        if (precision == null) {
            if (isNAN === true || rightOfTheDecimal.length > scale) {
                if (scale === 0)
                    msg = "Please enter a whole number.";
                else if (scale === 1)
                    msg = "Please enter a number with at most 1 decimal place.";
                else
                    msg = "Please enter a number with at most " + scale + " decimal places.";
            }
        }
        else {
            if (isNAN === true || leftOfTheDecimal.length > precision || rightOfTheDecimal.length > scale) {
                if (scale === 0)
                    msg = "Please enter a whole number that is at most " + precision + " digits in length.";
                else if (scale === 1)
                    msg = "Please enter a number that is at most " + precision + " digits in length, with at most 1 decimal place.";
                else
                    msg = "Please enter a number that is at most " + precision + " digits in length, with at most " + scale + " decimal places.";
            }
        }
        if (isNAN === false) {
            const testValue = parseFloat(textToTest);
            if (testValue != null) {
                let rangeMessage = "";
                if (minValue != null && maxValue != null) {
                    if (testValue < minValue || testValue > maxValue)
                        rangeMessage = "Please enter a number between " + minValue + " and " + maxValue + ".";
                }
                else if (minValue != null) {
                    if (testValue < minValue)
                        rangeMessage = "Please enter a number greater than or equal to " + minValue + ".";
                }
                else if (maxValue != null) {
                    if (testValue > maxValue)
                        rangeMessage = "Please enter a number less than or equal to " + maxValue + ".";
                }
                if (StringUtil.isEmptyString(rangeMessage) !== true)
                    msg = !StringUtil.isEmptyString(msg) ? msg + "\n" + rangeMessage : rangeMessage;
            }
        }
        if (StringUtil.isEmptyString(msg))
            return null;
        return { component: textbox, isValid: false, validationMessage: msg };
    }

    private static _validateDate(textbox: Textbox, hasDate: boolean, hasTime: boolean): ValidationResult {
        const value = textbox.text;
        const parsed = DateUtil.parseDateWithKeywords(value, hasDate, hasTime, textbox.timezone);
        if (parsed == null || isNaN(parsed.getTime())) {
            if (hasDate && hasTime)
                return { component: textbox, isValid: false, validationMessage: "Please enter a valid date/time." };
            else if (hasDate)
                return { component: textbox, isValid: false, validationMessage: "Please enter a valid date." };
            else
                return { component: textbox, isValid: false, validationMessage: "Please enter a valid time." };
        }
        else if (hasDate && hasTime)
            return { component: textbox, isValid: true, validatedValue: DateUtil.formatDateTime(parsed) };
        else if (hasDate)
            return { component: textbox, isValid: true, validatedValue: DateUtil.formatDate(parsed) };
        else
            return { component: textbox, isValid: true, validatedValue: DateUtil.formatTime(parsed) };
    }

    private static _validateDateRange(textbox: Textbox): ValidationResult {
        const text = textbox.text;
        let splitPos = text.indexOf("-");
        if (splitPos < 0)
            splitPos = text.indexOf(",");
        if (splitPos < 0) {
            const date = DateUtil.parseDateWithKeywords(text, true, false, textbox.timezone);
            if (date != null) {
                const dateString = DisplayValue.getDisplayValue(date, DisplayType.DATERANGE, textbox.format);
                textbox.text = dateString;
                return { component: textbox, isValid: true, validatedValue: textbox.text };
            }
        } else {
            const dateStrings = [textbox.text.substring(0, splitPos), textbox.text.substring(splitPos + 1)];
            const firstDate = DateUtil.parseDateWithKeywords(dateStrings[0], true, false, textbox.timezone);
            const secondDate = DateUtil.parseDateWithKeywords(dateStrings[1], true, false, textbox.timezone);
            if (firstDate != null && secondDate != null) {
                const firstDateString = DisplayValue.getDisplayValue(firstDate, DisplayType.DATERANGE, textbox.format);
                const secondDateString = DisplayValue.getDisplayValue(secondDate, DisplayType.DATERANGE, textbox.format);
                textbox.text = firstDateString + "-" + secondDateString;
                return { component: textbox, isValid: true, validatedValue: textbox.text };
            }
        }
        return { component: textbox, isValid: false, validationMessage: "Please enter valid date or date range." }
    }

    private static _validateEmail(textbox: Textbox): ValidationResult {
        const value = textbox.text;
        // use RFC 2822 standard email validation
        const regex = /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/;
        if (regex.test(value))
            return { component: textbox, isValid: true, validatedValue: value }
        else
            return { component: textbox, isValid: false, validationMessage: "Please enter a valid email address." };
    }

    private static _validateState(textbox: Textbox): ValidationResult {
        return null;
    }

    private static _validateCity(textbox: Textbox): ValidationResult {
        return null;
    }

    private static _validateLocation(textbox: Textbox): ValidationResult {
        return null;
    }

    private static _validatePhone(textbox: Textbox): ValidationResult {
        let value = textbox.text;
        const settings = getUnauthSettings().company_settings;
        if (settings.enable_phone_format === false || value.startsWith("0")) // international phone number - don't try to format
            return { component: textbox, isValid: true, validatedValue: value };
        let extension: string;
        value = value.toLowerCase().replace("ext", "x");
        const xIndex = value.indexOf("x");
        if (xIndex >= 0) {
            extension = value.substring(xIndex + 1).replace(" ", "");
            value = value.substring(0, xIndex);
        }
        const repl = value.replace(new RegExp("([( )-\. ])", 'g'), "");
        if (Number.isNaN(Number(repl)))
            return { component: textbox, isValid: false, validationMessage: "Phone numbers can only contain digits and formatting characters." };
        if (repl.length === 7)
            return { component: textbox, isValid: false, validationMessage: "Please enter an area code." };
        if (repl.length === 10) {
            let formatted: string;
            if (settings.phone_format === "-")
                formatted = repl.substring(0, 3) + "-" + repl.substring(3, 6) + "-" + repl.substring(6);
            else if (settings.phone_format === ".")
                formatted = repl.substring(0, 3) + "." + repl.substring(3, 6) + "." + repl.substring(6);
            else
                formatted = "(" + repl.substring(0, 3) + ") " + repl.substring(3, 6) + "-" + repl.substring(6);
            if (extension != null)
                formatted += " x" + extension;
            return { component: textbox, isValid: true, validatedValue: formatted };
        }
        else
            return { component: textbox, isValid: false, validationMessage: "Please enter a ten-digit phone number.  Formatting characters can be included or omitted." };
    }

    private static _validateLink(textbox: Textbox): ValidationResult {
        const value = textbox.text;
        let result;
        if (value.indexOf("/www.") > 0)
            result = /.+:\/\/www\..+\..+$/.test(value);
        else
            result = /.+:\/\/.+\..+$/.test(value);
        if (result === true)
            return { component: textbox, isValid: true, validatedValue: value };
        return { component: textbox, isValid: false, validationMessage: "Please enter a valid link.  Note that '://' must be included (example: https://example.com)." };
    }
}
