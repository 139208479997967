/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelBrTrackingCtrl.ts                *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/powerbroker/br-tracking-ctrl";

export class AutogenModelBrTrackingCtrl<RowType extends ModelRow> extends Endpoint<RowType, RowBrTrackingCtrlFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowBrTrackingCtrl extends ModelRow<RowBrTrackingCtrlFields> {
    constructor(values?: Partial<RowBrTrackingCtrlFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowBrTrackingCtrlFields {
    vendor: string;
    vendor_display_value: string;
}

