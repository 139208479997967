import { ArrayUtil } from "./ArrayUtil";
import { CurrencySettings } from "./CurrencyUtil";
import { DateUtil } from "./Date";
import { setLogConfig } from "./Logger";
import { setThemeNameOrValue } from "./Theme";
import { AuthTypeUtil } from "./constants/AuthType";
import { BroadcastMessageHandler } from "./messaging/BroadcastMessageHandler";

export type SettingsListener = () => void;

export enum ClientType { power_broker_web, portal }

interface UnauthSettings {
    base_url_offset?: string;
    company_settings?: CompanySettings;
    server_tz_id?: string;
    server_tz_offset?: number;
    messaging_url?: string;
    environment_message?: string;
    auth0_domain?: string;
    auth0_client_id?: string;
}

interface CompanySettings {
    allow_keep_signed_in?: boolean;
    header_bg_color?: string;
    header_logo?: string; // base64
    text_color?: string;
    enable_unauth_tracking?: boolean;
    google_api_key?: string;
    google_maps_url?: string;
    enable_phone_format?: boolean;
    phone_format?: string;
    name?: string;
}

interface UserSettings {
    date_format: string;
    time_format: string;
    user_id: string;
    user_login_id: string;
    user_type: string;
    user_name: string;
    user_phone: string;
    user_extension: string;
    user_company_name: string;
    company_name: string;
    company_scac: string;
    company_id: string;
    sel_text_on_focus: boolean;
    admin_email_address: string;
    daily_advance_limits: string;
    wire_code_settlement: string;
    bp_profile: string;
    tm_profile: string;
    default_wirecode: string;
    new_order_next_action: string;
    default_dispatch_layout: string;
    responsibility_codes: string[];
    user_settings_page_access: boolean;
    is_portal_user: boolean;
    ltl_quote_template: number;
    quote_template: number;
    default_revenue_code: string;
    allow_switching_tenants: boolean;
    margin_percentage: string;
    trimble_map_traffic: string;
    google_map_type: string;
    google_map_layer: string;
    // delivered_load_day is in here currently but probably should be broken out into some kind of LME/Portal-specific setting
}

interface LicenseInfo {
    licensed_packages: string[];
    licensed_classes: string[];
    excluded_classes: string[];
}

interface MapSettings {
    always_show_maps: boolean;
    distance_calc_vendor?: string;
    findnear_vendor?: string;
    single_point_vendor?: string;
    movement_map_vendor?: string;
    trimble_settings?: {
        api_key1?: string;
        api_key2?: string;
    }
    practical_distance_vendor?: string;
    google_greedy?: boolean;
}

interface MarketInsightSettings {
    username: string;
    password: string;
}

interface AuthSettings {
    config: Config;
    license: LicenseInfo;
    menu: any;
    menu_favorites: any[];
    menu_toolbox_items: any[];
    permissions: any;
    user_settings: UserSettings;
    dispatch_control: any;
    currency_settings: CurrencySettings;
    map_settings: MapSettings;
    market_insight_settings: MarketInsightSettings;
    support_email_address?: string;
    pbw_support_email_address?: string;
    running_in_ide?: boolean;
    driver_settings?: any;
    amqp?: string;
    client_type: ClientType;
}

interface Settings {
    auth?: AuthSettings;
    unauth?: UnauthSettings;
}

interface Config {
    theme?: string;
    snackbar_time?: number;
    use_smooth_transitions: string;
    [key: string]: any;
}

const settings: Settings = {};
const configListeners: { (newConfig: any, oldConfig: any): void }[] = [];

let config: Config;
let configCameFromAuth: boolean;
const authSettingsListeners: SettingsListener[] = [];

export function getAuthSettings(): AuthSettings {
    return settings?.auth;
}

export function setAuthSettings(value: AuthSettings) {
    settings.auth = value;
    if (value != null) {
        value.menu = value.menu?.menu;
        DateUtil.setUserSettings(value.user_settings.date_format, value.user_settings.time_format, value.user_settings.date_format + " " + value.user_settings.time_format);
        if (value.config != null)
            setConfig(value.config, true);
    }
    for (const listener of authSettingsListeners)
        listener();
    BroadcastMessageHandler.init();
}

export function getUserSettings() {
    return settings.auth?.user_settings;
}

export function getMapSettings() {
    return settings.auth?.map_settings;
}

export function isRunningInIDE() {
    return settings.auth?.running_in_ide === true;
}

export function getClientType(): ClientType {
    const key = Object.keys(ClientType).find(key => <any>key == settings.auth?.client_type);
    if (key != null)
        return ClientType[key];
}

export function addSettingsChangeListener(listener: SettingsListener) {
    authSettingsListeners.push(listener);
}

export function removeSettingsChangeListener(listener: SettingsListener) {
    ArrayUtil.removeFromArray(authSettingsListeners, listener);
}

/**
 * Adds a callback function to be called when the configuration changes.  This is needed by most things that store a configuration setting during
 * initialization because the configuration hasn't been loaded yet.
 *
 * NOTE: The callback will be called immediately upon adding the listener to handle the case where the configuration has already been loaded.
 *
 * @param listener
 */
export function addConfigChangeListener(listener: { (newConfig: Config, oldConfig: Config): void }) {
    configListeners.push(listener);
    listener(getConfig(), undefined);
}

export function removeConfigChangeListener(listener: { (newConfig: any, oldConfig: any): void }) {
    ArrayUtil.removeFromArray(configListeners, listener);
}

/**
 * Sets the system-level configuration.  Configuration will be sent with the unauthenticated settings that won't have any user-specific overrides.
 * Once a user is authenticated, the user-specific overrides will be added and the configuration will be sent again.
 *
 * @param value
 * @param isAuthConfig
 */
function setConfig(value: Config, isAuthConfig: boolean) {
    if (isAuthConfig || configCameFromAuth !== true) {
        const oldConfig = config;
        configCameFromAuth = isAuthConfig;
        config = value;
        for (const listener of configListeners)
            listener(value, oldConfig);
        if (value != null && value.theme != null)
            setThemeNameOrValue(value.theme);
    }
}

export function getConfig(): Config {
    return config;
}

export async function setUnauthSettings(value: any) {
    settings.unauth = value;
    if (value != null && value.log_config != null)
        setLogConfig(value.log_config);
    if (value != null && value.config != null)
        setConfig(value.config, false);
}

export function getUnauthSettings(): UnauthSettings {
    return settings.unauth;
}

/**
 * The setUserSetting and getUserSettingXXXX methods are used to persists and retrieve user settings.  For now, they are written to local storage.
 * In the future, we may decide to persist them remotely, but this abstraction gives us the ability to change that (or maybe even make it variable whether this is persisted locally or remotely.)
 * @param key
 * @returns
 */
export function getUserSettingString(key: string): string {
    return localStorage.getItem(key);
}

export function getUserSettingBoolean(key: string, valueIfNull = false): boolean {
    const value = localStorage.getItem(key);
    if (value === "true")
        return true;
    else if (value === "false")
        return false;
    return valueIfNull;
}

export function getUserSettingNumber(key: string, valueIfNull = 0): number {
    const value = localStorage.getItem(key);
    if (value == null)
        return valueIfNull;
    const result = parseInt(value);
    if (isNaN(result))
        return valueIfNull;
    return result;
}

export function getUserSettingObject(key: string, valueIfNull = null): Record<string, unknown> {
    const value = localStorage.getItem(key);
    if (value == null)
        return valueIfNull;
    return JSON.parse(value);
}

export function setUserSetting(key: string, value: number | boolean | string | Record<string, unknown>) {
    if (value == null)
        localStorage.removeItem(key);
    else
        localStorage.setItem(key, value.toString());
}

export function isMessagingEnabled() {
    return getUnauthSettings()?.messaging_url != null;
}

export function getUserAuthType(): string {
    return AuthTypeUtil.fromUserType(getAuthSettings()?.user_settings?.user_type);
}
