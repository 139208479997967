import { BlurEvent, ClickEvent, Component, DataDisplayEvent, DataSource, DataSourceMode, Image, Label, Layout, Overlay, Panel, TableRow, TableRowDisplayEvent, Textbox } from "@mcleod/components";
import { EditRowDecorator } from "@mcleod/components/src/page/decorators/EditRowDecorator";
import { Alignment, Api, ModelRow, StringUtil, getLogger } from "@mcleod/core";
import { MovementCarrierPay } from "../../powerbroker/src/MovementCarrierPay";
import { OfferField } from "../../powerbroker/src/models/ModelCarrierOffer";
import { CarrierOfferSlideout } from "./CarrierOfferSlideout";
import { OfferTabEvent, OfferTabEventAction, OfferTabType } from "./MovementOfferTabs";
import { OfferSource, OfferUtil } from "./OfferUtil";
import { isSpotOrder } from "./Orders";
import { QuickSellDetails } from "./QuickSellDetails";
import { AutogenLayoutMovementOffers } from "./autogen/AutogenLayoutMovementOffers";
import { ModelMovement } from "./models/ModelMovement";
import { DispatchUtil } from "./DispatchUtil";

const log = getLogger("lme.dispatch.MovementOffers");
export const CONTACT_NAME_LOOKUP_MODEL = "lme/general/contact";
export const CONTACT_NAME_LOOKUP_MODEL_LAYOUT = "lme/general/ContactLookup";

export class MovementOffers extends AutogenLayoutMovementOffers {
    private orderID: string;
    private moveRow: ModelRow;
    private movementID: string;
    private offerUtil: OfferUtil;
    stopCountText: string;
    public onTabEvent: (offerTabEvent: OfferTabEvent) => void = () => { };

    private sendTabEvent(offerTabEvent: OfferTabEvent) {
        this.onTabEvent(offerTabEvent);
    }

    public initializeOfferUtil(movementID: string, moveRow: ModelRow) {
        this.offerUtil = new OfferUtil(movementID, moveRow, this);
    }

    override onLoad() {
        this.tableOffers.onEditLayoutLoaded = this._offerLayoutLoaded();
    }

    async initialize(orderID: string, movementSource: DataSource, movementID: string) {
        this.orderID = orderID;
        this.sourceMovement = movementSource;
        this.movementID = movementID;
        await this.setMovementRow();
        this.initializeOfferUtil(movementID, this.moveRow);
        await this.search();
        this.mainDataSource.mode = DataSourceMode.UPDATE;
    }

    public async setMovementRow() {
        if (this.sourceMovement === null) {
            this.moveRow = await new ModelMovement().searchSingle({ id: this.movementID }, "lme/dispatch/Movement");
        } else {
            this.moveRow = this.sourceMovement.activeRow;
        }
    }

    public async search() {
        await this.sourceCarrierOffer.search({ "movement_id": this.moveRow.get("id") });
        this.sendTabEvent({ type: OfferTabType.OFFER_HISTORY, action: OfferTabEventAction.SEARCH });
    }

    public updateOfferCount() {
        this.labelOfferHistoryCount.caption = this.mainDataSource.data == null ? "" : `${this.mainDataSource.data.length}`;
        let totalCount = this.mainDataSource.data.length;
        if (this.sourceMovement != null)
            totalCount = this.sourceMovement.activeRow.get("carrier_offer_count") + this.mainDataSource.data.length - this.mainDataSource.originalRowCount;
        this.labelOfferHistory.tooltip = "Total Offers Created " + totalCount;
        this.labelOfferHistoryCount.tooltip = "Total Offers Created " + totalCount;
    }

    private _offerLayoutLoaded(): (rowDecorator: EditRowDecorator, tableRow: TableRow) => void {
        return (rowDecorator: EditRowDecorator, tableRow: TableRow) => {
            rowDecorator.labelTitle.caption = `Quick Offer - Order ${this.orderID}`;
            rowDecorator.onSave = (offerRow: ModelRow) => {
                const tbIccNum = tableRow.findComponentById("textboxIccNumber") as Textbox;
                this.offerUtil.iccNumberRequired(tbIccNum, offerRow);
                tableRow.saveChangesFromEdit(offerRow);
                this.displayStatus(tableRow);
            };
            const layout = rowDecorator.layout as CarrierOfferSlideout
            const quickSellDetails = layout.layoutQuickSellDetails as QuickSellDetails;
            quickSellDetails.configureForSlideout(this.stopCountText);
            quickSellDetails.mainDataSource.setRowsAndMode(DataSourceMode.NONE, [this.moveRow]);
            const loCarrierPay = layout.loCarrierPay as MovementCarrierPay;
            log.debug("Offer data:", layout.mainDataSource.activeRow)
            const parentRow = this.mainDataSource.parentDataSource?.activeRow;
            if (!parentRow?.isEmpty() && parentRow instanceof ModelMovement) {
                loCarrierPay.mainDataSource.data[0] = this.mainDataSource.parentDataSource.activeRow;
                loCarrierPay.mainDataSource.rowIndex = 0;
                this._loadSlideoutData(layout, loCarrierPay);
            } else {
                new ModelMovement().searchSingle({ id: this.moveRow.get("id") }, "lme/powerbroker/CarrierAssignment").then(moveRow => {
                    loCarrierPay.mainDataSource.data.push(moveRow);
                    loCarrierPay.mainDataSource.rowIndex = 0;
                    this._loadSlideoutData(layout, loCarrierPay)
                });
            }
            layout.assignableCheck = () => DispatchUtil.isAssignable(this.moveRow, layout.buttonAssignCarrier, null);
            layout.mainDataSource.displayDataInBoundComponents();
            layout.buttonAssignCarrier.enabled = !StringUtil.isEmptyString(layout.activeRow.get("icc_number", ""));
            layout.assignButtonClicked = () => rowDecorator.multiButton.primaryButton.clicked(); // triggers the save and close
            layout.carrierAssigned = this.offerUtil.doAfterOfferAssignment;
            loCarrierPay.buttonOverrideTargetPay.enabled = false;
            loCarrierPay.buttonOverrideMaxPay.enabled = false;
        }
    }

    private _loadSlideoutData(layout: CarrierOfferSlideout, loCarrierPay: MovementCarrierPay): void {
        const offerRow = layout.mainDataSource.activeRow;
        const carrierName = offerRow?.get("carrier_name");
        const contactName = offerRow?.get("contact_name");
        const tractorCityId = offerRow?.get("tractor_city_id");
        offerRow.set("order_id", this.orderID);
        offerRow.set("orders_on_hold", this.moveRow.get("orders.on_hold", null));
        offerRow.set("pending_payee", this.moveRow.get("pending_payee_id", null) ?? this.moveRow.get("has_pending_assignment", false));
        offerRow.set("is_subject_order", isSpotOrder(this.moveRow.get("orders.subject_order_status", null)));
        offerRow.set("order_total_chg", loCarrierPay.mainDataSource.activeRow.get("orders.total_charge"));
        loCarrierPay.layoutTargetPay.mpactRatingEnabled(loCarrierPay.mainDataSource.activeRow.get("target_pay_method", "") === "S");
        loCarrierPay.displayCalculatedFields();
        layout.updateMargins(OfferField.OFFER, "offer_margin", layout.tbDispatchMargin, layout.tbDispatchMarginPrcnt, true);
        layout.updateMargins(OfferField.COUNTER, "counter_margin", layout.tbCounterMargin, layout.tbCounterMarginPrcnt, true);
        offerRow.set("movement_id", loCarrierPay.mainDataSource.activeRow?.get("id"));

        if (carrierName) {
            offerRow.setLookupModelData("carrier_name", new ModelRow(layout.tbCarrierName.lookupModel, false, { "name": carrierName }));
            layout.tbContactName.lookupModel = CONTACT_NAME_LOOKUP_MODEL;
            layout.tbContactName.lookupModelLayout = CONTACT_NAME_LOOKUP_MODEL_LAYOUT;
            layout.tbCarrierName.displayData(offerRow, null, 0);
        }
        if (contactName) offerRow.setLookupModelData("contact_name", new ModelRow(layout.tbContactName.lookupModel, false, { "name": contactName }));
        if (tractorCityId && loCarrierPay.mainDataSource.activeRow?.get("id") != null) {
            Api.post("lme/dispatch/get-next-stop-distance", { city_id: tractorCityId, movement_id: loCarrierPay.mainDataSource.activeRow?.get("id") }).then(response => {
                offerRow.set("miles_to_origin", response?.data[0]?.distance);
                layout.tbMilesToPickup.text = response?.data[0]?.distance;
            })
        }
    }

    public fillOfferReferenceData(modelRow: ModelRow, movementRow: ModelRow, orderId: string, movementId: string) {
        modelRow.set("order_id", orderId);
        modelRow.set("movement_id", movementId);
        if (movementRow.get("override_targetpay") != null)
            modelRow.set("target_pay", movementRow.get("override_targetpay"));
        else
            modelRow.set("target_pay", movementRow.get("target_pay"));
        if (movementRow.get("override_max_pay") != null)
            modelRow.set("max_pay", movementRow.get("override_max_pay"));
        else
            modelRow.set("max_pay", movementRow.get("max_buy"));
        modelRow.set("order_total_chg", movementRow.get("orders.total_charge"));
    }

    /** This is an event handler for the onBlur event of textboxIccNumber.  */
    textboxIccNumberOnBlur(event: BlurEvent) {
        if (event.changedWhileFocused) {
            const textbox = event.target as Textbox;
            const tableRow: TableRow = TableRow.getContainingTableRow(textbox);
            this.populateTableRowFromIccNumber(tableRow);
        }
    }

    async populateTableRowFromIccNumber(tableRow: TableRow) {
        let carrierDataFound = false;
        const modelRow: ModelRow = tableRow.data;
        const iccNumber: String = modelRow.get("icc_number");
        const carrierNameComponent: Textbox = tableRow.findComponentById("textboxCarrierName") as Textbox;
        const carrierContactNameComponent: Textbox = tableRow.findComponentById("textboxContactName") as Textbox;

        if (carrierContactNameComponent.hasLookupModel()) {
            carrierContactNameComponent.lookupModel = null;
            carrierContactNameComponent.lookupModelLayout = null;
        }
        this.setCarrierData(tableRow, null);
        modelRow.setLookupModelData("contact_name", null);
        modelRow.set("contact_name", null);
        modelRow.set("contact_phone", null);
        modelRow.set("email", null);

        const prequalWarningLabel: Label = tableRow.findComponentById("labelWarningImage") as Label;
        if (prequalWarningLabel !== undefined) {
            prequalWarningLabel.visible = false;
        }

        if (iccNumber == null || iccNumber.length == 0) {
            return;
        }

        Api.search("lme/powerbroker/carriers", { icc_name_search: iccNumber }).then(async response => {
            const carrierData = response?.data?.[0];
            if (carrierData != null) {
                carrierDataFound = true;
                this.setCarrierData(tableRow, carrierData);
                carrierNameComponent.text = carrierData["payee.name"];
                this.offerUtil.validateCarrierForDispatch(tableRow);
                carrierContactNameComponent.lookupModel = CONTACT_NAME_LOOKUP_MODEL;
                carrierContactNameComponent.lookupModelLayout = CONTACT_NAME_LOOKUP_MODEL_LAYOUT;
                tableRow.findComponentById("textboxContactName")?.focus();
                carrierDataFound = true;
            }
            if (!carrierDataFound && await this.validActiveVendor()) {
                this.prequalifyCarrier(iccNumber, tableRow);

            }
        });
        tableRow.displayComponentValues();
    }

    setCarrierData(tableRow: TableRow, carrierData: any) {
        const modelRow = tableRow.data;
        modelRow.set("payee_id", carrierData?.["payee.id"]);
        modelRow.set("dot_number", carrierData?.["drs_payee.dot_number"]);
        modelRow.set("carrier_ranking", carrierData?.["drs_payee.carrier_ranking"]);
        modelRow.set("carrier_name", carrierData?.["payee.name"]);
    }

    showTooltipOnDataDisplay(event: DataDisplayEvent) {
        this.offerUtil.showTooltipOnDataDisplay(event);
    }

    /** This is an event handler for the onRowDisplay event of tableOffers.  */
    tableOffersOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        this.offerUtil.tableOffersOnRowDisplay(event);
        this.displayStatus(tableRow);
    }

    buttonAssignCarrierOnClick(event: ClickEvent) {
        this.offerUtil.assignCarrierEvent(event);
    }

    /** This is an event handler for the onContentsChanged event of tableOffers.  */
    tableOffersOnContentsChanged(event) {
        this.updateOfferCount();
        this.tableOffers.rows.forEach(tableRow => {
            const status = tableRow.data.get("status");
            const originalStatus = tableRow.data.originalData?.["status"];
            if (originalStatus === undefined || (status != originalStatus) && (status === "O" || originalStatus === "O")) {
                this.sendTabEvent({ type: OfferTabType.OFFER_HISTORY, action: OfferTabEventAction.SYNC_DATA, changedRow: tableRow });
            }
        });
    }
    /** This is an event handler for the onDisplay event of sourceCarrierOffer.  */
    sourceCarrierOfferOnDisplay(event) {
        this.updateOfferCount();

    }

    displayStatus(tableRow: TableRow) {
        const status = (tableRow.data as ModelRow).get("status");
        const panelStatus = tableRow.findComponentById("panelStatus") as Panel;
        this.offerUtil.setOfferStatusLabel(status, panelStatus);
    }

    panelStatusOnClick(event: ClickEvent) {
        const panel = event.target as Panel;
        panel.ripple("primary.lighter");
        const row = TableRow.getContainingTableRow(panel);
        row.clicked();
        if (panel["statusDropDownItems"] != null)
            this.displayOfferStatusDropDowns(panel);
        else {
            this.populateAndDisplayOfferStatusDropDowns(panel);
        }

    }

    displayOfferStatusDropDowns(panel: Panel) {
        const dropDownItems = panel["statusDropDownItems"];
        if (dropDownItems != null) {
            Overlay.showDropdown(panel, dropDownItems, null, { width: 225 }, {
                align: Alignment.LEFT,
                onClose: () => {
                    panel.borderShadow = false;
                }
            });
        }
    }

    populateAndDisplayOfferStatusDropDowns(panel: Panel) {
        const options = [{ "displayValue": "Initial Offer", "value": "I" }, { "displayValue": "Pending Accept", "value": "Z" }, { "displayValue": "Countered", "value": "O" }, { "displayValue": "Declined", "value": "D" }];
        panel["statusDropDownItems"] = options.map(item => {
            return { caption: item.displayValue, onClick: () => this.statusOnChange(item.value, panel) }
        })
        this.displayOfferStatusDropDowns(panel);
    }

    statusOnChange(value: string, panel: Panel) {
        const tableRow: TableRow = TableRow.getContainingTableRow(panel);
        const offerRow = tableRow.data;
        offerRow.set("status", value);
        this.offerUtil.setOfferStatusLabel(tableRow.data.get("status"), panel);
    }

    getPrequalValidationQuickInfoLayout(baseTooltip: Component, message: string, vendorUrl: string) {
        let layout = null;
        layout = Layout.getLayout("lme/powerbroker/PrequalValidationWarningQuickInfo");
        layout.onLoad = () => {
            layout["labelPrequalWarningMessage"].visible = true;
            layout["labelPrequalWarningMessage"].caption = message;
            if (!StringUtil.isEmptyString(vendorUrl)) {
                layout["vendorSiteBtn"].visible = true;
                layout["vendorSiteBtn"].link = vendorUrl;
            } else {
                layout["vendorSiteBtn"].visible = false;
            }
        };
        return layout;
    }

    /** This is an event handler for the beforeRowSave event of tableCarrierOffer.  */
    tableCarrierOfferBeforeRowSave(event) {
        const tableRow = event.getTableRow();
        const modelRow = tableRow.data as ModelRow;
        modelRow.set("source", OfferSource.QUICKOFFER);
        if (tableRow.data._appending)
            this.fillOfferReferenceData(tableRow.data, this.moveRow, this.orderID, this.moveRow.get("id"));
    }

    /** This is an event handler for the beforeLookupModelSearch event of textboxContactName.  */
    textboxContactNameBeforeLookupModelSearch(event) {
        const contactName = event.target as Textbox;
        const tableRow: TableRow = TableRow.getContainingTableRow(contactName);
        const modelRow: ModelRow = tableRow.data;
        event.filter.parent_row_id = modelRow.get("payee_id") ?? null;
        event.filter.parent_row_type = "P";
        event.filter.is_active = "Y";

        if (contactName != null && contactName.onSelectItem == null) {
            contactName.onSelectItem = ((textbox, selection) => {
                if (selection != null) {
                    tableRow.data.set("contact_phone", (selection as ModelRow).get("phone"));
                    tableRow.data.set("email", (selection as ModelRow).get("email"));
                    const contactPhone = tableRow.findComponentById("textboxContactPhone") as Textbox;
                    const contactEmail = tableRow.findComponentById("textboxContactEmail") as Textbox;
                    contactPhone.text = (selection as ModelRow).get("phone");
                    contactEmail.text = (selection as ModelRow).get("email");
                }
                return undefined;
            });
        }
    }

    setCarrierDataFromPrequal(tableRow: TableRow, prequalData: any) {
        const modelRow = tableRow.data;
        modelRow.set("dot_number", prequalData?.["dot_number"]);
        modelRow.set("carrier_name", prequalData?.["company_name"]);
    }

    prequalifyCarrier(iccNumber: String, tableRow: TableRow) {

        Api.post("lme/powerbroker/prequal-carrier", {
            identifier_type: "MC",
            identifier_number: iccNumber
        }).then(prequalResponse => {
            const prequalResultData = prequalResponse?.data?.[0];
            if (prequalResultData !== null && prequalResultData?.highway_vendor) {
                const labelWarningImage = tableRow?.findComponentById("labelWarningImage") as Image;
                const existInSystem = prequalResultData?.exist_in_carrier_system;
                const overallResult = prequalResultData?.overall_result;
                if (!StringUtil.equalsIgnoreCase(overallResult, "Pass") || !existInSystem) {
                    if (!existInSystem) {
                        const prequalErrorMessage = "This carrier does not exist in Highway's system, \npre-qualification unavailable.";
                        this.buildCarrierWarnings(tableRow, labelWarningImage, prequalErrorMessage, null);
                    } else {
                        const vendorUrl = prequalResultData?.vendor_url;
                        const prequalErrorMessage = "Highway overall rules assessment: " + overallResult;
                        this.buildCarrierWarnings(tableRow, labelWarningImage, prequalErrorMessage, vendorUrl);
                    }
                }
            } else if (prequalResultData !== null && prequalResultData?.aa_vendor) {
                const labelWarningImage = tableRow?.findComponentById("labelWarningImage") as Image;
                const existInSystem = prequalResultData?.exist_in_carrier_system;
                const riskAssessment = prequalResultData?.risk_assessment;
                if (!StringUtil.equalsIgnoreCase(riskAssessment, "Acceptable") || !existInSystem) {
                    if (!existInSystem) {
                        const prequalErrorMessage = "This carrier does not exist in Assure Advantage's system, \npre-qualification unavailable.";
                        this.buildCarrierWarnings(tableRow, labelWarningImage, prequalErrorMessage, null);
                    } else {
                        const vendorUrl = prequalResultData?.vendor_url;
                        const prequalErrorMessage = "Assure Advantage risk assessment: " + riskAssessment;
                        this.buildCarrierWarnings(tableRow, labelWarningImage, prequalErrorMessage, vendorUrl);
                    }
                    this.setCarrierDataFromPrequal(tableRow, prequalResultData);
                }
            } else if (prequalResultData !== null && prequalResultData?.rmis_vendor) {
                const labelWarningImage = tableRow?.findComponentById("labelWarningImage") as Image;
                const existInSystem = prequalResultData?.exist_in_carrier_system;
                const qualifiedCarrier = prequalResultData?.qualified_carrier;
                if (!qualifiedCarrier || !existInSystem) {
                    if (!existInSystem) {
                        const prequalErrorMessage = "This carrier does not exist in RMIS's system, \npre-qualification unavailable.";
                        this.buildCarrierWarnings(tableRow, labelWarningImage, prequalErrorMessage, null);
                    } else {
                        const vendorUrl = prequalResultData?.vendor_url;
                        const rmisDotOk = prequalResultData?.dot_ok;
                        const rmisInsuranceOk = prequalResultData?.insurance_ok;
                        this.buildRmisCarrierWarnings(tableRow, labelWarningImage, rmisDotOk, rmisInsuranceOk, vendorUrl);
                    }
                }
            }
        });
    }

    buildRmisCarrierWarnings(tableRow: TableRow, labelWarningImage: Image, rmisDotOk: any, rmisInsuranceOk: any, vendorUrl: any) {
        if (labelWarningImage !== undefined && (!StringUtil.isEmptyString(rmisDotOk) || !StringUtil.isEmptyString(rmisInsuranceOk))) {
            labelWarningImage.visible = true;
            labelWarningImage.tooltipCallback = (baseTooltip: Component, originatingEvent): Component => {
                const tooltip = this.getPrequalValidationQuickInfoRmisLayout(baseTooltip, rmisDotOk, rmisInsuranceOk, vendorUrl);
                return labelWarningImage["_internalShowTooltip"](tooltip, originatingEvent);
            }
        }
    }

    getPrequalValidationQuickInfoRmisLayout(baseTooltip: Component, rmisDotOk: any, rmisInsuranceOk: any, vendorUrl: any) {
        let layout = null;
        layout = Layout.getLayout("lme/powerbroker/PrequalValidationWarningQuickInfo");
        layout.onLoad = () => {
            if (!StringUtil.isEmptyString(rmisDotOk)) {
                layout["labelDotOk"].visible = true;
                layout["labelDotOk"].caption = "RMIS DOT OK: " + rmisDotOk;
            }
            if (!StringUtil.isEmptyString(rmisInsuranceOk)) {
                layout["labelInsuranceOk"].visible = true;
                layout["labelInsuranceOk"].caption = "Insurance OK: " + rmisInsuranceOk;
            }
            if (!StringUtil.isEmptyString(vendorUrl)) {
                layout["vendorSiteBtn"].visible = true;
                layout["vendorSiteBtn"].link = vendorUrl;
            } else {
                layout["vendorSiteBtn"].visible = false;
            }
        };
        return layout;
    }

    validActiveVendor(): Promise<boolean> {
        return this.sourceCarrierInsCtrl.search().then(result => {
            if (result.modelRows[0] !== undefined) {
                return validateVendor(result.modelRows);
            }
            return false;
        });
    }


    buildCarrierWarnings(tableRow: TableRow, labelWarningImage, prequalErrorMessage: string, vendorUrl: string) {
        if (labelWarningImage !== undefined && !StringUtil.isEmptyString(prequalErrorMessage)) {
            labelWarningImage.visible = true;
            labelWarningImage.tooltipCallback = (baseTooltip: Component, originatingEvent): Component => {
                const tooltip = this.getPrequalValidationQuickInfoLayout(baseTooltip, prequalErrorMessage, vendorUrl);
                return labelWarningImage["_internalShowTooltip"](tooltip, originatingEvent);
            }
        }
    }

    sourceCarrierOfferAfterExecution(event) {
        this.offerUtil.sortOffers(OfferSource.QUICKOFFER);
    }
}


function validateVendor(data: ModelRow<any>[]): boolean {
    return StringUtil.equalsIgnoreCase(data[0].get("vendor_id"), "H")
        || StringUtil.equalsIgnoreCase(data[0].get("vendor_id"), "D")
        || StringUtil.equalsIgnoreCase(data[0].get("vendor_id"), "R")
        || StringUtil.equalsIgnoreCase(data[0].get("vendor_id"), "C");
}


