import { DataSource, Layout, Panel, SearchButton, Table, Textbox, } from "@mcleod/components";

export abstract class AutogenLayoutARInquiry extends Layout {
    panelSearch: Panel;
    panel_: Panel;
    searchbuttonSearch: SearchButton;
    sourceArinquiry: DataSource;
    tableUnpostedLoads: Table;
    textCashReceipts: Textbox;
    textCreditMemos: Textbox;
    textMiscBills: Textbox;
    textNetTotal: Textbox;
    textUnposted: Textbox;
    textboxOrdersCustomerId: Textbox;
}
