import { AutogenModelMmsMedia, AutogenRowModelMmsMedia } from "./autogen/AutogenModelMmsMedia";

export class ModelMmsMedia extends AutogenModelMmsMedia<RowModelMmsMedia> {
	constructor() {
		super(RowModelMmsMedia);
	}
}

export class RowModelMmsMedia extends AutogenRowModelMmsMedia {
}
