/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelEdiCarrier.ts                    *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/datafusion/edi-carrier";

export class AutogenModelEdiCarrier<RowType extends ModelRow> extends Endpoint<RowType, RowEdiCarrierFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowEdiCarrier extends ModelRow<RowEdiCarrierFields> {
    constructor(values?: Partial<RowEdiCarrierFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowEdiCarrierFields {
    alt_partner_id: string;
    carrier_id: string;
    carrier_value: string;
    company_id: string;
    direction: string;
    edi_carrier_id: string;
    id: string;
    partner_id: string;
    scac: string;
    transaction_set: string;
    transaction_type: string;
    version: string;
}

