/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelZones.ts                         *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/general/zones";

export class AutogenModelZones<RowType extends ModelRow> extends Endpoint<RowType, RowZonesFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowZones extends ModelRow<RowZonesFields> {
    constructor(values?: Partial<RowZonesFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowZonesFields {
    company_id: string;
    descr: string;
    id: string;
    zone_area_id: string;
    city_id: string;
}

