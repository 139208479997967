import { Component, Container, KeyHandler, Snackbar } from "@mcleod/components";
import { ArrayUtil, HorizontalAlignment, Keys, VerticalAlignment } from "@mcleod/core";
import { AbstractUIDesigner } from "./AbstractUIDesigner";
import { doDesignerAction, redoDesignerAction, undoDesignerAction } from "./UIDesignerActionHistory";
import { ActionPasteComponent } from "./actions/ActionPaste";
import { ActionSwitchAdjacentComponents } from "./actions/ActionSwitchAdjacentComponents";
import { McLeodTailor } from "./custom/McLeodTailor";

export function getDesignerKeyListeners(designer): KeyHandler[] {
    const result = [];
    result.push({ key: Keys.R, modifiers: { ctrlKey: true }, listener: () => toggleRowBreak(designer) });
    result.push({ key: Keys.C, modifiers: { ctrlKey: true }, listener: () => copySelected(designer, false) });
    result.push({ key: Keys.X, modifiers: { ctrlKey: true }, listener: () => copySelected(designer, true) });
    result.push({ key: Keys.V, modifiers: { ctrlKey: true }, listener: () => paste(designer) });
    result.push({ key: Keys.F, modifiers: { ctrlKey: true }, listener: () => toggleFillRow(designer) });
    result.push({ key: Keys.B, modifiers: { ctrlKey: true }, listener: () => toggleBold(designer) });
    result.push({ key: Keys.A, modifiers: { ctrlKey: true }, listener: () => toggleAlign(designer) });
    result.push({ key: Keys.ARROW_UP, modifiers: { ctrlKey: true }, listener: () => moveSelected(designer, -1) });
    result.push({ key: Keys.U, modifiers: { ctrlKey: true }, listener: () => moveSelected(designer, -1) });
    result.push({ key: Keys.ARROW_LEFT, modifiers: { ctrlKey: true }, listener: () => moveSelected(designer, -1) });
    result.push({ key: Keys.ARROW_RIGHT, modifiers: { ctrlKey: true }, listener: () => moveSelected(designer, 1) });
    result.push({ key: Keys.ARROW_DOWN, modifiers: { ctrlKey: true }, listener: () => moveSelected(designer, 1) });
    result.push({ key: Keys.J, modifiers: { ctrlKey: true }, listener: () => moveSelected(designer, 1) });
    result.push({ key: Keys.Z, modifiers: { ctrlKey: true }, listener: () => undoDesignerAction(designer) });
    result.push({ key: Keys.Y, modifiers: { ctrlKey: true }, listener: () => redoDesignerAction(designer) });
    result.push({ key: Keys.ARROW_DOWN, listener: () => selectSibling(designer, 1) });
    result.push({ key: Keys.ARROW_RIGHT, listener: () => selectSibling(designer, 1) });
    result.push({ key: Keys.ARROW_UP, listener: () => selectSibling(designer, -1) });
    result.push({ key: Keys.ARROW_LEFT, listener: () => selectSibling(designer, -1) });
    result.push({ key: Keys.C, modifiers: { ctrlKey: true, shiftKey: true }, listener: () => selectFirstChild(designer) });
    result.push({ key: Keys.EQUAL, modifiers: { shiftKey: true }, listener: () => changeFontSize(designer, 1) });
    result.push({ key: Keys.MINUS, listener: () => changeFontSize(designer, -1) });
    return result;
}

function toggleRowBreak(designer) {
    if (designer.selectedComponents == null)
        return;
    for (const comp of designer.selectedComponents) {
        const props = comp.getPropertyDefinitions();
        if (props.rowBreak != null)
            changeComponentProp(designer, comp, "rowBreak", comp.rowBreak == null ? false : !comp.rowBreak);
    }
    designer.displayProperties();
}

function toggleFillRow(designer) {
    if (designer.selectedComponents == null)
        return;
    for (const comp of designer.selectedComponents) {
        const props = comp.getPropertyDefinitions();
        if (props.fillRow != null)
            changeComponentProp(designer, comp, "fillRow", !comp.fillRow);
    }
    designer.displayProperties()
}

function toggleVerticalAlign(designer) {
    if (designer.selectedComponents == null)
        return;
    for (const comp of designer.selectedComponents) {
        const props = comp.getPropertyDefinitions();
        if (props.verticalAlign != null) {
            if (comp.verticalAlign == null || comp.verticalAlign === VerticalAlignment.TOP)
                changeComponentProp(designer, comp, "verticalAlign", VerticalAlignment.CENTER);
            else if (comp.verticalAlign === VerticalAlignment.CENTER)
                changeComponentProp(designer, comp, "verticalAlign", VerticalAlignment.BOTTOM);
            else
                changeComponentProp(designer, comp, "verticalAlign", VerticalAlignment.TOP);
        }
    }
    designer.displayProperties();
}

function toggleAlign(designer) {
    if (designer.selectedComponents == null)
        return;
    for (const comp of designer.selectedComponents) {
        const props = comp.getPropertyDefinitions();
        if (props.align != null) {
            if (comp.align == null || comp.align === HorizontalAlignment.LEFT)
                changeComponentProp(designer, comp, "align", HorizontalAlignment.CENTER);
            else if (comp.align === HorizontalAlignment.CENTER)
                changeComponentProp(designer, comp, "align", HorizontalAlignment.RIGHT);
            else
                changeComponentProp(designer, comp, "align", HorizontalAlignment.LEFT);
        }
    }
    designer.displayProperties();
}

function toggleBold(designer) {
    if (designer.selectedComponents == null)
        return;
    for (const comp of designer.selectedComponents)
        changeComponentProp(designer, comp, "fontBold", comp.fontBold == null ? true : !comp.fontBold);
    designer.displayProperties();
}

function selectSibling(designer, by) {
    const comp = designer.selectedComponents[0];
    if (comp != null && comp.parent.indexOf != null) {
        const index = comp.parent.indexOf(comp);
        const switchIndex = index + by;
        if (switchIndex >= 0 && switchIndex < comp.parent.components.length)
            designer.selectComponent(comp.parent.components[switchIndex]);
    }
}

function selectFirstChild(designer) {
    const comp = designer.selectedComponents[0];
    if (comp != null && ArrayUtil.isEmptyArray(comp.components) !== true)
        designer.selectComponent(comp.components[0]);
}

function moveSelected(designer, by) {
    for (const comp of designer.selectedComponents)
        doDesignerAction(designer, new ActionSwitchAdjacentComponents(comp, by));
}

function copySelected(designer, cut) {
    designer.copiedComponents = [];
    designer.cutting = false;
    // Need compare the copiedComponents to AbstractUIDesigner.designerTools
    // so we don't allow users to create components they don't have access to
    let allowedToCopyOrCut = true;
    if (designer instanceof McLeodTailor) {
        const allComponents = [];
        for (const comp of designer.selectedLayoutComponents) {
            allComponents.push(comp);
            if (comp instanceof Container)
                allComponents.push(...comp.getRecursiveChildren(false));
        }
        allowedToCopyOrCut = allComponents?.every(component => designer.hasDesignerToolAccessForComponent(component))
    }
    if (allowedToCopyOrCut) {
        designer.copiedComponents = [...designer.selectedLayoutComponents];
        designer.cutting = cut;
    }
}

function paste(designer) {
    if (designer.selectedLayoutComponents.length !== 1)
        Snackbar.showSnackbar("You need to select a single parent component to paste.");
    else if (designer.copiedComponents.length === 0)
        Snackbar.showSnackbar("No components have been copied to paste.");
    else {
        let parent = designer.selectedLayoutComponents[0];
        if (parent.add == null)
            parent = parent.parent;
        if (designer.canAddComponentToContainer(designer.copiedComponents[0], parent)) {
            doDesignerAction(designer, new ActionPasteComponent(designer.getActiveTab(), designer.copiedComponents, parent, designer.cutting));
            if (designer.cutting) {
                designer.cutting = false;
                designer.copiedComponents = [];
            }
        } else {
            Snackbar.showSnackbar("Unable to paste into selected parent component.");
        }
    }
}

function changeFontSize(designer, by) {
    for (const comp of designer.selectedComponents) {
        let size = comp.fontSize;
        if (size == null)
            size = "medium";
        const sizes = ["xxsmall", "xsmall", "small", "medium", "large", "xlarge", "xxlarge", "xxxlarge", "skyline", "billboard"];
        let index = sizes.indexOf(size);
        if (index < 0)
            index = 2;
        index += by;
        if (index >= 0 && index < sizes.length) {
            changeComponentProp(designer, comp, "fontSize", sizes[index]);
        }
    }
    designer.displayProperties();
}

function changeComponentProp(designer: AbstractUIDesigner, comp: Component, propName: string, newValue: any) {
    designer.executeChangePropAction(comp, propName, newValue, comp[propName], true);
}
