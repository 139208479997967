import { ClearButtonVisible, Panel, PanelProps, Textbox, Tree, TreeNode } from "@mcleod/components";
import { Api, Collection, MenuItem, ObjectUtil } from "@mcleod/core";

class MenuTreeNode extends TreeNode {
    layoutPath: string;
}

export class PanelOpenLayoutMenu extends Panel {
    menuData: MenuItem;
    nonMenuData: MenuItem;
    public tree: Tree<MenuTreeNode>;
    nodesById: Collection<MenuTreeNode> = {};
    textSearchMenu: Textbox;


    constructor(props?: Partial<PanelProps>) {
        super({ fillRow: true, fillHeight: true, minWidth: 400, minHeight: 400, ...props });
        this.textSearchMenu = new Textbox({ placeholder: "Search", captionVisible: false, padding: 0, fillRow: true, marginBottom: 8, clearButtonVisible: ClearButtonVisible.YES })

        this.textSearchMenu.addChangeListener(() => this.populateTree())
        this.tree = new Tree<MenuTreeNode>({ fillHeight: true, fillRow: true, borderWidth: 1, borderRadius: 4, borderColor: "strokeSecondary", nodeLeafImageName: "form" });
        this.add(this.textSearchMenu);
        this.add(this.tree);
        Api.search("layout/menu")
            .then(menuData => {
                const response = menuData?.data?.[0];
                if (response == null)
                    return;
                this.menuData = response.menu;
                this.nonMenuData = response.non_menu;
                this.populateTree();
            });
    }


    populateTree() {
        const filter = this.textSearchMenu.text.toLowerCase();
        const rootChildren = [
            this.createHeaderNode("Menu items", this.menuData.items, filter, false),
            this.createHeaderNode("Non-menu items", this.nonMenuData.items, filter, false),
        ];
        for (let i = rootChildren.length - 1; i >= 0; i--)
            if (rootChildren[i].getChildCount() === 0)
                rootChildren.splice(i, 1);
        this.tree.getRootNode().setChildren(rootChildren);
        if (filter.length > 0)
            this.tree.getRootNode().expandAll();
        this.tree.selectedNode = null;
    }

    private createHeaderNode(caption: string, items: MenuItem[], filter: string, isAction: boolean) {
        let filtered = items;
        if (filter?.length > 0) {
            filtered = ObjectUtil.deepCopy(filtered);
            filtered = ObjectUtil.filterRecursiveArrayContainingString<MenuItem>(filtered, "items", "caption", filter);
        }
        const result = new MenuTreeNode({ caption });
        for (const item of filtered)
            this.addTreeNode(result, item, isAction);
        result.removeChildrenMatching(node => node.expandedImageName != null && node.getChildCount() === 0);
        return result;
    }


    private addTreeNode(parent: TreeNode, item: MenuItem, isAction: boolean) {
        if (item.items == null) {
            const node = this.createMenuNode(item, isAction);
            parent.addChild(node);
        }
        else {
            const node = new TreeNode({ caption: item.caption, expandedImageName: "minusInBox", collapsedImageName: "addInBox", allowSelect: false });
            parent.addChild(node);
            for (const child of item.items)
                this.addTreeNode(node, child, isAction);
        }
    }

    private createMenuNode(item: any, isAction: boolean): TreeNode {
        const result = new MenuTreeNode({ caption: item.caption });
        result.imageName = item.image;
        result.layoutPath = item.path;
        return result;
    }
}
