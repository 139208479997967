/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelBrokerageMovements.ts            *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/powerbroker/brokerage_movements";

export class AutogenModelBrokerageMovements<RowType extends ModelRow> extends Endpoint<RowType, RowBrokerageMovementsFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowBrokerageMovements extends ModelRow<RowBrokerageMovementsFields> {
    constructor() {
        super(AutogenUserSavedSearchEndpoint);
    }
}

export interface RowBrokerageMovementsFields {
    brk_qual_profile: string;
    brk_qual_status: string;
    broke_avail_type: string;
    broke_avail_zones: string;
    broke_carrier: string;
    broke_days: number;
    broke_perform_rate: string;
    broke_safety_rate: string;
    broke_team_drs: string;
    broke_trlr_type: string;
    brokerage_status: string;
    carrier_cont_type: string;
    commodity: string;
    company_id: string;
    contract_signed: string;
    customer: string;
    default_dest_filter: string;
    default_origin_filter: string;
    default_tabsetting: string;
    default_view: string;
    destination_city: string;
    destination_early: string;
    destination_late: string;
    destination_state: string;
    dispatcher: string;
    dispatcher_user_id: string;
    display_until_ready_to_bill: string;
    distance: string;
    equipment_type: string;
    exact_lane_type_search: string;
    freight_charge: string;
    id: string;
    insurance_current: string;
    int_search_profile_id: string;
    loadboard: string;
    minimum_score: number;
    move_status: string;
    movement_id: string;
    movement_type: string;
    on_hold: string;
    operations_user: string;
    ops_status: string;
    order_customers: string;
    order_days: number;
    order_gridconfig: string;
    order_ib_type: string;
    order_ib_zones: string;
    order_id: string;
    order_ob_type: string;
    order_ob_zones: string;
    order_resp_codes: string;
    order_resp_level: number;
    order_salesperson: string;
    order_status: string;
    order_trlr_type: string;
    order_type: string;
    origin_city: string;
    origin_early: string;
    origin_late: string;
    origin_state: string;
    override_payee_id: string;
    performance_rating: string;
    planning_comment: string;
    preass_order: string;
    progress_move: string;
    revenue_code: string;
    safety_rating: string;
    salesperson: string;
    show_broker_moves: string;
    show_exp_trk: string;
    show_ltl_orders: string;
    show_outside_tract: string;
    start_day: string;
    stops: string;
    title: string;
    tractor_gridconfig: string;
    tractor_resp_codes: string;
    tractor_resp_level: number;
    tractr_salesperson: string;
    weight: string;
}

