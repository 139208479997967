import { FileUtil } from "@mcleod/core";
import { Label, Snackbar } from "../..";
import { Overlay } from "../../page/Overlay";
import { Button } from "../button/Button";
import { ButtonVariant } from "../button/ButtonVariant";
import { Image } from "../image/Image";
import { ImageType } from "../image/ImageType";
import { Panel } from "../panel/Panel";
import { PanelProps } from "../panel/PanelProps";

export interface ImageViewerProps extends PanelProps {
  imageBase64: string;
  downloadFileName: string;
  title: string;
}

export class ImageViewer extends Panel {
  private _imageBase64: string;
  private _panelHeader: Panel;
  private _labelTitle: Label;
  private _buttonDownload: Button;
  private _buttonClose: Button;
  private _image: Image;
  public downloadFileName: string;
  _overlay: any;

  constructor(props?: Partial<ImageViewerProps>) {
    super(props, false);
    this._panelHeader = new Panel({ fillRow: true, backgroundColor: "background3", color: "#ddd" });
    this._labelTitle = new Label({ fontSize: "xlarge", fontBold: true, paddingRight: 12, fillRow: true, rowBreak: false });
    this._buttonDownload = new Button({ variant: ButtonVariant.round, tooltip: "Download this image", imageName: "download", rowBreak: false })
    this._buttonDownload.addClickListener(event => {
      FileUtil.downloadBase64AsFile(this.imageBase64, this.downloadFileName);
      Snackbar.showSnackbar("Downloaded " + this.downloadFileName);
    });
    this._buttonClose = new Button({ variant: ButtonVariant.round, tooltip: "Close this image preview", imageName: "x" })
    this._buttonClose.addClickListener(event => Overlay.hideOverlay(this._overlay));
    this._panelHeader.add(this._labelTitle, this._buttonDownload, this._buttonClose);
    this._image = new Image({ imageType: ImageType.IMG, fillRow: true, fillHeight: true, preserveAspectRatio: true });
    this.add(this._panelHeader, this._image);
    this.setProps({ padding: 0, ...props });
  }

  setProps(props: Partial<ImageViewerProps>) {
    super.setProps(props);
  }

  get imageBase64(): string {
    return this._imageBase64;
  }

  set imageBase64(value: string) {
    this._imageBase64 = value;
    this._image.imageBytes = value;
  }

  get title(): string {
    return this._labelTitle.caption;
  }

  set title(value: string) {
    this._labelTitle.caption = value;
  }

  showAsOverlay() {
      this._overlay = Overlay.showInOverlay(this, {
        style: {
          width: "96%",
          height: "96%",
          position: "absolute",
          left: "2%",
          top: "2%",
          backgroundColor: "#eee",
          borderRadius: "4px"
        }
      });
  }


  showSmsImageAsOverlay() {
    this._overlay = Overlay.showInOverlay(this, {
      style: {
        width: "50%",
        height: "50%",
        position: "fixed", // Use fixed position
        top: "50%",         // Center vertically
        left: "50%",        // Center horizontally
        transform: "translate(-50%, -50%)",  // Center the overlay
        backgroundColor: "#eee",
        borderRadius: "10px",
        overflowY: "auto"
      }
    });
  }


  public static eligibleToView(fileName: string) {
    return fileName.endsWith(".jpg") || fileName.endsWith(".png") || fileName.endsWith(".gif");
  }

  public static viewImage(imageBase64: string, downloadFileName: string) {
    const viewer = new ImageViewer({ width: "100%", height: "100%", imageBase64: imageBase64, borderShadow: true, downloadFileName: downloadFileName, title: downloadFileName });
    viewer.showAsOverlay();
  }

  public static smsImageViewer(imageBase64: string, downloadFileName: string) {
    const screenSize = Math.min(window.innerWidth, window.innerHeight);
    const viewer = new ImageViewer({
      width: "100%", // Full-screen width
      height: "100%", // Full-screen height
      imageBase64: imageBase64,
      borderShadow: true,
      downloadFileName: downloadFileName,
      title: downloadFileName
    });
    viewer.showSmsImageAsOverlay();
  }

}
