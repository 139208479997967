import { Checkbox } from "@mcleod/components/src/components/checkbox/Checkbox";
import { Label } from "@mcleod/components/src/components/label/Label";
import { TableRow } from "@mcleod/components/src/components/table/TableRow";
import { DataSourceMode } from "@mcleod/components/src/databinding/DataSource";
import { TableRowDisplayEvent } from "@mcleod/components/src/events/TableRowDisplayEvent";
import { ModelRow } from "@mcleod/core/src/ModelRow";
import { AutogenLayoutOtherChargeChangeCompareItem } from "./autogen/AutogenLayoutOtherChargeChangeCompareItem";

export class OtherChargeChangeCompareItem extends AutogenLayoutOtherChargeChangeCompareItem {

    private _compareItems: ModelRow[];

    override onLoad(): void | Promise<void> {

        this.tableChangeCompareItems.dataSource;
        this.tableChangeCompareItems.dataSource = this.sourceEdiCompareItem;
        this.tableChangeCompareItems.dataSource.data = [];
        const rows = [];
        if (this._compareItems != null)
            this.tableChangeCompareItems.dataSource.setRowsAndMode(DataSourceMode.NONE, this._compareItems);

        if (this._compareItems != null && this._compareItems.length == 0) {
            this.tableChangeCompareItems.visible = false;
        }

    }
    public async setupTableData(data: ModelRow[]) {
        this._compareItems = data;
    }

    onItemRowDisplay(event: TableRowDisplayEvent) {
        this.printCheckbox(event.getTableRow());
    }
    printCheckbox(row: TableRow) {
        const checkbox = row.findComponentById("checkboxApply") as Checkbox;
        checkbox.printable = false;
        const labelChecked = row.findComponentById("labelChecked") as Label;
        checkbox.addClickListener(() => labelChecked.visible = checkbox.checked);
    }

}