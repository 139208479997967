import { ComponentPropDefinitionUtil, ComponentPropDefinitions, ComponentProps } from "../../base/ComponentProps";
import { PropType } from "../../base/PropType";
import { layoutSelector } from "../textbox/TextboxProps";
import { Map } from "./Map";
import { PinPlotEvent } from "./PinPlotEvent";

export enum MapVendor {
    GOOGLE = "google",
    TRIMBLE = "trimble",
    NONE = "none",
    DEFAULT_DISTANCE_VENDOR = "default_dist_vendor",
    DEFAULT_FINDNEAR_VENDOR = "default_findnear_vendor",
    DEFAULT_SINGLE_POINT_VENDOR = "default_single_point_vendor",
    DEFAULT_MOVEMENT_MAP_VENDOR = "default_movement_map_vendor"
}

export type PinPlotEventType = string | ((event: PinPlotEvent) => void);

export interface MapProps extends ComponentProps {
    immediatelyActivateMap: boolean;
    latitudeField: string;
    longitudeField: string;
    pinLayout: string;
    onPlotPin?: PinPlotEventType;
    singleRecord: boolean;
    vendor: MapVendor;
}

export interface VendorMapProps extends ComponentProps {
    parentMap: Map;
}

let mapPropDefs: ComponentPropDefinitions;

export class MapPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (mapPropDefs == null) {
            mapPropDefs = {
                ...ComponentPropDefinitionUtil.getComponentPropertyDefinitions(),
                immediatelyActivateMap: { type: PropType.bool, defaultValue: false, description: "Use this option to immediately activate the map.  This provides the ability to activate a specific map, regardless of the overall 'always show maps' setting." },
                latitudeField: { type: PropType.string, description: "This specifies the field name from the DataSource that will be used as the latitude for plotted pins." },
                longitudeField: { type: PropType.string, description: "This specifies the field name from the DataSource that will be used as the latitude for plotted pins." },
                onPlotPin: { type: PropType.event, category: "Events", addListenerMethod: "addPinPlotListener", removeListenerMethod: "removePinPlotListener", description: "This event will fire when a pin is plotted on the map.  It is often used to set special properties of each pin that are not settable at the Map level." },
                pinLayout: { type: PropType.string, editor: layoutSelector, description: "This specifies the name of the layout that will be used to display detail about pins.  This is just a default and can be set on a pin-by-pin basis using the onPlotPin event if desired." },
                singleRecord: { type: PropType.bool, description: "This specifies whether we want to plot a pin for the DataSource's active row or whether we want to plot the pins for all the DataSource's rows." },
                vendor: { type: PropType.string, items: Object.values(MapVendor), defaultValue: MapVendor.GOOGLE, description: "This specifies which mapping vendor will be used when mapping data.  This must be known at the time of the map's construction, and will not have any effect if changed after initial map construction.", }
            };
            mapPropDefs.padding.defaultValue = 0;
        }
        return mapPropDefs;
    }
}
