import { TenderGridLayout } from "../TenderGridLayout";
import { Button, DataSource, HorizontalSpacer, Label, Layout, Panel, Switch, Tab, Tabset, Textbox } from "@mcleod/components";
export abstract class AutogenLayoutLoadTenderExpress extends Layout {
    buttonAccept: Button;
    buttonDecline: Button;
    buttonExclude: Button;
    buttonFilter: Button;
    buttonRefresh: Button;
    horizontalspacer1: HorizontalSpacer;
    horizontalspacer2: HorizontalSpacer;
    labelLastRefresh: Label;
    labelLastRefreshDateTime: Label;
    labelPageTitle: Label;
    layoutAll: TenderGridLayout;
    layoutCancel: TenderGridLayout;
    layoutChange: TenderGridLayout;
    layoutOriginal: TenderGridLayout;
    ltxPanel: Panel;
    ltxProfileTextbox: Textbox;
    panelLastRefresh: Panel;
    sourceEdiStop: DataSource;
    sourceLoadTenderExpress: DataSource;
    switchRefresh: Switch;
    tabAll: Tab;
    tabCancel: Tab;
    tabChange: Tab;
    tabOriginal: Tab;
    tabset1: Tabset;
}
