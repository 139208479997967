/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelLocationLookup.ts                *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/dispatch/location-lookup";

export class AutogenModelLocationLookup<RowType extends ModelRow> extends Endpoint<RowType, RowLocationLookupFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowLocationLookup extends ModelRow<RowLocationLookupFields> {
    constructor() {
        super(AutogenUserSavedSearchEndpoint);
    }
}

export interface RowLocationLookupFields {
    address1: string;
    address2: string;
    appt_required: string;
    assigned_to: string;
    bseg: string;
    category: string;
    cbsa_port_id: string;
    chassis_owner: string;
    chassis_pool: string;
    city_id: number;
    city_name: string;
    class_rqmt: string;
    comments: string;
    company_id: string;
    container_pool: string;
    container_vendor: string;
    customer_id: string;
    days_between_calls: number;
    ded_rqmt_type: string;
    ded_type: string;
    def_commodity_desc: string;
    def_commodity_id: string;
    directions: string;
    disable_loc_rating: string;
    dl_gen_cost: number;
    dl_gen_repower: number;
    dl_haz_cost: number;
    dl_haz_repower: number;
    dl_hvl_cost: number;
    dl_hvl_repower: number;
    dl_load_time: number;
    dl_svc_cost: number;
    dl_svc_repower: number;
    dl_unload_time: number;
    driver_load_id: string;
    driver_unload_id: string;
    ds_capacity_cost: number;
    ds_model_flag: string;
    extra_handling_time: number;
    filer_code: string;
    foreign_port: string;
    friday_close: Date;
    friday_open: Date;
    fuel_price_region: string;
    google_place_id: string;
    id: string;
    include_xref_trailers: string;
    insur_amt_rqrd: number;
    insur_amt_rqrd_c: string;
    insur_amt_rqrd_d: Date;
    insur_amt_rqrd_n: number;
    insur_amt_rqrd_r: number;
    is_active: string;
    is_consignee: string;
    is_customer: string;
    is_customs_broker: string;
    is_dist_center: string;
    is_dl_gen: string;
    is_dl_haz: string;
    is_dl_hvl: string;
    is_dl_svc: string;
    is_drop_yard: string;
    is_geocoded: string;
    is_outside_terminal: string;
    is_prospect: string;
    is_remote_terminal: string;
    is_shipper: string;
    is_steamship: string;
    is_terminal: string;
    is_trailer_pool: string;
    is_trailer_wash: string;
    is_truckstop: string;
    last_used_date: Date;
    latitude: number;
    layover: string;
    load_unload_count: number;
    load_unload_excl: string;
    load_unload_param: string;
    loading_instructs: string;
    loading_time: number;
    longitude: number;
    ltl_optimize_time_per_spot: number;
    ltl_seg_alloc_code: string;
    manual_exchange: string;
    max_drvr_complaint: number;
    max_pallet_balance: number;
    model_flag: string;
    monday_close: Date;
    monday_open: Date;
    name: string;
    need_clean_trailer: string;
    next_call_date: Date;
    no_stop_miles: number;
    on_duty_not_driving_mins: number;
    pallet_balance: number;
    pallets_required: string;
    plc_uid: number;
    pnn_city_id: number;
    pnn_city_name: string;
    pnn_state: string;
    pnn_zip_code: string;
    port_id: string;
    prev_code: string;
    primary_agency: string;
    radius: number;
    regulated: string;
    reload_rqmt: string;
    reserve_mins: number;
    salesperson: string;
    saturday_close: Date;
    saturday_open: Date;
    shuttle_capacity: string;
    state: string;
    sunday_close: Date;
    sunday_open: Date;
    swap_cost: number;
    team_rqmt: string;
    thursday_close: Date;
    thursday_open: Date;
    trailer_pool_min: number;
    trailer_pool_radius: number;
    trailer_pool_size: number;
    trailer_wash_vend: string;
    trip_starter: string;
    tuesday_close: Date;
    tuesday_open: Date;
    tw_seg_code: string;
    unload_instructs: string;
    unloading_time: number;
    website_url: string;
    wednesday_close: Date;
    wednesday_open: Date;
    zip_code: string;
}

