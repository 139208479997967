import { BorderType, Button, ButtonVariant, Image, Label, Overlay, OverlayedList, Panel, Switch, Textbox } from "@mcleod/components";
import { Alignment, HorizontalAlignment, VerticalAlignment } from "@mcleod/core";
import { Cardinality, JoinType, ModelTableDefinition } from "./ModelDesignerTypes";
import { PanelModelTable } from "./PanelModelTable";

const joinDefWidth = 260;

export class PanelModelTableConnector extends Panel {
    parentTable: PanelModelTable;
    childTable: PanelModelTable;
    horizConnector: Panel;
    vConnector: Panel;
    topConnector: Panel;
    panelJoinCondition: Panel;
    bottomConnector: Panel;
    horizParentLeftConnector: Panel;
    horizParentRightConnector: Panel;
    switchCardinality: Switch;
    buttonPredefinedConditions: Button;
    joinConditionParentField: Textbox;
    joinConditionChildField: Textbox;
    joinConditionEditingIndex: number;
    joinConditionField: Textbox;
    switchOuterJoin: Switch;
    private _hasRightSibling: boolean;
    private _hasLeftSibling: boolean;

    constructor(parentTable: PanelModelTable, childTable: PanelModelTable) {
        super();
        this.parentTable = parentTable;
        this.childTable = childTable;
        this.horizConnector = new Panel({ isRow: true, padding: 0, height: 16, width: "100%" });
        this.vConnector = new Panel({ padding: 0, align: HorizontalAlignment.CENTER });
        this.topConnector = new Panel({ borderLeftWidth: 2, padding: 0, height: 32, align: HorizontalAlignment.CENTER, borderLeftColor: "primary.darker" });
        this.panelJoinCondition = new Panel({
            borderWidth: 1, borderColor: "strokeSecondary", padding: 0, width: joinDefWidth, borderRadius: 4,
            borderLeftColor: "success.dark", backgroundColor: "defaultBackground", borderLeftWidth: 10
        });
        this.vConnector.add(this.topConnector);
        this.vConnector.add(this.panelJoinCondition);
        this.bottomConnector = new Panel({ borderLeftWidth: 2, padding: 0, height: 32, borderLeftColor: "primary.darker" });
        this.vConnector.add(this.bottomConnector);
        this.refreshView();
        const bothProps = { color: "primary.darker", padding: 0, fillRow: true, height: 16 };
        this.horizParentLeftConnector = new Panel({ ...bothProps, rowBreak: false });
        this.horizParentRightConnector = new Panel(bothProps);
        this.horizConnector.add(this.horizParentLeftConnector);
        this.horizConnector.add(this.horizParentRightConnector);
        childTable.add(this.horizConnector);
        childTable.add(this.vConnector);
        this.layoutParentConnector();
    }

    get def(): Partial<ModelTableDefinition> {
        return this.childTable.def;
    }

    refreshView() {
        this.panelJoinCondition.removeAll();
        if (this.def.joinConditions != null && this.def.joinConditions.length > 0) {
            for (let i = 0; i < this.def.joinConditions.length; i++) {
                // if this is our editing index, display the editor
                const join = this.def.joinConditions[i];
                const joinPanel = new Panel({ align: HorizontalAlignment.CENTER, fillRow: true, rowBreak: false });
                if (join.condition != null)
                    joinPanel.add(new Label({ caption: join.condition }));
                else {
                    joinPanel.add(new Label({ caption: join.parentField }));
                    joinPanel.add(new Image({ name: "pointer", rotation: 90, height: 20, width: 20, padding: 0, color: "success", fillRow: true }))
                    joinPanel.add(new Label({ caption: join.childField }));
                }
                this.panelJoinCondition.add(joinPanel);
                const absAnchor = new Panel({ padding: 0 });
                absAnchor.element.style.position = "relative";
                this.panelJoinCondition.add(absAnchor);
                const ellipsis = new Button({ imageName: "ellipsis", color: "subtle.darker", variant: ButtonVariant.round, left: -32 });
                ellipsis.addClickListener(event => this.showJoinEditOptions(event.target, i))
                ellipsis.element.style.position = "absolute";
                absAnchor.add(ellipsis);
                if (i < this.def.joinConditions.length - 1)
                    joinPanel.add(this.dividerPanel("and"));
            }
        }
        else {
            const buttonSetJoin = new Button({ caption: "Set join condition", color: "subtle.darker", imageName: "pencil", imageHeight: 16, imageWidth: 16, borderWidth: 0, padding: 4, fillRow: true });
            buttonSetJoin.addClickListener(event => this.addJoinConditionEditor(0));
            this.panelJoinCondition.add(buttonSetJoin);
        }
        this.layoutParentConnector();
    }

    showJoinEditOptions(anchor: Button, joinIndex: number) {
        let overlayedList: OverlayedList = null;
        const labelEdit = new Label({ caption: "Edit this condition", imageName: "pencil" });
        labelEdit.addClickListener(() => {
            Overlay.hideOverlay(overlayedList._overlay);
            this.addJoinConditionEditor(joinIndex)
        });
        const labelDelete = new Label({ caption: "Delete this condition", imageName: "delete" });
        labelDelete.addClickListener(() => {
            Overlay.hideOverlay(overlayedList._overlay);
            this.def.joinConditions.splice(joinIndex, 1);
            this.refreshView();
        });
        const labelAdd = new Label({ caption: "Add a new join condition", imageName: "add" });
        labelAdd.addClickListener(() => {
            Overlay.hideOverlay(overlayedList._overlay);
            this.addJoinConditionEditor(this.def.joinConditions.length)
        });
        const options = [labelEdit, labelDelete, labelAdd];
        overlayedList = Overlay.showDropdown(anchor, options, null, { width: null, color: "subtle.darker" });
    }

    getPredefinedConditions() {
        const parentName = this.parentTable.def.tableName;
        const childName = this.childTable.def.tableName;
        const childAlias = this.childTable.def.alias || childName;
        const result = [];
        if (childName != null && parentName != null) {
            for (const field of this.parentTable.availableFields)
                if (field.master_table === childName)
                    result.push({ parentField: parentName + "." + field.field_name, childField: childAlias + "." + field.master_field });
            for (const field of this.childTable.availableFields)
                if (field.master_table === this.parentTable.def.tableName)
                    result.push({ parentField: parentName + "." + field.master_field, childField: childAlias + "." + field.field_name });
        }
        return result;
    }

    showPredefinedConditions() {
        const predefinedConditions = this.getPredefinedConditions();
        const items = [];
        for (const cond of predefinedConditions) {
            const panel = new Panel({ verticalAlign: VerticalAlignment.CENTER });
            (panel as any).condition = cond;
            panel.add(new Label({ caption: cond.parentField, rowBreak: false }));
            panel.add(new Label({ caption: cond.childField, imageName: "pointer", imageColor: "success" }));
            items.push(panel);
        }
        if (items.length > 0)
            Overlay.showDropdown(this.buttonPredefinedConditions, items, (event) => {
                const selection = event.newSelection;
                this.def.joinConditions[this.joinConditionEditingIndex] = { parentField: selection.condition.parentField, childField: selection.condition.childField, condition: null };
                this.refreshView();
            }, { width: null });
    }

    addJoinConditionEditor(joinConditionEditingIndex: number) {
        this.joinConditionEditingIndex = joinConditionEditingIndex;
        const panel = this.panelJoinCondition;
        panel.removeAll();
        this.buttonPredefinedConditions = new Button({
            caption: "Select a suggested join",
            color: "subtle.light",
            borderWidth: 0,
            fontSize: "small",
            fillRow: true,
            imageName: "arrow",
            imageWidth: 12,
            imageHeight: 12,
            imageAlign: Alignment.RIGHT
        });
        this.buttonPredefinedConditions.addClickListener(event => this.showPredefinedConditions());
        panel.add(this.buttonPredefinedConditions);
        panel.add(this.dividerPanel("or"));

        this.joinConditionParentField = this.createConnectorFieldEditor("Select parent field");
        if (this.parentTable.availableFields != null)
            this.joinConditionParentField.items = this.parentTable.availableFields.map(field => field.field_name)
        const joinLabel = new Label({ imageName: "pointer", imageRotation: 90, imageHeight: 20, imageWidth: 20, padding: 0, color: "success", fillRow: true, align: HorizontalAlignment.CENTER })
        this.joinConditionChildField = this.createConnectorFieldEditor("Select child field");
        if (this.childTable.availableFields != null)
            this.joinConditionChildField.items = this.childTable.availableFields.map(field => field.field_name);
        panel.add(this.joinConditionParentField);
        panel.add(joinLabel);
        panel.add(this.joinConditionChildField);
        panel.add(this.dividerPanel("or"));

        this.joinConditionField = new Textbox({ placeholder: "Enter a join condition", captionVisible: false, warningLabelVisible: false, fontSize: "small", borderWidth: 0, fillRow: true });
        panel.add(this.joinConditionField);

        const options = new Panel({ fillRow: true, borderTopWidth: 1, borderTopColor: "success", marginTop: 12 });
        this.switchOuterJoin = new Switch({ leftCaption: "Inner join", rightCaption: "Outer join", leftImageName: "designer/innerJoin", rightImageName: "designer/outerJoin", marginLeft: 8 });
        this.switchOuterJoin.addChangeListener(event => this.joinTypeChanged(event.newValue));
        this.switchCardinality = new Switch({ leftCaption: "One-to-one", rightCaption: "One-to-many", leftImageName: "pointer", leftImageProps: { rotation: 90 }, rightImageName: "hierarchy", rightImageProps: { rotation: 90 } });
        this.switchCardinality.addChangeListener(event => this.cardinalityChanged(event.newValue));
        options.add(this.switchOuterJoin);
        options.add(this.switchCardinality);
        panel.add(options);

        const footer = new Panel({ align: HorizontalAlignment.RIGHT, fillRow: true, borderTopWidth: 1, borderTopColor: "success", marginTop: 12 });
        const buttonClose = new Button({ imageName: "x", color: "subtle.darker", variant: ButtonVariant.round, rowBreak: false });
        buttonClose.addClickListener(event => this.refreshView());
        footer.add(buttonClose);
        const buttonAccept = new Button({ imageName: "circleCheck", color: "success", variant: ButtonVariant.round });
        buttonAccept.addClickListener(event => {
            if (this.joinConditionParentField.text.length > 0 && this.joinConditionChildField.text.length > 0) {
                this.def.joinConditions[this.joinConditionEditingIndex] = { parentField: this.joinConditionParentField.text, childField: this.joinConditionChildField.text, condition: null };
                this.refreshView();
            }
            else if (this.joinConditionField.text.length > 0) {
                this.def.joinConditions[this.joinConditionEditingIndex] = { parentField: null, childField: null, condition: this.joinConditionField.text };
                this.refreshView();
            }
            else
                this.showTooltip("Please use on of the methods provided to enter a join condition.");
        });
        footer.add(buttonAccept);
        panel.add(footer);
        this.displayDefValues();
    }

    populateChildFields() {
        if (this.childTable.availableFields != null)
            this.joinConditionChildField.items = this.childTable.availableFields.map(field => field.field_name);
    }

    displayDefValues() {
        const condition = this.def.joinConditions[this.joinConditionEditingIndex];
        if (condition == null)
            return;
        if (condition.parentField != null)
            this.joinConditionParentField.text = condition.parentField;
        if (condition.childField != null)
            this.joinConditionChildField.text = condition.childField;
        if (condition.condition != null)
            this.joinConditionField.text = condition.condition;
        this.switchOuterJoin.checked = this.def.joinType !== JoinType.INNER;
        this.switchCardinality.checked = this.def.cardinality === Cardinality.MANY;
    }

    joinTypeChanged(value: JoinType) {
        this.def.joinType = value ? JoinType.OUTER : JoinType.INNER;
        this.layoutParentConnector();
    }

    cardinalityChanged(value: Cardinality) {
        this.def.cardinality = value ? Cardinality.MANY : Cardinality.ONE;
        this.layoutParentConnector();
    }

    dividerPanel(caption: string) {
        const result = new Panel({ fillRow: true, verticalAlign: VerticalAlignment.CENTER, color: "success.light" });
        result.add(new Panel({ fillRow: true, borderTopWidth: 1, borderTopColor: "success", rowBreak: false, paddingTop: 0, paddingBottom: 0 }));
        result.add(new Label({ caption: caption, rowBreak: false, fontBold: true, paddingTop: 0, paddingBottom: 0 }));
        result.add(new Panel({ fillRow: true, borderTopWidth: 1, borderTopColor: "success", rowBreak: false, paddingTop: 0, paddingBottom: 0 }));
        return result;
    }

    createConnectorFieldEditor(placeholder: string) {
        return new Textbox({ borderWidth: 0, captionVisible: false, warningLabelVisible: false, fontSize: "small", placeholder: placeholder, fillRow: true });
    }

    layoutParentConnector() {
        if (this.horizParentLeftConnector == null)
            return;
        this.horizParentLeftConnector.setProps({ borderRightWidth: 0, borderTopRightRadius: 0 });
        this.horizParentRightConnector.setProps({ borderLeftWidth: 0, borderTopLeftRadius: 0 });
        const borderType = this.getJoinBorderType();
        this.topConnector.borderLeftType = borderType;
        this.bottomConnector.borderLeftType = borderType;

        this.topConnector.removeAll();
        if (this.def.cardinality === "many") {
            const leftRight = {
                borderRightWidth: 2,
                borderRightType: borderType,
                borderLeftType: borderType,
                borderLeftWidth: 2,
                borderRightColor: "primary.darker",
                borderLeftColor: "primary.darker"
            };
            this.topConnector.setProps({
                width: 60,
                borderTopRightRadius: 4,
                borderTopLeftRadius: 4,
                borderTopWidth: 2,
                borderTopType: borderType,
                borderTopColor: "primary.darker",
                ...leftRight
            });
            this.topConnector.add(new Panel({ padding: 0, ...leftRight, width: 20, fillHeight: true }))
        }
        else {
            this.topConnector.setProps({
                width: null,
                borderRightWidth: 0,
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                borderLeftType: borderType,
                borderLeftColor: "primary.darker"
            })
        }


        if ((this.hasLeftSibling === this.hasRightSibling) || (!this.hasLeftSibling && !this.hasRightSibling))
            this.horizParentLeftConnector.borderRightWidth = 2;
        else if (this.hasLeftSibling) {
            this.horizParentLeftConnector.borderRightWidth = 2;
            this.horizParentLeftConnector.borderTopRightRadius = 10;
        }
        else if (this.hasRightSibling) {
            this.horizParentRightConnector.borderLeftWidth = 2;
            this.horizParentRightConnector.borderTopLeftRadius = 10;
        }
        this.horizParentLeftConnector.borderTopWidth = this.hasLeftSibling ? 2 : 0;
        this.horizParentRightConnector.borderTopWidth = this.hasRightSibling ? 2 : 0
    }

    getJoinBorderType(): BorderType {
        return this.def.joinType === "inner" ? BorderType.SOLID : BorderType.DASHED;
    }

    set hasLeftSibling(value: boolean) {
        this._hasLeftSibling = value;
        this.layoutParentConnector();
    }

    get hasLeftSibling(): boolean {
        return this._hasLeftSibling;
    }

    set hasRightSibling(value: boolean) {
        this._hasRightSibling = value;
        this.layoutParentConnector();
    }

    get hasRightSibling(): boolean {
        return this._hasRightSibling;
    }
}
