import { Button, Label, Layout, Panel, } from "@mcleod/components";

export abstract class AutogenLayoutDemoSlideInDialog extends Layout {
    111: Label;
    buttonSlideInBottom: Button;
    labelFromBottom: Label;
    panel1111: Panel;
    panel1: Panel;
    panel2: Panel;
    panel3: Panel;
    undefined1: Label;
    undefined: Label;
}
