import { ClickEvent, Component, DataSource, DataSourceMode, EditRowDecorator, Layout, TableRow, TableRowMode } from "@mcleod/components";
import { AutogenLayoutEdiStopShowAsButton } from "./autogen/AutogenLayoutEdiStopShowAsButton";
import { getDispatchControlValue } from "@mcleod/general/src/models/ModelDispatchControl";
import { EdiStopShowasQuickInfo } from "./EdiStopShowasQuickInfo";
import { EdiStopShowAs } from "./EdiStopShowAs";
import { EdiStop } from "./EdiStop";
import { ModelRow, StringUtil } from "@mcleod/core";
import { RowEdiStop } from "./models/ModelEdiStop";

enum ShowAsOrderType {
    EDI_STOP = "lme/datafusion/edi-stop"
}

type EdiStopLayout = EdiStop;

export class EdiStopShowAsButton extends AutogenLayoutEdiStopShowAsButton {
    private _orderSource: DataSource;
    private _ediStopRow: RowEdiStop;

    public get orderSource(): DataSource {
        return this._orderSource;
    }

    public set orderSource(value: DataSource) {
        this._orderSource = value;
    }

    public get ediStopRow(): RowEdiStop {
        return this._ediStopRow;
    }

    public set ediStopRow(value: RowEdiStop) {
        this._ediStopRow = value;
        if (value != null) {
            if (this.showAsInfoIsSet()) this.imageShowAs.color = "primary";
        }
    }

    onLoad() {
        this.imageShowAs.visible = getDispatchControlValue("showas_location") === 'Y'
        this.imageShowAs.tooltipCallback = this.getShowasToolTip();
    }

    getShowasToolTip() {
        return (baseTooltip: Component): Component => {
            const stopRow = TableRow.getContainingTableRow(this.imageShowAs).data;
            if (stopRow.get("showas_location_id") != null || stopRow.get("showas_location_name") != null || stopRow.get("showas_address") != null || stopRow.get("showas_city_name") != null) {
                const layout = Layout.getLayout("lme/dispatch/StopShowasQuickInfo") as EdiStopShowasQuickInfo;
                layout.onLoad = () => {
                    layout.mainDataSource.setRowsAndMode(DataSourceMode.NONE, [stopRow]);
                    layout.mainDataSource.displayDataInBoundComponents();
                }
                return this.imageShowAs.showTooltip(layout, null, { themeKey: "quickInfo", color: null, fillRow: true });
            }
            else {
                return this.imageShowAs.showTooltip(baseTooltip, null, {});
            }
        };
    }

    imageShowAsOnClick(event: ClickEvent) {
        const tableRow = TableRow.getContainingTableRow(event.target as Component);
        if (tableRow?.mode === TableRowMode.ADD || tableRow?.mode === TableRowMode.UPDATE) {
            const changesValid = tableRow.saveChanges();
            if (changesValid !== true) return;
        }
        if (tableRow != null) {
            const layout: EdiStopShowAs = Layout.getLayout("lme/datafusion/EdiStopShowAs") as EdiStopShowAs;
            layout.customerId = this.orderSource.activeRow.get("customer_id");
            const stopData = tableRow.data.createBasicCopy();
            const erd = new EditRowDecorator({
                title: `Stop Show As Details`,
                layout: layout,
                data: stopData,
                width: "65%",
                layoutLoadListeners: () => {
                    layout.tabStop.removeAll(); // remove the devInfo label
                    // inject the correct stop layout based on the table row's model path and initialize it
                    let stopLayout: EdiStopLayout;
                    switch (tableRow.data._modelPath) {
                        case ShowAsOrderType.EDI_STOP:
                            stopLayout = Layout.getLayout("lme/datafusion/EdiStop") as EdiStop;
                            break;
                    }
                    const enabledTextbox = StringUtil.isEmptyString(this.orderSource.activeRow?.get("order_id")) && ("Y" === this.orderSource.activeRow?.get("original_order"));
                    layout.tabStop.add(stopLayout)
                    layout.tabShowAs.forEveryChildComponent(comp =>
                        comp.displayData(stopData, null, 0)
                    )
                    layout.textboxShowasAddress.enabled = enabledTextbox;
                    layout.textboxShowasAddress2.enabled = enabledTextbox;
                    layout.locationShowas.enabled = enabledTextbox;
                    layout.textboxShowasLocationName.enabled = enabledTextbox;
                    layout.citystateShowAs.enabled = enabledTextbox;
                    stopLayout.addLayoutLoadListener(() => {
                        stopLayout.replaceMainDatasource(this.mainDataSource);
                        //Because there is a nested layout in the layout, the addLayout() in ERD does not set data
                        stopLayout.mainDataSource.setRowsAndMode(DataSourceMode.NONE, [stopData]);
                        stopLayout.initialize(true, tableRow.index, this.orderSource);
                        stopLayout.displayData(stopData, null, 0);
                        stopLayout.layoutStopShowAsButton.visible = false;
                        stopLayout.panelAdditionalDetails.visible = false;
                        stopLayout.textboxLocationId.enabled = enabledTextbox;
                        stopLayout.switchPickupConsignee.enabled = enabledTextbox;
                    })
                }, fillVerticalSpace: true, overlayProps: { closeOnClickOff: false, greyedBackground: true }, onSave: (updatedData: ModelRow | any) => {
                    layout.validateSimple();
                    tableRow.saveChangesFromEdit(updatedData);
                }, onClose: (cancelled: boolean) => {
                    this.showAsInfoIsSet() ? this.imageShowAs.color = "primary" : this.imageShowAs.color = "default";
                    tableRow.onSlideoutClose(erd, cancelled, false);
                }
            });
        }
    }

    showAsInfoIsSet(): boolean {
        return this.ediStopRow.get("showas_city_id") != null ||
            this.ediStopRow.get("showas_city_name") != null ||
            this.ediStopRow.get("showas_address") != null ||
            this.ediStopRow.get("showas_address2") != null ||
            this.ediStopRow.get("showas_location_id") != null ||
            this.ediStopRow.get("showas_location_name") != null ||
            this.ediStopRow.get("showas_state") != null ||
            this.ediStopRow.get("showas_zip_code") != null;
    }
}