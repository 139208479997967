import { ClickEvent, DataSourceMode } from "@mcleod/components";
import { Keys, getLogger } from "@mcleod/core";
import { AutogenLayoutDemoKeyHandlingSample } from "./autogen/AutogenLayoutDemoKeyHandlingSample";

const log = getLogger("component-demo/DemoKeyHandlingSample");

export class DemoKeyHandlingSample extends AutogenLayoutDemoKeyHandlingSample {
    override onLoad() {
        this.buttonSaveName.addKeyHandler({
            key: Keys.S,
            modifiers: { ctrlKey: true },
            listener: (event) => this._saveNameInfo(event),
            element: this.buttonSaveName._element,
            scope: this.panelNameInfo._element
        });
        this.buttonSaveName.addKeyHandler({
            key: Keys.ENTER,
            listener: (event) => this._saveNameInfo(event),
            element: this.buttonSaveName._element,
            scope: this.panelNameInfo._element
        });
        this.buttonSaveAddress.addKeyHandler({
            key: Keys.S,
            modifiers: { ctrlKey: true },
            listener: (event) => this._saveAddressInfo(event),
            element: this.buttonSaveAddress._element,
            scope: this.panelAddressInfo._element
        });
        this.buttonSaveAddress.addKeyHandler({
            key: Keys.ENTER,
            listener: (event) => this._saveAddressInfo(event),
            element: this.buttonSaveAddress._element,
            scope: this.panelAddressInfo._element
        });
        this.buttonSaveNumber.addKeyHandler({
            key: Keys.S,
            modifiers: { ctrlKey: true },
            listener: (event) => this._saveNumber(event),
            element: this.buttonSaveNumber._element,
            scope: this.panelNumber._element
        });
        this.buttonSaveNumber.addKeyHandler({
            key: Keys.ENTER,
            listener: (event) => this._saveNumber(event),
            element: this.buttonSaveNumber._element,
            scope: this.panelNumber._element
        });

        this.tableContact.dataSource.mode = DataSourceMode.UPDATE;
        this.tableContact.dataSource.search({ parent_row_type: "L", parent_row_id: "CG-H5" });
    }

    private _saveNameInfo(event: ClickEvent) {
        log.info("Saving name info: Event %o", event);
        this.labelSavedNameInfo.caption = this.textboxFirstName.text + " " + this.textboxLastName.text;
    }

    private _saveAddressInfo(event: ClickEvent) {
        log.info("Saving address info: Event %o", event);
        this.labelSavedAddressInfo.caption = this.textboxAddress.text + "  " + this.textboxCity.text + ", " + this.textboxState.text + " " + this.textboxZipCode.text;
    }

    private _saveNumber(event: ClickEvent) {
        log.info("Saving number: Event %o", event);
        this.labelSavedNumber.caption = this.textboxNumber.text;
    }

    getDemoPath() {
        return ["Concepts", "Key Handling Sample"];
    }
}
