import { Button, ButtonProps, ButtonVariant, Label, Layout, Panel, PanelProps, showTooltip } from "@mcleod/components";
import { Alignment, Clipboard, DynamicLoader, HorizontalAlignment, StringUtil } from "@mcleod/core";
import { SingleComponentDemo } from "./components/SingleComponentDemo";

// HEY YOU!  REFACTOR:
// move expand functionality to Panel.  It will be useful there.
// Move a property "line" to its own module to keep the same of this module down
// Correct the naming of examplePanel, examplePanel.example.  I didn't know how everything was going to be
//    laid out when I started, but we now have:
//        a PropertyLineItem
//        each PropertyLineItem has one or more "cases" that we want to demo
//        each case has a Panel that we either
//          display the simpler "value case" where we just specify the property value that we want to demo
//          display a "code case" where the demo specifies actual code (doesn't work yet)

// IMPROVE
// There needs to be a better visual separation and/or description between each case
// allow editing the code (should just work once we can execute code and change the Label to a multiline Input)
// do syntax highlighting of the code (probably would be useful elsewhere)

interface ExpandAllProps extends ButtonProps {
    expandAll: boolean;
}

export class ExpandAllButton extends Button {
    expandAll: boolean;

    constructor(props: Partial<ExpandAllProps>) {
        super(props);
    }
}

interface ExamplePanelProps extends PanelProps {
    example: SingleComponentDemo;
}

export class ExamplePanel extends Panel {
    example: SingleComponentDemo;

    constructor(props: Partial<ExamplePanelProps>) {
        super(props);
    }
}


export class ComponentDemo extends Layout {
    panelContent: Panel;
    componentPath: string;
    componentName: string;
    compClass: any;
    example: SingleComponentDemo;

    constructor({ ...props }) {
        super({ fillRow: true, align: HorizontalAlignment.CENTER, needsServerLayout: false, ...props });
        this.panelContent = new Panel({ fillRow: true });
        this.componentPath = props.componentPath;
        this.componentName = StringUtil.stringAfterLast(props.componentPath, "/");
        this.add(this.panelContent);
        this.panelContent.add(new Label({ caption: this.componentName + " API", fontSize: "xxlarge", fontBold: true, imageName: "designer/" + this.componentName.toLowerCase() }))
        const exampleClass = DynamicLoader.getClassForPath("component-demo/components/Demo" + this.componentName);
        if (exampleClass != null)
            this.example = new exampleClass();
        this.compClass = DynamicLoader.getClassForPath("components/components/" + this.componentPath);
        const comp = new this.compClass();
        const propDefs = comp.getPropertyDefinitions();
        const propNames = Object.keys(propDefs).sort();
        const localProps = [], compProps = [], databoundProps = [], otherProps = [];
        for (const propName of propNames) {
            const prop = propDefs[propName];
            if (prop.source == null)
                localProps.push(propName)
            else if (prop.source === "component")
                compProps.push(propName)
            else if (prop.source === "databound")
                databoundProps.push(propName)
            else if (prop.source === "component")
                otherProps.push(propName)
        }
        this.addSection("Properties/methods defined in " + this.componentName, localProps, propDefs);
        this.addSection("Properties/methods shared by all data-bound components", databoundProps, propDefs);
        this.addSection("Properties/methods defined externally", otherProps, propDefs);
        this.addSection("Properties/methods shared by all components", compProps, propDefs);
    }

    addSection(heading: string, propNames, props) {
        if (propNames.length == 0)
            return;
        const panelSection = new Panel({ fillRow: true });
        panelSection.add(new Label({ caption: heading, fontBold: true, fontSize: "large", color: "primary", marginTop: 32 }));
        this.addPropLine(panelSection, ["Name", "Type", "Default", "Description"], { fontSize: "large", fontBold: true }, null);
        for (const propName of propNames) {
            const prop = props[propName];
            this.addPropLine(panelSection, [propName, prop.type, prop.defaultValue, prop.description], {}, props);
        }
        this.panelContent.add(panelSection);
    }

    addPropLine(panelSection, columns, cellProps, prop) {
        const widths = [220, 100, 140, null];
        const panel = new Panel({ borderBottomWidth: 1, borderBottomColor: "strokeSecondary", fillRow: true });
        for (let i = 0; i < columns.length; i++) {
            const label = new Label({ caption: columns[i], width: widths[i], rowBreak: false, wrap: true, ...cellProps });
            if (widths[i] == null)
                label.fillRow = true;
            if (i === 0)
                label.fontBold = true;
            panel.add(label);
        }
        if (prop == null) { // this is the header line
            const expAllButton = new ExpandAllButton({ caption: "Expand all examples", expandAll: true, imageName: "arrow", fontSize: "small", width: 180, borderWidth: 0 });
            expAllButton.addClickListener(() => {
                for (const comp of panel.parent) {
                    const expPanel = this.getExpandablePanel(comp);
                    if (expPanel != null) {
                        if (expAllButton.expandAll) {
                            this.hideExamples(expPanel);
                            this.showExamples(expPanel);
                            expAllButton.caption = "Collapse all examples";
                        }
                        else {
                            this.hideExamples(expPanel);
                            expAllButton.caption = "Expand all examples";
                        }
                    }
                }
                expAllButton.expandAll = !expAllButton.expandAll;
            });
            panel.add(expAllButton);
        }
        else {
            const example = this.getExample(columns[0]);
            if (example != null) {
                this.addExampleComponents(example, panel);
            }
        }
        panelSection.add(panel);
    }

    getExpandablePanel(comp) {
        if (comp.getComponentCount != null && comp.getComponent(comp.getComponentCount() - 1).example != null)
            return comp.getComponent(comp.getComponentCount() - 1);
        return null;
    }

    addExampleComponents(example, panel) {
        const examplePanel = new ExamplePanel({ example: example, fillRow: true, borderColor: "strokeSecondary", color: "default", borderRadius: 4, marginLeft: 48, marginRight: 112, backgroundColor: "background2", padding: 0, height: 0, style: { overflow: "hidden" } });
        const buttonExample = new Button({ caption: "Examples", imageName: "arrow", fontSize: "small", borderWidth: 0 });
        panel.add(buttonExample);
        buttonExample.addClickListener(event => {
            if (examplePanel.getComponentCount() === 0)
                this.showExamples(examplePanel);
            else {
                examplePanel.setProps({ style: { transition: "height 200ms" }, padding: 0, borderWidth: 0, marginBottom: 0, borderShadow: false, height: 0 });
                examplePanel.removeAll();
            }
        });
        panel.add(examplePanel)
        panel.example = example;
    }

    getExample(propName) {
        if (this.example == null)
            return null;
        const result = this.example.examples[propName];
        if (result != null)
            result.propName = propName;
        return result;
    }

    hideExamples(examplePanel) {
        examplePanel.setProps({ style: { transition: "height 200ms" }, padding: 0, borderWidth: 0, marginBottom: 0, borderShadow: false, height: 0 });
        examplePanel.removeAll();
    }

    showExamples(examplePanel) {
        const padding = 8;
        examplePanel.setProps({ style: { transition: "height 400ms, padding 100ms" }, padding: padding, marginBottom: 12, borderWidth: 1, borderShadow: true });
        for (let i = 0; i < examplePanel.example.cases.length; i++) {
            const exampleCase = examplePanel.example.cases[i];
            if (exampleCase.description != null)
                examplePanel.add(new Label({ caption: exampleCase.description }));
            if (exampleCase.code != null)
                this.addCodeCase(examplePanel, exampleCase);
            else
                this.addValueCase(examplePanel, exampleCase);
            if (i < examplePanel.example.cases.length - 1)
                examplePanel.add(new Panel({ borderBottomWidth: 1, borderBottomColor: "strokeSecondary", marginRight: 48, marginLeft: 48, fillRow: true, marginBottom: 16 }));
        }
        const height = this.getNodeHeight(examplePanel.element);
        examplePanel.height = height + (padding * 2);
    }

    addValueCase(examplePanel, exampleCase) {
        const panel = new Panel({ fillRow: true });
        const varName = this.componentName.toLowerCase();
        const externalProps = this.combineProps(this.example.propsForAllExamples, examplePanel.example.props, exampleCase.props);
        if (externalProps != null)
            delete externalProps[examplePanel.example.propName];

        let code = "const " + varName + " = new " + this.componentName + "(" + this.getPropsSpecifier(varName, externalProps) + ");\n";
        if (exampleCase.value !== undefined)
            code += varName + "." + examplePanel.example.propName + " = " + quoteString(exampleCase.value) + ";"
        this.addCodeSnippet(panel, code);
        const livePanel = new Panel({
            fillRow: true,
            padding: 8,
            backgroundColor: "defaultBackground",
            color: "defaultColor",
            borderWidth: 1,
            borderColor: "strokeSecondary",
            align: HorizontalAlignment.CENTER,
            borderRadius: 4,
            borderShadow: true,
            margin: 16,
            marginTop: 0
        });
        const live = new this.compClass(externalProps);
        if (exampleCase.value !== undefined)
            live[examplePanel.example.propName] = exampleCase.value;
        livePanel.add(live);
        panel.add(new Label({ caption: "Result", fontBold: true, marginTop: 8 }));
        panel.add(livePanel);
        examplePanel.add(panel);
    }

    combineProps(...propList) {
        let result;
        for (const props of propList) {
            if (props != null) {
                if (result == null)
                    result = { ...props };
                else {
                    for (const key in props) {
                        const value = props[key];
                        if (value === undefined)
                            delete result[key];
                        else
                            result[key] = value;
                    }
                }
            }
        }
        return result;
    }

    getPropsSpecifier(varName, props) {
        let result = "";
        if (props != null) {
            const keys = Object.keys(props);
            for (let i = 0; i < keys.length; i++) {
                if (i === 0)
                    result += "{ ";
                result += keys[i] + ": " + quoteString(props[keys[i]]);
                if (i < keys.length - 1)
                    result += ", ";
                else
                    result += " }"
            }
        }
        return result;
    }

    addCodeCase(examplePanel, exampleCase) {
        let code = exampleCase.code.trim();
        const panel = new Panel({ fillRow: true });
        this.addCodeSnippet(panel, code);
        panel.add(new Label({ caption: "Result", fontBold: true, marginTop: 16 }));
        if (exampleCase.preCode != null)
            code = exampleCase.preCode + "\n" + code;
        if (exampleCase.postCode != null)
            code += "\n" + exampleCase.postCode;
        // best resources I have found for executing javascript with ES6 modules:
        // https://krasimirtsonev.com/blog/article/build-your-own-interactive-javascript-playground
        // https://2ality.com/2019/10/eval-via-import.html
        // const encodedJs = encodeURIComponent(js);
        // const dataUri = 'data:text/javascript;charset=utf-8,' + encodedJs;
        // import(dataUri);
        // panel.add(result of that execution);
        examplePanel.add(panel);
    }

    addCodeSnippet(panel, code) {
        panel.add(new Label({ caption: "Code", color: "default", fontBold: true, rowBreak: false }));
        const copyButton = new Button({ variant: ButtonVariant.round, imageName: "clipboard" });
        copyButton.addClickListener(event => {
            Clipboard.copyText(code);
            showTooltip(copyButton, "Code copied to clipboard", { shaking: true, timeout: 1200, position: Alignment.RIGHT });
        });
        panel.add(copyButton);
        panel.add(new Label({
            caption: code,
            color: "subtle.darker",
            backgroundColor: "background4",
            fontFamily: "courier, monospace",
            borderWidth: 1,
            borderRadius: 4,
            padding: 12,
            borderShadow: true,
            fillRow: true,
            marginRight: 16,
            marginLeft: 16
        }));
    }

    getDemoPath() {
        return ["Components", this.componentName];
    }

    getNodeHeight(node) {
        const clone = node.cloneNode(true);
        clone.style.cssText = "position:fixed; top:-9999px; opacity:0; height: auto";
        document.body.appendChild(clone);
        const height = clone.clientHeight;
        clone.parentNode.removeChild(clone);
        return height;
    }
}


function quoteString(value) {
    if (typeof value === "string")
        return '"' + value + '"';
    return value;
}
