import { Button, HorizontalSpacer, Layout, } from "@mcleod/components";

export abstract class AutogenLayoutDemoScreenFlowSampleButtons extends Layout {
    buttonClose: Button;
    buttonShowCustomToast: Button;
    buttonShowModalDialog: Button;
    buttonShowNonModalDialog: Button;
    buttonShowSimpleOverlay: Button;
    buttonShowSlideoutBottom: Button;
    buttonShowSlideoutLeft: Button;
    buttonShowSlideoutRight: Button;
    buttonShowSlideoutTop: Button;
    buttonShowSuccessToast: Button;
    buttonShowWarningSnackbar: Button;
    horizontalspacer1: HorizontalSpacer;
}
