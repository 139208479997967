import { Button, DataHeader, DataSource, HorizontalSpacer, Label, Layout, Location, Panel, SearchButton, Table, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutQuoteList extends Layout {
    buttonClear: Button;
    buttonSearchExpand: Button;
    dataheader1: DataHeader;
    horizontalspacer1: HorizontalSpacer;
    horizontalspacer2: HorizontalSpacer;
    horizontalspacer3: HorizontalSpacer;
    labelQuotes: Label;
    panelLeft: Panel;
    panelQuoteFields: Panel;
    panelQuoteListContainer: Panel;
    panelRight: Panel;
    panelSearch: Panel;
    panelSearchHeader: Panel;
    searchbuttonSearch: SearchButton;
    sourceQuoteOrder: DataSource;
    tableQuoteList: Table;
    textboxCustomerName: Textbox;
    textboxDeliveryDateRange: Textbox;
    textboxDestLocation: Location;
    textboxEffectiveDate: Textbox;
    textboxId: Textbox;
    textboxOrderTypeId: Textbox;
    textboxOrderedDate: Textbox;
    textboxOrderedMethod: Textbox;
    textboxOriginLocation: Location;
    textboxPickupDateRange: Textbox;
    textboxSalespersonId: Textbox;
}
