/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelQuotePostHist.ts                 *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/powerbroker/quote-post-hist";

export class AutogenModelQuotePostHist<RowType extends ModelRow> extends Endpoint<RowType, RowQuotePostHistFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowQuotePostHist extends ModelRow<RowQuotePostHistFields> {
    constructor(values?: Partial<RowQuotePostHistFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowQuotePostHistFields {
    attach_filename: string;
    attachment: string;
    carrier_id: string;
    comments: string;
    company_id: string;
    gps_accuracy: number;
    id: string;
    latitude: number;
    longitude: number;
    movement_id: string;
    order_id: string;
    parent_row_id: string;
    posted_by: string;
    posted_date: Date;
    posted_type: string;
    reason_code: string;
    system_added: string;
}

