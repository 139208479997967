import { DataSource, Layout, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutQuoteSendEmail extends Layout {
    panelHeaderInfo: Panel;
    sourceSendEmail: DataSource;
    textboxContactName: Textbox;
    textboxPlainText: Textbox;
    textboxSubject: Textbox;
    textboxTemplateNum: Textbox;
    textboxToAddress: Textbox;
}
