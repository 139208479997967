/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelDrsPayeeEquip.ts                 *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/dispatch/drs-payee-equip";

export class AutogenModelDrsPayeeEquip<RowType extends ModelRow> extends Endpoint<RowType, RowDrsPayeeEquipFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowDrsPayeeEquip extends ModelRow<RowDrsPayeeEquipFields> {
    constructor(values?: Partial<RowDrsPayeeEquipFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowDrsPayeeEquipFields {
    company_id: string;
    default_trailer: string;
    equipment_count: number;
    equipment_type_id: string;
    id: string;
    payee_id: string;
}

