import { Button, DataSource, Label, Layout } from "@mcleod/components";

export abstract class AutogenLayoutCarrierQualificationDetailsQuickInfo extends Layout {
    buttonButton1: Button;
    labelQualificationDetails: Label;
    labelStatus: Label;
    labelStatusCaption: Label;
    labelTitle: Label;
    sourceBrltlBillingFreightGroup: DataSource;
}
