import { ModelRow } from "@mcleod/core";
import { Component, DataSourceMode, TableRow } from "..";
import { TableRowMode } from "../components/table/TableRow";

export function getRelevantModelRow(component: Component): ModelRow {
    if (component.hasBoundField() !== true)
        return null;
    if (component.searchOnly === true) {
        const searchRow = component.dataSource?.searchRow;
        if (searchRow != null)
            return searchRow;
    }
    const activeRow = component.dataSource?.activeRow;
    if (activeRow != null)
        return activeRow;
    const tableRow: TableRow = TableRow.getContainingTableRow(component);
    if (tableRow != null)
        return tableRow.data as ModelRow;
    return null;
}

export function getCurrentDataSourceMode(component: Component): DataSourceMode {
    if (component.hasBoundField() !== true)
        return null;
    if (component.searchOnly === true)
        return DataSourceMode.SEARCH;
    if (component.dataSource?.activeRow != null)
        return component.dataSource.mode;
    const row: TableRow = TableRow.getContainingTableRow(component);
    if (row != null) {
        if (row.mode === TableRowMode.SEARCH)
            return DataSourceMode.SEARCH;
        if (row.data?._appending)
            return DataSourceMode.ADD;
        else
            return DataSourceMode.UPDATE;
    }
}