import { AutogenModelHazmatPlacard, AutogenRowHazmatPlacard } from "./autogen/AutogenModelHazmatPlacard";

export class ModelHazmatPlacard extends AutogenModelHazmatPlacard<RowHazmatPlacard> {
    constructor() {
        super(RowHazmatPlacard);
    }
}

export class RowHazmatPlacard extends AutogenRowHazmatPlacard {
}
