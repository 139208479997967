import {
    BlurEvent, Button, ClickEvent, DataSource, DataSourceExecutionEvent, DataSourceMode, DropdownItem, Switch, TableRow,
    TableRowDisplayEvent, Textbox
} from "@mcleod/components";
import { Alignment, Api, CurrencyUtil, ModelRow } from "@mcleod/core";
import { getDispatchControlBoolean } from "../../general/src/models/ModelDispatchControl";
import { MovementCarrierPay } from "../../powerbroker/src/MovementCarrierPay";
import { AutogenLayoutOrderRates } from "./autogen/AutogenLayoutOrderRates";
import { OtherChargesUtil } from "./OtherChargesUtil";
import { CommonTooltips } from "@mcleod/common";
import { RateUtil } from "./RateUtil";

export class OrderRates extends AutogenLayoutOrderRates {
    private _allocationEnabled: boolean;
    private _calculatingCharges = false;
    private chargePanelExpanded: boolean = true;
    private ratingDetailsPanelExpanded: boolean = false;
    private estimateFuelSurcharge: boolean = getDispatchControlBoolean("est_fuel_surcharge")
    private autoRate: boolean = getDispatchControlBoolean("auto_rate");
    private autoCalcMaxPay: boolean = getDispatchControlBoolean("auto_pay_rates");
    private otherChargesUtil: OtherChargesUtil = new OtherChargesUtil();
    doAfterRating: () => void;
    checkPickupAndDeliveryEntered = () => true;

    override onLoad(): void {
        this.textboxCollectionMethod.items = [{ caption: "Prepaid", value: "P" }, {
            caption: "Third party", value: "T"
        }, { caption: "Collect", value: "C" }];
        this.textboxCtrlParty.textCombined.manualAddLayout = null;
        this.buttonEstimateFuelSurcharge.visible = !this.estimateFuelSurcharge;
        this.otherChargesUtil.initialize(this);
        this.initializeRatingDetails();
    }

    sourceOrdersAfterExecution(event: DataSourceExecutionEvent) {
        RateUtil.setAutoRateContext(this, this.activeRow.get("rate_id"));
    }

    setMaxTargetPayData() {
        const maxPayData = this.mainDataSource.activeRow.get("max_target_pay_data");
        if (maxPayData != null && this.carrierPayLayout?.visible == true) {
            this.setupMaxTargetPanel(true);
            this.carrierPayLayout.setDataFromResponse(maxPayData);
            this.mainDataSource.activeRow.set("max_target_pay_data", undefined);
        }
    }

    getOtherChargeDatasource(): DataSource {
        return this.sourceOrderOtherCharge;
    }

    get carrierPayLayout(): MovementCarrierPay {
        return this.layoutMovementRates as MovementCarrierPay
    }

    set calculatingCharges(value: boolean) {
        this._calculatingCharges = value;
    }

    get calculatingCharges(): boolean {
        return this._calculatingCharges;
    }

    textboxSegAllocCodeBeforeLookupModelSearch(event) {
        event.filter.alloc_type = 'M';
        event.filter.is_active = 'Y';
    }

    buttonExpandChargesOnClick(event: ClickEvent) {
        const transitionProps = { speed: 100, paddingTop: 0 };
        if (this.chargePanelExpanded) this.panelOtherChargeTable.collapse(transitionProps).then(() => {
            this.buttonExpandCharges.imageRotation = 90
        }); else this.panelOtherChargeTable.expand(transitionProps).then(() => {
            this.buttonExpandCharges.imageRotation = 0
        });
        this.chargePanelExpanded = !this.chargePanelExpanded;
    }

    buttonExpandDetailsOnClick(event: ClickEvent) {
        const transitionProps = { speed: 100, paddingTop: 0 };
        if (this.ratingDetailsPanelExpanded) this.panelRatingDetailsTable.collapse(transitionProps).then(() => {
            this.buttonExpandDetails.imageRotation = 90
        }); else this.panelRatingDetailsTable.expand(transitionProps).then(() => {
            this.buttonExpandDetails.imageRotation = 0
        });
        this.ratingDetailsPanelExpanded = !this.ratingDetailsPanelExpanded;
    }

    sourceOrdersAfterModeChange(event) {
        if ((event.newMode == DataSourceMode.ADD || event.newMode == DataSourceMode.UPDATE) && event.newMod != event.oldMode) {
            this.setupMaxTargetPanel(true);
            this.sourceOrderOtherCharge.mode = DataSourceMode.ADD;
            this.checkSegAllocEnabled();
        }
        RateUtil.setRateListeners(this);
    }

    private setupMaxTargetPanel(visible: boolean) {
        this.carrierPayLayout.visible = visible;
        this.panelRateTotals.borderBottomRightRadius = visible ? 0 : 4;
        this.panelRateTotals.borderBottomLeftRadius = visible ? 0 : 4;
        if (this.carrierPayLayout.mainDataSource.activeRow == null) this.carrierPayLayout.configureForOrderEntry(this.mainDataSource);
    }

    private checkSegAllocEnabled() {
        this._allocationEnabled = this.sourceOrders.activeRow?.get("seg_alloc_enabled");
        delete this.sourceOrders.activeRow?.data["seg_alloc_enabled"];
        this.textboxSegAllocCode.visible = this._allocationEnabled;
        this.textboxSegAllocCode.required = this._allocationEnabled;
        if (!this._allocationEnabled) this.textboxSegAllocCode.dataSource = null;
    }

    /** This is an event handler for the onRowDisplay event of tableOtherCharges.  */
    tableOtherChargesOnRowDisplay(event) {
        this.otherChargesUtil.tableOtherChargesOnRowDisplay(event);
    }

    otherChargesOnBlur(event: BlurEvent) {
        this.otherChargesUtil.otherChargeOnBlur(event);
    }

    buttonEstimateFuelSurchargeOnClick(event: ClickEvent) {
        this.ratingButtonClicked(event, false, true);
    }

    buttonRateOnClick(event: ClickEvent) {
        this.ratingButtonClicked(event, true);
    }

    ratingButtonClicked(event: ClickEvent, autoRate: boolean, estimateFsc: boolean = this.estimateFuelSurcharge) {
        const button = event.target as Button;
        button.busy = true;
        button.enabled = false;
        this.calculateRates(autoRate, estimateFsc).finally(() => {
            button.busy = false
            button.enabled = true;
        });
    }

    buttonAvailableCreditOnClick(event: ClickEvent) {
        this.buttonAvailableCredit.busy = true;
        const row = this.mainDataSource.activeRow;
        const params = { customer_id: this.mainDataSource.activeRow.get("customer_id") };
        if (row.get("total_charge") != null) this.otherChargesUtil.addCurrencyToProps(params, row, "total_charge", "order_total_charges");
        if (row.get("totalcharge_and_excisetax") != null) this.otherChargesUtil.addCurrencyToProps(params, row, "totalcharge_and_excisetax", "order_total_charges_plus_excisetax");
        if (this.mainDataSource.mode != DataSourceMode.ADD && row.get("id") != null) params["exclude_order_id"] = row.get("id");

        Api.search("lme/ar/customer-balance", params).then(result => {
            const customerData = result?.data?.[0];
            if (customerData["available_credit"] == null) this.labelAvailableCredit.caption = ""; else this.labelAvailableCredit.caption = CurrencyUtil.formatCurrency(customerData["available_credit"]);
        }).finally(() => this.buttonAvailableCredit.busy = false);
    }

    chargeIdChanged(textbox: Textbox, selection) {
        this.otherChargesUtil.chargeIdChanged(textbox, selection);
    }

    tableOtherChargesOnContentsChanged(event) {
        this.otherChargesUtil.tableOtherChargesOnContentsChanged();
        this.calculateRates(false, false, false);
    }

    async calculateRates(autorate: boolean = this.autoRate, estimateFsc: boolean = this.estimateFuelSurcharge, affectRateId: boolean = true): Promise<any> {
        const orderRow: ModelRow = this.mainDataSource.getDataboundValues(null, true, null, null);
        const stopRows: ModelRow[] = this.mainDataSource.childDataSources.find((source) => source.id == "sourceStop")?.data
        if (!this.mainDataSource.isAddingOrUpdating() || this.calculatingCharges || this.mainDataSource.activeRow == null || !this.checkPickupAndDeliveryEntered()) return Promise.resolve();
        this.mainDataSource.setComponentsBusy(true);
        this.calculatingCharges = true;
        const calcMaxTargetPay = this.shouldCalcMaxPay();
        try {
            const response = await Api.search("lme/dispatch/calculate-order-charges", {
                "autorate": autorate,
                "estimate_fsc": estimateFsc,
                "order_row": orderRow,
                "calculate_max_target": calcMaxTargetPay
            });
            let totalChargeChanged = false;
            const data = response.data[0];
            if (!data) return;
            const orderApiData = data["order_row"] || [];

            if (stopRows != null) {
                const stopApiData = data["stop_data"] || [];
                stopApiData.forEach((stop, index) => {
                    stopRows[index].data["rate_dist_from_previous"] = stop["rate_dist_from_previous"];
                });
            }

            // Estimate Fuel Surcharge
            if (estimateFsc) {
                const appendingId: string[] = [];
                this.tableOtherCharges.rows.forEach(row => {
                    if (row.data?._appending == false) appendingId.push(row.data?._data["id"])
                });
                for (let i = this.tableOtherCharges.rows.length - 1; i >= 0; i--) {
                    const row = this.tableOtherCharges.rows[i];
                    if (row?.data?.data["est_fuel_surcharge"] === "Y") {
                        row.deleteRow()
                    }
                }
                this.sourceOrderOtherCharge.data = [];
                const otherCharges = data["other_charges"] || [];
                otherCharges.forEach(element => {
                    const isAppending = appendingId.length == 0 || !appendingId.includes(element["id"]);
                    const modelRow = new ModelRow(this.sourceOrderOtherCharge.url, isAppending);
                    modelRow.setValues(element);
                    this.sourceOrderOtherCharge.data.push(modelRow);
                });
                this.tableOtherCharges.displayData(null, this.sourceOrderOtherCharge.data, 0);
            }

            // Auto Rate
            const items = this.textboxRateType.items as DropdownItem[];
            this.textboxRateType.selectedItem = items.find((item) => item.value === orderApiData["rate_type"]);
            this.mainDataSource.activeRow.set("rate_type", orderApiData["rate_type"]);
            this.mainDataSource.activeRow.set("bill_distance", orderApiData["bill_distance"]);
            this.mainDataSource.activeRow.set("autorate_status", orderApiData["autorate_status"]);
            this.mainDataSource.activeRow.set("rate", orderApiData["rate"]);
            this.mainDataSource.activeRow.data["calc_method"] = data["rate_type"];
            this.mainDataSource.activeRow.set("rate_units", orderApiData["rate_units"]);
            if (orderApiData["freight_charge"] != null && (this.activeRow.getCurrency("freight_charge")?.amount != orderApiData["freight_charge"].amount || this.activeRow.getCurrency("freight_charge")?.currency_code != orderApiData["freight_charge"].currency_code)) {
                this.mainDataSource.activeRow.set("freight_charge", orderApiData["freight_charge"]);
                this.mainDataSource.activeRow.set("freight_charge_n", orderApiData["freight_charge_n"]);
            }
            if (orderApiData["total_charge"] != null) {
                if (orderApiData["total_charge"].amount != this.mainDataSource.activeRow.get("total_charge")?.amount || 0) {
                    this.mainDataSource.activeRow.set("total_charge", orderApiData["total_charge"]);
                    this.mainDataSource.activeRow.set("total_charge_n", orderApiData["total_charge_n"]);
                    totalChargeChanged = true;
                }
            }
            this.mainDataSource.activeRow.set("otherchargetotal", this.otherChargesUtil.getOtherChargeTotal(false));
            if (orderApiData["totalcharge_and_excisetax"] != null) this.mainDataSource.activeRow.set("totalcharge_and_excisetax", orderApiData["order_total_charges_plus_excisetax"]);

            if (affectRateId) {
                RateUtil.setAutoRateContext(this, orderApiData["rate_id"]);
            }
            if (calcMaxTargetPay) this.carrierPayLayout.setDataFromResponse(data.max_target_pay_data);
            if (totalChargeChanged && this.doAfterRating != null) this.doAfterRating();

        } finally {
            this.calculatingCharges = false;
            this.mainDataSource.setComponentsBusy(false);
        }
    }

    shouldCalcMaxPay(): boolean {
        return this.autoCalcMaxPay && this.carrierPayLayout?.visible && (this.activeRow.get("override_max_pay") == null || this.activeRow.get("override_targetpay") == null)
    }

    /** This is an event handler for the onChange event of checkboxLockMiles.  */
    checkboxLockMilesOnChange(event) {
        this.textboxBillDistance.enabled = event.newValue === false;
    }

    sourceRatingDetailsAfterExecution(event: DataSourceExecutionEvent) {
        this.initializeRatingDetails();
    }

    initializeRatingDetails() {
        this.switchByFreightItem.checked = true;
        this.tableRevSummary.visible = false;
        this.panelRatingDetailsTable.collapse({ speed: 100, paddingTop: 0 }).then(() => {
            this.buttonExpandDetails.imageRotation = 90;
        });
    }

    switchByFreightItemOnClick(event: ClickEvent) {
        const switchByFreightItem = event.target as Switch;
        this.tableRevPerFgi.visible = switchByFreightItem.checked;
        this.tableRevSummary.visible = !switchByFreightItem.checked;
    }

    tableRevPerFgiOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.getTableRow() as TableRow;
        this.addBuySellTooltips(tableRow, "textboxFgiRevenueAmount");
    }

    tableRevSummaryOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.getTableRow() as TableRow;
        this.addBuySellTooltips(tableRow, "textboxRevenueAmount");
    }

    addBuySellTooltips(tableRow: TableRow, component: string) {
        const modelRow: ModelRow = tableRow.data as ModelRow;
        const buyRateModelRow = new ModelRow(modelRow._modelPath, false, modelRow.data);
        buyRateModelRow.set("charge_as_calc_description", modelRow.get("buy_rate_description"));
        buyRateModelRow.set("tariff_id", modelRow.get("tariff_id"));
        buyRateModelRow.set("tli_uid", modelRow.get("tli_uid"));
        if (buyRateModelRow.get("tariff_id", null) != null)
            CommonTooltips.setTooltipFromLayoutCallback(tableRow.findComponentById(component), buyRateModelRow,
                "lme/dispatch/RevenueDetailQuickInfo", null, { position: Alignment.TOP });
    }
}
