import { Button, ClickEvent, DataSource, DataSourceMode, Layout } from "@mcleod/components";
import { getLogger, ModelRow } from "@mcleod/core";
import { TopMatchList } from "./TopMatchList";
import { TopMatchUtil } from "./TopMatchUtil";
import { AutogenLayoutTopMatchTable } from "./autogen/AutogenLayoutTopMatchTable";

interface TopMatchFilter {
    id: string,
    filter: (row: ModelRow) => boolean;
    caption: string;
    count: number;
}

const log = getLogger("lme.powerbroker.TopMatchTable");

export class TopMatchTable extends AutogenLayoutTopMatchTable {
    private _filters: TopMatchFilter[];
    private selectedFilter: string;
    private _topMatchUtil = new TopMatchUtil();
    private _stopCount: string;
    private masterModelRows: ModelRow[];
    public updateTabTitle: (rowCount: number) => void;

    set topMatchUtil(topMatchUtil: TopMatchUtil) {
        this._topMatchUtil = topMatchUtil;
    }

    get topMatchUtil() {
        return this._topMatchUtil;
    }

    override onLoad() {
        Layout.clearCachedLayout(this.layoutName);
    }

    override async search(searchValues: ModelRow) {
        this.resetValues();
        if (this.tableTopMatch.emptyCaption == null)
            this.tableTopMatch.emptyCaption = "No results found";
        await this.mainDataSource.search(searchValues);
        this.masterModelRows = [...this.mainDataSource.data];
        this.addFilters();
        this.populateFilteredTable();
        if (this.updateTabTitle != null) {
            this.updateTabTitle(this.tableTopMatch.rowCount);
        }
        return Promise.resolve();
    }

    public clear() {
        this.mainDataSource.data = [];
        this.masterModelRows = [];
        this.tableTopMatch.resetFilter();
        this.movement = null;
        this.tableTopMatch.emptyCaption = null;
        this.populateFilteredTable();
        this.updateTabTitle(null);
        this.resetValues();
    }

    private resetValues() {
        this.topMatchUtil.clearSentIds();
        this.panelFilterButtons.components = [];
    }

    public populateFilteredTable() {
        const modelRows = this.masterModelRows.filter(this.filter);
        this.mainDataSource.setRowsAndMode(DataSourceMode.ADD, modelRows, null);
        if (this.tableTopMatch.rowCount > 0) {
            // The following will populate up to 5 rows when the table is hidden.
            // This avoids populating them when the user switches tab
            const rowsToPopulate = Math.min(5, this.tableTopMatch.rowCount);
            for (let i = 0; i < rowsToPopulate; i++) {
                this.tableTopMatch.rows[i]?.populateDOMIfNeeded();
            }
        }
    }

    set stopCount(stopCount: string) {
        this._stopCount = stopCount;
    }

    set movement(sourceMovement: DataSource) {
        this.topMatchUtil.setMovement(sourceMovement);
    }

    async initialize(topMatchList: TopMatchList) {
        this.topMatchUtil.initialize(this, topMatchList);
    }

    // START: Filter buttons
    private get filter() {
        if (this.selectedFilter == null)
            return (row: ModelRow) => true;
        else
            return this.getSelectedFilter().filter;
    }

    private addFilters() {
        const filters: { source_type: string, source_count: number }[] = this.mainDataSource.summaryData?.get("source_data");
        this._filters = filters.map(f => {
            const id = f.source_type.toLowerCase().replace(/\s/g, '');
            return {
                id: id,
                filter: (row: ModelRow) => Object.keys(row.get("sources")).includes(f.source_type),
                caption: f.source_type,
                count: f.source_count
            }
        });
        this.setupFilters();
    }

    private getSelectedFilter(): TopMatchFilter {
        return this._filters.find(f => f.id === this.selectedFilter);
    }

    private setupFilters() {
        this.panelFilterButtons.components = [];
        this.panelFilterButtons.visible = true;
        this.selectedFilter = null;
        this._filters?.forEach(f => this.panelFilterButtons.add(this.getFilterButton(f)));
    }

    private getFilterButton(filter: TopMatchFilter): Button {
        return new Button({
            id: `btn${filter.id}`,
            caption: `${filter.count} ${filter.caption}`,
            rowBreak: false,
            color: "McLeodTertiary",
            marginRight: 12,
            paddingTop: 6,
            paddingBottom: 6,
            onClick: (event => this.toggleFilter(event, filter))
        });
    }

    private toggleFilter(event: ClickEvent, filter: TopMatchFilter) {
        log.debug(`Toggling secondary filter: ${filter.caption}`);

        if (this.selectedFilter == null || this.selectedFilter !== filter.id) {
            if (this.selectedFilter != null) {
                const btn = this.panelFilterButtons.findComponentById(`btn${this.getSelectedFilter().id}`) as Button;
                this.updateFilterBtnStyle(btn, false);
            }
            this.selectedFilter = filter.id;
            this.updateFilterBtnStyle((event.target as Button), true);
        } else {
            this.selectedFilter = null;
            this.updateFilterBtnStyle((event.target as Button), false);
        }
        this.populateFilteredTable();
    }

    private updateFilterBtnStyle(btn: Button, selected: boolean) {
        btn.color = selected ? "success.reverse" : "McLeodTertiary";
        btn.backgroundColor = selected ? "success" : null;
    }
    // END: Filter buttons
}
