import { getAuthSettings } from ".";

export class PermissionsUtil {

    static getDeniedItems(): any {
        return getAuthSettings()?.permissions?.denied;
    }

    static getDeniedActions(): any {
        return getAuthSettings()?.permissions?.denied?.Action;
    }

    static isUserDeniedAction(itemName: string): boolean {
        return PermissionsUtil.getDeniedActions()?.[itemName] != null;
    }

    static isUserDeniedLayout(layoutName: string): boolean {
        return PermissionsUtil.getDeniedItems()?.[layoutName]?.["Entire screen"] != null;
    }
}
