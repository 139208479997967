import { ClickEvent, MapPin } from "@mcleod/components";
import { getThemeColor } from "@mcleod/core";
import { RecentCallins } from "./RecentCallins";
import { AutogenLayoutMovementCallins } from "./autogen/AutogenLayoutMovementCallins";

export class MovementCallins extends AutogenLayoutMovementCallins {
    private _movementId;

    override onLoad() {
        this.recentCallinLayout.maxCallins = 1000;
        this.recentCallinLayout.newestFirst = false;
        this.addMapPinFormatting();
    }

    private get recentCallinLayout(): RecentCallins {
        return this.layoutRecentCallins as RecentCallins;
    }

    public set movementId(value: string) {
        if (value != this._movementId) {
            this._movementId = value;
            this.searchCallinsAndMap();
        }
    }

    public get movementId(): string {
        return this._movementId;
    }

    private searchCallinsAndMap() {
        if (this._movementId != null) {
            this.recentCallinLayout.includePositions = this.switchSwitchPositions.checked;

            if (this.recentCallinLayout.mainDataSource == null)
                this.recentCallinLayout.addLayoutDataLoadListener(() => this.recentCallinLayout.doSearch(this.movementId));
            else
                this.recentCallinLayout.doSearch(this.movementId)

            this.sourceMapData.search({ movement_id: this.movementId, include_positions: this.switchSwitchPositions.checked });
        }
    }

    private addMapPinFormatting() {
        this.mapStatus.addPinPlotListener(event => {
            const pin: MapPin = event.pin;
            if (pin.data.get("data_type") == "stop") {
                pin.caption = "" + pin.data.get("movement_sequence");
                pin.clusterable = false;
                if (pin.data?.get("status") == "D") {
                    pin.image = "map-pin-filled";
                    pin.captionColor = getThemeColor("primary.reverse");
                }
                else {
                    pin.image = "map-pin-outlined";
                    pin.captionColor = getThemeColor("primary");
                }
            }
            else {
                if (pin.data?.get("image") != null)
                    pin.image = pin.data?.get("image");
                else
                    pin.image = "map-pin-white-dot-small";
                if (pin.data?.get("descr") != null)
                    pin.tooltip = pin.data.get("descr");
            }
        });
    }

    switchSwitchPositionsOnClick(event: ClickEvent) {
        this.searchCallinsAndMap();
    }

    /** This is an event handler for the afterExecution event of sourceMovement.  */
    sourceMovementAfterExecution(event) {
        if (event?.getAction() == "search") {
            this.movementId = this.activeRow?.get("id");
        }
    }
}
