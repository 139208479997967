/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelRoutingGuideProfile.ts           *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/powerbroker/routing-guide-profile";

export class AutogenModelRoutingGuideProfile<RowType extends ModelRow> extends Endpoint<RowType, RowRoutingGuideProfileFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowRoutingGuideProfile extends ModelRow<RowRoutingGuideProfileFields> {
    constructor(values?: Partial<RowRoutingGuideProfileFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowRoutingGuideProfileFields {
    company_id: string;
    descr: string;
    id: string;
    is_active: string;
    owner: string;
}

