/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelGlAssignment.ts                  *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/dispatch/gl-assignment";

export class AutogenModelGlAssignment<RowType extends ModelRow> extends Endpoint<RowType, RowGlAssignmentFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowGlAssignment extends ModelRow<RowGlAssignmentFields> {
    constructor(values?: Partial<RowGlAssignmentFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowGlAssignmentFields {
    allocation_code: string;
    allow_for_rate_exceptions: string;
    company_id: string;
    description: string;
    gla_code: string;
    gla_type_code: string;
    gla_uid: number;
    income_account: string;
    pay_code: string;
    power_expense_acct: string;
    rev_calc_priority: number;
    sales_expense_acct: string;
    short_description: string;
}

