import { Component, Label, Layout, MouseEvent, Textbox } from "@mcleod/components";
import { StringUtil } from "@mcleod/core";

export class EdiMakeStopQuickInfo {
    static makeStopsTooltipCallbackFunction(id: string, component: Component) {
        return (baseTooltip: Component, originatingEvent: MouseEvent): Component => {
            const layout = EdiMakeStopQuickInfo.getQuickInfoLayout(id);
            if (layout == null)
                return layout;
            layout.onLoad = () => {
                layout.mainDataSource?.search({ order_id: id }).then(response => {
                    const label: Label = (component as Textbox)["_printableLabel"];
                    return label["_internalShowTooltip"](layout, originatingEvent);
                });
            };
            return layout;
        };
    }

    private static getQuickInfoLayout(id: string): Layout | null {
        if (StringUtil.isEmptyString(id))
            return null;
        return Layout.getLayout("lme/datafusion/EdiStopQuickInfo");
    }
}