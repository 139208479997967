import { DataSource, Layout, Switch, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutDriver extends Layout {
    sourceCarrierDriver: DataSource;
    switchActive: Switch;
    switchSMSOptIn: Switch;
    textboxCellPhoneNumber: Textbox;
    textboxDriverName: Textbox;
    textboxEmailAddress: Textbox;
    textboxNotes: Textbox;
    textboxOptOutDate: Textbox;
    textboxTractorNumber: Textbox;
}
