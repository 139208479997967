import { Checkbox, DataSource, HorizontalSpacer, Label, Layout, Panel, SaveButton, Tab, Tabset, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutCompanySettings extends Layout {
    checkboxAllowKeepSignedIn: Checkbox;
    checkboxUserAllowNewDevice: Checkbox;
    checkboxUserOverrideNewDevice: Checkbox;
    horizontalspacer1: HorizontalSpacer;
    horizontalspacer2: HorizontalSpacer;
    labelKeepMeSignedInSettings: Label;
    labelLoginSessionSettings: Label;
    labelNewDeviceApprovalSettings: Label;
    labelPageTitle: Label;
    panel1: Panel;
    panel2: Panel;
    panelKeepSignedIn: Panel;
    panelKeepSignedInHeader: Panel;
    panelSessionSettings: Panel;
    panelSessionSettingsHeader: Panel;
    saveButtonUserLicenseContact: SaveButton;
    saveLoginSettingsButton: SaveButton;
    sourceLicenseContactSettings: DataSource;
    sourceLoginSettings: DataSource;
    tabLoginSettings: Tab;
    tabUserLicenseContact: Tab;
    tabsetMain: Tabset;
    textboxAllowSignedInDays: Textbox;
    textboxMaxFailedLogins: Textbox;
    textboxTokenFinalExpiryMinutes: Textbox;
    textboxTokenIdleMinutes: Textbox;
    textboxUserLicContactPhone: Textbox;
    textboxUserLicenseContactEmail: Textbox;
    textboxUserLicenseContactName: Textbox;
}
