import { Api, ArrayUtil, DynamicLoader } from "@mcleod/core";
import { Component } from "../base/Component";
import { ComponentPropDefinitionUtil, ComponentPropDefinitions } from "../base/ComponentProps";
import { PropType } from "../base/PropType";
import { ReflectiveDialogs } from "../base/ReflectiveDialogs";
import { ButtonPropDefinitions, ButtonProps } from "../components/button/ButtonProps";

export interface TailorExtensionButtonProps extends ButtonProps {
    extension: string;
}

let tailorExtensionButtonProps: ComponentPropDefinitions;

export class TailorExtensionButtonPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (tailorExtensionButtonProps == null) {
            tailorExtensionButtonProps = {
                ...ButtonPropDefinitions.getDefinitions(),
                extension: { type: PropType.string, editor: tailorExtensionEditor, visibleInMcLeodTailor: { baseComponent: false, customComponent: true }, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "Defines the server-side extension that will be invoked when the button is clicked." }
            };
            ComponentPropDefinitionUtil.populateComponentPropNames(tailorExtensionButtonProps);
        }
        tailorExtensionButtonProps.caption.defaultValue = "Invoke Extension";
        tailorExtensionButtonProps.color.defaultValue = "primary";
        tailorExtensionButtonProps.minWidth.defaultValue = 100;
        return tailorExtensionButtonProps;
    }
}

export async function tailorExtensionEditor(value: string, prop): Promise<Component> {
    const response = await Api.search("tailor/extension-metadata");
    const extensionMetadata = response?.data?.[0]?.extensions;
    if (ArrayUtil.isEmptyArray(extensionMetadata) === true) {
        ReflectiveDialogs.showDialog("No extensions have been configured for McLeod Tailor.")
        return null;
    }
    // can't make a direct reference to TailorExtensionEditor at compile time
    const cls = DynamicLoader.getClassForPath("designer/ui/TailorExtensionEditor");
    return new cls(null, value, extensionMetadata);
}