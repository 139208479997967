import { BlurEvent, ClickEvent, Component, DataDisplayEvent, DataSourceExecutionEvent, DropdownItem, Image, ImageType, Label, Layout, LookupModelSearchEvent, Snackbar, StringOrPropsOrComponent, Textbox } from "@mcleod/components";
import { DataSourceAction } from "@mcleod/components/src/databinding/DataSource";
import { Alignment, Api, CityUtil, Currency, CurrencyUtil, DateFormat, DateUtil, DisplayType, ExtendedDateFormat, HorizontalAlignment, ModelRow, StringUtil, getAuthSettings } from "@mcleod/core";
import { ModifyCarrierPay } from "../../powerbroker/src/ModifyCarrierPay";
import { AssignedMovementActions } from "./AssignedMovementActions";
import { makeTooltipCallbackFunction } from "./CarrierMakeQuickInfo";
import { CLEARED_STATUS_CODE, Movement } from "./Movement";
import { PayAdvance } from "./PayAdvance";
import { AutogenLayoutAssignedMovementOverview } from "./autogen/AutogenLayoutAssignedMovementOverview";

const tabsetButtonProps = { marginBottom: 5, fontBold: true, borderWidth: 0, height: "26", fontSize: "medium", visible: true };
export class AssignedMovementOverview extends AutogenLayoutAssignedMovementOverview {

    movementActions: AssignedMovementActions;
    trackingLogo: Image;

    override async onLoad() {

        if (this._designer == null)
            await this.addAssignedMovementActionsLayout();
        this.panelMoveInfo.visible = this.mainDataSource?.owner instanceof Movement;

        this.textboxOverrideDriverNm.addBeforeLookupModelSearchListener(event => {
            event.filter.carrier_id = this.sourceMovement.activeRow.get("override_payee_id");
            event.filter.is_active = "Y";
        });
        this.textboxOverrideDriverNm.onSelectItem = ((textbox, selection) => {
            if (selection instanceof ModelRow)
                this.setDriverData(selection, "");
            return undefined;
        });
        this.textboxOverrideDriverNm.tooltipCallback = null;

        this.textboxOverrideDriver2Nm.addBeforeLookupModelSearchListener(event => {
            event.filter.carrier_id = this.sourceMovement.activeRow.get("override_payee_id");
            event.filter.is_active = "Y";
        });
        this.textboxOverrideDriver2Nm.onSelectItem = ((textbox, selection) => {
            if (selection instanceof ModelRow)
                this.setDriverData(selection, "2");
            return undefined;
        });
        this.textboxOverrideDriver2Nm.tooltipCallback = null;

        this.textboxCarrierContact.onSelectItem = ((textbox, selection) => {
            if (selection instanceof ModelRow)
                this.setCarrierContact(selection);
            return undefined;
        });

        if (this.tabTrackingRequirements.visible) {
            this.setVendorModelRows();
        }
    }

    addAssignedMovementActionsLayout(): Promise<AssignedMovementActions> {
        return new Promise(resolve => {
            Layout.getLayout("lme/dispatch/AssignedMovementActions").addLayoutLoadListener(event => {
                this.movementActions = event.target as AssignedMovementActions;
                this.movementActions.replaceMainDatasource(this.sourceMovement);
                this.tabsetCarrierDriver.addTool(this.movementActions);
                resolve(this.movementActions);
            });
        })
    }

    sourceMovementAfterExecution(event: DataSourceExecutionEvent) {
        if (event?.getAction() == DataSourceAction.SEARCH || event?.getAction() == DataSourceAction.UPDATE) {
            const orderId = this.sourceMovement.activeRow?.get("orders.id");
            if (orderId != null) {
                this.sourceEquipMatchDetail.search({ _parent_link: { model: this.sourceMovement.url, "orders.id": orderId } }).then(() => {
                    this.panelEquipmentRequired.visible = this.sourceEquipMatchDetail.data.length > 0;
                });
            }
            this.resetTrackingPanel();
            this.panelTempRange.visible = this.sourceMovement.activeRow?.get("orders.temperature_min") != null;
            this.tabTrackingRequirements.visible = this.sourceMovement.activeRow?.get("override_payee_id") != null;
            this.setTrackingBadgeProps(this.sourceMovement.activeRow);

            this.addOrderMovementReferenceNumberTextboxes();
            this.setCarrierRateData();
            this.setCarrierDriverLookupData(this.textboxOverrideDriverNm);
            this.setCarrierDriverLookupData(this.textboxOverrideDriver2Nm);
            this.setCarrierEmptyCityState();
            this.labelTeam.visible = this.sourceMovement?.activeRow.get("override_driver2_nm") != null;
            this.addTrackingLogo();
            this.cityStateCarrierEmptyFromCityIdPickupDist();
            PayAdvance.validateMovement(this);
            this.movementActions.setMenuOptions(this.sourceMovement.activeRow?.get("override_payee_id"));
            this.setCarrierStarRatingVisible(this.sourceMovement.activeRow);
            const driverOneName = this.sourceMovement.activeRow.get("override_driver_nm");
            this.enableDriverTwoComponents(driverOneName);

        }
    }

    sourceMovementOnDisplay() {
        const movementId = this.mainDataSource.activeRow?.get("id") as string;
        const orderId = this.mainDataSource.activeRow?.get("orders.id") as string;
        const customerId = this.mainDataSource.activeRow?.get("customer.id") as string;

        const layoutMovementDataFusionSummary = this.layoutMovementDataFusionSummary;
        if (movementId && orderId && customerId) {
            layoutMovementDataFusionSummary.movementId = movementId;
            layoutMovementDataFusionSummary.orderId = orderId;
            layoutMovementDataFusionSummary.customerId = customerId;
            const filter = [
                {
                    movement_id: movementId,
                    order_id: orderId,
                    customer_id: customerId
                }
            ];
            const body = JSON.stringify(filter);
            Api.search("lme/dispatch/movement-datafusion-summary", body).then(response => {
                if (response) {
                    layoutMovementDataFusionSummary.setEdiData(response.data[0]);
                    this.panelDataFusion.visible = layoutMovementDataFusionSummary.displayingData;
                }
            });
        }
    }

    // This Method is use to check if Daily Advance is not "N" or "Null" or "Empty" in User Screen under Settelement Tab
    private hasDailyAdvanceLimits(): boolean {
        const dailyAdvanceLimits = getAuthSettings().user_settings.daily_advance_limits;
        return !(dailyAdvanceLimits === "N" || dailyAdvanceLimits === null || dailyAdvanceLimits === "");
    }

    resetTrackingPanel() {
        this.panelTracking.visible = false;
        this.labelTrackingRequired.visible = false;
        this.labelNextScheduledHeader.visible = true;
        this.labelNextSchedCall.visible = true;
        this.labelTrackingCaption.visible = false;
        this.panelTracking.remove(this.trackingLogo);
    }

    addTrackingLogo() {
        this.labelTrackingCaption.visible = false;
        const trackingVendor = this.sourceMovement.activeRow?.get("br_vendor");
        if (trackingVendor != null) {
            this.labelNextSchedCall.visible = false;
            this.labelNextScheduledHeader.visible = false;
            Api.search("lme/powerbroker/tracking/brokerage-tracking-logo",
                {
                    "vendor_id": trackingVendor,
                    "movement_id": this.sourceMovement.activeRow?.get("id"),
                    "order_id": this.sourceMovement.activeRow?.get("orders.id")
                }).then(response => {
                    this.setTrackingLogo(response?.data?.[0]);
                    if (this.trackingLogo != null) {
                        this.labelTrackingCaption.visible = true;
                        this.panelTracking.visible = true;
                        this.panelTracking.add(this.trackingLogo);
                    }
                });
        }
    }

    setTrackingLogo(response: any) {
        this.trackingLogo = null;
        const logoBytes = response?.logo_bytes;
        const logoURL = response?.logo_url;
        const onClickUrl = response?.on_click_url;
        if (!StringUtil.isEmptyString(logoBytes) || !StringUtil.isEmptyString(logoURL)) {
            this.trackingLogo = new Image({
                id: "trackingLogo", imageType: ImageType.IMG, marginLeft: 6, marginTop: -8,
                preserveAspectRatio: true, height: 70, width: 120
            });

            if (!StringUtil.isEmptyString(logoBytes))
                this.trackingLogo.imageBytes = logoBytes;
            else
                this.trackingLogo._element.setAttribute("src", logoURL);

            if (!StringUtil.isEmptyString(onClickUrl))
                this.trackingLogo.addClickListener(() => window.open(onClickUrl, '_blank', 'noreferrer'));
            else
                this.trackingLogo.addClickListener(() => Snackbar.showSnackbar("Tracking details not found", { millisUntilDimissal: 2000 }));

        }
    }

    setTrackingBadgeProps(modelRow: ModelRow) {
        const showTrackingBadge = modelRow?.get("show_tracking_badge", false);
        this.panelTracking.visible = showTrackingBadge;
        this.labelTrackingRequired.visible = showTrackingBadge;
        this.setTrackingRequiredTooltip(this.mainDataSource.activeRow, modelRow);
        this.labelNextScheduledHeader.visible = !showTrackingBadge;
        this.labelNextSchedCall.visible = !showTrackingBadge;

        this.labelTrackingRequired.caption = modelRow.get("tracking_badge_text", "");
        this.labelTrackingRequired.backgroundColor = modelRow.get("tracking_badge_color");
    }

    setQuickInfoTooltip(component: Component, tooltip: StringOrPropsOrComponent) {
        if (component)
            component.tooltipCallback = (baseTooltip, originatingEvent) => {
                if (component["_internalShowTooltip"] != null)
                    return component["_internalShowTooltip"](tooltip, originatingEvent);
                return component.showTooltip(tooltip, null, { themeKey: "quickInfo", color: null });
            };
    }

    setCarrierDriverLookupData(driverTextbox: Textbox) {
        if (this.sourceMovement.activeRow?.get(driverTextbox.field) != null) {
            const lmData = new ModelRow(this.textboxOverrideDriverNm.lookupModel, false, { driver_name: this.sourceMovement.activeRow?.get(driverTextbox.field) });
            this.sourceMovement.activeRow.setLookupModelData(driverTextbox.field, lmData);
            driverTextbox.displayData(this.sourceMovement.activeRow, null, 0);
        }
    }

    public setStops(stops: ModelRow[]): void {
        this.addStopToHeader(stops);
        if (this.sourceMovement?.activeRow?.get("status") == "D") {
            this.labelNextStopCity.caption = "Delivered";
        }
    }

    addStopToHeader(stops: ModelRow[]) {
        let nextStopFound = false;

        stops?.forEach((stop, index) => {
            const cleared = stop.get("status") == CLEARED_STATUS_CODE;

            if (!cleared && !nextStopFound) {
                nextStopFound = true;
                this.addNextStopToHeader(stop);
                this.addOtherReferenceNumberTextboxes(stop);
            }
        });

        this.panelNextStopType.visible = nextStopFound;
    }

    addNextStopToHeader(stop: ModelRow) {
        const nextStopLocation = stop.get("city_name", "") + ", " + stop.get("state", "") + " " + stop.get("zip_code", "");
        const isPickup = stop.get("stop_type") === "PU";
        this.imageNextStopType.name = isPickup ? "pickup" : "delivery";
        this.labelNextStopCity.caption = nextStopLocation;
        this.labelNextStopLocation.caption = stop.get("location_name", "");
        this.labelNextStopAddress.caption = stop.get("address", "");
        this.labelNextStopAddress2.caption = stop.get("address2", "");
        this.labelNextStopScheduled.caption = DateUtil.formatDateTime(DateUtil.parseDateTime(stop.get("sched_arrive_early")), DateUtil.getDateFormat(DateFormat.DATE_TIME, ExtendedDateFormat.LONG));
        const nextStopCityId: string = stop.get("city_id");
        Api.search("lme/dispatch/callin", { "_max_search_results": 1, "movement_id": this.sourceMovement.activeRow.get("id"), "newest_first": true }).then((rowCallin: ModelRow) => {
            const callinData = rowCallin?.data?.[0];
            if (callinData != null && callinData.next_stop_city_id === nextStopCityId) {
                this.labelNextStopEta.caption = (callinData && callinData.new_eta) ? DateUtil.formatDateTime(DateUtil.parseDateTime(callinData.new_eta), DateUtil.getDateFormat(DateFormat.DATE_TIME, ExtendedDateFormat.LONG)) : "--";
                this.labelNextStopDistance.caption = (callinData && callinData.miles2consignee) ? callinData.miles2consignee + " " + callinData.miles2consignee_um : "--";
            }
            else {
                this.labelNextStopEta.caption = "";
                this.labelNextStopDistance.caption = "";
            }
        });

    }

    addReferenceNumberTextbox = function (caption: string, text: string) {
        if (text != null)
            this.panelRefNbrs.add(new Textbox({ fillRow: true, caption: caption, text: text, printable: true, fontBold: true }));
    }

    addOrderMovementReferenceNumberTextboxes() {
        this.panelRefNbrs.removeAll();
        const row = this.sourceMovement.activeRow;
        this.addReferenceNumberTextbox("BOL Number", row.get("orders.blnum"));
        this.addReferenceNumberTextbox("Consignee Reference Number", row.get("orders.consignee_refno"));
        this.addReferenceNumberTextbox("Carrier Pro Number", row.get("pro_nbr"));
    }

    addOtherReferenceNumberTextboxes(stop: ModelRow) {
        const firstStopId = stop.get("id");
        if (firstStopId) {
            Api.search("lme/general/reference-number", { stop_id: firstStopId }).then(response => {
                for (const data of response.data)
                    this.addReferenceNumberTextbox(data.description, data.reference_number);
            });
        }
    }

    setCarrierRateData() {
        Api.search("lme/dispatch/calculate-override-pay",
            {
                "movement_id": this.sourceMovement.activeRow.get("id"),
                "pay_method": this.sourceMovement.activeRow.get("override_type"),
                "rate_per_unit": this.sourceMovement.activeRow.get("override_pay_rate")
            }).then(response => {
                const setCurrency = function (textbox: Textbox, currency: Currency) {
                    if (currency != null) {
                        textbox.text = CurrencyUtil.formatCurrency(currency);
                    }
                }
                const data = response?.data?.[0];
                setCurrency(this.textboxTotalCharge, data?.order_total_charges);
                setCurrency(this.textboxFreightCharges, data?.order_freight_charge);
                setCurrency(this.textboxOtherCharges, data?.order_other_charges);
                setCurrency(this.textboxTotalPay, data?.total_pay);
                setCurrency(this.textboxMargin, data?.margin);
                setCurrency(this.textboxOtherPay, data?.other_pay);
                setCurrency(this.textboxOverridePayAmt, data?.carrier_pay);
                setCurrency(this.textboxTotalAdvances, data?.total_advances);
                setCurrency(this.textboxNetPay, data?.net_pay);
                this.textboxMarginPercentage.text = data?.margin_percentage;
                this.labelTotalPayPerMile.caption = data?.total_pay_per_mile;
                this.setTotalAdvancesTooltip();

            });
    }

    setTotalAdvancesTooltip() {
        this.textboxTotalAdvances.tooltipCallback = () => {
            const tooltipProps = { themeKey: "quickInfo", color: null, minWidth: 425, minHeight: 150, padding: 0 , tooltipPosition: Alignment.RIGHT};
            if (this.textboxTotalAdvances.tooltip == null) {
                const layout = Layout.getLayout("lme/dispatch/MovementAdvancesQuickInfo", { minWidth: 425, minHeight: 150 });
                layout.addLayoutLoadListener(() => {
                    layout.mainDataSource.search({ movement_id: this.activeRow?.get("id") });
                    this.textboxTotalAdvances.tooltip = layout;
                });
                return this.textboxTotalAdvances.showTooltip(layout, null, tooltipProps);
            } else {
                return this.textboxTotalAdvances.showTooltip(this.textboxTotalAdvances.tooltip, null, tooltipProps);
            }
        }
        this.textboxTotalAdvances.tooltipPosition = Alignment.RIGHT;
    }

    textboxOverrideDriverIdOnBlur(event: BlurEvent) {
        if (event.changedWhileFocused) {
            this.setDriverData(null, "");
        }
    }

    textboxOverrideDriver2IdOnBlur(event: BlurEvent) {
        if (event.changedWhileFocused) {
            this.setDriverData(null, "2");
        }
    }

    setDriverData(selectedRow: ModelRow, driverNumberSuffix: String) {
        this.sourceMovement.activeRow.set(`override_driver${driverNumberSuffix}_id`, selectedRow?.get("id"));
        this.sourceMovement.activeRow.set(`override_drvr${driverNumberSuffix}_cell`, selectedRow?.get("cell_phone_number"));
        this.sourceMovement.activeRow.set(`override_drvr${driverNumberSuffix}_email`, selectedRow?.get("email_address"));

        const movementTractorNumber = this.sourceMovement?.activeRow.get("carrier_tractor");
        const carrierDriverTractorNumber = selectedRow?.get("tractor_number");
        this.dynamicallySetCarrierTractor(driverNumberSuffix, carrierDriverTractorNumber, movementTractorNumber);

        const driverOneName = this.sourceMovement?.activeRow.get("override_driver_nm");
        this.enableDriverTwoComponents(driverOneName);

        if (driverNumberSuffix === "2") {
            this.labelTeam.visible = this.sourceMovement.activeRow.get("override_driver2_nm") != null;
        }

    }

    private enableDriverTwoComponents(driverOneName: any) {
        const driverOneNameIsEmpty: boolean = StringUtil.isEmptyString(driverOneName);
        this.textboxOverrideDrvr2Email.enabled = !driverOneNameIsEmpty;
        this.textboxOverrideDrvr2Cell.enabled = !driverOneNameIsEmpty;
        this.textboxOverrideDriver2Nm.enabled = !driverOneNameIsEmpty;

        if (driverOneNameIsEmpty) {
            this.textboxOverrideDriver2Nm.clear();
            this.textboxOverrideDrvr2Cell.clear();
            this.textboxOverrideDrvr2Email.clear();
            this.labelTeam.visible = false;
        }
    }

    private dynamicallySetCarrierTractor(driverNumberSuffix: String, carrierDriverTractorNumber: any, movementTractorNumber: any) {
        if (driverNumberSuffix === "") {
            this.sourceMovement.activeRow.set("carrier_tractor", carrierDriverTractorNumber);
        }
        else if (driverNumberSuffix === "2") {
            if (StringUtil.isEmptyString(movementTractorNumber)) {
                this.sourceMovement.activeRow.set(`carrier_tractor`, carrierDriverTractorNumber);
            }
            else {
                this.sourceMovement.activeRow.set(`carrier_tractor`, movementTractorNumber);
            }
        }
    }

    setCarrierContact(selectedRow: ModelRow<any>) {
        this.sourceMovement.activeRow.set("carrier_phone", selectedRow?.get("phone"));
        this.sourceMovement.activeRow.set("carrier_email", selectedRow?.get("email"));
    }

    setCarrierEmptyCityState() {
        const cityId = this.sourceMovement.activeRow.get("carrier_empty_from_city_id");
        if (cityId)
            Api.search("common/city", { id: cityId }).then(result => {
                const data = result?.data[0];
                this.cityStateCarrierEmptyFromCityId.textCombined.text = CityUtil.formatCityStateZip(data.name, data.state_id, data.zip_code);
                this.cityStateCarrierEmptyFromCityIdPickupDist();
            });
    }

    /** This is an event handler for the onClick event of buttonAssignCarrier.  */
    async buttonModifyPayOnClick(event: ClickEvent) {
        this.buttonModifyPay.busy = true;
        const isEdiCarrier: boolean = await this.checkCarrierForDataFusion(this.activeRow?.get("override_payee_id"));
        ModifyCarrierPay.showSlideout(this.activeRow?.get("id"), (movementSaved: boolean) => {
            if (movementSaved)
                this.sourceMovement.search({ id: this.activeRow?.get("id") });
        }, undefined, undefined, isEdiCarrier).then(() => this.buttonModifyPay.busy = false);
    }

    /** This is an event handler for the onBlur event of cityStateCarrierEmptyFromCityId.  */
    cityStateCarrierEmptyFromCityIdPickupDist() {
        const cityId = this.sourceMovement.activeRow.get("carrier_empty_from_city_id") || this.cityStateCarrierEmptyFromCityId?.textCombined?.getFirstLookupModelData()?.get("id");
        const moveId = this.mainDataSource.activeRow?.get("id");
        if (!StringUtil.isEmptyString(cityId) && moveId != null) {
            Api.post("lme/dispatch/get-next-stop-distance", { city_id: cityId, movement_id: moveId, use_load_dist_profile: true }).then(response => {
                if (!StringUtil.isEmptyString(response?.data[0]?.distance)) {
                    this.labelMilesToPickup.caption = `${response?.data[0]?.distance} Miles to Pick-up`;
                    this.labelNextStopDistance.caption = `${response?.data[0]?.distance} ${response?.data[0]?.distance_um}`;
                }
                else {
                    this.labelMilesToPickup.caption = "";
                    this.labelNextStopDistance.caption = "";
                }
            });
        }
        else {
            this.labelMilesToPickup.caption = "";
            this.labelNextStopDistance.caption = "";
        }
    }

    /** This is an event handler for the onDataDisplay event of labelPayeeName.  */
    labelPayeeNameOnDataDisplay(event: DataDisplayEvent) {
        (event.target as Label).tooltipCallback = makeTooltipCallbackFunction(this.mainDataSource.activeRow?.get("payee.id"), this.labelPayeeName);
    }

    /** This is an event handler for the beforeLookupModelSearch event of textboxCarrierContact.  */
    textboxCarrierContactBeforeLookupModelSearch(event: LookupModelSearchEvent) {
        event.filter.parent_row_id = this.mainDataSource.activeRow?.get("override_payee_id");
        event.filter.parent_row_type = "P";
    }

    setTrackingRequiredTooltip(movementModelRow: ModelRow<any>, modelRow: ModelRow<any>) {
        let vendorDisplayName = "";
        try {
            const response = Api.search("lme/dispatch/get-active-brokerage-tracking", {
                vendor_id: movementModelRow.get("required_vendor")
            }).then(response => {
                if (response !== null && response.data !== null
                    && response.data.length > 0 && response.data[0].vendor_name !== null) {
                    vendorDisplayName = response.data[0].vendor_name;
                    if (modelRow.get("tracking_badge_text", "") === "Tracking Automated") {
                        this.setQuickInfoTooltip(this.labelTrackingRequired, new Label({ caption: "Tracking will be sent automatically to " + vendorDisplayName + ".", align: HorizontalAlignment.CENTER }));
                    } else {
                        this.setQuickInfoTooltip(this.labelTrackingRequired, new Textbox({ caption: "Vendor", displayType: DisplayType.STRING, printable: true, text: vendorDisplayName }));
                    }
                } else {
                    this.tabTrackingRequirements.visible = false; // set to false because no vendor is active.
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    setCarrierStarRatingVisible(modelRow: ModelRow) {
        this.starratingRatingStarCount.visible = modelRow?.get("is_carrier_scorecard_licensed", false);
    }

    async checkCarrierForDataFusion(payeeId: String): Promise<boolean> {
        let result = "";
        await Api.search("lme/datafusion/df-carrier-search", {
            payee_id: payeeId
        }).then(response => {
            const data = response.data[0];
            result = data.is_datafusion_carrier;
        });
        return result != "false";
    }

    private async setVendorModelRows() {
        const vendorItems: DropdownItem[] = [];
        let selectedValue = '';
        try {
            const response = await Api.search("lme/dispatch/get-active-brokerage-tracking", {
                vendor_id: this.sourceMovement.activeRow?.get("required_vendor")
            });
            if (response && response.data[0]?.vendor_map) {
                for (const [key, value] of Object.entries(response.data[0].vendor_map)) {
                    vendorItems.push({ value: key, caption: value as string });
                    if (!selectedValue && key == this.textboxRequiredVendor.text) {
                        this.textboxRequiredVendor.text = "";
                        selectedValue = value as string;
                    }
                }
            }
        } catch (error) {
            console.error(error);
        }
        this.textboxRequiredVendor.text = selectedValue;
        this.textboxRequiredVendor.items = vendorItems;

    }
}
