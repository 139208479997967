import { CommonDialogs } from "@mcleod/common";
import { BlurEvent, CityState, DataSource, Label, Panel, Snackbar, TableRow, TableRowCreationEvent, TableRowCreationListener, TableRowDisplayEvent, TableRowMode, Textbox } from "@mcleod/components";
import { RouteType } from "@mcleod/components/src/components/map/GoogleMap";
import { TableRowMoveEvent } from "@mcleod/components/src/events/TableRowMoveEvent";
import { Api, DisplayType, Keys, StringUtil, getAuthSettings, getLogger, getMapSettings, getUnauthSettings } from "@mcleod/core";
import { DistanceCalculatorOverview } from "./DistanceCalculatorOverview";
import { DistanceRateUtil } from "./DistanceRateUtil";
import { RateIndexDataSummary } from "./RateIndexDataSummary";
import { AutogenLayoutDistanceRateIndexCalculator } from "./autogen/AutogenLayoutDistanceRateIndexCalculator";

const GoogleMapVendor = 'G';
const TrimbleAlkMapVendor = 'W';
const PCMILER = 'A';
const WEBPCMILER = 'W';
const MULTI_VERSION_PCMILER = 'M';
const EQUIPMENT_TYPE_VAN = 'Van';
const EQUIPMENT_TYPE_FLAT = 'Flat';
const EQUIPMENT_TYPE_REEFER = 'Reefer';
const EQUIPMENT_TYPE_OTHER = 'Other';

const log = getLogger("dispatch.DistanceRateIndexCalculator");

export enum DISTANCE_PROFILE {
    BILLING = "BILLING",
    FUEL = "FUEL",
    PAY = "PAY",
    PRACTICL = "PRACTICL",
    SHORT = "SHORT"
}

export enum RATE_VENDORS {
    MPACT = "E",
    DAT = "T",
    TRUCKSTOP = "I",
    FREIGHT_WAVES = "F",
    GREENSCREENS = "G"
}
export class DistanceRateIndexCalculator extends AutogenLayoutDistanceRateIndexCalculator {
    distCalcMapVendor: string = getMapSettings().distance_calc_vendor;
    isPcMilerVendor: boolean;
    private _showAllTotalRates: boolean = false;
    private currentRateIndexIdMap;
    private _forceRefresh = true;
    private _calculationDisplayed = false;
    private distanceUtil = new DistanceRateUtil();
    private _cachedPracticalProfile;
    private _cachedTrailerType;
    private rowCreationListener: TableRowCreationListener;
    private _userMarginPercentage = getAuthSettings()?.user_settings?.margin_percentage;

    override onLoad(): void {
        this.initializeStopTable();
        this.textboxTrailerType.items = [EQUIPMENT_TYPE_VAN, EQUIPMENT_TYPE_FLAT, EQUIPMENT_TYPE_REEFER, EQUIPMENT_TYPE_OTHER];
        this.textboxTrailerType.text = this.textboxTrailerType.items[0];
        this.textboxMarginPercentage.text = this._userMarginPercentage;
        this.sourceDistanceControl.search();
        this.sourceStops.parentDataSource = new DataSource();
        this.tableStops.addFunctionsVisible = true;
        this.tableStops.data = [];
        this.onSelectPracticalProfile();
        this.addLayoutDataLoadListener(() => {
            this.showMap();
        });
        this.panelDistanceCalculator.addHandlerForKey(Keys.ENTER, { ctrlKey: true }, () => this.buttonCalculateOnClick());
        this.panelDistanceCalculator.addHandlerForKey(Keys.ENTER, null, () => this.buttonCalculateOnClick());
        this.currentRateIndexIdMap = new Map<String, String>();

        this.tableStops.doOnRowDelete = (oRow) => {
            this.handleTableRowChange(oRow.index, oRow.index);
        }

        this.rowCreationListener = (event: TableRowCreationEvent) => {
            const tableRow = event.getTableRow();
            if (tableRow.mode == TableRowMode.QUICK_ADD)
                tableRow.addMountListener(() => this.focusCityStateTextbox(tableRow))
        }
    }

    private onSelectPracticalProfile() {
        const layoutDistanceCalculator = this.layoutDistanceCalculationOverview as DistanceCalculatorOverview;

        layoutDistanceCalculator.textboxPracticalProfile.onSelectItem = (textbox: Textbox, _selectedItem: any) => {
            if (this.currentRateIndexIdMap != undefined && this.currentRateIndexIdMap.size != 0 && _selectedItem.caption != this.cachedPracticalProfile) {
                this.currentRateIndexIdMap.clear();
            }
            this.buttonFetchVendorResultsOnClick(this._showAllTotalRates, false);
            return undefined;
        };
    }

    textboxTrailerTypeOnChange() {
        if (this.currentRateIndexIdMap != undefined && this.currentRateIndexIdMap.size != 0 && this.textboxTrailerType.text != this.cachedTrailerType) {
            this.currentRateIndexIdMap.clear();
        }
        this.buttonCalculateOnClick();
    }

    /** When Screen loads By Default add two Stop */
    private async initializeStopTable() {
        this.tableStops.removeRowCreateListener(this.rowCreationListener);
        await this.addNewStopToTable();
        await this.addNewStopToTable();
        this.focusCityStateTextbox(this.tableStops.rows[0]);
        this.tableStops.addRowCreateListener(this.rowCreationListener);
    }

    private async addNewStopToTable() {
        const newRowData = await this.tableStops._createNewRowData();
        if (newRowData != null) {
            this.tableStops.addRow(newRowData, { mode: TableRowMode.ADD }, { display: true, addToData: true });
        }
    }

    private validateStops() {
        let componentPassedValidation = false;
        if (this.tableStops.rowCount < 2) {
            CommonDialogs.showError("At least two stops are required to calculate the Short and Practical (Mileage and Toll Amount).");
            return;
        }
        else if (this.tableStops.rowCount > 1) {
            componentPassedValidation = this.tableStops.validateSimple(true, true);
            if (!componentPassedValidation) {
                Snackbar.showWarningSnackbar("All stops must have locations entered.")
                return componentPassedValidation;
            }
            return componentPassedValidation;
        }
    }

    private calculate(routeData: any[], fetchOtherResults: boolean) {
        const equipmentId = this.setEquipmentId();
        const marginPercentage = this.setMarginPercentage();
        this._calculationDisplayed = true;

        if (marginPercentage == null || marginPercentage == "")
            this.textboxMarginPercentage.text = "0";

        const excludeFuelSurcharge = this.switchExcludeFuelSurcharge.checked;
        const layoutDistanceCalculator = this.layoutDistanceCalculationOverview as DistanceCalculatorOverview;
        const totalRatesSummary = this.layoutTotalRatesSummary as RateIndexDataSummary;
        if (layoutDistanceCalculator.sourceDistanceControl.activeRow === undefined) {
            CommonDialogs.showError("Lookup Model cannot be null or empty");
            return;
        }
        const lookupShortProfile = layoutDistanceCalculator.sourceDistanceControl.activeRow?.get("short_dist_profile");
        const lookupPracticalProfile = layoutDistanceCalculator.sourceDistanceControl.activeRow?.get("prac_dist_profile");
        this.cachedPracticalProfile = lookupPracticalProfile;
        this.cachedTrailerType = this.textboxTrailerType.text;

        this.validationLookUpModel(lookupShortProfile, lookupPracticalProfile);
        const isStopsValid: boolean = this.validateStops();
        if (isStopsValid) {
            this.mainDataSource
                .search({ stops: routeData, short_dist_profile: lookupShortProfile, prac_dist_profile: lookupPracticalProfile })
                .then(() => {
                    const response = this.mainDataSource.data[0]?.data;
                    this.whenResponseNull(response, fetchOtherResults);
                    this.showMilesAndTollAmount(response, layoutDistanceCalculator);
                    this.getTotalRatesVendor(routeData, equipmentId, fetchOtherResults, totalRatesSummary, response?.practical_mileage, marginPercentage, excludeFuelSurcharge, response?.practical_toll_amount.amount, lookupPracticalProfile);
                    this.populateMap(response, routeData);
                });
        } else {
            this.resetButtons();
        }
    }

    private getTotalRatesVendor(routeData: any[], equipmentId: string, fetchOtherResults: boolean, layoutTotalRates: RateIndexDataSummary, mileage: string, marginPercentage: string, excludeFuelSurcharge: boolean, tolls: string, profile: string) {
        if (this._forceRefresh)
            layoutTotalRates.hideColumns();

        this.fetchAllRates(routeData, equipmentId, fetchOtherResults, layoutTotalRates, mileage, marginPercentage, excludeFuelSurcharge, tolls, profile)
            .then(() => {
                this.resetButtons();
            });
    }

    async fetchAllRates(routeData: any[], equipmentId: string, fetchOtherResults: boolean, layoutTotalRates: RateIndexDataSummary, mileage: string, marginPercentage: string, excludeFuelSurcharge: boolean, tolls: string, profile: string): Promise<any> {
        if (this.getShowAllRatesBoolean) {
            return await Promise.all([
                fetchRateIndexResults(this, RATE_VENDORS.TRUCKSTOP, routeData, equipmentId, fetchOtherResults, layoutTotalRates, mileage, marginPercentage, excludeFuelSurcharge, tolls, profile, this.currentRateIndexIdMap.get(RATE_VENDORS.TRUCKSTOP) ?? null, this._forceRefresh),
                fetchRateIndexResults(this, RATE_VENDORS.DAT, routeData, equipmentId, fetchOtherResults, layoutTotalRates, mileage, marginPercentage, excludeFuelSurcharge, tolls, profile, this.currentRateIndexIdMap.get(RATE_VENDORS.DAT) ?? null, this._forceRefresh),
                fetchRateIndexResults(this, RATE_VENDORS.MPACT, routeData, equipmentId, fetchOtherResults, layoutTotalRates, mileage, marginPercentage, excludeFuelSurcharge, tolls, profile, this.currentRateIndexIdMap.get(RATE_VENDORS.MPACT) ?? null, this._forceRefresh),
                fetchRateIndexResults(this, RATE_VENDORS.FREIGHT_WAVES, routeData, equipmentId, fetchOtherResults, layoutTotalRates, mileage, marginPercentage, excludeFuelSurcharge, tolls, profile, this.currentRateIndexIdMap.get(RATE_VENDORS.FREIGHT_WAVES) ?? null, this._forceRefresh),
                fetchRateIndexResults(this, RATE_VENDORS.GREENSCREENS, routeData, equipmentId, fetchOtherResults, layoutTotalRates, mileage, marginPercentage, excludeFuelSurcharge, tolls, profile, this.currentRateIndexIdMap.get(RATE_VENDORS.GREENSCREENS) ?? null, this._forceRefresh)
            ]);
        } else {
            return await Promise.all([
                fetchRateIndexResults(this, RATE_VENDORS.MPACT, routeData, equipmentId, fetchOtherResults, layoutTotalRates, mileage, marginPercentage, excludeFuelSurcharge, tolls, profile, this.currentRateIndexIdMap.get(RATE_VENDORS.MPACT) ?? null, this._forceRefresh)
            ]);
        }
    }

    private changeLabelDisplayType(labelName: Label, vendorURL: string, setDisplayType: boolean) {
        if (setDisplayType) {
            labelName.displayType = DisplayType.LINK;
            labelName.link = vendorURL;
        } else {
            labelName.displayType = null;
            labelName.link = vendorURL;
        }
    }

    private resetButtons() {
        this.buttonFetchOtherResults.caption = 'Fetch Other Results';
        this.enableButtons(true);
        this.buttonFetchOtherResults.busy = false;
        this.buttonFetchOtherResults.imageName = null;
        this.buttonCalculate.enabled = true;
        this.buttonCalculate.busy = false;
        this.buttonCalculate.backgroundColor = "primary";
        this.buttonCalculate.color = "McLeodWhite";
    }

    private showMilesAndTollAmount(response: any, layoutDistanceCalculator: DistanceCalculatorOverview) {

        const lowerValue = "A toll amount in blue indicates a lower or equal toll amount.";
        const higherValue = "A toll amount in grey indicates a higher toll amount.";
        let quickInfoValueShort;
        let quickInfoValuePractical;

        const shortTollAmount = response?.short_toll_amount;
        layoutDistanceCalculator.labelShortMilesCalculateValue.caption = response?.short_mileage;
        if (shortTollAmount)
            layoutDistanceCalculator.labelShortTollAmntCalculateValue.caption = shortTollAmount.symbol + shortTollAmount.amount;
        const colorShort = response?.short_toll_amount_color;
        layoutDistanceCalculator.labelShortTollAmntCalculateValue.color = colorShort;

        const practicalTollAmount = response?.practical_toll_amount;
        layoutDistanceCalculator.labelPracticalMilesCalculateValue.caption = response?.practical_mileage;
        if (practicalTollAmount)
            layoutDistanceCalculator.labelPracticalTollAmntCalculateValue.caption = practicalTollAmount.symbol + practicalTollAmount.amount;
        const colorPractical = response?.practical_toll_amount_color;
        layoutDistanceCalculator.labelPracticalTollAmntCalculateValue.color = colorPractical;

        if (colorShort === "default.dark") {
            quickInfoValueShort = higherValue;
        }
        if (colorShort === "primary") {
            quickInfoValueShort = lowerValue;
        }

        if (colorPractical === "default.dark") {
            quickInfoValuePractical = higherValue;
        }
        if (colorPractical === "primary") {
            quickInfoValuePractical = lowerValue;
        }

        const quickInfoShort = new Label({ id: "hoverIdTwelve", caption: quickInfoValueShort, rowBreak: true });
        const quickInfoPractical = new Label({ id: "hoverIdTwelve", caption: quickInfoValuePractical, rowBreak: true });

        this.distanceUtil.setQuickInfoTooltip(layoutDistanceCalculator.labelShortTollAmntCalculateValue as Label, quickInfoShort);
        this.distanceUtil.setQuickInfoTooltip(layoutDistanceCalculator.labelPracticalTollAmntCalculateValue as Label, quickInfoPractical);
    }

    private whenResponseNull(response: any, fetchOtherResults: boolean) {
        if (response == null) {
            Snackbar.showSnackbar(`Response cannot be null`);
            this.resetButtons();
            return;
        }
    }

    private validationLookUpModel(shortProfile: string, practicalProfile: string) {
        if (StringUtil.isEmptyString(shortProfile)) {
            CommonDialogs.showError("Short Distance Profile cannot be null or empty");
            return;
        }

        if (StringUtil.isEmptyString(practicalProfile)) {
            CommonDialogs.showError("Practical Distance Profile cannot be null or empty");
            return;
        }
    }

    private setEquipmentId() {
        const selectedEquipmentId = this.textboxTrailerType.text;
        let equipmentId = '';
        if (selectedEquipmentId === EQUIPMENT_TYPE_VAN)
            equipmentId = 'V';
        else if (selectedEquipmentId == EQUIPMENT_TYPE_FLAT)
            equipmentId = 'F';
        else if (selectedEquipmentId == EQUIPMENT_TYPE_REEFER)
            equipmentId = 'R';
        else if (selectedEquipmentId == EQUIPMENT_TYPE_OTHER)
            equipmentId = 'O';
        return equipmentId;
    }

    clear() {
        this.mainDataSource.clear();
        this.sourceStops.clear();
        if (this.tableStops.data != null) {
            const stopCityState = this.tableStops.quickAddRow.findComponentById("stopCityStateZipCode") as CityState;
            stopCityState.textCombined.text = "";
            stopCityState.textCombined["_clearLookupModelData"]();
        }
        this.mapRoute.clearRoute();
        this.initializeStopTable();
        this.displayData(null, null, 0);
        this.tableStops.data = [];
        this.labelMapinfo.caption = "";
        this.mapRoute.visible = true;
        const totalRatesSummary = this.layoutTotalRatesSummary as RateIndexDataSummary;
        this.clearTotalOverviewLayout();
        this.clearTotalRatesSummaryLayout(totalRatesSummary);
        totalRatesSummary.resetTotalRatesPanel();
        this.clearButtonStyles();
        this._forceRefresh = true;
        this._calculationDisplayed = false;
        this.currentRateIndexIdMap.clear();
        this.textboxMarginPercentage.text = this._userMarginPercentage;
        this.textboxTrailerType.text = this.textboxTrailerType.items[0];
    }

    private clearButtonStyles() {
        this.buttonCalculate.enabled = true;
        this.buttonCalculate.busy = false;
        this.buttonCalculate.backgroundColor = "primary";
        this.buttonCalculate.color = "McLeodWhite";
        this.buttonFetchOtherResults.caption = 'Fetch Other Results';
        this.buttonFetchOtherResults.enabled = true;
        this.buttonFetchOtherResults.imageName = null;
    }

    private clearTotalRatesSummaryLayout(totalRatesSummary: RateIndexDataSummary) {
        totalRatesSummary.setDefaultValues();
        this.resetTheLabelDisplayType(totalRatesSummary);
    }

    private resetTheLabelDisplayType(totalRatesSummary: RateIndexDataSummary) {
        this.changeLabelDisplayType(totalRatesSummary.labelMPactBuyRateHeader as Label, null, false);
        this.changeLabelDisplayType(totalRatesSummary.labelMPactSellRateHeader as Label, null, false);
        this.changeLabelDisplayType(totalRatesSummary.labelScacBuyRateHeader as Label, null, false);
        this.changeLabelDisplayType(totalRatesSummary.labelScacSellRateHeader as Label, null, false);
        this.changeLabelDisplayType(totalRatesSummary.labelDATSpotRateHeader as Label, null, false);
        this.changeLabelDisplayType(totalRatesSummary.labelDATContractRateHeader as Label, null, false);
        this.changeLabelDisplayType(totalRatesSummary.labelFreightWavesRateHeader as Label, null, false);
        this.changeLabelDisplayType(totalRatesSummary.labelTruckstopPostedRateHeader as Label, null, false);
        this.changeLabelDisplayType(totalRatesSummary.labelTruckstopPaidRateHeader as Label, null, false);
    }

    private clearTotalOverviewLayout() {
        const layoutDistanceCalculator = this.layoutDistanceCalculationOverview as DistanceCalculatorOverview;
        layoutDistanceCalculator.sourceDistanceControl.clear();
        layoutDistanceCalculator.sourceDistanceControl.search();
        const lookupShortProfile = layoutDistanceCalculator.sourceDistanceControl.activeRow?.get("short_dist_profile");
        const lookupPracticalProfile = layoutDistanceCalculator.sourceDistanceControl.activeRow?.get("prac_dist_profile");
        layoutDistanceCalculator.textboxShortDistProfile.text = lookupShortProfile;
        layoutDistanceCalculator.textboxPracticalProfile.text = lookupPracticalProfile;
        layoutDistanceCalculator.labelShortMilesCalculateValue.caption = "---";
        layoutDistanceCalculator.labelShortTollAmntCalculateValue.caption = "---";
        layoutDistanceCalculator.labelShortTollAmntCalculateValue.color = "#252525";
        layoutDistanceCalculator.labelPracticalMilesCalculateValue.caption = "---";
        layoutDistanceCalculator.labelPracticalTollAmntCalculateValue.caption = "---";
        layoutDistanceCalculator.labelPracticalTollAmntCalculateValue.color = "#252525";

        // Remove HoverToDiscover
        layoutDistanceCalculator.labelShortTollAmntCalculateValue.tooltipCallback = null;
        layoutDistanceCalculator.labelShortTollAmntCalculateValue.tooltip = null;
        layoutDistanceCalculator.labelPracticalTollAmntCalculateValue.tooltipCallback = null;
        layoutDistanceCalculator.labelPracticalTollAmntCalculateValue.tooltip = null;

    }

    /** This is an event handler for the onRowDisplay event of tableStops.  */
    tableStopsOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.getTableRow();
        const cityState: CityState = tableRow.findComponentById("stopCityStateZipCode") as CityState;
        cityState.textCombined.onSelectItem = (textbox: Textbox, _selectedItem: any) => {
            textbox.tooltipCallback = null;
            tableRow.data.set("city_id", _selectedItem.get("id"));
            tableRow.data.set("city_name", _selectedItem.get("name"));
            tableRow.data.set("state", _selectedItem.get("state_id"));
            tableRow.data.set("zip_code", _selectedItem.get("zip_code"));
            tableRow.data.set("latitude", _selectedItem.get("latitude"));
            tableRow.data.set("longitude", _selectedItem.get("longitude"));

            if (tableRow.mode != TableRowMode.QUICK_ADD) {
                if (this.currentRateIndexIdMap != undefined && this.currentRateIndexIdMap.size != 0) {
                    this.currentRateIndexIdMap.clear();
                }
                if (this._calculationDisplayed)
                    this.buttonCalculateOnClick();
            }

            return null;
        };

        if (tableRow.mode != TableRowMode.QUICK_ADD) {
            cityState.addHandlerForKey(Keys.ENTER, null, () => this.buttonCalculateOnClick());
            cityState.required = true;
        }
        else {
            cityState.textCombined.placeholder = "Add another stop...";
            cityState.required = false;
            const toolsPanel = tableRow.toolsPanel as Panel;
            toolsPanel.paddingTop = 5;
            toolsPanel.paddingRight = 5;

            cityState.textCombined.addHandlerForKey(Keys.ENTER, null, () => {
                this.handleCityStateEnterKey(tableRow);
            });
            cityState.textCombined.addHandlerForKey(Keys.ENTER, { ctrlKey: true }, () => {
                this.handleCityStateEnterKey(tableRow);
            });

            cityState.textCombined.addHandlerForKey(Keys.TAB, null, () => {
                this.handleCityStateEnterKey(tableRow);
            });
        }

        this.updatePlaceholders();
    }

    private handleCityStateEnterKey(tableRow: TableRow) {
        if (StringUtil.isEmptyString(tableRow.data.get("city_id")))
            this.buttonCalculateOnClick();
        else {
            tableRow.persistQuickAddRow();
        }
    }

    private updatePlaceholders() {
        this.tableStops.rows.forEach(row => {
            const cityState: CityState = row.findComponentById("stopCityStateZipCode") as CityState;
            if (cityState) {
                const index = row.index;
                cityState.textCombined.placeholder = `Stop [${index + 1}] - ZipCode`;
            }
        });
    }

    /** This is an event handler for the onClick event of buttonCalculate.  */
    buttonCalculateOnClick() {
        if (this.textboxMarginPercentage.validateSimple(true, true) == false) {
            this.textboxMarginPercentage.focus();
        } else {
            const stopData = this.sourceStops.data.map(value => value.data);
            this.enableButtons(false);
            this.setShowAllRatesBoolean = false;
            this.buttonCalculate.busy = true;
            this.buttonCalculate.backgroundColor = "subtle.lightest";
            this.buttonCalculate.color = "default.lighter";
            this.calculate(stopData, this.getShowAllRatesBoolean);
        }
    }

    private enableButtons(value: boolean) {
        this.buttonCalculate.enabled = value;
        this.buttonFetchOtherResults.enabled = value;
        this.tableStops.rows.forEach(row => {
            row.findComponentById("stopCityStateZipCode").enabled = value;
            row.toolsPanel.enabled = value;
        })
        this.tableStops.quickAddRow.findComponentById("stopCityStateZipCode").enabled = value;
        this.tableStops.quickAddRow.toolsPanel.enabled = value;
        this.clearStops.enabled = value;
        this.textboxTrailerType.enabled = value;
        this.textboxMarginPercentage.enabled = value;
        this.switchExcludeFuelSurcharge.enabled = value;
        this.layoutDistanceCalculationOverview.textboxShortDistProfile.enabled = value;
        this.layoutDistanceCalculationOverview.textboxPracticalProfile.enabled = value;
    }

    /** This is an event handler for the onClick event of button Fetch Other Results.  */
    buttonFetchResultsOnClick() {
        this.currentRateIndexIdMap.clear();
        this._forceRefresh = true;
        this.setShowAllRatesBoolean = true;
        this.fetchResults();
    }

    buttonFetchVendorResultsOnClick(showAllRates: boolean = true, forceRefresh: boolean = true) {
        this.setShowAllRatesBoolean = showAllRates;
        this._forceRefresh = forceRefresh;
        this.fetchResults();
    }

    fetchResults() {
        const stopData = this.sourceStops.data.map(value => value.data);
        this.enableButtons(false);
        this.buttonCalculate.backgroundColor = "subtle.lightest";
        this.buttonCalculate.color = "default.lighter";
        this.buttonFetchOtherResults.caption = 'Loading ...';
        this.buttonFetchOtherResults.busy = true;
        this.buttonFetchOtherResults.imageName = 'padlock';
        this.calculate(stopData, this.getShowAllRatesBoolean);
    }

    public get getShowAllRatesBoolean(): boolean {
        return this._showAllTotalRates;
    }

    public set setShowAllRatesBoolean(value: boolean) {
        this._showAllTotalRates = value;
    }

    private showMap() {
        if (this.distCalcMapVendor == GoogleMapVendor) {
            const googleApiKey = getUnauthSettings().company_settings.google_api_key;
            if (googleApiKey) {
                this.mapRoute.visible = true;
            }
        }
        else if (this.distCalcMapVendor == TrimbleAlkMapVendor) {
            this.mapRoute.visible = true;
        }
        else {
            this.mapRoute.visible = false;
        }
        this.mapRoute.fitPins()
    }

    private populateMap(response: any, routeData: any[]) {
        if (!response)
            return;

        const practicalDistanceVendor = response.practical_distance_vendor;
        this.isPcMilerVendor = [PCMILER, WEBPCMILER, MULTI_VERSION_PCMILER].includes(practicalDistanceVendor);
        if (this.distCalcMapVendor == GoogleMapVendor && this.isPcMilerVendor) {
            routeData["trimble_polyline_data"] = response?.trimble_route;
            this.labelMapinfo.caption = "Red - Trimble truck route       Blue - Google non-truck route";
            this.labelMapinfo.visible = true;
        }
        else if (this.distCalcMapVendor == GoogleMapVendor) {
            this.labelMapinfo.caption = "This is not a truck-based route";
            this.labelMapinfo.visible = true;
        }

        if (response) {
            this.updateMap(routeData);
        }
    }

    private updateMap(routeData: any[]) {
        routeData.sort((a, b) => {
            return a.order_sequence - b.order_sequence;
        });
        if (this.distCalcMapVendor == GoogleMapVendor) {
            this.mapRoute.createRoute(routeData, RouteType.Google);
            if (this.isPcMilerVendor) {
                this.mapRoute.createRoute(routeData["trimble_polyline_data"], RouteType.Trimble);
            }
        }
        else {
            const isTrafficEnabled = getAuthSettings()?.user_settings?.trimble_map_traffic;
            this.mapRoute.createRoute(routeData);
            if (isTrafficEnabled === "Y")
                this.mapRoute.setStopZoomLevel(isTrafficEnabled, routeData);
        }
    }

    stopsTableOnTableRowMove(event: TableRowMoveEvent) {
        this.handleTableRowChange(event?.oldIndex, event?.newIndex);
    }

    private handleTableRowChange(oldRowIndex: number, newRowIndex: number) {
        this.currentRateIndexIdMap.clear();
        if (oldRowIndex === undefined || newRowIndex === undefined)
            return;

        this.buttonCalculateOnClick();
    }

    private setMarginPercentage(): string {
        const marginPercentage = this.textboxMarginPercentage.text;
        return marginPercentage;
    }

    textboxMarginPercentageOnBlur(event: BlurEvent) {
        if (StringUtil.isEmptyString(this.textboxMarginPercentage.text))
            this.textboxMarginPercentage.text = "0";

        const margin = Number(this.textboxMarginPercentage.text);
        if (this.textboxMarginPercentage.validateSimple(true, true) == false || isNaN(margin) || margin < 0) {
            this.textboxMarginPercentage.required = true;
            this.textboxMarginPercentage.focus();
            this.textboxMarginPercentage.validationWarning = "Please enter a positive number greater than or equal to zero";
            this.buttonCalculate.enabled = false;
            this.buttonFetchOtherResults.enabled = false;
        } else {
            this.textboxMarginPercentage.required = false;
            this.buttonCalculate.enabled = true;
            this.buttonFetchOtherResults.enabled = true;
            this.textboxMarginPercentage.validationWarning = null;
            this._forceRefresh = false;
            if (this.currentRateIndexIdMap != undefined && this.currentRateIndexIdMap.size != 0 && event.changedWhileFocused) {
                this.buttonFetchVendorResultsOnClick(this._showAllTotalRates, false);
            }
        }
    }

    switchExcludeFuelSurchargeOnClick() {
        if (this.currentRateIndexIdMap != undefined && this.currentRateIndexIdMap.size != 0)
            this.buttonFetchVendorResultsOnClick(this._showAllTotalRates, false);
    }

    public setCurrentRateIndexId(vendor: string, id: string) {
        this.currentRateIndexIdMap.set(vendor, id);
    }

    public get cachedPracticalProfile(): string {
        return this._cachedPracticalProfile;
    }

    public set cachedPracticalProfile(value: string) {
        this._cachedPracticalProfile = value;
    }

    public get cachedTrailerType(): string {
        return this._cachedTrailerType;
    }

    public set cachedTrailerType(value: string) {
        this._cachedTrailerType = value;
    }

    focusCityStateTextbox(row: TableRow) {
        if (this._calculationDisplayed)
            this.buttonCalculateOnClick();

        (row.findComponentById("stopCityStateZipCode") as CityState)?.textCombined?.focus();
    }
}

export async function fetchRateIndexResults(calculator: DistanceRateIndexCalculator, vendor: string, routeData: any[], equipmentId: string, fetchOtherResults: boolean, layoutTotalRates: RateIndexDataSummary, mileage: string, marginPercentage: string, excludeFuelSurcharge: boolean, tolls: string, profile: string, currentRateId: string, forceRefresh: boolean): Promise<any> {
    return await Api.search("lme/dispatch/rate-index-results-data",
        {
            stops: routeData,
            equipment_id: equipmentId,
            show_all_total_rates: fetchOtherResults,
            record_id: currentRateId,
            force_refresh: forceRefresh,
            run_type: 3,
            mileage: mileage,
            tolls: tolls,
            margin_percentage: marginPercentage,
            exclude_fuel_surcharge: excludeFuelSurcharge,
            vendor: vendor
        })
        .then((response) => {
            const data = response?.data[0];
            log.debug("Results for vendor: " + vendor);
            const scacCode = data.show_company_scac;
            for (const vendorData of data.vendors_data) {
                layoutTotalRates.populateRateIndexResultData(vendorData, fetchOtherResults, scacCode, profile)
                calculator.setCurrentRateIndexId(vendor, vendorData?.movement_id);
            }

            Promise.resolve();
        })
}
