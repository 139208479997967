/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelAgency.ts                        *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/general/agency";

export class AutogenModelAgency<RowType extends ModelRow> extends Endpoint<RowType, RowAgencyFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowAgency extends ModelRow<RowAgencyFields> {
    constructor(values?: Partial<RowAgencyFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowAgencyFields {
    address1: string;
    address2: string;
    agency_name: string;
    city: string;
    company_id: string;
    fax_number: string;
    id: string;
    is_active: string;
    payee: string;
    phone_number: string;
    revenue_code_id: string;
    salesperson_id: string;
    seg_allocation_cod: string;
    state: string;
    website: string;
    zip: string;
}

