import { AutogenLayoutLogReaderPopout } from "./autogen/AutogenLayoutLogReaderPopout";
import { ChartLogReaderStat } from "./ChartLogReaderStat";

export class LogReaderPopout extends AutogenLayoutLogReaderPopout {
    override onLoad() {
        this.layoutChart.addLayoutLoadListener(() => {
            // since we assume that we are being passed this data, we should remove the chart and display a message telling them they shouldn't refresh the page
            if (window["passedLogReaderData"] != null) {
                const chart = (this.layoutChart as ChartLogReaderStat);
                chart.data = window["passedLogReaderData"];
                chart.detailContainer = this;
                window["passedLogData"] = null; // after we use it, clear it out
            }
        });
    }
}
