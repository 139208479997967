import { ClickEvent, Component, ComponentSearcher, Label, Tab, TableRow, TableRowDisplayEvent } from "@mcleod/components";
import { SelectionType } from "@mcleod/components/src/base/SelectionMode";
import { getLogger } from "@mcleod/core";
import { Customer } from "../../ar/src/Customer";
import { Orders } from "../../dispatch/src/Orders";
import { BrokerageMovementTable } from "./BrokerageMovementTable";
import { BrokerageMovementTableType } from "./BrokerageMovementTableType";
import { BrokeragePlanning } from "./BrokeragePlanning";
import { MovementStatus, rowMinuteUpdate, setTooltipCallbacks, setupBrkStatusPanel, tableSearcherCreationCallback } from "./BrokeragePlanningUtil";
import { AutogenLayoutBrokeragePlanningPeriscope } from "./autogen/AutogenLayoutBrokeragePlanningPeriscope";

const log = getLogger("lme.powerbroker.BrokeragePlanningPeriscope");

export class BrokeragePlanningPeriscope extends AutogenLayoutBrokeragePlanningPeriscope {
    private _type: BrokerageMovementTableType;
    private _brokeragePlanning: BrokeragePlanning;

    configureTableForType(type: BrokerageMovementTableType) {
        this._type = type;
        this.tableMovements.dataSource.orderBy = this._type.defaultSort;
    }

    public set brokeragePlanning(planning: BrokeragePlanning) {
        this._brokeragePlanning = planning;
    }

    public get type(): BrokerageMovementTableType {
        return this._type;
    }

    public get filter() {
        return this.type.filter;
    }

    public async updateFilterCounts() {
        return;
    }

    tableMovementsOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.getTableRow();
        const modelRow = tableRow.data;
        const orderIdLabel = tableRow.findComponentById("labelOrderId") as Label;
        if (orderIdLabel != null) {
            orderIdLabel.addUnderlineWhenMouseOver();
        }
        const customerLabel = tableRow.findComponentById("labelCustomer") as Label;
        if (customerLabel != null) {
            customerLabel.addUnderlineWhenMouseOver();
        }
        tableRow.addDblClickListener(event => {
            const movementStatus = modelRow.get("move_status");
            const orderId = modelRow.get("order_id");
            let selectedTab: Tab;
            log.debug(movementStatus)
            switch (movementStatus) {
                case MovementStatus.AVAILABLE:
                    selectedTab = this._brokeragePlanning.tabAvailableOrders;
                    break;
                case MovementStatus.COVERED:
                    selectedTab = this._brokeragePlanning.tabCoveredOrders;
                    break;
                case MovementStatus.PROGRESS:
                    selectedTab = this._brokeragePlanning.tabInProgressOrders;
                    break;
                case MovementStatus.DELIVERED:
                    selectedTab = this._brokeragePlanning.tabDeliveredOrders;
                    break;
                default:
            }
            if (selectedTab) {
                selectedTab.select();
                const layout = selectedTab.components[0] as BrokerageMovementTable;
                const selectedRow = layout.tableMovements.rows.find((row: TableRow) => row.data?.get("order_id") === orderId);
                if (selectedRow != null) {
                    layout.tableMovements.selectRow(selectedRow, SelectionType.SINGLE, event);
                    layout.tableMovements.displayRow(selectedRow, layout.tableMovements.selectedIndex);
                    layout.tableMovements.scrollToSelection();
                }
            }
        })
        setupBrkStatusPanel(tableRow);
        this.tableMovements.addlSearcherCallback = (): ComponentSearcher[] => tableSearcherCreationCallback();
        rowMinuteUpdate(tableRow, this.type);
        tableRow.forEveryChildComponent(comp => {
            setTooltipCallbacks(comp, modelRow);
        });
    }

    orderNumberOnClick(event: ClickEvent) {
        Orders.navigateTo((event.target as Label).caption);
    }

    customerIdOnClick(event: ClickEvent) {
        const row: TableRow = TableRow.getContainingTableRow(event.target as Component);
        Customer.navigateTo(row.data.get("customer_id"));
    }
}
