import { ArrayUtil, HorizontalAlignment, SortDirection, StringUtil } from "@mcleod/core";
import { ImageNames } from "@mcleod/images";
import { Label } from "../label/Label";
import { LabelProps } from "../label/LabelProps";
import { Panel } from "../panel/Panel";

export class SortSelector extends Panel {
    private _fieldName: string; //will be null for parent selectors
    private _fieldLabel: Label;
    private _orderLabel: Label;
    private _parentSelector: SortSelector;
    private _childSelectors: SortSelector[] = [];

    constructor(fieldName: string, labelProps: Partial<LabelProps>, parentSelector?: SortSelector) {
        super({ id: "sortSelector", padding: 0, margin: 0, fillRow: true }, true);
        this._fieldName = fieldName;
        this._createFieldLabel(labelProps);
        this._createOrderLabel();
        this.add(this._fieldLabel);
        this.add(this._orderLabel);
        this._parentSelector = parentSelector;
        this._parentSelector?._addChildSelector(this);
    }

    get fieldLabel(): Label {
        return this._fieldLabel;
    }

    override set align(value: HorizontalAlignment) {
        if (value === HorizontalAlignment.RIGHT)
            this._fieldLabel.fillRow = true;
        this._fieldLabel.align = value;
    }

    override get align(): HorizontalAlignment {
        return this._fieldLabel.align;
    }

    get fieldName(): string {
        return this._fieldName;
    }

    set fieldName(value: string) {
        this._fieldName = value;
    }

    getChildSelector(fieldName: string): SortSelector {
        if (ArrayUtil.isEmptyArray(this._childSelectors))
            return null;
        for (const childSelector of this._childSelectors) {
            if (childSelector.fieldName === fieldName)
                return childSelector;
        }
        return null;
    }

    private _createFieldLabel(props: Partial<LabelProps>) {
        this._fieldLabel = new Label({
            themeKey: "table.sortSelectorFieldLabel",
            rowBreak: false,
            ...props,
        });
    }

    private _createOrderLabel() {
        this._orderLabel = new Label({
            themeKey: "table.sortSelectorOrderLabel",
            marginTop: this._fieldLabel.marginTop
        });
    }

    public update(sort: SortDirection, order: number, displayOrder: boolean) {
        this._updateImageAndFontColor(sort);
        this._updateOrder(order, displayOrder);
        this._updateParentSelector(sort, order, displayOrder);
    }

    private _updateImageAndFontColor(sort: SortDirection) {
        if (sort === "asc") {
            this._fieldLabel.imageName = ImageNames.ascending;
            this._fieldLabel.color = "McLeodLinkSpecial";
        }
        else if (sort === "desc") {
            this._fieldLabel.imageName = ImageNames.descending;
            this._fieldLabel.color = "McLeodLinkSpecial";
        }
        else if (StringUtil.isEmptyString(sort)) {
            this._fieldLabel.imageName = null;
            this._fieldLabel.color = "McLeodSecondary";
        }
    }

    private _updateOrder(order: number, displayOrder: boolean) {
        if (displayOrder !== true)
            this._orderLabel.caption = "";
        else
            this._orderLabel.caption = order.toString();
    }

    private _addChildSelector(childSelector: SortSelector) {
        if (this._childSelectors == null)
            this._childSelectors = [];
        this._childSelectors.push(childSelector);
    }

    private _updateParentSelector(sort: SortDirection, order: number, displayOrder: boolean) {
        if (this._parentSelector == null)
            return;
        const sortedChildren = this._parentSelector._getSortedChildren();
        if (sortedChildren.length > 1) {
            this._parentSelector._fieldLabel.imageName = ImageNames.sorting_off;
            this._parentSelector._orderLabel.caption = "M";
        }
        else
            this._parentSelector.update(sort, order, displayOrder);
    }

    private _getSortedChildren(): SortSelector[] {
        const result = [];
        for (const child of this._childSelectors) {
            if (child._fieldLabel.imageName != null)
                result.push(child);
        }
        return result;
    }
}
