import { CommonDialogs } from "@mcleod/common";
import { Layout } from "@mcleod/components";

export class DemoYesNoDialog extends Layout {
    getDemoPath() {
        return ["Dialogs", "Yes/no"];
    }

    buttonYesNoClicked() {
        CommonDialogs.showYesNo("Are you sure you want to continue?").then(result => {
            if (result === true)
                CommonDialogs.showMessage("All your base are belong to us.");
            else if (result === false)
                CommonDialogs.showMessage("Good idea.");
            else if (result === undefined)
                CommonDialogs.showMessage("What's the matter, can't make a decision?")
        });
    }
}
