import { Button, CityState, DataSource, HorizontalSpacer, Image, Label, Layout, Panel, Textbox } from "@mcleod/components";
import { StopProgress } from "../StopProgress";

export abstract class AutogenLayoutOrderOverview extends Layout {
    buttonMoveDetails: Button;
    citystateCustomer: CityState;
    horizontalspacer1: HorizontalSpacer;
    inStatusErrorImage: Image;
    inTenderErrorImage: Image;
    label4: Label;
    labelAddress1: Label;
    labelAddress2: Label;
    labelBillDistanceUm: Label;
    labelBillingDistance: Label;
    labelBillingDistanceCaption: Label;
    labelContactEmail: Label;
    labelContactPhone: Label;
    labelDataFusion: Label;
    labelEquipmentTypeId: Label;
    labelInLoadTenderCount: Label;
    labelInTenders: Label;
    labelInboundShipmentStatuses: Label;
    labelInboundStatusCount: Label;
    labelMoveStatus: Label;
    labelMovementStatus: Label;
    labelOutTenderCount: Label;
    labelOutTenders: Label;
    labelOutboundShipmentStatuses: Label;
    labelOutboundStatusCount: Label;
    labelOverview: Label;
    labelReferenceNumbers: Label;
    labelTempDelim: Label;
    labelTempMax: Label;
    labelTempMin: Label;
    labelTemperatureRange: Label;
    labelTrackingRequired: Label;
    labelTrailerType: Label;
    layoutStopProgress: StopProgress;
    outStatusErrorImage: Image;
    outTenderErrorImage: Image;
    panel15: Panel;
    panel1: Panel;
    panel2: Panel;
    panel3: Panel;
    panel4: Panel;
    panel5: Panel;
    panel6: Panel;
    panel8: Panel;
    panelCommodity: Panel;
    panelDataFusion: Panel;
    panelDelivered: Panel;
    panelOrderInfo: Panel;
    panelRefNbrs: Panel;
    panelStopInfo: Panel;
    panelTempRange: Panel;
    sourceCustomer: DataSource;
    sourceOrders: DataSource;
    textboxBillingDate: Textbox;
    textboxBillingUser: Textbox;
    textboxBolDate: Textbox;
    textboxCommodity: Textbox;
    textboxContactName: Textbox;
    textboxInvoiceNbr: Textbox;
    textboxInvoiceRecvDate: Textbox;
    textboxMargin: Textbox;
    textboxName: Textbox;
    textboxOrderValue: Textbox;
    textboxSalespersonId: Textbox;
    textboxTotalCharge: Textbox;
    textboxTotalPay: Textbox;
    textboxTransferDate: Textbox;
    textboxWeight: Textbox;
}
