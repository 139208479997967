import { Label, Layout, Panel, } from "@mcleod/components";

export abstract class AutogenLayoutCarrierAssignmentProgress extends Layout {
    labelStep1: Label;
    labelStep2: Label;
    labelStep3: Label;
    labelTitleStep1: Label;
    labelTitleStep2: Label;
    labelTitleStep3: Label;
    panel4: Panel;
    panelAssignCarrier: Panel;
    panelIntiateTracking: Panel;
    panelRateCon: Panel;
    panelStep2Line: Panel;
    panelStep3Line: Panel;
}
