import { Currency } from "./Currency";
import { getAuthSettings } from "./Settings";

export interface CurrencySettings {
    multicurrency_enabled: boolean;
    color_negatives: boolean;
    effective_currency_code: string;
    override_conversion_date: Date;
}

export class CurrencyUtil {
    public static getCurrencySettings(): CurrencySettings {
        return getAuthSettings().currency_settings;
    }

    public static updateCurrency(currency: Currency, amount: number, operand?: string): Currency {
        if (!currency) {
            return Currency.createCurrency(amount);
        }
        currency.amount = Number.parseFloat(amount.toFixed(2));
        currency.base_amount = null;
        currency.conversion_rate = null;
        currency.operand = operand;
        return currency;
    }

    public static formatCurrency(currency: Currency): string {
        if (currency == null)
            return null;
        const formatter = new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: currency.currency_code,
        })
        return formatter.format(currency.amount);
    }

    public static isCurrency(value: any): boolean {
        if (value == null)
            return false;
        return value.currency_code != null && (value.base_amount != null || value.amount != null);
    }

    /**
     * This should be returning a Currency object instead of a string, but making it return a string for now since that was how it was used in Textbox
     * @param value
     * @returns
     */
    public static parseCurrency(value: string): string {
        return value.replace(/[\s*\$\s,]*/g, '');
    }

    /**
     * Compare two currency values (objects in the structure of the Currency interface)
     *
     * @param value1 a currency value that makes up one side of the equals
     * @param value2 a currency value that makes up one side of the equals
     * @returns a boolean indicating if the currency values are equal to each other
     */
    public static currencysAreEqual(value1: Currency, value2: Currency): boolean {
        if (value1 == null && value2 == null)
            return true;
        else if (value1 == null || value2 == null)
            return false;

        //wish we could get this list from the Currency interface, but it's an interface...
        const keys = ["amount", "base_amount", "currency_code", "conversion_rate", "conversion_date", "symbol"];
        for (const key of keys) {
            if ((value1[key] == null && value2[key] == null) ||
                value1[key] === value2[key]) {
                continue;
            }
            return false;
        }
        return true;
    }

    /**
     * Compare two currency values (objects in the structure of the Currency interface)
     *
     * @param value1 a currency value
     * @param value2 a currency value
     * @returns 0 if the currency values are equal, -1 if value 1 is less than value 2, or 1 if value 1 is greater than value 2
     */
    public static compareCurrencys(value1: Currency, value2: Currency, sortNullsAtEnd = false): number {
        if (value1 == null && value2 == null)
            return 0;
        else if (value1 == null)
            return -1;
        else if (value2 == null)
            return 1;

        //first try comparing using the base_amount value
        const value1BaseAmount = value1["base_amount"];
        const value2BaseAmount = value2["base_amount"];
        if (value1BaseAmount != null || value2BaseAmount != null) {
            if (value1BaseAmount === value2BaseAmount)
                return 0;
            if (value1BaseAmount == null)
                return -1;
            if (value2BaseAmount == null)
                return 1;
            if (value1BaseAmount < value2BaseAmount)
                return -1;
            if (value1BaseAmount > value2BaseAmount)
                return 1;
        }
        //in case base_amount is not available, we can check using currency_code + amount,
        //but only if both currencys have the same currency_code
        if (value1["currency_code"] === value2["currency_code"]) {
            const value1Amount = value1["amount"];
            const value2Amount = value2["amount"];
            if (value1Amount != null || value2Amount != null) {
                if (value1Amount === value2Amount)
                    return 0;
                if (value1Amount == null)
                    return -1;
                if (value2Amount == null)
                    return 1;
                if (value1Amount < value2Amount)
                    return -1;
                if (value1Amount > value2Amount)
                    return 1;
            }
        }
        return 0;
    }
}
