/**
 * This class is shown when the user tries to access an authenticated page but they are not signed in.
 */

import { Image, Label, Layout, LayoutProps } from "@mcleod/components";
import { AuthType, HorizontalAlignment, VerticalAlignment } from "@mcleod/core";
import { Router } from "./Router";

export class SignInNeeded extends Layout {
    constructor(props: Partial<LayoutProps>) {
        super({
            auth: AuthType.UNAUTH,
            allowDecoratorOverride: false,
            title: "Sign In Needed",
            align: HorizontalAlignment.CENTER,
            fillRow: true,
            verticalAlign: VerticalAlignment.CENTER,
            ...props
        });
        this.add(new Image({ name: "x", height: 140, width: 388, color: "primary", rowBreak: true, marginTop: 64, marginBottom: 48 }));
        this.add(new Label({ caption: "You need to sign in to continue.", color: "primary", fontSize: "xxlarge" }));
        this.add(new Label({ caption: "Sign in", link: Router.loginRoute, linkHardRefresh: true, fontSize: "large" }));
    }
}
