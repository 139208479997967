import { ClickEvent, DataSourceAction, DataSourceExecutionEvent, Table } from "@mcleod/components";
import { ModelRow, StringUtil, getAuthSettings } from "@mcleod/core";
import { LogicBrokeragePlanningPerformance } from "../LogicBrokeragePlanningPerformance";
import { AutogenLayoutTodayForecastOrders } from "./autogen/AutogenLayoutTodayForecastOrders";

export class TodayForecastOrders extends AutogenLayoutTodayForecastOrders {
    onLoad(): void | Promise<void> {
        const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const d = new Date();
        this.labelDayofWeek.caption = weekday[d.getDay()];
        this.labelDayofMonth.caption = '' + d.getDate();
    }

    private styleLastRow(table: Table) {
        table.rows[table.rows.length - 1].color = 'primary';
        table.rows[table.rows.length - 1].fontBold = true;
        table.rows[table.rows.length - 1].fontSize = 'xlarge';
        table.rows[table.rows.length - 1].borderBottomWidth = 0;
    }

    private convertObjectToModelRow(modelUrl: string, data: []): ModelRow[] {
        if (data == null)
            return;
        const modifiedData: ModelRow[] = [];
        for (const d of data) {
            const modelRow = new ModelRow(modelUrl, false, d);
            modifiedData.push(modelRow);
        }
        return modifiedData;
    }

    /** This is an event handler for the afterExecution event of sourceTodayForecast.  */
    sourceTodayForecastAfterExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() === DataSourceAction.SEARCH) {
            const message = this.sourceTodayForecast.data[0]?.get("message");
            if (StringUtil.isEmptyString(message) === true) {
                this.addLayoutLoadListener(event => {
                    const convertedRows = this.convertObjectToModelRow("lme/dispatch/brokerage-planning-today-forecast-orders", this.sourceTodayForecast.data[0]?.get("orders_data"));
                    this.sourceTodayForecastOrders.data = convertedRows;
                    this.sourceTodayForecastOrders.displayDataInBoundComponents();
                    this.styleLastRow(this.tableTodayForecast);
                });
            }
            else {
                // this.labelMessage.visible = true;
            }
        }
    }

    /** This is an event handler for the onClick event of labelTotal.  */
    labelTotalOnClick(event: ClickEvent) {
        LogicBrokeragePlanningPerformance.cellLabelOnClick(event, this.sourceTodayForecast.data[0], getAuthSettings()?.user_settings?.bp_profile ?? "");
    }
}
