import { AutogenLayoutCarrierAssignmentProgress } from "./autogen/AutogenLayoutCarrierAssignmentProgress";

export class CarrierAssignmentProgress extends AutogenLayoutCarrierAssignmentProgress {

    public setStep(step: number) {
        if (step >= 2) {
            this.panelStep2Line.borderTopColor = "primary";
            this.labelStep2.backgroundColor = "primary";
            this.labelStep2.color = "primary.reverse";
            this.labelTitleStep2.color = "primary";
        }
        if (step == 3) {
            this.panelStep3Line.borderTopColor = "primary";
            this.labelStep3.backgroundColor = "primary";
            this.labelStep3.color = "primary.reverse";
            this.labelTitleStep3.color = "primary";
        }
    }
}
