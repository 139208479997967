import { ClickEvent, DataSource, DataSourceExecutionEvent, Label, TableRow } from "@mcleod/components";
import { TableRowMode } from "@mcleod/components/src/components/table/TableRow";
import { EditRowDecorator } from "@mcleod/components/src/page/decorators/EditRowDecorator";
import { ModelRow } from "@mcleod/core";
import { OfferSource } from "@mcleod/dispatch/src/OfferUtil";
import { CarrierOfferSlideout } from "../../dispatch/src/CarrierOfferSlideout";
import { QuickSellDetails } from "../../dispatch/src/QuickSellDetails";
import { MovementCarrierPay } from "./MovementCarrierPay";
import { AutogenLayoutOfferOrder } from "./autogen/AutogenLayoutOfferOrder";
import { OfferField } from "./models/ModelCarrierOffer";

export class OfferOrder extends AutogenLayoutOfferOrder {
    public onSaveCallback: (offerRow: ModelRow) => void;
    private _stopCountText: string;

    public get stopCountText(): string {
        return this._stopCountText;
    }
    public set stopCountText(value: string) {
        this._stopCountText = value;
        (this.layoutQuickSellDetails as QuickSellDetails).textboxPickDrop.text = value;
    }


    override onLoad() {
        this.buttonBlastAllOffers.visible = false;
        this.tableOffers.onEditLayoutLoaded = this._offerLayoutLoaded();
        this.layoutQuickSellDetails.replaceMainDatasource(this.mainDataSource);
    }

    private _offerLayoutLoaded(): (rowDecorator: EditRowDecorator, tableRow: TableRow) => void {
        return (rowDecorator: EditRowDecorator, tableRow: TableRow) => {
            const loCarrierPay = (rowDecorator.layout as CarrierOfferSlideout).loCarrierPay as MovementCarrierPay;
            const loCarrierOffer = (rowDecorator.layout as CarrierOfferSlideout)
            const quickSellDetails = loCarrierOffer.layoutQuickSellDetails as QuickSellDetails;

            quickSellDetails.configureForSlideout(this.stopCountText);
            quickSellDetails.replaceMainDatasource(this.mainDataSource);
            quickSellDetails.displayData(this.mainDataSource.activeRow, null, 0);

            rowDecorator.multiButton.dropdownItems = null;
            rowDecorator.multiButton.primaryButton.paddingLeft = 7;
            rowDecorator.labelTitle.caption = `Quick Offer - Order ${this.mainDataSource.activeRow?.get("orders.id")}`;
            rowDecorator.onSave = (offerRow: ModelRow) => {
                this.onSaveCallback(offerRow);
                (tableRow.findComponentById("lblOffered") as Label).visible = true;
            };

            loCarrierPay.mainDataSource.data[0] = this.mainDataSource.activeRow;
            loCarrierPay.mainDataSource.rowIndex = 0;
            loCarrierPay.mainDataSource.displayDataInBoundComponents();
            loCarrierPay.buttonOverrideTargetPay.enabled = false;
            loCarrierPay.buttonOverrideMaxPay.enabled = false;
            loCarrierOffer.updateMargins(OfferField.OFFER, "offer_margin", loCarrierOffer.tbDispatchMargin, loCarrierOffer.tbDispatchMarginPrcnt, true);
        }
    }

    sourceMovementAfterExecution(event: DataSourceExecutionEvent) {
        if (event?.getAction() == "search") {
            this.searchCarrierOffers((event.target as DataSource)?.activeRow);
        }
    }

    searchCarrierOffers(movement: ModelRow<any>) {
        if (movement?.get("override_payee_id") != null)
            return;
        const movementId = movement?.get("id");
        const orderId = movement?.get("orders.id");

        if (movementId != null && orderId != null) {
            this.sourceCarrierOfferGenerator.search({ movement_id: movementId, order_id: orderId }).then(() => {
                this.sourceCarrierOfferGenerator.data?.forEach(row => row._appending = true);
                this.buttonBlastAllOffers.visible = this.sourceCarrierOfferGenerator.data?.length > 0;
            })
        }
    }

    tableOffersOnRowDisplay(event) {
        const tableRow: TableRow = event.target as TableRow;
        const modelRow = tableRow.data as ModelRow;
        if (modelRow?.get("contact_phone") != null) {
            const labelContactPhone = tableRow.findComponentById("labelContactPhone") as Label;
            labelContactPhone.caption = "Phone:\t" + modelRow?.get("contact_phone");
            labelContactPhone.visible = true;
        }
        if (modelRow?.get("mobile_phone") != null) {
            const labelContactPhone = tableRow.findComponentById("labelCellPhone") as Label;
            labelContactPhone.caption = "Cell:\t" + modelRow?.get("mobile_phone");
            labelContactPhone.visible = true;
        }
    }

    /** This is an event handler for the onClick event of buttonEmail.  */
    buttonEmailOnClick(event: ClickEvent) {
        this.sourceCarrierOfferGenerator.data.forEach(row => row._appending = true);
        this.sourceCarrierOfferGenerator.post();
    }

    buttonBlastAllOffersOnClick(event: ClickEvent) {
        this.tableOffers.rows.forEach(row => this.emailOffer(row));
        this.buttonBlastAllOffers.enabled = false;
    }

    labelEmailOfferOnClick(event: ClickEvent) {
        this.emailOffer(TableRow.getContainingTableRow(event.target as Label));
    }

    emailOffer(tableRow: TableRow) {
        const labelEmailOffer = tableRow.findComponentById("labelEmailOffer") as Label;
        if (labelEmailOffer.visible && labelEmailOffer.enabled) {
            labelEmailOffer.enabled = false;
            const modelRow = tableRow.data as ModelRow;
            tableRow.editRow();
            if (tableRow.validateSimple()) {
                tableRow.mode = TableRowMode.NONE;
                labelEmailOffer.busy = true;
                const offer = modelRow.createBasicCopy();
                offer.set("send_mail", true);
                offer.set("id", null);
                offer.post().then(modelRow => {
                    const labelEmailSent = tableRow.findComponentById("labelEmailSent") as Label;
                    labelEmailOffer.visible = false;
                    labelEmailSent.visible = true;
                }).finally(() => {
                    labelEmailOffer.enabled = true;
                    labelEmailOffer.busy = false;
                });
            } else {
                labelEmailOffer.enabled = true;
            }
        }
    }

    /** This is an event handler for the beforeRowSave event of tableCallRecords.  */
    tableCarrierOfferBeforeRowSave(event) {
        const modelRow = event.getTableRow().data as ModelRow;
        modelRow.set("source", OfferSource.QUICKOFFER);
    }
}
