import { AutogenLayoutTableCustomClassifications } from "./autogen/AutogenLayoutTableCustomClassifications";


export class TableCustomClassifications extends AutogenLayoutTableCustomClassifications {

    customClassificationTableOnRowDisplay(event) {
        const textboxClassificationResult = event.getTableRow().findComponentById("textboxClassificationResult");
        if (textboxClassificationResult.text.toLowerCase().includes('pass')) {
            textboxClassificationResult.color = 'success';
        } else {
            textboxClassificationResult.color = 'error';
        }
    }

}
