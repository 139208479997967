import { AutogenModelCancelMovement, AutogenRowCancelMovement } from "./autogen/AutogenModelUnclearStops";

export class ModelCancelMovement extends AutogenModelCancelMovement<RowCancelMovement> {
    constructor() {
        super(RowCancelMovement);
    }
}

export class RowCancelMovement extends AutogenRowCancelMovement {
}
