import { ErrorHandler } from "./ErrorHandler";
import { Model, ModelSearchResult } from "./Model";
import { ModelRow } from "./ModelRow";

export type RowConstructor<RowType extends ModelRow = ModelRow> = new () => ModelRow;

export abstract class Endpoint<RowType extends ModelRow = ModelRow, FieldInterface = unknown> {
    private modelPath: string;
    private rowConstructor: RowConstructor;

    constructor(modelPath: string, rowConstructor: RowConstructor) {
        this.modelPath = modelPath;
        this.rowConstructor = rowConstructor;
    }

    search(filter?: Partial<FieldInterface>, fieldList?: string, errorHandler?: ErrorHandler): Promise<ModelSearchResult> {
        return Model.search<FieldInterface>(this.modelPath, filter, fieldList, errorHandler, this.rowConstructor);
    }

    searchSingle(filter?: Partial<FieldInterface>, fieldList?: string, errorHandler?: ErrorHandler): Promise<ModelRow> {
        return Model.searchSingleRecord<FieldInterface>(this.modelPath, filter, fieldList, errorHandler, this.rowConstructor);
    }
}
