import { Collection } from "@mcleod/core";
import { Button, ButtonVariant, ClickEvent, DomEvent, Label, Panel, PanelProps } from "../..";
import { ListenerListDef } from "../../base/ListenerListDef";
import { DateTimeChangePart, DateTimePickerChangeEvent, DateTimePickerChangeListener } from "./DateTimePickerChangeEvent";

const _changeListenerDef: ListenerListDef = { listName: "_changeListeners" };

export interface ClockProps extends PanelProps {
    civilianTime: boolean;
    value: Date;
}


/**
 * A component that will display and allow editing a time.
 *
 * The civilianTime property must be set in the constructor. *
 */

export class Clock extends Panel implements ClockProps {
    value: Date;
    hand: Panel;
    selectionIndicator: Panel;
    dot: Panel;
    numberButtons: Button[] = [];
    _editingHours: boolean;
    civilianTime: boolean;
    am: boolean;
    buttonAM: Button;
    buttonPM: Button;

    constructor(props?: Partial<ClockProps>) {
        super({ themeKey: "clock", borderRadius: "50%", width: 260, height: 260, ...props });
        if (this.value == null)
            this.value = new Date();
        this.am = this.value.getHours() < 12;
        this.createSubcomponents();
        this._editingHours = true;
        this.addMountListener(() => {
            this.positionSubcomponents();
            this.updateUI();
        });
    }

    addChangeListener(value: DateTimePickerChangeListener): Clock {
        return this.addEventListener(_changeListenerDef, value) as Clock;
    }

    removeChangeListener(value: DateTimePickerChangeListener): Clock {
        return this.removeEventListener(_changeListenerDef, value) as Clock;
    }

    numberClicked(event: ClickEvent) {
        const value = parseInt((<Label>event.target).caption);
        const oldValue = new Date(this.value);
        if (this.editingHours) {
            if (this.am || !this.civilianTime)
                this.value.setHours(value);
            else
                this.value.setHours(value + 12);
        }
        else
            this.value.setMinutes(value);
        const changeEvent = new DateTimePickerChangeEvent(this, oldValue, this.value, event.domEvent, this.editingHours ? DateTimeChangePart.Hour : DateTimeChangePart.Minute);
        this.editingHours = !this.editingHours;
        this.fireListeners(_changeListenerDef, changeEvent);
    }

    setAM(value: boolean, domEvent?: DomEvent) {
        this.am = value;
        const oldValue = this.value;
        this.value = new Date(oldValue);
        const hours = this.value.getHours();
        if (value && hours >= 12)
            this.value.setHours(hours - 12);
        else if (!value && hours < 12)
            this.value.setHours(hours + 12);
        const changeEvent = new DateTimePickerChangeEvent(this, oldValue, this.value, domEvent, DateTimeChangePart.AmPm);
        this.fireListeners(_changeListenerDef, changeEvent);
        this.updateUI();
    }

    get editingHours() {
        return this._editingHours;
    }

    set editingHours(value: boolean) {
        this._editingHours = value;
        this.fireListeners(_changeListenerDef, new DateTimePickerChangeEvent(this, this.value, this.value, null, null));
        this.updateUI();
    }

    get handDegrees(): number {
        let result: number;
        if (this.value == null)
            result = -90;
        if (this.editingHours)
            result = this.value.getHours() * 30 - 90;
        else
            result = this.value.getMinutes() * 6 - 90;
        return result % 360;
    }

    updateUI() {
        const multiplier = this.editingHours ? 1 : 5;
        const is24Digits = !this.civilianTime && this.editingHours;
        for (let i = 0; i < 24; i++) {
            this.numberButtons[i].caption = ((i + 1) * multiplier).toString();
            this.numberButtons[i].visible = is24Digits || i < 12;
        }
        if (this.editingHours)
            this.numberButtons[23].caption = "00";
        else {
            this.numberButtons[0].caption = "05";
            this.numberButtons[11].caption = "00";
        }
        this.hand.style.transform = "rotate(" + this.handDegrees + "deg)";
        this.hand.width = !this.editingHours || this.value == null || this.civilianTime || this.value.getHours() <= 12 ? 96 : 60;
        this.selectionIndicator.left = this.hand.width;
        if (this.civilianTime) {
            this.buttonAM.backgroundColor = this.am ? "primary" : "unset";
            this.buttonAM.color = this.am ? "primary.reverse" : "unset";
            this.buttonPM.backgroundColor = !this.am ? "primary" : "unset";
            this.buttonPM.color = !this.am ? "primary.reverse" : "unset";
        }
    }

    positionSubcomponents() {
        const bounds = this.element.getBoundingClientRect();
        const centerX = bounds.width / 2 - 20;
        const centerY = bounds.height / 2 - 20;
        const max = this.civilianTime ? 12 : 24;
        for (let i = 0; i < max; i++) {
            const degrees = i * 30 - 60;
            const radians = degrees * (Math.PI / 180);
            const radius = i < 12 ? centerX : centerX - 36;
            this.numberButtons[i].left = centerX + radius * Math.cos(radians);
            this.numberButtons[i].top = centerY + radius * Math.sin(radians);
        }
        this.hand.left = centerX + 18;
        this.hand.top = centerY + 18;
        this.dot.left = centerX + 18;
        this.dot.top = centerY + 16;
        if (this.civilianTime) {
            const commonProps = { width: 28, height: 28, padding: 0, variant: ButtonVariant.round };
            this.buttonAM.setProps({ ...commonProps, top: bounds.height - 32, left: 8 });
            this.buttonPM.setProps({ ...commonProps, top: bounds.height - 32, left: bounds.width - 36 });
        }
    }

    private createSubcomponents() {
        this.hand = new Panel({
            themeKey: "clock.hand",
            style: {
                position: "absolute",
                transformOrigin: "3px",
                transition: "transform 400ms",
                transform: "rotate(-90deg)"
            },
            padding: 0,
            width: 96,
            height: 2
        });
        this.selectionIndicator = new Panel({
            borderRadius: "50%",
            padding: 0,
            themeKey: "clock.selection",
            height: 32,
            width: 32,
            left: 96,
            top: -15,
            style: { position: "absolute", opacity: "0.2" }
        });
        this.hand.add(this.selectionIndicator);
        this.dot = new Panel({
            backgroundColor: "primary",
            padding: 0,
            borderRadius: "50%",
            style: { position: "absolute" },
            width: 6,
            height: 6
        });
        this.add(this.hand, this.dot);
        for (let i = 0; i < 24; i++) {
            const btn = new Button({
                fontSize: "large",
                width: 32,
                height: 32,
                padding: 10,
                variant: ButtonVariant.round,
                themeKey: "clock.numbers",
                style: { position: "absolute" }
            });
            btn.addClickListener(event => this.numberClicked(event));
            this.add(btn);
            this.numberButtons[i] = btn;
        }
        if (this.civilianTime) {
            this.buttonAM = new Button({ caption: "AM", variant: ButtonVariant.round, style: { position: "absolute" } });
            this.buttonPM = new Button({ caption: "PM", variant: ButtonVariant.round, style: { position: "absolute" } });
            this.buttonAM.addClickListener(event => this.setAM(true, event));
            this.buttonPM.addClickListener(event => this.setAM(false, event));
            this.add(this.buttonAM, this.buttonPM);
        }
    }

    override getListenerDefs(): Collection<ListenerListDef> {
        return { ...super.getListenerDefs(), "change": { ..._changeListenerDef } };
    }
}
