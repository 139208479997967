import { ComponentPropDefinitions, ComponentPropDefinitionUtil } from "../../base/ComponentProps";
import { PropType } from "../../base/PropType";
import { PanelProps } from "../panel/PanelProps";
import { layoutSelector } from "../textbox/TextboxProps";

export interface CityStateProps extends PanelProps {
    caption: string;
    captionVisible: boolean;
    cityField: string;
    stateField: string;
    zipField: string;
    cityIdField: string;
    printable: boolean;
    printableDuringAdd: boolean;
    printableDuringSearch: boolean;
    printableDuringUpdate: boolean;
    quickInfoLayout: string;
}

let cityStatePropDefs: ComponentPropDefinitions;

export class CityStatePropDefintions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (cityStatePropDefs == null) {
            cityStatePropDefs = {
                ...ComponentPropDefinitionUtil.getComponentPropertyDefinitions(),
                caption: { type: PropType.string, visibleInMcLeodTailor: true, description: "This controls the label that appears above the CityState component." },
                captionVisible: {
                    type: PropType.bool,
                    defaultValue: true,
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether or not the caption of the Textbox will be shown.  If set to false, there will be no caption or additional whitespace where the caption would normally go."
                },
                cityField: { type: PropType.string, visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "This field to hold the city name." },
                stateField: { type: PropType.string, visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "This field to hold the state." },
                zipField: { type: PropType.string, visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "This field to hold the zip code." },
                cityIdField: { type: PropType.string, visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "This field to hold the city id." },
                printable: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether the CityState component appears as a caption with a user-editable input box or whether it should be displayed as a caption with a non-editable Label.  This is often changed at runtime.",
                },
                printableDuringAdd: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether the CityState component should appear in its printable state during add mode.",
                },
                printableDuringSearch: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether the CityState component should appear in its printable state during search mode.",
                },
                printableDuringUpdate: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether the CityState component should appear in its printable state during update mode.",
                },
                quickInfoLayout: { type: PropType.string, editor: layoutSelector, defaultValue: "common/CitySuggestionQuickInfo", visibleInMcLeodTailor: true, editableInMcLeodTailor: false, description: "Specifies a layout that will be opened as a tooltip when the user hovers over the CityState's 'combined' field.  The mainDataSource of this layout will be searched with the value in the 'combined' field." },
                onChange: { type: PropType.event, category: "Events", addListenerMethod: "addChangeListener", removeListenerMethod: "removeChangeListener", description: "This specifies a function to be called when the value changes." },
            };
            ComponentPropDefinitionUtil.populateComponentPropNames(cityStatePropDefs);
        }
        return cityStatePropDefs;
    }
}
