import { DataLoadActionHandler, DataLoadActionType } from "@mcleod/common";
import { ClickEvent } from "@mcleod/components";
import { Navigation, UrlUtil } from "@mcleod/core";
import { AutogenLayoutShipmentStatusErrors } from "./autogen/AutogenLayoutShipmentStatusErrors";

export class ShipmentStatusErrors extends AutogenLayoutShipmentStatusErrors {
    labelOnClick(event: ClickEvent) {
        const urlParams: string[] = [];
        let tabToNavigateTo: string;
        switch (event?.target?.id) {
            case "labelAvailableCount": {
                tabToNavigateTo = "tabAvailableOrders";
                break;
            }
            case "labelCoveredAmount": {
                tabToNavigateTo = "tabCoveredOrders";
                break;
            }
            case "labelDeliveredCount": {
                tabToNavigateTo = "tabDeliveredOrders";
                break;
            }
            case "labelInProgressAmount": {
                tabToNavigateTo = "tabInProgressOrders";
                break;
            }
        }
        urlParams.push("shipmentStatusError=true")
        urlParams.push(DataLoadActionHandler.createUrlQueryParamString(DataLoadActionType.SELECT_TAB, tabToNavigateTo));

        Navigation.navigateTo("lme/powerbroker/BrokeragePlanning" + UrlUtil.buildQueryString(urlParams));
    }
}