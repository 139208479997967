/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelViewEdiOrder.ts                  *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/datafusion/view-edi-order";

export class AutogenModelViewEdiOrder<RowType extends ModelRow> extends Endpoint<RowType, RowViewEdiOrderFields> {
	constructor(ctor: RowConstructor) {
		super(endpointPath, ctor);
	}
}

export class AutogenRowViewEdiOrder extends ModelRow<RowViewEdiOrderFields> {
	constructor(values?: Partial<RowViewEdiOrderFields>) {
		super(endpointPath, true, values);
	}
}

export interface RowViewEdiOrderFields {
	agency_id: string;
	agent_payee_id: string;
	alt_partner_id: string;
	app_type: string;
	autorate_status: string;
	bill_date: Date;
	bill_distance: number;
	bill_distance_um: string;
	billing_empty_distance: number;
	billing_empty_distance_um: string;
	billing_loaded_distance: number;
	billing_loaded_distance_um: string;
	billing_user_id: string;
	blnum: string;
	bol_received: string;
	booking_no: string;
	booking_number: string;
	bplant: string;
	cancelled_order: string;
	car_address: string;
	car_city: string;
	car_code: string;
	car_code_qualifier: string;
	car_name: string;
	car_pay_method: string;
	car_phone_number: string;
	car_pro_nbr: string;
	car_state: string;
	car_trip_lease: string;
	car_zip_code: string;
	carrier_id: string;
	carrier_tractor: string;
	carrier_trailer: string;
	changed_order: string;
	collection_method: string;
	commitment_id: string;
	commodity: string;
	commodity_id: string;
	company_id: string;
	complete_document: string;
	cons_ediloc: string;
	consignee_ediloc: string;
	consignee_refno: string;
	consignee_stop_id: string;
	container_planned: string;
	container_type_id: string;
	copy_from_company: string;
	copy_from_order_id: string;
	create_date: Date;
	ctrl_party_id: string;
	cube: number;
	curr_movement_id: string;
	currency_iso_id: string;
	currency_type_id: string;
	cust_order_no: string;
	customer_ediloc: string;
	customer_id: string;
	default_match_id: string;
	dim_height: string;
	dim_length: string;
	dim_surcharge: number;
	dim_surcharge_c: string;
	dim_surcharge_d: Date;
	dim_surcharge_n: number;
	dim_surcharge_r: number;
	dim_width: string;
	direction: string;
	dispatcher_user_id: string;
	dray_del_date: Date;
	dray_del_pay: number;
	dray_del_pay_c: string;
	dray_del_pay_d: Date;
	dray_del_pay_n: number;
	dray_del_pay_r: number;
	dray_del_payee: string;
	dray_del_xfer_dt: Date;
	dray_pu_date: Date;
	dray_pu_pay: number;
	dray_pu_pay_c: string;
	dray_pu_pay_d: Date;
	dray_pu_pay_n: number;
	dray_pu_pay_r: number;
	dray_pu_payee: string;
	dray_pu_xfer_dt: Date;
	edi_commodity_id: string;
	edi_equip_type_id: string;
	edi_error: number;
	edi_order_type_id: string;
	edi_revenue_code: string;
	edi_seg_alloc_code: string;
	eir1_no: string;
	eir2_no: string;
	entered_user_id: string;
	equipment_type_id: string;
	est_tolls: number;
	est_tolls_c: string;
	est_tolls_d: Date;
	est_tolls_n: number;
	est_tolls_r: number;
	external_invoiceno: string;
	extra_deliveries: number;
	extra_pickups: number;
	fgp_uid: number;
	fleet_manager: string;
	forwd_location_id: string;
	free_days: number;
	freight_charge: number;
	freight_charge_c: string;
	freight_charge_d: Date;
	freight_charge_n: number;
	freight_charge_r: number;
	fuel_distance: number;
	fuel_distance_um: string;
	func_ack_batch: number;
	func_ack_id: string;
	func_ack_rec: string;
	func_ack_rec_date: Date;
	func_ack_received: string;
	func_ack_status: string;
	gs06_group_cntlno: string;
	gs_date_time: Date;
	hazmat: string;
	hazmat_code: string;
	high_value: string;
	hold_reason: string;
	id: string;
	ignore_map_errors: string;
	image_is_present: string;
	import_export: string;
	include_split_point: string;
	initial_purpose: string;
	intercompany: string;
	intercompany_key: string;
	invoice_recv_date: Date;
	is_autorate_dist: string;
	is_billed: boolean;
	is_container: string;
	is_dedicated: string;
	is_dray: string;
	is_local_mile: string;
	isa13_intr_cntlno: string;
	jag_carrier_id: string;
	jag_cost_rate_id: number;
	ldport_location_id: string;
	linear_feet_avail: number;
	load_date: Date;
	lock_miles: string;
	ltl: string;
	manifest_id: string;
	move_status: string;
	no_display: string;
	nte_lp_num: string;
	nte_lp_qual: string;
	on_hold: string;
	operational_status: string;
	operations_user: string;
	order_compared: string;
	order_id: string;
	order_mode: string;
	order_status: string;
	order_type_id: string;
	order_value: number;
	order_value_c: string;
	order_value_d: Date;
	order_value_n: number;
	order_value_r: number;
	ordered_by: string;
	ordered_date: Date;
	ordered_method: string;
	orig_dest_rate_id: string;
	original_order: string;
	other_pay_total: number;
	other_pay_total_c: string;
	other_pay_total_d: Date;
	other_pay_total_n: number;
	other_pay_total_r: number;
	otherchargetotal: number;
	otherchargetotal_c: string;
	otherchargetotal_d: Date;
	otherchargetotal_n: number;
	otherchargetotal_r: number;
	override_pay_amt: number;
	override_pay_amt_c: string;
	override_pay_amt_d: Date;
	override_pay_amt_n: number;
	override_pay_amt_r: number;
	override_pay_rate: number;
	override_pay_units: number;
	override_type: string;
	override_units: number;
	pallets_how_many: number;
	pallets_required: string;
	partner_id: string;
	partner_name: string;
	pay_gross: number;
	pay_gross_c: string;
	pay_gross_d: Date;
	pay_gross_n: number;
	pay_gross_r: number;
	pick_up_no: string;
	pieces: number;
	placard_required: string;
	planning_comment: string;
	port_of_discharge: string;
	preload_trailer_id: string;
	preloaded: string;
	purpose: string;
	purpose_type: string;
	rate: number;
	rate_currency_type: string;
	rate_id: number;
	rate_min_weight: number;
	rate_min_weight_um: string;
	rate_type: string;
	rate_unit_desc: string;
	rate_units: number;
	rbd_timezone_id: string;
	ready_to_bill: string;
	ready_to_send: string;
	record_in_use: string;
	record_locked: string;
	recurring_order_id: string;
	reply_action: string;
	reply_altpartnerid: string;
	reply_batch_id: number;
	reply_created: string;
	reply_date: Date;
	reply_error: number;
	reply_error_descr: string;
	reply_gs_date_time: Date;
	reply_partner_id: string;
	reply_reason: string;
	reply_received: string;
	reply_received_ts: string;
	reply_remarks: string;
	reply_req_cancel: string;
	reply_req_change: string;
	reply_req_original: string;
	reply_st02: string;
	reply_tid: number;
	reply_trans_count: number;
	reply_transmitted: string;
	reply_version: string;
	require_tracking: string;
	required_vendor: string;
	requires_reply_outbound: string;
	respond_by_date: Date;
	revenue_code_id: string;
	revenue_share_method: string;
	rf_entry_code_required: boolean;
	round_trip: string;
	sail_date: Date;
	scac: string;
	seal_number: string;
	seg_alloc_code: string;
	ship_status_to_edi: string;
	shipment_id: string;
	shipper_ediloc: string;
	shipper_stop_id: string;
	shipstatus2edi_dt: Date;
	show_equipment_type_options: boolean;
	ss_location_id: string;
	st02_trxset_cntlno: string;
	status: string;
	teams_required: string;
	temperature_max: number;
	temperature_max_um: string;
	temperature_min: number;
	temperature_min_um: string;
	tender_ignored: string;
	tender_status: string;
	total_charge: number;
	total_charge_c: string;
	total_charge_d: Date;
	total_charge_n: number;
	total_charge_r: number;
	total_pay: number;
	total_pay_c: string;
	total_pay_d: Date;
	total_pay_n: number;
	total_pay_r: number;
	tractor_type: string;
	trans_count: number;
	transaction_set: string;
	transit_warning_id: string;
	transmit_status: string;
	ts_borrowing_company: string;
	ts_commodity: string;
	user_id: string;
	version: string;
	vessel: string;
	vessel_cutoff_date: Date;
	weight: number;
	weight_um: string;
	xferred2billing: string;
	xferred2billing_dt: Date;
	days_back: number;
}

