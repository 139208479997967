import { DataSource } from "..";
import { ModelDataChange } from "../databinding/DataSource";
import { Event, EventListener } from "./Event";

export interface DataSourceRemoteDataChangeListener extends EventListener {
    (event: DataSourceRemoteDataChangeEvent): void;
}

export class DataSourceRemoteDataChangeEvent extends Event {
    private _change: ModelDataChange;

    constructor(dataSource: DataSource, change: ModelDataChange) {
        super(dataSource, null);
        this._change = change;
    }

    public get change(): ModelDataChange {
        return this._change;
    }
}
