import { AutogenLayoutQuoteSummary } from "./autogen/AutogenLayoutQuoteSummary";

export class QuoteSummary extends AutogenLayoutQuoteSummary {
    onLoad() {
        this.setCommentStyling();
    }

    setCommentStyling() {
        this.labelComment.style.display = "-webkit-box";
        this.labelComment.style.webkitBoxOrient = "vertical";
        this.labelComment.style.webkitLineClamp = "3";
        this.labelComment.paddingBottom = 0;
    }

    labelCommentOnDataDisplay() {
        this.labelComment.tooltip = this.activeRow?.get("notes");
    }
}
