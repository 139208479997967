import { Label, MultiButton } from "@mcleod/components";
import { getLogger } from "@mcleod/core";
import { SendLoadTenderButtonPropDefinitions, SendLoadTenderButtonProps } from "./SendLoadTenderButtonProps";

const log = getLogger("lme/datafusion/SendLoadTenderButton");

export class SendLoadTenderButton extends MultiButton {
    private labelSendTender: Label;
    private labelSendRateCon: Label;

    constructor(props?: Partial<SendLoadTenderButtonProps>) {
        super({ id: props?.id });
        this.createDropdownItems();
        this.primaryButton.caption = SendLoadTenderButtonPropDefinitions.getDefinitions().caption.defaultValue,
            this.busyWhenDataSourceBusy = true;
        this.setProps({
            color: SendLoadTenderButtonPropDefinitions.getDefinitions().color.defaultValue,
            backgroundColor: SendLoadTenderButtonPropDefinitions.getDefinitions().backgroundColor.defaultValue,
            minWidth: SendLoadTenderButtonPropDefinitions.getDefinitions().minWidth.defaultValue,
            ...props
        });
    }

    private createDropdownItems() {
        this.labelSendTender = new Label({ caption: "Send Tender", onClick: () => this.sendLoadTender() });
        this.labelSendRateCon = new Label({ caption: "Send Rate Confirmation", onClick: () => this.sendRateCon() });
    }

    private sendLoadTender() {
        log.debug("In sendLoadTender()");
    }

    private sendRateCon() {
        log.debug("In sendRateCon()");
    }
}
