import { AutogenModelUserGroup, AutogenRowUserGroup } from "./autogen/AutogenModelUserGroup";

export class ModelUserGroup extends AutogenModelUserGroup<RowUserGroup> {
    constructor() {
        super(RowUserGroup);
    }
}

export class RowUserGroup extends AutogenRowUserGroup {
}
