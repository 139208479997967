import { Table } from "../components/table/Table";
import { TableRow } from "../components/table/TableRow";
import { DomEvent } from "./DomEvent";
import { Event, EventListener } from "./Event";

export interface TableRowDisplayListener extends EventListener {
    (event: TableRowDisplayEvent): void;
}

export class TableRowDisplayEvent extends Event {
    private table: Table = null;

    constructor(component: any, table: Table, domEvent?: DomEvent) {
        super(component, domEvent);
        this.table = table;
    }

    public get target(): TableRow {
        return super.target as TableRow;
    }

    public getTableRow(): TableRow {
        return this.target as TableRow;
    }

    public getTable(): Table {
        return this.table;
    }
}
