import { Button, CityState, DataHeader, DataSource, Label, Layout, Panel, Switch, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutCarrierOfferListDetail extends Layout {
    buttonAssignCarrier: Button;
    citystateConsignee: CityState;
    citystateShipper: CityState;
    citystateTractorCityId: CityState;
    dataheaderCarrierOfferList: DataHeader;
    labelAddress2: Label;
    labelAdditionalInformation: Label;
    labelAddress1: Label;
    labelCarrier: Label;
    labelCarrierContact: Label;
    labelCarrierDetails: Label;
    labelCarrierName: Label;
    labelCarrierState: Label;
    labelCarrierZip: Label;
    labelContactName: Label;
    labelContactPhone: Label;
    labelCounterMargin: Label;
    labelCounterMarginTitle: Label;
    labelCounterOffer: Label;
    labelCounterOfferDistance: Label;
    labelCounterOfferDistanceTitle: Label;
    labelCounterOfferTitle: Label;
    labelCounterPercentage: Label;
    labelCounterPercentageTitle: Label;
    labelDash: Label;
    labelDifference: Label;
    labelDifferenceTitle: Label;
    labelDispatchMargin: Label;
    labelDispatchMarginTitle: Label;
    labelDispatchOffer: Label;
    labelDispatchOfferDistanceTitle: Label;
    labelDispatchOfferTitle: Label;
    labelDispatchPayDistance: Label;
    labelDispatchPercentage: Label;
    labelDispatchPercentageTitle: Label;
    labelEmail: Label;
    labelGeneralInformation: Label;
    labelMaxMargin: Label;
    labelMaxMarginTitle: Label;
    labelMaxPay: Label;
    labelMaxPayDistance: Label;
    labelMaxPayDistanceTitle: Label;
    labelMaxPayTitle: Label;
    labelMaxPercentage: Label;
    labelMaxPercentageTitle: Label;
    labelOrderDetails: Label;
    labelOrderTotalChg: Label;
    labelPayeecity: Label;
    labelTargetMargin: Label;
    labelTargetMarginTitle: Label;
    labelTargetPay: Label;
    labelTargetPayDistance: Label;
    labelTargetPayDistanceTitle: Label;
    labelTargetPayTitle: Label;
    labelTargetPercentage: Label;
    labelTargetPercentageTitle: Label;
    labelTemperatureRange: Label;
    labelTotalCharges: Label;
    panel1: Panel;
    panelAdditionalInformation: Panel;
    panelAssignCarrier: Panel;
    panelCarrier: Panel;
    panelCarrierDetails: Panel;
    panelCarrierInfo: Panel;
    panelCharges: Panel;
    panelCounterOfferTitles: Panel;
    panelCounterOfferValues: Panel;
    panelCustomer: Panel;
    panelDispatchCounterOffer: Panel;
    panelDispatchCounterOfferValues: Panel;
    panelDispatchOfferTitles: Panel;
    panelDispatchOfferValues: Panel;
    panelGeneralInfo: Panel;
    panelMaxPayTitles: Panel;
    panelMaxPayValues: Panel;
    panelOffers: Panel;
    panelSummary: Panel;
    panelTargetPayTitles: Panel;
    panelTargetPayValues: Panel;
    panelTemperatureRange: Panel;
    panelTotalCharges: Panel;
    sourceCarrierOfferList: DataSource;
    switchWaterfallOffer: Switch;
    textboxComments: Textbox;
    textboxCommodity: Textbox;
    textboxConsigneeLocationName: Textbox;
    textboxDeclineReason: Textbox;
    textboxDestinationActualArrival: Textbox;
    textboxEnteredUserId: Textbox;
    textboxMilesToOrigin: Textbox;
    textboxModifiedDate: Textbox;
    textboxMovementmoveDistance: Textbox;
    textboxOfferDate: Textbox;
    textboxOfferSequence: Textbox;
    textboxOrderId: Textbox;
    textboxOrdersCustomerId: Textbox;
    textboxOrdersTemperatureMin: Textbox;
    textboxOrderstemperatureMax: Textbox;
    textboxOriginActualArrival: Textbox;
    textboxOriginLocationName: Textbox;
    textboxSalespersonId: Textbox;
    textboxSource: Textbox;
    textboxStatus: Textbox;
    textboxTrailerType: Textbox;
    textboxWeight: Textbox;
}
