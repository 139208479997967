import { ComponentPropDefinitionUtil, ComponentPropDefinitions, ComponentProps } from "../../base/ComponentProps";
import { PropType } from "../../base/PropType";
import { SelectionMode } from "../../base/SelectionMode";

export interface ListProps extends ComponentProps {
    items: any[] | ((list?) => any[]);
    scrollY: boolean;
    selectedItem: any;
    selectionMode: SelectionMode;
    selectOnEnter: boolean;
}

let listPropDefs: ComponentPropDefinitions;

export class ListPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (listPropDefs == null) {
            listPropDefs = {
                ...ComponentPropDefinitionUtil.getComponentPropertyDefinitions(),
                selectionMode: { type: PropType.string, defaultValue: SelectionMode.NONE, items: [...Object.values(SelectionMode)], description: "Specifies whether the user is allowed to select one or more rows in the List." },
                selectOnEnter: { type: PropType.bool },
                scrollY: { type: PropType.bool, defaultValue: true },
                onSelect: { type: PropType.event, category: "Events", addListenerMethod: "addSelectionListener", removeListenerMethod: "removeSelectionListener", description: "This specifies a function to be called when an item in the list is selected." } // passes selectedItem, selectedIndex, and the actual component that was selected (Label created internally if items was just a list of strings)
            };
            ComponentPropDefinitionUtil.populateComponentPropNames(listPropDefs);
        }
        return listPropDefs;
    }
}
