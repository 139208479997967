import { Button, Checkbox, ClickEvent, Label, Snackbar } from "@mcleod/components";
import { Table } from "@mcleod/components/src/components/table/Table";
import { TableRow } from "@mcleod/components/src/components/table/TableRow";
import { DataSourceMode } from "@mcleod/components/src/databinding/DataSource";
import { TableRowDisplayEvent } from "@mcleod/components/src/events/TableRowDisplayEvent";
import { getLogger } from "@mcleod/core";
import { Api } from "@mcleod/core/src/Api";
import { ModelRow } from "@mcleod/core/src/ModelRow";
import { StringUtil } from "@mcleod/core/src/StringUtil";
import { AddStopCompareItems } from "./AddStopCompareItems";
// import { DeleteStopCompareItems } from "./DeleteStopCompareItems";
import { AutogenLayoutCompareOrder } from "./autogen/AutogenLayoutCompareOrder";
import { ChangeStopCompareItems } from "./ChangeStopCompareItems";
import { DeleteStopCompareItems } from "./DeleteStopCompareItems";
import { OtherChargeChangeCompareItem } from "./OtherChargeChangeCompareItem";
import { OtherChargeCompare } from "./OtherChargeCompare";
import { HandlingRequirementsCompare } from "./HandlingRequirementsCompare";
import { RowEdiCompareItem } from "./models/ModelEdiCompareItem";
import { HandlingRequirementsCompareChangeItem } from "./HandlingRequirementsCompareChangeItem";
import { FreightGroupItemCompare } from "./FreightGroupItemCompare";
import { FreightGroupItemCompareChangeItem } from "./FreightGroupItemCompareChangeItem";

const log = getLogger("datafusion/CompareOrder");

export class CompareOrder extends AutogenLayoutCompareOrder {
    public compareData: ModelRow[];
    public stopChanges: ModelRow[];
    public newStops: ModelRow[];
    public removedStops: ModelRow[];
    public newOrRemovedOtherCharges: ModelRow[];
    public changedOtherCharges: ModelRow[];
    public changedAccessorials: ModelRow[];
    public newOrRemovedAccessorials: ModelRow[];
    public changedFreightGroupItems: ModelRow[];
    public newOrRemovedFreightGroupItems: ModelRow[];
    private _selectAll: boolean;
    private dataRows: ModelRow[];
    private mode: DataSourceMode;
    public orderId: String;
    public tenderId: String;
    private _buttonApply: Button;
    private _changesApplied: number = 0;

    public get changesApplied(): number {
        return this._changesApplied;
    }
    public set changesApplied(value: number) {
        this._changesApplied = value;
    }

    override onLoad(): void | Promise<void> {
        this.setOrderChanges(this.dataRows.filter(dataRow => dataRow["group_type"] == "OF"), this.mode);
        this.setNewStops(this.newStops, this.mode);
        this.setStopChanges(this.stopChanges, this.mode);
        this.setRemovedStops(this.removedStops, this.mode);
        this.setOtherCharges(this.mode);
        this.setHandlingRequirements(this.mode);
        this.setFreightGroupItems(this.mode);
    }
    public loadFromApiData(apiData: any, mode: DataSourceMode) {
        this.compareData = apiData;
        const filterByGroupType = (dataRow: ModelRow, groupType: string) => {
            return dataRow["group_type"] === groupType;
        }
        const changeGroups = new ModelRow("lme/datafusion/edi-compare-group", true, apiData);
        this.dataRows = this._getArrayAndNull(changeGroups, "compare_groups");
        this.mode = mode;
        this.stopChanges = this.dataRows.filter(dataRow => filterByGroupType(dataRow, "SF"));
        this.newStops = this.dataRows.filter(dataRow => filterByGroupType(dataRow, "NS"));
        this.removedStops = this.dataRows.filter(dataRow => filterByGroupType(dataRow, "OS"));
        this.changedOtherCharges = this.dataRows.filter(dataRow => filterByGroupType(dataRow, "CC"));
        this.newOrRemovedOtherCharges = this.dataRows.filter(dataRow => (filterByGroupType(dataRow, "NO") || filterByGroupType(dataRow, "OC")));
        this.newOrRemovedAccessorials = this.dataRows.filter(dataRow => (filterByGroupType(dataRow, "NA") || filterByGroupType(dataRow, "AR")));
        this.changedAccessorials = this.dataRows.filter(dataRow => filterByGroupType(dataRow, "AX"));
        this.newOrRemovedFreightGroupItems = this.dataRows.filter(dataRow => (filterByGroupType(dataRow, "NF") || filterByGroupType(dataRow, "FR")));
        this.changedFreightGroupItems = this.dataRows.filter(dataRow => filterByGroupType(dataRow, "FX"));
    }

    setOrderChanges(orderChanges: ModelRow[], mode: DataSourceMode) {
        if (orderChanges != null && orderChanges.length > 0) {
            this.loadChildTableData(this.tableOrderChanges, orderChanges[0]["compare_items"], mode);
            this.tabOrderChanges.caption = "Order Changes " + orderChanges[0]["compare_items"].length;
        } else {
            this.tabOrderChanges.visible = false;
        }
    }
    setStopChanges(stopChanges: ModelRow[], mode: DataSourceMode) {
        if (stopChanges != null && stopChanges.length > 0) {
            this.loadChildTableData(this.tableStopChanges, stopChanges, mode);
            this.tabStopChanges.caption = "Stop Changes " + stopChanges.length;
        } else {
            this.tabStopChanges.visible = false;
        }
    }
    setNewStops(newStops: ModelRow[], mode: DataSourceMode) {
        if (newStops == null || newStops.length == 0) {
            this.tabNewStops.visible = false;
            this.panelStopAdd.visible = false;
        } else {
            this.loadChildTableData(this.tableStopAdd, newStops, mode);
            this.tabNewStops.caption = "New Stops " + newStops.length;
        }
    }
    setRemovedStops(delStops: ModelRow[], mode: DataSourceMode) {
        if (delStops == null || delStops.length == 0) {
            this.tabRemovedStops.visible = false;

        } else {
            this.loadChildTableData(this.tableStopRemove, delStops, mode);
            this.tabRemovedStops.caption = "Removed Stops " + delStops.length;
        }
    }
    setOtherCharges(mode: DataSourceMode) {
        if ((this.newOrRemovedOtherCharges == null || this.newOrRemovedOtherCharges.length == 0) &&
            (this.changedOtherCharges == null || this.changedOtherCharges.length == 0)) {
            this.tabOtherCharges.visible = false;
        } else {
            const numItems: number = this.newOrRemovedOtherCharges.length + this.changedOtherCharges.length;
            this.tabOtherCharges.caption = "Other Charges " + numItems;
            const ocCompare = this.findComponentById("layoutOtherChargeCompare") as OtherChargeCompare;
            ocCompare.newOrRemovedOtherCharges = this.newOrRemovedOtherCharges;
            ocCompare.changedOtherCharges = this.changedOtherCharges;
            ocCompare.setupTableData(mode);
        }
    }
    setHandlingRequirements(mode: DataSourceMode) {
        if ((this.newOrRemovedAccessorials === null || this.newOrRemovedAccessorials.length === 0) &&
            (this.changedAccessorials === null || this.changedAccessorials.length === 0)) {
            this.tabAccessorials.visible = false;
        } else {
            const numberItems: number = this.newOrRemovedAccessorials.length + this.changedAccessorials.length;
            this.tabAccessorials.caption = "Accessorials Changes " + numberItems;
            const handlingRequirementsCompare = this.findComponentById("layoutHandlingRequirementChange") as HandlingRequirementsCompare;
            handlingRequirementsCompare.newOrRemovedHandlingRequirements = this.newOrRemovedAccessorials;
            handlingRequirementsCompare.changedHandlingRequirements = this.changedAccessorials;
            handlingRequirementsCompare.setupTableData(mode);
        }
    }

    setFreightGroupItems(mode: DataSourceMode) {
        if ((this.newOrRemovedFreightGroupItems === null || this.newOrRemovedFreightGroupItems.length === 0) &&
            (this.changedFreightGroupItems === null || this.changedFreightGroupItems.length === 0)) {
            this.tabFreightGroupItems.visible = false;
        } else {
            const numberItems: number = this.newOrRemovedFreightGroupItems.length + this.changedFreightGroupItems.length;
            this.tabFreightGroupItems.caption = "Freight Group Changes " + numberItems;
            const freightGroupCompare = this.findComponentById("layoutFreightGroupItemChange") as FreightGroupItemCompare;
            freightGroupCompare.newOrRemovedFreightGroupItems = this.newOrRemovedFreightGroupItems;
            freightGroupCompare.changedFreightGroupItems = this.changedFreightGroupItems;
            freightGroupCompare.setupTableData(mode);
        }
    }

    onOrderChangeRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        const checkbox = tableRow.findComponentById("checkboxApplyOrder") as Checkbox;
        const labelChecked = tableRow.findComponentById("labelCheckedOrder") as Label;
        checkbox.printable = false;
        checkbox.addClickListener(() => labelChecked.visible = checkbox.checked);
    }

    onStopAddDisplayRow(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        const checkbox = tableRow.findComponentById("checkboxAddStopApply") as Checkbox;
        const labelChecked = tableRow.findComponentById("labelCheckedAddStop") as Label;
        checkbox.printable = false;
        checkbox.addClickListener(() => labelChecked.visible = checkbox.checked);
        tableRow.findComponentById((comp: any) => {
            if (comp instanceof AddStopCompareItems) {
                const compareItems: AddStopCompareItems = comp;
                const rows = [];
                this.newStops[tableRow.index]["compare_items"].forEach((element: any) => {
                    const newRow = new RowEdiCompareItem(element);
                    if (!StringUtil.isEmptyString(newRow.get("source_value")) && newRow.get("source_value") != "1900-01-01") {
                        rows.push(newRow);
                    }
                });

                compareItems.setupTableData(rows);
            }
        });
    }

    onStopDeleteDisplayRow(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        const checkbox = tableRow.findComponentById("checkboxRemoveStopApply") as Checkbox;
        const labelChecked = tableRow.findComponentById("labelCheckedRemovedStops") as Label;
        checkbox.printable = false;
        checkbox.addClickListener(() => labelChecked.visible = checkbox.checked);
        tableRow.findComponentById((comp: any) => {
            if (comp instanceof DeleteStopCompareItems) {
                const compareItems: DeleteStopCompareItems = comp;
                const rows = [];
                this.removedStops[tableRow.index]["compare_items"].forEach((element: any) => {
                    const newRow = new RowEdiCompareItem(element);
                    if (!StringUtil.isEmptyString(newRow.get("target_value")) && newRow.get("target_value") != "1900-01-01") {
                        rows.push(newRow);
                    }
                });

                compareItems.setupTableData(rows);
            }
        });
    }

    onStopChangeDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.getTableRow();
        tableRow.findComponentById((comp: any) => {
            if (comp instanceof ChangeStopCompareItems) {
                const compareItems: ChangeStopCompareItems = comp;
                const rows = [];
                this.stopChanges[tableRow.index]["compare_items"].forEach((element: any) => {
                    const newRow = new RowEdiCompareItem(element);
                    rows.push(newRow);
                });
                compareItems.setupTableData(rows);

                if (this.stopChanges[tableRow.index]["child_item_groups"] != null) {
                    //get refnos
                    const refNos = this.stopChanges[tableRow.index]["child_item_groups"].filter(rowData => (rowData["group_type"] == "NR" || rowData["group_type"] == "OR" || rowData["group_type"] == "RC"));
                    compareItems.setRefnos(refNos);
                    //get stopnotes
                    const stopNotes = this.stopChanges[tableRow.index]["child_item_groups"].filter(rowData => (rowData["group_type"] == "NN" || rowData["group_type"] == "ON"));
                    compareItems.setStopNotes(stopNotes);
                }
            }
        });
        if (tableRow.index % 2 == 0) {
            event.target.backgroundColor = "#FFFFFF";
        } else {
            event.target.backgroundColor = "subtle.lightest";
        }
    }

    private loadChildTableData(table: Table, data: any[], mode: DataSourceMode) {
        const tableDataSource = table.dataSource;
        tableDataSource.data = [];
        const modelRows = [];
        data.forEach((element: any) => {
            modelRows.push(new ModelRow(tableDataSource.url, true, element));
        });
        tableDataSource.setRowsAndMode(mode, modelRows);
    }
    private _getArrayAndNull(row: ModelRow, field: string): ModelRow[] {
        const result = row.get(field) || [];
        row.set(field, null);
        return result;
    }

    selectAllOnClick(event: ClickEvent) {
        this._selectAll = true;
        this.processSelectAllTables();
    }
    onDeselectAll(event: ClickEvent) {
        this._selectAll = false;
        this.processSelectAllTables();
    }
    processSelectAllTables() {
        this.tableSetSelected(this.tableOrderChanges, this._selectAll);
        this.tableSetSelected(this.tableStopAdd, this._selectAll);
        this.tableSetSelected(this.tableStopRemove, this._selectAll);
        this.tableStopChanges.rows.forEach(tableRow => {
            tableRow.findComponentById((comp: any) => {
                if (comp instanceof ChangeStopCompareItems) {
                    this.tableSetSelected(comp.tableCompareItems, this._selectAll);
                    this.tableSetSelected(comp.tableStopComment, this._selectAll);
                    this.tableSetSelected(comp.tableStopRefNo, this._selectAll);
                }
            });
        });
        this.layoutOtherChargeCompare.findComponentById((comp: any) => {
            if (comp instanceof Table) {
                const ocTable: Table = comp;
                if (ocTable.id == "tableOCChanges") {
                    ocTable.rows.forEach(tableRow => {
                        tableRow.findComponentById((comp: any) => {
                            if (comp instanceof OtherChargeChangeCompareItem) {
                                const ocChangeItem: OtherChargeChangeCompareItem = comp;
                                this.tableSetSelected(ocChangeItem.tableChangeCompareItems, this._selectAll);
                            }
                        })

                    })
                } else if (ocTable.id = "tableOtherCharge") {
                    this.tableSetSelected(ocTable, this._selectAll);
                }

            }


        })
        this.layoutHandlingRequirementChange.findComponentById((comp: any) => {
            if (comp instanceof Table) {
                const handlingReqTable: Table = comp;
                if (handlingReqTable.id === "tableHandlingRequirementChange") {
                    handlingReqTable.rows.forEach(tableRow => {
                        tableRow.findComponentById((comp: any) => {
                            if (comp instanceof HandlingRequirementsCompareChangeItem) {
                                const handlingRequirmentChangeItem: HandlingRequirementsCompareChangeItem = comp;
                                this.tableSetSelected(handlingRequirmentChangeItem.tableHandlingChangeItems, this._selectAll);
                            }
                        });
                    });

                } else if (handlingReqTable.id === "tableHandlingRequirementAdd") {
                    this.tableSetSelected(handlingReqTable, this._selectAll);
                }
            }
        })
        this.layoutFreightGroupItemChange.findComponentById((comp: any) => {
            if (comp instanceof Table) {
                const freightGroupTable: Table = comp;
                if (freightGroupTable.id === "tableFreightGroupItemChange") {
                    freightGroupTable.rows.forEach(tableRow => {
                        tableRow.findComponentById((comp: any) => {
                            if (comp instanceof FreightGroupItemCompareChangeItem) {
                                const freightGroupChangeItem: FreightGroupItemCompareChangeItem = comp;
                                this.tableSetSelected(freightGroupChangeItem.tableFreightGroupItemChangeItems, this._selectAll);
                            }
                        });
                    });

                } else if (freightGroupTable.id === "tableFreightGroupItemAdd") {
                    this.tableSetSelected(freightGroupTable, this._selectAll);
                }
            }
        })
    }

    tableSetSelected(table: Table, selected: boolean) {
        table.rows.forEach(tableRow => {
            const {checkBoxValue, labelValue} = this.getCorrectComponentValues(table);
            const check = tableRow.findComponentById(checkBoxValue) as Checkbox
            const label = tableRow.findComponentById(labelValue) as Label;
            check.checked = selected;
            if (selected) {
                tableRow.data.set("apply", "Y");
                label.visible = true;
            } else {
                tableRow.data.set("apply", "N");
                label.visible = false;
            }
        });
    }

    public applyChanges(buttonApplyChanges: Button): number {

        const orderChanges: ModelRow[] = [];
        this.changesApplied += this.processCompareTable(this.tableOrderChanges, orderChanges);
        const stopChanges: ModelRow[] = [];
        const stopChangeGroups: ModelRow[] = [];
        const refnoChanges: ModelRow[] = [];
        const commentChanges: ModelRow[] = [];
        const ocAddsDeletes: ModelRow[] = [];
        const ocChanges: ModelRow[] = [];
        const handlingRequirementsAddsDeletes: ModelRow[] = [];
        const handlingRequirementsChanges: ModelRow[] =[];
        const freightGroupItemAddsDeletes: ModelRow[] = [];
        const freightGroupItemChanges: ModelRow[] = [];

        this.changesApplied += this.processCompareTable(this.tableStopChanges, stopChangeGroups);

        this.tableStopChanges.rows.forEach(tableRow => {
            tableRow.findComponentById((comp: any) => {
                if (comp instanceof ChangeStopCompareItems) {
                    const compareItems: ChangeStopCompareItems = comp;
                    this.changesApplied += this.processCompareTable(comp.tableCompareItems, stopChanges);
                    this.changesApplied += this.processCompareTable(comp.tableStopComment, commentChanges);
                    this.changesApplied += this.processCompareTable(comp.tableStopRefNo, refnoChanges);
                }
            });
        });
        //new stops
        const stopAdds: ModelRow[] = [];
        this.changesApplied += this.processCompareTable(this.tableStopAdd, stopAdds);
        //deleted stops
        const stopDels: ModelRow[] = [];
        this.changesApplied += this.processCompareTable(this.tableStopRemove, stopDels);

        //other charge changes/adds/deletes
        const ocCompare = this.findComponentById("layoutOtherChargeCompare") as OtherChargeCompare;
        this.changesApplied += this.processCompareTable(ocCompare.tableOtherCharge, ocAddsDeletes);
        ocCompare.tableOCChanges.rows.forEach(tableRow => {
            tableRow.findComponentById((comp: any) => {
                if (comp instanceof OtherChargeChangeCompareItem) {
                    this.changesApplied += this.processCompareTable(comp.tableChangeCompareItems, ocChanges);
                }

            });
        });

        //handling requirements changes/adds/deletes
        const handlingRequirementsCompare = this.findComponentById("layoutHandlingRequirementChange") as HandlingRequirementsCompare;
        this.changesApplied += this.processCompareTable(handlingRequirementsCompare.tableHandlingRequirementAdd, handlingRequirementsAddsDeletes);
        handlingRequirementsCompare.tableHandlingRequirementChange.rows.forEach(tableRow => {
            tableRow.findComponentById((comp: any) => {
                if (comp instanceof HandlingRequirementsCompareChangeItem) {
                    this.changesApplied += this.processCompareTable(comp.tableHandlingChangeItems, handlingRequirementsChanges);
                }
            })
        });

        const freightGroupCompare = this.findComponentById("layoutFreightGroupItemChange") as FreightGroupItemCompare;
        this.changesApplied += this.processCompareTable(freightGroupCompare.tableFreightGroupItemAdd, freightGroupItemAddsDeletes);
        freightGroupCompare.tableFreightGroupItemChange.rows.forEach(tableRow => {
            tableRow.findComponentById((comp: any) => {
                if (comp instanceof FreightGroupItemCompareChangeItem) {
                    this.changesApplied += this.processCompareTable(comp.tableFreightGroupItemChangeItems, freightGroupItemChanges);
                }
            })
        });

        Api.post("lme/datafusion/process-compare", {
            order_id: this.orderId,
            edi_order_id: this.tenderId,
            order_changes: orderChanges,
            stop_changes: stopChanges,
            stop_change_groups: stopChangeGroups,
            stop_adds: stopAdds,
            stop_removals: stopDels,
            refno_changes: refnoChanges,
            comment_changes: commentChanges,
            othercharge_adds_deletes: ocAddsDeletes,
            othercharge_changes: ocChanges,
            handling_reqs_adds_deletes: handlingRequirementsAddsDeletes,
            handling_reqs_changes: handlingRequirementsChanges,
            freight_group_adds_deletes: freightGroupItemAddsDeletes,
            freight_group_changes: freightGroupItemChanges
        }).then(async res => {
            buttonApplyChanges.busy = false;
            if (!StringUtil.isEmptyString(res.data[0].processing_error)) {
                Snackbar.showWarningSnackbar(res.data[0].processing_error);
                this.changesApplied = 0;
            } else {
                this.slideOut();
            }
        }).catch(reason => {
            log.debug(reason);
            Snackbar.showWarningSnackbar("Unable to process change tender : " + reason);
            this.buttonApply.busy = false;
        });
        return this.changesApplied;
    }

    public processCompareTable(table: Table, compareRows: ModelRow[]): number {
        let count: number = 0;
        table.rows.forEach(tableRow => {
            compareRows.push(tableRow.data);
            if ("Y" == tableRow.data.get("apply")) {
                count++;
            }
        });
        return count;
    }

    public get buttonApply(): Button {
        return this._buttonApply;
    }
    public set buttonApply(value: Button) {
        this._buttonApply = value;
    }

    private getCorrectComponentValues(enteredTable: Table): {checkBoxValue: string, labelValue: string} {
        let checkBoxValue: string;
        let labelValue: string;
        switch (enteredTable.id) {
            case "tableOrderChanges": {
                checkBoxValue = "checkboxApplyOrder";
                labelValue = "labelCheckedOrder";
                break;
            }
            case "tableStopAdd": {
                checkBoxValue = "checkboxAddStopApply";
                labelValue = "labelCheckedAddStop";
                break;
            }
            case "tableStopRemove": {
                checkBoxValue = "checkboxRemoveStopApply";
                labelValue = "labelCheckedRemovedStops";
                break;
            }
            case "tableStopComment": {
                checkBoxValue = "checkboxApplyStop";
                labelValue = "labelCheckedStop";
                break;
            }
            case "tableStopRefNo": {
                checkBoxValue = "checkboxApplyRefNo";
                labelValue = "labelCheckedRefNo";
                break;
            }
            case "tableChangeCompareItems":
            case "tableOtherCharge":
            case "tableCompareItems":
            case "tableHandlingChangeItems":
            case "tableHandlingRequirementAdd":
            case "tableFreightGroupItemChangeItems":
            case "tableFreightGroupItemAdd": {
                checkBoxValue = "checkboxApply";
                labelValue = "labelChecked";
                break;
            }
        }
        return {checkBoxValue, labelValue};
    }
}
