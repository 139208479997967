import { DataSource, Label, Layout, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutAddCarrier extends Layout {
    addCarrierSlideOut: Panel;
    labelAddingVendor: Label;
    sourceAddCarrier: DataSource;
    sourceCarrierInsCtrl: DataSource;
    sourceMovement: DataSource;
    textboxAddCarrierName: Textbox;
    textboxAddCarrierNbr: Textbox;
    textboxAddCarrierType: Textbox;
    textboxEmailAddress: Textbox;
    textboxPacketContactName: Textbox;
}
