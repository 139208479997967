import { Router } from "@mcleod/common";
import { Layout, Panel, Tree, TreeNode } from "@mcleod/components";
import { StringUtil, getLogger } from "@mcleod/core";
import { ComponentDemo } from "./ComponentDemo";

const log = getLogger("component-demo/Demo");

export class Demo extends Layout {
    treeDemo: Tree;
    router: Router;
    panelContent: Panel;

    constructor(props) {
        super(props);
    }

    onLoad() {
        const modules = require["context"](".", true);
        for (const key of modules.keys()) {
            if (key.startsWith("./") && !key.endsWith(".ts") && key !== "./Demo" && !key.endsWith("ComponentDemo") && !key.startsWith("./components/")) {
                const module = modules(key);
                const className = key.substring(key.lastIndexOf("/") + 1);
                if (module[className] == null)
                    log.info("Demo module " + key + " doesn't have a class named " + className);
                else {
                    const instance = new module[className];
                    if (instance.getDemoPath == null)
                        log.info("Demo module " + key + " class " + className + " doesn't have a function called getDemoPath() to determine where it should be added to the demo tree.");
                    else
                        this.addDemo(instance, key.substring(2));
                }
            }
        }
        this.treeDemo.addChangeListener(event => {
            if (event.newValue.module == null)
                this.router.removeAll();
            else
                this.router.displayRoute("component-demo/" + event.newValue.module);
        });
        this.router = new Router({ isMainDocumentRouter: false, id: "demoRouter" });
        const componentPaths = [
            "button/Button",
            "checkbox/Checkbox",
            "image/Image",
            "label/Label",
            "list/List",
            "layout/Layout",
            "panel/Panel",
            "stepper/Stepper",
            "switch/Switch",
            "table/Table",
            "tabset/Tabset",
            "textbox/Textbox",
            "tree/Tree",
        ];
        for (const componentPath of componentPaths) {
            const demo = new ComponentDemo({ componentPath: componentPath });
            this.addDemo(demo, "ComponentDemo?componentPath=" + componentPath, "designer/" + StringUtil.stringAfterLast(componentPath, "/").toLowerCase());
        }
        this.panelContent.add(this.router);
        if (this.treeDemo.getRootNode().getChildCount() > 0) {
            this.treeDemo.selectedNode = this.treeDemo.getRootNode().getChild(0);
            this.treeDemo.focus();
        }
    }

    addDemo(instance: ComponentDemo, module: string, imageName?: string) {
        const path = instance.getDemoPath();
        let parentNode = this.treeDemo.getRootNode();
        for (let i = 0; i < path.length; i++) {
            let childNode = parentNode.getChildWithCaption(path[i]);
            if (childNode == null)
                childNode = parentNode.addChild(new TreeNode({ caption: path[i], tree: this.treeDemo }));
            if (i === path.length - 1) {
                childNode.imageName = imageName;
                childNode["module"] = module;
            }
            parentNode = childNode;
        }
    }
}
