import { AutogenLayoutHandlingRequirements } from "./autogen/AutogenLayoutHandlingRequirements";

export class HandlingRequirements extends AutogenLayoutHandlingRequirements {
    override onLoad() {
        this.checkAddlInfoSwitch(this.switchText)
        this.checkAddlInfoSwitch(this.switchInteger)
        this.checkAddlInfoSwitch(this.switchDecimal)
        this.checkAddlInfoSwitch(this.switchDate)
        this.checkAddlInfoSwitch(this.switchTime)
        this.checkAddlInfoSwitch(this.switchDateTime)
    }

    switchOnChange(event) {
        const switchItem = event.target
        this.checkAddlInfoSwitch(switchItem)
    }

    /**
     * send textbox and whether switch parameter is checked to setCaptionEnabledDisabled
     */
    private checkAddlInfoSwitch(switchItem: any) {
        const checked = switchItem.checked
        const fieldType = switchItem.field.substring(5)
        switch (fieldType) {
            case "text":
                this.setCaptionEnabledDisabled(this.textboxTextTitle, checked)
                break;
            case "integer":
                this.setCaptionEnabledDisabled(this.textboxIntegerTitle, checked)
                break;
            case "float":
                this.setCaptionEnabledDisabled(this.textboxDecimalTitle, checked)
                break;
            case "date":
                this.setCaptionEnabledDisabled(this.textboxDateTitle, checked)
                break;
            case "time":
                this.setCaptionEnabledDisabled(this.textboxTimeTitle, checked)
                break;
            case "timestamp":
                this.setCaptionEnabledDisabled(this.textboxDateTimeTitle, checked)
                break;
            default:
                break;
        }
    }

    /**
     * set textbox enabled based on whether it's corresponding switch is checked
     */
    private setCaptionEnabledDisabled(textbox: any, checked: boolean) {
        if (checked)
            textbox.enabled = textbox.required = true
        else
            textbox.enabled = textbox.required = false
    }
}
