import { EditRowDecorator, Layout, MultiButton, Toast } from "@mcleod/components";
import { Api, Currency, ModelRow, StringUtil } from "@mcleod/core";
import { AutogenLayoutCarrierOfferCounter } from "./autogen/AutogenLayoutCarrierOfferCounter";

export interface CounterOfferSummaryInfo {
    order_id: string;
    commodity: string;
    distance: string;
    weight: string;
    equipment_type: string;
}

interface CounterOfferSlideoutProps {
    summaryInfo: CounterOfferSummaryInfo;
    data: ModelRow;
    doAfterSlideIn?: (layout: CarrierOfferCounter) => void;
    onSave?: (updatedData: ModelRow | any) => void;
}

export class CarrierOfferCounter extends AutogenLayoutCarrierOfferCounter {

    static showCounterOfferSlideout(props: CounterOfferSlideoutProps) {
        const layout = Layout.getLayout("lme/powerbroker/CarrierOfferCounter") as CarrierOfferCounter;
        let erdSaveButton: MultiButton;
        new EditRowDecorator({
            data: props.data.createBasicCopy(),
            layout: layout,
            width: window.innerWidth * .75,
            fillVerticalSpace: true,
            title: `Counter Offer - Order ${props.summaryInfo.order_id}`,
            doAfterSlideIn: (decorator: EditRowDecorator) => {
                props?.doAfterSlideIn(layout);
                layout.setupSummaryPanel(props.summaryInfo);
                erdSaveButton = decorator.multiButton;
                erdSaveButton.width = 115;
            },
            onClose: (cancelled) => { if (cancelled) layout.slideOut(); },
            onSave: async (updatedData) => {
                const brokerCounterString = layout.activeRow.get("broker_counter").amount.toString(); // Endpoint uses counter amount as string format
                erdSaveButton.busy = true;
                Api.post("lme/powerbroker/counter-counter-offer", { "offer_id": layout.activeRow.get("id"), "counter_amount": brokerCounterString }).then(() => {
                    layout.slideOut().then(() => {
                        Toast.showSuccessToast(`A new counter offer has been generated for ${updatedData.get("carrier_name")}`);
                    });
                    props?.onSave(updatedData);
                }).finally(() => {
                    erdSaveButton.busy = false;
                })
            },
        });
    }

    /** This is an event handler for the onChange event of textboxBrokerCounter.  */
    textboxBrokerCounterOnChange(event) {
        if (event.userInitiatedChange) {
            const totalCharges = this.activeRow.get("order_total_chg") as Currency;
            const margin = StringUtil.isEmptyString(event.newValue) ? null : this.calculateCounterMargin(event.newValue, totalCharges);
            const marginPct = margin ? ((margin / totalCharges.amount) * 100) : null;
            this.activeRow.set("margin_amount", margin);
            this.activeRow.set("margin_pct", marginPct);
        }
    }

    setupSummaryPanel(summaryInfo: CounterOfferSummaryInfo) {
        this.panelOrderInfo.displayData(new ModelRow("", false, summaryInfo), null, 0);
    }

    setupCarrierInfo(payee_id: string, is_carrier_scorecard_licensed: boolean) {
        if (payee_id != null) {
            this.sourceCarriersForAssignment.search({ "id": payee_id });
            this.starRatingCarrier.visible = is_carrier_scorecard_licensed;
        }
    }

    calculateCounterMargin(newValue: string, totalCharges: Currency) {
        const brokerCounter = parseFloat(newValue);
        return totalCharges.amount - brokerCounter;
    }

}
