import { Button, Dialog, Layout, Panel } from "@mcleod/components";
import { HorizontalAlignment, StringUtil } from "@mcleod/core";
import { LinkTender } from "./LinkTender";
import { RowEdiOrder } from "./models/ModelEdiOrder";

export class LinkTenderDialog extends Dialog {
    private _ediOrderRow: RowEdiOrder;

    override async show(): Promise<any> {

        const layout = Layout.getLayout("lme/datafusion/LinkTender") as LinkTender;
        const pnlButtons = new Panel({ fillRow: true, align: HorizontalAlignment.CENTER })
        const btnSave = new Button({ caption: "Link", rowBreak: false, marginRight: 10 });
        let doCompare = false;
        const isCancel: boolean = this.ediOrderRow.get("cancelled_order") === "Y";
        const isChange: boolean = this.ediOrderRow.get("changed_order") === "Y";
        btnSave.addClickListener(async _event => {
            if (!StringUtil.isEmptyString(layout.txtOrderId.text)) {
                if (layout.txtOrderId.validateSimple()) {
                    this.ediOrderRow.set("order_id", layout.txtOrderId.text);
                    await this.ediOrderRow.post();
                    this.close(true);

                } else {
                    layout.txtOrderId.showTooltip("Invalid order specified, please select a valid ID from the drop down.");
                }
            } else {
                layout.txtOrderId.showTooltip("Please select a an order before saving.");
            }
        });
        pnlButtons.add(btnSave);

        const btnSaveCompare = new Button({ caption: "Link & Compare", rowBreak: false, marginRight: 10 });
        btnSaveCompare.addClickListener(async _event => {
            if (!StringUtil.isEmptyString(layout.txtOrderId.text)) {
                if (layout.txtOrderId.validateSimple()) {
                    this.ediOrderRow.set("order_id", layout.txtOrderId.text);
                    await this.ediOrderRow.post().then(res => {
                        this.close(true);
                        doCompare = true;
                    })
                } else {
                    layout.txtOrderId.showTooltip("Invalid order specified, please select a valid ID from the drop down.");
                }
            } else {
                layout.txtOrderId.showTooltip("Please select a an order before saving.");
            }
        });
        if (isChange) pnlButtons.add(btnSaveCompare);

        layout.addLayoutLoadListener(() => {
            layout.insert(pnlButtons, 4);
        });

        this.add(layout);

        await super.show();
        return Promise.resolve(doCompare);
    }

    get ediOrderRow(): RowEdiOrder {
        return this._ediOrderRow;
    }
    set ediOrderRow(rowEdiOrder: RowEdiOrder) {
        this._ediOrderRow = rowEdiOrder;
    }
}
