import { Checkbox, DataSource, Layout, Tab, Table, Tabset, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutRoutingGuideProfile extends Layout {
    checkboxIsActive: Checkbox;
    sourceRoutingGuideDetail: DataSource;
    sourceRoutingGuideProfile: DataSource;
    sourceRoutingGuideUserGroups: DataSource;
    sourceRoutingGuideUsers: DataSource;
    tabAccess: Tab;
    tabCarriers: Tab;
    tableUser: Table;
    tableUserGroup: Table;
    tableCarriers: Table;
    tabset1: Tabset;
    textboxCommodityId: Textbox;
    textboxCustomerId: Textbox;
    textboxDescr: Textbox;
    textboxEquipmentTypeId: Textbox;
    textboxId: Textbox;
    textboxOrderMode: Textbox;
}
