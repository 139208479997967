import { DataSource, HorizontalSpacer, Label, Layout, Panel, SearchButton, Table, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutCarrierDriverMessage extends Layout {
    horizontalspacer1: HorizontalSpacer;
    labelPageTitle: Label;
    panel1: Panel;
    panel2: Panel;
    searchbuttonSearch: SearchButton;
    sourceCarrierDriverMessage: DataSource;
    tableCarrierDriverMessage: Table;
    textboxCarrierId: Textbox;
    textboxOrderId: Textbox;
}
