/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelCopyControl.ts                   *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/dispatch/copy-control";

export class AutogenModelCopyControl<RowType extends ModelRow> extends Endpoint<RowType, RowCopyControlFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowCopyControl extends ModelRow<RowCopyControlFields> {
    constructor(values?: Partial<RowCopyControlFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowCopyControlFields {
    allocation: string;
    bill_distance: string;
    bill_of_lading: string;
    booking_no: string;
    brk_qual_profile: string;
    brokerage_workflow: string;
    carrier_cost: string;
    comments: string;
    company_id: string;
    consignee_ref: string;
    container_dates: string;
    container_dray_pay: string;
    container_ldport: string;
    container_number: string;
    container_order: string;
    container_pod: string;
    container_type: string;
    container_vessel: string;
    copy_bill_to: string;
    copy_car_to_frght: string;
    copy_ctrl_party: string;
    copy_ovrd_payee: string;
    copy_revenue_id: string;
    copy_status: string;
    copy_to_company: string;
    copy_type: string;
    cust_order_no: string;
    dates: string;
    delivered_orders: string;
    descr: string;
    dray_transfer_date: string;
    driver_load_unload: string;
    edi_status: string;
    eir1_no: string;
    eir2_no: string;
    equip_match_detail: string;
    excise_taxes: string;
    forwarder: string;
    free_days: string;
    freight_items: string;
    handling_req: string;
    ic_alloc_code: string;
    ic_order_type: string;
    ic_resp_code: string;
    ic_rev_code: string;
    id: string;
    import_export: string;
    lock_miles: string;
    order_type: string;
    order_value: string;
    orig_ctrl_party: string;
    orig_ovrd_payee: string;
    orig_revenue_id: string;
    orig_zero_excise_taxes: string;
    orig_zero_frt_chg: string;
    orig_zero_othr_chg: string;
    orig_zero_ovr_pay: string;
    original_bill_to: string;
    original_status: string;
    other_charges: string;
    pick_up_no: string;
    rate: string;
    reference_numbers: string;
    revenue: string;
    showas_location: string;
    stops: string;
    weight: string;
    y_splits: string;
}

