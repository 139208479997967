import { Alignment, getLogger, ModuleLogger } from "@mcleod/core";
import { Component } from "../base/Component";
import { ComponentUtil, CompOrElementOrRect } from "../base/ComponentUtil";
import { Anchor, AnchorMutation, AnchorProps } from "./Anchor";

export class OnScreen {
    private static _log: ModuleLogger;

    public static ensureOnScreen(compOrElem: Component | HTMLElement, anchorProps: AnchorProps): AnchorMutation {
        const inRect = ComponentUtil.getRect(compOrElem);
        const mutation = OnScreen.ensureRectOnScreen(inRect, anchorProps);
        const rect = mutation.newRect;
        if (inRect.x !== rect.x || inRect.y !== rect.y || inRect.width !== rect.width || inRect.height !== rect.height)
            Anchor.alignToRect(compOrElem, rect);
        return mutation;
    }

    private static getBodyRect(anchorProps?: AnchorProps) {
        return new DOMRect(0, 0,
            document.body.offsetWidth - (anchorProps?.minOnScreen ?? 12),
            document.body.offsetHeight - (anchorProps?.minOnScreen ?? 12)
        );
    }

    public static ensureRectOnScreen(inRect: DOMRect, inAnchorProps: AnchorProps): AnchorMutation {
        const bodyRect = OnScreen.getBodyRect(inAnchorProps);
        OnScreen.log.debug(() => ["ensureRectOnScreen", inRect, "Body", bodyRect, "anchor", inAnchorProps]);
        if (OnScreen.isOnScreen(inRect, inAnchorProps))
            return { newRect: inRect, newAnchor: inAnchorProps };
        // if the rect is bigger than the screen, just give up - might be a good idea to try to adjust the size
        if (inRect.width >= bodyRect.width || inRect.height >= bodyRect.height)
            return { newRect: inRect, newAnchor: inAnchorProps };

        const anchorProps = { ...inAnchorProps };
        const mutatedRect = DOMRect.fromRect(inRect);

        // first see if we can just change the position and alignment around the anchor
        OnScreen.adjustAnchorAlignment(mutatedRect, anchorProps);

        // if changing the position around the anchor didn't get the component into the screen, just adjust it back onto the visible area
        const rect = Anchor.alignRect(mutatedRect, anchorProps);
        if (rect.x < 0)
            rect.x = 0;
        if (rect.y < 0)
            rect.y = 0;
        const offRight = rect.right - bodyRect.width;
        if (offRight > 0)
            rect.x = rect.x - offRight;
        const offBottom = rect.bottom - bodyRect.height;
        if (offBottom > 0)
            rect.y = rect.y - offBottom;
        return { newRect: rect, newAnchor: anchorProps };
    }

    public static isOnScreen(compOrElementOrRect: CompOrElementOrRect, anchorProps: AnchorProps): boolean {
        const rect = ComponentUtil.getRect(compOrElementOrRect);
        const bodyRect = OnScreen.getBodyRect(anchorProps)
        return rect.left >= 0
            && rect.top >= 0
            && rect.right <= bodyRect.width
            && rect.bottom <= bodyRect.height;
    }

    private static adjustAnchorAlignment(rect: DOMRect, anchorProps: AnchorProps) {
        const bodyRect = OnScreen.getBodyRect(anchorProps)
        const offScreenRight = rect.right - bodyRect.width;
        const offScreenBottom = rect.bottom - bodyRect.height;
        if (offScreenRight > 0 && (anchorProps.align === Alignment.LEFT || anchorProps.align === Alignment.CENTER)) {
            // ideally we should try to shrink the width to minWidth like we do the height
            anchorProps.align = Alignment.RIGHT;
        }
        if (rect.left < 0 && (anchorProps.align === Alignment.LEFT || anchorProps.align === Alignment.CENTER))
            anchorProps.align = Alignment.LEFT;
        if (offScreenBottom > 0) {
            let shouldSwitchPosition = true;
            if (anchorProps.minHeight != null) { // before switching to TOP position, see if it will fit by shrinking to minHeight
                const minBottom = rect.top + anchorProps.minHeight;
                if (minBottom < bodyRect.height) {
                    rect.height = bodyRect.height - rect.top;
                    shouldSwitchPosition = false;
                }
            }
            if (shouldSwitchPosition) {
                const testAnchorProps = { ...anchorProps };
                const testPositionOrder = this.getPositionTestOrder(anchorProps.position);
                for (const testPosition of testPositionOrder) {
                    testAnchorProps.position = testPosition;
                    const testRect = Anchor.alignRect(rect, testAnchorProps);
                    if (OnScreen.isOnScreen(testRect, testAnchorProps) === true) {
                        anchorProps.position = testPosition;
                        return;
                    }
                }
            }
        }
    }

    private static getPositionTestOrder(currentPosition?: Alignment): Alignment[] {
        switch (currentPosition) {
            case Alignment.TOP:
                return [Alignment.BOTTOM, Alignment.RIGHT, Alignment.LEFT];
            case Alignment.RIGHT:
                return [Alignment.BOTTOM, Alignment.TOP, Alignment.LEFT];
            case Alignment.BOTTOM:
                return [Alignment.RIGHT, Alignment.TOP, Alignment.LEFT];
            case Alignment.LEFT:
                return [Alignment.BOTTOM, Alignment.TOP, Alignment.RIGHT];
            default:
                return [Alignment.BOTTOM, Alignment.RIGHT, Alignment.TOP, Alignment.LEFT];
        }
    }

    private static get log() {
        if (this._log == null)
            this._log = getLogger("components.page.OnScreen");
        return this._log;
    }
}
