/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelAuthDispatchControl.ts           *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/dispatch/auth_dispatch_control";

export class AutogenModelAuthDispatchControl<RowType extends ModelRow> extends Endpoint<RowType, RowAuthDispatchControlFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowAuthDispatchControl extends ModelRow<RowAuthDispatchControlFields> {
    constructor(values?: Partial<RowAuthDispatchControlFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowAuthDispatchControlFields {
    allow_overrides: string;
    auto_pay_rates: string;
    auto_rate: string;
    check_call_warning: number;
    create_location_from_stop: string;
    delivery_warning: number;
    dimension_surchg: string;
    enable_authorized: string;
    enable_google_places: string;
    enable_location_add_google: string;
    enable_stop_add_google: string;
    enforce_billto: string;
    enforce_credit: string;
    enforce_max_pay: string;
    enforce_revenu_cod: string;
    enforce_shipper_id: string;
    enforce_target_pay: string;
    enforce_void_hist: string;
    equipment_match: string;
    est_fuel_surcharge: string;
    google_places_entry_delay: number;
    mandatory_carrier_ratings: string;
    mpact_host_url: string;
    mpact_smart_api_key: string;
    pickup_warning: number;
    prevent_dsp_hold: string;
    rate_carrier: string;
    rating_trigger_code: string;
    recurring_override: string;
    target_pay_calc: string;
    unassign_reason: string;
}

