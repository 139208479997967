import { DataSource, Layout } from "@mcleod/components";
import { ModelRow } from "@mcleod/core";
import { TopMatchList } from "./TopMatchList";
import { TopMatchUtil } from "./TopMatchUtil";
import { AutogenLayoutRoutingGuidesTable } from "./autogen/AutogenLayoutRoutingGuidesTable";

export class RoutingGuidesTable extends AutogenLayoutRoutingGuidesTable {
    public updateTabTitle: (rowCount: number) => void;
    private _topMatchUtil = new TopMatchUtil();
    private _stopCount: string;

    set topMatchUtil(topMatchUtil: TopMatchUtil) {
        this._topMatchUtil = topMatchUtil;
    }

    get topMatchUtil() {
        return this._topMatchUtil;
    }

    override onLoad() {
        Layout.clearCachedLayout(this.layoutName);
    }

    public clear() {
        this.mainDataSource.data = [];
        this.movement = null;
        this.tableRoutingGuides.resetFilter();
        this.tableRoutingGuides.emptyCaption = null;
        this.updateTabTitle(null);
        this.mainDataSource.displayDataInBoundComponents();
    }

    initialize(topMatchList: TopMatchList) {
        this.topMatchUtil.initialize(this, topMatchList);
    }

    set movement(sourceMovement: DataSource) {
        this.topMatchUtil.setMovement(sourceMovement);
    }

    set stopCount(stopCount: string) {
        this._stopCount = stopCount;
    }

    override async search(searchValues: ModelRow, offerHistoryData?: any) {
        this.tableRoutingGuides.allRows.forEach(tableRow => tableRow.data.set("exclude_from_selectall", false));
        if (this.tableRoutingGuides.emptyCaption == null)
            this.tableRoutingGuides.emptyCaption = "No results found";
        await this.sourceTopmatchRoutingGuides.search(searchValues);
        this.mainDataSource.setComponentsBusy(false);
        this.mainDataSource.setRowsAndMode(this.mainDataSource.mode,
            this.sourceTopmatchRoutingGuides.data.map((row: ModelRow) => {
                const carrierOfferRow = new ModelRow("lme/powerbroker/topmatch", true, row.data);
                carrierOfferRow.set("id", null);
                carrierOfferRow.set("order_id", searchValues.get("order_id"));
                carrierOfferRow.set("movement_id", searchValues.get("movement_id"));
                return carrierOfferRow;
            }));
        this.mainDataSource.displayDataInBoundComponents();
        if (this.updateTabTitle != null) {
            this.updateTabTitle(this.tableRoutingGuides.rowCount);
        }
        return Promise.resolve();
    }
}
