/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelOrderOtherChargeQuote.ts         *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/dispatch/order-other-charge-quote";

export class AutogenModelOrderOtherChargeQuote<RowType extends ModelRow> extends Endpoint<RowType, RowOrderOtherChargeQuoteFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowOrderOtherChargeQuote extends ModelRow<RowOrderOtherChargeQuoteFields> {
    constructor(values?: Partial<RowOrderOtherChargeQuoteFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowOrderOtherChargeQuoteFields {
    amount: number;
    calc_method: string;
    charge_id: string;
    descr: string;
    est_fuel_surcharge: string;
    id: string;
    incl_in_freight: string;
    order_id: string;
    rate: number;
    rate_id: number;
    units: number;
    sequence: number;
}

