import { CityState, DataSource, Image, Label, Layout, Panel, Table, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutMultiStop extends Layout {
    citystate2: CityState;
    citystateCustomer: CityState;
    citystateShipper: CityState;
    imageMPact: Image;
    labelAddress1: Label;
    labelAddress2: Label;
    labelBillDistanceUm: Label;
    labelBillingDistance: Label;
    labelContactPhone: Label;
    labelDistance: Label;
    labelSmartTarget: Label;
    panel1: Panel;
    panel2: Panel;
    panelCommodity: Panel;
    panelDistance: Panel;
    panelMPact: Panel;
    panelOrderInfo: Panel;
    panelOriginDest: Panel;
    panelStopInfo: Panel;
    panelTargetPay: Panel;
    panelWeight: Panel;
    sourceMultiStop: DataSource;
    sourceStop: DataSource;
    tableStop: Table;
    textboxCommodityId: Textbox;
    textboxMaxPay: Textbox;
    textboxName: Textbox;
    textboxPnnRate: Textbox;
    textboxTargetPay: Textbox;
    textboxTrailerType: Textbox;
    textboxWeight: Textbox;
}
