import { AutogenModelCarrierPerformanceDetail, AutogenRowCarrierPerformanceDetail } from "./autogen/AutogenModelCarrierPerformanceDetail";

export class ModelCarrierPerformanceDetail extends AutogenModelCarrierPerformanceDetail<RowCarrierPerformanceDetail> {
    constructor() {
        super(RowCarrierPerformanceDetail);
    }
}

export class RowCarrierPerformanceDetail extends AutogenRowCarrierPerformanceDetail {
}
