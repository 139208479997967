import { DataDisplayEvent } from "@mcleod/components";
import { AutogenLayoutCommodityQuickInfo } from "./autogen/AutogenLayoutCommodityQuickInfo";

export class CommodityQuickInfo extends AutogenLayoutCommodityQuickInfo {
    /** This is an event handler for the onDataDisplay event of labelHazmat
     *
     * @param event The event that has taken place.  The event's target is usually the component on which the event has fired.
     */
    labelHazmatOnDataDisplay(event: DataDisplayEvent) {
        this.labelHazmat.visible = event?.rowData?.get("is_hazmat") === "Y";
    }
}
