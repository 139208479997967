import { EdiOrderFASeg } from "../EdiOrderFASeg";
import { Checkbox, DataSource, Label, Layout, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutEdiOrderFA extends Layout {
	checkboxTransmitted: Checkbox;
	labelStatus: Label;
	layoutFASeg: EdiOrderFASeg;
	panelFA: Panel;
	sourceEdiFunctionalAck: DataSource;
	sourceEdiLog: DataSource;
	sourceViewEdiOrder: DataSource;
	textboxAk501TrxAckCd: Textbox;
	textboxAk902Sets: Textbox;
	textboxBatchId: Textbox;
	textboxCreateDate: Textbox;
	textboxEdiError: Textbox;
	textboxGSStatusCode: Textbox;
}
