import { DataDisplayEvent, Label, TableRow, TableRowDisplayEvent } from "@mcleod/components";
import { Alignment } from "@mcleod/core";
import { AutogenLayoutBrltlCommodityLookup } from "./autogen/AutogenLayoutBrltlCommodityLookup";
export class BrltlCommodityLookup extends AutogenLayoutBrltlCommodityLookup {

    labelHazmatOnDataDisplay(event: DataDisplayEvent) {
        const label = event.target as Label;
        label.caption = null;
        if (event.rowData?.get("is_hazmat") === "Y") {
            label.imageName = "warning";
            label.imageColor = "red";
            label.imageAlign = Alignment.LEFT;
        }
    }

    tableCommodityOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.target as TableRow;
        const modelRow = tableRow.data;
        if (modelRow.get("sort_column") == 0) {
            tableRow.fontBold = true;
            tableRow.backgroundColor = "success.lightest";
            tableRow.borderColor = "success.dark";
        }
    }
}
