import { ModelRow } from "@mcleod/core";
import { AutogenLayoutHandlingRequirementsCompare } from "./autogen/AutogenLayoutHandlingRequirementsCompare";
import { Checkbox, DataSourceMode, Label, Table, TableRow, TableRowDisplayEvent } from "@mcleod/components";
import { RowEdiCompareItem } from "./models/ModelEdiCompareItem";
import { HandlingRequirementsCompareItem } from "./HandlingRequirementsCompareItem";
import { HandlingRequirementsCompareChangeItem } from "./HandlingRequirementsCompareChangeItem";

export class HandlingRequirementsCompare extends AutogenLayoutHandlingRequirementsCompare {
    private _newOrRemovedHandlingRequirements: ModelRow[];
    private _changedHandlingRequirements: ModelRow[];

    public get newOrRemovedHandlingRequirements(): ModelRow[] {
        return this._newOrRemovedHandlingRequirements;
    }
    public set newOrRemovedHandlingRequirements(value: ModelRow[]) {
        this._newOrRemovedHandlingRequirements = value;
    }

    public get changedHandlingRequirements(): ModelRow[] {
        return this._changedHandlingRequirements;
    }
    public set changedHandlingRequirements(value: ModelRow[]) {
        this._changedHandlingRequirements = value;
    }

    setupTableData(mode: DataSourceMode) {
        if (this.newOrRemovedHandlingRequirements == null || this.newOrRemovedHandlingRequirements.length == 0) {
            this.labelAddsAndDeletes.visible = false;
            this.tableHandlingRequirementAdd.visible = false;
        } else {
            this.loadChildTableData(this.tableHandlingRequirementAdd, this.newOrRemovedHandlingRequirements, mode)
        }
        if (this.changedHandlingRequirements == null || this.changedHandlingRequirements.length == 0) {
            this.labelChanges.visible = false;
            this.tableHandlingRequirementChange.visible = false;
        } else {
            this.loadChildTableData(this.tableHandlingRequirementChange, this.changedHandlingRequirements, mode);
        }
    }

    onHandlingRequirementsAddDeleteRowDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.getTableRow();
        const checkboxApply = tableRow.findComponentById("checkboxApply") as Checkbox;
        checkboxApply.printable = false;
        const labelChecked = tableRow.findComponentById("labelChecked") as Label;
        checkboxApply.addClickListener(() => labelChecked.visible = checkboxApply.checked);
        this.setCompareItems(this.newOrRemovedHandlingRequirements, tableRow);
    }

    onHandlingRequirementsChangeRowDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.getTableRow();
        this.setCompareItems(this.changedHandlingRequirements, tableRow);
        if (tableRow.index % 2 == 0) {
            event.target.backgroundColor = "#FFFFFF";
        } else {
            event.target.backgroundColor = "subtle.lightest";
        }
    }

    private setCompareItems(modelRow: ModelRow[], tableRow: TableRow) {
        tableRow.findComponentById((comp: any) => {
            if (comp instanceof HandlingRequirementsCompareItem || comp instanceof HandlingRequirementsCompareChangeItem) {
                const compareItems: HandlingRequirementsCompareItem | HandlingRequirementsCompareChangeItem = comp;
                const rows = [];
                modelRow[tableRow.index]["compare_items"].forEach((element: any) => {
                    const newRow = new RowEdiCompareItem(element);
                    rows.push(newRow);
                });
                compareItems.setupTableData(rows);
            }
        });
    }

    private loadChildTableData(table: Table, data: any[], mode: DataSourceMode) {
        const tableDataSource = table.dataSource;
        tableDataSource.data = [];
        const modelRows = [];
        data.forEach((element: any) => {
            modelRows.push(new ModelRow(tableDataSource.url, true, element));
        });
        tableDataSource.setRowsAndMode(mode, modelRows);
    }
}