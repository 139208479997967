export class BrowserType {
    public static getPlatform(): string {
        const platform = navigator.platform?.toLowerCase();
        return platform.indexOf("win") != -1 ? "Windows"
            : platform.indexOf("Mac") != -1 ? "MacOS"
                : platform.indexOf("X11") != -1 ? "UNIX"
                    : platform.indexOf("Linux") != -1 ? "Linux"
                        : platform;
    }

    public static getBrowser(): string {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return userAgent.indexOf('edg') > -1 ? 'Edge'
            : userAgent.indexOf('opr') > -1 && !!window["opr"] ? 'Opera'
                : userAgent.indexOf('chrome') > -1 && !!window["chrome"] ? 'Chrome'
                    : userAgent.indexOf('trident') > -1 ? 'IE'
                        : userAgent.indexOf('firefox') > -1 ? 'Firefox'
                            : userAgent.indexOf('safari') > -1 ? 'Safari'
                                : window.navigator.userAgent;
    }
}