import { Label, TableRowDisplayEvent } from "@mcleod/components";
import { ModelRow } from "@mcleod/core";
import { AutogenLayoutEquipmentTypeList } from "./autogen/AutogenLayoutEquipmentTypeList";

export class EquipmentTypeList extends AutogenLayoutEquipmentTypeList {
    override onLoad() {
        this.mainDataSource.search({});
    }

    tableEquipmentTypeOnRowDisplay(event: TableRowDisplayEvent) {
        const modelRow = event.getTableRow().data as ModelRow;
        const labelAppliesTo = event.getTableRow().findComponentById("labelAppliesTo") as Label;
        switch (modelRow.get("applies_to")) {
            case "D": {
                labelAppliesTo.caption = "Driver Item";
                break;
            }
            case "L": {
                labelAppliesTo.caption = "Trailer";
                break;
            }
            case "T": {
                labelAppliesTo.caption = "Tractor";
                break;
            }
            case "X": {
                labelAppliesTo.caption = "Trailer Item";
                break;
            }
            case "Y": {
                labelAppliesTo.caption = "Tractor Item";
                break;
            }
            default: {
                labelAppliesTo.caption = "Other";
                break;
            }
        }
    }
}
