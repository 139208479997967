import { Button, ButtonVariant, Component, Container, DataSource, DesignableObject, Dialog, Label, Panel, PropType, Snackbar, Splitter, Switch, Table, TableColumn, TableRow, TableRowDisplayEvent, Textbox, Tree, TreeNode } from "@mcleod/components";
import { getLogger } from "@mcleod/core";
import { McLeodMainPage } from "./McLeodMainPage";

class ComponentTreeNode extends TreeNode {
    comp?: DesignableObject;
}

const log = getLogger("common.DiagnosticDialog");

export class DiagnosticDialog extends Dialog {
    panelHeader: Panel;
    buttonRefresh: Button;
    tree: DiagnosticTree;
    panelProps: Panel;
    tableProps: Table;
    splitter: Splitter;
    textSearch: Textbox;
    buttonLog: Button;
    switchShowAllProps: Switch;

    constructor(props?) {
        super({
            title: "Page Diagnostic",
            height: 400,
            width: 800,
            movable: true,
            resizable: true,
            modal: false,
            okVisible: false,
            ...props
        });
        this.panelTitle.marginBottom = 4;
        this.tree = new DiagnosticTree({ fillRow: true, fillHeight: true, rowBreak: false });
        this.tree.addChangeListener(() => this.selectionChanged());
        this.switchShowAllProps = new Switch({ caption: "Show props", leftCaption: "Non-default", rightCaption: "All", color: "subtle.darker", checked: true, rowBreak: false, marginRight: 48 });
        this.switchShowAllProps.addChangeListener(event => { this.selectionChanged(); log.debug("changed", event, this.switchShowAllProps.checked); });

        this.buttonLog = new Button({ imageName: "designer/list", tooltip: "Write this object to the console log", color: "subtle.darker", variant: ButtonVariant.round, rowBreak: false });
        this.buttonLog.addClickListener(() => this.logSelection());
        this.buttonRefresh = new Button({ imageName: "refresh", tooltip: "Refresh data", color: "subtle.darker", variant: ButtonVariant.round });
        this.buttonRefresh.addClickListener(() => this.tree.refresh());
        this.panelHeader = new Panel({ fillRow: true, marginBottom: 4 });
        this.textSearch = new Textbox({ placeholder: "Search components", captionVisible: false, imagePreName: "magnifyingGlass", width: 240, rowBreak: false });
        this.textSearch.addChangeListener(event => this.applySearch());
        this.panelHeader.add(this.textSearch, new Panel({ fillRow: true, rowBreak: false }),
            this.switchShowAllProps, this.buttonLog, this.buttonRefresh);
        this.panelProps = new Panel({ fillRow: true, padding: 0, fillHeight: true });
        this.tableProps = new Table({ fillRow: true, fillHeight: true, headerVisible: false, padding: 0 });
        this.tableProps.addColumn(new TableColumn({ heading: { caption: "Property", maxWidth: 220 }, cell: { field: "prop_name" } }), true, false);
        this.tableProps.addColumn(new TableColumn({ heading: { caption: "Value" }, cell: { field: "prop_value", borderLeftWidth: 1, borderLeftColor: "strokeSecondary" } }), false, true);
        this.tableProps.addRowDisplayListener(event => this.rowDisplayed(event));
        this.panelProps.add(this.tableProps);
        this.splitter = new Splitter({ id: "splitTreeProps", fillHeight: true, position: 440 });
        this.add(this.panelHeader);
        this.add(this.splitter);
        this.splitter.firstComponent = this.tree;
        this.splitter.secondComponent = this.panelProps;
    }

    applySearch() {
        this.tree.filter(this.textSearch.text, (filter: string) => { this.tree.refresh(filter); return this.tree.getRootNode(); });
    }

    selectionChanged() {
        this.tableProps.clearRows();
        const comp = this.tree.selectedNode.comp;
        if (comp == null)
            return;
        const props = comp.getPropertyDefinitions();
        for (const key of Object.keys(props).sort(this.idFirstSort)) {
            const prop = props[key];
            let value = comp[key];
            let defaultValue;
            if (comp instanceof Component)
                defaultValue = comp.getPropertyDefaultValue(prop);
            if (defaultValue === undefined && prop.type === PropType.bool)
                defaultValue = false;
            if (value instanceof DataSource)
                value = value.id;
            const isNonDefault = value != null && value !== defaultValue && (typeof value !== "string" || value.length > 0) && (Array.isArray(value) || typeof value !== "object");
            if (this.switchShowAllProps.checked || isNonDefault)
                this.tableProps.addRow({ prop_name: key, prop_value: value, non_default: isNonDefault }, {}, { display: true });
        }
    }

    idFirstSort(item1: string, item2: string): number {
        if (item1 === "id")
            return -1;
        else if (item2 === "id")
            return 1;
        else
            return item1.localeCompare(item2);
    }

    rowDisplayed(event: TableRowDisplayEvent) {
        const row = event.getTableRow();
        if (row.data.non_default === true) {
            row.cells[0].fontBold = true;
            row.cells[0].color = "default";
        }
    }

    logSelection() {
        const comp = this.tree.selectedNode?.comp;
        if (comp == null)
            Snackbar.showSnackbar("There was no selection to log.");
        else {
            log.info("Selected item in diagnostic", comp);
            Snackbar.showSnackbar("Selection has been logged to console.");
        }
    }

    focus() {
        this.tree.focus();
    }
}

export class DiagnosticTree extends Tree<ComponentTreeNode> {
    constructor(props?) {
        super(props);
        this.refresh();
        if (this.getRootNode().getChildCount() > 0)
            this.selectedNode = this.getRootNode().getChild(0);
    }

    refresh(filter?: string) {
        this.getRootNode().removeAllChildren();
        const nodes = this.getDiagnosticNodes(filter);
        this.getRootNode().addChild(...nodes);
    }

    getDiagnosticNodes(filter: string): TreeNode[] {
        const result = [];
        const nodeComponents: TreeNode = new TreeNode("Components");
        const nodeDataSources: TreeNode = new TreeNode("DataSources");
        const topLevelComp = McLeodMainPage.getInstance()?.router?.components[0];
        if (topLevelComp instanceof Container) {
            this.addComponents(nodeComponents, topLevelComp.components, filter);
            if (topLevelComp["layout"] != null) // set by the ListDecorator and AdvancedDecorator... probably want to make the decorators extend something and check instanceof here?
                this.addDataSources(nodeDataSources, topLevelComp["layout"], filter);
        }
        result.push(nodeComponents);
        result.push(nodeDataSources);
        return result;
    }

    addComponents(parent: TreeNode, comps: Component[], filter: string): boolean {
        let result = false;
        for (const comp of comps) {
            const child = new ComponentTreeNode({ caption: comp.id + " ( " + comp.constructor.name + " )" });
            child.comp = comp;
            let shouldAdd = this.passesFilter(comp, filter);
            if (filter != null && shouldAdd === true)
                child.setProps({ color: "primary", fontBold: true });
            let comps: Component[];
            if (comp instanceof Container && comp.components?.length > 0)
                comps = comp.components;
            else if (comp instanceof Table)
                comps = comp.getComponentsForDiagnostic();
            else if (comp instanceof TableRow)
                comps = comp.cells;
            if (comps != null) {
                const subsPass = this.addComponents(child, comps, filter);
                shouldAdd = shouldAdd || subsPass;
            }
            result = result || shouldAdd;
            if (shouldAdd)
                parent.addChild(child);
        }
        return result;
    }

    passesFilter(comp: DesignableObject, filter: string): boolean {
        if (filter == null)
            return true;
        if (comp == null)
            return false;
        filter = filter.toLowerCase();
        if (comp["getPropertyDefinitions"] == null) {
            log.info("Unexpected component", comp); // tdexapnders currently cause this
            return true;
        }
        const props = comp.getPropertyDefinitions();
        for (const key in props) {
            const prop = props[key];
            const value = comp[key];
            // if (prop.name?.toLowerCase().includes(filter))
            //   return true;
            if (value?.toString().toLowerCase().includes(filter)) {
                log.debug("Passes", comp, "Prop", prop.name, "Value", value);
                return true;
            }
        }
        return false;
    }

    addDataSources(parent: TreeNode, layout: any, filter?: string) {
        const added: string[] = [];
        for (const member of Object.values(layout))
            if (member instanceof DataSource) {
                if (added.indexOf(member.id) < 0) {
                    if (this.passesFilter(member, filter)) {
                        const childNode = new ComponentTreeNode({ caption: member.id });
                        childNode.comp = member;
                        const child = parent.addChild(childNode);
                        this.addDataNodes(child, member.data);
                        added.push(member.id);
                    }
                }
            }
    }

    addDataNodes(parent: TreeNode, data: any[]) {
        let keys = Object.keys(data);
        if (!Array.isArray(data))
            keys = keys.sort();
        for (const key of keys) {
            const value = data[key];
            let child: TreeNode;
            if (this.isLeaf(value)) {
                const panel = new Panel();
                panel.add(new Label({ caption: key + " :", fontBold: true, width: 280, rowBreak: false, color: "success" }));
                panel.add(new Label({ caption: value }));
                child = new TreeNode({ component: panel });
            }
            else {
                const keyNum = parseInt(key) + 1;
                const lbl = isNaN(keyNum) ? key : "Record: " + keyNum;
                child = new TreeNode(lbl);
                this.addDataNodes(child, value);
            }
            parent.addChild(child);
        }
    }

    private isLeaf(obj: any): boolean {
        const t = typeof (obj);
        return obj == null || t === "string" || t === "number" || t === "boolean" || obj instanceof Date;
    }
}
