import { AutogenModelBrokerRatingHeader, AutogenRowBrokerRatingHeader } from "./autogen/AutogenModelBrokerRatingHeader";

export class ModelBrokerRatingHeader extends AutogenModelBrokerRatingHeader<RowBrokerRatingHeader> {
    constructor() {
        super(RowBrokerRatingHeader);
    }
}

export class RowBrokerRatingHeader extends AutogenRowBrokerRatingHeader {
}
