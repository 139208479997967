import { AutogenModelBrltlOrderFreightGroupItem, AutogenRowBrltlOrderFreightGroupItem } from "./autogen/AutogenModelBrltlOrderFreightGroupItem";

export class ModelBrltlOrderFreightGroupItem extends AutogenModelBrltlOrderFreightGroupItem<RowBrltlOrderFreightGroupItem> {
    constructor() {
        super(RowBrltlOrderFreightGroupItem);
    }
}

export class RowBrltlOrderFreightGroupItem extends AutogenRowBrltlOrderFreightGroupItem {
}
