import { Collection, DateUtil, getAuthSettings, getLogger, HorizontalAlignment, VerticalAlignment } from "@mcleod/core";
import { Button, Label, Panel, PanelProps } from "../..";
import { ListenerListDef } from "../../base/ListenerListDef";
import { ButtonVariant } from "../../components/button/ButtonVariant";
import { Clock } from "./Clock";
import { DateTimePickerChangeListener } from "./DateTimePickerChangeEvent";

export interface TimePickerSelectionListener {
    (date: Date): void;
}

export interface TimePickerProps extends PanelProps {
    value: Date;
}

const _changeListenerDef: ListenerListDef = { listName: "_changeListeners" };

const log = getLogger("components.page.pickers.TimePicker");

export class TimePicker extends Panel {
    heading: Panel;
    labelHours: Button;
    labelColon: Label;
    labelMinutes: Button;
    labelAMPM: Button;
    panelContent: Panel;
    panelClock: Clock;
    civilianTime: boolean;
    private _value: Date;

    constructor(props?: Partial<TimePickerProps>) {
        super({ padding: 0, width: 314, fillRow: true }, false);
        this.civilianTime = getAuthSettings()?.user_settings?.time_format?.indexOf("a") > 0;
        log.debug(() => ["Time format", getAuthSettings()?.user_settings?.time_format, "Civ", this.civilianTime]);
        this.value = props?.value || new Date();
        this.panelClock = new Clock({ civilianTime: this.civilianTime, value: this.value });
        this.heading = new Panel({ verticalAlign: VerticalAlignment.CENTER, align: HorizontalAlignment.CENTER, height: 51, backgroundColor: "primary", color: "primary.reverse", fillRow: true });
        this.labelHours = new Button({ rowBreak: false, fontSize: "xlarge", width: 32, variant: ButtonVariant.round });
        this.labelHours.addClickListener(() => this.panelClock.editingHours = true);
        this.labelColon = new Label({ caption: ":", fontSize: "xlarge", paddingLeft: 0, paddingRight: 0, rowBreak: false });
        this.labelMinutes = new Button({ rowBreak: false, fontSize: "xlarge", margin: 0, width: 32, variant: ButtonVariant.round });
        this.labelMinutes.addClickListener(() => this.panelClock.editingHours = false);
        this.heading.add(this.labelHours, this.labelColon, this.labelMinutes);
        if (this.civilianTime) {
            this.labelAMPM = new Button({ fontSize: "xlarge", variant: ButtonVariant.round, margin: 2, backgroundColor: "primary" });
            this.labelAMPM.addClickListener(event => this.panelClock.setAM(!this.panelClock.am));
            this.heading.add(this.labelAMPM);
        }
        this.panelContent = new Panel({ padding: 16, fillRow: true, fillHeight: true });
        this.panelClock.value = this.value;
        this.panelClock.addChangeListener(event => {
            this.updateDisplay();
            this.fireListeners(_changeListenerDef, event);
        });
        this.add(this.heading, this.panelContent);
        this.panelContent.add(this.panelClock);
        this.updateDisplay();
        // this.setProps(props);
    }

    updateDisplay() {
        const date = this.panelClock.value;
        if (this.civilianTime) {
            this.labelHours.caption = DateUtil.formatDateTime(date, "hh");
            this.labelAMPM.caption = DateUtil.formatDateTime(date, "aaa");
        }
        else
            this.labelHours.caption = DateUtil.formatDateTime(date, "HH");
        this.labelMinutes.caption = date.getMinutes().toString().padStart(2, '0');
        this.labelHours.backgroundColor = this.panelClock.editingHours ? "primary.light" : "unset";
        this.labelMinutes.backgroundColor = this.panelClock.editingHours ? "unset" : "primary.light";
    }

    addChangeListener(value: DateTimePickerChangeListener): TimePicker {
        return <TimePicker>this.addEventListener(_changeListenerDef, value);
    }

    removeChangeListener(value: DateTimePickerChangeListener): TimePicker {
        return <TimePicker>this.removeEventListener(_changeListenerDef, value);
    }

    get value() {
        return this._value;
    }

    set value(value: Date) {
        this._value = value || new Date();
    }

    override getListenerDefs(): Collection<ListenerListDef> {
        return { ...super.getListenerDefs(), "change": { ..._changeListenerDef } };
    }
}
