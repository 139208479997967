import {
    Button, ClickEvent, Component, DataSourceExecutionEvent, DataSourceMode, Layout, SaveButton, SlideoutDecorator,
    TableRow, TableRowDisplayEvent, Textbox
} from "@mcleod/components";
import { ModelRow, PermissionsUtil, RowUpdateEvent } from "@mcleod/core";
import { ClearStop } from "./ClearStop";
import { DispatchSlideout } from "./DispatchSlideout";
import { makeTooltipCallbackFunction } from "./LocationMakeQuickInfo";
import { ServiceIncident } from "./ServiceIncident";
import { StopNotesAndReferenceNumbers } from "./StopNotesAndReferenceNumbers";
import { AutogenLayoutStopList } from "./autogen/AutogenLayoutStopList";
import { RowCallin } from "./models/ModelCallin";
import { getDispatchControlValue } from "@mcleod/general/src/models/ModelDispatchControl";

export class StopList extends AutogenLayoutStopList {
    private _showCommentsAndReference = true;

    /** This is an event handler for the onRowDisplay event of tableStop.  */
    tableStopOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.target as TableRow;
        const modelRow: ModelRow = tableRow.data as ModelRow;
        let sfRow = null;

        tableRow.table.dataSource.preventChangeNotifications = true;
        const locationName: Component = tableRow.findComponentById("textboxLocationName");
        if (locationName != null)
            locationName.tooltipCallback = makeTooltipCallbackFunction(modelRow.get("location_id"), locationName);
        if ("A" === modelRow.get("status") && modelRow.get("actual_arrival") != null)
            tableRow.findComponentById("labelArrivedBadge").visible = true;
        if ("D" === modelRow.get("status")) {
            tableRow.findComponentById("labelDepartedBadge").visible = true;
        }
        const buttonEditArrivalDepart = tableRow.findComponentById("buttonEditArrivalDepart") as Button;
        const userDeniedUpdateMoveAfterDelivery: boolean = PermissionsUtil.isUserDeniedAction("Dispatch.Update movements after delivery");
        buttonEditArrivalDepart.visible = modelRow.get("status") === "D" && !userDeniedUpdateMoveAfterDelivery;
        tableRow.findComponentById("labelAppointmentRequired").visible = modelRow.get("appt_required") === "Y" && modelRow.get("confirmed") === "N";
        tableRow.findComponentById("labelAppointmentConfirmed").visible = modelRow.get("appt_required") === "Y" && modelRow.get("confirmed") === "Y";
        const stopNotesAndReferenceLayout = tableRow.findComponentById((comp: any) => comp instanceof StopNotesAndReferenceNumbers) as StopNotesAndReferenceNumbers;
        stopNotesAndReferenceLayout.addLayoutLoadListener(() => {
            stopNotesAndReferenceLayout.stopRow = tableRow.data;
            stopNotesAndReferenceLayout.tableStopComments.allowEdit = false;
            stopNotesAndReferenceLayout.tableStopReferenceNumbers.allowEdit = false;
            if (!this._showCommentsAndReference) {
                stopNotesAndReferenceLayout.tabsetStopNotesRefNos.visible = false;
            }
        });

        const serviceIncidentLayout = tableRow.findComponentById((comp: any) => comp instanceof ServiceIncident) as ServiceIncident;
        serviceIncidentLayout.addLayoutLoadListener(() => {
            serviceIncidentLayout.visible = false;
            if (modelRow.get("service_fail") != null) {
                sfRow = new ModelRow("lme/dispatch/servicefail", false, modelRow.get("service_fail"));
                serviceIncidentLayout.orderCustomerId = this.sourceStop.parentDataSource?.activeRow?.get("orders.customer_id");
                serviceIncidentLayout.sourceServicefail.setRowsAndMode(DataSourceMode.UPDATE, [sfRow], null);
                serviceIncidentLayout.visible = true;
                serviceIncidentLayout.sourceServicefail.parentDataSource = this.sourceStop.parentDataSource;
            }
        });

        if (this.tableStop.dataSource.data.length == this.tableStop.rowCount)
            this.syncWidths();
    }

    private syncWidths() {
        const syncComps = new Map([["panelLocation", null], ["panelContact", null], ["panelDriverLoadUnload", null], ["panelDeliveryTimes", null], ["panelArriveDepart", null]])

        // get the widest component from the list of components from all table rows
        this.tableStop.rows.forEach(row => {
            syncComps.forEach((value, key) => {
                const comp = row.findComponentById(key);
                if (comp != null && (value == null || comp._element.clientWidth > value))
                    syncComps.set(key, comp._element.clientWidth);
            })
        })

        // set the width of the componenets on each row
        this.tableStop.rows.forEach(row => {
            syncComps.forEach((value, key) => {
                const comp = row.findComponentById(key);
                if (comp != null && value != null)
                    comp.width = value;
            })
        })
    }

    public set showCommentsAndReference(value: boolean) {
        this._showCommentsAndReference = value;
    }

    public get showCommentsAndReference(): boolean {
        return this._showCommentsAndReference;
    }

    /** This is an event handler for the onClick event of buttonEditCallinStop.  */
    buttonEditArrivalDepartOnClick(event: ClickEvent) {
        const buttonEditArrivalDepart = event.target as Button;
        buttonEditArrivalDepart.busy = true;
        const tableRow = TableRow.getContainingTableRow(event.target as Component);
        const stopType = (tableRow.findComponentById("textboxStopType") as Textbox).selectedItem.caption;
        const stopId = tableRow.data?.get("id");
        let showNextStopEta = false;
        if (tableRow.index + 1 < tableRow.table.rowCount) {
            const nextStopTableRow = tableRow.table.rows[tableRow.index + 1];
            showNextStopEta = "A" === nextStopTableRow.data.get("status")
        }
        if (stopId != null) {
            const saveBtn = new SaveButton({ rowBreak: false });
            const layout = Layout.getLayout("lme/dispatch/DispatchSlideout") as DispatchSlideout;
            const decorator = new SlideoutDecorator({
                layout: layout,
                fillVerticalSpace: true,
                addlComponents: [saveBtn],
                width: 900,
                title: `${stopType} Arrival and Departure - Order ${tableRow.data.get("order_id")}`,
                doAfterSlideIn: () => buttonEditArrivalDepart.busy = false,
                onClose: () => decorator.slideOut(),
                layoutLoadListeners: (async () => {
                    const clearStop = layout.layoutClearStop as ClearStop;
                    saveBtn.dataSource = clearStop.sourceCallinStop;
                    layout.configureForArrivalDepartEdits(showNextStopEta);

                    clearStop.sourceCallinStop.search({ id: stopId }).then(result => {
                        clearStop.sourceCallinStop.mode = DataSourceMode.UPDATE;
                        clearStop.ordersCustomerId = this.sourceStop.parentDataSource?.activeRow?.get("orders.customer_id")
                        clearStop.sourceCallinStop.addAfterExecutionListener((event: DataSourceExecutionEvent) => {
                            decorator.slideOut().then(() => this.sourceStop.parentDataSource.search(this.sourceStop.parentDataSource.lastSearch));
                        })

                        clearStop.textboxActualArrival.required = true;
                        if ( getDispatchControlValue("require_departure") == 'Y') {
                            clearStop.textboxActualDeparture.required = true;
                        }
                        clearStop.sourceCallinStop.activeRow.addBeforePostListener((event: RowUpdateEvent) => {
                            return new Promise((resolve) => {
                                layout.isValid().then(isValid => {
                                    if (!isValid)
                                        event.preventDefault("Invalid Data", () => { });
                                    resolve(isValid);
                                })
                            })
                        })
                        clearStop.sourceCallin.setRowsAndMode(DataSourceMode.NONE, [new RowCallin()], () => {
                            if (showNextStopEta)
                                clearStop.calcNextStopEta();
                        });
                    });
                })
            });
        }
    }
}
