import { Event, EventListener } from "./Event";

export interface DragListener extends EventListener {
    (event: DragEvent): void;
}

export class DragEvent extends Event {
    public get altKey(): boolean {
        return this.domEvent.altKey;
    }

    public get ctrlKey(): boolean {
        return this.domEvent.ctrlKey;
    }

    public get shiftKey(): boolean {
        return this.domEvent.shiftKey;
    }

    public get domEvent(): MouseEvent {
        return super.domEvent as MouseEvent;
    }
}
