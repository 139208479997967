import { TableRow, TableRowMode, Textbox } from "@mcleod/components";
import { DatePart, DateUtil, ModelRow } from "@mcleod/core";
import { AutogenLayoutCallRecordTable } from "./autogen/AutogenLayoutCallRecordTable";

export class CallRecordTable extends AutogenLayoutCallRecordTable {
    private _locationId: string;
    private _customerId: string;
    private _daysBetweenCalls: number;

    get locationId(): string {
        return this._locationId;
    }

    set locationId(value: string) {
        this._locationId = value;
    }

    get customerId(): string {
        return this._customerId;
    }

    set customerId(value: string) {
        this._customerId = value;
    }

    get daysBetweenCalls(): number {
        return this._daysBetweenCalls;
    }

    set daysBetweenCalls(value: number) {
        this._daysBetweenCalls = value;
    }

    /** This is an event handler for the beforeLookupModelSearch event of textboxContactName.  */
    textboxContactNameBeforeLookupModelSearch(event) {
        if (this.locationId !== undefined) {
            event.filter.parent_row_type = "L";
            event.filter.parent_row_id = this.locationId ?? null;
        }
        else {
            event.filter.parent_row_type = "C";
            event.filter.parent_row_id = this.customerId ?? null;
        }
        event.filter.is_active = "Y";
    }

    /** This is an event handler for the onRowDisplay event of tableCallRecords.  */
    tableCallRecordsOnRowDisplay(event) {
        const tableRow = event.target as TableRow;
        tableRow.canBeDeleted = false;
        const nextCallDate = tableRow.findComponentById("textboxNextCallDate") as Textbox;
        if (tableRow.mode == TableRowMode.QUICK_ADD) {
            tableRow.data.set("next_call_date", DateUtil.dateAdd(DatePart.DAY, new Date(), this.daysBetweenCalls ?? 0));
            nextCallDate.displayData(tableRow.data, null, 0);
            nextCallDate.visible = true;
        }
    }

    /** This is an event handler for the beforeRowSave event of tableCallRecords.  */
    tableCallRecordsBeforeRowSave(event) {
        const modelRow = event.getTableRow().data as ModelRow;
        if (this.locationId !== undefined) {
            modelRow.set("parent_row_type", "L");
            modelRow.set("parent_row_id", this.locationId);
        }
        else {
            modelRow.set("parent_row_type", "C");
            modelRow.set("parent_row_id", this.customerId);
        }
    }
}
