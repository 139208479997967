import { CommonDialogs } from "@mcleod/common";
import {
    Button, DataSourceMode, Label, Layout, Panel, PanelProps, Snackbar, Step, StepEvent, Toast
} from "@mcleod/components";
import { Alignment, getAuthSettings, getLogger, HorizontalAlignment, ModelRow } from "@mcleod/core";
import { CarrierOfferInfo } from "@mcleod/dispatch/src/CarrierOfferSlideout";
import { SendLoadTender } from "../../datafusion/src/SendLoadTender";
import { Carrier } from "../../dispatch/src/Carrier";
import { Movement } from "../../dispatch/src/Movement";
import { OrderCreditValidator } from "../../dispatch/src/OrderCreditValidator";
import { Orders } from "../../dispatch/src/Orders";
import { CarrierAssignment } from "./CarrierAssignment";
import { InitiateBrokerageTracking } from "./InitiateBrokerageTracking";
import { SendRateConfirmation } from "./SendRateConfirmation";
import { AutogenLayoutCarrierAssignmentStepper } from "./autogen/AutogenLayoutCarrierAssignmentStepper";

export interface CarrierAssignmentStepperProps extends PanelProps {
    movementId: string,
    orderId: string,
    carrierAssignmentButton: Button,
    carrierOfferInfo?: CarrierOfferInfo,
    onClose: (canceled: boolean, updatedData?: ModelRow) => void,
    doAfterCarrierAssignmentSearch: (layout: CarrierAssignment) => void;
    doAfterSlideIn: (layout: CarrierAssignment) => void;
}

const INDEX_ASSIGN = 0;
const INDEX_RATE_CON = 1;
const INDEX_TRACKING = 2;

const log = getLogger("powerbroker/CarrierAssignmentStepper");

export class CarrierAssignmentStepper extends AutogenLayoutCarrierAssignmentStepper {
    private movementId: string;
    private orderId: string;
    private onClose: (canceled: boolean, updatedData?: ModelRow) => void;
    private doAfterCarrierAssignmentSearch: (layout: CarrierAssignment) => void;
    private manualTrackingRequired = true;
    private activeTrackingVendorsFound = false;
    private assignedToastPanel: Panel;
    private changingStep = false;
    private panelHeader: Panel;
    private carrierAssignmentLayout: CarrierAssignment;
    private sendRateConLayout: SendRateConfirmation;
    private sendLoadTenderLayout: SendLoadTender;
    private initiateTrackingLayout: InitiateBrokerageTracking;

    override onLoad() {
        this.stepper["panelHeaderContent"].setProps({ paddingLeft: 30, paddingRight: 30, marginBottom: 24 });
        this.stepper.addBeforeStepListener((event: StepEvent) => {
            this.doBeforeStep(event);
        });
    }

    doBeforeStep(event: StepEvent) {
        if (!this.changingStep && event.newIndex > event.oldIndex) {
            event.preventDefault();
        } else {
            this.doBeforeStepLayoutDisplay(event.newIndex);
        }
    }

    doBeforeStepLayoutDisplay(index: number) {
        if (this.parent?.["dismissAllSnackbars"])
            this.parent["dismissAllSnackbars"]();
        const layout = this.getStepLayout(index);
        if (layout != null) {
            layout.setAllDataSourcesToMode(DataSourceMode.UPDATE);
            if (this.panelHeader != null) {
                this.panelHeader.setProps({ padding: 0, marginBottom: 12 });
                this.panelHeader.removeAll();
                layout["panelHeader"].visible = true;
                this.panelHeader.add(layout["panelHeader"]);
            }
        }
    }

    private getStepLayout(index: number) {
        return this.getStep(index).components[0] as Layout;
    }

    private getStep(index: number): Step {
        return this.stepper.components[index] as Step
    }

    private setupCarrierAssignmentLayout(carrierOfferInfo?: CarrierOfferInfo): Promise<CarrierAssignment> {
        return new Promise((resolve, reject) => {
            if (this.carrierAssignmentLayout != null) {
                resolve(this.carrierAssignmentLayout);
            } else {
                const layout = Layout.getLayout("lme/powerbroker/CarrierAssignment") as CarrierAssignment;
                layout.carrierOfferInfo = carrierOfferInfo;
                layout.addLayoutLoadListener(() => {
                    layout.sourceCarriersForAssignment.addDisplayListener((event) => {
                        if (event.rowData == null && layout.mainDataSource.activeRow != null) {
                            this.activeTrackingVendorsFound = layout.mainDataSource?.activeRow?.get("active_tracking_vendors_found", false);
                            if (this.activeTrackingVendorsFound) {
                                const manualTrackingRequiredOnMovement = layout.mainDataSource.activeRow?.getBoolean("manual_tracking_required", false);
                                this.setManualTrackingRequired(manualTrackingRequiredOnMovement, layout);
                            } else {
                                this.setManualTrackingRequired(false, layout);
                            }
                        } else if (!this.manualTrackingRequired && this.activeTrackingVendorsFound) {
                            const trackingDisabledOnPayee: boolean = layout.sourceCarriersForAssignment?.activeRow?.get("disable_automatic_tracking", "N") === "Y";
                            this.setManualTrackingRequired(trackingDisabledOnPayee, layout);
                        }
                    });
                    layout.mainDataSource.search({ "id": this.movementId, "orders.id": this.orderId }).then(result => {
                        if (layout.mainDataSource?.activeRow == null) {
                            reject("Unable to find movement");
                        } else {
                            const lockErrorMessage = layout.mainDataSource?.activeRow?.get("locked_error_message");
                            if (lockErrorMessage != null) {
                                reject(lockErrorMessage);
                            } else {
                                this.activeTrackingVendorsFound = layout.mainDataSource?.activeRow?.get("active_tracking_vendors_found", false);
                                this.setManualTrackingRequired(layout.mainDataSource?.activeRow?.get("manual_tracking_required", false) == true, layout);
                                layout.doAfterClose = (canceled: boolean) => this.close(canceled);
                                if (this.doAfterCarrierAssignmentSearch != null) {
                                    this.doAfterCarrierAssignmentSearch(layout);
                                }
                                this.getStep(INDEX_ASSIGN).add(layout);
                                this.carrierAssignmentLayout = layout;
                                resolve(layout);
                            }
                        }

                        let carrierStatus = "false";
                        let batch = "0";
                        layout.mainDataSource.addAfterExecutionListener(() => {
                            if (!layout.activeRow?.isNull("override_payee_id")) {
                                if (window.location.pathname == "/lme/powerbroker/BrokeragePlanning") {
                                    this.assignedToastPanel = this.getAssignedToastPanel(
                                        layout.mainDataSource.activeRow.get("override_payee_id"),
                                        layout.mainDataSource.activeRow.get("_lookup_pending_payee_id")[0].data.name
                                    );
                                }
                                const carrierId = layout.activeRow.get("override_payee_id");

                                CarrierAssignment.checkCarrierForDataFusion(carrierId).then(result => {
                                    carrierStatus = result;
                                    const rcStep = this.stepper.findComponentById("sendRateConStep") as Step;

                                    if (carrierStatus == "auto") {
                                        rcStep.caption = "Sent Tender";
                                        CarrierAssignment.sendAutoTender(carrierId, this.movementId).then(result => {
                                            log.debug("Result: " + result.data[0].tendersent);
                                            if (result.data[0].tendersent) {
                                                batch = result.data[0].batchid;
                                                const notes = result.data[0].text;
                                                Snackbar.showSnackbar("Result: " + notes + ".");
                                            } else {
                                                Snackbar.showWarningSnackbar("Failed to send outbound tender to DataFusion carrier " + carrierId + ": " + result.data[0].text);
                                            }
                                            this.setupLoadTenderLayout(carrierId, this.movementId, true, batch).then(result => {
                                                this.advanceToNextStep();
                                            });
                                        });
                                    } else if (carrierStatus == "manual") {
                                        rcStep.caption = "Send Tender";
                                        this.setupLoadTenderLayout(carrierId, this.movementId, false, "0").then(result => {
                                            this.advanceToNextStep();
                                        });
                                    } else {
                                        rcStep.caption = "Send Rate Con";
                                        this.setupRateConLayout().then(() => {
                                            this.advanceToNextStep();
                                            this.modifyLayoutAfterAssignment(layout);
                                        });
                                    }
                                });
                            } else {
                                const activeRow = layout.mainDataSource.activeRow;
                                const pendingPayee = activeRow.get("pending_payee_id", activeRow.get("pending_carrier_nbr"));
                                this.close(layout.origPendingPayeeId == null && pendingPayee == null, activeRow);
                            }
                        });
                    });
                });
            }
        });
    }

    modifyLayoutAfterAssignment(layout: CarrierAssignment) {
        layout.textboxPendingPayeeId.setProps({ printable: true, dataSource: null });
        const clickListeners = Object.assign([], layout.savebuttonAssign.getListeners("_clickListeners").listeners);
        for (const listener of clickListeners) {
            layout.savebuttonAssign.removeClickListener(listener);
        }
        layout.savebuttonAssign.setProps({
            caption: "Next",
            dataSource: layout.mainDataSource,
            busyWhenDataSourceBusy: true,
            onClick: () => layout.mainDataSource.post()
        });
        layout.buttonCancel.setProps({ preventCollapse: true, visible: false });
        layout.carrierPayCallback = (hasErrors: boolean) => {
            layout.savebuttonAssign.enabled = !hasErrors;
            if (!layout.savebuttonAssign.enabled)
                layout.savebuttonAssign.disabledTooltip = "Errors must be resolved before advancing to next step";
        };
    }

    private setupRateConLayout(): Promise<SendRateConfirmation> {
        return new Promise((resolve) => {
            if (this.sendRateConLayout != null) {
                if (this.sendRateConLayout.hasChangedData(this.carrierAssignmentLayout)) {
                    this.sendRateConLayout.search({
                        movement_id: this.movementId,
                        contact_name: this.carrierAssignmentLayout.activeRow.get("carrier_contact"),
                        email_address: this.carrierAssignmentLayout.activeRow.get("carrier_email")
                    }).then(() => {
                        resolve(this.sendRateConLayout);
                    });
                } else {
                    resolve(this.sendRateConLayout);
                }
            } else {
                const layout = Layout.getLayout("lme/powerbroker/SendRateConfirmation") as SendRateConfirmation;
                layout.addLayoutLoadListener(() => {
                    layout.search({
                        movement_id: this.movementId,
                        contact_name: this.carrierAssignmentLayout.activeRow.get("carrier_contact"),
                        email_address: this.carrierAssignmentLayout.activeRow.get("carrier_email")
                    }).then(() => {
                        layout.doAfterRateConSent = () => this.doAfterRateConStep();
                        layout.buttonSendLater.visible = true;
                        layout.buttonCancel.visible = false;
                        layout.buttonSend.caption = "Send Now";
                        layout.buttonSendLater.addClickListener(() => {
                            this.doAfterRateConStep(layout.buttonSendLater);
                        });
                        this.getStep(INDEX_RATE_CON).add(layout);
                        this.sendRateConLayout = layout;
                        resolve(this.sendRateConLayout);
                    });
                });
            }
        });
    }

    doAfterRateConStep(buttonSendLater?: Button) {
        if (buttonSendLater) buttonSendLater.busy = true;
        if (this.manualTrackingRequired) {
            this.setupTrackingLayout().then(() => {
                this.advanceToNextStep();
                if (buttonSendLater) buttonSendLater.busy = false;
            })
        } else {
            this.close(false);
        }
    }

    setupTrackingLayout(): Promise<InitiateBrokerageTracking> {
        return new Promise((resolve) => {
            if (this.initiateTrackingLayout != null) {
                resolve(this.initiateTrackingLayout);
            } else {
                const layout = Layout.getLayout("lme/powerbroker/InitiateBrokerageTracking") as InitiateBrokerageTracking;
                layout.addLayoutLoadListener(() => {
                    layout.search({ id: this.movementId }).then(() => {
                        layout.buttonInitiateLater.addClickListener(() => this.close(false));
                        layout.buttonInitiateLater.visible = true;
                        layout.buttonCancel.visible = false;
                        layout.buttonSave.caption = "Initiate Now";
                        layout.doAfterTrackingInitiated = () => this.close(false);
                        this.getStep(INDEX_TRACKING).add(layout);
                        this.initiateTrackingLayout = layout;
                        resolve(layout);
                    });
                });
            }
        });
    }

    advanceToNextStep() {
        const nextIndex = this.stepper.selectedIndex + 1;
        if (nextIndex >= this.stepper.components.length) {
            this.close(false);
        } else {
            this.changingStep = true;
            this.stepper.selectedIndex = nextIndex;
            this.changingStep = false;
        }
    }

    private setManualTrackingRequired(value: boolean, layout: CarrierAssignment) {
        if (this.manualTrackingRequired != value) {
            this.manualTrackingRequired = value;
            this.stepper.removeAt(INDEX_TRACKING);
        }
    }

    close(canceled: boolean, updatedData?: ModelRow) {
        this.parent.slideOut().then(() => {
            if (this.onClose)
                this.onClose(canceled);
            if (!canceled) {
                if (this.assignedToastPanel != null) {
                    Toast.showToast(this.assignedToastPanel, null, { millisUntilDismissal: 8000 });
                } else if (updatedData?.get("has_pending_assignment")) {
                    if (updatedData?.get("pending_payee_id") !== null && updatedData?.get("pending_payee_id") !== undefined) {
                        Toast.showToast(`A pending assignment has been created for order ${updatedData.get("orders.id")} with carrier ${updatedData.get("pending_carrier_name", updatedData.get("_lookup_pending_payee_id")[0].data.name)}`, null, { millisUntilDismissal: 8000 });
                    } else if (updatedData?.get("pending_carrier_name") !== null && updatedData?.get("pending_carrier_name") !== undefined) {
                        Toast.showToast(`A pending assignment has been created for order ${updatedData.get("orders.id")} with carrier ${updatedData?.get("pending_carrier_name")}`, null, { millisUntilDismissal: 8000 });
                    }

                }
            }
        });
    }


    getAssignedToastPanel(carrierID: string, carrierName: string, notes?: string): Panel {
        if (carrierID == null || carrierName == null) return null;
        const labelProps = {
            color: "white",
            fontSize: "large",
            rowBreak: false,
            paddingLeft: 1,
            paddingRight: 1,
            marginLeft: 0,
            marginRight: 0
        }
        const labelLinkProps = { ...labelProps, style: { textDecoration: "underline" } }
        return new Panel({
            align: HorizontalAlignment.CENTER, fillRow: true,
            components: [
                new Label({ caption: "Movement Assigned", fontSize: "xxlarge", fontBold: true, rowBreak: true }),
                new Label({ ...labelProps, caption: "Order " }),
                new Label({ ...labelLinkProps, caption: this.orderId, onClick: event => Orders.navigateTo(this.orderId) }),
                new Label({ ...labelProps, caption: " / Movement " }),
                new Label({
                    ...labelLinkProps,
                    caption: this.movementId,
                    onClick: event => Movement.navigateTo(this.movementId)
                }),
                new Label({ ...labelProps, caption: " has been assigned to " }),
                new Label({ ...labelLinkProps, caption: carrierName, onClick: event => Carrier.navigateTo(carrierID) })
            ]
        });
    }

    static showAsSlideout(props: Partial<CarrierAssignmentStepperProps>) {
        const button = props.carrierAssignmentButton;
        const carrierOfferInfo = props?.carrierOfferInfo;
        if (button) button.busy = true;

        const panelHeader = new Panel({ padding: 0, fillRow: true });
        const panelWrapper = new Panel({
            padding: 0, scrollY: false, id: "panelWrapper",
            maxWidth: 1300, width: "75%", height: "100%",
            backgroundColor: "defaultBackground", top: 30,
            borderRadius: 4, borderWidth: 1, borderShadow: true, borderColor: "strokeSecondary", ...props
        });

        Layout.getLayout("lme/powerbroker/CarrierAssignmentStepper", { ...props }).addLayoutLoadListener(event => {
            const carrierAssignmentStepper = event.target as CarrierAssignmentStepper;
            carrierAssignmentStepper.setupCarrierAssignmentLayout(carrierOfferInfo).then((carrierAssignment) => {
                carrierAssignmentStepper.panelHeader = panelHeader;
                panelWrapper.add(panelHeader, carrierAssignmentStepper);
                carrierAssignmentStepper.doBeforeStepLayoutDisplay(INDEX_ASSIGN);
                const overlayProps = { closeOnClickOff: false, greyedBackground: true };
                panelWrapper.slideIn({ direction: Alignment.RIGHT }, true, overlayProps).then(async () => {
                    if (props.doAfterSlideIn != null) {
                        props.doAfterSlideIn(carrierAssignment);
                    }

                    const carrierId = carrierAssignment.activeRow?.get("pending_payee_id");
                    if (carrierId != null) {
                        carrierAssignment.validateCarrierForDispatch(carrierId);
                    }

                    if (!!carrierOfferInfo) {
                        if (carrierId != null) {
                            carrierAssignment.activeRow.setLookupModelData("pending_payee_id", new ModelRow(carrierAssignment.textboxPendingPayeeId.lookupModel, false, {
                                id: carrierId,
                                name: carrierOfferInfo.carrier_name
                            }));
                            carrierAssignment.textboxPendingPayeeId.displayData(carrierAssignment.activeRow, null, 0);
                            carrierAssignment.setupLookupsForPendingCarriers(false);
                        } else {
                            carrierAssignment.setPanelUnknownCarrierVisible(true, false);
                            carrierAssignment.setPendingValuesFromOffer();
                        }

                        if (carrierOfferInfo.dispatch_offer)
                            carrierAssignment.activeRow.set("override_pay_rate", carrierOfferInfo.dispatch_offer.amount);
                    }
                    carrierAssignment.layoutMovementPay.defaultPayUnitsAndMethod();

                    carrierAssignment.layoutMovementPay.calculatePay(false, getAuthSettings().dispatch_control[0].rate_carrier == "Y", false, true);
                    carrierAssignment.textboxPendingPayeeId.focus();
                    carrierAssignment.setDFLabel(carrierId);
                    OrderCreditValidator.validateCreditAndShowWarning(carrierAssignmentStepper.orderId);
                });
            }).catch(reason => {
                CommonDialogs.showError(reason);
            }).finally(() => {
                if (button) button.busy = false;
            });
        });
    }

    private setupLoadTenderLayout(carrierId: string, movementId: string, isAuto: boolean, autoBatch: string): Promise<SendLoadTender> {
        return new Promise((resolve) => {
            const layout = Layout.getLayout("lme/datafusion/SendLoadTender") as SendLoadTender;
            layout.addLayoutLoadListener(() => {
                const auto: string = isAuto ? "true" : "false";
                layout.search({ carrier: carrierId, move_id: movementId, is_cancel: "false", is_auto: auto }).then(() => {
                    layout.doAfterLoadTenderSent = () => this.doAfterTenderSentStep();
                    if (isAuto) {
                        layout.textboxMustRespondBy.printable = true;
                        layout.textboxMustRespondBy.fontSize = "large";
                        layout.textboxContact.printable = true;
                        layout.textboxContact.fontSize = "large";
                        layout.textboxAddContact.visible = false;
                        layout.buttonSendLater.visible = false;
                        layout.buttonSend.caption = "Next";
                        layout.labelPurpose.caption = "ORIGINAL (Sent in batch " + autoBatch + ")";
                    } else {
                        layout.buttonSend.caption = "Send Now";
                    }

                    layout.buttonCancel.visible = false;
                    const purpose = layout.labelPurpose.caption;

                    layout.buttonSend.addClickListener(async (response) => {
                        if (!isAuto && window.location.pathname == "/lme/powerbroker/BrokeragePlanning") {
                            await CarrierAssignment.sendManualTender(carrierId, movementId, layout).then(response => {
                                if (response.data[0]?.tendersent) {
                                    Toast.showSuccessToast("Successfully sent " + purpose + " tender for movement " + movementId + " in batch " + response.data[0].batchid);
                                } else {
                                    Snackbar.showWarningSnackbar("Unable to send " + purpose + " tender for movement " + movementId + ".  " + response.data[0].text, { targetPanel: this.parent as Panel });
                                }
                            });
                        }
                        this.doAfterTenderSentStep(layout.buttonSend);
                    });
                });

                layout.buttonSendLater.addClickListener(() => {
                    this.doAfterTenderSentStep(layout.buttonSendLater);
                });

                this.getStep(INDEX_RATE_CON).add(layout);
                this.sendLoadTenderLayout = layout;
                resolve(this.sendLoadTenderLayout);
            });
        });
    }

    doAfterTenderSentStep(buttonSendLater?: Button) {
        if (this.manualTrackingRequired) {
            this.setupTrackingLayout().then(() => {
                this.advanceToNextStep();
            })
        } else {
            this.close(false);
        }
    }

}
