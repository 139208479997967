import { StringUtil } from "./StringUtil";

export class LocationUtil {
    /**
     * Formats the given locationName, locationId, city, state and zip in a way that is presentable to the user.  This allows for any of those pieces to be null and
     * will still return a presentable string (i.e. there won't be a trailing comma if there is a null state)
     *
     * @param locationName
     * @param locationId
     * @param city
     * @param state
     * @param zip
     * @returns
     */
    public static formatLocation(locationName: string, locationId: string, address: string, city: string, state: string, zip?: string): string {
        let result = "";
        if (!StringUtil.isEmptyString(locationName))
            result += locationName;
        result = LocationUtil.append(result, address, ",");
        result = LocationUtil.append(result, city, ",");
        result = LocationUtil.append(result, state, ",");
        result = LocationUtil.append(result, zip, "");
        if (!StringUtil.isEmptyString(locationId))
            result = LocationUtil.append(result, "(" + locationId + ")", "");
        return result;
    }

    private static append(prefix: string, appendValue: string, delimiter: string): string {
        let result = prefix;
        if (!StringUtil.isEmptyString(appendValue)) {
            if (result.length > 0)
                result += delimiter + " ";
            result += appendValue;
        }
        return result;
    }
}
