import { DataLoadActionHandler, DataLoadActionType } from "@mcleod/common";
import { Chart, ChartDataPoint, ChartDataset, ClickEvent, Component, Table, TableRow } from "@mcleod/components";
import { DOMUtil, ModelRow, Navigation, StringUtil, UrlUtil } from "@mcleod/core";

interface TabAndTable {
    tabId: string;
    tableId: string;
    spot?: string;
}

export class LogicBrokeragePlanningPerformance {
    static setTableColumnHeaders(table: Table, headerInfoRow: ModelRow) {
        for (let x = 1; x < table.columns.length - 1; x++) {
            const column = table.columns[x];
            const newCaption = headerInfoRow.get("day_" + (x - 1) + "_header");
            column.headingCell.caption = newCaption;
            table.resetColumnSorting();
        }
    }

    static createChartData(table: Table, chart: Chart, sourceData: ModelRow[], headerInfoRow: ModelRow, brokerageStatusesToChart: string[]) {
        chart.removeAllDatasets();
        for (const modelRow of sourceData) {
            if (brokerageStatusesToChart.includes(modelRow.get("brokerage_status")) !== true)
                continue;
            const dataPoints: ChartDataPoint[] = []
            for (let x = 1; x < table.columns.length - 1; x++) {
                const yValue = modelRow.get("day_" + (x - 1));
                dataPoints.push({
                    x: headerInfoRow.get("day_" + (x - 1) + "_header"),
                    y: yValue
                });
            }
            const dataset = new ChartDataset();
            dataset.chartData = dataPoints;
            dataset.label = modelRow.get("brokerage_status");
            chart.addDataset(dataset);
        }
    }

    static showTableOrChart(table: Table, chart: Chart, showChart: boolean) {
        if (showChart === true)
            LogicBrokeragePlanningPerformance.setChartSize(chart, table._element);
        table.visible = !showChart;
        chart.visible = showChart;
    }

    static setChartSize(chart: Chart, sourceElement: HTMLElement) {
        const height = DOMUtil.getElementHeight(sourceElement);
        const width = DOMUtil.getElementWidth(sourceElement);
        if (height != 0 && width != 0) {
            chart.minHeight = height;
            chart.maxHeight = height;
            chart.minWidth = width;
            chart.maxWidth = width;
        }
    }

    /**
     * Determine which cell was clicked, and navigate the user to Brokerage Planning with the following presets
     *     -> select the tab that corresponds to the brokerage status
     *     -> filter the table using the date that corresponds to the label clicked (unless a total label was clicked)
     * @param event
     */
    static cellLabelOnClick(event: ClickEvent, perfHeaderRow: ModelRow, bpProfile: string) {
        const component = event.target as Component;
        const tableRow = TableRow.getContainingTableRow(component);
        const brokerageStatusDescr = tableRow.data.get("brokerage_status");
        const tabAndTable = LogicBrokeragePlanningPerformance.getBPTabAndTable(brokerageStatusDescr);
        let date = null;
        if (component.field.startsWith("day_") === true) {
            const dayNumber = component.field.charAt(4);
            date = perfHeaderRow.get("day_" + dayNumber + "_date");
        }
        else if (component.field === 'total') {
            const today = new Date();
            const year = today.getFullYear();
            const month = (today.getMonth() + 1).toString().padStart(2, '0');
            const day = today.getDate().toString().padStart(2, '0');
            date = year + '-' + month + '-' + day;
        }
        const url = LogicBrokeragePlanningPerformance.buildClickUrl(tabAndTable, date, bpProfile);
        Navigation.navigateTo(url);
    }

    private static getBPTabAndTable(brokerageStatusDescr: string): TabAndTable {
        switch (brokerageStatusDescr.toLowerCase()) {
            case "spot orders": return { tabId: "tabAvailableOrders", tableId: "tableMovementsAvailable", spot: "include" };
            case "available": return { tabId: "tabAvailableOrders", tableId: "tableMovementsAvailable", spot: "exclude" };
            case "covered": return { tabId: "tabCoveredOrders", tableId: "tableMovementsCovered" };
            case "in progress": return { tabId: "tabInProgressOrders", tableId: "tableMovementsInProgress" };
            case "delivered": return { tabId: "tabDeliveredOrders", tableId: "tableMovementsDelivered" };
            default: return { tabId: "tabPeriscope", tableId: "tableMovements" };
        }
    }

    private static buildClickUrl(tabAndTable: TabAndTable, date: string, bpProfile: string): string {
        const dlaHandlers: string[] = [];
        let dlaCount = 0;
        if (!StringUtil.isEmptyString(bpProfile))
            dlaHandlers.push("bpProfile=" + bpProfile);
        if (tabAndTable.spot)
            dlaHandlers.push("spot=" + tabAndTable.spot);
        if (StringUtil.isEmptyString(tabAndTable.tabId) !== true)
            dlaHandlers.push(DataLoadActionHandler.createUrlQueryParamString(DataLoadActionType.SELECT_TAB, tabAndTable.tabId, ++dlaCount));
        if (date != null) {
            const map = new Map<string, string>();
            map.set("filterValue", "origin_early=" + date);
            dlaHandlers.push(DataLoadActionHandler.createUrlQueryParamString(DataLoadActionType.FILTER_TABLE, tabAndTable.tableId, ++dlaCount, map));
        }
        return "lme/powerbroker/BrokeragePlanning" + UrlUtil.buildQueryString(dlaHandlers);
    }
}
