import { DataSource, HorizontalSpacer, Image, Label, Layout, Panel } from "@mcleod/components";

export abstract class AutogenLayoutStatusQuickInfo extends Layout {
    goToClassicImage: Image;
    horizontalspacer10: HorizontalSpacer;
    horizontalspacer11: HorizontalSpacer;
    horizontalspacer12: HorizontalSpacer;
    horizontalspacer2: HorizontalSpacer;
    horizontalspacer3: HorizontalSpacer;
    horizontalspacer4: HorizontalSpacer;
    horizontalspacer5: HorizontalSpacer;
    horizontalspacer6: HorizontalSpacer;
    horizontalspacer7: HorizontalSpacer;
    horizontalspacer8: HorizontalSpacer;
    horizontalspacer9: HorizontalSpacer;
    imageIsResend: Image;
    labelAppointment: Label;
    labelAppointmentDate: Label;
    labelApptTZ: Label;
    labelBatch: Label;
    labelCreated: Label;
    labelDelayCode: Label;
    labelError: Label;
    labelErrorCode: Label;
    labelLabel1: Label;
    labelLabel2: Label;
    labelLabel3: Label;
    labelLabel4: Label;
    labelLabel5: Label;
    labelLastUpdated: Label;
    labelType: Label;
    label_: Label;
    panel1: Panel;
    sourceEdistatus: DataSource;
}
