import { AutogenModelEdiCompareItem, AutogenRowEdiCompareItem } from "./autogen/AutogenModelEdiCompareItem";

export class ModelEdiCompareItem extends AutogenModelEdiCompareItem<RowEdiCompareItem> {
	constructor() {
		super(RowEdiCompareItem);
	}
}


export class RowEdiCompareItem extends AutogenRowEdiCompareItem {
}