import {
    ClickEvent, DataSourceAction, DataSourceExecutionEvent, DataSourceMode, DataSourceModeChangeEvent, Toast
} from "@mcleod/components";
import { Api, Navigation, StringUtil } from "@mcleod/core";
import { QuoteSendEmail } from "@mcleod/powerbroker/src/QuoteSendEmail";
import { CarrierSelection } from "../CarrierSelection";
import { AutogenLayoutQuoteLTL } from "./autogen/AutogenLayoutQuoteLTL";
import { UsersResponsibilitySlideoutTrans } from "@mcleod/general/src/UsersResponsibilitySlideoutTrans";
import { OrderPostHist } from "../OrderPostHist";

enum QuoteStep {
    QUOTE_ENTRY = 0,
    CARRIER_SELECTION = 1
}

export class QuoteLTL extends AutogenLayoutQuoteLTL {
    private reRate: boolean = false;

    override onLoad() {
        this.setupNestedDataSources();
        this.mainDataSource.doBeforeDataSourcePost = async () => {
            this.reRate = await CarrierSelection.shouldRerate(this.layoutQuoteEntry);
            return true;
        }
        this.setupQuoteStatusBadge();
    }

    sourceQuoteOrderAfterModeChange(event: DataSourceModeChangeEvent) {
        if (event.newMode !== DataSourceMode.ADD) {
            this.title = "Quote Number " + this.activeRow.get("id");
            this.hideSuffix = true;
        }
        this.buttonQuoteHistory.visible = event.newMode === DataSourceMode.UPDATE;
        this.primaryButtonSetup();
        this.layoutQuoteEntry.setAllDataSourcesToMode(event.newMode);
    }

    primaryButtonSetup() {
        if (this.dataheaderQuote.showSaveAndClose) {
            this.buttonSave.allowSaveAndClose = true;
            this.buttonSave.onClose = () => Navigation.navigateBack();
        }
        this.buttonSave.caption = this.mainDataSource.mode === DataSourceMode.ADD ? "Generate Quote" : this.dataheaderQuote.showSaveAndClose ? "Save & Close" : "Save";
        this.buttonSave.onSave = (postedRow) => this.dataheaderQuote?.onExecute?.(postedRow);
    }

    /** This is an event handler for the beforeStepListener event of stepperQuote.  */
    stepperQuoteBeforeStepListener(event) {
        switch (event.newIndex) {
            case QuoteStep.QUOTE_ENTRY:
                break;
            case QuoteStep.CARRIER_SELECTION:
                if (this.activeRow.isNull("id")) {
                    event.preventDefault();
                    return;
                }
                this.layoutCarrierSelection.orderRow = this.activeRow;
                break;
        }
        this.buttonEmail.visible = event.newIndex === QuoteStep.CARRIER_SELECTION;
    }

    setupNestedDataSources() {
        this.layoutQuoteEntry.layoutQuoteStopTable.orderSource = this.mainDataSource;
        this.layoutQuoteEntry.layoutFreightItems.replaceMainDatasource(this.layoutQuoteEntry.sourceQuoteFreightGroupItem);
        this.layoutCarrierSelection.replaceMainDatasource(this.sourceQuoteBrltlBillingFreightGroup);
        this.layoutCarrierSelection.doAfterChoosingCarrier = async () => {
            await this.activeRow.post();
            const result = await Api.search("lme/dispatch/convert-quote-to-order", {
                quote_id: this.activeRow.get("id"), delay_posting: false
            });
            Navigation.navigateTo("/lme/dispatch/Orders?mode=update&key=" + result.data[0].id, null, { selectCarrier: true });
        }
    }

    buttonEmailOnClick(event) {
        QuoteSendEmail.show({
            sourceQuote: this.mainDataSource,
            doOnSlideoutClosed: (cancelled: boolean) => {
                if (!cancelled) {
                    Toast.showToast("Quote sent");
                }
            },
            isLtl: true
        });
    }

    sourceQuoteOrderAfterExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() === DataSourceAction.ADD) {
            Navigation.pseudoNavigateTo("/lme/dispatch/brltl/QuoteLTL?mode=update&key=" + this.activeRow.get("id"), null);
            const stopSource = this.layoutQuoteEntry.sourceQuoteStop;
            stopSource.addAfterExecutionListener(() => {
                this.stepperQuote.selectedIndex = QuoteStep.CARRIER_SELECTION;
            });
        }
        if (this.reRate) {
            CarrierSelection.findCarrierRates(this.activeRow.get("id")).finally(() => {
                this.layoutCarrierSelection.mainDataSource.search({ order_id: this.activeRow.get("id")})
                this.reRate = false;
            });
        }
    }

    // START - USERS Slideout
    /** This is an event handler for the onClick event of buttonUsers.  */
    buttonUsersOnClick(event: ClickEvent) {
        this.displayQuoteUsersSlideout();
    }

    displayQuoteUsersSlideout() {
        const urs = new UsersResponsibilitySlideoutTrans("lme/powerbroker/QuoteUsers", this.getQuoteUsersSlideoutTitle(), this.getQuoteUsersIdFilter(),
            this.getQuoteUsersSharedFieldNames(), this.sourceQuoteOrder, this.sourceResponsibleHist);
        urs.show();
    }

    private getQuoteUsersSlideoutTitle(): string {
        const name = this.sourceQuoteOrder.activeRow?.get("id");
        return "Quote Users" + ((StringUtil.isEmptyString(name) === false) ? (" - " + name) : "");
    }

    private getQuoteUsersIdFilter(): any {
        const id = this.sourceQuoteOrder.activeRow?.get("id");
        if (id != null)
            return { id: id };
        return null;
    }

    private getQuoteUsersSharedFieldNames(): string[] {
        return ["operations_user", "agent_payee_id", "entered_user_id", "billing_user_id"];
    }
    // END - USERS Slideout

    /** This is an event handler for the onClick event of buttonQuoteHistory.  */
    buttonQuoteHistoryOnClick(event: ClickEvent) {
        OrderPostHist.showInSlideout(
            "QUOTE",
            this.mainDataSource.activeRow.get("id"),
            null,
            DataSourceMode.UPDATE, this.buttonQuoteHistory);
    }

    setupQuoteStatusBadge() {
        this.mainDataSource.preventFocusFirstField = true;
        this.textboxQuoteStatus._input.style.height = "100%";
        this.textboxQuoteStatus._input.style.pointerEvents = "none";
        this.textboxQuoteStatus.addChangeListener(() => {
            if (this.stepperQuote.selectedIndex === QuoteStep.CARRIER_SELECTION) this.layoutCarrierSelection.getRates();
        });
        this.panelQuoteStatus.addClickListener(() => this.textboxQuoteStatus.showItemDropdown());
    }
}
