import { Button, DataSource, HorizontalSpacer, Label, Layout, Panel, Textbox } from "@mcleod/components";
import { LoadTenderExpressEdiStopGrid } from "../LoadTenderExpressEdiStopGrid";

export abstract class AutogenLayoutLoadTenderExpressExpanded extends Layout {
    buttonLinkTender: Button;
    buttonPurposeSwap: Button;
    horizontalspacer1: HorizontalSpacer;
    labelBatch: Label;
    labelBillOfLading: Label;
    labelBillingDistance: Label;
    labelBillingDistanceCaption: Label;
    labelBillingDistanceUM: Label;
    labelCreateDate: Label;
    labelCreated: Label;
    labelDetails: Label;
    labelFAStatus: Label;
    labelGs06GroupCntlno: Label;
    labelLabel1: Label;
    labelLabel3: Label;
    labelLabel4: Label;
    labelLabel5: Label;
    labelMoveStatus: Label;
    labelOrderType: Label;
    labelTenderID: Label;
    labelTenderId: Label;
    label_: Label;
    layoutLoadTenderExpressEdiStopGrid: LoadTenderExpressEdiStopGrid;
    panelBrkStatus: Panel;
    panelHeader: Panel;
    panelPurposeSwap: Panel;
    sourceEdiOrder: DataSource;
    sourceEdiStop: DataSource;
    textboxBrkStatus: Textbox;
}
