import { CommonDialogs } from "@mcleod/common";
import { Component, Panel, TableRow, TableRowDisplayEvent, TableRowMode, Textbox } from "@mcleod/components";
import { Api, ModelRow } from "@mcleod/core";
import { makeTooltipCallbackFunction } from "./LocationMakeQuickInfo";
import { AutogenLayoutRearrangeStops } from "./autogen/AutogenLayoutRearrangeStops";

export class RearrangeStops extends AutogenLayoutRearrangeStops {
    private _doAfterClose: (canceled: boolean) => void;
    public onSave = (): Promise<boolean> => this.rearrangeStops();
    private _orderId: string;

    private async rearrangeStops(): Promise<boolean> {
        if (this.checkStopStatuses()) {
            try {
                const response = await Api.post("lme/dispatch/rearrange-stops",
                    {
                        "order_id": this.orderId,
                        "order_type": "O",
                        "stops": this.tableStop.dataSource.data
                    });
                if (response) {
                    this.close(false);
                    return true;
                }
            } catch (e) {
                CommonDialogs.showError(e);
            }

        }
        else {
            CommonDialogs.showError("A cleared stop cannot be after an uncleared stop.");
        }
        return false;
    }

    private checkStopStatuses(): boolean {
        let foundAvailableStop = false;
        let result = true;
        const rows = this.tableStop.rows;
        for (let i = 0; i < rows.length; ++i) {
            const stopStatus = rows[i].data.get("status");
            if (stopStatus == "D") {
                if (foundAvailableStop) {
                    result = false;
                    break;
                }
            }
            else
                foundAvailableStop = true;
        }
        return result;
    }


    /** This is an event handler for the onRowDisplay event of tableStop.  */
    tableStopOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.target as TableRow;
        const modelRow: ModelRow = tableRow.data as ModelRow;
        const locationName: Component = tableRow.findComponentById("textboxLocationName");
        const between: Textbox = tableRow.findComponentById("textboxStopDateType") as Textbox;
        if (locationName != null)
            locationName.tooltipCallback = makeTooltipCallbackFunction(modelRow.get("location_id"), locationName);
        if (modelRow.get("actual_departure") != null)
            between.visible = false;
        else if (modelRow.get("sched_arrive_late") == null)
            between.text = "On";
        else
            between.text = "Between";
        if ("A" === modelRow.get("status") && modelRow.get("actual_arrival") != null)
            tableRow.findComponentById("labelArrivedBadge").visible = true;
        if (this.tableStop.dataSource.data.length == this.tableStop.rowCount)
            this.syncWidths();
        if (modelRow.get("stop_type") != "PU" && modelRow.get("stop_type") != "SO") {
            tableRow.hideDragHandle();
        }
        else if (modelRow.get("actual_arrival") == null) {
            const actuals: Panel = tableRow.findComponentById("panelArriveDepart") as Panel;
            if (actuals != null)
                actuals.visible = false;
            tableRow.mode = TableRowMode.UPDATE;
            tableRow.draggable = true;
        }
        else {
            const early: Textbox = tableRow.findComponentById("textboxSchedArriveEarly") as Textbox;
            const late: Textbox = tableRow.findComponentById("textboxSchedArriveLate") as Textbox;
            if (early != null)
                early.visible = false;
            if (late != null)
                late.visible = false;
            tableRow.mode = TableRowMode.NONE;
            tableRow.canBeEdited = false;
            tableRow.hideDragHandle();
        }
        tableRow.canBeDeleted = false;
    }

    private syncWidths() {
        const syncComps = new Map([["panelLocation", null], ["panelDeliveryTimes", null], ["panelArriveDepart", null]])

        // get the widest component from the list of components from all table rows
        this.tableStop.rows.forEach(row => {
            syncComps.forEach((value, key) => {
                const comp = row.findComponentById(key);
                if (comp != null && (value == null || comp._element.clientWidth > value))
                    syncComps.set(key, comp._element.clientWidth);
            })
        })

        // set the width of the componenets on each row
        this.tableStop.rows.forEach(row => {
            syncComps.forEach((value, key) => {
                const comp = row.findComponentById(key);
                if (comp != null && value != null)
                    comp.width = value;
            })
        })
    }

    textboxStopDateTypeOnChange(event) {
        const tableRow: TableRow = TableRow.getContainingTableRow(event.target as Component);
        const type: string = tableRow.data.get("stop_type");
        const early: Component = tableRow.findComponentById("textboxSchedArriveEarly");
        const late: Component = tableRow.findComponentById("textboxSchedArriveLate");
        const between = event.newValue == "Between";
        late.visible = between;
        if (!between)
            tableRow.data.set("sched_arrive_late", null);
        this.setStopDateCaptions(early as Textbox, type == "SO", between);
    }

    setStopDateCaptions(early: Textbox, isDelivery: boolean, isDateWindow: boolean) {
        const caption = isDelivery ? "Delivery" : "Pickup";
        early.caption = isDateWindow ? `${caption} Window` : `${caption} Appointment`;
    }

    close(canceled: boolean) {
        this.slideOut().then(() => {
            if (this.doAfterClose != null)
                this.doAfterClose(canceled);
        });
    }

    public set doAfterClose(f) {
        this._doAfterClose = f;
    }

    public get doAfterClose() {
        return this._doAfterClose;
    }

    public get orderId() {
        return this._orderId;
    }

    public set orderId(id) {
        this._orderId = id;
    }
}
