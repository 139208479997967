import { Button, DataSource, Image, Layout, Panel, Tab, Tabset, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutOnboardCarrier extends Layout {
    buttonIdentifyCarrier: Button;
    imageDetail: Image;
    panelIdentifyCarrier: Panel;
    panelManualOnboard: Panel;
    panelOnboardGeneral: Panel;
    panelOnboardRmis: Panel;
    rmisCarrierName: Textbox;
    rmisContactEmail: Textbox;
    rmisContactName: Textbox;
    rmisUserEmail: Textbox;
    rmisUserName: Textbox;
    sourceMovement: DataSource;
    sourceOnboardCarrier: DataSource;
    sourceSettings: DataSource;
    tabAuto: Tab;
    tabManual: Tab;
    tabsetOnboard: Tabset;
    textboxBcc: Textbox;
    textboxCarrierEmail: Textbox;
    textboxCarrierNumber: Textbox;
    textboxCc: Textbox;
    textboxComments: Textbox;
    textboxManualCarrierEmail: Textbox;
    textboxManualCarrierName: Textbox;
    textboxMyCarrierPacketsUser: Textbox;
    textboxNumberType: Textbox;
    textboxPendingCarrierName: Textbox;
    textboxSubject: Textbox;
}
