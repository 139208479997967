import { Label } from "@mcleod/components/src/components/label/Label";
import { DataSourceMode } from "@mcleod/components/src/databinding/DataSource";
import { TableRowDisplayEvent } from "@mcleod/components/src/events/TableRowDisplayEvent";
import { ModelRow } from "@mcleod/core/src/ModelRow";
import { StringUtil } from "@mcleod/core/src/StringUtil";
import { AutogenLayoutReferenceNumberCompareItem } from "./autogen/AutogenLayoutReferenceNumberCompareItem";

export class ReferenceNumberCompareItem extends AutogenLayoutReferenceNumberCompareItem {
    private _compareItems: ModelRow[];
    private _key: string;

    override onLoad(): void | Promise<void> {

        this.tableCompareItems.dataSource;
        this.tableCompareItems.dataSource = this.sourceEdiCompareItem;
        this.tableCompareItems.dataSource.data = [];
        const rows = [];
        if (this._compareItems != null)
            this.tableCompareItems.dataSource.setRowsAndMode(DataSourceMode.NONE, this._compareItems);

        if (this._compareItems != null && this._compareItems.length == 0) {
            this.tableCompareItems.visible = false;
        }

    }

    public async setupTableData(data: ModelRow[]) {
        this._compareItems = data;
    }
    onRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        const labelDescr = tableRow.findComponentById("labelDescr") as Label;
        if (StringUtil.isEmptyString(labelDescr.caption)) {
            if (!StringUtil.isEmptyString(tableRow.data.get("source_value"))) {
                labelDescr.caption = tableRow.data.get("source_descr");
            } else {
                labelDescr.caption = tableRow.data.get("target_descr");
            }
        }
    }
}
