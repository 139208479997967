import { getLogger } from "./Logger";

const log = getLogger("core.Clipboard");

export class Clipboard {
    /**
     * Copies the specified text to the user's clipboard so it is available to be pasted later.
     *
     * @param text The value to be copied to the clipboard.
     * @returns boolean true if copying was success
     */
    public static copyText(text: string): void {
        log.debug("Copying text [%s] to clipboard", text);
        if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            const textarea = document.createElement("textarea");
            textarea.textContent = text;
            textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
            document.body.appendChild(textarea);
            textarea.select();
            try {
                document.execCommand("copy");  // Security exception may be thrown by some browsers.
            }
            finally {
                document.body.removeChild(textarea);
            }
        }
    }
}
