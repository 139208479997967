import { ClickEvent, DataDisplayEvent } from "@mcleod/components";
import { AutogenLayoutTenderRates } from "./autogen/AutogenLayoutTenderRates";

export class TenderRates extends AutogenLayoutTenderRates {
    private chargePanelExpanded: boolean = false;
    private transitionProps = { speed: 100, paddingTop: 0 };

    onLoad() {
        this.setPanelOtherChargeTableCollapsed();
    }

    sourceOtherChargeEdiOnDisplay(event: DataDisplayEvent) {
        const otherChargeData = this.mainDataSource.data;
        this.labelNumberOfCharges.caption = String(otherChargeData.length);
    }

    buttonExpandChargesOnClick(event: ClickEvent) {
        if (this.chargePanelExpanded) this.panelOtherChargeTable.collapse(this.transitionProps).then(() => {
            this.setPanelOtherChargeTableCollapsed();
        });
        else this.panelOtherChargeTable.expand(this.transitionProps).then(() => {
            this.setPanelOtherChargeTableExpanded();
        });
        this.chargePanelExpanded = !this.chargePanelExpanded;
    }

    setPanelOtherChargeTableCollapsed() {
        this.buttonExpandCharges.imageRotation = 90;
        this.panelOtherChargeTable.borderWidth = 0;
        this.panelOtherChargesHeader.borderBottomWidth = 1;
        this.panelOtherChargesHeader.borderBottomColor = "strokeSecondary";
        this.panelOtherChargesHeader.borderBottomLeftRadius = 4;
        this.panelOtherChargesHeader.borderBottomRightRadius = 4;
    }

    setPanelOtherChargeTableExpanded() {
        this.buttonExpandCharges.imageRotation = 0;
        this.panelOtherChargeTable.borderWidth = 1;
        this.panelOtherChargeTable.borderColor = "strokeSecondary";
        this.panelOtherChargeTable.borderTopWidth = 0;
        this.panelOtherChargesHeader.borderBottomWidth = 0;
        this.panelOtherChargesHeader.borderBottomLeftRadius = 0;
        this.panelOtherChargesHeader.borderBottomRightRadius = 0;
    }
}
