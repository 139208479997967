import { DataDisplayEvent, DataSourceExecutionEvent, Label, Layout } from "@mcleod/components";
import { Alignment, ModelRow } from "@mcleod/core";
import { ThemeCommonPage } from "@mcleod/core/src/themes/common/ThemeCommonPage";
import { TransmissionStatusQuickInfo } from "./TransmissionStatusQuickInfo";
import { AutogenLayoutEdiOrderFA } from "./autogen/AutogenLayoutEdiOrderFA";

export class EdiOrderFA extends AutogenLayoutEdiOrderFA {
    override onLoad(): void {

    }

    textboxAk501TrxAckCdOnDisplay(event) {
        if (this.sourceEdiFunctionalAck.activeRow != null) {
            const faStatus = this.sourceEdiFunctionalAck.activeRow.get("ak501_trx_ack_cd");
            if (faStatus == "A") {
                this.textboxAk501TrxAckCd.text = "Accepted";
            } else if (faStatus == "R") {
                this.textboxAk501TrxAckCd.text = "Rejected";
            } else {
                this.textboxAk501TrxAckCd.text = "Unknown";
            }
        }
    }

    textboxGSStatusCodeOnDisplay(event) {
        if (this.sourceEdiFunctionalAck.activeRow != null) {
            const gsStatus = this.sourceEdiFunctionalAck.activeRow.get("ak901_func_ack_cd");
            if (gsStatus == "A") {
                this.textboxGSStatusCode.text = "Accepted";
            } else if (gsStatus == "R") {
                this.textboxGSStatusCode.text = "Rejected";
            } else {
                this.textboxGSStatusCode.text = "Unknown";
            }
        }
    }

    panelFAOnDataDisplay(event) {
        if (this.sourceViewEdiOrder.activeRow != null) {
            this.layoutFASeg.faId = this.sourceViewEdiOrder.activeRow?.get("func_ack_id");
        }
    }

    sourceEdiFunctionalAckBeforeExecution(event: DataSourceExecutionEvent) {
        event.filter.id = this.mainDataSource.activeRow.get("func_ack_id");
    }

    /** This is an event handler for the afterExecution event of sourceViewEdiOrder.  */
    sourceViewEdiOrderAfterExecution(event: DataSourceExecutionEvent) {
        const FAId = this.mainDataSource.activeRow.get("func_ack_id");
        if (FAId != null) {
            this.sourceEdiFunctionalAck.search({ "id": this.mainDataSource.activeRow.get("func_ack_id") }).then(result => {
                if (result.modelRows.length > 0) {
                    this.layoutFASeg.sourceEdiFunctionalSeg.search({ func_ack_id: this.sourceEdiFunctionalAck.activeRow.get("id") });
                    this.sourceEdiLog.search({
                        "func_receiver_id": this.sourceEdiFunctionalAck.activeRow.get("partner_id"),
                        "receiver_id": this.sourceEdiFunctionalAck.activeRow.get("alt_partner_id"),
                        "func_version": this.sourceEdiFunctionalAck.activeRow.get("version"),
                        "transaction_type": "F",
                        "batch": this.sourceEdiFunctionalAck.activeRow.get("batch_id"),
                        "direction": this.sourceEdiFunctionalAck.activeRow.get("direction")
                    });
                }
            });
        }

        this._showFieldsByDirection(this.mainDataSource.activeRow);

    }

    private _showFieldsByDirection(row: ModelRow<any>) {
        const tenderDirection = row?.get("direction");
        if ("I" == tenderDirection) {//outbound FA
            this.textboxCreateDate.caption = "Sent Date";
            this.textboxCreateDate.tooltip = "Date the Functional Acknowledgement was sent to the trading partner.";
            this.checkboxTransmitted.visible = true;
            this.checkboxTransmitted.caption = "Transmitted?";
            this.labelStatus.visible = true;
        } else { //inbound FA
            this.textboxCreateDate.caption = "Received Date";
            this.textboxCreateDate.tooltip = "Date the Functional Acknowledgement was received from the trading partner.";
            this.checkboxTransmitted.visible = false;
            this.labelStatus.visible = false;
        }
    }
    /** This is an event handler for the onDataDisplay event of textboxFuncAckRec.  */
    faRecOnDisplay(event: DataDisplayEvent) {
        // if(this.sourceViewEdiOrder.activeRow != null) {
        //   const faSent = this.sourceViewEdiOrder.activeRow.get("func_ack_rec");
        //   if(faSent == "Y") {
        //       this.textboxFuncAckRec.text = "Yes";
        //   } else {
        //       this.textboxFuncAckRec.text = "No";
        //   }
        // }

    }

    /** This is an event handler for the onDataDisplay event of labelStatus.  */
    labelStatusOnDataDisplay(event: DataDisplayEvent) {
        if (this.sourceEdiLog.activeRow != null) {
            const statusLabel = event.target as Label;
            statusLabel.tooltipCallback = (base, originatingEvent) => {
                if (statusLabel.caption !== "Not Sent") {
                    const layout = Layout.getLayout("lme/datafusion/TransmissionStatusQuickInfo") as TransmissionStatusQuickInfo;
                    layout.addLayoutLoadListener(() => {
                        if (layout.sourceEdiLog !== null) {
                            layout.sourceEdiLog.search({ id: this.sourceEdiLog.activeRow.get("id") });
                        }
                    });
                    return statusLabel.showTooltip(layout,
                        {
                            position: statusLabel.tooltipPosition == null ? Alignment.BOTTOM : statusLabel.tooltipPosition,
                            pointerColor: ThemeCommonPage.quickInfo.borderLeftColor, originatingEvent: originatingEvent
                        },
                        { themeKey: "quickinfo", backgroundColor: "background5", borderRightColor: ThemeCommonPage.quickInfo.borderLeftColor, borderRightWidth: 12, color: null });
                }
            }
            const status = this.sourceEdiLog.activeRow.get("status");
            if ("4" == status) {
                const successful = this.sourceEdiLog.activeRow.get("was_successful");
                this.labelStatus.caption = ("Y" == successful) ? "(Successful)" : "(Failed)";
                this.checkboxTransmitted.checked = "Y" == successful;
            }

        }
    }
}
