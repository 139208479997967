import { DecimalFormat } from "./DecimalFormat";

export class NumberUtil {
    public static removeFormatting(value: string): string {
        return value.replace(/,/g, '');
    }

    public static formatInteger(value: number, format?: string): string {
        if (value != null && !isNaN(value)) {
            if (format == null)
                return new Intl.NumberFormat("en-US", { useGrouping: false }).format(value);
            else
                return new DecimalFormat(format).format(value);
        }
        return null;
    }

    public static formatDecimal(value: number, format?: string): string {
        if (value != null && !isNaN(value)) {
            if (format == null)
                return new Intl.NumberFormat("en-US", { style: "decimal", minimumFractionDigits: 1 }).format(value);
            else
                return new DecimalFormat(format).format(value);
        }
        return null;
    }
}
