import { Component, DataSource, DesignableObject, Panel, PropType } from "@mcleod/components";
import { StringUtil, getThemeColor, makeStyles, setClassIncluded } from "@mcleod/core";
import { PropertiesTable } from "..";
import { doDesignerAction } from "./UIDesignerActionHistory";
import { ActionChangeComponentProperty } from "./actions/ActionChangeComponentProperty";

export const designerClasses = makeStyles("dsg", {
    designerSelected: { border: "2px dashed #444 !important" },
    designerContainer: { border: "1px dotted #999" },
    designerComponentIdError: { boxShadow: "inset 0px 0px 2px 2px " + getThemeColor("error") },
    designerComponentIdWarning: { boxShadow: "inset 0px 0px 2px 2px " + getThemeColor("warning") },
});

export function designerHandleComponentSelection(selectedComponents: Component[], component: Component, add: boolean, tableProps: PropertiesTable) {
    if (add && selectedComponents.indexOf(component) >= 0) {
        selectedComponents.splice(selectedComponents.indexOf(component), 1);
        setClassIncluded(component, designerClasses.designerSelected, false);
        if (tableProps != null)
            tableProps.displayProperties(selectedComponents);
        return selectedComponents;
    }

    for (let i = 0; !add && i < selectedComponents.length; i++)
        setClassIncluded(selectedComponents[i], designerClasses.designerSelected, false);
    if (component == null)
        selectedComponents.length = 0;
    else {
        if (add)
            selectedComponents.push(component);
        else {
            selectedComponents.length = 0;
            selectedComponents.push(component);
        }
        if (_markAsSelected(component) === true)
            setClassIncluded(component, designerClasses.designerSelected, true);
    }
    if (tableProps != null)
        tableProps.displayProperties(selectedComponents);
    return selectedComponents;
}

function _markAsSelected(component: DesignableObject): boolean {
    return !(component instanceof Panel && component.id === "designerTabHeading");
}

export function designerApplyChangeToSelectedComponents(selectedComponents, activeTab, data, newValue, tableProps: PropertiesTable) {
    if (data.prop.type === PropType.number) {
        newValue = (newValue == null || newValue.length === 0) ? null : parseInt(newValue);
    } else if (data.prop.type === PropType.string) {
        newValue = StringUtil.isEmptyString(newValue) ? null : newValue;
    }

    for (let i = 0; selectedComponents != null && i < selectedComponents.length; i++) {
        const designableObject = selectedComponents[i].designerDataSource ?? selectedComponents[i];
        activeTab.designer.doBeforePropChanged(designableObject, data.prop_name);

        if (designableObject instanceof DataSource) {
            if (data.prop_name === "parentDataSource")
                designableObject.parentDataSource = activeTab.dataSources[newValue];
            else {
                const currValue = designableObject[data.prop_name];
                if (newValue !== currValue)
                    designableObject[data.prop_name] = newValue;
            }
        } else {
            if (data.prop_name === "dataSource" || data.prop_name === "mainDataSource") {
                // need to come up with a better way to reference other components on the screen
                designableObject[data.prop_name] = activeTab.dataSources[newValue];
            } else {
                designableObject[data.prop_name] = newValue;
            }

        }
    }
    tableProps.syncRowCaptionStyle(data.prop_name);

    data.prop.affectsProps?.forEach(affect => {
        tableProps.redisplayProp(affect, selectedComponents[0][affect])
        tableProps.syncRowCaptionStyle(affect);
    });
}

export async function designerCheckForPropChange(designer, data, editor) {
    if (data != null) {
        let oldValue = data.value;
        for (const selectedComponent of designer.selectedComponents) {
            if (data.prop && data.prop.type === PropType.bool) {
                oldValue ??= false;
                if (oldValue !== editor.checked)
                    doDesignerAction(designer, new ActionChangeComponentProperty(selectedComponent, data.prop_name, editor.checked, oldValue));
            } else {
                let newValue = StringUtil.isEmptyString(editor.text) ? null : editor.text;
                oldValue = StringUtil.isEmptyString(oldValue) ? null : oldValue;
                if (oldValue != newValue) {
                    if (data.prop_name === "dataSource" && typeof oldValue === "string")
                        oldValue = designer.getActiveTab().dataSources[oldValue];
                    if (data.prop_name === "dataSource")
                        newValue = designer.getActiveTab().dataSources[newValue];
                    doDesignerAction(designer, new ActionChangeComponentProperty(selectedComponent, data.prop_name, newValue, oldValue));
                }
            }
        }
    }
}
