import { Checkbox, CityState, DataSource, Label, Layout, Panel, Switch, Table, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutRecurringSchedule extends Layout {
    checkboxFriday: Checkbox;
    checkboxMonday: Checkbox;
    checkboxSaturday: Checkbox;
    checkboxSunday: Checkbox;
    checkboxThursday: Checkbox;
    checkboxTuesday: Checkbox;
    checkboxWednesday: Checkbox;
    citystateCustomer: CityState;
    label1: Label;
    label2: Label;
    labelCustomer: Label;
    labelIntheMonth: Label;
    labelName: Label;
    labelOccurrence: Label;
    labelStopsScheduler: Label;
    labelTimeframe: Label;
    panelCommodity: Panel;
    panelConsignee: Panel;
    panelCustomer: Panel;
    panelDayMonth: Panel;
    panelDaysOfWeek: Panel;
    panelDistance: Panel;
    panelDistanceCommodity: Panel;
    panelOccurrence: Panel;
    panelOccurrenceDates: Panel;
    panelOrderOverview: Panel;
    panelShipper: Panel;
    panelShipperConsignee: Panel;
    panelStopsScheduler: Panel;
    panelSummary: Panel;
    panelTimeframe: Panel;
    panelTimeframeComponents: Panel;
    panelTotalCharge: Panel;
    panelTotalCharges: Panel;
    panelTrailerType: Panel;
    panelWeekMonthDayContainer: Panel;
    panelWeekMonthDayDateRow: Panel;
    panelWeekdayMonth: Panel;
    panelWeight: Panel;
    panelWeightTrailerType: Panel;
    sourceCustomer: DataSource;
    sourceRecurSchedStop: DataSource;
    sourceRecurringSched: DataSource;
    switchDaysofWeek: Switch;
    tableStops: Table;
    textboxBillDistance: Textbox;
    textboxCommodity: Textbox;
    textboxConsignee: Textbox;
    textboxEffectiveFrom: Textbox;
    textboxEffectiveTo: Textbox;
    textboxEquipmentTypeId: Textbox;
    textboxFrequency: Textbox;
    textboxLastGenOrderDt: Textbox;
    textboxMonthDay: Textbox;
    textboxMonthFrequency: Textbox;
    textboxMonthlyDate: Textbox;
    textboxOccurrence: Textbox;
    textboxOrdersCount: Textbox;
    textboxRecurringBy: Textbox;
    textboxShipper: Textbox;
    textboxTotalCharge: Textbox;
    textboxWeight: Textbox;
}
