import { Chart, DataSourceAction, DataSourceExecutionEvent, SelectionEvent, Snackbar, Tab, Table } from "@mcleod/components";
import { ModelRow, StringUtil, getAuthSettings } from "@mcleod/core";
import { BrokeragePlanningPerformanceOrders } from "../BrokeragePlanningPerformanceOrders";
import { BrokeragePlanningPerformanceProfit } from "../BrokeragePlanningPerformanceProfit";
import { BrokeragePlanningPerformanceRevenue } from "../BrokeragePlanningPerformanceRevenue";
import { LogicBrokeragePlanningPerformance } from "../LogicBrokeragePlanningPerformance";
import { AutogenLayoutBrokeragePlanningPerformance } from "./autogen/AutogenLayoutBrokeragePlanningPerformance";

export class BrokeragePlanningPerformance extends AutogenLayoutBrokeragePlanningPerformance {
    private _currentProfile: string;

    get currentProfile(): string {
        return this._currentProfile;
    }

    override async onLoad() {
        this._currentProfile = getAuthSettings()?.user_settings?.bp_profile;
        if (this.textboxProfileId) {
            this.textboxProfileId.text = this.currentProfile;


            this.textboxProfileId.onSelectItem = ((_textbox, _selection) => {
                const selectedId = this.textboxProfileId.getFirstLookupModelData()?.get("id");
                if (selectedId != this.currentProfile && selectedId !== null) {
                    this.mainDataSource.search({ bp_profile_id: selectedId });
                    this.textboxProfileId.text = selectedId;
                    this._currentProfile = selectedId;
                }
                return undefined;
            });
        }
    }
    /** This is an event handler for the afterExecution event of sourceBrokeragePlanningPerf.  */
    sourceBrokeragePlanningPerfAfterExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() === DataSourceAction.SEARCH) {
            const message = this.sourceBrokeragePlanningPerf.data[0]?.get("message");
            if (StringUtil.isEmptyString(message) === true) {
                const ordersLayout = this.layoutBPPerfOrders as BrokeragePlanningPerformanceOrders;
                ordersLayout?.addLayoutLoadListener(event => {
                    ordersLayout.perfHeaderRow = this.sourceBrokeragePlanningPerf.data[0];
                    const convertedRows = this.convertObjectToModelRow("lme/dispatch/brokerage-planning-perf-orders", this.sourceBrokeragePlanningPerf.data[0]?.get("orders_data"));
                    ordersLayout.mainDataSource.data = convertedRows;
                    ordersLayout.mainDataSource.displayDataInBoundComponents();
                    this.styleTableRows(ordersLayout.tableBPPerfOrders);
                });

                const profitLayout = this.layoutBPPerfProfit as BrokeragePlanningPerformanceProfit;
                profitLayout?.addLayoutLoadListener(event => {
                    profitLayout.perfHeaderRow = this.sourceBrokeragePlanningPerf.data[0];
                    const convertedRows = this.convertObjectToModelRow("lme/dispatch/brokerage-planning-perf-profit", this.sourceBrokeragePlanningPerf.data[0]?.get("profit_data"));
                    profitLayout.mainDataSource.data = convertedRows;
                    profitLayout.mainDataSource.displayDataInBoundComponents();
                    this.styleTableRows(profitLayout.tableBPPerfProfit)
                });

                const revenueLayout = this.layoutBPPerfRevenue as BrokeragePlanningPerformanceRevenue;
                revenueLayout?.addLayoutLoadListener(event => {
                    revenueLayout.perfHeaderRow = this.sourceBrokeragePlanningPerf.data[0];
                    const convertedRows = this.convertObjectToModelRow("lme/dispatch/brokerage-planning-perf-revenue", this.sourceBrokeragePlanningPerf.data[0]?.get("revenue_data"));
                    revenueLayout.mainDataSource.data = convertedRows;
                    revenueLayout.mainDataSource.displayDataInBoundComponents();
                    this.styleTableRows(revenueLayout.tableBPPerfRevenue);
                });
            }
            else {
                this.textboxProfileId.visible = false;
                this.tabsetBPPerf.visible = false;
                this.labelMessage.visible = false;
                Snackbar.showWarningSnackbar(message);
            }
        }

        // this.parent.minHeight = DOMUtil.getElementHeight(this.parent._element);
        // this.parent.minWidth = DOMUtil.getElementWidth(this.parent._element);
    }

    private styleTableRows(table: Table) {
        table.rows[table.rows.length - 1].color = 'primary';
        table.rows[table.rows.length - 1].fontBold = true;
        table.rows[table.rows.length - 1].fontSize = 'xlarge';
        table.rows[table.rows.length - 1].borderBottomWidth = 0;
        table.tableHeadingRow.padding = 0;
        table.tableHeadingRow._element.parentElement.style.borderTopWidth = '0';
        table.tableHeadingRow._element.parentElement.style.borderBottomColor = '#cdcdcd';
    }

    private convertObjectToModelRow(modelUrl: string, data: []): ModelRow[] {
        if (data == null)
            return;
        const modifiedData: ModelRow[] = [];
        for (const d of data) {
            const modelRow = new ModelRow(modelUrl, false, d);
            modifiedData.push(modelRow);
        }
        return modifiedData;
    }

    /** This is an event handler for the onChange event of switchView.  */
    switchViewOnChange() {
        const activeTab = this.tabsetBPPerf.getActiveTab();
        let layout: any;
        let table: Table;
        let chart: Chart;
        switch (activeTab) {
            case this.tabOrders:
                layout = activeTab.components[0] as BrokeragePlanningPerformanceOrders
                table = layout.tableBPPerfOrders
                chart = layout.chartOrders
                break;
            case this.tabProfit:
                layout = activeTab.components[0] as BrokeragePlanningPerformanceProfit
                table = layout.tableBPPerfProfit
                chart = layout.chartProfit
                break;
            case this.tabRevenue:
                layout = activeTab.components[0] as BrokeragePlanningPerformanceRevenue
                table = layout.tableBPPerfRevenue
                chart = layout.chartRevenue
                break;
            default:
        }
        LogicBrokeragePlanningPerformance.showTableOrChart(table, chart, this.switchView.checked === true);
    }

    /** This is an event handler for the afterTabSelection event of tabsetBPPerf.  */
    tabsetBPPerfAfterTabSelection(event: SelectionEvent) {
        if (event.oldSelection != null && event.newSelection instanceof Tab) {
            this.switchViewOnChange();
        }
    }
}
