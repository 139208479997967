import { ModelRow } from "@mcleod/core";
import { ClickEvent } from "../../events/ClickEvent";
import { Button } from "../button/Button";
import { Label } from "../label/Label";
import { Panel } from "../panel/Panel";
import { PanelProps } from "../panel/PanelProps";

export class LookupModelSelectedItemLabel extends Panel implements PanelProps {
    modelRow: ModelRow;
    private _deleteCallback: (lookupModelDataRow: ModelRow) => void;

    constructor(displayValue: string, modelRow: ModelRow, deleteCallback: (lookupModelDataRow: ModelRow) => void) {
        super({
            height: "100%",
            margin: 2,
            padding: 2,
            wrap: false,
            rowBreakDefault: false,
            rowBreak: false,
            backgroundColor: "subtle.lighter",
            borderColor: "primary",
            borderRadius: 4,
            borderWidth: 0
        });
        this.modelRow = modelRow;
        this._createLabel(displayValue);
        this._createButton();
        this._deleteCallback = deleteCallback;
    }

    private _createLabel(displayValue: string) {
        this.add(new Label({
            caption: displayValue,
            color: "default",
            backgroundColor: "subtle.lighter",
            wrap: false,
            margin: 0,
            marginLeft: 2,
            marginRight: 2,
            padding: 0
        }));
    }

    private _createButton() {
        this.add(new Button({
            imageProps: {
                name: "x"
            },
            color: "default",
            backgroundColor: "subtle.lighter",
            borderWidth: 0,
            margin: 0,
            padding: 0,
            onClick: (event: ClickEvent) => this._deleteCallback(this.modelRow)
        }));
    }
}