import { DataSource, Label, Tab } from "@mcleod/components";
import { Api, Collection, HorizontalAlignment, StringUtil } from "@mcleod/core";
import { ModelDesigner } from "./ModelDesigner";
import { ModelDesignerPanel } from "./ModelDesignerPanel";

/**
 * This represents a single tab on the Model Designer.  A tab holds the information needs to display and edit a single .model file.
 */

export class ModelDesignerTab extends Tab {
    private _modified: boolean;
    dataSources: Collection<DataSource>;
    _path: string;
    serviceProject: string;
    designerPanel: ModelDesignerPanel;

    constructor(designer: ModelDesigner, fullPath: string) {
        super({ fillHeight: true, fillRow: true, minHeight: 80, padding: 0, closeable: true });
        this.fullPath = fullPath;
        this.dataSources = {};
        this.designerPanel = new ModelDesignerPanel(designer);
        this.add(this.designerPanel);
        if (this.path != null)
            Api.search("metadata/model", { service_project: this.serviceProject, path: this.path }).then((response) => {
                const def = response.data[0].response;
                this.designerPanel.def = JSON.parse(def);
            }).catch(error => {
                let msg = error?.toString();
                const index = msg.indexOf("NoSuchFileException: ");
                if (index >= 0)
                    msg = msg.substring(index + "NoSuchFileException: ".length, msg.indexOf("\n", index));
                this.designerPanel.removeAll();
                this.designerPanel.add(new Label({ caption: "The model " + msg + " doesn't exist any longer.", fillRow: true, align: HorizontalAlignment.CENTER, marginTop: 32 }))
            });
        else
            this.designerPanel.def = {};
    }

    get fullPath(): string {
        const result = this.serviceProject == null ? "" : (this.serviceProject + "/");
        return result + this.path;
    }


    set fullPath(value: string) {
        this.serviceProject = value == null ? null : StringUtil.stringBefore(value, "/");
        this.path = value == null ? null : StringUtil.stringAfter(value, "/");
    }

    get modified() {
        return this._modified;
    }

    set modified(value: boolean) {
        if (this._modified === value)
            return;
        this._modified = value;
        this._syncCaption();
    }

    private getTabTitle(path: string): string {
        if (path == null)
            return "New model";
        else
            return path.substring(path.lastIndexOf("/") + 1);
    }

    get path(): string {
        return this._path;
    }

    set path(value: string) {
        this._path = value;
        this._syncCaption();
    }

    private _syncCaption() {
        let caption = this.getTabTitle(this.path);
        if (this._modified)
            caption += " *";
        this.caption = caption;
    }
}
