import { SingleComponentDemo } from "./SingleComponentDemo";

export class DemoTextbox extends SingleComponentDemo {
    constructor() {
        super();
        this.propsForAllExamples = {};
        this.examples = {
            ...SingleComponentDemo.getComponentDemoExamples(),

            displayType: {
                cases: [
                    { value: "date", description: "Setting the displayType to DisplayType.Date adds date validation and a visual date picker" },
                ]
            },

            format: {
                cases: [
                    { value: "long", props: { displayType: "date" }, description: "Setting the format to \"long\" with a displayType of DisplayType.DATE will cause the date to display as a full day name, month name, and year." },
                ]
            },

            imagePreName: {
                cases: [
                    { value: "person" },
                    { value: "circleCheck" },
                ]
            },

            multiline: {
                props: { height: 200, width: 400 },
                cases: [
                    { value: true },
                ]
            },

            password: {
                cases: [
                    { value: true, description: "Type in this Input and your characters will be masked." },
                ]
            },


            placeholder: {
                cases: [
                    { value: "Enter a city/state/zip" },
                ]
            },

            spellcheck: {
                cases: [
                    { value: undefined, description: "Note how mispelling something in this Input highlights the misspelling and allows you to correct it." },
                    { value: false, description: "Note how this Input doesn't validate spelling." },
                ]
            },

            text: {
                cases: [
                    { value: "Initial value" },
                ]
            },

        }
    }
}
