import { Event, Layout, Panel, PanelProps, SlideoutDecorator, TableRow, TableSelectionEvent } from "@mcleod/components";
import { SelectionMode } from "@mcleod/components/src/base/SelectionMode";
import { CancelStopSelection } from "./CancelStopSelection";
import { StopList } from "./StopList";

export class CancelStopSelectionSlideout extends Panel {
    private movementId: string;
    public progress: CancelStopSelection;

    constructor(movementId: string, props?: Partial<PanelProps>) {
        super({
            maxWidth: 1100, width: window.innerWidth * .75, height: window.innerHeight,
            backgroundColor: "defaultBackground", top: 30, padding: 0,
            borderRadius: 4, borderWidth: 1, borderShadow: true, borderColor: "strokeSecondary"
        });
        this.movementId = movementId;
    }


    public showSlideout(doAfterClose: (canceled: boolean) => void) {
        if (this.movementId) {
            this.progress = Layout.getLayout("lme/dispatch/CancelStopSelection", { fillHeight: true }) as CancelStopSelection;
            this.progress.addLayoutLoadListener(event => {
                this.showCancelStopSelection(doAfterClose);
            })
        }
    }

    private showCancelStopSelection(doAfterClose) {
        new SlideoutDecorator({
            layout: Layout.getLayout("lme/dispatch/CancelStopSelection", { fillHeight: true, width: "60%" }),
            title: `Rollback Stops - Movement ${this.movementId}`,
            layoutLoadListeners: (event: Event) => {
                const layout = event.target as CancelStopSelection;
                const tableStop = (layout.layout1 as StopList).tableStop;
                tableStop.selectionMode = SelectionMode.SINGLE;
                tableStop.fillHeight = true;
                tableStop.addSelectionListener(event => {
                    layout.savebutton1.enabled = true;
                    const rowIndex: number | undefined = (event as TableSelectionEvent).newIndex;
                    tableStop.rows.forEach((row: TableRow, index: number, array: TableRow[]) => {
                        if (rowIndex != null && index > rowIndex) {
                            tableStop.rows[index].backgroundColor = tableStop.rows[rowIndex].backgroundColor;
                        }
                        else if (rowIndex != null && index < rowIndex) {
                            tableStop.rows[index].backgroundColor = "background";
                        }
                    });
                });
            },
            doAfterSlideIn: (decorator: SlideoutDecorator) => {
                const layout = (decorator.layout as CancelStopSelection);
                layout.doAfterClose = doAfterClose;
                layout.layout1.mainDataSource.search({ "movement_id": this.movementId });
                layout.mainDataSource.search({ "id": this.movementId });
            },
            overlayProps: { width: "60%", height: "60%", closeOnClickOff: false, greyedBackground: true }
        });
    }

}
