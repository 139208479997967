/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelOrdersLookup.ts                  *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/datafusion/orders-lookup";

export class AutogenModelOrdersLookup<RowType extends ModelRow> extends Endpoint<RowType, RowOrdersLookupFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowOrdersLookup extends ModelRow<RowOrdersLookupFields> {
    constructor(values?: Partial<RowOrdersLookupFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowOrdersLookupFields {
    actual_reefer_profile: string;
    agency_id: string;
    agent_payee_id: string;
    allow_relay: string;
    autorate_status: string;
    bill_date: Date;
    bill_distance: number;
    bill_distance_um: string;
    billing_empty_distance: number;
    billing_empty_distance_um: string;
    billing_loaded_distance: number;
    billing_loaded_distance_um: string;
    billing_user_id: string;
    blnum: string;
    bol_note: string;
    bol_received: string;
    bol_recv_date: Date;
    booking_no: string;
    booking_number: string;
    brk_qual_profile: string;
    broker_location_id: string;
    brokerage_workflow_id: string;
    cbp_crossing_date: Date;
    cbp_crossing_location_id: string;
    cbp_crossing_reason: string;
    cbsa_crossing_date: Date;
    cbsa_crossing_location_id: string;
    cbsa_crossing_reason: string;
    cc_fuel_revenue: number;
    cc_fuel_revenue_c: string;
    cc_fuel_revenue_d: Date;
    cc_fuel_revenue_n: number;
    cc_fuel_revenue_r: number;
    cc_net_revenue: number;
    cc_net_revenue_c: string;
    cc_net_revenue_d: Date;
    cc_net_revenue_n: number;
    cc_net_revenue_r: number;
    cc_pro_nbr: string;
    cobroker_location_id: string;
    collection_method: string;
    com_setpnt_temp: number;
    com_setpnt_temp_um: string;
    commitment_id: string;
    commodity: string;
    commodity_id: string;
    company_id: string;
    consignee_refno: string;
    consignee_stop_id: string;
    container: string;
    container_planned: string;
    container_type_id: string;
    controlling_carrier_code: string;
    copy_from_company: string;
    copy_from_order_id: string;
    copy_to_company: string;
    copy_to_order_id: string;
    ctrl_party_id: string;
    cube: number;
    curr_movement_id: string;
    cust_order_no: string;
    customer_id: string;
    declared_val: number;
    def_move_type: string;
    default_match_id: string;
    dim_height: string;
    dim_length: string;
    dim_surcharge: number;
    dim_surcharge_c: string;
    dim_surcharge_d: Date;
    dim_surcharge_n: number;
    dim_surcharge_r: number;
    dim_width: string;
    dispatch_opt: string;
    dl_team_reqd: string;
    dray_del_date: Date;
    dray_del_pay: number;
    dray_del_pay_c: string;
    dray_del_pay_d: Date;
    dray_del_pay_n: number;
    dray_del_pay_r: number;
    dray_del_payee: string;
    dray_del_xfer_dt: Date;
    dray_pu_date: Date;
    dray_pu_pay: number;
    dray_pu_pay_c: string;
    dray_pu_pay_d: Date;
    dray_pu_pay_n: number;
    dray_pu_pay_r: number;
    dray_pu_payee: string;
    dray_pu_xfer_dt: Date;
    eir1_no: string;
    eir2_no: string;
    entered_user_id: string;
    equipment_type_id: string;
    equipment_type_options: string;
    est_tolls: number;
    est_tolls_c: string;
    est_tolls_d: Date;
    est_tolls_n: number;
    est_tolls_r: number;
    excise_disable_update: string;
    excise_taxable: string;
    excisetax_total: number;
    excisetax_total_c: string;
    excisetax_total_d: Date;
    excisetax_total_n: number;
    excisetax_total_r: number;
    external_invoiceno: string;
    extra_deliveries: number;
    extra_pickups: number;
    floor_loaded_freight: string;
    force_assign: string;
    forwd_location_id: string;
    free_days: number;
    freight_charge: number;
    freight_charge_c: string;
    freight_charge_d: Date;
    freight_charge_n: number;
    freight_charge_r: number;
    hazmat: string;
    hazmat_code: string;
    high_value: string;
    hold_reason: string;
    ic_company_from: string;
    ic_movement_from: string;
    ic_order_from: string;
    id: string;
    image_is_present: string;
    import_export: string;
    include_split_point: string;
    intelliset: string;
    invoice_recv_date: Date;
    is_auto_tonu: string;
    is_autorate_dist: string;
    is_container: string;
    is_dedicated: string;
    is_intercompany: string;
    is_local_mile: string;
    is_requested: string;
    is_tonu: string;
    jag_carrier_id: string;
    jag_cost_rate_id: number;
    ldport_location_id: string;
    linear_feet_avail: number;
    load_date: Date;
    loadboard: string;
    lock_miles: string;
    ltl: string;
    markup_flat: number;
    markup_flat_c: string;
    markup_flat_d: Date;
    markup_flat_n: number;
    markup_flat_r: number;
    markup_percent: number;
    master_order_id: string;
    master_sequence: number;
    next_rebill: string;
    nte_lp_num: string;
    nte_lp_qual: string;
    on_hold: string;
    operational_status: string;
    operations_user: string;
    optimize: string;
    optiset: string;
    order_mode: string;
    order_type_id: string;
    order_value: number;
    order_value_c: string;
    order_value_d: Date;
    order_value_n: number;
    order_value_r: number;
    ordered_by: string;
    ordered_date: Date;
    ordered_method: string;
    orig_dest_rate_id: string;
    otherchargetotal: number;
    otherchargetotal_c: string;
    otherchargetotal_d: Date;
    otherchargetotal_n: number;
    otherchargetotal_r: number;
    pallets_how_many: number;
    pallets_required: string;
    pay_gross: number;
    pay_gross_c: string;
    pay_gross_d: Date;
    pay_gross_n: number;
    pay_gross_r: number;
    pick_up_no: string;
    pieces: number;
    placard_required: string;
    planning_comment: string;
    pnn_callback: string;
    pnn_comment: string;
    pnn_comment2: string;
    pnn_loadboard_user_id: string;
    pnn_post_type: string;
    pnn_rate: number;
    pnn_rate_type: string;
    pnn_trailer_length: number;
    pnn_trailer_width: number;
    ponum: string;
    port: string;
    port_of_discharge: string;
    preload_trailer_id: string;
    preloaded: string;
    rate: number;
    rate_currency_type: string;
    rate_id: number;
    rate_min_weight: number;
    rate_min_weight_um: string;
    rate_source: string;
    rate_type: string;
    rate_unit_desc: string;
    rate_units: number;
    ready_to_bill: string;
    recurring_order_id: string;
    reply_transmitted: string;
    requesting_company: string;
    return_temp: number;
    return_temp_um: string;
    return_temp_var: number;
    return_temp_var_um: string;
    revenue_code_id: string;
    revenue_share_method: string;
    round_trip: string;
    sail_date: Date;
    seal_number: string;
    seg_alloc_code: string;
    setpoint_temp: number;
    setpoint_temp_um: string;
    setpoint_var: number;
    setpoint_var_um: string;
    ship_status_to_edi: string;
    shipment_id: string;
    shipper_stop_id: string;
    shipstatus2edi_dt: Date;
    ss_location_id: string;
    status: string;
    subject_order_number: string;
    subject_order_status: string;
    subject_order_void_date: Date;
    swap: string;
    teams_required: string;
    temperature_max: number;
    temperature_max_um: string;
    temperature_min: number;
    temperature_min_um: string;
    total_charge: number;
    total_charge_c: string;
    total_charge_d: Date;
    total_charge_n: number;
    total_charge_r: number;
    totalcharge_and_excisetax: number;
    totalcharge_and_excisetax_c: string;
    totalcharge_and_excisetax_d: Date;
    totalcharge_and_excisetax_n: number;
    totalcharge_and_excisetax_r: number;
    tractor_type: string;
    vessel: string;
    vessel_cutoff_date: Date;
    weight: number;
    weight_um: string;
    xferred2billing: string;
    xferred2billing_dt: Date;
}

