import { Panel } from "../..";
import { AdvancedToolbar } from "./AdvancedToolbar";
import { DecoratorProps } from "./DecoratorProps";

export class AdvancedDecorator extends Panel {
    toolbar: AdvancedToolbar;

    constructor(props: Partial<DecoratorProps>) {
        super();
        this.setProps({ fillRow: true, fillHeight: true, ...props });
        this.toolbar = new AdvancedToolbar({ fillRow: true, dataSource: props.layout?.mainDataSource });
        this.add(this.toolbar);
        if (props.layout != null)
            this.add(props.layout);
    }
}
