/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelTopmatchProfileDetails.ts        *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/powerbroker/topmatch-profile-details";

export class AutogenModelTopmatchProfileDetails<RowType extends ModelRow> extends Endpoint<RowType, RowTopmatchProfileDetailsFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowTopmatchProfileDetails extends ModelRow<RowTopmatchProfileDetailsFields> {
    constructor(values?: Partial<RowTopmatchProfileDetailsFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowTopmatchProfileDetailsFields {
    company_id: string;
    descr: string;
    id: string;
    integratedsearch_profile_id: string;
    score: number;
    search_type: string;
    sequence: number;
    weight: number;
}

