import { DesignableObject, DomEvent } from "..";
import { Event, EventListener } from "./Event";
import { KeyModifiers, ModifierKeys } from "./KeyModifiers";

export interface KeyListener extends EventListener {
    (event: KeyEvent): void;
}

export class KeyEvent extends Event implements ModifierKeys {
    constructor(target: DesignableObject, domEvent?: Event | DomEvent) {
        super(target, domEvent);
        this.shouldAutomaticallyStopPropagation = false;
    }

    public isKey(key: string, modifiers: ModifierKeys): boolean {
        if (key !== this.key)
            return false;
        return KeyModifiers.hasModifiers(modifiers, this.domEvent);
    }

    public get key(): string {
        return this.domEvent?.key;
    }

    public get code(): string {
        return this.domEvent?.code;
    }

    public get altKey(): boolean {
        return this.domEvent?.altKey;
    }

    public get ctrlKey(): boolean {
        return this.domEvent?.ctrlKey;
    }

    public get shiftKey(): boolean {
        return this.domEvent.shiftKey;
    }

    public get domEvent(): KeyboardEvent {
        return super.domEvent as KeyboardEvent;
    }
}
