/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelOrderEdistatus.ts                *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/datafusion/order-edistatus";

export class AutogenModelOrderEdistatus<RowType extends ModelRow> extends Endpoint<RowType, RowOrderEdistatusFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowOrderEdistatus extends ModelRow<RowOrderEdistatusFields> {
    constructor(values?: Partial<RowOrderEdistatusFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowOrderEdistatusFields {
    actual_arrival: Date;
    actual_departure: Date;
    agent_payee_id: string;
    alt_partner_id: string;
    appointment_date: Date;
    batch: number;
    bill_plant: string;
    blnum: string;
    carrier_id: string;
    carrier_name: string;
    carrier_trailer: string;
    cc_pro_nbr: string;
    collection_method: string;
    commodity: string;
    commodity_id: string;
    company_id: string;
    cons_address: string;
    cons_city_name: string;
    cons_loc_name: string;
    cons_state: string;
    cons_stop_id: string;
    cons_zip_code: string;
    consignee_plant: string;
    copied_from_id: string;
    create_date: Date;
    create_user_id: string;
    curr_address: string;
    curr_cases: number;
    curr_city_id: number;
    curr_city_name: string;
    curr_contact_name: string;
    curr_latitude: number;
    curr_loc_id: string;
    curr_loc_name: string;
    curr_longitude: number;
    curr_phone: string;
    curr_plant: string;
    curr_state: string;
    curr_stop_id: string;
    curr_stop_type: string;
    curr_volume: number;
    curr_volume_um: string;
    curr_weight: number;
    curr_weight_um: string;
    curr_zip_code: string;
    customer_id: string;
    delay_code: string;
    delivery_signature: string;
    direction: string;
    edi_commodity_id: string;
    edi_equip_type_id: string;
    equipment_type_id: string;
    error: number;
    error_count: string;
    error_descr: string;
    eta: Date;
    eta_next_stop: Date;
    event_type: string;
    except1_code: string;
    except1_qual: string;
    except1_quant: number;
    except2_code: string;
    except2_qual: string;
    except2_quant: number;
    frt_grp_pro_nbr: string;
    func_ack_batch: number;
    func_ack_id: string;
    func_ack_rec: string;
    func_ack_rec_date: Date;
    func_ack_status: string;
    gs06_group_cntlno: string;
    hazmat_code_id: string;
    hold_delay_code: string;
    hold_trailer_id: string;
    id: string;
    inquiry_request: string;
    intercompany: string;
    is_resend: string;
    isa13_intr_cntlno: string;
    manifest_id: string;
    movement_id: string;
    movement_sequence: number;
    next_plant: string;
    next_stop_id: string;
    order_cube: number;
    order_id: string;
    order_pieces: number;
    order_weight: number;
    order_weight_um: string;
    ordered_date: Date;
    partner_id: string;
    partner_name: string;
    partner_stop_no: string;
    prev_plant: string;
    prev_status_date: Date;
    prev_stop_id: string;
    pro_nbr: string;
    ready_to_send: string;
    refno: string;
    scac: string;
    sched_arrive_early: Date;
    sched_arrive_late: Date;
    seal: string;
    service_standard: number;
    ship_address: string;
    ship_city_name: string;
    ship_loc_name: string;
    ship_match_descr: string;
    ship_match_type: string;
    ship_state: string;
    ship_stop_id: string;
    ship_zip_code: string;
    shipment_id: string;
    shipper_plant: string;
    source_record_id: string;
    source_record_type: string;
    st02_trxset_cntlno: string;
    status_code: string;
    status_date: Date;
    status_desc: string;
    stop_number: string;
    tid: number;
    timezone_id: string;
    tractor_id: string;
    trailer: string;
    trailer_id: string;
    trans_count: number;
    transmission_status: string;
    trip_lease: string;
    update_date: Date;
    update_user_id: string;
    version: string;
    xmit_sequence: number;
}

