import { BrltlFreightItemTable } from "../BrltlFreightItemTable";
import { OrderHandlingRequirements } from "../OrderHandlingRequirements";
import { OrderOverview } from "../OrderOverview";
import { OrderRates } from "../OrderRates";
import { ShipperPONums } from "../ShipperPONums";
import {
    Button, Checkbox, CityState, DataHeader, DataSource, HorizontalSpacer, Image, Label, Layout, NumberEditor, Panel,
    Switch, Tab, Table, Tabset, Textbox
} from "@mcleod/components";
import { ImageTable } from "@mcleod/general/src/ImageTable";

export abstract class AutogenLayoutOrders extends Layout {
	buttonAddAnotherStop: Button;
	buttonAssignCarrier: Button;
	buttonAutoEquipmentMatch: Button;
	buttonCarrierRates: Button;
	buttonEnterNewOrder: Button;
	buttonFindCarrier: Button;
	buttonInitiateStatusProcessing: Button;
	buttonOpenClassic: Button;
	buttonOrderHistory: Button;
	buttonReleaseToBilling: Button;
	buttonSendBOL: Button;
	buttonUsers: Button;
	buttonViewOrder: Button;
	checkboxBolReceived: Checkbox;
	checkboxExpedited: Checkbox;
	checkboxFloorLoadedFreight: Checkbox;
	checkboxHighValue: Checkbox;
	checkboxPreloaded: Checkbox;
	checkboxReadyToBill: Checkbox;
	checkboxRoundTrip: Checkbox;
	checkboxTarps: Checkbox;
	checkboxTeamsRequired: Checkbox;
	dataheader1: DataHeader;
	horizontalspacer1: HorizontalSpacer;
	howManyOrders: NumberEditor;
	imageAddHandlingReq: Image;
	imageReorder: Image;
	labelAfterThisOrderIsSavedIWantTo: Label;
	labelAnd: Label;
	labelCreditBadge: Label;
	labelHazmatCommodity: Label;
	labelHoldBadge: Label;
	labelHowManyOrders: Label;
	labelStatusBadge: Label;
	labelTemperatureRange: Label;
	layoutFreightItems: BrltlFreightItemTable;
	layoutHandlingReqs: OrderHandlingRequirements;
	layoutImageTable: ImageTable;
	layoutOverview: OrderOverview;
	layoutRates: OrderRates;
	layoutShipperPONums: ShipperPONums;
	panel10: Panel;
	panel11: Panel;
	panel12: Panel;
	panel13: Panel;
	panel1: Panel;
	panel2: Panel;
	panel3: Panel;
	panel5: Panel;
	panel6: Panel;
	panel7: Panel;
	panel9: Panel;
	panelCommodityCheckboxes: Panel;
	panelCommodityExtra: Panel;
	panelCommodityHazmat: Panel;
	panelDataHeader2: Panel;
	panelDataHeaderAddlLeft: Panel;
	panelDataHeaderUpdateOnly: Panel;
	panelEquipTypeOptions: Panel;
	panelGeneralUpdate: Panel;
	panelHazmatComponents: Panel;
	panelHowManyOrders: Panel;
	panelNextActionButtonContainer: Panel;
	panelNextActionButtons: Panel;
	panelNextActions: Panel;
	postingCityState: CityState;
	sourceBrltlBillingFreightGroup: DataSource;
	sourceBrltlOrderFreightGroupItem: DataSource;
	sourceBrltlOrderHdrXFgp: DataSource;
	sourceEquipMatchDetail: DataSource;
	sourceOrders: DataSource;
	sourceResponsibleHist: DataSource;
	sourceStop: DataSource;
	stopsTable: Table;
	switchHazmatRouting: Switch;
	switchHoldLoad: Switch;
	switchPostLoadboard: Switch;
	switchPostToLoadBoards: Switch;
	switchRecurringOrder: Switch;
	switchTenderReplyTransmitted: Switch;
	tabAccessorials: Tab;
	tabBackOffice: Tab;
	tabBrltlFreightItems: Tab;
	tabCommodity: Tab;
	tabDataFusion: Tab;
	tabEquipment: Tab;
	tabGeneral: Tab;
	tabLoadBoardDetails: Tab;
	tabNextActions: Tab;
	tabOverview: Tab;
	tabRate: Tab;
	tabSpotOrder: Tab;
	tabStops: Tab;
	tableEquipmentRequirements: Table;
	tabsetAddOrders: Tabset;
	textboxAgentPayeeId: Textbox;
	textboxBillDate: Textbox;
	textboxBillingUserId: Textbox;
	textboxBlnum2: Textbox;
	textboxBlnum: Textbox;
	textboxBolNote: Textbox;
	textboxBolRecvDate: Textbox;
	textboxBrkQualProfile: Textbox;
	textboxBrokerageWorkflowId: Textbox;
	textboxCommodity: Textbox;
	textboxCommodityId: Textbox;
	textboxConsigneeRefno: Textbox;
	textboxCopyFromCompany: Textbox;
	textboxCopyFromOrderId: Textbox;
	textboxCopyToCompany: Textbox;
	textboxCopyToOrderId: Textbox;
	textboxCustomerId: Textbox;
	textboxDefMoveType: Textbox;
	textboxDefaultEquipMatchId: Textbox;
	textboxEnteredUserId: Textbox;
	textboxEquipmentTypeIdEquipment: Textbox;
	textboxEquipmentTypeIdGeneral: Textbox;
	textboxExternalInvoiceno: Textbox;
	textboxHazmatCode: Textbox;
	textboxHoldReason: Textbox;
	textboxInvoiceRecvDate: Textbox;
	textboxOperationsUser: Textbox;
	textboxOrderMode: Textbox;
	textboxOrderOrderedBy: Textbox;
	textboxOrderType: Textbox;
	textboxOrderValue: Textbox;
	textboxOrderedDate: Textbox;
	textboxOrderedMethod: Textbox;
	textboxOutboundStatusCompleted: Textbox;
	textboxPieces: Textbox;
	textboxPlanningComment: Textbox;
	textboxPnnCallback: Textbox;
	textboxPnnComment2: Textbox;
	textboxPnnComment: Textbox;
	textboxPnnLoadboardUserId: Textbox;
	textboxPnnRate: Textbox;
	textboxPnnRateType: Textbox;
	textboxPnnTrailerLength: Textbox;
	textboxPnnTrailerWidth: Textbox;
	textboxPuOverrideArriveEarly: Textbox;
	textboxPuOverrideArriveLate: Textbox;
	textboxRateSource: Textbox;
	textboxRecurringOrderId: Textbox;
	textboxRevenueCodeId: Textbox;
	textboxShipmentId: Textbox;
	textboxSubjectOrderNumber: Textbox;
	textboxSubjectOrderStatus: Textbox;
	textboxSubjectOrderVoidDate: Textbox;
	textboxTemperatureMax: Textbox;
	textboxTemperatureMin: Textbox;
	textboxTractorType: Textbox;
	textboxWeight: Textbox;
	textboxXferred2billingDt: Textbox;
}
