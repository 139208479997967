import { Cursor, DataSource, Image, Label, Panel, getDataSourcePropDefinitions } from "@mcleod/components";
import { HorizontalAlignment } from "@mcleod/core";
import { AbstractUIDesigner } from "./AbstractUIDesigner";

export class DesignerDataSource extends Panel {
    designerDataSource: DataSource;
    label: Label;

    constructor(designer: AbstractUIDesigner, dataSource: DataSource) {
        super({ rowBreak: false, color: "primary.light", cursor: Cursor.POINTER });
        this.designerDataSource = dataSource;
        const dsImage = new Image({ name: "designer/datasource", fillRow: true, align: HorizontalAlignment.CENTER });
        this.label = new Label({ caption: dataSource.id, fontSize: "small", fillRow: true, align: HorizontalAlignment.CENTER, allowSelect: false });
        this.addClickListener(event => {
            designer.selectComponent(this);
        });
        this.add(dsImage, this.label);
    }

    getPropertyDefinitions() {
        return getDataSourcePropDefinitions() as any;
    }

    updateId() {
        this.label.caption = this.designerDataSource.id;
    }
}
