import { Endpoint, MenuItem, ModelRow } from "@mcleod/core";

const AutogenPermsTreeEndpoint = "common/perms-tree";

export class ModelPermsTree<RowType extends ModelRow> extends Endpoint<RowType, RowPermsTreeFields> {
    constructor() {
        super(AutogenPermsTreeEndpoint, RowPermsTree);
    }
}

export class RowPermsTree extends ModelRow<RowPermsTreeFields> {
    constructor() {
        super(AutogenPermsTreeEndpoint);
    }
}

export interface RowPermsTreeFields {
    menu: MenuItem;
}
