import { DataSource, Label, Layout } from "@mcleod/components";

export abstract class AutogenLayoutCarrierInformationQuickInfo extends Layout {
	labelCarrierScac: Label;
	labelLabel3: Label;
	labelLabel4: Label;
	labelP44VendorInfo: Label;
	labelScac: Label;
    labelContractDescr: Label;
	labelRatingSource: Label;
	labelSource: Label;
	labelType: Label;
	sourceQuoteBillingFreightGroup: DataSource;
}
