import { BlurEvent, Button, ClickEvent, DataDisplayEvent, DataSource, DataSourceMode, DataSourceModeChangeEvent, Snackbar, TableAddRowResult, TableRow, TableRowBeforeSaveEvent, TableRowMode } from "@mcleod/components";
import { DateUtil, ModelRow } from "@mcleod/core";
import { ShipperPONums } from "@mcleod/dispatch/src/ShipperPONums";
import { QuoteStop } from "./QuoteStop";
import { AutogenLayoutQuoteStopTable } from "./autogen/AutogenLayoutQuoteStopTable";

export class QuoteStopTable extends AutogenLayoutQuoteStopTable {
    public calculateAutoRate: (event: BlurEvent) => void;
    private _orderSource: DataSource;
    private _shipperPONumLayout: ShipperPONums;

    set orderSource(value: DataSource) {
        this._orderSource = value;
    }

    get orderSource(): DataSource {
        return this._orderSource;
    }

    get shipperPONumLayout(): ShipperPONums {
        return this._shipperPONumLayout;
    }

    set shipperPONumLayout(value: ShipperPONums) {
        this._shipperPONumLayout = value;
    }

    /** This is an event handler for the beforeRowSave event of tableStop.  */
    tableStopBeforeRowSave(event: TableRowBeforeSaveEvent) {
        const tableRow: TableRow = event.target as TableRow;
        const modelRow: ModelRow = tableRow.data;
        const tableRowlayoutQuoteStop: QuoteStop = event.target["layoutQuoteStop"] as QuoteStop;
        modelRow.set("stop_notes", tableRowlayoutQuoteStop?.layoutStopNotesAndRefNbrs?.tableStopComments?.data ?? []);
        modelRow.set("stop_reference_numbers", tableRowlayoutQuoteStop?.layoutStopNotesAndRefNbrs?.tableStopReferenceNumbers?.data ?? []);
    }

    tableStopOnRowDisplay(event) {
        const tableRow = event.target as TableRow;
        tableRow.findComponentById((comp: any) => {
            if (comp instanceof QuoteStop) {
                comp.addLayoutLoadListener(() => {
                    this.sourceQuoteStop.preventChangeNotifications = true;
                    tableRow["layoutQuoteStop"] = comp;
                    comp.initialize(false, tableRow, this.orderSource);
                    comp.displayData(tableRow.data, this.sourceQuoteStop.data, tableRow.index);
                    this.sourceQuoteStop.preventChangeNotifications = false;
                    comp.switchPickupConsignee.addChangeListener((event) => comp.textboxSchedArriveEarly.caption = event.newValue === false ? "Scheduled Pickup" : "Scheduled Delivery");
                    if (tableRow.index === 0 && this.shipperPONumLayout) {
                        this.shipperPONumLayout.initialize({ "stopSource": this.sourceQuoteStop, "firstStopRow": tableRow.data })
                    }
                })
            }
        })
    }

    buttonAddAnotherStopOnClick(event: ClickEvent) {
        this.addNewStopToTable().then(row => {
            this.tableStop.rows.forEach((row: TableRow, index: number) => {
                const layoutQuoteStop = row["layoutQuoteStop"]; // Check for existing tablerows and update stop labels/pickup-consignee switch
                if (layoutQuoteStop) {
                    layoutQuoteStop.setStopLabels(row, index == this.tableStop.rowCount - 1);
                }
            })
            row.scrollIntoView()
        });
    }

    buttonAddAnotherStopOnDataDisplay(event: DataDisplayEvent) {
        (event.target as Button).setProps({ ...(event.target as Button).allProps, "color": "primary" });
    }

    public async addNewStopToTable(): Promise<TableRow> {
        let addRowResult: TableAddRowResult;
        const newRowData = await this.tableStop._createNewRowData();
        if (newRowData != null) {
            newRowData.set("stop_type", this.tableStop.data.length > 0 ? "SO" : "PU");
            addRowResult = this.tableStop.addRow(newRowData, { mode: TableRowMode.ADD }, { display: true, addToData: true });
        }
        return addRowResult.row;
    }

    validateStops(): boolean {
        let prevDateTime = null;
        if (this.sourceQuoteStop.data.length < 2) {
            Snackbar.showWarningSnackbar("You must have at least two stops.", { id: "stopCount", persist: false });
            return false;
        }
        for (const row of this.sourceQuoteStop.data) {
            if (typeof row?.get("city_id") === 'string') {
                row.set("city_id", parseInt(row.get("city_id")));
            }
            if (!row.isNull("sched_arrive_early")) {

                const earlyDateTime = DateUtil.parseDateTime(row.get("sched_arrive_early"));
                if (prevDateTime !== null && prevDateTime > earlyDateTime) {
                    Snackbar.showWarningSnackbar("The scheduled arrival at the shipper cannot be later than the scheduled arrival at the consignee.", { id: "stopDates", persist: false });
                    return false;
                }
                prevDateTime = earlyDateTime;
            }
        }
        return true;
    }

    async sourceQuoteStopAfterModeChange(event: DataSourceModeChangeEvent) {
        if (event.newMode == DataSourceMode.ADD) {
            await this.addNewStopToTable();
            await this.addNewStopToTable();
        }
    }
}
