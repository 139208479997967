import { DynamicLoader, StackUtil, StringUtil } from "@mcleod/core";
import { ReflectiveDialogs } from "../../base/ReflectiveDialogs";
import { Dialog, DialogProps } from "../Dialog";

export class StackDialog {
    public static async showStackDialog(error: Error, dialogProps: Partial<DialogProps>): Promise<Dialog> {
        const lineProps = [];
        StackUtil.mapStackTrace(error.stack, (stackElements) => {
            const commonProps = { padding: 0, marginBottom: 4 }
            for (const element of stackElements) {
                lineProps.push({ caption: element.text, rowBreak: element.file == null, marginRight: 4, ...commonProps });
                if (element.file != null) {
                    lineProps.push({ caption: "(", rowBreak: false, ...commonProps })
                    lineProps.push({
                        caption: StringUtil.stringAfterLast(element.file, "/") + ":" + element.line, color: "primary", ...commonProps, borderBottomWidth: 1, rowBreak: false, onClick: () => {
                            const codeEditor = DynamicLoader.getClassForPath("designer/common/CodeEditor"); // not valid to have a static import to designer from here, so look it up dynamically
                            if (codeEditor != null)
                                codeEditor.openCodeEditor(element.file, { line: element.line, col: element.col + 1 });
                        }
                    });
                    lineProps.push({ caption: ")", ...commonProps })
                }
            }
        });
        return ReflectiveDialogs.showDialog(lineProps, dialogProps);
    }
}
