import { Alignment, ArrayUtil, ObjectUtil, StringUtil } from "@mcleod/core";
import { Component } from "../../base/Component";
import { TransitionOptions } from "../../base/TransitionOptions";
import { Button } from "../../components/button/Button";
import { Label } from "../../components/label/Label";
import { LabelProps } from "../../components/label/LabelProps";
import { Panel } from "../../components/panel/Panel";
import { PanelProps } from "../../components/panel/PanelProps";
import { HorizontalSpacer } from "../HorizontalSpacer";
import { OverlayProps } from "../OverlayProps";
import { Decorator } from "./Decorator";
import { SlideoutDecoratorProps } from "./SlideoutDecoratorProps";

export class SlideoutDecorator extends Decorator implements SlideoutDecoratorProps {
    private _fillHorizontalSpace: boolean;
    _fillVerticalSpace: boolean;
    panelHeader: Panel;
    labelTitle: Label;
    btnClose: Button;
    onClose: (cancelled: boolean) => void;
    panelContent: Panel;
    private _transitionOptions: Partial<TransitionOptions>;
    private _displayOverlay: boolean;
    private _overlayProps: Partial<OverlayProps>;
    private _doAfterSlideIn: (decorator: SlideoutDecorator) => void;
    private _doAfterSlideOut: (decorator: SlideoutDecorator) => void;

    constructor(props?: Partial<SlideoutDecoratorProps>) {
        super({ themeKey: "slideoutDecorator.outerPanel", ...props });
        this._defaultOnClose(props);
        const addlComponents = props?.addlComponents != null ? ArrayUtil.getAsArray(props.addlComponents) : null;
        this.addHeaderActions(addlComponents);
        this.setupSlideInListener();
    }

    private _defaultOnClose(props: Partial<SlideoutDecoratorProps>) {
        //default onClose to closing the slideout, but only when the prop wasn't specified
        if (ObjectUtil.containsKey(props, "onClose") !== true)
            this.onClose = (cancelled: boolean) => this.slideOut();
    }

    override setupPanels(props: Partial<SlideoutDecoratorProps>) {
        this.createTitleLabel(props);
        this.createHeaderPanel(props?.headerProps);
        this.panelHeader.add(this.labelTitle, new HorizontalSpacer());
        this.createContentPanel(props?.contentProps);
        this.add(this.panelHeader, this.panelContent);
    }

    createHeaderPanel(props: Partial<PanelProps>) {
        this.panelHeader = new Panel({ themeKey: "slideoutDecorator.headerPanel", id: "sodPanelHeader", ...props });
    }

    createTitleLabel(props: Partial<SlideoutDecoratorProps>) {
        let labelProps: Partial<LabelProps> = {};
        if (props != null) {
            if (props.title != null)
                labelProps.caption = props.title;
            if (props.titleImage != null)
                labelProps.imageName = props.titleImage;
            if (props.titleImageColor != null)
                labelProps.imageColor = props.titleImageColor;
            if (props.titleProps != null)
                labelProps = { ...labelProps, ...props.titleProps };
        }
        this.labelTitle = new Label({
            rowBreak: false,
            themeKey: "slideoutDecorator.titleLabel",
            ...labelProps
        });
    }

    createContentPanel(props: Partial<PanelProps>) {
        this.panelContent = new Panel({ themeKey: "slideoutDecorator.contentPanel", id: "sodPanelContent", ...props });
    }

    addHeaderActions(addlComponents: Component[]): void {
        this.addComponentsToHeader(addlComponents);
        this.addCloseButton();
    }

    addComponentsToHeader(components: Component[]) {
        if (components == null)
            return;
        for (const component of components) {
            this.panelHeader.add(component);
        }
    }

    addCloseButton() {
        if (this.onClose != null) {
            this.createCloseButton();
            this.panelHeader.add(this.btnClose);
        }
    }

    createCloseButton() {
        this.btnClose = new Button({ themeKey: "slideoutDecorator.closeButton", id: "sodCloseButton", cancel: true });
        this.btnClose.addClickListener(() => this.onClose(true));
    }

    override layoutLoaded() {
        if (StringUtil.isEmptyString(this.labelTitle.caption))
            this.labelTitle.caption = "Edit " + this.layout.title;
        if (StringUtil.isEmptyString(this.labelTitle.imageName))
            this.labelTitle.imageName = this.layout.titleImage;
        if (this.layout.paddingBottom == null)
            this.layout.paddingBottom = 24;
    }

    override addLayout() {
        this.panelContent.add(this.layout);
    }

    setupSlideInListener() {
        this.layout.addLayoutLoadListener(() => this.slideInAndFill(this.fillHorizontalSpace, this.fillVerticalSpace, this.transitionOptions, this.displayOverlay, this.overlayProps).then(() => {
            if (this.doAfterSlideIn != null)
                this.doAfterSlideIn(this);
        }));
    }

    get fillHorizontalSpace(): boolean {
        return this._fillHorizontalSpace == null ? false : this._fillHorizontalSpace;
    }

    set fillHorizontalSpace(value: boolean) {
        this._fillHorizontalSpace = value;
    }

    get fillVerticalSpace(): boolean {
        return this._fillVerticalSpace == null ? false : this._fillVerticalSpace;
    }

    set fillVerticalSpace(value: boolean) {
        this._fillVerticalSpace = value;
    }

    get doAfterSlideIn(): (decorator: SlideoutDecorator) => void {
        return this._doAfterSlideIn;
    }

    set doAfterSlideIn(value: (decorator: SlideoutDecorator) => void) {
        this._doAfterSlideIn = value;
    }

    get doAfterSlideOut(): (decorator: SlideoutDecorator) => void {
        return this._doAfterSlideOut;
    }

    set doAfterSlideOut(value: (decorator: SlideoutDecorator) => void) {
        this._doAfterSlideOut = value;
    }

    public override slideOut(options?: TransitionOptions, componentToFocusOnClose?: Component): Promise<any> {
        const result = super.slideOut(options, componentToFocusOnClose);
        if (this._doAfterSlideOut != null)
            this._doAfterSlideOut(this);
        return result;
    }

    get transitionOptions(): Partial<TransitionOptions> {
        return this._transitionOptions == null ? { direction: Alignment.RIGHT } : this._transitionOptions;
    }

    set transitionOptions(value: Partial<TransitionOptions>) {
        this._transitionOptions = value;
    }

    get displayOverlay(): boolean {
        return this._displayOverlay == null ? true : this._displayOverlay;
    }

    set displayOverlay(value: boolean) {
        this._displayOverlay = value;
    }

    get overlayProps(): Partial<OverlayProps> {
        return this._overlayProps;
    }

    set overlayProps(value: Partial<OverlayProps>) {
        this._overlayProps = value;
    }
}
