import { Api, Currency, ModelRow } from "@mcleod/core";
import { AutogenModelCarrierOffer, AutogenRowCarrierOffer } from "./autogen/AutogenModelCarrierOffer";

export class ModelCarrierOffer extends AutogenModelCarrierOffer<RowCarrierOffer> {
    constructor() {
        super(RowCarrierOffer);
    }
}

export class RowCarrierOffer extends AutogenRowCarrierOffer {
    public static async getMarginData(modelRow: ModelRow, offerField: 'amount' | 'counter_offer'): Promise<{ margin: string, margin_pct: number }> {
        if (!offerField) return;
        const totalCharge = modelRow.get("order_total_chg") as Currency;
        const offer = modelRow.get(offerField) as Currency;

        if (!offer || !offer.amount) {
            throw { name: "nullOffer", message: "An offer or counter off is required to calculate margins." }
        }
        if (!totalCharge || !totalCharge.amount) {
            throw { name: "nullTotalCharge", message: "Total charge is required to calculate margins." };
        }

        return await Api.post("lme/powerbroker/offermargin", {
            offer: offer, total_charge: totalCharge
        }).then(marginData => {
            return marginData?.data[0];
        });
    }
}

export enum OfferField {
    OFFER = 'amount',
    COUNTER = 'counter_offer',
}
