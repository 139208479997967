import { ChangeEvent, Textbox } from "@mcleod/components";
import { AutogenLayoutLocationFindNear } from "./autogen/AutogenLayoutLocationFindNear";

export class LocationFindNear extends AutogenLayoutLocationFindNear {
    PLOT_SETTING = "lme/dispatch/LocationFindNear.checkPlotOnMap.checked";

    override onLoad() {
        this.citystateNear.textCombined.onSelectItem = (textbox: Textbox, selectedItem: any) => {
            this.citystateNear.textCombined.tooltipCallback = null;
            return undefined;
        }
        this.splitter.position = this.switchPlotOnMap.checked ? "50%" : "100%";
    }

    textboxCategoryBeforeLookupModelSearch(event) {
        event.filter.location = 'Y';
    }

    switchShowLocationsOnMapOnChange(event: ChangeEvent) {
        const shouldPlot = this.switchPlotOnMap.checked;
        this.mapFindNear.visible = shouldPlot;
        this.mapFindNear.dataSource = shouldPlot ? this.sourceLocation : null;
        if (event.userInitiatedChange) {
            this.splitter.position = shouldPlot ? "50%" : "100%";
            this.splitter.storeUserChoiceIfRemembered();
        }
        if (shouldPlot)
            this.sourceLocation.displayDataInBoundComponents();
    }
}
