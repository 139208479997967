import { SmsMessageDetails } from "../SmsMessageDetails";
import { DataSource, HorizontalSpacer, Label, Layout, Panel, Switch, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutSendSmsMessage extends Layout {
	horizontalspacer3: HorizontalSpacer;
	labelArrivalTimes: Label;
	labelCellPhoneNumber: Label;
	labelCommentInformation: Label;
	labelContactInformation: Label;
	labelDirections: Label;
	labelDirectionsDisclaimer: Label;
	labelDistanceBetweenStops: Label;
	labelDoNotCallNotice: Label;
	labelEmailAddress: Label;
	labelOfficePhoneNumber: Label;
	labelReferenceNumbers: Label;
	labelStopInfo: Label;
	layoutSmsMessageDetail: SmsMessageDetails;
	panelCharactersUsed: Panel;
	panelMessageTextContainer: Panel;
	panelTemplates: Panel;
	smsMessageText: Textbox;
	sourceCarrierDriver: DataSource;
	sourceCarrierDriverMessage: DataSource;
	sourceMovement: DataSource;
	switchCellPhone: Switch;
	switchComments: Switch;
	switchContactInfo: Switch;
	switchDirections: Switch;
	switchDirectionsDisclaimer: Switch;
	switchDistBetweenStops: Switch;
	switchDoNotCall: Switch;
	switchEmailAddr: Switch;
	switchOfficePhone: Switch;
	switchReferenceNumbers: Switch;
	switchSchedArrivalTimes: Switch;
	switchStopName: Switch;
	textboxCellPhoneNumber: Textbox;
	textboxCharacters: Textbox;
	textboxCharactersRemaining: Textbox;
	textboxDriverName: Textbox;
	textboxNumofTextMessages: Textbox;
}
