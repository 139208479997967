import { Button, ClickEvent, DataSource, DataSourceMode, DataSourceModeChangeEvent, Layout, SlideoutDecorator } from "@mcleod/components";
import { LOOKUP_MODEL_PREFIX } from "@mcleod/core/src/ModelRow";
import { ResponsibleHist } from "./ResponsibleHist";

/**
 * The purpose of this class is to display the provided layout in a SlideoutDecorator.  The provided layout is expected
 * to be a 'users info' layout from a transaction screen.  For example, this would be the OrderUsers layout from the Orders
 * screen.  The important thing about the provided layout is that it is expected to contain an instance of
 * ResponsibleHist as a nested layout.
 */
export class UsersResponsibilitySlideoutTrans {
    private layout: Layout;
    private title: string;
    private idFilter: any;
    private sharedFieldNames: string[];
    private responsibleHist: ResponsibleHist;
    private layoutRespsonsibleHistDataSource: DataSource;
    private parentMainDataSource: DataSource;
    private parentRespsonsibleHistDataSource: DataSource;

    public constructor(layoutName: string, title: string, idFilter: any, sharedFieldNames: string[], parentMainDataSource: DataSource, parentRespsonsibleHistDataSource: DataSource) {
        this.title = title;
        this.idFilter = idFilter;
        this.sharedFieldNames = sharedFieldNames;
        this.parentMainDataSource = parentMainDataSource;
        this.parentRespsonsibleHistDataSource = parentRespsonsibleHistDataSource;
        this.layout = Layout.getLayout(layoutName);
        this.layout.addLayoutLoadListener(async event => {
            this.layout.mainDataSource.addAfterModeChangeListener((event: DataSourceModeChangeEvent) => {
                if (event.newMode === DataSourceMode.ADD) {
                    for (const field of this.sharedFieldNames) {
                        this.layout.mainDataSource.activeRow.set(field, this.parentMainDataSource.activeRow.get(field));
                        const lookupModelFieldName = LOOKUP_MODEL_PREFIX + field;
                        if (this.parentMainDataSource.activeRow.get(lookupModelFieldName) != null)
                            this.layout.mainDataSource.activeRow.set(lookupModelFieldName, this.parentMainDataSource.activeRow.get(lookupModelFieldName));
                    }
                    this.layout.mainDataSource.displayDataInBoundComponents();
                }
            });
            this.responsibleHist = this.findResponsibleHistNestedLayout(this.layout);
            if (this.responsibleHist != null) {
                this.layoutRespsonsibleHistDataSource = this.findResponsibleHistDataSource(this.layout);
                await this.responsibleHist.setup(this.layoutRespsonsibleHistDataSource/*, this.layout.mainDataSource*/);
            }
        });
    }

    public show() {
        const sod = new SlideoutDecorator({
            layout: this.layout,
            width: window.innerWidth * .75,
            maxWidth: 1320,
            fillVerticalSpace: true,
            title: this.title,
            displayOverlay: true,
            overlayProps: { closeOnClickOff: false, greyedBackground: false },
            addlComponents: new Button({
                id: "ursSaveButton",
                caption: "Save",
                backgroundColor: "primary",
                color: "primary.reverse",
                minWidth: 128,
                borderWidth: 0,
                rowBreak: false,
                onClick: (event: ClickEvent) => {
                    this.processSaveClick(event.target as Button, sod);
                },
            }),
            onClose: () => {
                sod.slideOut();
            },
            doAfterSlideIn: (sod: SlideoutDecorator) => {
                if (this.parentMainDataSource.mode === DataSourceMode.UPDATE)
                    this.layout.mainDataSource.search({ ...this.idFilter });
                else if (this.parentMainDataSource.mode === DataSourceMode.ADD) {
                    if (this.layoutRespsonsibleHistDataSource != null) {
                        this.layoutRespsonsibleHistDataSource.data = this.parentRespsonsibleHistDataSource.getDataCopy();
                        this.layoutRespsonsibleHistDataSource.displayDataInBoundComponents();
                        if (this.parentMainDataSource.activeRow.get("rf_entry_code_required", false) === true)
                            this.responsibleHist.addBlankEntryRecordToTable();
                    }
                }
                this.layout.mainDataSource.mode = this.parentMainDataSource.mode;
                if (this.layoutRespsonsibleHistDataSource != null)
                    this.layoutRespsonsibleHistDataSource.mode = this.parentMainDataSource.mode;
            }
        });
    }

    private async processSaveClick(button: Button, sod: SlideoutDecorator) {
        try {
            button.busy = true;
            if (this.parentMainDataSource.isAddingOrUpdating() === true) {
                if (sod.validateSimple() !== true)
                    return;
                if (this.parentMainDataSource.mode === DataSourceMode.UPDATE)
                    await this.layout.mainDataSource.post();

                for (const field of this.sharedFieldNames) {
                    const lookupModelFieldName = LOOKUP_MODEL_PREFIX + field;
                    const parentActiveRow = this.parentMainDataSource.activeRow;
                    parentActiveRow.set(lookupModelFieldName, this.layout.mainDataSource.activeRow.get(lookupModelFieldName));
                    parentActiveRow.resetOriginalData(lookupModelFieldName);
                    parentActiveRow.set(field, this.layout.mainDataSource.activeRow.get(field));
                    parentActiveRow.resetOriginalData(field);
                }
                this.parentMainDataSource.displayDataInBoundComponents();

                if (this.responsibleHist != null) {
                    this.parentRespsonsibleHistDataSource.data = this.layoutRespsonsibleHistDataSource.data;
                }
                sod.slideOut();
            }
            else if (this.parentMainDataSource.mode === DataSourceMode.SEARCH) {
                if (this.responsibleHist != null) {
                    this.parentRespsonsibleHistDataSource.data = this.layoutRespsonsibleHistDataSource.data;
                }
                sod.slideOut();
            }
        }
        finally {
            button.busy = false;
        }
    }

    private findResponsibleHistNestedLayout(layout: Layout): ResponsibleHist {
        for (const variable of Object.keys(layout)) {
            if (layout[variable] instanceof ResponsibleHist)
                return layout[variable];
        }
        return null; //can return null if AgenciesWithPrimary nested layout was removed due to licensing
    }

    private findResponsibleHistDataSource(layout: Layout): DataSource {
        for (const dataSourceId of layout.dataSourceIDs) {
            if ((layout[dataSourceId] as DataSource).url === "lme/general/responsible-hist")
                return layout[dataSourceId];
        }
        throw new Error("The provided layout does not contain the expected Responsbility Codes DataSource.")
    }
}
