import { DataSource, Label, Layout, Panel, SearchButton, Tab, Tabset, Textbox } from "@mcleod/components";
import { SalesCallListTable } from "../SalesCallListTable";

export abstract class AutogenLayoutSalesCallList extends Layout {
    labelPageTitle: Label;
    layoutAll: SalesCallListTable;
    layoutToday: SalesCallListTable;
    panel1: Panel;
    panel2: Panel;
    searchbuttonSearch: SearchButton;
    sourceSalesCalls: DataSource;
    tabAll: Tab;
    tabToday: Tab;
    tabsetMain: Tabset;
    textboxAssignedTo: Textbox;
}
