import { Button, Image, Label, Layout, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutResetPassword extends Layout {
    buttonCancel: Button;
    buttonSave: Button;
    imgRequirements: Image;
    labelAsterisk: Label;
    labelAsteriskTwo: Label;
    labelConfirmNewPassword: Label;
    labelMinimumLength: Label;
    labelMinimumSpecials: Label;
    labelNewPassword: Label;
    labelPasswordCriteria: Label;
    panelContent: Panel;
    panelHeader: Panel;
    panelLogo: Panel;
    panelMain: Panel;
    panelPage: Panel;
    textConfirmPassword: Textbox;
    textPassword: Textbox;
}
