
import { Label } from "@mcleod/components";
import { TableRow } from "@mcleod/components/src/components/table/TableRow";
import { TableRowDisplayEvent } from "@mcleod/components/src/events/TableRowDisplayEvent";
import { HorizontalAlignment } from "@mcleod/core";
import { AutogenLayoutGooglePlacesSearch } from "./autogen/AutogenLayoutGooglePlacesSearch";

export class GooglePlacesSearch extends AutogenLayoutGooglePlacesSearch {

    tableGooglePlacesOnDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        const row = (event.target as TableRow).data;
        const isDuplicate = row.get("duplicate_location");
        const labelLocationId = tableRow.findComponentById("celllocation_id") as Label;
        if (isDuplicate) {
            labelLocationId.imageName = "circlePlusFilled";
            labelLocationId.imageProps = { stroke: "primary-reverse", fill: "primary" };
            labelLocationId.tooltip = "Duplicate Google Place";
            labelLocationId.align = HorizontalAlignment.LEFT;
        }
    }
}
