import { DataHeader, DataSource, Layout, Switch, Tab, Tabset, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutHandlingRequirements extends Layout {
    dataheaderHandlingRequirement: DataHeader;
    sourceGlAssignment: DataSource;
    sourceHandlingRequirement: DataSource;
    switchDate: Switch;
    switchDateTime: Switch;
    switchDecimal: Switch;
    switchIncludeonBOL: Switch;
    switchInteger: Switch;
    switchText: Switch;
    switchTime: Switch;
    tabAdditionalInformation: Tab;
    tabGeneral: Tab;
    tabSequence: Tab;
    tabset1: Tabset;
    textboxDateTimeTitle: Textbox;
    textboxDateTitle: Textbox;
    textboxDecimalTitle: Textbox;
    textboxGlaUid: Textbox;
    textboxHdrDescription: Textbox;
    textboxHdrId: Textbox;
    textboxHdrOrder: Textbox;
    textboxHdrTitle: Textbox;
    textboxHdrWebOrder: Textbox;
    textboxIntegerTitle: Textbox;
    textboxTextTitle: Textbox;
    textboxTimeTitle: Textbox;
}
