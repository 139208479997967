import { getThemeColor, makeStyles } from "@mcleod/core";

export const LabelStyles = makeStyles("lbl", () => {
    return {
        base: { display: "flex", alignItems: "center", letterSpacing: "0.01071em", whiteSpace: "pre-wrap", overflow: "hidden", textOverflow: "ellipsis" },
        noSelect: { WebkitTouchCallout: "none", WebkitUserSelect: "none", KhtmlUserSelect: "none", MozUserSelect: "none", MsUserSelect: "none", userSelect: "none" },
        designerEmpty: { border: "1px dotted #999", minWidth: 24, minHeight: 32 },
        link: { color: getThemeColor("primary") },
        linkHover: { color: getThemeColor("primary"), textDecoration: "underline", textDecorationColor: getThemeColor("primary") },
        negativeCurrency: { color: getThemeColor("error") }
    }
});
