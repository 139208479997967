import { Checkbox, Label, Layout, Panel, Snackbar, } from "@mcleod/components";
import { getLogger } from "@mcleod/core";

const log = getLogger("component-demo/dialogs/DemoSnackbarDialog");

export class DemoSnackbarDialog extends Layout {
    getDemoPath() {
        return ["Dialogs", "Snackbar"];
    }

    buttonShowSnackbarOnClicked() {
        Snackbar.showSnackbar("This is a basic snackbar that displays text by providing only a string value.");
    }

    buttonShowWarningSnackbarOnClicked() {
        Snackbar.showWarningSnackbar("This is a pre-formatted warning snackbar.  It's really orange.");
    }

    buttonShowDownloadSnackbarOnClicked() {
        Snackbar.showDownloadSnackbar("My Download", "This is a pre-formatted download snackbar.  It lets you provide a title and a message, and includes a spinner.");
    }

    buttonShowAdvSnackbarOnClicked() {
        const panel = new Panel();
        panel.add(new Label({ caption: "This is a fancy snackbar.  It can have any number of components.", color: "primary" }));
        panel.add(new Checkbox({ caption: "Like a checkbox" }));
        Snackbar.showSnackbar(panel, { persist: true });
    }

    buttonShowSnackbarUserDismissedOnClicked() {
        Snackbar.showSnackbar("This is an example of a snackbar set to persist.  It will stay in view until the user dismisses it.", { persist: true });
    }

    buttonShowSnackbarAutoDismissedDefaultOnClicked() {
        Snackbar.showSnackbar("This is an example of a snackbar set to disappar after the default number of seconds.  This is the default behavior for all snackbars.");
    }

    buttonShowSnackbarAutoDismissedOnClicked() {
        Snackbar.showSnackbar("This is an example of a snackbar set to disappar after a configurable number of milliseconds.", { millisUntilDimissal: 15000 });
    }

    buttonShowSnackbarNoDuplicatesOnClicked() {
        Snackbar.showSnackbar("This is an example of a snackbar that can only appear once at any given time.", { id: "sampleID", persist: true });
    }

    buttonShowSnackbarOnDismissOnClicked() {
        Snackbar.showSnackbar("This is an example of a snackbar that invokes the onDismiss callback function when it is closed.", { onDismiss: () => log.info("do something amazing"), persist: true });
    }
}
