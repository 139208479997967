import { DataSource, Label, Layout, Table } from "@mcleod/components";

export abstract class AutogenLayoutMovementOffers extends Layout {
    labelOfferHistory: Label;
    labelOfferHistoryCount: Label;
    sourceCarrierInsCtrl: DataSource;
    sourceCarrierOffer: DataSource;
    sourceMovement: DataSource;
    tableOffers: Table;
}
