import { DataHeader, DataSource, HorizontalSpacer, Layout, Panel, SearchButton, Table, Textbox, } from "@mcleod/components";

export abstract class AutogenLayoutUnbilledOrders extends Layout {
    dataheader1: DataHeader;
    horizontalspacer1: HorizontalSpacer;
    panel1: Panel;
    searchbuttonSearch: SearchButton;
    sourceExpandedOrder: DataSource;
    sourceOrders: DataSource;
    tableUnbilledOrders: Table;
    textboxDaysBack: Textbox;
}
