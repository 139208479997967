import { Label, Panel } from "@mcleod/components";
import { HorizontalAlignment } from "@mcleod/core";
import { ModelDesigner } from "./ModelDesigner";
import { PanelModelProps } from "./PanelModelProps";
import { PanelModelTable } from "./PanelModelTable";

export class ModelDesignerPanel extends Panel {
    rootPanel: PanelModelTable;
    panelModelProps: PanelModelProps;
    labelTable: Label;
    _def: any;
    designer: ModelDesigner;

    constructor(designer: ModelDesigner) {
        super({ id: "ModelDesignerPanel", minHeight: "100%", fillHeight: true, fillRow: true, scrollX: true, padding: 0, paddingTop: 12, paddingBottom: 24, borderRadius: 12, borderTopLeftRadius: 0, backgroundColor: "#E0EEEE", align: HorizontalAlignment.CENTER });
        this.designer = designer;
        this.rootPanel = new PanelModelTable(this.designer);
        this.labelTable = new Label({ caption: "Tables in this model", color: "primary", fontSize: "xlarge", fontBold: true, marginBottom: 16 });
        this.panelModelProps = new PanelModelProps(this.rootPanel);
        this.add(this.panelModelProps);
        this.add(new Panel({ borderTopWidth: 1, borderTopColor: "strokeSecondary", width: 500 }));
        this.add(this.labelTable);
        this.add(this.rootPanel);
    }

    get def(): any {
        return this._def;
    }

    set def(value: any) {
        this._def = value;
        this.rootPanel.def = value;
        this.panelModelProps.def = value;
    }
}
