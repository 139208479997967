import { CircularArray } from "./CircularArray";
import { Collection } from "./Collection";

const loggers = {};

enum LogLevel {
    DEBUG = "DEBUG", INFO = "INFO", ERROR = "ERROR"
}

let buffer = new CircularArray(0);
const cssRegex = /%c/g;
const modelRegex = /{[^{}]+}/;

export class LogConfig {
    private static singleton: LogConfig = {
        defaultLogLevel: LogLevel.INFO,
        logFormat: null,
        bufferSize: 0,
        logLevels: {},
        enableConsoleLog: true
    }

    defaultLogLevel: LogLevel.INFO;
    bufferSize: number;
    logFormat: string;
    logLevels: Collection<LogLevel>;
    enableConsoleLog: boolean;

    public static get(): LogConfig {
        return LogConfig.singleton;
    }

    public static set(value: LogConfig) {
        LogConfig.singleton = value;
    }
}

const logger = getLogger("core.Logger");

interface DateTimeFormatOptions extends Intl.DateTimeFormatOptions {
    fractionalSecondDigits: 0 | 1 | 2 | 3;
}
const f: DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
    fractionalSecondDigits: 3
}
const dtf = new Intl.DateTimeFormat("en-US", f);

export interface ModuleLogger {
    name: string;
    isDebugEnabled: () => boolean;
    debug: (...args: any) => void;
    info: (...args: any) => void;
    error: (...args: any) => void;
}

function getDateTimeStringWithMillis(date: Date): string {
    // fractional doesn't seem to be working, and would prefer in format of YYYY-MM-dd HH:mm:ss.sss
    // need to drop dtf (who comes up with these variable names???) and use date-fns
    return dtf.format(date);
}

export function setLogConfig(value: LogConfig) {
    if (value != null) {
        LogConfig.set(value);
        buffer = new CircularArray(value.bufferSize);
        for (const name in loggers)
            updateLogger(loggers[name], name);
        logger.debug("New log config", value);
    }
}

export function getLogger(name: string): ModuleLogger {
    let result = loggers[name];
    if (result == null) {
        result = {};
        updateLogger(result, name);
        loggers[name] = result;
    }
    return result;
}

function updateLogger(logger: ModuleLogger, name: string) {
    let logLevel = LogConfig.get().logLevels[name];
    if (logLevel == null)
        logLevel = LogConfig.get().defaultLogLevel;
    logger.name = name;
    logger.isDebugEnabled = () => logLevel === LogLevel.DEBUG;
    logger.debug = noop;
    logger.info = noop;
    if (logLevel === LogLevel.DEBUG)
        logger.debug = (...args) => log(LogLevel.DEBUG, logger, ...args);
    if (logLevel === LogLevel.DEBUG || logLevel === LogLevel.INFO)
        logger.info = (...args) => log(LogLevel.INFO, logger, ...args);
    logger.error = (...args) => log(LogLevel.ERROR, logger, ...args);
}

function noop() {
}

export function getLog(): string {
    return buffer.getFlattened().join("\n");
}

function log(level: LogLevel, logger: ModuleLogger, ...rest) {
    const config = LogConfig.get();
    if (rest.length === 1 && typeof rest[0] === "function")
        rest = rest[0]();
    if (rest.length > 0) {
        if (config.logFormat == null)
            rest[0] = getDateTimeStringWithMillis(new Date()) + " " + level + " [" + logger.name + "] " + rest[0];
        else
            applyLogFormat(level, rest);
    }
    if (config.enableConsoleLog)
        console.log(...rest);
    if (config.bufferSize > 0)
        buffer.push(rest[0]); // need to push args as well but don't feel like doing that right now
}

function applyLogFormat(level: LogLevel, args: string[]): void {
    const extraParams: string[] = [];
    const replacements = {
        level: level,
        timestamp: getDateTimeStringWithMillis(new Date()),
        name: logger.name,
        message: args[0]
    }
    const logFormat = LogConfig.get().logFormat
    addCSSArgs(logFormat, extraParams);
    args.splice(1, 0, ...extraParams);
    args[0] = getLogFormat(logFormat, replacements);
}

function getLogFormat(format: string, replacements: Collection<string>): string {
    if (format == null)
        return "";
    if (format.indexOf("{") < 0 || format.indexOf("}") < 0)
        return format;
    let result = format;
    let match = modelRegex.exec(result);
    while (match != null) {
        const replacementKey = match[0].substring(1, match[0].length - 1);
        const replacement = replacements == null ? null : replacements[replacementKey];
        const evalValue = replacement || "";
        result = result.substring(0, match.index) + evalValue + result.substring(match.index + match[0].length);
        match = modelRegex.exec(result);
    }
    return result;
}

function addCSSArgs(format: string, args: string[]) {
    const count = format.match(cssRegex).length;
    for (let index = 1; index <= count; index++) {
        const css = LogConfig.get()["logFormat.css." + index];
        if (css == null)
            console.log("Error: log configuration format specifies CSS formatting but logFormat.css." + index + " was not specified.");
        args.push(css);
    }
}
