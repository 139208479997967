import { DataSourceMode, } from "@mcleod/components";
import { ModelRow } from "@mcleod/core";
import { AutogenLayoutCommodityImage } from "./autogen/AutogenLayoutCommodityImage";

export class CommodityImage extends AutogenLayoutCommodityImage {

    override onLoad(): void | Promise<void> {

        this.attachmentCommodityImage["setAttachmentWithUI"] = (file: File) => {
            const maxSize = this.attachmentCommodityImage.maxSize;
            const fileName = file.name.toLowerCase();
            if (!fileName.endsWith(".jpg") && !fileName.endsWith(".png") && !fileName.endsWith(".gif"))
                return this.showTooltip("The file must be an image.", { shaking: true, timeout: 5000 });
            else if (maxSize != null && file.size > maxSize)
                return this.showTooltip("The file is too big to attach. The file must be smaller than " + this.attachmentCommodityImage.maxSize / (1024 * 1024) + "MB.", { shaking: true, timeout: 5000 });
            else {
                this.attachmentCommodityImage.setFile(file);
            }
        }
        this.attachmentCommodityImage["updateIfBound"] = () => {
            if (this.attachmentCommodityImage["_boundField"] != null) {
                const imageData = new Map<String, any>([
                    ["commodity_id", this.mainDataSource.owner.mainDataSource.data[0].data.id],
                    ["commodity_image", this.attachmentCommodityImage.fileBase64],
                    ["commodity_image_name", this.attachmentCommodityImage.fileName]
                ]);
                if (this.sourceCommodityImage.data.length == 0)
                    this.setupNewImage(imageData)
                let row = null;
                if (this.attachmentCommodityImage.dataSource != null && this.attachmentCommodityImage.dataSource.activeRow != null)
                    row = this.attachmentCommodityImage.dataSource.activeRow;
                else if (this.attachmentCommodityImage.boundRow != null)
                    row = this.attachmentCommodityImage.boundRow;
                if (row != null)
                    this.attachmentCommodityImage.updateBoundData(row, DataSourceMode.UPDATE);
            }
        }
    }

    private async setupNewImage(imageData: Map<String, any>) {
        const row = await this.sourceCommodityImage.createBlankRow();
        for (const key of imageData.keys()) {
            row.set(key, imageData.get(key));
        }
        this.sourceCommodityImage.addRow(new ModelRow('lme/dispatch/commodity-image', true, row.data), 0, false);
        this.sourceCommodityImage.setRowIndexWithoutUpdate(0)
        const active = this.sourceCommodityImage.activeRow
        active.set("commodity_id", active.data.commodity_id) //Setting this value to itself updates the save button
    }
}
