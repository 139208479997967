import { getAuthSettings, StringUtil } from "@mcleod/core";

export class SupportEmail {

    static doOnSupportEmailClick() {
        let mailToLink = `mailto:${getAuthSettings().pbw_support_email_address}?subject=${this.getSupportEmailSubject()}&body=${this.getSupportEmailBody()}`;
        const userSettings = getAuthSettings().user_settings;
        if (userSettings != null && userSettings.is_portal_user === true)
            mailToLink = `mailto:${getAuthSettings().support_email_address}`;
        window.location.href = mailToLink;
    }

    private static getSupportEmailSubject(): string {
        const userSettings = getAuthSettings().user_settings;
        let company = userSettings.company_scac;
        if (StringUtil.isEmptyString(company) === true)
            company = userSettings.company_name;
        return encodeURIComponent("PBW Support Request" + (StringUtil.isEmptyString(company) !== true ? (": " + company) : ""));
    }

    private static getSupportEmailBody(): string {
        //User id, login id, Name, phone, extension
        const userSettings = getAuthSettings().user_settings;
        let body = "Please tell us how we can help you by entering your message here:\n\n\n";
        body += "\nUser ID: " + userSettings.user_id;
        if (StringUtil.isEmptyString(userSettings.user_login_id) !== true && userSettings.user_id !== userSettings.user_login_id)
            body += "\nLogin ID: " + userSettings.user_login_id;
        if (StringUtil.isEmptyString(userSettings.user_name) !== true)
            body += "\nName: " + userSettings.user_name;
        if (StringUtil.isEmptyString(userSettings.user_phone) !== true)
            body += "\nPhone: " + userSettings.user_phone;
        if (StringUtil.isEmptyString(userSettings.user_extension) !== true)
            body += "\nExtension: " + userSettings.user_extension;
        body += "\n\nCurrent Screen:\n" + window.location.href + "\n"
        return encodeURIComponent(body);
    }
}
