import { ChangeEvent, DataSourceMode, TableRow, TableRowDisplayEvent, Textbox } from "@mcleod/components";
import { DisplayType, ModelSearchResult } from "@mcleod/core";
import { AutogenLayoutInstanceSettings } from "./autogen/AutogenLayoutInstanceSettings";

export class InstanceSettings extends AutogenLayoutInstanceSettings {
    override onLoad() {
        this.tabsetMain.remove(this.tabSecurityNotifications); // removing this until we are ready for users to access ut
        this.sourceInstanceSettings.search().then((result: ModelSearchResult) => {
            this.sourceInstanceSettings.mode = result.modelRows.length === 0 ? DataSourceMode.ADD : DataSourceMode.UPDATE;
            // this.sourceNotificationSettings.mode = DataSourceMode.UPDATE;
        });
    }

    /** This is an event handler for the onChange event of textboxRecipientType.  */
    textboxRecipientTypeOnChange(event: ChangeEvent) {
        this.hideShowUserFields(event.target["row"]);
    }

    /** This is an event handler for the onRowDisplay event of tableNotificationSettings.  */
    tableNotificationSettingsOnRowDisplay(event: TableRowDisplayEvent) {
        this.hideShowUserFields(event.getTableRow());
    }

    private hideShowUserFields(row: TableRow) {
        const type = row.data.get("recipient_type");
        const isUser = type === "U";
        const textRecip = row.findComponentById("textboxRecipient") as Textbox;
        if (textRecip == null)
            return;
        textRecip.visible = !isUser;
        row.findComponentById("textboxNotifyUserId").visible = isUser;
        row.findComponentById("panelUserSettings").visible = isUser;
        textRecip.displayType = type === "E" ? DisplayType.EMAIL : DisplayType.PHONE;
    }
}
