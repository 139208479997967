import { Component } from "@mcleod/components";
import { getLogger, isRunningInIDE } from "@mcleod/core";
import { AbstractUIDesigner } from "./AbstractUIDesigner";
import { DesignerTool } from "./DesignerTool";
import { DesignerTab } from "./actions/DesignerTab";

const log = getLogger("common.designer.UIDesigner");

export class UIDesigner extends AbstractUIDesigner {

    constructor(props?) {
        super({ ...props, title: "UI Designer" })
    }

    get localStorageKeys() { return { openTabs: "designer.ui.open", selectedTab: "designer.ui.selected" } }


    get designerTools(): DesignerTool[] {
        return [
            new DesignerTool(this, "Label"),
            new DesignerTool(this, "Textbox"),
            new DesignerTool(this, "Button"),
            new DesignerTool(this, "Checkbox"),
            new DesignerTool(this, "Switch"),
            new DesignerTool(this, "Table"),
            new DesignerTool(this, "Image"),
            new DesignerTool(this, "CityState"),
            new DesignerTool(this, "Splitter"),
            new DesignerTool(this, "Tree"),
            new DesignerTool(this, "Map"),
            new DesignerTool(this, "Panel"),
            new DesignerTool(this, "Stepper"),
            new DesignerTool(this, "Tabset"),
            new DesignerTool(this, "List"),
            new DesignerTool(this, "Attachment"),
            new DesignerTool(this, "Layout", { isNested: true }),
            new DesignerTool(this, "HorizontalSpacer"),
            new DesignerTool(this, "SearchButton"),
            new DesignerTool(this, "SaveButton"),
            new DesignerTool(this, "DataHeader"),
            new DesignerTool(this, "NumberEditor"),
            new DesignerTool(this, "StarRating"),
            new DesignerTool(this, "Chart"),
            new DesignerTool(this, "Location", null, "CityState")
        ];
    }

    override filterProps(props: any, selectedComponent: Component) {
        for (const key of Object.keys(props)) {
            log.debug("Evaluating property for UI Designer visibility: %o for component", props[key]);
            if (props[key].visibleInDesigner === false) {
                log.debug("Removing property from UI Designer: %o", props[key]);
                delete props[key];
            }
        }
    }

    override disablePropertyEditors(prop: any, editorComponents: Component[], selectedComponent: Component): void {
        log.debug("Evaluating property for UI Designer editability: %o for component %o", prop, selectedComponent);
        const isEditable = prop.editableInDesigner == null ? true : prop.editableInDesigner;
        if (isEditable !== true) {
            log.debug("Disabling edit of property within UI Designer: %o", prop);
            for (const component of editorComponents) {
                component.enabled = false;
            }
        }
    }

    override updateToolbarButtonVisibility(tab: DesignerTab) {
        if (isRunningInIDE() === true) {
            this.buttonManageVersions.visible = false;
            this.buttonSaveNewVersion.visible = false;
        }
        else
            super.updateToolbarButtonVisibility(tab);
    }
}
