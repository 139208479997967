import { Button, Checkbox, Component, DataSource, Label, Panel, Textbox } from "@mcleod/components";
import { DateUtil, DisplayType, StringUtil, getLogger } from "@mcleod/core";
import { ClearStop } from "../../dispatch/src/ClearStop";
import { AutogenLayoutCallinScript } from "./autogen/AutogenLayoutCallinScript";

const log = getLogger("lme.powerbroker.CallinScript");

export interface FieldInfo {
    allow_nulls?: string,
    browse_file?: string,
    caption?: string,
    company_id: string,
    custom_field?: string,
    data_length?: number,
    data_type?: string,
    decimal_precision?: number,
    default_value?: string,
    display_label?: string,
    domain_name?: string,
    field_name: string,
    help_line?: string,
    is_thumb_multi_comp?: string,
    long_desc?: string,
    master_field?: string,
    master_table?: string,
    ordinal_position?: number,
    primary_key_seq?: number,
    required?: string,
    table_name: string,
    upshifted?: string,
    v6_field_name?: string,
    v6_table_name?: string,
    value_constraints?: string,
    workorder?: number
}

export interface CallinScriptTemplateDetail {
    callin_script_template_id?: string,
    company_id: string,
    detail_type?: string,
    display_stored_value?: string,
    display_total_pay?: string,
    field_info: FieldInfo,
    field_name?: string,
    id: string,
    include_input_field?: string,
    is_required?: string,
    save_table?: string,
    save_field?: string,
    prompt?: string,
    save_data_field?: string,
    sequence?: number,
    stop_to_display?: string,
    table_name?: string,
    update_field?: string,
    stored_value?: any,
    stop_id?: string
}

export class CallinScript extends AutogenLayoutCallinScript {
    public clearStop: ClearStop;
    public compList: Component[] = [];
    private bubbleProps = {
        borderRadius: 60,
        padding: 8,
        backgroundColor: "subtle.lightest"
    };

    override onLoad() {
        this.visible = false;
    }

    buildScript() {
        const scriptDetails = this.sourceCallin?.activeRow?.get("callin_script_details");
        if (scriptDetails != null) {
            this.visible = scriptDetails?.length > 0;
            scriptDetails?.forEach(detail => this.add(this.getDetailComponent(detail)));
        }
    }

    getDetailComponent(detail: CallinScriptTemplateDetail): Component {
        let comp: Component;

        if (this.getBoolean(detail.include_input_field, false)) {
            if (detail.field_info.domain_name && detail.field_info.domain_name === "yes_no") {
                comp = this.getCheckbox(detail);
            } else {
                comp = this.getTextbox(detail);
            }
        } else {
            comp = this.getLabel(detail);
        }

        return comp;
    }

    getCheckbox(detail: CallinScriptTemplateDetail): Panel {
        const container = new Panel();
        const check = new Checkbox({ caption: detail.prompt, required: this.getBoolean(detail.is_required, false) });

        if (this.getBoolean(detail.is_required, false))
            check.allowIndeterminate = false;

        this.addToCompList(detail, check);
        container.add(check);

        return container;
    }

    getTextbox(detail: CallinScriptTemplateDetail): Panel {
        const container = new Panel();
        const lblCaption = new Label({ ...this.bubbleProps, caption: detail.prompt, rowBreak: false, marginRight: 8 });
        const textbox = new Textbox({ required: this.getBoolean(detail.is_required, false), captionVisible: false });

        if (detail.field_info)
            textbox.displayType = this.getDisplayType(detail.field_info);

        if (detail.update_field) {
            lblCaption.backgroundColor = "primary";
            lblCaption.color = "primary.reverse";
        }

        this.setDataSource(detail, textbox)
        this.addToCompList(detail, textbox);
        container.add(lblCaption, textbox);

        if (detail.stored_value) {
            textbox.rowBreak = false;
            const caption = textbox.displayType === DisplayType.DATETIME
                ? DateUtil.formatDateTime(DateUtil.parseDateWithKeywords(detail.stored_value, true, true))
                : detail.stored_value;

            if (detail.detail_type === "T" && detail.stored_value) {
                const lblTempRang = new Label({
                    caption,
                    fontSize: "small",
                    padding: 4
                })
                container.add(lblTempRang);
            } else {
                const btnStored = new Button({
                    caption,
                    borderWidth: 0,
                    color: "primary",
                    fontSize: "small",
                    padding: 4
                });

                btnStored.addClickListener(event => textbox.text = detail.stored_value);
                container.add(btnStored);
            }
        }

        return container;
    }

    setDataSource(detail: CallinScriptTemplateDetail, comp: Component) {
        let ds: DataSource;
        if ("movement" === detail.save_table) {
            ds = this.sourceDispatch
        } else if ("callin" === detail.save_table && "remark" !== detail.save_field) {
            ds = this.sourceCallin;
        } else if (
            ["O", "D", "C", "N"].includes(detail.save_table)
            && detail.stop_id
            && detail.stop_id === this.sourceCallinStop?.activeRow?.get("id")
        ) {
            ds = this.sourceCallinStop;
        } else {
            return;
        }

        comp.field = detail.save_field
        comp.dataSource = ds;

        ds.getComponentsByField(detail.save_field).forEach(relatedComp => {
            comp.shareListenersFrom(relatedComp);
        });
    }

    getLabel(detail: CallinScriptTemplateDetail): Panel {
        const container = new Panel();
        const label = new Label(this.bubbleProps);
        const storedValue = this.getBoolean(detail.display_stored_value, false) ? detail.stored_value ?? "" : "";

        label.caption = `${detail.prompt}${storedValue ? " " + storedValue : ""}`;
        container.add(label);

        return container;
    }

    addToCompList(detail: CallinScriptTemplateDetail, comp: Component) {
        // These will be passed back to the server to update the table & field
        if (!comp.dataSource && detail.save_table && detail.save_field) {
            comp["detail"] = detail;
            this.compList.push(comp);
        }
    }

    getBoolean(value: string | undefined, valueIfNull: boolean): boolean {
        return (value == null) ? valueIfNull : StringUtil.equalsIgnoreCase(value, "Y");
    }

    isEven(value: number): boolean {
        return value % 2 == 0;
    }

    getDisplayType(fieldInfo: FieldInfo): DisplayType {
        // first check the domain
        if (fieldInfo?.domain_name) {
            switch (fieldInfo.domain_name) {
                case "location_id": return DisplayType.LOCATION;
                case "city_id": return DisplayType.CITY;
                case "state_id": return DisplayType.STATE;
                case "phone_number": return DisplayType.PHONE;
                case "email_address": return DisplayType.EMAIL;
                case "money": return DisplayType.CURRENCY;
                case "weight": return DisplayType.WEIGHT;
                case "distance": return DisplayType.DISTANCE;
            }
        }

        // if nothing matched on the domain, check the data_type
        if (fieldInfo?.data_type) {
            switch (fieldInfo.data_type) {
                case "date": return DisplayType.DATE;
                case "time": return DisplayType.TIME;
                case "datetime": return DisplayType.DATETIME;
                case "double precision":
                case "long":
                case "decimal": return DisplayType.DECIMAL;
                case "integer": return DisplayType.INTEGER;
                case "money": return DisplayType.CURRENCY;
            }
        }

        // if nothing matched, return a string type
        return DisplayType.STRING;
    }

    getPostData(): CallinScriptPostData | undefined {
        const remarks: string[] = [];
        const tableData: TableData[] = [];

        this.compList.forEach(comp => {
            const detail: CallinScriptTemplateDetail = comp["detail"];
            const value = comp["text"];
            if (!value || (typeof value === "string" && StringUtil.isEmptyString(value))) return;

            if (StringUtil.equalsIgnoreCase("remark", detail.save_field)) {
                remarks.push(`${detail.prompt} - ${value}`);
            } else {
                tableData.push({
                    save_table: detail.save_table,
                    save_field: detail.save_field,
                    value
                })
            }
        });

        if (remarks.length == 0 && tableData.length <= 0)
            return undefined;

        log.debug("CallinScript.getPostData:", { remarks, tableData });
        return {
            remarks,
            table_data: tableData
        }
    }
}
export interface CallinScriptPostData {
    remarks?: string[],
    table_data?: TableData[]
}

interface TableData {
    save_table?: string,
    save_field?: string,
    value?: any
}
