import { CallinScript } from "../../../powerbroker/src/CallinScript";
import { CityState, DataSource, Label, Layout, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutCallinEntry extends Layout {
	citystateCurrentLocation: CityState;
	labelCallWarning: Label;
	labelCallinDetails: Label;
	labelDispatchEmail: Label;
	labelDispatchPhone: Label;
	labelDriverInformation: Label;
	labelLabel6: Label;
	labelLabel7: Label;
	labelLabel8: Label;
	labelLabel9: Label;
	layoutCallinScript: CallinScript;
	panel2: Panel;
	panelCallinData: Panel;
	panelCallinEntry: Panel;
	panelCallinWarning: Panel;
	panelDispatcher: Panel;
	panelDriverTwo: Panel;
	sourceCallin: DataSource;
	sourceCallinStop: DataSource;
	sourceDispatch: DataSource;
	textboxCallDateTime: Textbox;
	textboxCurrentLocationTime: Textbox;
	textboxDispatcher: Textbox;
	textboxInitiatedType: Textbox;
	textboxNextSchedCall: Textbox;
	textboxOverrideDriver2Nm: Textbox;
	textboxOverrideDriverNm: Textbox;
	textboxRemark: Textbox;
	textboxSetpointTemp: Textbox;
	textboxTemperature: Textbox;
}
