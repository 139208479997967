import { ClickEvent, DataDisplayEvent, Label, TableRow, TableRowDisplayEvent } from "@mcleod/components";
import { ModelRow, Navigation } from "@mcleod/core";
import { makeTooltipCallbackFunction } from "./EDITenderProfileMakeQuickInfo";
import { TenderListUtil } from "./TenderListUtil";
import { AutogenLayoutInboundTenderList } from "./autogen/AutogenLayoutInboundTenderList";

export class InboundTenderList extends AutogenLayoutInboundTenderList {
    private _searchTendersURL = "lme/datafusion/TenderList?id=";
    private _viewTenderURL = "lme/datafusion/ViewEdiOrder?mode=update&key=";

    setErrorsLabelColor(event) {
        TenderListUtil.setErrorsLabelColor(event, true);
    }

    setWarningsLabelColor(event) {
        TenderListUtil.setErrorsLabelColor(event, false);
    }

    buttonAdditionalInfoOnClick() {
        const orderId = this.mainDataSource.data[0]?.get("order_id") as string;
        Navigation.navigateTo(this.searchTendersURL + orderId, { newTab: true });
    }

    tableInboundTendersOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        tableRow.addDblClickListener((event: ClickEvent) => {
            const tableRow = event.target as TableRow;
            const modelRow = tableRow?.data as ModelRow;
            const tenderId = modelRow?.get("id");
            if (tenderId) Navigation.navigateTo(this.viewTenderURL + tenderId, { newTab: true });
        });
    }

    displayPartnerName(event: DataDisplayEvent) {
        const partnerNameLabel = <Label>event.target;
        partnerNameLabel.tooltipCallback = makeTooltipCallbackFunction(partnerNameLabel.boundRow?.get("id"), partnerNameLabel);
    }

    get searchTendersURL() {
        return this._searchTendersURL;
    }

    get viewTenderURL() {
        return this._viewTenderURL;
    }
}