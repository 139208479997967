/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelCarrierRateHistory.ts            *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/powerbroker/carrier-rate-history";

export class AutogenModelCarrierRateHistory<RowType extends ModelRow> extends Endpoint<RowType, RowCarrierRateHistoryFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowCarrierRateHistory extends ModelRow<RowCarrierRateHistoryFields> {
    constructor() {
        super(AutogenUserSavedSearchEndpoint);
    }
}

export interface RowCarrierRateHistoryFields {
    carrier_city: string;
    carrier_name: string;
    carrier_state: string;
    carrier_zip: string;
    dest_full_address: string;
    equipment: string;
    extra_pay: number;
    least_paid: number;
    margin: number;
    most_paid: number;
    origin_full_address: string;
    qualification: string;
    total_pay: number;
}

