import { Currency, CurrencyUtil } from "@mcleod/core";
import { AutogenLayoutLocationDetentionHistory } from "./autogen/AutogenLayoutLocationDetentionHistory";

export class LocationDetentionHistory extends AutogenLayoutLocationDetentionHistory {

    override onLoad() {
        this.sourceLocationDetentionHistory.addAfterExecutionListener((event) => {
            this.setSummaryText(event);
        })
    }

    setSummaryText(event: any) {
        if ("search" == event.getAction()) {

            const getCurrencyText = (currency: Currency) => currency ? CurrencyUtil.formatCurrency(currency) : null;

            this.textboxSummaryCount.text = this.sourceLocationDetentionHistory?.summaryData?.get("summary_count", 0);
            this.textboxSummaryChargeCount.text = this.sourceLocationDetentionHistory?.summaryData?.get("summary_charge_count", 0);
            this.textboxSummaryTotalCharges.text = getCurrencyText(this.sourceLocationDetentionHistory?.summaryData?.get("summary_charge_total") || null);
            this.textboxSummaryAverageCharge.text = getCurrencyText(this.sourceLocationDetentionHistory?.summaryData?.get("summary_charge_average") || null);
        }
    }
}

