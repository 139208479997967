import { DOMUtil, getLogger } from "@mcleod/core";
import { Button, ButtonVariant, Cursor, Panel, deserializeComponents, serializeComponents } from "../..";
import { Component } from "../../base/Component";
import { ComponentTypes } from "../../base/ComponentTypes";
import { Container } from "../../base/Container";
import { DomEvent, DomEventListener } from "../../events/DomEvent";
import { ComponentCreationCallback } from "../../serializer/ComponentDeserializer";
import { SplitterOrientation, SplitterPropDefinitions, SplitterProps } from "./SplitterProps";
import { splitterStyles } from "./SplitterStyles";

const log = getLogger("components.Splitter");
/**
 * The Splitter component is a container that can accept two child components.  It provides a
 * user-draggable divider between those components that lets the user resize the two components
 * relative to each other (i.e. increasing the size of one component decreases the size of the other).
 *
 * Set the orientation property to either SplitterOrientation.horizontal (the default) to specify that the two child
 * child components should be side-by-side (dragging the divider changes the width of the components)
 * or SplitterOrientation.vertical to specify that the components should be stacked one on
 * top of the other (dragging the divider changes the height of the components).
 */
export class Splitter extends Container implements SplitterProps {
    _firstComponent: Component;
    _secondComponent: Component;
    private _firstElement: HTMLElement;
    private _secondElement: HTMLElement;
    private _splitDivider: Panel;
    private _orientation: SplitterOrientation = SplitterOrientation.horizontal;
    private _position: number | string;
    private _dragListener: DomEventListener;
    private _mouseUpListener: DomEventListener;
    private _expandButtonsVisible: boolean;
    public buttonExpandFirst: Button;
    public buttonExpandSecond: Button;
    private _buttonContainer: Panel;
    private _buttonRelative: HTMLElement;
    /**
     * _expandFirstComponent is true is the first component, false, if the secondComponent is expanded, or null is neither component is expanded
     */
    private _expandedFirstComponent: boolean = null;
    private _positionBeforeExpand: string | number;

    constructor(props?: Partial<SplitterProps>) {
        super("div", props);
        this.className = splitterStyles.splitBase;
        const buttonProps = { themeKey: "splitter.buttons", variant: ButtonVariant.round, rowBreak: false };
        this.buttonExpandFirst = new Button(buttonProps);
        this.buttonExpandFirst.addClickListener(event => this.expandComponent(true, event));
        this.buttonExpandSecond = new Button(buttonProps);
        this.buttonExpandSecond.addClickListener(event => this.expandComponent(false, event));
        this._buttonRelative = document.createElement("div");
        this._buttonRelative.style.position = "relative";
        this._buttonContainer = new Panel({ themeKey: "splitter.buttonContainer", wrap: false, style: { position: "absolute" } });
        this._firstElement = document.createElement("div");
        this._firstElement.id = (props?.id || "split") + "-firstElement";
        this._firstElement.classList.add(splitterStyles.splitContainer);
        this._secondElement = document.createElement("div");
        this._secondElement.id = (props?.id || "split") + "-secondElement";
        this._secondElement.classList.add(splitterStyles.splitContainer);
        this._secondElement.style.flex = "1";
        this._splitDivider = new Panel({ themeKey: "splitter.divider" });//document.createElement("div");
        this._splitDivider.className = splitterStyles.splitDivider;
        this._splitDivider.addMouseDownListener(event => this.dividerMouseDown(event));
        this._splitDivider.id = (props?.id || "split") + "-divider";
        this._buttonContainer.add(this.buttonExpandFirst);
        this._buttonContainer.add(this.buttonExpandSecond);
        this._buttonRelative.appendChild(this._buttonContainer._element)
        this._element.appendChild(this._firstElement);
        this._element.appendChild(this._splitDivider.element);
        this._element.appendChild(this._secondElement);
        this.setProps(props);
        this.addMountListener(() => this._syncExpandButtons());
    }

    override setProps(props: Partial<SplitterProps>) {
        super.setProps(props);
    }

    override add(comp: Component): void {
        if (this.firstComponent == null)
            this.firstComponent = comp;
        else if (this.secondComponent == null)
            this.secondComponent = comp;
        else
            throw new Error("A Splitter can only have two Components.");
    }

    dividerMouseDown(event: MouseEvent) {
        if (event["domEvent"] != null)
            event = event["domEvent"];
        log.debug(() => ["Mouse down", event]);
        if (event.target === this._splitDivider._element) {
            this._dragListener = event => this.dividerDrag(event as MouseEvent);
            this._mouseUpListener = event => this.dividerMouseUp(event as MouseEvent);
            window.addEventListener("mousemove", this._dragListener, false);
            window.addEventListener("mouseup", this._mouseUpListener, false);
        }
    }

    dividerDrag(event: MouseEvent) {
        if (event.buttons !== 1) {
            this.dividerMouseUp(event);
            return;
        }
        event.preventDefault();
        const bounds = this._firstElement.getBoundingClientRect();
        if (this.orientation === SplitterOrientation.vertical)
            this._internalSetPosition(event.clientY - bounds.y, event);
        else
            this._internalSetPosition(event.clientX - bounds.x, event);
        this._expandedFirstComponent = null;
        this._positionBeforeExpand = null;
        this._syncExpandButtons();
        log.debug(() => ["Drag bounds", bounds, "Event", event]);
    }

    dividerMouseUp(event: MouseEvent) {
        log.debug(() => ["Mouse up", event]);
        window.removeEventListener("mousemove", this._dragListener, false);
        window.removeEventListener("mouseup", this._mouseUpListener, false);
    }

    private _syncExpandButtons() {
        const show = this.expandButtonsVisible;
        const contains = this._splitDivider._element.contains(this._buttonRelative);
        if (show && !contains)
            this._splitDivider._element.appendChild(this._buttonRelative);
        else if (!show && contains)
            this._splitDivider._element.removeChild(this._buttonContainer._element);
        const horiz = this.orientation === SplitterOrientation.horizontal;
        this.buttonExpandFirst.imageRotation = horiz ? 270 : 0;
        this.buttonExpandSecond.imageRotation = horiz ? 90 : 180;
        this._splitDivider.style.flexDirection = horiz ? "column" : "row";
        this._buttonContainer.visible = this.expandButtonsVisible;
        const containerBounds = this._buttonContainer._element.getBoundingClientRect();
        const parentBounds = this._splitDivider._element.parentElement?.getBoundingClientRect();

        const dividerPos = this.positionAsPixels;
        let containerLeft = 0;
        let containerTop = 0;
        if (horiz && dividerPos + containerBounds.width > parentBounds.width)
            containerLeft = parentBounds.width - (dividerPos + containerBounds.width);

        if (!horiz && dividerPos + containerBounds.height > parentBounds.height)
            containerTop = parentBounds.height - (dividerPos + containerBounds.height);

        this._buttonContainer.left = horiz ? containerLeft : containerBounds.width / -2; // surely CSS can be used to center the relative container on the divider?
        this._buttonContainer.top = horiz ? containerBounds.height / -2 : containerTop;

        this.buttonExpandFirst.rowBreak = horiz;
        if (this._expandedFirstComponent == null) {
            this.buttonExpandFirst.tooltip = horiz ? "Expand the section to the left" : "Expand the section above";
            this.buttonExpandSecond.tooltip = horiz ? "Expand the section to the right" : "Expand the section below";
            this.buttonExpandFirst.visible = this.expandButtonsVisible;
            this.buttonExpandSecond.visible = this.expandButtonsVisible;
        } else {
            this.buttonExpandFirst.tooltip = "Restore both sections";
            this.buttonExpandSecond.tooltip = "Restore both sections";
            this.buttonExpandFirst.visible = this.expandButtonsVisible && !this._expandedFirstComponent;
            this.buttonExpandSecond.visible = this.expandButtonsVisible && this._expandedFirstComponent;
        }
        const buttonSpacing = this.buttonExpandFirst.visible && this.buttonExpandSecond.visible ? 4 : 0;
        this.buttonExpandSecond.marginLeft = horiz ? 0 : buttonSpacing;
        this.buttonExpandSecond.marginTop = horiz ? buttonSpacing : 0;
    }

    get components() {
        const result = [];
        if (this.firstComponent != null)
            result.push(this.firstComponent);
        if (this.secondComponent != null)
            result.push(this.secondComponent);
        return result;
    }

    set components(value: Component[]) {
        for (const comp of value)
            this.add(comp);
    }

    get firstComponent(): Component {
        return this._firstComponent;
    }

    set firstComponent(value: Component) {
        this._firstComponent = value;
        this._firstElement.innerHTML = "";
        if (value != null)
            this._firstElement.appendChild(value._element);
    }

    get secondComponent(): Component {
        return this._secondComponent;
    }

    set secondComponent(value: Component) {
        this._secondComponent = value;
        this._secondElement.innerHTML = "";
        if (value != null)
            this._secondElement.appendChild(value._element);
    }

    get orientation() {
        return this._orientation;
    }

    set orientation(value: SplitterOrientation) {
        this._orientation = value;
        if (value === SplitterOrientation.vertical) {
            this.style.flexDirection = "column";
            this._splitDivider.style.cursor = Cursor.NS_RESIZE;
        } else { // fall back to style class by blanking everything out
            this.style.flexDirection = "";
            this._splitDivider.style.cursor = "";
        }
        this.position = this.position;
        this._syncExpandButtons();
    }

    get expandButtonsVisible(): boolean {
        return this._expandButtonsVisible == null ? true : this._expandButtonsVisible;
    }

    set expandButtonsVisible(value: boolean) {
        this._expandButtonsVisible = value;
        this._syncDatabinding();
    }

    get valueAsString(): string {
        return this.positionAsPercent;
    }

    set valueAsString(value: string) {
        this.position = value;
    }

    get positionAsPixels(): number {
        let result = this.position;
        if (typeof result === "string") {
            const parentBounds = this._splitDivider._element.parentElement.getBoundingClientRect();
            if (parentBounds == null)
                return 140;
            if (result.startsWith("calc"))
                result = "100%";
            if (!result.endsWith("%"))
                result = "50%";
            result = result.substring(0, result.length - 1);
            const percent = parseInt(result);
            result = Math.floor(parentBounds.height * (percent / 100));
        }
        return result;
    }

    get positionAsPercent(): string {
        let result = this.position;
        if (typeof result === "number") {
            const parentBounds = this._splitDivider._element.parentElement.getBoundingClientRect();
            if (parentBounds == null)
                result = "50%";
            else {
                const percent = Math.floor((result / parentBounds.height) * 100);
                result = percent + "%";
            }
        }
        return result;
    }

    get position() {
        return this._position == null ? "50%" : this._position;
    }

    set position(value: number | string) {
        this._internalSetPosition(value);
    }

    _internalSetPosition(value: number | string, domEvent?: DomEvent) {
        const dividerBounds = this._splitDivider._element.getBoundingClientRect();
        const parentBounds = this._splitDivider._element.parentElement?.getBoundingClientRect();
        if (typeof value === "number" && parentBounds?.width > 0 && parentBounds.height > 0) {
            if (this.orientation === SplitterOrientation.vertical)
                value = Math.min(value, parentBounds.height - dividerBounds.height);
            else
                value = Math.min(value, parentBounds.width - dividerBounds.width);
            value = Math.max(value, 0);
        }
        this._position = value;
        if (domEvent != null)
            this.storeUserChoiceIfRemembered();
        const size = DOMUtil.getSizeSpecifier(value);
        log.debug(() => ["set position", value, size]);
        this._firstElement.style.flex = "0 0 " + size;
        if (this.orientation === SplitterOrientation.vertical) {
            this._firstElement.style.maxHeight = size;
            this._firstElement.style.maxWidth = "";
        }
        else {
            this._firstElement.style.maxHeight = "";
            this._firstElement.style.maxWidth = size;
        }
    }

    get _designer() {
        return super._designer;
    }

    set _designer(value) {
        super._designer = value;
        if (value != null && value.addDesignerContainerProperties != null) {
            value.addDesignerContainerProperties(this, 120, 120, null, () => false);
            this.position = this.position;
        }
    }

    override removeAt(index: number, domEvent?: DomEvent) {
        if (index === 0)
            this.firstComponent = null;
        else if (index === 1)
            this.secondComponent = null;
        else
            throw new Error("Can only remove elements 0 or 1 from a Splitter.");
    }

    expandComponent(firstComponent = true, event?: DomEvent) {
        if (firstComponent === this._expandedFirstComponent)
            return;
        const computed = getComputedStyle(this._splitDivider._element);
        const dividerSize = this.orientation === SplitterOrientation.horizontal ? computed.width : computed.height;
        if (this._positionBeforeExpand == null) {
            this._positionBeforeExpand = this.position;
            if (firstComponent)
                this._internalSetPosition("calc(100% - " + dividerSize + ")", event);
            else
                this._internalSetPosition(0, event);
            this._expandedFirstComponent = firstComponent;
        } else {
            this.position = this._positionBeforeExpand;
            this._positionBeforeExpand = null;
            this._expandedFirstComponent = null;
        }
        this._syncExpandButtons();
    }

    _serializeNonProps(): string {
        const comps = [];
        if (this.firstComponent != null)
            comps.push(this.firstComponent);
        if (this.secondComponent != null)
            comps.push(this.secondComponent);
        if (comps.length > 0)
            return "\"components\": " + serializeComponents(comps, null,) + ",\n";
        return "";
    }

    _deserializeSpecialProps(componentOwner, compDef, defaultPropValues, dataSources, componentCreationCallback: ComponentCreationCallback): string[] {
        const compSpecial = super._deserializeSpecialProps(componentOwner, compDef, defaultPropValues, dataSources, componentCreationCallback);
        if (compDef.components != null && compDef.components.length > 0) {
            const children = deserializeComponents(componentOwner, compDef.components, this._designer, defaultPropValues, dataSources, componentCreationCallback);
            for (let i = 0; i < children.length; i++)
                this.add(children[i]);
        }
        return [...compSpecial, "components"];
    }

    override getPropertyDefinitions() {
        return SplitterPropDefinitions.getDefinitions();
    }

    override get serializationName() {
        return "splitter";
    }

    override getBasicValue(): any {
        return this.position;
    }

    public override discoverIncludedComponents(): Component[] {
        //return null here so that the components that make up the splitter aren't included
        return null;
    }
}

ComponentTypes.registerComponentType("splitter", Splitter.prototype.constructor);
