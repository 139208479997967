/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelCommitmentAutoRating.ts          *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/datafusion/commitment-auto-rating";

export class AutogenModelCommitmentAutoRating<RowType extends ModelRow> extends Endpoint<RowType, RowCommitmentAutoRatingFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowCommitmentAutoRating extends ModelRow<RowCommitmentAutoRatingFields> {
    constructor(values?: Partial<RowCommitmentAutoRatingFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowCommitmentAutoRatingFields {
    bill_distance: number;
    bill_distance_um: string;
    "commitment_detail.auto_decline_tender": string;
    "commitment_detail.award_date": Date;
    "commitment_detail.award_volume": number;
    "commitment_detail.bill_distance": number;
    "commitment_detail.bill_distance_um": string;
    "commitment_detail.company_id": string;
    "commitment_detail.disqualify_auto_rate": string;
    "commitment_detail.expiration_date": Date;
    "commitment_detail.frequency": string;
    "commitment_detail.id": string;
    "commitment_detail.minimum_charge": number;
    "commitment_detail.orig_dest_rate_id": string;
    "commitment_detail.rate": string;
    "commitment_detail.rate_type": string;
    "commitment_detail.requested_vol": number;
    "commitment_detail.start_date": Date;
    "commitment_history.commitment_id": string;
    "commitment_history.company_id": string;
    "commitment_history.customer_id": string;
    "commitment_history.date_order_created": Date;
    "commitment_history.date_tender_accepted": Date;
    "commitment_history.date_tender_declined": Date;
    "commitment_history.date_tender_received": Date;
    "commitment_history.decline_reason": string;
    "commitment_history.id": string;
    "commitment_history.load_tender_id": string;
    "commitment_history.order_id": string;
    "commitment_history.orig_dest_rate_id": string;
    "commitment_history.rate": string;
    "commitment_history.rate_id": number;
    "commitment_history.rate_type": string;
    company_id: string;
    descr: string;
    dest_city_name: string;
    dest_city_no: number;
    dest_code: string;
    dest_state: string;
    dest_value: string;
    dest_zip: string;
    id: string;
    intermediate_city_name: string;
    intermediate_city_no: number;
    intermediate_code: string;
    intermediate_state: string;
    intermediate_value: string;
    intermediate_zip: string;
    last_used_date: Date;
    minimum_charge: number;
    minimum_charge_c: string;
    minimum_charge_d: Date;
    minimum_charge_n: number;
    minimum_charge_r: number;
    month_accepted: number;
    month_offered: number;
    month_rejected: number;
    orig_city_name: string;
    orig_city_no: number;
    orig_code: string;
    orig_state: string;
    orig_value: string;
    orig_zip: string;
    pt_to_pt: string;
    quarter_accepted: number;
    quarter_offered: number;
    quarter_rejected: number;
    rate: string;
    rate_id: number;
    rate_type: string;
    remaining_needed: number;
    times_used: number;
    today_accepted: number;
    today_offered: number;
    today_rejected: number;
    transit_time: number;
    unit_descr: string;
    week_accepted: number;
    week_offered: number;
    week_rejected: number;
    year_accepted: number;
    year_offered: number;
    year_rejected: number;
}

