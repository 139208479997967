import { DataSource, Label, Layout, Panel, Switch, Textbox } from "@mcleod/components";
export abstract class AutogenLayoutElectronicAdvances extends Layout {
    electronicAdvancesSlideout: Panel;
    generalInfoLabel: Label;
    sourceIssueAdvance: DataSource;
    switchDeduction: Switch;
    switchOther: Switch;
    textboxAmount: Textbox;
    textboxDescr: Textbox;
    textboxPayeeId: Textbox;
    textboxWirecode: Textbox;
}
