import { DataSource, Layout, Panel, Tab, Table, Tabset } from "@mcleod/components";

export abstract class AutogenLayoutEdiOrderFASeg extends Layout {
    panelFASeg: Panel;
    sourceEdiFunctionalSeg: DataSource;
    sourceViewEdiOrder: DataSource;
    tabFASegment: Tab;
    tabFuncSeg: Tabset;
    table2: Table;
}
