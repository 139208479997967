import {
    Attachment, ClickEvent, DataSourceAction, DataSourceExecutionEvent, Image, Label, Panel, Textbox
} from "@mcleod/components";
import { getUnauthSettings, McLeodClassicIntegration, ModelRow, Navigation, StringUtil } from "@mcleod/core";
import { BrltlUtil } from "@mcleod/dispatch/BrltlUtil";
import { AutogenLayoutWebControl } from "./autogen/AutogenLayoutWebControl";
import { CommonTooltips } from "@mcleod/common";

const OPS_USER = { caption: "Operations User", value: "O" };

export class WebControl extends AutogenLayoutWebControl {

    onLoad() {
        this.textboxDefaultPostingUserRole.selectedItem = OPS_USER;
        this.setRequiredStatusForRatingScreenTrigger();

        this.textboxTargetPayCalc.onSelectItem = (textbox: Textbox, selectedItem: string | ModelRow<any>) => {
            this.showHideMPactApiKeyTB(selectedItem["value"]);
            return undefined;
        };

        this.tabMandatoryCarrierRatings.visible = getUnauthSettings()?.company_settings?.["carrier_scorecard_licensed"];

        this.attachmentOfferOrderLogo["setAttachmentWithUI"] = (file: File) => {
            return this.saveImageCorrectly(file, this.attachmentOfferOrderLogo);
        };

        this.attachmentWaterfallLogo["setAttachmentWithUI"] = (file: File) => {
            return this.saveImageCorrectly(file, this.attachmentWaterfallLogo);
        };
        if (BrltlUtil.isBrokerageLtl()) {
            this.tabBrokerageLTL.visible = true;
        }

    }

    /** This is an event handler for the afterExecution event of sourceDispatchControl.  */
    sourceDispatchControlAfterExecution(event: DataSourceExecutionEvent) {
        this.showHideMPactApiKeyTB(this.mainDataSource.activeRow.get("target_pay_calc"));
        if (event.getAction() === DataSourceAction.UPDATE) {
            Navigation.reloadCurrentPage(true);
        }
    }

    /** This is an event handler for the beforeExecution event of sourceDispatchControl.  */
    sourceDispatchControlBeforeExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() === DataSourceAction.UPDATE) {
            if (this.activeRow.getChangedData()?.mpact_smart_api_key) {
                this.activeRow.set("mpact_key_last_entered", new Date());
            }
        }
    }

    showHideMPactApiKeyTB(value) {
        this.textboxMPactAPI.visible = value === "M";
        this.textboxMpactHostURL.visible = value === "M";
        this.textboxMpactKeyLastEntered.visible = value === "M";
        const companyScac = getUnauthSettings().company_settings["scac"];
        if (companyScac == null) {
            this.imageMPactSmartTargetWarning.visible = value === "M";
            const tooltipPanel = new Panel();
            tooltipPanel.add(new Label({ caption: "No SCAC is set in company settings. A SCAC is required to use the MPact Smart Target API.", rowBreak: false }));
            const classicButton = new Image({ name: "classicMode", marginLeft: 10, tooltip: "Open Company Classic screen", onClick: () => McLeodClassicIntegration.openClassicScreen("com.tms.client.lib.EntryCompany", getUnauthSettings().company_settings["id"]) });
            tooltipPanel.add(classicButton);
            CommonTooltips.setTooltipCallback(this.imageMPactSmartTargetWarning, tooltipPanel);
        }
    }

    textboxDoBeforeCarrierRateConfirmationFormat(event) {
        event.filter.document_type = "Z";
        event.filter.template_number = ">0";
    }

    textboxDoBeforeExpirationNotificationFormat(event) {
        event.filter.document_type = "X";
        event.filter.template_number = ">0";
    }

    textboxDoBeforeCallinScriptTemplate(event) {
        event.filter.is_active = "Y";
    }

    setRequiredStatusForRatingScreenTrigger() {
        this.textboxRatingTriggerCode.required = this.isMandatoryRatingsChecked();
        this.textboxRatingTriggerCode.enabled = this.isMandatoryRatingsChecked();
    }

    private isMandatoryRatingsChecked(): boolean {
        return this.switchMandatoryRatings?.getDataValue() === 'Y';
    }

    private saveImageCorrectly(file: File, attachment: Attachment) {
        const maxSize = attachment.maxSize;
        const fileName = file.name.toLowerCase();
        if (!fileName.endsWith(".jpg") && !fileName.endsWith(".png") && !fileName.endsWith(".gif") && !fileName.endsWith(".jpeg")) {
            return attachment.showTooltip("The file must be an image.", { shaking: true, timeout: 5000 });
        } else if (maxSize != null && file.size > maxSize)
            return attachment.showTooltip("The file is too big to attach. The file must be smaller than " + maxSize / (1024 * 1024) + "MB.", { shaking: true, timeout: 5000 });
        else {
            attachment.setFile(file);
        }
    }

    buttonOpenClassicOnClick(event: ClickEvent) {
        const rateConfFormat = this.activeRow?.get("conf_fax_template") as string;
        McLeodClassicIntegration.openClassicScreen("com.tms.client.loadmaster.drs.EntryReportTemplate",
            "<ext> search report_template.template_number='" + rateConfFormat + "' and report_template.document_type='Z'");
    }

    buttonOpenClassicCarrierQualificationOnClick(event: ClickEvent) {
        if (!StringUtil.isEmptyString(this.mainDataSource.activeRow.get("brk_qual_profile"))) {
            McLeodClassicIntegration.openClassicScreen("com.tms.client.loadmaster.brdsp.EntryBrkQualProfile",
                this.mainDataSource.activeRow.get("brk_qual_profile"));
        }
        else {
            McLeodClassicIntegration.openClassicScreen("com.tms.client.loadmaster.brdsp.EntryBrkQualProfile", "<ext> sort id");
        }
    }

    buttonOpenClassicBrokerageWorkflowOnClick(event: ClickEvent) {
        if (!StringUtil.isEmptyString(this.mainDataSource.activeRow.get("brokerage_workflow_id"))) {
            McLeodClassicIntegration.openClassicScreen("com.tms.client.loadmaster.brdsp.EntryBrokerageWorkflow",
                this.mainDataSource.activeRow.get("brokerage_workflow_id"));
        }
        else {
            McLeodClassicIntegration.openClassicScreen("com.tms.client.loadmaster.brdsp.EntryBrokerageWorkflow", "<ext> sort id");
        }
    }
}
