import { getLogger } from "@mcleod/core";
import { Panel } from "../panel/Panel";
import { VendorMap } from "./Map";
import { MapPin } from "./MapPin";

const log = getLogger("components/Map");

export enum RouteType {
    None
}

export class NoMap extends Panel implements VendorMap {
    addPin(pin: MapPin): void { }
    removeAllPins() { }
    fitPins() { }
    setZoom(level: number) { }
    createRoute(routeData: any[], routeType: RouteType) { }
    setStopZoomLevel: (isTrafficEnabled: string, routeData: any[]) => void;
    clearRoute() { }
}
