/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelBrokerRatingHeader.ts            *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/powerbroker/broker-rating-header";

export class AutogenModelBrokerRatingHeader<RowType extends ModelRow> extends Endpoint<RowType, RowBrokerRatingHeaderFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowBrokerRatingHeader extends ModelRow<RowBrokerRatingHeaderFields> {
    constructor(values?: Partial<RowBrokerRatingHeaderFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowBrokerRatingHeaderFields {
    comments: string;
    company_id: string;
    id: string;
    movement_id: string;
    order_id: string;
    payee_id: string;
    rated_by: string;
    rated_date: Date;
}

