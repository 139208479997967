import {
    Button, Checkbox,
    CloneComponent,
    Component,
    ComponentTypes,
    Container,
    Cursor, DesignerInterface,
    DragListener,
    KeyHandler, Label,
    Layout,
    Overlay, OverlayedList,
    Panel,
    Snackbar,
    Splitter, SplitterOrientation,
    Table,
    TableCell,
    Textbox,
    deserializeComponents,
    serializeComponents
} from "@mcleod/components";
import { asCaptioned } from "@mcleod/components/src/base/CaptionedComponent";
import { DropTargetPanel } from "@mcleod/components/src/components/panel/DropTargetPanel";
import { ModelLayout, RowLayout } from "@mcleod/components/src/models/ModelLayout";
import { AuthType, DynamicLoader, setClassIncluded } from "@mcleod/core";
import { ActionAddComponent, PropertiesTable, designerApplyChangeToSelectedComponents, designerClasses, designerHandleComponentSelection, doDesignerAction, getDesignerKeyListeners } from "@mcleod/designer";
import { DesignerTool } from "@mcleod/designer/src/ui/DesignerTool";
import { CommonDialogs } from "./CommonDialogs";

export class ListDefinitionEditor extends Layout implements DesignerInterface {
    selectedComponents: Component[];
    path: string;
    defName: string;
    labelDefName: Label;
    layout: Layout;
    table: Table;
    comps: Component[];
    draggedComponent: Component;
    panelSaveDropdownAnchor: Component;
    tableProps: PropertiesTable;
    checkIncludeCaptions: Checkbox;
    panelComps: Panel;
    containerDragOverListener: DragListener;
    containerDragDropListener: DragListener;
    splitter: Splitter;

    constructor(props?) {
        super({
            auth: AuthType.ANY,
            title: "Edit List Layout",
            fillRow: true,
            needsServerLayout: false,
            ...props
        });
        this.containerDragDropListener = (event) => {
            if (this.isDragOverTable(event)) {
                let container = event.target;
                if (!container._isCompoundComponent) {
                    if (container instanceof DropTargetPanel) {
                        container = container.getSpecialDesignerDropTarget();
                    }
                    if (container != null)
                        this.componentDropped(container);
                }
            }
        };
        this.containerDragOverListener = (event) => {
            if (this.isDragOverTable(event) && !event.target._isCompoundComponent)
                event.preventDefault();
        };
        this.selectedComponents = [];

        this.splitter = new Splitter({
            fillRow: true, fillHeight: true, rowBreak: false, orientation: SplitterOrientation.vertical,
            firstComponent: this.createDesignedLayout(), secondComponent: this.createBottomSection()
        });
        this.components = [
            this.createHeader(),
            this.splitter,
            new Panel({
                width: 300, fillHeight: true, padding: 0, borderLeftWidth: 1, borderLeftColor: "strokeSecondary", marginLeft: 8, paddingLeft: 8, components: [
                    new PropertiesTable(this, { id: "tableProps" })
                ]
            }),
        ];
        this.tieComponentsToOwner(this.components, this);
        this.loadDefinition();
        this.showDefName();
    }

    override getKeyHandlers(): KeyHandler[] {
        return [...getDesignerKeyListeners(this)];
    }

    private isDragOverTable(event: DragEvent): boolean {
        if (!(event.target instanceof Component))
            return false;
        return this.table._element.contains(event.target._element);
    }

    createDesignedLayout(): Layout {
        this.layout = new Layout();
        this.table = new Table({ id: "table", fillRow: true, _designer: this, editingConfig: true, headerVisible: false });
        this.layout.add(this.table);
        return this.layout;
    }

    createBottomSection() {
        return new Panel({
            fillRow: true, fillHeight: true, components: [
                this.createBottomHeader(),
                new Panel({ id: "panelComps", fillRow: true, fillHeight: true, scrollY: true })
            ]
        });
    }

    createBottomHeader() {
        return new Panel({
            fillRow: true, components: [
                new Panel({
                    widthFillWeight: 2, rowBreak: false, components: [
                        new Label({ caption: "Drag fields from the page below onto this listing above.", fontSize: "small", color: "subtle.darker" }),
                        new Label({ caption: "Whatever other verbiage that tells the user what they need to do.", fontSize: "small", color: "subtle.darker" }),
                        new Panel({
                            id: "panelOptions", fillRow: true, components: [
                                new Checkbox({ id: "checkIncludeCaptions", caption: "Include field captions when adding to the listing" })
                            ]
                        }),
                    ]
                }),
                new Panel({
                    widthFillWeight: 1, components: [
                        new Label({ caption: "Advanced Layout (figure out how to explain this to the user)" }),
                        new DesignerTool(this as any, "Panel"),
                        new DesignerTool(this as any, "Layout"),
                    ]
                })
            ]
        });
    }

    createHeader() {
        const buttonAdd = new Button({ imageName: "add", caption: "New", color: "subtle.darker", rowBreak: false });
        buttonAdd.addClickListener(() => this.newDef(true));
        const buttonSave = new Button({ imageName: "disk", caption: "Save", backgroundColor: "primary", color: "primary.reverse", borderTopRightRadius: 0, borderBottomRightRadius: 0, rowBreak: false, marginRight: 0, borderRightWidth: 0 });
        buttonSave.addClickListener(() => this.saveWithPrompt(false));
        const buttonSaveAs = new Button({ imageName: "chevron", backgroundColor: "primary", color: "primary.reverse", borderTopLeftRadius: 0, borderBottomLeftRadius: 0, marginLeft: 0, borderLeftWidth: 0, paddingLeft: 2, paddingRight: 12 });
        buttonSaveAs.addClickListener(() => this.saveDropdown());
        return new Panel({
            fillRow: true, components: [
                new Label({ caption: "Edit Listing", fontSize: "xlarge", fontBold: true, color: "primary", rowBreak: false }),
                new Label({ id: "labelDefName", fontSize: "xlarge", fontBold: true, color: "primary", rowBreak: false }),
                new Panel({ fillRow: true, rowBreak: false }),
                new Panel({
                    components: [
                        buttonAdd,
                        new Panel({
                            id: "panelSaveDropdownAnchor", padding: 0, components: [
                                buttonSave,
                                buttonSaveAs
                            ]
                        })
                    ]
                })
            ]
        });
    }

    showDefName() {
        if (this.defName == null)
            this.labelDefName.caption = "( New listing configuration )";
        else
            this.labelDefName.caption = "( " + this.defName.replace("_", " ") + " )";
    }

    loadDefinition() {
        if (this.path == null)
            throw new Error("Cannot edit a list definition without a path.");
        this.table.configName = this.path;
        if (this.defName == null)
            this.newDef(false);
        else
            this.table.configDefName = this.defName;
        new ModelLayout().searchSingle({ path: this.path }).then(row => {
            const def = JSON.parse(row.get("definition"));
            const owner = DynamicLoader.getClassForPath(this.path);
            this.comps = deserializeComponents(owner, def, this, null, null, null);
            this.comps[0].setProps({ fillRow: true, fillHeight: true });
            this.panelComps.components = this.comps;
            this.makeCompsDraggable(this.comps);
        });
    }

    displayProperties() {
        this.tableProps.displayProperties(this.selectedComponents);
    }

    newDef(createNewTable: boolean) {
        if (createNewTable) {
            this.layout = this.createDesignedLayout();
            this.splitter.firstComponent = this.layout;
            this.defName = null;
        }
        this.table.configDefName = null;
        this.table.addDesignerColumn();
        this.showDefName();
    }

    save() {
        const definition = serializeComponents(this.layout, null);
        const row = new RowLayout();
        row.set({ path: this.path + "_" + this.defName, definition: definition });
        row.post()
            .then(response => Snackbar.showSnackbar("Saved list configuration (" + this.defName + ")"))
            .catch(error => CommonDialogs.showError(error));
    }

    saveDropdown() {
        const labelSaveAs = new Label({ caption: "Save as", padding: 8 });
        let overlayedList: OverlayedList = null;
        labelSaveAs.addClickListener(event => {
            Overlay.hideOverlay(overlayedList?._overlay);
            this.saveWithPrompt(true);
        });
        overlayedList = Overlay.showDropdown(this.panelSaveDropdownAnchor, [labelSaveAs]);
    }

    saveWithPrompt(alwaysShowPrompt: boolean) {
        if (alwaysShowPrompt || this.defName == null) {
            CommonDialogs.showInputDialog("Please enter a name for this list configuration", "Save List Config").then(value => {
                this.defName = value || "Default";
                this.save();
                this.showDefName();
            });
        }
        else
            this.save();
    }


    makeCompsDraggable(comps: Component[]) {
        for (const comp of comps) {
            if (/*comp.dataSource != null && */comp.field != null) {// want to make sure component's datasource is the same as the datasource of the listing, but datasource isn't populated because it's just a string
                comp.draggable = true;
                (comp as any)._isScreenComponent = true;
                comp.cursor = Cursor.POINTER;
                comp.addDragStartListener((event) => {
                    this.draggedComponent = comp;
                });
            }
            if (comp instanceof Container)
                this.makeCompsDraggable(comp.components);
        }
    }

    filterProps(props: any, component: Component) {
        // this seems problematic to maintain.  Maybe have a property of the property (ha) that determines if it's visible in 'list editor' mode?
        const remove = ["id", "form", "required", "dataSource", "cursor", "draggable", "enabled", "zIndex", "allowSelect", "wrap", "tooltip",
            "left", "field", "closeable", "link", "linkHardRefresh", "text", "imagePreName", "multiline", "password", "spellcheck", "captionVisible", "placeholder",
            "warningLabelVisible"
        ];
        for (const key of Object.keys(props))
            if (props[key].category === "Events")
                remove.push(key);
        for (const r of remove) {
            delete props[r];
        }
        this.changeCategory(props, "Appearance", "visible");
        this.changeCategory(props, "default", "fillRow", "rowBreak", "color", "fontBold", "fontSize", "align");
    }

    changeCategory(props: any, category: string, ...names) {
        for (const name of names)
            if (props[name] != null)
                props[name].category = category;
    }

    getActiveTab() {
        return this;
    }

    addDesignerContainerProperties(container: Component, minWidth: number, minHeight: number): void {
        setClassIncluded(container, designerClasses.designerContainer);
        if (container.width === undefined && container.minWidth === undefined)
            container.element.style.minWidth = minWidth + "px";
        if (container.minHeight === undefined)
            container.element.style.minHeight = minHeight + "px";
        container.addDragOverListener(this.containerDragOverListener);
        container.addDropListener(this.containerDragDropListener);
    }

    componentDropped(container) {
        let component = this.draggedComponent;
        if (component == null)
            return;
        this.draggedComponent = null;
        if (component instanceof DesignerTool)
            component = ComponentTypes.createComponentOfType(component.toolName.toLowerCase(), { _designer: this } as any);
        const captioned = asCaptioned(component);
        if (container instanceof TableCell && container.col != null && container.col.heading.caption.startsWith("Column ") && captioned != null) {
            container.col.heading.caption = captioned.caption;
            container.col.headingCell.caption = captioned.caption;
        }
        if (component instanceof Textbox && !this.checkIncludeCaptions.checked)
            component = this.convertToLabel(component);
        else
            component = CloneComponent.clone({ component: component });
        component.setProps({ printable: true, fillRow: true, fillHeight: false, rowBreak: true, draggable: false, width: null });
        doDesignerAction(this, new ActionAddComponent(component, container));
        this.selectComponent(component, false);
    }

    convertToLabel(component: Component): Label {
        const result = new Label({ _designer: component._designer });
        for (const prop of Object.values(result.getPropertyDefinitions()))
            if (prop.name !== "caption") {
                const value = component[prop.name];
                if (result.getPropertyDefaultValue(prop) !== value)
                    result[prop.name] = value;
            }
        return result;
    }

    displayDataSourceTools() {
    }


    selectComponent(component: Component, add: boolean) {
        if (component == null || (!(component as any)._isScreenComponent && component !== this.table))
            designerHandleComponentSelection(this.selectedComponents, component, add, this.tableProps);
    }

    dragTool(event, tool: DesignerTool, toolProps: any) {
        this.draggedComponent = tool;
        event.domEvent.dataTransfer.setDragImage(tool.image._element, 0, 0);
    }

    selectTool(tool) {
    }


    modified() {
    }

    applyChangeToSelectedComponents(data, newValue) {
        this.modified();
        designerApplyChangeToSelectedComponents(this.selectedComponents, this.getActiveTab(), data, newValue, this.tableProps);
    }

    get allowsDrop(): boolean {
        return true;
    }
}
