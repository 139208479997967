import { AutogenLayoutCustomerOrderHistory } from "./autogen/AutogenLayoutCustomerOrderHistory";

export class CustomerOrderHistory extends AutogenLayoutCustomerOrderHistory {

    override onLoad() {
        this.addComboCaptionAndValues();
    }

    addComboCaptionAndValues() {
        this.textboxBillOrderedDistance.items = [
            { caption: "Bill Date", value: "B" },
            { caption: "Ordered Date", value: "O" }
        ];
    }
}
