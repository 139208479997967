import { DialogProps } from "@mcleod/components";
import { AbstractPanelOpenTree } from "./AbstractPanelOpenTree";

export class PanelOpenLayout extends AbstractPanelOpenTree {
    dialogProps: Partial<DialogProps> = { title: "Layout Selection", cursor: null };

    override getApiName(): string {
        return "layout/list";
    }

    override getCustomizedTooltip(): string {
        return "This is a customized layout.";
    }
}
