import { TableRowDisplayEvent, TableRowMode, Textbox } from "@mcleod/components";
import { AutogenLayoutBillToOverrideTable } from "./autogen/AutogenLayoutBillToOverrideTable";

export class BillToOverrideTable extends AutogenLayoutBillToOverrideTable {

    /** This is an event handler for the onRowDisplay event of tableBillToOverride.  */
    tableBillToOverrideOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        const data = event.getTableRow().data;
        const textboxCarrierScac = tableRow.findComponentById("textboxCarrierScac") as Textbox;

        if (tableRow.mode !== TableRowMode.SEARCH) {
            textboxCarrierScac.text = data.get("carrier_scac");
        }
    }
}
