import { ChangeEvent, Component, DomEvent, EventListener } from "../..";

export enum DateTimeChangePart {
    Date = "date",
    Hour = "hour",
    Minute = "minute",
    AmPm = "ampm"
}

export interface DateTimePickerChangeListener extends EventListener {
    (event: DateTimePickerChangeEvent): void;
}

export class DateTimePickerChangeEvent extends ChangeEvent {
    changePart: DateTimeChangePart;
    constructor(target: Component, oldValue: any, newValue: any, domEvent: DomEvent, changePart: DateTimeChangePart) {
        super(target, oldValue, newValue, domEvent);
        this.changePart = changePart;
    }
}
