import { Button, DataSource, HorizontalSpacer, Label, Layout, Panel, SearchButton, Table, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutCarrierOfferList extends Layout {
    buttonClear: Button;
    buttonExpand: Button;
    horizontalspacer1: HorizontalSpacer;
    labelFilters: Label;
    panelFilterComponents: Panel;
    panelFilterContent: Panel;
    panelFilterHeader: Panel;
    panelFilters: Panel;
    panelMainSearchFilters: Panel;
    panelOffersListContainer: Panel;
    searchbuttonSearch: SearchButton;
    sourceCarrierOfferList: DataSource;
    tableOffers: Table;
    textboxCarrierName: Textbox;
    textboxDateRange: Textbox;
    textboxEnteredUserId: Textbox;
    textboxOrderId: Textbox;
    textboxSource: Textbox;
    textboxStatus: Textbox;
}
