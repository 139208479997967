import { Component, Container } from "@mcleod/components";
import { DesignerAction } from "./DesignerAction";
import { DesignerActionResult } from "./DesignerActionResult";

export class ActionComponentDrop implements DesignerAction {
    dropped: Component;
    droppedIndex: number;
    droppedParent: Container;
    targetComp: Component;
    targetIndex: number;
    targetContainer: Container;

    constructor(dropped: Component, targetContainer: Container, targetIndex?: number) {
        this.dropped = dropped;
        this.droppedParent = dropped.parent;
        this.droppedIndex = this.droppedParent.indexOf(this.dropped);
        this.targetContainer = targetContainer;
        this.targetIndex = targetIndex;
    }

    execute(): DesignerActionResult {
        if (this.targetContainer != null) {
            if (this.targetContainer != this.droppedParent) {
                this.droppedParent?.remove(this.dropped);
                if (this.targetIndex != null)
                    this.targetContainer.insert(this.dropped, this.targetIndex);
                else
                    this.targetContainer.add(this.dropped);
                return { success: true };
            }
        }
        return { success: false };
    }

    undo() {
        if (this.targetContainer != this.droppedParent) {
            this.targetContainer.remove(this.dropped);
            if (this.droppedIndex != null)
                this.droppedParent?.insert(this.dropped, this.droppedIndex);
        }
    }

    toString() {
        return `Drop ${this.dropped.constructor.name} - ${this.dropped.id}`;
    }
}
