import { ComponentPropDefinitions, ComponentPropDefinitionUtil } from "../../base/ComponentProps";
import { ContainerProps } from "../../base/ContainerProps";
import { PropType } from "../../base/PropType";
import { ImagePropDefinitions } from "../image/ImageProps";
import { PanelPropDefinitions } from "../panel/PanelProps";

export interface TabProps extends ContainerProps {
    caption: string;
    closeable: boolean;
    showTitleInScroll: boolean;
}

let tabPropDefs: ComponentPropDefinitions;

export class TabPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (tabPropDefs == null) {
            tabPropDefs = {
                ...PanelPropDefinitions.cloneDefs(),
                ...ImagePropDefinitions.getContainedImagePropDefs(),
                caption: { type: PropType.string, visibleInMcLeodTailor: true, editableInMcLeodTailor: true },
                closeable: { type: PropType.bool },
                showTitleInScroll: { type: PropType.bool, defaultValue: true, description: "Specifies if the tab, when rendered in scroll mode, should display a title label whose caption matches the tab's heading caption." },
                beforeSelection: { type: PropType.event, category: "Events", addListenerMethod: "addBeforeSelectionListener", removeListenerMethod: "removeBeforeSelectionListener", description: "This event is fired before a Tab is selected." },
                afterSelection: { type: PropType.event, category: "Events", addListenerMethod: "addAfterSelectionListener", removeListenerMethod: "removeAfterSelectionListener", description: "This event is fired after a Tab is selected." },
                beforeClose: { type: PropType.event, category: "Events", addListenerMethod: "addBeforeCloseListener", removeListenerMethod: "removeBeforeCloseListener", description: "This event is fired before a tab is closed/removed from the Tabset." },
                afterClose: { type: PropType.event, category: "Events", addListenerMethod: "addAfterCloseListener", removeListenerMethod: "removeAfterCloseListener", description: "This event is fired after a tab is closed/removed from the Tabset." },
            };
            ComponentPropDefinitionUtil.populateComponentPropNames(tabPropDefs);
        }
        return tabPropDefs;
    }
}
