import { Button, Component, KeyHandler, Layout, Panel, serializeComponents, Splitter } from "@mcleod/components";
import { ModelLayout } from "@mcleod/components/src/models/ModelLayout";
import { AuthType, HorizontalAlignment, Keys, ModelRow } from "@mcleod/core";
import { CommonDialogs } from "../CommonDialogs";
import { ModelUserHomePage, RowUserHomePage } from "../models/ModelUserHomePage";
import { HomeWidget } from "./HomeWidget";
import { gridTemplateRows, WidgetGrid } from "./WidgetGrid";
import { WidgetMenu } from "./WidgetMenu";

export class HomePageEditor extends Layout {
    private widgetMenu: WidgetMenu;
    private _selectedWidget: HomeWidget;
    private _rowUserHomePage: RowUserHomePage;
    private _originalDefinition: any;
    private _savedRow: RowUserHomePage;
    private widgetGrid: WidgetGrid;
    private _buttonSave: Button;

    public get buttonSave(): Button {
        return this._buttonSave;
    }

    constructor(props?) {
        super({
            auth: AuthType.ANY,
            title: "Edit Home Page",
            fillRow: true,
            needsServerLayout: false,
            ...props
        });
    }

    override async onLoad() {
        if (this.rowUserHomePage == null)
            this.rowUserHomePage = await new ModelUserHomePage().searchSingle();
        if (this.rowUserHomePage == null) return;

        this._buttonSave = new Button({
            rowBreak: false,
            enabled: false,
            backgroundColor: "primary",
            color: "primary.reverse",
            disabledTooltip: "You cannot save your homepage because nothing has changed.",
            caption: "Save",
            width: 100,
            onClick: () => this.save()
        });
        this.widgetGrid = new WidgetGrid();
        this.widgetGrid.style.gridTemplateRows = gridTemplateRows;
        await this.widgetGrid.displayLayout(this._rowUserHomePage.get("definition"), this);
        const gridWrapper = new Panel({ align: HorizontalAlignment.CENTER, fillRow: true, fillHeight: true });
        gridWrapper.add(this.widgetGrid);
        this.widgetMenu = new WidgetMenu(this,this._rowUserHomePage.get("is_edi_licensed"), { fillRow: true, fillHeight: true, padding: 0 });
        const splitTools = new Splitter({ padding: 0, firstComponent: this.widgetMenu, secondComponent: gridWrapper, fillHeight: true, fillRow: true, position: 220, expandButtonsVisible: false });
        this.add(splitTools);
    }

    public get rowUserHomePage(): RowUserHomePage {
        return this._rowUserHomePage;
    }

    public set rowUserHomePage(value: any) {
        this._rowUserHomePage = value;
        if (this._rowUserHomePage?.get("definition") != null)
            this._originalDefinition = JSON.parse(this._rowUserHomePage.get("definition"));
    }

    public get savedRow(): RowUserHomePage {
        return this._savedRow;
    }

    getWidgetFromGrid(layoutName: string): HomeWidget {
        if (layoutName == null)
            return null;
        return this.widgetGrid.components.find((comp) => comp instanceof HomeWidget && layoutName == comp.layoutName) as HomeWidget;
    }

    addWidget(widgetLayout: HomeWidget, gridOrder: number = this.widgetGrid.components.length + 1) {
        widgetLayout.style.order = gridOrder.toString();
        this.widgetGrid.add(widgetLayout)
        this.syncSaveButton();
        this.syncMenu();
    }

    deleteSelected() {
        if (this.selectedWidget != null)
            this.removeWidget(this.selectedWidget);
    }

    removeWidget(homeWidget: HomeWidget) {
        const widgetTitle = homeWidget.layout.title ?? "this widget";
        CommonDialogs.showYesNo({ caption: `Are you sure you want to remove ${widgetTitle}?` }, "Remove Widget").then(remove => {
            if (remove) {
                if (homeWidget == this.selectedWidget)
                    this.selectedWidget = null;
                this.widgetGrid.remove(homeWidget);
                this.syncMenu();
                this.syncSaveButton();
            }
        });
    }

    selectedDropped(dropTarget: Component) {
        this.widgetGrid.dropped(this.selectedWidget, dropTarget as HomeWidget);
        this.syncSaveButton();
    }

    get selectedWidget(): HomeWidget {
        return this._selectedWidget;
    }

    set selectedWidget(value: HomeWidget) {
        if (this._selectedWidget != null && this._selectedWidget != value)
            this._selectedWidget.selected = false
        this._selectedWidget = value;
        this.syncSaveButton();
    }

    syncMenu() {
        this.widgetMenu.syncMenu();
    }

    syncSaveButton() {
        this._buttonSave.enabled = this._userChangedLayout();
    }

    private _userChangedLayout(): boolean {
        const orig = this._createComponentKey(this._originalDefinition.components);
        const current = this._createComponentKey(this.widgetGrid.components);
        return orig != current;
    }

    private _createComponentKey(comps: any[]): string {
        return JSON.stringify(comps.map(comp => comp.layoutName));
    }

    async save() {
        try {
            this.selectedWidget = null;
            this.buttonSave.busy = true;
            const layouts = [];
            this.widgetGrid.components.forEach(comp => {
                if (comp instanceof HomeWidget)
                    layouts.push(comp.layout)
            });

            const ser = serializeComponents(layouts, null);
            const components = JSON.parse(ser);
            const def = JSON.stringify({ "type": "layout", "components": components });
            let rowToPost = this.rowUserHomePage;
            if (this.rowUserHomePage.isNull("id")) {
                rowToPost = new ModelRow(this.rowUserHomePage._modelPath, true);
                await rowToPost.populateDefaultValues();
            } else {
                // delete the custom defintion if it matches our default layout
                const defaultRow = await new ModelLayout().searchSingle({ path: "lme/homepage/UserHomeDefault" });
                const defaultLayoutDef = JSON.parse(defaultRow.get("definition"));
                const saveData = this._createComponentKey(components);
                const defaultData = this._createComponentKey(defaultLayoutDef.components);
                if (saveData == defaultData) {
                    rowToPost = null;
                    await this.rowUserHomePage.delete();
                    this._savedRow = await new ModelUserHomePage().searchSingle();
                }
            }

            if (rowToPost != null) {
                rowToPost.set({ definition: def });
                await rowToPost.post();
                this._savedRow = rowToPost;
            }
            this.slideOut();
        } catch (error) {
            CommonDialogs.showError(error);
        } finally {
            this.buttonSave.busy = false;
        }

    }

    async useDefaultLayout(components: any) {
        const rowLayout = await new ModelLayout().searchSingle({ path: "lme/homepage/UserHomeDefault" });
        const defaultLayoutDef = JSON.parse(rowLayout.get("definition"));
        const saveData = this._createComponentKey(components);
        const defaultData = this._createComponentKey(defaultLayoutDef.components);
        return saveData != defaultData;
    }

    getRouterProps() {
        return { padding: 0 };
    }

    override getKeyHandlers(): KeyHandler[] {
        return [{ key: Keys.DELETE, listener: () => this.deleteSelected() }];
    }
}
