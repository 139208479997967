import { CommonTooltips } from "@mcleod/common";
import { Component, TableRow, Textbox } from "@mcleod/components";
import { ModelRow } from "@mcleod/core";
import { AutogenLayoutRoutingGuideProfile } from "./autogen/AutogenLayoutRoutingGuideProfile";

export class RoutingGuideProfile extends AutogenLayoutRoutingGuideProfile {

    textboxPayeeIdOnLookupModelSelection(textbox: Textbox, selectedRow: ModelRow) {
        if (selectedRow != null) {
            const tableRow = TableRow.getContainingTableRow(textbox);
            tableRow.data.set("carrier_name", selectedRow.get("name"));
        }
    }

    textboxUserIdBeforeLookupModelSearch(event) {
        event.filter.web_user_type = 'U';
    }

    textboxPayeeIdBeforeLookupModelSearch(event) {
        event.filter.status = "in A,N";
    }

    /** This is an event handler for the beforeLookupModelSearch event of textboxContactName.  */
    textboxContactNameBeforeLookupModelSearch(event) {
        const contactName = event.target as Textbox;
        const tableRow: TableRow = TableRow.getContainingTableRow(contactName);
        const modelRow: ModelRow = tableRow.data;
        const payeeId: string = modelRow.get("payee_id");
        if (payeeId != null) {
            event.filter.parent_row_id = payeeId;
            event.filter.parent_row_type = "P";
            event.filter.is_active = "Y";
        }

        if (contactName != null && contactName.onSelectItem == null) {
            contactName.onSelectItem = ((textbox, selection) => {
                if (selection != null) {
                    modelRow.set("contact_phone", (selection as ModelRow).get("phone"));
                    modelRow.set("contact_email", (selection as ModelRow).get("email"));
                    modelRow.set("contact_mobile_phone", (selection as ModelRow).get("mobile_phone"));
                    const contactPhone = tableRow.findComponentById("textboxContactPhone") as Textbox;
                    const contactEmail = tableRow.findComponentById("textboxContactEmail") as Textbox;
                    const contactMobilePhone = tableRow.findComponentById("textboxContactMobilePhone") as Textbox;
                    contactPhone.text = (selection as ModelRow).get("phone");
                    contactEmail.text = (selection as ModelRow).get("email");
                    contactMobilePhone.text = (selection as ModelRow).get("mobile_phone");
                }
                return undefined;
            });
        }
    }

    tableCarriersOnRowDisplay(event) {
        const tableRow = event.getTableRow() as TableRow;
        tableRow.forEveryChildComponent((comp: Component) => {
            this.setTooltipCallbacks(comp, tableRow.data);
        });
    }

    setTooltipCallbacks(comp: Component, modelRow: ModelRow) {
        if (comp.id == null) return;
        switch (comp.id) {
            case "textboxPayeeId":
                return CommonTooltips.setTooltipFromLayoutCallback(comp, modelRow.get("payee_id"), "lme/powerbroker/CarrierQuickInfo", { minWidth: 128, minHeight: 140 });
        }
    }
}
