/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelPnnEquipType.ts                  *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/dispatch/pnn-equip-type";

export class AutogenModelPnnEquipType<RowType extends ModelRow> extends Endpoint<RowType, RowPnnEquipTypeFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowPnnEquipType extends ModelRow<RowPnnEquipTypeFields> {
    constructor(values?: Partial<RowPnnEquipTypeFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowPnnEquipTypeFields {
    company_id: string;
    descr: string;
    id: string;
    truckstop_code: string;
}

