import { DataSource, Table, Textbox } from "@mcleod/components";
import { ModelDataChangeType } from "@mcleod/components/src/databinding/DataSource";
import { Api, Model, ModelRow, ModelRowType, getAuthSettings, getLogger } from "@mcleod/core";
import { Orders } from "./Orders";

const log = getLogger("lme.dispatch.OrdersEquipmentMatchListener");

export class OrdersEquipmentMatchListener {
    private dataSource: DataSource;
    private ordersLayout: Orders;

    constructor(order: Orders) {
        this.ordersLayout = order;
        this.dataSource = order.mainDataSource;
    }

    async rowUpdated(): Promise<any> {
        if (getAuthSettings().dispatch_control[0].equipment_match === "Y") {
            this.dataSource.activeRow.set("default_match_id", null);
            this.ordersLayout.textboxDefaultEquipMatchId.visible = false;
            const orderRow = this.dataSource.getDataboundValues(null, true, null, null);
            return new Promise((resolve, reject) => {
                Api.search("lme/dispatch/find-matching-equipment", {
                    "order_row": orderRow,
                }).then(async response => {
                    await this.updateOrder(response.data[0]);
                    resolve(response);
                });
            })
        } else {
            return Promise.resolve();
        }
    }

    async updateOrder(data: any) {
        this.ordersLayout.textboxDefaultEquipMatchId.visible = data.default_match_ids != null;
        if (data.brk_qual_profile_id != null && this.dataSource.activeRow.isNull("brk_qual_profile")) {
            this.dataSource.activeRow.set("brk_qual_profile", data.brk_qual_profile_id);
            const lmData = new ModelRow(this.ordersLayout.textboxBrkQualProfile.lookupModel, false, { id: data.brk_qual_profile_id, descr: data.brk_qual_profile_descr });
            this.dataSource.activeRow.setLookupModelData("brk_qual_profile", lmData);
            this.ordersLayout.textboxBrkQualProfile.displayData(this.dataSource.activeRow, null, 0);
        }
        if (data.default_match_ids != null)
            this.dataSource.activeRow.set("default_match_id", data.default_match_ids.join(","));

        await this.setDefaultsAndRequiredFields(data.default_values);
        await this.setAccessorials(data.accessorial_values);
        await this.setEquipmentItems(data.required_equipment || []);
    }

    async setDefaultsAndRequiredFields(defaultValues: any[]) {
        for (const defaultVal of defaultValues) {
            const row = this.dataSource.activeRow;
            const fieldName = defaultVal.field_name;
            if (defaultVal.required == "Y") {
                for (const comp of this.ordersLayout.findComponentByField(fieldName))
                    comp.required = true;
            }
            if (defaultVal.default_value != null && (defaultVal.override_user == "Y" || row.isNull(fieldName))) {
                for (const comp of this.ordersLayout.findComponentByField(fieldName)) {
                    if (comp instanceof Textbox && comp.lookupModel && comp.lookupModelLayout && comp.lookupModelResultField) {
                        await Model.searchSingleRecord(comp.lookupModel, { [comp.lookupModelResultField]: defaultVal.default_value }, comp.lookupModelLayout).then(result => {
                            if (result) {
                                row.set(fieldName, defaultVal.default_value);
                                result.type = ModelRowType.LOOKUP_MODEL_DATA;
                                row.setLookupModelData(fieldName, result);
                                comp.displayData(row, null, 0);
                            }
                        });
                    }
                    else {
                        row.set(fieldName, defaultVal.default_value);
                    }
                }
            }
        }
    }

    async setAccessorials(accessorialValues: any[]) {
        const dataSource = this.ordersLayout.sourceBrltlOrderHdrXFgp;
        this.removePreviousMatches(dataSource);
        for (const accessorialVal of accessorialValues) {
            if (!this.ordersLayout.sourceBrltlOrderHdrXFgp.data.some(row => row.data["hdr_uid"] === accessorialVal.hdr_uid)) {
                const row = new ModelRow("lme/dispatch/order-handling-requirements", true, accessorialVal);
                this.ordersLayout.hdrChanged(row, ModelDataChangeType.ADD);
            }
        }
    }

    async setEquipmentItems(equipItems: any[]) {
        const dataSource = this.ordersLayout.sourceEquipMatchDetail;
        this.removePreviousMatches(dataSource);
        for (const equipItem of equipItems) {
            if (!dataSource.data.some(row => row.data["id"] === equipItem.id)) {
                const row = new ModelRow("lme/dispatch/equip-match-detail", true, equipItem);
                dataSource.addRow(row, dataSource.data.length, false);
            }
        }
    }

    removePreviouslyMatchedEquipment(table: Table) {
        for (const tableRow of table.rows) {
            if (!tableRow.data?.isNull("equipment_match_id"))
                table.deleteRow(tableRow.index);
        }
    }

    removePreviousMatches(dataSource: DataSource) {
        for (let i = dataSource.data.length - 1; i >= 0; i--) {
            const row = dataSource.data[i];
            if (row.data["equipment_match_id"] != null) {
                dataSource.deleteTableRow(i, true);
            }
        }
    }
}
