import { CityState, DataSource, Label, Layout, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutQuoteSummary extends Layout {
    citystateConsigneeCS: CityState;
    citystateShipperCS: CityState;
    labelCaptionContact: Label;
    labelCaptionCustomer: Label;
    labelComment: Label;
    labelCommentCaption: Label;
    labelConsigneeLocationName: Label;
    labelContactEmail: Label;
    labelContactName: Label;
    labelContactPhone: Label;
    labelCustomerName: Label;
    labelDeliveryCaption: Label;
    labelPickupCaption: Label;
    labelShipperLocationName: Label;
    panelComment: Panel;
    panelConsignee: Panel;
    panelContainer: Panel;
    panelCustomerContainer: Panel;
    panelCustomerInfo: Panel;
    panelQuote: Panel;
    panelRow1: Panel;
    panelRow2: Panel;
    panelShipper: Panel;
    panelSummary: Panel;
    sourceQuoteOrder: DataSource;
    textboxDistanceReview: Textbox;
    textboxRateMethodReview: Textbox;
    textboxRateReview: Textbox;
    textboxTotalChargeReview: Textbox;
    textboxTrailerTypeReview: Textbox;
    textboxWeightReview: Textbox;
}
