import { getLogger } from "@mcleod/core";
import { AutogenLayoutPurposeSwap } from "./autogen/AutogenLayoutPurposeSwap";
import { RowEdiOrder } from "./models/ModelEdiOrder";
const log = getLogger("lme.datafusion.PurposeSwap");

export class PurposeSwap extends AutogenLayoutPurposeSwap {
    async swapOriginalToChange(ediOrderRow: RowEdiOrder, orderId: string) {
        ediOrderRow.set("order_id", orderId);
        ediOrderRow.set("purpose_type", "U");
        ediOrderRow.set("purpose", "04");
        ediOrderRow.set("original_order", "N");
        ediOrderRow.set("changed_order", "Y");
        await ediOrderRow.post();

        return;
    }

    async swapChangeToOriginal(ediOrderRow: RowEdiOrder) {
        ediOrderRow.set("purpose_type", "O");
        ediOrderRow.set("purpose", "00");
        ediOrderRow.set("original_order", "Y");
        ediOrderRow.set("changed_order", "N");
        ediOrderRow.set("order_id", "");
        await ediOrderRow.post();

        return;
    }
}
