import {
    BlurEvent, ClickEvent, DataSource, DataSourceMode, Panel, PanelProps, Textbox, TextboxProps
} from "@mcleod/components";
import { Api, DisplayType, PermissionsUtil, StringUtil } from "@mcleod/core";
import { RowMovement } from "../../dispatch/src/models/ModelMovement";
import { getDispatchControlBoolean } from "../../general/src/models/ModelDispatchControl";
import { AutogenLayoutMovementCarrierPay } from "./autogen/AutogenLayoutMovementCarrierPay";

export function userCanOverrideMaxPay(): boolean {
    return !getDispatchControlBoolean("enforce_max_pay") || !PermissionsUtil.isUserDeniedAction("Brokerage.Max pay override")
}

export function userCanOverrideTargetPay(): boolean {
    return !getDispatchControlBoolean("enforce_target_pay") || !PermissionsUtil.isUserDeniedAction("Brokerage.Target pay override")
}

export class MovementCarrierPay extends AutogenLayoutMovementCarrierPay {

    private orderSource: DataSource;
    override onLoad(): void {
        this.setTooltipCallback(this.panelMaxAmt, this.textboxMaxPayCalc);
        this.layoutTargetPay.addLayoutLoadListener(() => {
            this.setTooltipCallback(this.layoutTargetPay.panelTargetPayText, this.layoutTargetPay.textboxTargetPayCalc);
            this.layoutTargetPay.textboxTargetPayCalc.addBlurListener(event => this.payTextboxOnBlur(event));
        })
    }

    private setTooltipCallback(panel: Panel, textbox: Textbox) {
        // max/target textboxes are wrapped in panels because resetting the textbox field
        // calls Textbox._syncHoverCallback() and wipes out the textbox tooltipCallback
        panel.tooltipCallback = () => {
            if (textbox.printable)
                return panel.showTooltip(() => this.makeTargetMaxPayTooltip(textbox), null, { themeKey: "quickInfo", color: null });
        }
    }

    configureForOrderEntry(orderSource: DataSource) {
        this.orderSource = orderSource;
        this.remove(this.panelOrderCharges);

        if (orderSource?.activeRow?._appending) {
            this.mainDataSource.setRowsAndMode(DataSourceMode.ADD, [new RowMovement()]);
        } else if (this.orderSource?.activeRow?.get("curr_movement_id") != null) {
            this.mainDataSource.search({ id: this.orderSource?.activeRow?.get("curr_movement_id") }).then(() => {
                this.mainDataSource.mode = DataSourceMode.UPDATE;
            })
        }
        const panelProps: Partial<PanelProps> = { borderRightWidth: 0, width: '33%' }
        this.panelTargetPay.setProps({ ...panelProps });
        this.panelMaxPay.setProps({ ...panelProps });
        this.textboxMaxPayCalc.setProps({ marginLeft: 5 });
        this.panelOrderCharges.setProps({ ...panelProps });
    }

    /** This is an event handler for the afterModeChange event of sourceMovement.  */
    sourceMovementAfterModeChange(event) {
        this.buttonOverrideMaxPay.enabled = userCanOverrideMaxPay();
        this.buttonOverrideTargetPay.enabled = userCanOverrideTargetPay();
    }

    public async displayCalculatedFields() {
        Api.search("lme/dispatch/calculate-override-pay", {
            "movement_id": this.mainDataSource.activeRow.get("id"),
            "pay_method": this.mainDataSource.activeRow.get("override_type"),
            "rate_per_unit": this.mainDataSource.activeRow.get("override_pay_rate"),
        }).then(response => {
            this.displayCalcPromiseResult(response);
        })
    }

    displayCalcPromiseResult(response: any) {
        this.displayPayFields(response.data[0]);
    }

    makeTargetMaxPayTooltip(textbox: Textbox): Panel {
        const maxPay = textbox.id === "textboxMaxPayCalc";
        const dataSource = textbox.dataSource;
        const row = dataSource.activeRow;
        const methodCaption = maxPay ? "Max Pay Method" : "Target Pay Method";
        const methodText = getPayMethodDescription(row.get(maxPay ? "max_pay_method" : "target_pay_method"));
        const textProps: Partial<TextboxProps> = { printable: true, nullDisplayValue: "--" }
        let payFields: Partial<TextboxProps>[] = [
            { caption: "Per Mile", field: maxPay ? "max_per_mile" : "target_per_mile" },
            { caption: "Amount", field: maxPay ? "max_buy" : "target_pay" },
            { caption: "Override Amount", field: maxPay ? "override_max_pay" : "override_targetpay" }
        ];

        const targetRateData = row.get("target_rate_data", "");
        if (!maxPay && row.get("target_pay_method", "") === "S" && !StringUtil.isEmptyString(targetRateData)) {
            const mpactRateData = JSON.parse(targetRateData);
            const mpactRateFields = [
                { caption: "Number of Orders", text: mpactRateData.sourceInfo.orders },
                { caption: "Number of Contributors", text: mpactRateData.sourceInfo.contributors },
                { caption: "Days", text: mpactRateData.sourceInfo.days }
            ];
            payFields = payFields.concat(mpactRateFields);
        }

        const tooltipPanel: Panel = new Panel({
            width: 150, components: [
                new Textbox({ ...textProps, displayType: DisplayType.STRING, caption: methodCaption, text: methodText }),
                ...this.createTooltipTextboxes(textProps, payFields)
            ]
        });

        tooltipPanel.displayData(row, null, 0);
        return tooltipPanel;
    }

    createTooltipTextboxes(textProps: Partial<TextboxProps>, payFields: Partial<TextboxProps>[]): Textbox[] {
        return payFields.map(payField => {
            return new Textbox({ ...textProps, displayType: payField.field ? DisplayType.CURRENCY : DisplayType.STRING, caption: payField.caption, field: payField.field ?? null, text: payField.text ?? null });
        })
    }

    private displayPayFields(data) {
        this.mainDataSource.activeRow?.set("orders.freight_charge", data["order_freight_charge"]);
        this.mainDataSource.activeRow?.set("orders.otherchargetotal", data["order_other_charges"]);
        this.mainDataSource.activeRow?.set("orders.total_charge", data["order_total_charges"]);
    }

    public getCalcPromise(): Promise<any> {
        return Api.search("lme/dispatch/calculate-override-pay", {
            "movement_id": this.mainDataSource.activeRow.get("id"),
            "pay_method": this.mainDataSource.activeRow.get("override_type"),
            "rate_per_unit": this.mainDataSource.activeRow.get("override_pay_rate"),
        });
    }

    buttonOverrideMaxPayOnClick(event: ClickEvent) {
        this.overrideButtonOnClick(this.textboxMaxPayCalc, "override_max_pay")
    }
    buttonOverrideTargetPayOnClick(event: ClickEvent) {
        this.overrideButtonOnClick(this.layoutTargetPay.textboxTargetPayCalc, "override_targetpay")
    }

    overrideButtonOnClick(textbox: Textbox, field: string) {
        textbox.width = 110;
        textbox.printable = false;
        if (textbox.field != field)
            textbox.field = field;
        textbox.focus();
        textbox.selectText();
    }

    payTextboxOnBlur(event: BlurEvent) {
        const textbox = event.target as Textbox;
        textbox.width = undefined;
        textbox.printable = true;
        this.validateOverride(textbox)
        this.calculateMargins(textbox);
        if (this.orderSource != null)
            this.updateOrderSource(textbox);
    }

    updateOrderSource(textbox: Textbox) {
        const payFields = this.getPayTextboxFields(textbox);
        if (payFields.overrideField == textbox.field) {
            const originalAmt = this.activeRow?.originalData?.[textbox.field + "_n"];
            const newAmt = this.activeRow.get(textbox.field)?.amount;
            if (newAmt != originalAmt)
                this.orderSource.activeRow.set(textbox.field, this.activeRow?.get(textbox.field));
            else
                this.orderSource.activeRow.set(textbox.field, undefined);
        }
    }

    // checks if override amount is not undefined and differs from pay amount
    validateOverride(textbox: Textbox) {
        const payFields = this.getPayTextboxFields(textbox);
        const calcValue = this.activeRow.get(payFields.payField);
        const overrideValue = this.activeRow.get(payFields.overrideField);
        if (overrideValue == undefined || overrideValue?.amount == calcValue?.amount) {
            this.activeRow.set(payFields.overrideField, null);
            textbox.field = payFields.payField;
        }
    }

    calculateMargins(textbox: Textbox) {
        const payFields = this.getPayTextboxFields(textbox);
        let totalCharge = null;
        if (this.orderSource != null)
            totalCharge = this.orderSource.activeRow.get("total_charge");
        else
            totalCharge = this.activeRow.get("orders.total_charge");
        textbox.printable = true;
        const value = this.activeRow.getCurrency(textbox.field);
        const distance = this.activeRow.get("move_distance");
        this.activeRow.set({ [payFields.marginField]: "0", [payFields.perMileField]: "0", [payFields.marginPctField]: null });
        if (totalCharge?.amount != null && value?.amount != null) {
            const margin = totalCharge?.amount - value?.amount;
            this.activeRow.set(payFields.marginField, margin);
            if (totalCharge?.amount != 0)
                this.activeRow.set(payFields.marginPctField, ((margin / totalCharge?.amount) * 100).toFixed(2) + "%");

            if (distance != null && distance != 0)
                this.activeRow.set(payFields.perMileField, value?.amount / distance);
        }
    }

    // code below is for calculating / overriding max and target pay from Orders page
    buttonCalcMaxTargetOnClick(event: ClickEvent) {
        if (this.orderSource == null) return;
        const data = this.orderSource.getDataboundValues(null, true, null, null);
        data.removeLookupModelData();
        data.set("total_charge", this.orderSource.activeRow.get("total_charge"));
        return Api.search("lme/dispatch/calculate-order-max-target", { order_row: data }).then(response => {
            this.setDataFromResponse(response.data[0], true);
        });
    }

    setDataFromResponse(responseData: any, clearData?: boolean) {
        this.activeRow.set("move_distance", responseData?.move_distance);
        this.activeRow.set("target_rate_data", responseData?.target_rate_data);
        this.setPayDataFromResponse(responseData, this.layoutTargetPay.textboxTargetPayCalc, clearData);
        this.setPayDataFromResponse(responseData, this.textboxMaxPayCalc, clearData);
    }

    setPayDataFromResponse(responseData: any, textbox: Textbox, clearData: boolean) {
        const payFields = this.getPayTextboxFields(textbox);
        if (clearData || this.activeRow.get(payFields.overrideField) == null) {
            payFields.allFields.forEach(field => this.activeRow.set(field, null));
            textbox.field = payFields.payField;
            payFields.allFields.forEach(field => this.activeRow.set(field, responseData?.[field]));
        }
        textbox.parent.displayData(this.activeRow, null, 0);
    }

    getPayTextboxFields(textbox: Textbox): PayTextboxFields {
        if (textbox == this.textboxMaxPayCalc) {
            return {
                isMaxPay: true, payField: "max_buy", overrideField: "override_max_pay",
                marginField: "max_margin", marginPctField: "max_margin_percent",
                perMileField: "max_per_mile",
                allFields: ["max_buy", "override_max_pay", "max_pay_calc", "max_margin", "max_margin_percent", "max_per_mile", "max_pay_method"]
            }
        }
        else {
            return {
                isMaxPay: false, payField: "target_pay", overrideField: "override_targetpay",
                marginField: "target_margin", marginPctField: "target_margin_percent",
                perMileField: "target_per_mile",
                allFields: ["target_pay", "override_targetpay", "target_pay_calc", "target_margin", "target_margin_percent", "target_per_mile", "target_pay_method"]
            }
        }
    }
}

interface PayTextboxFields {
    allFields: string[], isMaxPay: boolean, payField: string, overrideField: string,
    marginField: string, marginPctField: string, perMileField: string
}

const methods = {
    "F": "Flat", "D": "Distance", "P": "Margin %", "I": "Market Rate - ITS", "B": "Market Rate - DAT",
    "M": "Market Rate - McLeod", "N": "None", "G": "Pending - DAT", "T": "Pending - DAT", "E": "Pending - McLeod", "": "", "S": "MPACT - Smart Target"
};

function getPayMethodDescription(method: string): string {
    return methods[method];
}
