import { Button, DataSource, Label, Layout, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutBrokerCounter extends Layout {
    buttonConfirmCounter: Button;
    labelAdditionalDetails: Label;
    labelCounterRate: Label;
    labelDeliveryDate: Label;
    labelDeliveryDateTz: Label;
    labelDistance: Label;
    labelDistanceCaption: Label;
    labelMI: Label;
    labelPickupDate: Label;
    labelPickupDateTz: Label;
    labelSuccessfullyRespondedToThisLoad: Label;
    labelThisLoadIsNoLongerAvailable: Label;
    panelAddlDetails: Panel;
    panelAvailable: Panel;
    panelDelivery: Panel;
    panelDetails: Panel;
    panelNotAvailable: Panel;
    panelPickup: Panel;
    panelSuccess: Panel;
    sourceCarrierAvailableOrders: DataSource;
    textboxCommodity: Textbox;
    textboxCounterAmount: Textbox;
    textboxCounterRateCurrency: Textbox;
    textboxDestinationLoc: Textbox;
    textboxEquipmentDescr: Textbox;
    textboxEquipmentLength: Textbox;
    textboxLtl: Textbox;
    textboxOrderId: Textbox;
    textboxOriginLoc: Textbox;
    textboxWeight: Textbox;
}
