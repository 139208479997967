import { ArrayUtil, Collection, ObjectUtil } from "@mcleod/core";
import { DataSource } from "..";
import { Event, EventListener } from "../events/Event";
import { EventListenerList, getOwnerFunction } from "../events/EventListenerList";
import { DesignableObjectPropDefinition, DesignableObjectProps, DesignableObjectPropsCollection } from "./DesignableObjectProps";
import { ListenerListDef } from "./ListenerListDef";
import { ListenerListDefUtil } from "./ListenerListDefUtil";
import { PropType } from "./PropType";

export abstract class DesignableObject {
    private _owner: any;
    private __interactionEnabled: boolean = true;
    protected _id: string;
    private _deserializing: boolean;
    private _baseVersionProps: any;

    public get id(): string {
        return this._id;
    }

    public set id(value: string) {
        this._id = value;
    }

    get owner(): any {
        return this._owner;
    }

    set owner(value: any) {
        this._owner = value;
    }

    public abstract getDesigner(): any;
    public abstract setDesigner(designer: any): void;

    public getPropertyDefinitions(): DesignableObjectPropsCollection {
        throw new Error("Component type " + this.constructor.name + " must implement getPropertyDefinitions().");
    }

    public setProps(props: Partial<DesignableObjectProps>) {
        if (props != null) {
            const compProps = this.getPropertyDefinitions();
            if ((props as any)._designer != null)
                this.setDesigner((props as any)._designer);
            for (const key in props)
                if (key !== "_designer") {
                    this.setPropUsingPropsCollection(props[key], key, compProps);
                }
        }
    }

    public isDeserializing(): boolean {
        return this._deserializing === true;
    }

    public clearDeserializingFlag() {
        delete this._deserializing;
    }

    /**
     * This will set properties on this object and return an object containing the original values of those properties.
     * See ObjectUtil.replaceObjectProps for more detail.
     * @param props The properties that you wish to apply to this object
     * @returns The original values of the properties that were set
     */
    public replaceProps(props: Partial<DesignableObjectProps>): Partial<DesignableObjectProps> {
        return ObjectUtil.replaceObjectProps(this, props);
    }

    public async replacePropsDuring(props: Partial<DesignableObjectProps>, promiseFunction: Promise<any>, restorePropsOnError: boolean = true): Promise<any> {
        const originalProps = this.replaceProps(props);
        return promiseFunction.then(promiseResponse => {
            this.replaceProps(originalProps);
            return Promise.resolve(promiseResponse);
        }).catch(promiseError => {
            if (restorePropsOnError)
                this.replaceProps(originalProps);
            return Promise.reject(promiseError);
        });
    }

    public setPropUsingPropsCollection(value: any, key: string, compProps: Collection<DesignableObjectPropDefinition>) {
        const compProp = compProps[key];
        this.setProp(value, key, compProp);
    }

    public setProp(value: any, key: string, compProp: DesignableObjectPropDefinition) {
        const designer = this.getDesigner();
        if (compProp == null || compProp.type !== PropType.event || designer != null) {
            this[key] = value;
        }
        else if (!(this instanceof DataSource && designer != null)) {
            const addListenerMethod = compProp.addListenerMethod;
            if (addListenerMethod != null) {
                let eventFunction = value;
                if (this.getDesigner() == null && typeof eventFunction === "string") {
                    eventFunction = getOwnerFunction(this, key, eventFunction);
                }
                if (typeof eventFunction === "function") {
                    this[addListenerMethod](eventFunction);
                }
            }
        }
    }

    protected createEventListenerListIfNeeded(which: ListenerListDef) {
        if (this.getListeners(which.listName) == null)
            this._setListeners(which.listName, new EventListenerList(this, which));
    }

    protected addEventListener(which: ListenerListDef, value: EventListener): DesignableObject {
        this.createEventListenerListIfNeeded(which);
        this.getListeners(which.listName).add(value);
        return this;
    }

    protected insertEventListener(which: ListenerListDef, value: EventListener, index: number): DesignableObject {
        this.createEventListenerListIfNeeded(which);
        this.getListeners(which.listName).insert(value, index);
        return this;
    }

    protected removeEventListener(which: string | ListenerListDef, value: EventListener): DesignableObject {
        const list = this.getListeners(which);
        if (list != null) {
            for (let i = list.listeners.length - 1; i >= 0; i--) {
                const item = list.listeners[i];
                if (item === value || item?.wrapped === value)
                    list.listeners.splice(i, 1);
            }
            if (list.isEmpty())
                this._setListeners(which, undefined);
        }
        return this;
    }

    protected removeAllEventListeners(which: string | ListenerListDef): DesignableObject {
        const list = this.getListeners(which);
        if (list != null) {
            list.removeAll();
            this._setListeners(which, undefined);
        }
        return this;
    }

    hasListener(which: ListenerListDef, listener: EventListener): boolean {
        const list = this.getListeners(which.listName);
        return list != null && list.hasListener(listener);
    }

    hasListeners(which: ListenerListDef): boolean {
        const list = this.getListeners(which.listName);
        return list != null && !list.isEmpty();
    }

    fireListeners<EventType extends Event = Event>(name: string | ListenerListDef, event: Event | (() => Event)): EventType {
        if (typeof name === "object")
            name = name.listName;
        const list: EventListenerList = this[name];
        if (list != null && !list.isEmpty())
            return list.fireListeners<EventType>(event);
    }

    getListeners(name: string | ListenerListDef): EventListenerList {
        const n = ListenerListDefUtil.getListName(name);
        return this[n] as EventListenerList;
    }

    getFirstListener(name: string | ListenerListDef): EventListener {
        const n = ListenerListDefUtil.getListName(name);
        const list = this[n] as EventListenerList;
        return (ArrayUtil.isEmptyArray(list.listeners) !== true) ? list.listeners[0] : null;
    }

    getLastListener(name: string | ListenerListDef): EventListener {
        const n = ListenerListDefUtil.getListName(name);
        const list = this[n] as EventListenerList;
        return (ArrayUtil.isEmptyArray(list.listeners) !== true) ? list.listeners[list.listeners.length - 1] : null;
    }

    private _setListeners(name: string | ListenerListDef, value: EventListenerList) {
        const n = ListenerListDefUtil.getListName(name);
        this[n] = value;
    }

    public getEventTarget(): HTMLElement {
        return null;
    }

    get _interactionEnabled(): boolean {
        return this.__interactionEnabled;
    }

    set _interactionEnabled(value: boolean) {
        this.__interactionEnabled = value;
    }

    getListenerDefs(): Collection<ListenerListDef> {
        return {};
    }

    shareListenersFrom(otherDO: DesignableObject, listeners?: ListenerListDef[]) {
        const thisDefs = this.getListenerDefs();
        if (thisDefs == null)
            return;
        for (const listenerDef of Object.values(thisDefs)) {
            if (listeners != null && listeners.includes(listenerDef) !== true)
                continue;
            if (otherDO.hasListeners(listenerDef))
                this._setListeners(listenerDef, otherDO.getListeners(listenerDef).copyFor(this));
        }
    }

    public get baseVersionProps(): any {
        return this._baseVersionProps;
    }

    public set baseVersionProps(value: any) {
        this._baseVersionProps = value;
        this.syncBaseVersionProps();
    }

    protected syncBaseVersionProps() {
        if (ObjectUtil.isEmptyObject(this._baseVersionProps))
            this._baseVersionProps = null;
    }
}
