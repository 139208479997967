/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelTopmatch.ts                      *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/powerbroker/topmatch";

export class AutogenModelTopmatch<RowType extends ModelRow> extends Endpoint<RowType, RowTopmatchFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowTopmatch extends ModelRow<RowTopmatchFields> {
    constructor(values?: Partial<RowTopmatchFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowTopmatchFields {
    carrier_id: string;
    carrier_name: string;
    contact_email: string;
    contact_name: string;
    contact_phone: string;
    movement_status: string;
    rate: number;
    rate_id: string;
    sources: string;
    total_score: number;
    movement_id: string;
    order_id: string;
    topmatch_profile: string;
    trailer_type: string;
}

