/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelPbwLoadboardServices.ts          *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/powerbroker/pbw-loadboard-services";

export class AutogenModelPbwLoadboardServices<RowType extends ModelRow> extends Endpoint<RowType, RowPbwLoadboardServicesFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowPbwLoadboardServices extends ModelRow<RowPbwLoadboardServicesFields> {
    constructor(values?: Partial<RowPbwLoadboardServicesFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowPbwLoadboardServicesFields {
    access_token: string;
    account_id: string;
    client_id: string;
    client_secret: string;
    company_id: string;
    description: string;
    default_posting_user_role: string;
    file_type: string;
    id: string;
    last_posting_update: Date;
    password: string;
    posting_end: Date;
    posting_frequency: number;
    posting_start: Date;
    refresh_token: string;
    remote_directory: string;
    remote_port: number;
    service_type: string;
    vendor_user_id: string;
    sftp_file_prefix: string;
    token_expires: Date;
    url: string;
    user_id: string;
    weekend_posting: string;
    repost_enabled: boolean;
    last_repost_date: Date;
}

