import {
    Button, ClickEvent, Component, DataSourceExecutionEvent, Layout, Overlay, Panel, SelectionEvent, Snackbar, Tab,
    Table, TableRowCreationEvent, Toast
} from "@mcleod/components";
import { DataSourceAction, DataSourceMode, ModelDataChangeType } from "@mcleod/components/src/databinding/DataSource";
import { DataSourceRemoteDataChangeEvent } from "@mcleod/components/src/events/DataSourceRemoteDataChangeEvent";
import {
    ApiMetadata, DatePart, DateUtil, getAuthSettings, getLogger, ModelRow, StringUtil, UrlUtil, WindowTitle
} from "@mcleod/core";
import { BrokerageMovementTable } from "./BrokerageMovementTable";
import { BrokerageMovementTableType } from "./BrokerageMovementTableType";
import { BrokerageMovementsGridExpansion } from "./BrokerageMovementsGridExpansion";
import { BrokeragePlanningPeriscope } from "./BrokeragePlanningPeriscope";
import { BrokeragePlanningProfile } from "./BrokeragePlanningProfile";
import { BrokeragePlanningProfileFilter } from "./BrokeragePlanningProfileFilter";
import { BrokeragePlanningFilter, tableMinuteUpdate } from "./BrokeragePlanningUtil";
import { AutogenLayoutBrokeragePlanning } from "./autogen/AutogenLayoutBrokeragePlanning";
import { ModelBrokeragePlanningProfile, RowBrokeragePlanningProfile } from "./models/ModelBrokeragePlanningProfile";

interface BrokeragePlanningTab {
    type: BrokerageMovementTableType;
    layout?: BrokerageMovementTable | BrokeragePlanningPeriscope;
    tab?: Tab;
}

const log = getLogger("lme.powerbroker.BrokeragePlanning");

export class BrokeragePlanning extends AutogenLayoutBrokeragePlanning {
    private _profileRow: RowBrokeragePlanningProfile;
    private filterObject: BrokeragePlanningFilter;
    private showingErrorToast: boolean;
    private arrivedAtShipper: boolean;
    private missingNextCheckCall: boolean;
    private noDriverInfoToday: boolean;
    private noDriverInfoTomorrow: boolean;
    private noDriverInfoTotal: boolean;
    private shipmentStatusError: boolean;
    private dataLoadActionId: string;
    private spot: string;
    private brokerageProfile: string;
    private timerId;

    private tabs: BrokeragePlanningTab[] = [
        { type: BrokerageMovementTableType.PERISCOPE },
        { type: BrokerageMovementTableType.AVAILABLE },
        { type: BrokerageMovementTableType.COVERED },
        { type: BrokerageMovementTableType.PROGRESS },
        { type: BrokerageMovementTableType.DELIVERED },
        { type: BrokerageMovementTableType.OFFERS },
        { type: BrokerageMovementTableType.HOLD }
    ];

    override async onLoad() {
        this.buttonFilter.visible = false;
        this.applyUrlParams();
        this.setupTables();
        this.showDynamicFilters();
        this.textboxId.addBeforeLookupModelSearchListener(event => {
            if (this.profileRow != null && this.profileRow.get("id", "") === this.textboxId.text)
                event.filter = null;
        });
        this.textboxId.onSelectItem = ((_textbox, _selection) => {
            if (this.textboxId.getFirstLookupModelData()?.get("id") != this.profileRow?.get("id")) {
                new ModelBrokeragePlanningProfile().searchSingle({ id: this.textboxId.getFirstLookupModelData()?.get("id") }).then(row => {
                    this.setProfileRow(row, false);
                });
            }
            return undefined;
        });
        this.setMinuteTimer();
        this.addUnmountListener(() => this.clearMinuteTimer());
    }

    private applyUrlParams() {
        const urlParams = UrlUtil.getPropsFromUrl(window.location.href);
        this.arrivedAtShipper = urlParams?.["arrivedAtShipper"];
        this.missingNextCheckCall = urlParams?.["missingNextCheckCall"];
        this.noDriverInfoToday = urlParams?.["noDriverInfoToday"];
        this.noDriverInfoTomorrow = urlParams?.["noDriverInfoTomorrow"];
        this.noDriverInfoTotal = urlParams?.["noDriverInfoTotal"];
        this.brokerageProfile = urlParams?.["bpProfile"];
        this.shipmentStatusError = urlParams?.["shipmentStatusError"];
        this.dataLoadActionId = urlParams?.["dataLoadActionId1"];
        this.spot = urlParams?.["spot"];
    }

    private showDynamicFilters() {
        const buttonNoDriverInfoToday = this.layoutCovered?.findComponentById('btn' + BrokerageMovementTableType.FILTER_NO_DRIVER_INFO_TODAY.id);
        if (buttonNoDriverInfoToday != null) {
            buttonNoDriverInfoToday.visible = !!this.noDriverInfoToday;
        }
        const buttonNoDriverInfoTomorrow = this.layoutCovered?.findComponentById('btn' + BrokerageMovementTableType.FILTER_NO_DRIVER_INFO_TOMORROW.id);
        if (buttonNoDriverInfoTomorrow != null) {
            buttonNoDriverInfoTomorrow.visible = !!this.noDriverInfoTomorrow;
        }
        const buttonNoDriverInfoTotal = this.layoutCovered?.findComponentById('btn' + BrokerageMovementTableType.FILTER_NO_DRIVER_INFO_TOTAL.id);
        if (buttonNoDriverInfoTotal != null) {
            buttonNoDriverInfoTotal.visible = !!this.noDriverInfoTotal;
        }
    }

    setupTables() {
        for (let i = 0; i < this.tabs.length; i++) {
            const tab = this.tabsetMain.components.find((component: Tab) => component.caption === `${this.tabs[i].type.caption}`) as Tab;
            const layout = tab?.components[0];
            if (layout instanceof BrokerageMovementTable || layout instanceof BrokeragePlanningPeriscope) {
                layout.configureTableForType(this.tabs[i].type);
                this.tabs[i].layout = layout;
                this.tabs[i].tab = tab;
                layout.brokeragePlanning = this;
                layout.tableMovements.addRowCreateListener((event: TableRowCreationEvent) => event.getTable().busy = false);
            }
            if (i === this.tabs.length - 1) {
                this.loadInitialProfile();
            }
        }
    }

    public get profileRow(): RowBrokeragePlanningProfile {
        return this._profileRow;
    }

    public setProfileRow(value: ModelRow, profileFiltered: boolean = false) {
        if (value != this._profileRow) {
            this._profileRow = value;
            this.filterObject = new BrokeragePlanningFilter(value);
            this.buttonFilter.visible = this._profileRow != null;
            if (this._profileRow != null) {
                this.searchMainDatasource();
                this.textboxId.text = profileFiltered ? "" : this.profileRow.get("id");
                this.buttonFilter.imageName = profileFiltered ? "profile_filtered" : "profile_filter";
                this.setDocumentTitle();
                this.showDateRange(this._profileRow.get("start_day"), this._profileRow.get("order_days"));
            }
        }
    }

    private setSecondaryFilters() {
        if (this.arrivedAtShipper) {
            this.layoutCovered.findComponentById('btn' + BrokerageMovementTableType.FILTER_ARRIVED_AT_SHIPPER.id).clicked();
        }
        else if (this.missingNextCheckCall) {
            this.layoutCovered.findComponentById('btn' + BrokerageMovementTableType.FILTER_MISS_NEXT_CALL_DATE.id).clicked();
            this.layoutProgress.findComponentById('btn' + BrokerageMovementTableType.FILTER_MISS_NEXT_CALL_DATE.id).clicked();
        }
        else if (this.noDriverInfoToday) {
            this.layoutCovered.findComponentById('btn' + BrokerageMovementTableType.FILTER_NO_DRIVER_INFO_TODAY.id).clicked();
        }
        else if (this.noDriverInfoTomorrow) {
            this.layoutCovered.findComponentById('btn' + BrokerageMovementTableType.FILTER_NO_DRIVER_INFO_TOMORROW.id).clicked();
        }
        else if (this.noDriverInfoTotal) {
            this.layoutCovered.findComponentById('btn' + BrokerageMovementTableType.FILTER_NO_DRIVER_INFO_TOTAL.id).clicked();
        }
        else if (this.spot === "include") {
            this.layoutAvailable.findComponentById('btn' + BrokerageMovementTableType.FILTER_SPOT_ORDERS.id).clicked();
        }
        else if (this.spot === "exclude") {
            this.layoutAvailable.findComponentById('btn' + BrokerageMovementTableType.FILTER_COMMITTED_ORDERS.id).clicked();
        }
        else if (this.shipmentStatusError) {
            switch (this.dataLoadActionId) {
                case "tabAvailableOrders": {
                    this.layoutAvailable.findComponentById('btn' + BrokerageMovementTableType.FILTER_SHIPMENT_STATUS_ERROR.id).clicked();
                    break;
                }
                case "tabCoveredOrders": {
                    this.layoutCovered.findComponentById('btn' + BrokerageMovementTableType.FILTER_SHIPMENT_STATUS_ERROR.id).clicked();
                    break;
                }
                case "tabDeliveredOrders": {
                    this.layoutDelivered.findComponentById('btn' + BrokerageMovementTableType.FILTER_SHIPMENT_STATUS_ERROR.id).clicked();
                    break;
                }
                case "tabInProgressOrders": {
                    this.layoutProgress.findComponentById('btn' + BrokerageMovementTableType.FILTER_SHIPMENT_STATUS_ERROR.id).clicked();
                    break;
                }
            }
        }
    }

    async loadInitialProfile() {
        const profileId = this.brokerageProfile ?? getAuthSettings()?.user_settings?.bp_profile;

        if (profileId == null)
            Snackbar.showSnackbar("You do not have a default brokerage planning profile defined. "
                + "Add a default profile to your preferences so your orders will automatically "
                + "display or you can select a profile above to get started.", { persist: true });
        else {
            const profileRow = await new ModelBrokeragePlanningProfile().searchSingle({ id: profileId });
            if (profileRow == null)
                Snackbar.showSnackbar("The profile [" + profileId + "] was not found.", { persist: true });
            else
                this.setProfileRow(profileRow, false);
        }
    }

    searchMainDatasource() {
        this.mainDataSource.search({
            profile_row: this.profileRow
        }).then(() => this.setSecondaryFilters());
    }

    getTabAndFilterCounts() {
        const countMap = {};
        const tabIndexMap = {};
        this.tabs.forEach((tab, index) => {
            countMap[tab.type.tableIdSuffix] = {};
            if (tab.type.moveStatus)
                tabIndexMap[tab.type.moveStatus] = index;
            else
                tabIndexMap[tab.type.tableIdSuffix] = index;
        })

        this.mainDataSource.data.forEach(row => {
            const types: BrokerageMovementTableType[] = [];
            if (row.get("move_status") in tabIndexMap) {
                types.push(this.tabs[tabIndexMap[row.get("move_status")]].type);
            }

            if (BrokerageMovementTableType.hasActiveOffer(row)) {
                types.push(this.tabs[tabIndexMap["Offers"]].type);
            }

            if (row.get("hold") === true) {
                types.push(this.tabs[tabIndexMap["OnHold"]].type);
            }

            types.forEach((type: BrokerageMovementTableType) => {
                const passedHoldFilterCheck = BrokerageMovementTableType.holdFilter(row, this.profileRow);
                if (!passedHoldFilterCheck && type != BrokerageMovementTableType.HOLD) {
                    return;
                }
                if (!countMap[type.tableIdSuffix]["totalCount"]) {
                    countMap[type.tableIdSuffix]["totalCount"] = 1;
                } else {
                    countMap[type.tableIdSuffix]["totalCount"] += 1;
                }

                if (type.secondaryFilters) {
                    type.secondaryFilters.forEach(sFilter => {
                        if (!countMap[type.tableIdSuffix][sFilter.caption])
                            countMap[type.tableIdSuffix][sFilter.caption] = { id: sFilter.id, caption: sFilter.caption, totalCount: 0 };
                        if (sFilter.filter(row)) {
                            countMap[type.tableIdSuffix][sFilter.caption]["totalCount"] += 1;
                        }
                    })
                }
            })
        })

        return countMap;
    }

    setDocumentTitle(rowCount?: number) {
        let countString = "";
        if (rowCount != null)
            countString = "(" + rowCount + ")";
        WindowTitle.set([this.profileRow.get("id"), countString].join(" "));
    }

    sourceBrokerageMovementsBeforeExecution(event: DataSourceExecutionEvent) {
        if (DataSourceAction.SEARCH == event.getAction()) {
            this.tabs.forEach(tab => {
                if (tab.layout != null) {
                    tab.layout.tableMovements.busy = true
                }
            });
        }
    }

    sourceBrokerageMovementsAfterExecution(event) {
        if (DataSourceAction.SEARCH == event.getAction()) {
            this.updateTabTitles();
            this.populateFilteredTables();
        }
    }

    populateFilteredTables(tableType?: BrokerageMovementTableType) {
        this.tabs.forEach((tab, index) => {
            if ((tableType != null && tableType != tab.type) || tab.layout == null)
                return;

            this.populateFilteredTable(tab, index);
        })
    }

    populateFilteredTable(tab: BrokeragePlanningTab, index: number) {
        const layout = tab.layout;
        const modelRows = this.sourceBrokerageMovements.data.filter(row => layout.filter(row, this.profileRow));
        layout.mainDataSource.setRowsAndMode(DataSourceMode.NONE, modelRows, null);
        if (index != this.tabsetMain.selectedIndex && layout.tableMovements.rowCount > 0) {
            // the following will populate up to 5 rows when the table is hidden.
            // This avoids populating them when the user switches tab
            const rowsToPopulate = Math.min(5, layout.tableMovements.rowCount)
            for (let i = 0; i < rowsToPopulate; i++)
                layout.tableMovements.rows[i]?.populateDOMIfNeeded();
        }
    }

    updateTabTitles() {
        const tabMap = this.getTabAndFilterCounts();
        this.tabs.forEach((tab, index) => {
            if (tab.layout == null) return;
            tab.layout.tableMovements.busy = false
            if (index == 0) {
                tab.tab.caption = tab.type.caption + " " + this.mainDataSource.data.length;
                this.setDocumentTitle(this.mainDataSource.data.length);
            } else {
                const tabCountMap = tabMap[tab.type.tableIdSuffix];
                tab.tab.caption = tab.type.caption + " " + (tabCountMap.totalCount ?? 0);
                for (const value in tabCountMap) {
                    if (value != "totalCount") {
                        const movementTable = tab.layout as BrokerageMovementTable;
                        const btn = movementTable.panelSecondaryFilters.findComponentById(`btn${tabCountMap[value].id}`) as Button;
                        btn.caption = `${tabCountMap[value].totalCount} ${tabCountMap[value].caption}`;
                    }
                }
            }
        });
    }

    buttonFilterOnClick(event: ClickEvent) {
        // for (const data of this.mainDataSource.data)
        //   log.info("Passes", data, this.filterObject.dataPasses(data));
        Layout.getLayout("lme/powerbroker/BrokeragePlanningProfileFilter", {
            backgroundColor: "defaultBackground", borderShadow: true, paddingBottom: 50, fillHeight: false, width: 1125
        }).addLayoutLoadListener(event => {
            this.showFilter(event.target as BrokeragePlanningProfileFilter);
        });
    }

    showFilter(filterLayout: BrokeragePlanningProfileFilter) {
        filterLayout.mainDataSource.createBlankRow().then(row => {
            row._appending = false;
            row.setValues(this.profileRow.data);
            filterLayout.mainDataSource.data = [row];
            filterLayout.mainDataSource.setRowIndexWithoutUpdate(0);
            filterLayout.mainDataSource.mode = DataSourceMode.UPDATE;
            filterLayout.buttonCancel.addClickListener(() => Overlay.hideOverlay(overlay));
            filterLayout.buttonApply.addClickListener(() => {
                if (filterLayout.validateSimple()) {
                    Overlay.hideOverlay(overlay);
                    if (row.hasChanged())
                        this.setProfileRow(row, true);
                }
            });
            (filterLayout.layoutProfile as BrokeragePlanningProfile).addDateRangeListeners();
            const overlay = Overlay.showInOverlay(filterLayout, { closeOnClickOff: false, greyedBackground: true, centered: true });
        })
    }

    /** This is an event handler for the onRemoteDataChange event of sourceBrokerageMovements.  */
    sourceBrokerageMovementsOnRemoteDataChange(event: DataSourceRemoteDataChangeEvent) {
        if (event.change.type !== ModelDataChangeType.DELETE && !this.filterObject.dataPasses(event.change.data))
            event.change.type = ModelDataChangeType.DELETE;
        const changedRow = event.change.data as ModelRow;
        const changedValues = this.getChangedData(changedRow);
        for (let i = 0; i < this.tabs.length; i++) {
            const changeTypeForTable = this.tabs[i].layout.filter(changedRow, this.profileRow) ? event.change.type : ModelDataChangeType.DELETE;
            const changeForTable = { type: changeTypeForTable, data: changedRow };
            this.tabs[i].layout.mainDataSource.handleDataChange(changeForTable);
            if (this.tabs[i].type.caption === "Carrier Offers" && changedValues["waterfall_in_progress"] != null) {
                this.handleCarrierOfferLiveUpdate(changedRow, this.tabs[i].layout as BrokerageMovementTable);
            }
        }
        this.mainDataSource.handleDataChange({ type: event.change.type, data: changedRow });
        /**
         * Typically a dataSource updates happen automatically on reDisplayRow, but in this case there are no bound components.
         * If handleDataChange() in the future handles this case, the following code can be removed.
         */
        if (event.change.type == ModelDataChangeType.UPDATE) {
            const movementId = changedRow.get("movement_id");
            const existingRow: boolean = this.mainDataSource.data.some(row => row.get("movement_id") === movementId);
            if (!existingRow) {
                this.mainDataSource.addRow(changedRow);
            }
        }
        this.updateTabTitles();
        event.preventDefault();
    }

    /** This is an event handler for the onSubscriberError event of sourceBrokerageMovements.  */
    sourceBrokerageMovementsOnSubscriberError(event) {
        log.error(event.error);
        if (!this.showingErrorToast) {
            this.showingErrorToast = true;
            Toast.showToast("There was a problem keeping this page up-to-date real time.  Try refreshing the page.", null, { persist: true, onDismiss: () => this.showingErrorToast = false });
        }
    }

    getChangedData(changedRow: ModelRow<any>) {
        const originalData = changedRow.get("originalData");
        const meta: ApiMetadata = changedRow.getMetadata();
        const result = {};
        for (const [key, value] of Object.entries(changedRow.data)) {
            if ((value instanceof ModelRow && value.isLookupModelDataRow()) || ModelRow.isLookupModelFieldName(key))
                continue;
            const orig = originalData[key];
            const changed = key != "originalData" && !changedRow.fieldsAreEqual(meta.output[key], orig, value);
            if (changed)
                result[key] = value;
        }
        return result;
    }

    async handleCarrierOfferLiveUpdate(changedRow: ModelRow, tableLayout: BrokerageMovementTable) {
        const changedTableRow = tableLayout.tableMovements.rows.find(tableRow => tableRow.data.get("movement_id") === changedRow.get("movement_id"));
        if (changedTableRow && changedTableRow.expanded) {
            const expandedRow = changedTableRow.getExpansionComponent() as Panel;
            const gridExpansion = expandedRow.findComponentById("ExpandedBrokerageMovementsGrid") as BrokerageMovementsGridExpansion;
            const waterfallInProgress = changedRow.get("waterfall_in_progress", false);
            if (waterfallInProgress) {
                await gridExpansion.layoutMovementOfferTabs.layoutWaterfallOffers.search();
            }
            gridExpansion.layoutMovementOfferTabs.updateWaterfallComponents(waterfallInProgress);
        }
    }

    /** This is an event handler for the afterTabSelection event of tabsetMain.  */
    tabsetMainAfterTabSelection(event: SelectionEvent) {
        if (event.newSelection instanceof Tab) {
            const table = event.newSelection.findComponentById((component: Component) => component.id?.startsWith("tableMovements") === true) as Table;
            if (table != null) {
                table.scrollToSelection();
                table.rows.forEach(row => row.setExpanded(false));
            }
        }
    }

    public showDateRange(startDay: string, daysOut: string) {
        let orderStartDate: Date;
        let orderEndDate: Date;
        let dateRange: string;
        try {
            if (!StringUtil.isEmptyString(startDay))
                orderStartDate = DateUtil.parseDateWithKeywords(startDay, true, false);
            else
                throw "Invalid start date"

            if (!StringUtil.isEmptyString(daysOut)) {
                const daysOutBaseDate = orderStartDate || new Date();
                orderEndDate = DateUtil.dateAdd(DatePart.DAY, daysOutBaseDate, parseInt(daysOut));
            } else {
                throw "No days out"
            }

            if (orderStartDate != null && orderStartDate.toString() != null
                && orderEndDate != null && orderEndDate.toString() != null) {
                dateRange = `${DateUtil.formatDate(orderStartDate)} - ${DateUtil.formatDate(orderEndDate)}`;
            } else {
                this.textBoxDateRange.visible = false;
            }
            this.textBoxDateRange.text = dateRange;
            this.textBoxDateRange.visible = true;
        } catch (error) {
            this.textBoxDateRange.visible = false;
        }
    }

    // START - Minute updater stuff
    private setMinuteTimer() {
        this.timerId = setTimeout(() => this.executeMinuteUpdates(), (60 - new Date().getSeconds()) * 1000);
    }

    private clearMinuteTimer() {
        this.timerId = null;
    }

    private async executeMinuteUpdates() {
        this.tabs.forEach(tab => tab.layout != null && tableMinuteUpdate(tab.layout as BrokerageMovementTable))
        this.setMinuteTimer();
    }
    // END - Minute updater stuff
}
