import { DatePart } from "./Date";

const MILLIS_PER_SECOND = 1000;
const MILLIS_PER_MINUTE = MILLIS_PER_SECOND * 60;
const MILLIS_PER_HOUR = MILLIS_PER_MINUTE * 60;
const MILLIS_PER_DAY = MILLIS_PER_HOUR * 24;

/** 
 * This class breaks a number of milliseconds down to its number of days, hours, minutes, seconds.
 * It supports passing a smallest and biggest time part.
 * 
 * The biggest time part is used to determine at which point the time parts stop rolling over.
 * For example, assume we pass the millisecond equivalent of 28 hours.  If our biggest part is DatePart.DAY,
 * the 'days' value of the Duration is 1 and the 'hours' value is 4.
 * If the biggest part is DatePart.HOUR, the 'days' value is 0 and the 'hours' value is 28.
 * 
 * The smallest part is used to round the result to the nearest time art.  For example, assume we pass the 
 * millisecond equivalent of 3 hours, 35 minutes, and 16 seconds.  If our smallest part is DatePart.MINUTE, 
 * the 'hours' value of the duration is 3 and the 'minutes' is 35.  If our smallest part is DatePart.HOUR,
 * the 'hours' value will be 4 and the 'minutes' will be 0. * 
 */
export class Duration {
    public millis: number;
    public seconds: number;
    public minutes: number;
    public hours: number;
    public days: number;

    constructor(millis: number, smallest: DatePart = DatePart.SECOND, biggest: DatePart = DatePart.DAY) {
        millis = this.roundToSmallest(millis, smallest);
        if (biggest === DatePart.DAY) {
            this.days = Math.trunc(millis / MILLIS_PER_DAY);
            millis %= MILLIS_PER_DAY;
        }
        if ([DatePart.DAY, DatePart.HOUR].includes(biggest)) {
            this.hours = Math.trunc(millis / MILLIS_PER_HOUR);
            millis %= MILLIS_PER_HOUR;
        }
        if ([DatePart.DAY, DatePart.HOUR, DatePart.MINUTE].includes(biggest)) {
            this.minutes = Math.trunc(millis / MILLIS_PER_MINUTE);
            millis %= MILLIS_PER_MINUTE;
        }
        if ([DatePart.DAY, DatePart.HOUR, DatePart.MINUTE, DatePart.SECOND].includes(biggest)) {
            this.seconds = Math.trunc(millis / MILLIS_PER_SECOND);
            millis = millis % MILLIS_PER_SECOND;
        }
        this.millis = millis;
    }

    public getPart(part: DatePart) {
        switch (part) {
            case DatePart.MILLI: return this.millis;
            case DatePart.SECOND: return this.seconds;
            case DatePart.MINUTE: return this.minutes;
            case DatePart.HOUR: return this.hours;
            case DatePart.DAY: return this.days;
        }
        throw new Error("Unsupported date part: " + part);
    }

    private roundToSmallest(millis: number, part: DatePart) {
        switch (part) {
            case DatePart.MILLI: return millis;
            case DatePart.SECOND: return this.round(millis, MILLIS_PER_SECOND);
            case DatePart.MINUTE: return this.round(millis, MILLIS_PER_MINUTE)
            case DatePart.HOUR: return this.round(millis, MILLIS_PER_HOUR);
            case DatePart.DAY: return this.round(millis, MILLIS_PER_DAY);
        }
        throw new Error("Unsupported date part: " + part);
    }

    private round(value: number, toNearest: number): number {
        return Math.trunc((value + (toNearest / 2)) / toNearest) * toNearest;
    }
}

export interface DurationPartFormat {
    suffix: string;
    suffixPlural: string;
    minDigits: number;
    separator: string;
    showZero: boolean;
}

/**
 * This class formats a duration of time as a string.  It allows specifying the format for each of the 
 * parts of the duration.  It takes into account:
 * - Rounding to the smallest time part that has where a format for that part is specified
 * - Suffixes of each time part (hr/min/sec vs hour/minute/second)
 * - Plural values (hrs/mins/secs) 
 * - Showing or hiding a part of the result when that part has a zero value (6hr 0min 10sec vs 6hr 10sec)
 * - Showing a minimum number of digits for each time part
 */
export class DurationFormat {
    private partFormats: Array<DurationPartFormat> = [];
    private _separator: string = ", ";
    private orderedParts = [DatePart.MILLI, DatePart.SECOND, DatePart.MINUTE, DatePart.HOUR, DatePart.DAY];

    public separator(separator: string): DurationFormat {
        this._separator = separator;
        return this;
    }

    public milliFormat(format?: Partial<DurationPartFormat>): DurationFormat {
        return this.withPartFormat(DatePart.MILLI, format);
    }

    public secondFormat(format?: Partial<DurationPartFormat>): DurationFormat {
        return this.withPartFormat(DatePart.SECOND, format);
    }

    public minuteFormat(format?: Partial<DurationPartFormat>): DurationFormat {
        return this.withPartFormat(DatePart.MINUTE, format);
    }

    public hourFormat(format?: Partial<DurationPartFormat>): DurationFormat {
        return this.withPartFormat(DatePart.HOUR, format);
    }

    public dayFormat(format?: Partial<DurationPartFormat>): DurationFormat {
        return this.withPartFormat(DatePart.DAY, format);
    }

    public withPartFormat(part: DatePart, format?: Partial<DurationPartFormat>): DurationFormat {
        this.partFormats[part] = format || {};
        return this;
    }

    public format(millis: number): string {
        let smallest: DatePart, biggest: DatePart;
        for (const part of this.orderedParts)
            if (this.partFormats[part] != null) {
                if (smallest == null)
                    smallest = part;
                biggest = part;
            }
        const duration = new Duration(millis, smallest, biggest);
        let result = "";
        let lastOutputtedFormat: DurationPartFormat;
        for (const part of this.orderedParts) {
            const format = this.partFormats[part];
            if (format != null) {
                const partValue = duration.getPart(part);
                if ((partValue != null && partValue > 0) || format.showZero) {
                    let partString = "";
                    partString += new String(partValue).padStart(format.minDigits, '0');
                    if (partValue === 1)
                        partString += format.suffix || "";
                    else
                        partString += format.suffixPlural || format.suffix || "";
                    if (result.length > 0)
                        partString += format.separator || this._separator;
                    result = partString + result;
                    lastOutputtedFormat = format;
                }
            }
        }
        if (result.length === 0)
            result = "0" + this.partFormats[smallest].suffix;
        return result;
    }
}
