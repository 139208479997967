import { ChangeEvent, Checkbox, DataSourceExecutionEvent, DataSourceMode, Label, Panel, Snackbar, TableRow, TableRowDisplayEvent, Textbox } from "@mcleod/components";
import { ModelRow, StringUtil } from "@mcleod/core";
import { makeTooltipCallbackFunction } from "./LocationMakeQuickInfo";
import { AutogenLayoutRecurringSchedule } from "./autogen/AutogenLayoutRecurringSchedule";
import { RowRecurringOrder } from "./models/ModelRecurringOrder";
import { RowStopRecOrder } from "./models/ModelStopRecOrder";

export class RecurringSchedule extends AutogenLayoutRecurringSchedule {
    private daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    private rowRecurringOrder: ModelRow<RowRecurringOrder>;
    private stopRecOrderStops: ModelRow<RowStopRecOrder>[];

    initialize(mode: DataSourceMode, recOrderRow: ModelRow, recOrderStops: ModelRow<RowStopRecOrder>[]) {
        this.initializeRecurringOrder(recOrderRow);
        this.initializeRecOrderStops(recOrderStops);
        this.mainDataSource.mode = mode; // trigger afterModeChange event for setup
        const customerId = recOrderRow.get("customer_id", null)
        if (customerId) {
            this.sourceCustomer.search({ "id": customerId });
        } else {
            this.labelName.caption = "No Customer Selected";
        }
    }

    initializeRecurringOrder(row: ModelRow<RowRecurringOrder>) {
        this.rowRecurringOrder = row;
    }

    initializeRecOrderStops(rows: ModelRow<RowStopRecOrder>[]) {
        this.stopRecOrderStops = rows;
    }

    async setup() {
        const isAppending = this.mainDataSource.activeRow._appending;
        await this.setRecurringOrderFields();
        this.setupDaysOnChangeListeners();
        if (isAppending) {
            await this.setRecurSchedStopInfo();
        }
        else {
            this.switchDaysofWeek.checked = this.checkDaysOfWeekChecked();
            const recurringBy = this.mainDataSource.activeRow.get("recurring_by", "D");
            this.setupOccurrencePanel(recurringBy);
            this.setRequiredOccurrenceFields(recurringBy);
        }
    }

    async onSave(): Promise<ModelRow<any>> {
        if (this.mainDataSource.activeRow.get("recurring_by") === "D" && !this.checkAtLeastOneDayOfWeekChecked()) {
            Snackbar.showWarningSnackbar("At least one day of the week is required when recurring daily is selected");
            return;
        }
        const updatedRow = await this.mainDataSource.post();
        this.slideOut();
        return updatedRow;
    }

    /** This is an event handler for the afterExecution event of sourceRecurSchedStop.  */
    sourceRecurSchedStopAfterExecution(event: DataSourceExecutionEvent) {
        this.setRecurSchedStopInfo();
    }

    /** This is an event handler for the onRowDisplay event of tableStops.  */
    tableStopsOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.target as TableRow;
        const modelRow: ModelRow = tableRow.data as ModelRow;
        tableRow.hideDragHandle();
        tableRow.table.dataSource.preventChangeNotifications = true;
        const stopType = modelRow.get("stop_type");
        const panelSchedOverview = tableRow.findComponentById("panelSchedOverview") as Panel;
        const labelPickupDelivery = panelSchedOverview.findComponentById("labelPickupDelivery") as Label;
        labelPickupDelivery.caption = stopType === "SO" ? "Delivery" : "Pickup";
        const labelLocation = tableRow.findComponentById("textboxLocationName") as Label;
        labelLocation.tooltipCallback = makeTooltipCallbackFunction(modelRow.get("location_id"), labelLocation);
    }

    private async setRecurringOrderFields() {
        if (this.mainDataSource.activeRow._appending) {
            await this.mainDataSource.createBlankRow();
        }

        for (const field in this.rowRecurringOrder.data) {
            if (field == "id")
                this.mainDataSource.activeRow.set("recurring_order_id", this.rowRecurringOrder.data[field]);
            else
                this.mainDataSource.activeRow.set(field, this.rowRecurringOrder.data[field]);
        }
        this.mainDataSource.displayDataInBoundComponents();
    }

    private async setRecurSchedStopInfo() {
        const stops = this.stopRecOrderStops as ModelRow[];
        let index = 0;
        for (const recur_sched_stop of stops) {
            if (this.mainDataSource.activeRow._appending) {
                await this.setupBlankStop(recur_sched_stop, index);
            }
            else {
                const recurSchedStopRow = this.sourceRecurSchedStop.data.find((stop: ModelRow) => stop.get("stop_id") === recur_sched_stop.get("id"));
                if (recurSchedStopRow) {
                    for (const field in recur_sched_stop.data) {
                        if (field != "id")
                            recurSchedStopRow.set(field, recur_sched_stop.data[field])
                    }
                }
            }
            this.setLocationText(recur_sched_stop);
            index++;
        }
        this.sourceRecurSchedStop.displayDataInBoundComponents();
    }

    sourceRecurringSchedAfterModeChange(event: DataSourceExecutionEvent) {
        this.setup();
    }

    private async setupBlankStop(recur_sched_stop: ModelRow, index: number) {
        recur_sched_stop.set("stop_id", recur_sched_stop.get("id"));
        const row = await this.sourceRecurSchedStop.createBlankRow();
        for (const field in recur_sched_stop.data) {
            if (field != "id")
                row.set(field, recur_sched_stop.data[field]);
        }
        this.sourceRecurSchedStop.addRow(new ModelRow('lme/dispatch/recur-sched-stop', true, row.data), index, false);
    }

    private setupOccurrencePanel(recurring_by: string) {
        this.panelDaysOfWeek.visible = recurring_by === "D";
        this.panelWeekMonthDayContainer.visible = recurring_by === "M" || recurring_by === "B";
        this.panelWeekdayMonth.visible = recurring_by === "M";
        this.panelDayMonth.visible = recurring_by === "B";
    }

    private setRequiredOccurrenceFields(recurring_by: string) {
        this.panelWeekdayMonth.forEveryChildComponent(comp => comp.required = recurring_by === "M" && comp instanceof Textbox);
        this.textboxMonthlyDate.required = recurring_by === "B";
    }

    private setupDaysOnChangeListeners() {
        this.panelDaysOfWeek.forEveryChildComponent(comp => {
            if (comp instanceof Checkbox) {
                comp.addChangeListener((event: ChangeEvent) => this.dayOnChange(event));
            }
        })
    }

    dayOnChange(event: ChangeEvent) {
        if (event.userInitiatedChange) {
            this.switchDaysofWeek.checked = this.checkDaysOfWeekChecked();
        }
    }

    /** This is an event handler for the onChange event of textboxRecurringBy.  */
    textboxRecurringByOnChange(event) {
        const textbox = event.target as Textbox;
        if (event.userInitiatedChange) {
            this.setupOccurrencePanel(textbox.selectedItem.value);
            this.setRequiredOccurrenceFields(textbox.selectedItem.value);
        }
    }

    /** This is an event handler for the onChange event of switchDaysofWeek.  */
    switchDaysofWeekOnChange(event) {
        if (event.userInitiatedChange) {
            this.setDaysOfWeekChecked(this.switchDaysofWeek.checked);
        }
    }

    setLocationText(stop: ModelRow) {
        const city_name = stop.get("city_name", "");
        const state = stop.get("state", "");
        if (stop.get("id") === this.rowRecurringOrder.data["shipper_stop_id"]) {
            this.textboxShipper.text = city_name + (StringUtil.isEmptyString(city_name) ? "" : ", ") + state;
        } else if (stop.get("id") === this.rowRecurringOrder.data["consignee_stop_id"]) {
            this.textboxConsignee.text = city_name + (StringUtil.isEmptyString(city_name) ? "" : ", ") + state;
        }
    }

    checkDaysOfWeekChecked(): boolean {
        return this.daysOfWeek.every(day => this.sourceRecurringSched.activeRow.get(day) === "Y");
    }

    checkAtLeastOneDayOfWeekChecked(): boolean {
        return this.daysOfWeek.some(day => this.sourceRecurringSched.activeRow.get(day) === "Y");
    }

    setDaysOfWeekChecked(checked: boolean): void {
        this.daysOfWeek.forEach(day => this.sourceRecurringSched.activeRow.set(day, checked));
        this.mainDataSource.displayDataInBoundComponents();
    }
}
