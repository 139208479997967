import { ModelRow } from "./ModelRow";

export class RowUpdateEvent {
    private _row: ModelRow;
    private _originator: any;
    private _preventable: boolean;
    private _preventReason: string;
    private _onPrevent: () => void;

    constructor(row: ModelRow, preventable: boolean, originator: any) {
        this._row = row;
        this._originator = originator;
        this._preventable = preventable;
    }

    get row(): ModelRow {
        return this._row;
    }

    get preventable(): boolean {
        return this._preventable;
    }

    get originator(): any {
        return this._originator;
    }

    get preventReason(): string {
        return this._preventReason;
    }

    get onPrevent(): () => void {
        return this._onPrevent;
    }

    preventDefault(reason: string, onPrevent?: () => void) {
        this._preventReason = reason;
        this._onPrevent = onPrevent;
    }
}
