import { Alignment, Color, DisplayType, DynamicLoader } from "@mcleod/core";
import { ImageName } from "@mcleod/images";
import { Component } from "../../base/Component";
import { ComponentPropDefinitionUtil, ComponentPropDefinitions, ComponentProps } from "../../base/ComponentProps";
import { PropType } from "../../base/PropType";
import { ImagePropDefinitions } from "../image/ImageProps";
import { ReadMoreType } from "../label/ReadMoreType";
import { ClearButtonVisible } from "./ClearButtonVisible";
import { ForcedCase } from "./ForcedCase";
import { LookupModelPopulatedButton } from "./LookupModelPopulatedButton";
import { TextboxVariant } from "./TextboxVariant";

export interface DropdownItem {
    caption: string;
    value?: string;
}

export interface TextboxProps extends ComponentProps {
    allowDropdownBlank: boolean;
    buttonImageName: ImageName;
    caption: string;
    captionAlignment: Alignment.LEFT | Alignment.TOP;
    captionVisible: boolean;
    captionVisibleInsideTable: boolean;
    clearButtonVisible: ClearButtonVisible;
    defaultDataValue: string;
    displayType: DisplayType;
    dropDownAnchor: Component;
    forcedCase: ForcedCase;
    imagePreName: string;
    imagePostName: string;
    lookupModel: string;
    lookupModelAllowFreeform: boolean;
    lookupModelAllowMultiSelect: boolean;
    lookupModelAllowSearchAll: boolean;
    lookupModelDisplayField: string;
    lookupModelExtraFieldList: string;
    lookupModelInputDelay: number;
    lookupModelLayout: string;
    lookupModelLayoutHeight: number;
    lookupModelLayoutWidth: number;
    lookupModelMinChars: number;
    lookupModelPopulatedButton: LookupModelPopulatedButton;
    lookupModelResultField: string;
    minValue: number;
    maxValue: number;
    multiline: boolean;
    nullDisplayValue: "hide" | string;
    items: string[] | (() => string[]) | DropdownItem[] | (() => DropdownItem[]);
    password: boolean;
    placeholder: string;
    placeholderColor: Color;
    printable: boolean;
    printableDuringAdd: boolean;
    printableDuringSearch: boolean;
    printableDuringUpdate: boolean;
    quickInfoLayout: string;
    readMoreType: ReadMoreType;
    spellcheck: boolean;
    text: string;
    variant: string;
    warningLabelVisible: boolean;
    dateDefault: string;
    manualAddLayout: string;
}

let textboxPropDefs: ComponentPropDefinitions;

export class TextboxPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (textboxPropDefs == null) {
            textboxPropDefs = {
                ...ComponentPropDefinitionUtil.getComponentPropertyDefinitions(),
                allowDropdownBlank: { type: PropType.bool, defaultValue: true, visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "Allows for control over the insertion of the '--' (blank) option in a dropdown.  If this property is 'true', the '--' option will be added to the dropdown if the field is not required.  If this property is 'false', the '--' option will not be added to the dropdown, regardless of whether or not the field is required.  The default value is 'true'." },
                caption: {
                    type: PropType.string,
                    affectsProps: ["id", "displayLabel"],
                    visibleInMcLeodTailor: true,
                    description: "This controls the label that appears near the input."
                },
                captionAlignment: {
                    type: PropType.string,
                    items: [Alignment.LEFT, Alignment.TOP],
                    defaultValue: Alignment.TOP,
                    visibleInMcLeodTailor: true,
                    description: "This controls whether the Textbox's caption appears on top of the Textbox or to the left of it."
                },
                captionVisible: {
                    type: PropType.bool,
                    defaultValue: true,
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether or not the caption of the Textbox will be shown.  If set to false, there will be no caption or additional whitespace where the caption would normally go."
                },
                captionVisibleInsideTable: { type: PropType.bool, category: "Uncommon", defaultValue: false, visibleInMcLeodTailor: true, description: "This specifies if the caption should be displayed when the Textbox is part of a TableRow. " },
                clearButtonVisible: {
                    type: PropType.string,
                    defaultValue: ClearButtonVisible.WHEN_FOCUSED,
                    items: Object.values(ClearButtonVisible),
                    visibleInMcLeodTailor: true,
                    description: "This specifies whether there is an 'X' button on the right side of the Textbox that will clear out the text.  Certain types of Textbox fields do not consider if the field has focus; they only care if they are allowed to show the X at all."
                },
                displayType: { type: PropType.string, items: Object.values(DisplayType), category: "Data", visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "The display type controls the display and validation of the component." },
                forcedCase: {
                    type: PropType.string,
                    items: [ForcedCase.NONE, ForcedCase.UPPER, ForcedCase.LOWER],
                    defaultValue: ForcedCase.NONE,
                    visibleInMcLeodTailor: true,
                    editableInMcLeodTailor: { baseComponent: true, customComponent: true },
                    description: "This controls whether the Textbox's value is automatically converted to upper or lower case.  The default option is to not automatically convert text."
                },
                format: { type: PropType.string, category: "Data", visibleInMcLeodTailor: true, description: "For some display types (numeric and date DisplayType), this controls the format that will be used to display data in this component." },
                imagePreName: { type: PropType.string, editor: ImagePropDefinitions.imagePropertyEditor, visibleInMcLeodTailor: true, description: "This specifies an image name that will appear on the left side of the Textbox." },
                imagePostName: { type: PropType.string, editor: ImagePropDefinitions.imagePropertyEditor, visibleInMcLeodTailor: true, description: "This specifies an image name that will appear on the right side of the Textbox." },
                items: { type: PropType.string, editor: stringArrayEditor, visibleInMcLeodTailor: true, description: "This specifies a list of items that the user can select from.  Setting items to an array or a function that returns an array will cause the Textbox to render a dropdown button." },
                lookupModel: { type: PropType.string, category: "Lookup model", editor: endpointSelector, description: "This specifies a model name that will be used to populate either a drop-down list of items or the auto complete for this Textbox." },
                lookupModelAllowFreeform: { type: PropType.bool, category: "Lookup model", defaultValue: false, description: "This specifies if the user can enter text that is not returned from the lookup." },
                lookupModelAllowMultiSelect: { type: PropType.bool, category: "Lookup model", defaultValue: false, description: "This specifies if the user is able to select more than one item from the dropdown.  When true, items are selected one at a time." },
                lookupModelAllowSearchAll: { type: PropType.bool, category: "Lookup model", defaultValue: true, description: "This specifies if the 'Search all records' option can be displayed in the dropdown." },
                lookupModelDisplayField: { type: PropType.string, category: "Lookup model", description: "This specifies the field from the lookupModel that will be displayed when the user selects a record in the lookup model auto-complete.\n\nNote that this can also be a combination of fields in curly braces.  For example, this is valid:\n\n {id} - {name} {city}, {state} {zip}" },
                lookupModelExtraFieldList: { type: PropType.string, category: "Lookup model", editor: fieldListSelector, description: "This specifies a field list file, whose fields will be added to the result of the lookup model output." },
                lookupModelInputDelay: { type: PropType.number, category: "Lookup model", defaultValue: 300, description: "This specifies the delay in milliseconds before searching the lookupModel." },
                lookupModelLayout: { type: PropType.string, category: "Lookup model", editor: layoutSelector, visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "This specifies the layout to use when displaying the drop down for the lookupModel.  Setting this causes the Textbox to display a button that allows drop-down." },
                lookupModelLayoutHeight: { type: PropType.number, category: "Lookup model", description: "This specifies the height of the drop down for the lookupModel.  This is only needed when it's different than the lookupModelLayout's default height." },
                lookupModelLayoutWidth: { type: PropType.number, category: "Lookup model", description: "This specifies the width of the drop down for the lookupModel.  This is only needed when it's different than the lookupModelLayout's default width." },
                lookupModelMinChars: { type: PropType.number, category: "Lookup model", defaultValue: 3, description: "This specifies the minimum number of characters that must be entered before the lookup model search will fire." },
                lookupModelPopulatedButton: { type: PropType.string, category: "Lookup model", defaultValue: LookupModelPopulatedButton.MAGNIFYING_GLASS, items: Object.values(LookupModelPopulatedButton), description: "Allows for selection of the button that will be displayed when the type-ahead field is populated.  The default value will be the magnifying glass.  Note that the X button cannot be displayed when the 'clearButtonVisible' property is set to false, and that freeform type-ahead fields will always display the X button when populated." },
                lookupModelResultField: { type: PropType.string, category: "Lookup model", description: "This specifies the field from lookupModel that will be used as this Textbox's value.  This value will be resolved back to the model." },
                maxValue: { type: PropType.number, visibleInMcLeodTailor: true, description: "This specifies the maximum value that can be entered for numeric/currency fields." },
                minValue: { type: PropType.number, visibleInMcLeodTailor: true, description: "This specifies the minimum value that can be entered for numeric/currency fields." },
                multiline: { type: PropType.bool, visibleInMcLeodTailor: true, description: "This toggles whether the user is able to enter text on multiple lines." },
                nullDisplayValue: { type: PropType.string, category: "Data", visibleInMcLeodTailor: true, description: "This specifies the value that should be displayed if this Textbox's bound data is empty.  We often set this to something like 'N/A' when it makes sense for the UX of the particular Textbox.  Setting this property to 'hide' will cause this Textbox to be hidden when its data is empty." },
                dateDefault: { type: PropType.string, category: "Data", visibleInMcLeodTailor: true, description: "This specifies what Date or Time value should be used for this textbox by default. If the display type is date range, to use two separate date values, separate both keywords with a ','. For exmaple, 't-60,t'" },
                defaultDataValue: { type: PropType.string, visibleInMcLeodTailor: true, category: "Data", source: "databound", description: "This specifies the default text value when entering add mode." },
                password: { type: PropType.bool, defaultValue: false, visibleInMcLeodTailor: true, editableInMcLeodTailor: { baseComponent: false, customComponent: true }, description: "This toggles whether the user's input will be hidden as they type." },
                placeholder: { type: PropType.string, visibleInMcLeodTailor: true, description: "When set, placeholder text will appear in an empty Input field.  It is usually used to give the user more information about what the Input is used for." },
                placeholderColor: { type: PropType.string, category: "Uncommon", description: "Specifies the color of the text of the placeholder." },
                quickInfoLayout: { type: PropType.string, editor: layoutSelector, visibleInMcLeodTailor: true, description: "Specifies a layout that will be opened as a tooltip when the user hovers over the Textbox.  The mainDataSource of this layout will be searched with the value in this Textbox." },
                onChange: { type: PropType.event, category: "Events", addListenerMethod: "addChangeListener", removeListenerMethod: "removeChangeListener", description: "This specifies a function to be called when the input value changes." },
                beforeLookupModelSearch: { type: PropType.event, category: "Events", addListenerMethod: "addBeforeLookupModelSearchListener", removeListenerMethod: "removeBeforeLookupModelSearchListener", description: "This specifies a function to be called before the lookup model is searched, to adjust the filter." },
                printable: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether the Textbox appears as a caption with a user-editable input box or whether it should be displayed as a caption with a non-editable Label.  This is often changed at runtime.",
                },
                printableDuringAdd: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether the Textbox should appear in its printable state during add mode.",
                },
                printableDuringSearch: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether the Textbox should appear in its printable state during search mode.",
                },
                printableDuringUpdate: {
                    type: PropType.bool,
                    defaultValue: false,
                    category: "Appearance",
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether the Textbox should appear in its printable state during update mode.",
                },
                readMoreType: { type: PropType.string, items: Object.values(ReadMoreType), defaultValue: ReadMoreType.NONE, visibleInMcLeodTailor: true, description: "This specifies if the view-only version of this Textbox (presented as a Label) is allowed to display the Read More link, and if so, what happens when the link is clicked." },
                spellcheck: { type: PropType.bool, defaultValue: true, visibleInMcLeodTailor: true, description: "This toggles whether the browser-provided spellcheck is enabled for this Input." },
                text: { type: PropType.string, visibleInMcLeodTailor: true, description: "This sets the actual text of the Input.  It is the same property that the user can affect as they type." },
                variant: { type: PropType.string, items: Object.values(TextboxVariant), defaultValue: TextboxVariant.OUTLINED, visibleInMcLeodTailor: true, description: "This sets the general look of the Textbox." },
                warningLabelVisible: {
                    type: PropType.bool,
                    visibleInMcLeodTailor: true,
                    description: "This toggles whether or not a validation warning label appears underneath the Textbox.  This label will be empty and appear as whitespace unless the validationWarning property is also set."
                },
                manualAddLayout: {
                    type: PropType.string,
                    editor: layoutSelector,
                    description: "When no record is found, a drop down shows to enter the data manually."
                }
            };
            ComponentPropDefinitionUtil.populateComponentPropNames(textboxPropDefs);
        }
        return textboxPropDefs;
    }
}

export function stringArrayEditor(value: string | string[], prop): Promise<Component> {
    // can't make a direct reference to MultiLineEditor at compile time
    let inputValue: string = null;
    if (typeof value === "string")
        inputValue = value.trim().split(",").join("\n");
    else
        inputValue = value.join("\n");
    const cls = DynamicLoader.getClassForPath("designer/ui/MultiLineEditor");
    return Promise.resolve(new cls({ returnAsArray: true, value: inputValue }));

    //started creating a different editor for this that rendered a table, but ran into too many issues and gave up.
    //the last issue was that the table didn't have a DataSource.  would be nice to make that work someday.
    //const cls = DynamicLoader.getClassForPath("designer/ui/StringArrayEditor");
    //return new cls({ returnAsArray: true, value: value });
}

export function endpointSelector(value, prop): Promise<Component> {
    // can't make a direct reference to PanelOpenModel at compile time
    const cls = DynamicLoader.getClassForPath("designer/ui/PanelOpenModel");
    return Promise.resolve(new cls());
}

export function layoutSelector(value, prop): Promise<Component> {
    // can't make a direct reference to PanelOpenLayout at compile time
    const cls = DynamicLoader.getClassForPath("designer/ui/PanelOpenLayout");
    return Promise.resolve(new cls());
}

export function fieldListSelector(value, prop): Promise<Component> {
    // can't make a direct reference to PanelOpenFieldList at compile time
    const cls = DynamicLoader.getClassForPath("designer/ui/PanelOpenFieldList");
    return Promise.resolve(new cls());
}
