import { Panel, PanelProps, Tree, TreeNode } from "@mcleod/components";
import { Api } from "@mcleod/core";
import { HomePageEditor } from "./HomePageEditor";
import { WidgetMenuGroup } from "./WidgetMenuGroup";
import { WidgetMenuItem } from "./WidgetMenuItem";

export class WidgetMenu extends Panel {
    private homeEditor: HomePageEditor;
    private tree: Tree;

    constructor(homeEditor: HomePageEditor, isEdiLicensed: boolean, props: Partial<PanelProps>) {
        super({ ...props, minWidth: 220 });
        this.homeEditor = homeEditor;
        Api.search("widget/list").then(response => {
            this.createMenuTree(!isEdiLicensed ? this.filterDatafusion(response.data[0]) : response.data[0]);
        });
    }

    createMenuTree(data: any) {
        this.tree = new Tree({
            selectedNodeBackgroundColor: "default.reverse", selectedNodeColor: "default", fillHeight: true, fillRow: true,
            padding: 0
        });

        const root = this.tree.makeTreeNodesFromObject(data, "title", "children", (node: TreeNode, data) => {
            if (node.data.children == null)
                node.setProps({ component: new WidgetMenuItem(this.homeEditor, node.data) });
            else
                node.setProps({ component: new WidgetMenuGroup(node) });
        });
        this.tree.getRootNode().setChildren(root.getChildren());
        this.add(this.tree);
    }

    syncMenu() {
        this.tree.getRootNode().recurseChildren(node => {
            if (node._component instanceof WidgetMenuGroup)
                node._component.syncMenu();
        });
    }

    filterDatafusion(data: any): any {
        const filteredChildren = data.children.filter(input => input.title !== "DataFusion");
        if (data?.children) {
            data.children = filteredChildren;
        }
        return data;
    }
}
