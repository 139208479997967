import { ClickEvent, DataSource, Label, Snackbar, TableRow, TableRowDisplayEvent } from "@mcleod/components";
import { ModelRow, StringUtil } from "@mcleod/core";
import { Orders } from "../../dispatch/src/Orders";
import { AutogenLayoutReplyResults } from "./autogen/AutogenLayoutReplyResults";

export class ReplyResults extends AutogenLayoutReplyResults {
    public tendersProcessedDataSource: DataSource;
    public resultData: ModelRow[];

    override onLoad(): void {
        this.replyResultsTable.selectedIndex = 0;
    }

    slideoutUnmountListener: () => void;

    replyResultsTableOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.getTableRow();
        const row = (event.target as TableRow).data;
        const purposeLabel = tableRow.findComponentById("purposeLabel") as Label;

        if (row.get("purpose_out") == "O") {
            purposeLabel.caption = "Original";
        }
        else if (row.get("purpose_out") == "U") {
            purposeLabel.caption = "Change";
        }
        else if (row.get("purpose_out") == "V") {
            purposeLabel.caption = "Cancel";
        }

        const orderLabel = tableRow.findComponentById("orderLabel") as Label;
        const orderId: string = row.get("orderid_out");

        if (StringUtil.isEmptyString(orderId) || orderId == "undefined") {
            orderLabel.caption = "N/A";
        }
    }

    replyResultsTableOnDoubleClick(event: ClickEvent) {
        const orderId = this.replyResultsTable.selectedRow.data.get("orderid_out");
        if (!StringUtil.isEmptyString(orderId) && orderId != "N/A" && orderId != "undefined") {
            Orders.navigateTo(orderId);
        } else {
            Snackbar.showSnackbar("Selected tender is not bound to an order.", { persist: true });
        }
    }
}
