import { Button, ButtonVariant, ChangeEvent, Cursor, Image, Label, Panel, Tree, TreeNode } from "@mcleod/components";
import { Collection, MenuItem, Navigation, ObjectUtil, VerticalAlignment } from "@mcleod/core";
import { ModelPermsSecureItem, RowPermsSecureItem } from "../models/ModelPermsSecureItem";
import { ModelPermsTree } from "../models/ModelPermsTree";
import { PanelPermsGrant } from "./PanelPermsGrant";
import { PermsContainer } from "./PermsContainer";
import { AutogenLayoutPermissionsMain } from "./autogen/AutogenLayoutPermissionsMain";

class MenuTreeNode extends TreeNode {
    page_identifier: string;
    secureImage: Image;
    groupImage: Image;
    isAction: boolean;
}

export class PermissionsMain extends AutogenLayoutPermissionsMain implements PermsContainer {
    panelGrant: PanelPermsGrant;
    permsById: Collection<RowPermsSecureItem> = {};
    nodesById: Collection<MenuTreeNode> = {};
    menuData: MenuItem;
    nonMenuData: MenuItem;
    actionPerms: MenuItem;
    apiPerms: MenuItem;
    treeMenuCasted: Tree<MenuTreeNode>;

    onLoad() {
        this.treeMenuCasted = this.treeMenu as Tree<MenuTreeNode>;
        this.panelGrant = (this.layoutPermsGrant as PanelPermsGrant);
        this.panelGrant.securityType = 'V';
        this.panelGrant.permsContainer = this;
        this.panelGrant.scrollY = true;
        this.loadPerms();
    }

    loadPerms() {
        new ModelPermsSecureItem().search({ top_level_only: true, security_type: "V" }).then(rows => {
            for (const row of rows.modelRows)
                this.permsById[this.getPermsKey(row)] = row;
        }).then(response => {
            new ModelPermsTree().search().then(menuData => {
                const response = menuData.modelRows[0];
                this.menuData = response.get("menu").menu;
                this.nonMenuData = response.get("non_menu").non_menu;
                this.actionPerms = response.get("actions").actions;
                this.apiPerms = response.get("api").api;
                this.populateTree();
            });
        });
    }

    textSearchMenuOnChange(_event: any) {
        this.populateTree();
    }

    treeMenuOnChange(event: ChangeEvent) {
        if (event.oldValue instanceof MenuTreeNode)
            this.updateNodeImage(event.oldValue, this.permsById[event.oldValue.page_identifier]?.get("allow_count"));
        const sel = this.treeMenuCasted.selectedNode;
        if (sel?.page_identifier == null)
            this.panelGrant.setItemBeingEdited(null, null, null, undefined);
        else {
            sel.secureImage.color = "primary.reverse";
            const perms = [];
            if (this.permsById[sel.page_identifier] != null)
                perms.push(this.permsById[sel.page_identifier]);
            if (sel.isAction) {
                const def = {
                    description: "Execute security",
                    permsType: "V",
                    availableToAllDescription: "Everyone can execute this action",
                    availableToNoneDescription: "No one can execute this action"
                };
                this.panelGrant.setItemBeingEdited("Action", sel.page_identifier, [def], perms);
            } else {
                const def = {
                    description: "View security",
                    permsType: "V",
                    availableToAllDescription: "Everyone can see this page",
                    availableToNoneDescription: "No one can see this page"
                };
                this.panelGrant.setItemBeingEdited(sel.page_identifier, "Entire screen", [def], perms);
            }
        }
    }

    private getPermsKey(row: RowPermsSecureItem) {
        const screenClass = row.get("screen_class");
        if (screenClass === "Action")
            return row.get("item_name");
        return screenClass;
    }

    permsChanged(row: RowPermsSecureItem, deleted: boolean) {
        if (row != null) {
            const key = this.getPermsKey(row);
            this.permsById[key] = deleted ? null : row;
            const node = this.nodesById[key];
            if (node != null)
                this.updateNodeImage(node, row.get("allow_count"));
        }
    }

    async updateNodeImage(node: MenuTreeNode, allowCount: number) {
        if (node.groupImage == null)
            return;
        node.groupImage.name = "threePeople";
        if (this.permsById[node.page_identifier] == null) {
            node.secureImage.name = "visibilityOn";
            node.secureImage.color = this.treeMenuCasted.selectedNode == node ? "primary.reverse" : "primary";
            node.groupImage.color = this.treeMenuCasted.selectedNode == node ? "primary.reverse" : "primary";
        } else {
            if (allowCount > 0)
                node.groupImage.name = "threePeopleBlueMiddle";
            else
                node.groupImage.color = this.treeMenuCasted.selectedNode == node ? "subtle.reverse" : "subtle";
            node.secureImage.name = "visibilityOff";
            node.secureImage.color = this.treeMenuCasted.selectedNode == node ? "subtle.reverse" : "subtle";
        }
    }

    populateTree() {
        const filter = this.textSearchMenu.text.toLowerCase();
        const rootChildren = [
            this.createHeaderNode("Menu items", this.menuData.items, filter, false),
            this.createHeaderNode("Non-menu items", this.nonMenuData.items, filter, false),
            this.createHeaderNode("Actions", this.actionPerms.items, filter, true),
            this.createHeaderNode("API", this.apiPerms.items, filter, true),
        ];
        for (let i = rootChildren.length - 1; i >= 0; i--)
            if (rootChildren[i].getChildCount() === 0)
                rootChildren.splice(i, 1);
        this.treeMenu.getRootNode().setChildren(rootChildren);
        if (filter.length > 0)
            this.treeMenu.getRootNode().expandAll();
        this.treeMenu.selectedNode = null;
    }

    private createHeaderNode(caption: string, items: MenuItem[], filter: string, isAction: boolean) {
        let filtered = items;
        if (filter?.length > 0) {
            filtered = ObjectUtil.deepCopy(filtered);
            filtered = ObjectUtil.filterRecursiveArrayContainingString<MenuItem>(filtered, "items", "caption", filter);
        }
        const result = new MenuTreeNode({ caption });
        for (const item of filtered)
            this.addTreeNode(result, item, isAction);
        result.removeChildrenMatching(node => node.expandedImageName != null && node.getChildCount() === 0);
        return result;
    }

    getSoleLeaf(node) {
        if (node.getChildCount() > 1)
            return null;
        else if (node.getChildCount() === 0)
            return node;
        else
            return this.getSoleLeaf(node.getChild(0));
    }

    private addTreeNode(parent: TreeNode, item: MenuItem, isAction: boolean) {
        if (item.items == null) {
            const node = this.createMenuNode(item, isAction);
            parent.addChild(node);
        }
        else {
            const node = new TreeNode({ caption: item.caption, expandedImageName: "minusInBox", collapsedImageName: "addInBox", allowSelect: false });
            parent.addChild(node);
            for (const child of item.items)
                this.addTreeNode(node, child, isAction);
        }
    }

    private createMenuNode(item: MenuItem, isAction: boolean): MenuTreeNode {
        const panel = new Panel({ fillRow: false, verticalAlign: VerticalAlignment.CENTER });
        const secureImage = new Image({ rowBreak: false, color: "primary", paddingRight: 8 });
        const groupImage = new Image({ rowBreak: false, color: "primary", paddingRight: 4, height: 20, width: 26 });
        const label = new Label({ caption: item.caption, rowBreak: false, minWidth: 280, cursor: Cursor.POINTER, allowSelect: false });
        panel.add(secureImage, groupImage, label);
        if (!isAction) {
            const button = new Button({ imageName: "detail", tooltip: "Open for editing field-level permissions", color: "subtle.darker", variant: ButtonVariant.round });
            button.addClickListener(() => Navigation.navigateTo("common/permissions/PermissionsEditor?page=" + item.path, { newTab: true }));
            panel.add(button)
        }
        const pageId = item.path;
        const result = new MenuTreeNode({ component: panel });
        result.page_identifier = pageId;
        result.secureImage = secureImage;
        result.groupImage = groupImage;
        result.isAction = isAction;
        this.nodesById[pageId] = result;
        this.updateNodeImage(result, this.permsById[pageId]?.get("allow_count"));
        return result;
    }
}
