import { DataSource, Label, Layout, Panel } from "@mcleod/components";

export abstract class AutogenLayoutOutstandingLoadTenderCounts extends Layout {
    labelCancelCaption: Label;
    labelCancelCount: Label;
    labelChangeCaption: Label;
    labelChangeCount: Label;
    labelOriginalCaption: Label;
    labelOriginalCount: Label;
    labelOverallCount: Label;
    labelTotal: Label;
    panel1: Panel;
    panel2: Panel;
    panel3: Panel;
    panel4: Panel;
    sourceLtxOutstandingCounts: DataSource;
}
