import { AutogenModelBrokerageTracking, AutogenRowBrokerageTracking } from "./autogen/AutogenModelBrokerageTracking";

export class ModelBrokerageTracking extends AutogenModelBrokerageTracking<RowBrokerageTracking> {
    static LOAD_TRACK_VENDOR = "L";
    static MACROPOINT_LITE_VENDOR = "M";
    static FOURKITES_VENDOR = "F";
    static PROJECT44_VENDOR = "P";
    static TRANSFLOVELOCITY_VENDOR = "T";
    constructor() {
        super(RowBrokerageTracking);
    }
}

export class RowBrokerageTracking extends AutogenRowBrokerageTracking {
}
