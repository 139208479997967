import { ClickEvent, DataDisplayEvent, Label, Layout, TableCell, TableRow, TableRowDisplayEvent } from "@mcleod/components";
import { Alignment, McLeodClassicIntegration, ModelRow, Navigation } from "@mcleod/core";
import { ThemeCommonPage } from "@mcleod/core/src/themes/common/ThemeCommonPage";
import { makeTooltipCallbackFunction } from "@mcleod/datafusion/src/EDITenderProfileMakeQuickInfo";
import { TenderListUtil } from "./TenderListUtil";
import { TransmissionStatusQuickInfo } from "./TransmissionStatusQuickInfo";
import { AutogenLayoutOutboundTenderList } from "./autogen/AutogenLayoutOutboundTenderList";

export class OutboundTenderList extends AutogenLayoutOutboundTenderList {
    private _searchTendersURL = "lme/datafusion/TenderList?id=";
    private _viewTenderURL = "lme/datafusion/ViewEdiOrder?mode=update&key=";

    setErrorsLabelColor(event) {
        TenderListUtil.setErrorsLabelColor(event, true);
    }

    setWarningsLabelColor(event) {
        TenderListUtil.setErrorsLabelColor(event, false);
    }

    buttonAdditionalInfoOnClick() {
        const orderId = this.mainDataSource.data[0]?.get("order_id") as string;
        Navigation.navigateTo(this.searchTendersURL + orderId, { newTab: true });
    }

    tableOutboundTendersOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        tableRow.addDblClickListener((event: ClickEvent) => {
            const tableRow = event.target as TableRow;
            const modelRow = tableRow?.data as ModelRow;
            const tenderId = modelRow?.get("id");
            if (tenderId) Navigation.navigateTo(this.viewTenderURL + tenderId, { newTab: true });
        });
    }

    openOutTransmissionLogsInClassic(event: ClickEvent) {
        const row = (event.target as TableCell).row as TableRow;
        const partnerId = row.data.get("partner_id");
        const direction = row.data.get("direction");
        const batch = row.data.get("gs06_group_cntlno");
        McLeodClassicIntegration.openClassicScreen("com.tms.client.loadmaster.edi.EntryEdiLog", "<ext> search receiver_id='" + partnerId +
            "' and direction='" + direction + "'" + " and func_batch='" + batch + "' and transaction_type='T'");
    }

    viewTransmissionStatusQuickInfo(event: DataDisplayEvent) {
        const transmissionLabel = event.target as Label;
        transmissionLabel.tooltipCallback = (base, originatingEvent) => {
            const row = (<Label>originatingEvent.target).boundRow;
            if (transmissionLabel.caption !== "Not Sent") {
                const layout = Layout.getLayout("lme/datafusion/TransmissionStatusQuickInfo") as TransmissionStatusQuickInfo;
                layout.addLayoutLoadListener(() => {
                    if (layout.sourceEdiLog !== null) {
                        layout.sourceEdiLog.search({
                            direction: row.get("direction"),
                            func_receiver_id: row.get("partner_id"),
                            receiver_id: row.get("alt_partner_id"),
                            batch: row.get("gs06_group_cntlno"),
                            func_version: row.get("version"),
                            transaction_type: 'T'
                        });
                    }
                });
                return transmissionLabel.showTooltip(layout,
                    {
                        position: transmissionLabel.tooltipPosition == null ? Alignment.LEFT : transmissionLabel.tooltipPosition,
                        pointerColor: ThemeCommonPage.quickInfo.borderLeftColor, originatingEvent: originatingEvent
                    },
                    { themeKey: "quickinfo", backgroundColor: "background5", borderRightColor: ThemeCommonPage.quickInfo.borderLeftColor, borderRightWidth: 12, color: null });
            }
        }
    }

    displayPartnerName(event) {
        const partnerNameLabel = <Label>event.target;
        partnerNameLabel.tooltipCallback = makeTooltipCallbackFunction(partnerNameLabel.boundRow?.get("id"), partnerNameLabel);
    }

    get searchTendersURL() {
        return this._searchTendersURL;
    }

    get viewTenderURL() {
        return this._viewTenderURL;
    }
}
