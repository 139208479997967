import { DataSourceMode } from "@mcleod/components";
import { ModelSearchResult } from "@mcleod/core";
import { AutogenLayoutCompanySettings } from "./autogen/AutogenLayoutCompanySettings";

export class CompanySettings extends AutogenLayoutCompanySettings {
    override onLoad() {
        this.sourceLoginSettings.search().then((result: ModelSearchResult) => {
            this.sourceLoginSettings.mode = result.modelRows.length === 0 ? DataSourceMode.ADD : DataSourceMode.UPDATE;
        });
        this.sourceLicenseContactSettings.search().then((result: ModelSearchResult) => {
            this.sourceLicenseContactSettings.mode = result.modelRows.length === 0 ? DataSourceMode.ADD : DataSourceMode.UPDATE;
        });
    }

    /** This is an event handler for the onChange event of checkboxAllowKeepSignedIn.  */
    checkboxAllowKeepSignedInOnChange(event) {
        this.textboxAllowSignedInDays.enabled = this.checkboxAllowKeepSignedIn.checked;
        if (!this.textboxAllowSignedInDays.enabled)
            this.textboxAllowSignedInDays.disabledTooltip = "This is disabled because remaining signed in is not allowed.";
    }
}
