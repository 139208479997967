/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelAgencyLink.ts                    *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/general/agency-link";

export class AutogenModelAgencyLink<RowType extends ModelRow> extends Endpoint<RowType, RowAgencyLinkFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowAgencyLink extends ModelRow<RowAgencyLinkFields> {
    constructor(values?: Partial<RowAgencyLinkFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowAgencyLinkFields {
    agency_id: string;
    company_id: string;
    description: string;
    id: string;
    is_primary: boolean;
    row_id: string;
    row_type: string;
}

