import { Component, Cursor, Label, Panel, StringOrPropsOrComponent } from "@mcleod/components";
import { Alignment, Navigation, StringUtil } from "@mcleod/core";

export class DistanceRateUtil {
    setQuickInfoTooltip(component: Component, tooltip: StringOrPropsOrComponent) {
        if (component)
            component.tooltipCallback = (originatingEvent) => {
                if (component["_internalShowTooltip"] != null)
                    return component["_internalShowTooltip"](tooltip, originatingEvent);
                return component.showTooltip(tooltip, null, { themeKey: "quickInfo", backgroundColor: "background5", color: null, tooltipPosition: Alignment.BOTTOM });
            };
    }

    navigateTo(URL: string): void {
        Navigation.navigateTo(URL, { newTab: true });
    }

    addClickListenerToLabel(labelName: Label, vendorURL: any) {
        labelName.addClickListener(() => this.navigateTo(vendorURL));
    }

    createHoverToDiscover(vendorsData: any, label: Label) {
        const vendor_name = vendorsData?.vendor_name;
        const rateTypeDesc = vendorsData?.rate_type_desc;
        const vendor = vendorsData?.vendor;
        label.tooltipPosition = Alignment.BOTTOM;

        const panel = new Panel({ id: "panelHoverToDisplay", maxWidth: 400, cursor: Cursor.DEFAULT }) as Panel;
        const fuelSurchargeNote = vendorsData?.fuel_surcharge_note;
        const labelOne = new Label({ id: "hoverIdOne", fontBold: true, fontSize: "xlarge", caption: `${vendorsData?.vendor_name} `, rowBreak: false });
        const labelTwo = new Label({ id: "hoverIdTwo", fontBold: true, caption: "", rowBreak: true, imageName: "detail", color: "primary", tooltip: `Click to go to Vendor [${vendorsData?.vendor_name}] Website`, cursor: Cursor.POINTER });
        const labelThree = new Label({ id: "hoverIdThree", caption: `Miles and tolls used in the rate calculation come from the upper right distance profile set in the Overview section of this screen.`, rowBreak: true });
        const labelFour = new Label({ id: "hoverIdFour", caption: "Rate calculations use non-rounded numbers, but display as rounded numbers, potentially resulting in minor differences.", rowBreak: true });
        const labelFive = new Label({ id: "hoverIdFive", caption: `Confidence rating: ${vendorsData?.confidence_rating} \n`, rowBreak: true });
        const labelSix = new Label({ id: "hoverIdSix", caption: rateTypeDesc, rowBreak: true });

        this.addClickListenerToLabel(labelTwo, vendorsData?.rate_url);
        panel.add(labelOne);
        panel.add(labelTwo);
        panel.add(labelThree);
        panel.add(labelFour);
        if(StringUtil.equalsIgnoreCase(vendor_name,"Greenscreens")){
            panel.add(labelFive);
        }
        panel.add(labelSix);

        if (!StringUtil.isEmptyString(fuelSurchargeNote)) {
            const labelDynamicA = new Label({ id: "hoverIdDynamicFuelSurcharge", caption: fuelSurchargeNote, rowBreak: true });
            panel.add(labelDynamicA);
        }
        this.setQuickInfoTooltip(label, panel);
    }

    populateValueTotalRateVendor(label: Label, vendorsData: any) {
        if (label.field === "total_rate_vendor" || label.field === "total_sell_rate") {
            label.caption = vendorsData[label.field];
            label.color = "subtle.darkest";
            label.fontSize = "xxlarge";
        }
    }

    populateValueRatePerMile(label: Label, vendorsData: any) {
        if (label.field === "total_rate_per_mile") {
            label.caption = "rpm / " + vendorsData[label.field];
            label.color = "subtle.dark";
            label.fontSize = "medium";
        }
    }
}
