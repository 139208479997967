/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelCarrierInsCtrl.ts                *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/powerbroker/carrier-ins-ctrl";

export class AutogenModelCarrierInsCtrl<RowType extends ModelRow> extends Endpoint<RowType, RowCarrierInsCtrlFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowCarrierInsCtrl extends ModelRow<RowCarrierInsCtrlFields> {
    constructor(values?: Partial<RowCarrierInsCtrlFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowCarrierInsCtrlFields {
    onboarding_server: string;
    packet_website_url: string;
    server: string;
    vendor_id: string;
}

