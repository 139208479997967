import { StringUtil } from "@mcleod/core";
import { ClickEvent, ComponentPropDefinitions, DataSource } from "..";
import { ComponentTypes } from "../base/ComponentTypes";
import { Button } from "../components/button/Button";
import { TailorExtension } from "./TailorExtension";
import { TailorExtensionButtonPropDefinitions, TailorExtensionButtonProps } from "./TailorExtensionButtonProps";

/**
 * This class is a button that will execute some pre-defined server-side extension, such as Document Power's 'invoke' program, or calling an external API.
 */
export class TailorExtensionButton extends Button implements TailorExtensionButtonProps {
    private _extension: TailorExtension;

    constructor(props?: Partial<TailorExtensionButtonProps>) {
        const propDefs = TailorExtensionButtonPropDefinitions.getDefinitions();
        super({
            caption: propDefs.caption.defaultValue,
            color: propDefs.color.defaultValue,
            minWidth: propDefs.minWidth.defaultValue,
            ...props
        });
        this.addClickListener((event: ClickEvent) => this.invokeExtension(this.dataSource));
    }

    //the tailor extension is handled as a string because of the limitations of how PropertiesTable
    //handles property values from specialty editors.  we can eventually make this look not-so-dodgy.
    public get extension(): string {
        if (this._extension == null)
            return null;
        return JSON.stringify(this._extension);
    }

    public set extension(value: string) {
        if (StringUtil.isEmptyString(value) === true) {
            this._extension = null;
            return;
        }
        const def = JSON.parse(value);
        this._extension = def != null ? new TailorExtension(def.id, def.descr, def.params) : null;
    }

    invokeExtension(dataSource: DataSource) {
        try {
            this.busy = true;
            if (this._extension != null)
                this._extension.invoke(dataSource);
        }
        finally {
            this.busy = false;
        }
    }

    override getPropertyDefinitions(): ComponentPropDefinitions {
        return TailorExtensionButtonPropDefinitions.getDefinitions();
    }

    override get serializationName() {
        return "tailorextensionbutton";
    }
}

ComponentTypes.registerComponentType("tailorextensionbutton", TailorExtensionButton.prototype.constructor, false);
