/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelLocationOrderHistory.ts          *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/dispatch/location-order-history";

export class AutogenModelLocationOrderHistory<RowType extends ModelRow> extends Endpoint<RowType, RowLocationOrderHistoryFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowLocationOrderHistory extends ModelRow<RowLocationOrderHistoryFields> {
    constructor() {
        super(AutogenUserSavedSearchEndpoint);
    }
}

export interface RowLocationOrderHistoryFields {
    charge_codes: string;
    empty_percentage: string;
    freight_charge_per_distance: string;
    freight_charge_per_loaded_dist: string;
    order_status: string;
    total_charge_per_distance: string;
    total_move_distance: string;
}

