import { ConversionUtil, getAuthSettings } from "@mcleod/core";
import { AutogenModelDispatchControl, AutogenRowDispatchControl, RowDispatchControlFields } from "./autogen/AutogenModelDispatchControl";

export class ModelDispatchControl extends AutogenModelDispatchControl<RowDispatchControl> {
    constructor() {
        super(RowDispatchControl);
    }
}

export function getDispatchControlBoolean(field: keyof RowDispatchControlFields, valueIfNull: boolean = false): boolean {
    const result = getDispatchControlValue(field);
    if (typeof result === "string")
        return ConversionUtil.parseBoolean(result);
    return result ?? valueIfNull;
}

export function getDispatchControlValue(field: keyof RowDispatchControlFields): any {
    return getAuthSettings().dispatch_control[0][field];
}

export class RowDispatchControl extends AutogenRowDispatchControl {
}
