import { DataSource, DataSourceMode, TableRow } from "@mcleod/components";
import { MovementOfferTabs } from "@mcleod/dispatch/src/MovementOfferTabs";
import { AssignedMovementOverview } from "../../dispatch/src/AssignedMovementOverview";
import { BrokerageMovementTableType } from "./BrokerageMovementTableType";
import { AutogenLayoutBrokerageMovementsGridExpansion } from "./autogen/AutogenLayoutBrokerageMovementsGridExpansion";

export class BrokerageMovementsGridExpansion extends AutogenLayoutBrokerageMovementsGridExpansion {
    private movementID: string;
    private orderID: string;
    private brokeragePlanningSource: DataSource;
    private status: string;
    private row: TableRow;
    private type: BrokerageMovementTableType;

    public initialize(row: TableRow, movementID: string, orderID: string, status: string, dataSource: DataSource, type: BrokerageMovementTableType): void {
        this.movementID = movementID;
        this.orderID = orderID;
        this.status = status;
        this.brokeragePlanningSource = dataSource;
        this.row = row;
        this.type = type;

        const isOffer = this.type === BrokerageMovementTableType.OFFERS;
        const movementOfferLayout = this.status === "A" ? isOffer ? this.layoutMovementOfferTabs : this.layoutMovementOffers : null;
        if (movementOfferLayout != null) {
            if (movementOfferLayout instanceof MovementOfferTabs) {
                movementOfferLayout.stopCountText = this.row.data.get("stops_pick_drop");
                movementOfferLayout.initialize(this.orderID, this.movementID, row.data);
            } else {
                movementOfferLayout.initialize(this.orderID, null, this.movementID);
            }
            this.layoutMovementOfferTabs.visible = isOffer;
            this.layoutMovementOffers.visible = !isOffer;
            this.layoutAssignedMovementOverview.visible = false;
            this.buttonSave.visible = false;
        } else if (this.movementID != null) {
            this.buttonSave.visible = true;
            this.layoutMovementOffers.visible = false;
            this.layoutMovementOfferTabs.visible = false;
            const overview: AssignedMovementOverview = this.layoutAssignedMovementOverview as AssignedMovementOverview;
            overview.addLayoutLoadListener(event => {
                this.buttonSave.enabled = false;
                this.buttonSave.dataSource = this.sourceMovement;
            })
            overview.visible = true;
            overview.panelContent.borderWidth = 0;
            overview.tabsetCarrierDriver.borderWidth = 0;
            this.sourceMovement?.search({ id: this.movementID }).then(result => {
                this.sourceMovement.mode = DataSourceMode.UPDATE;
                overview.movementActions.doAfterAction = () => { this.brokeragePlanningSource.search(this.brokeragePlanningSource.lastSearch) }
            });
        }

        this.buttonSave.addClickListener(() => {
            this.buttonSave.enabled = false;
        })
    }
}
