import { HorizontalAlignment } from "@mcleod/core";
import { Image } from "../image/Image";
import { Panel } from "../panel/Panel";
import { PanelProps } from "../panel/PanelProps";

/**
 * This is a wrapper around a spinner image.  This is just a shortcut to create a spinner image with
 * a few default properties set that center it in its parent container, though these properties can 
 * still be overridden if desired.
 */
export class Spinner extends Panel {
    private spinner: Image;

    constructor(props?: Partial<PanelProps>) {
        super({
            id: "spinnerContainer",
            align: HorizontalAlignment.CENTER,
            top: "max(0px, calc(0% - 20px))",
            fillRow: true,
            fillHeight: true,
            ...props
        });
        this.spinner = new Image({
            id: "spinner",
            name: "spinner",
            rotate: true,
            color: "primary.lightest",
            height: 40,
            width: 40
        });
        this.add(this.spinner);
    }

    get spinnerImage() {
        return this.spinner;
    }
}