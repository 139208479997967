/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelEdiCustomer.ts                   *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/datafusion/edi-customer";

export class AutogenModelEdiCustomer<RowType extends ModelRow> extends Endpoint<RowType, RowEdiCustomerFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowEdiCustomer extends ModelRow<RowEdiCustomerFields> {
    constructor(values?: Partial<RowEdiCustomerFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowEdiCustomerFields {
    alt_partner_id: string;
    carrier: string;
    check_manual_order: string;
    company_id: string;
    credit_rebill_filter: string;
    customer_id: string;
    default_correction_ind: string;
    direction: string;
    hold_214_for_reply: string;
    id: string;
    partner_id: string;
    partner_name: string;
    require_correction_ind: string;
    scac: string;
    time_zone: string;
    transaction_set: string;
    transaction_type: string;
    version: string;
}

