import { DataSource, Label, Layout, Panel, Table } from "@mcleod/components";

export abstract class AutogenLayoutTodayForecastOrders extends Layout {
    labelDayofMonth: Label;
    labelDayofWeek: Label;
    panel1: Panel;
    sourceTodayForecast: DataSource;
    sourceTodayForecastOrders: DataSource;
    tableTodayForecast: Table;
}
