import { CommonDialogs } from "@mcleod/common";
import { ClickEvent, DataSourceMode, Label, Layout, Textbox } from "@mcleod/components";
import { Alignment, Api, DisplayType, DisplayValue, ModelRow, getAuthSettings, getLogger } from "@mcleod/core";
import { AutogenLayoutEquipmentMatchDetail } from "../../dispatch/src/autogen/AutogenLayoutEquipmentMatchDetail";
import { AutogenLayoutOfferManagement } from "./autogen/AutogenLayoutOfferManagement";

const log = getLogger("powerbroker/OfferManagement");

export class OfferManagement extends AutogenLayoutOfferManagement {

    private settingFinancialText: boolean = false;

    override onLoad() {
        this.textboxCarrierOfferPayeeId.lookupModelDisplayField = "name";
        this.sourceOfferManagement.addAfterModeChangeListener(() => {
            if (this.sourceOfferManagement.activeRow?.data != null) {
                const mode = this.sourceOfferManagement.mode;
                if (DataSourceMode.UPDATE === mode)
                    this.setFinancialPanelText();

                this.buttonEquipmentRequired.visible = DataSourceMode.UPDATE === mode && this.sourceOfferManagement.activeRow.data["required_equipment"] != null;
                this.buttonCarrierRate.visible = DataSourceMode.UPDATE === mode;
                this.panelOrderMoveSearch.visible = (DataSourceMode.SEARCH === mode || DataSourceMode.ADD === mode);
            }
        })
        this.panelDimensionSurcharge.visible = getAuthSettings().dispatch_control[0].dimension_surchg == "Y"
    }

    /** This is an event handler for the onChange event of textboxCarrierOfferAmount and textboxCarrierOfferCounterOffer
     *
     * @param event The event that has taken place.  The event's target is usually the component on which the event has fired.
     */
    textboxAmountOrCounterOnChange(event) {
        if (event.userInitiatedChange)
            this.setFinancialPanelText();
    }

    setFinancialPanelText() {
        const row = this.sourceOfferManagement.activeRow;
        if (!this.settingFinancialText && row != null) {
            this.settingFinancialText = true;
            if (row == null) return;

            const setCalcValues = function (textbox: Textbox, lblMargin: Label, lblPercent: Label, lblDistance: Label): any {
                lblMargin.caption = null;
                lblPercent.caption = null;
                lblDistance.caption = null;

                const value = parseFloat(textbox.text?.replace(/^\s*\$\s*/, ''));
                if (value != null && !isNaN(value)) {
                    const totalCharges = row.get("order_total_chg", 0);
                    const margin = (totalCharges - value);
                    const percent = (margin != null && totalCharges > 0) ? ((margin / totalCharges) * 100) : null;
                    let distancePerMile: number;

                    lblMargin.caption = DisplayValue.getDisplayValue(margin, DisplayType.CURRENCY);
                    lblPercent.caption = percent ? percent.toFixed(2) + "%" : null;

                    if (row.get("move_distance", 0) > 0) {
                        distancePerMile = value / row.get("move_distance");
                        lblDistance.caption = DisplayValue.getDisplayValue(distancePerMile, DisplayType.CURRENCY);
                    }
                    return { margin: margin, percent: percent, perMile: distancePerMile }
                }
            }

            const offerValues = setCalcValues(this.textboxCarrierOfferAmount, this.labelMarginOffer, this.labelPercentageOffer, this.labelDistOffer);
            const counterValues = setCalcValues(this.textboxCarrierOfferCounterOffer, this.labelMarginCounter, this.labelPercentageCounter, this.labelDistCounter);

            this.textboxDifference.text = null;
            this.labelPercentageDifference.caption = null;
            this.labelDistDifference.caption = null;

            const offerAmt = parseFloat(this.textboxCarrierOfferAmount.text.replace("$", ""));
            const counterAmt = parseFloat(this.textboxCarrierOfferCounterOffer.text.replace("$", ""));
            if (!isNaN(offerAmt) && !isNaN(counterAmt))
                this.textboxDifference.text = "$" + (counterAmt - offerAmt).toFixed(2);

            if (offerValues?.percent != null && counterValues?.percent != null) {
                this.labelPercentageDifference.caption = (counterValues.percent - offerValues.percent).toFixed(2) + "%";
                if (counterValues.perMile != null) {
                    this.labelDistDifference.caption = DisplayValue.getDisplayValue(counterValues.perMile - offerValues.perMile, DisplayType.CURRENCY);
                }
            }
            this.settingFinancialText = false;
        }
    }

    /** This is an event handler for the onClick event of buttonCarrierRate
     *
     * @param event The event that has taken place.  The event's target is usually the component on which the event has fired.
     */
    buttonCarrierRateOnClick(event: ClickEvent) {
        this.buttonCarrierRate.busy = true;
        this.rateOffer().then(() => this.buttonCarrierRate.busy = false);
    }

    rateOffer(): Promise<any> {
        const row = this.sourceOfferManagement.activeRow;
        const movementId = this.sourceOfferManagement.activeRow.get("movement_id");
        const payeeId = this.sourceOfferManagement.activeRow.get("payee_id");
        if (!movementId && !payeeId) {
            CommonDialogs.showDialog("A movement and carrier is required to calculate a rate.");
            return Promise.resolve();
        }

        return Api.search(row._modelPath, {
            "action": "rateOffer",
            "movement_id": movementId,
            "payee_id": payeeId
        }).then(response => {
            this.updateModelRowRateData(row, response?.data?.[0]);
            this.textboxCarrierOfferAmount.displayData(row, null, 0);
            this.textboxCarrierOfferCarrierRateId.displayData(row, null, 0);
            this.setFinancialPanelText();

            const offerAmt = parseFloat(this.textboxCarrierOfferAmount.text.replace("$", ""));
            const counterAmt = parseFloat(this.textboxCarrierOfferCounterOffer.text.replace("$", ""));
            if (!isNaN(offerAmt) && !isNaN(counterAmt))
                this.textboxDifference.text = "$" + (counterAmt - offerAmt).toFixed(2);
        }).catch(err => {
            log.error("Error", err);
        });
    }

    updateModelRowRateData(row: ModelRow, rateResponseData: any) {
        // Need to explicity set this to null when undefined. ModelRow.setValue will remove
        // entries with undefined values from the _data[]. This causes ModelRow.post to not recognize these updates
        // because ModelRow.getChangedValues compares values currently in _data[] to the _originalData[]
        row.set("carrier_rate_lane_id", rateResponseData?.["carrier_rate_lane_id"] || null);
        row.set("carrier_rate_id", rateResponseData?.["carrier_rate_id"] || null);
        row.set("amount", rateResponseData?.["amount"] || null);

        row.set("commitment_frequency", rateResponseData?.["commitment_frequency"]);
        row.set("commitment_offered", rateResponseData?.["commitment_offered"]);
        row.set("commitment_met", rateResponseData?.["commitment_met"]);
        row.set("commitment_priority", rateResponseData?.["commitment_priority"]);

        this.sourceOfferManagement.displayDataInBoundComponents();
    }

    /** This is an event handler for the onClick event of buttonEquipmentRequired
     *
     * @param event The event that has taken place.  The event's target is usually the component on which the event has fired.
     */
    buttonEquipmentRequiredOnClick(event: ClickEvent) {
        const equipmentData = this.sourceOfferManagement.activeRow?.data?.["required_equipment"];
        if (equipmentData) {
            const layout = Layout.getLayout("lme/dispatch/EquipmentMatchDetail", {
                width: 800,
                height: window.innerHeight,
                backgroundColor: "backgroundSubtle",
                top: this.tabsetCarrierOffer.bounds.top,
                borderRadius: 4,
                borderWidth: 1,
                borderShadow: true,
                fillHeight: true,
                borderColor: "strokeSecondary",
                paddingBottom: 8
            }) as AutogenLayoutEquipmentMatchDetail;

            layout.addLayoutLoadListener(() => {
                equipmentData.map((data: any) => new ModelRow(layout.mainDataSource.url, false, data)).forEach(row => {
                    layout.mainDataSource.data.push(row);
                });
                layout.buttonClose.addClickListener(event => layout.slideOut());
                layout.mainDataSource.displayDataInBoundComponents();
                layout.slideIn({ direction: Alignment.RIGHT }, true);
            });
        }
    }
}
