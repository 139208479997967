import { ClickEvent, DataDisplayEvent, DataSourceMode, EdiLocation, Label, Location, Snackbar, TableCell, TableRow, TableRowDisplayEvent, Textbox, Toast } from "@mcleod/components";
import { Api, ModelRow, StringUtil } from "@mcleod/core";
import { AutogenLayoutDataFusionLocationCrossReference } from "./autogen/AutogenLayoutDataFusionLocationCrossReference";

export class DataFusionLocationCrossReference extends AutogenLayoutDataFusionLocationCrossReference {
    private _tenderId: string;
    private _ediOrderRow: TableRow;
    private _shipperLocReq: string = "N";
    saveButtonClicked: boolean = false;

    override onLoad() {
        this.sourceEdiStop.search({ order_id: this._tenderId }).then(() => this.sourceEdiStop.mode = DataSourceMode.UPDATE);
    }

    set shipperLocReq(req: string) {
        this._shipperLocReq = req;
    }

    onStopTypeDisplay(event: DataDisplayEvent) {
        const stopTypeLabel = <Label>event.target;
        const stopType = event.rowData.get("stop_type");
        if (stopType == "PU") {
            stopTypeLabel.caption = "Pickup";
        } else if (stopType == "SO") {
            stopTypeLabel.caption = "Delivery";
        }

    }

    onRowDataDisplay(event: DataDisplayEvent) {
        const locId = event.rowData.get("location_id");
        const orderSequence = event.rowData.get("order_sequence");
        const locIdTextbox = (event.target as TableCell).findComponentById("textboxLocationId") as Location;
        if ((!StringUtil.isEmptyString(locId) && StringUtil.isEmptyString(locIdTextbox.textCombined.text))) {
            locIdTextbox.textCombined.text = locId;
        }
        if (this._shipperLocReq === "Y" && orderSequence == 1) {
            locIdTextbox.required = true;
            locIdTextbox.textCombined.placeholder = "Required";
        }
    }

    onTableRowDisplay(event: TableRowDisplayEvent) {
        const row: TableRow = event.getTableRow();
        const modelRow: ModelRow = row.data as ModelRow;
        const location = (event.target as TableRow).findComponentById("textboxLocationId") as EdiLocation;

        //This is a hack to get around the fact that the combined Textbox is not bound to a row in this screen.
        //We need a better strategy for the Textbox's syncHoverCallback() so that it
        //doesn't get called when the user picks a value in the lookup model dropdown.
        location.textCombined["_getKeyValue"] = (): any => {
            return modelRow.get("location_id");
        }

        event.preventDefault();
        row.canBeDeleted = false;
    }

    saveOnClick(event: ClickEvent) {
        this.saveButtonClicked = true;
        const partnerId = this._ediOrderRow.data.get("partner_id");
        const version = this._ediOrderRow.data.get("version");
        let missingLocId: boolean = false;
        for (let x = 0; x < this.tableXref.rowCount; x++) {
            const ediLoc = this.tableXref.data[x].get("edi_loc_code");
            const loc = this.tableXref.data[x].get("location_id");
            const stopNo = this.tableXref.data[x].get("order_sequence");
            if (!StringUtil.isEmptyString(loc)) {
                this.tableXref.data[x].post();
                Api.post("lme/datafusion/add-location-xref", {
                    "partner_id": partnerId,
                    "version": version,
                    "edi_location": ediLoc,
                    "location_id": loc
                });
            } else if (stopNo == "1" && this._shipperLocReq == "Y") {
                const tableRow = this.tableXref.getRowByIndex(0);
                const locIdTextbox = tableRow.findComponentById("textboxLocationId") as Textbox;
                locIdTextbox.borderColor = "error";
                missingLocId = true;
            }
        }
        if (!missingLocId) {
            this.slideOut();
            Toast.showSuccessToast("DataFusion location cross reference added.");
        } else {
            Snackbar.showWarningSnackbar("Missing required shipper location code");
        }
    }

    get tenderId(): string {
        return this._tenderId;
    }
    set tenderId(value: string) {
        this._tenderId = value;
    }

    set ediOrderRow(row: TableRow) {
        this._ediOrderRow = row;
    }
    get ediOrderRow(): TableRow {
        return this._ediOrderRow;
    }
}
