import { DataSource, Label, Layout, Panel } from "@mcleod/components";

export abstract class AutogenLayoutNoDriverInfo extends Layout {
    labelToday: Label;
    labelTodayCount: Label;
    labelTomorrow: Label;
    labelTomorrowCount: Label;
    labelTotal: Label;
    labelTotalCount: Label;
    panel1: Panel;
    panel2: Panel;
    panel3: Panel;
    sourceNoDriverInfo: DataSource;
}
