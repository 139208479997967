import { Button, ClickEvent, Panel } from "@mcleod/components";

export class ExpandableFilterController {
    private panelSearchHeader: Panel;
    private panelSearchFilter: Panel;
    private buttonExpand: Button;
    private buttonClear: Button;
    private buttonSearch: Button;
    public onClear: (event: ClickEvent) => void;
    public onSearch: (event: ClickEvent) => void;

    public initialize(panelSearchHeader, panelSearchFilter) {
        this.panelSearchHeader = panelSearchHeader;
        this.panelSearchFilter = panelSearchFilter;
        this.buttonExpand = panelSearchHeader.findComponentById("buttonExpand");
        this.buttonExpand.addClickListener((event: ClickEvent) => this.buttonExpandOnClick(event));
        this.buttonClear = panelSearchFilter.findComponentById("buttonClear");
        if (this.buttonClear)
            this.buttonClear.addClickListener((event: ClickEvent) => this.onClear(event));
        this.buttonSearch = panelSearchFilter.findComponentById("searchButton");
        if (this.buttonSearch)
            this.buttonSearch.addClickListener((event: ClickEvent) => this.onSearch(event))
    }

    buttonExpandOnClick(event: ClickEvent) {
        this.toggle();
    }

    toggle(expand?: boolean) {
        const transitionProps = { speed: 100, paddingTop: 0 };
        if (!this.filterIsExpanded() || expand)
            this.panelSearchFilter.expand(transitionProps).then(() => {
                this.setSearchBordersExpanded();
            });
        else if (this.filterIsExpanded() || !expand)
            this.panelSearchFilter.collapse(transitionProps).then(() => {
                this.setSearchBordersCollapsed();
            });
    }

    private filterIsExpanded(): boolean {
        return this.panelSearchFilter.style.height != "0px";
    }

    setSearchBordersCollapsed() {
        this.buttonExpand.imageRotation = 90;
        this.panelSearchFilter.borderWidth = 0;
        this.panelSearchHeader.borderBottomWidth = 1;
        this.panelSearchHeader.borderColor = "strokeSecondary";
        this.panelSearchHeader.borderBottomRightRadius = 4;
        this.panelSearchHeader.borderBottomLeftRadius = 4;
    }

    setSearchBordersExpanded() {
        this.buttonExpand.imageRotation = 0;
        this.panelSearchFilter.borderWidth = 1
        this.panelSearchFilter.borderColor = "strokeSecondary";
        this.panelSearchFilter.borderRadius = 4;
        this.panelSearchFilter.borderTopWidth = 0;
        this.panelSearchFilter.borderTopLeftRadius = 0;
        this.panelSearchFilter.borderTopRightRadius = 0;
        this.panelSearchHeader.borderBottomWidth = 0;
        this.panelSearchHeader.borderBottomRightRadius = 0;
        this.panelSearchHeader.borderBottomLeftRadius = 0;
    }
}
