import { ModelUsers } from "@mcleod/common/src/models/ModelUsers";
import { BlurEvent, DataSourceMode, Snackbar } from "@mcleod/components";
import { Api, Currency, DateUtil, Model, ModelRow, ModelRowType, StringUtil, getAuthSettings, getLogger } from "@mcleod/core";
import { MovementCarrierPay } from "../../powerbroker/src/MovementCarrierPay";
import { AutogenLayoutPostToLoadBoards } from "./autogen/AutogenLayoutPostToLoadBoards";

const log = getLogger("lme.dispatch.PostToLoadBoards");

const equipmentTypeOptionsMap = {
    tarps: "T",
    expedited: "E"
};

export class PostToLoadBoards extends AutogenLayoutPostToLoadBoards {

    public get carrierRatesLayout(): MovementCarrierPay {
        return this.layoutCarrierRates as MovementCarrierPay;
    }

    async postingCityStateOnBlur(event: BlurEvent) {
        if (StringUtil.isEmptyString(this.postingCityState.getBasicValue())) {
            this.populatePostingCity(
                this.mainDataSource.activeRow.get("override_posting_city_id")
            );
            Snackbar.showWarningSnackbar(
                "Posting City / State / Zip can not be empty."
            );
        }
    }

    async textboxPuOverrideArriveEarlyOnBlur(event: BlurEvent) {
        const buttons = document.getElementsByClassName(
            "cmp-base btn-base btn-round"
        );
        const panel = document.querySelector(".cmp-base.pnl-panel.ovr-popup");
        const buttonArray = Array.from(buttons);
        if (buttonArray.some((button) => button === event.relatedTarget) || panel) {
            return; // Skip if the focus moves to the date button or if the date picker panel is open.
        }

        if (StringUtil.isEmptyString(this.textboxPuOverrideArriveEarly.text)) {
            const stopInfo = this.sourceStop.data[0];
            const modelData = await this.getStopInfoFromModel(stopInfo.get("id"));
            if (modelData) {
                if (modelData.get("pu_override_arrive_early")) {
                    stopInfo.set(
                        "pu_override_arrive_early",
                        new Date(modelData.get("pu_override_arrive_early"))
                    );
                } else {
                    stopInfo.set(
                        "pu_override_arrive_early",
                        new Date(modelData.get("sched_arrive_early"))
                    );
                    this.textboxPuOverrideArriveEarly.text = DateUtil.formatDate(
                        new Date(modelData.get("sched_arrive_early")),
                        "MM/dd/yyyy HHmm"
                    );
                }
                Snackbar.showWarningSnackbar(
                    "Override early pickup time can not be empty."
                );
            }
        }
    }

    async textboxPuOverrideArriveLateOnBlur(event: BlurEvent) {
        const buttons = document.getElementsByClassName(
            "cmp-base btn-base btn-round"
        );
        const panel = document.querySelector(".cmp-base.pnl-panel.ovr-popup");
        const buttonArray = Array.from(buttons);
        if (buttonArray.some((button) => button === event.relatedTarget) || panel) {
            return; // Skip if the focus moves to the date button or if the date picker panel is open.
        }
        if (StringUtil.isEmptyString(this.textboxPuOverrideArriveLate.text)) {
            const stopInfo = this.sourceStop.data[0];
            const modelData = await this.getStopInfoFromModel(stopInfo.get("id"));
            if (modelData) {
                if (modelData.get("pu_override_arrive_late")) {
                    stopInfo.set(
                        "pu_override_arrive_late",
                        new Date(modelData.get("pu_override_arrive_late"))
                    );
                } else {
                    stopInfo.set(
                        "pu_override_arrive_late",
                        new Date(modelData.get("sched_arrive_late"))
                    );
                    this.textboxPuOverrideArriveLate.text = DateUtil.formatDate(
                        new Date(modelData.get("sched_arrive_late")),
                        "MM/dd/yyyy HHmm"
                    );
                }
                Snackbar.showWarningSnackbar(
                    "Override late pickup time can not be empty."
                );
            }
        }
    }

    override onLoad() {
        this.sourceLocation.preventFocusFirstField = true;
    }
    async setDefaultById(stopId: string) {
        if (!StringUtil.isEmptyString(stopId)) {
            Model.searchSingleRecord("lme/dispatch/stop", { id: stopId }).then(
                async (result) => {
                    await this.setDefaultsByStop(result);
                }
            );
        }
    }

    async getStopInfoFromModel(stopId: string) {
        if (!StringUtil.isEmptyString(stopId)) {
            return await Model.searchSingleRecord("lme/dispatch/stop", {
                id: stopId,
            });
        }
    }

    async setDefaultsByStop(stopInfo: ModelRow) {
        this.setDisplayEquipTypeOptions();
        this.displayPostPreventionReasons(
            this.mainDataSource.activeRow.get("id"),
            this.mainDataSource.activeRow.get("loadboard")
        );
        await this._setUserInfoFields();
        await this._setEquipFields();
        await this._setRateFields();
        await this._setPostingCityField(stopInfo);
        await this._setOverrideStopFields(stopInfo);
        this.mainDataSource.displayDataInBoundComponents();
    }

    async displayPostPreventionReasons(order: string, loadboard: string) {
        if (StringUtil.isEmptyString(order) || loadboard !== "Y") {
            return;
        }

        await Api.post("lme/powerbroker/get-loadboard-prevent-posting-reasons", {
            order: order,
        }).then(async (response) => {
            const reasonsList = response?.data?.[0].prevent_posting_reasons;
            if (reasonsList) {
                const formattedReasons = reasonsList.map((reason) => `• ${reason}`);
                const message = formattedReasons.join("\n");

                if (message) {
                    this.panelMainLbReasons.visible = true;
                    this.layoutLbReasonsPanel.lbReasonsPanel.visible = true;
                    this.layoutLbReasonsPanel.labelReasons.visible = true;
                    this.layoutLbReasonsPanel.labelDetails.visible = true;
                    this.layoutLbReasonsPanel.horizontalspacer1.visible = true;
                    this.layoutLbReasonsPanel.labelReasons.visible = true;

                    // Create a div element to store the message
                    const messageDiv = document.createElement("div");
                    messageDiv.innerHTML = message;

                    this.layoutLbReasonsPanel.labelReasons.caption = messageDiv.innerHTML;
                    this.layoutLbReasonsPanel.labelReasons.fontBold = true;
                } else {
                    const panelToRemove = document.getElementById("panelMainLbReasons");
                    if (panelToRemove) {
                        panelToRemove.className = "";
                        panelToRemove.parentNode?.removeChild(panelToRemove);
                    }
                }
            }
        });
    }

    async _setOverrideStopFields(stopInfo: ModelRow) {
        const schedArriveEarly = stopInfo.get("sched_arrive_early");
        const schedArriveLate = stopInfo.get("sched_arrive_late");
        const overrideArriveEarly = stopInfo.get("pu_override_arrive_early");
        const overrideArriveLate = stopInfo.get("pu_override_arrive_late");

        if (StringUtil.isEmptyString(overrideArriveEarly)) {
            stopInfo.set("pu_override_arrive_early", new Date(schedArriveEarly));
        } else {
            stopInfo.set("pu_override_arrive_early", new Date(overrideArriveEarly));
        }

        if (StringUtil.isEmptyString(schedArriveLate) && StringUtil.isEmptyString(overrideArriveLate)) {
            this.textboxPuOverrideArriveLate.visible = false;
            this.labelAnd.visible = false;
            this.textboxPuOverrideArriveEarly.caption = "Override pickup time";
        } else if (StringUtil.isEmptyString(overrideArriveLate)) {
            stopInfo.set("pu_override_arrive_late", new Date(schedArriveLate));
            this.textboxPuOverrideArriveLate.visible = true;
            this.labelAnd.visible = true;
            this.textboxPuOverrideArriveEarly.caption = "Override earliest pickup";
        } else {
            stopInfo.set("pu_override_arrive_late", new Date(overrideArriveLate));
            this.textboxPuOverrideArriveLate.visible = true;
            this.labelAnd.visible = true;
            this.textboxPuOverrideArriveEarly.caption = "Override earliest pickup";
        }

        this.sourceStop.setRowsAndMode(DataSourceMode.UPDATE, [stopInfo]);
    }

    async _setPostingCityField(stopInfo: ModelRow) {
        const originId = stopInfo.get("location_id");
        let locationCityId = null;
        let pnnOverrideCityId = null;
        let originStopCityId = null;
        if (
            !StringUtil.isEmptyString(
                this.mainDataSource.activeRow.get("override_posting_city_id")
            )
        ) {
            await this.populatePostingCity(
                this.mainDataSource.activeRow.get("override_posting_city_id")
            );
        } else if (!StringUtil.isEmptyString(originId)) {
            pnnOverrideCityId = await this.getOverrideLocationCityId(originId);
            if (!StringUtil.isEmptyString(pnnOverrideCityId)) {
                await this.populatePostingCity(await pnnOverrideCityId);
            } else {
                locationCityId = await this.getLocationCityId(originId);
                await this.populatePostingCity(await locationCityId);
            }
        } else {
            originStopCityId = stopInfo.get("city_id");
            await this.populatePostingCity(await originStopCityId);
        }
    }

    async getOverrideLocationCityId(originLocationId: string): Promise<string> {
        if (originLocationId) {
            const result = await this.sourceLocation.search({ id: originLocationId });
            const rowLocation = result?.modelRows[0];
            return rowLocation?.get("pnn_city_id");
        }
    }

    async getLocationCityId(originLocationId: string): Promise<string> {
        if (originLocationId) {
            const result = await this.sourceLocation.search({ id: originLocationId });
            const rowLocation = result?.modelRows[0];
            return rowLocation?.get("city_id") as string;
        }
    }

    async populatePostingCity(cityId: string) {
        if (cityId) {
            Model.searchSingleRecord("common/city", { id: cityId }).then(
                (cityRow: ModelRow) => {
                    this.displayPostingCityData(cityRow);
                }
            );
        }
    }

    displayPostingCityData(cityRow: any) {
        if (cityRow) {
            const defaultData = new ModelRow(this.sourceCity.layoutName, false, {
                override_posting_city_id: cityRow.get("id"),
                name: cityRow.get("name"),
                state_id: cityRow.get("state_id"),
                zip_code: cityRow.get("zip_code"),
            });
            this.mainDataSource.activeRow.set(
                "override_posting_city_id",
                cityRow.get("id")
            );
            this.postingCityState.displayData(defaultData, null, 0);
            this.postingCityState.tooltipCallback =
                this.postingCityState.makeCityStateTooltip(
                    cityRow.get("id"),
                    this.postingCityState
                );
        }
    }

    async postDataSources() {
        log.debug("posting to load board")

        const equipmentTypeOptions = [];
        if (this.checkboxTarps.checked) {
            equipmentTypeOptions.push(equipmentTypeOptionsMap.tarps);
        }
        if (this.checkboxExpedited.checked) {
            equipmentTypeOptions.push(equipmentTypeOptionsMap.expedited);
        }
        this.mainDataSource.activeRow.set("equipment_type_options", equipmentTypeOptions.join("|"));
        this.mainDataSource.activeRow.set("loadboard","Y");

        await this.mainDataSource.post().then(row => {
            log.debug("posted row", row);
        });

        await this.sourceStop.post().then(row => {
            log.debug("Posted stop row", row);
        })

        if (this.carrierRatesLayout.mainDataSource.hasChanged()) {
            await this.layoutCarrierRates.mainDataSource.post().then(result => {
                log.debug("Posted target and max pay changes", result);
            });
        }
    }

    private async _setUserInfoFields() {
        let userId = this.mainDataSource.activeRow.get("pnn_loadboard_user_id");
        if (userId == null)
            userId = this.mainDataSource.activeRow.get("entered_user_id");
        if (userId == null)
            userId = getAuthSettings()?.user_settings?.user_id;

        if (userId != null) {
            await new ModelUsers().searchSingle({ id: userId }, "common/UserLookup").then(userRow => {
                if (!userRow) return;

                const lmData = new ModelRow("common/users", false, {
                    id: userRow.get("id"),
                    name: userRow.get("name"),
                    phone: userRow.get("phone")
                });
                lmData.type = ModelRowType.LOOKUP_MODEL_DATA;
                this.mainDataSource.activeRow.setLookupModelData("pnn_loadboard_user_id", lmData);

                if (!this.mainDataSource.activeRow.get("pnn_loadboard_user_id"))
                    this.mainDataSource.activeRow.set("pnn_loadboard_user_id", userRow.get("id"));

                if (!this.mainDataSource.activeRow.get("pnn_callback"))
                    this.mainDataSource.activeRow.set("pnn_callback", userRow.get("phone"));
                this.mainDataSource.displayDataInBoundComponents();
            })
        }

        this.textboxPnnLoadboardUserId.onSelectItem = ((textbox, selection) => {
            this.mainDataSource.activeRow.set("pnn_callback", (selection as ModelRow).get("phone"));
            this.textboxPnnCallback.displayData(this.mainDataSource.activeRow, null, 0);
            this.textboxPnnCallback.validate(false, false);
            return undefined;
        })
    }

    private async _setEquipFields() {
        const row = this.mainDataSource.activeRow;
        if (!StringUtil.isEmptyString(row.get("equipment_type_id"))) {
            await Model.searchSingleRecord("lme/dispatch/match-trailer-type", { id: row.get("equipment_type_id") }, "lme/dispatch/EquipmentTypeAppliesToTrailer").then(equipRow => {
                row.set("pnn_trailer_length", equipRow.get("equip_length"));
                row.set("pnn_trailer_width", equipRow.get("equip_width"));
            })
        }
    }

    private async _setRateFields() {
        const orderRow = this.mainDataSource.activeRow;
        this.carrierRatesLayout.buttonOverrideTargetPay.visible = false;
        this.carrierRatesLayout.buttonOverrideMaxPay.visible = false;
        if (orderRow?.get("curr_movement_id") != null) {
            await this.carrierRatesLayout.mainDataSource.search({ id: orderRow.get("curr_movement_id") }, "lme/dispatch/Movement").then(result => {
                const moveRow = this.carrierRatesLayout.mainDataSource.activeRow;
                const pnnRate = orderRow.get("pnn_rate", moveRow?.get("override_targetpay", moveRow.get("target_pay", null))) as Currency;

                if (pnnRate?.amount > 0) {
                    orderRow.set("pnn_rate", pnnRate.amount);
                    orderRow.set("pnn_rate_type", "F");
                }
            });
        }
    }

    private setDisplayEquipTypeOptions() {
        const shouldDisplay = this.mainDataSource.activeRow.get("show_equip_type_options");
        this.checkboxTarps.visible = shouldDisplay;
        this.checkboxExpedited.visible = shouldDisplay;
    }

    textboxPnnLoadboardUserIdBeforeLookupModelSearch(event) {
        event.filter.web_user_type = "U";
    }
}
