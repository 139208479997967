/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelPackagingType.ts                 *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/dispatch/packaging-type";

export class AutogenModelPackagingType<RowType extends ModelRow> extends Endpoint<RowType, RowPackagingTypeFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowPackagingType extends ModelRow<RowPackagingTypeFields> {
    constructor(values?: Partial<RowPackagingTypeFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowPackagingTypeFields {
    add_timestamp: Date;
    add_userid: string;
    company_id: string;
    edi_standard_code: string;
    field_code: string;
    field_code_desc: string;
    field_name: string;
    field_table_alias: string;
    mod_timestamp: Date;
    mod_userid: string;
}

