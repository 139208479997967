import { Button, ButtonVariant, DataDisplayEvent, Label, TableRowDisplayEvent, TableRowExpansionEvent } from "@mcleod/components";
import { DateUtil, ModelRow, ModelSearchResult, Navigation, StringUtil } from "@mcleod/core";
import { CommonDialogs } from "../CommonDialogs";
import { RequestDetail } from "./RequestDetail";
import { AutogenLayoutRequestList } from "./autogen/AutogenLayoutRequestList";

export class RequestList extends AutogenLayoutRequestList {
    logFileName: string;
    logFileContents: string;
    requestWithError: string;
    highlightText: string;

    onLoad() {
        this.table.addTool(new Button({
            imageName: "detail",
            color: "primary",
            variant: ButtonVariant.round,
            onClick: () => this.popout()
        }));
        if (window["passedLogReaderData"] != null)
            this.displayRows(window["passedLogReaderData"]);
    }

    public set filter(value: any) {
        this.addLayoutLoadListener(() => {
            this.table.busy = true;
            this.sourceLogReader.search({ ...value, log_name: this.logFileName, log_contents: this.logFileContents, detail_level: "request" }).then((response: ModelSearchResult) => {
                this.table.busy = false;
                this.requestData = response.modelRows[0].get("detail");
            }).catch(error => {
                this.table.busy = false;
                CommonDialogs.showError(error);
            });
        });
    }

    set requestData(value: any) {
        const rows: ModelRow[] = [];
        for (const item of value)
            rows.push(new ModelRow("", true, item));
        this.displayRows(rows);
    }

    private displayRows(rows: ModelRow[]) {
        this.sourceLogReader.data = rows;
        this.table.displayData(rows[0], rows, 0);
    }

    async popout() {
        const screen = window.screen as any;
        const left = screen.availLeft + (screen.availWidth / 2) - 500;
        const top = screen.availTop + (screen.availHeight / 2) - 350;
        const openedWindow = await Navigation.navigateTo("common/logreader/RequestList", { newTab: true, left, top, height: 700, width: 1000, windowDecorators: false });
        openedWindow["passedLogReaderData"] = this.sourceLogReader.data;
    }

    /** This is an event handler for the onDataDisplay event of labelEndpoint.  */
    labelEndpointOnDataDisplay(event: DataDisplayEvent) {
        const label = event.target as Label;
        label.caption = StringUtil.stringAfter(label.caption, "/api/");
    }

    /** This is an event handler for the onRowExpand event of table.  */
    tableOnRowExpand(event: TableRowExpansionEvent) {
        const detail = event.expandComponents[0] as RequestDetail;
        detail.maxDetailHeight = 400;
        const data: ModelRow = event.getTableRow().data;
        detail.highlightText = this.highlightText;
        if (!StringUtil.isEmptyString(data.get("requestId"))) {
            detail.logFileName = this.logFileName;
            detail.logFileContents = this.logFileContents;
            detail.requestId = data.get("requestId");
        }
    }

    /** This is an event handler for the onRowDisplay event of table.  */
    tableOnRowDisplay(event: TableRowDisplayEvent) {
        const rowData = event.getTableRow().data;
        if (rowData.get("requestId") === this.requestWithError)
            event.getTableRow().expanded = true;
    }

    /** This is an event handler for the onDataDisplay event of labelRequestTime.  */
    labelRequestTimeOnDataDisplay(event: DataDisplayEvent) {
        const millis = event.rowData.get("requestStart");
        if (millis > 0)
            (event.target as Label).caption = DateUtil.formatDateTime(new Date(millis), "MM-dd HH:mm:ss.SSS");
    }

    public removeDBStatColumns() {
        this.table.removeColumn(6);
        this.table.removeColumn(5);
    }
}
