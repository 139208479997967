import { Label, Layout, TableRow } from "@mcleod/components";
import { Alignment } from "@mcleod/core";
import { ThemeCommonPage } from "@mcleod/core/src/themes/common/ThemeCommonPage";
import { StatusErrorQuickInfo } from "./StatusErrorQuickInfo";

export class TenderListUtil {
    static setErrorsLabelColor(event, isError: boolean) {
        const row = event.target as TableRow;
        const label = isError ? row?.owner?.labelErrors : row?.owner?.labelWarnings as Label;
        label.tooltipCallback = (base, originatingEvent) => {
            TenderListUtil.quickInfoFunction(base, originatingEvent, label, isError);
        };
        label.imageName = "circleCheckEmpty";
        label.color = "success";
        if (isError ? label.caption !== "0 Errors" : label.caption !== "0 Warnings") {
            label.imageName = isError ? "warning-bold" : "circleWarning";
            label.color = isError ? "error" : "warning";
        }
    }

    static quickInfoFunction = (base, originatingEvent, label: Label, isError: boolean) => {
        if (isError ? label.caption !== "0 Errors" : label.caption !== "0 Warnings") {
            const statusId = (<Label>originatingEvent.target).boundRow.get("id");
            const layout = Layout.getLayout("lme/datafusion/StatusErrorQuickInfo") as StatusErrorQuickInfo;
            layout.addLayoutLoadListener(() => {
                if (layout.sourceEdiMapError != null) {
                    layout.sourceEdiMapError.search({ parent_id: statusId, parent_tablename: "edi_order", error_type: isError ? 'E' : 'W' });
                }
                if (!isError) layout.labelErrorDescription.caption = 'Warning Description';
            });
            return label.showTooltip(layout,
                {
                    position: label.tooltipPosition == null ? Alignment.RIGHT : label.tooltipPosition, anchorToMouse: true,
                    pointerColor: ThemeCommonPage.quickInfo.borderLeftColor, originatingEvent: originatingEvent
                },
                { themeKey: "quickinfo", backgroundColor: "background5", borderLeftColor: ThemeCommonPage.quickInfo.borderLeftColor, borderLeftWidth: 12, color: null });
        }
    }
}

