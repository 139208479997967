import { Button, DataSource, HorizontalSpacer, Label, Layout, Panel, Textbox, } from "@mcleod/components";

export abstract class AutogenLayoutSendLoadTender extends Layout {
    buttonCancel: Button;
    buttonSend: Button;
    buttonSendLater: Button;
    horizontalspacer1: HorizontalSpacer;
    labelCarrierId: Label;
    labelCarrierName: Label;
    labelHeaderTitle: Label;
    labelPurpose: Label;
    panelHeader: Panel;
    panelProgress: Panel;
    sourcePrepareTender: DataSource;
    textboxAddContact: Textbox;
    textboxContact: Textbox;
    textboxMustRespondBy: Textbox;
}
