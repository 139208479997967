import { DataSourceMode, Snackbar, Toast } from "@mcleod/components";
import { Api, getLogger, Navigation, StringUtil, UrlUtil } from "@mcleod/core";
import { ErrorHandlerFunction } from "@mcleod/core/src/ErrorHandler";
import { AutogenLayoutBrokerCounter } from "./autogen/AutogenLayoutBrokerCounter";

const log = getLogger("powerbroker/BrokerCounter");

export class BrokerCounter extends AutogenLayoutBrokerCounter {
    override async onLoad() {
        const props = UrlUtil.getPropsFromUrl(document.location.search);
        if (!StringUtil.isEmptyString(props.token)) {
            this.mainDataSource.search({ type: "pending", token: props.token }, this._errorHandler).then(response => {
                if (response?.modelRows?.length > 0) {
                    this.panelAvailable.visible = true;
                }
                else {
                    this.panelNotAvailable.visible = true;
                    this.panelAvailable.visible = false;
                }
            });
        } else {
            this.panelNotAvailable.visible = true;
            this.panelAvailable.visible = false;
        }

        this.mainDataSource.mode = DataSourceMode.NONE;
    }

    confirmCounter() {

        const counterAmount = this.textboxCounterAmount.getDataValue();
        const decimalPlace = counterAmount.indexOf(".");

        if (decimalPlace >= 0) {
            const rightOfTheDecimal = counterAmount.substring(decimalPlace + 1);
            if (rightOfTheDecimal.length > 2) {
                Snackbar.showWarningSnackbar("Offer amount must have a max precision of 2 decimal places");
                this.textboxCounterAmount.focus();
                return;
            }
        }

        if (this.textboxCounterAmount.validateSimple(true, true) && counterAmount > 0) {
            const props = UrlUtil.getPropsFromUrl(document.location.search);
            if (!StringUtil.isEmptyString(props.token)) {
                Api.post("lme/powerbroker/counter-counter-offer", { token: props.token, counter_amount: counterAmount }).then(response => {
                    if (!StringUtil.isEmptyString(response.data[0].success)) {
                        Toast.showSuccessToast(response.data[0].success);
                        this.panelAvailable.visible = false;
                        this.panelSuccess.visible = true;
                    }
                }).catch(err => {
                    Snackbar.showWarningSnackbar("An error occurred while processing your request.");
                    log.error(err);
                });
            }
        }
        else
            Snackbar.showWarningSnackbar("Counter amount must be a valid amount greater than 0.")
    }

    _errorHandler: ErrorHandlerFunction = (error) => {
        Navigation.navigateTo("");
    }

}
