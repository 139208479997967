import { DataSource, Image, Label, Layout, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutTargetPay extends Layout {
	imageMPact: Image;
	labelSmartTarget: Label;
	panelMPact: Panel;
	panelTargetPayText: Panel;
	sourceMovement: DataSource;
	textboxTargetPayCalc: Textbox;
}
