import { BlurEvent, Button, ClickEvent, Layout, SlideoutDecorator, Snackbar, Textbox, Toast } from "@mcleod/components";
import { Api, Currency, ModelRow, StringUtil } from "@mcleod/core";
import { makeTooltipCallbackFunction } from "./CarrierMakeQuickInfo";
import { AutogenLayoutElectronicAdvances } from "./autogen/AutogenLayoutElectronicAdvances";

export class ElectronicAdvances extends AutogenLayoutElectronicAdvances {
    private _movementId: string;
    private _issueAdvanceCallback: Function;

    private buttonSave = new Button({
        rowBreak: false,
        backgroundColor: "primary",
        color: "primary.reverse",
        caption: "Issue Advance",
        onClick: (event: ClickEvent) => this.issueAdvance()
    })

    constructor(props?) {
        super(props);
        this._movementId = props.movementId
        this._issueAdvanceCallback = props.issueAdvanceCallback
    }

    override onLoad(): void {
        this.textboxPayeeId.addBeforeLookupModelSearchListener(event => {
            event.filter.pay_advances = 'Y';
        })

        if (this._movementId) {
            this.mainDataSource.search({ "movement_id": this._movementId })
                .then(() => {
                    this.textboxAmount.enabled = !StringUtil.isEmptyString(this.textboxPayeeId.text) && !StringUtil.isEmptyString(this.textboxWirecode.text);
                });

            this.textboxWirecode.addBeforeLookupModelSearchListener((event) => {
                event.filter.movement_id = this._movementId;
            });

        }

        this.textboxPayeeId.onSelectItem = (textbox, selectedItem: ModelRow) => {
            const payeeId = textbox.getDataValue();

            this.validatePayee(payeeId)
                .then(result => {
                    if (!StringUtil.isEmptyString(result)) {
                        Snackbar.showWarningSnackbar(result)
                        this.activeRow.set({
                            payee_id: null,
                            amount: null
                        })
                        this.textboxAmount.enabled = false;
                    } else {
                        if (!StringUtil.isEmptyString(this.textboxWirecode.text)) {
                            this.textboxAmount.enabled = true;
                            this.activeRow.set({
                                amount: null
                            })
                        }
                    }
                    this.textboxPayeeId.tooltipCallback = makeTooltipCallbackFunction(selectedItem.data.id, this.textboxPayeeId);
                });
            return selectedItem.data.id;
        }

        this.textboxWirecode.onSelectItem = (textbox, selectedItem: ModelRow) => {
            const wirecode = textbox.getDataValue();

            this.validateWirecode(wirecode, this._movementId)
                .then(result => {
                    if (!StringUtil.isEmptyString(result)) {
                        Snackbar.showWarningSnackbar(result);
                        this.activeRow.set({
                            wirecode: null,
                            description: null,
                            deduction: null,
                            other: null,
                            amount: null
                        })
                        this.textboxAmount.enabled = false;
                    } else {
                        this.textboxAmount.enabled = !StringUtil.isEmptyString(this.textboxPayeeId.text);
                        this.activeRow.set({
                            wirecode: selectedItem.data.id,
                            description: selectedItem.data.descr,
                            deduction: selectedItem.data.create_deduction,
                            other: selectedItem.data.create_other_charge,
                            amount: null
                        })
                    }
                });

            return selectedItem.data.id;
        }

        this.textboxPayeeId.addBlurListener((event: BlurEvent) => {
            const isPayeeIdBlank = (event.target as Textbox).text == undefined || StringUtil.isEmptyString((event.target as Textbox).text);
            if (isPayeeIdBlank) {
                this.activeRow.set({
                    payee_id: null,
                    amount: null
                })
                this.textboxAmount.enabled = false;
            }
        })

        this.textboxWirecode.addBlurListener((event: BlurEvent) => {
            const isWirecodeBlank = StringUtil.isEmptyString((event.target as Textbox).text);
            if (isWirecodeBlank) {
                this.activeRow.set({
                    wirecode: null,
                    description: null,
                    deduction: null,
                    other: null,
                    amount: null
                })
                this.textboxAmount.enabled = false;
            }
        })

        this.textboxAmount.addBlurListener((event: BlurEvent) => {
            const currencyAmount = (event.target as Textbox).getDataValue() as Currency;
            const wirecode = this.textboxWirecode.getDataValue();
            const carrierId = this.textboxPayeeId.getDataValue();
            if (currencyAmount && currencyAmount.amount) {
                if (currencyAmount.amount > 0) {
                    this.validateAmount(currencyAmount, carrierId, wirecode, this._movementId)
                        .then(result => {
                            if (!StringUtil.isEmptyString(result)) {
                                Snackbar.showWarningSnackbar(result);
                                this.activeRow.set({
                                    amount: null
                                })
                            }
                        });
                } else {
                    Snackbar.showWarningSnackbar("Amount invalid: Must be numeric and postive")
                    this.activeRow.set({
                        amount: null
                    })
                }
            }
        });
    }

    public async validatePayee(payeeId: string): Promise<string> {
        //validate
        return Api.search("lme/powerbroker/validate-payee-for-advance", { "payee_id": payeeId })
            .then(response => response.data[0]?.result)
    }

    public async validateWirecode(wirecode: string, movementId: string): Promise<string> {
        //validate
        return Api.search("lme/powerbroker/validate-wirecode-for-advance", { "wirecode": wirecode, "movement_id": movementId })
            .then(response => response.data[0]?.result)
    }

    public async validateAmount(amount: Currency, carrierId: string, wirecode: string, movementId: string): Promise<string> {
        //validate
        return Api.search("lme/powerbroker/validate-amount-for-advance", { "amount": amount, "carrier_id": carrierId, "wirecode": wirecode, "movement_id": movementId })
            .then(response => response.data[0]?.result)
    }

    async delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    static showEditRowDecorator(movementId: string, issueAdvanceCallback?: Function) {
        const layout = Layout.getLayout("lme/dispatch/ElectronicAdvances", { movementId: movementId, issueAdvanceCallback: issueAdvanceCallback } as any) as ElectronicAdvances;

        const slideoutDecorator = new SlideoutDecorator({
            title: "Electronic Advances",
            layout: layout,
            fillVerticalSpace: true,
            width: 1000,
            overlayProps: { greyedBackground: true },
            addlComponents: layout.buttonSave,
            onClose: (cancelled: boolean) => layout.slideOut()
        });
    }

    private issueAdvance() {
        this.buttonSave.busy = true;
        const payeeId = this.textboxPayeeId.getDataValue();
        const wirecode = this.textboxWirecode.getDataValue();
        const amount = this.textboxAmount.getDataValue();
        const descr = this.textboxDescr.getDataValue();
        const deduction = this.switchDeduction.getDataValue();
        const other = this.switchOther.getDataValue()

        if (!(amount.amount > 0)) {
            this.buttonSave.busy = false;
            Snackbar.showWarningSnackbar("Amount cannot be zero");
            return;
        }

        const isPayeeValidPromise = this.validatePayee(payeeId);
        const isWirecodeValidPromise = this.validateWirecode(wirecode, this._movementId);
        const isAmountValidPromise = this.validateAmount(amount, payeeId, wirecode, this._movementId);

        Promise.all([isPayeeValidPromise, isWirecodeValidPromise, isAmountValidPromise])
            .then(values => {
                if (
                    StringUtil.isEmptyString(values[0])
                    && StringUtil.isEmptyString(values[1])
                    && StringUtil.isEmptyString(values[2])
                ) {
                    Api.post("lme/dispatch/issue-advance",
                        {
                            "movement_id": this._movementId,
                            "payee_id": payeeId,
                            "wirecode": wirecode,
                            "description": descr,
                            "amount": amount,
                            "deduction": deduction,
                            "other": other
                        }).then(response => {

                            this.buttonSave.busy = false;
                            const { amount, carrier_name, check_code } = response.data[0];
                            if (!StringUtil.isEmptyString(check_code)) {
                                this.slideOut();
                                setTimeout(() => {
                                    Toast.showSuccessToast("Success", `Advance issued for ${amount} to ${carrier_name}. Code is ${check_code}`, null);
                                    if (this._issueAdvanceCallback) {
                                        this._issueAdvanceCallback();
                                    }
                                }, 500);
                            }
                        })
                        .catch(reason => {
                            this.buttonSave.busy = false;
                            throw reason;
                        })
                } else {
                    this.buttonSave.busy = false;
                    if (!StringUtil.isEmptyString(values[0])) {
                        Snackbar.showWarningSnackbar(values[0])
                        this.activeRow.set({
                            payee_id: null,
                            amount: null
                        })
                        this.textboxAmount.enabled = false;
                    }
                    if (!StringUtil.isEmptyString(values[1])) {
                        Snackbar.showWarningSnackbar(values[1]);
                        this.activeRow.set({
                            wirecode: null,
                            description: null,
                            deduction: null,
                            other: null,
                            amount: null
                        })
                        this.textboxAmount.enabled = false;
                    }
                    if (!StringUtil.isEmptyString(values[2])) {
                        Snackbar.showWarningSnackbar(values[2]);
                        this.activeRow.set({
                            amount: null
                        })
                    }
                }
            })
    }

    set issueAdvanceCallback(cb: Function) {
        this._issueAdvanceCallback = cb;
    }
}
