import { DataLoadActionHandler, DataLoadActionType } from "@mcleod/common";
import { ClickEvent } from "@mcleod/components";
import { Navigation, UrlUtil } from "@mcleod/core";
import { AutogenLayoutNoDriverInfo } from "./autogen/AutogenLayoutNoDriverInfo";

export class NoDriverInfo extends AutogenLayoutNoDriverInfo {

    /** This is an event handler for the onClick event of labelTodayCount.  */
    labelTodayCountOnClick(event: ClickEvent) {
        const urlParams: string[] = [];
        let dlaCount = 0;
        urlParams.push("noDriverInfoToday=true");
        urlParams.push(DataLoadActionHandler.createUrlQueryParamString(DataLoadActionType.SELECT_TAB, "tabCoveredOrders", ++dlaCount));

        Navigation.navigateTo("lme/powerbroker/BrokeragePlanning" + UrlUtil.buildQueryString(urlParams));
    }

    /** This is an event handler for the onClick event of labelTomorrowCount.  */
    labelTomorrowCountOnClick(event: ClickEvent) {
        const urlParams: string[] = [];
        let dlaCount = 0;
        urlParams.push("noDriverInfoTomorrow=true");
        urlParams.push(DataLoadActionHandler.createUrlQueryParamString(DataLoadActionType.SELECT_TAB, "tabCoveredOrders", ++dlaCount));

        Navigation.navigateTo("lme/powerbroker/BrokeragePlanning" + UrlUtil.buildQueryString(urlParams));
    }

    /** This is an event handler for the onClick event of labelTotalCount.  */
    labelTotalCountOnClick(event: ClickEvent) {
        const urlParams: string[] = [];
        let dlaCount = 0;
        urlParams.push("noDriverInfoTotal=true");
        urlParams.push(DataLoadActionHandler.createUrlQueryParamString(DataLoadActionType.SELECT_TAB, "tabCoveredOrders", ++dlaCount));

        Navigation.navigateTo("lme/powerbroker/BrokeragePlanning" + UrlUtil.buildQueryString(urlParams));
    }
}
