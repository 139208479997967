import { UserQuickInfo } from "@mcleod/common/src/UserQuickInfo";
import { Button, ClickEvent, Component, DataDisplayEvent, DataSourceMode, Image, Label, Layout, List, Panel, Table, TableRow, TableRowDisplayEvent, TableRowMode, Textbox } from "@mcleod/components";
import { getRelevantModelRow } from "@mcleod/components/src/base/ComponentDataLink";
import { Api, DisplayType, FieldUpdateEvent, HorizontalAlignment, ModelRow, RowUpdateEvent, StringUtil } from "@mcleod/core";
import { CarrierAssignmentStepper } from "@mcleod/powerbroker/src/CarrierAssignmentStepper";
import { makeTooltipCallbackFunction } from "./CarrierMakeQuickInfo";
import { CarrierOfferInfo } from "./CarrierOfferSlideout";
import { CounterOffers } from "./CounterOffers";
import { DispatchUtil } from "./DispatchUtil";
import { OfferTabEventAction, OfferTabType } from "./MovementOfferTabs";
import { MovementOffers } from "./MovementOffers";
import { WaterfallOffers } from "./WaterfallOffers";

export enum OfferSource {
    BLAST = "BLAST",
    QUICKOFFER = "QUICKOFFER",
    WATERFALL = "WATERFALL"
}

export class OfferUtil {
    private carrierQualificationErrors = new Map<string, string>();
    private layout: MovementOffers | WaterfallOffers | CounterOffers;
    private movementID: string;
    private moveRow: ModelRow;

    constructor(movementID: string, moveRow: ModelRow, layout: MovementOffers | WaterfallOffers | CounterOffers) {
        this.movementID = movementID;
        this.moveRow = moveRow;
        this.layout = layout;
        this.addTableListeners();
    }

    addTableListeners() {
        this.layout.tableOffers.addContentsChangedListener(() => {
            this.layout.onTabEvent({ type: this.getTabType(), action: OfferTabEventAction.CONTENTS_CHANGED });
        });
    }

    getTabType(): OfferTabType {
        return this.layout instanceof MovementOffers ? OfferTabType.OFFER_HISTORY : this.layout instanceof WaterfallOffers ? OfferTabType.WATERFALL : OfferTabType.COUNTER;
    }

    setOfferStatusLabel(status: string, statusPanel: Panel) {
        enum Styles {
            GREENFILL,
            GREEN,
            ORANGE,
            RED
        }
        let style: Styles;
        let caption: string;
        switch (status) {
            case "Z":
                style = Styles.ORANGE;
                caption = "PENDING ACCEPT";
                break;
            case "A":
                style = Styles.GREENFILL;
                caption = "ACCEPTED";
                break;
            case "O":
                style = Styles.ORANGE;
                caption = "COUNTERED";
                break;
            case "D":
                style = Styles.RED;
                caption = "Declined";
                break;
            case "E":
                style = Styles.RED;
                caption = "Expired";
                break;
            case "P":
                style = Styles.GREEN;
                caption = "Pending";
                break;
            case "S":
                style = Styles.RED;
                caption = "Skipped";
                break;
            case "C":
                style = Styles.RED;
                caption = "Cancelled";
                break;
            default:
                style = Styles.GREEN;
                caption = "Initial Offer"
                break;
        }

        const statusLabel = statusPanel.findComponentById("labelStatus") as Label;
        const caret = statusPanel.findComponentById("imageCaret");

        statusLabel.caption = caption;
        const color = style === Styles.ORANGE ? "warning.reverse" :
            style === Styles.RED ? "error" :
                style === Styles.GREENFILL ? "success.reverse" : "success";
        const borderColor = style === Styles.ORANGE ? "warning" :
            style === Styles.RED ? "error" : "success";
        const backgroundColor = style === Styles.ORANGE ? "warning" :
            style === Styles.GREENFILL ? "success" : null;

        statusPanel.borderColor = borderColor;
        statusPanel.backgroundColor = backgroundColor;
        statusLabel.color = color;
        if (caret != null)
            caret.color = color;
    }

    validateCarrierForDispatch(tableRow: TableRow) {
        const labelWarningImage = tableRow?.findComponentById("labelWarningImage") as Image;
        this.getValidationErrorMessage(tableRow).then(result => {
            if (labelWarningImage !== undefined && result != null) {
                const warningList = result?.data?.[0]?.warnings;
                const errorList = result?.data?.[0]?.errors;
                if ((warningList != null && warningList.length > 0) || (errorList != null && errorList.length > 0)) {
                    labelWarningImage.visible = true;
                    labelWarningImage.tooltipCallback = (baseTooltip: Component, originatingEvent): Component => {
                        const tooltip = this.getCarrierValidationQuickInfoLayout(baseTooltip, tableRow.data?.get("payee_id"), "", warningList, errorList);
                        return labelWarningImage["_internalShowTooltip"](tooltip, originatingEvent);
                    }
                }
            }
        });
    }

    getCarrierValidationQuickInfoLayout(baseTooltip: Component, carrierId: string, message: string, warningList?: List, errorList?: List): Layout | null {
        let layout = null;
        if (carrierId != null) {
            layout = Layout.getLayout("lme/powerbroker/CarrierValidationWarningQuickInfo");
            layout.onLoad = () => {
                const modelRow = new ModelRow(layout.mainDataSource.url, false, { id: carrierId });
                layout.mainDataSource.setRowsAndMode(DataSourceMode.NONE, [modelRow])
                layout["labelWarningMessage"].visible = false;

                const items = [];
                if (warningList != null) {
                    if (warningList) {
                        for (const warning of Object.values(warningList)) {
                            const label = new Label({ caption: warning });
                            items.push(label);
                        }
                    }
                }
                if (errorList != null) {
                    if (errorList) {
                        for (const error of Object.values(errorList)) {
                            let label = null;

                            if (error.details != null)
                                label = new Label({ caption: error.message + "\n\t" + error.details.join("\n\t") });
                            else
                                label = new Label({ caption: error.message });

                            items.push(label);
                        }
                    }
                }
                layout["errorList"].items = items
            };
        }
        return layout;
    }

    getValidationErrorMessage(tableRow: TableRow): Promise<any> {
        const carrierId = tableRow?.data?.get("payee_id");
        if (carrierId == null) return Promise.resolve(null);
        if (!this.carrierQualificationErrors.has(carrierId)) {
            const validateError = tableRow?.data?.get("carrier_validation_error");
            if (validateError != null && tableRow?.data.getChangedData()["payee_id"] == null) {
                this.carrierQualificationErrors.set(carrierId, validateError);
                return Promise.resolve(validateError);
            } else if (this.movementID != null) {
                return new Promise((resolve) => {
                    Api.search("lme/powerbroker/carrier-dispatch-validator",
                        { "movement_id": this.movementID, "payee_id": carrierId }).then(result => {
                            resolve(result);
                        });
                });
            }
        }
        return Promise.resolve(this.carrierQualificationErrors.get(carrierId));
    }

    iccNumberRequired(textbox: Textbox, offerRow: ModelRow) {
        textbox.required = StringUtil.isEmptyString(offerRow.get("dot_number"));
        textbox.validate(false, false);
    }

    setToolTipCallbacks(tableRow: TableRow) {
        const offerDate = tableRow.findComponentById("labelOfferDate") as Label;
        offerDate.tooltipCallback = () => {
            return offerDate.showTooltip(() => { return this.makeUserQuickInfoLayout(tableRow.data) }, null, { themeKey: "quickInfo", color: null });
        }
        if (tableRow.data.get("source") !== undefined) {
            const tbAmount = tableRow.findComponentById("textboxAmount");
            tbAmount.tooltipCallback = () => { return this.makeSourceToolTipCallback(tableRow.data.get("source"), tbAmount) };
            const panelStatus = tableRow.findComponentById("panelStatus") as Panel;
            panelStatus.tooltipCallback = () => { return this.makeSourceToolTipCallback(tableRow.data.get("source"), panelStatus) };
        }
        const tbCounter = tableRow.findComponentById("textboxCounterOffer");
        tbCounter.tooltipCallback = () => { return this.makeCounterDateToolTipCallback(tableRow.data, tbCounter) };
    }

    makeSourceToolTipCallback(source: String, component: Component) {
        const panel = new Panel({ padding: 0, margin: 0 });
        panel.add(new Label({ caption: "Offer Source", fontSize: "small", fontBold: true }));
        panel.add(new Label({ caption: `     ${source}` }));
        return component.showTooltip(panel, null, { themeKey: "quickInfo" });
    }

    makeUserQuickInfoLayout(modelRow: ModelRow): Layout {
        const layout = Layout.getLayout("common/UserQuickInfo") as UserQuickInfo;
        layout.addLayoutLoadListener(event => {
            if (modelRow.get("entered_user_id") instanceof ModelRow) {
                (event.target as Layout).mainDataSource.data = [modelRow.get("entered_user_id")];
                layout.mainDataSource.rowIndex = 0;
            } else {
                (event.target as Layout).mainDataSource.search({ id: modelRow.get("entered_user_id") });
            }
            layout.textboxId.caption = "Entered By"
        });
        return layout;
    }

    makeCounterDateToolTipCallback(modelRow: ModelRow, component: Component) {
        const panel = new Panel({ padding: 0, margin: 0 });
        panel.add(new Label({ caption: "Counter Offer Received", fontSize: "large", fontBold: true, align: HorizontalAlignment.CENTER, fillRow: true }));
        panel.add(new Label({ caption: modelRow.get("counter_offer_date"), displayType: DisplayType.DATETIME, align: HorizontalAlignment.CENTER, fillRow: true }));
        return component.showTooltip(panel, null, { themeKey: "quickInfo" });
    }

    tableOffersOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.target as TableRow;
        this.syncAssignButton(tableRow);
        this.validateCarrierForDispatch(tableRow);
        this.addAfterPostListener(tableRow.data, tableRow.table);
        const tbIccNum = tableRow.findComponentById("textboxIccNumber") as Textbox;
        this.iccNumberRequired(tbIccNum, tableRow.data as ModelRow);
        const panelStatus = tableRow.findComponentById("panelStatus") as Panel;
        this.setOfferStatusLabel(tableRow.data.get("status"), panelStatus);
        this.setToolTipCallbacks(tableRow);
    }

    syncAssignButton(tableRow: TableRow) {
        const modelRow = tableRow?.data as ModelRow;
        const buttonAssignCarrier = tableRow.findComponentById("buttonAssignCarrier") as Button;
        if (tableRow.mode === TableRowMode.QUICK_ADD) {
            buttonAssignCarrier.visible = false;
        } else {
            if (modelRow["payeeListener"] == null) {
                const payeeListener = (event: FieldUpdateEvent) => {
                    if (event.fieldName == "payee_id")
                        this.enableAssignButton(buttonAssignCarrier);
                }
                modelRow["payeeListener"] = payeeListener;
                modelRow.addAfterFieldUpdateListener(payeeListener);
            }
            this.enableAssignButton(buttonAssignCarrier);
        }
    }

    enableAssignButton(buttonAssignCarrier: Button) {
        if (buttonAssignCarrier !== undefined) {
            buttonAssignCarrier.disabledTooltip = null;
            DispatchUtil.isAssignable(this.moveRow, buttonAssignCarrier, null);
        }
    }

    addAfterPostListener(modelRow: ModelRow, tableOffers: Table) {
        if (modelRow != null && modelRow["_rowPostListenersAdded"] != true) {
            // tableOffers.persistChangesImmediately == true, so an AfterPostListener is required
            // to display/hide carrier validation warning after row is posted. This is fired when
            // adding / modify a quickAdd TableRow or when saving data in the edit slideout
            modelRow.addAfterPostListener((event: RowUpdateEvent) => {
                const tableRow = tableOffers.rows.find(tableRow => tableRow.data.get("id") == event.row.get("id"));
                this.validateCarrierForDispatch(tableRow);
                return Promise.resolve();
            });
            modelRow["_rowPostListenersAdded"] = true;
        }
    }

    showTooltipOnDataDisplay(event: DataDisplayEvent) {
        const textbox = event.target as Textbox;
        const modelRow = getRelevantModelRow(textbox);
        (event.target as Textbox).tooltipCallback = makeTooltipCallbackFunction(modelRow.get("payee_id"), textbox);
    }

    assignCarrierEvent(event: ClickEvent) {
        const button = event.target as Button;
        const tableRow = TableRow.getContainingTableRow(button);

        if (tableRow.table.persistChangesImmediately)
            tableRow.saveChanges(() => this.showAssignCarrierSlideout(tableRow, button))
        else
            this.showAssignCarrierSlideout(tableRow, button);
    }

    showAssignCarrierSlideout(tableRow: TableRow, button: Button = tableRow.findComponentById("buttonAssignCarrier") as Button) {
        const modelRow = tableRow.data as ModelRow;
        const carrierOfferInfo: CarrierOfferInfo = {
            payee_id: modelRow.get("payee_id", null),
            carrier_name: modelRow.get("carrier_name", ""),
            icc_number: modelRow.get("icc_number", null),
            dot_number: modelRow.get("dot_number", null),
            carrier_contact: modelRow.get("contact_name", ""),
            carrier_phone: modelRow.get("contact_phone", ""),
            carrier_email: modelRow.get("email", ""),
            dispatch_offer: modelRow.get("counter_offer", modelRow.get("amount")),
            carrier_empty_from_city_id: modelRow.get("tractor_city_id", "")
        }

        CarrierAssignmentStepper.showAsSlideout({
            movementId: this.moveRow.get("id"),
            orderId: this.moveRow.get("orders.id"),
            carrierAssignmentButton: button,
            carrierOfferInfo,
            onClose: (canceled: boolean) => {
                if (!canceled) {
                    modelRow.set("status", "A");
                    modelRow.post();
                    if (!(this.layout instanceof WaterfallOffers))
                        this.doAfterOfferAssignment();
                }
            },
        });
    }

    async doAfterOfferAssignment() {
        if (this.layout !== null && this.layout !== undefined) {
            await this.layout.setMovementRow();
            this.layout.tableOffers.rows.forEach(tableRow => {
                const buttonAssignCarrier = tableRow.findComponentById("buttonAssignCarrier") as Button;
                this.enableAssignButton(buttonAssignCarrier);
            });
        }
    }

    sortOffers(source: OfferSource) {
        let offerTypeWeights: Object;
        if (source == OfferSource.WATERFALL) {
            offerTypeWeights = {
                "I": 1, // Initial
                "O": 2, // Countered
                "P": 3, // Pending
                "D": 4, // Declined
                "E": 5, // Expired
                "S": 6  // Skipped
            }
        }
        else {
            offerTypeWeights = {
                "Z": 1, // Pending Accept
                "I": 2, // Initial
                "O": 3, // Countered
                "D": 4, // Declined
            }
        }
        this.layout.mainDataSource.data = this.layout.mainDataSource.data.filter((row) => row.get("status") != "C").sort((row1, row2) => {
            if (row1.get("status") == row2.get("status")) {
                return row1.get("offer_date") > row2.get("offer_date") ? -1 : 1;
            }
            return offerTypeWeights[row1.get("status")] - offerTypeWeights[row2.get("status")];
        });
        this.layout.mainDataSource.displayDataInBoundComponents()
    }
}
