import { Button, Dialog, Layout, Panel } from "@mcleod/components";
import { HorizontalAlignment, StringUtil, getLogger } from "@mcleod/core";
import { PurposeSwap } from "./PurposeSwap";
import { RowEdiOrder } from "./models/ModelEdiOrder";
const log = getLogger("lme.datafusion.PurposeSwap");
export class PurposeSwapDialog extends Dialog {
    private _ediOrderRow: RowEdiOrder;

    override async show(orderId?: string): Promise<any> {
        const layout = Layout.getLayout("lme/datafusion/PurposeSwap") as PurposeSwap;
        const pnlButtons = new Panel({ fillRow: true, align: HorizontalAlignment.CENTER })
        const btnSave = new Button({ caption: "Update Purpose", rowBreak: false, marginRight: 10 });

        btnSave.addClickListener(async _event => {
            if (!StringUtil.isEmptyString(layout.textOrderId.text)) {
                if (layout.textOrderId.validateSimple()) {
                    this.ediOrderRow.set("order_id", layout.textOrderId.text);
                    await this.ediOrderRow.post();
                    this.close(true);

                } else {
                    layout.textOrderId.showTooltip("Invalid order specified, please select a valid ID from the drop down.");
                }
            } else {
                layout.textOrderId.showTooltip("Please select a an order before saving.");
            }
        });

        pnlButtons.add(btnSave);

        layout.addLayoutLoadListener(() => {
            layout.insert(pnlButtons, 5);
            if (orderId) {
                layout.textOrderId.text = orderId;
                layout.textOrderId.selectText();
            }
        });

        this.add(layout);

        await super.show();
        return Promise.resolve(true);
    }

    public get ediOrderRow(): RowEdiOrder {
        return this._ediOrderRow;
    }

    public set ediOrderRow(value: RowEdiOrder) {
        this._ediOrderRow = value;
    }
}
