/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelRoutingGuideDetail.ts            *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/powerbroker/routing-guide-detail";

export class AutogenModelRoutingGuideDetail<RowType extends ModelRow> extends Endpoint<RowType, RowRoutingGuideDetailFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowRoutingGuideDetail extends ModelRow<RowRoutingGuideDetailFields> {
    constructor(values?: Partial<RowRoutingGuideDetailFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowRoutingGuideDetailFields {
    carrier_name: string;
    company_id: string;
    contact_email: string;
    contact_mobile_phone: string;
    contact_name: string;
    contact_phone: string;
    id: string;
    parent_row_id: string;
    payee_id: string;
    sequence: number;
}

