/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelCarrierPayee.ts                  *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/powerbroker/carrier-payee";

export class AutogenModelCarrierPayee<RowType extends ModelRow> extends Endpoint<RowType, RowCarrierPayeeFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowCarrierPayee extends ModelRow<RowCarrierPayeeFields> {
    constructor(values?: Partial<RowCarrierPayeeFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowCarrierPayeeFields {
    ach_batch_class_code: string;
    add_fed_to_wh: number;
    add_fed_to_wh_c: string;
    add_fed_to_wh_d: Date;
    add_fed_to_wh_n: number;
    add_fed_to_wh_r: number;
    addl_st_to_whold: number;
    addl_st_to_whold_c: string;
    addl_st_to_whold_d: Date;
    addl_st_to_whold_n: number;
    addl_st_to_whold_r: number;
    address1: string;
    address2: string;
    adp_co_code: string;
    adp_e_no: string;
    birth_date: Date;
    check_address: string;
    check_address2: string;
    check_city: string;
    check_city_st_zip: string;
    check_date: Date;
    check_name: string;
    check_number: string;
    check_st: string;
    check_stub_comment: string;
    check_zip: string;
    city: string;
    city_tax_code: string;
    company_id: string;
    country_id: string;
    county_tax_code: string;
    currency: string;
    dependents: number;
    dependents_c: string;
    dependents_d: Date;
    dependents_n: number;
    dependents_r: number;
    disable_payroll_taxes: string;
    email: string;
    email_summary: string;
    enable_carrier_app: string;
    exempt_from_fed: string;
    extra_withholdings: number;
    extra_withholdings_c: string;
    extra_withholdings_d: Date;
    extra_withholdings_n: number;
    extra_withholdings_r: number;
    fed_amt_to_whold: number;
    fed_amt_to_whold_c: string;
    fed_amt_to_whold_d: Date;
    fed_amt_to_whold_n: number;
    fed_amt_to_whold_r: number;
    fed_exemptions: number;
    fed_marital_status: string;
    freeze_pay: string;
    freight_matching_override: string;
    gp_exclude: string;
    gp_exported: Date;
    hire_date: Date;
    holiday_hours_pd: number;
    id: string;
    last_raise_date: Date;
    legal_name: string;
    load_booking_eligible: string;
    loadpay_contact_phone: string;
    multiple_jobs: string;
    name: string;
    non_office_emp: string;
    office_employee: string;
    other_deductions: number;
    other_deductions_c: string;
    other_deductions_d: Date;
    other_deductions_n: number;
    other_deductions_r: number;
    other_income: number;
    other_income_c: string;
    other_income_d: Date;
    other_income_n: number;
    other_income_r: number;
    overtime_hours_pd: number;
    payment_method: string;
    phone_number: string;
    prev_code: string;
    primary_agency: string;
    regular_hours_pd: number;
    remarks: string;
    settlement_status: string;
    sick_hours_pd: number;
    social_security_no: string;
    st_amt_to_whold: number;
    st_amt_to_whold_c: string;
    st_amt_to_whold_d: Date;
    st_amt_to_whold_n: number;
    st_amt_to_whold_r: number;
    st_marital_status: string;
    state: string;
    state_exemptions: number;
    state_tax_code: string;
    status: string;
    sui_tax_code: string;
    tax_table_city_aux: number;
    tax_table_city_misc: number;
    tax_table_county_aux: number;
    tax_table_county_misc: number;
    tax_table_fit_aux: number;
    tax_table_fit_misc: number;
    tax_table_st_aux: number;
    tax_table_st_misc: number;
    tax_table_sui_aux: number;
    tax_table_sui_misc: number;
    termination_date: Date;
    vacation_hours_pd: number;
    vm_part_update: string;
    w4_status: string;
    wf_queue: number;
    ytd_holiday_hrs: number;
    ytd_overtime_hrs: number;
    ytd_reg_hrs_paid: number;
    ytd_sick_hrs_paid: number;
    ytd_vacation_hrs: number;
    zip_code: string;
}

