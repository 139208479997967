import { Button, ClickEvent, Label, Panel, Textbox } from "@mcleod/components";
import { Api, ModelRow, Navigation, StringUtil, VerticalAlignment, getThemeColor } from "@mcleod/core";
import { AutogenLayoutCarrierPrequalResult } from "./autogen/AutogenLayoutCarrierPrequalResult";


export class CarrierPrequalResult extends AutogenLayoutCarrierPrequalResult {

    async prequalCarrier(carrierType: string, carrierIdentifier: string) {

        try {
            await this.validActiveVendor().then(result => {
                if (result) {
                    Api.post("lme/powerbroker/prequal-carrier", {
                        identifier_type: carrierType,
                        identifier_number: carrierIdentifier
                    }).then(response => {
                        let existInCarrierSystem = false;
                        let companyName = "";
                        let vendorName = "";
                        let mcNumber = "";
                        let dotNumber = "";
                        let dotOk = "";
                        let insuranceOk = "";
                        let riskAssessment = "";
                        let overallResult = "";
                        let authority = "";
                        let insurance = "";
                        let safety = "";
                        let operation = "";
                        let other = "";
                        let error = "";
                        let classifications = "";
                        let carrierWatchDetails = null;
                        let vendorUrl = "";

                        if (response != null && response.data[0] != null) {
                            existInCarrierSystem = response?.data[0]?.exist_in_carrier_system;
                            vendorName = response?.data[0]?.vendor_name;
                            companyName = response?.data[0]?.company_name;
                            mcNumber = response?.data[0]?.mc_number;
                            dotNumber = response?.data[0]?.dot_number;
                            dotOk = response?.data[0]?.dot_ok;
                            insuranceOk = response?.data[0]?.insurance_ok;

                            classifications = response?.data[0]?.classifications;
                            riskAssessment = response?.data[0]?.risk_assessment;
                            overallResult = response.data[0]?.overall_result;
                            authority = response?.data[0]?.aa_authority;
                            insurance = response?.data[0]?.aa_insurance;
                            safety = response?.data[0]?.aa_safety;
                            operation = response?.data[0]?.aa_operation;
                            other = response?.data[0]?.aa_other;
                            error = response?.data[0]?.error;
                            vendorUrl = response?.data[0]?.vendor_url;


                            carrierWatchDetails = new CarrierWatchInfo(
                                response?.data[0]?.carrier_watch_common_auth,
                                response?.data[0]?.carrier_watch_contract_auth,
                                response?.data[0]?.carrier_watch_broker_auth,
                                response?.data[0]?.carrier_watch_cargo_effective_date,
                                response?.data[0]?.carrier_watch_liablity_effective_date,
                                response?.data[0]?.carrier_watch_liablity_amount,
                                response?.data[0]?.carrier_watch_cargo_amount,
                                response?.data[0]?.carrier_watch_cargo_company_name,
                                response?.data[0]?.carrier_watch_liablity_company_name,
                                response?.data[0]?.vendor_name,
                                response?.data[0]?.company_name,
                                response?.data[0]?.mc_number,
                                response?.data[0]?.dot_number,
                                response?.data[0]?.exist_in_carrier_system,
                                response?.data[0]?.certificate_on_file);

                        }


                        if (response !== null && response.data[0] !== null && response?.data[0]?.error !== undefined) {
                            if (response?.data[0]?.rmis_vendor) {
                                this.displayErrorPanel(error);
                            } else if (response?.data[0]?.highway_vendor) {
                                this.displayHighwayErrorPanel(error);
                            } else if (response?.data[0]?.carrier_watch_vendor) {
                                this.displayCarrierWatchErrorPanel(error);
                            } else {
                                this.displayAAErrorPanel(error);
                            }
                        } else {
                            if (response !== null && response.data[0] !== null && response?.data[0]?.qualified_carrier) {
                                if (response?.data[0]?.rmis_vendor) {
                                    this.displayRmisQualifiedPanel(companyName, mcNumber, dotNumber, dotOk, insuranceOk, vendorName);
                                } else if (response?.data[0]?.highway_vendor) {
                                    this.displayHighwayQualifiedPanel(companyName, mcNumber, dotNumber, overallResult, classifications, vendorName, vendorUrl);
                                } else if (response?.data[0]?.carrier_watch_vendor) {
                                    this.displayCarrierWatchQualifiedPanel(carrierWatchDetails);
                                } else {
                                    this.displayAssureAdvantageQualifiedPanel(vendorName, companyName, mcNumber, dotNumber, riskAssessment, authority, insurance, safety, operation, other, vendorUrl);
                                }
                            } else {
                                if (response?.data[0]?.rmis_vendor) {
                                    this.displayRmisDisqualifiedPanel(companyName, mcNumber, dotNumber, dotOk, insuranceOk, vendorName, existInCarrierSystem);
                                } else if (response?.data[0]?.highway_vendor) {
                                    this.displayHighwayDisqualifiedPanel(companyName, mcNumber, dotNumber, overallResult, vendorName, existInCarrierSystem, classifications, vendorUrl);
                                } else if (response?.data[0]?.carrier_watch_vendor) {
                                    this.displayCarrierWatchDisqualifiedPanel(carrierWatchDetails);
                                } else {
                                    this.displayAssureAdvantageDisqualifiedPanel(vendorName, companyName, mcNumber, dotNumber, riskAssessment, authority, safety, insurance, operation, other,
                                        existInCarrierSystem, vendorUrl);
                                }
                            }
                        }
                    });
                }
            });

        } catch (error) {
            console.error(error);
        }
    }

    validActiveVendor(): Promise<boolean> {
        return this.sourceCarrierInsCtrl.search().then(result => {
            if (result.modelRows[0] !== undefined) {
                return this.validateVendor(result.modelRows);
            }
            return false;
        });
    }

    validateVendor(data: ModelRow<any>[]): boolean {
        return StringUtil.equalsIgnoreCase(data[0].get("vendor_id"), "H")
            || StringUtil.equalsIgnoreCase(data[0].get("vendor_id"), "D")
            || StringUtil.equalsIgnoreCase(data[0].get("vendor_id"), "R")
            || StringUtil.equalsIgnoreCase(data[0].get("vendor_id"), "C");
    }

    public btnAssureAdvantageSiteOnClick(event: ClickEvent) {
        const link = this.labelAssureAdvantageUrl.caption;
        if (!StringUtil.isEmptyString(link)) {
            Navigation.navigateTo(link, { newTab: true });
        }
    }

    public btnHighwaySiteOnClick(event: ClickEvent) {
        const link = this.labelHighwayUrl.caption;
        if (!StringUtil.isEmptyString(link)) {
            Navigation.navigateTo(link, { newTab: true });
        }
    }

    public displayAAErrorPanel(error: string) {
        this.panelCarrierAAQualificationInfo.visible = true;
        this.labelAAPrequalResult.caption = error;
        this.labelAAPrequalResult.verticalAlign = VerticalAlignment.CENTER;
        this.panelCarrierAAQualificationInfo.borderColor = "error";
        this.labelAAPrequalResult.imageName = "warning";
        this.labelAAPrequalResult.imageColor = "error";
        this.labelAAPrequalResult.color = "error";
        setAALabelsAndTextboxVisbility(this as CarrierPrequalResult, false);
    }

    public displayErrorPanel(error: string) {
        this.panelCarrierQualificationInfo.visible = true;
        this.panelCarrierQualificationInfo.borderColor = "error";
        this.labelPrequalResult.caption = error;
        this.labelPrequalResult.verticalAlign = VerticalAlignment.CENTER;
        this.labelPrequalResult.imageName = "warning";
        this.labelPrequalResult.imageColor = "error";
        this.labelPrequalResult.color = "error";
        setRmisLabelsAndTextboxVisbility(this as CarrierPrequalResult, false);
    }

    public displayHighwayErrorPanel(error: string) {
        this.panelHWCarrierQualificationInfo.visible = true;
        this.labelHWPrequalResult.caption = error;
        this.labelHWPrequalResult.verticalAlign = VerticalAlignment.CENTER;
        this.panelHWCarrierQualificationInfo.borderColor = "error";
        this.labelHWPrequalResult.imageName = "warning";
        this.labelHWPrequalResult.imageColor = "error";
        this.labelHWPrequalResult.color = "error";
        setHighwayLabelsAndTextboxVisbility(this as CarrierPrequalResult, false);
    }

    public displayCarrierWatchErrorPanel(error: string) {
        this.panelCarrierWatchQualInfo.visible = true;
        this.panelCarrierWatchQualInfo.borderColor = "error";
        this.labelCWPrequalResult.caption = error;
        this.labelCWPrequalResult.verticalAlign = VerticalAlignment.CENTER;
        this.labelCWPrequalResult.imageName = "warning";
        this.labelCWPrequalResult.imageColor = "error";
        this.labelCWPrequalResult.color = "error";
        this.labelCarrierWatchQualified.visible = false;
        setCarrierWatchLabelsAndTextboxVisbility(this as CarrierPrequalResult, false);
    }

    configurePreQualBtn(textboxPendingCarrierId: Textbox, textboxPendingCarrierIdType: Textbox, textboxPrequalVendor: Textbox, btnPrequalCarrier: Button, vendorId: string) {
        if (vendorId === 'R' || vendorId === 'D' || vendorId === 'H' || vendorId === 'C') {
            if (!StringUtil.isEmptyString(textboxPendingCarrierId.text) && !StringUtil.isEmptyString(textboxPendingCarrierIdType.text) && textboxPendingCarrierIdType.text !== "Intrastate Code") {
                btnPrequalCarrier.visible = true;
                textboxPrequalVendor.visible = true;
            } else {
                btnPrequalCarrier.visible = false;
                textboxPrequalVendor.visible = false;
            }
        } else {
            btnPrequalCarrier.visible = false;
            textboxPrequalVendor.visible = false;
        }
    }

    public displayHighwayQualifiedPanel(companyName: string, mcNumber: string, dotNumber: string, overallResult: string, classifications: string, vendorName: string, vendorUrl: string) {
        this.panelHWCarrierQualificationInfo.visible = true;
        configureSuccessQualificationPanel(this.panelHWCarrierQualificationInfo, this.labelHWPrequalResult, vendorName);
        setHighwayLabelsAndTextboxVisbility(this as CarrierPrequalResult, true);
        setHighwayTextboxValues(this as CarrierPrequalResult, companyName, mcNumber, dotNumber, overallResult, vendorUrl);
        setClassificationPanel(this as CarrierPrequalResult, classifications);
        this.textboxOverallResult.printableLabel._element.style.color = this._fontColor("Pass");
    }

    public displayHighwayDisqualifiedPanel(companyName: string, mcNumber: string, dotNumber: string, overallResult: string, vendorName: string, existInCarrierSystem: boolean, classifications: string, vendorUrl: string) {
        this.panelHWCarrierQualificationInfo.visible = true;
        this.panelCarrierAAQualificationInfo.visible = false;
        this.panelCarrierQualificationInfo.visible = false;
        if (existInCarrierSystem) {
            this.labelHWPrequalResult.caption = "Carrier is disqualified";
            configureDisQualifiedPanel(this.panelHWCarrierQualificationInfo, this.labelHWPrequalResult);
            setHighwayLabelsAndTextboxVisbility(this as CarrierPrequalResult, true);
            setHighwayTextboxValues(this as CarrierPrequalResult, companyName, mcNumber, dotNumber, overallResult, vendorUrl);
            setClassificationPanel(this as CarrierPrequalResult, classifications);
            this.textboxOverallResult.printableLabel._element.style.color = this._fontColor("Failed");
        } else {
            this.labelHighwayClassifications.visible = false;
            this.panelClassificationDetails.visible = false;
            configureNotAvailableQualificationPanel(this.panelHWCarrierQualificationInfo, this.labelHWPrequalResult, vendorName);
            setHighwayLabelsAndTextboxVisbility(this as CarrierPrequalResult, false);
        }
    }

    public displayRmisDisqualifiedPanel(companyName: string, mcNumber: string, dotNumber: string, dotOk: string, insuranceOk: string, vendorName: string, existInCarrierSystem: boolean) {
        this.panelCarrierAAQualificationInfo.visible = false;
        this.panelHWCarrierQualificationInfo.visible = false;
        this.panelCarrierQualificationInfo.visible = true;
        if (existInCarrierSystem) {
            this.labelPrequalResult.caption = "Carrier is disqualified";
            setRmisLabelsAndTextboxVisbility(this as CarrierPrequalResult, true);
            setRmisTextboxValues(this as CarrierPrequalResult, companyName, mcNumber, dotNumber, dotOk, insuranceOk);
            configureDisQualifiedPanel(this.panelCarrierQualificationInfo, this.labelPrequalResult);

            if (dotOk === "Failed") {
                this.textboxDotOk.printableLabel._element.style.color = this._fontColor("Failed");
            } else if (dotOk === "Pass") {
                this.textboxDotOk.printableLabel._element.style.color = this._fontColor("Pass");
            }
            if (insuranceOk === "Failed") {
                this.textboxInsuranceOk.printableLabel._element.style.color = this._fontColor("Failed");
            } else if (insuranceOk === "Pass") {
                this.textboxInsuranceOk.printableLabel._element.style.color = this._fontColor("Pass");
            }
        } else {
            configureNotAvailableQualificationPanel(this.panelCarrierQualificationInfo, this.labelPrequalResult, vendorName);
            setRmisLabelsAndTextboxVisbility(this as CarrierPrequalResult, false);
        }
    }

    public displayCarrierWatchDisqualifiedPanel(carrierWatchDetails: any) {
        this.panelCarrierAAQualificationInfo.visible = false;
        this.panelHWCarrierQualificationInfo.visible = false;
        this.panelCarrierQualificationInfo.visible = false;
        this.panelCarrierWatchQualInfo.visible = true;
        this.labelCarrierWatchQualified.visible = false;
        this.labelCWPrequalResult.visible = true;
        if (carrierWatchDetails.existInCarrierSystem) {
            this.labelCWPrequalResult.caption = "Carrier is disqualified";
            this.labelCWPrequalResult.color = "error";
            this.labelCWPrequalResult.imageColor = "error";
            this.labelCwNoCertificateOnFile.visible = true;
            this.labelLiabilityInsuranceInfo.caption = "FMCSA Liability Insurance Information";
            this.labelCargoInsuranceInfo.caption = "FMCSA Cargo Insurance Information";
            this.labelCargoEffectiveDate.caption = "Cargo Effective Date";
            this.labelCwEffectiveDate.caption = "Liability Effective Date";
            setCarrierWatchLabelsAndTextboxVisbility(this as CarrierPrequalResult, true);
            setCarrierWatchTextboxValues(this as CarrierPrequalResult, carrierWatchDetails);
            configureDisQualifiedPanel(this.panelCarrierWatchQualInfo, this.labelPrequalResult);

            if (carrierWatchDetails.carrierWatchCommonAuth === 'None') {
                this.textboxCwCommonAuth.printableLabel._element.style.color = this._fontColor("Failed");
            } else {
                this.textboxCwCommonAuth.printableLabel._element.style.color = null;
            }
            if (carrierWatchDetails.carrierWatchContractAuth === 'None') {
                this.textboxCwContractAuth.printableLabel._element.style.color = this._fontColor("Failed");
            } else {
                this.textboxCwContractAuth.printableLabel._element.style.color = null;
            }
            if (carrierWatchDetails.carrierWatchBrokerAuth === 'None') {
                this.textboxCwBrokerAuth.printableLabel._element.style.color = this._fontColor("Failed");
            } else {
                this.textboxCwBrokerAuth.printableLabel._element.style.color = null;
            }
            if (carrierWatchDetails.carrierWatchCargoAmount === '0.00') {
                this.textboxCargoAmount.printableLabel._element.style.color = this._fontColor("Failed");
            } else {
                this.textboxCargoAmount.printableLabel._element.style.color = null;
            }
            if (carrierWatchDetails.carrierWatchLiabilityAmount === '0.00') {
                this.textboxLiablityAmount.printableLabel._element.style.color = this._fontColor("Failed");
            } else {
                this.textboxLiablityAmount.printableLabel._element.style.color = null;
            }
        } else {
            this.labelCwNoCertificateOnFile.visible = false;
            configureNotAvailableQualificationPanel(this.panelCarrierWatchQualInfo, this.labelCWPrequalResult, carrierWatchDetails.vendorName);
            setCarrierWatchLabelsAndTextboxVisbility(this as CarrierPrequalResult, false);
        }
    }

    public displayRmisQualifiedPanel(companyName: string, mcNumber: string, dotNumber: string, dotOk: string, insuranceOk: string, vendorName: string) {
        this.panelCarrierQualificationInfo.visible = true;
        configureSuccessQualificationPanel(this.panelCarrierQualificationInfo, this.labelPrequalResult, vendorName);
        setRmisTextboxValues(this as CarrierPrequalResult, companyName, mcNumber, dotNumber, dotOk, insuranceOk);
        this.textboxDotOk.printableLabel._element.style.color = this._fontColor("Pass");
        this.textboxInsuranceOk.printableLabel._element.style.color = this._fontColor("Pass");
    }

    public displayCarrierWatchQualifiedPanel(carrierInfo: any) {
        this.panelCarrierWatchQualInfo.visible = true;
        this.labelCwNoCertificateOnFile.visible = false;
        this.labelLiabilityInsuranceInfo.caption = "Liability Insurance Information";
        this.labelCargoInsuranceInfo.caption = "Cargo Insurance Information";
        this.labelCargoEffectiveDate.caption = "Cargo Expiration Date";
        this.labelCwEffectiveDate.caption = "Liability Expiration Date";
        this.labelCWPrequalResult.visible = false;

        if (carrierInfo.carrierWatchCommonAuth === 'None') {
            this.textboxCwCommonAuth.printableLabel._element.style.color = this._fontColor("Failed");
        } else {
            this.textboxCwCommonAuth.printableLabel._element.style.color = null;
        }
        if (carrierInfo.carrierWatchContractAuth === 'None') {
            this.textboxCwContractAuth.printableLabel._element.style.color = this._fontColor("Failed");
        } else {
            this.textboxCwContractAuth.printableLabel._element.style.color = null;
        }
        if (carrierInfo.carrierWatchBrokerAuth === 'None') {
            this.textboxCwBrokerAuth.printableLabel._element.style.color = this._fontColor("Failed");
        } else {
            this.textboxCwBrokerAuth.printableLabel._element.style.color = null;
        }
        if (carrierInfo.carrierWatchCargoAmount === '0.00') {
            this.textboxCargoAmount.printableLabel._element.style.color = this._fontColor("Failed");
        } else {
            this.textboxCargoAmount.printableLabel._element.style.color = null;
        }
        if (carrierInfo.carrierWatchLiabilityAmount === '0.00') {
            this.textboxLiablityAmount.printableLabel._element.style.color = this._fontColor("Failed");
        } else {
            this.textboxLiablityAmount.printableLabel._element.style.color = null;
        }
        configureCarrierWatchSuccessQualificationPanel(this.panelCarrierWatchQualInfo, this.labelCarrierWatchQualified, carrierInfo.vendorName);
        setCarrierWatchLabelsAndTextboxVisbility(this as CarrierPrequalResult, true);
        setCarrierWatchTextboxValues(this as CarrierPrequalResult, carrierInfo);

    }

    public displayAssureAdvantageDisqualifiedPanel(vendorName: string, companyName: string, mcNumber: string, dotNumber: string, riskAssessment: string, authority:
        string, insurance: string, safety: string, operation: string, other: string, existInCarrierSystem: boolean, vendorUrl: string) {
        this.panelCarrierQualificationInfo.visible = false;
        this.panelHWCarrierQualificationInfo.visible = false;
        this.panelCarrierAAQualificationInfo.visible = true;

        if (existInCarrierSystem) {
            this.labelAAPrequalResult.caption = "Carrier is disqualified";
            setAALabelsAndTextboxVisbility(this as CarrierPrequalResult, true);
            setAATextboxValues(this as CarrierPrequalResult, companyName, mcNumber, dotNumber, riskAssessment, authority, insurance, safety, operation, other, vendorUrl);
            configureDisQualifiedPanel(this.panelCarrierAAQualificationInfo, this.labelAAPrequalResult);

            if (riskAssessment === "Unacceptable") {
                this.textboxRiskAssessment.printableLabel._element.style.color = this._fontColor("Failed");
            } else if (riskAssessment === "Review Required") {
                this.textboxRiskAssessment.printableLabel._element.style.color = this._fontColor("Failed");
            } else if (riskAssessment === "Moderate") {
                this.textboxRiskAssessment.printableLabel._element.style.color = this._fontColor("Warning");
            } else if (riskAssessment === "Acceptable") {
                this.textboxRiskAssessment.printableLabel._element.style.color = this._fontColor("Pass");
            }
        } else {
            configureNotAvailableQualificationPanel(this.panelCarrierAAQualificationInfo, this.labelAAPrequalResult, vendorName);
            setAALabelsAndTextboxVisbility(this as CarrierPrequalResult, false);
        }

    }

    public displayAssureAdvantageQualifiedPanel(vendorName: string, companyName: string, mcNumber: string, dotNumber: string, riskAssessment: string, authority: string, insurance:
        string, safety: string, operation: string, other: string, vendorUrl: string) {
        this.panelCarrierQualificationInfo.visible = false;
        this.panelCarrierAAQualificationInfo.visible = true;
        configureSuccessQualificationPanel(this.panelCarrierAAQualificationInfo, this.labelAAPrequalResult, vendorName);
        setAALabelsAndTextboxVisbility(this as CarrierPrequalResult, true);
        setAATextboxValues(this as CarrierPrequalResult, companyName, mcNumber, dotNumber, riskAssessment, authority, insurance, safety, operation, other, vendorUrl);

        if (riskAssessment === "Unacceptable") {
            this.textboxRiskAssessment.printableLabel._element.style.color = this._fontColor("Failed");
        } else if (riskAssessment === "Review Required") {
            this.textboxRiskAssessment.printableLabel._element.style.color = this._fontColor("Failed");
        } else if (riskAssessment === "Moderate") {
            this.textboxRiskAssessment.printableLabel._element.style.color = this._fontColor("caution");
        } else if (riskAssessment === "Acceptable") {
            this.textboxRiskAssessment.printableLabel._element.style.color = this._fontColor("Pass");
        }
    }

    public hidePrequalPanels() {
        this.panelCarrierQualificationInfo.visible = false;
        this.panelCarrierAAQualificationInfo.visible = false;
        this.panelHWCarrierQualificationInfo.visible = false;
    }

    private _fontColor = (result) => {
        if (result === "Pass") {
            return getThemeColor("success");
        } else {
            return getThemeColor("error");
        }
    }
}
function setAALabelsAndTextboxVisbility(carrierPrequalResultPanel: CarrierPrequalResult, visible: boolean) {
    carrierPrequalResultPanel.labelAACompanyName.visible = visible;
    carrierPrequalResultPanel.labelAAMcNumber.visible = visible;
    carrierPrequalResultPanel.labelAADotNumber.visible = visible;
    carrierPrequalResultPanel.labelRiskAssessment.visible = visible;
    carrierPrequalResultPanel.labelAuthority.visible = visible;
    carrierPrequalResultPanel.labelInsurance.visible = visible;
    carrierPrequalResultPanel.labelSafety.visible = visible;
    carrierPrequalResultPanel.labelOperation.visible = visible;
    carrierPrequalResultPanel.labelOther.visible = visible;

    carrierPrequalResultPanel.textboxAACompanyName.visible = visible;
    carrierPrequalResultPanel.textboxAAMcNumber.visible = visible;
    carrierPrequalResultPanel.textboxAADotNumber.visible = visible;
    carrierPrequalResultPanel.textboxRiskAssessment.visible = visible;
    carrierPrequalResultPanel.textboxAuthority.visible = visible;
    carrierPrequalResultPanel.textboxInsurance.visible = visible;
    carrierPrequalResultPanel.textboxSafety.visible = visible;
    carrierPrequalResultPanel.textboxOperation.visible = visible;
    carrierPrequalResultPanel.textboxOther.visible = visible;
    carrierPrequalResultPanel.btnAssureAdvantageSite.visible = visible;
}
function setRmisLabelsAndTextboxVisbility(carrierPrequalResultPanel: CarrierPrequalResult, visible: boolean) {
    carrierPrequalResultPanel.labelCompanyName.visible = visible;
    carrierPrequalResultPanel.labelMcNumber.visible = visible;
    carrierPrequalResultPanel.labelDotNumber.visible = visible;
    carrierPrequalResultPanel.labelDotOk.visible = visible;
    carrierPrequalResultPanel.labelInsuranceOk.visible = visible;

    carrierPrequalResultPanel.textboxCompanyName.visible = visible;
    carrierPrequalResultPanel.textboxMcNumber.visible = visible;
    carrierPrequalResultPanel.textboxDotNumber.visible = visible;
    carrierPrequalResultPanel.textboxDotOk.visible = visible;
    carrierPrequalResultPanel.textboxInsuranceOk.visible = visible;
}

function setCarrierWatchLabelsAndTextboxVisbility(carrierPrequalResultPanel: CarrierPrequalResult, visible: boolean) {
    carrierPrequalResultPanel.labelCarrierWatchGeneralInfo.visible = visible;
    carrierPrequalResultPanel.labelCWCompanyName.visible = visible;
    carrierPrequalResultPanel.labelCWMcNumber.visible = visible;
    carrierPrequalResultPanel.labelCWDotNumber.visible = visible;

    carrierPrequalResultPanel.labelAuthorityInfo.visible = visible;
    carrierPrequalResultPanel.labelCwCommonAuth.visible = visible;
    carrierPrequalResultPanel.labelCwContractAuth.visible = visible;
    carrierPrequalResultPanel.labelCwBrokerAuth.visible = visible;

    carrierPrequalResultPanel.labelLiabilityInsuranceInfo.visible = visible;
    carrierPrequalResultPanel.labelCwLiablityAmount.visible = visible;
    carrierPrequalResultPanel.labelCwEffectiveDate.visible = visible;
    carrierPrequalResultPanel.labelLiablityCompany.visible = visible;

    carrierPrequalResultPanel.labelCargoInsuranceInfo.visible = visible;

    carrierPrequalResultPanel.labelCargoAmount.visible = visible;
    carrierPrequalResultPanel.labelCargoEffectiveDate.visible = visible;
    carrierPrequalResultPanel.labelCargoCompany.visible = visible;

    carrierPrequalResultPanel.textboxCWCompanyName.visible = visible;
    carrierPrequalResultPanel.textboxCWMcNumber.visible = visible;
    carrierPrequalResultPanel.textboxCWDotNumber.visible = visible;
    carrierPrequalResultPanel.textboxCwCommonAuth.visible = visible;
    carrierPrequalResultPanel.textboxCwContractAuth.visible = visible;
    carrierPrequalResultPanel.textboxCwBrokerAuth.visible = visible;
    carrierPrequalResultPanel.textboxLiablityAmount.visible = visible;
    carrierPrequalResultPanel.textboxLiabilityEffectiveDate.visible = visible;
    carrierPrequalResultPanel.textboxLiablityUnderwriter.visible = visible;
    carrierPrequalResultPanel.textboxCargoAmount.visible = visible;
    carrierPrequalResultPanel.textboxCargoEffectiveDate.visible = visible;
    carrierPrequalResultPanel.textboxCargoUnderwriter.visible = visible;

}

function setHighwayLabelsAndTextboxVisbility(carrierPrequalResultPanel: CarrierPrequalResult, visible: boolean) {
    carrierPrequalResultPanel.labelHWCompanyName.visible = visible;
    carrierPrequalResultPanel.labelHWMcNumber.visible = visible;
    carrierPrequalResultPanel.labelHWDotNumber.visible = visible;
    carrierPrequalResultPanel.labelOverallResult.visible = visible;

    carrierPrequalResultPanel.textboxHWCompanyName.visible = visible;
    carrierPrequalResultPanel.textboxHWMcNumber.visible = visible;
    carrierPrequalResultPanel.textboxHWDotNumber.visible = visible;
    carrierPrequalResultPanel.textboxOverallResult.visible = visible;
    carrierPrequalResultPanel.btnHighwaySite.visible = visible;
}

function setAATextboxValues(carrierPrequalResultPanel: CarrierPrequalResult, companyName: string, mcNumber: string, dotNumber: string, riskAssessment: string, authority: string, insurance:
    string, safety: string, operation: string, other: string, vendorUrl: string) {
    carrierPrequalResultPanel.textboxAACompanyName.text = companyName;
    carrierPrequalResultPanel.textboxAAMcNumber.text = mcNumber;
    carrierPrequalResultPanel.textboxAADotNumber.text = dotNumber;
    carrierPrequalResultPanel.textboxRiskAssessment.text = riskAssessment;
    carrierPrequalResultPanel.textboxAuthority.text = authority;
    carrierPrequalResultPanel.textboxInsurance.text = insurance;
    carrierPrequalResultPanel.textboxSafety.text = safety;
    carrierPrequalResultPanel.textboxOperation.text = operation;
    carrierPrequalResultPanel.textboxOther.text = other;
    carrierPrequalResultPanel.labelAssureAdvantageUrl.caption = vendorUrl;
    carrierPrequalResultPanel.btnAssureAdvantageSite.tooltip = "Open Assure Advantage carrier monitoring web site"

}

function setRmisTextboxValues(carrierPrequalResultPanel: CarrierPrequalResult, companyName: string, mcNumber: string, dotNumber: string, dotOk: string, insuranceOk: string) {

    carrierPrequalResultPanel.textboxCompanyName.text = companyName;
    carrierPrequalResultPanel.textboxMcNumber.text = mcNumber;
    carrierPrequalResultPanel.textboxDotNumber.text = dotNumber;
    carrierPrequalResultPanel.textboxDotOk.text = dotOk;
    carrierPrequalResultPanel.textboxInsuranceOk.text = insuranceOk;

}

function setCarrierWatchTextboxValues(carrierPrequalResultPanel: CarrierPrequalResult, carrierInfo: any) {

    carrierPrequalResultPanel.textboxCWCompanyName.text = carrierInfo.companyName;
    carrierPrequalResultPanel.textboxCWMcNumber.text = carrierInfo.mcNumber;
    carrierPrequalResultPanel.textboxCWDotNumber.text = carrierInfo.dotNumber;

    carrierPrequalResultPanel.textboxCwCommonAuth.text = carrierInfo.carrierWatchCommonAuth;
    carrierPrequalResultPanel.textboxCwContractAuth.text = carrierInfo.carrierWatchContractAuth;
    carrierPrequalResultPanel.textboxCwBrokerAuth.text = carrierInfo.carrierWatchBrokerAuth;
    carrierPrequalResultPanel.textboxLiablityAmount.text = carrierInfo.carrierWatchLiablityAmount;
    carrierPrequalResultPanel.textboxLiabilityEffectiveDate.text = carrierInfo.carrierWatchLiablityEffectiveDate;
    carrierPrequalResultPanel.textboxLiablityUnderwriter.text = carrierInfo.carrierWatchLiablityCompanyName;
    carrierPrequalResultPanel.textboxCargoAmount.text = carrierInfo.carrierWatchCargoAmount;
    carrierPrequalResultPanel.textboxCargoEffectiveDate.text = carrierInfo.carrierWatchCargoEffectiveDate;
    carrierPrequalResultPanel.textboxCargoUnderwriter.text = carrierInfo.carrierWatchCargoCompanyName;

}

function setHighwayTextboxValues(carrierPrequalResultPanel: CarrierPrequalResult, companyName: string, mcNumber: string, dotNumber: string, overallResult: string, vendorUrl: string) {

    carrierPrequalResultPanel.textboxHWCompanyName.text = companyName;
    carrierPrequalResultPanel.textboxHWMcNumber.text = mcNumber;
    carrierPrequalResultPanel.textboxHWDotNumber.text = dotNumber;
    carrierPrequalResultPanel.textboxOverallResult.text = overallResult;
    carrierPrequalResultPanel.labelHighwayUrl.caption = vendorUrl;
    carrierPrequalResultPanel.btnHighwaySite.tooltip = "Open Highway carrier monitoring web site"

}

function configureCarrierWatchSuccessQualificationPanel(vendorPanel: Panel, panelLabel: Label, vendorName: string) {
    panelLabel.caption = "Carrier Qualification Information";
    panelLabel.color = "primary";
    vendorPanel.borderColor = "primary";
}


function configureSuccessQualificationPanel(vendorPanel: Panel, panelLabel: Label, vendorName: string) {
    panelLabel.caption = "Carrier passes " + vendorName + " qualification rules.";
    panelLabel.imageName = "check";
    panelLabel.imageColor = "success";
    panelLabel.color = "success";
    vendorPanel.borderColor = "success";
}

function configureDisQualifiedPanel(vendorPanel: Panel, panelLabel: Label) {
    panelLabel.imageName = "warning";
    panelLabel.imageColor = "error";
    panelLabel.color = "error";
    vendorPanel.borderColor = "error";
}

function configureNotAvailableQualificationPanel(vendorPanel: Panel, panelLabel: Label, vendorName: string) {
    vendorPanel.borderColor = "error";
    panelLabel.caption = "This carrier does not exist in " + vendorName + "'s system, pre-qualification status is not available at this time.";
    panelLabel.verticalAlign = VerticalAlignment.CENTER;
    panelLabel.color = "error";
    panelLabel.imageName = "warning";
    panelLabel.imageColor = "error";
}


function setClassificationPanel(carrierPrequalResultPanel: CarrierPrequalResult, classifications: string) {
    if (classifications !== "" && classifications !== undefined) {
        carrierPrequalResultPanel.labelHighwayClassifications.visible = true;
        carrierPrequalResultPanel.panelClassificationDetails.visible = true;

        const splitStrings = classifications.split(/(?<=\b(?:Pass|Fail),\s)(?=[^:]+:)/);

        if (splitStrings.length === 1) {
            carrierPrequalResultPanel.labelFirstClassification.caption = splitStrings[0].trim().replace(/,$/, '');
            carrierPrequalResultPanel.labelSecondClassification.visible = false;
            carrierPrequalResultPanel.panelClassificationDetails.tooltip = null;
        } else if (splitStrings.length >= 2) {
            carrierPrequalResultPanel.labelFirstClassification.caption = splitStrings[0].trim().replace(/,$/, '');

            if (splitStrings.length === 2) {
                carrierPrequalResultPanel.labelSecondClassification.caption = splitStrings[1].trim().replace(/,$/, '');
                carrierPrequalResultPanel.panelClassificationDetails.tooltip = null;
            } else {
                const tooltipString = splitStrings.slice(1).map(s => s.trim().replace(/,$/, '')).join('\n');
                carrierPrequalResultPanel.labelSecondClassification.caption = splitStrings[1].trim().replace(/,$/, '') + '...';
                carrierPrequalResultPanel.panelClassificationDetails.tooltip = tooltipString;
            }
        }
    } else {
        carrierPrequalResultPanel.panelClassificationDetails.tooltip = null;
    }
}


export class CarrierWatchInfo {
    carrierWatchCommonAuth: string;
    carrierWatchContractAuth: string;
    carrierWatchBrokerAuth: string;
    carrierWatchCargoEffectiveDate: string;
    carrierWatchLiablityEffectiveDate: string;
    carrierWatchLiablityAmount: string;
    carrierWatchCargoAmount: string;
    carrierWatchCargoCompanyName: string;
    carrierWatchLiablityCompanyName: string;
    vendorName: string;
    companyName: string;
    mcNumber: string;
    dotNumber: string;
    existInCarrierSystem: string;
    certificateOnFile: boolean;

    constructor(carrierWatchCommonAuth: string, carrierWatchContractAuth: string, carrierWatchBrokerAuth: string,
        carrierWatchCargoEffectiveDate: string, carrierWatchLiablityEffectiveDate: string, carrierWatchLiablityAmount: string, carrierWatchCargoAmount: string,
        carrierWatchCargoCompanyName: string, carrierWatchLiablityCompanyName: string, vendorName: string, companyName: string, mcNumber: string, dotNumber: string,
        existInCarrierSystem: string, certificateOnFile: boolean) {

        this.carrierWatchCommonAuth = carrierWatchCommonAuth;
        this.carrierWatchContractAuth = carrierWatchContractAuth;
        this.carrierWatchBrokerAuth = carrierWatchBrokerAuth;
        this.carrierWatchCargoEffectiveDate = carrierWatchCargoEffectiveDate;
        this.carrierWatchLiablityEffectiveDate = carrierWatchLiablityEffectiveDate;
        this.carrierWatchLiablityAmount = carrierWatchLiablityAmount;
        this.carrierWatchCargoAmount = carrierWatchCargoAmount;
        this.carrierWatchCargoCompanyName = carrierWatchCargoCompanyName;
        this.carrierWatchLiablityCompanyName = carrierWatchLiablityCompanyName;
        this.vendorName = vendorName;
        this.companyName = companyName;
        this.mcNumber = mcNumber;
        this.dotNumber = dotNumber;
        this.existInCarrierSystem = existInCarrierSystem;
        this.certificateOnFile = certificateOnFile;
    }
}

