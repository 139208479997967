import { SingleComponentDemo } from "./SingleComponentDemo";

export class DemoCheckbox extends SingleComponentDemo {
    constructor() {
        super();
        this.propsForAllExamples = { caption: "Option one" };
        this.examples = {
            ...SingleComponentDemo.getComponentDemoExamples(),

            caption: {
                cases: [
                    { value: "Option one" },
                    {
                        value: "Some other caption to demonstrate what changing it does"
                    },
                ]
            },

            checkColor: {
                cases: [
                    { value: "primary", description: "This Checkbox uses the current theme's primary color." },
                    { value: "green", description: "This Checkbox is green." },
                ]
            },

            checked: {
                cases: [
                    { value: true, description: "This Checkbox is checked." },
                    { value: false, description: "This Checkbox is unchecked." },
                ]
            },

            focusable: {
                cases: [
                    { value: true, description: "Note how you can tab into this Checkbox and that it maintains focus after toggling the value (via the spacebar)." },
                    { value: false, description: "This Checkbox isn't allowed to gain focus." },
                ]
            },

            printable: {
                cases: [
                    { value: true, description: "This example shows how a printable Checkbox is displayed." },
                    { value: false, description: "This Checkbox is normal." }
                ]
            },

        }
    }
}
