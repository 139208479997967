import { ModelRow } from "./ModelRow";
import { RowUpdateEvent } from "./RowUpdateEvent";

export class FieldUpdateEvent extends RowUpdateEvent {
    private _fieldName: string;
    private _oldValue: any;
    private _newValue: any;

    constructor(row: ModelRow, fieldName: string, oldValue: any, newValue: any, preventable: boolean, originator: any) {
        super(row, preventable, originator);
        this._fieldName = fieldName;
        this._oldValue = oldValue;
        this._newValue = newValue;
    }

    get fieldName(): string {
        return this._fieldName;
    }

    get oldValue(): any {
        return this._oldValue;
    }

    get newValue(): any {
        return this._newValue;
    }
}
