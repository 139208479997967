import { Button, CityState, DataSource, HorizontalSpacer, Label, Layout, Panel, Switch, Tab, Table, Tabset, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutCarrierSelection extends Layout {
	buttonAddSpotQuote: Button;
	buttonChangeMarkups: Button;
	buttonFindCarrierRates: Button;
	buttonP44Settings: Button;
	citystateConsignee: CityState;
	citystateShipper: CityState;
	horizontalspacer2: HorizontalSpacer;
	labelPickup: Label;
	panelBlueContainer: Panel;
	panelControls: Panel;
	panelErrors: Panel;
	panelOrderStopFields: Panel;
	panelOverview: Panel;
	panelTab1: Panel;
	panelTableBfg: Panel;
	sourceBrltlBillingFreightGroup: DataSource;
	switchExpandAll: Switch;
	tabErrors: Tab;
	tabRates: Tab;
	tabSpotQuote: Tab;
	tableBFGs: Table;
	tableErrors: Table;
	tabsetCarrierSelection: Tabset;
	tabsetSpotQuote: Tabset;
	textboxAccessorialAmount: Textbox;
	textboxBaseAmount: Textbox;
	textboxCarrier: Textbox;
	textboxFreightClass: Textbox;
	textboxFuelAmount: Textbox;
	textboxP44DirectionOverride: Textbox;
	textboxP44PaymentTerms: Textbox;
	textboxRatingMode: Textbox;
	textboxReference: Textbox;
	textboxScheduledArrival: Textbox;
	textboxSource: Textbox;
	textboxTotalWeight: Textbox;
	textboxTransitDays: Textbox;
}
