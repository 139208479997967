import { ComponentPropDefinitions, ComponentProps } from "../..";
import { PropType } from "../../base/PropType";
import { ImagePropDefinitions } from "../image/ImageProps";
import { PanelPropDefinitions } from "../panel/PanelProps";

export interface StepProps extends ComponentProps {
    caption: string;
}

let stepPropDefs: ComponentPropDefinitions;

export class StepPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (stepPropDefs == null) {
            stepPropDefs = {
                ...PanelPropDefinitions.cloneDefs(),
                ...ImagePropDefinitions.getContainedImagePropDefs(),
                caption: { type: PropType.string }
            };
        }
        return stepPropDefs;
    }
}
