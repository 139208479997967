/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelDistanceControl.ts               *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/dispatch/distance-control";

export class AutogenModelDistanceControl<RowType extends ModelRow> extends Endpoint<RowType, RowDistanceControlFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowDistanceControl extends ModelRow<RowDistanceControlFields> {
    constructor(values?: Partial<RowDistanceControlFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowDistanceControlFields {
    bill_dist_profile: string;
    bill_method: string;
    bill_ovr: string;
    bill_source: string;
    bill_type: string;
    company_id: string;
    default_latlong: string;
    default_zip: string;
    dro_dist_profile: string;
    empty_dist_profile: string;
    empty_move_method: string;
    empty_move_ovr: string;
    empty_move_source: string;
    empty_move_type: string;
    enable_local_miles: string;
    eta_dist_profile: string;
    etaoor_method: string;
    etaoor_source: string;
    etaoor_type: string;
    fuel_dist_profile: string;
    fuel_method: string;
    fuel_source: string;
    fuel_type: string;
    haz_bill_method: string;
    haz_bill_source: string;
    haz_bill_type: string;
    haz_fuel_method: string;
    haz_fuel_source: string;
    haz_fuel_type: string;
    haz_pay_method: string;
    haz_pay_source: string;
    haz_pay_type: string;
    id: number;
    load_dist_profile: string;
    loaded_move_method: string;
    loaded_move_ovr: string;
    loaded_move_source: string;
    loaded_move_type: string;
    lookup_um: string;
    move_distance: number;
    move_distance_um: string;
    order_distance: number;
    order_distance_um: string;
    pay_dist_profile: string;
    pay_method: string;
    pay_source: string;
    pay_type: string;
    payusing: string;
    plan_method: string;
    plan_source: string;
    plan_type: string;
    prac_dist_profile: string;
    practical_method: string;
    practical_source: string;
    practical_type: string;
    server_flag: string;
    server_host: string;
    server_port: number;
    short_dist_profile: string;
    short_method: string;
    short_source: string;
    short_type: string;
    store_mileage: string;
}

