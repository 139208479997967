import { ClickEvent, Layout, SlideoutDecorator } from "@mcleod/components";
import { ModelRow } from "@mcleod/core";
import { ShipmentStatusSlideout } from "./ShipmentStatusSlideout";
import { AutogenLayoutMovementDataFusionSummary } from "./autogen/AutogenLayoutMovementDataFusionSummary";

export class MovementDataFusionSummary extends AutogenLayoutMovementDataFusionSummary {
    private _movementId: string;
    private _orderId: string;
    private _customerId: string;
    private _displayingData: boolean = true;

    setEdiData(responseData: ModelRow) {
        const hasEdi: boolean = responseData?.["has_edi"] ?? false;
        const hasLogistics: boolean = responseData?.["has_logistics"] ?? false;
        const hasOutStatuses: boolean = responseData?.["has_out_statuses"] ?? false;
        const hasInStatuses: boolean = responseData?.["has_in_statuses"] ?? false;
        const hasLogTenders: boolean = responseData?.["has_log_tenders"] ?? false;
        const inTenderCount: number = responseData?.["in_tender_count"] ?? 0;
        const outTenderCount: number = responseData?.["out_tender_count"] ?? 0;
        const inTenderError: boolean = responseData?.["in_tender_error"] ?? false;
        const outTenderError: boolean = responseData?.["out_tender_error"] ?? false;

        if (!hasEdi && !hasLogistics || (!hasOutStatuses && !hasInStatuses && !hasLogTenders && (inTenderCount == 0 && outTenderCount == 0))) {
            this.displayingData = false;
            return;
        }

        this.displayingData = true;

        // Show or hide the outbound tender count
        if (hasLogTenders) {
            this.labelOutboundTenders.visible = true;
            this.labelOutboundTenderCount.visible = true;
            this.labelOutboundTenderCount.caption = outTenderCount.toString();
            if (outTenderError) {
                this.labelOutboundTenderCount.color = "error";
                this.imageOutboundTenderError.visible = true;
            } else {
                this.labelOutboundTenderCount.color = "primary.dark";
                this.imageOutboundTenderError.visible = false;
            }
        } else {
            this.labelOutboundTenders.visible = false;
            this.labelOutboundTenderCount.visible = false;
            this.imageOutboundTenderError.visible = false;
        }

        // Show or hide the inbound tender count
        if (inTenderCount > 0) {
            this.labelInboundTenders.visible = true;
            this.labelInboundTenderCount.visible = true;
            this.labelInboundTenderCount.caption = inTenderCount.toString();
            if (inTenderError) {
                this.labelInboundTenderCount.color = "error";
                this.imageInboundTenderError.visible = true;
            } else {
                this.labelInboundTenderCount.color = "primary.dark";
                this.imageInboundTenderError.visible = false;
            }
        } else {
            this.labelInboundTenders.visible = false;
            this.labelInboundTenderCount.visible = false;
            this.imageInboundTenderError.visible = false;
        }

        // Show or hide the outbound shipment status count
        if (hasOutStatuses) {
            const outboundRows: ModelRow[] = responseData?.["edi_statuses_out"];
            this.labelOutboundShipmentStatuses.visible = true;
            this.labelOutboundShipmentStatusCount.visible = true;
            if (outboundRows && outboundRows.length > 0) {
                this.labelOutboundShipmentStatusCount.caption = outboundRows.length.toString();
                this.labelOutboundShipmentStatusCount.color = "primary.dark";
                this.imageOutboundShipmentStatusError.visible = false;

                for (let i = 0; i < outboundRows.length; i++) {
                    const statusRow: ModelRow = outboundRows[i];
                    const error: number = statusRow["status_error"];

                    if (error > 0) {
                        this.labelOutboundShipmentStatusCount.color = "error";
                        this.imageOutboundShipmentStatusError.visible = true;
                        break;
                    }
                }
            } else {
                this.labelOutboundShipmentStatusCount.caption = "0";
                this.labelOutboundShipmentStatusCount.color = "primary.dark";
                this.imageOutboundShipmentStatusError.visible = false;
            }
        } else {
            this.labelOutboundShipmentStatuses.visible = false;
            this.labelOutboundShipmentStatusCount.visible = false;
            this.imageOutboundShipmentStatusError.visible = false;
        }

        // Show or hide the inbound shipment status count
        if (hasInStatuses) {
            const inboundRows: ModelRow[] = responseData?.["edi_statuses_in"];
            this.labelInboundShipmentStatuses.visible = true;
            this.labelInboundShipmentStatusCount.visible = true;
            if (inboundRows && inboundRows.length > 0) {
                this.labelInboundShipmentStatusCount.caption = inboundRows.length.toString();
                this.labelInboundShipmentStatusCount.color = "primary.dark";
                this.imageInboundShipmentStatusError.visible = false;

                for (let i = 0; i < inboundRows.length; i++) {
                    const statusRow: ModelRow = inboundRows[i];
                    const error: number = statusRow["status_error"];

                    if (error > 0) {
                        this.labelInboundShipmentStatusCount.color = "error";
                        this.imageInboundShipmentStatusError.visible = true;
                        break;
                    }
                }
            } else {
                this.labelInboundShipmentStatusCount.caption = "0";
                this.labelInboundShipmentStatusCount.color = "primary.dark";
                this.imageInboundShipmentStatusError.visible = false;
            }
        } else {
            this.labelInboundShipmentStatuses.visible = false;
            this.labelInboundShipmentStatusCount.visible = false;
            this.imageInboundShipmentStatusError.visible = false;
        }
    }

    dataFusionCompCountOnClick(event: ClickEvent) {
        const selectedCompId = event.target.id;

        if (this.movementId && this.orderId && this.customerId) {
            const layout = Layout.getLayout("lme/dispatch/ShipmentStatusSlideout", {
                width: window.innerWidth * .75,
                backgroundColor: "defaultBackground",
                fillHeight: true,
                paddingBottom: 50
            }) as ShipmentStatusSlideout;

            new SlideoutDecorator({
                layout: layout,
                title: "DataFusion - Movement " + this.movementId,
                fillVerticalSpace: true,
                addlComponents: [layout.saveButton],
                doAfterSlideIn: (decorator: SlideoutDecorator) => {
                    layout.sourceOrderEdistatus.search({ movement_id: this.movementId });
                    layout.sourceEdiOrder.search({ order_id: this.orderId });
                    layout.filterByMovement = true;
                    layout.saveButton.addClickListener(event => {
                        if (decorator.validateSimple()) {
                            const dataLayout = layout.outboundDataSource.data;
                            dataLayout?.forEach((data, index) => {
                                if (data.hasChanged()) {
                                    layout.outboundDataSource.data[index].post();
                                }
                            });
                        }
                    });
                    let selIndex: number = 0;
                    if (selectedCompId == this.labelInboundTenderCount.id) {
                        selIndex = layout.tabset1.indexOf(layout.tabInboundTender);
                    } else if (selectedCompId == this.labelOutboundTenderCount.id) {
                        selIndex = layout.tabset1.indexOf(layout.tabOutboundTender);
                    } else if (selectedCompId == this.labelOutboundShipmentStatusCount.id) {
                        selIndex = layout.tabset1.indexOf(layout.tabOutbound);
                    } else if (selectedCompId == this.labelInboundShipmentStatusCount.id) {
                        selIndex = layout.tabset1.indexOf(layout.tabInbound);
                    }
                    layout.tabset1.selectedIndex = selIndex;
                }
            });

            layout.orderId = this.orderId;
            layout.movementId = this.movementId;
            layout.customerId = this.customerId;
        }
    }

    get movementId(): string {
        return this._movementId;
    }

    set movementId(movementId: string) {
        this._movementId = movementId;
    }

    get orderId(): string {
        return this._orderId;
    }

    set orderId(orderId: string) {
        this._orderId = orderId;
    }

    get customerId(): string {
        return this._customerId;
    }

    set customerId(customerId: string) {
        this._customerId = customerId;
    }

    get displayingData(): boolean {
        return this._displayingData;
    }

    set displayingData(displayingData: boolean) {
        this._displayingData = displayingData;
    }
}
