import { EdiBrltlFreightItemTable } from "../EdiBrltlFreightItemTable";
import { EdiOrderCarrierPay } from "../EdiOrderCarrierPay";
import { EdiOrderFA } from "../EdiOrderFA";
import { EdiOrderHandlingRequirements } from "../EdiOrderHandlingRequirements";
import { EdiOrderOverview } from "../EdiOrderOverview";
import { EdiOrderRates } from "../EdiOrderRates";
import { EdiOrderReply } from "../EdiOrderReply";
import { EdiTenderErrors } from "../EdiTenderErrors";
import { Button, Checkbox, DataHeader, DataSource, HorizontalSpacer, Image, Label, Layout, Panel, Switch, Tab, Table, Tabset, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutViewEdiOrder extends Layout {
	actionPanel: Panel;
	buttonLinkTender: Button;
	buttonLock: Button;
	buttonPurposeSwap: Button;
	buttonUsers: Button;
	checkboxFloorLoadedFreight: Checkbox;
	checkboxHazmatRouting: Checkbox;
	checkboxHighValue: Checkbox;
	checkboxIntercompany: Checkbox;
	checkboxNoDisplay: Checkbox;
	checkboxPreloaded: Checkbox;
	checkboxRoundTrip: Checkbox;
	checkboxTeamsRequired: Checkbox;
	classicButton: Button;
	dataheader1: DataHeader;
	errorLayout: EdiTenderErrors;
	generalPanel: Panel;
	horizontalspacer12: HorizontalSpacer;
	imageGoToOrder: Image;
	labelHyphen: Label;
	labelLabel5: Label;
	labelOrderId: Label;
	labelOrderNumber: Label;
	labelOrderStatus: Label;
	labelTemperatureRange: Label;
	labelTenderNumber: Label;
	labelTransmitStatus: Label;
	layoutEdiOrderCarrierPay: EdiOrderCarrierPay;
	layoutFA: EdiOrderFA;
	layoutFreightItems: EdiBrltlFreightItemTable;
	layoutHandlingReqs: EdiOrderHandlingRequirements;
	layoutOverview: EdiOrderOverview;
	layoutPartnerValues: Layout;
	layoutRates: EdiOrderRates;
	layoutReply: EdiOrderReply;
	panelCommodityCheckboxes: Panel;
	panelDataHeaderAddlLeft: Panel;
	panelErrors: Panel;
	panelGeneralUpdate: Panel;
	panelOutbound: Panel;
	panelTenderInfo: Panel;
	sourceBrltlEdiOrderHdrXFgp: DataSource;
	sourceEdiBrltlOrderFreightGroupItem: DataSource;
	sourceEdiFunctionalAck: DataSource;
	sourceEdiMapError: DataSource;
	sourceEdiOrder: DataSource;
	sourceEdiStop: DataSource;
	sourceResponsibleHist: DataSource;
	sourceViewEdiOrder: DataSource;
	stopsTable: Table;
	switchHazmat: Switch;
	switchIgnoreErrors: Switch;
	tabAccessorials: Tab;
	tabBrltlFreightItems: Tab;
	tabCarrierPay: Tab;
	tabCommodity: Tab;
	tabEquipment: Tab;
	tabErrors: Tab;
	tabFA: Tab;
	tabGeneral: Tab;
	tabMapErrors: Tabset;
	tabOverview: Tab;
	tabPartnerInfo: Tab;
	tabRate: Tab;
	tabReply: Tab;
	tabStops: Tab;
	tabTenderInfo: Tab;
	tableEquipmentRequirements: Table;
	tabsetAddOrders: Tabset;
	teamPanel: Panel;
	tempPanel: Panel;
	textReadyToSend: Textbox;
	textboxAgentPayeeId: Textbox;
	textboxAppType: Textbox;
	textboxBlnum: Textbox;
	textboxCommodity: Textbox;
	textboxCommodityId: Textbox;
	textboxConsigneeRefno: Textbox;
	textboxCreateDate: Textbox;
	textboxCustomerId: Textbox;
	textboxDefaultEquipMatchId: Textbox;
	textboxDirection: Textbox;
	textboxEdiError: Textbox;
	textboxEnteredUserId: Textbox;
	textboxEquipmentTypeIdEquipment: Textbox;
	textboxEquipmentTypeIdGeneral: Textbox;
	textboxGs06GroupCntlno: Textbox;
	textboxGsDateTime: Textbox;
	textboxHazmatCode: Textbox;
	textboxId: Textbox;
	textboxIsa13IntrCntlno: Textbox;
	textboxOrderMode: Textbox;
	textboxOrderType: Textbox;
	textboxOrderValue: Textbox;
	textboxOrderedBy: Textbox;
	textboxOrderedDate: Textbox;
	textboxOrderedMethod: Textbox;
	textboxPartnerId: Textbox;
	textboxPieces: Textbox;
	textboxPurpose: Textbox;
	textboxRateSource: Textbox;
	textboxRbdTimezoneId: Textbox;
	textboxRespondByDate: Textbox;
	textboxRevenueCodeId: Textbox;
	textboxShipmentId: Textbox;
	textboxSt02TrxsetCntlno: Textbox;
	textboxTemperatureMax: Textbox;
	textboxTemperatureMin: Textbox;
	textboxTenderStatus: Textbox;
	textboxTractorType: Textbox;
	textboxVersion: Textbox;
	textboxWeight: Textbox;
}
