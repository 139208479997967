import { Button, ClickEvent, DataDisplayEvent, Panel, SelectionEvent, Snackbar } from "@mcleod/components";
import { CityUtil, DateRange, StringUtil, getLogger } from "@mcleod/core";
import { ExpandableFilterController } from "@mcleod/general/src/ExpandableFilterController";
import { EdiMakeStopQuickInfo } from "./EdiMakeStopQuickInfo";
import { AutogenLayoutEdiSuggestedPricing } from "./autogen/AutogenLayoutEdiSuggestedPricing";

const log = getLogger("lme.datafusion.EdiSuggestedPricing");

export class EdiSuggestedPricing extends AutogenLayoutEdiSuggestedPricing {
    private expandableFilter: ExpandableFilterController = new ExpandableFilterController();
    private isLaneHistoryDataLoaded: boolean = false;
    private isRateIndexDataLoaded: boolean = false;
    private isCommitmentDataLoaded: boolean = false;
    private initialSelectedTabId: string;
    private excludeButtonOnClick: () => {};
    private declineButtonOnClick: () => {};
    private acceptButtonOnClick: () => {};

    onLoad() {
        this.setupExpandableFilter();
        this.layoutTenderRates.panelOtherChargeTable.collapse();
    }

    sourceEdiOrdersListOnDisplay(event: DataDisplayEvent) {
        switch (this.initialSelectedTabId) {
            case "tabLaneHistory":
                this.populateLaneHistoryInformation();
                this.tabsetEdiSuggestedPricing.selectedIndex = this.tabLaneHistory._index;
                break;
            case "tabRateIndex":
                this.populateRateIndexInformation();
                this.tabsetEdiSuggestedPricing.selectedIndex = this.tabRateIndex._index;
                break;
            case "tabCommitmentAutoRating":
                this.populateCommitmentInformation();
                this.tabsetEdiSuggestedPricing.selectedIndex = this.tabCommitmentAutoRating._index;
                break;
        }
    }

    tabsetEdiSuggestedPricingBeforeTabSelection(event: SelectionEvent) {
        if (!this.isLaneHistoryDataLoaded && event.newSelection.element.id === "tabLaneHistory")
            this.populateLaneHistoryInformation();
        else if (!this.isRateIndexDataLoaded && event.newSelection.element.id === "tabRateIndex")
            this.populateRateIndexInformation();
        else if (!this.isCommitmentDataLoaded && event.newSelection.element.id === "tabCommitmentAutoRating")
            this.populateCommitmentInformation();
    }

    populateCommitmentInformation() {
        const row = this.mainDataSource.activeRow;
        if (row) {
            const origDestRateId = row?.get("orig_dest_rate_id");
            const commitmentId = row?.get("commitment_id");
            const filter = commitmentId ? { id: origDestRateId, "commitment_detail.id": commitmentId } : { id: origDestRateId };
            if (origDestRateId) {
                this.layoutCommitmentAutoRating.search(filter);
            }
            if (!commitmentId) {
                Snackbar.showSnackbar("No active commitment detail found. Load tender did not receive a commitment ID when auto rated.");
            }
            this.isCommitmentDataLoaded = true;
        }
    }

    async populateRateIndexInformation() {
        const rateIndexSnackbar = Snackbar.showDownloadSnackbar("Loading...", "Loading rate index information...");
        this.layoutRateIndex.resetTotalRatesPanel();
        this.layoutRateIndex.hideColumns();
        this.layoutRateIndex.buttonFetchOtherResults.visible = false;
        this.layoutRateIndex.marginBottom = 20;
        await this.sourceEdiStop?.search({ order_id: this.activeRow?.get("id") }).then(response => {
            if (response) {
                const stopData = response["modelRows"].map(value => value.data);
                this.layoutRateIndex.search({
                    stops: stopData,
                    mileage: this.activeRow?.get("bill_distance"),
                    equipment_id: this.activeRow?.get("equipment_type_id"),
                    show_all_total_rates: true
                }).then((response) => {
                    if (response) {
                        const data = response["modelRows"][0].data;
                        for (const vendorData of data["vendors_data"]) {
                            this.layoutRateIndex.populateRateIndexResultData(vendorData, false, data["show_company_scac"], null);
                        }
                    }
                    else log.error("Error fetching rate index results for Load Tender Express");
                    rateIndexSnackbar.dismiss(0, true);
                });
            }
        });
        this.isRateIndexDataLoaded = true;
    }

    populateLaneHistoryInformation() {
        const row = this.mainDataSource.activeRow;
        const findNearCityId = row?.get("edi_stop_origin.city_id");
        const destinationState = row?.get("edi_stop_consignee.state");
        this.textboxRadius.text = "75";
        this.citystateStopOriginCityId.textCombined.text = CityUtil.formatCityStateZip(row?.get("edi_stop_origin.city_name"), row?.get("edi_stop_origin.state"), row?.get("edi_stop_origin.zip_code"));
        if (findNearCityId && destinationState) this.layoutLaneHistory.search({ find_near_radius: 75, days_back: 180, find_near_city_id: findNearCityId, destination_state: destinationState });
        this.isLaneHistoryDataLoaded = true;
    }

    setupToolTip() {
        this.textboxStopsPickDrop.tooltipCallback = EdiMakeStopQuickInfo.makeStopsTooltipCallbackFunction(this.activeRow?.get("id"), this.textboxStopsPickDrop);
    }

    // START - Expandable Filter Logic
    private setupExpandableFilter() {
        const panelSearchHeader = this.tabLaneHistory.findComponentById("panelSearchHeader") as Panel;
        const panelSearchFilter = this.tabLaneHistory.findComponentById("panelSearchFilter") as Panel;
        this.expandableFilter.initialize(panelSearchHeader, panelSearchFilter);
        this.expandableFilter.toggle();
        this.expandableFilter.onClear = (event) => this.clearLaneHistoryFilter(event);
        this.expandableFilter.onSearch = (event) => this.searchLaneHistoryFilter(event);
    }

    private clearLaneHistoryFilter(event: ClickEvent) {
        this.sourceLaneHistory.searchRow.clear();
        this.textboxRadius.clear();
        this.textboxDateRange.clear();
        this.textboxMovementStatus.clear();
        this.textboxMovementStatus.selectedItem = null;
    }

    private searchLaneHistoryFilter(event: ClickEvent) {
        const data = this.mainDataSource.activeRow;
        const findNearCityId = data?.get("edi_stop_origin.city_id");
        const destinationState = data?.get("edi_stop_consignee.state");
        const dateRange = DateRange.parseNumericDateRange(this.textboxDateRange.getDataValue()) as DateRange;
        const status = this.textboxMovementStatus.getDataValue();
        const searchParams = {
            "date_range.start": dateRange.beginningDate ?? null,
            "date_range.end": dateRange.endDate ?? null,
            find_near_radius: !StringUtil.isEmptyString(this.textboxRadius.getDataValue()) ? this.textboxRadius.getDataValue() : 75,
            days_back: dateRange.beginningDate && dateRange.endDate ? null : 180,
            find_near_city_id: findNearCityId,
            destination_state: destinationState,
            status: status ?? null
        }
        if (findNearCityId && destinationState) this.layoutLaneHistory.search(searchParams);
    }
    // END - Expandable Filter Logic

    get excludeButton(): Button {
        return new Button({
            id: "excludeButton",
            caption: "Exclude",
            backgroundColor: "defaultBackground",
            color: "primary",
            fontBold: true,
            minWidth: 128,
            borderWidth: 0,
            rowBreak: false,
            onClick: () => {
                this.excludeButtonOnClick();
            }
        });
    }

    get declineButton(): Button {
        return new Button({
            id: "declineButton",
            caption: "Decline",
            backgroundColor: "defaultBackground",
            color: "primary",
            minWidth: 128,
            fontBold: true,
            borderWidth: 0,
            rowBreak: false,
            onClick: () => {
                this.declineButtonOnClick();
            }
        });
    }

    get acceptButton(): Button {
        return new Button({
            id: "acceptButton",
            caption: "Accept",
            backgroundColor: "primary",
            color: "McLeodWhite",
            minWidth: 128,
            fontBold: true,
            borderWidth: 0,
            rowBreak: false,
            onClick: () => {
                this.acceptButtonOnClick();
            }
        });
    }

    set excludeButtonCallback(callback) {
        this.excludeButtonOnClick = callback;
    }

    set declineButtonCallback(callback) {
        this.declineButtonOnClick = callback;
    }

    set acceptButtonCallback(callback) {
        this.acceptButtonOnClick = callback;
    }

    set initialSelectedTab(id: string) {
        this.initialSelectedTabId = id;
    }
}
