import { DataSource, HorizontalSpacer, Layout, Panel, SearchButton, Table, Textbox, } from "@mcleod/components";

export abstract class AutogenLayoutMovementSearch extends Layout {
    horizontalspacer1: HorizontalSpacer;
    panel1: Panel;
    searchbuttonSearch: SearchButton;
    sourceMovement: DataSource;
    tableMovements: Table;
    textboxId: Textbox;
    textboxOrdersId: Textbox;
}
