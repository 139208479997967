import { Label, Panel } from "@mcleod/components";
import { DateUtil, ModelRow } from "@mcleod/core";

export class DispatchCommentFormat {
    private maxChars: number;

    constructor(maxChars: number) {
        this.maxChars = maxChars;
    }

    public displayComment(panel: Panel, row: ModelRow) {
        let message = '"' + row.get("comments") + '"';
        const user = row.get("user_name");
        const date: string = DateUtil.formatDate(new Date(row.get("entered_date")));

        const tooltip = message;
        if (message.length > this.maxChars) {
            message = message.substring(0, this.maxChars) + ' ..."';
        }
        panel.add(new Label({ caption: message, tooltip: tooltip, fontSize: "medium", fontBold: true, paddingRight: 4, fillRow: true, rowBreak: false }));
        panel.add(new Label({ caption: user, fontSize: "medium", fontBold: false, rowBreak: false }));
        panel.add(new Label({ caption: date, fontSize: "medium", fontBold: false, rowBreak: false }));
    }
}
