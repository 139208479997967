import { Checkbox, DataDisplayEvent, Label, Table, TableRow, TableRowDisplayEvent } from "@mcleod/components";
import { DataSourceMode } from "@mcleod/components/src/databinding/DataSource";
import { ModelRow } from "@mcleod/core/src/ModelRow";
import { ReferenceNumberCompareItem } from "./ReferenceNumberCompareItem";
import { StopNoteCompareItem } from "./StopNoteCompareItem";
import { AutogenLayoutChangeStopCompareItems } from "./autogen/AutogenLayoutChangeStopCompareItems";


export class ChangeStopCompareItems extends AutogenLayoutChangeStopCompareItems {
    private _compareItems: ModelRow[];
    private _childItemGroups: ModelRow[];
    private _key: string;
    private _refnos: ModelRow[];
    private _stopNotes: ModelRow[] = [];

    override onLoad(): void | Promise<void> {

        this.tableCompareItems.dataSource;
        this.tableCompareItems.dataSource = this.sourceEdiCompareItem;
        this.tableCompareItems.dataSource.data = [];

        const rows = [];
        if (this._compareItems != null)
            this.tableCompareItems.dataSource.setRowsAndMode(DataSourceMode.NONE, this._compareItems);

        if (this._compareItems != null && this._compareItems.length == 0) {
            this.tableCompareItems.visible = false;
        }
        if (this._refnos != null && this._refnos.length > 0) {
            this.loadChildTableData(this.tableStopRefNo, this._refnos, DataSourceMode.ADD);
        } else {
            this.panelRefnos.visible = false;
        }

        if (this._stopNotes != null && this._stopNotes.length > 0) {
            this.loadChildTableData(this.tableStopComment, this._stopNotes, DataSourceMode.ADD);
        } else {
            this.panelComments.visible = false;
        }
    }

    valueOnDisplay(event: DataDisplayEvent) {
        const label = event.target as Label;
        if (label.caption == "1900-01-01") {
            label.caption = "";
        }
    }
    public async setupTableData(data: ModelRow[]) {
        this._compareItems = data;
    }

    public setRefnos(data: ModelRow[]): void {
        this._refnos = data;
    }

    public setStopNotes(data: ModelRow[]): void {
        this._stopNotes = data;
    }

    tableCompareItemsOnRowDisplay(event: TableRowDisplayEvent) {
        this.printCheckbox("tableCompareItems", event.getTableRow());
    }

    private loadChildTableData(table: Table, data: any[], mode: DataSourceMode) {
        const tableDataSource = table.dataSource;
        tableDataSource.data = [];
        const modelRows = [];
        data.forEach((element: any) => {
            modelRows.push(new ModelRow(tableDataSource.url, true, element));
        });
        tableDataSource.setRowsAndMode(mode, modelRows);
    }

    onCommentRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        this.printCheckbox("tableStopComment", tableRow);
        tableRow.findComponentById((comp: any) => {
            if (comp instanceof StopNoteCompareItem) {
                const compareItems: StopNoteCompareItem = comp;
                const rows = [];
                this._stopNotes[tableRow.index]["compare_items"].forEach((element: any) => {
                    const newRow = new ModelRow("lme/datafusion/edi-compare-item", true, element);
                    rows.push(newRow);
                });

                compareItems.setupTableData(rows);
            }
        });
    }

    onRefnoRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        this.printCheckbox("tableStopRefNo", tableRow);
        tableRow.findComponentById((comp: any) => {
            if (comp instanceof ReferenceNumberCompareItem) {
                const compareItems: ReferenceNumberCompareItem = comp;
                const rows = [];
                this._refnos[tableRow.index]["compare_items"].forEach((element: any) => {
                    const newRow = new ModelRow("lme/datafusion/edi-compare-item", true, element);
                    rows.push(newRow);
                });

                compareItems.setupTableData(rows);
            }
        });
    }

    printCheckbox(tableName: string, row: TableRow) {
        let checkboxText: string;
        let labelText: string;

        switch (tableName) {
            case "tableCompareItems": {
                checkboxText = "checkboxApply";
                labelText = "labelChecked";
                break;
            }
            case "tableStopComment": {
                checkboxText = "checkboxApplyStop";
                labelText = "labelCheckedStop";
                break;
            }
            case "tableStopRefNo": {
                checkboxText = "checkboxApplyRefNo";
                labelText = "labelCheckedRefNo";
                break;
            }
        }
        const checkbox = row.findComponentById(checkboxText) as Checkbox;
        checkbox.printable = false;
        const labelChecked = row.findComponentById(labelText) as Label;
        checkbox.addClickListener(() => labelChecked.visible = checkbox.checked);
    }
}
