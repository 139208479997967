import { DataSourceMode, ValidationResult } from "@mcleod/components";
import { ModelRow, StringUtil } from "@mcleod/core";
import { AutogenLayoutServiceIncident } from "./autogen/AutogenLayoutServiceIncident";

export class ServiceIncident extends AutogenLayoutServiceIncident {

    private _orderCustomer: String;

    override onLoad() {
        this.textboxEdiStandardCode.onSelectItem = ((textbox, selectedRow) => {
            const modelRow = selectedRow as ModelRow;

            this.mainDataSource.activeRow.set("edi_element_id", modelRow.get("element_id"));
            this.mainDataSource.activeRow.set("edi_code_version", modelRow.get("version"));

            return undefined;
        });
    }

    sourceCallinStopOnDisplay(event) {
        this.sourceServicefail.mode = DataSourceMode.UPDATE;
    }

    filterStandardCodes(orderCustomerId: String) {
        this.textboxEdiStandardCode.addBeforeLookupModelSearchListener(event => {
            event.filter.customer_id = orderCustomerId;
            event.filter.code_type = (StringUtil.isEmptyString(orderCustomerId)) ? "1651" : "DelayReason";
            event.filter.use_enabled_flag = "Y";
        })
    }

    getStandardCodeFilter(): any {
        return {
            "customer_id": this._orderCustomer,
            "code_type": (StringUtil.isEmptyString(this._orderCustomer)) ? "1651" : "DelayReason",
            "use_enabled_flg": "Y"
        };
    }

    set orderCustomerId(value: String) {
        this._orderCustomer = value;
        this.filterStandardCodes(this._orderCustomer);
    }

    get orderCustomerId() {
        return this._orderCustomer;
    }

    override validate(checkRequired: boolean, showErrors: boolean = true): ValidationResult[] {
        if (this.visible == false)
            return [{ component: this, isValid: true }];
        else
            return super.validate(checkRequired, showErrors);
    }
}
