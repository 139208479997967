import { TableRowDisplayEvent } from "@mcleod/components";
import { AutogenLayoutContactLookup } from "./autogen/AutogenLayoutContactLookup";

export class ContactLookup extends AutogenLayoutContactLookup {

    override onLoad() {
        this.tableContactLookup.addRowDisplayListener((event: TableRowDisplayEvent) => {
            const data = event.getTableRow().data;
            if (data.get("phone") == null)
                data.set("phone", data.get("mobile_phone"))
        })
    }
}
