import { CommonDialogs } from "@mcleod/common";
import { ClickEvent, Component, TableRow, TableRowDisplayEvent } from "@mcleod/components";
import { Api, ModelRow } from "@mcleod/core";
import { makeTooltipCallbackFunction } from "./LocationMakeQuickInfo";
import { AutogenLayoutRecRearrangeStops } from "./autogen/AutogenLayoutRecRearrangeStops";

export class RecRearrangeStops extends AutogenLayoutRecRearrangeStops {
    private _doAfterClose: (canceled: boolean) => void;
    public onSave = (): Promise<boolean> => this.rearrangeStops();
    private _orderId: string;

    private async rearrangeStops(): Promise<boolean> {
        if (this.checkStopStatuses()) {
            try {
                const response = await Api.post("lme/dispatch/rearrange-stops",
                    {
                        "order_id": this.orderId,
                        "order_type": "R",
                        "stops": this.tableStop.dataSource.data
                    });
                if (response) {
                    this.close(false);
                    return true;
                }
            } catch (e) {
                CommonDialogs.showError(e);
            }

        }
        else {
            CommonDialogs.showError("A cleared stop cannot be after an uncleared stop.");
        }
        return false;
    }


    private checkStopStatuses(): boolean {
        let foundAvailableStop = false;
        let result = true;
        const rows = this.tableStop.rows;
        for (let i = 0; i < rows.length; ++i) {
            const stopStatus = rows[i].data.get("status");
            if (stopStatus == "D") {
                if (foundAvailableStop) {
                    result = false;
                    break;
                }
            }
            else
                foundAvailableStop = true;
        }
        return result;
    }

    savebutton1OnClick(event: ClickEvent) {
        this.rearrangeStops();
    }

    /** This is an event handler for the onRowDisplay event of tableStop.  */
    tableStopOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.target as TableRow;
        const modelRow: ModelRow = tableRow.data as ModelRow;
        const locationName: Component = tableRow.findComponentById("textboxLocationName");
        if (locationName != null)
            locationName.tooltipCallback = makeTooltipCallbackFunction(modelRow.get("location_id"), locationName);
        tableRow.canBeDeleted = false;
    }

    close(canceled: boolean) {
        this.slideOut().then(() => {
            if (this.doAfterClose != null)
                this.doAfterClose(canceled);
        });
    }

    public set doAfterClose(f) {
        this._doAfterClose = f;
    }

    public get doAfterClose() {
        return this._doAfterClose;
    }

    public get orderId() {
        return this._orderId;
    }

    public set orderId(id) {
        this._orderId = id;
    }
}
