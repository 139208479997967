import { makeStyles } from "@mcleod/core";

export const DialogStyles = makeStyles("dlg", {
    centered: {
        margin: 0,
        position: "absolute",
        top: "50%",
        left: "50%",
        MsTransform: "translate(-50%, -50%)",
        transform: "translate(-50%, -50%)",
        maxWidth: "90%",
        maxHeight: "calc(100% - 64px)",
        boxShadow: "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
        borderRadius: 4
    }
});