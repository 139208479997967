import { AutogenLayoutCommitmentAutoRating } from "./autogen/AutogenLayoutCommitmentAutoRating";

export class CommitmentAutoRating extends AutogenLayoutCommitmentAutoRating {
    onLoad(): void | Promise<void> {
        this.labelAwardVolume.caption = "--";
        this.labelFrequency.caption = "--";
        this.labelRemainingNeeded.caption = "--";

        this.labelOfferedToday.caption = "--";
        this.labelOfferedMonth.caption = "--";
        this.labelOfferedWeek.caption = "--";
        this.labelOfferedQuarter.caption = "--";
        this.labelOfferedYear.caption = "--";

        this.labelAcceptedToday.caption = "--";
        this.labelAcceptedMonth.caption = "--";
        this.labelAcceptedWeek.caption = "--";
        this.labelAcceptedQuarter.caption = "--";
        this.labelAcceptedYear.caption = "--";

        this.labelRejectedToday.caption = "--";
        this.labelRejectedMonth.caption = "--";
        this.labelRejectedWeek.caption = "--";
        this.labelRejectedQuarter.caption = "--";
        this.labelRejectedYear.caption = "--";


        this.labelRate.caption = "--";
        this.labelRateNumber.caption = "--";
        this.labelCommitmentId.caption = "--";
        this.labelRateDescription.caption = "--";
        this.labelDescr.caption = "--";
    }
}