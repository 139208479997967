import { Button, DataSource, Layout, Table } from "@mcleod/components";

export abstract class AutogenLayoutRoutingGuidesTable extends Layout {
    buttonBlastAllOffers: Button;
    buttonWaterfallTender: Button;
    sourceMovement: DataSource;
    sourceTopmatch: DataSource;
    sourceTopmatchRoutingGuides: DataSource;
    tableRoutingGuides: Table;
}
