import { DataDisplayEvent, Label } from "@mcleod/components";
import { AutogenLayoutCommodityLookup } from "./autogen/AutogenLayoutCommodityLookup";

export class CommodityLookup extends AutogenLayoutCommodityLookup {
    labelHazmatOnDataDisplay(event: DataDisplayEvent) {
        const label = event.target as Label;
        label.caption = null;
        if (event.rowData?.get("is_hazmat") === "Y") {
            label.imageName = "warning";
            label.imageColor = "red";
        }
    }
}
