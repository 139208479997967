import { Button, Image, ImageType, Layout, SlideoutDecorator, Snackbar, Textbox, Toast } from "@mcleod/components";
import { ModelRow } from "@mcleod/core";
import { makeTooltipCallbackFunction } from "./LocationMakeQuickInfo";
import { AutogenLayoutBillOfLading } from "./autogen/AutogenLayoutBillOfLading";

interface BillOfLadingProps {
    order_id: string,
    customer_id: string,
    _lookup_customer_id: ModelRow,
    shipperLocationId: string,
    bol_note: string,
    onError: () => void
}

export class BillOfLading extends AutogenLayoutBillOfLading {
    static showBillOfLadingSlideout(props: { shipperLocationId: any; bol_note: any; onError: (message: string) => Snackbar; _lookup_customer_id: any; customer_id: any; order_id: any }) {
        const layout = Layout.getLayout("lme/dispatch/BillOfLading") as BillOfLading;
        new SlideoutDecorator({
            layout,
            title: "Bill of Lading",
            fillVerticalSpace: true,
            layoutLoadListeners: async () => {
                await layout.mainDataSource.search({ order_id: props.order_id, bol_note: props.bol_note });
                if (layout.activeRow.get("error_message", false)) {
                    layout.slideOut().then(() => {
                        props.onError(layout.activeRow.get("error_message"));
                    });
                }
                layout.activeRow.set("order_id", props.order_id); // need to set it here for POST - seems that because order_id isn't a bound component it needs to be done this way
                layout.activeRow.setValues({ ...props });
                layout.setupContactListeners();
                const bolReport = layout.activeRow.get("bol_document", null);
                if (bolReport) {
                    layout.generateBillOfLading(bolReport, props.order_id);
                }
                layout.mainDataSource.addAfterExecutionListener(() => {
                    layout.slideOut().then(() => {
                        Toast.showSuccessToast("Bill of Lading Sent");
                    })
                });
            },
            addlComponents: layout.sendButton
        });
    }

    private sendButton: Button = new Button({
        dataSource: this.mainDataSource,
        busyWhenDataSourceBusy: true,
        caption: "Send",
        rowBreak: false,
        backgroundColor: "primary",
        color: "primary.reverse",
        borderWidth: 0,
        width: 150,
        onClick: () => this.sendEmail()
    });

    /** This is an event handler for the onChange event of switchCustomerShipper.  */
    switchCustomerShipperOnChange(event) {
        this.textboxCustomer.visible = !event.target.checked;
        this.textboxShipper.visible = event.target.checked;
        this.textboxContactName.clear();
        this.textboxEmail.clear();
    }

    private sendEmail() {
        if (this.validateSimple()) {
            this.mainDataSource.post();
        }
    }

    async generateBillOfLading(bolReport: string, orderId: string) {
        const imgBol = new Image({ imageType: ImageType.PDF, fillHeight: true, minWidth: 700, id: "imageBolReport" });
        imgBol.imageBytes = bolReport;
        this.panelPdf.add(imgBol);
        this.activeRow.set("subject", "Bill of Lading for Order # " + orderId);
    }

    private setupContactListeners() {
        this.textboxCustomer.displayData(this.activeRow, null, 0);
        this.textboxContactName.addBeforeLookupModelSearchListener(event => {
            if (this.activeRow.get("lookup_type") === "S") {
                // Shipper
                if (!this.activeRow.isNull("shipperLocationId")) {
                    event.filter.parent_row_type = "L";
                    event.filter.parent_row_id = this.activeRow.get("shipperLocationId");
                    event.filter.is_active = "Y";
                } else {
                    event.filter.id = null;
                }
            } else {
                // Customer
                event.filter.parent_row_id = this.activeRow.get("customer_id");
                event.filter.parent_row_type = "C";
            }
        });

        this.textboxContactName.onSelectItem = ((textbox: Textbox, selection: ModelRow) => {
            const emailAddress = selection.get("email", null);
            if (emailAddress) {
                this.activeRow.set("to_address", emailAddress);
            }
            return undefined;
        });

        this.textboxShipper.tooltipCallback = makeTooltipCallbackFunction(this.activeRow.get("shipperLocationId"), this.textboxShipper);
    }
}
