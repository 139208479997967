import { Checkbox, DataDisplayEvent, TableRow, TableRowDisplayEvent } from "@mcleod/components";
import { Table } from "@mcleod/components/src/components/table/Table";
import { DataSourceMode } from "@mcleod/components/src/databinding/DataSource";
import { ModelRow } from "@mcleod/core/src/ModelRow";
import { OtherChargeChangeCompareItem } from "./OtherChargeChangeCompareItem";
import { OtherChargeCompareItem } from "./OtherChargeCompareItem";
import { AutogenLayoutOtherChargeCompare } from "./autogen/AutogenLayoutOtherChargeCompare";


export class OtherChargeCompare extends AutogenLayoutOtherChargeCompare {
    private _newOrRemovedOtherCharges: ModelRow[];
    private _changedOtherCharges: ModelRow[];


    public get newOrRemovedOtherCharges(): ModelRow[] {
        return this._newOrRemovedOtherCharges;
    }
    public set newOrRemovedOtherCharges(value: ModelRow[]) {
        this._newOrRemovedOtherCharges = value;
    }

    public get changedOtherCharges(): ModelRow[] {
        return this._changedOtherCharges;
    }
    public set changedOtherCharges(value: ModelRow[]) {
        this._changedOtherCharges = value;
    }

    setupTableData(mode: DataSourceMode) {
        if (this.newOrRemovedOtherCharges == null || this.newOrRemovedOtherCharges.length == 0) {
            this.labelOtherChargeAddsAndDeletes.visible = false;
            this.tableOtherCharge.visible = false;
        } else {
            this.loadChildTableData(this.tableOtherCharge, this.newOrRemovedOtherCharges, mode)
        }
        if (this.changedOtherCharges == null || this.changedOtherCharges.length == 0) {
            this.labelChanges.visible = false;
            this.tableOCChanges.visible = false;
        } else {
            this.loadChildTableData(this.tableOCChanges, this.changedOtherCharges, mode);
        }
    }

    private loadChildTableData(table: Table, data: any[], mode: DataSourceMode) {
        const tableDataSource = table.dataSource;
        tableDataSource.data = [];
        const modelRows = [];
        data.forEach((element: any) => {
            modelRows.push(new ModelRow(tableDataSource.url, true, element));
        });
        tableDataSource.setRowsAndMode(mode, modelRows);
    }
    private _getArrayAndNull(row: ModelRow, field: string): ModelRow[] {
        const result = row.get(field) || [];
        row.set(field, null);
        return result;
    }
    onItemRowDisplay(event: TableRowDisplayEvent) {

    }
    onOtherChargeRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        const checkboxApply = tableRow.findComponentById("checkboxApply") as Checkbox;
        checkboxApply.printable = false;
        const layoutItems = tableRow.findComponentById((comp: any) => {
            if (comp instanceof OtherChargeCompareItem) {
                const compareItems: OtherChargeCompareItem = comp;
                const rows = [];
                this.newOrRemovedOtherCharges[tableRow.index]["compare_items"].forEach((element: any) => {
                    const newRow = new ModelRow("lme/datafusion/edi-compare-item", true, element);
                    rows.push(newRow);
                });
                compareItems.setupTableData(rows);
            }
        });
    }

    valueOnDisplay(event: DataDisplayEvent) {

    }

    onOCChangeDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.getTableRow();
        const layoutItems = tableRow.findComponentById((comp: any) => {
            if (comp instanceof OtherChargeChangeCompareItem) {
                const compareItems: OtherChargeChangeCompareItem = comp;
                const rows = [];
                this.changedOtherCharges[tableRow.index]["compare_items"].forEach((element: any) => {
                    const newRow = new ModelRow("lme/datafusion/edi-compare-item", true, element);
                    rows.push(newRow);
                });
                compareItems.setupTableData(rows);

            }
        });
        if (tableRow.index % 2 == 0) {
            event.target.backgroundColor = "#FFFFFF";
        } else {
            event.target.backgroundColor = "subtle.lightest";
        }
    }
}