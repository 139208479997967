import { Attachment, DataSource, Layout, Panel, Switch, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutComments extends Layout {
    attachmentAttachment: Attachment;
    panelMain: Panel;
    panelOrderFields: Panel;
    sourceComments: DataSource;
    switchCopyToOrder: Switch;
    switchDispatch: Switch;
    textComments: Textbox;
    textboxCommentTypeId: Textbox;
    textboxEnteredDate: Textbox;
    textboxOrderCommentType: Textbox;
    textboxUserName: Textbox;
}
