/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelCalculateOtherCharge.ts          *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/dispatch/calculate-other-charge";

export class AutogenModelCalculateOtherCharge<RowType extends ModelRow> extends Endpoint<RowType, RowCalculateOtherChargeFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowCalculateOtherCharge extends ModelRow<RowCalculateOtherChargeFields> {
    constructor() {
        super(AutogenUserSavedSearchEndpoint);
    }
}

export interface RowCalculateOtherChargeFields {
    allocated_charge_key: string;
    allocation_method: string;
    amount: number;
    amount_c: string;
    amount_d: Date;
    amount_n: number;
    amount_r: number;
    bill_type: string;
    calc_method: string;
    charge_id: string;
    company_id: string;
    customer_id: string;
    ded_charge_customer_id: string;
    descr: string;
    driver_id: string;
    empty_distance_units: number;
    empty_distance_units_um: string;
    est_fuel_surcharge: string;
    id: string;
    incl_in_freight: string;
    is_dedicated_distance: string;
    is_taxable: string;
    jag_rate_id: number;
    loaded_distance_units: number;
    loaded_distance_units_um: string;
    ltl_allocation_method: string;
    order_id: string;
    rate: number;
    rate_id: number;
    revenue_share_order_id: string;
    sequence: number;
    stop_id: string;
    stop_loc_name: string;
    stop_movement_sequence: number;
    stop_no: string;
    tli_uid: number;
    units: number;
    charge_code: string;
    other_charge_row: string;
}

