import { QuoteStopTable } from "../../../../powerbroker/src/QuoteStopTable";
import { BrltlFreightItemTable } from "../../BrltlFreightItemTable";
import { OrderHandlingRequirements } from "../../OrderHandlingRequirements";
import { ShipperPONums } from "../../ShipperPONums";
import { DataSource, Image, Label, Layout, Location, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutQuoteEntryLTL extends Layout {
	imageAddHandlingReq: Image;
	labelAccessorials: Label;
	labelAdditionalDetails: Label;
	labelFreightItems: Label;
	labelGeneralInformation: Label;
	labelPickupDelivery: Label;
	layoutFreightItems: BrltlFreightItemTable;
	layoutHandlingReqs: OrderHandlingRequirements;
	layoutQuoteStopTable: QuoteStopTable;
	layoutShipperPONums: ShipperPONums;
	panelAccessorials: Panel;
	panelAdditionalDetails: Panel;
	panelFreightItems: Panel;
	panelGeneralFields: Panel;
	panelGeneralInfo: Panel;
	panelLeftGenInfo: Panel;
	panelPickupDelivery: Panel;
	panelRightGenInfo: Panel;
	sourceQuoteFreightGroupItem: DataSource;
	sourceQuoteHdrXFgp: DataSource;
	sourceQuoteOrder: DataSource;
	sourceQuoteStop: DataSource;
	textboxBlnum: Textbox;
	textboxBolNote: Textbox;
	textboxConsigneeRefno: Textbox;
	textboxCtrlParty: Location;
	textboxCustomerContactName: Textbox;
	textboxCustomerEmail: Textbox;
	textboxCustomerName: Textbox;
	textboxCustomerPhone: Textbox;
	textboxEffectiveDate: Textbox;
	textboxExpirationDate: Textbox;
	textboxGroupId: Textbox;
	textboxNotes: Textbox;
	textboxOrderMode: Textbox;
	textboxOrderTypeId: Textbox;
	textboxRevenueCodeId: Textbox;
}
