import { getLogger } from "@mcleod/core";

const log = getLogger("components.MountListener");
const TEN_MINUTES = 1000 * 60 * 10;

type MountCallback = () => void;

enum MountType {
    MOUNT,
    UNMOUNT
}

interface MountListener {
    element: Element;
    callback: MountCallback;
    addMillis: number;
    mountType: MountType;
}

const mountListeners: MountListener[] = [];

let observer: MutationObserver;

export class MountUtil {
    public static addMountListener(element: Element, callback: MountCallback) {
        MountUtil.internalAddMountListener(element, callback, MountType.MOUNT);
    }

    public static addUnmountListener(element: Element, callback: MountCallback) {
        MountUtil.internalAddMountListener(element, callback, MountType.UNMOUNT);
    }

    private static handleMutation() {
        const nowMillis = new Date().getTime();
        for (let i = mountListeners.length - 1; i >= 0; i--) {
            const listener = mountListeners[i];
            const contains = document.body.contains(listener.element);
            if ((contains && listener.mountType === MountType.MOUNT) || (!contains && listener.mountType === MountType.UNMOUNT)) {
                mountListeners.splice(i, 1);
                listener.callback();
                log.debug(() => ["Mounted", listener.mountType, listener.element, "New mount listeners", mountListeners]);
            }
            else if (nowMillis - listener.addMillis > TEN_MINUTES) {
                mountListeners.splice(i, 1);
                log.debug(() => ["Mount listener expired", listener.element, "New mount listeners", mountListeners]);
            }
        }
        if (mountListeners.length === 0) {
            log.debug(() => ["Disconnected mount listener observer"]);
            observer.disconnect();
        }
    }

    private static internalAddMountListener(element: Element, callback: MountCallback, mountType: MountType) {
        mountListeners.push({ element, callback, addMillis: new Date().getTime(), mountType: mountType });
        log.debug(() => ["Add mount listener", mountListeners]);
        if (observer == null)
            observer = new MutationObserver((_mutations, _inObserver) => MountUtil.handleMutation());
        if (mountListeners.length === 1)
            observer.observe(document.body, { childList: true, subtree: true });
    }
}
