import { ClickEvent, DataSourceMode, DataSourceModeChangeEvent } from "@mcleod/components";
import { DataSourceValidationEvent } from "@mcleod/components/src/events/DataSourceValidationEvent";
import { AutogenLayoutProject44Profile } from "./autogen/AutogenLayoutProject44Profile";

const PICKUP_NOTE = "PICKUP_NOTE"
const DELIVERY_NOTE = "DELIVERY_NOTE"
const PRIORITY_ACCESSORIALS = "PRIORITY_ACCESSORIALS"
const PICKUP_ACCESSORIALS = "PICKUP_ACCESSORIALS"
const DELIVERY_ACCESSORIALS = "DELIVERY_ACCESSORIALS"
const OTHER_ACCESSORIALS = "OTHER_ACCESSORIALS"
const DIMENSIONS = "DIMENSIONS"

const shipmentNotes: { [key: string]: string } = {
    PICKUP_NOTE: "switchPickupNote",
    DELIVERY_NOTE: "switchDeliveryNote",
    PRIORITY_ACCESSORIALS: "switchPriorityAcc",
    PICKUP_ACCESSORIALS: "switchPickupAcc",
    DELIVERY_ACCESSORIALS: "switchDeliveryAcc",
    OTHER_ACCESSORIALS: "switchOtherAcc",
    DIMENSIONS: "switchDimensions"
}

export class Project44Profile extends AutogenLayoutProject44Profile {
    override onLoad(): void | Promise<void> {
        if (this.switchCarrierConfiguration.checked)
            this.tabRateConfiguration.visible = false
    }

    includeAll() {
        this.switchPickupNote._internalSetChecked(true, null, true)
        this.switchDeliveryNote._internalSetChecked(true, null, true)
        this.switchPriorityAcc._internalSetChecked(true, null, true)
        this.switchPickupAcc._internalSetChecked(true, null, true)
        this.switchDeliveryAcc._internalSetChecked(true, null, true)
        this.switchOtherAcc._internalSetChecked(true, null, true)
        this.switchDimensions._internalSetChecked(true, null, true)
    }

    excludeAll() {
        this.switchPickupNote._internalSetChecked(false, null, true)
        this.switchDeliveryNote._internalSetChecked(false, null, true)
        this.switchPriorityAcc._internalSetChecked(false, null, true)
        this.switchPickupAcc._internalSetChecked(false, null, true)
        this.switchDeliveryAcc._internalSetChecked(false, null, true)
        this.switchOtherAcc._internalSetChecked(false, null, true)
        this.switchDimensions._internalSetChecked(false, null, true)
    }

    includeInShipmentNote(event: ClickEvent) {
        this.buildShipmentNotesInclusionList()
    }

    buildShipmentNotesInclusionList() {
        const incl = [];
        if (this.switchPickupNote.checked)
            incl.push(PICKUP_NOTE)
        if (this.switchDeliveryNote.checked)
            incl.push(DELIVERY_NOTE)
        if (this.switchPriorityAcc.checked)
            incl.push(PRIORITY_ACCESSORIALS)
        if (this.switchPickupAcc.checked)
            incl.push(PICKUP_ACCESSORIALS)
        if (this.switchDeliveryAcc.checked)
            incl.push(DELIVERY_ACCESSORIALS)
        if (this.switchOtherAcc.checked)
            incl.push(OTHER_ACCESSORIALS)
        if (this.switchDimensions.checked)
            incl.push(DIMENSIONS)

        const notes = this.mainDataSource.data[0].data.disp_note_config
        const newNotes = incl.join(", ")
        if (notes !== newNotes)
            this.mainDataSource.data[0].set("disp_note_config", newNotes)
    }

    sourceP44ConfigProfileAfterModeChange(event: DataSourceModeChangeEvent) {
        if (event.oldMode != DataSourceMode.ADD && event.newMode == DataSourceMode.ADD) {
            this.switchQuoteFallbacktoDefaultLoginGroup.checked = true
            this.includeAll()
        }
        if (event.oldMode != DataSourceMode.UPDATE && event.newMode == DataSourceMode.UPDATE)
            this.initializeInclusions()
    }

    initializeInclusions() {
        const inclusions = this.mainDataSource.data[0].data.disp_note_config
        if (inclusions != null) {
            this.excludeAll()
            const incList = inclusions.substring(0, inclusions.length).split(", ");
            for (const note of incList)
                this.setItemIncluded(shipmentNotes[note])
        }
    }

    setItemIncluded(buttonId: String) {
        switch (buttonId) {
            case shipmentNotes.PICKUP_NOTE:
                this.switchPickupNote._internalSetChecked(true, null, true)
                break;
            case shipmentNotes.DELIVERY_NOTE:
                this.switchDeliveryNote._internalSetChecked(true, null, true)
                break;
            case shipmentNotes.PRIORITY_ACCESSORIALS:
                this.switchPriorityAcc._internalSetChecked(true, null, true)
                break;
            case shipmentNotes.PICKUP_ACCESSORIALS:
                this.switchPickupAcc._internalSetChecked(true, null, true)
                break;
            case shipmentNotes.DELIVERY_ACCESSORIALS:
                this.switchDeliveryAcc._internalSetChecked(true, null, true)
                break;
            case shipmentNotes.OTHER_ACCESSORIALS:
                this.switchOtherAcc._internalSetChecked(true, null, true)
                break;
            case shipmentNotes.DIMENSIONS:
                this.switchDimensions._internalSetChecked(true, null, true)
                break;
            default:
                break;
        }
    }

    switchCarrierConfigurationOnChange(event) {
        if (this.switchCarrierConfiguration.checked)
            this.tabRateConfiguration.visible = false
        else
            this.tabRateConfiguration.visible = true
    }

    sourceP44ConfigProfileOnValidate(event: DataSourceValidationEvent) {
        this.buildShipmentNotesInclusionList()
    }
}
