import {
    BlurEvent, ChangeEvent, Component, DataDisplayEvent, DataSourceAction, DataSourceExecutionEvent, DataSourceMode,
    DataSourceModeChangeEvent, ForcedCase, Label, Layout, Panel, ScreenStack, Snackbar, Tab, TableContentsChangedEvent,
    TableRow, TableRowCreationEvent, TableRowMode, Textbox
} from "@mcleod/components";
import {
    Api, Currency, CurrencyUtil, getAuthSettings, getThemeColor, HorizontalAlignment, ModelRow, StringUtil
} from "@mcleod/core";
import { MovementCarrierPay } from "./MovementCarrierPay";
import { AutogenLayoutMovementPay } from "./autogen/AutogenLayoutMovementPay";

export interface PayError {
    message: string, details: string
}
export class MovementPay extends AutogenLayoutMovementPay {

    private _listenersActive = true;
    private _payError: PayError;
    private _fromSave: boolean = false;
    public preventSnackbar = false;
    private carrierPaySnackbar: Snackbar;
    public carrierPayCallback: (errorMessage: string, errorDetails: string) => string;
    public cachedOtherPay: ModelRow[];

    private _marginColor = (num) => {
        if (num > 0) {
            return getThemeColor("success");
        } else if (num <= 0) {
            return getThemeColor("error.lightest");
        } else {
            return getThemeColor(this.textboxMargin.color);
        }
    }

    override onLoad() {
        this.textboxOverrideType.forcedCase = ForcedCase.NONE;
        this.textboxMargin.currencyColorCallback = this._marginColor;
        this.layoutMoveCarrierPay.layoutTargetPay.addLayoutLoadListener(() => {
            this.layoutMoveCarrierPay.layoutTargetPay.textboxTargetPayCalc.addBlurListener(event => this.calculatePay(true));
        })
        this.layoutMoveCarrierPay.textboxMaxPayCalc.addBlurListener(event => this.calculatePay(true));
    }


    private get listenersActive() {
        return this._listenersActive;
    }

    private set listenersActive(value) {
        this._listenersActive = value ? value : !this._listenersActive;
    }

    public get payError(): PayError {
        return this._payError;
    }

    private set payError(value: PayError) {
        if (value?.message == null)
            value = null;
        if (value != this.payError) {
            this._payError = value;
            if (this.payError == null && this.carrierPaySnackbar != null) {
                this.carrierPaySnackbar.dismiss();
                this.carrierPaySnackbar = null;
            }
        }
    }

    private get layoutMoveCarrierPay(): MovementCarrierPay {
        return this.layoutCarrierRates as MovementCarrierPay;
    }


    sourceMovementAfterExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() == DataSourceAction.SEARCH && this.mainDataSource.activeRow != null) {
            this.setupExtraPayTab();
            const movementId = this.activeRow.get("id");
            if (movementId != null) {
                this.sourceExtraPay.search({ "movement_id": movementId }).then(() => {
                    this.cachedOtherPay = this.sourceExtraPay.data;
                });
            }
        }
    }

    setupExtraPayTab() {
        this.styleTab(this.tableExtraPay.parent as Tab);
        this.sourceExtraPay.addAfterExecutionListener(event => {
            this.updateOtherPayTabCaption();
        });
        this.tableExtraPay.addContentsChangedListener(event => {
            this.updateOtherPayTabCaption();
        });
    }

    sourceMovementAfterModeChange(event: DataSourceModeChangeEvent) {
        this.sourceExtraPay.mode = event.newMode;
        if (DataSourceMode.UPDATE == event.newMode)
            this.defaultPayUnitsAndMethod();
    }

    defaultPayUnitsAndMethod() {
        if (this.mainDataSource.activeRow.get("override_units") == null) {
            this.mainDataSource.activeRow.set("override_units", 1);
            this.textboxOverrideUnits.displayData(this.mainDataSource.activeRow, null, 0);
        }
        if (this.mainDataSource.activeRow.get("override_type") == null)
            this.mainDataSource.activeRow.set("override_type", "F");
    }

    updateOtherPayTabCaption() {
        const tab = this.tableExtraPay.parent as Tab;
        const count = this.tableExtraPay.rowCount;
        if (tab != null && tab["countLabel"] != null)
            tab["countLabel"].caption = count + "";
    }

    async rateRelatedFieldChanged(event: BlurEvent | ChangeEvent) {
        if (this.listenersActive === true && ((event instanceof ChangeEvent && event.oldValue != event.newValue && event.userInitiatedChange == true) ||
            (event instanceof BlurEvent && event.changedWhileFocused))) {
            await this.calculatePay(true);
            if (event instanceof ChangeEvent) {
                this.textboxOverridePayRate.focus();
                this.textboxOverridePayRate.selectText();
            }
            this.labelAutoRated.visible = false;
        }
    }

    otherPayChanged(): boolean {
        return this.tableExtraPay.dataSource.hasChanged();
    }

    async deleteFuelSurchargeRows() {
        for (let i = 0; i < this.sourceExtraPay.data.length; i++) {
            const row = this.sourceExtraPay.data[i] as ModelRow;
            if (row?.data["fuel_surcharge_pay"]) {
                await row.delete();
            }
        }
    }

    async calculatePay(editedByUser: boolean, autoRate: boolean = false, preventSnackbar: boolean = false, calculateFuelSurcharge: boolean = false,
        validatePay: boolean = true): Promise<any> {
            this.labelAutoRated.visible = false;
        const payMethod = this.textboxOverrideType.selectedItem?.value;
        const payRate = this.textboxOverridePayRate.valueAsString;

        if (calculateFuelSurcharge) {
            await this.deleteFuelSurchargeRows();
        }

        this.preventSnackbar = preventSnackbar;
        return new Promise((resolve) => {
            Api.search("lme/dispatch/calculate-override-pay", {
                "validate_pay": validatePay,
                "movement_id": this.mainDataSource.activeRow.get("id"),
                "pay_method": payMethod,
                "rate_per_unit": payRate || "0",
                "max_pay": this.mainDataSource.activeRow.get(this.layoutMoveCarrierPay.textboxMaxPayCalc.field),
                "target_pay": this.mainDataSource.activeRow.get(this.layoutMoveCarrierPay.layoutTargetPay.textboxTargetPayCalc.field),
                "changed_other_pay_rows": this.otherPayChanged() ? this.sourceExtraPay.data : null,
                "auto_rate": autoRate,
                "pay": this.mainDataSource.activeRow.get("override_pay_amt"),
                "payee_id": this.mainDataSource.activeRow.get("pending_payee_id"),
                "calculate_fuel_surcharge": calculateFuelSurcharge
            }).then(response => {
                this.displayCalculatedData(editedByUser, response, calculateFuelSurcharge);
                this.validateCarrierPayResult(response.data[0]);
                this.listenersActive = true;
                this.preventSnackbar = false;
                resolve(this.payError == null);
            });
        })
    }


    private validateCarrierPayResult(data: any) {
        const payValidation = data?.validation_data;
        const errorMessage = payValidation?.["dispatch_error_msg"];
        let errorDetails = payValidation?.["dispatch_update_pay_msg"];
        if (this.carrierPayCallback != null)
            errorDetails = this.carrierPayCallback(errorMessage, errorDetails);

        this.payError = { message: errorMessage, details: errorDetails };
        if (this.preventSnackbar === false && errorMessage != null && errorMessage != this.payError) {
            if (this.carrierPaySnackbar != null) {
                this.carrierPaySnackbar["_onDismiss"] = () => { this.showPaySnackbar() };
                this.carrierPaySnackbar.dismiss();
            }
            else
                this.showPaySnackbar()
        }
    }

    private displayCalculatedData(editedByUser: boolean, response: any, calculateFuelSurcharge: boolean) {
        const data = response.data[0];
        if (!StringUtil.isEmptyString(data["type"])) {
            this.mainDataSource.activeRow?.set("override_type");
            const items = this.textboxOverrideType.items;
            for (let i = 0; i < items.length; ++i) {
                if (items[i].value == data["type"]) {
                    this.textboxOverrideType.selectedItem = items[i];
                    break;
                }
            }
        }
        if (!StringUtil.isEmptyString(data["units"])) {
            this.mainDataSource.activeRow?.set("override_units", data["units"]);
        }
        if (editedByUser || !StringUtil.isEmptyString(data["pay"])) {
            if (!data["pay"])
                this.mainDataSource.activeRow?.set("override_pay_amt", null);
            else {
                this.mainDataSource.activeRow?.set("override_pay_amt", data["pay"]);
                const move_distance = this.mainDataSource.activeRow?.get("move_distance", 0);
                if (move_distance > 0) {
                    this.textboxPayPerMile.text = (data["pay"].amount / move_distance).toFixed(2).toString() + "/mile";
                }
            }
        }
        this.textboxOtherPay.text = CurrencyUtil.formatCurrency(data.other_pay);
        this.textboxTotalPay.text = CurrencyUtil.formatCurrency(data.total_pay);
        this.textboxMargin.text = CurrencyUtil.formatCurrency(data.margin);
        this.textboxMarginPercentage.text = data["margin_percentage"] ? `${data["margin_percentage"]}%` : "--";
        if (this.textboxMarginPercentage.printableLabel)
            this.textboxMarginPercentage.printableLabel._element.style.color = this._marginColor(data["margin_percentage"]);
        if (data["override_pay_rate"] != null)
            this.mainDataSource.activeRow?.set("override_pay_rate", data["override_pay_rate"]);
        if (data["carrier_rate_id"] != null) {
            this.mainDataSource.activeRow?.set("carrier_rate_id", data["carrier_rate_id"]);
            this.labelAutoRated.visible = true;
            this.labelAutoRated.tooltipCallback = this.makeCarrierRateTooltipCallbackFunction(data["carrier_rate_id"], this.labelAutoRated);
        }
        if (calculateFuelSurcharge) {
            this.sourceExtraPay.search({ "movement_id": this.mainDataSource.activeRow?.get("id") })
        }
    }

    makeCarrierRateTooltipCallbackFunction(id, component: Component) {
        return (baseTooltip: Component, originatingEvent): Component => {
            const tooltip = this.getCarrierRateQuickInfoLayout(baseTooltip, id);
            if (component instanceof Textbox && (component as Textbox).printable) {
                const label: Label = (component as Textbox)["_printableLabel"];
                return label["_internalShowTooltip"](tooltip, originatingEvent);
            }
            else
                return component["_internalShowTooltip"](tooltip, originatingEvent);
        };
    }

    getCarrierRateQuickInfoLayout(baseTooltip: Component, id) {
        if (id == null)
            return null;
        const layout = Layout.getLayout("lme/powerbroker/CarrierRateQuickInfo");
        layout.onLoad = () => {
            if (layout.mainDataSource != null)
                layout.mainDataSource.search({ search: id.toString() });
        };
        return layout;
    }

    textboxDeductCodeIdOnDataDisplay(event: DataDisplayEvent) {
        const row = TableRow.getContainingTableRow(event.target as Component).data;
        if (row.get("deduct_code_id") != null)
            (event.target as Textbox).tooltipCallback = this.makeExtraPayDeductCodeTooltipCallbackFunction(row.get("id"), row.get("deduct_code_id"), row.get("short_desc"), event.target as Component);
    }

    textboxAmountOnDataDisplay(event: DataDisplayEvent) {
        const row = TableRow.getContainingTableRow(event.target as Component).data;
        if (row.get("fuel_surcharge_pay") != null)
            (event.target as Textbox).tooltipCallback = this.makeExtraPayAmountTooltipCallbackFunction(row.get("id"), event.target as Component);
    }

    makeExtraPayDeductCodeTooltipCallbackFunction(id: string, deductCode: string, description: string, component: Component) {
        return (baseTooltip: Component, originatingEvent): Component => {
            const tooltip = this.getExtraPayDeductCodeQuickInfoLayout(baseTooltip, id, deductCode, description);
            if (component instanceof Textbox && (component as Textbox).printable) {
                const label: Label = (component as Textbox)["_printableLabel"];
                return label["_internalShowTooltip"](tooltip, originatingEvent);
            }
            else
                return component["_internalShowTooltip"](tooltip, originatingEvent);
        };
    }

    makeExtraPayAmountTooltipCallbackFunction(id: string, component: Component) {
        return (baseTooltip: Component, originatingEvent): Component => {
            const tooltip = this.getExtraPayAmountQuickInfoLayout(baseTooltip, id);
            if (component instanceof Textbox && (component as Textbox).printable) {
                const label: Label = (component as Textbox)["_printableLabel"];
                return label["_internalShowTooltip"](tooltip, originatingEvent);
            }
            else
                return component["_internalShowTooltip"](tooltip, originatingEvent);
        };
    }

    getExtraPayDeductCodeQuickInfoLayout(baseTooltip: Component, id: string, deductCode: string, description: string): Layout | null {
        if (deductCode == null)
            return null;
        const layout = Layout.getLayout("lme/powerbroker/MovementExtraPayDeductCodeQuickInfo");
        layout.onLoad = () => {
            if (layout.mainDataSource != null)
                layout.mainDataSource.search({ search: deductCode });
            layout["textboxDriveExtraPayId"].text = deductCode; //deduct_code_id
            layout["textboxDeductDescr"].text = description; //short_desc
        };
        return layout;
    }

    getExtraPayAmountQuickInfoLayout(baseTooltip: Component, id: string): Layout | null {
        if (id == null)
            return null;
        const layout = Layout.getLayout("lme/powerbroker/MovementExtraPayAmountQuickInfo");
        layout.onLoad = () => {
            if (layout.mainDataSource != null)
                layout.mainDataSource.search({ search: id });
        };
        return layout;
    }

    textboxDeductCodeIdOnChange(event: ChangeEvent) {
        const textbox = event.target as Textbox;
        if (event.userInitiatedChange && event.newValue != event.oldValue) {
            const tableRow = TableRow.getContainingTableRow(textbox);
            const textboxShortDesc = tableRow.findComponentById("textboxShortDesc") as Textbox;
            const selectingFromDropdown = textbox.isDropdownVisible() && textbox.getFirstLookupModelData() != null;
            if (selectingFromDropdown) {
                // after selecting a deduct code, set the textboxShortDesc.text and then textboxUnits gets focus
                textboxShortDesc.text = textbox.getFirstLookupModelData().get("short_desc");
                textbox.hideDropdown(false);
                this.otherPayTextboxChanged(textbox);
                tableRow.findComponentById("textboxUnits").focus();
            } else {
                textboxShortDesc.text = null;
            }
        }
    }

    textboxUnitsOnBlur(event: BlurEvent) {
        this.otherPayTextboxOnBlur(event);
    }

    textboxRateOnBlur(event: BlurEvent) {
        this.otherPayTextboxOnBlur(event);
    }

    otherPayTextboxOnBlur(event: BlurEvent) {
        if (event.changedWhileFocused) {
            const textbox: Textbox = event.target as Textbox;
            this.otherPayTextboxChanged(textbox);
        }
    }

    otherPayTextboxChanged(textbox: Textbox) {
        const tableRow: TableRow = TableRow.getContainingTableRow(textbox);
        const rate = tableRow.data.get("rate");
        const units = tableRow.data.get("units");
        tableRow.data.set("amount", new Currency({ amount: rate * units }));
        if (textbox.text != null && textbox.text != "") {
            const payeeId = this.sourceMovement.activeRow.get("override_payee_id");
            Api.search("lme/dispatch/calculate-carrier-other-pay",
                {
                    "other_pay_row": TableRow.getContainingTableRow(textbox).data,
                    "payee_id": payeeId
                }).then(response => {
                    this.otherPayFromApi(response, textbox);
                });
        }
        else {
            const descTextbox: Textbox = tableRow.findComponentById("textboxShortDesc") as Textbox;
            tableRow.data.set("short_desc", "");
            descTextbox.displayData(tableRow.data, null, 0);
        }
    }

    private otherPayFromApi(response, textbox: Textbox) {
        const data = response.data[0];
        const otherPay = data["other_pay_row"];
        const row = TableRow.getContainingTableRow(textbox);
        row.data.set("transaction_date", otherPay["transaction_date"]);

        const payeeIdTextbox: Textbox = row.findComponentById("textboxOtherPayPayeeId") as Textbox;
        const payeeId: string = otherPay["payee_id"];
        row.data.set("payee_id", payeeId);
        row.data.setLookupModelData("payee_id", otherPay["_lookup_payee_id"]);
        payeeIdTextbox.displayData(row.data, null, 0);

        const descTextbox: Textbox = row.findComponentById("textboxShortDesc") as Textbox;
        if (otherPay["short_desc"] != null)
            descTextbox.text = otherPay["short_desc"];

        const units: Textbox = row.findComponentById("textboxUnits") as Textbox;
        units.text = otherPay["units"]?.toString();
        const rate: Textbox = row.findComponentById("textboxRate") as Textbox;
        rate.text = otherPay["rate"]?.toString();
        TableRow.getContainingTableRow(textbox).data.set("amount", otherPay["amount"]);

        const chargeRow: TableRow = TableRow.getContainingTableRow(textbox);
        chargeRow.data.set("amount", otherPay["amount"]);
        const amount: Textbox = row.findComponentById("textboxAmount") as Textbox;
        if (row.mode === TableRowMode.QUICK_ADD)
            amount.text = CurrencyUtil.formatCurrency(otherPay["amount"])?.toString();
    }

    showPaySnackbar() {
        Snackbar.showWarningSnackbar(this.createPayErrorPanel(), { id: "carrierPay", persist: true });
        this.carrierPaySnackbar = ScreenStack.getOldestSnackbar("carrierPay") as Snackbar;
    }

    createPayErrorPanel(): Panel {
        if (this.payError != null) {
            return new Panel({
                width: 450, rowBreak: false, padding: 0,
                components: [
                    new Label({ caption: this.payError.message, align: HorizontalAlignment.CENTER, fontBold: true, fontSize: "medium" }),
                    new Label({ caption: this.payError.details || "" })
                ]
            });
        }
    }

    styleTab(tab: Tab) {
        if (tab.heading != null && tab.headingLabel != null) {
            tab.heading.color = "subtle.light";
            tab.heading.setProps({ color: "subtle.light", fontBold: false, borderWidth: 0 });
            tab.headingLabel.setProps({ color: "default", fontBold: true, fillRow: false });
            if (tab["countLabel"] == null) {
                tab["countLabel"] = new Label({
                    fontBold: true, caption: "0", fillRow: true,
                    color: "primary", rowBreak: false, marginLeft: 5
                });
                tab.heading.insert(tab["countLabel"], 1);
            }
        }
    }

    /** This is an event handler for the afterTabSelection event of tabsetOtherPay.  */
    tabsetOtherPayAfterTabSelection(event) {
        const tabHeader = this?.tabOtherPay?.["_heading"];
        if (tabHeader != null) {
            tabHeader.color = "subtle.light";
            tabHeader.backgroundColor = "table.headingRowBackgroundColor";
            this.tabOtherPay.headingLabel.color = "default";
        }
    }

    /** This is an event handler for the onContentsChanged event of tableExtraPay.  */
    tableExtraPayOnContentsChanged(event: TableContentsChangedEvent) {
        this.calculatePay(false);
    }

    tableExtraPayOnRowCreate(event: TableRowCreationEvent) {
        const modelRow = event.getTableRow().data as ModelRow;
        if (modelRow.get("payee_id") == null) {
            let payeeId = this.sourceMovement.activeRow?.get("override_payee_id");
            if (payeeId == null)
                payeeId = this.sourceMovement.activeRow?.get("pending_payee_id");
            modelRow.set("payee_id", payeeId);
            modelRow.set("_lookup_payee_id", { id: payeeId });
        }
        if (modelRow._appending) {
            modelRow.set("order_id", this.sourceMovement.activeRow?.get("orders.id"));
            modelRow.set("movement_id", this.sourceMovement.activeRow?.get("id"));
            modelRow.set("entered_user_id", getAuthSettings()?.user_settings?.user_id);
        }
    }

    public get fromSave(): boolean {
        return this._fromSave;
    }

    public set fromSave(value: boolean) {
        this._fromSave = value;
    }

}
