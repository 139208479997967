import { Overlay, Snackbar, Textbox, Toast } from "@mcleod/components";
import { Api, getAuthSettings, getLogger, ModelRow, StringUtil } from "@mcleod/core";
import { AutogenLayoutUnassignCarrierPrompt } from "./autogen/AutogenLayoutUnassignCarrierPrompt";
import { CommonDialogs } from "@mcleod/common";

const log = getLogger("lme.dispatch.UnassignCarrierPrompt");
export class UnassignCarrierPrompt extends AutogenLayoutUnassignCarrierPrompt {
    private _movementId: string;
    brTrackingVendor: string;
    private reasonRequired: boolean;
    private _overlay: Overlay;
    success: boolean;
    private unassignSnackbar: Snackbar;
    public toastMessage: string;
    onCancel: () => void;
    onSave: (success: boolean, vendorName: string, forceCancel: boolean) => Promise<void>;

    override async onLoad(): Promise<void> {
        this.reasonRequired = "Y" === getAuthSettings().dispatch_control[0].unassign_reason;
        this.textboxReasonCode.required = this.textboxReasonCode.visible = this.reasonRequired;
        this.textboxComments.required = this.textboxComments.visible = this.reasonRequired;
        if (this.reasonRequired) {
            this.textboxReasonCode.onSelectItem = (textbox: Textbox, selectedItem: string | ModelRow<any>) => {
                textbox.text = (selectedItem as ModelRow)?.get("descr");
                return undefined;
            }
            this.textboxReasonCode.addBeforeLookupModelSearchListener(event => {
                event.filter.is_active = "Y";
                event.filter.code_type = "U";
            });
        }

        const cancel = () => {
            if (this.onCancel != null)
                this.onCancel();
        }
        this.buttonClose.addClickListener(cancel);
        this.buttonNo.addClickListener(cancel);


        //Search for carriers with advanced already listed for this movement, and list those carries if found
        return Api.search("lme/dispatch/carriers-with-advances", { movement_id: this.movementId })
            .then(resp => {
                const data = resp.data[0]
                if (data.carriers && data.carriers.length > 0) {
                    this.advanceCarriersLabel.visible = true
                    this.advanceCarriersLabel.caption =
                        `Advance(s) issued to:\n ${data.carriers.join(", ")}`
                }
            })
    }

    async unassignCarrier() {
        this.unassignSnackbar = Snackbar.showDownloadSnackbar("Unassign Carrier", "Unassigning...");
        return Api.post("lme/dispatch/cancel-dispatch", {
            movement_id: this.movementId,
            reason_code: this.textboxReasonCode.getFirstLookupModelData()?.get("id"),
            comment: this.textboxComments.text
        }, null, null).then(async response => {

            this.success = response?.data[0]?.success;

            if (this.brTrackingVendor) {
                await this.cancelTracking(this.movementId);
            } else {
                await this.onSave(this.success, "", false);
            }
        }).finally(() => {
            this.unassignSnackbar.dismiss();
            if (!StringUtil.isEmptyString(this.toastMessage) && this.success) {
                Toast.showSuccessToast(this.toastMessage);
            }
            else if (!this.success) {
                CommonDialogs.showError("Carrier unassignment failed");
            }
        });
    }

    async cancelTracking(movementId: string) {
        return await Api.post("lme/powerbroker/tracking/cancel-tracking", { "movement_id": movementId })
            .then(response => {
                this.onSave(this.success, response?.data[0]?.vendor_name, response?.data[0]?.force_cancel);
            });
    }

    get movementId(): string {

        return this._movementId;

    }
    set movementId(value: string) {

        this._movementId = value;

    }
}
