/**
 * This is currently unused.  I thought I was going to use it
 * to dynamically add the google charts script, but then we
 * didn't use google charts.
 * This class is used to add items to the head of the document.
 */
export class Head {
    /**
     * This adds the given script URL to the head element of the document.  If the script
     * is already present, it will not be added a second time.
     */
    public static addScript(url: string): HTMLScriptElement {
        const scripts = document.head.getElementsByTagName("script");
        for (let i = 0; scripts != null && i < scripts.length; i++)
            if (scripts[i].src === url)
                return scripts[i];
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        document.head.appendChild(script);
        return script;
    }

    public static removeScript(url: string) {
        const scripts = document.head.getElementsByTagName("script");
        for (let i = scripts.length - 1; scripts != null && i >= 0; i--)
            if (scripts[i].src === url) {
                scripts[i].remove();
                break;
            }
    }

    public static addLink(url: string): HTMLLinkElement {
        const links = document.head.getElementsByTagName("link");
        for (let i = 0; links != null && i < links.length; i++)
            if (links[i].href === url)
                return links[i];
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.href = url;
        document.head.appendChild(link);
        return link;
    }

    public static removeLink(url: string) {
        const links = document.head.getElementsByTagName("link");
        for (let i = links.length - 1; links != null && i >= 0; i--)
            if (links[i].href === url) {
                links[i].remove();
                break;
            }
    }
}
