import { Component, Label, Panel, Textbox } from "@mcleod/components";
import { AutogenLayoutCarrierQualification } from "./autogen/AutogenLayoutCarrierQualification";
import { getThemeColor } from "@mcleod/core";

interface ValidationData {
    passed: boolean;
    warning: boolean;
    message: string;
    profile_id: string;
    details: {
        points: number;
        descr: string;
    }[];
    failed_items: string[];
}

export class CarrierQualification extends AutogenLayoutCarrierQualification {
    setQualificationData(carrierValidationData: any) {
        this.panelResults.removeAll();
        if (carrierValidationData != null) {
            const qualified = carrierValidationData.passed && !carrierValidationData.warning;
            const warning = carrierValidationData.warning;
            const color = qualified ? getThemeColor("success") : warning ? getThemeColor("warning") : getThemeColor("error");

            const detailPanel = new Panel({ fillRow: true });
            detailPanel.add(new Label({
                caption: carrierValidationData.message, color: color,
                imageName: qualified ? this.getSuccessImageName(carrierValidationData.warning) : "rateConExpiring", fontSize: "large", fontBold: true
            }));

            this.performOnDetails(carrierValidationData.details, detailPanel, qualified);

            this.panelResults.add(detailPanel);
            this.panelResults.borderColor = color;
            this.textboxProfile.text = carrierValidationData.profile_id;
            this.panelResults.visible = true;
            this.textboxProfile.visible = true;
            this.panelResults.tooltipCallback = () => { return this.makeQualificationToolTipCallback(carrierValidationData.details, detailPanel, qualified, color, carrierValidationData) };
        }
    }

    getSuccessImageName(warning: boolean): string {
        if (warning) {
            return "warning"
        }
        return "circleCheck"
    }

    makeQualificationToolTipCallback(details: any, component: Component, success, color, carrierValidationData: any) {
        const panel = new Panel({ padding: 0, margin: 0 });
        panel.add(new Label({ caption: "Qualifications", fontSize: "large", fontBold: true }));
        panel.add(new Textbox({printable: true, caption: "Qualification Profile", enabled: this.enabled, width: undefined, field: "text_value", text: carrierValidationData.profile_id}));
        panel.add(new Label({
            caption: carrierValidationData.message, color: color,
            imageName: success ? this.getSuccessImageName(carrierValidationData.warning) : "rateConExpiring", fontSize: "large", fontBold: true
        }));

        this.performOnDetails(details, panel, success);

        return component.showTooltip(panel, null, { themeKey: "quickInfo" });
    }

    clearQualificationData() {
        this.panelResults.removeAll();
        this.panelResults.visible = false;
        this.textboxProfile.visible = false;
    }

    performOnDetails(details: any, Panel: Panel, success) {
        details?.forEach(detail => {
            if ((success && detail.points == 0) || (!success && detail.points > 0))
                Panel.add(new Label({ caption: detail.descr, fontSize: "medium", marginLeft: 35, fontBold: true }));
        });
    }
}
