import { CommonDialogs } from "@mcleod/common";
import {
    Button, ButtonProps, ButtonVariant, ClickEvent, DataSourceMode, EditRowDecorator, Layout, Panel, PanelProps,
    Snackbar, Table, TableRow, TableSelectionEvent, Toast
} from "@mcleod/components";
import { ModelDataChangeType } from "@mcleod/components/src/databinding/DataSource";
import { DataSourceRemoteDataChangeEvent } from "@mcleod/components/src/events/DataSourceRemoteDataChangeEvent";
import { Api, getAuthSettings, Model, ModelRow, Navigation, NavOptions, ProcessLock, StringUtil } from "@mcleod/core";
import { BrltlUtil } from "@mcleod/dispatch/BrltlUtil";
import { DispatchUtil } from "@mcleod/dispatch/src/DispatchUtil";
import { isSpotOrder } from "@mcleod/dispatch/src/Orders";
import { DispatchSlideout } from "../../dispatch/src/DispatchSlideout";
import { PostToLoadBoards } from "../../dispatch/src/PostToLoadBoards";
import { BrokerageMovementTable } from "./BrokerageMovementTable";
import { BrokerageMovementTableType } from "./BrokerageMovementTableType";
import { CarrierAssignmentStepper } from "./CarrierAssignmentStepper";
import { P44ShipmentTracking } from "../../powerbroker/src/P44ShipmentTracking";
import { CarrierSelectionStepper } from "@mcleod/dispatch/src/CarrierSelectionStepper";

const buttonProps: Partial<ButtonProps> = {
    color: "primary", variant: ButtonVariant.round, borderWidth: 0,
    imageWidth: 22, imageHeight: 22, rowBreak: false, enabled: false
}

const borderPanelProps: Partial<PanelProps> = {
    height: 20,
    padding: 0,
    marginRight: 10,
    borderRightWidth: 1,
    borderRightColor: "subtle.light",
    rowBreak: false
}

export class BrokeragePlanningToolPanel extends Panel {
    private _buttonAssign: Button;
    private _buttonDispatch: Button;
    table: Table;
    tableType: BrokerageMovementTableType;
    private preventDispatchOnHold = getAuthSettings().dispatch_control[0].prevent_dsp_hold == "Y";
    public callinRequiredBrkStatusCode: ModelRow;

    constructor(tableLayout: BrokerageMovementTable) {
        super({ marginLeft: 15, paddingTop: 0, paddingBottom: 0 });
        this.table = tableLayout.tableMovements;
        this.tableType = tableLayout.type;
        this.addActionButtons();
        this.table.dataSource.addRemoteDataChangeListener((event: DataSourceRemoteDataChangeEvent) => {
            if (event?.change?.type === ModelDataChangeType.DELETE) {
                const movementId = event?.change?.data?.get("movement_id");
                if (movementId != null && this.table.selectedRow != null) {
                    const selectedMoveId = this.table.selectedRow.data.get("movement_id");
                    if (movementId === selectedMoveId)
                        this.table.selectedRow = null;
                }
            }
        })
    }

    async addActionButtons() {
        this.add(new Panel({ ...borderPanelProps, marginLeft: 0, marginRight: 10 }));
        this.addAssignAndDispatchButtons();
        this.addLoadboardButton();
        this.addTopMatchButton();
        this.addConvertSpotToOrderButton();
        this.addReleaseToBillingButton();
        this.addCarrierSelectionButton();
        await this.addTerminalInfoButton();
        this.add(new Panel({ ...borderPanelProps, marginLeft: 10, marginRight: 0 }));
        if (this.components.length == 2)
            this.removeAll();
    }

    addAssignAndDispatchButtons() {
        this.addAssignCarrierButton();
        this.addDispatchButton();
        this.table.addSelectionListener((event: TableSelectionEvent) => {
            const data = event.newRow?.data;
            if (data == null)
                return;

            DispatchUtil.isAssignable(data, this._buttonAssign, this._buttonDispatch);
        });
    }

    private addReleaseToBillingButton() {
        if (this.shouldReleaseToBilling()) {
            const button = new Button({
                ...buttonProps,
                id: "buttonReleaseToBilling",
                imageWidth: 20,
                imageHeight: 20,
                imageName: "rocketShip",
                tooltip: "Release to billing"
            });
            button.addClickListener((event: ClickEvent) => this.releaseToBilling(event));
            this.add(button);
            this.table.addSelectionListener((event: TableSelectionEvent) => button.enabled = this.enableReleaseToBillingButton(button, event.newRow as TableRow));
        }
    }

    shouldReleaseToBilling() {
        switch (this.tableType) {
            case BrokerageMovementTableType.DELIVERED:
                return true;
            default:
                return false;
        }
    }

    enableReleaseToBillingButton(button: Button, tableRow: TableRow) {
        button.disabledTooltip = null;
        if (tableRow.data == null) return false;
        if (isSpotOrder(tableRow.data)) {
            button.disabledTooltip = "Release to billing is not available for spot orders.";
            return false;
        }
    }

    private releaseToBilling(event: ClickEvent) {
        const button = event.target as Button;
        const modelRow = this.table?.selectedRow?.data;
        if (modelRow == null) return;
        button.enabled = false;
        button.busy = true;
        const order_id = modelRow.get("order_id");
        Api.post("lme/powerbroker/release-to-billing", { order_id }).then(output => {
            const success = output.data[0].success;
            const error_message = output.data[0].error_message;
            if (success) {
                Toast.showSuccessToast("Released to Billing", "Order " + order_id);
            } else {
                Toast.showToast(error_message);
            }
            button.enabled = true;
            button.busy = false;
        });
    }

    private addConvertSpotToOrderButton() {
        if (!this.shouldAddConvertToSpotOrderButton()) return;
        const button = new Button({
            ...buttonProps,
            id: "buttonConvertSpotToOrder",
            imageName: "spotOrder",
            tooltip: "Convert spot order to order"
        });
        button.addClickListener((event: ClickEvent) => this.convertSpotToOrder(event));
        this.add(button);
        this.table.addSelectionListener((event: TableSelectionEvent) => button.enabled = this.enableSpotToOrderButton(button, event.newRow as TableRow));
    }

    enableSpotToOrderButton(button: Button, selectedRow: TableRow) {
        button.disabledTooltip = null;
        if (selectedRow == null) return false;
        if (!isSpotOrder(selectedRow.data)) {
            button.disabledTooltip = "This order is not a spot order.";
            return false;
        } else if (selectedRow.data?.get("customer_id") == null) {
            button.disabledTooltip = "A customer must be assigned to this spot order before it can be converted to an order.";
            return false;
        } else {
            return true;
        }
    }

    private convertSpotToOrder(event: ClickEvent) {
        const button = event.target as Button;
        const modelRow = this.table?.selectedRow?.data;
        if (modelRow == null) return;
        button.enabled = false;
        button.busy = true;
        Api.post("lme/dispatch/convert-spot-to-order", { spot_id: modelRow.get("order_id") }).then(output => {
            const errorReason = output.data[0].error_reason;
            if (errorReason != null) {
                Snackbar.showWarningSnackbar(errorReason);
            } else {
                const orderId = output.data[0].order_id;
                Toast.showSuccessToast("Spot order converted to order " + orderId);
            }
        }).finally(() => {
            button.enabled = true;
            button.busy = false;
        });
    }

    shouldAddConvertToSpotOrderButton() {
        switch (this.tableType) {
            case BrokerageMovementTableType.AVAILABLE:
                return true;
            default:
                return false;
        }
    }

    private addDispatchButton() {
        this._buttonDispatch = new Button({
            ...buttonProps,
            id: "buttonDispatch",
            imageName: "send",
            imageHeight: 17,
            imageWidth: 17,
            tooltip: "Dispatch"
        });
        this._buttonDispatch.addClickListener((event: ClickEvent) => this.dispatchOnClick(event));
        this.add(this._buttonDispatch);
    }

    private async dispatchOnClick(event: ClickEvent) {
        const modelRow = this.table?.selectedRow?.data;
        if (modelRow == null)
            return;

        const button = event.target as Button;

        button.busy = true;
        const isBrokerageLtlOrder = modelRow.getBoolean("is_brokerage_ltl");
        const dispatchType = (await Api.post("lme/dispatch/movement-dispatch-type", { movement_id: modelRow.get("movement_id") })).data[0]?.["dispatch_type"];
        await (isBrokerageLtlOrder && StringUtil.equalsIgnoreCase(dispatchType, "P44") ? P44ShipmentTracking : DispatchSlideout).showSlideout(modelRow.get("movement_id"), {
            movementOrId: modelRow.get("movement_id"),
            orderId: modelRow.get("order_id"),
            customerId: modelRow.get("customer_id"),
            orderOnHold: modelRow.getBoolean("hold", false),
            originCity: modelRow.get("origin_city"),
            originState: modelRow.get("origin_state"),
            destinationCity: modelRow.get("destination_city"),
            destinationState: modelRow.get("destination_state"),
            callinRequiredBrkStatusCode: this.callinRequiredBrkStatusCode
        }).finally(() => button.busy = false);
    }

    private addAssignCarrierButton() {
        if (this.shouldAddAssignCarrierButton()) {
            this._buttonAssign = new Button({ ...buttonProps, imageName: "exit", tooltip: "Assign Carrier" });
            this._buttonAssign.addClickListener((event: ClickEvent) => this.assignCarrierOnClick(event));
            this.add(this._buttonAssign);
        }
    }

    shouldAddAssignCarrierButton() {
        switch (this.tableType) {
            case BrokerageMovementTableType.HOLD:
                return !this.preventDispatchOnHold;
            case BrokerageMovementTableType.AVAILABLE:
                return true;
            case BrokerageMovementTableType.OFFERS:
                return true;
            default:
                return false;
        }
    }

    assignCarrierOnClick(event: ClickEvent) {
        const modelRow = this.table?.selectedRow?.data;
        if (modelRow != null) {
            ProcessLock.aquireLock("Carrier dispatch", modelRow.get("movement_id")).then(lockResult => {
                if (lockResult.success === true)
                    this.displayCarrierAssignmentSlideout(event, modelRow);
                else
                    CommonDialogs.showDialog(lockResult.message, { title: "Assignment in Progress" });
            });
        }
    }

    private displayCarrierAssignmentSlideout(event: ClickEvent, modelRow: ModelRow) {
        this.table.selectedRow.expanded = false;
        CarrierAssignmentStepper.showAsSlideout({
            movementId: modelRow.get("movement_id"),
            orderId: modelRow.get("order_id"),
            carrierAssignmentButton: event.target as Button,
            onClose: (cancelled: boolean) => ProcessLock.releaseLock("Carrier dispatch", modelRow.get("movement_id"))
        });
    }

    private addLoadboardButton() {
        if (this.shouldAddLoadboardButton()) {
            const button = new Button({ ...buttonProps, imageName: "loadboard", tooltip: "Post to Load Board" });
            button.addClickListener((event: ClickEvent) => this.loadboardOnClick(event));
            this.table.addSelectionListener((event: TableSelectionEvent) => this.enableLoadboardButton(button, event.newRow));
            this.add(button);
        }
    }

    shouldAddLoadboardButton() {
        switch (this.tableType) {
            case BrokerageMovementTableType.AVAILABLE:
                return true;
            default:
                return false;
        }
    }

    enableLoadboardButton(button: Button, selectedRow: TableRow) {
        button.disabledTooltip = null;
        if (selectedRow != null) {
            if (selectedRow.data?.getBoolean("hold"))
                button.disabledTooltip = "Cannot post to loadboards because the order is on hold.";
            else if ("A" != selectedRow.data?.get("move_status"))
                button.disabledTooltip = "Only Available movements can be posted to loadboard.";
            else if (selectedRow.data?.getBoolean("is_brokerage_ltl"))
                button.disabledTooltip = "This order is a Brokerage LTL order";
        }
        button.enabled = selectedRow != null && button.disabledTooltip == null;
    }

    loadboardOnClick(event: ClickEvent) {
        const data = this.table?.selectedRow?.data;
        const orderId = data?.get("order_id");
        if (orderId != null) {
            Layout.getLayout("lme/dispatch/PostToLoadBoards").addLayoutLoadListener(
                (event) => {
                    const layout = event.target as PostToLoadBoards;
                    layout.mainDataSource.search({ id: orderId }).then((result) => {
                        const erd = new EditRowDecorator({
                            title: "Post to Load Boards - Order " + orderId,
                            layout: layout,
                            layoutLoadListeners: (event) => { },
                            width: 900,
                            overlayProps: { greyedBackground: true },
                            data: layout.mainDataSource.activeRow,
                            onSave: () => {
                                erd.multiButton.busy = true;
                                layout
                                .postDataSources()
                                .then(() => layout.slideOut())
                                .finally(() => (erd.multiButton.busy = false));
                            },
                            onClose: (cancelled: boolean) => layout.slideOut(),
                            doAfterSlideIn: () => {
                                layout.mainDataSource.mode = DataSourceMode.UPDATE;
                                layout.mainDataSource.activeRow.set("loadboard", "Y");
                                layout.sourceStop.mode = DataSourceMode.UPDATE;
                                layout.setDefaultById(data.get("origin_stop_id"));
                            }
                        });
                    });
                }
            );
        }
    }

    private addTopMatchButton() {
        if (this.shouldAddTopMatchButton()) {
            const button = new Button({
                ...buttonProps,
                id: "buttonTopMatch",
                imageName: "carrierSearch",
                tooltip: "Open TopMatch",
                enabled: true
            });
            button.addClickListener((event: ClickEvent) => this.topMatchOnClick(event));
            this.add(button);
            this.table.addSelectionListener((event: TableSelectionEvent) => button.enabled = this.enableTopMatchButton(button, event.newRow as TableRow));
        }
    }

    shouldAddTopMatchButton() {
        switch (this.tableType) {
            case BrokerageMovementTableType.AVAILABLE:
                return true;
            default:
                return false;
        }
    }

    enableTopMatchButton(button: Button, selectedRow: TableRow) {
        button.disabledTooltip = null;
        if (selectedRow == null) return false;
        const isBrokerageLtlOrder = selectedRow.data?.getBoolean("is_brokerage_ltl");
        if (isBrokerageLtlOrder) {
            button.disabledTooltip = "This order is a BRLTL order";
            return false;
        } else {
            return true;
        }
    }

    topMatchOnClick(event: ClickEvent) {
        const moveId = this.table?.selectedRow?.data?.get("movement_id");
        const orderId = this.table?.selectedRow?.data?.get("order_id");
        const equipType = this.table?.selectedRow?.data?.get("equipment_type");
        const queryParams = {};

        if (orderId != null)
            queryParams["orderId"] = orderId;

        if (moveId != null)
            queryParams["movementId"] = moveId;

        if (equipType != null)
            queryParams["trailerType"] = equipType;

        if (getAuthSettings().user_settings?.tm_profile != null)
            queryParams["profile"] = getAuthSettings()?.user_settings?.tm_profile;

        queryParams["autoSearch"] = true;

        let params = "";
        if (Object.keys(queryParams).length != 0)
            params = "?" + new URLSearchParams(queryParams);

        Navigation.navigateTo("lme/powerbroker/TopMatchList" + params.toString(), this.getPopoutNavOptions());
    }

    private getPopoutNavOptions(): Partial<NavOptions> {
        const windowSize = {
            width: 1400,
            height: 700
        };
        const loc = {
            left: ((window.screen as any).availLeft + (window.screen.availWidth / 2)) - (windowSize.width / 2),
            top: ((window.screen as any).availTop + (window.screen.availHeight / 2)) - (windowSize.height / 2)
        };
        return { left: loc.left, top: loc.top, height: 700, width: 1400, newTab: true, windowDecorators: false };
    }

    private async addTerminalInfoButton() {
        const shouldAdd = await this.shouldAddTerminalInfoButton();
        if (!shouldAdd) return;
        const button = new Button({
            ...buttonProps,
            id: "buttonTerminalInfo",
            imageName: "terminalInfo",
            enabled: false,
            tooltip: "Get terminal info via SMC Carrier Connect"
        });
        button.addClickListener((event: ClickEvent) => this.getTerminalInfo(event));
        this.add(button);
        this.table.addSelectionListener(async (event: TableSelectionEvent) => button.enabled = await this.enableTerminalInfoButton(button, event.newRow as TableRow));
    }

    async shouldAddTerminalInfoButton(): Promise<boolean> {
        if (!BrltlUtil.isBrokerageLtl()) return false;
        switch (this.tableType) {
            case BrokerageMovementTableType.PROGRESS:
            case BrokerageMovementTableType.COVERED:
                return (await Model.searchSingleRecord("lme/powerbroker/contract-management-control", { id: "CC" }))?.get("is_active") == "Y";
            default:
                return false;
        }
    }

    async enableTerminalInfoButton(button: Button, selectedRow: TableRow) {
        button.disabledTooltip = null;
        if (selectedRow == null) return false;
        const isBrokerageLtlOrder = selectedRow.data?.getBoolean("is_brokerage_ltl");
        if (!isBrokerageLtlOrder) {
            button.disabledTooltip = "This order is not a Brokerage LTL order";
            return false;
        } else {
            return true;
        }
    }

    private getTerminalInfo(event: ClickEvent) {
        const button = event.target as Button;
        const modelRow = this.table?.selectedRow?.data;
        if (modelRow == null) return;
        button.enabled = false;
        button.busy = true;
        BrltlUtil.getTerminalInfo(modelRow.get("order_id")).finally(() => {
            button.enabled = true;
            button.busy = false;
        });
    }

    addCarrierSelectionButton() {
        if (!BrltlUtil.isBrokerageLtl()) return;
        const button = new Button({ ...buttonProps, id: "buttonCarrierSelection", imageName: "carrierRates", enabled: false, tooltip: "Carrier Selection" });
        button.addClickListener((event: ClickEvent) => {
            const modelRow = this.table?.selectedRow?.data;
            if (modelRow == null) return;
            CarrierSelectionStepper.showSlideout({ orderId: modelRow.get("order_id") });
        });
        this.add(button);
        this.table.addSelectionListener(async (event: TableSelectionEvent) => button.enabled = this.enableCarrierSelectionButton(button, event.newRow as TableRow));
    }

    enableCarrierSelectionButton(button: Button, selectedRow: TableRow) {
        button.disabledTooltip = null;
        if (selectedRow == null) return false;
        const isBrokerageLtlOrder = selectedRow.data?.getBoolean("is_brokerage_ltl");
        if (!isBrokerageLtlOrder) {
            button.disabledTooltip = "This order is not a Brokerage LTL order";
            return false;
        } else {
            return true;
        }
    }
}
