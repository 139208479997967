import { Button, HorizontalSpacer, Label, Layout, Panel, Textbox, } from "@mcleod/components";

export abstract class AutogenLayoutSendTrackingMessage extends Layout {
    btnHeaderCancel: Button;
    buttonCancel: Button;
    buttonSendMessage: Button;
    horizontalspacer5: HorizontalSpacer;
    labelTitle: Label;
    panelBtns: Panel;
    panelHeader: Panel;
    panelMain: Panel;
    textboxComment: Textbox;
    textboxTrackingVendor: Textbox;
}
