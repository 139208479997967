import { Layout, StringOrPropsOrComponent, Textbox } from "@mcleod/components";
import { Alignment, ModelRow, StringUtil } from "@mcleod/core";
import { AutogenLayoutDeclineDetails } from "./autogen/AutogenLayoutDeclineDetails";
import { AutogenLayoutEDIDeclineCodeQuickInfo } from "./autogen/AutogenLayoutEDIDeclineCodeQuickInfo";
import { ModelEdiorderProfile } from "./models/ModelEdiorderProfile";

export class DeclineDetails extends AutogenLayoutDeclineDetails {
    version: string;
    selectedReason: string;
    partnerId: string;
    mainRow: any;

    override onLoad() {
        this.mainDataSource.data[0] = this.mainRow;
        this.mainDataSource.rowIndex = 0;

        new ModelEdiorderProfile().search({ partner_id: this.partnerId, version: this.version, decl_req_reason: "Y" }).then(rows => {
            if (rows.modelRows.length > 0) {
                this.reasonTextbox.required = true;
                this.reasonTextbox.placeholder = "Required";
            }
        });

        this.reasonTextbox.addBeforeLookupModelSearchListener(event => {
            event.filter.partner_id = this.partnerId;
            event.filter.version = this.version;
        });

        this.reasonTextbox.onSelectItem = ((textbox, selection) => {
            this.setQuickInfoTooltip(this.reasonTextbox, "TEST");
            return undefined;
        })
    }

    setQuickInfoTooltip(component: Textbox, tooltip: StringOrPropsOrComponent) {
        if (component)
            component.tooltipCallback = (baseTooltip, originatingEvent) => {
                const layout = Layout.getLayout("lme/datafusion/EDIDeclineCodeQuickInfo") as AutogenLayoutEDIDeclineCodeQuickInfo;
                layout.onLoad = () => {
                    if (component != null && component.lookupModelData != null) {
                        const dataRow: ModelRow = component.lookupModelData[0] as ModelRow;
                        if (dataRow != null) {
                            layout.textboxReasonCode.text = dataRow.get("standard_code");
                            layout.textboxDescription.text = dataRow.get("description");
                            layout.textboxVersion.text = dataRow.get("version");
                            layout.textboxElementID.text = dataRow.get("element_id");
                        }
                    }
                };
                if (!StringUtil.isEmptyString(component.text))
                    component.showTooltip(layout,
                        { position: component.tooltipPosition == null ? Alignment.BOTTOM : component.tooltipPosition, anchorToMouse: true, originatingEvent: originatingEvent },
                        { themeKey: "quickInfo", backgroundColor: "background5", color: null });
                return layout;
            };
    }
}
