import {
    Button, ClickEvent, Component, DataDisplayEvent, DataSourceAction, DataSourceExecutionEvent, DataSourceMode,
    DropdownItem, Label, Snackbar, TableRow, TableRowDisplayEvent, Textbox, Toast
} from "@mcleod/components";
import { Api, DateUtil, getConfig, getUserSettings, ModelRow, Navigation, StringUtil } from "@mcleod/core";
import { AutogenLayoutUserSettings } from "./autogen/AutogenLayoutUserSettings";
import { CommonDialogs } from "./CommonDialogs";

interface TimeFormatItem extends DropdownItem {
    format: string;
}

const GoogleMapTypeRoadMap = 'RoadMap';
const GoogleMapTypeSatellite = 'Satellite';
const GoogleMapTypeTerrain = 'Terrain';
const GoogleMapTypeHybrid = 'Hybrid';

export class UserSettings extends AutogenLayoutUserSettings {
    onLoad() {
        this.textboxGoogleMapTypes.items = [GoogleMapTypeRoadMap, GoogleMapTypeSatellite, GoogleMapTypeTerrain, GoogleMapTypeHybrid];
        this.textboxGoogleMapTypes.text = this.textboxGoogleMapTypes.items[0];
        this.textTheme.items = ["Default", "Dark (experimental)"];
        this.textDateFormat.items = ["MM/dd/yy", "MM/dd/yyyy", "dd/MM/yyyy", "dd/MM/yy"];
        const civFormat = { caption: "Civilian", value: "C", format: "hh:mma" };
        const milFormat = { caption: "Military", value: "M", format: "HHmm" };
        this.textTimeFormat.items = [civFormat, milFormat];

        this.sourceUsers.addHasChangedComponent(this.buttonSave);
        this.sourceSettings.addHasChangedComponent(this.buttonSave);
        this.sourceCurrentUserPowerbroker.addHasChangedComponent(this.buttonSave);
        this.buttonSave.extraDataSources = [this.sourceSettings, this.sourceCurrentUserPowerbroker];
        this.sourceSettings.data = [new ModelRow("common/user-system-config", false, { ...getConfig(), user_id: getUserSettings()?.user_id })];
        this.sourceSettings.rowIndex = 0;
        this.sourceUserRecognizedDevice.search();

        this.textboxDATService.addBeforeLookupModelSearchListener((event) => {
            event.filter.service_type = 'C';
        });
        this.textboxTruckstopRESTService.addBeforeLookupModelSearchListener((event) => {
            event.filter.service_type = 'R';
        });
        this.onSelectedGoogleMapType();
    }

    sourceUsersAfterExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() !== DataSourceAction.SEARCH) {
            Navigation.reloadCurrentPage(true).then(() => {
                Toast.showToast("You have successfully updated your User Settings!")
            });
        }
    }

    onSelectedGoogleMapType() {
        this.textboxGoogleMapTypes.onSelectItem = () => {
            this.textboxGoogleMapTypes.text = this.textboxGoogleMapTypes.text;
            return undefined;
        };
    }

    async buttonSaveClicked(event) {
        if (this.validateSimple()) {
            this.buttonSave.busy = true;
            if (this.sourceCurrentUserPowerbroker.activeRow.get("loadboard_dat_id") == null) {
                this.sourceCurrentUserPowerbroker.activeRow.set("dat_user_id", null);
                this.sourceCurrentUserPowerbroker.activeRow.set("dat_password", null);
                this.textboxDatUserId.text = null;
                this.textboxDatPassword.text = null;
            }
            Promise.all([this.sourceUsers.post(), this.sourceSettings.post(), this.sourceCurrentUserPowerbroker.post()]).then(() => {
                Navigation.reloadCurrentPage(true).then(() => {
                    Toast.showToast("You have successfully updated your User Settings!")
                })
            })
                .catch(error => CommonDialogs.showError(error))
                .finally(() => this.buttonSave.busy = false)
        }
    }

    textDateFormatOnChange(event) {
        this.updateSample();
    }

    textTimeFormatOnChange(event) {
        this.updateSample();
    }

    updateSample() {
        const date = new Date();
        date.setFullYear(date.getFullYear(), 11, 31);
        date.setHours(16);
        date.setMinutes(35);
        let format;
        if (this.textDateFormat.selectedItem != null)
            format = (<TimeFormatItem>this.textDateFormat.selectedItem).caption;
        else
            format = "MM/dd/yyyy";
        format += " ";
        if (this.textTimeFormat.selectedItem != null)
            format += (<TimeFormatItem>this.textTimeFormat.selectedItem).format;
        else
            format += "hh:mma";
        this.labelSample.caption = DateUtil.formatDateTime(date, format);
    }

    sourceCurrentUserPowerbrokerAfterExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() == DataSourceAction.SEARCH && this.sourceCurrentUserPowerbroker.activeRow == null) {
            this.sourceCurrentUserPowerbroker.mode = DataSourceMode.ADD;
        }
        if (this.sourceCurrentUserPowerbroker.activeRow) {
            const [datUser, datPass, datError, truckstopTokensExist] = [
                this.sourceCurrentUserPowerbroker.activeRow.get("dat_user_id"),
                this.sourceCurrentUserPowerbroker.activeRow.get("dat_password"),
                this.sourceCurrentUserPowerbroker.activeRow.get("dat_error"),
                this.sourceCurrentUserPowerbroker.activeRow.get("truckstop_tokens_exist"),
            ]

            if (!StringUtil.isEmptyString(datUser) && !StringUtil.isEmptyString(datPass)) {
                if (StringUtil.isEmptyString(datError)) {
                    this.labelDATResult.imageName = "circleCheck";
                    this.labelDATResult.imageColor = "success";
                } else {
                    this.labelDATResult.imageName = "circleX";
                    this.labelDATResult.imageColor = "error";
                    this.labelDATResult.tooltip = datError;
                }
            }
            if (truckstopTokensExist) {
                this.labelTruckstopResult.imageName = "circleCheck";
                this.labelTruckstopResult.imageColor = "success";
            } else {
                this.labelTruckstopResult.imageName = "circleX";
                this.labelTruckstopResult.imageColor = "error";
                this.labelTruckstopResult.tooltip = "Tokens not set for this user";
            }
        }
    }

    /** This is an event handler for the onClick event of buttonToggleDeviceApproval.  */
    buttonToggleDeviceApprovalOnClick(event: ClickEvent) {
        const tableRow = TableRow.getContainingTableRow(event.target as Component);
        tableRow.data.set("approved", tableRow.data.get("approved") === "N" ? "Y" : "N");
        tableRow.data.post().then(() => this.tableUserDevices.redisplaySingleRow(tableRow.index, tableRow.data, false, false));
    }

    /** This is an event handler for the onClick event of buttonAuthorize.  */
    buttonAuthorizeOnClick(event: ClickEvent) {
        this.labelTruckstopResult.imageColor = "primary";
        this.labelTruckstopResult.busy = true;
        const [serviceId, userId, password] = [
            this.textboxTruckstopRESTService.getDataValue(),
            this.textboxTruckstopUserId.text,
            this.textboxTruckstopPassword.text
        ];

        if (StringUtil.isEmptyString(serviceId)
            || StringUtil.isEmptyString(userId)
            || StringUtil.isEmptyString(password)) {
            this.labelTruckstopResult.imageName = null;
            this.labelTruckstopResult.imageColor = "primary";
            this.labelTruckstopResult.busy = false;
            this.labelTruckstopResult.tooltip = null;
            Snackbar.showWarningSnackbar("Please provide all Truckstop REST fields (Service, Truckstop User ID, Password");
        } else {
            Api.post("lme/powerbroker/truckstop-user-auth", {
                "service_id": serviceId,
                "user_id": userId,
                "password": password
            }).then(response => {
                this.labelTruckstopResult.busy = false;
                if (response.data[0].success) {
                    this.sourceCurrentUserPowerbroker.activeRow.set("truckstop_access_token", response.data[0].access);
                    this.sourceCurrentUserPowerbroker.activeRow.set("truckstop_refresh_token", response.data[0].refresh);
                    this.sourceCurrentUserPowerbroker.activeRow.set("truckstop_token_expires", response.data[0].expire);

                    this.labelTruckstopResult.imageName = "circleCheck";
                    this.labelTruckstopResult.imageColor = "success";
                    this.textboxTruckstopPassword.clear();
                    Snackbar.showSnackbar("Success: Access tokens saved, Truckstop password not saved");
                } else {
                    this.labelTruckstopResult.imageName = "circleX";
                    this.labelTruckstopResult.imageColor = "error";
                    this.labelTruckstopResult.tooltip = response.data[0].error ? response.data[0].error : "There was an unexpected error Authorizing access.";
                }
            }).catch(reason => this.labelTruckstopResult.busy = false);
        }
    }

    /** This is an event handler for the onRowDisplay event of tableUserDevices.  */
    tableUserDevicesOnRowDisplay(event: TableRowDisplayEvent) {
        const button = event.getTableRow().findComponentById("buttonToggleDeviceApproval") as Button;
        if (event.getTableRow().data.get("approved") === "N")
            button.setProps({ caption: "Unblock this Device", color: "success" });
        else
            button.setProps({ caption: "Block this Device", color: "warning" });
    }

    /** This is an event handler for the onDataDisplay event of labelDeviceApproved.  */
    labelDeviceApprovedOnDataDisplay(event: DataDisplayEvent) {
        const label = event.target as Label;
        const tableRow = TableRow.getContainingTableRow(label);
        const thisDeviceId = localStorage.getItem("deviceId");
        if (tableRow.data.get("approved") === "N")
            label.setProps({ caption: "Blocked", backgroundColor: "warning", color: "warning.reverse" });
        else if (tableRow.data.get("device_id") === thisDeviceId)
            label.setProps({ caption: "This Device", backgroundColor: "success", color: "success.reverse" });
        else
            label.setProps({ caption: "Approved", backgroundColor: "success", color: "success.reverse" });
    }

    /** This is an event handler for the onDataDisplay event of label1.  */
    labelDeviceLocationOnDataDisplay(event: DataDisplayEvent) {
        const label = event.target as Textbox;
        const tableRow = TableRow.getContainingTableRow(label);
        if (StringUtil.isEmptyString(tableRow.data.get("last_ip_location")))
            label.text = "Unknown location";
        label.tooltip = "IP address: " + tableRow.data.get("last_ip_address");
    }
}
