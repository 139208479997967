import { ClickEvent, Label, Panel } from "@mcleod/components";
import { Alignment, DisplayType, StringUtil } from "@mcleod/core";
import { DistanceRateUtil } from "./DistanceRateUtil";
import { AutogenLayoutRateIndexDataSummary } from "./autogen/AutogenLayoutRateIndexDataSummary";

const TOTAL_RATE_TOOLTIP = 'Total rate';

const VENDOR_MCLEOD_EXCHANGE = "E";
const VENDOR_TRANSCORE_WEB = "T";
const VENDOR_INTERNET_TRUCKSTOP = "I";
const VENDOR_FREIGHTWAVES = "F";
const VENDOR_GREENSCREENS = "G";

const RATE_TYPE_SPOT = "S";
const RATE_TYPE_BILLED = "B";
const RATE_TYPE_CONTRACT = "C";
const INTERNAL_SPOT_RATE_TYPE = "N";
const INTERNAL_BILLED_RATE_TYPE = "T";

export class RateIndexDataSummary extends AutogenLayoutRateIndexDataSummary {
    private detailsPanelExpanded: boolean = false;
    private listOfPanels: Panel[];
    private distanceUtil = new DistanceRateUtil();

    override onLoad(): void {
        this.labelScacBuyRateHeader.caption = 'SCAC Buy';
        this.labelScacSellRateHeader.caption = 'SCAC Sell';

        this.listOfPanels = [
            this.panelMpactBuyTotalRate, this.panelMpactSellTotalRate, this.panelScacBuyTotalRate, this.panelScacSellTotalRate,
            this.panelDATSpotTotalRate, this.panelDATContractTotalRate, this.panelTruckstopPostedTotalRate, this.panelTruckstopPaidTotalRate,
            this.panelGreenscreenNetworkTotalRate, this.panelGreenscreenTargetTotalRate,
            this.panelFreightWavesTotalRate, this.panelMpactBuyExpandable, this.panelScacBuyExpandable, this.panelDATSpotExpandable,
            this.panelTruckstopPostedExpandable, this.panelFreightWavesExpandable, this.panelMpactSellExpandable,
            this.panelScacSellExpandable, this.panelDATContractExpandable, this.panelTruckstopPaidExpandable,
            this.panelGreenscreenNetworkExpandable, this.panelGreenscreenTargetExpandable];
    }

    public populateRateIndexResultData(vendorsData: any, showAllRates: boolean, scacCode: string, profile: string) {
        const vendor = vendorsData?.vendor;
        const rateType = vendorsData?.rate_type;

        if ((vendor === VENDOR_MCLEOD_EXCHANGE && rateType === INTERNAL_SPOT_RATE_TYPE) || (vendor === VENDOR_MCLEOD_EXCHANGE && rateType === INTERNAL_BILLED_RATE_TYPE)) {
            this.labelScacBuyRateHeader.caption = scacCode + " Buy";
            this.labelScacSellRateHeader.caption = scacCode + " Sell";
        }

        const [panelTotalRate, panelExpandable] = this.getPanelPerVendorAndRateType(vendor, rateType);
        if (panelTotalRate === null || panelTotalRate === undefined || panelExpandable === null || panelExpandable === undefined) {
            return;
        }

        panelTotalRate.visible = true;
        panelExpandable.visible = true;

        for (const component of panelExpandable.components) {
            const label = component as Label;
            if (!StringUtil.isEmptyString(label.field)) {
                label.caption = vendorsData[label.field];
                label.color = "subtle.dark";
            }
            label.tooltipPosition = Alignment.BOTTOM;
            this.distanceUtil.createHoverToDiscover(vendorsData, label);
        }

        panelTotalRate.findComponentByField("total_rate_vendor").forEach((component) => this.updateRateLabel(component as Label, vendorsData));
        panelTotalRate.findComponentByField("total_sell_rate").forEach((component) => this.updateRateLabel(component as Label, vendorsData));
        panelTotalRate.findComponentByField("total_rate_per_mile").forEach((component) => this.updateRateLabel(component as Label, vendorsData));
    }

    private updateRateLabel(label: Label, vendorsData: any) {
        if (!StringUtil.isEmptyString(label.field)) {
            this.distanceUtil.populateValueRatePerMile(label, vendorsData);
            this.distanceUtil.populateValueTotalRateVendor(label, vendorsData);
            this.distanceUtil.createHoverToDiscover(vendorsData, label);
        }
        else {
            label.displayType = DisplayType.LINK;
            label.link = vendorsData?.rate_url;
        }
    }

    public hideColumns() {
        const listOfExpandables = this.listOfPanels;
        for (const setData of listOfExpandables) {
            const panel = setData as Panel;
            panel.visible = false;
        }
    }

    buttonFetchOtherResultsOnClick(event: ClickEvent) {
        this.setBusyStatus(true);
        this.buttonFetchOtherResults.imageName = 'padlock';
        const lastSearch = this.mainDataSource.lastSearch
        lastSearch["show_all_total_rates"] = true;
        this.mainDataSource.search(lastSearch).then((response) => {
            const data = response["modelRows"][0].data;
            for (const vendorData of data["vendors_data"]) {
                this.populateRateIndexResultData(vendorData, true, data["show_company_scac"], null)
            }
            this.setBusyStatus(false);
        });
    }

    setBusyStatus(busy: boolean) {
        if (busy) {
            this.buttonFetchOtherResults.caption = 'Loading ...';
            this.buttonFetchOtherResults.enabled = false;
            this.buttonFetchOtherResults.busy = true;
        }
        else {
            this.buttonFetchOtherResults.caption = 'Fetch Other Results';
            this.buttonFetchOtherResults.enabled = true;
            this.buttonFetchOtherResults.busy = false;
            this.buttonFetchOtherResults.imageName = null;
        }
    }

    buttonExpandDetailsOnClick(event: ClickEvent) {
        const transitionProps = { speed: 100, paddingTop: 0 };
        this.panelExpandedDetails.visible = true;
        if (this.detailsPanelExpanded) this.panelExpandedDetails.collapse(transitionProps).then(() => {
            this.buttonExpandDetails.imageRotation = 90
        }); else this.panelExpandedDetails.expand(transitionProps).then(() => {
            this.buttonExpandDetails.imageRotation = 0
        });
        this.detailsPanelExpanded = !this.detailsPanelExpanded;
    }

    public setDefaultValues() {
        const vendorsData: Object = this.activeRow?.data["vendors_data"][0] || {};
        Object.keys(vendorsData).forEach(field => {
            this.findComponentByField(field).forEach((component) => this.setDefaultValue(component as Label));
        })
        this.labelScacBuyRateHeader.caption = "SCAC Buy";
        this.labelScacSellRateHeader.caption = "SCAC Sell";
    }

    setDefaultValue(label: Label) {
        const fieldName = label.field;
        label.displayData(null, null, 0)
        if (!StringUtil.isEmptyString(fieldName)) {
            label.caption = "---";
            label.fontSize = "large";
            label.color = "primary";
        }
        if (fieldName === "total_rate_vendor" || fieldName === "total_rate_per_mile" || !StringUtil.isEmptyString(fieldName)) {
            label.tooltipCallback = null;
            label.tooltip = null;
        }
    }

    public resetTotalRatesPanel() {
        this.setDefaultValues();
    }

    public getPanelPerVendorAndRateType(vendor: string, rateType: string) {
        if (vendor === VENDOR_MCLEOD_EXCHANGE && rateType === RATE_TYPE_SPOT)
            return [this.panelMpactBuyTotalRate, this.panelMpactBuyExpandable] as const;
        else if (vendor === VENDOR_MCLEOD_EXCHANGE && rateType === RATE_TYPE_BILLED)
            return [this.panelMpactSellTotalRate, this.panelMpactSellExpandable] as const;
        else if (vendor === VENDOR_MCLEOD_EXCHANGE && rateType === INTERNAL_SPOT_RATE_TYPE)
            return [this.panelScacBuyTotalRate, this.panelScacBuyExpandable] as const;
        else if (vendor === VENDOR_MCLEOD_EXCHANGE && rateType === INTERNAL_BILLED_RATE_TYPE)
            return [this.panelScacSellTotalRate, this.panelScacSellExpandable] as const;
        else if (vendor === VENDOR_TRANSCORE_WEB && rateType === RATE_TYPE_SPOT)
            return [this.panelDATSpotTotalRate, this.panelDATSpotExpandable] as const;
        else if (vendor === VENDOR_TRANSCORE_WEB && rateType === RATE_TYPE_CONTRACT)
            return [this.panelDATContractTotalRate, this.panelDATContractExpandable] as const;
        else if (vendor === VENDOR_INTERNET_TRUCKSTOP && rateType === RATE_TYPE_SPOT)
            return [this.panelTruckstopPostedTotalRate, this.panelTruckstopPostedExpandable] as const;
        else if (vendor === VENDOR_INTERNET_TRUCKSTOP && rateType === RATE_TYPE_BILLED)
            return [this.panelTruckstopPaidTotalRate, this.panelTruckstopPaidExpandable] as const;
        else if (vendor === VENDOR_FREIGHTWAVES && rateType === RATE_TYPE_SPOT)
            return [this.panelFreightWavesTotalRate, this.panelFreightWavesExpandable] as const;
        else if (vendor === VENDOR_GREENSCREENS && rateType === RATE_TYPE_SPOT)
            return [this.panelGreenscreenNetworkTotalRate, this.panelGreenscreenNetworkExpandable] as const;
        else if (vendor === VENDOR_GREENSCREENS && rateType === RATE_TYPE_BILLED)
            return [this.panelGreenscreenTargetTotalRate, this.panelGreenscreenTargetExpandable] as const;
    }

}
