import { Button, CityState, DataHeader, DataSource, HorizontalSpacer, Image, Label, Layout, Panel, SaveButton, Step, Stepper, Tab, Tabset, Textbox } from "@mcleod/components";
import { LaneHistoryTable } from "../../../dispatch/src/LaneHistoryTable";
import { QuoteRates } from "../../../dispatch/src/QuoteRates";
import { RateIndexDataSummary } from "../../../dispatch/src/RateIndexDataSummary";
import { QuoteLanding } from "../QuoteLanding";
import { QuoteStopTable } from "../QuoteStopTable";

export abstract class AutogenLayoutQuickQuote extends Layout {
    buttonClear: Button;
    buttonContinue: Button;
    buttonExpand: Button;
    buttonSave: SaveButton;
    citystateConsigneeCS: CityState;
    citystateShipperCS: CityState;
    citystateStopOriginCityId: CityState;
    dataheaderQuote: DataHeader;
    horizontalspacer1: HorizontalSpacer;
    horizontalspacer2: HorizontalSpacer;
    imageHazmat: Image;
    labelAdditionalInformation: Label;
    labelCaptionContactSuggestedPricing: Label;
    labelCaptionCustomerSuggestedPricing: Label;
    labelContactEmailSuggestedPricing: Label;
    labelContactNameSuggestedPricing: Label;
    labelContactPhoneSuggestedPricing: Label;
    labelCustomerNameSuggestedPricing: Label;
    labelDeliveryCaption: Label;
    labelFilter: Label;
    labelHazmatCommodity: Label;
    labelPickupCaption: Label;
    labelPickupandDeliveries: Label;
    layoutLaneHistory: LaneHistoryTable;
    layoutQuoteLanding: QuoteLanding;
    layoutQuoteRates: QuoteRates;
    layoutQuoteStopTable: QuoteStopTable;
    layoutRateIndex: RateIndexDataSummary;
    panelCommodityHazmat: Panel;
    panelConsignee: Panel;
    panelContainerSuggestedPricing: Panel;
    panelCustomer: Panel;
    panelCustomerContact: Panel;
    panelCustomerContainer: Panel;
    panelCustomerSuggestedPricing: Panel;
    panelDataHeaderAddlRight: Panel;
    panelFilterComponents: Panel;
    panelOrderFields: Panel;
    panelPickupandDeliveries: Panel;
    panelPricingContent: Panel;
    panelQuoteFieldContainer: Panel;
    panelQuoteOrderFields: Panel;
    panelRow1SuggestedPricing: Panel;
    panelSearchFilter: Panel;
    panelSearchHeader: Panel;
    panelShipperSuggestedPricing: Panel;
    panelStepTwoCommodity: Panel;
    panelSuggestedPricingOverview: Panel;
    searchButton: Button;
    sourceLaneHistory: DataSource;
    sourceQuoteOrder: DataSource;
    sourceQuoteStop: DataSource;
    stepEntry: Step;
    stepNextActions: Step;
    stepSuggestedPricing: Step;
    stepperQuickQuote: Stepper;
    tabLaneHistory: Tab;
    tabRateIndex: Tab;
    tabsetSuggestedPricing: Tabset;
    textboxCommodity: Textbox;
    textboxCommodityId: Textbox;
    textboxCustomerContactName: Textbox;
    textboxCustomerEmail: Textbox;
    textboxCustomerName: Textbox;
    textboxCustomerPhone: Textbox;
    textboxDateRange: Textbox;
    textboxDistanceSuggestedPricing: Textbox;
    textboxEquipmentTypeId: Textbox;
    textboxMovementStatus: Textbox;
    textboxNotes: Textbox;
    textboxRadius: Textbox;
    textboxStepTwoCommodityId: Textbox;
    textboxTotalCharge: Textbox;
    textboxTrailerTypeReviewSuggestedPricing: Textbox;
    textboxWeight: Textbox;
    textboxWeightSuggestedPricing: Textbox;
}
