import { Component, Table } from "@mcleod/components";
import { StringUtil } from "@mcleod/core";
import { AbstractUIDesigner } from "../AbstractUIDesigner";
import { DesignerDataSource } from "../DesignerDataSource";
import { ActionDeleteTableComponent } from "./ActionDeleteTableComponent";
import { DesignerAction } from "./DesignerAction";
import { DesignerActionResult } from "./DesignerActionResult";

export class ActionDeleteComponent implements DesignerAction {
    component: Component;
    designer: AbstractUIDesigner;
    index: number;
    specialDeleteAction: DesignerAction;

    constructor(component) {
        this.component = component;
    }

    execute(): DesignerActionResult {
        if (this.component.parent instanceof Table) {
            const deleteAction = new ActionDeleteTableComponent(this.component, this.component.parent);
            const result = deleteAction.execute()
            if (result != null) {
                this.specialDeleteAction = deleteAction;
                return result;
            }
        }
        this.index = this.component.parent.indexOf(this.component);
        this.component.parent.remove(this.component);

        if (this.component instanceof DesignerDataSource) {
            const tab = this.designer.getActiveTab();
            delete tab.dataSources[this.component.designerDataSource.id];
        }
        this.designer.selectComponent(null);
        return { success: true };
    }

    undo() {
        if (this.specialDeleteAction?.undo != null) {
            this.specialDeleteAction?.undo();
        } else {
            this.component.parent.insert(this.component, this.index);
            this.designer.selectComponent(this.component);
        }
    }

    toString() {
        if (this.specialDeleteAction)
            return this.specialDeleteAction.toString();
        return `Delete ${StringUtil.toProperCase(this.component.serializationName)} - ${this.component.id}`;
    }
}
