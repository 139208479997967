import { DataDisplayEvent, Label, Tab } from "@mcleod/components";
import { AutogenLayoutEdiOrderCarrierPay } from "./autogen/AutogenLayoutEdiOrderCarrierPay";

export class EdiOrderCarrierPay extends AutogenLayoutEdiOrderCarrierPay {

    override onLoad(): void | Promise<void> {
        this.styleTab(this.tabOtherPay);
    }
    sourceExtraPayAfterExecution() {
        this.tabOtherPay["countLabel"].caption = this.sourceEdiDriverextrapay.data.length;
    }

    /** This is an event handler for the onDataDisplay event of textboxOverridePayRate.  */
    textboxOverridePayRateOnDataDisplay(event: DataDisplayEvent) {
        const miles = this.mainDataSource.activeRow?.get("bill_distance");
        const pay = this.mainDataSource.activeRow?.get("override_pay_amt");

        if (miles != null) {
            this.labelBillDistance.caption = miles + " miles";
        } else {
            this.labelBillDistance.caption = "";
        }

        if (miles != null && pay != null) {
            const payPerMile: number = pay.base_amount / miles;
            this.labelRateDesc.caption = payPerMile.toFixed(2) + "/mile";
        } else {
            this.labelRateDesc.caption = "";
        }
    }
    styleTab(tab: Tab) {
        if (tab["_heading"] != null && tab["_headingLabel"] != null) {
            tab["_heading"].color = "subtle.light";
            tab["_heading"].marginBottom = 10;
            tab["_headingLabel"].setProps({ color: "default", fontBold: true, fillRow: false })
            if (tab["countLabel"] == null) {
                tab["countLabel"] = new Label({
                    fontBold: true, caption: "0", fillRow: true,
                    color: "primary", rowBreak: false, marginLeft: 5
                });
                tab["_heading"].insert(tab["countLabel"], 1);
            }
        }
    }

}


