import { Button, DataSource, HorizontalSpacer, Label, Layout, Panel, Table, } from "@mcleod/components";

export abstract class AutogenLayoutEquipmentMatchDetail extends Layout {
    buttonClose: Button;
    horizontalspacer1: HorizontalSpacer;
    labelEquipmentRequired: Label;
    panel1: Panel;
    panel2: Panel;
    sourceEquipmentMatchDetail: DataSource;
    table1: Table;
}
