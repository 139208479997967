/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelBrltlSpotQuote.ts                *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/dispatch/brltl-spot-quote";

export class AutogenModelBrltlSpotQuote<RowType extends ModelRow> extends Endpoint<RowType, RowBrltlSpotQuoteFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowBrltlSpotQuote extends ModelRow<RowBrltlSpotQuoteFields> {
    constructor(values?: Partial<RowBrltlSpotQuoteFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowBrltlSpotQuoteFields {
    add_timestamp: Date;
    add_userid: string;
    bfg_comment: string;
    bfg_status_code: string;
    bfg_type_code: string;
    bfg_uid: number;
    bol_address1: string;
    bol_address2: string;
    bol_city: string;
    bol_name: string;
    bol_phone: string;
    bol_state: string;
    bol_zip_code: string;
    calc_description: string;
    calc_quan: number;
    calc_rate: number;
    calc_type_code: string;
    calc_uom_code: string;
    carrier_display_name: string;
    carrier_key: string;
    carrier_scac: string;
    ccv_uid: number;
    charge_as_calc_description: string;
    charge_as_qual_description: string;
    charge_cost_as_amount: number;
    commissionable_flag: boolean;
    company_id: string;
    contract_descr: string;
    contract_type_descr: string;
    contractual_maximum: number;
    contractual_minimum: number;
    conversion_rate: number;
    cur_uid: number;
    currency_code: string;
    currency_type_id: string;
    deficit_quantity_added: number;
    delivery_lane: string;
    dispatch_type: string;
    est_delivery_date: Date;
    external_charge_code: string;
    fgp_uid: number;
    freight_class_code: string;
    fxb_uid: number;
    gla_uid: number;
    mod_timestamp: Date;
    mod_userid: string;
    movement_id: string;
    p44_capacity_provider_acct: string;
    p44_direction_override: string;
    p44_payment_terms: string;
    p44_scac: string;
    p_comp_override_flag: string;
    pickup_lane: string;
    power_comp_pct: number;
    qual_description: string;
    quote_expire_date: Date;
    quote_number: string;
    rate_before_minimum: number;
    rated_as_class_code: string;
    rating_mode: string;
    reference_id: string;
    rev_uid: number;
    revenue_amount: number;
    service_descr: string;
    shipment_id: string;
    tli_uid: number;
    total_cost: number;
    total_pieces: number;
    total_req_spots: number;
    total_weight: number;
    transit_days: number;
}

