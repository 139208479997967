import { Button, DataSource, DataSourceMode, Dialog, DialogProps, Label, Panel, ScreenStack, Snackbar, StringOrPropsOrComponent, Textbox } from "@mcleod/components";
import { Api, DisplayType, HorizontalAlignment, RowUpdateEvent, StringUtil, getAuthSettings, getLogger } from "@mcleod/core";
import { OrderRates } from "./OrderRates";
import { Orders } from "./Orders";

const log = getLogger("lme.dispatch.OrderCreditValidator");

export class OrderCreditValidator {
    private mainDataSource: DataSource;
    private ordersLayout: Orders;
    private validatingCredit = false;
    private creditSnackbar: Snackbar;
    private userDismissedCreditSnackbar;
    private _customerId: string;
    private shouldValidateCredit = false;

    constructor(order: Orders) {
        this.ordersLayout = order;
        this.mainDataSource = order.mainDataSource;
        const enforceCredit = getAuthSettings().dispatch_control[0].enforce_credit;
        this.shouldValidateCredit = "S" === enforceCredit || "L" === enforceCredit;
    }

    public get customerId(): string {
        return this._customerId;
    }

    public set customerId(value: string) {
        if (value != this._customerId) {
            this._customerId = value;
            this.reset();
            if (!StringUtil.isEmptyString(value))
                this.validate();
        }
    }

    private reset() {
        this.removeWarningSnackbar();
        this.ordersLayout.labelCreditBadge.visible = false;
    }

    private removeWarningSnackbar() {
        if (this.creditSnackbar != null) {
            this.creditSnackbar["_onDismiss"] = null;
            this.creditSnackbar.dismiss();
            this.creditSnackbar = null;
            this.userDismissedCreditSnackbar = null;
        }
    }


    public async validate(): Promise<any> {
        if (this.shouldValidateCredit && !this.validatingCredit && !this.mainDataSource.activeRow.isNull("customer_id")) {
            this.validatingCredit = true;
            const snackbarTarget = ScreenStack.getSnackbarTarget(); //hold onto the snackbar target in case the user does something while we are waiting on the server
            this.getCreditValidationResult().then(result => {
                const data = result?.data?.[0];
                if (data.credit_limit_warning === true) {
                    const creditStatusKey = this.getCreditStatusKey(data.credit_limit_status);
                    // check if the user dismissed previous warnings for this customer and credit limit status
                    if (creditStatusKey != this.userDismissedCreditSnackbar) {
                        this.userDismissedCreditSnackbar = null;
                        const panel = OrderCreditValidator.createCreditSnackbarPanel(data);
                        // if currently displaying a snackbar, update the message
                        if (this.creditSnackbar != null) {
                            const displayedPanel = this.creditSnackbar.findComponentById("snackPanel") as Panel;
                            displayedPanel.removeAll();
                            panel.components.forEach(comp => displayedPanel.add(comp));
                        } else {
                            this.showCustomerCreditValidationSnackbar(panel, creditStatusKey, snackbarTarget);
                        }
                    }
                }
                this.displayCreditBadge(data);
                if (this.mainDataSource.mode === DataSourceMode.ADD && this.isCreditDeniedOrUndefined(data)) {
                    this.showInvalidCustomerDialog(data.error_message, data.message_to_user);
                }
            }).finally(() => this.validatingCredit = false);
        }
    }

    private displayCreditBadge(data: any) {
        const badgeCaption = data?.error_message;
        if (badgeCaption != null) {
            this.ordersLayout.labelCreditBadge.caption = badgeCaption;
            this.ordersLayout.labelCreditBadge.imageName = "warning";
            this.removeWarningSnackbar();
        }
        this.ordersLayout.labelCreditBadge.visible = badgeCaption != null;
    }


    showInvalidCustomerDialog(title: string, message: string) {
        this.createCustomerCreditValidationDialog(true, title, message).show().then(() => {
            const textboxCustomerId = this.ordersLayout.textboxCustomerId as Textbox;
            this.mainDataSource.activeRow.set("customer_id", null, this);
            this.mainDataSource.activeRow.setLookupModelData("customer_id", null, this);
            textboxCustomerId.displayData(this.mainDataSource.activeRow, null, 0);
            textboxCustomerId.focus();
            this.customerId = null;
        });
    }

    async rowUpdated(postEvent: RowUpdateEvent): Promise<any> {
        if (this.shouldValidateCredit) {
            return new Promise((resolve, reject) => {
                this.getCreditValidationResult().then(async result => {
                    await this.showCustomerCreditValidationDialog(result?.data?.[0], postEvent)
                        .then(result => resolve(result));
                });
            });
        }
    }

    async getCreditValidationResult(): Promise<any> {
        return await Api.search("lme/dispatch/perform-credit-check", {
            order_row: this.mainDataSource.getDataboundValues(null, true, null, null),
            order_id: this.mainDataSource.mode === DataSourceMode.UPDATE ? this.mainDataSource.activeRow.get("id") : null,
            total_charge: this.mainDataSource.activeRow.get("total_charge")
        });
    }

    private getCreditStatusKey(creditStatus: string) {
        return this.mainDataSource.activeRow.get("customer_id") + "|" + creditStatus;
    }

    public showCustomerCreditValidationSnackbar(snackText: StringOrPropsOrComponent, statusKey?: string, snackbarTarget?: Panel) {
        Snackbar.showWarningSnackbar(snackText, {
            id: statusKey, targetPanel: snackbarTarget, persist: true, onDismiss: (() => {
                this.creditSnackbar = null;
                this.userDismissedCreditSnackbar = statusKey;
            })
        });
        this.creditSnackbar = ScreenStack.getOldestSnackbar(statusKey) as Snackbar
    }

    private isCreditDeniedOrUndefined(data: any): boolean {
        return data?.credit_denied == true || data?.credit_limit_undefined == true;
    }


    async showCustomerCreditValidationDialog(data: any, postEvent: RowUpdateEvent): Promise<Dialog> {
        if (data?.invalid_credit != true) return null;
        this.displayCreditBadge(data);
        const preventSave = this.isCreditDeniedOrUndefined(data) || (data?.credit_limit_exceeded == true && data?.can_place_on_hold == false);
        const dialog = this.createCustomerCreditValidationDialog(preventSave, data.error_message, data.message_to_user);
        if (preventSave) {
            postEvent.preventDefault(data.message_to_user, () => { });
        } else {
            const buttonProps = { rowBreak: false, marginLeft: 10, marginRight: 10, width: 100 };
            const pnlButtons = new Panel({
                fillRow: true, align: HorizontalAlignment.CENTER, marginTop: 20,
                components: [
                    new Button({
                        ...buttonProps, caption: "Modify", onClick: event => {
                            dialog.close(true);
                            postEvent.preventDefault("Credit exceeded! User selected to modify order.", () => {
                                this.ordersLayout.tabsetAddOrders.scrollToTab(this.ordersLayout.tabRate);
                                const tbRate: Textbox = (this.ordersLayout.layoutRates as OrderRates).textboxRate;
                                tbRate.focus().selectText();
                            });
                        }
                    }),
                    new Button({
                        ...buttonProps, caption: "Hold", onClick: () => {
                            this.mainDataSource.activeRow.set("fail_on_credit_warning", false);
                            this.ordersLayout.switchPostToLoadBoards.checked = false;
                            dialog.close(true);
                        }
                    })
                ]
            });
            dialog.add(pnlButtons);
        }

        return await dialog.show();
    }

    createCustomerCreditValidationDialog(preventSave: boolean, title: string, message: string): Dialog {
        const dialog = new Dialog(this.getDialogProps(preventSave, title));
        dialog.add(new Label({ caption: message, align: HorizontalAlignment.LEFT, padding: 0 }));
        return dialog;
    }

    getDialogProps(preventSave: boolean, title: string): Partial<DialogProps> {
        return {
            okVisible: preventSave, xVisible: false, maxWidth: 450,
            titleProps: {
                height: 40, paddingTop: 0, paddingBottom: 0, caption: title,
                imageHeight: 24, imageMarginRight: 8, imageName: "warning"
            }
        };
    }

    private static createCreditSnackbarPanel(data: any, snackMessage: string = data.message_to_user as string): Panel {
        const panel = new Panel({ id: "snackPanel" });
        panel.add(new Label({ caption: snackMessage, paddingTop: 0, paddingBottom: 15, fillRow: true }));
        if (data.contact_email != null) {
            panel.add(new Label({ caption: `Please contact ${data.contact_name} at`, rowBreak: false, paddingTop: 0, paddingBottom: 0 }));
            panel.add(new Label({ caption: data.contact_email, displayType: DisplayType.EMAIL, fontBold: true, color: "primary.dark", paddingTop: 0, paddingBottom: 0 }));
            panel.add(new Label({ caption: "with questions.", rowBreak: false, paddingTop: 0, paddingBottom: 0 }));
        }
        return panel;
    }

    public static validateCreditAndShowWarning(orderId: string): Promise<Snackbar> {
        return new Promise((resolve) => {
            if (orderId != null && "L" === getAuthSettings().dispatch_control[0].enforce_credit) {
                Api.search("lme/dispatch/perform-credit-check", { order_id: orderId, include_contact_info: true, validating_for_dispatch: true }).then(result => {
                    const data = result?.data?.[0];
                    let snackMessage = null;
                    if (data.credit_limit_warning === true) {
                        snackMessage = data.message_to_user;
                    } else if (data.credit_limit_exceeded === true) {
                        snackMessage = `${data.customer_name} has exceeded their credit limit.`;
                    } else if (data.credit_limit_undefined === true) {
                        snackMessage = `${data.customer_name} does not have a defined credit limit.`;
                    }
                    if (snackMessage != null) {
                        const snackPanel = OrderCreditValidator.createCreditSnackbarPanel(data, snackMessage);
                        resolve(Snackbar.showWarningSnackbar(snackPanel, { id: "creditWarning", persist: true }));
                    }
                })
            }
        });
    }

    public static customerCreditLimitUndefined(orderId: string): Promise<boolean> {
        return new Promise((resolve) => {
            if (orderId != null && "L" === getAuthSettings().dispatch_control[0].enforce_credit) {
                Api.search("lme/dispatch/perform-credit-check", { order_id: orderId, validating_for_dispatch: true }).then(result => {
                    resolve(result?.data?.[0]?.credit_limit_undefined === true);
                })
            } else {
                resolve(false);
            }
        });
    }

}
