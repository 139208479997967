import { DataSourceMode, DataSourceModeChangeEvent, TableRowMode, Textbox } from "@mcleod/components";
import { getUnauthSettings } from "@mcleod/core";
import { AutogenLayoutProductBookTable } from "./autogen/AutogenLayoutProductBookTable";

export class ProductBookTable extends AutogenLayoutProductBookTable {
    override onLoad() {
        this.hideLtlColumns();
    }

    hideLtlColumns() {
        if (getUnauthSettings()?.company_settings?.["is_brokerage_ltl"] == false) {
            this.removeColumn("typeCode");
            this.removeColumn("packaging");
            this.removeColumn("weight");
            this.removeColumn("nmfcClassCode");
            this.removeColumn("spots");
            this.removeColumn("handlingUnits");
            this.removeColumn("cubicUnits");
            this.removeColumn("productSKU");

            this.setColumnWidth("commodityCode", null);
            this.setColumnWidth("descr", null);
            this.setColumnWidth("isHazmat", null);
            this.setColumnWidth("hazmatNumber", null);
        }
    }

    sourceLocationCommodityAfterModeChange(event: DataSourceModeChangeEvent) {
        if (event.newMode === DataSourceMode.SEARCH) {
            this.removeColumn("isHazmat");
            this.removeColumn("hazmatNumber");
            if (getUnauthSettings()?.company_settings?.["is_brokerage_ltl"] == true) {
                this.removeColumn("typeCode");
                this.removeColumn("packaging");
                this.removeColumn("weight");
                this.removeColumn("nmfcClassCode");
                this.removeColumn("spots");
                this.removeColumn("handlingUnits");
                this.removeColumn("cubicUnits");
                this.removeColumn("productSKU");
            }

            this.setColumnWidth("commodityCode", null);
            this.setColumnWidth("descr", null);
        }
        else if (getUnauthSettings()?.company_settings?.["is_brokerage_ltl"] == false) {
            this.setColumnWidth("commodityCode", null);
            this.setColumnWidth("descr", null);
            this.setColumnWidth("isHazmat", null);
            this.setColumnWidth("hazmatNumber", null);
        }
        else {
            this.setColumnWidth("commodityCode", "15%");
            this.setColumnWidth("descr", "10%");
            this.setColumnWidth("typeCode", "5%");
            this.setColumnWidth("packaging", "5%");
            this.setColumnWidth("weight", "10%");
            this.setColumnWidth("nmfcClassCode", "5%");
            this.setColumnWidth("spots", "10%");
            this.setColumnWidth("handlingUnits", "10%");
            this.setColumnWidth("cubicUnits", "10%");
            this.setColumnWidth("isHazmat", "5%");
            this.setColumnWidth("hazmatNumber", "5%");
            this.setColumnWidth("productSKU", "10%");
        }
    }

    private setColumnWidth(columnId: String, width: string) {
        for (const column of this.tableProductBook.columns) {
            if (column?.cellDef?.def?.id === columnId) {
                this.tableProductBook.columns[column.index].headingCell.width = width;
                this.tableProductBook.columns[column.index].cellDef.def.width = width;
            }
        }
    }

    private removeColumn(columnId: String) {
        for (const column of this.tableProductBook.columns) {
            if (column?.cellDef?.def?.id === columnId) {
                this.tableProductBook.removeColumn(column.index); return;
            }
        }
    }

    tableProductBookOnRowDisplay(event) {
        const tableRow = event.getTableRow();
        const data = event.getTableRow().data;
        const textboxCommodityId = tableRow.findComponentById("textboxCommodityId") as Textbox;
        const textboxDescr = tableRow.findComponentById("textboxDescr") as Textbox;
        const labelHazmat = tableRow.findComponentById("labelHazmat");
        const labelHazmatNumber = tableRow.findComponentById("labelHazmatNumber");
        const labelType = tableRow.findComponentById("labelType");
        const labelPackaging = tableRow.findComponentById("labelPackaging");
        const labelWeight = tableRow.findComponentById("labelWeight");
        const labelWeightIsPerPiece = tableRow.findComponentById("labelWeightIsPerPiece");
        const labelClass = tableRow.findComponentById("labelClass");
        const labelSpots = tableRow.findComponentById("labelSpots");
        const labelSpotsIsPerPiece = tableRow.findComponentById("labelSpotsIsPerPiece");
        const labelHandlingUnits = tableRow.findComponentById("labelHandlingUnits");
        const labelHandlingUnitsIsPerPiece = tableRow.findComponentById("labelHandlingUnitsIsPerPiece");
        const labelCubicUnits = tableRow.findComponentById("labelCubicUnits");
        const labelCubicUnitsIsPerPiece = tableRow.findComponentById("labelCubicUnitsIsPerPiece");
        const labelProductSKU = tableRow.findComponentById("labelProductSKU");

        if (tableRow.mode === TableRowMode.QUICK_ADD) textboxDescr.printable = true;

        if (tableRow.mode !== TableRowMode.SEARCH) {
            labelHazmat.caption = data.get("commodity.is_hazmat");
            labelHazmatNumber.caption = data.get("commodity.hazmat_number");
            if (getUnauthSettings()?.company_settings?.["is_brokerage_ltl"] == true) {
                labelType.caption = data.get("commodity.type_code");
                labelPackaging.caption = data.get("commodity.packaging_type_code");
                if (data.get("commodity.weight") != null) labelWeight.caption = Number(data.get("commodity.weight"))?.toFixed(2);
                labelWeightIsPerPiece.caption = (data.get("commodity.weight_is_per_piece") == "Y") ? "/Piece" : null;
                labelClass.caption = data.get("commodity.nmfc_class_code");
                if (data.get("commodity.req_spots") != null) labelSpots.caption = Number(data.get("commodity.req_spots"))?.toFixed(2);
                labelSpotsIsPerPiece.caption = (data.get("commodity.spots_is_per_piece") == "Y") ? "/Piece" : null;
                if (data.get("commodity.handling_units") != null) labelHandlingUnits.caption = Number(data.get("commodity.handling_units"))?.toFixed();
                labelHandlingUnitsIsPerPiece.caption = (data.get("commodity.handling_units_is_per_piece") == "Y") ? "/Piece" : null;
                if (data.get("commodity.cubic_units") != null) labelCubicUnits.caption = Number(data.get("commodity.cubic_units"))?.toFixed(2);
                labelCubicUnitsIsPerPiece.caption = (data.get("commodity.cubic_units_is_per_piece") == "Y") ? "/Piece" : null;
                labelProductSKU.caption = data.get("commodity.product_sku");
                labelProductSKU.tooltip = labelProductSKU.caption;
            }
        }

        textboxCommodityId.onSelectItem = (textboxCommodityId, selection) => {
            textboxDescr.text = textboxCommodityId.boundRow.getFirstLookupModelData("commodity_id")?.get("descr");
            if (textboxDescr.text != "") textboxDescr.enabled = false;
            data.set("descr", textboxDescr.text);

            if (tableRow.mode !== TableRowMode.SEARCH) {
                labelHazmat.caption = textboxCommodityId.boundRow.getFirstLookupModelData("commodity_id")?.get("is_hazmat");
                data.set("commodity.is_hazmat", labelHazmat.caption);

                labelHazmatNumber.caption = textboxCommodityId.boundRow.getFirstLookupModelData("commodity_id")?.get("hazmat_number");
                data.set("commodity.hazmat_number", labelHazmatNumber.caption);

                if (getUnauthSettings()?.company_settings?.["is_brokerage_ltl"] == true) {
                    labelType.caption = textboxCommodityId.boundRow.getFirstLookupModelData("commodity_id")?.get("type_code");
                    data.set("commodity.type_code", labelType.caption);

                    labelPackaging.caption = textboxCommodityId.boundRow.getFirstLookupModelData("commodity_id")?.get("packaging_type_code");
                    data.set("commodity.packaging_type_code", labelPackaging.caption);

                    labelWeight.caption = textboxCommodityId.boundRow.getFirstLookupModelData("commodity_id")?.get("weight");
                    data.set("commodity.weight", labelWeight.caption);

                    labelWeightIsPerPiece.caption = (textboxCommodityId.boundRow.getFirstLookupModelData("commodity_id")?.get("weight_is_per_piece") == "Y") ? "/Piece" : null;
                    if (labelWeightIsPerPiece.caption != null) data.set("commodity.weight_is_per_piece", "Y");

                    labelClass.caption = textboxCommodityId.boundRow.getFirstLookupModelData("commodity_id")?.get("nmfc_class_code");
                    data.set("commodity.nmfc_class_code", labelClass.caption);

                    labelSpots.caption = textboxCommodityId.boundRow.getFirstLookupModelData("commodity_id")?.get("req_spots");
                    data.set("commodity.req_spots", labelSpots.caption);

                    labelSpotsIsPerPiece.caption = (textboxCommodityId.boundRow.getFirstLookupModelData("commodity_id")?.get("spots_is_per_piece") == "Y") ? "/Piece" : null;
                    if (labelSpotsIsPerPiece.caption != null) data.set("commodity.spots_is_per_piece", "Y");

                    labelHandlingUnits.caption = textboxCommodityId.boundRow.getFirstLookupModelData("commodity_id")?.get("handling_units");
                    data.set("commodity.handling_units", labelHandlingUnits.caption);

                    labelHandlingUnitsIsPerPiece.caption = (textboxCommodityId.boundRow.getFirstLookupModelData("commodity_id")?.get("handling_units_is_per_piece") == "Y") ? "/Piece" : null;
                    if (labelHandlingUnitsIsPerPiece.caption != null) data.set("commodity.handling_units_is_per_piece", "Y");

                    labelCubicUnits.caption = textboxCommodityId.boundRow.getFirstLookupModelData("commodity_id")?.get("cubic_units");
                    data.set("commodity.cubic_units", labelCubicUnits.caption);

                    labelCubicUnitsIsPerPiece.caption = (textboxCommodityId.boundRow.getFirstLookupModelData("commodity_id")?.get("cubic_units_is_per_piece") == "Y") ? "/Piece" : null;
                    if (labelCubicUnitsIsPerPiece.caption != null) data.set("commodity.cubic_units_is_per_piece", "Y");

                    labelProductSKU.caption = textboxCommodityId.boundRow.getFirstLookupModelData("commodity_id")?.get("product_sku");
                    labelProductSKU.tooltip = labelProductSKU.caption;
                    data.set("commodity.product_sku", labelProductSKU.caption);
                }
            }

            return undefined;
        };
    }
}
