import { Button, DataSource, Layout, Panel } from "@mcleod/components";

export abstract class AutogenLayoutAssignedMovementActions extends Layout {
    buttonInitiateTracking: Button;
    buttonLoadTracking: Button;
    buttonSendDataFusionTender: Button;
    buttonSendRateCon: Button;
    panelButtons: Panel;
    sourceBrokerageTrackingCtrl: DataSource;
    sourceMovement: DataSource;
    sourceExtraPay: DataSource
}
