import { CommonDialogs } from "@mcleod/common";
import { BlurEvent, Component, DataSource, DataSourceMode, DataSourceModeChangeEvent, DropdownItem, Panel, Switch, TableRow, Textbox } from "@mcleod/components";
import { DatePart, DateUtil, ModelRow } from "@mcleod/core";
import { OrderStop } from "./OrderStop";

const DATE_ON: DropdownItem = { caption: "On", value: "O" };
const DATE_BETWEEN: DropdownItem = { caption: "Between", value: "B" };

export function checkAppointment(event: BlurEvent, orderStopLayout?: OrderStop) {
    const schedArriveTextbox: Textbox = event.target as Textbox;
    if (schedArriveTextbox.getRelevantModelRow()._appending !== true) { // Not in ADD Mode
        const tableRow: TableRow = TableRow.getContainingTableRow(event.target as Component); // get the table row if the event is from a table row
        const row: ModelRow = tableRow?.data ?? orderStopLayout.mainDataSource.activeRow;
        const originalData: ModelRow = row.originalData;
        const oldSchedArriveEarly = originalData["sched_arrive_early"];
        const oldSchedArriveLate = originalData["sched_arrive_late"];
        const datesChanged = checkChangedDates(row, originalData);
        const datesNotNull = row.get("sched_arrive_early") != null || row.get("sched_arrive_late") != null;
        const early: Textbox = tableRow?.findComponentById("textboxSchedArriveEarly") as Textbox ?? orderStopLayout.textboxSchedArriveEarly;
        const late: Textbox = tableRow?.findComponentById("textboxSchedArriveLate") as Textbox ?? orderStopLayout.textboxSchedArriveLate;
        const switchConfirmed: Switch = tableRow?.findComponentById("switchConfirmed") as Switch ?? orderStopLayout.switchConfirmed;
        const stopDateType: Textbox = tableRow?.findComponentById("textboxStopDateType") as Textbox ?? orderStopLayout.textboxStopDateType;
        // the isDropDownVisible() is accounting for when you change dates and the dropdown is visible; BlurEvents get triggered on those events as well
        if (!schedArriveTextbox.isDropdownVisible() && schedArriveTextbox.validateSimple() && (datesChanged && datesNotNull) && (orderStopLayout == null || orderStopLayout.validateApptChanges) && isApptConfirmed(row)) {
            if (schedArriveTextbox == late || schedArriveTextbox == early && stopDateType.text == DATE_ON.caption) {
                schedArriveTextbox.hideDropdown(false);
                if (orderStopLayout != null) {
                    // OrderStop Workflow
                    showAppointmentChangeDialog({ [schedArriveTextbox.field]: schedArriveTextbox == late ? oldSchedArriveLate : oldSchedArriveEarly }, orderStopLayout.sourceAppointmentChange, row, { early, late, switchConfirmed });
                } else {
                    // MultiStop Workflow
                    showAppointmentChangeDialog({ [schedArriveTextbox.field]: schedArriveTextbox == late ? oldSchedArriveLate : oldSchedArriveEarly }, null, row, { early, late, switchConfirmed }, tableRow);
                }
            }
            else if (schedArriveTextbox == early && stopDateType.text == DATE_BETWEEN.caption) {
                late.text = "";
            }
        }
    }
}

export function checkChangedDates(row: ModelRow, originalData: ModelRow): boolean {
    const schedArriveEarly = row.get("sched_arrive_early");
    const schedArriveLate = row.get("sched_arrive_late", null);
    const oldSchedArriveEarly = originalData["sched_arrive_early"];
    const oldSchedArriveLate = originalData["sched_arrive_late"];
    const arriveDiff = DateUtil.dateDiff(DatePart.SECOND, new Date(schedArriveEarly), new Date(oldSchedArriveEarly)) != 0;
    const lateDiff = DateUtil.dateDiff(DatePart.SECOND, new Date(schedArriveLate), new Date(oldSchedArriveLate)) != 0
    if (schedArriveLate == null && arriveDiff ||
        schedArriveLate != null && (lateDiff || arriveDiff)) {
        return true;
    }
    return false;
}

export async function showAppointmentChangeDialog(oldValues: any, sourceApptChange: DataSource, modelRow: ModelRow, dateComponents: Object = {}, tableRow?: TableRow) {
    const panel = new Panel({ id: "apptChangePanel" });
    const reasonCodeTextbox = new Textbox({
        required: true, id: "textboxReasonCode", caption: "Reason code",
        dataSource: sourceApptChange, field: "reason_code",
        lookupModel: "lme/dispatch/edi-partner-code", lookupModelLayout: "lme/dispatch/EdiPartnerCode",
        lookupModelDisplayField: "description", lookupModelResultField: "standard_code", lookupModelMinChars: 2
    }) as Textbox;

    reasonCodeTextbox.addBeforeLookupModelSearchListener(event => {
        event.filter.stop_id = modelRow.get("id");
        event.filter.code_type = "DelayReason";
        event.filter.use_enabled_flag = "Y";
    });

    const descriptionTextBox = new Textbox({ id: "textboxDescription", caption: "Description", dataSource: sourceApptChange, field: "descr" });
    const contactTextBox = new Textbox({ id: "textboxContact", caption: "Contact", dataSource: sourceApptChange, field: "contact" });
    panel.add(reasonCodeTextbox, descriptionTextBox, contactTextBox);

    if (sourceApptChange != null) {
        setSourceAppointmentChangeModeAdd(sourceApptChange);
    }

    const confirmed = await CommonDialogs.showYesNo(panel, "Change Appointment", { xVisible: false, noButtonCaption: "Cancel", yesButtonCaption: "OK" }, false, 100)

    const oldApptChangeData = sourceApptChange?.data;
    if (confirmed) {
        if (sourceApptChange == null) {
            // MultiStop Case
            const data = {
                stop_id: tableRow.data.get("id"),
                descr: descriptionTextBox.valueAsString,
                contact: contactTextBox.valueAsString,
                reason_code: reasonCodeTextbox.getFirstLookupModelData().get("standard_code", ""),
                sequence: tableRow.data.get("order_sequence"),
            }
            tableRow["appointmentChangeData"] = new ModelRow("lme/dispatch/appointment-change", false, data);
        } else {
            modelRow.set("appointment_change", sourceApptChange.activeRow);
        }
    } else {
        if (sourceApptChange == null) {
            // MultiStop Case
            revertApptChanges(oldValues, null, null, dateComponents["early"], dateComponents["late"], dateComponents["switchConfirmed"], modelRow);
        } else {
            // OrderStop Case
            revertApptChanges(oldValues, oldApptChangeData, sourceApptChange, dateComponents["early"], dateComponents["late"], dateComponents["switchConfirmed"], modelRow);
        }
    }

}

export function revertApptChanges(oldValues: any, oldApptChangeData: any, dataSource: DataSource, earlyTextbox: Textbox, lateTextbox: Textbox, switchConfirmed: Switch, modelRow?: ModelRow,) {
    const apptComps: Component[] = [earlyTextbox, lateTextbox, switchConfirmed];
    modelRow?.setValues(oldValues);
    apptComps.forEach(comp => {
        if (comp !== undefined)
            comp.displayData(modelRow, null, 0);
    });
    if (dataSource != null) {
        dataSource.activeRow.setValues(oldApptChangeData);
    }
}

export function isApptConfirmed(modelRow: ModelRow): boolean {
    return modelRow?.get("confirmed") == "Y";
}

export function apptConfirmCheck(changedData: any, modelRow: ModelRow): boolean {
    // Scenario 1: If confirmed was not set to Y initially and current confirmed status is Y, then post appointment_change record
    // Scenario 2: If confirmed was initially Y and set to N, check to see that current confirmed status is 'N'
    // Scenario 3: If confirmed was set to Y initially and only dates were changed
    // We have these scenarios to account for showing the appointment change dialog on dates after the user clicks confirm or
    // if the stop was already confirmed
    if ((changedData["confirmed"] != null && changedData["confirmed"] === "Y") && modelRow.get("confirmed") == 'Y') {
        // In Scenario 1, confirmed was not set to Y initially, thus we need to create another initial confirmation record showing it was set to confirm
        // This can be a blank appointment change record, a dialog does not need to be created;
        const initialAppointmentChangeData = {
            stop_id: modelRow.get("id"),
            descr: "",
            contact: "",
            reason_code: "",
            sequence: modelRow.get("order_sequence"),
            revised_early: modelRow.get("sched_arrive_early"),
            revised_late: modelRow.get("sched_arrive_late")
        };
        modelRow.set("initial_appointment_change", new ModelRow("lme/dispatch/appointment-change", false, initialAppointmentChangeData));
        return true;
    }
    // Scenario 2
    else if ((changedData["confirmed"] != null && changedData["confirmed"] === "N") && modelRow.get("confirmed") == 'N') {
        return true;
    }
    // Scenario 3
    else if (changedData["sched_arrive_late"] != null || changedData["sched_arrive_early"] != null && modelRow.get("confirmed") == 'Y') {
        return true;
    }
    return false;
}

export function setSourceAppointmentChangeModeAdd(sourceAppointmentChange: DataSource): Promise<Boolean> {
    return new Promise((resolve) => {
        if (sourceAppointmentChange.mode !== DataSourceMode.ADD) {
            sourceAppointmentChange.addAfterModeChangeListener((event: DataSourceModeChangeEvent) => {
                if (event.newMode == DataSourceMode.ADD)
                    resolve(true);
            });
            sourceAppointmentChange.mode = DataSourceMode.ADD;
        } else {
            resolve(true);
        }
    })
}
