import { Label, TableRowDisplayEvent } from "@mcleod/components";
import { AutogenLayoutEdiLtxExpressProfLookup } from "./autogen/AutogenLayoutEdiLtxExpressProfLookup";

export class EdiLtxExpressProfLookup extends AutogenLayoutEdiLtxExpressProfLookup {

    tableLookupOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        const label = tableRow.findComponentById("labelTitle") as Label;
        label.caption = tableRow.data?.get("title", tableRow.data?.get("id"));
    }
}
