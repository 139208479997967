import { Button, Checkbox, CityState, DataHeader, DataSource, HorizontalSpacer, Label, Layout, Location, Panel, SearchButton, Switch, Table, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutOrderList extends Layout {
	buttonClear: Button;
	buttonSearchExpand: Button;
	checkboxAvailable: Checkbox;
	checkboxDelivered: Checkbox;
	checkboxInProgress: Checkbox;
	checkboxVoid: Checkbox;
	citystateStopDestCityId: CityState;
	citystateStopOriginCityId: CityState;
	dataheaderOrdersList: DataHeader;
	horizontalspacer3: HorizontalSpacer;
	horizontalspacerLocation: HorizontalSpacer;
	horizontalspacerSearch: HorizontalSpacer;
	labelOrders: Label;
	panelConsigneeLocation: Panel;
	panelOrderListContainer: Panel;
	panelSearch: Panel;
	panelSearchHeader: Panel;
	panelShipperLocation: Panel;
	searchbuttonSearch: SearchButton;
	sourceOrdersList: DataSource;
	switchCheckHazmat: Switch;
	tableOrders: Table;
	textboxCustomerId: Textbox;
	textboxDeliveryDateRange: Textbox;
	textboxDestLocation: Location;
	textboxId: Textbox;
	textboxOrderTypeId: Textbox;
	textboxOriginLocation: Location;
	textboxPickupDateRange: Textbox;
	textboxReferenceNumbers: Textbox;
	textboxRevenueCodeId: Textbox;
	textboxStopDestAddress2: Textbox;
	textboxStopDestAddress: Textbox;
	textboxStopDestLocationId: Textbox;
	textboxStopDestLocationName: Textbox;
	textboxStopOriginAddress2: Textbox;
	textboxStopOriginAddress: Textbox;
	textboxStopOriginLocationId: Textbox;
	textboxStopOriginLocationName: Textbox;
}
