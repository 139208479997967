import { CommonDialogs } from "@mcleod/common";
import {
    Button, ClickEvent, Component, CrudDecorator, DataSourceAction, DataSourceExecutionEvent, DataSourceMode, DataSourceModeChangeEvent, Label, Layout, Panel,
    ParentSearchMode, TableRow, Textbox, Toast
} from "@mcleod/components";
import {
    Api, Currency, CurrencyUtil, getAuthSettings, getLogger, HorizontalAlignment, McLeodClassicIntegration, ModelRow,
    Navigation, PermissionsUtil, ProcessLock, StringUtil, UrlUtil, VerticalAlignment
} from "@mcleod/core";
import { CarrierRatings } from "@mcleod/powerbroker/src/CarrierRatings";
import { UsersResponsibilitySlideoutTrans } from "../../general/src/UsersResponsibilitySlideoutTrans";
import { CarrierAssignmentStepper } from "../../powerbroker/src/CarrierAssignmentStepper";
import { CarrierQuickInfo } from "../../powerbroker/src/CarrierQuickInfo";
import { MovementCallins } from "../../powerbroker/src/MovementCallins";
import { BRLTLOrder } from "../BrltlUtil";
import { AssignedMovementOverview } from "./AssignedMovementOverview";
import { AvailableMovementOverview } from "./AvailableMovementOverview";
import { CancelStopSelectionSlideout } from "./CancelStopSelectionSlideout";
import { DispatchSlideout } from "./DispatchSlideout";
import { DispatchUtil } from "./DispatchUtil";
import { Orders } from "./Orders";
import { StopList } from "./StopList";
import { StopProgress } from "./StopProgress";
import { AutogenLayoutMovement } from "./autogen/AutogenLayoutMovement";
import { RowMovement } from "./models/ModelMovement";
import { P44ShipmentTracking } from "../../powerbroker/src/P44ShipmentTracking";
import { calculateTextColor } from "@mcleod/core/src/Styles";

export const CLEARED_STATUS_CODE = "D";

const log = getLogger("lme.dispatch.Movement");
const statusDisplayValues = { A: "Available", C: "Covered", P: "Progress", V: "Void", D: "Delivered" };

export class Movement extends AutogenLayoutMovement {
    private static viewMovementURL = "lme/dispatch/Movement?mode=update&key=";
    isCarrierAssigned: boolean;
    private cancelling: boolean;
    private currentMovementId: number;
    private movementTabBusy: boolean = false;
    private currentTabId: string;
    private origBrkStatus = null;
    private callinRequiredBrkStatusCode: ModelRow;

    override onLoad() {
        this.panelDimensionsAndSurcharge.visible = getAuthSettings().dispatch_control[0].dimension_surchg == "Y";
        this.setupStopRelationship();
        this.textboxBrokerageStatus.addBeforeLookupModelSearchListener((event) => {
            if (this.sourceMovement.activeRow?.get("id"))
                event.filter = { movement_id: this.sourceMovement.activeRow.get("id") };
        });

        this.textboxBrokerageStatus.onSelectItem = (textbox, selectedItem) => {
            this.setTextboxBrokerageStatusColor((selectedItem as ModelRow).get("planning_color"));
            this.brokerageStatusOnChange();
            return textbox.text;
        }

        this.layoutCarrierRates.textboxMaxPayCalc.addBlurListener(event => {
            const textbox = event.target as Textbox;
            this.sourceMovement.activeRow.set("max_pay_calc", textbox.getDataValue())
            this.sourceMovement.post();
        })

        this.layoutCarrierRates.layoutTargetPay.addLayoutLoadListener(() => {
            this.layoutCarrierRates.layoutTargetPay.textboxTargetPayCalc.addBlurListener(async event => {
                const originalTargetPay = this.mainDataSource.activeRow.originalData["override_targetpay"] as Currency;
                const currentTargetPay = this.mainDataSource.activeRow.get("override_targetpay", null) as Currency; // "base_amount" always null
                if (originalTargetPay != null)
                    originalTargetPay["base_amount"] = null; // setting original "base_amount" to null to force comparison based on "amount"
                if (CurrencyUtil.compareCurrencys(originalTargetPay, currentTargetPay) != 0) {
                    const textbox = event.target as Textbox;
                    this.sourceMovement.activeRow.set("target_pay_calc", textbox.getDataValue());
                    await this.sourceMovement.post();
                }
            })
        });

        this.layoutTopMatchList.textboxOrderId.visible = false;
        this.layoutTopMatchList.textboxMovementId.visible = false;
        this.layoutTopMatchList.labelPageTitle.visible = false;

        (this.layoutStopProgress as StopProgress).setStopsTable((this.layoutStopList as StopList).tableStop);

        if (history.state?.state?.findCarrier === true) {
            this.addLayoutDataLoadListener(event => {
                this.tabsetMain.scrollToTab(this.tabTopMatch, 2000);
                this.tabTopMatch.select();
            });
        }
    }

    public get isAsset(): boolean {
        return this.activeRow.get("brokerage") === "N";
    }

    setTextboxBrokerageStatusColor(color: any) {
        if (color != null) {
            this.textboxBrokerageStatus.backgroundColor = color;
            this.textboxBrokerageStatus.color = calculateTextColor(color);
        } else {
            this.textboxBrokerageStatus.backgroundColor = "default.reverse";
            this.textboxBrokerageStatus.color = "default";
        }
    }

    brokerageStatusOnChange() {
        const row = this.mainDataSource.activeRow;
        if (row.get("brokerage_status") !== null && row.get("brokerage_status") !== row["originalData"]["brokerage_status"]) {
            const lmData = row.getFirstLookupModelData("brokerage_status");
            row.set("brokerage_status_color", lmData?.get("planning_color"));
            if (lmData != null && lmData.getBoolean("display_callin_screen", false)) {
                this.callinRequiredBrkStatusCode = row.getFirstLookupModelData("brokerage_status", null);
                this.buttonDispatch.clicked();
            }
        }
    }

    private unclearStops() {
        this.cancelling = true;
        return Api.search("lme/dispatch/unclear-stops",
            {
                "movement_id": this.sourceMovement.activeRow.get("id"),
                "leave_carrier_assigned": true
            }).then(response => {
            this.sourceMovement.search({ id: this.sourceMovement.activeRow.get("id") });
            Toast.showSuccessToast("Your movement was cancelled successfully");
        }).catch(error => CommonDialogs.showError(error));
    }

    sourceMovementAfterModeChange(event: DataSourceModeChangeEvent) {
        if (event.oldMode === DataSourceMode.NONE && event.newMode === DataSourceMode.UPDATE) {
            this.enforceActionPermissions();
        }
     }

     enforceActionPermissions(component?: Layout | TableRow) {
         const isUpdateMovementsAfterDeliveredDenied = PermissionsUtil.isUserDeniedAction("Dispatch.Update movements after delivery");
         const isDelivered = this.activeRow?.get("status", null) === "D";

         if ((isUpdateMovementsAfterDeliveredDenied == true && isDelivered === true)) {
             this.layoutAssignedOverview.tabsetCarrierDriver.forEveryChildComponent((component: Component) => {
                 component.enabled = false;
             });
             this.layoutAssignedOverview.movementActions.buttonInitiateTracking.enabled = false;
         }
     }

     private setComponentVisibility() {
        this.tabRates.visible = !this.isCarrierAssigned && this.activeRow.getBoolean("brokerage");
        this.tabTopMatch.visible = !this.isCarrierAssigned && !this.activeRow.getBoolean("is_brokerage_ltl") && this.activeRow.getBoolean("brokerage");
        this.layoutAvailableOverview.visible = !this.isCarrierAssigned;
        if (this.layoutAvailableOverview.visible) {
            this.layoutAvailableOverview.layoutOffers.visible = !this.isAsset;
            this.layoutAvailableOverview.panelOfferHistory.visible = !this.isAsset;
            this.layoutAvailableOverview.panelTargetPay.visible = !this.isAsset;
            this.layoutAvailableOverview.labelDistance.visible = !this.isAsset;
            this.layoutAvailableOverview.labelMoveDistance.visible = !this.isAsset;
        }

        this.layoutAssignedOverview.visible = this.isCarrierAssigned;
        if (this.layoutAssignedOverview.visible) {
            this.layoutAssignedOverview.tabsetCarrierDriver.visible = !this.isAsset;
        }
    }

    public override getDataHeaderEllipsisActions(): Label[] {
        const result: Label[] = []
        if (this.mainDataSource?.activeRow == null || this.mainDataSource.mode !== DataSourceMode.UPDATE)
            return result;
        const moveStatus = this.mainDataSource.activeRow.get("status");
        const payeeId = this.mainDataSource.activeRow.get("override_payee_id");
        if (!this.isAsset && (moveStatus == "P" || moveStatus == "D"))
            result.push(new Label({ caption: "Rollback Stops", wrap: false, onClick: (event) => this._rollbackStops() }));
        if (!this.isAsset && (moveStatus == "C" || moveStatus == "P" || moveStatus == "D" || payeeId != null))
            result.push(new Label({
                caption: "Remove Carrier", wrap: false, onClick: async (event) => {
                    const isUpdateMovementsAfterDeliveredDenied = PermissionsUtil.isUserDeniedAction("Dispatch.Update movements after delivery");
                    const isDelivered = this.activeRow?.get("status", null) === "D";
                    if (isUpdateMovementsAfterDeliveredDenied == true && isDelivered === true) {
                        CommonDialogs.showDialog("You do not have permission to remove carriers from delivered movements.");
                        return;
                    }
                    this.layoutAssignedOverview.movementActions.removeCarrier(this.mainDataSource?.activeRow);
                }
            }));
        return result;
    }

    private _rollbackStops() {
        const isUpdateMovementsAfterDeliveredDenied = PermissionsUtil.isUserDeniedAction("Dispatch.Update movements after delivery");
        const isDelivered = this.activeRow?.get("status", null) === "D";
        if (isUpdateMovementsAfterDeliveredDenied == true && isDelivered === true) {
            CommonDialogs.showDialog("You do not have permission to rollback delivered movements.");
            return;
        }
        const stopCount: number = (this.layoutStopList as StopList)?.tableStop?.rowCount;
        const status: string = this.mainDataSource?.activeRow.get("status");
        if (status == "D" || stopCount > 2) {
            const slideout = new CancelStopSelectionSlideout(this.mainDataSource?.activeRow.get("id"));
            slideout.showSlideout((canceled: boolean) => {
                if (!canceled)
                    this.sourceMovement.search({ id: this.sourceMovement.activeRow.get("id") });
            });
        } else {
            CommonDialogs.showYesNo("Are you sure you want unclear the pickup and retain the carrier?").then(clickedYes => {
                if (clickedYes) {
                    if (this.cancelling) return Promise.resolve();
                    return this.unclearStops();
                }
            });
        }
    }

    private goToStopDetails() {
        this.tabsetMain.scrollToTab(this.tabOrderDetails);
    }

    private setupStopRelationship() {
        const sourceStops = this.layoutStopList.mainDataSource;
        sourceStops.parentDataSource = this.sourceMovement;
        sourceStops.parentSearchMode = ParentSearchMode.onParentDisplay;
        sourceStops.preventChangeNotifications = true;
        sourceStops.addDisplayListener(event => {
            if (this.isCarrierAssigned)
                (this.layoutAssignedOverview as AssignedMovementOverview).setStops(sourceStops.data);
            else {
                const availableOverview = this.layoutAvailableOverview as AvailableMovementOverview;
                const stopCountText = sourceStops.summaryData?.get("stop_count_text");
                availableOverview.stopCountText = stopCountText;
            }
        });
    }

    /** This is an event handler for the afterExecution event of sourceMovement.  */
    async sourceMovementAfterExecution(event: DataSourceExecutionEvent) {
        if (event?.getAction() == "search") {
            const custId = event.dataSource?.data[0]?.get("orders.customer_id");
            if (custId != null)
                this.panelCustomerInfo.dataSource.search({ id: custId });
            if (this.mainDataSource.activeRow != null) {
                this.isCarrierAssigned = this.mainDataSource.activeRow.get("override_payee_id") != null;
                this.setComponentVisibility();
                (this.layoutMovementCallins as MovementCallins).movementId = this.activeRow?.get("id");
                this.setTextboxBrokerageStatusColor(this.mainDataSource.activeRow.get("brokerage_status_color"));
                this.origBrkStatus = this.activeRow.get("brokerage_status", null);
                this.layoutTopMatchList.setDefaultsFromDataSource().then(() => {
                    this.tabsetMain.addAfterTabSelectionListener(() => {
                        if (!StringUtil.isEmptyString(getAuthSettings().user_settings.tm_profile) &&
                            this.layoutTopMatchList.layoutTopMatch.activeRow == null &&
                            !this.layoutTopMatchList.layoutTopMatch.sourceTopMatch.isSearching) {
                            this.layoutTopMatchList.search();
                        }
                    });
                });
                this.tabTracking.visible = this.mainDataSource.activeRow.get("status", null) != "A" && !this.activeRow.getBoolean("is_brokerage_ltl");
                if (this.isAsset) {
                    this.tabRates.visible = false;
                    this.tabTopMatch.visible = false;
                    this.layoutAvailableOverview.layoutOffers.visible = false;
                    this.tabTracking.visible = false;
                    this.layoutAssignedOverview.tabsetCarrierDriver.visible = false;
                }
            }
            this.dataHeader.syncTitle();
        } else if (event?.getAction() === DataSourceAction.UPDATE) {
            const brkStatus = this.activeRow.get("brokerage_status", null);
            if (!brkStatus != null && brkStatus !== this.origBrkStatus)
                this.showCarrierRatingSlideout();
        }
        this.labelPendingAssignment.visible = this.mainDataSource.activeRow.getBoolean("has_pending_assignment", false);
        if (this.labelPendingAssignment.visible) {
            this.setTooltipPendingLabelCallback(this.labelPendingAssignment, this.mainDataSource.activeRow);
        }
        if (history.state != null) {
            if (history.state.state?.assignCarrier == true) {
                this.assignCarrierSlideout(this.buttonAssignCarrier, () => Orders.navigateTo(this.mainDataSource.activeRow.get("movement_order.order_id"), false));
                history.state.state = undefined;
            }
        }

        const orderOnHold = event.dataSource?.data[0]?.getBoolean("orders.on_hold");
        await DispatchUtil.isAssignable(event.dataSource?.data[0], this.buttonAssignCarrier, this.buttonDispatch);

        if (orderOnHold) {
            this.labelHoldBadge.visible = true;
            this.labelHoldBadge.tooltip = event.dataSource?.data[0]?.get("orders.hold_reason");
        }
        else {
            this.labelHoldBadge.visible = false;
        }

        if (this.currentMovementId == null)
            this.setupMovementTabController();
        else {
            this.updateMovementTab(this.currentMovementId);
        }

        this.movementTabBusy = false;
    }

    async setupMovementTabController() {
        const order_id = this.mainDataSource.activeRow.get("movement_order.order_id");
        const response = await Api.post("lme/dispatch/order-movements", { "order_id": order_id })
        if (response.data.length > 1) {
            const movements = response.data;
            const urlParams = UrlUtil.getPropsFromUrl(window.location.href);
            const urlId = urlParams["key"];
            const isMovement = movements.some(movement => movement.id === urlId);
            this.currentMovementId = isMovement ? urlId : this.currentMovementId ?? this.mainDataSource.activeRow.get("orders.curr_movement_id", "");
            this.initializeMovementTabs(movements);
            this.panelMovementStatus.visible = false;
            this.labelYsplitBadge.visible = true;
        }
    }

    initializeMovementTabs(movements: RowMovement[]) {
        movements.forEach(movement => {
            const movementTab = this.buildMovementTab(this.currentMovementId, movement);
            movementTab.addClickListener((event) => this.movementTabOnClick(movement))
            this.panelMovementTabController.add(movementTab);
        })
    }

    getBRLTLData() {
        const orderData: BRLTLOrder = {
            order_type_id: this.activeRow.get("orders.order_type_id"),
            revenue_code_id: this.activeRow.get("orders.revenue_code_id"),
            order_mode: this.activeRow.get("orders.order_mode")
        }
        return orderData
    }

    buildMovementTab(curr_movement_id, movement): Panel {
        const movementRow = new ModelRow("lme/dispatch/movement", false, movement);
        const panelProps = {
            id: "movementTab" + movement.id,
            minWidth: 275, rowBreak: false, marginRight: 10,
            padding: 0, backgroundColor: movement.id == curr_movement_id ? "default.reverse" : "subtle.lightest",
            borderTopWidth: 1, borderTopColor: "default.lightest", borderTopLeftRadius: 6, borderTopRightRadius: 6,
            borderRightWidth: 1, borderRightColor: "default.lightest", borderBottomWidth: 0,
            borderLeftWidth: 1, borderLeftColor: "default.lightest",
        };
        const label = new Label({ caption: "Movement " + (movement.first_stop + " - " + movement.last_stop), fontBold: true, fontSize: 16, height: 50, padding: 10, marginRight: 10 });
        label.rowBreak = false
        const statusLabel = new Label({ id: "statusLabel" + movement.id, caption: statusDisplayValues[movement.status], color: "primary", borderWidth: 1, borderColor: "primary", borderRadius: 18, minWidth: 100 })
        statusLabel.align = HorizontalAlignment.CENTER;
        statusLabel.rowBreak = false;
        label.verticalAlign = VerticalAlignment.CENTER;
        const pendingLabel = this.createPendingLabel(movement.id);
        const newMovementTab = new Panel(panelProps);
        newMovementTab.add(label);
        newMovementTab.add(statusLabel);
        if (movement["pending_payee_id"] || movement["pending_carrier_nbr"]) {
            this.setTooltipPendingLabelCallback(pendingLabel, movementRow);
            newMovementTab.add(pendingLabel);
        }
        newMovementTab.zIndex = curr_movement_id == movement.id ? 10 : 0;
        this.currentTabId = curr_movement_id;
        return newMovementTab;
    }

    createPendingLabel(movement_id: number) {
        const pendingLabel = new Label({ id: "pendingLabel" + movement_id, caption: "Pending", backgroundColor: "warning", color: "warning.reverse", borderWidth: 1, borderRadius: 18, marginLeft: 10, marginRight: 10, paddingLeft: 20, paddingRight: 20, width: 110, fontBold: true });
        pendingLabel.align = HorizontalAlignment.CENTER;
        return pendingLabel
    }

    async updateMovementTab(movement_id: number) {
        const movementTab = this.panelMovementTabController.findComponentById("movementTab" + movement_id) as Panel;
        const statusLabel = movementTab.findComponentById("statusLabel" + movement_id) as Label;
        statusLabel.caption = this.labelMovementStatus.caption;
        const isPending = this.mainDataSource.activeRow.get("has_pending_assignment", false);
        const pendingLabel = movementTab.findComponentById("pendingLabel" + movement_id) as Label;
        if (!pendingLabel && isPending) {
            const newPendingLabel = this.createPendingLabel(movement_id);
            this.setTooltipPendingLabelCallback(newPendingLabel, this.mainDataSource.activeRow);
            movementTab.add(newPendingLabel);
        } else if (pendingLabel && !isPending) {
            movementTab.remove(pendingLabel);
        }
    }

    movementTabOnClick(movement) {
        if (this.currentTabId != movement.id) {
            const movementTab = this.panelMovementTabController.findComponentById("movementTab" + movement.id) as Panel;
            const oldTab = this.panelMovementTabController.findComponentById("movementTab" + this.currentTabId) as Panel;
            movementTab.backgroundColor = "default.reverse";
            movementTab.zIndex = 10;
            oldTab.backgroundColor = "subtle.lightest";
            oldTab.zIndex = 0;
            this.supressPendingBadgeTooltip(movement.id, movementTab, oldTab);
            this.currentTabId = movement.id;
            this.handleMovementTabChange(movement);
        }
    }

    handleMovementTabChange(movement) {
        if (!this.movementTabBusy) {
            this.movementTabBusy = true;
            this.currentMovementId = movement.id;
            this.mainDataSource.search({ id: movement.id });
        }
    }

    updateButton(button: Button, status: boolean, message?: string) {
        button.enabled = status;
        if (status === false) {
            button.disabledTooltip = message;
        }
    }

    buttonAssignCarrierOnClick(event: ClickEvent) {
        const modelRow = this.mainDataSource.activeRow;
        if (modelRow == null)
            return;
        ProcessLock.aquireLock("Carrier dispatch", modelRow.get("id")).then(lockResult => {
            if (lockResult.success === true)
                this.assignCarrierSlideout(event.target as Button);
            else
                CommonDialogs.showDialog(lockResult.message, { title: "Assignment in Progress" });
        });
    }

    private assignCarrierSlideout(carrierAssignBtn: Button, onCancel?: () => void) {
        CarrierAssignmentStepper.showAsSlideout({
            movementId: this.mainDataSource.activeRow?.get("id"),
            orderId: this.mainDataSource.activeRow?.get("movement_order.order_id"),
            carrierAssignmentButton: carrierAssignBtn,
            onClose: (canceled) => {
                const movementId = this.sourceMovement.activeRow.get("id");
                const isEdi: boolean = this.sourceMovement.owner.isEdi;

                if (!canceled) {
                    isEdi ? Navigation.navigateTo("lme/datafusion/LoadTenderExpress") : this.sourceMovement.search({ id: movementId });
                }
                else if (onCancel)
                    onCancel();


                ProcessLock.releaseLock("Carrier dispatch", movementId);
            }
        });
    }

    async buttonDispatchOnClick(event: ClickEvent) {
        const modelRow = this.mainDataSource.activeRow;
        if (modelRow == null)
            return;

        const button = event.target as Button;

        button.busy = true;
        const isBrokerageLtlOrder = modelRow.getBoolean("is_brokerage_ltl");
        const dispatchType = (await Api.post("lme/dispatch/movement-dispatch-type", { movement_id: modelRow.get("id") })).data[0]?.["dispatch_type"];
        await (isBrokerageLtlOrder && StringUtil.equalsIgnoreCase(dispatchType, "P44") ? P44ShipmentTracking : DispatchSlideout).showSlideout(modelRow.get("id"), {
            movementOrId: modelRow.get("id"),
            orderId: modelRow.get("orders.id"),
            customerId: modelRow.get("orders.customer_id"),
            orderOnHold: modelRow.getBoolean("orders.on_hold", false),
            originCity: modelRow.get("stop_origin.city_name"),
            originState: modelRow.get("stop_origin.state"),
            destinationCity: modelRow.get("stop_dest.city_name"),
            destinationState: modelRow.get("stop_dest.state"),
            callinRequiredBrkStatusCode: this.callinRequiredBrkStatusCode,
            doOnSlideoutClosed: (cancelled: boolean) => {
                if (!cancelled) {
                    const id = this.mainDataSource.activeRow.get("id");
                    this.search({ id: id });
                    this.layoutMovementCallins.layoutRecentCallins.mainDataSource.search({ movement_id: id });
                } else if (modelRow.getFirstLookupModelData("brokerage_status", null) !== null) {
                    const originalData = this.mainDataSource.activeRow.originalData;
                    const lmBrokerageStatusData = {
                        descr: originalData["_lookup_brokerage_status"][0]["descr"] ?? originalData["_lookup_brokerage_status"][0]["_data"]["descr"],
                        id: originalData["_lookup_brokerage_status"][0]["id"] ?? originalData["_lookup_brokerage_status"][0]["_data"]["id"]
                    }
                    this.mainDataSource.activeRow.set("_lookup_brokerage_status", lmBrokerageStatusData);
                    this.mainDataSource.activeRow.set("brokerage_status", originalData["brokerage_status"]);
                    this.mainDataSource.activeRow.set("brokerage_status_color", originalData["brokerage_status_color"]);
                    this.setTextboxBrokerageStatusColor(this.mainDataSource.activeRow.get("brokerage_status_color"));
                }
            }
        }).finally(() => button.busy = false);
    }


    showCarrierRatingSlideout() {
        const triggerCode = getAuthSettings().dispatch_control[0].rating_trigger_code;
        if (!StringUtil.isEmptyString(triggerCode) && triggerCode === this.activeRow.get("brokerage_status")) {
            CarrierRatings.show({
                carrierRatingHeaderData: {
                    order_id: this.activeRow.get("movement_order.order_id"),
                    movement_id: this.activeRow.get("id"),
                    payee_id: this.activeRow.get("payee.id"),
                },
                carrier_name: this.activeRow.get("payee.name")
            });
        }
    }

    private supressPendingBadgeTooltip(newMovementId: string, newTab: Panel, oldTab: Panel) {
        const newTabPendingLabel = newTab.findComponentById("pendingLabel" + newMovementId);
        const oldTabPendingLabel = oldTab.findComponentById("pendingLabel" + this.currentTabId);
        if (newTabPendingLabel)
            newTabPendingLabel.suppressTooltip = false;
        if (oldTabPendingLabel)
            oldTabPendingLabel.suppressTooltip = true;
    }

    async setTooltipPendingLabelCallback(pendingLabel: Label, modelRow: ModelRow) {
        const pendingTooltipData = await this.getPendingBadgeTooltipData(modelRow);
        if (pendingTooltipData) {
            pendingLabel.tooltipCallback = () => {
                return pendingLabel.showTooltip(() => this.getPendingBadgeLayout(pendingTooltipData), null, { themeKey: "quickInfo", color: null })
            }
        }
    }

    async getPendingBadgeTooltipData(modelRow: ModelRow) {
        let tooltipData = null;
        if (modelRow.isNull("pending_payee_id")) {
            const pendingCarrierType = modelRow.get("pending_carrier_type");
            const pendingCarrierNumber = modelRow.get("pending_carrier_nbr");
            tooltipData = new ModelRow("lme/powerbroker/carriers-for-assignment", false, {
                movement_id: modelRow.get("id"),
                locked_user: modelRow.get("locked_user_id"),
                name: modelRow.get("pending_carrier_name"),
                pending_carrier: true,
                ...(pendingCarrierType == "M" ? { icc_number: pendingCarrierNumber } : {}),
                ...(pendingCarrierType == "D" ? { dot_number: pendingCarrierNumber } : {}),
                ...(pendingCarrierType == "I" ? { intrastate_code: pendingCarrierNumber } : {})
            })
        } else {
            const result = await Api.search("lme/powerbroker/carriers-for-assignment", { id: modelRow.get("pending_payee_id") })
            const payeeData = result?.data[0];
            tooltipData = new ModelRow("lme/powerbroker/carriers-for-assignment", false, {
                movement_id: modelRow.get("id"),
                locked_user: modelRow.get("locked_user_id"),
                name: payeeData.name,
                phone_number: payeeData.phone_number ?? "",
                icc_number: payeeData.icc_number,
                dot_number: payeeData.dot_number,
                intrastate_code: payeeData.intrastate_code,
                pending_carrier: false,
                id: payeeData.id
            });
        }
        return tooltipData;
    }

    getPendingBadgeLayout(modelRow: ModelRow): Layout {
        const layout = Layout.getLayout("lme/powerbroker/CarrierQuickInfo") as CarrierQuickInfo;
        layout.addLayoutLoadListener(event => {
            (event.target as Layout).mainDataSource.data = [modelRow];
            layout.mainDataSource.rowIndex = 0;
        });
        layout.pendingCancelled = (lock_changed, user_can_unlock) => {
            if (lock_changed && user_can_unlock) {
                this.mainDataSource.search({ id: this.mainDataSource.activeRow.get("id") });
            }
        }
        return layout;
    }

    displayMovementUsersSlideout() {
        const urs = new UsersResponsibilitySlideoutTrans("lme/dispatch/MovementUsers", this.getMovementUsersSlideoutTitle(), this.getMovementUsersIdFilter(),
            this.getMovementUsersSharedFieldNames(), this.sourceMovement, this.sourceResponsibleHist);
        urs.show();
    }

    private getMovementUsersSlideoutTitle(): string {
        const name = this.sourceMovement.activeRow?.get("id");
        return "Movement Users" + ((StringUtil.isEmptyString(name) === false) ? (" - " + name) : "");
    }

    private getMovementUsersIdFilter(): any {
        const id = this.sourceMovement.activeRow?.get("id");
        if (id != null)
            return { id: id };
        return null;
    }

    private getMovementUsersSharedFieldNames(): string[] {
        return [];
    }

    /** This is an event handler for the onClick event of buttonOrderDetails.  */
    buttonOrderDetailsOnClick(event: ClickEvent) {
        const orderPanel = new CrudDecorator({
            layout: Layout.getLayout("lme/dispatch/Orders") as Orders,
            mode: DataSourceMode.UPDATE,
            key: this.mainDataSource?.activeRow.get("movement_order.order_id"),
            headerProps: {
                showClose: true,
                showSaveAndClose: true,
                onExecute: (row: ModelRow<any>) => {
                    this.sourceMovement.search({ id: this.sourceMovement.activeRow.get("id") })
                }
            }
        });
        orderPanel.slideIn({ speed: 200 });
    }

    buttonClassicModeOnClick(event: ClickEvent) {
        McLeodClassicIntegration.openClassicScreen("com.tms.client.loadmaster.dsp.EntryMovement", this.sourceMovement.activeRow?.get("id"));
    }

    public static navigateTo(id: string): void {
        Navigation.navigateTo(this.viewMovementURL + id, { newTab: true });
    }

}
