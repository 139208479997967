import { Collection, Color, Keys, StringUtil, getLogger, getThemeColor, makeStyles } from "@mcleod/core";
import { MouseListener } from "../..";
import { BorderType } from "../../base/BorderType";
import { Component } from "../../base/Component";
import { ComponentListenerDefs } from "../../base/ComponentListenerDefs";
import { ComponentTypes } from "../../base/ComponentTypes";
import { Cursor } from "../../base/Cursor";
import { ListenerListDef } from "../../base/ListenerListDef";
import { SelectionMode } from "../../base/SelectionMode";
import { DomEvent } from "../../events/DomEvent";
import { KeyEvent } from "../../events/KeyEvent";
import { SelectionEvent, SelectionListener } from "../../events/SelectionEvent";
import { StringOrPropsOrComponent, getComponentFromStringOrPropsOrComponent } from "../../page/getComponentFromStringOrPropsOrComponent";
import { Panel } from "../panel/Panel";
import { ListPropDefinitions, ListProps } from "./ListProps";

const _changeListenerDef: ListenerListDef = { listName: "_changeListeners" };
const handledKeys = [Keys.ARROW_DOWN, Keys.ARROW_UP, Keys.PAGE_DOWN, Keys.PAGE_UP, Keys.HOME, Keys.END];
const classes = makeStyles("list", {
    root: {
        outline: "none"
    }
});

const log = getLogger("components.list.List");

type ItemType = StringOrPropsOrComponent[] | ((list: List) => StringOrPropsOrComponent[]);

export class List extends Component implements ListProps {
    public static SEPARATOR = "__list_separator";
    private _items: ItemType = [];
    private _filteredItems: ItemType;
    private _filter: string;
    private _selectedIndexes: number[];
    private _itemComps: Component[];
    private _scrollY: boolean;
    private selectedBackgroundColor: Color = "list.selection.base";
    private selectedColor: Color = "list.selection.reverse";
    public selectOnEnter: boolean;
    private _selectionMode: SelectionMode;

    constructor(props: Partial<ListProps>) {
        super("div", props);
        this._element.classList.add(classes.root);
        this._element.tabIndex = 0;
        this._selectedIndexes = [];
        this._itemComps = [];
        this.setProps(props);
        this._element.onkeydown = (event) => this._internalKeydown(event);
        if (props == null || props.scrollY === undefined)
            this.scrollY = true;
        this.backgroundColor ??= getThemeColor("defaultBackground")
        this.addMountListener(() => this.scrollToSelection());
    }

    setProps(props: Partial<ListProps>) {
        super.setProps(props);
    }

    _internalKeydown(event) {
        if (this.sendKey(event)) {
            event.preventDefault();
            event.stopPropagation();
        }
        // if (this.onKeyDown != null)
        // this._handleElementEvent("onKeyDown", this.onKeyDown, event);
    }

    _setDesigner(value: any) {
        if (value != null && value.addDesignerContainerProperties != null)
            value.addDesignerContainerProperties(this, 80, 48, null, () => false);
    }


    private buildItems() {
        const items = this.resolveItems();
        this._itemComps = [];
        this._element.innerHTML = "";
        for (const item of items)
            this._internalAddItem(item);
    }

    private _internalAddItem(item: StringOrPropsOrComponent): Component {
        const comp = item === List.SEPARATOR ? this.createSeparator() :
            getComponentFromStringOrPropsOrComponent(item, { cursor: Cursor.POINTER, paddingLeft: 8, paddingRight: 12 });
        if (StringUtil.isEmptyString(this._filter) || comp.containsText(this._filter)) {
            // we are tacking list information on that isn't part of a generic Component, so we cast as any.  Better patterns are welcome.
            (comp as any).originalColor ??= comp.color ?? this.color;
            (comp as any).originalBackgroundColor ??= comp.backgroundColor ?? this.backgroundColor;
            (comp as any).listIndex = this._itemComps.length;
            if (comp.hasListeners(ComponentListenerDefs.click) !== true)
                comp.addClickListener(event => this.fireSelect((comp as any).listIndex, event.domEvent));
            if (comp instanceof Component) {
                comp.addMouseEnterListener(() => {
                    if (this.selectionMode !== SelectionMode.NONE && (comp as any).selectable !== false) {
                        this.selectedIndex = (comp as any).listIndex;
                        const listener: MouseListener = (event) => { this.selectedIndex = -1; }
                        listener.runsOnce = true;
                        comp.addMouseLeaveListener(listener);
                    }
                });
            }
            (comp as any).item = item;
            this._itemComps.push(comp);
            this._element.appendChild(comp._element);
        }
        return comp;
    }

    createSeparator(): Panel {
        const result = new Panel({ height: 1, borderBottomColor: "subtle.lighter", borderBottomWidth: 1, borderBottomType: BorderType.SOLID });
        (result as any).selectable = false;
        return result;
    }

    fireSelect(index: number, domEvent: DomEvent) {
        this.fireListeners(_changeListenerDef, () => new SelectionEvent(this, this.getItem(index), index, this.getItem(index), index, domEvent));
    }

    relativeSelect(offset) {
        const max = this._itemComps.length - 1;
        let index = this.selectedIndex + offset;
        while (index >= 0 && index <= max) {
            if ((this._itemComps[index] as any).selectable === false)
                index += offset > 0 ? 1 : -1;
            else
                break;
        }
        if (index >= 0 && index <= max)
            this.selectedIndex = index;
    }

    filter(value: string) {
        this._filter = value;
        this.buildItems();
    }

    resolveItems(): StringOrPropsOrComponent[] {
        if (typeof this._items === "function")
            return this._items(this);
        else
            return this._items;
    }

    get filteredItems() {
        if (this._filteredItems == null)
            return this.items;

        return this._filteredItems;
    }

    sendKey(event: KeyEvent | KeyboardEvent) {
        const code = event.key;
        log.debug("code is " + code);
        log.debug("selectOnEnter is " + this.selectOnEnter);
        if (handledKeys.includes(code)) {
            if (code === Keys.ARROW_DOWN)
                this.relativeSelect(1);
            else if (code === Keys.ARROW_UP)
                this.relativeSelect(-1);
            else if (code === Keys.PAGE_UP)
                this.relativeSelect(-5);
            else if (code === Keys.PAGE_DOWN)
                this.relativeSelect(5);
            else if (code === Keys.HOME)
                this.selectedIndex = 0;
            else if (code === Keys.END)
                this.selectedIndex = this.items.length - 1;
            return true;
        }
        else if (code === Keys.ENTER && this.selectOnEnter) {
            log.info("in selectOnEnter block");
            const domEvent = event instanceof KeyEvent ? event.domEvent : event;
            this.fireSelect(this.selectedIndex, domEvent);
            return true;
        }
        return false;
    }

    search(value) {
        for (let i = 0; i < this._itemComps.length; i++) {
            const item = (this._itemComps[i] as any).item
            if (item.startsWith != null && item.startsWith(value)) { // need to handle non-string items
                this.selectedIndex = i;
                return true;
            }
        }
        return false;
    }

    get items(): ItemType {
        return this._items;
    }

    set items(value: ItemType) {
        this._items = value;
        this.buildItems();
    }

    public addSeparator() {
        this.addItem(List.SEPARATOR);
    }

    addItem(item: StringOrPropsOrComponent): Component {
        if (typeof this._items === "function")
            throw new Error("Cannot add items to a List whose items are a function.");
        this._items.push(item);
        return this._internalAddItem(item);
    }

    removeItem(item: StringOrPropsOrComponent) {
        if (typeof this._items === "function")
            throw new Error("Cannot remove items from a List whose items are a function.");
        const index = this._items.indexOf(item);
        this._items.splice(index, 1);
        const comp = this._itemComps[index];
        this._itemComps.splice(index, 1);
        for (let i = index; i < this._itemComps.length; i++)
            (this._itemComps[i] as any).listIndex--;
        this._element.removeChild(comp._element);
    }

    get selectedIndex(): number {
        if (this.selectedIndexes.length === 0)
            return -1;
        else
            return this.selectedIndexes[0];
    }

    set selectedIndex(value: number) {
        if (value < 0)
            this.selectedIndexes = [];
        else
            this.selectedIndexes = [value];
    }

    get selectedIndexes(): number[] {
        return this._selectedIndexes;
    }

    set selectedIndexes(value: number[]) {
        if (this.selectionMode !== SelectionMode.NONE)
            for (const index of this._selectedIndexes)
                if (!value.includes(index)) {
                    this.updateItemColor(index, false)
                }
        this._selectedIndexes = value;
        if (this.selectionMode !== SelectionMode.NONE) {
            for (const index of this._selectedIndexes)
                this.updateItemColor(index, true);
            this.scrollToSelection();
        }
    }

    private updateItemColor(index: number, selected: boolean) {
        const item = this._itemComps[index];
        if (item != null) {
            item.color = selected ? this.selectedColor : item["originalColor"];
            item.backgroundColor = selected ? this.selectedBackgroundColor : item["originalBackgroundColor"];
        }
    }

    scrollToSelection() {
        if (this._selectedIndexes.length === 1)
            this._itemComps[this._selectedIndexes[0]].scrollIntoView({ smooth: false });
    }

    get selectionMode(): SelectionMode {
        return this._selectionMode;
    }

    set selectionMode(value: SelectionMode) {
        this._selectionMode = value;
    }

    get selectedItem() {
        return this.getItem(this.selectedIndex);
    }

    set selectedItem(value) {
        const index = this.indexOf(value);
        if (index >= 0)
            this.selectedIndex = index;
    }

    indexOf(item) {
        if (item != null)
            for (let i = 0; i < this._itemComps.length; i++)
                if (this.itemsEqual((this._itemComps[i] as any).item, item))
                    return i;
        return -1;
    }

    private itemsEqual(item1, item2) {
        return item1 === item2 || (item1 != null && item2 != null && item1.caption != null && item1.caption == item2.caption);
    }

    getItem(index) {
        if (index < 0)
            return null;
        if (index >= this._itemComps.length)
            throw new Error("Cannot get List item at index  " + index + " because it only has " + this._itemComps.length + " items.");
        return (this._itemComps[index] as any).item;
    }

    setOnClickForAllItems(onClick: (event) => void) {
        if (this._itemComps == null) {
            return;
        }
        for (const itemComp of this._itemComps) {
            itemComp.addClickListener(onClick);
        }
    }

    get scrollY() {
        return this._scrollY
    }

    set scrollY(value) {
        this._scrollY = value;
        if (value !== false)
            this._element.style.overflowY = "auto";
        else
            this._element.style.overflowY = "";
    }

    public addSelectionListener(value: SelectionListener) {
        this.addEventListener(_changeListenerDef, value);
    }

    public removeSelectionListener(value: SelectionListener) {
        this.removeEventListener(_changeListenerDef, value);
    }

    override getPropertyDefinitions() {
        return ListPropDefinitions.getDefinitions();
    }

    override get serializationName() {
        return "list";
    }

    override getListenerDefs(): Collection<ListenerListDef> {
        return {
            ...super.getListenerDefs(),
            "change": { ..._changeListenerDef }
        };
    }

    override getBasicValue(): any {
        return this.selectedItem;
    }
}

ComponentTypes.registerComponentType("list", List.prototype.constructor);
