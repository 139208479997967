/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelPayrollControl.ts                *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/dispatch/payroll-control";

export class AutogenModelPayrollControl<RowType extends ModelRow> extends Endpoint<RowType, RowPayrollControlFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowPayrollControl extends ModelRow<RowPayrollControlFields> {
    constructor(values?: Partial<RowPayrollControlFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowPayrollControlFields {
    ach_batch_class_code: string;
    ach_optional_security_ln: string;
    address_where: string;
    adjustment_glid: string;
    advance_ar_glid: string;
    age_period_1: number;
    age_period_1_desc: string;
    age_period_2: number;
    age_period_2_desc: string;
    age_period_3: number;
    age_period_3_desc: string;
    age_period_4: number;
    age_period_4_desc: string;
    agent_adv_glid: string;
    agent_cash_glid: string;
    agent_exp_glid: string;
    agent_pay_glid: string;
    auto_so_pu_pay: string;
    bank_name: string;
    bank_number: string;
    bol_report_print: string;
    break_after_carr_subtotal: string;
    break_after_ownop_subtotal: string;
    carrier_adv_glid: string;
    carrier_cash_glid: string;
    carrier_exp_glid: string;
    carrier_interface_cash_glid: string;
    carrier_limit_amount: number;
    carrier_limit_amount_c: string;
    carrier_limit_amount_d: Date;
    carrier_limit_amount_n: number;
    carrier_limit_amount_r: number;
    carrier_limit_method: string;
    carrier_order_pct_limit: number;
    carrier_pay_glid: string;
    carrier_trans_per_day: number;
    carrier_valid_move_status: string;
    chkondemand_glid: string;
    co_discr_data: string;
    commsn_pay_glid: string;
    comp_cutoff_date: Date;
    comp_pay_freq: string;
    company_id: string;
    company_limit_amount: number;
    company_limit_amount_c: string;
    company_limit_amount_d: Date;
    company_limit_amount_n: number;
    company_limit_amount_r: number;
    company_limit_method: string;
    company_order_pct_limit: number;
    company_pu_acct: string;
    company_pu_amt: number;
    company_pu_amt_c: string;
    company_pu_amt_d: Date;
    company_pu_amt_n: number;
    company_pu_amt_r: number;
    company_pu_code: string;
    company_so_acct: string;
    company_so_amt: number;
    company_so_amt_c: string;
    company_so_amt_d: Date;
    company_so_amt_n: number;
    company_so_amt_r: number;
    company_so_code: string;
    company_valid_move_status: string;
    contract_cash_glid: string;
    country_id: string;
    cpa_code: number;
    cr_union_glid: string;
    create_ready: string;
    currency_realized_glid: string;
    currency_unrealized_glid: string;
    days_to_pay: number;
    deduct_tied_order: string;
    deduct_warning: string;
    dir_dep_account: string;
    dir_dep_class_desc: string;
    dir_dep_comp_id: string;
    dir_dep_days: number;
    dir_dep_dest_flag: string;
    dir_dep_dest_name: string;
    dir_dep_filename: string;
    dir_dep_format: string;
    dir_dep_holiday: string;
    dir_dep_min_amt: number;
    dir_dep_min_amt_c: string;
    dir_dep_min_amt_d: Date;
    dir_dep_min_amt_n: number;
    dir_dep_min_amt_r: number;
    dir_dep_orig_name: string;
    dir_dep_orig_num: string;
    dir_dep_printdebit: string;
    dir_dep_saturday: string;
    dir_dep_sepfile_co: string;
    dir_dep_sunday: string;
    driver_cash_glid: string;
    driver_exp_glid: string;
    eft_file_number: number;
    email_at_posting: string;
    enable_commissions: string;
    escrow_template_id: number;
    fed_min_wage: number;
    fed_min_wage_c: string;
    fed_min_wage_d: Date;
    fed_min_wage_n: number;
    fed_min_wage_r: number;
    fed_reserve_num: string;
    fuel_cash_glid: string;
    gl_expense_when: string;
    hourly_pay_allocation: string;
    ic_expense_glid: string;
    id: number;
    lease_op_liab_glid: string;
    leaseop_adv_glid: string;
    leaseop_cash_glid: string;
    leaseop_exp_glid: string;
    leaseop_loans_glid: string;
    left_adj: number;
    level_1_limit: number;
    level_1_limit_c: string;
    level_1_limit_d: Date;
    level_1_limit_n: number;
    level_1_limit_r: number;
    level_2_limit: number;
    level_2_limit_c: string;
    level_2_limit_d: Date;
    level_2_limit_n: number;
    level_2_limit_r: number;
    level_3_limit: number;
    level_3_limit_c: string;
    level_3_limit_d: Date;
    level_3_limit_n: number;
    level_3_limit_r: number;
    loan_ar_gl_id: string;
    manh_rdcost_index: number;
    manh_seth_index: number;
    manh_toll_index: number;
    manifest_pay: string;
    manifest_s_p_pay: string;
    notify_on_chgaft_advance: string;
    off_cutoff_date: Date;
    off_pay_freq: string;
    office_cash_glid: string;
    originator_id: string;
    owner_pay_glid: string;
    owner_pu_acct: string;
    owner_pu_amt: number;
    owner_pu_amt_c: string;
    owner_pu_amt_d: Date;
    owner_pu_amt_n: number;
    owner_pu_amt_r: number;
    owner_pu_code: string;
    owner_so_acct: string;
    owner_so_amt: number;
    owner_so_amt_c: string;
    owner_so_amt_d: Date;
    owner_so_amt_n: number;
    owner_so_amt_r: number;
    owner_so_code: string;
    ownerop_limit_amount: number;
    ownerop_limit_amount_c: string;
    ownerop_limit_amount_d: Date;
    ownerop_limit_amount_n: number;
    ownerop_limit_amount_r: number;
    ownerop_limit_method: string;
    ownerop_order_pct_limit: number;
    ownerop_valid_move_status: string;
    ownop_cutoff_date: Date;
    ownop_pay_freq: string;
    pay_unbilled: string;
    perdiem_glid: string;
    print_deduct: string;
    print_expanded_pay_summary: string;
    print_owe: string;
    print_stops: string;
    print_tax_id: string;
    print_window: string;
    print_zero_ck: string;
    print_zero_debit: string;
    puc_glid: string;
    puc_tax_rate: number;
    reply_email_driver: string;
    reply_email_office: string;
    require_pay_periods: string;
    settle_check_type: number;
    settlement_payee_sort: string;
    settlement_rounding_glid: string;
    settlement_sort: string;
    settlement_subsort: string;
    state_hh_tax_table: string;
    suppress_pay_rates: string;
    suppress_rates: string;
    tonu_earning_code: string;
    top_adj: number;
    trailer_rent_glid: string;
    trans_by_cont_stat: string;
    use_ot_hours: string;
    user_trans_per_day: number;
    wcomp_calculation: string;
    wcomp_glid: string;
    wcomp_other_charge: string;
    wcomp_print_option: string;
    wire_exp_glid: string;
    wire_glid: string;
    wire_income_glid: string;
}

