import { CommonDialogs } from "@mcleod/common";
import {
    ClickEvent, DataDisplayEvent, DataSourceMode, Label, Layout, Overlay, OverlayedList, Panel, Snackbar, Toast
} from "@mcleod/components";
import {
    Alignment, Api, getAuthSettings, getLogger, HorizontalAlignment, Model, ModelRow, ModelSearchResult
} from "@mcleod/core";
import { SendLoadTender } from "../../datafusion/src/SendLoadTender";
import { InitiateBrokerageTracking } from "../../powerbroker/src/InitiateBrokerageTracking";
import { ModifyCarrierPay } from "../../powerbroker/src/ModifyCarrierPay";
import { SendRateConfirmation } from "../../powerbroker/src/SendRateConfirmation";
import { ModelBrokerageTracking } from "../../powerbroker/src/models/ModelBrokerageTracking";
import { BrokerageTrackingActions } from "../../powerbroker/src/tracking/BrokerageTrackingActions";
import { UnassignCarrierPrompt } from "./UnassignCarrierPrompt";
import { AutogenLayoutAssignedMovementActions } from "./autogen/AutogenLayoutAssignedMovementActions";

const log = getLogger("lme/datafusion/AssignedMovementActions");

export class AssignedMovementActions extends AutogenLayoutAssignedMovementActions {
    private _doAfterAction: () => void = () => this.mainDataSource.search(this.mainDataSource.lastSearch);
    trackingOverlayedList: OverlayedList;
    tenderOverlayedList: OverlayedList;
    slideoutProps = {
        width: 1200, height: window.innerHeight, backgroundColor: "defaultBackground", top: 30, padding: 0,
        borderRadius: 4, borderWidth: 1, borderShadow: true, borderColor: "strokeSecondary"
    };


    override onLoad() {
        this.buttonLoadTracking.addMouseEnterListener(() => this.showOrderTrackingBorder(true));
        this.buttonLoadTracking.addMouseLeaveListener(() => {
            if (this.trackingOverlayedList == null)
                this.showOrderTrackingBorder(false)
        });
        this.buttonSendDataFusionTender.visible = false;
        this.buttonSendRateCon.visible = false;
    }

    public get doAfterAction(): () => void {
        return this._doAfterAction;
    }

    public set doAfterAction(value: () => void) {
        this._doAfterAction = value;
    }

    private executeDoAfterAction() {
        if (this.doAfterAction != null)
            this.doAfterAction();
    }

    /** This is an event handler for the onClick event of buttonSendRateCon.  */
    buttonSendRateConOnClick(event: ClickEvent) {
        const moveId = this.movement?.get("id");
        const orderId = this.mainDataSource.activeRow?.get("orders.id");
        if (moveId != null) {
            Layout.getLayout("lme/powerbroker/SendRateConfirmation", { ...this.slideoutProps, width: 1000 }).addLayoutLoadListener(event => {
                const layout = event.target as SendRateConfirmation;
                layout.panel1.paddingTop = 25;
                layout.buttonCancel.addClickListener(() => layout.slideOut());
                this.buttonSendRateCon.busy = true;
                layout.search({ movement_id: moveId }).then(result => {
                    if (result?.modelRows?.length > 0) {
                        layout.doAfterRateConSent = (() => layout.slideOut().then(() => {
                            this.executeDoAfterAction();
                        }));
                        layout.setAllDataSourcesToMode(DataSourceMode.UPDATE);
                        layout.slideIn({ direction: Alignment.RIGHT }, true, { closeOnClickOff: false, greyedBackground: true });
                    }
                }).finally(() => this.buttonSendRateCon.busy = false);
            });
        }
    }

    /** This is an event handler for the onClick event of buttonInitiateTracking.  */
    buttonInitiateTrackingOnClick(event: ClickEvent) {
        this.initiateOrEditTracking(event);
    }

    private initiateOrEditTracking(clickEvent: ClickEvent, editTracking?: boolean) {
        const moveId = this.movement?.get("id");
        const orderId = this.mainDataSource.activeRow?.get("orders.id");

        if (moveId != null) {
            Layout.getLayout("lme/powerbroker/InitiateBrokerageTracking", { ...this.slideoutProps }).addLayoutLoadListener(event => {
                const layout = event.target as InitiateBrokerageTracking;
                layout.editTracking = editTracking;
                layout.buttonInitiateLater.visible = false;
                layout.buttonCancel.addClickListener(() => layout.slideOut());
                this.buttonInitiateTracking.busy = true;
                layout.search({ "id": moveId, "orders.id": orderId }).then(result => {
                    if (result?.modelRows?.length > 0) {
                        layout.setAllDataSourcesToMode(DataSourceMode.UPDATE)
                        layout.slideIn({ direction: Alignment.RIGHT }, true, { closeOnClickOff: false, greyedBackground: true });
                        layout.doAfterTrackingInitiated = () => {
                            layout.slideOut();
                            if (layout.mainDataSource.activeRow?.get("br_vendor") != null) {
                                this.executeDoAfterAction();
                            }
                        }
                    }
                }).finally(() => this.buttonInitiateTracking.busy = false);
            });
        }
    }

    private cancelTracking() {
        const moveId = this.movement?.get("id");
        CommonDialogs.showYesNo(`Movement ${moveId} will no longer be tracked by ${this.sourceMovement.activeRow?.get("br_vendor_name", "")}. Would you like to continue?`,
            "Cancel Tracking", { titleProps: { imageName: "circleX2", imageColor: "primary.light" } }).then(yes => {
                if (yes) {
                    this.buttonLoadTracking.busy = true;
                    Api.post("lme/powerbroker/tracking/cancel-tracking", { "movement_id": moveId }).then(response => {
                        Toast.showSuccessToast(`Tracking Canceled for Movement ${moveId} `);
                        this.executeDoAfterAction();
                    }).finally(() => this.buttonLoadTracking.busy = false);
                }
            })
    }

    /** This is an event handler for the onClick event of buttonOrderTracking.  */
    buttonLoadTrackingOnClick(event: ClickEvent) {
        const dropDownItems = [
            { caption: "Cancel Tracking", onClick: () => this.cancelTracking() }
        ];
        if (this.canUpdateTracking())
            dropDownItems.push({ caption: "Edit Tracking", onClick: () => this.initiateOrEditTracking(event, true) })
        if (this.canSendTrackingMsg())
            dropDownItems.push({
                caption: "Send Message",
                onClick: () => new BrokerageTrackingActions()
                    .sendTrackingMessage(
                        this.movement.get("id"),
                        this.movement.get("br_vendor_name", this.movement.get("br_vendor")),
                        this.sendMessageOnClose,
                        this.sendMessageDoAfterMessageSent)
            });

        this.trackingOverlayedList = Overlay.showDropdown(this.buttonLoadTracking, dropDownItems, null, { width: this.buttonLoadTracking.width }, {
            onClose: () => {
                this.trackingOverlayedList = null;
                this.showOrderTrackingBorder(false);
            }
        })
    }

    canSendTrackingMsg(): boolean {
        const vendor = this.movement?.get("br_vendor");
        if (vendor == null) return false;
        switch (vendor) {
            case ModelBrokerageTracking.MACROPOINT_LITE_VENDOR:
            case ModelBrokerageTracking.LOAD_TRACK_VENDOR:
            case ModelBrokerageTracking.FOURKITES_VENDOR:
                return true;
            default: return false;
        }
    }

    canUpdateTracking(): boolean {
        const vendor = this.movement?.get("br_vendor");
        if (vendor == null) return false;
        switch (vendor) {
            case ModelBrokerageTracking.MACROPOINT_LITE_VENDOR:
            case ModelBrokerageTracking.LOAD_TRACK_VENDOR:
            case ModelBrokerageTracking.PROJECT44_VENDOR:
                return true;
            default: return false;
        }
    }

    private showOrderTrackingBorder(value: boolean) {
        this.buttonLoadTracking._element.style.outline = value ? "1px solid #cdcdcd" : null;
    }

    public async removeCarrier(moveRow: ModelRow) {
        const moveId = moveRow?.get("id");
        const brTrackingVendor = moveRow?.get("br_vendor");

        if (moveId != null) {
            const dispatchControlSettings = getAuthSettings().dispatch_control[0];
            if (dispatchControlSettings.enforce_revenu_cod == 'Y' && moveRow?.get("orders.revenue_code_id") == null) {
                CommonDialogs.showError("A revenue code is required for the order.");
                return;
            }
            if (dispatchControlSettings.enforce_billto == 'Y' && moveRow?.get("orders.customer_id") == null) {
                CommonDialogs.showError("A Bill-to customer is required for the order.");
                return;
            }
            const unassignCarrierPromptLayout = Layout.getLayout("lme/dispatch/UnassignCarrierPrompt", {
                backgroundColor: "defaultBackground",
                borderRadius: 4, borderWidth: 1, borderShadow: true, borderColor: "strokeSecondary",
            }) as UnassignCarrierPrompt;
            unassignCarrierPromptLayout.movementId = moveRow.get("id");
            const carrierId = moveRow.get("override_payee_id");
            unassignCarrierPromptLayout.addLayoutDataLoadListener(event => {
                unassignCarrierPromptLayout.brTrackingVendor = brTrackingVendor;
                unassignCarrierPromptLayout.onCancel = () => Overlay.hideOverlay(overlay);
                unassignCarrierPromptLayout.onSave = async (success: boolean, vendorName: string, forceCancel: boolean) => {
                    this.cancelAllowed(carrierId, moveId).then(cancelAllowed => {
                        if (cancelAllowed) {
                            this.sendDFCancel(carrierId, moveId);
                        }
                        else {
                            unassignCarrierPromptLayout.toastMessage = `${this.mainDataSource.activeRow.get("payee.name")} was removed from movement ${moveId}.`;
                            if (brTrackingVendor) {
                                if (forceCancel)
                                    unassignCarrierPromptLayout.toastMessage += ' Tracking removed by forced cancellation.';
                                else
                                    unassignCarrierPromptLayout.toastMessage += ' ' + vendorName + ' tracking canceled.';
                            }
                        }
                    });
                    await new Promise(resolve => setTimeout(resolve, 2500)).then(() => {
                        this.executeDoAfterAction();
                    });
                };
                unassignCarrierPromptLayout.buttonYes.addClickListener(async (event) => {
                    if (unassignCarrierPromptLayout.validateSimple()) {
                        Overlay.hideOverlay(overlay);
                        const isBrokerageLtl = this.activeRow.getBoolean("is_brokerage_ltl");
                        await unassignCarrierPromptLayout.unassignCarrier();
                        if (!isBrokerageLtl) {
                            this.showTONUYesNO(carrierId);
                        }
                    }
                });
                const overlay = Overlay.showInOverlay(unassignCarrierPromptLayout, { closeOnClickOff: false, greyedBackground: true, centered: true });
            });
        }
    }

    addTONUCharges(tonuCharges: Array<ModelRow>, movementID: String) {
        tonuCharges.forEach(async (row: ModelRow) => {
            const modelRow = new ModelRow("lme/powerbroker/extra-pay", row._appending);
            modelRow.setValues(row.data);
            modelRow.set("movement_id", movementID);
            await Api.update("lme/powerbroker/extra-pay", modelRow);
        })
    }


    public async showTONUYesNO(carrierId: String) {
        const pcData = await Model.searchSingleRecord("lme/dispatch/payroll-control")
        const okClicked = await CommonDialogs.showYesNo(`Would you like to pay the carrier for a TONU?`, "TONU Pay")
        if (okClicked) {
            this.openTONUSlideout(pcData.data?.tonu_earning_code, carrierId);
            return;
        }
    }

    openTONUSlideout(tonu_earning_code: String, carrierId: String) {
        const moveId = this.movement?.get("id");
        ModifyCarrierPay.showSlideout(moveId, async (movementSaved: boolean, moveRow: ModelRow) => {
            await this.sourceExtraPay.search({ movement_id: this.movement.get("id") });
            const tonuCharges = this.sourceExtraPay.data;
            this.addTONUCharges(tonuCharges, moveRow.get("id"));
        }, tonu_earning_code, carrierId);
    }


    /** This is an event handler for the onDisplay event of sourceMovement.  */
    async sourceMovementOnDisplay(event: DataDisplayEvent) {
        const panelVisible = this.movement != null;
        const activeVendorExist = await this.isBrVendorActive();
        if (panelVisible && activeVendorExist) {
            const showOrderTracking = this.movement.get("br_vendor") != null;
            this.buttonInitiateTracking.visible = !showOrderTracking;
            this.buttonLoadTracking.visible = showOrderTracking;
        } else {
            this.buttonInitiateTracking.visible = false;
            this.buttonLoadTracking.visible = false;
        }
    }


    private get movement(): ModelRow<any> {
        return this.mainDataSource?.activeRow;
    }

    private sendMessageOnClose(overLay: Overlay): void {
        Overlay.hideOverlay(overLay);
    }

    private sendMessageDoAfterMessageSent(overlay: Overlay, response: any): void {
        Overlay.hideOverlay(overlay);
        const message = response?.data?.[0]?.toast_message;
        if (message) {
            Toast.showSuccessToast(message);
        }
    }

    public setMenuOptions(payeeId: string) {
        const carrierId = payeeId;

        if (carrierId != null) {
            this.checkCarrierForDataFusion(carrierId).then(result => {
                if (result == "false") {
                    this.buttonSendRateCon.visible = true;
                    this.buttonSendDataFusionTender.visible = false;
                }
                else {
                    this.buttonSendRateCon.visible = false;
                    this.buttonSendDataFusionTender.visible = true;
                }
            });
        }
    }

    async checkCarrierForDataFusion(payeeId: String): Promise<string> {
        let result = "";
        await Api.search("lme/datafusion/df-carrier-search", {
            payee_id: payeeId
        }).then(response => {
            const data = response.data[0];
            result = data.is_datafusion_carrier;
        });
        return result;
    }

    async buttonSendLoadTenderOnClick(event: ClickEvent) {
        const moveId = this.movement?.get("id");
        const carrierId = this.movement?.get("override_payee_id");

        if (moveId != null) {
            await this.cancelAllowed(carrierId, moveId).then(result => {
                const cancelAllowed: boolean = result;

                const extraItems = [
                    { caption: "Send DataFusion Tender", onClick: () => this.sendLoadTender(carrierId, moveId, "false") }
                ];

                if (cancelAllowed) {
                    extraItems.push({ caption: "Send DataFusion Cancel", onClick: () => this.sendLoadTender(carrierId, moveId, "true") });
                }
                extraItems.push({ caption: "Send Rate Confirmation", onClick: () => this.sendRC() });

                this.tenderOverlayedList = Overlay.showDropdown(this.buttonSendDataFusionTender, extraItems, null, { width: this.buttonSendDataFusionTender.width },
                    {
                        onClose: () => {
                            this.tenderOverlayedList = null;
                        }
                    });
            });
        }
    }

    sendLoadTender(carrierId: string, moveId: string, isCancel: string) {
        Layout.getLayout("lme/datafusion/SendLoadTender", { ...this.slideoutProps, width: 1000 }).addLayoutLoadListener(event => {
            const layout = event.target as SendLoadTender;
            layout.parentPanel = this.parent as Panel;
            layout.buttonCancel.addClickListener(() => layout.slideOut());
            layout.addUnmountListener(() => this.refresh());
            layout.buttonSendLater.visible = false;
            layout.search({ carrier: carrierId, move_id: moveId, is_cancel: isCancel, is_auto: "false" }).then(result => {
                if (result?.modelRows?.length > 0) {
                    layout.setAllDataSourcesToMode(DataSourceMode.UPDATE);
                    layout.slideIn({ direction: Alignment.RIGHT }, true, { closeOnClickOff: false, greyedBackground: true });
                }
            });
        });
    }

    sendRC() {
        const moveId = this.movement?.get("id");
        const orderId = this.mainDataSource.activeRow?.get("orders.id");
        if (moveId != null) {
            Layout.getLayout("lme/powerbroker/SendRateConfirmation", { ...this.slideoutProps, width: 1000 }).addLayoutLoadListener(event => {
                const layout = event.target as SendRateConfirmation;
                layout.panel1.paddingTop = 25;
                layout.buttonCancel.addClickListener(() => layout.slideOut());
                this.buttonSendRateCon.busy = true;
                layout.search({ movement_id: moveId }).then(result => {
                    if (result?.modelRows?.length > 0) {
                        layout.doAfterRateConSent = (() => layout.slideOut().then(() => {
                            this.executeDoAfterAction();
                        }));
                        layout.setAllDataSourcesToMode(DataSourceMode.UPDATE);
                        layout.slideIn({ direction: Alignment.RIGHT }, true, { closeOnClickOff: false, greyedBackground: true });
                    }
                }).finally(() => this.buttonSendRateCon.busy = false);
            });
        }
    }

    async cancelAllowed(carrierId: string, moveId: string): Promise<boolean> {
        let result: boolean = true;

        await Api.search("lme/datafusion/df-cancel-allowed", { carrier: carrierId, move_id: moveId }).then((response) => {
            result = response.data[0].is_cancel_allowed == "Y" ? true : false;
            const info = response.data[0].information;
            if (!result) {
                log.debug("Cancel tender not sent: " + info);
            }
        });

        return result;
    }

    async sendDFCancel(carrierId: string, moveId: string): Promise<any> {
        Api.post("lme/datafusion/send-tender", {
            carrier: carrierId, move_id: moveId, respond_by: null, purpose: "CANCEL", contacts: null
        }).then(response => {
            if (response.data[0]?.tendersent) {
                const text = response.data[0].text;
                const batch = response.data[0].batchid;
                const p = new Panel();
                p.add(new Label({ caption: "Carrier was Successfully Removed", align: HorizontalAlignment.CENTER, fontBold: true, fontSize: "xxlarge" }));
                const cap = (batch == "0") ? text : "\t  CANCEL tender sent in batch " + batch;
                cap.trim;
                p.add(new Label({ fontBold: true, fontSize: "large", align: HorizontalAlignment.CENTER, caption: cap }));
                Toast.showToast(p, { backgroundColor: "primary" });
            }
            else {
                Snackbar.showWarningSnackbar("Unable to send CANCEL tender for movement " + moveId + ".  " + response.data[0].text);
            }
        });
    }

    refresh() {
        log.debug("Refreshing after tender sent.")
        this.mainDataSource.search(this.mainDataSource.lastSearch);
    }

    async isBrVendorActive() {
        return new Promise((resolve, reject) => {
            this.sourceBrokerageTrackingCtrl.search().then((result: ModelSearchResult) => {
                const data: ModelRow[] = result.modelRows;
                for (const row of data) {
                    if (row.get("is_active") === "Y") {
                        resolve(true);
                        return;
                    }
                }
                resolve(false);
            }).catch(error => {
                reject(error);
            });
        });
    }
}

