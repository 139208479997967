/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelSalesperson.ts                   *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/ar/salesperson";

export class AutogenModelSalesperson<RowType extends ModelRow> extends Endpoint<RowType, RowSalespersonFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowSalesperson extends ModelRow<RowSalespersonFields> {
    constructor() {
        super(AutogenUserSavedSearchEndpoint);
    }
}

export interface RowSalespersonFields {
    address: string;
    city: string;
    company_id: string;
    id: string;
    is_manager: string;
    name: string;
    percentage: number;
    sales_manager_id: string;
    state: string;
    user_id: string;
    zip_code: string;
}

