import { Button, DataSourceMode, Panel, TableRow, TableRowExpansionEvent } from "@mcleod/components";
import { getLogger } from "@mcleod/core";
import { AutogenLayoutUnbilledOrders } from "./autogen/AutogenLayoutUnbilledOrders";

const log = getLogger("lme.dispatch.UnbilledOrders");

export class UnbilledOrders extends AutogenLayoutUnbilledOrders {
    _daysBack = 30;

    override onLoad(): void | Promise<void> {
        this.mainDataSource?.addDisplayListener(event => {
            this.mainDataSource.orderBy = [{ field: "delivered_date", sort: "asc" }];
            this.mainDataSource.activeRow?.setValues({ "days_back": this._daysBack });
        });
        this.textboxDaysBack.text = this._daysBack.toString();
    }

    onRowExpand(event: TableRowExpansionEvent) {
        const tableRow: TableRow = event.target as TableRow;
        if (event.isExpanding) {
            tableRow.editRow();
            const expandPanel: Panel = event.expandComponentParent as Panel;

            this._closeOtherExpansions(tableRow.index);
            this._setExpandDatasource(tableRow.data.data["id"]);

            (expandPanel.findComponentById("btnSave") as Button).addClickListener(_event => this._saveTableRow(tableRow));
            (expandPanel.findComponentById("btnCancel") as Button).addClickListener(_event => this._cancelTableRow(tableRow));
        }
    }

    private _closeOtherExpansions(thisIndex: number) {
        for (const tableRow of this.tableUnbilledOrders.rows) {
            if (tableRow.index === thisIndex) continue;
            tableRow.setExpanded(false);
        }
    }

    private _setExpandDatasource(orderId: string) {
        this.sourceExpandedOrder.search({ id: orderId }).then(row => {
            this.sourceExpandedOrder.mode = DataSourceMode.UPDATE;
        })
    }

    private _saveTableRow(tableRow: TableRow) {
        log.debug("Saving row...", this.sourceExpandedOrder.activeRow);
        this.sourceExpandedOrder.post().then(savedRow => {
            tableRow.displayData(savedRow, this.mainDataSource.data, tableRow.index);
            tableRow.setExpanded(false);
        });

    }

    private _cancelTableRow(tableRow: TableRow) {
        log.debug("Cancelling row changes...", this.sourceExpandedOrder.activeRow);
        tableRow.cancelEdit();
        tableRow.setExpanded(false);
    }

    textboxDaysBackOnChange(event) {
        this._daysBack = event.newValue;
    }
}
