import { DialogProps } from "@mcleod/components";
import { AbstractPanelOpenTree } from "./AbstractPanelOpenTree";

export class PanelOpenFieldList extends AbstractPanelOpenTree {
    dialogProps: Partial<DialogProps> = { title: "Field List Selection", cursor: null };

    override getApiName(): string {
        return "field-list/list";
    }

    override getCustomizedTooltip(): string {
        return "This is a field list.";
    }
}
