import { ObjectUtil } from "@mcleod/core";
import { DataSourceMode } from "..";
import { ComponentTypes } from "../base/ComponentTypes";
import { Button } from "../components/button/Button";
import { ButtonPropDefinitions, ButtonProps } from "../components/button/ButtonProps";

const searchButtonProps = {
    ...ObjectUtil.deepCopy(ButtonPropDefinitions.getDefinitions())
};
searchButtonProps.caption.defaultValue = "Search";
searchButtonProps.color.defaultValue = "primary";
searchButtonProps.minWidth.defaultValue = 100;

/**
 * This class is a button that will execute searches on its associated DataSource without changing its mode.
 * It's typically used on pages where there is a header with fields used for filtering the DataSource and then some panel below
 * it to display the filtered results.
 */
export class SearchButton extends Button {
    constructor(props?: Partial<ButtonProps>) {
        super({
            caption: searchButtonProps.caption.defaultValue,
            color: searchButtonProps.color.defaultValue,
            minWidth: searchButtonProps.minWidth.defaultValue,
            busyWhenDataSourceBusy: true,
            ...props
        });
        this.addClickListener(_event => this.performSearch());
    }

    performSearch() {
        if (this.dataSource != null)
            this.dataSource.search(this.dataSource.getDataboundValues(DataSourceMode.SEARCH, null, true));
    }

    override getPropertyDefinitions() {
        return searchButtonProps;
    }

    override get serializationName() {
        return "searchbutton";
    }
}

ComponentTypes.registerComponentType("searchbutton", SearchButton.prototype.constructor, false);
