/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelLayoutLookup.ts                  *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/general/layout-lookup";

export class AutogenModelLayoutLookup<RowType extends ModelRow> extends Endpoint<RowType, RowLayoutLookupFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowLayoutLookup extends ModelRow<RowLayoutLookupFields> {
    constructor() {
        super(AutogenUserSavedSearchEndpoint);
    }
}

export interface RowLayoutLookupFields {
    company_id: string;
    layout_name: string;
    model_name: string;
}

