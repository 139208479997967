import { CityState, DataSource, HorizontalSpacer, Label, Layout, Map, Panel, SearchButton, Splitter, Switch, Table, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutLocationFindNear extends Layout {
    buttonSearch: SearchButton;
    citystateNear: CityState;
    horizontalspacer1: HorizontalSpacer;
    labelFindLocationsWithType: Label;
    labelMilesOf: Label;
    labelPageTitle: Label;
    labelWithin: Label;
    mapFindNear: Map;
    panel1: Panel;
    sourceLocation: DataSource;
    splitter: Splitter;
    switchPlotOnMap: Switch;
    tableFindNear: Table;
    textboxCategory: Textbox;
    textboxFindNearRadius: Textbox;
}
