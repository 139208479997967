import { Checkbox, DataSource, Label, Layout, Switch, Textbox, } from "@mcleod/components";

export abstract class AutogenLayoutBrokeragePlanningProfile extends Layout {
    checkBrokerageStatusExclude: Checkbox;
    checkDispatcherUserIdExclude: Checkbox;
    checkOperationsUserExclude: Checkbox;
    checkOrderCustomersExclude: Checkbox;
    checkOrderIbExclude: Checkbox;
    checkOrderObExclude: Checkbox;
    checkOrderSalespersonExclude: Checkbox;
    checkOrderTrailerTypeExclude: Checkbox;
    checkOrderTypeExclude: Checkbox;
    checkRevenueCodeExclude: Checkbox;
    labelDateRange: Label;
    sourceBrokeragePlanningProfile: DataSource;
    switchDisplayUntilReadyToBill: Switch;
    switchIncludeOnHoldOrders: Switch;
    textboxBrokerageStatus: Textbox;
    textboxDispatcherUserId: Textbox;
    textboxId: Textbox;
    textboxOperationsUser: Textbox;
    textboxOrderCustomers: Textbox;
    textboxOrderDays: Textbox;
    textboxOrderIbType: Textbox;
    textboxOrderIbZones: Textbox;
    textboxOrderObType: Textbox;
    textboxOrderObZones: Textbox;
    textboxOrderRespCodes: Textbox;
    textboxOrderRespLevel: Textbox;
    textboxOrderSalesperson: Textbox;
    textboxOrderTrlrType: Textbox;
    textboxOrderType: Textbox;
    textboxRevenueCode: Textbox;
    textboxStartDay: Textbox;
    textboxTitle: Textbox;
}
