import { DataSource, Image, Label, Layout, Panel } from "@mcleod/components";

export abstract class AutogenLayoutMovementDataFusionSummary extends Layout {
    imageInboundShipmentStatusError: Image;
    imageInboundTenderError: Image;
    imageOutboundShipmentStatusError: Image;
    imageOutboundTenderError: Image;
    labelDataFusion: Label;
    labelInboundShipmentStatusCount: Label;
    labelInboundShipmentStatuses: Label;
    labelInboundTenderCount: Label;
    labelInboundTenders: Label;
    labelOutboundShipmentStatusCount: Label;
    labelOutboundShipmentStatuses: Label;
    labelOutboundTenderCount: Label;
    labelOutboundTenders: Label;
    panelDataFusion: Panel;
    sourceMovementDataFusionSummary: DataSource;
}
