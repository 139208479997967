/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelContact.ts                       *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/general/contact";

export class AutogenModelContact<RowType extends ModelRow> extends Endpoint<RowType, RowContactFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowContact extends ModelRow<RowContactFields> {
    constructor(values?: Partial<RowContactFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowContactFields {
    comments: string;
    company_id: string;
    contact_name: string;
    customer_sequence: number;
    detention_contact: string;
    ect_uid: number;
    email: string;
    fax: string;
    id: string;
    is_active: string;
    is_sms_opted_in: string;
    mobile_phone: string;
    name: string;
    opt_out_date: Date;
    parent_row_id: string;
    parent_row_type: string;
    payable_contact: string;
    personal_ref: string;
    phone: string;
    rapid_alert_notify: string;
    rec_interval: number;
    rec_next_date: Date;
    rec_ops_user_id: string;
    rec_salesperson_id: string;
    sequence: number;
    terms_contact: string;
    title: string;
    type_of: string;
    web_access: string;
}

