import { DataSourceMode, DataSourceModeChangeEvent } from "@mcleod/components";
import { LoadTenderExpressProfile } from "./LoadTenderExpressProfile";
import { AutogenLayoutLoadTenderExpressProfileFilter } from "./autogen/AutogenLayoutLoadTenderExpressProfileFilter";


export class LoadTenderExpressProfileFilter extends AutogenLayoutLoadTenderExpressProfileFilter {

    override onLoad() {
        (this.layoutProfile as LoadTenderExpressProfile).textboxTitle.required = false;
    }

    /** This is an event handler for the afterModeChange event of sourceEdiLtxexpressProf.  */
    sourceEdiLtxexpressProfAfterModeChange(event: DataSourceModeChangeEvent) {
        if (event.newMode === DataSourceMode.UPDATE)
            this.layoutProfile.switchMyProfile.enabled = false
    }
}
