/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelEquipMatchDetail.ts              *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/dispatch/equip-match-detail";

export class AutogenModelEquipMatchDetail<RowType extends ModelRow> extends Endpoint<RowType, RowEquipMatchDetailFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowEquipMatchDetail extends ModelRow<RowEquipMatchDetailFields> {
    constructor() {
        super(AutogenUserSavedSearchEndpoint);
    }
}

export interface RowEquipMatchDetailFields {
    applies_to: string;
    company_id: string;
    equipment_match_id: number;
    equipment_requirement: string;
    equipment_type_id: string;
    equipment_zmit: string;
    id: string;
    is_active: string;
    is_shipper_consignee: string;
    parent_row_id: string;
    parent_row_type: string;
    priority: number;
    quantity: number;
}

