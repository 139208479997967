import { Api } from "./Api";

export class McLeodClassicIntegration {
    public static async openClassicScreen(screenClassName: string, screenParams: string): Promise<void> {
        return Api.post("lib/open-classic-screen", {
            screen_class: screenClassName,
            screen_params: screenParams
        }).then(response => {
            if (response.data == null && response.data.length !== 1)
                return Promise.reject("There was an error attempting to open a classic screen.");
            if (response.data[0].found_client !== true)
                return Promise.reject("Unable to open the requested screen.  Please make sure the McLeod classic application is running for the current company.");
            return Promise.resolve();
        });
    }
}
