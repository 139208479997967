import { Button, Image, Label, Layout, Overlay, Panel, ScreenStack, Snackbar, Toast } from "@mcleod/components";
import { Alignment, makeStyles } from "@mcleod/core";
import { CommonDialogs } from "../../../common/src/CommonDialogs";
import { AutogenLayoutDemoScreenFlowSampleButtons } from "./autogen/AutogenLayoutDemoScreenFlowSampleButtons";

let _dialogCount = 0;
let _snackbarCount = 0;
let _toastCount = 0;

const classes = makeStyles("adhoc", {
    centered: {
        margin: 0,
        position: "absolute",
        top: "50%",
        left: "50%",
        MsTransform: "translate(-50%, -50%)",
        transform: "translate(-50%, -50%)",
        maxWidth: "90%",
        maxHeight: "calc(100% - 64px)",
        boxShadow: "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
        borderRadius: 4
    }
});

export class DemoScreenFlowSampleButtons extends AutogenLayoutDemoScreenFlowSampleButtons {
    public alterForInitialDisplay() {
        this.remove(this.horizontalspacer1);
        this.remove(this.buttonClose);
        this.buttonShowCustomToast.rowBreak = true;
    }

    closeSlideout() {
        const overlay = ScreenStack.getNewestOverlay();
        overlay?.component.slideOut();
    }

    showNonModalDialog() {
        CommonDialogs.showDialog("This is a boring non-modal dialog.  You can move it around.", { modal: false, movable: true });
    }

    showModalDialog() {
        this._launchAModalDialog();
    }

    private _launchAModalDialog() {
        const panel = new Panel();
        panel.add(new Label({ caption: "This is a boring modal dialog." }));
        panel.add(this._getLaunchADialogButton());
        CommonDialogs.showDialog(panel, { title: "Number " + ++_dialogCount, modal: true, movable: true });
    }

    private _getLaunchADialogButton(): Button {
        return new Button({ caption: "Launch another one", onClick: (event) => this._launchAModalDialog() });
    }

    showSimpleOverlay() {
        const b = this._getLaunchADialogButton();
        b.addClass(classes.centered);
        b.default = true;
        Overlay.showInOverlay(b);
    }

    showWarningSnackbar() {
        Snackbar.showWarningSnackbar("This is snackbar #" + ++_snackbarCount, { persist: true });
    }

    showSuccessToast() {
        Toast.showSuccessToast("This Is a Title", "This is message #" + ++_toastCount + " saying that all is well", "magnifyingGlass", { persist: true });
    }

    showCustomToast() {
        const p = new Panel({ backgroundColor: "success" });
        p.add(new Image({ color: "#fff", name: "magnifyingGlass", height: 22, width: 22, rowBreak: false }));
        p.add(new Label({ color: "#fff", caption: "This is a custom toast built in code." }));
        Toast.showToast(p, null, { persist: true });
    }

    showSlideoutTop() {
        this._showSlideout(Alignment.TOP);
    }

    showSlideoutRight() {
        this._showSlideout(Alignment.RIGHT);
    }

    showSlideoutBottom() {
        this._showSlideout(Alignment.BOTTOM);
    }

    showSlideoutLeft() {
        this._showSlideout(Alignment.LEFT);
    }

    private _showSlideout(direction: Alignment) {
        const layout = this._getSlideoutLayout();
        layout.addLayoutLoadListener(() => {
            layout.slideIn({ direction: direction }, true);
        });
    }

    private _getSlideoutLayout(): DemoScreenFlowSampleButtons {
        const layout = Layout.getLayout("component-demo/dialogs/DemoScreenFlowSampleButtons", {
            width: window.innerWidth * 0.45,
            height: window.innerHeight * 0.5,
            backgroundColor: "backgroundSubtle",
            borderRadius: 4,
            borderWidth: 1,
            borderShadow: true,
            fillHeight: false,
            borderColor: "secondaryStroke",
            paddingBottom: 50
        }) as DemoScreenFlowSampleButtons;
        return layout;
    }
}
