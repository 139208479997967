import { Button, DataSource, Label, Layout, Panel, Table, Textbox, } from "@mcleod/components";

export abstract class AutogenLayoutDemoKeyHandlingSample extends Layout {
    buttonSaveAddress: Button;
    buttonSaveName: Button;
    buttonSaveNumber: Button;
    labelAddressInfoDescr: Label;
    labelDeleteDescr: Label;
    labelInnerAddressDescr: Label;
    labelNameInfoDescr: Label;
    labelNumberOutputTitle: Label;
    labelOverallDescr: Label;
    labelQuickAddDescr: Label;
    labelSavedAddressInfo: Label;
    labelSavedAddressInfoTitle: Label;
    labelSavedNameInfo: Label;
    labelSavedNameInfoTitle: Label;
    labelSavedNumber: Label;
    labelTableDescription: Label;
    panel1: Panel;
    panelAddressInfo: Panel;
    panelAddressInfoInputs: Panel;
    panelAddressInfoOutputs: Panel;
    panelNameInfo: Panel;
    panelNameInfoInputs: Panel;
    panelNameInfoOutputs: Panel;
    panelNumber: Panel;
    sourceContact: DataSource;
    tableContact: Table;
    textboxAddress: Textbox;
    textboxCity: Textbox;
    textboxFirstName: Textbox;
    textboxLastName: Textbox;
    textboxNumber: Textbox;
    textboxState: Textbox;
    textboxZipCode: Textbox;
}
