import { Api, ArrayUtil, Navigation, StringUtil } from ".";

export class HelpLink {
    private _caption: string;
    private url: string; //can also be a video item id
    private _relatedLayoutElement: HTMLElement;

    constructor(caption: string, url: string, relatedLayoutElement: HTMLElement) {
        this._caption = caption;
        this.url = url;
        this._relatedLayoutElement = relatedLayoutElement;
    }

    public get caption(): string {
        return this._caption;
    }

    public getRelatedLayoutElement(): HTMLElement {
        return this._relatedLayoutElement;
    }

    public async open(): Promise<boolean> {
        if (this.url == null)
            return;
        if (this.url.startsWith("https://") === true || this.url.startsWith("http://") === true) {
            Navigation.navigateTo(this.url, { newTab: true });
            return true;
        }

        //helpLink is assumed to be a video ID, ask the server for a current URL to access that video
        const response = await Api.post("video/create-url", { id: this.url });
        const data = ArrayUtil.isEmptyArray(response?.data) !== true ? response.data[0] : null;
        const url = data?.url;
        if (StringUtil.isEmptyString(url) !== true) {
            Navigation.navigateTo(url, { newTab: true });
            return true;
        }
        return false;
    }

    public equals(otherHelpLink: HelpLink): boolean {
        if (otherHelpLink == null)
            return false;
        return this.caption === otherHelpLink.caption && this.url === otherHelpLink.url;
    }
}