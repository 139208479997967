import { ClickEvent, Component, DataDisplayEvent, TableRow, TableRowDisplayEvent } from "@mcleod/components";
import { ModelRow } from "@mcleod/core";
import { LogicBrokeragePlanningPerformance } from "./LogicBrokeragePlanningPerformance";
import { AutogenLayoutBrokeragePlanningPerformanceProfit } from "./autogen/AutogenLayoutBrokeragePlanningPerformanceProfit";
import { BrokeragePlanningPerformance } from "./widgets/BrokeragePlanningPerformance";

export class BrokeragePlanningPerformanceProfit extends AutogenLayoutBrokeragePlanningPerformanceProfit {
    public perfHeaderRow: ModelRow;
    private brokeragePlanningPerformance: BrokeragePlanningPerformance;

    onLoad() {
        this.chartProfit.renderDefaultData = false;
        this.brokeragePlanningPerformance = this.owner as BrokeragePlanningPerformance;
    }

    /** This is an event handler for the onDisplay event of sourceBPPerfProfit.  */
    sourceBPPerfProfitOnDisplay(event: DataDisplayEvent) {
        LogicBrokeragePlanningPerformance.setTableColumnHeaders(this.tableBPPerfProfit, this.perfHeaderRow);
        const brokerageStatusesToChart = ["Covered", "In Progress", "Delivered", "Total"];
        LogicBrokeragePlanningPerformance.createChartData(this.tableBPPerfProfit, this.chartProfit, this.mainDataSource.data, this.perfHeaderRow, brokerageStatusesToChart);
        this.chartProfit.renderChart();
    }

    /** This is an event handler for the onDataDisplay event of tableBPPerfProfit.  */
    tableBPPerfProfitOnRowDisplay(event: TableRowDisplayEvent) {
        const percentValuePresent = event.target.data.get("day_0_percent", null) != null;
        const tableRow = event.target as TableRow;
        tableRow.forEveryChildComponent((component: Component) => {
            const field = component.field;
            if (!(field.startsWith("day_") || field.startsWith("total")))
                return;
            component.visible = (field.endsWith("_percent") && percentValuePresent) || (!field.endsWith("_percent") && !percentValuePresent);
        });
    }

    cellLabelOnClick(event: ClickEvent) {
        LogicBrokeragePlanningPerformance.cellLabelOnClick(event, this.perfHeaderRow, this.brokeragePlanningPerformance.currentProfile);
    }
}
