/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelRevenueCode.ts                   *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/ar/revenue-code";

export class AutogenModelRevenueCode<RowType extends ModelRow> extends Endpoint<RowType, RowRevenueCodeFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowRevenueCode extends ModelRow<RowRevenueCodeFields> {
    constructor(values?: Partial<RowRevenueCodeFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowRevenueCodeFields {
    company_id: string;
    descr: string;
    expense_glid: string;
    id: string;
    order_mode: string;
    revenue_glid: string;
}

