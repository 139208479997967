import { ChangeEvent } from "./ChangeEvent";
import { DomEvent } from "./DomEvent";
import { EventListener } from "./Event";

export interface SelectionListener extends EventListener {
    (event: SelectionEvent): void;
}

export class SelectionEvent extends ChangeEvent {
    private _oldSelection: any;
    private _newSelection: any;

    constructor(component: any, newSelection: any, newIndex: number, oldSelection: any, oldIndex: number, domEvent?: DomEvent) {
        super(component, oldIndex, newIndex, domEvent);
        this._oldSelection = oldSelection;
        this._newSelection = newSelection;
    }

    public get oldSelection(): any {
        return this._oldSelection;
    }

    public get newSelection(): any {
        return this._newSelection;
    }
}
