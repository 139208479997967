import { ClickEvent, DataSourceExecutionEvent, DataSourceMode, DataSourceModeChangeEvent, Location, LookupModelSearchEvent, Panel, StepEvent, Textbox } from "@mcleod/components";
import { DateRange, ModelRow, StringUtil, getLogger } from "@mcleod/core";
import { makeTooltipCallbackFunction } from "@mcleod/dispatch/src/CustomerMakeQuickInfo";
import { makeTooltipCallbackFunction as makeLocationQuickInfo } from "@mcleod/dispatch/src/LocationMakeQuickInfo";
import { ExpandableFilterController } from "@mcleod/general/src/ExpandableFilterController";
import { getDispatchControlBoolean } from "@mcleod/general/src/models/ModelDispatchControl";
import { AutogenLayoutQuickQuote } from "./autogen/AutogenLayoutQuickQuote";

enum QuoteStep {
    QUOTE_ENTRY = 0,
    SUGGESTED_PRICING = 1,
    NEXT_ACTIONS = 2
}

const log = getLogger("lme.powerbroker.QuickQuote");

export class QuickQuote extends AutogenLayoutQuickQuote {
    private expandableFilter: ExpandableFilterController = new ExpandableFilterController();

    override onLoad() {
        this.configureSaveButton();
        this.setupExpandableFilter();
        this.setCustomerNameListeners();
        this.setupQuoteStopTable();
    }

    setCustomerNameListeners() {
        this.textboxCustomerName.onSelectItem = (textbox, selectedItem) => {
            const modelRow = selectedItem as ModelRow;
            this?.activeRow.set("customer_id", modelRow.data["id"]);
            this?.activeRow.set("salesperson_id", modelRow.data["salesperson_id"]);
            this.clearCustomerContactInfo();
            this.setCustomerNameTooltip();
            this.layoutQuoteStopTable.tableStop.rows?.forEach(tableRow => {
                const location = tableRow["layoutQuoteStop"].locationStop as Location
                location.googlePlacesProps.customerId = this.mainDataSource.activeRow?.get("customer_id");
            });
            return undefined;
        }
        this.textboxCustomerName.addDataDisplayListener(event => this.setCustomerNameTooltip());
        this.textboxCustomerName.addChangeListener(event => {
            if (StringUtil.isEmptyString(event.newValue)) {
                this.activeRow.set("customer_name", null);
                this.activeRow.set("customer_id", null);
                this.clearCustomerContactInfo();
            }
        });
    }

    private clearCustomerContactInfo() {
        this.activeRow.set("customer_contact_name", null);
        this.activeRow.set("customer_phone", null);
        this.activeRow.set("customer_email", null);
        this.textboxCustomerContactName.clear();
    }

    private async setupQuoteLanding() {
        // There is an issue when simulatenously loading QuoteRates in Step 2 and Step 3. (Invalid parentDataSource)
        // This is a workaroud, where we manually set the datasources for QuoteLanding for QuickQuote process
        this.layoutQuoteLanding.panelNextActionsAdd.visible = true;
        await this.layoutQuoteLanding.mainDataSource.search({ "id": this.activeRow.get("id") });
        const updatedDataSource = this.layoutQuoteLanding.mainDataSource;
        this.buttonSave.dataSource = updatedDataSource;
        this.layoutQuoteLanding.saveButton = this.buttonSave;
    }

    private setupQuoteStopTable() {
        this.layoutQuoteStopTable.orderSource = this.mainDataSource;
    }

    setCustomerNameTooltip() {
        const lookupModelData = this.textboxCustomerName.getFirstLookupModelData();
        this.textboxCustomerName.tooltipCallback = makeTooltipCallbackFunction(lookupModelData?.get("id"), this.textboxCustomerName);
    }

    configureSaveButton(index?: number) {
        const lastStep = this.stepperQuickQuote.stepCount == (index ?? this.stepperQuickQuote.selectedIndex) + 1;
        this.buttonSave.visible = index == QuoteStep.SUGGESTED_PRICING || lastStep;
        this.buttonSave.caption = index == QuoteStep.SUGGESTED_PRICING ? "Generate Quote" : "Submit";
        this.buttonSave.width = index == QuoteStep.SUGGESTED_PRICING ? 150 : 125;
        this.buttonContinue.visible = index !== QuoteStep.SUGGESTED_PRICING && !lastStep;
    }

    checkStopsForDistanceChange(): boolean {
        const stopdata = this.sourceQuoteStop.data;
        if (this.sourceQuoteStop.deletedData?.length > 0) return true;
        for (let i = 0; i < stopdata?.length; i++) {
            if (stopdata[i]._appending || stopdata[i].getChangedData()?.city_id != null) return true;
        }
        return false;
    }

    buttonContinueOnClick(event: ClickEvent) {
        const stepper = this.stepperQuickQuote;
        stepper.selectedIndex = stepper.selectedIndex + 1;
    }

    textboxCustomerNameBeforeLookupModelSearch(event: LookupModelSearchEvent) {
        event.filter.is_customer = "Y";
    }

    textboxContactNameBeforeLookupModelSearch(event) {
        const contactName = event.target as Textbox;
        event.filter.parent_row_id = this.activeRow.get("customer_id") ?? null;
        event.filter.parent_row_type = "C";
        event.filter.is_active = "Y";

        if (contactName != null && contactName.onSelectItem == null) {
            contactName.onSelectItem = ((textbox, selection) => {
                if (selection != null) {
                    this.activeRow.set("customer_phone", (selection as ModelRow).get("phone"));
                    this.activeRow.set("customer_email", (selection as ModelRow).get("email"));
                }
                return undefined;
            });
        }
    }

    populateAdditionalPricingInformation() {
        this.populateLaneHistoryInformation();
        this.populateRateIndexInformation();
    }

    populateRateIndexInformation() {
        this.layoutRateIndex.resetTotalRatesPanel();
        this.layoutRateIndex.hideColumns();
        this.layoutRateIndex.buttonFetchOtherResults.visible = true;
        const stopData = this.sourceQuoteStop.data.map(value => value.data);
        this.layoutRateIndex.marginBottom = 20;
        this.layoutRateIndex.search({
            stops: stopData,
            mileage: this.activeRow.get("bill_distance"),
            equipment_id: this.activeRow.get("equipment_type_id")
        }).then((response) => {
            if (response) {
                const data = response["modelRows"][0].data;
                for (const vendorData of data["vendors_data"]) {
                    this.layoutRateIndex.populateRateIndexResultData(vendorData, false, data["show_company_scac"], null)
                }
            } else {
                log.error("Error fetching rate index results for Quick Quote");
            }
        });
    }

    populateLaneHistoryInformation() {
        const findNearCityId = this.sourceQuoteStop.data[0].get("city_id");
        const destinationState = this.sourceQuoteStop.data[this.sourceQuoteStop.data.length - 1].get("state");
        this.textboxRadius.text = "75";
        this.layoutLaneHistory.search({ find_near_radius: 75, days_back: 180, find_near_city_id: findNearCityId, destination_state: destinationState });
    }

    setStopData() {
        const shipperStop = this.sourceQuoteStop.data[0];
        const consigneeStop = this.sourceQuoteStop.data[this.sourceQuoteStop.data.length - 1];
        this.mainDataSource.activeRow.setValues({
            "stop_origin.location_name": shipperStop.get("location_name"),
            "stop_origin.city_name": shipperStop.get("city_name"),
            "stop_origin.state": shipperStop.get("state"),
            "stop_consignee.location_name": consigneeStop.get("location_name"),
            "stop_consignee.city_name": consigneeStop.get("city_name"),
            "stop_consignee.state": consigneeStop.get("state"),
        })
        this.citystateShipperCS.tooltipCallback = makeLocationQuickInfo(shipperStop.get("location_id"), this.citystateShipperCS);
        this.citystateConsigneeCS.tooltipCallback = makeLocationQuickInfo(consigneeStop.get("location_id"), this.citystateConsigneeCS);
        this.citystateStopOriginCityId.displayData(shipperStop, null, 0);
        this.mainDataSource.displayDataInBoundComponents();
    }

    async stepperQuickQuoteBeforeStepListener(event: StepEvent) {
        this.mainDataSource.displayDataInBoundComponents();
        switch (event.oldIndex) {
            case QuoteStep.QUOTE_ENTRY:
                if (!this.layoutQuoteStopTable.validateStops()) {
                    event.preventDefault();
                    return;
                }
                this.setStopData();
                break;
            case QuoteStep.NEXT_ACTIONS:
                event.preventDefault();
                return;
        }

        switch (event.newIndex) {
            case QuoteStep.SUGGESTED_PRICING:
                if (event.oldIndex == QuoteStep.QUOTE_ENTRY) {
                    if (this.checkStopsForDistanceChange()) {
                        const rateId = this.activeRow.get("rate_id");
                        this.activeRow.set("bill_distance", null);
                        await this.layoutQuoteRates.calculateRates(false, getDispatchControlBoolean("est_fuel_surcharge"), rateId);
                        if (this.layoutQuoteRates.buttonExpand.imageRotation != 90) {
                            this.layoutQuoteRates.buttonExpand.clicked(); // toggle collapse of additional charges as default view
                        }
                        this.layoutQuoteRates.textboxRateType.focus();
                        this.populateAdditionalPricingInformation();
                    }
                }
                break;
            case QuoteStep.NEXT_ACTIONS:
                if (this.activeRow.isNull("id")) {
                    event.preventDefault();
                    return;
                }
                break;
        }
        this.configureSaveButton(event.newIndex);
    }
    // END - Component Events

    // START - Expandable Filter Logic
    private setupExpandableFilter() {
        const panelSearchHeader = this.tabLaneHistory.findComponentById("panelSearchHeader") as Panel;
        const panelSearchFilter = this.tabLaneHistory.findComponentById("panelSearchFilter") as Panel;
        this.expandableFilter.initialize(panelSearchHeader, panelSearchFilter);
        this.expandableFilter.toggle();
        this.expandableFilter.onClear = (event) => this.clearLaneHistoryFilter(event);
        this.expandableFilter.onSearch = (event) => this.searchLaneHistoryFilter(event);
    }

    private clearLaneHistoryFilter(event: ClickEvent) {
        this.sourceLaneHistory.searchRow.clear();
        this.textboxRadius.clear();
        this.textboxDateRange.clear();
        this.textboxMovementStatus.clear();
        this.textboxMovementStatus.selectedItem = null;
    }

    private searchLaneHistoryFilter(event: ClickEvent) {
        const stopdata = this.sourceQuoteStop.data;
        const findNearCityId = stopdata[0].get("city_id");
        const destinationState = stopdata[stopdata.length - 1].get("state");
        const dateRange = DateRange.parseNumericDateRange(this.textboxDateRange.getDataValue()) as DateRange;
        const status = this.textboxMovementStatus.getDataValue();
        const searchParams = {
            "date_range.start": dateRange.beginningDate ?? null,
            "date_range.end": dateRange.endDate ?? null,
            find_near_radius: !StringUtil.isEmptyString(this.textboxRadius.getDataValue()) ? this.textboxRadius.getDataValue() : 75,
            days_back: dateRange.beginningDate && dateRange.endDate ? null : 180,
            find_near_city_id: findNearCityId,
            destination_state: destinationState,
            status: status ?? null
        }
        this.layoutLaneHistory.search(searchParams);
    }
    // END - Expandable Filter Logic

    // START - Commodity Code
    /** This is an event handler for the onChange event of textboxCommodityId.  */
    textboxCommodityIdOnChange(event) {
        if (event.userInitiatedChange) {
            this.updateCommodityCode();
        }
    }

    private updateCommodityCode() {
        const commodityCleared = this.activeRow.isNull("commodity_id");
        const lookupData = commodityCleared ? null : this.textboxCommodityId.getFirstLookupModelData();
        this.activeRow.set("commodity", lookupData?.get("descr"));
        this.showHazmatWarning();
    }

    private showHazmatWarning() {
        const lookupData = this.textboxCommodityId.getFirstLookupModelData();
        const hazmat = lookupData?.get("is_hazmat");
        this.panelQuoteOrderFields.marginTop = hazmat === "Y" ? 35 : 0;
        this.panelCommodityHazmat.padding = hazmat === "Y" ? 9 : 0;
        this.panelCommodityHazmat.borderWidth = hazmat === "Y" ? 1 : 0;
        this.panelCommodityHazmat.borderColor = hazmat === "Y" ? "error" : "";
        this.labelHazmatCommodity.visible = this.imageHazmat.visible = hazmat === "Y";
    }
    // END - Commodity Code

    // START - Datasource Events
    async sourceQuoteStopAfterModeChange(event: DataSourceModeChangeEvent) {
        this.textboxWeight.addBlurListener(event => { if (event.changedWhileFocused) this.layoutQuoteRates.calculateRates(false, false) });
    }

    sourceQuoteOrderAfterExecution(event: DataSourceExecutionEvent) {
        if (!this.activeRow.isNull("id")) {
            this.buttonSave.allowSaveAndClose = this.dataheaderQuote.showClose;
            this.buttonSave.allowSave = !this.dataHeader.showClose;
            this.stepperQuickQuote.selectedIndex = QuoteStep.NEXT_ACTIONS;
            this.setupQuoteLanding();
        }
    }

    sourceQuoteOrderBeforeModeChange(event: DataSourceModeChangeEvent) {
        if (event.newMode !== DataSourceMode.ADD) {
            this.title = "Quote Number " + this.activeRow.get("id");
            this.hideSuffix = true;
        }
    }
    // END - Datasource Events
}
