import { Button, Component, DataSource, DesignerInterface, Panel } from "@mcleod/components";
import { ReflectiveDialogs } from "@mcleod/components/src/base/ReflectiveDialogs";
import { Navigation } from "@mcleod/core";
import { DesignerDataSource } from "../DesignerDataSource";
import { PanelOpenModel } from "../PanelOpenModel";
import { McLeodTailor } from "../custom/McLeodTailor";
import { DesignerTab } from "./DesignerTab";

export class DesignerDataSourcePanel extends Panel {
    private targetPanel: Panel;
    private buttonAdd: Button;
    private buttonModelView: Button;

    constructor(tab: DesignerTab, props?) {
        super(null, false);
        this._shouldAddDesignerContainerProperties = false;
        this.setProps({ fillRow: true, borderColor: "strokeSecondary", borderWidth: 1, marginTop: 16, ...props });
        this.targetPanel = new Panel({ fillRow: true });
        this.add(this.targetPanel);
        if ((tab.designer instanceof McLeodTailor) !== true) {
            this.minHeight = 80;
            this.buttonAdd = new Button({ width: 140, height: 26, padding: 4, color: "primary", caption: "Add datasource", imageName: "add", rowBreak: false });
            this.buttonAdd.addClickListener(event => {
                tab.modified = true;
                let dsName: string;
                for (let nextNum = 1; dsName == null && nextNum < 1000; nextNum++)
                    if (tab.dataSources["source" + nextNum] == null)
                        dsName = "source" + nextNum;
                const dataSource = new DataSource({ id: dsName }, null, tab._designer, tab);
                tab.dataSources[dataSource.id] = dataSource;
                const dsPanel = new DesignerDataSource(tab.designer, dataSource);
                this.addDesignerDataSource(dsPanel);
                tab.designer.selectComponent(dsPanel, false);
                this.showEndpointSelector(dataSource, tab._designer);
            });
            this.buttonModelView = new Button({ width: 140, height: 26, padding: 4, color: "primary", caption: "View model", imageName: "magnifyingGlass", enabled: false });
            this.buttonModelView.addClickListener((event) => {
                tab.modified = true;
                const datasource = tab.designer.selectedComponents[0] as DesignerDataSource;
                if (datasource.designerDataSource.url != null)
                    Navigation.navigateTo("designer/model/ModelDesigner?open=" + datasource.designerDataSource.url, { newTab: true, windowDecorators: false });
            });
            this.add(this.buttonAdd);
            this.add(this.buttonModelView);
        }
    }

    addDesignerDataSource(value: DesignerDataSource) {
        this.targetPanel.add(value);
    }

    getDesignerDataSourceRecursiveChildren(): Component[] {
        return this.targetPanel.getRecursiveChildren();
    }

    enableButtonModelView(value: boolean) {
        if (this.buttonModelView != null)
            this.buttonModelView.enabled = value;
    }

    updateIds() {
        for (const dsPanel of this.targetPanel)
            (dsPanel as DesignerDataSource).updateId();
    }

    showEndpointSelector(dataSource: DataSource, designer: DesignerInterface) {
        const sel = new PanelOpenModel();
        ReflectiveDialogs.showDialog(sel, { height: 600, width: 500, title: "Select Endpoint" }).then(() => {
            const result = sel.getValue();
            if (result !== undefined) {
                dataSource.url = result;
                designer.redisplayProp("url", result);
                designer.displayDataSourceTools();
            }
        });
    }
}
