import { Button, Checkbox, DataSource, HorizontalSpacer, Label, Layout, Location, Panel, Table, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutRecOrderRates extends Layout {
    buttonEstimateFuelSurcharge: Button;
    buttonExpandCharges: Button;
    buttonRate: Button;
    checkboxLockMiles: Checkbox;
    horizontalspacer3: HorizontalSpacer;
    horizontalspacer4: HorizontalSpacer;
    labelAdditionalCharges: Label;
    labelAutoRated: Label;
    labelFreightCharge: Label;
    labelTotalCharge: Label;
    panel1: Panel;
    panel2: Panel;
    panel3: Panel;
    panel4: Panel;
    panel5: Panel;
    panel8: Panel;
    panelFreightCharge: Panel;
    panelOtherChargeTable: Panel;
    panelOtherCharges: Panel;
    panelOtherChargesHeader2: Panel;
    panelOtherChargesHeader: Panel;
    panelRateTotals: Panel;
    panelRatesHeader: Panel;
    sourceOrderOtherChargeRec: DataSource;
    sourceRecurringOrder: DataSource;
    tableOtherCharges: Table;
    textboxBillDistance: Textbox;
    textboxCollectionMethod: Textbox;
    textboxCtrlParty: Location;
    textboxFreightCharge2: Textbox;
    textboxFreightCharge: Textbox;
    textboxOtherchargetotal: Textbox;
    textboxPieces: Textbox;
    textboxRate: Textbox;
    textboxRateExpiration: Textbox;
    textboxRateMinWeight: Textbox;
    textboxRateType: Textbox;
    textboxRateUnits: Textbox;
    textboxSegAllocCode: Textbox;
    textboxTotalCharge: Textbox;
    textboxWeight: Textbox;
}
