import { Button, DataSource, Image, Label, Layout, Panel, Table, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutCommodityQuickInfo extends Layout {
    buttonOpenCommodity: Button;
    labelHazmat: Image;
    labelLoadRequirements: Label;
    panelCommodity: Panel;
    panelLoadRequirements: Panel;
    sourceCommodity: DataSource;
    sourceEquipMatchDetail: DataSource;
    tableLoadRequirements: Table;
    textboxDescr: Textbox;
    textboxHazmatNumber: Textbox;
    textboxId: Textbox;
}
