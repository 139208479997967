import { Button, DataSource, HorizontalSpacer, Label, Layout, Panel, Table } from "@mcleod/components";

export abstract class AutogenLayoutBrltlFreightItemTable extends Layout {
    buttonAddItem: Button;
    horizontalspacer1: HorizontalSpacer;
    labelHandlingUnitsCaption: Label;
    labelTotalUnits: Label;
    labelTotalWeight: Label;
    labelWeightCaption: Label;
    panelFrightInfo: Panel;
    sourceBrltlOrderFreightGroupItem: DataSource;
    sourceQuoteFreightGroupItem: DataSource;
    tableFreightItems: Table;
}
