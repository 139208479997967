import {AutogenLayoutTableContact} from "./autogen/AutogenLayoutTableContact";
import {BlurEvent, ChangeEvent, Switch, TableRow, Textbox} from "@mcleod/components";
import {StringUtil} from "@mcleod/core";

export class TableContact extends AutogenLayoutTableContact {

    switchSmsOptInOnChange(event: ChangeEvent){
        const optInSwitch = event.target as Switch;
        const today = new Date();
        const tableRow = TableRow.getContainingTableRow(optInSwitch);
        const textboxOptOutDate = tableRow.findComponentById("textboxOptOutDate") as Textbox;
        if(textboxOptOutDate !== undefined){
            if(optInSwitch.checked) {
                textboxOptOutDate.clear();
            }else{
                textboxOptOutDate.text = today.toLocaleDateString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric'
                });
            }
        }
    }

    textboxOptOutDateOnBlur(event: BlurEvent){
        const textboxOptOutDate = event.target as Textbox;
        const tableRow = TableRow.getContainingTableRow(textboxOptOutDate);
        const optInSwitch = tableRow.findComponentById("switchSmsOptIn") as Switch;
        if (!StringUtil.isEmptyString(textboxOptOutDate.text)) {
            const optOutDate = new Date(textboxOptOutDate.text);
            const currentDate = new Date();
            if (optOutDate <= currentDate) {
                optInSwitch.checked = false;
            }
        }
    }
}
