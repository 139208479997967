import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenLayoutEndpoint = "layout";

export class AutogenModelLayout<RowType extends ModelRow> extends Endpoint<RowType, RowLayoutFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenLayoutEndpoint, ctor);
    }
}

export class AutogenRowLayout extends ModelRow<RowLayoutFields> {
    constructor() {
        super(AutogenLayoutEndpoint);
    }
}

export interface RowLayoutFields {
    path: string;
    apply_field_level_perms: boolean;
    apply_field_level_licensing: boolean;
    definition: string;
    response: string;
}
