import { DataLoadActionHandler, DataLoadActionType } from "@mcleod/common";
import { ClickEvent, DataSourceExecutionEvent } from "@mcleod/components";
import { Navigation } from "@mcleod/core";
import { AutogenLayoutOutstandingLoadTenderCounts } from "./autogen/AutogenLayoutOutstandingLoadTenderCounts";

export class OutstandingLoadTenderCounts extends AutogenLayoutOutstandingLoadTenderCounts {
    labelOriginalCountOnClick(event: ClickEvent) {
        const count = this.mainDataSource.activeRow.get("original_count");
        if (count > 0) {
            this.navigateToLtx("tabOriginal");
        }
    }

    labelChangeCountOnClick(event: ClickEvent) {
        const count = this.mainDataSource.activeRow.get("change_count");
        if (count > 0) {
            this.navigateToLtx("tabChange");
        }
    }

    labelCancelCountOnClick(event: ClickEvent) {
        const count = this.mainDataSource.activeRow.get("cancel_count");
        if (count > 0) {
            this.navigateToLtx("tabCancel");
        }
    }

    labelAllCountOnClick(event: ClickEvent) {
        const count = this.mainDataSource.activeRow.get("overall_count");
        if (count > 0) {
            this.navigateToLtx("tabAll");
        }
    }

    private navigateToLtx(tabId: string) {
        if (tabId === "tabAll") {
            Navigation.navigateTo("lme/datafusion/LoadTenderExpress", { newTab: false });
        }
        else {
            const dlaSnippet = DataLoadActionHandler.createUrlQueryParamString(DataLoadActionType.SELECT_TAB, tabId);
            Navigation.navigateTo("lme/datafusion/LoadTenderExpress?" + dlaSnippet, { newTab: false });
        }
    }

    /** This is an event handler for the afterExecution event of sourceLtxOutstandingCounts.  */
    sourceLtxOutstandingCountsAfterExecution(event: DataSourceExecutionEvent) {
        this.labelOriginalCount._element.style.display = ''
        this.labelChangeCount._element.style.display = ''
        this.labelCancelCount._element.style.display = ''
        this.labelOverallCount._element.style.display = ''
    }
}
