/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelLaneHistory.ts                   *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/dispatch/lane-history";

export class AutogenModelLaneHistory<RowType extends ModelRow> extends Endpoint<RowType, RowLaneHistoryFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowLaneHistory extends ModelRow<RowLaneHistoryFields> {
    constructor(values?: Partial<RowLaneHistoryFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowLaneHistoryFields {
    authorized: string;
    average_broker_rating: number;
    booking_no: string;
    br_details_link: string;
    br_status_link: string;
    br_track_interval: number;
    br_track_status: string;
    br_track_status_desc: string;
    br_track_vehicle_id: string;
    br_tracking_id: string;
    br_vendor: string;
    broker_avail_tract: string;
    brokerage: string;
    brokerage_status: string;
    carrier_contact: string;
    carrier_email: string;
    carrier_empty_from_city_id: number;
    carrier_fuel_pay_rate_id: number;
    carrier_lane_rate_id: string;
    carrier_override_user: string;
    carrier_phone: string;
    carrier_rate_id: number;
    carrier_tractor: string;
    carrier_trailer: string;
    carrier_trailer_type: string;
    chassis: string;
    checkcall_sched_id: string;
    company_id: string;
    container: string;
    container_returned: string;
    container_status: string;
    controlling_carrier_code: string;
    "customer.activity_count": number;
    "customer.activity_start_date": Date;
    "customer.add_empty_distance_occ": string;
    "customer.add_finance_charge": string;
    "customer.address1": string;
    "customer.address2": string;
    "customer.allow_inc_in_frt": string;
    "customer.auto_rate": string;
    "customer.available_template": number;
    "customer.average_bill": number;
    "customer.average_bill_c": string;
    "customer.average_bill_d": Date;
    "customer.average_bill_n": number;
    "customer.average_bill_r": number;
    "customer.average_pay_days": number;
    "customer.balance": number;
    "customer.balance_c": string;
    "customer.balance_d": Date;
    "customer.balance_n": number;
    "customer.balance_r": number;
    "customer.benchmark_ctg_uid": number;
    "customer.benchmark_tar_uid": number;
    "customer.bill_due_days": number;
    "customer.bill_format_flag": string;
    "customer.bill_template": number;
    "customer.bill_transfer_when": string;
    "customer.billed_loads": number;
    "customer.bl_logo": string;
    "customer.bl_logo_name": string;
    "customer.blanket_buy_tariff_id": string;
    "customer.blanket_sell_tariff_id": string;
    "customer.bridge_id": string;
    "customer.brokerage_filter": string;
    "customer.business_hours": string;
    "customer.business_number": string;
    "customer.buy_tariff_id": string;
    "customer.category": string;
    "customer.cert_of_ins_date": Date;
    "customer.charge_detention": string;
    "customer.chassis_chargeby": string;
    "customer.chassis_freedays": number;
    "customer.chassis_rate": number;
    "customer.chassis_rate_c": string;
    "customer.chassis_rate_d": Date;
    "customer.chassis_rate_n": number;
    "customer.chassis_rate_r": number;
    "customer.city": string;
    "customer.city_id": number;
    "customer.coll_thresh_pd1": number;
    "customer.coll_thresh_pd1_c": string;
    "customer.coll_thresh_pd1_d": Date;
    "customer.coll_thresh_pd1_n": number;
    "customer.coll_thresh_pd1_r": number;
    "customer.coll_thresh_pd2": number;
    "customer.coll_thresh_pd2_c": string;
    "customer.coll_thresh_pd2_d": Date;
    "customer.coll_thresh_pd2_n": number;
    "customer.coll_thresh_pd2_r": number;
    "customer.coll_thresh_pd3": number;
    "customer.coll_thresh_pd3_c": string;
    "customer.coll_thresh_pd3_d": Date;
    "customer.coll_thresh_pd3_n": number;
    "customer.coll_thresh_pd3_r": number;
    "customer.coll_thresh_pd4": number;
    "customer.coll_thresh_pd4_c": string;
    "customer.coll_thresh_pd4_d": Date;
    "customer.coll_thresh_pd4_n": number;
    "customer.coll_thresh_pd4_r": number;
    "customer.collections_id": string;
    "customer.collections_ok": string;
    "customer.company_id": string;
    "customer.confidence_level": number;
    "customer.container_chargeby": string;
    "customer.container_freedays": number;
    "customer.container_rate": number;
    "customer.container_rate_c": string;
    "customer.container_rate_d": Date;
    "customer.container_rate_n": number;
    "customer.container_rate_r": number;
    "customer.contract_cbc": string;
    "customer.contract_ccc": string;
    "customer.contract_gbc": string;
    "customer.contract_gcc": string;
    "customer.contract_sbc": string;
    "customer.contract_scc": string;
    "customer.controlling_carrier_code": string;
    "customer.conversion_date": Date;
    "customer.cost_ctg_uid": number;
    "customer.cost_tar_uid": number;
    "customer.covered_template": number;
    "customer.cp_logo": string;
    "customer.cp_logo_name": string;
    "customer.credit_application": string;
    "customer.credit_check_date": Date;
    "customer.credit_limit": number;
    "customer.credit_limit_c": string;
    "customer.credit_limit_d": Date;
    "customer.credit_limit_n": number;
    "customer.credit_limit_r": number;
    "customer.credit_status": string;
    "customer.credit_warning_pct": number;
    "customer.ctg_uid": number;
    "customer.currency_type": string;
    "customer.cust_requestor_load_id": string;
    "customer.cust_stmt_template": number;
    "customer.d_and_b_code": string;
    "customer.d_and_b_date": Date;
    "customer.d_and_b_number": string;
    "customer.days_between_calls": number;
    "customer.deadhead_target": number;
    "customer.ded_last_fixed_cost_billdt": Date;
    "customer.dedicated_cycle": string;
    "customer.dedicated_revenue_code_id": string;
    "customer.dedicated_seg_alloc_code": string;
    "customer.dedicated_start_day": string;
    "customer.dedicated_template": number;
    "customer.def_rate_confirm_temp": number;
    "customer.delivered_template": number;
    "customer.departure_date_at": string;
    "customer.disable_automatic_tracking": string;
    "customer.display_eta": string;
    "customer.display_ysplit_stops": string;
    "customer.distance_method": string;
    "customer.distance_profile": string;
    "customer.distance_source": string;
    "customer.distance_type": string;
    "customer.dot_number": string;
    "customer.driver_load": string;
    "customer.empty_distance_charge_id": string;
    "customer.empty_distance_rate": number;
    "customer.enable_notifications": string;
    "customer.enforce_dep_dates": string;
    "customer.entered_date": Date;
    "customer.excise_taxable": string;
    "customer.expected_close": Date;
    "customer.extend_rates": string;
    "customer.factor_percent": number;
    "customer.factor_receivables": string;
    "customer.factoring_id": string;
    "customer.fak_profile_id": string;
    "customer.fourkites_customer_id": string;
    "customer.fuel_amount": number;
    "customer.fuel_base_price": number;
    "customer.fuel_credit": string;
    "customer.fuel_method": string;
    "customer.fuel_percent": number;
    "customer.fuel_price_region": string;
    "customer.fuel_surcharge_id": string;
    "customer.fuel_surchg_enddt": Date;
    "customer.fuel_surchg_strtdt": Date;
    "customer.fuel_variance": number;
    "customer.global_detention": string;
    "customer.google_place_id": string;
    "customer.gp_exclude": string;
    "customer.gp_exported": Date;
    "customer.grace_period": number;
    "customer.high_balance": number;
    "customer.high_balance_c": string;
    "customer.high_balance_d": Date;
    "customer.high_balance_n": number;
    "customer.high_balance_r": number;
    "customer.ic_company_id": string;
    "customer.id": string;
    "customer.include_by_date": string;
    "customer.include_empty_distance": string;
    "customer.include_fuel_surcharge": string;
    "customer.include_split_point": string;
    "customer.is_active": string;
    "customer.is_consignee": string;
    "customer.is_distribution_center": string;
    "customer.is_drop_yard": string;
    "customer.is_geocoded": string;
    "customer.is_intercompany": string;
    "customer.is_pallets_required": string;
    "customer.is_shipper": string;
    "customer.is_trailer_pool_required": string;
    "customer.jaguar_id": number;
    "customer.last_bill_date": Date;
    "customer.last_fin_charge_dt": Date;
    "customer.last_pay_date": Date;
    "customer.last_ship_date": Date;
    "customer.latitude": number;
    "customer.location_id": string;
    "customer.longitude": number;
    "customer.macropoint_requestor_id": string;
    "customer.main_phone": string;
    "customer.manual_exchange": string;
    "customer.mark_plan_id": string;
    "customer.max_pay_percent": number;
    "customer.misc_bill_template": number;
    "customer.monthly_order_goal": number;
    "customer.motor_carrier_id": string;
    "customer.name": string;
    "customer.next_call_date": Date;
    "customer.next_rec_call": Date;
    "customer.nxt_action_code_id": string;
    "customer.nxt_action_date": Date;
    "customer.operations_user": string;
    "customer.order_count": number;
    "customer.order_detail_at": string;
    "customer.order_start_date": Date;
    "customer.p44_profile_id": string;
    "customer.paid_loads": number;
    "customer.pay_days_date": Date;
    "customer.pay_days_orders": number;
    "customer.payee_id": string;
    "customer.pd0_alert": number;
    "customer.pd1_np_auto": string;
    "customer.pd1_sp_auto": string;
    "customer.pd1_sp_thresh": number;
    "customer.pd1_sp_thresh_c": string;
    "customer.pd1_sp_thresh_d": Date;
    "customer.pd1_sp_thresh_n": number;
    "customer.pd1_sp_thresh_r": number;
    "customer.pd2_np_auto": string;
    "customer.pd2_sp_auto": string;
    "customer.pd2_sp_thresh": number;
    "customer.pd2_sp_thresh_c": string;
    "customer.pd2_sp_thresh_d": Date;
    "customer.pd2_sp_thresh_n": number;
    "customer.pd2_sp_thresh_r": number;
    "customer.pd3_np_auto": string;
    "customer.pd3_sp_auto": string;
    "customer.pd3_sp_thresh": number;
    "customer.pd3_sp_thresh_c": string;
    "customer.pd3_sp_thresh_d": Date;
    "customer.pd3_sp_thresh_n": number;
    "customer.pd3_sp_thresh_r": number;
    "customer.pd4_np_auto": string;
    "customer.pd4_sp_auto": string;
    "customer.pd4_sp_thresh": number;
    "customer.pd4_sp_thresh_c": string;
    "customer.pd4_sp_thresh_d": Date;
    "customer.pd4_sp_thresh_n": number;
    "customer.pd4_sp_thresh_r": number;
    "customer.plc_uid": number;
    "customer.post_summary_dtl": string;
    "customer.potential": number;
    "customer.potential_c": string;
    "customer.potential_d": Date;
    "customer.potential_n": number;
    "customer.potential_r": number;
    "customer.prev_code": string;
    "customer.primary_agency": string;
    "customer.primary_commodity_id": string;
    "customer.primary_contact": string;
    "customer.primary_sort": string;
    "customer.primary_trailer_type": string;
    "customer.print_containers": string;
    "customer.progress_template": number;
    "customer.prospect_type_id": string;
    "customer.quebec_number": string;
    "customer.quote_days_to_expire": number;
    "customer.rate_override_nmfc_code": string;
    "customer.reassign_profile_id": string;
    "customer.rec_call_days": number;
    "customer.remarks": string;
    "customer.require_tracking": string;
    "customer.required_vendor": string;
    "customer.revenue": number;
    "customer.revenue_c": string;
    "customer.revenue_d": Date;
    "customer.revenue_n": number;
    "customer.revenue_r": number;
    "customer.revenue_start_date": Date;
    "customer.routing_guide_profile_id": string;
    "customer.sales_manager_id": string;
    "customer.sales_status_id": string;
    "customer.salesperson_id": string;
    "customer.search_city": string;
    "customer.search_state_id": string;
    "customer.search_zip_code": string;
    "customer.secondary_sort": string;
    "customer.sell_tariff_id": string;
    "customer.service_fail_cons": string;
    "customer.service_fail_drop": string;
    "customer.service_fail_pick": string;
    "customer.service_fail_ship": string;
    "customer.service_failures": string;
    "customer.service_failures_review": string;
    "customer.shipment_ident_type": string;
    "customer.sic_code": string;
    "customer.start_date": Date;
    "customer.state_id": string;
    "customer.summary_billing": string;
    "customer.summary_template": number;
    "customer.tar_uid": number;
    "customer.target_profit": number;
    "customer.tenfour_customer_id": string;
    "customer.tracking_hours_prior": number;
    "customer.trimble_place_id": string;
    "customer.type_of": string;
    "customer.use_local_mile": string;
    "customer.vm_part_update": string;
    "customer.waterfall_auto_route_guides": string;
    "customer.waterfall_lead_time": number;
    "customer.waterfall_notify_email": string;
    "customer.waterfall_process": string;
    "customer.website_url": string;
    "customer.wf_auto_renbill": string;
    "customer.wf_autobill": string;
    "customer.wf_bwop": string;
    "customer.wf_queue": number;
    "customer.zip_code": string;
    "customer.zone_id": string;
    dedicated_order_id: string;
    dest_stop_id: string;
    digital_booking: string;
    dispatcher_user_id: string;
    "drs_payee.adp_co_code": string;
    "drs_payee.adp_e_no": string;
    "drs_payee.agreement_dt": Date;
    "drs_payee.authority_reg_date": Date;
    "drs_payee.authority_reg_status": string;
    "drs_payee.authority_reg_type": string;
    "drs_payee.auto_create_dispatch_tractr": string;
    "drs_payee.bank_acct_type_name": string;
    "drs_payee.bank_address_1": string;
    "drs_payee.bank_address_2": string;
    "drs_payee.bank_city_name": string;
    "drs_payee.bank_state": string;
    "drs_payee.bank_zip_code": string;
    "drs_payee.basic_update": Date;
    "drs_payee.begin_vacation_dt": Date;
    "drs_payee.bond_filed": string;
    "drs_payee.bond_required": string;
    "drs_payee.box_for_1099_total": string;
    "drs_payee.broker_auth_grant_date": Date;
    "drs_payee.broker_auth_status": string;
    "drs_payee.c_tpat_flag": string;
    "drs_payee.c_tpat_svi": string;
    "drs_payee.calc_agent_pay": string;
    "drs_payee.carb_flag": string;
    "drs_payee.cargo_agri_farm": string;
    "drs_payee.cargo_beverages": string;
    "drs_payee.cargo_building_mat": string;
    "drs_payee.cargo_cancel_date": Date;
    "drs_payee.cargo_chemicals": string;
    "drs_payee.cargo_coal_coke": string;
    "drs_payee.cargo_commod_dry": string;
    "drs_payee.cargo_construction": string;
    "drs_payee.cargo_drive_tow": string;
    "drs_payee.cargo_email": string;
    "drs_payee.cargo_garbage": string;
    "drs_payee.cargo_gen_freight": string;
    "drs_payee.cargo_grain_feed": string;
    "drs_payee.cargo_household": string;
    "drs_payee.cargo_ins_amount": number;
    "drs_payee.cargo_ins_amount_c": string;
    "drs_payee.cargo_ins_amount_d": Date;
    "drs_payee.cargo_ins_amount_n": number;
    "drs_payee.cargo_ins_amount_r": number;
    "drs_payee.cargo_ins_on_file": string;
    "drs_payee.cargo_ins_renew_dt": Date;
    "drs_payee.cargo_ins_required": string;
    "drs_payee.cargo_insur_fax": string;
    "drs_payee.cargo_insur_phone": string;
    "drs_payee.cargo_insurer": string;
    "drs_payee.cargo_intermodal": string;
    "drs_payee.cargo_liquids_gas": string;
    "drs_payee.cargo_livestock": string;
    "drs_payee.cargo_logs_lumber": string;
    "drs_payee.cargo_machinery": string;
    "drs_payee.cargo_meat": string;
    "drs_payee.cargo_metal": string;
    "drs_payee.cargo_mobile_homes": string;
    "drs_payee.cargo_oil_equip": string;
    "drs_payee.cargo_other": string;
    "drs_payee.cargo_other_descr": string;
    "drs_payee.cargo_paper_prod": string;
    "drs_payee.cargo_passengers": string;
    "drs_payee.cargo_policy": string;
    "drs_payee.cargo_produce": string;
    "drs_payee.cargo_reefer_food": string;
    "drs_payee.cargo_related_oa": string;
    "drs_payee.cargo_related_ot": string;
    "drs_payee.cargo_related_pct": number;
    "drs_payee.cargo_related_sv": string;
    "drs_payee.cargo_us_mail": string;
    "drs_payee.cargo_utilities": string;
    "drs_payee.cargo_vehicles": string;
    "drs_payee.cargo_water_well": string;
    "drs_payee.carrier": string;
    "drs_payee.carrier_cont_type": string;
    "drs_payee.carrier_contract": string;
    "drs_payee.carrier_flat_count": number;
    "drs_payee.carrier_has_flats": string;
    "drs_payee.carrier_has_refers": string;
    "drs_payee.carrier_has_vans": string;
    "drs_payee.carrier_mgr": string;
    "drs_payee.carrier_ranking": number;
    "drs_payee.carrier_refe_count": number;
    "drs_payee.carrier_status_id": string;
    "drs_payee.carrier_van_count": number;
    "drs_payee.category": string;
    "drs_payee.child_contract": number;
    "drs_payee.common_auth_grant_date": Date;
    "drs_payee.common_auth_status": string;
    "drs_payee.company_id": string;
    "drs_payee.con_substance_oa": string;
    "drs_payee.con_substance_ot": string;
    "drs_payee.con_substance_sv": string;
    "drs_payee.cont_substance_pct": number;
    "drs_payee.contact": string;
    "drs_payee.contract_auth_grant_date": Date;
    "drs_payee.contract_auth_stat": string;
    "drs_payee.contract_authority": string;
    "drs_payee.contract_date": Date;
    "drs_payee.cr_union_wh": number;
    "drs_payee.cr_union_wh_c": string;
    "drs_payee.cr_union_wh_d": Date;
    "drs_payee.cr_union_wh_n": number;
    "drs_payee.cr_union_wh_r": number;
    "drs_payee.crashes_fatal": number;
    "drs_payee.crashes_injury": number;
    "drs_payee.crashes_total": number;
    "drs_payee.crashes_tow": number;
    "drs_payee.credit_approved": string;
    "drs_payee.credit_check_date": Date;
    "drs_payee.cxna_id": number;
    "drs_payee.date_entered": Date;
    "drs_payee.def_load_to_radius": number;
    "drs_payee.def_loc_radius": number;
    "drs_payee.default_bank_account": string;
    "drs_payee.department": string;
    "drs_payee.detention_max_pay_amt": number;
    "drs_payee.detention_max_pay_amt_c": string;
    "drs_payee.detention_max_pay_amt_d": Date;
    "drs_payee.detention_max_pay_amt_n": number;
    "drs_payee.detention_max_pay_amt_r": number;
    "drs_payee.detention_pay_rate": number;
    "drs_payee.detention_tm_earning_code": string;
    "drs_payee.detention_tm_max_pay_amt": number;
    "drs_payee.detention_tm_max_pay_amt_c": string;
    "drs_payee.detention_tm_max_pay_amt_d": Date;
    "drs_payee.detention_tm_max_pay_amt_n": number;
    "drs_payee.detention_tm_max_pay_amt_r": number;
    "drs_payee.detention_tm_pay_rate": number;
    "drs_payee.disable_automatic_tracking": string;
    "drs_payee.disable_electronic_offers": string;
    "drs_payee.distance_method": string;
    "drs_payee.distance_profile": string;
    "drs_payee.distance_source": string;
    "drs_payee.distance_type": string;
    "drs_payee.division_id": string;
    "drs_payee.dob_processed": string;
    "drs_payee.dot_number": string;
    "drs_payee.dray_rate": number;
    "drs_payee.driver_fitness_oa": string;
    "drs_payee.driver_fitness_ot": string;
    "drs_payee.driver_fitness_pct": number;
    "drs_payee.driver_fitness_sv": string;
    "drs_payee.driver_inspection": number;
    "drs_payee.driver_rating": string;
    "drs_payee.drivers": number;
    "drs_payee.drv_oos_inspection": number;
    "drs_payee.earning_code": string;
    "drs_payee.eforms_carrier_onboarding": string;
    "drs_payee.eforms_contract_alert": number;
    "drs_payee.eforms_contract_expire": number;
    "drs_payee.eforms_docrequest_alert": number;
    "drs_payee.eforms_docrequest_expire": number;
    "drs_payee.eforms_expirations": string;
    "drs_payee.eforms_profile_alert": number;
    "drs_payee.eforms_profile_expire": number;
    "drs_payee.eforms_qual_profile": string;
    "drs_payee.efs_account": string;
    "drs_payee.email": string;
    "drs_payee.empty_pay_rate": number;
    "drs_payee.equipment_type_id": string;
    "drs_payee.exclude_from_perf_calc": string;
    "drs_payee.expense_glid": string;
    "drs_payee.experience_date": Date;
    "drs_payee.factor_name": string;
    "drs_payee.factoring_company_id": string;
    "drs_payee.fatigue_drive_pct": number;
    "drs_payee.fatigued_drive_oa": string;
    "drs_payee.fatigued_drive_ot": string;
    "drs_payee.fatigued_drive_sv": string;
    "drs_payee.fax": string;
    "drs_payee.fuel_reimburse_id": string;
    "drs_payee.fuel_surcharge_pay": string;
    "drs_payee.futa": number;
    "drs_payee.futa_c": string;
    "drs_payee.futa_d": Date;
    "drs_payee.futa_n": number;
    "drs_payee.futa_r": number;
    "drs_payee.gross_non_taxed": number;
    "drs_payee.gross_non_taxed_c": string;
    "drs_payee.gross_non_taxed_d": Date;
    "drs_payee.gross_non_taxed_n": number;
    "drs_payee.gross_non_taxed_r": number;
    "drs_payee.haz_oos_inspection": number;
    "drs_payee.hazmat_cert_number": string;
    "drs_payee.hazmat_expire_date": Date;
    "drs_payee.hazmat_inspection": number;
    "drs_payee.highway_id_number": string;
    "drs_payee.highway_rule_assessment": string;
    "drs_payee.highway_status": string;
    "drs_payee.holiday_hours_pd": number;
    "drs_payee.hourly_pay_allocation": string;
    "drs_payee.household_goods": string;
    "drs_payee.ic_company_id": string;
    "drs_payee.icc_number": string;
    "drs_payee.id": string;
    "drs_payee.ins_auto_update": string;
    "drs_payee.ins_expire_notify": string;
    "drs_payee.ins_last_upd_dt": Date;
    "drs_payee.ins_on_file": string;
    "drs_payee.insurance_oa": string;
    "drs_payee.insurance_sv": string;
    "drs_payee.intrastate_code": string;
    "drs_payee.intrastate_only": string;
    "drs_payee.intrastate_state": string;
    "drs_payee.is_air": string;
    "drs_payee.is_broker": string;
    "drs_payee.is_certified": string;
    "drs_payee.is_expedited": string;
    "drs_payee.is_factored": string;
    "drs_payee.is_intercompany": string;
    "drs_payee.is_intermodal": string;
    "drs_payee.is_ltl": string;
    "drs_payee.is_ocean": string;
    "drs_payee.is_partial": string;
    "drs_payee.is_rail": string;
    "drs_payee.is_truckload": string;
    "drs_payee.its_cpr_reports": string;
    "drs_payee.its_suspension_reason": string;
    "drs_payee.job_class_code_id": string;
    "drs_payee.last_contacted": Date;
    "drs_payee.last_contacted_by": string;
    "drs_payee.last_upd_rate": Date;
    "drs_payee.leased_tractors": number;
    "drs_payee.leased_trailers": number;
    "drs_payee.liab_cancel_date": Date;
    "drs_payee.liab_expire_date": Date;
    "drs_payee.liab_insur_fax": string;
    "drs_payee.liab_insur_phone": string;
    "drs_payee.liability_amount": number;
    "drs_payee.liability_amount_c": string;
    "drs_payee.liability_amount_d": Date;
    "drs_payee.liability_amount_n": number;
    "drs_payee.liability_amount_r": number;
    "drs_payee.liability_email": string;
    "drs_payee.liability_insurer": string;
    "drs_payee.liability_percent": number;
    "drs_payee.liability_policy": string;
    "drs_payee.liability_reqd": number;
    "drs_payee.liability_reqd_c": string;
    "drs_payee.liability_reqd_d": Date;
    "drs_payee.liability_reqd_n": number;
    "drs_payee.liability_reqd_r": number;
    "drs_payee.loaded_pay_rate": number;
    "drs_payee.loadpay_accepted_status": string;
    "drs_payee.loadpay_acct_id": string;
    "drs_payee.loadpay_contact_email": string;
    "drs_payee.loadpay_contact_name": string;
    "drs_payee.loadpay_date": Date;
    "drs_payee.logo": string;
    "drs_payee.logo_name": string;
    "drs_payee.macropoint_provider_code": string;
    "drs_payee.mark_plan_id": string;
    "drs_payee.mc_payroll_pin": string;
    "drs_payee.mcs_150_date": Date;
    "drs_payee.mcs_150_mileage": number;
    "drs_payee.mcs_150_mileage_yr": number;
    "drs_payee.method": string;
    "drs_payee.mileage_code": string;
    "drs_payee.minimum_pay": number;
    "drs_payee.minimum_pay_c": string;
    "drs_payee.minimum_pay_d": Date;
    "drs_payee.minimum_pay_n": number;
    "drs_payee.minimum_pay_r": number;
    "drs_payee.monitor_ins": string;
    "drs_payee.monitor_ins_basic": string;
    "drs_payee.mpw_carrier_contact_method": string;
    "drs_payee.mpw_carrier_high": number;
    "drs_payee.mpw_carrier_low": number;
    "drs_payee.mpw_exclude": string;
    "drs_payee.mpw_last_contact": Date;
    "drs_payee.mpw_num_attempts": number;
    "drs_payee.mpw_num_orders": number;
    "drs_payee.night_phone_number": string;
    "drs_payee.no_dispatch": string;
    "drs_payee.num_days_to_pay": number;
    "drs_payee.onboarded": string;
    "drs_payee.onboarding_status": string;
    "drs_payee.operations_class": string;
    "drs_payee.ops_class_descr": string;
    "drs_payee.order_entry_url": string;
    "drs_payee.out_of_service": string;
    "drs_payee.override_qual_date": Date;
    "drs_payee.override_qual_enf": string;
    "drs_payee.override_reason": string;
    "drs_payee.overtime_cutoff": number;
    "drs_payee.overtime_hours_pd": number;
    "drs_payee.overtime_rate": number;
    "drs_payee.owned_tractors": number;
    "drs_payee.owned_trailers": number;
    "drs_payee.p44_cap_provider_code": string;
    "drs_payee.p44_profile_id": string;
    "drs_payee.parent_contract": number;
    "drs_payee.partner_tar_uid": number;
    "drs_payee.partner_tariff_id": string;
    "drs_payee.passenger": string;
    "drs_payee.pay_advances": string;
    "drs_payee.pay_factoring_company": string;
    "drs_payee.pay_method": string;
    "drs_payee.pay_option": string;
    "drs_payee.pay_rate_group": string;
    "drs_payee.payment_frequency": string;
    "drs_payee.payroll_run": string;
    "drs_payee.pend_broker_auth": string;
    "drs_payee.pend_common_auth": string;
    "drs_payee.pend_contract_auth": string;
    "drs_payee.pend_rev_broker": string;
    "drs_payee.pend_rev_common": string;
    "drs_payee.pend_rev_contract": string;
    "drs_payee.perdiem_rate": number;
    "drs_payee.perform_rating": string;
    "drs_payee.perform_rating_dt": Date;
    "drs_payee.period_loads": number;
    "drs_payee.period_miles": number;
    "drs_payee.period_revenue": number;
    "drs_payee.period_revenue_c": string;
    "drs_payee.period_revenue_d": Date;
    "drs_payee.period_revenue_n": number;
    "drs_payee.period_revenue_r": number;
    "drs_payee.power_units": number;
    "drs_payee.print_1099": string;
    "drs_payee.print_driver_orders_dtl": string;
    "drs_payee.process_status": string;
    "drs_payee.property": string;
    "drs_payee.pu_pay_amount": number;
    "drs_payee.pu_pay_amount_c": string;
    "drs_payee.pu_pay_amount_d": Date;
    "drs_payee.pu_pay_amount_n": number;
    "drs_payee.pu_pay_amount_r": number;
    "drs_payee.puc_number": string;
    "drs_payee.quickpay_amount": number;
    "drs_payee.quickpay_basis": string;
    "drs_payee.quickpay_contract": Date;
    "drs_payee.quickpay_daystopay": number;
    "drs_payee.quickpay_deduct_id": string;
    "drs_payee.quickpay_enabled": string;
    "drs_payee.quickpay_min_chg": number;
    "drs_payee.quickpay_min_chg_c": string;
    "drs_payee.quickpay_min_chg_d": Date;
    "drs_payee.quickpay_min_chg_n": number;
    "drs_payee.quickpay_min_chg_r": number;
    "drs_payee.recur_tractor_last_created": Date;
    "drs_payee.regular_hours_pd": number;
    "drs_payee.regular_rate": number;
    "drs_payee.reimburse_fuel": string;
    "drs_payee.req_dispatch_over": string;
    "drs_payee.risk_assessment": string;
    "drs_payee.rmis_carrier_id": string;
    "drs_payee.safety_fitness": string;
    "drs_payee.safety_fitness_dt": Date;
    "drs_payee.safety_rate_rev_dt": Date;
    "drs_payee.safety_rating": string;
    "drs_payee.safety_rating_dt": Date;
    "drs_payee.safety_reg_date": Date;
    "drs_payee.safety_reg_status": string;
    "drs_payee.safety_review_type": string;
    "drs_payee.salesperson_id": string;
    "drs_payee.scac": string;
    "drs_payee.sea_accident": number;
    "drs_payee.sea_driver": number;
    "drs_payee.sea_safety_mgmt": number;
    "drs_payee.sea_vehicle": number;
    "drs_payee.search_city_id": number;
    "drs_payee.search_city_name": string;
    "drs_payee.search_state": string;
    "drs_payee.search_zip_code": string;
    "drs_payee.send_to": string;
    "drs_payee.sick_earned": number;
    "drs_payee.sick_hours_due": number;
    "drs_payee.sick_hours_pd": number;
    "drs_payee.smartway_certified": string;
    "drs_payee.smartway_score": number;
    "drs_payee.so_pay_amount": number;
    "drs_payee.so_pay_amount_c": string;
    "drs_payee.so_pay_amount_d": Date;
    "drs_payee.so_pay_amount_n": number;
    "drs_payee.so_pay_amount_r": number;
    "drs_payee.start_date": Date;
    "drs_payee.taxable_owed": number;
    "drs_payee.taxable_owed_c": string;
    "drs_payee.taxable_owed_d": Date;
    "drs_payee.taxable_owed_n": number;
    "drs_payee.taxable_owed_r": number;
    "drs_payee.team_empty_rate": number;
    "drs_payee.team_method": string;
    "drs_payee.team_pay_rate": number;
    "drs_payee.term_code": string;
    "drs_payee.term_is_quick_pay": string;
    "drs_payee.terminal_id": string;
    "drs_payee.tiered_stop_id": string;
    "drs_payee.total_inspection": number;
    "drs_payee.trackid_carrier_onboarding": number;
    "drs_payee.trackid_document_request": number;
    "drs_payee.trackid_expiring_insurance": number;
    "drs_payee.trailer_rent": number;
    "drs_payee.trainee_rate": number;
    "drs_payee.trainer_rate": number;
    "drs_payee.type_of": string;
    "drs_payee.unsafe_driving_oa": string;
    "drs_payee.unsafe_driving_ot": string;
    "drs_payee.unsafe_driving_pct": number;
    "drs_payee.unsafe_driving_sv": string;
    "drs_payee.vacation_earned": number;
    "drs_payee.vacation_hours_due": number;
    "drs_payee.vacation_hours_pd": number;
    "drs_payee.vacation_hrs_base": number;
    "drs_payee.vacation_pay_rate": number;
    "drs_payee.veh_oos_inspection": number;
    "drs_payee.vehicle_inspection": number;
    "drs_payee.vehicle_maint_oa": string;
    "drs_payee.vehicle_maint_ot": string;
    "drs_payee.vehicle_maint_pct": number;
    "drs_payee.vehicle_maint_sv": string;
    "drs_payee.via": string;
    "drs_payee.w9_on_file": string;
    "drs_payee.watchdog_report": string;
    "drs_payee.wats_phone_number": string;
    "drs_payee.workmans_comp": number;
    "drs_payee.workmanscomp_date": Date;
    "drs_payee.ytd_401k_catchup": number;
    "drs_payee.ytd_401k_catchup_c": string;
    "drs_payee.ytd_401k_catchup_d": Date;
    "drs_payee.ytd_401k_catchup_n": number;
    "drs_payee.ytd_401k_catchup_r": number;
    "drs_payee.ytd_401k_contrib": number;
    "drs_payee.ytd_401k_contrib_c": string;
    "drs_payee.ytd_401k_contrib_d": Date;
    "drs_payee.ytd_401k_contrib_n": number;
    "drs_payee.ytd_401k_contrib_r": number;
    "drs_payee.ytd_city_gross": number;
    "drs_payee.ytd_city_gross_c": string;
    "drs_payee.ytd_city_gross_d": Date;
    "drs_payee.ytd_city_gross_n": number;
    "drs_payee.ytd_city_gross_r": number;
    "drs_payee.ytd_city_tax_wh": number;
    "drs_payee.ytd_city_tax_wh_c": string;
    "drs_payee.ytd_city_tax_wh_d": Date;
    "drs_payee.ytd_city_tax_wh_n": number;
    "drs_payee.ytd_city_tax_wh_r": number;
    "drs_payee.ytd_county_wages": number;
    "drs_payee.ytd_county_wages_c": string;
    "drs_payee.ytd_county_wages_d": Date;
    "drs_payee.ytd_county_wages_n": number;
    "drs_payee.ytd_county_wages_r": number;
    "drs_payee.ytd_county_wh": number;
    "drs_payee.ytd_county_wh_c": string;
    "drs_payee.ytd_county_wh_d": Date;
    "drs_payee.ytd_county_wh_n": number;
    "drs_payee.ytd_county_wh_r": number;
    "drs_payee.ytd_ett_wages": number;
    "drs_payee.ytd_ett_wages_c": string;
    "drs_payee.ytd_ett_wages_d": Date;
    "drs_payee.ytd_ett_wages_n": number;
    "drs_payee.ytd_ett_wages_r": number;
    "drs_payee.ytd_fed_gross": number;
    "drs_payee.ytd_fed_gross_c": string;
    "drs_payee.ytd_fed_gross_d": Date;
    "drs_payee.ytd_fed_gross_n": number;
    "drs_payee.ytd_fed_gross_r": number;
    "drs_payee.ytd_fed_withheld": number;
    "drs_payee.ytd_fed_withheld_c": string;
    "drs_payee.ytd_fed_withheld_d": Date;
    "drs_payee.ytd_fed_withheld_n": number;
    "drs_payee.ytd_fed_withheld_r": number;
    "drs_payee.ytd_fica_hi_gros": number;
    "drs_payee.ytd_fica_hi_gros_c": string;
    "drs_payee.ytd_fica_hi_gros_d": Date;
    "drs_payee.ytd_fica_hi_gros_n": number;
    "drs_payee.ytd_fica_hi_gros_r": number;
    "drs_payee.ytd_fica_hi_wh": number;
    "drs_payee.ytd_fica_hi_wh_c": string;
    "drs_payee.ytd_fica_hi_wh_d": Date;
    "drs_payee.ytd_fica_hi_wh_n": number;
    "drs_payee.ytd_fica_hi_wh_r": number;
    "drs_payee.ytd_fica_oadi_gr": number;
    "drs_payee.ytd_fica_oadi_gr_c": string;
    "drs_payee.ytd_fica_oadi_gr_d": Date;
    "drs_payee.ytd_fica_oadi_gr_n": number;
    "drs_payee.ytd_fica_oadi_gr_r": number;
    "drs_payee.ytd_fica_oadi_wh": number;
    "drs_payee.ytd_fica_oadi_wh_c": string;
    "drs_payee.ytd_fica_oadi_wh_d": Date;
    "drs_payee.ytd_fica_oadi_wh_n": number;
    "drs_payee.ytd_fica_oadi_wh_r": number;
    "drs_payee.ytd_fui_gross": number;
    "drs_payee.ytd_fui_gross_c": string;
    "drs_payee.ytd_fui_gross_d": Date;
    "drs_payee.ytd_fui_gross_n": number;
    "drs_payee.ytd_fui_gross_r": number;
    "drs_payee.ytd_holiday_hrs": number;
    "drs_payee.ytd_loads": number;
    "drs_payee.ytd_miles": number;
    "drs_payee.ytd_overtime_hrs": number;
    "drs_payee.ytd_puc_gross": number;
    "drs_payee.ytd_puc_gross_c": string;
    "drs_payee.ytd_puc_gross_d": Date;
    "drs_payee.ytd_puc_gross_n": number;
    "drs_payee.ytd_puc_gross_r": number;
    "drs_payee.ytd_puc_withheld": number;
    "drs_payee.ytd_puc_withheld_c": string;
    "drs_payee.ytd_puc_withheld_d": Date;
    "drs_payee.ytd_puc_withheld_n": number;
    "drs_payee.ytd_puc_withheld_r": number;
    "drs_payee.ytd_reg_hrs_paid": number;
    "drs_payee.ytd_revenue": number;
    "drs_payee.ytd_revenue_c": string;
    "drs_payee.ytd_revenue_d": Date;
    "drs_payee.ytd_revenue_n": number;
    "drs_payee.ytd_revenue_r": number;
    "drs_payee.ytd_sdi_gross": number;
    "drs_payee.ytd_sdi_gross_c": string;
    "drs_payee.ytd_sdi_gross_d": Date;
    "drs_payee.ytd_sdi_gross_n": number;
    "drs_payee.ytd_sdi_gross_r": number;
    "drs_payee.ytd_sdi_withheld": number;
    "drs_payee.ytd_sdi_withheld_c": string;
    "drs_payee.ytd_sdi_withheld_d": Date;
    "drs_payee.ytd_sdi_withheld_n": number;
    "drs_payee.ytd_sdi_withheld_r": number;
    "drs_payee.ytd_sick_hrs_paid": number;
    "drs_payee.ytd_state_gross": number;
    "drs_payee.ytd_state_gross_c": string;
    "drs_payee.ytd_state_gross_d": Date;
    "drs_payee.ytd_state_gross_n": number;
    "drs_payee.ytd_state_gross_r": number;
    "drs_payee.ytd_state_wh": number;
    "drs_payee.ytd_state_wh_c": string;
    "drs_payee.ytd_state_wh_d": Date;
    "drs_payee.ytd_state_wh_n": number;
    "drs_payee.ytd_state_wh_r": number;
    "drs_payee.ytd_sui_gross": number;
    "drs_payee.ytd_sui_gross_c": string;
    "drs_payee.ytd_sui_gross_d": Date;
    "drs_payee.ytd_sui_gross_n": number;
    "drs_payee.ytd_sui_gross_r": number;
    "drs_payee.ytd_sui_withheld": number;
    "drs_payee.ytd_sui_withheld_c": string;
    "drs_payee.ytd_sui_withheld_d": Date;
    "drs_payee.ytd_sui_withheld_n": number;
    "drs_payee.ytd_sui_withheld_r": number;
    "drs_payee.ytd_total_gross": number;
    "drs_payee.ytd_total_gross_c": string;
    "drs_payee.ytd_total_gross_d": Date;
    "drs_payee.ytd_total_gross_n": number;
    "drs_payee.ytd_total_gross_r": number;
    "drs_payee.ytd_vacation_hrs": number;
    eform_rate_confirmation: string;
    empty2next_order: string;
    equipment_group_id: string;
    est_tolls: number;
    est_tolls_c: string;
    est_tolls_d: Date;
    est_tolls_n: number;
    est_tolls_r: number;
    exclude_movement: string;
    fleet_manager: string;
    freight_matching_override: string;
    fuel_distance: number;
    fuel_distance_um: string;
    fuel_tax_exempt: string;
    hubtran_approved_inv_date: Date;
    hubtran_ok2pay_date: Date;
    ic_company_to: string;
    ic_order_to: string;
    id: string;
    integrated_carrier_search: string;
    invoice_recv_date: Date;
    is_container: string;
    is_dray: string;
    is_intercompany: string;
    is_local_mile: string;
    lane: string;
    linked_id: number;
    linked_sequence: number;
    load_booking_eligible: string;
    load_booking_source: string;
    loaded: string;
    "location.address1": string;
    "location.address2": string;
    "location.appt_required": string;
    "location.assigned_to": string;
    "location.bseg": string;
    "location.category": string;
    "location.cbsa_port_id": string;
    "location.chassis_owner": string;
    "location.chassis_pool": string;
    "location.city_id": number;
    "location.city_name": string;
    "location.class_rqmt": string;
    "location.comments": string;
    "location.company_id": string;
    "location.container_pool": string;
    "location.container_vendor": string;
    "location.cust_id": string;
    "location.customer_id": string;
    "location.days_between_calls": number;
    "location.ded_rqmt_type": string;
    "location.ded_type": string;
    "location.def_commodity_desc": string;
    "location.def_commodity_id": string;
    "location.delivery_date_option": string;
    "location.directions": string;
    "location.disable_loc_rating": string;
    "location.dl_gen_cost": number;
    "location.dl_gen_repower": number;
    "location.dl_haz_cost": number;
    "location.dl_haz_repower": number;
    "location.dl_hvl_cost": number;
    "location.dl_hvl_repower": number;
    "location.dl_load_time": number;
    "location.dl_svc_cost": number;
    "location.dl_svc_repower": number;
    "location.dl_unload_time": number;
    "location.driver_load_id": string;
    "location.driver_unload_id": string;
    "location.ds_capacity_cost": number;
    "location.ds_model_flag": string;
    "location.extra_handling_time": number;
    "location.filer_code": string;
    "location.foreign_port": string;
    "location.friday_close": Date;
    "location.friday_open": Date;
    "location.fuel_price_region": string;
    "location.google_place_fm_address": string;
    "location.google_place_id": string;
    "location.google_place_type": string;
    "location.id": string;
    "location.include_xref_trailers": string;
    "location.insur_amt_rqrd": number;
    "location.insur_amt_rqrd_c": string;
    "location.insur_amt_rqrd_d": Date;
    "location.insur_amt_rqrd_n": number;
    "location.insur_amt_rqrd_r": number;
    "location.is_active": string;
    "location.is_consignee": string;
    "location.is_customer": string;
    "location.is_customs_broker": string;
    "location.is_dist_center": string;
    "location.is_dl_gen": string;
    "location.is_dl_haz": string;
    "location.is_dl_hvl": string;
    "location.is_dl_svc": string;
    "location.is_drop_yard": string;
    "location.is_geocoded": string;
    "location.is_outside_terminal": string;
    "location.is_prospect": string;
    "location.is_remote_terminal": string;
    "location.is_shipper": string;
    "location.is_steamship": string;
    "location.is_terminal": string;
    "location.is_trailer_pool": string;
    "location.is_trailer_wash": string;
    "location.is_truckstop": string;
    "location.last_used_date": Date;
    "location.latitude": number;
    "location.layover": string;
    "location.load_unload_count": number;
    "location.load_unload_excl": string;
    "location.load_unload_param": string;
    "location.loading_instructs": string;
    "location.loading_time": number;
    "location.longitude": number;
    "location.ltl_optimize_time_per_spot": number;
    "location.ltl_seg_alloc_code": string;
    "location.manual_exchange": string;
    "location.max_drvr_complaint": number;
    "location.max_pallet_balance": number;
    "location.max_payment_code_amt": number;
    "location.max_payment_code_amt_c": string;
    "location.max_payment_code_amt_d": Date;
    "location.max_payment_code_amt_n": number;
    "location.max_payment_code_amt_r": number;
    "location.model_flag": string;
    "location.monday_close": Date;
    "location.monday_open": Date;
    "location.name": string;
    "location.need_clean_trailer": string;
    "location.next_call_date": Date;
    "location.no_fuel_radius_inbound": string;
    "location.no_fuel_radius_outbound": number;
    "location.no_stop_miles": number;
    "location.on_duty_not_driving_mins": number;
    "location.pallet_balance": number;
    "location.pallets_required": string;
    "location.payment_api_excluded": string;
    "location.pickup_date_option": string;
    "location.plc_uid": number;
    "location.pnn_city_id": number;
    "location.pnn_city_name": string;
    "location.pnn_state": string;
    "location.pnn_zip_code": string;
    "location.port_id": string;
    "location.prev_code": string;
    "location.primary_agency": string;
    "location.radius": number;
    "location.regulated": string;
    "location.reload_rqmt": string;
    "location.reserve_mins": number;
    "location.salesperson": string;
    "location.saturday_close": Date;
    "location.saturday_open": Date;
    "location.shuttle_capacity": string;
    "location.state": string;
    "location.sunday_close": Date;
    "location.sunday_open": Date;
    "location.swap_cost": number;
    "location.team_rqmt": string;
    "location.thursday_close": Date;
    "location.thursday_open": Date;
    "location.trailer_pool_min": number;
    "location.trailer_pool_radius": number;
    "location.trailer_pool_size": number;
    "location.trailer_wash_vend": string;
    "location.trimble_place_id": string;
    "location.trip_starter": string;
    "location.tuesday_close": Date;
    "location.tuesday_open": Date;
    "location.tw_seg_code": string;
    "location.unload_instructs": string;
    "location.unloading_time": number;
    "location.website_url": string;
    "location.wednesday_close": Date;
    "location.wednesday_open": Date;
    "location.zip_code": string;
    locked_user_id: string;
    ltl: string;
    ltl_staging_wrs_uid: number;
    macropoint_status: string;
    manifest_empty_distance: number;
    manifest_estimated_arrival: Date;
    manifest_estimated_depart: Date;
    manifest_id: string;
    manifest_loaded_distance: number;
    manifest_reg_uid: number;
    manifest_spots_capacity: number;
    manifest_spots_consumed: number;
    manifest_weight_capacity: number;
    manifest_weight_consumed: number;
    margin: number;
    max_buy: number;
    max_buy_c: string;
    max_buy_d: Date;
    max_buy_n: number;
    max_buy_r: number;
    max_pay_method: string;
    max_pay_units: number;
    missed_call_sent: string;
    move_charge: number;
    move_distance: number;
    move_distance_um: string;
    move_pay: number;
    "movement_order.company_id": string;
    "movement_order.id": string;
    "movement_order.movement_id": string;
    "movement_order.on_at_move_dest": string;
    "movement_order.on_at_move_origin": string;
    "movement_order.order_id": string;
    "movement_order.planned_manifest_id": string;
    "movement_order.sequence": number;
    movement_stop_count: number;
    movement_type: string;
    must_respond_by: Date;
    next_sched_call: Date;
    operational_status: string;
    operations_user: string;
    "orders.actual_reefer_profile": string;
    "orders.agency_id": string;
    "orders.agent_payee_id": string;
    "orders.allow_relay": string;
    "orders.autorate_status": string;
    "orders.bill_date": Date;
    "orders.bill_distance": number;
    "orders.bill_distance_um": string;
    "orders.billing_empty_distance": number;
    "orders.billing_empty_distance_um": string;
    "orders.billing_loaded_distance": number;
    "orders.billing_loaded_distance_um": string;
    "orders.billing_user_id": string;
    "orders.blnum": string;
    "orders.bol_note": string;
    "orders.bol_received": string;
    "orders.bol_recv_date": Date;
    "orders.booking_no": string;
    "orders.booking_number": string;
    "orders.brk_qual_profile": string;
    "orders.broker_location_id": string;
    "orders.brokerage_workflow_id": string;
    "orders.cbp_crossing_date": Date;
    "orders.cbp_crossing_location_id": string;
    "orders.cbp_crossing_reason": string;
    "orders.cbsa_crossing_date": Date;
    "orders.cbsa_crossing_location_id": string;
    "orders.cbsa_crossing_reason": string;
    "orders.cc_fuel_revenue": number;
    "orders.cc_fuel_revenue_c": string;
    "orders.cc_fuel_revenue_d": Date;
    "orders.cc_fuel_revenue_n": number;
    "orders.cc_fuel_revenue_r": number;
    "orders.cc_net_revenue": number;
    "orders.cc_net_revenue_c": string;
    "orders.cc_net_revenue_d": Date;
    "orders.cc_net_revenue_n": number;
    "orders.cc_net_revenue_r": number;
    "orders.cc_pro_nbr": string;
    "orders.cobroker_location_id": string;
    "orders.collection_method": string;
    "orders.com_setpnt_temp": number;
    "orders.com_setpnt_temp_um": string;
    "orders.commitment_id": string;
    "orders.commodity": string;
    "orders.commodity_id": string;
    "orders.company_id": string;
    "orders.consignee_refno": string;
    "orders.consignee_stop_id": string;
    "orders.container": string;
    "orders.container_planned": string;
    "orders.container_type_id": string;
    "orders.controlling_carrier_code": string;
    "orders.copy_from_company": string;
    "orders.copy_from_order_id": string;
    "orders.copy_to_company": string;
    "orders.copy_to_order_id": string;
    "orders.ctrl_party_id": string;
    "orders.cube": number;
    "orders.curr_movement_id": string;
    "orders.cust_order_no": string;
    "orders.customer_id": string;
    "orders.declared_val": number;
    "orders.def_move_type": string;
    "orders.default_match_id": string;
    "orders.dim_height": string;
    "orders.dim_length": string;
    "orders.dim_surcharge": number;
    "orders.dim_surcharge_c": string;
    "orders.dim_surcharge_d": Date;
    "orders.dim_surcharge_n": number;
    "orders.dim_surcharge_r": number;
    "orders.dim_width": string;
    "orders.dispatch_opt": string;
    "orders.dl_team_reqd": string;
    "orders.dray_del_date": Date;
    "orders.dray_del_pay": number;
    "orders.dray_del_pay_c": string;
    "orders.dray_del_pay_d": Date;
    "orders.dray_del_pay_n": number;
    "orders.dray_del_pay_r": number;
    "orders.dray_del_payee": string;
    "orders.dray_del_xfer_dt": Date;
    "orders.dray_pu_date": Date;
    "orders.dray_pu_pay": number;
    "orders.dray_pu_pay_c": string;
    "orders.dray_pu_pay_d": Date;
    "orders.dray_pu_pay_n": number;
    "orders.dray_pu_pay_r": number;
    "orders.dray_pu_payee": string;
    "orders.dray_pu_xfer_dt": Date;
    "orders.eir1_no": string;
    "orders.eir2_no": string;
    "orders.entered_user_id": string;
    "orders.equipment_type_id": string;
    "orders.equipment_type_options": string;
    "orders.est_tolls": number;
    "orders.est_tolls_c": string;
    "orders.est_tolls_d": Date;
    "orders.est_tolls_n": number;
    "orders.est_tolls_r": number;
    "orders.excise_disable_update": string;
    "orders.excise_taxable": string;
    "orders.excisetax_total": number;
    "orders.excisetax_total_c": string;
    "orders.excisetax_total_d": Date;
    "orders.excisetax_total_n": number;
    "orders.excisetax_total_r": number;
    "orders.external_invoiceno": string;
    "orders.extra_deliveries": number;
    "orders.extra_pickups": number;
    "orders.floor_loaded_freight": string;
    "orders.force_assign": string;
    "orders.forwd_location_id": string;
    "orders.free_days": number;
    "orders.freight_charge": number;
    "orders.freight_charge_c": string;
    "orders.freight_charge_d": Date;
    "orders.freight_charge_n": number;
    "orders.freight_charge_r": number;
    "orders.hazmat": string;
    "orders.hazmat_code": string;
    "orders.high_value": string;
    "orders.hold_reason": string;
    "orders.ic_company_from": string;
    "orders.ic_movement_from": string;
    "orders.ic_order_from": string;
    "orders.id": string;
    "orders.image_is_present": string;
    "orders.import_export": string;
    "orders.include_split_point": string;
    "orders.intelliset": string;
    "orders.invoice_recv_date": Date;
    "orders.is_auto_tonu": string;
    "orders.is_autorate_dist": string;
    "orders.is_container": string;
    "orders.is_dedicated": string;
    "orders.is_intercompany": string;
    "orders.is_local_mile": string;
    "orders.is_requested": string;
    "orders.is_tonu": string;
    "orders.jag_carrier_id": string;
    "orders.jag_cost_rate_id": number;
    "orders.ldport_location_id": string;
    "orders.linear_feet_avail": number;
    "orders.load_date": Date;
    "orders.loadboard": string;
    "orders.lock_miles": string;
    "orders.ltl": string;
    "orders.markup_flat": number;
    "orders.markup_flat_c": string;
    "orders.markup_flat_d": Date;
    "orders.markup_flat_n": number;
    "orders.markup_flat_r": number;
    "orders.markup_percent": number;
    "orders.master_order_id": string;
    "orders.master_sequence": number;
    "orders.next_rebill": string;
    "orders.nte_lp_num": string;
    "orders.nte_lp_qual": string;
    "orders.on_hold": string;
    "orders.operational_status": string;
    "orders.operations_user": string;
    "orders.optimize": string;
    "orders.optiset": string;
    "orders.order_mode": string;
    "orders.order_type_id": string;
    "orders.order_value": number;
    "orders.order_value_c": string;
    "orders.order_value_d": Date;
    "orders.order_value_n": number;
    "orders.order_value_r": number;
    "orders.ordered_by": string;
    "orders.ordered_by_id": string;
    "orders.ordered_date": Date;
    "orders.ordered_method": string;
    "orders.orig_dest_rate_id": string;
    "orders.otherchargetotal": number;
    "orders.otherchargetotal_c": string;
    "orders.otherchargetotal_d": Date;
    "orders.otherchargetotal_n": number;
    "orders.otherchargetotal_r": number;
    "orders.pallets_how_many": number;
    "orders.pallets_required": string;
    "orders.pay_gross": number;
    "orders.pay_gross_c": string;
    "orders.pay_gross_d": Date;
    "orders.pay_gross_n": number;
    "orders.pay_gross_r": number;
    "orders.pick_up_no": string;
    "orders.pieces": number;
    "orders.placard_required": string;
    "orders.planning_comment": string;
    "orders.pnn_callback": string;
    "orders.pnn_comment": string;
    "orders.pnn_comment2": string;
    "orders.pnn_loadboard_user_id": string;
    "orders.pnn_post_type": string;
    "orders.pnn_rate": number;
    "orders.pnn_rate_type": string;
    "orders.pnn_trailer_length": number;
    "orders.pnn_trailer_width": number;
    "orders.ponum": string;
    "orders.port": string;
    "orders.port_of_discharge": string;
    "orders.preload_trailer_id": string;
    "orders.preloaded": string;
    "orders.rate": number;
    "orders.rate_currency_type": string;
    "orders.rate_id": number;
    "orders.rate_min_weight": number;
    "orders.rate_min_weight_um": string;
    "orders.rate_source": string;
    "orders.rate_type": string;
    "orders.rate_unit_desc": string;
    "orders.rate_units": number;
    "orders.ready_to_bill": string;
    "orders.recurring_order_id": string;
    "orders.reply_transmitted": string;
    "orders.requesting_company": string;
    "orders.return_temp": number;
    "orders.return_temp_um": string;
    "orders.return_temp_var": number;
    "orders.return_temp_var_um": string;
    "orders.revenue_code_id": string;
    "orders.revenue_share_method": string;
    "orders.round_trip": string;
    "orders.sail_date": Date;
    "orders.seal_number": string;
    "orders.seg_alloc_code": string;
    "orders.setpoint_temp": number;
    "orders.setpoint_temp_um": string;
    "orders.setpoint_var": number;
    "orders.setpoint_var_um": string;
    "orders.ship_status_to_edi": string;
    "orders.shipment_id": string;
    "orders.shipper_stop_id": string;
    "orders.shipstatus2edi_dt": Date;
    "orders.ss_location_id": string;
    "orders.status": string;
    "orders.subject_order_number": string;
    "orders.subject_order_status": string;
    "orders.subject_order_void_date": Date;
    "orders.swap": string;
    "orders.teams_required": string;
    "orders.temperature_max": number;
    "orders.temperature_max_um": string;
    "orders.temperature_min": number;
    "orders.temperature_min_um": string;
    "orders.total_charge": number;
    "orders.total_charge_c": string;
    "orders.total_charge_d": Date;
    "orders.total_charge_n": number;
    "orders.total_charge_r": number;
    "orders.totalcharge_and_excisetax": number;
    "orders.totalcharge_and_excisetax_c": string;
    "orders.totalcharge_and_excisetax_d": Date;
    "orders.totalcharge_and_excisetax_n": number;
    "orders.totalcharge_and_excisetax_r": number;
    "orders.tractor_type": string;
    "orders.vessel": string;
    "orders.vessel_cutoff_date": Date;
    "orders.weight": number;
    "orders.weight_um": string;
    "orders.xferred2billing": string;
    "orders.xferred2billing_dt": Date;
    origin_stop_id: string;
    override_crny_type: string;
    override_driver2_id: number;
    override_driver2_nm: string;
    override_driver_id: number;
    override_driver_nm: string;
    override_drvr2_cell: string;
    override_drvr2_email: string;
    override_drvr_cell: string;
    override_drvr_email: string;
    override_max_pay: number;
    override_max_pay_c: string;
    override_max_pay_d: Date;
    override_max_pay_n: number;
    override_max_pay_r: number;
    override_pay_amt: number;
    override_pay_amt_c: string;
    override_pay_amt_d: Date;
    override_pay_amt_n: number;
    override_pay_amt_r: number;
    override_pay_rate: number;
    override_paye_rate: number;
    override_payee_id: string;
    override_targetpay: number;
    override_targetpay_c: string;
    override_targetpay_d: Date;
    override_targetpay_n: number;
    override_targetpay_r: number;
    override_type: string;
    override_unit_desc: string;
    override_units: number;
    pay_distance: number;
    pay_distance_um: string;
    "payee.ach_batch_class_code": string;
    "payee.add_fed_to_wh": number;
    "payee.add_fed_to_wh_c": string;
    "payee.add_fed_to_wh_d": Date;
    "payee.add_fed_to_wh_n": number;
    "payee.add_fed_to_wh_r": number;
    "payee.addl_st_to_whold": number;
    "payee.addl_st_to_whold_c": string;
    "payee.addl_st_to_whold_d": Date;
    "payee.addl_st_to_whold_n": number;
    "payee.addl_st_to_whold_r": number;
    "payee.address1": string;
    "payee.address2": string;
    "payee.adp_co_code": string;
    "payee.adp_e_no": string;
    "payee.birth_date": Date;
    "payee.check_address": string;
    "payee.check_address2": string;
    "payee.check_city": string;
    "payee.check_city_st_zip": string;
    "payee.check_date": Date;
    "payee.check_name": string;
    "payee.check_number": string;
    "payee.check_st": string;
    "payee.check_stub_comment": string;
    "payee.check_zip": string;
    "payee.city": string;
    "payee.city_tax_code": string;
    "payee.company_id": string;
    "payee.country_id": string;
    "payee.county_tax_code": string;
    "payee.currency": string;
    "payee.dependents": number;
    "payee.dependents_c": string;
    "payee.dependents_d": Date;
    "payee.dependents_n": number;
    "payee.dependents_r": number;
    "payee.disable_payroll_taxes": string;
    "payee.email": string;
    "payee.email_summary": string;
    "payee.enable_carrier_app": string;
    "payee.exempt_from_fed": string;
    "payee.extra_withholdings": number;
    "payee.extra_withholdings_c": string;
    "payee.extra_withholdings_d": Date;
    "payee.extra_withholdings_n": number;
    "payee.extra_withholdings_r": number;
    "payee.fed_amt_to_whold": number;
    "payee.fed_amt_to_whold_c": string;
    "payee.fed_amt_to_whold_d": Date;
    "payee.fed_amt_to_whold_n": number;
    "payee.fed_amt_to_whold_r": number;
    "payee.fed_exemptions": number;
    "payee.fed_marital_status": string;
    "payee.freeze_pay": string;
    "payee.freight_matching_override": string;
    "payee.gp_exclude": string;
    "payee.gp_exported": Date;
    "payee.hire_date": Date;
    "payee.holiday_hours_pd": number;
    "payee.id": string;
    "payee.last_raise_date": Date;
    "payee.legal_name": string;
    "payee.load_booking_eligible": string;
    "payee.loadpay_contact_phone": string;
    "payee.multiple_jobs": string;
    "payee.name": string;
    "payee.non_office_emp": string;
    "payee.office_employee": string;
    "payee.other_deductions": number;
    "payee.other_deductions_c": string;
    "payee.other_deductions_d": Date;
    "payee.other_deductions_n": number;
    "payee.other_deductions_r": number;
    "payee.other_income": number;
    "payee.other_income_c": string;
    "payee.other_income_d": Date;
    "payee.other_income_n": number;
    "payee.other_income_r": number;
    "payee.overtime_hours_pd": number;
    "payee.payment_method": string;
    "payee.phone_number": string;
    "payee.posted_check_date": Date;
    "payee.prev_code": string;
    "payee.primary_agency": string;
    "payee.regular_hours_pd": number;
    "payee.remarks": string;
    "payee.settlement_status": string;
    "payee.sick_hours_pd": number;
    "payee.social_security_no": string;
    "payee.st_amt_to_whold": number;
    "payee.st_amt_to_whold_c": string;
    "payee.st_amt_to_whold_d": Date;
    "payee.st_amt_to_whold_n": number;
    "payee.st_amt_to_whold_r": number;
    "payee.st_marital_status": string;
    "payee.state": string;
    "payee.state_exemptions": number;
    "payee.state_tax_code": string;
    "payee.status": string;
    "payee.sui_tax_code": string;
    "payee.tax_table_city_aux": number;
    "payee.tax_table_city_misc": number;
    "payee.tax_table_county_aux": number;
    "payee.tax_table_county_misc": number;
    "payee.tax_table_fit_aux": number;
    "payee.tax_table_fit_misc": number;
    "payee.tax_table_st_aux": number;
    "payee.tax_table_st_misc": number;
    "payee.tax_table_sui_aux": number;
    "payee.tax_table_sui_misc": number;
    "payee.termination_date": Date;
    "payee.vacation_hours_pd": number;
    "payee.vm_part_update": string;
    "payee.w4_status": string;
    "payee.wf_queue": number;
    "payee.ytd_holiday_hrs": number;
    "payee.ytd_overtime_hrs": number;
    "payee.ytd_reg_hrs_paid": number;
    "payee.ytd_sick_hrs_paid": number;
    "payee.ytd_vacation_hrs": number;
    "payee.zip_code": string;
    pending_carrier_name: string;
    pending_carrier_nbr: string;
    pending_carrier_type: string;
    pending_payee_id: string;
    placard_required: string;
    preassign_sequence: number;
    priority: string;
    pro_nbr: string;
    rate_alert_basis: string;
    rate_alert_date: Date;
    rate_alert_days: number;
    rate_alert_hours: number;
    rate_alert_minutes: number;
    rate_confirm_template: number;
    rate_confirmation_sent_date: Date;
    rate_confirmation_status: string;
    rate_expiration_basis: string;
    rate_expiration_date: Date;
    rate_expiration_days: number;
    rate_expiration_hours: number;
    rate_expiration_minutes: number;
    rdy_pickup_date: Date;
    recurring_movement_id: string;
    reminder_sent: string;
    require_tracking: string;
    required_vendor: string;
    reserved: string;
    return_date: Date;
    seg_alloc_code: string;
    shipper_email: string;
    shipper_notify_frequency: string;
    shpmt_ident_type: string;
    shpmt_ident_value: string;
    ss_location_id: string;
    status: string;
    "stop_dest.actual_arrival": Date;
    "stop_dest.actual_departure": Date;
    "stop_dest.address": string;
    "stop_dest.address2": string;
    "stop_dest.appointment_status_id": string;
    "stop_dest.appt_contact_name": string;
    "stop_dest.appt_required": string;
    "stop_dest.cases": number;
    "stop_dest.city_id": number;
    "stop_dest.city_name": string;
    "stop_dest.company_id": string;
    "stop_dest.confirmed": string;
    "stop_dest.contact_name": string;
    "stop_dest.dist_from_prev": number;
    "stop_dest.dist_from_prev_um": string;
    "stop_dest.driver_load_unload": string;
    "stop_dest.edi_code": string;
    "stop_dest.edi_loc_code": string;
    "stop_dest.eta": Date;
    "stop_dest.eta_distance": number;
    "stop_dest.eta_late": string;
    "stop_dest.eta_oor": string;
    "stop_dest.google_place_id": string;
    "stop_dest.grouped_id": number;
    "stop_dest.hub": number;
    "stop_dest.ic_stop_id": string;
    "stop_dest.id": string;
    "stop_dest.inbound_status": string;
    "stop_dest.late_eta_colorcode": string;
    "stop_dest.late_route_status": string;
    "stop_dest.latitude": number;
    "stop_dest.location_id": string;
    "stop_dest.location_name": string;
    "stop_dest.longitude": number;
    "stop_dest.manifest_fgp_uid": number;
    "stop_dest.manifest_reg_uid": number;
    "stop_dest.move_dist_from_preassign": number;
    "stop_dest.move_dist_from_preassign_um": string;
    "stop_dest.move_dist_from_previous": number;
    "stop_dest.move_dist_from_previous_um": string;
    "stop_dest.movement_id": string;
    "stop_dest.movement_sequence": number;
    "stop_dest.operational_status": string;
    "stop_dest.order_id": string;
    "stop_dest.order_sequence": number;
    "stop_dest.orig_sched_early": Date;
    "stop_dest.orig_sched_late": Date;
    "stop_dest.pallets_dropped": number;
    "stop_dest.pallets_picked_up": number;
    "stop_dest.payment_code_reviewed": string;
    "stop_dest.phone": string;
    "stop_dest.planned_arrival_time": Date;
    "stop_dest.ponum": string;
    "stop_dest.prior_uncleared_stops": string;
    "stop_dest.projected_arrival": Date;
    "stop_dest.rate_dist_from_previous": number;
    "stop_dest.rate_dist_from_previous_um": string;
    "stop_dest.refno": string;
    "stop_dest.requested_service": string;
    "stop_dest.sched_arrive_early": Date;
    "stop_dest.sched_arrive_late": Date;
    "stop_dest.seal": string;
    "stop_dest.service_date": Date;
    "stop_dest.showas_address": string;
    "stop_dest.showas_address2": string;
    "stop_dest.showas_city_id": number;
    "stop_dest.showas_city_name": string;
    "stop_dest.showas_location_id": string;
    "stop_dest.showas_location_name": string;
    "stop_dest.showas_state": string;
    "stop_dest.showas_zip_code": string;
    "stop_dest.signed_for_name": string;
    "stop_dest.state": string;
    "stop_dest.status": string;
    "stop_dest.stop_type": string;
    "stop_dest.trimble_place_id": string;
    "stop_dest.txl_uid": number;
    "stop_dest.volume": number;
    "stop_dest.volume_um": string;
    "stop_dest.weight": number;
    "stop_dest.weight_um": string;
    "stop_dest.zip_code": string;
    "stop_dest.zone_id": string;
    "stop_origin.actual_arrival": Date;
    "stop_origin.actual_departure": Date;
    "stop_origin.address": string;
    "stop_origin.address2": string;
    "stop_origin.appointment_status_id": string;
    "stop_origin.appt_contact_name": string;
    "stop_origin.appt_required": string;
    "stop_origin.cases": number;
    "stop_origin.city_id": number;
    "stop_origin.city_name": string;
    "stop_origin.company_id": string;
    "stop_origin.confirmed": string;
    "stop_origin.contact_name": string;
    "stop_origin.dist_from_prev": number;
    "stop_origin.dist_from_prev_um": string;
    "stop_origin.driver_load_unload": string;
    "stop_origin.edi_code": string;
    "stop_origin.edi_loc_code": string;
    "stop_origin.eta": Date;
    "stop_origin.eta_distance": number;
    "stop_origin.eta_late": string;
    "stop_origin.eta_oor": string;
    "stop_origin.google_place_id": string;
    "stop_origin.grouped_id": number;
    "stop_origin.hub": number;
    "stop_origin.ic_stop_id": string;
    "stop_origin.id": string;
    "stop_origin.inbound_status": string;
    "stop_origin.late_eta_colorcode": string;
    "stop_origin.late_route_status": string;
    "stop_origin.latitude": number;
    "stop_origin.location_id": string;
    "stop_origin.location_name": string;
    "stop_origin.longitude": number;
    "stop_origin.manifest_fgp_uid": number;
    "stop_origin.manifest_reg_uid": number;
    "stop_origin.move_dist_from_preassign": number;
    "stop_origin.move_dist_from_preassign_um": string;
    "stop_origin.move_dist_from_previous": number;
    "stop_origin.move_dist_from_previous_um": string;
    "stop_origin.movement_id": string;
    "stop_origin.movement_sequence": number;
    "stop_origin.operational_status": string;
    "stop_origin.order_id": string;
    "stop_origin.order_sequence": number;
    "stop_origin.orig_sched_early": Date;
    "stop_origin.orig_sched_late": Date;
    "stop_origin.pallets_dropped": number;
    "stop_origin.pallets_picked_up": number;
    "stop_origin.payment_code_reviewed": string;
    "stop_origin.phone": string;
    "stop_origin.planned_arrival_time": Date;
    "stop_origin.ponum": string;
    "stop_origin.prior_uncleared_stops": string;
    "stop_origin.projected_arrival": Date;
    "stop_origin.rate_dist_from_previous": number;
    "stop_origin.rate_dist_from_previous_um": string;
    "stop_origin.refno": string;
    "stop_origin.requested_service": string;
    "stop_origin.sched_arrive_early": Date;
    "stop_origin.sched_arrive_late": Date;
    "stop_origin.seal": string;
    "stop_origin.service_date": Date;
    "stop_origin.showas_address": string;
    "stop_origin.showas_address2": string;
    "stop_origin.showas_city_id": number;
    "stop_origin.showas_city_name": string;
    "stop_origin.showas_location_id": string;
    "stop_origin.showas_location_name": string;
    "stop_origin.showas_state": string;
    "stop_origin.showas_zip_code": string;
    "stop_origin.signed_for_name": string;
    "stop_origin.state": string;
    "stop_origin.status": string;
    "stop_origin.stop_type": string;
    "stop_origin.trimble_place_id": string;
    "stop_origin.txl_uid": number;
    "stop_origin.volume": number;
    "stop_origin.volume_um": string;
    "stop_origin.weight": number;
    "stop_origin.weight_um": string;
    "stop_origin.zip_code": string;
    "stop_origin.zone_id": string;
    target_extra_stop_pay: number;
    target_extra_stop_pay_c: string;
    target_extra_stop_pay_d: Date;
    target_extra_stop_pay_n: number;
    target_extra_stop_pay_r: number;
    target_fuel_surcharge_pay: string;
    target_pay: number;
    target_pay_c: string;
    target_pay_d: Date;
    target_pay_method: string;
    target_pay_n: number;
    target_pay_r: number;
    target_pay_units: number;
    target_rate_id: number;
    time_to_send_msg: Date;
    track_start_date: Date;
    tracking_duration_ext: number;
    tracking_notify_email: string;
    tracking_request_status: string;
    trailer_rent_pct: number;
    trip_lease: string;
    trip_mgt_recalc_datetime: Date;
    trip_mgt_tracking_id: string;
    triumphpay_exclude: string;
    trp_uid: number;
    ts_borrowing_company: string;
    ts_commodity: string;
    ts_movement_id: string;
    unlock_time: Date;
    waterfall_flood_time: Date;
    waterfall_in_progress: string;
    xfer2settle_date: Date;
    xferred2fueltax: string;
    xferred2fueltaxdt: Date;
    xmit_accepted: string;
    xmitted2driver: Date;
    zmit_trip: string;
    find_near_radius: number;
}

