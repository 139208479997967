export const sampleChartData = [
    { x: 2010, y: 10 },
    { x: 2011, y: 20 },
    { x: 2012, y: 18 },
    { x: 2013, y: 21 },
    { x: 2014, y: 22 },
    { x: 2015, y: 30 },
    { x: 2016, y: 28 },
    { x: 2017, y: 33 },
    { x: 2018, y: 34 },
    { x: 2019, y: 40 },
    { x: 2020, y: 42 },
    { x: 2021, y: 32 },
    { x: 2022, y: 34 },
];
