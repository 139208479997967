import { Endpoint, ModelRow } from "@mcleod/core";

const TableConfigEndpoint = "layout/table-config";

export class ModelTableConfig extends Endpoint<RowTableConfig, RowTableConfigFields> {
    constructor() {
        super(TableConfigEndpoint, RowTableConfig);
    }
}

export class RowTableConfig extends ModelRow<RowTableConfigFields> {
    constructor() {
        super(TableConfigEndpoint);
    }
}

export interface RowTableConfigFields {
    id: string;
    name: string;
    definition: string;
}
