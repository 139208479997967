import { Button, DataSource, Label, Layout, Panel, Switch, Textbox } from "@mcleod/components";
import { LoadboardUsersDat } from "../LoadboardUsersDat";
import { LoadboardUsersTruckstop } from "../LoadboardUsersTruckstop";

export abstract class AutogenLayoutPbwLoadboardServicesSlideout extends Layout {
    buttonAuthenticate: Button;
    checkboxWeekendPosting: Switch;
    labelAssociatedUsers: Label;
    labelPostFrom: Label;
    labelResult: Label;
    labelTo: Label;
    layoutLoadboardUsersDat: LoadboardUsersDat;
    layoutLoadboardUsersTruckstop: LoadboardUsersTruckstop;
    panelDatUserTable: Panel;
    panelOptions: Panel;
    panelPostTiming: Panel;
    panelSftpOptions: Panel;
    panelTruckstop: Panel;
    panelTruckstopUserTable: Panel;
    sourcePbwLoadboardServices: DataSource;
    switchRepostEnabled: Switch;
    switchShowHideSftp: Switch;
    textboxClientId: Textbox;
    textboxClientSecret: Textbox;
    textboxDaysAfterPickup: Textbox;
    textboxDaysPrior: Textbox;
    textboxDescription: Textbox;
    textboxLastPostingUpdate: Textbox;
    textboxPassword: Textbox;
    textboxPostingEnd: Textbox;
    textboxPostingFrequency: Textbox;
    textboxPostingStart: Textbox;
    textboxRemoteDirectory: Textbox;
    textboxRemotePort: Textbox;
    textboxServiceType: Textbox;
    textboxSftpFilePrefix: Textbox;
    textboxTranscorePassword: Textbox;
    textboxUrl: Textbox;
    textboxUserId: Textbox;
    textboxVendorUserId: Textbox;
}
