import { AuthToken } from "./AuthToken";
import { ErrorHandler, handleError } from "./ErrorHandler";
import { setForwardDestination } from "./Forward";
import { getLogger, LogConfig } from "./Logger";
import { BroadcastMessageHandler } from "./messaging/BroadcastMessageHandler";
import { Navigation } from "./Navigation";
import { ObjectUtil } from "./ObjectUtil";
import { ServerError } from "./ServerError";

const log = getLogger("lib.util.api");
const broadcastChannel = new BroadcastChannel("api-channel");
broadcastChannel.onmessage = (event) => handleBroadcastMessage(event.data);

const POST_ERROR_DEFAULT_TEXT = "An error occurred while posting data.";
const DELETE_ERROR_DEFAULT_TEXT = "An error occurred while deleting data.";
const SEARCH_ERROR_DEFAULT_TEXT = "An error occurred while searching for data.";

export enum ApiMethod {
    GET = "GET",
    SEARCH = "PATCH",
    POST = "POST",
    UPDATE = "PUT",
    DELETE = "DELETE",
}

interface ApiOptions {
    allowRedirect?: boolean;
    sendAuth?: boolean;
    appendPrefix?: boolean;
}

export class Api {
    private static _BASE_URL: string;
    private static apiContext: string = "mcleod-api-lme";
    private static localHostServerPort = 8080;

    public static get BASE_URL(): string {
        if (this._BASE_URL == null) {
            let result = window.location.origin;
            const localHostPos = result.indexOf("localhost");
            if (localHostPos >= 0) {
                const slashPos = result.lastIndexOf("/", localHostPos);
                result = result.substring(slashPos + 1, localHostPos + 9);
                result = "http://" + result + ":" + Api.localHostServerPort + "/";
            }
            else if (result.indexOf("azurewebsites.net") >= 0 || result.indexOf("mcleod-internal.com") >= 0 || result.indexOf("abctrucking") >= 0)
                result = "https://mcleod-api-auth-develop.azurewebsites.net/";
            else
                result += "/" + this.apiContext + "/";
            this._BASE_URL = result;
        }
        return this._BASE_URL;
    }

    public static setLocalHostServerPort(value: number) {
        Api.localHostServerPort = value;
    }

    public static async post(endpoint: string, body?: any, options?: ApiOptions, errorHandler?: ErrorHandler): Promise<any> {
        return callApi(endpoint, ApiMethod.POST, body, options, errorHandler || POST_ERROR_DEFAULT_TEXT);
    }

    public static async get(endpoint: string, body?: any, options?: ApiOptions, errorHandler?: ErrorHandler): Promise<any> {
        return callApi(endpoint, ApiMethod.GET, body, options, errorHandler || SEARCH_ERROR_DEFAULT_TEXT);
    }

    public static async search<ResultType>(endpoint: string, body?: any, options?: ApiOptions, errorHandler?: ErrorHandler): Promise<ResultType | any> {
        return callApi(endpoint, ApiMethod.SEARCH, body, options, errorHandler || SEARCH_ERROR_DEFAULT_TEXT);
    }

    public static async update(endpoint: string, body?: any, options?: ApiOptions, errorHandler?: ErrorHandler): Promise<any> {
        return callApi(endpoint, ApiMethod.UPDATE, body, options, errorHandler || POST_ERROR_DEFAULT_TEXT);
    }

    public static async delete(endpoint: string, body?: any, options?: ApiOptions, errorHandler?: ErrorHandler): Promise<any> {
        return callApi(endpoint, ApiMethod.DELETE, body, options, errorHandler || DELETE_ERROR_DEFAULT_TEXT);
    }

    /**
   * This method sets the first path of the context path of the URL that is used when making api calls.
   * @param value
   */
    public static setContextPath(value: string) {
        this.apiContext = value;
    }
}

function logApi(url: string, method: string, body: any, response: any) {
    if (log.isDebugEnabled()) {
        let logMethod: string = method;
        if (logMethod === "PATCH")
            logMethod = "SEARCH";
        if (body == null) {
            if (response == null)
                log.debug(() => ["%c%s API %s", LogConfig.get()?.["logFormat.css.apiRequest"], logMethod, url]);
            else
                log.debug(() => ["%cResponse of %s API %s: %o", LogConfig.get()?.["logFormat.css.apiResponse"], logMethod, url, response]);
        }
        else {
            if (url.endsWith("/login"))
                log.debug(() => ["%c%s API %s (login info not shown)", LogConfig.get()?.["logFormat.css.apiRequest"], logMethod, url]);
            else if (response == null)
                log.debug(() => ["%c%s API %s with body %o", LogConfig.get()?.["logFormat.css.apiRequest"], logMethod, url, body]);
            else
                log.debug(() => ["%cResponse of %s API %s with body %o: %o", LogConfig.get()?.["logFormat.css.apiResponse"], logMethod, url, body, response]);
        }
    }
}

export async function callApi(endpoint: string, method: ApiMethod = ApiMethod.GET, body?: any, options?: ApiOptions, errorHandler?: ErrorHandler): Promise<any> {
    if (endpoint == null)
        throw new Error("Cannot callApi without an endpoint.");
    if (method === ApiMethod.UPDATE && body?.key != null)
        delete body.key;
    const fullURL = Api.BASE_URL + endpoint;
    logApi(fullURL, method, body, null);
    if (ObjectUtil.isObject(body) || Array.isArray(body))
        body = JSON.stringify(body);
    const token = AuthToken.get();
    let authHeader = null;
    if (token != null && token.length > 0 && (options == null || options.sendAuth !== false))
        authHeader = "Bearer " + token;
    const headers = {
        Accept: "application/json",
        Authorization: authHeader,
        "Content-type": "application/json",
        "X-Client-Id": BroadcastMessageHandler.getInstanceIdentifier()
    };
    return fetch(fullURL, { headers: headers, method: method, body: body })
        .catch(reason => handleFetchError(reason, errorHandler))
        .then(response => handleFetchResponse(endpoint, response, options, errorHandler).then(response => {
            logApi(fullURL, method, body, response);
            return response;
        }));
}

async function handleFetchResponse(endpoint: string, response: any, options: ApiOptions, errorHandler?: ErrorHandler): Promise<any> {
    if (response == null || !response.ok)
        return handleFetchBadResponse(response, options, errorHandler)
    const refreshToken = response.headers.get("refresh-token");
    if (refreshToken != null && !endpoint.endsWith("/logout")) {
        broadcastChannel.postMessage({ type: "refreshToken", value: refreshToken });
        AuthToken.set(refreshToken);
    }
    if (response.headers.get("content-type") === "application/octet-stream")
        return Promise.resolve(getStream(response.body.getReader()));
    else
        return response.json();
}

async function handleFetchBadResponse(response: any, options: ApiOptions, errorHandler?: ErrorHandler) {
    if (response == null)
        throw new Error("There was no response from the API call.")
    if (response.status === 401) {
        if (options?.allowRedirect !== false)
            sessionExpired();
        return;
    }
    let text = await Promise.resolve(response.json());
    const type = typeof text?.message;
    if (type === "object" || (type === "string" && text.message?.startsWith("{"))) {
        text = new ServerError(text.message);
        // if (typeof errorHandler === "string" && text.messages.length > 0)
        //   text.replaceMessage(errorHandler, 0);
    }
    else if (typeof errorHandler === "string")
        text = errorHandler;
    log.debug("Error %o", text);
    return Promise.reject(text);
}

async function handleFetchError(reason: any, handler?: ErrorHandler): Promise<any> {
    try {
        if (handler == null)
            throw reason;
        else
            handleError(reason, handler);
    }
    catch (error) {
        return Promise.reject([
            "There was a problem connecting to the server.  Please check your network connection and try again.",
            "If the problem persists, the server may not be running or having networking issues."
        ]);
    }
}

async function getStream(reader) {
    return await parseReader(reader);
}

function parseReader(reader) {
    let result = [];
    return new Promise(resolve => {
        reader.read().then(function process({ done, value }) {
            if (done) {
                resolve(result);
                return;
            }
            result = result.concat(Array.from(value));
            return reader.read().then(process);
        });
    });
}

function sessionExpired(): void {
    AuthToken.clear();
    setForwardDestination(window.location.pathname + window.location.search);
    // ideally we would may want to check for unsaved data before navigating to the session expired page
    // we can't really do anything about it because the token is expired, but we might want to leave it on the
    // screen so they can copy it or something

    // really ideally, we could pop up a dialog asking for login and then continue whatever API call we were trying to do
    Navigation.navigateTo("common/SessionExpired", { hardRefresh: true });
}

function handleBroadcastMessage(data: any) {
    log.debug("Received message on api-channel", data);
    if (data.type === "refreshToken") {
        const current = AuthToken.get();
        if (current == null)
            return;
        const currentParsed = AuthToken.parseToken(current);
        const newParsed = AuthToken.parseToken(data.value);
        log.debug("Received new token", currentParsed, newParsed);
        if (newParsed.exp > currentParsed.exp
            && newParsed.sub === currentParsed.sub
            && newParsed.company_id === currentParsed.company_id
            && newParsed.userKey === currentParsed.userKey) {
            log.debug("Updated token", currentParsed, newParsed);
            AuthToken.set(data.value);
        }
    }
}
