import { DataSource, Layout, Textbox } from "@mcleod/components";
import { ResponsibleHist } from "../../../general/src/ResponsibleHist";

export abstract class AutogenLayoutMovementUsers extends Layout {
    layoutResponsibleHist: ResponsibleHist;
    sourceMovement: DataSource;
    sourceResponsibleHist: DataSource;
    textboxDispatcherUserId: Textbox;
    textboxOperationsUser: Textbox;
}
