import { ClickEvent, Switch, TableRow, TableRowDisplayEvent } from "@mcleod/components";
import { ModelRow, StringUtil } from "@mcleod/core";
import { AutogenLayoutCarrierDriverMessage } from "./autogen/AutogenLayoutCarrierDriverMessage";

export class CarrierDriverMessage extends AutogenLayoutCarrierDriverMessage {


    tableCarrierDriverMessageOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.getTableRow();
        const switchMessageRead = tableRow.findComponentById("switchMessageRead") as Switch;
        if (StringUtil.equalsIgnoreCase(tableRow.data.get("direction_display"), "Inbound")) {
            switchMessageRead.visible = true;
            switchMessageRead.printable = false;
        } else {
            switchMessageRead.visible = false;
        }


        tableRow.addDblClickListener((event: ClickEvent) => {
            const tableRow = event.target as TableRow;
            const modelRow = tableRow?.data as ModelRow;
            const switchMessageRead = tableRow.findComponentById("switchMessageRead") as Switch;
            if (!switchMessageRead.checked) {
                switchMessageRead.checked = true;
                modelRow.set("message_read", "Y");
                modelRow.post();
            }
        });
    }


    switchMessageReadOnClick(event: ClickEvent) {
        const switchMessageRead = event.target as Switch;
        const tableRow = TableRow.getContainingTableRow(switchMessageRead);
        const modelRow = tableRow.data;
        if (StringUtil.equalsIgnoreCase(modelRow.get("message_read"), "Y")) {
            modelRow.set("message_read_display", "Yes");
        } else {
            modelRow.set("message_read_display", "No");
        }
        modelRow.post();
    }

    switchMessageReadBtnToRead(tableRow: TableRow, modelRow: ModelRow) {
        alert("Hit");
        const switchMessageRead = tableRow.findComponentById("switchMessageRead") as Switch;
        if (!switchMessageRead.checked) {
            switchMessageRead.checked = true;
            modelRow.set("message_read", "Yes");
            modelRow.post();
        }
    }
}


