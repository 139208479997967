import { ClickEvent, DataSource, SelectionEvent } from "@mcleod/components";
import { Collection } from "@mcleod/core";
import { ModelGroupMembership, RowGroupMembership } from "../models/ModelGroupMembership";
import { ModelUsers, RowUsers } from "../models/ModelUsers";
import { AutogenLayoutUserGroups } from "./autogen/AutogenLayoutUserGroups";

export class UserGroupList extends AutogenLayoutUserGroups {
    private allUsers: Collection<RowUsers>;

    async onLoad() {
        this.allUsers = (await new ModelUsers().search()).mapRowsByField("id");
    }

    /** This is an event handler for the onSelect event of tableGroups.  */
    tableGroupsOnSelect(event: SelectionEvent) {
        const group = this.tableGroups.selectedRow?.data.get("id");
        this.panelMembership.visible = group != null;
        if (group == null) {
            this.tableUsersInGroup.clearRows();
            return;
        }
        const groupName = this.tableGroups.selectedRow?.data.get("descr");
        this.labelGroupMembership.caption = "Group Membership for " + (groupName || "Group");
        new ModelGroupMembership().search({ "user_group_id": group }).then(response => {
            const usersInGroup = [];
            const allUserClone = { ...this.allUsers };
            for (const member of response.modelRows) {
                const userId = member.get("user_id");
                const userRow = allUserClone[userId];
                if (userRow != null) {
                    usersInGroup.push(userRow);
                    delete allUserClone[userId];
                }
            }
            this.sourceUsersInGroup.data = usersInGroup;
            this.sourceUsersInGroup.displayDataInBoundComponents();
            this.sourceUsersNotInGroup.data = Object.values(allUserClone);
            this.sourceUsersNotInGroup.displayDataInBoundComponents();
        });
    }

    /** This is an event handler for the onClick event of buttonRemoveUser.  */
    buttonRemoveUserOnClick(event: ClickEvent) {
        this.transferUser(event.target["row"]?.data, this.sourceUsersInGroup, this.sourceUsersNotInGroup, true);
    }

    /** This is an event handler for the onClick event of buttonAddUser.  */
    buttonAddUserOnClick(event: ClickEvent) {
        this.transferUser(event.target["row"]?.data, this.sourceUsersNotInGroup, this.sourceUsersInGroup, false);
    }

    private transferUser(userRow: RowUsers, sourceFrom: DataSource, sourceTo: DataSource, deleteMembership: boolean): void {
        if (userRow == null || this.tableGroups.selectedRow == null)
            return;
        sourceTo.addRow(userRow);
        const index = sourceFrom.data.indexOf(userRow);
        if (index >= 0)
            sourceFrom.deleteTableRow(index, false);
        const membership = new RowGroupMembership();
        membership.set("user_group_id", this.tableGroups.selectedRow?.data.get("id"));
        membership.set("user_id", userRow.get("id"));
        deleteMembership ? membership.delete() : membership.post();
    }
}
