import { Button, DataSource, Label, Layout, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutBrokeragePlanningLocationQuickInfo extends Layout {
    buttonOpenLocation: Button;
    labelLabel1: Label;
    labelLabel2: Label;
    labelReferenceNumbers: Label;
    panel1: Panel;
    panel5: Panel;
    panelArrivalDeparture: Panel;
    panelRefNbrs: Panel;
    sourceOrderStop: DataSource;
    textboxActualArrival: Textbox;
    textboxActualDeparture: Textbox;
    textboxContactName: Textbox;
    textboxLocationName: Textbox;
    textboxPhone: Textbox;
}
