import { Button, CityState, DataSource, Layout, Panel, Textbox, } from "@mcleod/components";

export abstract class AutogenLayoutCustomerQuickInfo extends Layout {
    buttonOpenCustomer: Button;
    citystate1: CityState;
    panel1: Panel;
    sourceCustomer: DataSource;
    textboxId: Textbox;
    textboxName: Textbox;
    textboxSalespersonName: Textbox;
}
