import { DataLoadActionHandler, DataLoadActionType } from "@mcleod/common";
import { ClickEvent } from "@mcleod/components";
import { Navigation, UrlUtil } from "@mcleod/core";
import { AutogenLayoutArrivedAtShipper } from "./autogen/AutogenLayoutArrivedAtShipper";

export class ArrivedAtShipper extends AutogenLayoutArrivedAtShipper {

    /** This is an event handler for the onClick event of labelArrivedAtShipper.  */
    labelArrivedAtShipperOnClick(event: ClickEvent) {
        const urlParams: string[] = [];
        let dlaCount = 0;
        urlParams.push("arrivedAtShipper=true");
        urlParams.push(DataLoadActionHandler.createUrlQueryParamString(DataLoadActionType.SELECT_TAB, "tabCoveredOrders", ++dlaCount));

        Navigation.navigateTo("lme/powerbroker/BrokeragePlanning" + UrlUtil.buildQueryString(urlParams));
    }
}
