import { CommonTooltips } from "@mcleod/common";
import {
    BlurEvent, Component, Table, TableRow, TableRowDisplayEvent, Textbox, KeyEvent, KeyModifiers, TableCell,
    RowModeControlType
} from "@mcleod/components";
import { Alignment, Currency, Keys, ModelRow } from "@mcleod/core";
import { AutogenLayoutCarrierSelectionExpand } from "./autogen/AutogenLayoutCarrierSelectionExpand";

export class CarrierSelectionExpand extends AutogenLayoutCarrierSelectionExpand {

    private _parentTableRow: TableRow;
    private _chosenCarrier: ModelRow;

    public setParentTableRow(tableRow: TableRow) {
        this._parentTableRow = tableRow
    }

    public get chosenCarrier(): ModelRow {
        return this._chosenCarrier;
    }

    public set chosenCarrier(value: ModelRow) {
        this._chosenCarrier = value;
    }

    tableRevenueDetailsOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.getTableRow() as TableRow;
        const modelRow: ModelRow = tableRow.data as ModelRow;
        if (RowModeControlType.AUTO == this.tableRevenueDetails.rowModeControlType) this.disableFields(tableRow);
        if (Number.parseFloat(modelRow.get("revenue_amount")["amount"]) == 0.0) {
            const textMargin: Textbox = tableRow.findComponentById("textboxMarginAmount") as Textbox;
            textMargin.enabled = false;
            const textMarkup: Textbox = tableRow.findComponentById("textboxMarkupAmount") as Textbox;
            textMarkup.enabled = false;
        }
        this.addBuySellTooltips(tableRow);
        tableRow.canBeDeleted = false;
        tableRow.forEveryChildComponent((component: Component) => {
            if (component instanceof Textbox && component.enabled)
                component.addKeyDownListener((event: KeyEvent) => this.handleTab(event, tableRow));
        })
    }

    handleTab(event: KeyEvent, tableRow: TableRow, textBoxId?: String) {
        const noModifiers: KeyModifiers = { ctrlKey: false, shiftKey: false, altKey: false };
        const shiftModifier: KeyModifiers = { ctrlKey: false, shiftKey: true, altKey: false };
        if (event.isKey(Keys.TAB, noModifiers) === true || event.isKey(Keys.TAB, shiftModifier) === true) {
            const forward: boolean = event.isKey(Keys.TAB, noModifiers) === true
            const id = textBoxId ? textBoxId : event.target.id;
            const nextRow = tableRow.table.rows[forward ? tableRow.index + 1 : tableRow.index - 1];
            const currenctTextBox = tableRow.findComponentById(id) as Textbox;
            const nextTextBox = nextRow?.findComponentById(id) as Textbox;

            if (nextTextBox === undefined) {
                const isLastCell = tableRow.isLastCellInRow(currenctTextBox.findParentOfType("cell") as TableCell);
                if (isLastCell === true && forward === true)
                    return;
                this.tabOverDisabledFields(event, tableRow, forward, currenctTextBox, nextTextBox);
            }
            else if (nextTextBox.enabled === false && nextRow !== undefined) {
                if(textBoxId !== undefined)
                    this.handleTab(event, nextRow, textBoxId);
                else
                    this.handleTab(event, nextRow);
            }
            else
                (nextRow?.findComponentById(id) as Textbox)?.focus();
            event.preventDefault();
        }
    }

    tabOverDisabledFields(event: KeyEvent, tableRow: TableRow, forward: boolean, currenctTextBox: Textbox, nextTextBox: Textbox) {
        const nextRow = tableRow.table.rows[forward ? 0: tableRow.table.rows.length - 1];
        const textbox = nextTextBox !== undefined ? nextTextBox : currenctTextBox;
        const parentCell = textbox?.findParentOfType("cell") as TableCell;
        let index: number;
        for (index = 0; index < tableRow.cells.length; index++) {
            if (parentCell?.id === tableRow.cells[index].id)
                break;
        }
        if (index !== -1) {
            const nextCell = nextRow.cells[forward ? index+1 : index-1];
            nextCell?.forEveryChildComponent((comp) => {
                if (comp instanceof Textbox) {
                    if(comp.enabled === true)
                        comp.focus();
                    else
                        this.handleTab(event, nextRow, comp.id);
                }
            });
        }
    }

    disableFields(tableRow: TableRow) {
        tableRow.forEveryChildComponent((component: Component) => {
            if (component instanceof Textbox) {
                component.printable = true;
                component.enabled = false;
                component.backgroundColor = null;
            }
            component.marginTop = 5;
        });
    }

    addBuySellTooltips(tableRow: TableRow) {
        const modelRow: ModelRow = tableRow.data as ModelRow;
        const buyRateModelRow = new ModelRow(modelRow._modelPath, false, modelRow.data);
        buyRateModelRow.set("charge_as_calc_description", modelRow.get("buy_rate_description"));
        buyRateModelRow.set("tariff_id", modelRow.get("buy_rate_tariff_id"));
        buyRateModelRow.set("tli_uid", modelRow.get("buy_rate_tli_uid"));
        const sellRateModelRow = new ModelRow(modelRow._modelPath, false, modelRow.data);
        sellRateModelRow.set("charge_as_calc_description", modelRow.get("sell_rate_description"));
        sellRateModelRow.set("tariff_id", modelRow.get("sell_rate_tariff_id", null));
        sellRateModelRow.set("tli_uid", modelRow.get("sell_rate_tli_uid", null));
        if (buyRateModelRow.get("charge_as_calc_description", null) != null) {
            CommonTooltips.setTooltipFromLayoutCallback(tableRow.findComponentById("textboxBuyRate"), buyRateModelRow, "lme/dispatch/RevenueDetailQuickInfo", null, { position: Alignment.TOP });
        }
        if (sellRateModelRow.get("tariff_id", null) != null)
            CommonTooltips.setTooltipFromLayoutCallback(tableRow.findComponentById("textboxChargeCostAsAmount"), sellRateModelRow, "lme/dispatch/RevenueDetailQuickInfo", null, { position: Alignment.TOP });
    }

    performCalculationsOnRatingData(modelRow: ModelRow, fieldChanged: String) {
        if (modelRow.isNull("revenue_amount")) return;
        const buyRate = modelRow.get("revenue_amount").amount;
        let sellRate: number;

        switch (fieldChanged) {
            case "margin_amount":
                const margin = modelRow.get("margin_amount", 0);
                sellRate = -buyRate / (margin / 100 - 1);
                break;
            case "profit_amount":
                const profit = modelRow.get("profit_amount", 0).amount;
                sellRate = profit + buyRate;
                break;
            case "markup_amount":
                const markup = modelRow.get("markup_amount", 0);
                sellRate = (buyRate * markup / 100) + buyRate;
                break;
            case "charge_cost_as_amount":
                sellRate = modelRow.get("charge_cost_as_amount", 0).amount;
                break;
            default:
                return;
        }
        this.updateRevenueRowMetrics(buyRate, Number.parseFloat(sellRate.toFixed(2)), modelRow);
    }

    updateRevenueRowMetrics(buy: number, sell: number, modelRow: ModelRow) {
        const profit: number = sell - buy;
        if (buy != 0.0 && buy / sell != 1.0)
            modelRow.set("markup_amount", 100 * profit / buy);
        if (sell != 0.0 && sell / profit != 1.0)
            modelRow.set("margin_amount", 100 * profit / sell);
        modelRow.set("profit_amount", Currency.createCurrency(profit));
        modelRow.set("charge_cost_as_amount", Currency.createCurrency(sell));
        this.recalculateParentRowTotals();
    }

    recalculateParentRowTotals() {
        const totalRevenueAmount = Currency.createCurrency(0);
        const totalCostAmount = Currency.createCurrency(0);
        const totalProfitAmount = Currency.createCurrency(0);
        this.mainDataSource.data.forEach((row: ModelRow) => {
            if (row.get("revenue_amount") != null && row.get("charge_cost_as_amount") != null) {
                totalRevenueAmount.amount += row.get("revenue_amount").amount;
                totalCostAmount.amount += row.get("charge_cost_as_amount").amount;
                totalProfitAmount.amount += row.get("profit_amount").amount;
            }
        })
        const parentModelRow: ModelRow = this._parentTableRow.data as ModelRow;
        parentModelRow.set("total_revenue_amount", totalRevenueAmount);
        parentModelRow.set("total_charge_amount", totalCostAmount);
        parentModelRow.set("total_profit_amount", totalProfitAmount);
        parentModelRow.set("total_markup_amount", 100 * totalProfitAmount.amount / totalRevenueAmount.amount);
        parentModelRow.set("total_margin_amount", 100 * totalProfitAmount.amount / totalCostAmount.amount);
        parentModelRow.set("revenue_details", this.mainDataSource.data.map((row: ModelRow) => row.data));
        const parentTable: Table = this._parentTableRow.table;
        const idx = this._parentTableRow.index;
        parentTable.redisplaySingleRow(idx, parentModelRow, false, true);
    }

    textboxOnBlur(event: BlurEvent) {
        const component: Textbox = event.target as Textbox;
        const tableRow: TableRow = TableRow.getContainingTableRow(event.target as Textbox);
        if (tableRow.data.get(component.field) != null && (event.changedWhileFocused || component.displayType == "currency")) {
            this.performCalculationsOnRatingData(tableRow.data, component.field);
        }
    }
}
