/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelLoadboards.ts                    *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/powerbroker/loadboards";

export class AutogenModelLoadboards<RowType extends ModelRow> extends Endpoint<RowType, RowLoadboardsFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowLoadboards extends ModelRow<RowLoadboardsFields> {
    constructor(values?: Partial<RowLoadboardsFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowLoadboardsFields {
    allow_cntr_offer: string;
    amount: number;
    amount_c: string;
    amount_d: Date;
    amount_n: number;
    amount_r: number;
    carrier_name: string;
    carrier_ranking: number;
    carrier_rate_calc_pay: number;
    carrier_rate_calc_pay_c: string;
    carrier_rate_calc_pay_d: Date;
    carrier_rate_calc_pay_n: number;
    carrier_rate_calc_pay_r: number;
    carrier_rate_id: number;
    carrier_rate_lane_id: string;
    comments: string;
    company_id: string;
    contact_name: string;
    contact_phone: string;
    counter_offer: number;
    counter_offer_c: string;
    counter_offer_d: Date;
    counter_offer_n: number;
    counter_offer_r: number;
    decline_descr: string;
    decline_reason: string;
    dot_number: string;
    electronic_offer: string;
    email: string;
    email_subject: string;
    entered_user_id: string;
    equipment_type_id: string;
    fax: string;
    icc_number: string;
    id: string;
    max_pay: number;
    max_pay_c: string;
    max_pay_d: Date;
    max_pay_n: number;
    max_pay_r: number;
    miles_to_origin: number;
    mobile_phone: string;
    modified_date: Date;
    movement_id: string;
    my_avail_offers: string;
    offer_date: Date;
    offer_sequence: number;
    order_id: string;
    order_total_chg: number;
    order_total_chg_c: string;
    order_total_chg_d: Date;
    order_total_chg_n: number;
    order_total_chg_r: number;
    payee_id: string;
    response_time: number;
    routing_guide_profile_id: string;
    salesperson_id: string;
    source: string;
    status: string;
    target_pay: number;
    target_pay_c: string;
    target_pay_d: Date;
    target_pay_n: number;
    target_pay_r: number;
    tractor_available_date: Date;
    tractor_city: string;
    tractor_city_id: number;
    tractor_expires_date: Date;
    tractor_state: string;
    tractor_zip: string;
    waterfall_offer: string;
}

