import { CityState, DataSource, Label, Layout, Panel, SearchButton, Table, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutCarrierRateHistory extends Layout {
    destCityStateZip: CityState;
    labelAnd: Label;
    labelHighestRate: Label;
    labelLowestRate: Label;
    originCityStateZip: CityState;
    panel1: Panel;
    panel2: Panel;
    panel3: Panel;
    searchbuttonSearch: SearchButton;
    sourceCarrierRateHistory: DataSource;
    sourceLocation: DataSource;
    table1: Table;
    textboxCutoffDateRange: Textbox;
    textboxDestMarketArea: Textbox;
    textboxDestState: Textbox;
    textboxDestType: Textbox;
    textboxDestZip: Textbox;
    textboxDestZone: Textbox;
    textboxLeastPaid: Textbox;
    textboxMostPaid: Textbox;
    textboxMovementoverridePayeeId: Textbox;
    textboxOrigZone: Textbox;
    textboxOriginMarketArea: Textbox;
    textboxOriginState: Textbox;
    textboxOriginType: Textbox;
    textboxOriginZip: Textbox;
    textboxRadius: Textbox;
}
