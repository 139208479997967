export const props = { viewBox: "0 0 59 12" };
export const innerHTML = `<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
<g id="Group" fill-rule="nonzero">
    <path d="M9.35314503,0.52173913 L10.0901175,6.55029955 L10.2152638,6.55029955 C10.3973983,6.0479941 10.5865994,5.51546982 10.8371199,5.0274904 L13.1936994,0.52173913 L18.1790344,0.52173913 L18.2608696,12 L13.5260551,12 L14.0332507,5.71267614 L13.9438931,5.71267614 C13.784098,6.09343445 13.6213395,6.48896635 13.4107108,6.8545032 L10.5065878,12 L7.64714374,12 L6.7991581,7.14393323 C6.7471848,6.65684922 6.7063812,6.19976019 6.76154583,5.71267614 L6.6006109,5.71267614 C6.48002374,6.16954132 6.3564731,6.64118009 6.21742169,7.09849299 L4.73527043,12 L0,12 L4.31332918,0.52173913 L9.35314503,0.52173913 Z" id="Fill-1" fill="#FFD040"></path>
    <path d="M25.3959297,0.52173913 L25.5877017,0.524479054 C26.6620327,0.555531521 27.5259533,0.850529959 28.1794635,1.40947437 C28.8714153,2.00129786 29.2173913,2.91758598 29.2173913,4.15833874 C29.2173913,5.51330305 28.8714153,6.47112266 28.1794635,7.03179751 C27.4875116,7.59247242 26.4993833,7.87280984 25.2150786,7.87280984 L22.7539315,7.87280984 L22.7539315,12 L20.3478261,12 L20.3478261,0.52173913 L25.3959297,0.52173913 Z M25.0126987,2.56231884 L22.6956522,2.56231884 L22.6956522,5.87826089 L25.0126987,5.87826089 C25.5993609,5.87826089 26.0555042,5.74328014 26.3811286,5.47331864 C26.706753,5.20335715 26.8695652,4.77549367 26.8695652,4.18972818 C26.8695652,3.60396269 26.706753,3.18628642 26.3811286,2.93669939 C26.0555042,2.68711236 25.5993609,2.56231884 25.0126987,2.56231884 Z" id="Combined-Shape" fill="#000000"></path>
    <path d="M34.5117728,0.52173913 L38.6086957,12 L35.9872929,12 L35.2416843,9.64049317 L30.9799427,9.64049317 L30.1793945,12 L27.6521739,12 L31.7804908,0.52173913 L34.5117728,0.52173913 Z M33.2810482,2.94492753 L31.9058824,7.66376812 L34.6127877,7.66376812 L33.2810482,2.94492753 Z" id="Combined-Shape" fill="#000000"></path>
    <path d="M44.0370112,12 C45.400723,12 46.5190197,11.6085548 47.3919013,10.8256643 C48.2647829,10.0427738 48.8073492,9.0265716 49.0196,7.77705767 L49.0196,7.77705767 L46.5919809,7.77705767 C46.4274865,8.36811407 46.2205419,8.81140633 45.9711472,9.10693456 C45.5201141,9.65651327 44.8647896,9.93130268 44.0051736,9.93130268 C43.1667827,9.93130268 42.4756409,9.61114711 41.9317481,8.97083603 C41.3878553,8.33052496 41.1159089,7.367466 41.1159089,6.08165911 C41.1159089,4.79585224 41.373263,3.80168503 41.8879713,3.09915749 C42.4026797,2.39662994 43.1057606,2.04536617 43.9972142,2.04536617 C44.872749,2.04536617 45.5333798,2.29423202 45.9791066,2.79196371 C46.2285013,3.07193778 46.4327927,3.48671419 46.5919809,4.03629294 L46.5919809,4.03629294 L49.0434783,4.03629294 C49.0063343,3.32080363 48.7357145,2.60790667 48.2316187,1.89760207 C47.3189401,0.632534024 45.8756343,0 43.9017013,0 C42.4265579,0 41.2114218,0.471808166 40.2562929,1.4154245 C39.1578947,2.49902787 38.6086957,4.04147764 38.6086957,6.04277379 C38.6086957,7.89371353 39.0915664,9.35061569 40.0573077,10.4134802 C41.0230491,11.4711601 42.349617,12 44.0370112,12 Z" id="Path" fill="#000000"></path>
    <polygon id="Path" fill="#000000" points="54.9661351 12 54.9661351 2.55418559 58.4347826 2.55418559 58.4347826 0.52173913 49.0434783 0.52173913 49.0434783 2.55418559 52.5278567 2.55418559 52.5278567 12"></polygon>
</g>
</g>`
