import { Button, ButtonVariant, Checkbox, Label, Panel, Textbox } from "@mcleod/components";
import { ReflectiveDialogs } from "@mcleod/components/src/base/ReflectiveDialogs";
import { AuthType, HorizontalAlignment, Keys } from "@mcleod/core";
import { PanelOpenLayout } from "../ui/PanelOpenLayout";
import { ModelDefinition } from "./ModelDesignerTypes";
import { PanelModelTable } from "./PanelModelTable";

/**
 * This allows editing the top-level properties of a model in the Model Designer page.
 *
 * Note: at some point we should bite the bullet and tie the components to a DataSource so that we don't have to continue writing
 * logic to display newly-added fields in the ModelDefinition interface and the accompanying ChangeListener to update the _def.
 */
export class PanelModelProps extends Panel {
    textUserTypes: Textbox;
    checkRequiresFieldList: Checkbox;
    textDisplayFormat: Textbox;
    textModelForDomain: Textbox;
    textLookupLayout: Textbox;
    textEntryLayout: Textbox;
    textQuickInfoLayout: Textbox;
    textSortByField: Textbox;
    textDatabaseKey: Textbox;
    checkMultiCompany: Checkbox;
    panelRootTable: PanelModelTable;
    panelDetail: Panel;
    panelLookupModelInfo: Panel;
    panelLookupModelInset: Panel;
    panelLookupModelPopulated: Panel;
    labelLookupModel: Label;
    buttonToggleDetail: Button;
    _def: ModelDefinition;

    constructor(rootTablePanel: PanelModelTable) {
        super({
            backgroundColor: "defaultBackground",
            borderColor: "#AAA",
            borderRadius: 6,
            borderShadow: true,
            borderLeftColor: "warning.dark",
            borderLeftWidth: 10,
            marginBottom: 28,
            minWidth: 408
        });
        this.panelRootTable = rootTablePanel;
        this.createLookupModelFields();
        this.createSortFields();
        this.createDatabaseKeyField();
        this.textUserTypes = new Textbox({
            fillRow: true,
            caption: "User types",
            items: Object.keys(AuthType)
        });
        this.textUserTypes.addKeyUpListener(event => {
            if (event.key === Keys.ENTER)
                this.panelRootTable.focus();
        });
        this.checkRequiresFieldList = new Checkbox({ caption: "This model requires a field list" });
        this.checkRequiresFieldList.addChangeListener(() => this.def.fieldListRequired = this.checkRequiresFieldList.checked);
        this.checkMultiCompany = new Checkbox({ caption: "Enable multi-company" });
        this.checkMultiCompany.addChangeListener(() => this.def.multiCompany = this.checkMultiCompany.checked);
        this.buttonToggleDetail = new Button({ imageName: "pencil", variant: ButtonVariant.round, color: "subtle.darker", onClick: () => this.toggleDetail() });
        this.add(new Label({ caption: "Model Properties", fillRow: true, fontBold: true, fontSize: "large", color: "primary", align: HorizontalAlignment.CENTER, rowBreak: false }));
        this.add(this.buttonToggleDetail);
        this.panelDetail = new Panel({ fillRow: true });
        this.panelLookupModelInfo = new Panel({ fillRow: true, borderWidth: 1, borderRadius: 4, borderColor: "strokeSecondary", margin: 16, paddingTop: 8 });
        this.panelLookupModelInset = new Panel({ fillRow: true, paddingTop: 16, paddingBottom: 16, paddingLeft: 24, paddingRight: 24 });
        this.panelLookupModelPopulated = new Panel({ fillRow: true, padding: 0, visible: false });

        this.labelLookupModel = new Label({ caption: "Lookup Model Information", fontBold: true });
        this.panelLookupModelInfo.add(this.labelLookupModel, this.panelLookupModelInset);
        this.panelLookupModelInset.add(this.textModelForDomain, this.panelLookupModelPopulated);
        this.panelLookupModelPopulated.add(this.textDisplayFormat, this.textEntryLayout, this.textQuickInfoLayout, this.textLookupLayout);
        this.panelDetail.add(this.textSortByField, this.textUserTypes, this.textDatabaseKey, this.checkRequiresFieldList, this.checkMultiCompany, this.panelLookupModelInfo);
    }

    private toggleDetail() {
        const contains = this.contains(this.panelDetail);
        this.buttonToggleDetail.imageName = contains ? "pencil" : "noPencil";
        this.buttonToggleDetail.tooltip = contains ? "Edit the detailed properties of this model" : "Hide the detailed properties of this model";
        if (contains)
            this.remove(this.panelDetail);
        else
            this.add(this.panelDetail);
    }

    private createDatabaseKeyField() {
        this.textDatabaseKey = new Textbox({
            caption: "Database to use",
            tooltip: "Enter the database key (indirect reference to a database alias) to use for the database to use for this model.  If left blank, this will use the default dtaabase.",
            fillRow: true,
            marginRight: 8
        });
        this.textSortByField.addChangeListener(() => this.def.databaseKey = this.textDatabaseKey.text);
    }

    private createSortFields() {
        this.textSortByField = new Textbox({ caption: "Sort by", fillRow: true, marginRight: 8, rowBreak: false });
        this.textSortByField.addChangeListener(() => {
            if (this.textSortByField.text.trim().length === 0)
                this.def.orderBy = [];
            else
                this.def.orderBy = this.textSortByField.text.split(",");
        });
    }

    private createLookupModelFields() {
        this.textModelForDomain = new Textbox({
            fillRow: true,
            caption: "Model for domain",
            tooltip: "If this model is the default lookup model for a schema editor domain, enter that domain name here."
        });
        this.textModelForDomain.addChangeListener(() => {
            this.panelLookupModelPopulated.visible = this.textModelForDomain.text.length > 0;
            this._def.modelForDomain = this.textModelForDomain.text;
        });

        this.textDisplayFormat = new Textbox({
            fillRow: true,
            caption: "Display format",
            tooltip: "This defines the field or display format that will be used to to display a brief description for records returned by this model.\n\n" +
                "For example, assume this model is the lookup model for the commodity_id domain.  The model's table name would be 'commodity' and its display format might be 'descr' (without the quotes).  " +
                "That means that when there is a Textbox that contains a commodity field and the user selects one, the descr field of the commodity will be displayed in the Textbox.\n\n" +
                "When a display format containing multiple fields from the model is desired, field names can be wrapped in curly braces.\n\n" +
                "For example, assume this model is the lookup model for the customer_id domain.  The model's table name would be 'customer' and its display format might be '{id} - {name} {city}, {state_id}' (without the quotes).  " +
                "That means that when there is a Textbox that contains a customer field and the user selects one, the id, name, city, and state of the customer will be displayed in the Textbox."
        });
        this.textDisplayFormat.addChangeListener(() => this._def.displayField = this.textDisplayFormat.text);

        this.textLookupLayout = new Textbox({
            caption: "Lookup layout",
            tooltip: "If this model is used as a lookup model, enter the default listing layout that will be used to let users lookup and select records."
        });
        this.setupLayoutBox(this.textLookupLayout);
        this.textLookupLayout.addChangeListener(() => this._def.lookupLayout = this.textLookupLayout.text);

        this.textEntryLayout = new Textbox({
            caption: "Main entry layout",
            tooltip: "If this model is used as a lookup model, enter the default layout that is used to enter and edit its records."
        });
        this.setupLayoutBox(this.textEntryLayout);
        this.textEntryLayout.addChangeListener(() => this._def.entryLayout = this.textEntryLayout.text);

        this.textQuickInfoLayout = new Textbox({
            caption: "Quick info layout",
            tooltip: "If this model is used as a lookup model, enter the default layout that is used to display a brief summary of its records, usually when the user hovers over one of its records."
        });
        this.setupLayoutBox(this.textQuickInfoLayout);
        this.textQuickInfoLayout.addChangeListener(() => this._def.quickInfoLayout = this.textQuickInfoLayout.text);
    }

    private setupLayoutBox(textbox: Textbox) {
        textbox.setProps({ fillRow: true, buttonImageName: "magnifyingGlass" });
        textbox.addButtonClickListener(event => {
            const pnl = new PanelOpenLayout();
            ReflectiveDialogs.showDialog(pnl, { title: "Select Layout", height: 600, width: 500 }).then(() => {
                let path = "";
                pnl.tree?.selectedNode.path.forEach((node) => { path += node.caption + "/"; });
                path = path.substring(0, path.length - 1);
                textbox.text = path;
            });

        });
    }

    get def(): ModelDefinition {
        return this._def;
    }

    set def(value: ModelDefinition) {
        this._def = value;
        if (this.def?.auth?.length === 1)
            this.textUserTypes.text = this.def.auth[0];
        this.checkRequiresFieldList.checked = this.def.fieldListRequired === true;
        this.checkMultiCompany.checked = this.def.multiCompany !== false;
        if (this.def.orderBy?.length > 0)
            this.textSortByField.text = this.def.orderBy.join(",");
        this.textModelForDomain.text = this.def.modelForDomain;
        this.textDisplayFormat.text = this.def.displayField;
        this.textEntryLayout.text = this.def.entryLayout;
        this.textQuickInfoLayout.text = this.def.quickInfoLayout;
        this.textLookupLayout.text = this.def.lookupLayout;
        this.textDatabaseKey.text = this.def.databaseKey;
        this.panelLookupModelPopulated.visible = this.def.modelForDomain?.length > 0;
    }

    focus() {
        this.textUserTypes.focus();
    }
}
