import { Collection, DatePart, DateUtil, HorizontalAlignment, VerticalAlignment, getThemeColor } from "@mcleod/core";
import { Button, DomEvent, Label, Panel, PanelProps } from "../..";
import { Cursor } from "../../base/Cursor";
import { ListenerListDef } from "../../base/ListenerListDef";
import { ButtonVariant } from "../../components/button/ButtonVariant";
import { DateTimeChangePart, DateTimePickerChangeEvent, DateTimePickerChangeListener } from "./DateTimePickerChangeEvent";

const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const labelProps = {
    width: 36, height: 36, padding: 5, margin: 2, align: HorizontalAlignment.CENTER,
    fontSize: "small", allowSelection: false, borderRadius: "50%"
};
const _changeListenerDef: ListenerListDef = { listName: "_changeListeners" };

export interface DatePickerProps extends PanelProps {
    value: Date;
}

export class DatePicker extends Panel implements DatePickerProps {
    calendarContainer: Panel;
    heading: Panel;
    buttonPreviousMonth: Button;
    labelMonth: Label;
    buttonNextMonth: Button;
    panelCalendar: Panel;
    panelDays: Panel;
    displayMonth: Date;
    _value: Date;

    constructor(props?: Partial<DatePickerProps>) {
        super({ padding: 0, width: 700, ...props }, false);
        this.calendarContainer = new Panel({ padding: 0, fillRow: true, fillHeight: true, maxWidth: 500 });
        this.heading = new Panel({ verticalAlign: VerticalAlignment.CENTER, padding: 8, backgroundColor: "primary", color: "primary.reverse", fillRow: true });
        this.buttonPreviousMonth = new Button({ imageName: "chevron", imageRotation: 90, variant: ButtonVariant.round, rowBreak: false });
        this.buttonPreviousMonth.addClickListener(event => this.addMonth(-1));
        this.labelMonth = new Label({ fillRow: true, fontSize: "large", rowBreak: false, align: HorizontalAlignment.CENTER });
        this.buttonNextMonth = new Button({ imageName: "chevron", imageRotation: -90, variant: ButtonVariant.round, rowBreak: false });
        this.buttonNextMonth.addClickListener(event => this.addMonth(1));
        this.heading.add(this.buttonPreviousMonth);
        this.heading.add(this.labelMonth);
        this.heading.add(this.buttonNextMonth);
        this.panelCalendar = new Panel({ paddingRight: 16, paddingLeft: 16, paddingBottom: 16, fillRow: true });
        this.panelDays = new Panel({ padding: 0, fillRow: true })
        this.addDayHeadings();
        this.calendarContainer.add(this.heading);
        this.calendarContainer.add(this.panelCalendar);
        this.add(this.calendarContainer);
        this.panelCalendar.add(this.panelDays);
        let value = props?.value || new Date();
        if (value == null)
            value = new Date();
        this.displayMonth = new Date(value);
        this.value = value;
        this.displayMonth.setDate(1);
        if (props != null)
            delete props.value;
        this.setProps(props);
    }

    addDayHeadings() {
        for (const day of dayNames)
            this.panelCalendar.add(new Label({ caption: day, ...labelProps, align: HorizontalAlignment.CENTER, fillRow: true, rowBreak: day === dayNames[dayNames.length - 1], padding: 0, margin: 0 }));
    }

    get value(): Date {
        return this._value;
    }

    set value(date: Date) {
        this._value = date;
        this.displayCalendar();
    }

    addMonth(amount: number) {
        this.displayMonth = DateUtil.dateAdd(DatePart.MONTH, this.displayMonth, amount);
        this.displayCalendar();
    }

    displayCalendar() {
        this.labelMonth.caption = DateUtil.getMonthOfYear(this.displayMonth) + " " + this.displayMonth.getFullYear();
        this.addDateLabels();
    }

    addDateLabels() {
        this.panelDays.removeAll();
        const selDate = DateUtil.justDate(this.value);
        const thisMonth = this.displayMonth.getMonth();
        const lastDay = this.getLastDateToDisplay(this.displayMonth);
        let curr = this.getFirstDateToDisplay(this.displayMonth);
        while (curr <= lastDay) {
            const thisDate = DateUtil.justDate(new Date(curr));
            let colors;
            if (selDate != null && thisDate.getTime() === selDate.getTime())
                colors = { backgroundColor: "primary", color: "primary.reverse", hoverBackground: "primary.light", hoverColor: "primary.reverse" };
            else if (thisMonth === thisDate.getMonth())
                colors = { backgroundColor: "", color: "", hoverBackground: "subtle.light", hoverColor: "subtle.reverse" };
            else
                colors = { backgroundColor: "", color: "subtle.darker", hoverBackground: "subtle.light", hoverColor: "subtle.reverse" };
            const label = new Label({ ...labelProps, ...colors, cursor: Cursor.POINTER, caption: thisDate.getDate(), rowBreak: thisDate.getDay() === 6 });
            label.addMouseEnterListener(event => event.target.backgroundColor = colors.hoverBackground);
            label.addMouseLeaveListener(event => event.target.backgroundColor = colors.backgroundColor);
            label.addClickListener(event => this.select(thisDate, event));
            this.panelDays.add(label);
            curr = DateUtil.dateAdd(DatePart.DAY, curr, 1);
        }
    }

    select(date, domEvent: DomEvent) {
        const oldValue = this._value;
        this._value = date;
        this.addDateLabels();
        this.fireListeners(_changeListenerDef, new DateTimePickerChangeEvent(this, oldValue, date, domEvent, DateTimeChangePart.Date));
    }

    addChangeListener(value: DateTimePickerChangeListener): DatePicker {
        return <DatePicker>this.addEventListener(_changeListenerDef, value);
    }

    removeChangeListener(value: DateTimePickerChangeListener): DatePicker {
        return <DatePicker>this.removeEventListener(_changeListenerDef, value);
    }

    getFirstDateToDisplay(date: Date): Date {
        const result = new Date(date);
        result.setDate(1);
        result.setDate((result.getDay() * -1) + 1);
        return result;
    }

    getLastDateToDisplay(date: Date): Date {
        const thisMonth = date.getMonth();
        const days = DateUtil.daysInMonth(thisMonth, date.getFullYear());
        const result = new Date(date);
        result.setDate(days);
        result.setDate(days + (6 - result.getDay()));
        return result;
    }

    hoverColors(noHoverBackground, noHoverColor, hoverBackground, hoverColor) {
        return {
            backgroundColor: noHoverBackground,
            color: noHoverColor,
            onMouseEnter: (event) => {
                event.domEvent.target.style.backgroundColor = getThemeColor(hoverBackground);
                event.domEvent.target.style.color = getThemeColor(hoverColor);
            },
            onMouseLeave: (event) => {
                event.domEvent.target.style.backgroundColor = getThemeColor(noHoverBackground);
                event.domEvent.target.style.color = getThemeColor(noHoverColor);
            }
        }
    }

    override getListenerDefs(): Collection<ListenerListDef> {
        return { ...super.getListenerDefs(), "change": { ..._changeListenerDef } };
    }
}
