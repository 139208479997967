import { AutogenLayoutDemoScreenFlowSample } from "./autogen/AutogenLayoutDemoScreenFlowSample";
import { DemoScreenFlowSampleButtons } from "./DemoScreenFlowSampleButtons";

export class DemoScreenFlowSample extends AutogenLayoutDemoScreenFlowSample {
    override onLoad() {
        (this.layout1 as DemoScreenFlowSampleButtons).alterForInitialDisplay();
    }

    getDemoPath() {
        return ["Concepts", "Screen Flow Sample"];
    }
}
