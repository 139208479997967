import { Client } from "@stomp/stompjs";
import { getLogger } from "../Logger";
import { getAuthSettings, getUnauthSettings } from "../Settings";

const stompLog = getLogger("core.messaging.StompLog");

export class Messaging {
    public static getClient(): Client {
        const result = new Client({ connectHeaders: { login: 'msg', passcode: getAuthSettings().amqp } });
        result.brokerURL = getUnauthSettings().messaging_url;
        if (stompLog.isDebugEnabled)
            result.debug = msg => stompLog.debug(msg);
        return result;
    }
}
