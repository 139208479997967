/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelUserHomePage.ts                  *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "common/user-home-page";

export class AutogenModelUserHomePage<RowType extends ModelRow> extends Endpoint<RowType, RowUserHomePageFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowUserHomePage extends ModelRow<RowUserHomePageFields> {
    constructor() {
        super(AutogenUserSavedSearchEndpoint);
    }
}

export interface RowUserHomePageFields {
    allow_configure: boolean;
    definition: string;
    home_page: string;
    id: string;
    is_edi_licensed: boolean;
}
