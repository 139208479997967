import { Button, Checkbox, Label, Layout, Panel, Switch, Textbox, } from "@mcleod/components";

export abstract class AutogenLayoutDemoCollapse extends Layout {
    buttonCollapse: Button;
    buttonCollapseThenExpand: Button;
    buttonExpand: Button;
    checkTest: Checkbox;
    labelDescr: Label;
    panelCollapse: Panel;
    panelDemo: Panel;
    switchTest: Switch;
    textbox2: Textbox;
    v: Panel;
}
