/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelCustomerLookup.ts                *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/ar/customer-lookup";

export class AutogenModelCustomerLookup<RowType extends ModelRow> extends Endpoint<RowType, RowCustomerLookupFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowCustomerLookup extends ModelRow<RowCustomerLookupFields> {
    constructor() {
        super(AutogenUserSavedSearchEndpoint);
    }
}

export interface RowCustomerLookupFields {
    activity_count: number;
    activity_start_date: Date;
    add_empty_distance_occ: string;
    add_finance_charge: string;
    address1: string;
    address2: string;
    allow_inc_in_frt: string;
    allow_quick_dispatch: string;
    auto_rate: string;
    available_template: number;
    average_bill: number;
    average_bill_c: string;
    average_bill_d: Date;
    average_bill_n: number;
    average_bill_r: number;
    average_pay_days: number;
    balance: number;
    balance_c: string;
    balance_d: Date;
    balance_n: number;
    balance_r: number;
    benchmark_ctg_uid: number;
    benchmark_tar_uid: number;
    bill_due_days: number;
    bill_format_flag: string;
    bill_template: number;
    bill_transfer_when: string;
    billed_loads: number;
    bl_logo: string;
    bl_logo_name: string;
    blanket_buy_tariff_id: string;
    blanket_sell_tariff_id: string;
    bridge_id: string;
    brokerage_filter: string;
    business_hours: string;
    business_number: string;
    buy_tariff_id: string;
    category: string;
    cert_of_ins_date: Date;
    charge_detention: string;
    chassis_chargeby: string;
    chassis_freedays: number;
    chassis_rate: number;
    chassis_rate_c: string;
    chassis_rate_d: Date;
    chassis_rate_n: number;
    chassis_rate_r: number;
    city: string;
    city_id: number;
    coll_thresh_pd1: number;
    coll_thresh_pd1_c: string;
    coll_thresh_pd1_d: Date;
    coll_thresh_pd1_n: number;
    coll_thresh_pd1_r: number;
    coll_thresh_pd2: number;
    coll_thresh_pd2_c: string;
    coll_thresh_pd2_d: Date;
    coll_thresh_pd2_n: number;
    coll_thresh_pd2_r: number;
    coll_thresh_pd3: number;
    coll_thresh_pd3_c: string;
    coll_thresh_pd3_d: Date;
    coll_thresh_pd3_n: number;
    coll_thresh_pd3_r: number;
    coll_thresh_pd4: number;
    coll_thresh_pd4_c: string;
    coll_thresh_pd4_d: Date;
    coll_thresh_pd4_n: number;
    coll_thresh_pd4_r: number;
    collections_id: string;
    collections_ok: string;
    company_id: string;
    confidence_level: number;
    container_chargeby: string;
    container_freedays: number;
    container_rate: number;
    container_rate_c: string;
    container_rate_d: Date;
    container_rate_n: number;
    container_rate_r: number;
    contract_cbc: string;
    contract_ccc: string;
    contract_gbc: string;
    contract_gcc: string;
    contract_sbc: string;
    contract_scc: string;
    controlling_carrier_code: string;
    conversion_date: Date;
    cost_ctg_uid: number;
    cost_tar_uid: number;
    covered_template: number;
    cp_logo: string;
    cp_logo_name: string;
    credit_application: string;
    credit_check_date: Date;
    credit_limit: number;
    credit_limit_c: string;
    credit_limit_d: Date;
    credit_limit_n: number;
    credit_limit_r: number;
    credit_status: string;
    credit_warning_pct: number;
    ctg_uid: number;
    currency_type: string;
    cust_requestor_load_id: string;
    cust_stmt_template: number;
    d_and_b_code: string;
    d_and_b_date: Date;
    d_and_b_number: string;
    days_between_calls: number;
    deadhead_target: number;
    ded_last_fixed_cost_billdt: Date;
    dedicated_cycle: string;
    dedicated_revenue_code_id: string;
    dedicated_seg_alloc_code: string;
    dedicated_start_day: string;
    dedicated_template: number;
    def_rate_confirm_temp: number;
    delivered_template: number;
    departure_date_at: string;
    disable_automatic_tracking: string;
    display_eta: string;
    distance_method: string;
    distance_profile: string;
    distance_source: string;
    distance_type: string;
    dot_number: string;
    driver_load: string;
    empty_distance_charge_id: string;
    empty_distance_rate: number;
    enable_notifications: string;
    enforce_dep_dates: string;
    entered_date: Date;
    excise_taxable: string;
    expected_close: Date;
    extend_rates: string;
    factor_percent: number;
    factor_receivables: string;
    factoring_id: string;
    fak_profile_id: string;
    fourkites_customer_id: string;
    fuel_amount: number;
    fuel_base_price: number;
    fuel_credit: string;
    fuel_method: string;
    fuel_percent: number;
    fuel_price_region: string;
    fuel_surcharge_id: string;
    fuel_surchg_enddt: Date;
    fuel_surchg_strtdt: Date;
    fuel_variance: number;
    global_detention: string;
    google_place_id: string;
    gp_exclude: string;
    gp_exported: Date;
    grace_period: number;
    high_balance: number;
    high_balance_c: string;
    high_balance_d: Date;
    high_balance_n: number;
    high_balance_r: number;
    id: string;
    include_by_date: string;
    include_empty_distance: string;
    include_fuel_surcharge: string;
    include_split_point: string;
    is_active: string;
    is_consignee: string;
    is_distribution_center: string;
    is_drop_yard: string;
    is_geocoded: string;
    is_pallets_required: string;
    is_shipper: string;
    is_trailer_pool_required: string;
    jaguar_id: number;
    last_bill_date: Date;
    last_fin_charge_dt: Date;
    last_pay_date: Date;
    last_ship_date: Date;
    latitude: number;
    location_id: string;
    longitude: number;
    macropoint_requestor_id: string;
    main_phone: string;
    manual_exchange: string;
    mark_plan_id: string;
    max_pay_percent: number;
    misc_bill_template: number;
    monthly_order_goal: number;
    motor_carrier_id: string;
    name: string;
    next_call_date: Date;
    next_rec_call: Date;
    nxt_action_code_id: string;
    nxt_action_date: Date;
    operations_user: string;
    order_count: number;
    order_detail_at: string;
    order_start_date: Date;
    p44_profile_id: string;
    paid_loads: number;
    pay_days_date: Date;
    pay_days_orders: number;
    payee_id: string;
    pd0_alert: number;
    pd1_np_auto: string;
    pd1_sp_auto: string;
    pd1_sp_thresh: number;
    pd1_sp_thresh_c: string;
    pd1_sp_thresh_d: Date;
    pd1_sp_thresh_n: number;
    pd1_sp_thresh_r: number;
    pd2_np_auto: string;
    pd2_sp_auto: string;
    pd2_sp_thresh: number;
    pd2_sp_thresh_c: string;
    pd2_sp_thresh_d: Date;
    pd2_sp_thresh_n: number;
    pd2_sp_thresh_r: number;
    pd3_np_auto: string;
    pd3_sp_auto: string;
    pd3_sp_thresh: number;
    pd3_sp_thresh_c: string;
    pd3_sp_thresh_d: Date;
    pd3_sp_thresh_n: number;
    pd3_sp_thresh_r: number;
    pd4_np_auto: string;
    pd4_sp_auto: string;
    pd4_sp_thresh: number;
    pd4_sp_thresh_c: string;
    pd4_sp_thresh_d: Date;
    pd4_sp_thresh_n: number;
    pd4_sp_thresh_r: number;
    plc_uid: number;
    post_summary_dtl: string;
    potential: number;
    potential_c: string;
    potential_d: Date;
    potential_n: number;
    potential_r: number;
    prev_code: string;
    primary_agency: string;
    primary_commodity_id: string;
    primary_contact: string;
    primary_sort: string;
    primary_trailer_type: string;
    print_containers: string;
    progress_template: number;
    prospect_type_id: string;
    quebec_number: string;
    quote_days_to_expire: number;
    rate_override_nmfc_code: string;
    reassign_profile_id: string;
    rec_call_days: number;
    remarks: string;
    require_tracking: string;
    required_vendor: string;
    revenue: number;
    revenue_c: string;
    revenue_d: Date;
    revenue_n: number;
    revenue_r: number;
    revenue_start_date: Date;
    routing_guide_profile_id: string;
    sales_manager_id: string;
    sales_status_id: string;
    salesperson_id: string;
    search_city: string;
    search_state_id: string;
    search_zip_code: string;
    secondary_sort: string;
    sell_tariff_id: string;
    service_fail_cons: string;
    service_fail_drop: string;
    service_fail_pick: string;
    service_fail_ship: string;
    service_failures: string;
    service_failures_review: string;
    sic_code: string;
    start_date: Date;
    state_id: string;
    summary_billing: string;
    summary_template: number;
    tar_uid: number;
    target_profit: number;
    tenfour_customer_id: string;
    tracking_hours_prior: number;
    type_of: string;
    use_local_mile: string;
    vm_part_update: string;
    waterfall_auto_route_guides: string;
    waterfall_lead_time: number;
    waterfall_notify_email: string;
    waterfall_process: string;
    website_url: string;
    wf_auto_renbill: string;
    wf_autobill: string;
    wf_bwop: string;
    wf_queue: number;
    zip_code: string;
    zone_id: string;
}

