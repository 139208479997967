import { DataSource, HorizontalSpacer, Label, Layout, SaveButton, Textbox, } from "@mcleod/components";

export abstract class AutogenLayoutCancelStopSelection extends Layout {
    horizontalspacer2: HorizontalSpacer;
    labelSelectingAllStopsWillRollBackAllStopsAndSetTheMovementBackToCovered: Label;
    labelSelectingPartialStopsWillRollBackTheSelectedStopsAndKeepTheMovementInProgress: Label;
    layout1: Layout;
    savebutton1: SaveButton;
    sourceMovement: DataSource;
    textboxId: Textbox;
}
