/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelCallinScriptTemplate.ts          *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/general/callin_script_template";

export class AutogenModelCallinScriptTemplate<RowType extends ModelRow> extends Endpoint<RowType, RowCallinScriptTemplateFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowCallinScriptTemplate extends ModelRow<RowCallinScriptTemplateFields> {
    constructor(values?: Partial<RowCallinScriptTemplateFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowCallinScriptTemplateFields {
    brokerage_status: string;
    commodity_id: string;
    company_id: string;
    customer_id: string;
    descr: string;
    equipment_type_id: string;
    eta_to_next_stop: string;
    event_id: string;
    id: string;
    is_active: string;
    is_required: string;
    location_id: string;
    movement_status: string;
    order_mode: string;
    order_type_id: string;
    order_value: number;
    order_value_c: string;
    order_value_comparison: string;
    order_value_d: Date;
    order_value_n: number;
    order_value_r: number;
    revenue_code_id: string;
    stop_type: string;
    user_group_id: string;
    user_id: string;
}

