import { Panel, PanelProps, Table, TableRowDisplayEvent } from "@mcleod/components";
import { ModelRow, VerticalAlignment } from "@mcleod/core";

export class TableBaseProps extends Panel {
    private table: Table;

    constructor(props?: Partial<PanelProps>, extraColumnsDefs?: any[]) {
        super({ fillHeight: true, fillRow: true, padding: 0, margin: 0, ...props });
        this.addTable(extraColumnsDefs);
    }

    private addTable(extraColumnsDefs: any[]) {
        const columns = [
            { heading: { caption: "Property", fontBold: true }, cell: { field: "prop_name", paddingLeft: 10 } },
            { heading: { caption: "Base Value", fontBold: true }, cell: { field: "old_value_display" } },
            { heading: { caption: "Modified", fontBold: true }, cell: { field: "new_value_display" } },
        ];
        if (extraColumnsDefs != null) {
            columns.push(...extraColumnsDefs);
        }
        this.table = new Table({
            id: "tableProps",
            fillRow: true,
            fillHeight: true,
            padding: 0,
            headerVisible: false,
            rowAlign: VerticalAlignment.CENTER,
            rowSpacing: 0,
            columns: columns
        });
        this.add(this.table);
    }

    addRow(...data: ModelRow[]) {
        for (const row of data) {
            this.table.addRow(row, null, { display: true });
        }
    }

    addOnTableRowDisplayCallback(callback: (event: TableRowDisplayEvent) => void) {
        this.table.addRowDisplayListener(callback);
    }

    clear() {
        this.table.clearRows();
    }

    isEmpty(): boolean {
        return this.table.isEmpty();
    }
}
