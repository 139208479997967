import {
    ChangeEvent, DataSource, DataSourceExecutionEvent, DataSourceMode, DataSourceModeChangeEvent, Textbox
} from "@mcleod/components";
import { ModelDataChangeType } from "@mcleod/components/src/databinding/DataSource";
import { ModelRow, StringUtil } from "@mcleod/core";
import { makeTooltipCallbackFunction } from "../CustomerMakeQuickInfo";
import { OrderHandlingRequirementsSlideout } from "../OrderHandlingRequirementsSlideout";
import { AutogenLayoutQuoteEntryLTL } from "./autogen/AutogenLayoutQuoteEntryLTL";

export class QuoteEntryLTL extends AutogenLayoutQuoteEntryLTL {
    override onLoad() {
        this.mainDataSource.doBeforeDataSourcePost = async () => {
            if (this.mainDataSource.mode == DataSourceMode.ADD) {
                if (this.layoutFreightItems.mainDataSource.data.length === 1 && !this.layoutFreightItems.mainDataSource.validateSimple()) {
                    this.layoutFreightItems.tableFreightItems.removeRow(0);
                }
            }
            return true;
        }

        this.setupContactListeners();
        this.layoutQuoteStopTable.shipperPONumLayout = this.layoutShipperPONums;
    }

    /** This is an event handler for the afterModeChange event of sourceQuoteFreightGroupItem.  */
    sourceQuoteFreightGroupItemAfterModeChange(event: DataSourceModeChangeEvent) {
        if (event.newMode === DataSourceMode.ADD)
            this.layoutFreightItems.addFreightItemToTable();
    }

    imageAddHandlingReqOnClick() {
        OrderHandlingRequirementsSlideout.showSlideout({
            hdrDataSource: this.sourceQuoteHdrXFgp,
            hdrChanged: (row: ModelRow, type: ModelDataChangeType) => this.hdrChanged(row, type),
            onSave: (hasChanged: boolean) => {
                this.layoutHandlingReqs.populateHdrs(this.sourceQuoteHdrXFgp.data, true, hasChanged).then(() => {
                    this.sourceQuoteHdrXFgp.notifyHasChangedComponents(true);
                });
            },
            invokedFromEdi: false
        })
    }

    hdrChanged(hdr: ModelRow<any>, type: ModelDataChangeType) {
        if (type == ModelDataChangeType.ADD) {
            hdr.set("fgp_uid", this.mainDataSource.activeRow.get("fgp_uid"));
            hdr.set("order_id", this.mainDataSource.activeRow.get("id"));
            hdr._appending = true;
            this.sourceQuoteHdrXFgp.addRow(hdr, this.sourceQuoteHdrXFgp.data.length, false);
        }
        else if (type == ModelDataChangeType.DELETE) {
            this.sourceQuoteHdrXFgp.data.forEach((row, index) => {
                if (row?.data["hdr_uid"] == hdr?.data["hdr_uid"]) {
                    this.sourceQuoteHdrXFgp.deleteTableRow(index, true);
                    return;
                }
            });
        }
        else if (type == ModelDataChangeType.UPDATE) {
            this.sourceQuoteHdrXFgp.data.forEach((row, index) => {
                if (row?.data["hdr_uid"] == hdr?.data["hdr_uid"]) {
                    row.setValues({
                        "text_value": hdr.data["text_value"],
                        "integer_value": hdr.data["integer_value"],
                        "float_value": hdr.data["float_value"],
                        "time_value": hdr.data["time_value"],
                        "date_value": hdr.data["date_value"]
                    });
                }
            });
        }

    }

    setupContactListeners() {
        this.textboxCustomerName.onSelectItem = (textbox, selectedItem: ModelRow) => {
            if (!selectedItem.isNull("id")) this.activeRow.set("customer_id", selectedItem.get("id"));
            return undefined;
        }
        this.textboxCustomerName.addChangeListener((event: ChangeEvent) => this.customerOnChange(event));
        this.textboxCustomerName.addBeforeLookupModelSearchListener(event => this.textboxCustomerNameBeforeLookupModelSearch(event));
        this.textboxCustomerContactName.addBeforeLookupModelSearchListener(event => this.textboxCustomerContactNameBeforeLookupModelSearch(event));
    }

    customerOnChange(event: ChangeEvent) {
        this.textboxCustomerName.tooltipCallback = makeTooltipCallbackFunction(this.textboxCustomerName.getFirstLookupModelData()?.get("id"), this.textboxCustomerName);
        if (StringUtil.isEmptyString(event.newValue)) {
            this.activeRow.set({ customer_id: null, customer_name: null });
            this.clearCustomerContactInfo();
        }
    }

    textboxCustomerNameBeforeLookupModelSearch(event) {
        event.filter.is_customer = "Y";
    }

    /** This is an event handler for the beforeLookupModelSearch event of textboxCustomerContactName.  */
    textboxCustomerContactNameBeforeLookupModelSearch(event) {
        const contactName = event.target as Textbox;
        event.filter.parent_row_id = this.activeRow.get("customer_id") ?? null;
        event.filter.parent_row_type = "C";
        event.filter.is_active = "Y";

        if (contactName != null && contactName.onSelectItem == null) {
            contactName.onSelectItem = ((textbox, selection) => {
                if (selection != null) {
                    this.activeRow.set("customer_phone", (selection as ModelRow).get("phone"));
                    this.activeRow.set("customer_email", (selection as ModelRow).get("email"));
                }
                return undefined;
            });
        }
    }

    private clearCustomerContactInfo() {
        this.activeRow.set("customer_contact_name", null);
        this.activeRow.set("customer_phone", null);
        this.activeRow.set("customer_email", null);
        this.textboxCustomerContactName.clear();
    }

    /** This is an event handler for the afterExecution event of sourceQuoteHdrXFgp.  */
    sourceQuoteHdrXFgpAfterExecution(event: DataSourceExecutionEvent) {
        this.layoutHandlingReqs.populateHdrs(event.dataSource.data, true);
    }

    getFreightGroupItemDataSource(): DataSource {
        return this.sourceQuoteFreightGroupItem;
    }

    getStopDataSource(): DataSource {
        return this.sourceQuoteStop;
    }

    getHdrXFgpDataSource(): DataSource {
        return this.sourceQuoteHdrXFgp;
    }

    getBillingFreightGroupDataSource(): DataSource {
        return this.mainDataSource.childDataSources.find(ds => ds.id === "sourceQuoteBrltlBillingFreightGroup");
    }
}
