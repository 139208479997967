import { Button, DataSource, HorizontalSpacer, Label, Layout, Panel, } from "@mcleod/components";

export abstract class AutogenLayoutBrokeragePlanningProfileFilter extends Layout {
    buttonApply: Button;
    buttonCancel: Button;
    horizontalspacer1: HorizontalSpacer;
    labelAdvancedFilter: Label;
    layoutProfile: Layout;
    panel1: Panel;
    panel2: Panel;
    sourceBrokeragePlanningProfile: DataSource;
}
