import { Button, DataSource, HorizontalSpacer, Label, Layout, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutInitiateBrokerageTracking extends Layout {
    buttonCancel: Button;
    buttonInitiateLater: Button;
    buttonSave: Button;
    carrierDriver: Label;
    carrierEmail: Label;
    carrierEmailHeader: Label;
    carrierPhone: Label;
    carrierPhoneHeader: Label;
    horizontalspacer1: HorizontalSpacer;
    labelBusy: Label;
    labelCarrierConnectionMethod: Label;
    labelCarrierDriverHeader: Label;
    labelHeaderTitle: Label;
    labelMPTrackingNote: Label;
    labelTrackDurationExtension: Label;
    labelTrackIntervalMins: Label;
    labelTrackStartDate: Label;
    labelTrackingNote: Label;
    labelTrackingVendors: Label;
    panel1: Panel;
    panel2: Panel;
    panelBusy: Panel;
    panelCarrierDriverInfo: Panel;
    panelContent: Panel;
    panelDriverInfoContainer: Panel;
    panelHeader: Panel;
    panelProgress: Panel;
    panelVendorF: Panel;
    panelVendorL: Panel;
    panelVendorM: Panel;
    panelVendorP: Panel;
    panelVendorT: Panel;
    sourceBrokerageTracking: DataSource;
    sourceUsers: DataSource;
    textboxCapacityProviderID: Textbox;
    textboxCarrierEmail: Textbox;
    textboxCarrierPhone: Textbox;
    textboxCarrierTractor: Textbox;
    textboxComment: Textbox;
    textboxDriverComments: Textbox;
    textboxEmailAddress: Textbox;
    textboxEquipmentIdentifier: Textbox;
    textboxFourKitesComment: Textbox;
    textboxOverrideDrvrEmail: Textbox;
    textboxShipperEmail: Textbox;
    textboxShipperNotificationFrequency: Textbox;
    textboxShpmtIdentValue: Textbox;
    textboxTrackDurationExt: Textbox;
    textboxTrackIntervalMins: Textbox;
    textboxTrackStartDate: Textbox;
    textboxVendor: Textbox;
}
