import { ClickEvent, DataSourceAction, DataSourceExecutionEvent, DataSourceMode, Tab, TableRowCreationEvent } from "@mcleod/components";
import { ModelRow, ModelRowType, UrlUtil, getAuthSettings } from "@mcleod/core";
import { SalesCallListTable } from "./SalesCallListTable";
import { SalesCallListTableType } from "./SalesCallListTableType";
import { AutogenLayoutSalesCallList } from "./autogen/AutogenLayoutSalesCallList";

interface SalesCallListTab {
    type: SalesCallListTableType;
    layout?: SalesCallListTable;
    tab?: Tab;
}

export class SalesCallList extends AutogenLayoutSalesCallList {
    private urlParams: any = UrlUtil.getPropsFromUrl(window.location.href);

    private tabs: SalesCallListTab[] = [
        { type: SalesCallListTableType.ALL },
        { type: SalesCallListTableType.TODAY },
    ]

    override onLoad() {
        this.setupTables();
        this.executeDefaultSearch();
    }

    executeDefaultSearch() {
        const userId = getAuthSettings()?.user_settings.user_id
        const userName = getAuthSettings()?.user_settings?.user_name
        const lmData = new ModelRow("common/users", false, {
            id: userId,
            name: userName
        });
        lmData.type = ModelRowType.LOOKUP_MODEL_DATA;
        this.textboxAssignedTo.text = userName;
        this.mainDataSource.search({
            "user_id": userId
        }).then(() => this.setSecondaryFilters());
    }

    setupTables() {
        for (let i = 0; i < this.tabs.length; i++) {
            const table = this.tabsetMain.getTab(i).components[0];
            if (table instanceof SalesCallListTable) {
                table.configureTableForType(this.tabs[i].type);
                this.tabs[i].layout = table;
                this.tabs[i].tab = this.tabsetMain.getTab(i);
                table.salesCallList = this;
                table.tableSalesCalls.addRowCreateListener((event: TableRowCreationEvent) => event.getTable().busy = false);
            }
        }
    }

    private setSecondaryFilters() {
        if (this.urlParams?.["locationProspect"]) {
            this.layoutToday.findComponentById('btn' + SalesCallListTableType.LOCATION_PROSPECT.id).clicked();
        }
        else if (this.urlParams?.["customer"]) {
            this.layoutToday.findComponentById('btn' + SalesCallListTableType.CUSTOMER.id).clicked();
        }
    }

    searchbuttonSearchOnClick(event: ClickEvent) {
        this.mainDataSource.search({
            "user_id": this.textboxAssignedTo.getFirstLookupModelData().get("id")
        });
    }

    updateTabTitle(tab: SalesCallListTab) {
        const modelRows = this.sourceSalesCalls.data.filter(tab.type.filter);
        tab.tab.caption = tab.type.caption + " " + modelRows.length;
    }

    sourceSalesCallsBeforeExecution(event: DataSourceExecutionEvent) {
        if (DataSourceAction.SEARCH == event.getAction()) {
            this.tabs.forEach(tab => tab.layout.tableSalesCalls.busy = true);
        }
    }

    sourceSalesCallsAfterExecution(event: DataSourceExecutionEvent) {
        if (DataSourceAction.SEARCH == event.getAction()) {
            this.tabs.forEach(tab => {
                tab.layout.tableSalesCalls.busy = false
                tab.layout.updateFilterCounts();
                this.updateTabTitle(tab);
            });
            this.populateFilteredTables();
        }
    }

    populateFilteredTables(tableType?: SalesCallListTableType) {
        this.tabs.forEach((tab, index) => {
            if (tableType != null && tableType != tab.type)
                return;

            this.populateFilteredTable(tab, index);
        })
    }

    populateFilteredTable(tab: SalesCallListTab, index: number) {
        const layout = tab.layout;
        const modelRows = this.sourceSalesCalls.data.filter(layout.filter);
        layout.mainDataSource.setRowsAndMode(DataSourceMode.NONE, modelRows, null);
        if (index != this.tabsetMain.selectedIndex && layout.tableSalesCalls.rowCount > 0) {
            // the following will populate up to 5 rows when the table is hidden.
            // This avoids populating them when the user switches tab
            const rowsToPopulate = Math.min(5, layout.tableSalesCalls.rowCount)
            for (let i = 0; i < rowsToPopulate; i++)
                layout.tableSalesCalls.rows[i]?.populateDOMIfNeeded();
        }
    }

    /** This is an event handler for the onChange event of textboxAssignedTo.  */
    textboxAssignedToOnChange(event) {
        this.searchbuttonSearch.enabled = this.textboxAssignedTo.lookupModelData != null;
    }
}
