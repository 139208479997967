import { Button, ButtonVariant, ChangeEvent, Component, Container, DesignableObject, Dialog, McLeodTailorPropertyEvaluator, PropType, Snackbar } from "@mcleod/components";
import { ReflectiveDialogs } from "@mcleod/components/src/base/ReflectiveDialogs";
import { StringUtil, getLogger } from "@mcleod/core";
import { ClientType, getClientType } from "@mcleod/core/src/Settings";
import { AbstractUIDesigner } from "../AbstractUIDesigner";
import { DesignerTool } from "../DesignerTool";
import { DesignerTab } from "../actions/DesignerTab";
import { McLeodTailorAgreementManager } from "./McLeodTailorAgreementManager";
import { PanelOpenLayoutMenu } from "./PanelOpenLayoutMenu";
import { PanelOpenPortalLayoutMenu } from "./PanelOpenPortalLayoutMenu";
import { PanelApplyBaseProps } from "./PanelApplyBaseProps";

const log = getLogger("common.designer.McLeodTailor");

export class McLeodTailor extends AbstractUIDesigner {
    private buttonBaseProps: Button;
    private panelApplyBaseProps: PanelApplyBaseProps;
    private savedSplitterPosition: string|number;
    constructor(props?) {
        super({ ...props, title: "McLeod Tailor", layoutEndpointPath: "custom-layout" });
        this.addBasePropsButton();
        this.syncRevertPropsButton();
    }

    override async onLoad() {
        if (getClientType() === ClientType.portal)
            this.title = "Web Portals - McLeod Tailor";
        await new McLeodTailorAgreementManager().handleAgreement();
    }

    addBasePropsButton() {
        this.buttonBaseProps = new Button({
            caption: "i", visible: true, padding: 0, marginRight: 50, marginTop: 5,
            color: "primary.reverse", width: 17, height: 17,
            variant: ButtonVariant.round, borderWidth: 2, borderColor: "primary.reverse", borderRadius: 50,
            style: { position: "absolute", right: "0px", top: "0px", fontFamily: "Georgia, serif" },
            onClick: () => this.showTableApplyProps()
        });
        this.panelPropsHeader._element.appendChild(this.buttonBaseProps._element);
    }

    get localStorageKeys() { return { openTabs: "custom.designer.ui.open", selectedTab: "custom.designer.ui.selected" } }

    get designerTools(): DesignerTool[] {
        return [
            new DesignerTool(this, "Textbox"),
            new DesignerTool(this, "Label"),
            new DesignerTool(this, "Checkbox"),
            new DesignerTool(this, "Switch"),
            new DesignerTool(this, "Button"),
            new DesignerTool(this, "NumberEditor"),
            new DesignerTool(this, "Panel"),
            new DesignerTool(this, "HorizontalSpacer"),
            new DesignerTool(this, "CityState"),
            new DesignerTool(this, "Location", null, "CityState"),
            new DesignerTool(this, "Layout", { isNested: true }),
            new DesignerTool(this, "TailorExtension", null, "button", "tailorextensionbutton")
        ];
    }

    get tabsetTools(): Button[] {
        return [
            this.buttonRun,
            this.buttonOpen,
            this.buttonSave,
            this.buttonSaveNewVersion,
            this.buttonManageVersions,
            this.buttonUndo,
            this.buttonRedo
        ]
    }

    override showOpen() {
        this.updateToolbarButtonVisibility(this.getActiveTab()); //this call will remove the buttons if there isn't an active tab
        if (getClientType() === ClientType.portal) {
            this.showOpenPortalLayout();
        } else {
            this.showOpenPBWebLayout();
        }
    }

    private showOpenPBWebLayout() {
        const pnl = new PanelOpenLayoutMenu();
        ReflectiveDialogs.showDialog(pnl, { title: "Open Layout", height: 600, width: 500 }).then((dialog: Dialog) => {
            const path = pnl.tree?.selectedNode?.layoutPath;
            if (!dialog.wasCancelled && path != null)
                this.openTab(pnl.tree?.selectedNode?.layoutPath, false, true);
        });
    }

    private showOpenPortalLayout() {
        const pnl = new PanelOpenPortalLayoutMenu();
        ReflectiveDialogs.showDialog(pnl, { title: "Open Layout", height: 600, width: 500 }).then((dialog: Dialog) => {
            if (dialog.wasCancelled === true || pnl.tree?.selectedNode?.path == null)
                return;
            let path = "";
            const selectedNode = pnl.tree.selectedNode;
            if (selectedNode.hasChildren() === true) //user tried to select a directory entry in the tree
                return; //returning here is better than an error, but is still weak sauce.  ideally the OK button in the dialog would not be available in this situation.
            selectedNode.path.forEach((node) => {
                path += (node.data.unmodified_name == null ? node.caption : node.data.unmodified_name) + "/";
            });
            const baseVersion: boolean = selectedNode.data.base_version;
            if (path.startsWith("mcleod-api-portal/"))
                path = path.substring(path.indexOf("/") + 1);
            path = path.substring(0, path.length - 1);
            this.openTab(path, baseVersion, true);
        });
    }

    openTab(path: string, requireBaseVersion: boolean, selectTab: boolean, customLayoutId?: string): DesignerTab | undefined {
        if (path == null) {
            this.showOpen();
            return undefined;
        }
        return super.openTab(path, requireBaseVersion, selectTab, customLayoutId);
    }

    override showSave(createBaseTS: boolean = false, baseTsOnly: boolean = false) {
        const activeTab = this.getActiveTab();
        if (activeTab != null)
            return super.saveCustomLayout(activeTab);
    }

    override async saveActiveTab(createBaseTS: boolean = false, baseTSOnly: boolean = false, comment?: string) {
        const tab = this.getActiveTab();
        if (tab != null) {
            if (tab.baseVersion === true)
                tab.firstSaveOfCustomLayout = true;
            tab.baseVersion = false;
            super.saveActiveTab(false, false, comment);
        }
    }

    override doAfterActiveTabSave(tab: DesignerTab, baseTSOnly: boolean, response: any) {
        try {
            if (tab.firstSaveOfCustomLayout === true) {
                //remove the baseVersion from the last open tabs
                const tabDescriptor = tab.getDescriptor();
                tabDescriptor.baseVersion = true;
                this.deleteTabFromLastOpen(tabDescriptor);
            }
            super.doAfterActiveTabSave(tab, baseTSOnly, response);
        }
        finally {
            tab.firstSaveOfCustomLayout = undefined;
        }
    }

    override doOnActiveTabSaveError(tab: DesignerTab, error: any) {
        try {
            //if initial save of a custom layout failed, tab needs to indicate it's still the base version
            if (tab.firstSaveOfCustomLayout === true)
                tab.baseVersion = true;
        }
        finally {
            tab.firstSaveOfCustomLayout = undefined;
        }
        super.doOnActiveTabSaveError(tab, error);
    }

    override addNewTab() {
        //calling openTab() below would actually ending up calling showOpen().
        //But we don't want to do this when the last tab is closed (which may happen from the manage custom versions slideout).
        //So do nothing here.
        //this.openTab(null, false, false);
    }

    override filterProps(props: any, selectedComponent: Component) {
        for (const key of Object.keys(props)) {
            log.debug("Evaluating property for McLeod Tailor visibility: %o for component %o", props[key], selectedComponent);
            if (McLeodTailorPropertyEvaluator.isVisible(props[key], selectedComponent) !== true) {
                log.debug("Removing property from McLeod Tailor: %o", props[key]);
                delete props[key];
            }
        }
    }

    override disablePropertyEditors(prop: any, editorComponents: Component[], selectedComponent: Component): void {
        log.debug("Evaluating property for McLeod Tailor editability: %o for component %o", prop, selectedComponent);
        if (McLeodTailorPropertyEvaluator.isEditable(prop, selectedComponent) !== true) {
            log.debug("Disabling edit of property within McLeod Tailor: %o", prop);
            for (const component of editorComponents) {
                component.enabled = false;
            }
        }
    }

    override deleteComponents(components: DesignableObject[]) {
        if (this.selectedComponents == null)
            return;
        const compsToDelete = [];
        for (const component of this.selectedComponents) {
            if (this.canDelete(component)) {
                this.modified();
                compsToDelete.push(component);
            } else {
                Snackbar.showWarningSnackbar("Only custom components can be deleted.");
                return;
            }
        }
        super.deleteComponents(compsToDelete);
    }

    canDelete(comp: DesignableObject) {
        if (comp instanceof Component && (comp.isCustom || !comp.deserialized)) {
            if (comp instanceof Container) {
                for (const child of comp.components) {
                    if (!this.canDelete(child))
                        return false;
                }
            }
            return true;
        }
        return false;
    }

    notifyComponentAdded(component: any, container: Container) {
        super.notifyComponentAdded(component, container);
        component.isCustom = true;
    }

    override selectComponent(component: DesignableObject, add: boolean = false) {
        super.selectComponent(component, add);
        this.syncRevertPropsButton();
        this.panelApplyBaseProps?.close()
    }

    syncRevertPropsButton() {
        if (this.buttonBaseProps != null) {
            const modified = this.selectedLayoutComponents?.length == 1 && this.firstSelectedLayoutComponent?.baseVersionProps != null;
            this.buttonBaseProps.visible = modified;
        }
    }

    showTableApplyProps() {
        if (!this.panelProps.contains(this.panelApplyBaseProps)) {
            this.savedSplitterPosition = this.splitterProps.position;
            this.panelApplyBaseProps = new PanelApplyBaseProps(this);
            this.panelApplyBaseProps.onClose = () => this.hidePanelApplyBaseProps();
            this.panelProps.replace(this.tableProps, this.panelApplyBaseProps);
            this.splitterProps.position = "70%";
        }
        else
            this.hidePanelApplyBaseProps();
    }

    private hidePanelApplyBaseProps() {
        this.panelProps.replace(this.panelApplyBaseProps, this.tableProps);
        this.panelApplyBaseProps = null;
        this.splitterProps.position = this.savedSplitterPosition;
    }

    override doBeforePropChanged(component: Component, propName: string, propsSeen: string[] = []) {
        log.debug("Invoked doBeforePropChanged for property: %o", propName);
        if (propsSeen.includes(propName)) {
            log.debug("Property %o already seen in doBeforePropChanged", propName);
            return;
        }
        propsSeen.push(propName);
        if (component.isCustom || "id" == propName) return;
        const affectsProps: string[] = component.getPropertyDefinitions()[propName]?.affectsProps;
        const baseProps = component.baseVersionProps ?? {};
        const value = this.cleanPropValue(component, propName, component[propName]);
        if (!(propName in baseProps))
            baseProps[propName] = value;
        component.baseVersionProps = baseProps;
        this.syncBasePropsComponents(component, propName);
        log.debug("Property %o affects other properties: %o", propName, affectsProps);
        affectsProps?.forEach(affect => this.doBeforePropChanged(component, affect, propsSeen));
    }

    syncPropChanged(component: Component, propName: string, oldValue: any, newValue: any, redisplayProp?: boolean) {
        this.setBaseVersionProp(component, propName, oldValue, newValue);
        this.syncBasePropsComponents(component, propName);
    }

    setBaseVersionProp(component: Component, propName: string, oldValue: any, newValue: any) {
        if (component.isCustom || "id" == propName) return;
        const baseProps = component.baseVersionProps ?? {};
        oldValue = this.cleanPropValue(component, propName, oldValue);
        newValue = this.cleanPropValue(component, propName, newValue);
        if (!(propName in baseProps))
            baseProps[propName] = oldValue;
        else if (baseProps[propName] == newValue)
            delete baseProps[propName];
        component.baseVersionProps = baseProps;
    }

    syncBasePropsComponents(component: Component, propName: string) {
        if (component === this.firstSelected) {
            this.tableProps.syncRowCaptionStyle(propName);
            this.syncRevertPropsButton();
        }
    }

    checkForPropChange(event: ChangeEvent, data: any) {
        const propName = data.prop_name;
        const newValue = this.cleanPropValue(this.firstSelected, propName, event.newValue);
        if ((newValue == data.value || newValue == this.firstSelected?.baseVersionProps?.[propName])) {
            for (const comp of this.selectedComponents)
                this.doAfterPropChanged(comp, propName, event.oldValue, event.newValue, true);
        }
    }

    cleanPropValue(comp: DesignableObject, propName: string, value: any): any {
        const prop = comp.getPropertyDefinitions()[propName];
        if (prop.type === PropType.bool)
            value ??= false;
        else if (value == null || StringUtil.isEmptyString(value))
            return null;
        return value;
    }

    canModifyProp(propName: string, component: Component): boolean {
        const prop = component.getPropertyDefinitions()[propName];
        return McLeodTailorPropertyEvaluator.isVisible(prop, component)
            && McLeodTailorPropertyEvaluator.isEditable(prop, component);
    }
}
