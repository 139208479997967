import { mapStackTrace as extMap } from "sourcemapped-stacktrace";
import { StringUtil } from "./StringUtil";

interface StackElement {
    text: string;
    file: string;
    line: number;
    col: number;
}

export class StackUtil {
    public static getStackTrace(): string {
        return StringUtil.stringAfter(StringUtil.stringAfter(new Error().stack, "\n"), "\n"); // Drop the "Error" line and the stack frame for this function call
    }

    public static mapStackTrace(stack: string, callback: (lines: StackElement[]) => void) {
        const stackLines = stack.split("\n");
        extMap(stack, (mappedLines) => {
            const elements = [];
            let offset = 0;
            try {
                while (offset < stackLines.length && !stackLines[offset].trim().startsWith("at "))
                    elements.push({ text: stackLines[offset++] });
                for (let i = offset; i < stackLines.length; i++) {
                    const line = stackLines[i];
                    const element = {} as StackElement;
                    element.text = StringUtil.stringBefore(line, " (");
                    let mappedLine = mappedLines[i - offset];
                    mappedLine = StringUtil.stringAfter(mappedLine, "main/");
                    mappedLine = StringUtil.stringAfter(mappedLine, "webpack://");
                    if (mappedLine.startsWith("@mcleod"))
                        mappedLine = StringUtil.stringAfter(mappedLine, "/");
                    element.file = StringUtil.stringBefore(mappedLine, ":");
                    const colLine = StringUtil.stringAfter(mappedLine, ":");
                    element.line = parseInt(StringUtil.stringBefore(colLine, ":"));
                    element.col = parseInt(StringUtil.stringAfter(colLine, ":"));
                    elements.push(element);
                }
                callback(elements);
            }
            catch (e) {
                console.error({ e });
            }
        }, { sync: true });
    }

}
