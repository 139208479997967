import { ClickEvent, Panel, Snackbar, Toast } from "@mcleod/components";
import { Api, StringUtil } from "@mcleod/core";
import { AutogenLayoutSendLoadTender } from "./autogen/AutogenLayoutSendLoadTender";

export class SendLoadTender extends AutogenLayoutSendLoadTender {
    private _parentPanel: Panel;

    public get parentPanel(): Panel {
        return this._parentPanel;
    }
    public set parentPanel(value: Panel) {
        this._parentPanel = value;
    }

    public doAfterLoadTenderSent: () => void;
    //layout: import("C:/Users/ToddF/git/ij/loadmaster/mcleod-ui/mcleod-ui-common/components/src/index").Container;

    buttonSendOnClick(event: ClickEvent) {
        if (window.location.pathname == "/lme/dispatch/Movement" ||
            window.location.pathname == "/lme/dispatch/Orders") {
            this.buttonSend.busy = true;
            const moveId = this.mainDataSource.activeRow.get("moveid_out");
            const isAuto = this.mainDataSource.activeRow.get("is_auto_out");
            const carrierId = this.labelCarrierId.caption;
            const purpose = this.labelPurpose.caption;
            const mrb = this.textboxMustRespondBy.text;
            const contacts = this.textboxContact.text as unknown;
            const addlContacts = this.textboxAddContact.text;

            let allContacts: Array<string>;
            let cString = "";

            if (isAuto == "false") {
                if (!StringUtil.isEmptyString(contacts)) {
                    allContacts = contacts as Array<string>;
                }

                for (let i = 0; i < allContacts.length; i++) {
                    if (i > 0) cString += ",";
                    cString += allContacts[i];
                }

                if (!StringUtil.isEmptyString(addlContacts)) {
                    const addContactList: string[] = addlContacts.split(",");

                    addContactList.forEach((item) => {
                        if (!StringUtil.isEmptyString(cString)) cString += ",";
                        cString += item as string;
                    });
                }

                Api.post("lme/datafusion/send-tender", {
                    carrier: carrierId, move_id: moveId, respond_by: mrb, purpose: purpose, contacts: cString
                }).then(response => {
                    this.buttonSend.busy = false;
                    this.slideOut();
                    if (response.data[0]?.tendersent) {
                        Toast.showSuccessToast("Successfully sent " + purpose + " tender for movement " + moveId + " in batch " + response.data[0].batchid);
                    }
                    else {
                        Snackbar.showWarningSnackbar("Unable to send " + purpose + " tender for movement " + moveId + ".  " + response.data[0].text);
                    }
                }).catch(error => {
                    Snackbar.showWarningSnackbar("Unable to send " + purpose + " tender for movement " + moveId + ".  " + error);
                });
            }
        }
    }
}
