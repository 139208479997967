import { Button, ButtonVariant, ClickEvent, Component, DataSource, DataSourceAction, DataSourceExecutionEvent, DataSourceMode, Event, Label, Layout, Panel, SlideoutDecorator, Snackbar, Textbox } from "@mcleod/components";
import { Api, ArrayUtil, HorizontalAlignment, McLeodClassicIntegration, ModelRow, Navigation, StringUtil, getAuthSettings } from "@mcleod/core";
import { TableComment } from "../../general/src/TableComment";
import { UsersResponsibilitySlideoutMF } from "../../general/src/UsersResponsibilitySlideoutMF";
import { AutogenLayoutTableContact } from "../../general/src/autogen/AutogenLayoutTableContact";
import { CallRecordTable } from "./CallRecordTable";
import { AutogenLayoutLocation } from "./autogen/AutogenLayoutLocation";

const viewLocationURL = "lme/dispatch/Location?mode=update&key=";
export class Location extends AutogenLayoutLocation {
    resettingGoogleSearch: boolean = false;
    buttonOpenClassic: any;

    override onLoad() {
        if (this["mode"] == "list" && this["key"] != null && this.mainDataSource != null)
            this.mainDataSource.search({ id: this["key"] })
        else {
            this.textboxGooglePlacesSearch.visible = false;
            this.textboxGooglePlacesSearch.lookupModelInputDelay = getAuthSettings().dispatch_control[0].google_places_entry_delay;
            this.sourceLocation.addAfterModeChangeListener(() => {
                this.textboxGooglePlacesSearch.visible =
                    (this.sourceLocation.mode == DataSourceMode.ADD &&
                        getAuthSettings().dispatch_control[0].enable_google_places == "Y" &&
                        getAuthSettings().dispatch_control[0].enable_location_add_google == "Y");
                if (this.sourceLocation.mode == DataSourceMode.ADD) {
                    this.getDriverLoadUnloadDescr(this.textboxDriverLoadId).then(() => {
                        if (this.mainDataSource.activeRow?.get("driver_load_id") == this.mainDataSource.activeRow?.get("driver_unload_id")) {
                            this.mainDataSource.data[0].set("_lookup_driver_unload_id", this.mainDataSource.activeRow?.get("_lookup_driver_load_id"));
                            this.textboxDriverUnloadId.displayData(this.mainDataSource.data[0], null, 0);
                        }
                        else
                            this.getDriverLoadUnloadDescr(this.textboxDriverUnloadId);
                    });
                }
            });
        }
        (this.layoutTableComment as TableComment).displayCopyOrderFields = true;
        this.sourceLocation.afterGetDataboundValues = (row: ModelRow, dataSource: DataSource) => this.sourceLocationAfterGetDataboundValues(row, dataSource);

        this.mapLocation.addPinPlotListener(() => {
            const row = this.sourceLocation.activeRow;
            if (row.get("google_place_id") != null && row.get("latitude") != null && row.get("longitude") != null) {
                this.mapLocation.fitPins();
                this.mapLocation.setZoom(14);
            }
        });
        this.layoutCallRecord.locationId = null;
    }

    textboxGooglePlacesSearchOnChange(event) {
        if (this.resettingGoogleSearch) {
            this.resettingGoogleSearch = false;
            return;
        }
        const data = this.textboxGooglePlacesSearch.getFirstLookupModelData();
        if (data != null) {
            if (data.get("location_id") != null) {
                this.createGoogleSearchMessagePanel(data.get("location_id"));
                this.resettingGoogleSearch = true;
                this.textboxGooglePlacesSearch.text = event.oldValue;
            }
            else {
                if (data.get("type") != "locality") {
                    this.textName.text = data.get("name");
                    this.textAddress1.text = data.get("address1");
                }
                const row = this.sourceLocation.activeRow;
                row.set("google_place_id", data.get("google_place_id"));
                row.set("latitude", data.get("latitude"));
                row.set("longitude", data.get("longitude"));
                this.sourceLocation.displayDataInBoundComponent(this.mapLocation);
                if (this.mapLocation.map != null) {
                    this.mapLocation.fitPins();
                    this.mapLocation.setZoom(14);
                }
                if (!StringUtil.isEmptyString(data.get("city_id"))) {
                    row.set("city_name", data.get("city_name"));
                    row.set("state", data.get("state"));
                    row.set("zip_code", data.get("zip_code"));
                    row.set("city_id", data.get("city_id"));
                    this.textCity.displayData(this.sourceLocation.activeRow, null, 0);
                }
                else {
                    Snackbar.showWarningSnackbar("No city found for this location. Please enter the city manually.", { targetPanel: this });
                }
                const googlePlaceId = row.get("google_place_id");
                if (googlePlaceId != null) {
                    Api.search("dispatch/google-places-detail", { "id": googlePlaceId }).then(response => {
                        for (const data of response.data) {
                            this.textWebsiteURL.text = data.website;
                            this.addPhoneContact(data.phone_number)
                        }
                    });
                }
                this.textboxGooglePlacesSearch.visible = false;
            }
        }
    }

    private addPhoneContact(phoneNumber: any) {
        if (phoneNumber != null) {
            if (this.sourceContacts.mode !== DataSourceMode.ADD) {
                this.sourceContacts.mode = DataSourceMode.ADD;
            }
            this.sourceContacts.createBlankRow().then(contactRow => {
                contactRow.set("name", "Main");
                contactRow.set("phone", phoneNumber);
                (this.layoutContactTable as AutogenLayoutTableContact).tableContacts.addRow(contactRow, {}, { display: true, addToData: true });
            })
        }
    }

    private createGoogleSearchMessagePanel(locationCode: string) {
        const panel: Panel = new Panel();
        const messageLabelProps = {
            focusable: false,
            rowBreak: true,
            width: "100%",
            caption: "This location already exists as location code " + locationCode + ".  Would you like to view the location details or continue adding a location?"
        };
        const messageLabel = new Label(messageLabelProps);
        const viewButtonProps = {
            focusable: false,
            rowBreak: false,
            width: "45%",
            height: 40,
            caption: "View location details"
        };
        const continueAddButtonProps = {
            focusable: false,
            caption: "Continue to add new",
            width: "45%",
            height: 40,
            backgroundColor: "primary",
            color: "white"
        };
        const continueAddButton: Button = new Button(continueAddButtonProps);
        continueAddButton.addClickListener(() => {
            panel.hideTooltip();
        });
        const viewButton: Button = new Button(viewButtonProps);
        viewButton.addClickListener(() => {
            const windowSize = {
                width: 1400,
                height: 700,
            };
            const loc = {
                left: ((window.screen as any).availLeft + (window.screen.availWidth / 2)) - (windowSize.width / 2),
                top: ((window.screen as any).availTop + (window.screen.availHeight / 2)) - (windowSize.height / 2)
            };
            Navigation.navigateTo("lme/dispatch/Location?mode=update&key=" + locationCode,
                { left: loc.left, top: loc.top, height: windowSize.height, width: windowSize.width, newTab: true, windowDecorators: false });
        });
        panel.add(messageLabel);
        panel.add(viewButton);
        panel.add(continueAddButton);
        this.textboxGooglePlacesSearch.showTooltip(panel, { shaking: true });
    }

    displayLocationUsersSlideout() {
        const urs = new UsersResponsibilitySlideoutMF("lme/dispatch/LocationUsers", this.getLocationUsersSlideoutTitle(), this.getLocationUsersIdFilter(),
            this.getLocationUsersAgencyField(), this.sourceLocation, this.sourceAgencyLink);
        urs.show();
    }

    private getLocationUsersSlideoutTitle(): string {
        const name = this.sourceLocation.activeRow?.get("name");
        return "Location Users" + ((StringUtil.isEmptyString(name) === false) ? (" - " + name) : "");
    }

    private getLocationUsersIdFilter(): any {
        const id = this.sourceLocation.activeRow?.get("id");
        if (id != null)
            return { id: id };
        return null;
    }

    private getLocationUsersAgencyField(): string {
        return "primary_agency";
    }

    /** This is an event handler for the beforeExecution event of sourceLocation.  It's gross. */
    sourceLocationAfterGetDataboundValues(row: ModelRow, dataSource: DataSource) {
        if (dataSource.mode === DataSourceMode.SEARCH) {
            //have to set primary_agency in search mode because the field isn't bound
            const primaryAgencyField = this.getLocationUsersAgencyField();
            row.set(primaryAgencyField, dataSource.searchRow.get(primaryAgencyField));

            //have to add agency_link search data to the filter manually, since there are no bound components for those records
            if (ArrayUtil.isEmptyArray(this.sourceAgencyLink.data))
                return;
            row.addLinkedModel({ model: this.sourceAgencyLink.url, rows: [this.sourceAgencyLink.data[0]] });
        }
    }

    async getDriverLoadUnloadDescr(component: Textbox) {
        await Api.search("lme/dispatch/load-unload", { id: this.sourceLocation.activeRow?.get(component.field) }).then(response => {
            this.mainDataSource.data[0].set("_lookup_" + component.field, response?.data[0]);
            component.displayData(this.mainDataSource.data[0], null, 0);
        });
    }

    textboxAssignedToBeforeLookupModelSearch(event) {
        event.filter.web_user_type = 'U';
    }

    buttonOpenClassicOnClick(event: ClickEvent) {
        McLeodClassicIntegration.openClassicScreen("com.tms.client.loadmaster.dsp.EntryLocation", this.mainDataSource.activeRow.get("id"));
    }

    sourceLocationBeforeModeChange(event) {
        this.buttonOpenClassic.visible = event.newMode === DataSourceMode.UPDATE;
    }

    buttonAllCallsOnClick(event: ClickEvent) {
        const id = this.activeRow?.get("id");
        if (!StringUtil.isEmptyString(id)) {
            const layout = Layout.getLayout("lme/dispatch/CallRecordTable") as CallRecordTable;
            new SlideoutDecorator({
                layout: layout,
                width: window.innerWidth * .75,
                fillVerticalSpace: true,
                title: `Location Sales Calls - ${this.activeRow.get("name")}`,
                layoutLoadListeners: (event: Event) => layout.tableCallRecords.headerVisible = true,
                doAfterSlideIn: (decorator: SlideoutDecorator) => layout.mainDataSource.search({ "parent_row_type": "L", "parent_row_id": id })
            })
        }
    }

    /** This is an event handler for the afterExecution event of sourceLocation.  */
    sourceLocationAfterExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() == DataSourceAction.SEARCH) {
            (this.layoutCallRecord as CallRecordTable).locationId = this.activeRow?.get("id");
        }
    }

    sourceLocationBeforeExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() === DataSourceAction.ADD) {
            this.addBeforePostListeners();
        }
    }

    addBeforePostListeners() {
        if (this.mainDataSource.activeRow != null) {
            const row = this.mainDataSource.activeRow;
            if (row["autogenerateRowPostListener"] == null) {
                const autogenerateRowPostListener = async postEvent => {
                    if (this.mainDataSource.activeRow?.isNull("id")) {
                        await this.checkAutogen()
                            .then((response) => {
                                if (response.data[0]?.is_duplicate) {
                                    postEvent.preventDefault("Generated code [" + response.data[0]?.code + "] is a duplicate of an existing code", () => {
                                        (async function (tempTextbox: Textbox, duplicatePanel: Panel) {
                                            await Snackbar.showWarningSnackbar(duplicatePanel, { persist: true, warningTitle: "Duplicate Code" });
                                            tempTextbox.focus();
                                        })(this.textId, this.getDuplicateLocationSnackbarPanel(response.data[0]?.code, response.data[0]?.description));
                                    });

                                    return Promise.resolve(false);
                                } else {
                                    return Promise.resolve(true);
                                }
                            })
                    }
                }

                row["autogenerateRowPostListener"] = autogenerateRowPostListener;
                row.addBeforePostListener(autogenerateRowPostListener);
            }
        }
    }

    async checkAutogen(): Promise<any> {
        return await Api.post("lme/dispatch/check-manual-autogen",
            {
                "table_name": "location",
                "table_row": this.mainDataSource?.activeRow?.data
            })
    }

    private duplicateLocationLinkOnClick(locationCode: string) {
        Location.navigateTo(locationCode, true);
    }

    public static navigateTo(id: string, newTab = true): void {
        Navigation.navigateTo(viewLocationURL + id, { newTab: newTab });
    }

    private getDuplicateLocationSnackbarPanel(locationId: string, description: string): Panel {
        const labelProps = { color: "white", fontSize: "large", rowBreak: false, paddingLeft: 1, paddingRight: 1, marginLeft: 0, marginRight: 0 };

        let components: Component[] = [];
        if (!StringUtil.isEmptyString(locationId)) {
            components = [
                new Label({ ...labelProps, caption: "The generated code [" }),
                new Label({ ...labelProps, caption: locationId + " - " + description + " " }),
                new Button({ imageName: "detail", imageWidth: 20, imageHeight: 20, margin: 0, tooltip: "Click to go to Location screen", color: "subtle.light", variant: ButtonVariant.round, onClick: () => this.duplicateLocationLinkOnClick(locationId), rowBreak: false }),
                new Label({ ...labelProps, rowBreak: false, caption: "] " }),
                new Label({ ...labelProps, caption: "is a duplicate of an existing code.  Please enter a new one." })
            ];
        }
        return new Panel({ wrap: true, align: HorizontalAlignment.LEFT, fillRow: true, components: components, fillHeight: true });
    }
}
