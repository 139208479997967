import { Component, Label, Layout, Overlay } from "@mcleod/components";
import { Alignment, ArrayUtil, DOMUtil, HelpLink } from "@mcleod/core";
import { CommonDialogs } from "./CommonDialogs";

export class HelpLinkLauncher {
    public static showHelpLinks(layouts: Layout[], anchorComponent: Component) {
        const topMostLayoutHelpLinks = this.discoverHelpLinks(layouts);
        if (ArrayUtil.isEmptyArray(topMostLayoutHelpLinks))
            return;
        const dropdownItems: Label[] = [];
        for (const helpLink of topMostLayoutHelpLinks) {
            dropdownItems.push(new Label({ caption: helpLink.caption, onClick: () => helpLink.open().then(success => this.evaluateLinkSuccess(success)) }));
        }
        Overlay.showDropdown(anchorComponent, dropdownItems, null, { width: null }, { align: Alignment.RIGHT });
    }

    private static discoverHelpLinks(layouts: Layout[]): HelpLink[] {
        if (ArrayUtil.isEmptyArray(layouts))
            return null;
        const topMostLayout = layouts[0];
        const result = topMostLayout.getAllHelpLinks();
        for (let x = result.length - 1; x >= 0; x--) {
            if (DOMUtil.isOrContains(document.body, result[x].getRelatedLayoutElement()) !== true)
                result.splice(x, 1);
        }
        return result;
    }

    //this is only here because we can't access CommonDialogs from HelpLink (because it's in core)
    private static evaluateLinkSuccess(success: boolean) {
        if (success !== true)
            CommonDialogs.showDialog("We're sorry, this link is not accessible at this time.");
    }
}
