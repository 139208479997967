/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelEdiStop.ts                       *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/datafusion/edi-stop";

export class AutogenModelEdiStop<RowType extends ModelRow> extends Endpoint<RowType, RowEdiStopFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowEdiStop extends ModelRow<RowEdiStopFields> {
    constructor(values?: Partial<RowEdiStopFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowEdiStopFields {
    actual_arrival: Date;
    actual_departure: Date;
    address: string;
    appointment_status_id: string;
    appt_required: string;
    cases: number;
    city_id: number;
    city_name: string;
    company_id: string;
    confirmed: string;
    contact_name: string;
    dist_from_prev: number;
    driver_load_unload: string;
    edi_loc_code: string;
    edi_ref_number: string;
    edi_stop_notes: string;
    eta: Date;
    id: string;
    latitude: number;
    location_id: string;
    location_name: string;
    longitude: number;
    order_id: string;
    order_sequence: number;
    phone: string;
    sched_arrive_early: Date;
    sched_arrive_late: Date;
    showas_address: string;
	showas_address2: string;
	showas_city_id: number;
	showas_city_name: string;
	showas_location_id: string;
	showas_location_name: string;
	showas_state: string;
	showas_zip_code: string;
    state: string;
    status: string;
    stop_type: string;
    volume: number;
    weight: number;
    zip_code: string;
    zone_id: string;
}

