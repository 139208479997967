/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelHierarchy.ts                     *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "common/hierarchy";

export class AutogenModelHierarchy<RowType extends ModelRow> extends Endpoint<RowType, RowHierarchyFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowHierarchy extends ModelRow<RowHierarchyFields> {
    constructor() {
        super(AutogenUserSavedSearchEndpoint);
    }
}

export interface RowHierarchyFields {
    company_id: string;
    description: string;
    hierarchy_level: number;
    id: string;
    parent_id: string;
}

