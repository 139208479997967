import { BlurEvent, ChangeEvent, DataSourceMode, Textbox } from "@mcleod/components";
import { AutogenLayoutEdiStopShowAs } from "./autogen/AutogenLayoutEdiStopShowAs";
import { CityUtil, ModelRow } from "@mcleod/core";
import { getDispatchControlBoolean } from "@mcleod/general/src/models/ModelDispatchControl";
import { makeTooltipCallbackFunction } from "@mcleod/dispatch/src/LocationMakeQuickInfo";

export class EdiStopShowAs extends AutogenLayoutEdiStopShowAs {

    private _customerId: string;

    get customerId(): string {
        return this._customerId;
    }

    set customerId(value: string) {
        this._customerId = value;
    }

    override onLoad(): void {

        this.tabsetShowAs.selectedIndex = 1;
        this.locationShowas.addBlurListener((event: BlurEvent) => {
            const selection = this.locationShowas.textCombined.getFirstLookupModelData();
            this.setLocationTextAfterLocation(this.locationShowas.textCombined, selection as ModelRow, event);
        });

        this.locationShowas.googlePlacesProps = {
            enableSearch: getDispatchControlBoolean("enable_google_places"),
            createLocations: getDispatchControlBoolean("create_location_from_stop"),
            customerId: this.customerId,
            doBeforeGoogleLocationCreated: () => this.labelCreatingLocation.visible = true,
            doAfterGoogleLocationCreated: () => this.labelCreatingLocation.visible = false
        };

        this.citystateShowAs.textCombined.addChangeListener((event: ChangeEvent) => {
            const data = this.citystateShowAs.textCombined.getFirstLookupModelData();
            if (data == null && event.newValue === "") {
                this.sourceEdiStop?.activeRow.set({ "showas_city_id": null, "showas_city_name": null, "showas_state": null, "showas_zip_code": null })
                this.citystateShowAs.updateBoundData(this.sourceEdiStop?.activeRow, DataSourceMode.UPDATE);
                this.locationShowas.displayData(this.sourceEdiStop.activeRow, null, 0);
            }
        })
    }
    
    setLocationTextAfterLocation(textBox: Textbox, row: ModelRow, event?: BlurEvent) {
        const data = row?.data
        const source = row?.data["source_name"];
        if (textBox.isDropdownVisible()) return;
        if (source == "CITY") {
            textBox.text = CityUtil.formatCityStateZip(data?.["city_name"], data?.["state"], data?.["zip_code"]);
        }
        else if (event.changedWhileFocused === true && data === undefined) {
            this.sourceEdiStop?.activeRow.set({ "showas_location_name": null, "showas_location_id": null, "showas_address": null, "showas_address2": null });
            this.textboxShowasLocationName.displayData(this.sourceEdiStop.activeRow, null, 0);
            this.textboxShowasAddress.displayData(this.sourceEdiStop.activeRow, null, 0);
            this.textboxShowasAddress2.displayData(this.sourceEdiStop.activeRow, null, 0);
            this.textboxShowasLocationId.displayData(this.sourceEdiStop.activeRow, null, 0);
        }
        else {
            this.textboxShowasLocationId.text = data?.["id"];
            this.textboxShowasLocationId.tooltipCallback = makeTooltipCallbackFunction(data?.["id"], this.textboxShowasLocationId);
            this.textboxShowasLocationName.displayData(this.sourceEdiStop.activeRow, null, 0);
            this.textboxShowasAddress.displayData(this.sourceEdiStop.activeRow, null, 0);
            this.textboxShowasAddress2.displayData(this.sourceEdiStop.activeRow, null, 0);
        }
        this.citystateShowAs.displayData(this.sourceEdiStop.activeRow, null, 0);
    }
}