/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelEdiFunctionalSeg.ts              *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/datafusion/edi-functional-seg";

export class AutogenModelEdiFunctionalSeg<RowType extends ModelRow> extends Endpoint<RowType, RowEdiFunctionalSegFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowEdiFunctionalSeg extends ModelRow<RowEdiFunctionalSegFields> {
    constructor(values?: Partial<RowEdiFunctionalSegFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowEdiFunctionalSegFields {
    bad_data_element: string;
    comp_elem_position: string;
    company_id: string;
    data_elem_number: string;
    data_elem_position: string;
    error_number: number;
    error_type: string;
    error_value: string;
    func_ack_id: string;
    id: string;
    segment_error_code: string;
    segment_id: string;
    segment_loop: string;
    seq_segment: string;
}

