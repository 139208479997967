import { AutogenModelLocation, AutogenRowLocation } from "./autogen/AutogenModelLocation";

export class ModelLocation extends AutogenModelLocation<RowLocation> {
    constructor() {
        super(RowLocation);
    }
}

export class RowLocation extends AutogenRowLocation {
}
