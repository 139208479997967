import {
    BlurEvent, ChangeEvent, DataSource, DataSourceMode, DataSourceModeChangeEvent, Textbox
} from "@mcleod/components";
import { Api, DatePart, DateUtil, ModelRow, StringUtil } from "@mcleod/core";
import { CallinScript } from "../../powerbroker/src/CallinScript";
import { AutogenLayoutCallinEntry } from "./autogen/AutogenLayoutCallinEntry";

export class CallinEntry extends AutogenLayoutCallinEntry {
    calculateEta: (dataSource: DataSource, filter: any) => void;
    callinScript: CallinScript;

    override onLoad(): void {
        this.textboxCurrentLocationTime.visible = false;
        this.citystateCurrentLocation.textCombined.onSelectItem = (textbox: Textbox, selectedItem: any) => {
            this.cityStateSelected(selectedItem as ModelRow);
            return null;
        };
    }

    cityStateSelected(selectedRow: ModelRow<any>) {
        const cityId = selectedRow?.get("id");
        if (cityId) {
            const d = new Date(this.textboxCallDateTime.getDataValue());
            const tz = d.toLocaleString('en-US', { timeZoneName: 'short' }).split(' ').pop();
            Api.search("lme/general/calculate-timezone", { "city_id": cityId, "local_timezone": tz, "date_time": d }).then(result => {
                const data = result?.data[0];
                if (data) {
                    const calculatedDateTime = data["calculated_date_time"];
                    const calculatedTimezone = data["calculated_timezone"];
                    if (calculatedDateTime && data["timezones_equal"] != null && data["timezones_equal"] === false) {
                        this.textboxCurrentLocationTime.visible = true;
                        const formattedDate = DateUtil.formatDateTime(DateUtil.parseDateTime(calculatedDateTime), "iiii, MMMM dd h:mm a");
                        this.textboxCurrentLocationTime.text = formattedDate + " " + calculatedTimezone;
                    }
                    else
                        this.textboxCurrentLocationTime.visible = false;
                }
            });
            this.calcNextStopEta();
        }
    }

    /** This is an event handler for the onChange event of textboxInitiatedType.  */
    textboxInitiatedTypeOnChange(event: ChangeEvent) {
        //if B, current location is required if B or C
        if (event.newValue === "B" || event.newValue === "Broker initiated" ||
            event.newValue === "C" || event.newValue === "Carrier initiated") {
            this.citystateCurrentLocation.required = true;
        }
        else {
            this.citystateCurrentLocation.required = false;
            this.citystateCurrentLocation.validate(); //Since you start in this field and leave to change type, will be red when you leave field.
        }
    }

    compareNextSchedToCallinDate() {
        const callinDate = DateUtil.parseDateWithKeywords(this.textboxCallDateTime.text, true, true);
        const nextSchedDate = new Date(this.sourceDispatch.activeRow.get("next_sched_call"));
        const diffDays = DateUtil.dateDiff(DatePart.DAY, callinDate, nextSchedDate);
        const diffHours = DateUtil.dateDiff(DatePart.HOUR, callinDate, nextSchedDate);
        const diffMinutes = DateUtil.dateDiff(DatePart.MINUTE, callinDate, nextSchedDate);
        if (diffMinutes > 0) {
            if (diffMinutes < 60)
                this.labelCallWarning.caption = "Check call is " + diffMinutes + " minutes past due";
            else if (diffHours < 24)
                this.labelCallWarning.caption = "Check call is " + diffHours + " hours " + (diffMinutes % 60) + " minutes past due";
            else if (diffDays > 0)
                this.labelCallWarning.caption = "Check call is " + diffDays + " days " + (diffHours % 24) + " hours " + (diffMinutes % 60) + " minutes past due";

            this.panelCallinWarning.visible = true;
        }
        else
            this.panelCallinWarning.visible = false;
    }

    /** This is an event handler for the afterExecution event of sourceDispatch.  */
    sourceDispatchAfterExecution() {
        this.textboxInitiatedType.selectedItem = this.textboxInitiatedType.items[0].value;
        this.compareNextSchedToCallinDate();
        if (StringUtil.isEmptyString(this.sourceDispatch.activeRow.get("override_driver2_nm")))
            this.panelDriverTwo.visible = false;
    }

    /** This is an event handler for the afterModeChange event of sourceCallin.
     * Must set date default after mode change or it's blanked out.
    */
    sourceCallinAfterModeChange(event: DataSourceModeChangeEvent) {
        if (event.newMode === DataSourceMode.ADD) {
            this.textboxCallDateTime.dateDefault = "n";
            this.textboxNextSchedCall.text = "";
        }
    }

    textboxCallDateTimeOnBlur(event: BlurEvent) {
        this.calcNextStopEta();
    }

    calcNextStopEta() {
        const moveId = this.sourceDispatch.activeRow.get("id");
        const cityId = this.citystateCurrentLocation?.textCombined?.getFirstLookupModelData()?.get("id");
        const filter = { ...this.sourceCallin.activeRow.data, calc_miles_eta: true, movement_id: moveId, city_id: cityId };
        if (this.calculateEta != null)
            this.calculateEta(this.sourceCallin, filter);
    }

    bindCallinDataSource(callin: DataSource) {
        this.sourceCallin.rebindComponentsTo(callin);
        callin.attachListeners(this.sourceCallin);
        this.sourceCallin = callin;
        this.layoutCallinScript.sourceCallin = this.sourceCallin;
        this.layoutCallinScript.sourceCallinStop = this.sourceCallinStop;
    }
}
