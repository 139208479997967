import { ModelRow } from "@mcleod/core";
import { ClickListener } from "../../events/ClickEvent";

export class MapPin {
    latitude: number;
    longitude: number;
    clusterable: boolean = true;
    pinId: string;
    tooltip: string;
    data: ModelRow;
    rows: ModelRow[] = [];
    image: string;
    caption: any;
    captionColor: string;
    layoutName: string;
    onClick: ClickListener;

    constructor(latitude: number, longitude: number) {
        this.latitude = latitude;
        this.longitude = Math.abs(longitude) * -1;
    }
}
