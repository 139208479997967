import { CloneComponent, Component, DataDisplayEvent, Label, LabelProps, Panel, StringOrPropsOrComponent, Textbox } from "@mcleod/components";
import { DateUtil } from "@mcleod/core";
import { AutogenLayoutQuickSellDetails } from "./autogen/AutogenLayoutQuickSellDetails";

export class QuickSellDetails extends AutogenLayoutQuickSellDetails {

    override onLoad() {
        this.textboxStopOriginOrigSchedEarly["_captionLabel"].color = "primary.reverse";
        this.labelOriginAppt.preventCollapse = true;
        this.panelDate.tooltipCallback = () => {
            return this.textboxStopOriginOrigSchedEarly.showTooltip(this.dateTooltipPanel,
                null, { themeKey: "quickInfo", color: null, fillRow: true });
        };
    }

    configureForSlideout(stopPickDropText: string) {
        this.panelLeft.setProps({ borderWidth: 0, borderTopRightRadius: 6, borderBottomLeftRadius: 0, fillRow: true, rowBreak: true, paddingTop: 4, paddingBottom: 4 });
        this.panelRightMain.setProps({ widthFillWeight: undefined, fillRow: true });
        this.panelLeft.components.forEach(comp => comp.setProps({ rowBreak: false, marginRight: 30 }));
        this.panelMain.setProps({ borderWidth: 1, borderColor: "strokeSecondary", borderTopWidth: 0, borderRadius: 6 });
        this.textboxPickDrop.text = stopPickDropText;
    }


    get dateTooltipPanel(): StringOrPropsOrComponent {
        const panel = new Panel({ padding: 0 });
        panel.add(...this.getStopTooltipComponents("Pickup", "stop_origin"));
        panel.add(...this.getStopTooltipComponents("Delivery", "stop_dest"));
        return panel;
    }

    labelOriginApptRequiredOnDataDisplay(event: DataDisplayEvent) {
        this.setApptLabelProps("stop_origin", this.labelOriginAppt);
    }

    private getStopTooltipComponents(caption: string, tableAlias: string): Component[] {
        const components: Component[] = [];
        const textbox = CloneComponent.clone({
            component: this.textboxStopOriginOrigSchedEarly,
            id: "textboxOriginEarly",
            overrideProps: {
                caption: caption, paddingBottom: 10,
                dataSource: undefined, fontSize: null, rowBreak: true,
                text: this.formatDateText(`${tableAlias}.sched_arrive_early`),
            }
        }) as Textbox;

        if (this.mainDataSource.activeRow.get(`${tableAlias}.sched_arrive_late`) != null) {
            const earlyDate = DateUtil.parseDateTime(this.mainDataSource.activeRow?.get(`${tableAlias}.sched_arrive_early`));
            const lateDate = DateUtil.parseDateTime(this.mainDataSource.activeRow?.get(`${tableAlias}.sched_arrive_late`));
            const format = earlyDate.toDateString() === lateDate.toDateString() ? "h:mm a" : undefined;
            textbox.text = textbox.text + " - " + this.formatDateText(`${tableAlias}.sched_arrive_late`, format);
        }

        components.push(textbox);
        const labelAppt = this.setApptLabelProps(tableAlias, new Label(), { paddingBottom: 10, paddingTop: 0, fontSize: "small", imageWidth: 15 });
        if (labelAppt.caption?.length > 0) {
            textbox.paddingBottom = 0;
            components.push(labelAppt);
        }
        return components;
    }

    private setApptLabelProps(tableAlias: string, label: Label, props?: Partial<LabelProps>): Label {
        const apptRequired = this.mainDataSource.activeRow?.get(tableAlias + ".appt_required") === "Y" && this.mainDataSource.activeRow?.get(tableAlias + ".confirmed") === "N";
        const apptConfirmed = this.mainDataSource.activeRow?.get(tableAlias + ".appt_required") === "Y" && this.mainDataSource.activeRow?.get(tableAlias + ".confirmed") === "Y";
        if (apptRequired)
            label.caption = "Appointment Required";
        else if (apptConfirmed)
            label.caption = "Appointment Confirmed";

        label.setProps({
            imageName: apptRequired ? "circleWarning" : "circleCheck",
            color: apptConfirmed ? "success.light" : null,
            imageMarginLeft: 0, paddingLeft: 0, visible: apptRequired || apptConfirmed,
            ...props
        })
        return label;
    }

    formatDateText(fieldName: string, format: string = "MM/dd h:mm a"): string {
        return DateUtil.formatDate(DateUtil.parseDateTime(this.mainDataSource.activeRow?.get(fieldName)), format);
    }
}

