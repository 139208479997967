/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelCarrierRate.ts                   *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/powerbroker/carrier-rate";

export class AutogenModelCarrierRate<RowType extends ModelRow> extends Endpoint<RowType, RowCarrierRateFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowCarrierRate extends ModelRow<RowCarrierRateFields> {
    constructor(values?: Partial<RowCarrierRateFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowCarrierRateFields {
    carrier_id: string;
    commodity_id: string;
    company_id: string;
    customer_id: string;
    deduct_code_descr: string;
    effective_date: Date;
    equipment_type_id: string;
    expiration_date: Date;
    extra_stop_pay: number;
    extra_stop_pay_c: string;
    extra_stop_pay_d: Date;
    extra_stop_pay_deduct_code: string;
    extra_stop_pay_n: number;
    extra_stop_pay_r: number;
    friday: string;
    fuel_surcharge_pay: string;
    id: number;
    item_number: string;
    last_date_used: Date;
    monday: string;
    order_type_id: string;
    page_no: string;
    revenue_code_id: string;
    revised: string;
    saturday: string;
    sunday: string;
    target_rate: string;
    tariff: string;
    thursday: string;
    times_rate_used: number;
    tuesday: string;
    wednesday: string;
}

