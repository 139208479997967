import { AutogenLayoutLocationOrderHistory } from "./autogen/AutogenLayoutLocationOrderHistory";

export class LocationOrderHistory extends AutogenLayoutLocationOrderHistory {

    override onLoad() {
        this.addComboCaptionAndValues();
    }

    addComboCaptionAndValues() {
        this.textboxBillOrderedDistance.items = [
            { caption: "Bill Date", value: "B" },
            { caption: "Ordered Date", value: "O" }
        ];

        this.textboxStopType.items = [
            { caption: "Shipper", value: "S" },
            { caption: "Consignee", value: "C" },
            { caption: "All", value: "A" }
        ];
    }
}
