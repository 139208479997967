import { CityState, DataSource, Label, Layout, Panel, Table, Textbox } from "@mcleod/components";
import { MovementDataFusionSummary } from "../MovementDataFusionSummary";
import { MovementOffers } from "../MovementOffers";
import { TargetPay } from "../TargetPay";

export abstract class AutogenLayoutAvailableMovementOverview extends Layout {
    citystateConsignee: CityState;
    citystateShipper: CityState;
    labelDeliveryDate: Label;
    labelDestApptConfirmed: Label;
    labelDestApptRequired: Label;
    labelDestDash: Label;
    labelDestSchedArriveEarlyDate: Label;
    labelDestSchedArriveEarlyDay1: Label;
    labelDestSchedArriveEarlyDay2: Label;
    labelDestSchedArriveLateDate: Label;
    labelDestSchedArriveLateDay: Label;
    labelDestSchedArriveLateMonth: Label;
    labelDestTimeEarly: Label;
    labelDestTimeLate: Label;
    labelDestTo: Label;
    labelDistance: Label;
    labelEquipmentRequired: Label;
    labelIntermediateStops: Label;
    labelMoveDistance: Label;
    labelOriginApptConfirmed: Label;
    labelOriginApptRequired: Label;
    labelOriginDash: Label;
    labelOriginSchedArriveEarlyDate: Label;
    labelOriginSchedArriveEarlyDay: Label;
    labelOriginSchedArriveEarlyMonth: Label;
    labelOriginSchedArriveLateDate: Label;
    labelOriginSchedArriveLateDay: Label;
    labelOriginSchedArriveLateMonth: Label;
    labelOriginTimeEarly: Label;
    labelOriginTimeLate: Label;
    labelOriginTo: Label;
    labelPickupDate: Label;
    labelTrackingRequired: Label;
    layoutMovementDataFusionSummary: MovementDataFusionSummary;
    layoutOffers: MovementOffers;
    layoutTargetPay: TargetPay;
    panelDataFusion: Panel;
    panelDetails: Panel;
    panelMoveInfo: Panel;
    panelOfferHistory: Panel;
    panelStopLocations: Panel;
    panelStopTimes: Panel;
    panelTargetPay: Panel;
    sourceEquipMatchDetail: DataSource;
    sourceMovement: DataSource;
    sourceMovementDataFusionSummary: DataSource;
    tableEquipmentRequired: Table;
    textboxMaxPay: Textbox;
    textboxOrdersCommodityId: Textbox;
    textboxOrdersEquipmentTypeId: Textbox;
    textboxOrdersPlanningComment: Textbox;
    textboxOrdersWeight: Textbox;
}
