/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelLoginSettings.ts                 *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "common/login-settings";

export class AutogenModelLoginSettings<RowType extends ModelRow> extends Endpoint<RowType, RowLoginSettingsFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowLoginSettings extends ModelRow<RowLoginSettingsFields> {
    constructor(values?: Partial<RowLoginSettingsFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowLoginSettingsFields {
    allow_keep_signed_in: string;
    company_id: string;
    id: number;
    keep_signed_in_days: number;
}

