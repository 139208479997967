import { ClickEvent, SelectionEvent, Tab, TableRow, TableRowMode } from "@mcleod/components";
import { Api, ModelRow } from "@mcleod/core";
import { AutogenLayoutMovementOfferTabs } from "./autogen/AutogenLayoutMovementOfferTabs";

export enum OfferTabType {
    WATERFALL,
    COUNTER,
    OFFER_HISTORY
}

export interface OfferTabEvent {
    type: OfferTabType;
    action: OfferTabEventAction;
    changedRow?: TableRow;
}

export enum OfferTabEventAction {
    SEARCH,
    SYNC_DATA,
    SELECT_OFFER,
    REMOTE_DATA_CHANGE,
    CONTENTS_CHANGED
}

export class MovementOfferTabs extends AutogenLayoutMovementOfferTabs {
    private movementID: string;
    private orderID: string;
    private isWaterfall: boolean;
    private brokerageMovementRow: ModelRow;

    async initialize(orderID: string, movementID: string, brokerageMovementRow: ModelRow) {
        this.movementID = movementID;
        this.orderID = orderID;
        this.brokerageMovementRow = brokerageMovementRow;
        this.addTabListeners();
        await this.setMovement();
        await this.initializeWaterfallOffersLayout();
        await this.initializeCounterOffersLayout();
        this.initializeOfferHistoryLayout();
        this.tabsetOfferTables.visible = true;
    }

    async setMovement() {
        await this.sourceMovement.search({ "id": this.movementID });
    }

    addTabListeners() {
        this.layoutWaterfallOffers.onTabEvent = (offerTabEvent: OfferTabEvent) => this.waterfallTabEventHandler(offerTabEvent);
        this.layoutCounterOffers.onTabEvent = (offerTabEvent: OfferTabEvent) => this.counterTabEventHandler(offerTabEvent);
        this.layoutOfferHistory.onTabEvent = (offerTabEvent: OfferTabEvent) => this.offerHistoryTabEventHandler(offerTabEvent);
    }

    waterfallTabEventHandler(offerTabEvent: OfferTabEvent) {
        switch (offerTabEvent.action) {
            case OfferTabEventAction.SELECT_OFFER: this.buttonSkipCarrier.enabled = offerTabEvent.changedRow != null; break;
            case OfferTabEventAction.REMOTE_DATA_CHANGE: {
                if (this.tabOfferHistory.visible === false) {
                    this.initializeOfferHistoryLayout();
                } else {
                    this.layoutOfferHistory.search();
                }
            }
        }
        this.updateTabTitle(OfferTabType.WATERFALL);
    }

    counterTabEventHandler(offerTabEvent: OfferTabEvent) {
        this.updateTabTitle(OfferTabType.COUNTER);
    }

    offerHistoryTabEventHandler(offerTabEvent: OfferTabEvent) {
        switch (offerTabEvent.action) {
            case OfferTabEventAction.SYNC_DATA: this.syncTabData(offerTabEvent.changedRow); break;
        }
        this.updateTabTitle(OfferTabType.OFFER_HISTORY);
    }

    async initializeWaterfallOffersLayout() {
        await this.layoutWaterfallOffers.initialize(this.getOrderSummaryInfo(), this.sourceMovement);
        this.isWaterfall = this.sourceMovement.activeRow.getBoolean("waterfall_in_progress", false);
        this.updateWaterfallComponents(this.isWaterfall);
    }

    updateWaterfallComponents(waterfalling: boolean) {
        this.isWaterfall = waterfalling;
        this.tabWaterfallProgress.visible = waterfalling;
        this.tabsetOfferTables.selectedIndex = waterfalling ? 0 : 1;
        this.buttonCancelWaterfall.visible = waterfalling;
        this.buttonSkipCarrier.visible = waterfalling;
        this.tabCounterOffers.visible = this.layoutOfferHistory.tableOffers.rows.some(tableRow => tableRow.data.get("status") === "O");
    }

    async initializeCounterOffersLayout() {
        await this.layoutCounterOffers.initialize(this.getOrderSummaryInfo(), this.sourceMovement);
    }

    async initializeOfferHistoryLayout() {
        this.layoutOfferHistory.labelOfferHistory.visible = this.layoutOfferHistory.labelOfferHistoryCount.visible = false;
        await this.layoutOfferHistory.initialize(this.orderID, this.sourceMovement, this.movementID);
        this.showFirstVisibleTab();
    }

    async syncTabData(changedRow: TableRow) {
        // Data is refreshed when changing tabs (onClick) but if a tab needs to react before switching,
        // we need to check for updates to automatically trigger intialization
        const modelRow = changedRow.data as ModelRow;
        const status = modelRow.get("status");
        const originalStatus = modelRow.originalData["status"];
        if (status == "O" || originalStatus === "O") { // if changed to countered or changed back from countered sync row
            if (originalStatus === "O") {
                const counterOfferRowIndex = this.layoutCounterOffers.tableOffers.rows.findIndex(tableRow => tableRow.data.get("id") === changedRow.data.get("id"));
                this.layoutCounterOffers.tableOffers.removeRow(counterOfferRowIndex);
            } else {
                this.findAndAddOrReplaceRow(OfferTabType.COUNTER, changedRow);
            }
            this.updateTabTitle(OfferTabType.COUNTER);
        }
    }

    findAndAddOrReplaceRow(type: OfferTabType, row: TableRow) {
        const table = this.getTableForType(type);
        const index = table.rows.findIndex(tableRow => tableRow.data.get("id") === row.data.get("id"));
        if (index >= 0) {
            table.rows[index].data.setValues(row.data);
        } else {
            table.addRow(row.data, { mode: TableRowMode.NONE }, { display: true, addToData: false });
        }
        this.updateTabTitle(type);
    }

    getTableForType(type: OfferTabType) {
        switch (type) {
            case OfferTabType.WATERFALL: return this.layoutWaterfallOffers.tableOffers;
            case OfferTabType.COUNTER: return this.layoutCounterOffers.tableOffers;
            case OfferTabType.OFFER_HISTORY: return this.layoutOfferHistory.tableOffers;
        }
    }

    updateTabTitle(tabType: OfferTabType) {
        let rowCount: number;
        switch (tabType) {
            case OfferTabType.WATERFALL:
                rowCount = this.layoutWaterfallOffers.tableOffers.rowCount;
                this.tabWaterfallProgress.caption = `Waterfall Progress ${rowCount > 0 ? rowCount : ""}`;
                break;
            case OfferTabType.COUNTER:
                rowCount = this.layoutCounterOffers.tableOffers.rowCount;
                this.tabCounterOffers.caption = `Active Counters ${rowCount > 0 ? rowCount : ""}`;
                this.tabCounterOffers.visible = rowCount > 0;
                break;
            case OfferTabType.OFFER_HISTORY:
                rowCount = this.layoutOfferHistory.tableOffers.rowCount;
                this.tabOfferHistory.caption = `Offer History ${rowCount > 0 ? rowCount : ""}`;
                this.tabOfferHistory.visible = rowCount > 0
                break;
        }
    }

    getOrderSummaryInfo() {
        return {
            order_id: this.orderID,
            commodity: this.brokerageMovementRow.get("commodity"),
            distance: this.brokerageMovementRow.get("distance"),
            weight: this.brokerageMovementRow.get("weight"),
            equipment_type: this.brokerageMovementRow.get("equipment_type")
        }
    }

    showFirstVisibleTab() {
        this.tabsetOfferTables.components.some(comp => {
            if (comp instanceof Tab && comp.visible === true) {
                comp.select();
                return true;
            }
        })
    }

    public set stopCountText(stopCountText: string) {
        this.layoutOfferHistory.stopCountText = stopCountText;
    }

    async buttonCancelWaterfallOnClick(event: ClickEvent) {
        await Api.post("lme/powerbroker/move-locking", { movement_id: this.movementID, action: "cancel_waterfall" });
        await this.layoutWaterfallOffers.search();
        this.updateTabTitle(OfferTabType.COUNTER);
        this.showFirstVisibleTab();
    }

    async buttonSkipCarrierOnClick(event: ClickEvent) {
        const row: ModelRow = this.layoutWaterfallOffers.tableOffers.selectedRow?.data;
        if (row == null) return;
        await Api.post("lme/powerbroker/skip-waterfall-offer", { offer_id: row.get("id") });
        this.layoutWaterfallOffers.search();
    }

    tabsetOfferTablesAfterTabSelection(event: SelectionEvent) {
        const isWaterfallTab = event.newSelection == this.tabWaterfallProgress;
        this.buttonCancelWaterfall.visible = this.buttonSkipCarrier.visible = isWaterfallTab;
        if (event.userInitiatedChange) {
            switch (event.newSelection) {
                // Waterfall gets live updates so new search not needed
                case this.tabCounterOffers: this.layoutCounterOffers.search(); break;
                case this.tabOfferHistory: this.layoutOfferHistory.search(); break;
            }
        }
    }
}
