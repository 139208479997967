import { Button, DataSource, HorizontalSpacer, Layout, Tab, Table, Tabset } from "@mcleod/components";

export abstract class AutogenLayoutImageTable extends Layout {
    buttonUploadImage: Button;
    horizontalspacer1: HorizontalSpacer;
    sourceImages: DataSource;
    tabImages: Tab;
    tableImages: Table;
    tabsetImages: Tabset;
}
