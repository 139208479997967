/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelArinquiry.ts                     *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/ar/arinquiry";

export class AutogenModelArinquiry<RowType extends ModelRow> extends Endpoint<RowType, RowArinquiryFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowArinquiry extends ModelRow<RowArinquiryFields> {
    constructor() {
        super(AutogenUserSavedSearchEndpoint);
    }
}

export interface RowArinquiryFields {
    cash_receipts: number;
    credit_memos: number;
    misc_bills: number;
    net_total: number;
    status_display: string;
    unposted: number;
}

