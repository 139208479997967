import { BlurEvent, ChangeEvent, DropdownItem, Textbox } from "@mcleod/components";
import { EdiOrderRates } from "@mcleod/datafusion/src/EdiOrderRates";
import { OrderRates } from "./OrderRates";
import { QuoteRates } from "./QuoteRates";
import { RecOrderRates } from "./RecOrderRates";

export class RateUtil {
    public static readonly PREPAID = {value: "P", caption: "Prepaid"};
    public static readonly THIRD_PARTY = {value: "T", caption: "Third party"};
    public static readonly COLLECT = {value: "C", caption: "Collect"};

    public static getCollectionMethodDropdownItems(): DropdownItem[] {
        return [this.PREPAID, this.THIRD_PARTY, this.COLLECT];
    }

    public static setRateListeners(layout: OrderRates | RecOrderRates | QuoteRates | EdiOrderRates) {
        RateUtil.addRateFieldUpdateListener(layout);
        layout.textboxRateType.addChangeListener((event: ChangeEvent) => {
            if (event.userInitiatedChange && !layout.textboxRateType.isDropdownVisible()) {
                RateUtil.enableDisableUnitsAndRates(layout, event.newValue);
            }
        });
    }

    public static enableDisableUnitsAndRates(layout: OrderRates | RecOrderRates | QuoteRates | EdiOrderRates, newValue: string) {
        const isOther = newValue === "Other" || newValue === "O";
        if (isOther) {
            layout.textboxRateUnits.enabled = true;
            layout.textboxRateUnits.enabledDuringAdd = true;
            layout.textboxRateUnits.enabledDuringUpdate = true;
        } else {
            layout.textboxRateUnits.enabled = false;
            layout.textboxRateUnits.enabledDuringAdd = false;
            layout.textboxRateUnits.enabledDuringUpdate = false;
        }
        layout.calculateRates(false, false).then(() => {
            isOther ? layout.textboxRateUnits.focus() : layout.textboxRate.focus();
        });
    }

    public static setAutoRateContext(layout: OrderRates | RecOrderRates | QuoteRates | EdiOrderRates, rateId: number) {
        if (rateId != null) {
            layout.labelAutoRated.visible = true;
            layout.labelAutoRated.tooltip = `Rate Number: ${rateId}`;
            layout.buttonRate.tooltip = layout.labelAutoRated.tooltip;
            layout.mainDataSource.activeRow.set("rate_id", rateId);
        }
        else {
            layout.labelAutoRated.visible = false;
            layout.textboxRate.tooltipCallback = null;
            layout.buttonRate.tooltip = null;
            layout.mainDataSource.activeRow.set("rate_id", null);
            for (let i = layout.tableOtherCharges.rows.length - 1; i >= 0; i--) {
                const row = layout.tableOtherCharges.rows[i];
                if (row?.data?.data["rate_id"] != null) {
                    row.deleteRow();
                }
            }
        }
    }

    private static addRateFieldUpdateListener(layout: OrderRates | RecOrderRates | QuoteRates | EdiOrderRates) {
        const rateFields = ["rate", "rate_type", "rate_units", "weight", "rate_min_weight", "equipment_type_id", "bill_distance", "customer_id", "order_type_id", "commodity_id", "tractor_type"];
        layout.mainDataSource.boundComponents.forEach((comp) => {
            if (comp instanceof Textbox && rateFields.includes(comp.field)) {
                if (comp.hasLookupModel()) {
                    comp.onSelectItem = (textbox: Textbox, selectedItem: any) => {
                        layout.calculateRates(false, comp.field === "customer_id");
                        return undefined;
                    };
                }
                else if (comp.hasDropdown()) {
                    comp.addChangeListener(async (event: ChangeEvent) => {
                        if (event.oldValue != event.newValue && layout.labelAutoRated.visible && event.userInitiatedChange) {
                            await layout.calculateRates(false, false);
                        }
                    });
                } else {
                    comp.addBlurListener(async (event: BlurEvent) => {
                        if (event.changedWhileFocused) {
                            await layout.calculateRates(false, comp.field === "bill_distance"); // only update fuel surcharge if bill distance is changed
                        }
                    });
                }
            }
        });
    }
}
