/**
 * This class is used to store items that should be available from a global window perspective.
 * It was developed to solve this on-site debugging challenge: 
 * In a browser's debugger you might want to use the expression evaluator to show values of
 * different objects.  But, without setting a breakpoint somewhere, there is no clear 'starting point.'
 * For example, I may have brokerage planning open and I want to examine the contents of the
 * data in the tables.  I know that if I could find a reference to the BrokeragePlanning class, I could 
 * look at its members to find the tables and then examine the tables' data.  But finding that reference to 
 * the BrokeragePlanning is tough.  This class exposes that and other things that we might find we'd like
 * to expose.
 */
export class McLeodContext {
    public static get(key: string): any {
        return window["mcleodContext"]?.[key];
    }

    public static set(key: string, value: any) {
        let context = window["mcleodContext"];
        if (context == null) {
            context = {};
            window["mcleodContext"] = context;
        }
        context[key] = value;
    }

    public static setRouter(value: object) {
        this.set("router", value);
    }

    public static getRouter(): object {
        return this.get("router");
    }

    public static getMainLayout(): object {
        const comps = this.getRouter()?.["components"];
        if (comps?.length > 0)
            return comps[0]; // this can be a Decorator... not sure if folks will want that or the Layout that's being decorated
        return null;
    }
}