import { Api } from "./Api";
import { ApiMetadata, DataType, getApiMetadata, getApiMetadataFromCache, MetadataField } from "./ApiMetadata";
import { ArrayUtil } from "./ArrayUtil";
import { Collection } from "./Collection";
import { ConversionUtil } from "./ConversionUtil";
import { Currency } from "./Currency";
import { CurrencyUtil } from "./CurrencyUtil";
import { DateUtil } from "./Date";
import { ErrorHandler } from "./ErrorHandler";
import { FieldUpdateEvent } from "./FieldUpdateEvent";
import { getLogger } from "./Logger";
import { Model } from "./Model";
import { ObjectUtil } from "./ObjectUtil";
import { RowUpdateEvent } from "./RowUpdateEvent";
import { StringUtil } from "./StringUtil";
import { SUCCESS, SuccessFail } from "./SuccessFail";

const log = getLogger("core.ModelRow");

export type FieldUpdateListener = (event: FieldUpdateEvent) => void;
export type RowPostListener = (event: RowUpdateEvent) => Promise<any>;

export const LOOKUP_MODEL_PREFIX: string = "_lookup_";

export interface LinkedModel {
    model: string;
    rows: ModelRow[];
    deletedRows?: ModelRow[];
}

export enum ModelRowType {
    NORMAL = "normal",
    LOOKUP_MODEL_DATA = "lookupModelData"
}

export class ModelRow<DataInterface = any>  {
    private _data: DataInterface = {} as DataInterface;
    private _originalData: DataInterface;
    private _carryoverData: DataInterface = {} as DataInterface;
    _modelPath: string;
    _appending: boolean;
    private linkedModels: LinkedModel[];
    private _beforePostListeners: RowPostListener[];
    private _afterPostListeners: RowPostListener[];
    private _beforeFieldUpdateListeners: FieldUpdateListener[];
    private _afterFieldUpdateListeners: FieldUpdateListener[];
    private _type: ModelRowType;
    constructor(modelPath: string, appending: boolean = true, values?: Partial<DataInterface>) {
        this._modelPath = modelPath;
        this._appending = appending;
        if (values != null)
            this.setValues(values);
    }

    /**
     * This method fetches the default values for a given ModelRow from the server
     * and populates those values into this row.  Keep in mind that it's an
     * asynchronous method and the default values won't be populated until
     * the Promise is resolved.
     * @returns a Promise that resolves to an object containing just the values were
     * set on the ModelRow
     */
    async populateDefaultValues(fieldList?: string): Promise<DataInterface> {
        if (StringUtil.isEmptyString(this._modelPath) === true)
            return null;
        const result = await Model.getDefaultRow<DataInterface>(this._modelPath, fieldList);
        log.debug(() => ["Default values", result]);
        this.setValues(result);
        return result;
    }

    public get originalData(): DataInterface {
        return this._originalData;
    }

    get data(): DataInterface {
        return this._data;
    }

    get(field: keyof DataInterface, valueIfNull?: any): any {
        const result = this._data[field];
        return result == null ? valueIfNull : result;
    }

    getBoolean(field: keyof DataInterface, valueIfNull: boolean = false): boolean {
        const result = this.get(field, valueIfNull);
        if (typeof result === "string")
            return ConversionUtil.parseBoolean(result);
        return result;
    }

    getCurrency(field: keyof DataInterface, valueIfNull?: number | Currency): Currency {
        const fieldData = this.get(field);
        return fieldData ? new Currency(fieldData) : valueIfNull instanceof Currency ? valueIfNull : new Currency({ amount: valueIfNull });
    }

    getLookupModelData(field: keyof DataInterface, valueIfNull?: any): any[] {
        return this.get(ModelRow.getLookupModelFieldName(field) as keyof DataInterface, valueIfNull);
    }

    getFirstLookupModelData(field: keyof DataInterface, valueIfNull?: any): any {
        const lmArray = this.getLookupModelData(field);
        if (ArrayUtil.isEmptyArray(lmArray) !== true)
            return lmArray[0];
        return null;
    }

    /**
     * Sets either a single field's value or multiple values in one call.
     *
     * @param values
     * If values is a string, this will set that single fields' value to valueToSetWhenSingleField.
     * If values is a object, all that object's key/value pairs will be applied to the ModelRow's data.
     * @param valueToSetWhenSingleField
     * @param originator The object (usually a Component) that triggered the update
     */
    set(values: Partial<DataInterface> | keyof DataInterface, valueToSetWhenSingleField?: any, originator?: any): SuccessFail {
        if (!this._appending && this._originalData == null)
            this.resetOriginalData();
        if (typeof values === "string")
            return this._internalSet(values, valueToSetWhenSingleField, originator);
        else {
            if (valueToSetWhenSingleField != null)
                throw new Error("DataRow.set() second argument should not be used when the first argument is an object.");
            for (const [key, value] of Object.entries(values)) {
                const result = this._internalSet(key, value, originator);
                if (!result.success)
                    return result;
            }
            return SUCCESS;
        }
    }

    setLookupModelData(field: keyof DataInterface, value: any, originator?: any) {
        this.set(ModelRow.getLookupModelFieldName(field) as keyof DataInterface, value, originator);
    }

    addLookupModelData(field: keyof DataInterface, value: any, originator?: any) {
        this.setLookupModelData(field, [...this.getLookupModelData(field), value], originator);
    }

    protected _internalSet(field: string, value: any, originator: any): SuccessFail {
        const event = this.fireListeners(this._beforeFieldUpdateListeners, () => new FieldUpdateEvent(this, field, oldValue, this._data[field], true, originator));
        if (event?.preventReason != null) {
            log.debug(() => ["beforeFieldUpdateListeners prevented update", event.preventReason, this, field, value, originator]);
            return new SuccessFail(false, event.preventReason);
        }
        const oldValue = this._data[field];
        if (value === undefined)
            delete this._data[field];
        else {
            if (value == null || ModelRow.isLookupModelFieldName(field) !== true)
                this._data[field] = value;
            else {
                if (Array.isArray(value) !== true)
                    this._data[field] = [value];
                else
                    this._data[field] = value;
            }
        }
        this.fireListeners(this._afterFieldUpdateListeners, () => new FieldUpdateEvent(this, field, oldValue, this._data[field], false, originator));
        return SUCCESS;
    }

    public clearLinkedModels() {
        this.linkedModels = null;
    }

    public addLinkedModel(value: LinkedModel) {
        if (this.linkedModels == null)
            this.linkedModels = [];
        this.linkedModels.push(value);
    }

    public isEmpty(): boolean {
        return this._data == null || Object.keys(this._data).length === 0;
    }

    public clear() {
        if (!this.isEmpty()) {
            for (const key of Object.keys(this._data))
                delete this._data[key];
        }
    }

    public addSingleQuotes() {
        for (const key in this) {
            if (key === "_modelPath" || key === "_appending") {
                continue;
            }
            const value = this[key];
            if (typeof value === "string") {
                let modifiedValue: string = value;
                if (modifiedValue.substr(0, 1) !== "'") {
                    modifiedValue = "'" + modifiedValue;
                }
                if (modifiedValue.substr(modifiedValue.length - 1, 1) !== "'") {
                    modifiedValue += "'";
                }
                this[key] = modifiedValue as any;
            }
        }
    }

    public toJSON(): any {
        const result: any = { ...this._data, _appending: this._appending };
        if (this.linkedModels?.length > 0)
            result._child_links = this.linkedModels;
        return result;
    }

    // TODO: add methods to remove listeners
    addBeforeFieldUpdateListener(listener: FieldUpdateListener) {
        if (this._beforeFieldUpdateListeners == null)
            this._beforeFieldUpdateListeners = [];
        this._beforeFieldUpdateListeners.push(listener);
    }

    addAfterFieldUpdateListener(listener: FieldUpdateListener) {
        if (this._afterFieldUpdateListeners == null)
            this._afterFieldUpdateListeners = [];
        this._afterFieldUpdateListeners.push(listener);
    }

    protected fireListeners(listeners: any[], eventCreatorFunction: () => FieldUpdateEvent): RowUpdateEvent {
        if (listeners != null && listeners.length > 0) {
            const event = eventCreatorFunction();
            for (const listener of listeners)
                listener(event);
            return event;
        }
        return undefined;
    }

    addBeforePostListener(listener: RowPostListener) {
        if (this._beforePostListeners == null)
            this._beforePostListeners = [];
        if (this._beforePostListeners.indexOf(listener) < 0)
            this._beforePostListeners.push(listener);
    }

    clearBeforePostListeners() {
        this._beforePostListeners = null;
    }

    addAfterPostListener(listener: RowPostListener) {
        if (this._afterPostListeners == null)
            this._afterPostListeners = [];
        if (this._afterPostListeners.indexOf(listener) < 0)
            this._afterPostListeners.push(listener);
    }

    clearAfterPostListeners() {
        this._afterPostListeners = null;
    }

    setValues(values: Partial<DataInterface>, originator?: any): ModelRow<DataInterface> {
        if (values != null)
            for (const [key, value] of Object.entries(values))
                this._internalSet(key, value, originator);
        return this;
    }

    protected async beforePost(): Promise<SuccessFail> {
        if (this._beforePostListeners != null) {
            const event = new RowUpdateEvent(this, true, null);
            for (const listener of this._beforePostListeners) {
                await listener(event);
                if (event.preventReason != null)
                    return Promise.resolve(new SuccessFail(false, event.preventReason, event.onPrevent));
            }
        }
        this._carryoverData = this._getCarryoverData();
        return Promise.resolve(SUCCESS);
    }

    protected async afterPost(response: any): Promise<any> {
        const data = response?.data?.[0]?.data?.[0];
        if (data != null)
            this.setValues(data);
        if (this._carryoverData != null) {
            this.setValues(this._carryoverData);
            this._carryoverData = null;
        }
        if (this.linkedModels != null)
            this.linkedModels = null;
        this._appending = false;
        this.resetOriginalData();
        if (this._afterPostListeners != null) {
            const event = new RowUpdateEvent(this, true, null);
            for (const listener of this._afterPostListeners)
                await listener(event);
        }
    }

    resetOriginalData(field?: string) {
        if (field == null)
            this._originalData = ObjectUtil.deepCopy(this._data);
        else if (this._originalData != null) //could be null in add mode
            this._originalData[field] = ObjectUtil.deepCopy(this._data[field]);
    }

    public getBodyWithLinkedModels() {
        const body = { ...this._data };
        this.populateLinkedModels(body);
        return body;
    }

    async post(errorHandler?: ErrorHandler): Promise<ModelRow> {
        const beforePostSuccessFail = await this.beforePost();
        if (!beforePostSuccessFail.success)
            if (beforePostSuccessFail.onFail)
                return Promise.reject(beforePostSuccessFail.onFail);
            else
                return Promise.reject(beforePostSuccessFail.reason);
        if (this._appending) {
            const body = this._getBodyForPost();
            this.populateLinkedModels(body);
            return Api.post(this._modelPath, body, null, errorHandler).then(async response => {
                await this.afterPost(response);
                return this;
            });
        }
        else {
            await getApiMetadata(this._modelPath);
            const keyData = this.getKeyData();
            const body = { ...this.getChangedData() };
            this.populateLinkedModels(body);
            if (Object.keys(body).length > 0)
                return Api.update(this._modelPath, { ...keyData, ...body }, null, errorHandler).then(async response => {
                    await this.afterPost(response);
                    return this;
                });
            else {
                log.debug(() => ["Not posting unchanged row with keyData", keyData]);
                return Promise.resolve(null); //return a null in place of the response object
            }
        }
    }

    protected populateLinkedModels(body: any) {
        if (this.linkedModels != null) {
            let bodyLinkArray: LinkedModel[];
            for (const link of this.linkedModels) {
                let modelLink: LinkedModel;
                if (link.deletedRows != null) {
                    for (const row of link.deletedRows) {
                        if (bodyLinkArray == null) {
                            bodyLinkArray = [];
                            body._child_links = bodyLinkArray;
                        }
                        if (modelLink == null) {
                            modelLink = { model: link.model, rows: [] };
                            bodyLinkArray.push(modelLink);
                        }
                        if (modelLink.deletedRows == null)
                            modelLink.deletedRows = [];
                        modelLink.deletedRows.push(new ModelRow(link.model, false, { ...row.getKeyData() }))
                    }
                }
                for (const row of link.rows) {
                    const changedData = row.getChangedData();
                    if (changedData != null && Object.keys(changedData).length > 0) {
                        if (bodyLinkArray == null) {
                            bodyLinkArray = [];
                            body._child_links = bodyLinkArray;
                        }
                        if (modelLink == null) {
                            modelLink = { model: link.model, rows: [] };
                            bodyLinkArray.push(modelLink);
                        }
                        modelLink.rows.push(new ModelRow(link.model, row._appending, { ...row.getKeyData(), ...changedData }));
                    }
                }
            }
        }
    }

    getMetadata() {
        return getApiMetadataFromCache(this._modelPath);
    }

    getKeyData(): Collection<unknown> {
        const meta: ApiMetadata = this.getMetadata();
        const result = {};
        if (meta.keyFields == null)
            throw new Error("This row has no key data - " + this._modelPath);
        for (const fieldName of meta.keyFields)
            result[fieldName] = this._data[fieldName];
        return result;
    }

    getChangedData(): Partial<DataInterface> {
        if (this._appending)
            return this._data;
        if (this._originalData == null)
            return {};
        const result = {};
        const meta: ApiMetadata = this.getMetadata();

        for (const [key, value] of Object.entries(this._data)) {
            if (this._isLookupModelData(key, value))
                continue;
            const orig = this._originalData[key];
            const changed = !this.fieldsAreEqual(meta.output[key], orig, value);
            if (changed)
                result[key] = value;
        }
        return result;
    }

    hasChanged(newRecordsChanged: boolean = true): boolean {
        if (this._appending)
            return newRecordsChanged;
        if (this._originalData == null || Object.keys(this._originalData).length === 0)
            return false;

        const meta: ApiMetadata = this.getMetadata();
        for (const [key, value] of Object.entries(this._data)) {
            if (this._isLookupModelData(key, value))
                continue;
            const orig = this._originalData[key];
            const changed = !this.fieldsAreEqual(meta?.output[key], orig, value);
            if (changed)
                return true;
        }
        return false;
    }

    fieldsAreEqual(field: MetadataField, value1: any, value2: any) {
        if (value1 == null && value2 == null)
            return true;
        else if (value1 == null || value2 == null)
            return false;
        if (field != null) {
            if (field.dataType === DataType.TIME)
                return DateUtil.timesEqual(value1, value2);
            if (field.dataType === DataType.CURRENCY)
                return CurrencyUtil.currencysAreEqual(value1, value2);
            if (field.dataType === DataType.LIST || field.dataType === DataType.OBJECT)
                return ObjectUtil.deepEqual(value1, value2);
        }
        return value1 == value2;
    }

    delete(errorHandler?: ErrorHandler): Promise<any> {
        const body = this._getBodyForDelete();
        return Api.delete(this._modelPath, body, null, errorHandler);
    }

    private _getBodyForPost(): DataInterface {
        return this._getAllNonLookupModelData();
    }

    private _getBodyForDelete(): DataInterface {
        return this._getAllNonLookupModelData();
    }

    private _getCarryoverData(): DataInterface {
        return this._getAllLookupModelData();
    }

    removeLookupModelData() {
        for (const [key, value] of Object.entries(this._data)) {
            if (this._isLookupModelData(key, value))
                delete this._data[key];
        }
    }

    private _getAllLookupModelData(): DataInterface {
        const result = {};
        for (const [key, value] of Object.entries(this.data)) {
            if (this._isLookupModelData(key, value) === true)
                result[key] = this.data[key] != null ? [...this.data[key]] : null;
        }
        return result as DataInterface;
    }

    private _getAllNonLookupModelData(): DataInterface {
        const result = { ...this._data };
        for (const [key, value] of Object.entries(result)) {
            if (this._isLookupModelData(key, value))
                delete result[key];
        }
        return result;
    }

    public static isLookupModelFieldName(field: string): boolean {
        if (typeof field === "string")
            return field.startsWith(LOOKUP_MODEL_PREFIX);
        return false;
    }

    public static getLookupModelFieldName(field: any): string {
        if (ModelRow.isLookupModelFieldName(field) !== true)
            return LOOKUP_MODEL_PREFIX + field;
        return field;
    }

    private _isLookupModelData(key: string, value: any) {
        return (value instanceof ModelRow && value.isLookupModelDataRow()) || ModelRow.isLookupModelFieldName(key);
    }

    get type(): ModelRowType {
        return this._type == null ? ModelRowType.NORMAL : this._type;
    }

    set type(value: ModelRowType) {
        this._type = value;
    }

    isLookupModelDataRow(): boolean {
        return this.type === ModelRowType.LOOKUP_MODEL_DATA;
    }

    public createBasicCopy(): ModelRow {
        return new ModelRow(this._modelPath, this._appending, this.data);
    }

    public isNull(fieldName: keyof DataInterface): boolean {
        const value = this.get(fieldName, undefined);
        if (value != null) {
            if (typeof value === "string")
                return StringUtil.isEmptyString(value);
            return false;
        } else {
            return true;
        }
    }
}
