import { ObjectUtil } from "@mcleod/core";
import { PropType } from "../..";
import { ComponentPropDefinitions, ComponentPropDefinitionUtil } from "../../base/ComponentProps";
import { ButtonPropDefinitions, ButtonProps } from "../button/ButtonProps";
import { ImagePropDefinitions } from "../image/ImageProps";

export interface MultiButtonProps extends Partial<ButtonProps> {
    busyWhenDataSourceBusy: boolean;
    caption: string;
    dropdownImageSize: number;
    showSeparator: boolean;
}

let multiButtonProps: ComponentPropDefinitions;

export class MultiButtonPropDefinitions {
    public static getDefinitions(): ComponentPropDefinitions {
        if (multiButtonProps == null) {
            multiButtonProps = {
                ...ObjectUtil.deepCopy(ButtonPropDefinitions.getDefinitions()),
                caption: { type: PropType.string },
                dropdownImageSize: { type: PropType.number, defaultValue: 16 },
                hotkey: { type: PropType.string },
                imageName: { type: PropType.string, editor: ImagePropDefinitions.imagePropertyEditor },
                showSeparator: { type: PropType.bool }
            };
            ComponentPropDefinitionUtil.populateComponentPropNames(multiButtonProps);
        }
        return multiButtonProps;
    }
}
