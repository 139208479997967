import { Component, StringOrPropsOrComponent } from "../..";
import { ComponentPropDefinition } from "../../base/ComponentProps";
import { Overlay } from "../../page/Overlay";
import { Button } from "../button/Button";
import { Panel } from "../panel/Panel";
import { MultiButtonPropDefinitions, MultiButtonProps } from "./MultiButtonProps";

export class MultiButton extends Panel implements MultiButtonProps {
    public primaryButton: Button;
    public dropdownButton: Button;
    private _defaultProps = {
        borderRadius: 4,
        padding: 0,
        maxHeight: 36
    };

    constructor(props?: Partial<MultiButtonProps>) {
        super(props, false);
        this.primaryButton = this.createPrimaryButton();
        this.dropdownButton = this.createDropdownButton();
        this.add(this.primaryButton);
        this.setProps({ ...this._defaultProps, ...props });
    }

    setProps(props: Partial<MultiButtonProps>) {
        super.setProps(props);
    }

    private createPrimaryButton(): Button {
        return new Button({
            borderWidth: 0,
            fillRow: true,
            paddingLeft: 14,
            paddingTop: 10,
            paddingBottom: 10,
            paddingRight: 7,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            margin: 0,
            rowBreak: false,
            fillHeight: true,
            id: this.id + "_primary"
        });
    }

    private createDropdownButton(): Button {
        const result = new Button({
            caption: "",
            margin: 0,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            paddingRight: 0,
            paddingLeft: 4,
            paddingTop: 10,
            paddingBottom: 10,
            borderWidth: 0,
            borderLeftColor: "strokePrimary",
            borderLeftWidth: 1,
            rowBreak: false,
            imageWidth: MultiButtonPropDefinitions.getDefinitions().dropdownImageSize.defaultValue,
            imageHeight: MultiButtonPropDefinitions.getDefinitions().dropdownImageSize.defaultValue,
            imageName: "arrow",
            maxWidth: 32,
            fillRow: true,
            fillHeight: true,
            id: this.id + "_dropdown"
        });
        result.handleDropdownClick = async () => {
            result["._overlay"] = Overlay.showDropdown(this,
                this.dropdownItems, null,
                { ...result.dropdownListProps },
                result.dropdownOverlayProps)._overlay;
        };
        return result;
    }

    get busyWhenDataSourceBusy(): boolean {
        return this.primaryButton.busyWhenDataSourceBusy == null ? MultiButtonPropDefinitions.getDefinitions().busyWhenDataSourceBusy.defaultValue : this.primaryButton.busyWhenDataSourceBusy;
    }

    set busyWhenDataSourceBusy(value: boolean) {
        this.primaryButton.busyWhenDataSourceBusy = value;
    }

    get busy(): boolean {
        return this.primaryButton.busy != null ? this.primaryButton.busy : false;
    }

    set busy(value: boolean) {
        this.primaryButton.busy = value;
    }

    get _interactionEnabled(): boolean {
        return super._interactionEnabled;
    }

    set _interactionEnabled(value: boolean) {
        super._interactionEnabled = value;
        this.primaryButton._interactionEnabled = value;
        this.dropdownButton._interactionEnabled = value;
    }

    public getEventTarget(): HTMLElement {
        return this.primaryButton._element;
    }

    get dropdownItems(): StringOrPropsOrComponent[] {
        return this.dropdownButton.dropdownItems as StringOrPropsOrComponent[];
    }

    set dropdownItems(value: StringOrPropsOrComponent[]) {
        this.dropdownButton.dropdownItems = value;
        if (value == null || value.length === 0)
            this.remove(this.dropdownButton);
        else if (!this.contains(this.dropdownButton))
            this.add(this.dropdownButton);
    }

    get caption(): string {
        return this.primaryButton.caption;
    }

    set caption(value: string) {
        this.primaryButton.caption = value;
    }

    get hotkey(): string {
        return this.primaryButton.hotkey;
    }

    set hotkey(value: string) {
        this.primaryButton.hotkey = value;
    }

    get id(): string {
        return super.id;
    }

    set id(value: string) {
        super.id = value;
        if (this.primaryButton != null)
            this.primaryButton.id = value + "_primary";
        if (this.dropdownButton != null)
            this.dropdownButton.id = value + "_dropdown";
    }

    get imageName() {
        return this.primaryButton.imageName;
    }

    set imageName(value: string) {
        this.primaryButton.imageName = value;
    }

    get dropdownImageSize(): number {
        return this.dropdownButton.imageWidth;
    }

    set dropdownImageSize(value: number) {
        this.dropdownButton.imageWidth = value;
        this.dropdownButton.imageHeight = value;
    }

    get showSeparator(): boolean {
        return this.dropdownButton.borderLeftWidth > 0;
    }

    set showSeparator(value: boolean) {
        this.dropdownButton.borderLeftWidth = value ? 1 : 0;
    }

    override _applyEnabled(value: boolean): void {
        // if (value === false)
        //   this._hideDropdown(false);
        this.primaryButton.enabled = value;
        if (this.dropdownButton != null)
            this.dropdownButton.enabled = value;
        if (value === false) {
            // this.primaryButton._element.style.borderWidth = "0px";
            // this.primaryButton._element.style.borderTopWidth = "1px";
            // this.primaryButton._element.style.borderLeftWidth = "1px";
            // this.primaryButton._element.style.borderBottomWidth = "1px";
            if (this.dropdownButton != null) {
                // this.dropdownButton._element.style.borderWidth = "0px";
                // this.dropdownButton._element.style.borderTopWidth = "1px";
                // this.dropdownButton._element.style.borderRightWidth = "1px";
                // this.dropdownButton._element.style.borderBottomWidth = "1px";
            }
        }
    }

    public set _designer(value) {
        super._designer = value;
        this.primaryButton.setDesigner(value);
        this.dropdownButton.setDesigner(value);
    }

    _serializeNonProps(): string {
        //we don't want to serialize this component like a normal Panel
        //the primary button is controlled the top-level props, so that rendered button shouldn't be serialized
        //like dropdown actions should not be serialized, those are controlled by props as well
        return "";
    }

    override getPropertyDefaultValue(prop: ComponentPropDefinition): any {
        if (this._defaultProps[prop.name] != null)
            return this._defaultProps[prop.name];
        if (prop.name === "showSeparator")
            return true; //because default borderLeftWidth > 0
        return super.getPropertyDefaultValue(prop);
    }

    public isNestedDesignerContainer(): boolean {
        return true;
    }

    public override discoverIncludedComponents(): Component[] {
        //return null here so that the components that make up the multi button aren't included
        return null;
    }
}
