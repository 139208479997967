import { CommonDialogs } from "@mcleod/common";
import {
    DataSourceMode, Layout, Panel, SaveButton, SlideoutDecorator, Step, StepEvent, Textbox
} from "@mcleod/components";
import { Api, ModelRow, ModelRowType, RowUpdateEvent } from "@mcleod/core";
import { MovementPay } from "./MovementPay";
import { SendRateConfirmation } from "./SendRateConfirmation";
import { AutogenLayoutModifyCarrierPay } from "./autogen/AutogenLayoutModifyCarrierPay";

export class ModifyCarrierPay extends AutogenLayoutModifyCarrierPay {

    public layoutMovePay: MovementPay;
    public layoutSendRateCon: SendRateConfirmation;
    private movementId: string;
    private movementSaved = false;

    private saveButton = new SaveButton({
        id: "saveCloseButton", width: 160,
        enabled: false, rowBreak: false, marginTop: 2,
    });
    private panelButtons = new Panel({ padding: 0, components: [this.saveButton], rowBreak: false });
    private changingStep = false;

    advanceStep() {
        this.changingStep = true;
        this.stepper.selectedIndex++;
        this.changingStep = false;
    }

    stepperBeforeStepListener(event: StepEvent) {
        if (!this.changingStep && event.newIndex > event.oldIndex) {
            event.preventDefault();
        } else {
            this.panelButtons.removeAll();
            if (event.newIndex == 0) {
                this.panelButtons.add(this.saveButton);
            } else {
                this.panelButtons.add(this.layoutSendRateCon.buttonSend);
            }
        }
    }

    setupStepOneLayout(isCarrierEdi: boolean): Promise<any> {
        return this.setupStepLayout("lme/powerbroker/MovementPay", { id: this.movementId }, 0).then(layout => {
            this.layoutMovePay = layout as MovementPay;
            this.saveButton.dataSource = this.layoutMovePay.mainDataSource;
            this.layoutMovePay.setAllDataSourcesToMode(DataSourceMode.UPDATE);
            this.layoutMovePay.labelRates.visible = false;
            this.layoutMovePay.panelFooter.borderBottomWidth = 0;
            this.layoutMovePay.activeRow.addBeforePostListener(event => this.beforeMovePost(event));
            this.layoutMovePay.mainDataSource.addAfterExecutionListener(() => {
                this.movementSaved = true;
                if (!isCarrierEdi) {
                    this.setupStepTwoLayout().then(() => this.advanceStep())
                } else {
                    this.slideOut();
                }
            });
        })
    }

    async beforeMovePost(event: RowUpdateEvent): Promise<any> {
        await this.layoutMovePay.calculatePay(false, false, true).then(() => {
            if (this.layoutMovePay.payError != null)
                event.preventDefault("invalid data after equipment match applied", () => {
                    CommonDialogs.showMessage(this.layoutMovePay.createPayErrorPanel(), "Unable to Save Movement").then(() => {
                        this.layoutMovePay.textboxOverridePayRate.focus();
                        this.layoutMovePay.textboxOverridePayRate.selectText();
                        this.layoutMovePay.activeRow["linkedModels"] = null;
                    })
                });
        });
        return Promise.resolve(this.layoutMovePay);
    }

    setupStepTwoLayout(): Promise<any> {
        if (this.layoutSendRateCon == null) {
            return this.setupStepLayout("lme/powerbroker/SendRateConfirmation", { movement_id: this.movementId }, 1).then(layout => {
                this.layoutSendRateCon = layout as SendRateConfirmation;
                this.layoutSendRateCon.panelHeader.visible = false;
                this.layoutSendRateCon.doAfterRateConSent = () => {
                    this.slideOut();
                }
            })
        }
        return Promise.resolve(this.layoutSendRateCon);
    }

    setupStepLayout(path: string, searchFilter: any, step: number): Promise<Layout> {
        return new Promise((resolve) => {
            const layout = Layout.getLayout(path, { marginTop: 20 });
            layout.addLayoutLoadListener(() => {
                layout.mainDataSource.search(searchFilter).then(() => {
                    (this.stepper.components[step] as Step).add(layout);
                    resolve(layout)
                })
            })
        })
    }

    public static showSlideout(movementId: string, doAfterSlideOut: (movementSaved: boolean, moveRow?: ModelRow) => void, tonu_earning_code?: String, carrierId?: String, isCarrierEdi?: boolean): Promise<any> {
        const modifyCarrierPay = Layout.getLayout("lme/powerbroker/ModifyCarrierPay", {
            maxWidth: 1150, backgroundColor: "defaultBackground",
            borderRadius: 4, borderWidth: 1, borderShadow: true, borderColor: "strokeSecondary",
        }) as ModifyCarrierPay;

        return new Promise((resolve) => {
            modifyCarrierPay.addLayoutLoadListener(() => {
                modifyCarrierPay.movementId = movementId;
                if (isCarrierEdi) {
                    modifyCarrierPay.stepper.removeStep(modifyCarrierPay.step2);
                }
                modifyCarrierPay.setupStepOneLayout(isCarrierEdi).then(() => {
                    new SlideoutDecorator({
                        layout: modifyCarrierPay,
                        title: "Modify Carrier Pay",
                        fillVerticalSpace: true,
                        addlComponents: [modifyCarrierPay.panelButtons],
                        doAfterSlideIn: async () => {
                            const layoutMovePay = modifyCarrierPay.layoutMovePay;
                            layoutMovePay.updateOtherPayTabCaption();
                            await layoutMovePay.tabOtherPay.expand();
                            layoutMovePay.tabOtherPay.expanded = true;
                            const textboxDeductCodeId = layoutMovePay.tableExtraPay.quickAddRow.findComponentById("textboxDeductCodeId") as Textbox;
                            const textboxOtherPayPayeeId = layoutMovePay.tableExtraPay.quickAddRow.findComponentById("textboxOtherPayPayeeId") as Textbox;
                            const textboxUnits = layoutMovePay.tableExtraPay.quickAddRow.findComponentById("textboxUnits") as Textbox;
                            const quickAddData = layoutMovePay.tableExtraPay.quickAddData as ModelRow;
                            quickAddData.set("payee_id", carrierId);
                            quickAddData.set("_lookup_payee_id", { id: carrierId });
                            textboxOtherPayPayeeId.displayData(quickAddData, null, 0);
                            if (tonu_earning_code) {
                                for (let x = layoutMovePay.sourceExtraPay.data.length - 1; x > -1; x--) {
                                    if (layoutMovePay.sourceExtraPay.data[x].get("deduct_code_id") != tonu_earning_code)
                                        layoutMovePay.sourceExtraPay.deleteTableRow(x, true);
                                }
                                const modelRow = layoutMovePay.activeRow as ModelRow;
                                const override_pay_amt = layoutMovePay.activeRow.get("override_pay_amt");
                                if (override_pay_amt !== undefined) {
                                    override_pay_amt.amount = 0;
                                    modelRow.set("override_pay_amt", override_pay_amt);
                                }
                                modelRow.set("override_pay_amt_n", 0);
                                modelRow.set("override_type", "F");
                                modelRow.set("override_pay_rate", 0);
                                Api.search(textboxDeductCodeId.lookupModel, { id: tonu_earning_code }).then(response => {
                                    if (response?.data[0] != null) {
                                        const lmModelRow = new ModelRow(textboxDeductCodeId.lookupModel, false, { id: response.data[0].id, short_desc: response.data[0].descr });
                                        lmModelRow.type = ModelRowType.LOOKUP_MODEL_DATA;
                                        quickAddData.setLookupModelData("deduct_code_id", lmModelRow);
                                        quickAddData.set("deduct_code_id", response.data[0].id);
                                        quickAddData.set("units", "1");
                                        textboxDeductCodeId.displayData(quickAddData, null, 0);
                                        textboxUnits.displayData(quickAddData, null, 0);
                                        layoutMovePay.otherPayTextboxChanged(textboxUnits)
                                    }
                                });
                            }
                            layoutMovePay.calculatePay(false)
                        },
                        doAfterSlideOut: () => {
                            if (doAfterSlideOut)
                                doAfterSlideOut(modifyCarrierPay.movementSaved, modifyCarrierPay.layoutMovePay.activeRow);
                        }
                    });
                }).finally(() => resolve(true))
            });
        })
    }
}
