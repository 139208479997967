import { Alignment, DynamicLoader, HorizontalAlignment, Keys, ModelRow, Navigation, NavOptions } from "@mcleod/core";
import { Button, ButtonVariant, Component, DataSourceMode, Label, Layout, List, Overlay, PanelProps, StringOrPropsOrComponent } from "../..";
import { ReflectiveDialogs } from "../../base/ReflectiveDialogs";
import { SelectionMode } from "../../base/SelectionMode";
import { ClickEvent } from "../../events/ClickEvent";
import { CrudDecorator } from "../../page/decorators/CrudDecorator";
import { CrudDecoratorProps } from "../../page/decorators/CrudDecoratorProps";
import { getComponentFromStringOrPropsOrComponent } from "../../page/getComponentFromStringOrPropsOrComponent";
import { ButtonProps } from "../button/ButtonProps";
import { Panel } from "../panel/Panel";
import { PinnedSearch } from "./PinnedSearch";
import { Table } from "./Table";
import { ExportType, TableExport } from "./TableExport";

export enum DetailMode {
    SAME_TAB,
    NEW_TAB_NO_SWITCH,
    NEW_TAB_WITH_SWITCH,
    NEW_WINDOW
}

const basicDropdownProps: Partial<ButtonProps> = {
    borderWidth: 0,
    borderRadius: 0,
    marginLeft: 0,
    paddingRight: 12,
    width: "100%",
    align: HorizontalAlignment.LEFT,
    fontSize: "medium"
};

export class TableToolsPanel extends Panel {
    private table: Table;
    private _addCaption: string;
    private _allowAdd: boolean;
    private _allowConfig: boolean;
    private _allowDelete: boolean;
    private _allowDetail: boolean;
    private _allowEdit: boolean;
    private _allowExport: boolean;
    private _allowPin: boolean;
    private _allowAdvancedSearch: boolean;
    private _allowShare: boolean;
    private stdLeftTools: Panel;
    private stdRightTools: Panel;
    private postStdTools: Panel;
    leftTools: Panel;
    rightTools: Panel;
    private toolSpacer: Panel;
    private buttonAdvancedSearch: Button;
    private buttonDelete: Button;
    private buttonAdd: Button;
    private buttonShare: Button;
    private buttonDefaultDetail: Button;
    private buttonDetailDropdown: Button;
    private buttonEllipsis: Button;
    private crudPanel: Panel;
    public determineDetailLayout: () => string;


    constructor(table: Table, props?: Partial<PanelProps>) {
        super({ padding: 0, fillRow: true, ...props });
        this.table = table;
        this.stdLeftTools = new Panel({ id: "stdLeftTools", rowBreak: false });
        this.leftTools = new Panel({ id: "leftTools", rowBreak: false, rowBreakDefault: false, _designer: table._designer });
        this.postStdTools = new Panel({ id: "postStdTools", rowBreak: false });
        this.toolSpacer = new Panel({ id: "toolSpacer", rowBreak: false, fillRow: true });
        this.rightTools = new Panel({ id: "rightTools", _designer: table._designer, rowBreak: false });
        this.stdRightTools = new Panel({ id: "stdRightTools" });
        this.createButtons();
        this.add(this.stdLeftTools, this.leftTools, this.postStdTools, this.toolSpacer, this.rightTools, this.stdRightTools);
        this.addMountListener(() => this.syncAddCaption());
    }

    addTool(tool: StringOrPropsOrComponent, addToLeftSection: boolean = true) {
        const target = addToLeftSection ? this.leftTools : this.rightTools;
        return target.add(getComponentFromStringOrPropsOrComponent(tool));
    }

    removeTool(...tools: Component[]) {
        if (tools != null)
            for (const tool of tools) {
                this.leftTools.remove(tool);
                this.rightTools.remove(tool);
            }
    }

    private createButtons() {
        const buttonProps = { rowBreak: false, color: "primary", imageWidth: 20, imageHeight: 20 };
        this.buttonAdvancedSearch = new Button({
            ...buttonProps,
            variant: ButtonVariant.round,
            imageName: "magnifyingGlassPage",
            hotkey: "Alt-S",
            onClick: () => this.showSearch(),
            tooltip: "Show advanced search options (Alt-S)",
        });
        this.buttonAdvancedSearch.addHandlerForKey(Keys.S, { altKey: true });
        this.buttonDelete = new Button({
            ...buttonProps,
            variant: ButtonVariant.round,
            enabled: false,
            imageName: "delete",
            onClick: () => this.handleDelete(),
            tooltip: "Delete the selected rows"
        });
        this.buttonShare = new Button({
            ...buttonProps,
            variant: ButtonVariant.round,
            enabled: false,
            imageName: "share",
            onClick: () => this.showShare(),
            tooltip: "Share the selected row"
        });
        this.buttonDefaultDetail = new Button({
            ...buttonProps,
            enabled: false,
            hotkey: "Alt-D",
            padding: 4,
            borderWidth: 0,
            margin: 0,
            marginRight: 0,
            paddingRight: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            onClick: () => this.showDefaultDetail(),
            ...this.getDetailButtonProps(this.getDefaultDetailMode())
        });
        this.buttonDetailDropdown = new Button({
            ...buttonProps,
            enabled: false,
            imageName: "arrow",
            imageWidth: 12,
            imageHeight: 12,
            padding: 8,
            borderWidth: 0,
            margin: 0,
            paddingLeft: 0,
            marginLeft: 0,
            paddingRight: 2,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            dropdownProps: { align: Alignment.RIGHT, width: 208 },
            dropdownListProps: { selectionMode: SelectionMode.NONE },
            dropdownItems: () => this.getDetailDropdownItems(),
            tooltip: "Show other options for drilling down to the selected row in this table"
        });
        this.buttonEllipsis = new Button({
            ...buttonProps,
            enabled: true,
            variant: ButtonVariant.round,
            imageName: "ellipsis",
            tooltip: "Show more options",
            dropdownListProps: { maxHeight: 800 },
            dropdownItems: () => this.getDropdownOptions()
        });
        this.buttonAdd = new Button({
            id: "buttonAdd",
            caption: "Add",
            imageName: "add",
            color: "primary",
            hotkey: "Alt-A",
            onClick: () => this.showAdd(),
            tooltip: "Add a new record",
        });
    }

    public get allowEdit(): boolean {
        return this._allowEdit;
    }

    public set allowEdit(value: boolean) {
        this._allowEdit = value;
        this.syncTools();
    }

    public get allowDetail(): boolean {
        return this._allowDetail;
    }

    public set allowDetail(value: boolean) {
        if (value !== true && this.table.rowProps != null)
            delete this.table.rowProps.onDblClick;
        this._allowDetail = value;
        if (value === true) {
            if (this.table.rowProps == null)
                this.table.rowProps = {};
            this.table.rowProps.onDblClick = (event: ClickEvent) => {
                if (event.hasModifiers({ ctrlKey: true, shiftKey: true }))
                    this.showDetail(DetailMode.NEW_TAB_WITH_SWITCH);
                else if (event.hasModifiers({ shiftKey: true }))
                    this.showDetail(DetailMode.NEW_WINDOW);
                else if (event.hasModifiers({ ctrlKey: true }))
                    this.showDetail(DetailMode.NEW_TAB_NO_SWITCH);
                else
                    this.showDetail(DetailMode.SAME_TAB);
            };
        }
        this.syncTools();
    }

    public get allowShare(): boolean {
        return this._allowShare;
    }

    public set allowShare(value: boolean) {
        this._allowShare = value;
        this.syncTools();
    }

    public get allowExport(): boolean {
        return this._allowExport;
    }

    public set allowExport(value: boolean) {
        this._allowExport = value;
        this.syncTools();
    }

    public get allowAdd(): boolean {
        return this._allowAdd;
    }

    public set allowAdd(value: boolean) {
        this._allowAdd = value;
        this.syncTools();
    }

    public get addCaption(): string {
        return this._addCaption == null ? "Add" + this.getLayoutTitleSuffix() : this._addCaption;
    }

    public set addCaption(value: string) {
        this._addCaption = value;
        this.syncAddCaption();
    }

    public get allowAdvancedSearch(): boolean {
        return this._allowAdvancedSearch;
    }

    public set allowAdvancedSearch(value: boolean) {
        this._allowAdvancedSearch = value;
        this.syncTools();
    }

    public get allowDelete(): boolean {
        return this._allowDelete;
    }

    public set allowDelete(value: boolean) {
        this._allowDelete = value;
        this.syncTools();
    }

    public get allowPin(): boolean {
        return this._allowPin;
    }

    public set allowPin(value: boolean) {
        this._allowPin = value;
        this.syncTools();
    }

    public get allowConfig(): boolean {
        return this._allowConfig;
    }

    public set allowConfig(value: boolean) {
        this._allowConfig = value;
        this.syncTools();
    }

    private getDefaultDetailMode(): DetailMode {
        return DetailMode.SAME_TAB;
    }

    private getDetailButtonProps(mode: DetailMode): Partial<ButtonProps> {
        switch (mode) {
            case DetailMode.NEW_TAB_NO_SWITCH: // when clicking from the toolbar, there doesn't seem like a reason to not switch to it
            case DetailMode.NEW_TAB_WITH_SWITCH: return { imageName: "tab", tooltip: "Drill down to the selected row in a new browser tab" };
            case DetailMode.NEW_WINDOW: return { imageName: "overlappingWindows", tooltip: "Drill down to the selected row in a new browser window" };
            default: return { imageName: "detail", tooltip: "Drill down to the selected row in this table in this browser tab" };
        }
    }

    private getDetailDropdownItems(): Button[] {
        const result: Button[] = [];
        this.addDropdownItem(result, DetailMode.SAME_TAB, "Drill down in this tab");
        this.addDropdownItem(result, DetailMode.NEW_TAB_WITH_SWITCH, "Drill down in new tab");
        this.addDropdownItem(result, DetailMode.NEW_WINDOW, "Drill down in new window");
        return result;
    }

    private addDropdownItem(array: Button[], mode: DetailMode, caption: string) {
        const defaultMode = this.getDefaultDetailMode();
        if (mode != defaultMode)
            array.push(new Button({
                ...basicDropdownProps,
                caption: caption,
                ...this.getDetailButtonProps(mode),
                onClick: () => this.showDetail(mode)
            }))
    }

    public syncTools() {
        // ideally, we want to execute this only once during initialization of the Table from a .layout
        this.stdLeftTools.removeAll();
        this.stdRightTools.removeAll();
        if (this.allowAdvancedSearch)
            this.stdLeftTools.add(this.buttonAdvancedSearch);
        if (this.allowDelete)
            this.stdLeftTools.add(this.buttonDelete);
        if (this.allowShare)
            this.stdLeftTools.add(this.buttonShare);
        if (this.allowDetail) {
            this.stdLeftTools.add(this.buttonDefaultDetail);
            this.stdLeftTools.add(this.buttonDetailDropdown);
        }
        if (this.allowConfig || this.allowPin || this.allowExport)
            this.postStdTools.add(this.buttonEllipsis);

        if (this.allowAdd)
            this.stdRightTools.add(this.buttonAdd);
    }

    public showConfig() {

    }

    private closeCrud() {
        this.crudPanel.slideOut();
    }

    public showShare() {

    }

    public async showAdd() {
        const url = "/" + this.table.addLayout + "?mode=add";
        if (await Navigation.pseudoNavigateTo(url, () => this.closeCrud())) {
            this.showCrud(DataSourceMode.ADD, null, (addedRow: ModelRow) => {
                this.table.dataSource.addRow(addedRow, 0);
            });
        }
    }

    private getPopoutNavOptions(): Partial<NavOptions> {
        const windowSize = {
            width: 1400,
            height: 700,
        };
        const loc = {
            left: ((window.screen as any).availLeft + (window.screen.availWidth / 2)) - (windowSize.width / 2),
            top: ((window.screen as any).availTop + (window.screen.availHeight / 2)) - (windowSize.height / 2)
        };
        return { left: loc.left, top: loc.top, height: 700, width: 1400, newTab: true, windowDecorators: false };
    }

    public showDefaultDetail(): void {
        this.showDetail(DetailMode.SAME_TAB);
    }

    public async showDetail(detailMode: DetailMode) {
        const row = this.table?.selectedRow?.data as ModelRow;
        if (row == null)
            return;
        const keyData = row.getKeyData();
        const keys = Object.keys(keyData);
        const key = keyData[keys[0]] as string;
        this.table.detailLayout = this.determineDetailLayout != undefined ? this.determineDetailLayout() : this.table.detailLayout
        const url = "/" + this.table.detailLayout + "?mode=update&key=" + key;
        if (detailMode === DetailMode.SAME_TAB) {
            if (await Navigation.pseudoNavigateTo(url, () => this.closeCrud())) {
                this.showCrud(DataSourceMode.UPDATE, { key: key }, async (updatedRow: ModelRow) => {
                    this.table.resolveRowEdit(this.table.selectedRow.data, updatedRow);
                });
            }
        }
        else if (detailMode === DetailMode.NEW_WINDOW)
            Navigation.navigateTo(url, this.getPopoutNavOptions());
        else if (detailMode === DetailMode.NEW_TAB_NO_SWITCH) {
            Navigation.navigateTo(url, { newTab: true });
            window.focus();
        }
        else
            Navigation.navigateTo(url, { newTab: true });
    }

    public showSearch() {
        this.showCrud(DataSourceMode.SEARCH, null, (filterValues: ModelRow) => this.table.dataSource.search(filterValues));
    }


    public showCrud(mode: DataSourceMode, crudDecoratorProps: Partial<CrudDecoratorProps>, onExecute: (row: ModelRow) => void) {
        let layout: Layout;
        switch (mode) {
            case DataSourceMode.ADD:
                layout = Layout.getLayout(this.table.addLayout);
                break;
            case DataSourceMode.UPDATE:
                layout = Layout.getLayout(this.table.detailLayout);
                break;
            case DataSourceMode.SEARCH:
                layout = Layout.getLayout(this.table.searchLayout);
                break;
            default:
                layout = Layout.getLayout(this.table.generalLayout);
                break;
        }
        this.crudPanel = new CrudDecorator({
            layout: layout,
            mode: mode,
            headerProps: {
                showClose: true,
                showSaveAndClose: true,
                onClose: () => {
                    if (mode === DataSourceMode.SEARCH)
                        this.closeCrud();
                    else
                        Navigation.navigateBack();
                },
                onSaveButtonClose: () => Navigation.navigateBack(),
                onExecute: onExecute
            },
            ...crudDecoratorProps
        });
        this.crudPanel.slideIn({ speed: 200 });
    }

    selectionChanged() {
        const sel = this.table.selectedRows.length === 1;
        this.buttonDelete.enabled = sel;
        this.buttonDefaultDetail.enabled = sel;
        this.buttonDetailDropdown.enabled = sel;
        this.buttonShare.enabled = sel;
    }

    async getDropdownOptions(): Promise<StringOrPropsOrComponent[]> {
        const result = [];
        if (this.allowPin) {
            const pinItems = await PinnedSearch.getDropdownComponents(DynamicLoader.getRouteFromURL(), this.table.dataSource);
            result.push(...pinItems);
        }
        if (result.length > 0 && this.allowConfig)
            result.push(List.SEPARATOR);
        if (this.allowConfig) {
            const editCapiton = Object.keys(this.table.possibleConfigs).length == 0 ? "Add list configuration" : "Edit this list configuration";
            const labelEdit = new Label({ caption: editCapiton, imageName: "settings", ...basicDropdownProps });
            labelEdit.addClickListener(() => {
                this.buttonEllipsis.hideDropdown();
                this.showColumnConfig();
            });
            result.push(labelEdit);
            for (const key in this.table.possibleConfigs)
                if (this.table.configName !== key) {
                    const labelUseConfig = new Label({ caption: "Use configuration: " + key.replace("_", " "), imageName: "designer/list", ...basicDropdownProps });
                    labelUseConfig.addClickListener(() => {
                        this.table.configDefName = key;
                        this.buttonEllipsis.hideDropdown();
                    });
                    result.push(labelUseConfig);
                }
        }
        if (this.allowExport) {
            const panel = new Panel({ fillRow: true, padding: 0 })
            const labelExport = new Label({ caption: "Export listing", fillRow: true, rowBreak: false, imageName: "download", ...basicDropdownProps });
            const labelChevron = new Label({ imageName: "chevron", imageRotation: 270 });
            panel.add(labelExport, labelChevron);
            panel.addClickListener((event) => {
                event.stopImmediatePropagation();
                this.showExportDropdown(panel)
            });
            result.push(panel);
        }
        return result;
    }

    private showExportDropdown(anchor: Component) {
        const labelExcel = new Label({ caption: "Export as Excel", imageName: "exportExcel", ...basicDropdownProps });
        const labelCSV = new Label({ caption: "Export as CSV", imageName: "exportCSV", ...basicDropdownProps });
        labelExcel.addClickListener(() => {
            this.buttonEllipsis.hideDropdown();
            this.export(ExportType.Excel);
        });
        labelCSV.addClickListener(() => {
            this.buttonEllipsis.hideDropdown();
            this.export(ExportType.CSV);
        });
        Overlay.showDropdown(anchor, [labelExcel, labelCSV], null, { height: null },
            { position: Alignment.RIGHT });
    }

    export(exportType: ExportType) {
        new TableExport(this.table).exportTable(true, exportType);
    }

    showColumnConfig() {
        const defNameProp = this.table.possibleConfigs[this.table.configDefName] ? "&defName=" + this.table.configDefName : "";
        Navigation.navigateTo("common/ListDefinitionEditor?path=" + this.table.configName + defNameProp);
    }

    private syncAddCaption() {
        this.buttonAdd.caption = this.addCaption;
    }

    private getLayoutTitleSuffix(): string {
        let parent = this.parent;
        while (parent != null && !(parent instanceof Layout))
            parent = parent.parent;
        const title = (parent as Layout)?.titleSingular?.toString();
        return title == null ? "" : " " + title;
    }

    async handleDelete() {
        const sel = this.table.selectedRows;
        if (sel.length === 0) {
            ReflectiveDialogs.showMessage("No records are selected to delete.");
            return;
        }
        const prompt = "Are you sure you want to delete the selected " + (sel.length === 1 ? "record" : "records") + "?";
        if (await ReflectiveDialogs.showYesNo(prompt, "Confirm Delete")) {
            for (let i = sel.length - 1; i >= 0; i--) {
                await sel[i].data.delete();
                this.table.removeRow(sel[i].index);
            }
            ReflectiveDialogs.showSnackbar("Selected " + (sel.length === 1 ? "record has" : "records have") + " been deleted.");
        }
    }
}
