import { Button, DataSource, Layout, Panel, Textbox } from "@mcleod/components";
import { TargetPay } from "../../../dispatch/src/TargetPay";

export abstract class AutogenLayoutMovementCarrierPay extends Layout {
    buttonOverrideMaxPay: Button;
    buttonOverrideTargetPay: Button;
    layoutTargetPay: TargetPay;
    panelCarrierPayCalc: Panel;
    panelMaxAmt: Panel;
    panelMaxPay: Panel;
    panelOrderCharges: Panel;
    panelTargetPay: Panel;
    sourceMovement: DataSource;
    textboxFreightCharge: Textbox;
    textboxMaxPayCalc: Textbox;
    textboxMaxPayMargin: Textbox;
    textboxMaxPayMarginAmt: Textbox;
    textboxTargetMargin: Textbox;
    textboxTargetMarginAmt: Textbox;
    textboxTotalCharge: Textbox;
    textboxTotalOtherCharges: Textbox;
}
