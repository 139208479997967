import MarkerClusterer from "@googlemaps/markerclustererplus";
import { getAuthSettings, getLogger, getMapSettings, getUnauthSettings } from "@mcleod/core";
import { getSVGUrl } from "@mcleod/images";
import { Panel } from "../panel/Panel";
import { Map, VendorMap } from "./Map";
import { MapPin } from "./MapPin";
import { MapVendor, VendorMapProps } from "./MapProps";

const log = getLogger("components/Map");

interface google {
    maps: typeof window.google.maps,
}

export enum RouteType {
    Google,
    Trimble
}

const GoogleMapTypeRoadMap = 'RoadMap';
const GoogleMapTypeSatellite = 'Satellite';
const GoogleMapTypeTerrain = 'Terrain';
const GoogleMapTypeHybrid = 'Hybrid';

export class GoogleMap extends Panel implements VendorMap {
    map: google.maps.Map;
    markerClusterer: MarkerClusterer;
    markers: google.maps.Marker[];
    parentMap: Map;
    directionsService: google.maps.DirectionsService;
    directionsRenderer: google.maps.DirectionsRenderer;
    trimblePath: google.maps.Polyline;

    private _userSelectionMapType = getAuthSettings()?.user_settings?.google_map_type;
    private _userSelectionMapLayer = getAuthSettings()?.user_settings?.google_map_layer;

    constructor(props?: Partial<VendorMapProps>) {
        super({ borderRadius: 4, ...props });
        if (props.parentMap == null)
            throw new Error("GoogleMap needs to have its parentMap property set.");
        this.markers = [];
        this.parentMap.vendor = MapVendor.GOOGLE;
    }

    async init() {
        if (window.google == null)
            await this.initHeader();
        else
            this.initMap();
    }

    addPin(pin: MapPin): void {
        if (this.map == null)
            return;
        const image = pin.image == null ? undefined : {
            icon: {
                url: getSVGUrl(pin.image),
                labelOrigin: new google.maps.Point(12, 12)
            }
        };
        const marker = new window.google.maps.Marker({
            ...image,
            position: new window.google.maps.LatLng(pin.latitude, pin.longitude),
            label: {
                text: pin.caption,
                color: pin.captionColor,
                fontSize: "12px"
            },
            title: pin.tooltip
        });

        this.markers.push(marker);
        if (this.parentMap.pinNeedsClickListener(pin))
            marker.addListener("click", (event) => this.parentMap.pinClicked(pin, event.domEvent));
        if (pin.clusterable === true)
            this.markerClusterer.addMarker(marker);
        else
            marker.setMap(this.map);
    }

    removeAllPins() {
        this.markerClusterer?.removeMarkers(this.markers);
        for (const marker of this.markers)
            marker.setMap(null);
        this.markers = [];
    }

    private initHeader(): Promise<void> {
        const url = getUnauthSettings().company_settings.google_maps_url;
        const apiKey = getUnauthSettings().company_settings.google_api_key;
        const script = document.createElement("script");
        script.src = url + apiKey;
        window.document.body.appendChild(script);
        return new Promise((resolve, reject) => {
            script.onload = () => {
                this.initMap();
                log.debug("Map initialized successfully");
                resolve();
            };
            script.onerror = () => {
                log.debug("An error occurred during map initialization");
                reject();
            };
        });
    }

    private getGestureHandling() {
        if (getMapSettings()?.google_greedy)
            return "greedy";
        else
            return "cooperative";
    }

    private setMapType() {
        const userSelectionMapType = this._userSelectionMapType;
        let mapType = "";
        if (userSelectionMapType === GoogleMapTypeRoadMap)
            mapType = "roadmap";
        else if (userSelectionMapType == GoogleMapTypeSatellite)
            mapType = "satellite";
        else if (userSelectionMapType == GoogleMapTypeTerrain)
            mapType = "terrain";
        else if (userSelectionMapType == GoogleMapTypeHybrid)
            mapType = "hybrid";
        else
            mapType = "roadmap";
        return mapType;
    }

    private initMap() {
        const userSelectionMapType = this.setMapType();

        this.map = new window.google.maps.Map(this._element, {
            disableDefaultUI: false,
            gestureHandling: this.getGestureHandling(),
            center: new window.google.maps.LatLng(39.8283, -98.5795),
            zoom: 5,
            mapTypeId: userSelectionMapType
        });

        this.markerClusterer = new MarkerClusterer(this.map, [], {
            styles: [
                {
                    textColor: "white",
                    anchorText: [18, 0],
                    fontWeight: "600",
                    url: getSVGUrl("map-pin-cluster"),
                    height: 48,
                    width: 48
                }
            ],

            maxZoom: 12,
            gridSize: 20
        });
        const mapLayer = this._userSelectionMapLayer;
        if ("Y" === mapLayer) {
            const trafficLayer = new google.maps.TrafficLayer();
            trafficLayer.setMap(this.map);
        }
        this.directionsService = new google.maps.DirectionsService();
        this.directionsRenderer = new google.maps.DirectionsRenderer();
    }

    fitPins() {
        if (this.map == null)
            return;
        if (this.markers.length === 1)
            this.map.setCenter(this.markers[0].getPosition());
        else if (this.markers.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            for (const marker of this.markers)
                bounds.extend(marker.getPosition());
            this.map.fitBounds(bounds);
        }
        if (this.map.getZoom() > 10)
            this.map.setZoom(10);
    }

    setZoom(level: number) {
        this.map.setZoom(level);
    }

    setStopZoomLevel: (isTrafficEnabled: string, routeData: any[]) => void;

    createRoute(routeData: any[], routeType: RouteType) {
        if (routeType == RouteType.Google) {
            const data: google.maps.LatLngLiteral[] = this.formatGoogleRouteData(routeData);
            this.directionsService
                .route({
                    origin: data[0]["location"],
                    destination: data[data.length - 1]["location"],
                    waypoints: data.slice(1, -1) as google.maps.DirectionsWaypoint[],
                    travelMode: google.maps.TravelMode.DRIVING
                })
                .then((response) => {
                    this.directionsRenderer.setDirections(response);
                    this.directionsRenderer.setMap(this.map);
                });
        }
        if (routeType == RouteType.Trimble) {
            if (this.trimblePath) {
                this.trimblePath.setMap(null);
            }
            this.trimblePath = new google.maps.Polyline({
                path: routeData,
                geodesic: true,
                strokeColor: "#FF0000",
                strokeOpacity: 1.0,
                strokeWeight: 4,
            });
            this.trimblePath.setMap(this.map);
        }
    }

    clearRoute() {
        this.directionsRenderer.setMap(null);
        if (this.trimblePath) {
            this.trimblePath.setMap(null);
        }
    }

    private formatGoogleRouteData(routeData: any[]): google.maps.LatLngLiteral[] {
        const coordinatesList = [];
        routeData.forEach(stop => {
            const lat = Number(stop.latitude);
            const lng = Number(Math.abs(stop.longitude) * -1);
            coordinatesList.push({ "location": { lat, lng } });
        });
        return coordinatesList;
    }

}
