import { getLogger } from "./Logger";
import { ServerError } from "./ServerError";

export type ErrorHandlerFunction = (error: ErrorParam) => void;
export type ErrorHandler = string | boolean | ErrorHandlerFunction;
export type ErrorParam = string | Error | ServerError;

let defaultErrorHandler: ErrorHandlerFunction;

const log = getLogger("core.ErrorHandler");

export interface ErrorMessage {
    message: string;
    displayed: boolean;
}

/**
 * This function is used to inject a dependency for the default handler for errors.
 *
 * We want to be able to trigger the default error handler for errors from the
 * @mcleod/core level.  The most common thing to do would be to display an error dialog
 * when an otherwise unhandled error occurs.  Error dialogs are introduced at the
 * @mcleod/components level, but that's not accessible from @mcleod/core.  So
 * something at a higher level can inject the @mcleod/components error handler
 * into @mcleod/core using this function.
 *
 * @param value
 */
export function setDefaultErrorHandler(value: ErrorHandlerFunction) {
    defaultErrorHandler = value;
    window.onerror = (message, source, lineno, colno, error) => {
        if (shouldProcessError(message, error))
            value(error || message as string);
        else
            log.info(message);
    };
    window.onunhandledrejection = (event: PromiseRejectionEvent) => {
        value(event.reason);
    }
}

function shouldProcessError(message: string | Event, error: Error) {
    if (message === "ResizeObserver loop limit exceeded") // At least one extension (LastPass) throws this error with our code.  We need to ignore this error.
        return false;
    // presumably there will be other errors we will want to swallow in the future
    return true;
}

/**
 * Call this function to handle with some options around how the error should be handled.
 *
 * @param error The error to be handled.  A string, Error, or ServerError object.
 * @param handler An error handler instance that can be a boolean, string, or function.
 * If handler is true or omitted, the default error handler that has been registered
 * with setDefaultErrorHandler() will be used.
 *
 * If handler it false, the error will be silently discarded.
 *
 * If handler is a string, this will be passed to the default error handler (and
 * typically displayed in an error dialog).
 *
 * If handler is a function, that function will be called and error will be passed to it.
 */
export function handleError(error: ErrorParam, handler: ErrorHandler = true): void {
    if (typeof handler === "function")
        handler(error);
    else {
        if (typeof handler === "string")
            error = handler;
        if (handler !== false) {
            if (defaultErrorHandler == null)
                log.error(error);
            else
                defaultErrorHandler(error);
        }
    }
}
