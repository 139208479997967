import { Button, DataSource, Label, Layout, Panel, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutCarrierOfferQuickInfo extends Layout {
    buttonETU: Button;
    labelContactName: Label;
    labelContactNameValue: Label;
    panelContactName: Panel;
    panelContainer: Panel;
    sourceCarrierOfferHistory: DataSource;
    textboxAmount: Textbox;
    textboxEmail: Textbox;
    textboxOfferDate: Textbox;
}
