import { Image, Label, Layout, Panel, Toast } from "@mcleod/components";
import { getLogger } from "@mcleod/core";

const log = getLogger("component-demo/dialogs/DemoToastDialog");

export class DemoToastDialog extends Layout {
    getDemoPath() {
        return ["Dialogs", "Toast"];
    }

    buttonShowToastOnClicked() {
        Toast.showToast("This is a basic toast created by providing only a string.");
    }

    buttonShowSuccessToastTitleOnClicked() {
        Toast.showSuccessToast("This is a basic success toast created by providing only a title.");
    }

    buttonShowSuccessToastOnClicked() {
        Toast.showSuccessToast("Success!", "This is a basic success toast created by providing a title, a message, and an image name.", "magnifyingGlass");
    }

    buttonShowCustomToastOnClicked() {
        const p = new Panel({ backgroundColor: "success" });
        p.add(new Image({ color: "#fff", name: "magnifyingGlass", height: 22, width: 22, rowBreak: false }));
        p.add(new Label({ color: "#fff", caption: "This is a custom toast built in code." }));
        Toast.showToast(p, null, { persist: true });
    }

    buttonShowSuccessToastUserDismissedOnClicked() {
        Toast.showSuccessToast("Success!", "This is a basic success toast that must be dismissed by the user.", "magnifyingGlass", { persist: true });
    }

    buttonShowSuccessToastAutoDismissedOnClicked() {
        Toast.showSuccessToast("Success!", "This is a basic success toast that is automatically dismissed after 15 seconds.  If not specified, toasts are automatically dismissed after 7 seconds.", "magnifyingGlass", { millisUntilDismissal: 15000 });
    }

    buttonShowToastNoDuplicatesOnClicked() {
        Toast.showSuccessToast("Success!", "This is an example of a basic success toast that can only appear once at any given time.", "magnifyingGlass", { id: "sampleID", persist: true });
    }

    buttonShowSuccessToastOnDismissOnClicked() {
        Toast.showSuccessToast("Success!", "This is a basic success toast that prints a message to the console when it is dismissed.", "magnifyingGlass", { onDismiss: () => log.info("do something amazing"), persist: true });
    }
}
