/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelCarrierOfferList.ts              *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const endpointPath = "lme/powerbroker/carrier-offer-list";

export class AutogenModelCarrierOfferList<RowType extends ModelRow> extends Endpoint<RowType, RowCarrierOfferListFields> {
    constructor(ctor: RowConstructor) {
        super(endpointPath, ctor);
    }
}

export class AutogenRowCarrierOfferList extends ModelRow<RowCarrierOfferListFields> {
    constructor(values?: Partial<RowCarrierOfferListFields>) {
        super(endpointPath, true, values);
    }
}

export interface RowCarrierOfferListFields {
    allow_cntr_offer: string;
    amount: number;
    amount_c: string;
    amount_d: Date;
    amount_n: number;
    amount_r: number;
    carrier_name: string;
    carrier_ranking: number;
    carrier_rate_calc_pay: number;
    carrier_rate_calc_pay_c: string;
    carrier_rate_calc_pay_d: Date;
    carrier_rate_calc_pay_n: number;
    carrier_rate_calc_pay_r: number;
    carrier_rate_id: number;
    carrier_rate_lane_id: string;
    comments: string;
    commitment_amount: number;
    commitment_frequency: string;
    commitments_met: number;
    commitments_offered: number;
    company_id: string;
    contact_name: string;
    contact_phone: string;
    counter_offer: number;
    counter_offer_c: string;
    counter_offer_d: Date;
    counter_offer_n: number;
    counter_offer_r: number;
    decline_descr: string;
    decline_reason: string;
    "destination.actual_arrival": Date;
    "destination.actual_departure": Date;
    "destination.address": string;
    "destination.address2": string;
    "destination.appointment_status_id": string;
    "destination.appt_contact_name": string;
    "destination.appt_required": string;
    "destination.cases": number;
    "destination.city_id": number;
    "destination.city_name": string;
    "destination.company_id": string;
    "destination.confirmed": string;
    "destination.contact_email": string;
    "destination.contact_name": string;
    "destination.dist_from_prev": number;
    "destination.dist_from_prev_um": string;
    "destination.driver_load_unload": string;
    "destination.edi_code": string;
    "destination.edi_loc_code": string;
    "destination.eta": Date;
    "destination.eta_distance": number;
    "destination.eta_late": string;
    "destination.eta_oor": string;
    "destination.google_place_id": string;
    "destination.grouped_id": number;
    "destination.hub": number;
    "destination.ic_stop_id": string;
    "destination.id": string;
    "destination.inbound_status": string;
    "destination.late_eta_colorcode": string;
    "destination.late_route_status": string;
    "destination.latitude": number;
    "destination.lease_number": string;
    "destination.location_id": string;
    "destination.location_name": string;
    "destination.longitude": number;
    "destination.manifest_fgp_uid": number;
    "destination.manifest_reg_uid": number;
    "destination.move_dist_from_preassign": number;
    "destination.move_dist_from_preassign_um": string;
    "destination.move_dist_from_previous": number;
    "destination.move_dist_from_previous_um": string;
    "destination.movement_id": string;
    "destination.movement_sequence": number;
    "destination.operational_status": string;
    "destination.order_id": string;
    "destination.order_sequence": number;
    "destination.orig_sched_early": Date;
    "destination.orig_sched_late": Date;
    "destination.pallets_dropped": number;
    "destination.pallets_picked_up": number;
    "destination.payment_code_reviewed": string;
    "destination.phone": string;
    "destination.planned_arrival_time": Date;
    "destination.ponum": string;
    "destination.prior_uncleared_stops": string;
    "destination.projected_arrival": Date;
    "destination.rate_dist_from_previous": number;
    "destination.rate_dist_from_previous_um": string;
    "destination.refno": string;
    "destination.requested_service": string;
    "destination.sched_arrive_early": Date;
    "destination.sched_arrive_late": Date;
    "destination.seal": string;
    "destination.service_date": Date;
    "destination.showas_address": string;
    "destination.showas_address2": string;
    "destination.showas_city_id": number;
    "destination.showas_city_name": string;
    "destination.showas_location_id": string;
    "destination.showas_location_name": string;
    "destination.showas_state": string;
    "destination.showas_zip_code": string;
    "destination.signed_for_name": string;
    "destination.state": string;
    "destination.status": string;
    "destination.stop_type": string;
    "destination.tank_operator": string;
    "destination.trimble_place_id": string;
    "destination.txl_uid": number;
    "destination.volume": number;
    "destination.volume_um": string;
    "destination.weight": number;
    "destination.weight_um": string;
    "destination.zip_code": string;
    "destination.zone_id": string;
    dot_number: string;
    electronic_offer: string;
    email: string;
    email_subject: string;
    entered_user_id: string;
    equipment_type_id: string;
    fax: string;
    field2: string;
    icc_number: string;
    id: string;
    max_pay: number;
    max_pay_c: string;
    max_pay_d: Date;
    max_pay_n: number;
    max_pay_r: number;
    miles_to_origin: number;
    mobile_phone: string;
    modified_date: Date;
    "movement.authorized": string;
    "movement.average_broker_rating": number;
    "movement.booking_no": string;
    "movement.br_details_link": string;
    "movement.br_status_link": string;
    "movement.br_track_interval": number;
    "movement.br_track_status": string;
    "movement.br_track_status_desc": string;
    "movement.br_track_vehicle_id": string;
    "movement.br_tracking_id": string;
    "movement.br_vendor": string;
    "movement.broker_avail_tract": string;
    "movement.brokerage": string;
    "movement.brokerage_status": string;
    "movement.carrier_contact": string;
    "movement.carrier_email": string;
    "movement.carrier_empty_from_city_id": number;
    "movement.carrier_fuel_pay_rate_id": number;
    "movement.carrier_lane_rate_id": string;
    "movement.carrier_override_carrier": string;
    "movement.carrier_override_user": string;
    "movement.carrier_phone": string;
    "movement.carrier_rate_id": number;
    "movement.carrier_tractor": string;
    "movement.carrier_trailer": string;
    "movement.carrier_trailer_type": string;
    "movement.chassis": string;
    "movement.checkcall_sched_id": string;
    "movement.company_id": string;
    "movement.container": string;
    "movement.container_returned": string;
    "movement.container_status": string;
    "movement.controlling_carrier_code": string;
    "movement.dedicated_order_id": string;
    "movement.dest_stop_id": string;
    "movement.digital_booking": string;
    "movement.dispatcher_user_id": string;
    "movement.eform_rate_confirmation": string;
    "movement.empty2next_order": string;
    "movement.equipment_group_id": string;
    "movement.est_tolls": number;
    "movement.est_tolls_c": string;
    "movement.est_tolls_d": Date;
    "movement.est_tolls_n": number;
    "movement.est_tolls_r": number;
    "movement.estimated_pay": number;
    "movement.estimated_pay_c": string;
    "movement.estimated_pay_d": Date;
    "movement.estimated_pay_n": number;
    "movement.estimated_pay_r": number;
    "movement.exclude_movement": string;
    "movement.fleet_manager": string;
    "movement.freight_matching_override": string;
    "movement.fuel_distance": number;
    "movement.fuel_distance_um": string;
    "movement.fuel_tax_exempt": string;
    "movement.hubtran_approved_inv_date": Date;
    "movement.hubtran_ok2pay_date": Date;
    "movement.ic_company_to": string;
    "movement.ic_order_to": string;
    "movement.id": string;
    "movement.integrated_carrier_search": string;
    "movement.invoice_recv_date": Date;
    "movement.is_container": string;
    "movement.is_dray": string;
    "movement.is_intercompany": string;
    "movement.is_local_mile": string;
    "movement.is_this_a_demo": string;
    "movement.linked_id": number;
    "movement.linked_sequence": number;
    "movement.load_booking_eligible": string;
    "movement.load_booking_source": string;
    "movement.loaded": string;
    "movement.lock_ts": number;
    "movement.locked_user_id": string;
    "movement.ltl": string;
    "movement.ltl_staging_wrs_uid": number;
    "movement.macropoint_status": string;
    "movement.manifest_empty_distance": number;
    "movement.manifest_estimated_arrival": Date;
    "movement.manifest_estimated_depart": Date;
    "movement.manifest_id": string;
    "movement.manifest_loaded_distance": number;
    "movement.manifest_reg_uid": number;
    "movement.manifest_spots_capacity": number;
    "movement.manifest_spots_consumed": number;
    "movement.manifest_weight_capacity": number;
    "movement.manifest_weight_consumed": number;
    "movement.max_buy": number;
    "movement.max_buy_c": string;
    "movement.max_buy_d": Date;
    "movement.max_buy_n": number;
    "movement.max_buy_r": number;
    "movement.max_pay_method": string;
    "movement.max_pay_units": number;
    "movement.missed_call_sent": string;
    "movement.move_distance": number;
    "movement.move_distance_um": string;
    "movement.movement_type": string;
    "movement.must_respond_by": Date;
    "movement.next_sched_call": Date;
    "movement.operational_status": string;
    "movement.operations_user": string;
    "movement.origin_stop_id": string;
    "movement.override_crny_type": string;
    "movement.override_driver2_id": number;
    "movement.override_driver2_nm": string;
    "movement.override_driver_id": number;
    "movement.override_driver_nm": string;
    "movement.override_drvr2_cell": string;
    "movement.override_drvr2_email": string;
    "movement.override_drvr_cell": string;
    "movement.override_drvr_email": string;
    "movement.override_max_pay": number;
    "movement.override_max_pay_c": string;
    "movement.override_max_pay_d": Date;
    "movement.override_max_pay_n": number;
    "movement.override_max_pay_r": number;
    "movement.override_pay_amt": number;
    "movement.override_pay_amt_c": string;
    "movement.override_pay_amt_d": Date;
    "movement.override_pay_amt_n": number;
    "movement.override_pay_amt_r": number;
    "movement.override_pay_rate": number;
    "movement.override_paye_rate": number;
    "movement.override_payee_id": string;
    "movement.override_targetpay": number;
    "movement.override_targetpay_c": string;
    "movement.override_targetpay_d": Date;
    "movement.override_targetpay_n": number;
    "movement.override_targetpay_r": number;
    "movement.override_type": string;
    "movement.override_unit_desc": string;
    "movement.override_units": number;
    "movement.pay_distance": number;
    "movement.pay_distance_um": string;
    "movement.pending_carrier_name": string;
    "movement.pending_carrier_nbr": string;
    "movement.pending_carrier_type": string;
    "movement.pending_payee_id": string;
    "movement.placard_required": string;
    "movement.preassign_sequence": number;
    "movement.priority": string;
    "movement.pro_nbr": string;
    "movement.rate_alert_basis": string;
    "movement.rate_alert_date": Date;
    "movement.rate_alert_days": number;
    "movement.rate_alert_hours": number;
    "movement.rate_alert_minutes": number;
    "movement.rate_confirm_template": number;
    "movement.rate_confirmation_sent_date": Date;
    "movement.rate_confirmation_status": string;
    "movement.rate_expiration_basis": string;
    "movement.rate_expiration_date": Date;
    "movement.rate_expiration_days": number;
    "movement.rate_expiration_hours": number;
    "movement.rate_expiration_minutes": number;
    "movement.rdy_pickup_date": Date;
    "movement.recurring_movement_id": string;
    "movement.reminder_sent": string;
    "movement.require_tracking": string;
    "movement.required_vendor": string;
    "movement.reserved": string;
    "movement.return_date": Date;
    "movement.seg_alloc_code": string;
    "movement.shipper_email": string;
    "movement.shipper_notify_frequency": string;
    "movement.shpmt_ident_type": string;
    "movement.shpmt_ident_value": string;
    "movement.ss_location_id": string;
    "movement.status": string;
    "movement.target_extra_stop_pay": number;
    "movement.target_extra_stop_pay_c": string;
    "movement.target_extra_stop_pay_d": Date;
    "movement.target_extra_stop_pay_n": number;
    "movement.target_extra_stop_pay_r": number;
    "movement.target_fuel_surcharge_pay": string;
    "movement.target_pay": number;
    "movement.target_pay_c": string;
    "movement.target_pay_d": Date;
    "movement.target_pay_method": string;
    "movement.target_pay_n": number;
    "movement.target_pay_r": number;
    "movement.target_pay_units": number;
    "movement.target_rate_data": string;
    "movement.target_rate_id": number;
    "movement.time_to_send_msg": Date;
    "movement.track_start_date": Date;
    "movement.tracking_duration_ext": number;
    "movement.tracking_notify_email": string;
    "movement.tracking_request_status": string;
    "movement.trailer_rent_pct": number;
    "movement.trip_lease": string;
    "movement.trip_mgt_recalc_datetime": Date;
    "movement.trip_mgt_tracking_id": string;
    "movement.triumphpay_exclude": string;
    "movement.trp_uid": number;
    "movement.ts_borrowing_company": string;
    "movement.ts_commodity": string;
    "movement.ts_movement_id": string;
    "movement.unlock_time": Date;
    "movement.waterfall_flood_time": Date;
    "movement.waterfall_in_progress": string;
    "movement.xfer2settle_date": Date;
    "movement.xferred2fueltax": string;
    "movement.xferred2fueltaxdt": Date;
    "movement.xmit_accepted": string;
    "movement.xmitted2driver": Date;
    "movement.zmit_trip": string;
    movement_id: string;
    my_avail_offers: string;
    offer_date: Date;
    offer_sequence: number;
    order_id: string;
    order_total_chg: number;
    order_total_chg_c: string;
    order_total_chg_d: Date;
    order_total_chg_n: number;
    order_total_chg_r: number;
    "orders.actual_reefer_profile": string;
    "orders.agency_id": string;
    "orders.agent_payee_id": string;
    "orders.all_in_rate": string;
    "orders.allow_relay": string;
    "orders.app_status_id": string;
    "orders.autorate_status": string;
    "orders.bill_date": Date;
    "orders.bill_distance": number;
    "orders.bill_distance_um": string;
    "orders.billing_clerk": string;
    "orders.billing_empty_distance": number;
    "orders.billing_empty_distance_um": string;
    "orders.billing_loaded_distance": number;
    "orders.billing_loaded_distance_um": string;
    "orders.billing_user_id": string;
    "orders.blnum": string;
    "orders.blnum2": string;
    "orders.blnum3": string;
    "orders.bol_note": string;
    "orders.bol_received": string;
    "orders.bol_recv_date": Date;
    "orders.booking_no": string;
    "orders.booking_number": string;
    "orders.brk_qual_profile": string;
    "orders.broker_location_id": string;
    "orders.brokerage": string;
    "orders.brokerage_workflow_id": string;
    "orders.cbp_crossing_date": Date;
    "orders.cbp_crossing_location_id": string;
    "orders.cbp_crossing_reason": string;
    "orders.cbsa_crossing_date": Date;
    "orders.cbsa_crossing_location_id": string;
    "orders.cbsa_crossing_reason": string;
    "orders.cc_fuel_revenue": number;
    "orders.cc_fuel_revenue_c": string;
    "orders.cc_fuel_revenue_d": Date;
    "orders.cc_fuel_revenue_n": number;
    "orders.cc_fuel_revenue_r": number;
    "orders.cc_net_revenue": number;
    "orders.cc_net_revenue_c": string;
    "orders.cc_net_revenue_d": Date;
    "orders.cc_net_revenue_n": number;
    "orders.cc_net_revenue_r": number;
    "orders.cc_pro_nbr": string;
    "orders.cobroker_location_id": string;
    "orders.collection_method": string;
    "orders.com_setpnt_temp": number;
    "orders.com_setpnt_temp_um": string;
    "orders.commitment_id": string;
    "orders.commodity": string;
    "orders.commodity_id": string;
    "orders.company_id": string;
    "orders.consgne_refno_stop": string;
    "orders.consignee_refno": string;
    "orders.consignee_stop_id": string;
    "orders.container": string;
    "orders.container_planned": string;
    "orders.container_type_id": string;
    "orders.controlling_carrier_code": string;
    "orders.copy_from_company": string;
    "orders.copy_from_order_id": string;
    "orders.copy_to_company": string;
    "orders.copy_to_order_id": string;
    "orders.ctrl_party_id": string;
    "orders.cube": number;
    "orders.curr_movement_id": string;
    "orders.cust_order_no": string;
    "orders.customer_id": string;
    "orders.customer_po_num": string;
    "orders.declared_val": number;
    "orders.def_move_type": string;
    "orders.default_match_id": string;
    "orders.dim_height": string;
    "orders.dim_length": string;
    "orders.dim_surcharge": number;
    "orders.dim_surcharge_c": string;
    "orders.dim_surcharge_d": Date;
    "orders.dim_surcharge_n": number;
    "orders.dim_surcharge_r": number;
    "orders.dim_width": string;
    "orders.dispatch_opt": string;
    "orders.dl_team_reqd": string;
    "orders.dray_del_date": Date;
    "orders.dray_del_pay": number;
    "orders.dray_del_pay_c": string;
    "orders.dray_del_pay_d": Date;
    "orders.dray_del_pay_n": number;
    "orders.dray_del_pay_r": number;
    "orders.dray_del_payee": string;
    "orders.dray_del_xfer_dt": Date;
    "orders.dray_pu_date": Date;
    "orders.dray_pu_pay": number;
    "orders.dray_pu_pay_c": string;
    "orders.dray_pu_pay_d": Date;
    "orders.dray_pu_pay_n": number;
    "orders.dray_pu_pay_r": number;
    "orders.dray_pu_payee": string;
    "orders.dray_pu_xfer_dt": Date;
    "orders.eir1_no": string;
    "orders.eir2_no": string;
    "orders.entered_user_id": string;
    "orders.equipment_type_id": string;
    "orders.equipment_type_options": string;
    "orders.est_tolls": number;
    "orders.est_tolls_c": string;
    "orders.est_tolls_d": Date;
    "orders.est_tolls_n": number;
    "orders.est_tolls_r": number;
    "orders.excise_disable_update": string;
    "orders.excise_taxable": string;
    "orders.excisetax_total": number;
    "orders.excisetax_total_c": string;
    "orders.excisetax_total_d": Date;
    "orders.excisetax_total_n": number;
    "orders.excisetax_total_r": number;
    "orders.external_invoiceno": string;
    "orders.external_refno": string;
    "orders.extra_deliveries": number;
    "orders.extra_pickups": number;
    "orders.floor_loaded_freight": string;
    "orders.force_assign": string;
    "orders.forwd_location_id": string;
    "orders.free_days": number;
    "orders.freight_charge": number;
    "orders.freight_charge_c": string;
    "orders.freight_charge_d": Date;
    "orders.freight_charge_n": number;
    "orders.freight_charge_r": number;
    "orders.hazmat": string;
    "orders.hazmat_code": string;
    "orders.high_value": string;
    "orders.hold_reason": string;
    "orders.ic_company_from": string;
    "orders.ic_movement_from": string;
    "orders.ic_order_from": string;
    "orders.id": string;
    "orders.image_is_present": string;
    "orders.import_export": string;
    "orders.include_split_point": string;
    "orders.intelliset": string;
    "orders.invoice_recv_date": Date;
    "orders.is_auto_tonu": string;
    "orders.is_autorate_dist": string;
    "orders.is_container": string;
    "orders.is_dedicated": string;
    "orders.is_intercompany": string;
    "orders.is_local_mile": string;
    "orders.is_requested": string;
    "orders.is_split": string;
    "orders.is_tonu": string;
    "orders.jag_carrier_id": string;
    "orders.jag_cost_rate_id": number;
    "orders.last_location": string;
    "orders.ld_issue_code": string;
    "orders.ldport_location_id": string;
    "orders.linear_feet_avail": number;
    "orders.load_date": Date;
    "orders.load_issue_code": string;
    "orders.load_issue_descr": string;
    "orders.loadboard": string;
    "orders.lock_miles": string;
    "orders.ltl": string;
    "orders.markup_flat": number;
    "orders.markup_flat_c": string;
    "orders.markup_flat_d": Date;
    "orders.markup_flat_n": number;
    "orders.markup_flat_r": number;
    "orders.markup_percent": number;
    "orders.master_order_id": string;
    "orders.master_sequence": number;
    "orders.next_rebill": string;
    "orders.nte_lp_num": string;
    "orders.nte_lp_qual": string;
    "orders.on_hold": string;
    "orders.operational_status": string;
    "orders.operations_user": string;
    "orders.optimize": string;
    "orders.optiset": string;
    "orders.order_mode": string;
    "orders.order_type_id": string;
    "orders.order_value": number;
    "orders.order_value_c": string;
    "orders.order_value_d": Date;
    "orders.order_value_n": number;
    "orders.order_value_r": number;
    "orders.ordered_by": string;
    "orders.ordered_by_id": string;
    "orders.ordered_date": Date;
    "orders.ordered_method": string;
    "orders.orig_dest_rate_id": string;
    "orders.otherchargetotal": number;
    "orders.otherchargetotal_c": string;
    "orders.otherchargetotal_d": Date;
    "orders.otherchargetotal_n": number;
    "orders.otherchargetotal_r": number;
    "orders.override_rating": string;
    "orders.pallets_how_many": number;
    "orders.pallets_required": string;
    "orders.pay_gross": number;
    "orders.pay_gross_c": string;
    "orders.pay_gross_d": Date;
    "orders.pay_gross_n": number;
    "orders.pay_gross_r": number;
    "orders.pick_up_no": string;
    "orders.pieces": number;
    "orders.placard_required": string;
    "orders.planning_comment": string;
    "orders.pnn_callback": string;
    "orders.pnn_comment": string;
    "orders.pnn_comment2": string;
    "orders.pnn_loadboard_user_id": string;
    "orders.pnn_post_type": string;
    "orders.pnn_rate": number;
    "orders.pnn_rate_type": string;
    "orders.pnn_trailer_length": number;
    "orders.pnn_trailer_width": number;
    "orders.ponum": string;
    "orders.port": string;
    "orders.port_of_discharge": string;
    "orders.preload_trailer_id": string;
    "orders.preloaded": string;
    "orders.progress_bill": string;
    "orders.pta_number": string;
    "orders.rate": number;
    "orders.rate_currency_type": string;
    "orders.rate_id": number;
    "orders.rate_min_weight": number;
    "orders.rate_min_weight_um": string;
    "orders.rate_source": string;
    "orders.rate_type": string;
    "orders.rate_unit_desc": string;
    "orders.rate_units": number;
    "orders.ready_to_bill": string;
    "orders.recurring_order_id": string;
    "orders.reply_transmitted": string;
    "orders.requesting_company": string;
    "orders.return_temp": number;
    "orders.return_temp_um": string;
    "orders.return_temp_var": number;
    "orders.return_temp_var_um": string;
    "orders.revenue_code_id": string;
    "orders.revenue_share_method": string;
    "orders.round_trip": string;
    "orders.sail_date": Date;
    "orders.seal_number": string;
    "orders.seg_alloc_code": string;
    "orders.setpoint_temp": number;
    "orders.setpoint_temp_um": string;
    "orders.setpoint_var": number;
    "orders.setpoint_var_um": string;
    "orders.ship_status_to_edi": string;
    "orders.shipment_id": string;
    "orders.shipper_stop_id": string;
    "orders.shipstatus2edi_dt": Date;
    "orders.spot_rate": string;
    "orders.ss_location_id": string;
    "orders.status": string;
    "orders.subject_order_number": string;
    "orders.subject_order_status": string;
    "orders.subject_order_void_date": Date;
    "orders.swap": string;
    "orders.tarp_type": string;
    "orders.teams_required": string;
    "orders.temperature_max": number;
    "orders.temperature_max_um": string;
    "orders.temperature_min": number;
    "orders.temperature_min_um": string;
    "orders.test": string;
    "orders.total_charge": number;
    "orders.total_charge_c": string;
    "orders.total_charge_d": Date;
    "orders.total_charge_n": number;
    "orders.total_charge_r": number;
    "orders.totalcharge_and_excisetax": number;
    "orders.totalcharge_and_excisetax_c": string;
    "orders.totalcharge_and_excisetax_d": Date;
    "orders.totalcharge_and_excisetax_n": number;
    "orders.totalcharge_and_excisetax_r": number;
    "orders.tractor_type": string;
    "orders.vessel": string;
    "orders.vessel_cutoff_date": Date;
    "orders.voided_load_transmitted": string;
    "orders.voided_load_transmitted_dt": Date;
    "orders.weight": number;
    "orders.weight_um": string;
    "orders.xferred2billing": string;
    "orders.xferred2billing_dt": Date;
    "origin.actual_arrival": Date;
    "origin.actual_departure": Date;
    "origin.address": string;
    "origin.address2": string;
    "origin.appointment_status_id": string;
    "origin.appt_contact_name": string;
    "origin.appt_required": string;
    "origin.cases": number;
    "origin.city_id": number;
    "origin.city_name": string;
    "origin.company_id": string;
    "origin.confirmed": string;
    "origin.contact_email": string;
    "origin.contact_name": string;
    "origin.dist_from_prev": number;
    "origin.dist_from_prev_um": string;
    "origin.driver_load_unload": string;
    "origin.edi_code": string;
    "origin.edi_loc_code": string;
    "origin.eta": Date;
    "origin.eta_distance": number;
    "origin.eta_late": string;
    "origin.eta_oor": string;
    "origin.google_place_id": string;
    "origin.grouped_id": number;
    "origin.hub": number;
    "origin.ic_stop_id": string;
    "origin.id": string;
    "origin.inbound_status": string;
    "origin.late_eta_colorcode": string;
    "origin.late_route_status": string;
    "origin.latitude": number;
    "origin.lease_number": string;
    "origin.location_id": string;
    "origin.location_name": string;
    "origin.longitude": number;
    "origin.manifest_fgp_uid": number;
    "origin.manifest_reg_uid": number;
    "origin.move_dist_from_preassign": number;
    "origin.move_dist_from_preassign_um": string;
    "origin.move_dist_from_previous": number;
    "origin.move_dist_from_previous_um": string;
    "origin.movement_id": string;
    "origin.movement_sequence": number;
    "origin.operational_status": string;
    "origin.order_id": string;
    "origin.order_sequence": number;
    "origin.orig_sched_early": Date;
    "origin.orig_sched_late": Date;
    "origin.pallets_dropped": number;
    "origin.pallets_picked_up": number;
    "origin.payment_code_reviewed": string;
    "origin.phone": string;
    "origin.planned_arrival_time": Date;
    "origin.ponum": string;
    "origin.prior_uncleared_stops": string;
    "origin.projected_arrival": Date;
    "origin.rate_dist_from_previous": number;
    "origin.rate_dist_from_previous_um": string;
    "origin.refno": string;
    "origin.requested_service": string;
    "origin.sched_arrive_early": Date;
    "origin.sched_arrive_late": Date;
    "origin.seal": string;
    "origin.service_date": Date;
    "origin.showas_address": string;
    "origin.showas_address2": string;
    "origin.showas_city_id": number;
    "origin.showas_city_name": string;
    "origin.showas_location_id": string;
    "origin.showas_location_name": string;
    "origin.showas_state": string;
    "origin.showas_zip_code": string;
    "origin.signed_for_name": string;
    "origin.state": string;
    "origin.status": string;
    "origin.stop_type": string;
    "origin.tank_operator": string;
    "origin.trimble_place_id": string;
    "origin.txl_uid": number;
    "origin.volume": number;
    "origin.volume_um": string;
    "origin.weight": number;
    "origin.weight_um": string;
    "origin.zip_code": string;
    "origin.zone_id": string;
    "payee.ach_batch_class_code": string;
    "payee.add_fed_to_wh": number;
    "payee.add_fed_to_wh_c": string;
    "payee.add_fed_to_wh_d": Date;
    "payee.add_fed_to_wh_n": number;
    "payee.add_fed_to_wh_r": number;
    "payee.addl_st_to_whold": number;
    "payee.addl_st_to_whold_c": string;
    "payee.addl_st_to_whold_d": Date;
    "payee.addl_st_to_whold_n": number;
    "payee.addl_st_to_whold_r": number;
    "payee.address1": string;
    "payee.address2": string;
    "payee.adp_co_code": string;
    "payee.adp_e_no": string;
    "payee.birth_date": Date;
    "payee.check_address": string;
    "payee.check_address2": string;
    "payee.check_city": string;
    "payee.check_city_st_zip": string;
    "payee.check_date": Date;
    "payee.check_name": string;
    "payee.check_number": string;
    "payee.check_st": string;
    "payee.check_stub_comment": string;
    "payee.check_zip": string;
    "payee.city": string;
    "payee.city_tax_code": string;
    "payee.company_id": string;
    "payee.country_id": string;
    "payee.county_tax_code": string;
    "payee.currency": string;
    "payee.dependents": number;
    "payee.dependents_c": string;
    "payee.dependents_d": Date;
    "payee.dependents_n": number;
    "payee.dependents_r": number;
    "payee.disable_payroll_taxes": string;
    "payee.eft_first_name": string;
    "payee.eft_individual": string;
    "payee.eft_last_name": string;
    "payee.eft_middle_initial": string;
    "payee.email": string;
    "payee.email_summary": string;
    "payee.enable_carrier_app": string;
    "payee.exempt_from_fed": string;
    "payee.extra_withholdings": number;
    "payee.extra_withholdings_c": string;
    "payee.extra_withholdings_d": Date;
    "payee.extra_withholdings_n": number;
    "payee.extra_withholdings_r": number;
    "payee.fed_amt_to_whold": number;
    "payee.fed_amt_to_whold_c": string;
    "payee.fed_amt_to_whold_d": Date;
    "payee.fed_amt_to_whold_n": number;
    "payee.fed_amt_to_whold_r": number;
    "payee.fed_exemptions": number;
    "payee.fed_marital_status": string;
    "payee.freeze_pay": string;
    "payee.freight_matching_override": string;
    "payee.gp_exclude": string;
    "payee.gp_exported": Date;
    "payee.hire_date": Date;
    "payee.holiday_hours_pd": number;
    "payee.id": string;
    "payee.last_raise_date": Date;
    "payee.legal_name": string;
    "payee.load_booking_eligible": string;
    "payee.loadpay_contact_phone": string;
    "payee.multiple_jobs": string;
    "payee.name": string;
    "payee.non_office_emp": string;
    "payee.office_employee": string;
    "payee.other_deductions": number;
    "payee.other_deductions_c": string;
    "payee.other_deductions_d": Date;
    "payee.other_deductions_n": number;
    "payee.other_deductions_r": number;
    "payee.other_income": number;
    "payee.other_income_c": string;
    "payee.other_income_d": Date;
    "payee.other_income_n": number;
    "payee.other_income_r": number;
    "payee.overtime_hours_pd": number;
    "payee.payment_method": string;
    "payee.phone_number": string;
    "payee.posted_check_date": Date;
    "payee.prev_code": string;
    "payee.primary_agency": string;
    "payee.pto_timeclock": number;
    "payee.regular_hours_pd": number;
    "payee.remarks": string;
    "payee.settlement_status": string;
    "payee.sick_hours_pd": number;
    "payee.social_security_no": string;
    "payee.st_amt_to_whold": number;
    "payee.st_amt_to_whold_c": string;
    "payee.st_amt_to_whold_d": Date;
    "payee.st_amt_to_whold_n": number;
    "payee.st_amt_to_whold_r": number;
    "payee.st_marital_status": string;
    "payee.state": string;
    "payee.state_exemptions": number;
    "payee.state_tax_code": string;
    "payee.status": string;
    "payee.sui_tax_code": string;
    "payee.tax_table_city_aux": number;
    "payee.tax_table_city_misc": number;
    "payee.tax_table_county_aux": number;
    "payee.tax_table_county_misc": number;
    "payee.tax_table_fit_aux": number;
    "payee.tax_table_fit_misc": number;
    "payee.tax_table_st_aux": number;
    "payee.tax_table_st_misc": number;
    "payee.tax_table_sui_aux": number;
    "payee.tax_table_sui_misc": number;
    "payee.termination_date": Date;
    "payee.vacation_hours_pd": number;
    "payee.vm_part_update": string;
    "payee.w4_status": string;
    "payee.wf_queue": number;
    "payee.ytd_holiday_hrs": number;
    "payee.ytd_overtime_hrs": number;
    "payee.ytd_reg_hrs_paid": number;
    "payee.ytd_sick_hrs_paid": number;
    "payee.ytd_vacation_hrs": number;
    "payee.zip_code": string;
    payee_id: string;
    response_time: number;
    routing_guide_profile_id: string;
    salesperson_id: string;
    source: string;
    status: string;
    target_pay: number;
    target_pay_c: string;
    target_pay_d: Date;
    target_pay_n: number;
    target_pay_r: number;
    tractor_available_date: Date;
    tractor_city: string;
    tractor_city_id: number;
    tractor_expires_date: Date;
    tractor_state: string;
    tractor_zip: string;
    waterfall_offer: string;
}

