import { DataSource, Label, Layout, Panel, Table, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutCarrierRatings extends Layout {
    labelCarrierInformation: Label;
    labelCarrierRatings: Label;
    labelComments: Label;
    panelCarrierInfo: Panel;
    panelCarrierRatings: Panel;
    panelComments: Panel;
    sourceBrokerRating: DataSource;
    sourceBrokerRatingHeader: DataSource;
    tableRatings: Table;
    textboxComments: Textbox;
    textboxMovementId: Textbox;
    textboxOrderId: Textbox;
    textboxPayeeId: Textbox;
    textboxRatedBy: Textbox;
    textboxRatedDate: Textbox;
}
