import { DataSource, HorizontalSpacer, Layout, SaveButton } from "@mcleod/components";
import { AssignedMovementOverview } from "../../../dispatch/src/AssignedMovementOverview";
import { MovementOfferTabs } from "../../../dispatch/src/MovementOfferTabs";
import { MovementOffers } from "../../../dispatch/src/MovementOffers";

export abstract class AutogenLayoutBrokerageMovementsGridExpansion extends Layout {
    buttonSave: SaveButton;
    horizontalspacer2: HorizontalSpacer;
    layoutAssignedMovementOverview: AssignedMovementOverview;
    layoutMovementOfferTabs: MovementOfferTabs;
    layoutMovementOffers: MovementOffers;
    sourceBrokerageMovements: DataSource;
    sourceMovement: DataSource;
}
