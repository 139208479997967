import { DataSource, HorizontalSpacer, Label, Layout, Panel, SearchButton, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutLogErrorSearch extends Layout {
    buttonFindError: SearchButton;
    horizontalspacer1: HorizontalSpacer;
    labelOrDropALogOrZipFileHere: Label;
    layoutRequestList: Layout;
    panelDragLog: Panel;
    panelHeader: Panel;
    panelResults: Panel;
    sourceLogSearchError: DataSource;
    textErrorTag: Textbox;
    textLogFile: Textbox;
}
