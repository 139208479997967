import { CityState, DataSource, HorizontalSpacer, Label, Layout, Panel, Table, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutEdiMultiStop extends Layout {
    captionDistance: Label;
    citystateConsignee: CityState;
    citystateCustomer: CityState;
    citystateShipper: CityState;
    horizontalspacer1: HorizontalSpacer;
    labelBillDistance: Label;
    labelBillDistanceUm: Label;
    labelCustomeraddress1: Label;
    labelCustomeraddress2: Label;
    panelCustomer: Panel;
    panelDistance: Panel;
    panelDistanceInner: Panel;
    panelEdiOrderInfo: Panel;
    panelEdiOriginDest: Panel;
    panelStopInfo: Panel;
    panelWeight: Panel;
    sourceAppointmentChange: DataSource;
    sourceEdiMultistop: DataSource;
    sourceEdiStop: DataSource;
    sourceMovement: DataSource;
    tableEdiStop: Table;
    textboxCommodityId: Textbox;
    textboxCustomername: Textbox;
    textboxEquipmentTypeId: Textbox;
    textboxTotalCharge: Textbox;
    textboxWeight: Textbox;
}
