/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelMultistop.ts                     *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/dispatch/multistop";

export class AutogenModelMultistop<RowType extends ModelRow> extends Endpoint<RowType, RowMultistopFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowMultistop extends ModelRow<RowMultistopFields> {
    constructor(values?: Partial<RowMultistopFields>) {
        super(AutogenUserSavedSearchEndpoint, true, values);
    }
}

export interface RowMultistopFields {
    activity_count: number;
    activity_start_date: Date;
    actual_reefer_profile: string;
    add_empty_distance_occ: string;
    add_finance_charge: string;
    address1: string;
    address2: string;
    agency_id: string;
    agent_payee_id: string;
    all_in_rate: string;
    allow_inc_in_frt: string;
    allow_quick_dispatch: string;
    allow_relay: string;
    app_status_id: string;
    authorized: string;
    auto_rate: string;
    autorate_status: string;
    available_template: number;
    average_bill: number;
    average_bill_c: string;
    average_bill_d: Date;
    average_bill_n: number;
    average_bill_r: number;
    average_broker_rating: number;
    average_pay_days: number;
    balance: number;
    balance_c: string;
    balance_d: Date;
    balance_n: number;
    balance_r: number;
    benchmark_ctg_uid: number;
    benchmark_tar_uid: number;
    bill_date: Date;
    bill_distance: number;
    bill_distance_um: string;
    bill_due_days: number;
    bill_format_flag: string;
    bill_template: number;
    bill_transfer_when: string;
    billed_loads: number;
    billing_clerk: string;
    billing_empty_distance: number;
    billing_empty_distance_um: string;
    billing_loaded_distance: number;
    billing_loaded_distance_um: string;
    billing_user_id: string;
    bl_logo: string;
    bl_logo_name: string;
    blanket_buy_tariff_id: string;
    blanket_sell_tariff_id: string;
    blnum: string;
    blnum2: string;
    blnum3: string;
    bol_note: string;
    bol_received: string;
    bol_recv_date: Date;
    booking_no: string;
    booking_number: string;
    br_details_link: string;
    br_status_link: string;
    br_track_interval: number;
    br_track_status: string;
    br_track_status_desc: string;
    br_track_vehicle_id: string;
    br_tracking_id: string;
    br_vendor: string;
    bridge_id: string;
    brk_qual_profile: string;
    broker_avail_tract: string;
    broker_location_id: string;
    brokerage: string;
    brokerage_filter: string;
    brokerage_status: string;
    brokerage_workflow_id: string;
    business_hours: string;
    business_number: string;
    buy_tariff_id: string;
    carrier_contact: string;
    carrier_email: string;
    carrier_empty_from_city_id: number;
    carrier_fuel_pay_rate_id: number;
    carrier_lane_rate_id: string;
    carrier_override_user: string;
    carrier_phone: string;
    carrier_rate_id: number;
    carrier_tractor: string;
    carrier_trailer: string;
    carrier_trailer_type: string;
    category: string;
    cbp_crossing_date: Date;
    cbp_crossing_location_id: string;
    cbp_crossing_reason: string;
    cbsa_crossing_date: Date;
    cbsa_crossing_location_id: string;
    cbsa_crossing_reason: string;
    cc_fuel_revenue: number;
    cc_fuel_revenue_c: string;
    cc_fuel_revenue_d: Date;
    cc_fuel_revenue_n: number;
    cc_fuel_revenue_r: number;
    cc_net_revenue: number;
    cc_net_revenue_c: string;
    cc_net_revenue_d: Date;
    cc_net_revenue_n: number;
    cc_net_revenue_r: number;
    cc_pro_nbr: string;
    cert_of_ins_date: Date;
    charge_detention: string;
    chassis: string;
    chassis_chargeby: string;
    chassis_freedays: number;
    chassis_rate: number;
    chassis_rate_c: string;
    chassis_rate_d: Date;
    chassis_rate_n: number;
    chassis_rate_r: number;
    checkcall_sched_id: string;
    city: string;
    city_id: number;
    cobroker_location_id: string;
    coll_thresh_pd1: number;
    coll_thresh_pd1_c: string;
    coll_thresh_pd1_d: Date;
    coll_thresh_pd1_n: number;
    coll_thresh_pd1_r: number;
    coll_thresh_pd2: number;
    coll_thresh_pd2_c: string;
    coll_thresh_pd2_d: Date;
    coll_thresh_pd2_n: number;
    coll_thresh_pd2_r: number;
    coll_thresh_pd3: number;
    coll_thresh_pd3_c: string;
    coll_thresh_pd3_d: Date;
    coll_thresh_pd3_n: number;
    coll_thresh_pd3_r: number;
    coll_thresh_pd4: number;
    coll_thresh_pd4_c: string;
    coll_thresh_pd4_d: Date;
    coll_thresh_pd4_n: number;
    coll_thresh_pd4_r: number;
    collection_method: string;
    collections_id: string;
    collections_ok: string;
    com_setpnt_temp: number;
    com_setpnt_temp_um: string;
    commitment_id: string;
    commodity: string;
    commodity_id: string;
    company_id: string;
    confidence_level: number;
    consgne_refno_stop: string;
    consignee_refno: string;
    consignee_stop_id: string;
    container: string;
    container_chargeby: string;
    container_freedays: number;
    container_planned: string;
    container_rate: number;
    container_rate_c: string;
    container_rate_d: Date;
    container_rate_n: number;
    container_rate_r: number;
    container_returned: string;
    container_status: string;
    container_type_id: string;
    contract_cbc: string;
    contract_ccc: string;
    contract_gbc: string;
    contract_gcc: string;
    contract_sbc: string;
    contract_scc: string;
    controlling_carrier_code: string;
    conversion_date: Date;
    copy_from_company: string;
    copy_from_order_id: string;
    copy_to_company: string;
    copy_to_order_id: string;
    cost_ctg_uid: number;
    cost_tar_uid: number;
    covered_template: number;
    cp_logo: string;
    cp_logo_name: string;
    credit_application: string;
    credit_check_date: Date;
    credit_limit: number;
    credit_limit_c: string;
    credit_limit_d: Date;
    credit_limit_n: number;
    credit_limit_r: number;
    credit_override_status: string;
    credit_status: string;
    credit_warning_pct: number;
    ctg_uid: number;
    ctrl_party_id: string;
    cube: number;
    curr_movement_id: string;
    currency_type: string;
    cust_order_no: string;
    cust_requestor_load_id: string;
    cust_stmt_template: number;
    customer_id: string;
    customer_po_num: string;
    d_and_b_code: string;
    d_and_b_date: Date;
    d_and_b_number: string;
    days_between_calls: number;
    deadhead_target: number;
    declared_val: number;
    ded_last_fixed_cost_billdt: Date;
    dedicated_cycle: string;
    dedicated_order_id: string;
    dedicated_revenue_code_id: string;
    dedicated_seg_alloc_code: string;
    dedicated_start_day: string;
    dedicated_template: number;
    def_move_type: string;
    def_rate_confirm_temp: number;
    default_match_id: string;
    delivered_template: number;
    departure_date_at: string;
    dest_stop_id: string;
    digital_booking: string;
    dim_height: string;
    dim_length: string;
    dim_surcharge: number;
    dim_surcharge_c: string;
    dim_surcharge_d: Date;
    dim_surcharge_n: number;
    dim_surcharge_r: number;
    dim_width: string;
    disable_automatic_tracking: string;
    dispatch_opt: string;
    dispatcher_user_id: string;
    display_eta: string;
    display_ysplit_stops: string;
    distance_method: string;
    distance_profile: string;
    distance_source: string;
    distance_type: string;
    dl_team_reqd: string;
    dot_number: string;
    dray_del_date: Date;
    dray_del_pay: number;
    dray_del_pay_c: string;
    dray_del_pay_d: Date;
    dray_del_pay_n: number;
    dray_del_pay_r: number;
    dray_del_payee: string;
    dray_del_xfer_dt: Date;
    dray_pu_date: Date;
    dray_pu_pay: number;
    dray_pu_pay_c: string;
    dray_pu_pay_d: Date;
    dray_pu_pay_n: number;
    dray_pu_pay_r: number;
    dray_pu_payee: string;
    dray_pu_xfer_dt: Date;
    driver_load: string;
    eform_rate_confirmation: string;
    eir1_no: string;
    eir2_no: string;
    empty2next_order: string;
    empty_distance_charge_id: string;
    empty_distance_rate: number;
    enable_notifications: string;
    enforce_dep_dates: string;
    entered_date: Date;
    entered_user_id: string;
    equipment_group_id: string;
    equipment_type_id: string;
    equipment_type_options: string;
    est_tolls: number;
    est_tolls_c: string;
    est_tolls_d: Date;
    est_tolls_n: number;
    est_tolls_r: number;
    estimated_pay: number;
    estimated_pay_c: string;
    estimated_pay_d: Date;
    estimated_pay_n: number;
    estimated_pay_r: number;
    excise_disable_update: string;
    excise_taxable: string;
    excisetax_total: number;
    excisetax_total_c: string;
    excisetax_total_d: Date;
    excisetax_total_n: number;
    excisetax_total_r: number;
    exclude_movement: string;
    expected_close: Date;
    extend_rates: string;
    external_invoiceno: string;
    external_refno: string;
    extra_deliveries: number;
    extra_pickups: number;
    factor_percent: number;
    factor_receivables: string;
    factoring_id: string;
    fak_profile_id: string;
    fleet_manager: string;
    floor_loaded_freight: string;
    force_assign: string;
    forwd_location_id: string;
    fourkites_customer_id: string;
    free_days: number;
    freight_charge: number;
    freight_charge_c: string;
    freight_charge_d: Date;
    freight_charge_n: number;
    freight_charge_r: number;
    freight_matching_override: string;
    fuel_amount: number;
    fuel_base_price: number;
    fuel_credit: string;
    fuel_distance: number;
    fuel_distance_um: string;
    fuel_method: string;
    fuel_percent: number;
    fuel_price_region: string;
    fuel_surcharge_id: string;
    fuel_surchg_enddt: Date;
    fuel_surchg_strtdt: Date;
    fuel_tax_exempt: string;
    fuel_variance: number;
    global_detention: string;
    google_place_id: string;
    gp_exclude: string;
    gp_exported: Date;
    grace_period: number;
    hazmat: string;
    hazmat_code: string;
    high_balance: number;
    high_balance_c: string;
    high_balance_d: Date;
    high_balance_n: number;
    high_balance_r: number;
    high_value: string;
    hold_reason: string;
    hubtran_approved_inv_date: Date;
    hubtran_ok2pay_date: Date;
    ic_company_from: string;
    ic_company_id: string;
    ic_company_to: string;
    ic_movement_from: string;
    ic_order_from: string;
    ic_order_to: string;
    id: string;
    image_indicator: number;
    image_is_present: string;
    import_export: string;
    include_by_date: string;
    include_empty_distance: string;
    include_fuel_surcharge: string;
    include_split_point: string;
    integrated_carrier_search: string;
    intelliset: string;
    intercompany: string;
    intercompanyintercompany: string;
    invoice_recv_date: Date;
    is_active: string;
    is_ask_dispatcher: string;
    is_auto_tonu: string;
    is_autorate_dist: string;
    is_consignee: string;
    is_container: string;
    is_dedicated: string;
    is_distribution_center: string;
    is_dray: string;
    is_drop_yard: string;
    is_geocoded: string;
    is_intercompany: string;
    is_local_mile: string;
    is_pallets_required: string;
    is_requested: string;
    is_shipper: string;
    is_split: string;
    is_this_a_demo: string;
    is_tonu: string;
    is_trailer_pool_required: string;
    jag_carrier_id: string;
    jag_cost_rate_id: number;
    jaguar_id: number;
    last_bill_date: Date;
    last_fin_charge_dt: Date;
    last_location: string;
    last_pay_date: Date;
    last_ship_date: Date;
    latitude: number;
    ld_issue_code: string;
    ldport_location_id: string;
    level_ref_dup: string;
    linear_feet_avail: number;
    linked_id: number;
    linked_sequence: number;
    load_booking_eligible: string;
    load_booking_source: string;
    load_date: Date;
    load_issue_code: string;
    load_issue_descr: string;
    loadboard: string;
    loaded: string;
    location_id: string;
    lock_miles: string;
    lock_ts: number;
    locked_user_id: string;
    longitude: number;
    ltl: string;
    ltl_staging_wrs_uid: number;
    macropoint_requestor_id: string;
    macropoint_status: string;
    main_phone: string;
    manifest_empty_distance: number;
    manifest_estimated_arrival: Date;
    manifest_estimated_depart: Date;
    manifest_id: string;
    manifest_loaded_distance: number;
    manifest_reg_uid: number;
    manifest_spots_capacity: number;
    manifest_spots_consumed: number;
    manifest_weight_capacity: number;
    manifest_weight_consumed: number;
    manual_exchange: string;
    mark_plan_id: string;
    markup_flat: number;
    markup_flat_c: string;
    markup_flat_d: Date;
    markup_flat_n: number;
    markup_flat_r: number;
    markup_percent: number;
    master_order_id: string;
    master_sequence: number;
    max_buy: number;
    max_buy_c: string;
    max_buy_d: Date;
    max_buy_n: number;
    max_buy_r: number;
    max_pay_method: string;
    max_pay_percent: number;
    max_pay_units: number;
    misc_bill_template: number;
    missed_call_sent: string;
    monthly_order_goal: number;
    motor_carrier_id: string;
    move_distance: number;
    move_distance_um: string;
    movement_type: string;
    must_respond_by: Date;
    name: string;
    next_call_date: Date;
    next_rebill: string;
    next_rec_call: Date;
    next_sched_call: Date;
    nte_lp_num: string;
    nte_lp_qual: string;
    nxt_action_code_id: string;
    nxt_action_date: Date;
    on_hold: string;
    operational_status: string;
    operations_user: string;
    optimize: string;
    optiset: string;
    order_count: number;
    order_detail_at: string;
    order_mode: string;
    order_start_date: Date;
    order_type_id: string;
    order_value: number;
    order_value_c: string;
    order_value_d: Date;
    order_value_n: number;
    order_value_r: number;
    ordered_by: string;
    ordered_by_id: string;
    ordered_date: Date;
    ordered_method: string;
    orig_dest_rate_id: string;
    origin_stop_id: string;
    otherchargetotal: number;
    otherchargetotal_c: string;
    otherchargetotal_d: Date;
    otherchargetotal_n: number;
    otherchargetotal_r: number;
    override_crny_type: string;
    override_driver2_id: number;
    override_driver2_nm: string;
    override_driver_id: number;
    override_driver_nm: string;
    override_drvr2_cell: string;
    override_drvr2_email: string;
    override_drvr_cell: string;
    override_drvr_email: string;
    override_max_pay: number;
    override_max_pay_c: string;
    override_max_pay_d: Date;
    override_max_pay_n: number;
    override_max_pay_r: number;
    override_pay_amt: number;
    override_pay_amt_c: string;
    override_pay_amt_d: Date;
    override_pay_amt_n: number;
    override_pay_amt_r: number;
    override_pay_rate: number;
    override_paye_rate: number;
    override_payee_id: string;
    override_rating: string;
    override_targetpay: number;
    override_targetpay_c: string;
    override_targetpay_d: Date;
    override_targetpay_n: number;
    override_targetpay_r: number;
    override_type: string;
    override_unit_desc: string;
    override_units: number;
    ovrride_dupl_cref: string;
    p44_profile_id: string;
    paid_loads: number;
    pallets_how_many: number;
    pallets_required: string;
    pay_days_date: Date;
    pay_days_orders: number;
    pay_distance: number;
    pay_distance_um: string;
    pay_gross: number;
    pay_gross_c: string;
    pay_gross_d: Date;
    pay_gross_n: number;
    pay_gross_r: number;
    payee_id: string;
    pd0_alert: number;
    pd1_np_auto: string;
    pd1_sp_auto: string;
    pd1_sp_thresh: number;
    pd1_sp_thresh_c: string;
    pd1_sp_thresh_d: Date;
    pd1_sp_thresh_n: number;
    pd1_sp_thresh_r: number;
    pd2_np_auto: string;
    pd2_sp_auto: string;
    pd2_sp_thresh: number;
    pd2_sp_thresh_c: string;
    pd2_sp_thresh_d: Date;
    pd2_sp_thresh_n: number;
    pd2_sp_thresh_r: number;
    pd3_np_auto: string;
    pd3_sp_auto: string;
    pd3_sp_thresh: number;
    pd3_sp_thresh_c: string;
    pd3_sp_thresh_d: Date;
    pd3_sp_thresh_n: number;
    pd3_sp_thresh_r: number;
    pd4_np_auto: string;
    pd4_sp_auto: string;
    pd4_sp_thresh: number;
    pd4_sp_thresh_c: string;
    pd4_sp_thresh_d: Date;
    pd4_sp_thresh_n: number;
    pd4_sp_thresh_r: number;
    pending_carrier_name: string;
    pending_carrier_nbr: string;
    pending_carrier_type: string;
    pending_payee_id: string;
    pick_up_no: string;
    pieces: number;
    placard_required: string;
    planning_comment: string;
    plc_uid: number;
    pnn_callback: string;
    pnn_comment: string;
    pnn_comment2: string;
    pnn_loadboard_user_id: string;
    pnn_post_type: string;
    pnn_rate: number;
    pnn_rate_type: string;
    pnn_trailer_length: number;
    pnn_trailer_width: number;
    ponum: string;
    port: string;
    port_of_discharge: string;
    post_summary_dtl: string;
    potential: number;
    potential_c: string;
    potential_d: Date;
    potential_n: number;
    potential_r: number;
    preassign_sequence: number;
    preload_trailer_id: string;
    preloaded: string;
    prev_code: string;
    primary_agency: string;
    primary_commodity_id: string;
    primary_contact: string;
    primary_sort: string;
    primary_trailer_type: string;
    print_containers: string;
    priority: string;
    pro_nbr: string;
    progress_bill: string;
    progress_template: number;
    prospect_type_id: string;
    pta_number: string;
    pu_ticket_type: string;
    quebec_number: string;
    quote_days_to_expire: number;
    rate: number;
    rate_alert_basis: string;
    rate_alert_date: Date;
    rate_alert_days: number;
    rate_alert_hours: number;
    rate_alert_minutes: number;
    rate_confirm_template: number;
    rate_confirmation_sent_date: Date;
    rate_confirmation_status: string;
    rate_currency_type: string;
    rate_expiration_basis: string;
    rate_expiration_date: Date;
    rate_expiration_days: number;
    rate_expiration_hours: number;
    rate_expiration_minutes: number;
    rate_id: number;
    rate_min_weight: number;
    rate_min_weight_um: string;
    rate_override_nmfc_code: string;
    rate_source: string;
    rate_type: string;
    rate_unit_desc: string;
    rate_units: number;
    rdy_pickup_date: Date;
    ready_to_bill: string;
    reassign_profile_id: string;
    rec_call_days: number;
    recurring_movement_id: string;
    recurring_order_id: string;
    remarks: string;
    reminder_sent: string;
    reply_transmitted: string;
    requesting_company: string;
    require_tracking: string;
    required_vendor: string;
    reserved: string;
    return_date: Date;
    return_temp: number;
    return_temp_um: string;
    return_temp_var: number;
    return_temp_var_um: string;
    revenue: number;
    revenue_c: string;
    revenue_code_id: string;
    revenue_d: Date;
    revenue_n: number;
    revenue_r: number;
    revenue_share_method: string;
    revenue_start_date: Date;
    round_trip: string;
    routing_guide_profile_id: string;
    sail_date: Date;
    sales_manager_id: string;
    sales_status_id: string;
    salesperson_id: string;
    seal_number: string;
    search_city: string;
    search_state_id: string;
    search_zip_code: string;
    secondary_sort: string;
    seg_alloc_code: string;
    sell_tariff_id: string;
    service_fail_cons: string;
    service_fail_drop: string;
    service_fail_pick: string;
    service_fail_ship: string;
    service_failures: string;
    service_failures_review: string;
    setpoint_temp: number;
    setpoint_temp_um: string;
    setpoint_var: number;
    setpoint_var_um: string;
    ship_status_to_edi: string;
    shipment_id: string;
    shipment_ident_type: string;
    shipper_email: string;
    shipper_notify_frequency: string;
    shipper_stop_id: string;
    shipstatus2edi_dt: Date;
    shpmt_ident_type: string;
    shpmt_ident_value: string;
    sic_code: string;
    spot_rate: string;
    ss_location_id: string;
    start_date: Date;
    state_id: string;
    status: string;
    "stop_dest.actual_arrival": Date;
    "stop_dest.actual_departure": Date;
    "stop_dest.address": string;
    "stop_dest.address2": string;
    "stop_dest.appointment_status_id": string;
    "stop_dest.appt_contact_name": string;
    "stop_dest.appt_required": string;
    "stop_dest.cases": number;
    "stop_dest.city_id": number;
    "stop_dest.city_name": string;
    "stop_dest.company_id": string;
    "stop_dest.confirmed": string;
    "stop_dest.contact_name": string;
    "stop_dest.dist_from_prev": number;
    "stop_dest.dist_from_prev_um": string;
    "stop_dest.driver_load_unload": string;
    "stop_dest.edi_code": string;
    "stop_dest.edi_loc_code": string;
    "stop_dest.eta": Date;
    "stop_dest.eta_distance": number;
    "stop_dest.eta_late": string;
    "stop_dest.eta_oor": string;
    "stop_dest.google_place_id": string;
    "stop_dest.grouped_id": number;
    "stop_dest.hub": number;
    "stop_dest.ic_stop_id": string;
    "stop_dest.id": string;
    "stop_dest.inbound_status": string;
    "stop_dest.late_eta_colorcode": string;
    "stop_dest.late_route_status": string;
    "stop_dest.latitude": number;
    "stop_dest.lease_number": string;
    "stop_dest.location_id": string;
    "stop_dest.location_name": string;
    "stop_dest.longitude": number;
    "stop_dest.manifest_fgp_uid": number;
    "stop_dest.manifest_reg_uid": number;
    "stop_dest.move_dist_from_preassign": number;
    "stop_dest.move_dist_from_preassign_um": string;
    "stop_dest.move_dist_from_previous": number;
    "stop_dest.move_dist_from_previous_um": string;
    "stop_dest.movement_id": string;
    "stop_dest.movement_sequence": number;
    "stop_dest.operational_status": string;
    "stop_dest.order_id": string;
    "stop_dest.order_sequence": number;
    "stop_dest.orig_sched_early": Date;
    "stop_dest.orig_sched_late": Date;
    "stop_dest.pallets_dropped": number;
    "stop_dest.pallets_picked_up": number;
    "stop_dest.payment_code_reviewed": string;
    "stop_dest.phone": string;
    "stop_dest.planned_arrival_time": Date;
    "stop_dest.ponum": string;
    "stop_dest.prior_uncleared_stops": string;
    "stop_dest.projected_arrival": Date;
    "stop_dest.rate_dist_from_previous": number;
    "stop_dest.rate_dist_from_previous_um": string;
    "stop_dest.refno": string;
    "stop_dest.requested_service": string;
    "stop_dest.sched_arrive_early": Date;
    "stop_dest.sched_arrive_late": Date;
    "stop_dest.seal": string;
    "stop_dest.service_date": Date;
    "stop_dest.showas_address": string;
    "stop_dest.showas_address2": string;
    "stop_dest.showas_city_id": number;
    "stop_dest.showas_city_name": string;
    "stop_dest.showas_location_id": string;
    "stop_dest.showas_location_name": string;
    "stop_dest.showas_state": string;
    "stop_dest.showas_zip_code": string;
    "stop_dest.signed_for_name": string;
    "stop_dest.state": string;
    "stop_dest.status": string;
    "stop_dest.stop_type": string;
    "stop_dest.tank_operator": string;
    "stop_dest.trimble_place_id": string;
    "stop_dest.txl_uid": number;
    "stop_dest.volume": number;
    "stop_dest.volume_um": string;
    "stop_dest.weight": number;
    "stop_dest.weight_um": string;
    "stop_dest.zip_code": string;
    "stop_dest.zone_id": string;
    "stop_origin.actual_arrival": Date;
    "stop_origin.actual_departure": Date;
    "stop_origin.address": string;
    "stop_origin.address2": string;
    "stop_origin.appointment_status_id": string;
    "stop_origin.appt_contact_name": string;
    "stop_origin.appt_required": string;
    "stop_origin.cases": number;
    "stop_origin.city_id": number;
    "stop_origin.city_name": string;
    "stop_origin.company_id": string;
    "stop_origin.confirmed": string;
    "stop_origin.contact_name": string;
    "stop_origin.dist_from_prev": number;
    "stop_origin.dist_from_prev_um": string;
    "stop_origin.driver_load_unload": string;
    "stop_origin.edi_code": string;
    "stop_origin.edi_loc_code": string;
    "stop_origin.eta": Date;
    "stop_origin.eta_distance": number;
    "stop_origin.eta_late": string;
    "stop_origin.eta_oor": string;
    "stop_origin.google_place_id": string;
    "stop_origin.grouped_id": number;
    "stop_origin.hub": number;
    "stop_origin.ic_stop_id": string;
    "stop_origin.id": string;
    "stop_origin.inbound_status": string;
    "stop_origin.late_eta_colorcode": string;
    "stop_origin.late_route_status": string;
    "stop_origin.latitude": number;
    "stop_origin.lease_number": string;
    "stop_origin.location_id": string;
    "stop_origin.location_name": string;
    "stop_origin.longitude": number;
    "stop_origin.manifest_fgp_uid": number;
    "stop_origin.manifest_reg_uid": number;
    "stop_origin.move_dist_from_preassign": number;
    "stop_origin.move_dist_from_preassign_um": string;
    "stop_origin.move_dist_from_previous": number;
    "stop_origin.move_dist_from_previous_um": string;
    "stop_origin.movement_id": string;
    "stop_origin.movement_sequence": number;
    "stop_origin.operational_status": string;
    "stop_origin.order_id": string;
    "stop_origin.order_sequence": number;
    "stop_origin.orig_sched_early": Date;
    "stop_origin.orig_sched_late": Date;
    "stop_origin.pallets_dropped": number;
    "stop_origin.pallets_picked_up": number;
    "stop_origin.payment_code_reviewed": string;
    "stop_origin.phone": string;
    "stop_origin.planned_arrival_time": Date;
    "stop_origin.ponum": string;
    "stop_origin.prior_uncleared_stops": string;
    "stop_origin.projected_arrival": Date;
    "stop_origin.rate_dist_from_previous": number;
    "stop_origin.rate_dist_from_previous_um": string;
    "stop_origin.refno": string;
    "stop_origin.requested_service": string;
    "stop_origin.sched_arrive_early": Date;
    "stop_origin.sched_arrive_late": Date;
    "stop_origin.seal": string;
    "stop_origin.service_date": Date;
    "stop_origin.showas_address": string;
    "stop_origin.showas_address2": string;
    "stop_origin.showas_city_id": number;
    "stop_origin.showas_city_name": string;
    "stop_origin.showas_location_id": string;
    "stop_origin.showas_location_name": string;
    "stop_origin.showas_state": string;
    "stop_origin.showas_zip_code": string;
    "stop_origin.signed_for_name": string;
    "stop_origin.state": string;
    "stop_origin.status": string;
    "stop_origin.stop_type": string;
    "stop_origin.tank_operator": string;
    "stop_origin.trimble_place_id": string;
    "stop_origin.txl_uid": number;
    "stop_origin.volume": number;
    "stop_origin.volume_um": string;
    "stop_origin.weight": number;
    "stop_origin.weight_um": string;
    "stop_origin.zip_code": string;
    "stop_origin.zone_id": string;
    subject_order_number: string;
    subject_order_status: string;
    subject_order_void_date: Date;
    summary_billing: string;
    summary_template: number;
    swap: string;
    tar_uid: number;
    target_extra_stop_pay: number;
    target_extra_stop_pay_c: string;
    target_extra_stop_pay_d: Date;
    target_extra_stop_pay_n: number;
    target_extra_stop_pay_r: number;
    target_fuel_surcharge_pay: string;
    target_pay: number;
    target_pay_c: string;
    target_pay_d: Date;
    target_pay_method: string;
    target_pay_n: number;
    target_pay_r: number;
    target_pay_units: number;
    target_profit: number;
    target_rate_id: number;
    tarp_type: string;
    teams_required: string;
    temperature_max: number;
    temperature_max_um: string;
    temperature_min: number;
    temperature_min_um: string;
    tenfour_customer_id: string;
    test: string;
    time_to_send_msg: Date;
    total_charge: number;
    total_charge_c: string;
    total_charge_d: Date;
    total_charge_n: number;
    total_charge_r: number;
    totalcharge_and_excisetax: number;
    totalcharge_and_excisetax_c: string;
    totalcharge_and_excisetax_d: Date;
    totalcharge_and_excisetax_n: number;
    totalcharge_and_excisetax_r: number;
    track_start_date: Date;
    tracking_duration_ext: number;
    tracking_hours_prior: number;
    tracking_notify_email: string;
    tracking_request_status: string;
    tractor_type: string;
    trailer_rent_pct: number;
    trimble_place_id: string;
    trip_lease: string;
    trip_mgt_recalc_datetime: Date;
    trip_mgt_tracking_id: string;
    triumphpay_exclude: string;
    trp_uid: number;
    ts_borrowing_company: string;
    ts_commodity: string;
    ts_movement_id: string;
    type_of: string;
    unlock_time: Date;
    use_local_mile: string;
    vessel: string;
    vessel_cutoff_date: Date;
    vm_part_update: string;
    waterfall_auto_route_guides: string;
    waterfall_flood_time: Date;
    waterfall_in_progress: string;
    waterfall_lead_time: number;
    waterfall_notify_email: string;
    waterfall_process: string;
    website_url: string;
    weight: number;
    weight_um: string;
    wf_auto_renbill: string;
    wf_autobill: string;
    wf_bwop: string;
    wf_queue: number;
    xfer2settle_date: Date;
    xferred2billing: string;
    xferred2billing_dt: Date;
    xferred2fueltax: string;
    xferred2fueltaxdt: Date;
    xmit_accepted: string;
    xmitted2driver: Date;
    zip_code: string;
    zmit_trip: string;
    zone_id: string;
}

