import { DataSourceMode, EditRowDecorator, TableRow, } from "@mcleod/components";
import { ModelRow } from "@mcleod/core";
import { RecurringSchedule } from "./RecurringSchedule";
import { AutogenLayoutRecurringScheduleTable } from "./autogen/AutogenLayoutRecurringScheduleTable";
import { RowStopRecOrder } from "./models/ModelStopRecOrder";

export class RecurringScheduleTable extends AutogenLayoutRecurringScheduleTable {
    private recOrderRow: ModelRow;
    private stopRecOrderRows: ModelRow[];

    override onLoad(): void {
        this.tableRecurringSchedule.onEditLayoutLoaded = this.tableRecurringScheduleOnEditLayoutLoaded();
    }

    initialize(recOrderRow: ModelRow, stopRecOrderRows: ModelRow<RowStopRecOrder>[]) {
        this.recOrderRow = recOrderRow;
        this.stopRecOrderRows = stopRecOrderRows;
    }

    tableRecurringScheduleOnEditLayoutLoaded(): (rowDecorator: EditRowDecorator, tableRow: TableRow) => void {
        return async (rowDecorator: EditRowDecorator, tableRow: TableRow) => {
            const recurringSchedule = rowDecorator.layout as RecurringSchedule;
            recurringSchedule.initialize(DataSourceMode.UPDATE, this.recOrderRow, this.stopRecOrderRows);
            rowDecorator.onClose = (cancelled) => { if (cancelled) recurringSchedule.slideOut(); }; // Override default save and close behavior
            rowDecorator.onSave = async () => {
                const updatedRow = await recurringSchedule.onSave();
                if (updatedRow)
                    this.sourceRecurringSched.search({ recurring_order_id: this.activeRow.get("recurring_order_id") });
            };
            rowDecorator.btnDelete.visible = false;
            rowDecorator.layout.width = window.innerWidth * .6;
            rowDecorator.labelTitle.caption = "Recurring Order Schedule Assignments";
            rowDecorator.multiButton.dropdownItems = null;
        }
    }


}
