import { Image, Label, Overlay, Panel, Snackbar, Toast } from "@mcleod/components";
import { Api, HorizontalAlignment, ModelRow, StringUtil } from "@mcleod/core";
import { BrokerageTrackingActions } from "./BrokerageTrackingActions";

const LOAD_TRACK_VENDOR = "L";
const MACROPOINT_LITE_VENDOR = "M";
const FOURKITES_VENDOR = "F";
const PROJECT44_VENDOR = "P";

export class BrTrackingQuickInfoPanel extends Panel {
    private labelVendorNameHeading: Label = new Label({
        caption: "Vendor Name",
        fontSize: "12px",
        color: "default.light"
    });

    private labelVendorNameValue: Label = new Label({
        fontSize: "14px",
        fontBold: true
    });

    private labelTrackStatusHeader: Label = new Label({
        caption: "Tracking Status",
        fontSize: "12px",
        color: "default.light"
    });

    private labeTrackStatusValue: Label = new Label({
        fontSize: "14px",
        fontBold: true
    });

    private labelTrackDescHeader: Label = new Label({
        caption: "Tracking Desc",
        fontSize: "12px",
        color: "default.light"
    });

    private labeTrackDescValue: Label = new Label({
        fontSize: "14px",
        fontBold: true
    });

    private trackingActionsPanel: Panel = new Panel({
        padding: 0,
        paddingBottom: 0,
        align: HorizontalAlignment.LEFT,
        fillRow: true
    });

    private imageEmail: Image = new Image({
        name: "mail",
        visible: true,
        rowBreak: false,
        height: 22,
        width: 22,
        marginRight: 5,
        tooltip: "Send tracking message to driver"
    });

    private imagePencil: Image = new Image({
        name: "pencil",
        visible: true,
        rowBreak: false,
        height: 37,
        width: 37,
        tooltip: "Edit tracking"
    });

    private imageVendorTrackingIcon: Image = new Image({
        name: "shipmentLocation",
        visible: true,
        rowBreak: false,
        height: 22,
        width: 22,
        tooltip: ""
    });

    private imageCancel: Image = new Image({
        name: "circleX",
        visible: true,
        height: 22,
        width: 22,
        marginLeft: 3,
        tooltip: "Cancel tracking"
    });

    private editTrackingSlideoutProps = {
        width: 1200,
        height: window.innerHeight,
        backgroundColor: "defaultBackground",
        top: 30,
        padding: 0,
        borderRadius: 4,
        borderWidth: 1,
        borderShadow: true,
        borderColor: "strokeSecondary"
    };

    constructor(data: ModelRow<any>) {
        super();
        const brokerageTrackingActions = new BrokerageTrackingActions();

        this.align = HorizontalAlignment.LEFT;

        this.labelVendorNameValue.caption = data?.get("br_vendor", "--");
        this.labeTrackStatusValue.caption = data?.get("br_full_track_status", "--");
        this.labeTrackDescValue.caption = data?.get("br_track_status_desc", "--");

        this.imageEmail.setProps({
            onClick: () => brokerageTrackingActions
                .sendTrackingMessage(
                    data?.get("movement_id"),
                    data?.get("br_vendor"),
                    this.sendMessageOnClose,
                    this.sendMessageDoAfterMessageSent)
        });

        this.imagePencil.setProps({
            onClick: () => brokerageTrackingActions.editTracking(
                data?.get("movement_id"),
                data?.get("order_id"),
                this.editTrackingSlideoutProps)
        });
        this.imageVendorTrackingIcon.tooltip = setToolTipDetails(data?.get("br_vendor"));
        this.imageVendorTrackingIcon.setProps({
            onClick: () =>
                Api.search("lme/powerbroker/tracking/brokerage-tracking-logo",
                    {
                        "vendor_id": data?.get("br_vendor"),
                        "movement_id": data?.get("movement_id"),
                        "order_id": data?.get("order_id")
                    }).then(response => {
                        if (response != null && response.data != null) {
                            const onClickUrl = response?.data?.[0]?.on_click_url;
                            if (!StringUtil.isEmptyString(onClickUrl)) {
                                window.open(onClickUrl, '_blank', 'noreferrer');
                            } else {
                                Snackbar.showSnackbar("Tracking details not found", { millisUntilDimissal: 2000 })
                            }
                        }
                    })
        });

        this.imageCancel.setProps({
            onClick: () => brokerageTrackingActions.cancelTracking(
                data?.get("movement_id"),
                data?.get("br_vendor"))
        });

        const { brVendorId, whenFailedTo, whenFailedToAutomate, whenFailedToAutomateTracking, cancelBadge } = this.setFieldsForTrackingActionPanel(data);

        if (MACROPOINT_LITE_VENDOR === brVendorId || FOURKITES_VENDOR === brVendorId || LOAD_TRACK_VENDOR === brVendorId) {
            this.trackingActionsPanel.add(this.imageEmail);
        }

        if (MACROPOINT_LITE_VENDOR === brVendorId || LOAD_TRACK_VENDOR === brVendorId || PROJECT44_VENDOR === brVendorId) {
            this.trackingActionsPanel.add(this.imagePencil);
            this.trackingActionsPanel.add(this.imageVendorTrackingIcon);
        }
        if (FOURKITES_VENDOR === brVendorId) {
            this.trackingActionsPanel.add(this.imageVendorTrackingIcon);
        }

        if (!whenFailedTo && !whenFailedToAutomate && !whenFailedToAutomateTracking && !cancelBadge) {
            this.trackingActionsPanel.add(this.imageCancel);
        }

        this.add(
            this.labelVendorNameHeading,
            this.labelVendorNameValue,
            new Label({ visible: true }), //spacer
            this.labelTrackStatusHeader,
            this.labeTrackStatusValue,
            new Label({ visible: true }), //spacer
            this.labelTrackDescHeader,
            this.labeTrackDescValue,
            new Label({ visible: true }), //spacer
            this.trackingActionsPanel
        );
    }

    private setFieldsForTrackingActionPanel(data: ModelRow<any>) {
        const brVendorId = data?.get("br_vendor_id");
        const brokerageTrackStatusBadge = data?.get("br_track_status_for_badge");
        const brokerageFullTrackStatus = data?.get("br_full_track_status");
        const whenFailedToAutomate: boolean = brokerageFullTrackStatus === "Failed to automate";
        const whenFailedToAutomateTracking: boolean = brokerageFullTrackStatus === "Failed to automate tracking";
        const whenFailedTo: boolean = brokerageTrackStatusBadge === "Failed To ...";
        const cancelBadge: boolean = brokerageTrackStatusBadge === "Cancelled";
        return { brVendorId, whenFailedTo, whenFailedToAutomate, whenFailedToAutomateTracking, cancelBadge };
    }

    private sendMessageOnClose(overLay: Overlay): void {
        Overlay.hideOverlay(overLay);
    }

    private sendMessageDoAfterMessageSent(overlay: Overlay, response: any): void {
        Overlay.hideOverlay(overlay);
        const message = response?.data?.[0]?.toast_message;
        if (message) {
            Toast.showSuccessToast(message);
        }
    }

}
function setToolTipDetails(vendorId: String) {
    if (!StringUtil.isEmptyString(vendorId)) {
        return "View load status on " + vendorId + " website."
    }
    return "";
}

