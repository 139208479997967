import { Checkbox, DataDisplayEvent, DataSourceMode, Label, TableRow, TableRowDisplayEvent } from "@mcleod/components";
import { AutogenLayoutHandlingRequirementsCompareChangeItem } from "./autogen/AutogenLayoutHandlingRequirementsCompareChangeItem";
import { ModelRow, StringUtil } from "@mcleod/core";

export class HandlingRequirementsCompareChangeItem extends AutogenLayoutHandlingRequirementsCompareChangeItem {
    private _compareItems: ModelRow[];

    public async setupTableData(data: ModelRow[]) {
        this._compareItems = data;
    }

    override onLoad(): void | Promise<void> {
        this.tableHandlingChangeItems.dataSource = this.sourceEdiCompareItem;
        this.tableHandlingChangeItems.dataSource.data = [];
        if (this._compareItems != null) {
            if (this._compareItems.length === 0) {
                this.tableHandlingChangeItems.visible = false;
            } else {
                this.tableHandlingChangeItems.dataSource.setRowsAndMode(DataSourceMode.NONE, this._compareItems);
            }
        }
    }

    onItemRowDisplay(event: TableRowDisplayEvent) {
        this.printCheckbox(event.getTableRow());
    }

    printCheckbox(row: TableRow) {
        const checkbox = row.findComponentById("checkboxApply") as Checkbox;
        checkbox.printable = false;
        const labelChecked = row.findComponentById("labelChecked") as Label;
        checkbox.addClickListener(() => labelChecked.visible = checkbox.checked);
    }

    valueOnDisplay(event: DataDisplayEvent) {
        const label = event.target as Label;
        if (label.caption == "1900-01-01") {
            label.caption = "";
        }
    }

    onDescrDisplay(event: DataDisplayEvent) {
        const labelDescr = <Label>event.target
        if (StringUtil.isEmptyString(event.rowData.get("target_descr"))) {
            labelDescr.caption = event.rowData.get("source_descr");
        }
        if (event.rowData.get("handling_req_value") !== null && event.rowData.get("handling_req_value") !== undefined) {
            labelDescr.caption = event.rowData.get("handling_req_value");
        }
    }
}