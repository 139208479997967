import { Button, Component, DataDisplayEvent, DataSource, DataSourceExecutionEvent, DataSourceMode, Label, Table, TableRow, TableRowDisplayEvent, Textbox } from "@mcleod/components";
import { Alignment, Api, McLeodClassicIntegration, ModelRow, Navigation, StringUtil, getLogger } from "@mcleod/core";
import { AutogenLayoutShipmentStatusSlideout } from "./autogen/AutogenLayoutShipmentStatusSlideout";
import { CommonTooltips } from "@mcleod/common";

const log = getLogger("lme.dispatch.ShipmentStatusSlideout");

export class ShipmentStatusSlideout extends AutogenLayoutShipmentStatusSlideout {

    private _orderId;
    private _movementId;
    private _customerId;
    private _filterByMovement = false;
    private _saveButton = new Button({
        id: "",
        caption: "Save",
        backgroundColor: "primary",
        color: "primary.reverse",
        minWidth: 128,
        borderWidth: 0,
        rowBreak: false,
        enabled: false,
        tooltip: "Updates to the status record will be evaluated for corrections and resends."
    });

    public inboundRows: ModelRow[];
    public outboundRows: ModelRow[];
    public inboundDataSource: DataSource = new DataSource();
    public outboundDataSource: DataSource = new DataSource();
    public inboundTenderRows: ModelRow[];
    public outboundTenderRows: ModelRow[];
    public inboundTenderDataSource: DataSource = new DataSource();
    public outboundTenderDataSource: DataSource = new DataSource();

    override onLoad(): void {
        this.tableOutbound.toolsPanel.rowBreak = false;
        this.tableInbound.toolsPanel.rowBreak = false;
        this.sourceOrderEdistatus.search({ order_id: this.orderId });
        this.sourceEdiOrder.search({ order_id: this.orderId });
        this.sourceEdiMovementCarrier.search({ movement_id: this.movementId });
        this.sourceEdiCustomer.search({ customer_id: this.customerId, transaction_type: "S" });
    }

    delayCodeBeforeLookupModelSearch(event) {
        const delayCode = event.target as Textbox;
        const currRow = delayCode.boundRow?.data;
        const eventType = currRow["event_type"];
        let codeType = "DelayReason";

        if (eventType == "AP") {
            codeType = "AppointmentReason";
        }

        event.filter.stop_id = currRow["curr_stop_id"];
        event.filter.customer_id = currRow["customer_id"];
        event.filter.code_type = codeType;
        event.filter.partner_id = currRow["partner_id"];
        event.filter.version = currRow["version"];
    }

    sourceOrderEdistatusAfterExecution() {
        this.outboundRows = this.sourceOrderEdistatus.data.filter((row) => row.data["direction"] === "O");
        this.outboundDataSource.data = this.outboundRows;
        this.tableOutbound.dataSource = this.outboundDataSource;
        this.tableOutbound.dataSource.mode = DataSourceMode.UPDATE;
        this.tableOutbound.dataSource.displayDataInBoundComponents();

        this.inboundRows = this.sourceOrderEdistatus.data.filter((row) => row.data["direction"] === "I");
        this.inboundDataSource.data = this.inboundRows;
        this.tableInbound.dataSource = this.inboundDataSource;
        this.tableInbound.dataSource.displayDataInBoundComponents();
    }

    sourceEdiOrderAfterExecution() {
        if (this.filterByMovement) {
            this.outboundTenderRows = this.sourceEdiOrder.data.filter((row) => row.data["direction"] === "O" && row.data["shipment_id"] === this.movementId);
            this.filterByMovement = false;
        } else {
            this.outboundTenderRows = this.sourceEdiOrder.data.filter((row) => row.data["direction"] === "O");
        }
        this.outboundTenderDataSource.data = this.outboundTenderRows;
        const tableOutboundTenders = this.tabOutboundTender.findComponentById("tableOutboundTenders");
        tableOutboundTenders.dataSource.mode = DataSourceMode.UPDATE;
        tableOutboundTenders.dataSource = this.outboundTenderDataSource;
        tableOutboundTenders.dataSource.displayDataInBoundComponents();

        this.inboundTenderRows = this.sourceEdiOrder.data.filter((row) => row.data["direction"] === "I");
        this.inboundTenderDataSource.data = this.inboundTenderRows;
        const tableInboundTenders = this.tabInboundTender.findComponentById("tableInboundTenders") as Table;
        tableInboundTenders.dataSource.mode = DataSourceMode.UPDATE;
        tableInboundTenders.dataSource = this.inboundTenderDataSource;
        tableInboundTenders.dataSource.displayDataInBoundComponents();
        this.tabInboundTender.visible = this.inboundTenderRows.length > 0;
        tableInboundTenders.dataSource.orderBy = [{ field: "create_date", sort: "desc" }];
        tableOutboundTenders.dataSource.orderBy = [{ field: "create_date", sort: "desc" }];
    }

    async textboxOutDelayCodeOnBlur(event) {
        const statusRow = event.target.row as TableRow;
        const row = statusRow.data as ModelRow;

        if (!row.hasChanged()) {
            log.debug("row has not changed");
            return;
        }

        const statusId = row.get("id");
        const delayCode = row.get("delay_code");

        await Api.search("lme/datafusion/validate-status", { status_id: statusId, in_delay_code: delayCode }).then((result) => {
            const errorCount = result.data[0]["error_count"];
            const errorIcon = statusRow.findComponentById("labelError") as Label;

            if (errorCount == "0 Errors") {
                errorIcon.imageName = "circleCheckEmpty";
                errorIcon.color = "success";
                errorIcon.caption = errorCount;
            }
            else {
                errorIcon.imageName = "warning-bold";
                errorIcon.color = "error";
                errorIcon.caption = errorCount;
            }
            this.saveButton.enabled = true;
        });
    }

    async textboxOutTrailerOnBlur(event) {
        const statusRow = event.target.row as TableRow;
        const row = statusRow.data as ModelRow;

        if (!row.hasChanged()) {
            log.debug("row has not changed");
            return;
        }

        this.saveButton.enabled = true;
    }

    tableOutboundOnRowDisplay(event: DataDisplayEvent) {
        const currRow = event.target as TableRow;
        currRow.canBeDeleted = false;
        const currModelRow = currRow.data as ModelRow;
        const errorCount = currModelRow.get("error_count");
        const errorIcon = currRow.owner.labelError;
        const eventType = currModelRow.get("event_type");
        const partnerName = currRow.findComponentById("labelPartnerOut") as Label;
        this.makeTooltipCallbackFunction(currModelRow, partnerName);

        const labelStopOut = currRow.findComponentById("labelStopOut") as Label;
        const labelStatusOut = currRow.findComponentById("labelStatusOut") as Label;
        const labelStatusDateOut = currRow.findComponentById("labelStatusDateOut") as Label;
        this.makeTooltipCallbackFunction(currModelRow, labelStopOut);
        this.makeTooltipCallbackFunction(currModelRow, labelStatusOut);
        this.makeTooltipCallbackFunction(currModelRow, labelStatusDateOut);


        let codeType = "DelayReason";

        const labelTransmitted = currRow.findComponentById("labelTransmissionStatus") as Label;
        if (labelTransmitted.caption !== "Not Sent") {
            this.makeTooltipCallbackFunction(currModelRow, labelTransmitted);
        }

        if (eventType == "AP") {
            codeType = "AppointmentReason";
        }

        if (errorCount == "0 Errors") {
            errorIcon.imageName = "circleCheckEmpty";
            errorIcon.color = "success";
        }
        else {
            errorIcon.imageName = "warning-bold";
            errorIcon.color = "error";
            this.makeTooltipCallbackFunction(currModelRow, errorIcon);
        }

        const currDelay = currModelRow.get("delay_code");
        const delayCode = currRow.findComponentById("textboxOutDelayCode") as Textbox;
        this.makeTooltipCallbackFunction(currModelRow, delayCode);

        if (currDelay != null) {
            this.getDelayCode(currDelay, currModelRow.get("version")).then(async (delayRow) => {
                this.sourceOrderEdistatus.activeRow?.setLookupModelData("delay_code", delayRow);
                delayCode.text = delayRow.data[0]?.["standard_code"];
            });
        }
    }

    checkInMapErrors(event: TableRowDisplayEvent) {
        const currRow = event.target as TableRow;
        const currModelRow = currRow.data;
        const errorCount = currModelRow.get("error_count");
        const errorIcon = currRow.owner.labelInboundError;

        if (errorCount == "0 Errors") {
            errorIcon.imageName = "circleCheckEmpty";
            errorIcon.color = "success";
        }
        else {
            errorIcon.imageName = "warning-bold";
            errorIcon.color = "error";
            this.makeTooltipCallbackFunction(currModelRow, errorIcon);
        }
        const partnerName = currRow.findComponentById("labelInboundPartner") as Label;
        this.makeTooltipCallbackFunction(currModelRow, partnerName);

        const labelInboundStop = currRow.findComponentById("labelInboundStop") as Label;
        const labelInboundStatusDesc = currRow.findComponentById("labelInboundStatusDesc") as Label;
        const labelInboundStatusDate = currRow.findComponentById("labelInboundStatusDate") as Label;
        this.makeTooltipCallbackFunction(currModelRow, labelInboundStop);
        this.makeTooltipCallbackFunction(currModelRow, labelInboundStatusDesc);
        this.makeTooltipCallbackFunction(currModelRow, labelInboundStatusDate);

        const delayCode = currRow.findComponentById("labelInboundDelay") as Label;
        this.makeTooltipCallbackFunction(currModelRow, delayCode);
    }

    async getDelayCode(currDelay: string, version: string): Promise<ModelRow> {
        return await Api.search("lme/datafusion/delay-code", { delay_code: currDelay, version: version });
    }

    buttonOpenClassicOutStatuses() {
        McLeodClassicIntegration.openClassicScreen("com.tms.client.loadmaster.edi.EntryEdiStatus", "<ext> search order_id='" + this.orderId + "' and direction = 'O'");
    }

    buttonOpenClassicInStatuses() {
        McLeodClassicIntegration.openClassicScreen("com.tms.client.loadmaster.edi.EntryEdiStatus", "<ext> search order_id='" + this.orderId + "' and direction = 'I'");
    }

    makeTooltipCallbackFunction(modelRow: ModelRow, component: Component) {
        if (component.id === null) return;
        switch (component.id) {
            case "labelError":
            case "labelInboundError":
                return CommonTooltips.setTooltipFromLayoutCallback(component, null, "lme/datafusion/StatusErrorQuickInfo", null, { position: Alignment.TOP },
                    {
                        "parent_id": modelRow.get("id"), "parent_tablename": "edistatus"
                    });
            case "textboxOutDelayCode":
            case "labelInboundDelay":
                return CommonTooltips.setTooltipFromLayoutCallback(component, null, "lme/datafusion/SrcQuickInfo", null, { position: Alignment.TOP },
                    {
                        "standard_code": modelRow.get("delay_code")
                    });
            case "labelTransmissionStatus":
                return CommonTooltips.setTooltipFromLayoutCallback(component, null, "lme/datafusion/TransmissionStatusQuickInfo", null, { position: Alignment.LEFT },
                    {
                        "direction": modelRow.get("direction"),
                        "batch": modelRow.get("batch"),
                        "func_receiver_id": modelRow.get("partner_id"),
                        "receiver_id": modelRow.get("alt_partner_id"),
                        "func_version": modelRow.get("version"),
                        "transaction_type": "S"
                    });
            case "labelPartnerOut":
            case "labelInboundPartner":
            case "labelStopOut":
            case "labelStatusOut":
            case "labelStatusDateOut":
            case "labelInboundStop":
            case "labelInboundStatusDesc":
            case "labelInboundStatusDate":
                return CommonTooltips.setTooltipFromLayoutCallback(component, null, "lme/datafusion/StatusQuickInfo", null, { position: Alignment.TOP },
                    {
                        "id": modelRow.get("id")
                    });

        }

    }

    set orderId(value: string) {
        this._orderId = value;
    }

    get orderId(): string {
        return this._orderId;
    }

    public get movementId() {
        return this._movementId;
    }

    public set movementId(value) {
        this._movementId = value;
    }

    public get customerId() {
        return this._customerId;
    }

    public set customerId(value) {
        this._customerId = value;
    }

    public get filterByMovement(): boolean {
        return this._filterByMovement;
    }

    public set filterByMovement(value: boolean) {
        this._filterByMovement = value;
    }

    public get saveButton() {
        return this._saveButton;
    }

    public set saveButton(value: Button) {
        this._saveButton = value;
    }

    /** This is an event handler for the afterExecution event of sourceEdiMovementCarrier.  */
    sourceEdiMovementCarrierAfterExecution(event: DataSourceExecutionEvent) {
        const isLoadTenderCarrier = this.sourceEdiMovementCarrier?.activeRow?.data["is_loadtender_carrier"];
        const isStatusCarrier = this.sourceEdiMovementCarrier?.activeRow?.data["is_status_carrier"];

        this.tabOutboundTender.visible = isLoadTenderCarrier;
        this.tabInbound.visible = isStatusCarrier;
    }



    /** This is an event handler for the afterExecution event of sourceEdiCustomer.  */
    sourceEdiCustomerAfterExecution(event: DataSourceExecutionEvent) {
        const ediCustomer = this.sourceEdiCustomer?.activeRow?.data["customer_id"];
        this.tabOutbound.visible = !StringUtil.isEmptyString(ediCustomer);
    }
}
