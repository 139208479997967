/*******************************************************************************
 *                                                                             *
 * WARNING! This file was automatically generated from the server-side model!  *
 * You shouldn't edit this by hand.  If you want to modify the client-side     *
 * model behavior, you should modify: ../ModelEquipmentType.ts                 *
 *                                                                             *
/*******************************************************************************/

import { Endpoint, ModelRow, RowConstructor } from "@mcleod/core";

const AutogenUserSavedSearchEndpoint = "lme/dispatch/equipment-type";

export class AutogenModelEquipmentType<RowType extends ModelRow> extends Endpoint<RowType, RowEquipmentTypeFields> {
    constructor(ctor: RowConstructor) {
        super(AutogenUserSavedSearchEndpoint, ctor);
    }
}

export class AutogenRowEquipmentType extends ModelRow<RowEquipmentTypeFields> {
    constructor() {
        super(AutogenUserSavedSearchEndpoint);
    }
}

export interface RowEquipmentTypeFields {
    applies_to: string;
    company_id: string;
    descr: string;
    equip_length: number;
    equip_width: number;
    exchange_type: string;
    fms_length: string;
    fms_type: string;
    id: string;
    picture_resource: string;
    pnn_code_id: string;
    web_order: number;
}

