import { ClickEvent, Toast } from "@mcleod/components";
import { LookupModelSearchListener } from "@mcleod/components/src/events/LookupModelSearchEvent";
import { Api, ModelRow, StringUtil, getLogger } from "@mcleod/core";
import { CarrierAssignment } from "./CarrierAssignment";
import { AutogenLayoutSendRateConfirmation } from "./autogen/AutogenLayoutSendRateConfirmation";

const log = getLogger("lme.powerbroker.SendConfirmation");

export class SendRateConfirmation extends AutogenLayoutSendRateConfirmation {
    public doAfterRateConSent: () => void;
    override onLoad() {
        this.mainDataSource.addAfterExecutionListener((event) => {
            if (this.mainDataSource.data != null && this.mainDataSource.data.length > 0) {
                const orderId = this.mainDataSource.data[0].get("order_id");
                if (orderId != null)
                    this.labelHeaderTitle.caption = "Send Rate Confirmation - Order " + orderId;

                this.textboxContactName.onSelectItem = ((textbox, selection) => {
                    this.textboxEmailAddress.text = (selection as ModelRow).get("email");
                    return undefined;
                })

                const carrierId: string = this?.activeRow?.get("override_payee_id");
                const contactBeforeSearchListener = this.getContactBeforeLookupListener(carrierId);
                this.textboxContactName.addBeforeLookupModelSearchListener(contactBeforeSearchListener);

                if (StringUtil.isEmptyString(this.textboxSubject.text))
                    this.textboxSubject.text = this?.activeRow?.get("default_subject");

                if (StringUtil.isEmptyString(this.textboxComment.text))
                    this.textboxComment.text = this?.activeRow?.get("default_comment");

                this.displayData(this?.activeRow, null, 0);
            }
        });
        this.buttonSendLater.addClickListener(event => {
            this.mainDataSource.cancel();
        });
    }

    hasChangedData(layout: CarrierAssignment) {
        return this.activeRow != null && (this.activeRow.hasChanged() ||
            this?.activeRow?.get("default_subject") != this.textboxSubject.text ||
            this?.activeRow?.get("default_comment") != this.textboxComment.text ||
            this?.activeRow?.get("default_contact") != layout.activeRow.get("carrier_contact") ||
            this?.activeRow?.get("default_email_address") != layout.activeRow.get("carrier_email"))
    }

    private getContactBeforeLookupListener(payee_id: string): LookupModelSearchListener {
        return event => {
            event.filter.parent_row_type = "P";
            event.filter.parent_row_id = payee_id ? payee_id : null;
        }
    }

    /** This is an event handler for the onClick event of buttonSend.  */
    buttonSendOnClick(event: ClickEvent) {
        if (this.validateSimple()) {
            this.buttonSend.busy = true;
            const body =
            {
                movement_id: this.mainDataSource.data[0].get("movement_id"),
                comment_text: this.textboxComment.text,
                template_number: this.textboxDefaultTemplate.getFirstLookupModelData().data["template_number"],
                contact_name: this.textboxContactName.text,
                subject: this.textboxSubject.text,
                email_address: this.textboxEmailAddress.text
            }
            Api.post(this.mainDataSource.url, body, null, null).then(response => {
                log.info("posted");
                this.mainDataSource.cancel();
                Toast.showSuccessToast("Rate Confirmation Sent");
                setTimeout(() => {
                    if (this.doAfterRateConSent != null)
                        this.doAfterRateConSent();
                }, 2000);
            }).finally(() => this.buttonSend.busy = false);
        }
    }
}
