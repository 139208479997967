import { Button, ClickEvent, Component, DataSourceMode, DropdownItem, Label, Panel, TableRow, TableRowDisplayEvent, TableRowMode, Textbox } from "@mcleod/components";
import { ModelRow, StringUtil } from "@mcleod/core";
import { makeTooltipCallbackFunction } from "@mcleod/dispatch/src/LocationMakeQuickInfo";
import { EdiStopNotesAndReferenceNumbers } from "./EdiStopNotesAndReferenceNumbers";
import { AutogenLayoutEdiMultiStop } from "./autogen/AutogenLayoutEdiMultiStop";

const DATE_ON = { caption: "On", value: "O" };
const DATE_BETWEEN = { caption: "Between", value: "B" };

export class EdiMultiStop extends AutogenLayoutEdiMultiStop {
    public get stopDateTypeOn(): DropdownItem { return DATE_ON }
    public get stopDateTypeBetween(): DropdownItem { return DATE_BETWEEN }
    private distanceBadgeArray: boolean[] = [];
    commentsAndRefNbrs: EdiStopNotesAndReferenceNumbers;

    setupTableRowListeners(tableRow: TableRow) {
        const btnExpandRow = tableRow.findComponentById("btnExpandRow") as Button;
        btnExpandRow.addClickListener(event => this.btnExpandRowOnClick(event));
    }

    /** This is an event handler for the onRowDisplayEvent of tableStop */
    tableEdiStopOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow: TableRow = event.target as TableRow;
        const modelRow: ModelRow = tableRow.data as ModelRow;
        tableRow.hideDragHandle();
        tableRow.table.dataSource.preventChangeNotifications = true;
        tableRow.table.rows[tableRow.index].borderBottomWidth = 0;
        tableRow["appointmentChangeData"] = null;
        this.setupTableRowListeners(tableRow);

        const locationName: Component = tableRow.findComponentById("textboxLocationName");
        const contactName: Textbox = tableRow.findComponentById("textboxContactName") as Textbox;
        const contactPhone: Textbox = tableRow.findComponentById("textboxPhone") as Textbox;
        const appointmentStatus: Textbox = tableRow.findComponentById("textboxAppointmentStatusId") as Textbox;
        const between: Textbox = tableRow.findComponentById("textboxStopDateType") as Textbox;
        const distanceBadge: Label = tableRow.findComponentById("labelDistanceBadge") as Label;

        if (StringUtil.isEmptyString(modelRow.get("contact_name")))
            contactName.text = "";

        if (StringUtil.isEmptyString(modelRow.get("phone")))
            contactPhone.text = "";

        if (StringUtil.isEmptyString(modelRow.get("appointment_status_id")))
            appointmentStatus.text = "";
        else appointmentStatus.text = modelRow.get("appointment_status_id");


        // if current stop or delivered - solid orange
        // all other badges clear
        const orderSequence = modelRow.get("order_sequence");
        this.distanceBadgeArray[orderSequence] = (modelRow.get("actual_arrival") != null || modelRow.get("actual_departure") != null);

        if (orderSequence != 1) {
            if (this.distanceBadgeArray[orderSequence - 1] == true) {
                distanceBadge.backgroundColor = "warning";
                distanceBadge.color = "warning.reverse";
                distanceBadge.borderWidth = 0;
            }
            else {
                distanceBadge.backgroundColor = "warning.reverse";
                distanceBadge.color = "warning";
                distanceBadge.borderWidth = 1;
            }
        }

        if (locationName != null)
            locationName.tooltipCallback = makeTooltipCallbackFunction(modelRow.get("location_id"), locationName);

        if (modelRow.get("actual_departure") != null)
            between.visible = false;
        else if (modelRow.get("sched_arrive_late") == null)
            between.text = "On";
        else
            between.text = "Between";

        const early: Textbox = tableRow.findComponentById("textboxSchedArriveEarly") as Textbox;
        const late: Textbox = tableRow.findComponentById("textboxSchedArriveLate") as Textbox;
        const actualArrive: Textbox = tableRow.findComponentById("textboxActualArrival") as Textbox;
        const actualDepart: Textbox = tableRow.findComponentById("textboxActualDeparture") as Textbox;

        if (modelRow.get("actual_arrival") == null) {
            if (actualArrive != null)
                actualArrive.visible = false;
            if (actualDepart != null)
                actualDepart.visible = false;
            if (early != null)
                early.visible = true;
            if (late != null)
                late.visible = true && between.text == "Between";
        }
        else {
            if (actualArrive != null)
                actualArrive.visible = true;
            if (actualDepart != null)
                actualDepart.visible = true && between.text == "Between";
            if (early != null)
                early.visible = false;
            if (late != null)
                late.visible = false;

            tableRow.mode = TableRowMode.NONE;
            tableRow.canBeEdited = false;
        }

        const labelDistanceBadge: Label = tableRow.findComponentById("labelDistanceBadge") as Label;
        if (modelRow.get("dist_from_prev") != null) {
            labelDistanceBadge.visible = true;
            labelDistanceBadge.caption = modelRow.get("dist_from_prev") + " MILES BETWEEN STOPS";
        } else {
            labelDistanceBadge.visible = false;
            labelDistanceBadge.caption = "";
        }

        this.setAppointmentLabel(tableRow, modelRow, "labelApptImage", "confirmed", "appt_required");

        tableRow.canBeDeleted = false;
    }

    /** This is an event handler for the onClick event of btnExpandRow. */
    btnExpandRowOnClick(event: ClickEvent) {
        const tableRow: TableRow = TableRow.getContainingTableRow(event.target as Component);
        const row = tableRow.data;
        const expandablePanel: Component = tableRow.findComponentById("expandablePanel");
        tableRow["custom_expanded"] = !tableRow["custom_expanded"] ?? true;
        event.target["imageRotation"] = tableRow["custom_expanded"] ? 0 : 90;
        expandablePanel.visible = tableRow["custom_expanded"];

        if (tableRow["custom_expanded"]) {
            const panelEdiStopCommentsReferenceNumbers = tableRow.findComponentById("panelEdiStopCommentsReferenceNumbers") as Panel;
            const ediStopCommentRefNbr: EdiStopNotesAndReferenceNumbers = panelEdiStopCommentsReferenceNumbers.components[0] as EdiStopNotesAndReferenceNumbers;
            // Initializing the EdiStopNotesAndReferenceNumbers component
            const ediStopRow = new ModelRow(this.sourceEdiStop.url, true, row.data);
            ediStopCommentRefNbr.stopRow = ediStopRow;
            this.setupEdiCommentRefNbrTables(ediStopCommentRefNbr);

            const textboxContactName = tableRow.findComponentById("textboxContactName") as Textbox;
            textboxContactName.text = row.get("contact_name");

            // Populate the location website
            if (row.data.location != null && row.data.location[0].website_url != null) {
                let url = row.data.location[0].website_url;
                if (!url.startsWith("http:") && !url.startsWith("https")) {
                    url = "https://" + url;
                }
                row.set("location_website", url);
            }
        }
    }

    setupEdiCommentRefNbrTables(ediStopCommentRefNbr: EdiStopNotesAndReferenceNumbers) {
        const tableEdiStopComment = ediStopCommentRefNbr.tableStopComments;
        const tableEdiStopRefNbr = ediStopCommentRefNbr.tableStopReferenceNumbers;
        tableEdiStopComment.dataSource.parentDataSource = this.sourceEdiStop;
        tableEdiStopRefNbr.dataSource.parentDataSource = this.sourceEdiStop;
        tableEdiStopComment.dataSource.mode = DataSourceMode.NONE;
        tableEdiStopRefNbr.dataSource.mode = DataSourceMode.NONE;
    }

    private setAppointmentLabel(tableRow: TableRow, modelRow: any, labelId: string, confirmedField: string, requiredField: string) {
        const labelAppt = tableRow.findComponentById(labelId) as Label;
        const switchConfirmed = tableRow.findComponentById("switchConfirmed");
        labelAppt.imageMarginLeft = 0;
        const apptRequired = modelRow.getBoolean(requiredField);
        if (apptRequired) {
            if (modelRow.getBoolean(confirmedField)) {
                labelAppt.caption = "Confirmed";
                labelAppt.imageColor = "green";
            } else {
                labelAppt.caption = "Required";
                labelAppt.imageColor = "Orange";
            }
            labelAppt.imageName = "clock";
        }
        labelAppt.visible = apptRequired;
        switchConfirmed.visible = apptRequired;
    }

}
