import { CommonDialogs, CommonTooltips } from "@mcleod/common";
import {
    Button, Component, DataDisplayEvent, DataSourceMode, Label, LookupModelSearchEvent, SlideoutDecorator,
    TableRowBeforeSaveEvent, TableRowDisplayEvent, TableRowMode, Textbox
} from "@mcleod/components";
import { StringUtil } from "@mcleod/core";
import { AutogenLayoutOrderPostHist } from "./autogen/AutogenLayoutOrderPostHist";

const REQUIRE_REASON_TYPES = [
    "B", // unassign tractor / driver type
    "Z", // feasibility type
    "V", // void order type
];

export class OrderPostHist extends AutogenLayoutOrderPostHist {

    private movementId: string;
    private orderId: string;

    public static showInSlideout(orderType: string, orderId: string, movementId: string, mode: DataSourceMode, button: Button) {
        if (button) button.busy = true;

        OrderPostHist.getLayout("lme/dispatch/OrderPostHist", { width: window.innerWidth * .8 }).addLayoutLoadListener(event => {
            const layout = event.target as OrderPostHist;
            layout.initialize(orderType, orderId, movementId).then(() => {
                layout.width = window.innerWidth * .7;
                // focusing on a component while sliding out results in components shifting around
                // Table.ts will default focus to the search filter if the layout's first focusable
                // component is the table. Add some hidden component until CWG fixes this
                layout.insert(new Button({ visible: false }), 0);
                new SlideoutDecorator({
                    layout: layout,
                    title: layout.getSlideoutTitle(orderType) + " - Order " + orderId,
                    titleImage: undefined,
                    fillVerticalSpace: true,
                    doAfterSlideIn: (decorator: SlideoutDecorator) => {
                        if (button) button.busy = false;
                        layout.tableOrderPostHist.dataSource.mode = mode;
                    }
                });
            }).catch(error => {
                CommonDialogs.showError(error);
                if (button) button.busy = false;
            })
        })
    }

    private getSlideoutTitle(orderType: string) {
        return orderType === "QUOTE" ? "Quote History" : "Order History";
    }

    public initialize(orderType: string, orderId: string, movementId: string): Promise<any> {
        const isQuote = orderType === "QUOTE";
        if (orderId != null && (movementId != null || isQuote)) {
            this.orderId = orderId;
            this.movementId = movementId;
            this.tableOrderPostHist.persistChangesImmediately = true;
            if (isQuote) {
                this.tableOrderPostHist.dataSource = this.sourceQuotePostHist;
                this.tableOrderPostHist.removeColumn(this.tableOrderPostHist.columns.findIndex(column => column.headingCell.caption === "Movement"));
            }
            return this.tableOrderPostHist.dataSource.search({ order_id: orderId });
        } else {
            return Promise.reject("orders.id and movement.id is required");
        }
    }

    /** This is an event handler for the onRowDisplay event of tableOrderPostHist.  */
    tableOrderPostHistOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        tableRow.canBeDeleted = false;
        const textboxReasonCode = tableRow.findComponentById("textboxReasonCode") as Textbox;
        const modelRow = tableRow.data;

        const textboxPostedType = tableRow.findComponentById("textboxPostedType") as Textbox;
        textboxPostedType.addBeforeLookupModelSearchListener((event: LookupModelSearchEvent) => {
            event.filter.allow_manual_entry = "Y"
        });

        textboxPostedType.enabled = modelRow._appending;
        textboxReasonCode.enabled = !modelRow._appending && REQUIRE_REASON_TYPES.indexOf(modelRow.get("posted_type")) > -1;
        if (textboxReasonCode.enabled)
            textboxReasonCode.required = true;

        textboxReasonCode.addBeforeLookupModelSearchListener((event: LookupModelSearchEvent) => {
            event.filter.code_type = modelRow.get("posted_type");
        });

        if (tableRow.mode === TableRowMode.QUICK_ADD && this.movementId)
            (tableRow.findComponentById("labelMovement") as Label).caption = this.movementId;
    }

    tableOrderPostHistBeforeRowSave(event: TableRowBeforeSaveEvent) {
        const data = event.getTableRow().data;
        if (data._appending) {
            data.set("movement_id", this.movementId);
            data.set("order_id", this.orderId);
        }
    }

    labelPostedByOnDataDisplay(event: DataDisplayEvent) {
        const labelPostedBy = event.target as Label;
        CommonTooltips.setTooltipFromLayoutCallback(labelPostedBy, event.rowData?.get("posted_by"), "common/UserQuickInfo");
    }

    internalIsEmpty(component: Component) {
        if (component == null) return true;
        if (component instanceof Textbox) return StringUtil.isEmptyString(component.text);
        if (component instanceof Label) return StringUtil.isEmptyString(component.caption);
        return false;
    }
}
