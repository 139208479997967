import {
    Button, ChangeEvent, ClickEvent, DataDisplayEvent, DataSourceMode, Label, Panel, Tab, Toast, ValidationResult
} from "@mcleod/components";
import { ReflectiveDialogs } from "@mcleod/components/src/base/ReflectiveDialogs";
import {
    Api, ArrayUtil, CurrencyUtil, DatePart, DateUtil, DisplayType, DisplayValue, FieldUpdateEvent, getAuthSettings,
    getLogger, ModelRow, StringUtil
} from "@mcleod/core";
import { ModelRowType } from "@mcleod/core/src/ModelRow";
import { ExpandableFilterController } from "@mcleod/general/src/ExpandableFilterController";
import { AutogenLayoutTopMatchList } from "./autogen/AutogenLayoutTopMatchList";

const log = getLogger("lme.powerbroker.TopMatchList");
enum SEARCH_TYPES {
    CARRIER_TERMINAL = "T",
    CARRIER_LANE = "L",
    LOWEST_RATE = "R",
    AVAIL_TRACTOR = "A",
    NUM_MOVES = "N",
    AVG_MARGIN = "M",
    CARRIER_OFFERS = "O",
    LOADBOARDS = "B"
}
export class TopMatchList extends AutogenLayoutTopMatchList {
    private _acTimeoutHandle: number;
    private searchMovement = true;
    private _enableLoadboards = false;
    private expandableFilter: ExpandableFilterController = new ExpandableFilterController();

    override async onLoad() {
        this.citystateShipper.textCombined.text = "--";
        this.citystateConsignee.textCombined.text = "--";
        this.setupExpandableFilter();
        this.initializeTabs(); // Must be initialized before defaults are set
        await this.setDefaultValues();
        await this.checkProfilePermissions();

        this.textboxMovementId.onSelectItem = (textbox, selectedItem) => {
            if (!StringUtil.isEmptyString(selectedItem["value"])) {
                this.setMovement();  // "this" is the textbox
            } else {
                this.sourceMovement.clear();
                this.sourceMovement.displayDataInBoundComponents();
                const labelSummary: Label = this.panelOverview.findComponentById("labelStopSummary") as Label;
                labelSummary.caption = null;
            }

            return selectedItem["value"];
        };

        if (this["autoSearch"]) {
            const results = this.panelSearchFilter.validate(true, false, true);
            if (results == null || results.filter(r => !r.isValid).length == 0)
                this.search();
        }
        this.buttonClear.enabled = false;
        this.sourceTopMatch.searchRow.addAfterFieldUpdateListener((event: FieldUpdateEvent) => this.buttonClear.enabled = this.sourceTopMatch.searchRow.hasChanged());
    }

    private initializeTabs() {
        this.layoutTopMatch.initialize(this);
        this.layoutLoadBoards.initialize(this);
        this.layoutRoutingGuides.initialize(this);
    }

    async defaultDateRange() {
        const firstDateString = DisplayValue.getDisplayValue(DateUtil.dateAdd(DatePart.DAY, new Date(), -60), DisplayType.DATERANGE, this.textboxDateRange.format);
        const secondDateString = DisplayValue.getDisplayValue(new Date(), DisplayType.DATERANGE, this.textboxDateRange.format);
        this.textboxDateRange.text = firstDateString + "-" + secondDateString;
    }

    // START - Set defaults
    async setDefaultsFromDataSource(searchMovement = false): Promise<void> {
        this.searchMovement = searchMovement;
        if (this.mainDataSource?.activeRow != null) {
            await Promise.all([
                this.setDefaultOrderId(this.activeRow.get("movement_order.order_id")),
                this.setDefaultMovementId(this.activeRow.get("id")),
                this.setDefaultTrailerType(),
                this.setDefaultTopMatchProfile()
            ]);
        }
    }

    async checkProfilePermissions() {
        if (!StringUtil.isEmptyString(this.textboxTopMatchProfile.text)) {
            const results = await Api.search("lme/powerbroker/topmatch-profile-details", { integratedsearch_profile_id: this.textboxTopMatchProfile.text });
            if (results.data.length > 0) {
                this._enableLoadboards = false;
                results.data.forEach(row => {
                    if (row.search_type === SEARCH_TYPES.LOADBOARDS) {
                        this._enableLoadboards = true;
                    }
                })
                this.tabLoadBoards.visible = this._enableLoadboards;
            }
        }
    }

    async setDefaultValues() {
        let orderID = null;
        let movementID = null;
        let trailerType;
        let tmProfile;
        if (this["orderId"] != null) orderID = this["orderId"];
        if (this["movementId"] != null) movementID = this["movementId"];
        if (this["trailerType"] != null) trailerType = this["trailerType"];
        if (this["profile"] != null) tmProfile = this["profile"];

        await Promise.all([
            this.setDefaultOrderId(orderID),
            this.setDefaultTabTitles(),
            this.setDefaultMovementId(movementID),
            this.setDefaultTrailerType(trailerType),
            this.setDefaultTopMatchProfile(tmProfile),
            this.defaultDateRange(),
        ]);
    }

    async setDefaultOrderId(orderID?: string) {
        if (orderID != null)
            this.textboxOrderId.text = orderID;

        return Promise.resolve();
    }

    async setDefaultTabTitles(): Promise<void> {
        this.layoutTopMatch.movement = null;
        this.layoutTopMatch.updateTabTitle = (rowCount: number) => this.updateTabTitle(this.tabTopMatch, "TopMatch", rowCount);
        this.layoutLoadBoards.updateTabTitle = (rowCount: number) => this.updateTabTitle(this.tabLoadBoards, "Load Boards", rowCount);
        this.layoutRoutingGuides.updateTabTitle = (rowCount: number) => this.updateTabTitle(this.tabRoutingGuides, "Routing Guides", rowCount);
        return Promise.resolve();
    }

    private updateTabTitle(tab: Tab, caption: string, rowCount: number) {
        if (rowCount != null)
            caption += ` ${rowCount}`;
        tab.caption = caption;
    }

    async setDefaultMovementId(movementID?: string): Promise<void> {
        if (movementID != null) {
            const options = [{ caption: movementID, value: movementID }];
            this.textboxMovementId.items = options;
            this.textboxMovementId.selectedItem = options[0];
            await this.setMovement();
            return Promise.resolve();
        } else if (!StringUtil.isEmptyString(this.textboxOrderId.text)) {
            await this.setMovementIdOptions();
            return Promise.resolve();
        }
    }

    async setDefaultTrailerType(trailerType?: string): Promise<void> {
        if (trailerType != null) {
            const lmData = new ModelRow(this.textboxTrailerType.lookupModel, false, { "id": trailerType });
            lmData.type = ModelRowType.LOOKUP_MODEL_DATA;
            this.sourceTopMatch.searchRow.setLookupModelData(this.textboxTrailerType.field, lmData);
            this.sourceTopMatch.searchRow.set(this.textboxTrailerType.field, trailerType);
            this.textboxTrailerType.displayData(this.sourceTopMatch.searchRow, null, 0);
            return Promise.resolve();
        } else {

            this.setOrderTrailerType();

            return Promise.resolve();
        }
    }

    async setDefaultTopMatchProfile(tmProfile?: string) {
        if (tmProfile == null) {
            const userSettings = getAuthSettings().user_settings;
            if (userSettings != null && userSettings.tm_profile != null) {
                tmProfile = userSettings.tm_profile;
            }
        }

        this.sourceTopMatch.searchRow.setLookupModelData(
            this.textboxTopMatchProfile.field,
            new ModelRow(this.textboxTopMatchProfile.lookupModel, false, { id: tmProfile })
        );
        this.sourceTopMatch.searchRow.set(this.textboxTopMatchProfile.field, tmProfile);
        this.textboxTopMatchProfile.displayData(this.sourceTopMatch.searchRow, null, 0);

        return Promise.resolve();
    }
    // END - Set defaults

    // START - Expandable Filter Logic
    private setupExpandableFilter() {
        this.expandableFilter.initialize(this.panelSearchHeader, this.panelSearchFilter);
        this.expandableFilter.setSearchBordersExpanded();
        this.expandableFilter.onClear = (event: ClickEvent) => this.buttonClearOnClick(event);
        this.expandableFilter.onSearch = (event: ClickEvent) => this.searchButtonOnClick(event);
    }
    // End - Expandable Filter Logic

    async searchButtonOnClick(event: ClickEvent) {
        await this.search();
        this.buttonClear.enabled = true;
    }

    public async search(): Promise<any> {
        const results = this.panelSearchFilter.validate(true, true, true);
        if (results != null && results.filter(r => !r.isValid).length > 0) {
            this.showValidationDialog(results);
            return;
        }

        try {
            const offerHistoryData = (await Api.search("lme/powerbroker/offer-log", { movement_id: this.sourceMovement.activeRow.get("id") })).data[0]["offer_history"];
            const searchValues = this.sourceTopMatch.getDataboundValues(DataSourceMode.SEARCH, null, true);
            searchValues.set("offer_history", offerHistoryData)

            // Load Boards Table
            if (this._enableLoadboards === true) {
                this.layoutLoadBoards.search(searchValues).then(res => {
                    if (this.panelSearchFilter.style.height != "0px" && !this.layoutTopMatch.sourceTopMatch.activeRow?.isEmpty())
                        this.expandableFilter.toggle(false);
                }).catch(err => {
                    log.error(err);
                });
            }

            // TopMatch Table
            this.layoutTopMatch.movement = this.sourceMovement;
            this.layoutTopMatch.search(searchValues).then(res => {
                if (this.panelSearchFilter.style.height != "0px" && !this.layoutTopMatch.sourceTopMatch.activeRow?.isEmpty())
                    this.expandableFilter.toggle(false);
            }).catch(err => {
                log.error(err);
            })

            // Routing Guides
            searchValues.set("customer_id", this.sourceMovement.activeRow.get("orders.customer_id"));
            searchValues.set("commodity_id", this.sourceMovement.activeRow.get("orders.commodity_id"));
            searchValues.set("order_mode", this.sourceMovement.activeRow.get("orders.order_mode"));
            this.layoutRoutingGuides.search(searchValues).then(res => {
                if (this.panelSearchFilter.style.height != "0px" && !this.layoutTopMatch.sourceTopMatch.activeRow?.isEmpty())
                    this.expandableFilter.toggle(false);
            }).catch(err => {
                log.error(err);
            })

        }
        catch (e) {
            // do nothing
        }

        return Promise.resolve()
    }

    private showValidationDialog(results: ValidationResult[]) {
        if (results.length > 0) {
            const lineProps = [];
            lineProps.push({ caption: "Unable to perform TopMatch search because data is missing or invalid.", paddingBottom: 12 });
            let componentToFocus = null;
            results.forEach(result => {
                if (!result.isValid && result.component) {
                    result.caption = result.component?.["caption"] ?? result.component?.displayLabel ?? result.component?.field;
                    if (result.caption) {
                        componentToFocus ??= result.componentToFocus ?? result.component;
                        lineProps.push({ caption: result.caption, fontBold: true });
                    }
                }
            });
            ReflectiveDialogs.showDialog(lineProps, { title: "Invalid Data", panelContentProps: { maxHeight: 400, scrollY: true } }).then(() => {
                if (componentToFocus != null)
                    componentToFocus.focus()
            });
        }
    }

    async showOfferLogIcon(orderId: string) {
        Api.search("lme/powerbroker/offer-log", { "order_id": orderId, "just_log": true }).then(response => {
            const offerHistory = response.data[0]["offer_history"];
            if (!ArrayUtil.isEmptyArray(offerHistory)) {
                let offerLogs = "";
                for (let i = 0; i < offerHistory.length; i++) {
                    const offerDate = offerHistory[i]["offer_date"];
                    const numOffers = offerHistory[i]["offer_count"];
                    const offerType = offerHistory[i]["offer_type"];
                    offerLogs += `${offerDate}  ${numOffers.toString().padStart(6, ' ')} ${offerType} Offer${numOffers > 1 ? "s" : ""}\n`;
                }
                this.labelOfferLog.visible = true;
                this.labelOfferLog.tooltipCallback = () => {
                    const panel = new Panel({ padding: 0, margin: 0 });
                    panel.add(new Label({ caption: "Offer Log", fontSize: "xlarge", fontBold: true }));
                    panel.add(new Label({ caption: offerLogs }));
                    return this.labelOfferLog.showTooltip(panel, null, { themeKey: "quickInfo" });
                }
            }
            else {
                this.labelOfferLog.visible = false;
                this.labelOfferLog.tooltipCallback = null;
            }
        });
    }

    textboxOrderIdOnChange(event: ChangeEvent) {
        if (event.newValue.length >= 7) {
            this.showOfferLogIcon(event.newValue);
        }

        if (!event.userInitiatedChange)
            return;

        this.textboxMovementId.selectedItem = null;
        this.textboxMovementId.items = [];
        this.textboxTrailerType.clear();

        if (this._acTimeoutHandle != null)
            window.clearTimeout(this._acTimeoutHandle);

        this._acTimeoutHandle = window.setTimeout(async () => {
            if (event.newValue.length >= 7) {
                this.setMovementIdOptions();
            }
        }, this.textboxOrderId.lookupModelInputDelay);
    }

    async setMovementIdOptions() {
        Api.post("lme/dispatch/order-movements",
            { order_id: this.textboxOrderId.text, brokerage_only: false }
        ).then(response => {
            if (response.data == null || response.data.length == 0)
                return;

            const moveIds = response.data.map(move => {
                return { caption: move.id, value: move.id };
            });
            this.textboxMovementId.items = moveIds;
            this.textboxMovementId.selectedItem = moveIds[0];
            this.setMovement();
        });
    }

    public async setMovement() {
        this.layoutTopMatch.clear();
        this.layoutLoadBoards.clear();
        this.layoutRoutingGuides.clear();

        if (this.searchMovement) {
            await this.sourceMovement.search({ id: this.textboxMovementId.selectedItem.value });
        }

        this.layoutTopMatch.movement = this.sourceMovement;
        this.layoutLoadBoards.movement = this.sourceMovement;
        this.layoutRoutingGuides.movement = this.sourceMovement;
        this.setOrderTrailerType();
        this.updateMoneyPanelLayout();
        this.updateStopSummary();
    }

    waterfallStarted() {
        this.sourceMovement.activeRow.set("waterfall_in_progress", true);
        this.layoutTopMatch.topMatchUtil.toggleWaterfall();
        this.layoutRoutingGuides.topMatchUtil.toggleWaterfall();
        Toast.showSuccessToast("Waterfall tendering successfully started");
    }

    setOrderTrailerType() {
        const trailerType = this.activeRow?.get("orders.equipment_type_id");
        if (StringUtil.isEmptyString(trailerType)) return;

        const lmData = new ModelRow(this.textboxTrailerType.lookupModel, false, { "id": trailerType });
        lmData.type = ModelRowType.LOOKUP_MODEL_DATA;
        this.sourceTopMatch.searchRow.setLookupModelData(this.textboxTrailerType.field, lmData);
        this.sourceTopMatch.searchRow.set(this.textboxTrailerType.field, trailerType);
        this.textboxTrailerType.displayData(this.sourceTopMatch.searchRow, null, 0);
    }

    private updateStopSummary(clear = false) {
        if (clear) {
            this.labelStopSummary.caption = null;
            this.panelPickupTimes.tooltip = null;
            return;
        }

        Api.search("lme/dispatch/stop", { "movement_id": this.activeRow.get("id") }).then(response => {
            const stopCount = response.summary_data.stop_count_text;
            this.labelStopSummary.caption = stopCount;
            this.layoutLoadBoards.stopCount = stopCount;
            this.layoutTopMatch.stopCount = stopCount;
            this.layoutRoutingGuides.stopCount = stopCount;
            const lastStop = response.data[response.data.length - 1];
            const delSchedArriveEarly =
                DateUtil.formatDate(DateUtil.parseDateTime(lastStop["sched_arrive_early"]), "iiii MMM do h:mma");
            this.panelPickupTimes.tooltip = `Delivery: ${delSchedArriveEarly}`;
        })
    }

    textboxPostedRateOnDataDisplay(event: DataDisplayEvent) {
        if (event?.rowData?.get("pnn_rate") != null)
            this.textboxOrderspnnRate.text = CurrencyUtil.formatCurrency(event?.rowData?.get("pnn_rate"));
    }

    // Method to change layout of the panel with Posted Rate, Target Pay, and Max Pay depending on whether the order has a Posted Rate
    updateMoneyPanelLayout() {
        this.textboxOrderspnnRate.visible = this.activeRow?.get("orders.pnn_rate") != null;
    }

    /** This is an event handler for the onClick event of buttonClear.  */
    buttonClearOnClick(event: ClickEvent) {
        // clear sources and summary
        this.sourceMovement.clear();
        this.sourceMovement.displayDataInBoundComponents();
        this.sourceTopMatch.clear();
        this.sourceTopMatch.displayDataInBoundComponents();
        this.updateStopSummary(true);

        // clear TopMatch & Load Boards tables
        this.layoutTopMatch.clear();
        this.layoutLoadBoards.clear();

        // clear Search fields
        this.textboxOrderId.clear();
        this.textboxMovementId.clear();
        this.textboxDateRange.clear();
        this.textboxTrailerType.clear();
        this.textboxTopMatchProfile.clear();

        // Set defaults back
        this.setDefaultTopMatchProfile();
        this.defaultDateRange();
        const clearButton: Button = event.target as Button;
        clearButton.enabled = false;
        this.textboxOrderId.focus();
    }

    /** This is an event handler for the onChange event of textboxTopMatchProfile.  */
    textboxTopMatchProfileOnChange(event) {
        this.checkProfilePermissions();
    }

    async carrierAssigned() {
        if (!this.isNested) {
            await this.setMovement();
            this.search();
        } else {
            this.owner.mainDataSource.search({ id: this.sourceMovement.activeRow.get("id") });
        }
    }
}
