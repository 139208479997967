import { Button, ClickEvent, Layout, SlideoutDecorator, Snackbar, Toast } from "@mcleod/components";
import { Api, StringUtil } from "@mcleod/core";
import { DeclineDetails } from "./DeclineDetails";
import { RowEdiOrder } from "./models/ModelEdiOrder";

export class DeclineTender {
    private _replyDescr: string = "Problem replying to tender";
    private _ediOrderRow: RowEdiOrder;

    async decline(refresh: () => void) {

        const ediOrderId = this.ediOrderRow.get("id");
        const layout = Layout.getLayout("lme/datafusion/DeclineDetails", {
            height: 1000,
            backgroundColor: "defaultBackground",
            borderColor: "strokeSecondary"
        }) as DeclineDetails;

        layout.version = this.ediOrderRow.get("version");
        layout.partnerId = this.ediOrderRow.get("partner_id");

        if ("Y" == this.ediOrderRow.get("record_in_use")) {
            Snackbar.showSnackbar("Shipment ID: " + this.ediOrderRow.get("shipment_id") + ". This tender is currently locked, and can not be accepted.", { persist: true });
            return;
        }

        if (ediOrderId) {
            const sod = new SlideoutDecorator({
                layout: layout,
                title: "Decline Tender - " + ediOrderId,
                doAfterSlideOut: () => {
                    refresh()
                },
                addlComponents: new Button({
                    caption: "Decline",
                    rowBreak: false,
                    onClick: (event: ClickEvent) => {
                        this.processDeclineClick(event.target as Button, sod, this.ediOrderRow, layout);
                    }
                })
            })
        }
    }

    private async processDeclineClick(button: Button, sod: SlideoutDecorator, ediOrderRow: RowEdiOrder, layout: DeclineDetails) {
        const reqReason = layout.reasonTextbox.text;
        let declineCode: string = "";
        if (reqReason === "Y" && layout.reasonTextbox.text == "") {
            Snackbar.showSnackbar("A reason code is required by this partner when declining a tender.  Could not send decline.");
            return;
        }
        else if (reqReason === "Y" && layout.reasonTextbox.validate) {
            ediOrderRow.set("reply_reason", layout.reasonTextbox.text);
            declineCode = layout.reasonTextbox.getFirstLookupModelData().get(layout.reasonTextbox.lookupModelResultField, null);
        }
        else {
            if (layout.reasonTextbox.validate && !StringUtil.isEmptyString(layout.reasonTextbox.text)) {
                declineCode = layout.reasonTextbox.getFirstLookupModelData().get(layout.reasonTextbox.lookupModelResultField, null);
            }
            else {
                declineCode = "";
            }
        }
        const ediOrderId = ediOrderRow.get("id");
        const remarks = layout.remarksTextbox.text;

        await this.declineTender(ediOrderId, declineCode, remarks).then(async response => {
            const desc = response?.data[0].description
            if (!StringUtil.isEmptyString(desc)) {
                this._replyDescr = desc;
            }
        });

        sod.slideOut();
        layout.addUnmountListener(() => {
            Toast.showSuccessToast(this._replyDescr);
        });
    }

    async declineTender(ediOrderId: string, declineCode: string, remarks: string): Promise<any> {
        return await Api.post("lme/datafusion/decline-tender", { edi_order_id: ediOrderId, decline_code: declineCode, decline_remarks: remarks });
    }
    public get replyDescr(): string {
        return this._replyDescr;
    }
    public set replyDescr(value: string) {
        this._replyDescr = value;
    }

    public get ediOrderRow(): RowEdiOrder {
        return this._ediOrderRow;
    }
    public set ediOrderRow(value: RowEdiOrder) {
        this._ediOrderRow = value;
    }
}
